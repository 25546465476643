import {MenuItem} from './MenuItem'
import {useAuth} from '../../../../app/modules/auth'
import {isCandidatePath} from '../../../../constants/CommonFunctions'

const hostname = window.location.hostname
export function CandidateMenuInner() {
  const {currentUser} = useAuth()
  const MasterNav = () => {
    ;<div></div>
  }

  return (
    <>
      {(currentUser?.role === 'candidate' || isCandidatePath(hostname)) && (
        <>
          <MenuItem title='Dashboard' to='/candidatedashboard' />
          <MenuItem title='Vacancies' to='/mainvacancies' />
          <MenuItem title='Applications' to='/candidateapplications' />
        </>
      )}
    </>
  )
}
