import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const MasterNav = () => {
    ;<div></div>
  }

  return (
    <>
      {currentUser?.role === 'masterRecruiter' && (
        <>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
          <MenuItem title='Jobs' to='/jobs' />
          <MenuItem title='Vacant Positions' to='/vacantpositions' />
          <MenuItem title='Competencies' to='/competencies' />
          <MenuItem title='Tests' to='/tests' />
          {/* <MenuItem title='Leaderboard' to='/leaderboard' /> */}
          <MenuItem title='Analytics' to='/analytics' />
          <MenuItem title='Settings' to='/settings' />
          <MenuItem title='Users' to='/users' />
        </>
      )}
      {currentUser?.role === 'recruiter' && (
        <>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
          <MenuItem title='Jobs' to='/jobs' />
          {/* <MenuItem title='Test' to='/tests' /> */}
          <MenuItem title='Vacant Positions' to='/vacantpositions' />
          <MenuItem title='Leaderboard' to='/leaderboard' />
          {/* <MenuItem title='Settings' to='/settings' /> */}
        </>
      )}
      {currentUser?.role === 'poc' && (
        <>
          <MenuItem title='Dashboard' to='/pocdashboard' />
          <MenuItem title='Positions' to='/positions' />
        </>
      )}
      {(currentUser?.role === 'ta_lead' || currentUser?.role === 'ta_head') && (
        <>
          <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
          <MenuItem title='Positions' to='/positions' />
          <MenuItem title='Vacant Positions' to='/vacantpositions' />
          <MenuItem title='Jobs' to='/jobs' />
          <MenuItem title='Competencies' to='/competencies' />
          <MenuItem title='Tests' to='/tests' />
          <MenuItem title='Settings' to='/settings' />
          {/* <MenuItem title='Leaderboard' to='/leaderboard' /> */}
          <MenuItem title='Analytics' to='/analytics' />
        </>
      )}
      {currentUser?.role === 'none' && (
        <>
          <MenuItem title='Dashboard' to='/employeeDashboard' />
        </>
      )}
    </>
  )
}
