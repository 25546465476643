import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import UserIcon from './img/Group 9681.png'
import ExperienceIcon from './img/experience.png'
import Cap from './img/mortarboard.png'
import { useAuth } from '../../../../app/modules/auth'
import axios from 'axios'
import Layout from '../images/Layout-4-blocks (3).png'
import imagePath from "../../../../constants/imagePath";

type Props = {
    show: boolean
    jobapp_id: string
    position: string
    candidate_id: string
    name: string
    banking_experience: string
    total_experience: string
    insitution: string
    profile_pic: string
    recruiter_id: string
    handleClose: () => void
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_APPROVE_DATA = `${API_URL}/panel/get-approve-data`

export const APPROVE_CANDIDATE = `${API_URL}/panel/approve-candidate`

export const REJECT_CANDIDATE = `${API_URL}/panel/panel-rejection`

export const PANEL_OBJECTION = `${API_URL}/panel/panel-objection`

export const GET_PANEL_OBJECTION = `${API_URL}/jobapplication/get-filter-objection-data`

type ObjectionNote = {
    name: string;
    profile: string;
    member: {
        time: string;
        message: string;
    };
};

const ApporvalsObjection = ({ show, jobapp_id, position, candidate_id, name,
    banking_experience, total_experience, insitution, profile_pic, recruiter_id, handleClose }: Props) => {
    const { auth } = useAuth()
    const [inputValue, setInputValue] = useState<any>("");
    const [userData, setUserData] = useState<any>([])
    const [candData, setCandData] = useState<any>([])
    const [memberData, setMemberData] = useState<any>([])
    const [interviewData, setInterviewData] = useState<any>([])
    const [jobData, setJobData] = useState<any>([])
    const [jobDataPay, setJobDataPay] = useState<any>([])
    const [text, setText] = useState<any>('');
    const [texts, setTexts] = useState<any>([]);
    const [displayedTexts, setDisplayedTexts] = useState<any>([]);
    const [formValues, setFormValues] = useState<any>({
        basicPay: '',
        carAllowance: '',
        fuel: '',
        monthlyPackage: '',
        percentIncrease: '',
        allowanceType: '',
    });
    const [messages, setMessages] = useState<any>([]);
    const [percentIncrease, setPercentIncrease] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isEditable, setIsEditable] = useState(false);
    const [carAllowanceChecked, setCarAllowanceChecked] = useState(false);
    const [fuelChecked, setFuelChecked] = useState(false);
    const [imageURL, setImageURL] = useState<any>('')
    const [dataFetched, setDataFetched] = useState(false)
    const [allJobObjection, setallJobObjection] = useState<any>([])
    let values;
    const [jobappid, setjobappid] = useState<any>('')
    const [memberid, setmemberid] = useState<any>('')
    const [oldConversation, setOldConversation] = useState<any>([])
    const [conversation, setConversation] = useState<any>([])
    const [showOlderTxt, setShowOlderTxt] = useState<any>([])
    const [currentUser, setCurrentUser] = useState<any>([])
    const [modal, setShowModal] = useState<boolean>(true)
    const [objectionNotes, setObjectionNotes] = useState<ObjectionNote[]>([]);
    const [objectionDateTime, setObjectionDateTime] = useState('')

    const GETAPPROVADATA = async () => {
        await
            axios.post(
                GET_APPROVE_DATA,
                {
                    jobapp_id: jobapp_id,
                    name: "approvals",
                    member_id: recruiter_id
                },

            ).then((res) => {
                console.log({ res })
                setUserData(res?.data?.user)
                setCandData(res?.data?.candidate)
                setMemberData(res?.data?.members)
                setInterviewData(res?.data?.jobApplication)
                setJobData(res?.data?.job)
                setJobDataPay(JSON.parse(res?.data?.job?.previous_job_pay));
                setImageURL(res?.data?.candidate?.cv_url)
                setCurrentUser(res?.data?.currentMember)

            })
    }

    const GETPANELOBJECTIONS = async () => {
        await axios.post(GET_PANEL_OBJECTION, {
            jobapp_id: jobapp_id,
            jobapp_stage: "approvals",
            member_id: null
        }).then((res) => {
            console.log(res)
            console.log(res?.data?.objection_notes)
            setObjectionNotes(res?.data?.objection_notes)
            let objectionRecord = JSON.parse(res?.data?.jobApplication?.objection_record);
            let time = objectionRecord?.time_of_objection;
            setObjectionDateTime(time)
        })

    }
    console.log({ objectionNotes })

    const handleSendButtonClick = async () => {
        console.log({ text })
        setDisplayedTexts([
            <div key={memberid} className='request-for-approvals-external-header-objection-chat-txt'>
                <span className='request-for-approvals-internal-header-objection-msgs-cont'>
                    {text}
                </span>
            </div>,
            ...displayedTexts
        ]);
        await OBJECTIONAPI()
        setText('');
        GETPANELOBJECTIONS();

    };

    console.log({ conversation })
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendButtonClick();
        }
    }

    const handleInputChangeObjection = (event) => {
        setText(event.target.value)
    }

    const OBJECTIONAPI = async () => {
        await axios.post(
            PANEL_OBJECTION,
            {

                member_id: recruiter_id,
                name: "approvals",
                jobapp_id: jobapp_id,
                message: text

            }
        ).then((res) => {
            console.log({ res })
        })
    }

    useEffect(() => {
        GETAPPROVADATA();
        GETPANELOBJECTIONS();

    }, [])
    const [showPopup, setShowPopup] = useState(true);

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    console.log({ currentUser })
    return (
        <div>
            <Modal id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName="modal-90w"
                show={true}
                onHide={handleClose}
                backdrop={true}
            >
                <Modal.Header className="modal-header">
                    <Modal.Title
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            fontSize: '19px',
                            fontWeight: '600',
                            marginLeft: '15rem'
                        }}
                    >Note</Modal.Title>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <img alt='' src={imagePath.ic_close}></img>
                    </div>
                </Modal.Header>

                <Modal.Body style={{ height: '73vh' }}>
                    <p className="model-position">Position: {position}</p>

                    <div>
                        <label className="model-chat-p">Select Group Heads in sequence for Approvals</label>
                        <div className='model-panel-members'>
                            {
                                memberData?.map((item, index) => {
                                    return (
                                        <div key={index} className="model-panel-tile">
                                            <label>{index + 1}.</label>
                                            {item?.profilePic === null ?
                                                <img src={UserIcon} style={{ height: "3vh", width: "3vh", borderRadius: "50%", objectFit: "cover" }} />
                                                :
                                                <img src={item?.profilePic} style={{ height: "3vh", width: "3vh", borderRadius: "50%", objectFit: "cover" }} />
                                            }
                                            <label style={{ whiteSpace: 'nowrap' }}>{item['userName']}</label>
                                        </div>
                                    )

                                })
                            }
                        </div>
                    </div>

                    {/* <p className="model-headinger">Candidate Details</p>
                    <div className="model-profile">

                        <div className="model-name-cont">
                            <img src={UserIcon} />
                            <span className="model-name-tag">{name}</span>
                        </div>

                        <div className="model-exp-cont">
                            <img src={ExperienceIcon} />
                            <div className="model-exp-inner-cont">
                                <span className="model-exp-inner-const">Banking Experience: <p style={{ margin: 0, fontWeight: 'bold', color: 'black' }}>{banking_experience} yrs</p></span>
                                <span className="model-exp-inner-const">Total Experience: <p style={{ margin: 0, fontWeight: 'bold', color: 'black' }}>{total_experience} yrs</p></span>
                            </div>
                        </div>

                        <div className="model-exp-cont">
                            <img src={Cap} />
                            <div className="model-exp-inner-cont">
                                <span className="model-exp-inner-const">MS Computer Science</span>
                                <span className="model-exp-inner-const">{insitution}</span>
                            </div>
                        </div>
                    </div> */}

                    <hr className="model-hr" />

                    <div className="model-chat-cont">
                        <p className="model-chat-p">Add Notes</p>



                        <div className="model-input-cont">
                            {currentUser?.profile_pic ? (
                                <img src={currentUser?.profile_pic} style={{ height: "5vh", width: "5vh", borderRadius: "50%", objectFit: "cover" }} />
                            ) : (
                                <img src={UserIcon} style={{ height: "5vh", width: "5vh", borderRadius: "50%", objectFit: "cover" }} />
                            )}

                            <input
                                className='request-for-approvals-external-header-objection-input-field'
                                type='text'
                                value={text}
                                placeholder='Write a Note'
                                style={{ paddingRight: '40px' }}
                                onChange={handleInputChangeObjection}
                                onKeyDown={handleInputKeyDown}
                            />
                            <i className='fa fa-paper-plane' onClick={handleSendButtonClick}
                                style={{ marginLeft: '-33px', cursor: 'pointer', marginTop: '9px' }}></i>
                        </div>

                        <div className="model-txtx">
                            {messages.map((message, index) => (
                                <div key={index} className='model-txtx-msg'>
                                    <p className="model-name-tag"> {name} </p>
                                    <p>{message} </p>
                                </div>
                            ))}
                        </div>

                        <div className='request-for-approvals-internal-header-objection-msgs'

                        >

                            <div>
                                {objectionNotes
                                    .slice()
                                    .reverse()
                                    .map((conversation, index) => {
                                        console.log('conversationData: ', conversation);
                                        const conversationTime: any = new Date(conversation?.member?.time);
                                        const currentTime = new Date();
                                        const timeDiffInMs = currentTime.getTime() - conversationTime.getTime();

                                        // Calculate time difference in seconds, minutes, hours, and days
                                        const timeDiffInSeconds = Math.floor(timeDiffInMs / 1000);
                                        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                                        const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                                        const timeDiffInDays = Math.floor(timeDiffInHours / 24);

                                        let displayTimeDiff: string;

                                        if (timeDiffInDays > 0) {
                                            displayTimeDiff = timeDiffInDays + 'd';
                                        } else if (timeDiffInHours > 0) {
                                            displayTimeDiff = timeDiffInHours + 'h';
                                        } else if (timeDiffInMinutes > 0) {
                                            displayTimeDiff = timeDiffInMinutes + 'm';
                                        } else {
                                            displayTimeDiff = timeDiffInSeconds + 's';
                                        }

                                        // checking is message is from old objection or not
                                        const objectionCreatedDateTime = new Date(objectionDateTime);
                                        let objectionCreatedTime = objectionCreatedDateTime.getTime();
                                        let conversationDateTime = conversationTime.getTime();
                                        let isOldMessage = (objectionCreatedTime - conversationDateTime) >= 0 ? true : false;
                                        console.log('isOldMessage: conversationDateTime: ', conversationDateTime);
                                        console.log('isOldMessage: objectionCreatedTime: ', objectionCreatedTime);
                                        console.log('isOldMessage: ', isOldMessage);
                                        return (
                                            <div key={index} style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                                                <span>
                                                    {conversation?.profile ?
                                                        <img
                                                            src={conversation?.profile}
                                                            alt="Profile"
                                                            style={{ height: "5vh", width: "5vh", borderRadius: "50%", objectFit: "cover" }}
                                                        />
                                                        :
                                                        <img
                                                            src={UserIcon}
                                                            style={{ height: "5vh", width: "5vh", borderRadius: "50%", objectFit: "cover" }}
                                                        />
                                                    }

                                                </span>
                                                {isOldMessage ? (<div className='old-objection-bg mb-2 p-2'>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: '10px', marginRight: '10px', marginBottom: '5px' }}>
                                                        <p style={{ margin: '0', color: '#747880', font: "normal normal 650 12px/20px Nunito" }}>
                                                            {conversation.name}
                                                        </p>
                                                        <p style={{ margin: '0' }}>
                                                            <p style={{ margin: '0', color: '#A4A4AD', fontSize: '10px' }}>
                                                                {displayTimeDiff}
                                                            </p>
                                                        </p>
                                                    </div>
                                                    <p style={{ margin: '0', marginLeft: '10px', color: '#A4A4AD', font: "normal normal normal 12px/20px Nunito" }}>
                                                        {conversation?.member?.message}
                                                    </p>
                                                </div>)
                                                    : (<div className='request-for-approvals-external-header-objection-msgs-cont'>
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: '10px', marginRight: '10px', marginBottom: '5px' }}>
                                                            <p style={{ margin: '0', color: '#373D4A', font: "normal normal 650 12px/20px Nunito" }}>
                                                                {conversation.name}
                                                            </p>
                                                            <p style={{ margin: '0' }}>
                                                                <p style={{ margin: '0', color: '#80808F', fontSize: '10px' }}>
                                                                    {displayTimeDiff}
                                                                </p>
                                                            </p>
                                                        </div>
                                                        <p style={{ margin: '0', marginLeft: '10px', color: '#80808F', font: "normal normal normal 12px/20px Nunito" }}>
                                                            {conversation?.member?.message}
                                                        </p>
                                                    </div>)}
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

            <style type="text/css">
                {`
          .modal-90w {
            position: fixed;
            bottom: 0;
            right: 0;
            z-index: 1050;
            width: 45vw;
            margin-bottom: 0;
          }

          .modal-header {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background-color: #FF8750;
            gap: 14rem;
          }

          .model-position {
            background: #F3F6F9 0% 0% no-repeat padding-box;
            border-radius: 4px;
            text-align: center;
            font: normal normal bold 14px/20px Nunito;
            letter-spacing: 0px;
            color: #80808F;
            padding: 10px;


          }

          .model-tiles{
            background: var(--chips-bg) 0% 0% no-repeat padding-box;
            background: #FFEBCE 0% 0% no-repeat padding-box;
            border-radius: 6px;
            opacity: 1;
            text-align: left;
            font: normal normal bold 12px/20px Nunito;
            letter-spacing: 0px;
            color: #F36523;
            opacity: 1;
            display: flex;
            flex-directin: row;
            gap: 10px
          }

          .model-headinger{
            text-align: left;
            font: normal normal 600 12px/20px Nunito;
            letter-spacing: 0px;
            color: #80808F;
            opacity: 1;
            margin-top: 1rem;
          }

          .model-name-tag{
            text-align: left;
            font: normal normal 700 14px/23px Nunito;
            letter-spacing: 0px;
            color: #373D4A;
            opacity: 1;
          }

          .model-name-cont {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
          }
          
          .model-exp-cont {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        
          .model-exp-inner-cont {
            display: flex;
            flex-direction: column;
            margin: 0;
          }
        
          .model-exp-inner-const {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font: normal normal medium 12px/20px Nunito;
            letter-spacing: 0px;
            color: #80808F;
          }
        
          .model-profile {
            display: flex;
            flex-direction: row;
            gap: 3.7rem;
            align-items: center;
          }

          .model-hr{
            border: none;
            border-top: 3px dotted black;
            background-color: transparent;
            height: 0;
            margin: 1em 0;
            color: #E0E0E0;
          }

          .model-chat-p{
            text-align: left;
            font: normal normal bold 14px/23px Nunito;
            letter-spacing: 0px;
            color: #373D4A;
            opacity: 1;
            margin-top: 1rem;
            margin-bottom: 1rem;
          }

          .model-input-cont {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }

          .model-txtx {
            display: flex;
            gap: 10px;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
        } 

        .model-txtx-msg {
            width: 90%;
            border: none;
            background-color: #F3F6F9;
            height: auto;
            /* padding: 3px; */
            border-radius: 7px;
            /* margin: 10px; */
            padding-left: 10px;
            padding-top: 3px;
        }

        .model-panel-members{
            display : grid;
            grid-template-rows: auto;
            grid-template-columns: repeat(2, 140px);
            gap: 15px;
        }

        .model-panel-tile {
            display : flex;
            flex-direction : row;
            gap: 10px;
            background: #FFEBCE 0% 0% no-repeat padding-box;
            border-radius: 6px;
            opacity: 1;
            text-align: left;
            font: normal normal bold 12px/20px Nunito;
            letter-spacing: 0px;
            color: #F36523;
            opacity: 1;
            padding: 9px;
        }
        .request-for-approvals-internal-header-objection-msgs {
            margin-top: 1rem;
            height: 200px;
            max-height: 240px;
            overflow-y: scroll;
        }
        request-for-approvals-internal-header-objection-msgs {
            margin-top: 1rem;
            margin-left: 0rem;
            height: 212px;
            max-height: 240px;
            overflow-y: scroll;
        }
        .old-objection-bg {
            background-color: #EDEFF1;
            border-radius: 4px;
            flex: 1;
        }
        
        `}
            </style>

        </div>
    )
}

export { ApporvalsObjection }