//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import './CreateCompetency.css'
import cross from '../images/Layout-4-blocks.png'
import hurrayImg from '../images/hurray_img@3x.png';
import imagePath from '../../../../constants/imagePath';

type Props = {
    handleClose: () => void
    message: any
}
const modalsRoot = document.getElementById('root-modals') || document.body


// create a component
const ActionFailedPopup = ({ handleClose, message }: Props) => {
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-400px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123'>

                {/* end::Close */}
                <div className=' d-flex flex-column mt-10 pt-10'>
                    <img style={{width:'64px', height:'64px', margin:'auto'}} alt='warning-icon' src={imagePath.ic_warning_triangle_orange}  />
                    <h3 className='fw-bolder mx-auto my-5'>Caution</h3>
                    <p className=' mx-auto my-5 text-style col-md-8 '>{message? message : 'Something went wrong!'}</p>
                </div>
            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
                <form
                >
                    <div className='btn-container'>
                        <button
                            onClick={handleClose}
                            type='button'
                            className='btn fw-bolder px-10 candi-btn2 text-white'
                        >
                            Close
                        </button>
                    </div>
                </form>
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default ActionFailedPopup;
