import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useState, useEffect, useContext} from 'react'
import './Test.css'
import {useAuth} from '../auth/core/Auth'
import {Link, useNavigate} from 'react-router-dom'
// import {useContext} from 'react'
import arrowdown from './images/navigate_next_black_24dp.png'
import {useTestContext} from '../../../context/useTestContext'
import axios from 'axios'
import unchecked from './images/Group 10062.png'
import checked from './images/tick.png'
import uploadIcon from './images/upload (1).png'
import uncheckedMCQsOption from './images/tick grey.png'
import CheckedMCQOprion from './images/tick green.png'
import TestQuestion from './TestQuestion'
import deleteIcon from './images/delete-icon.png'
import cross from '../../../_metronic/partials/content/images/cross.png'
import {useTestQuestionDataContext} from '../../../context/useTestQuestionDataContext'
import {TestQuestionDataProvider, TestQuestionDataContext} from '../../../context/TestQuestionDataContext'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get?type=Competency`
export const CREATE_TEST_URL = `${API_URL}/test/create`

const Test = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {auth, logout} = useAuth()
  const {Test: TestCheck, setTestButton} = useTestContext()
  const [testName, setTestName] = useState('')
  const [components, setComponents] = useState([<TestQuestion questionNumber={1} />])
  const {testQuestionsData, setTestQuestionsData} = useContext(TestQuestionDataContext)
  const [questions, setQuestions] = useState<any>([
    {type: '', text: '', options: {a: '', b: ''}, correct_answer: '', competencies: [], video: null},
  ])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [groupCompetency, setGroupCompetency] = useState<any>([])
  const [loading, setLoading] = useState(false)

  const validateQuestionsData = (questions) => {
    let result = true
    if (!testName) {
      setMessage('Test name is not valid.')
      setShowErrorMessage(true)
      return false
    }
    if (!SetTimeForEachTest) {
      setMessage('Test time is not valid.')
      setShowErrorMessage(true)
      return false
    }
    const isGroupCompetencyValid = groupCompetency && groupCompetency.length > 0 && groupCompetency.every((item) => item)
    if (!isGroupCompetencyValid) {
      setMessage('Please link group competencies first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (questions.length > 0) {
      for (let i = 0; i < questions.length; i++) {
        let question = questions[i]
        if (question) {
          if (!question.type || question.type === 'Select type') {
            setMessage('Please select type of question first')
            setShowErrorMessage(true)
            result = false
            break
          }
          // check if question time is video mcq and video file us uploaded or not
          if (question.type === 'Video Based MCQs' && !question.video) {
            setMessage('Video based questions should have video, Please upload first!')
            setShowErrorMessage(true)
            result = false
            break
          }
          if (!question.text) {
            setMessage('Question can not be empty')
            setShowErrorMessage(true)
            result = false
            break
          }
          if (!question.correct_answer) {
            setMessage('Each question should have a correct answer')
            setShowErrorMessage(true)
            result = false
            break
          }
          if (!question.competencies || question.competencies.length === 0) {
            setMessage('Each question should have at least 1 competency')
            setShowErrorMessage(true)
            result = false
            break
          }
          if (!question.options || Object.keys(question.options).length < 2) {
            setMessage('Each question should have at least 2 options')
            setShowErrorMessage(true)
            result = false
            break
          }
          let isEmptyValuePresent = false

          for (let key in question.options) {
            if (question.options[key] === '') {
              isEmptyValuePresent = true
              break
            }
          }
          if (isEmptyValuePresent) {
            setMessage('Options of question can not be empty')
            setShowErrorMessage(true)
            result = false
            break
          }
        }
      }
    } else {
      setMessage('Test without questions can not be saved')
      setShowErrorMessage(true)
      return false
    }
    return result
  }

  function handleSaveButtonClick() {
    // const questionData = testQuestionsData.map(testQuestion => {
    //   return {
    //     type: testQuestion["questionType"],
    //     text: testQuestion["question"],
    //     options: testQuestion["options"],
    //     correct_answer: testQuestion["selectedOption"],
    //     competencies: testQuestion["selectedCompetencies"],
    //   };
    // });

    // console.log('questionDataOfTest: ', questionData);

    if (!validateQuestionsData(questions)) {
      return
    }

    const QuestionDataFinal = questions.filter((question) => {
      return question != null && Object.values(question).some((val) => val !== undefined)
    })

    setLoading(true)
    var testData = {
      name: testName,
      competencies: groupCompetency,
      test_time: SetTimeForEachTest,
      questions: QuestionDataFinal,
    }
    console.log('TESTData', testData)
    var data = JSON.stringify(testData)
    const url = CREATE_TEST_URL
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        console.log('res', res)
        navigate('/tests')
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  function handleButtonClick() {
    setQuestions([...questions, {type: '', text: '', options: {a: '', b: ''}, correct_answer: '', competencies: []}])
  }
  function handleDelete(index) {
    const newQuestions = [...questions]
    newQuestions.splice(index, 1)
    console.log('questionListUpdated: data:', newQuestions)

    setQuestions(newQuestions)
  }

  const removeItem = (index) => {
    setGroupCompetency([...groupCompetency.slice(0, index), ...groupCompetency.slice(index + 1)])
  }
  useEffect(() => {
    setTestButton('save')

    return () => {
      setTestButton('none')
    }
  }, [TestCheck])
  // console.log(' dash', loggedIn)
  const [displayTestTime, setdisplayTestTime] = useState(false)
  const [SetTimeForEachTest, setSetTimeForEachTest] = useState('None')
  const [displayCompetencies, setdisplayCompetencies] = useState(false)
  // const [SetTimeForEachTest, setSetTimeForEachTest] = useState([])

  const DifferentTime = [
    'None',
    '15 minutes',
    '20 minutes',
    '25 minutes',
    '30 minutes',
    '35 minutes',
    '40 minutes',
    '45 minutes',
    '50 minutes',
    '55 minutes',
    '60 minutes',
  ]

  const DisplayCompetenciesropDown = () => {
    setdisplayCompetencies(!displayCompetencies)
  }

  const handleAddCompetency = (competency) => {
    if (!groupCompetency.includes(competency)) {
      setGroupCompetency([...groupCompetency, competency])

      setdisplayCompetencies(false)
    }
    setdisplayCompetencies(false)
  }

  const DisplayTestTimeDropDown = () => {
    setdisplayTestTime(!displayTestTime)
  }

  const SetTestTimeFunction = (event) => {
    setSetTimeForEachTest(event.target.value)
    // setdisplayTestTime(!displayTestTime)
  }

  const SetGroupCompetencies = (value) => {
    setGroupCompetency(value)
  }

  const changeTestName = (value) => {
    setTestName(value)
  }

  const [checkedstate, setcheckedstate] = useState(-1)
  const Selectedcompetencies: any = []

  const checkedStateFunction = (index, Competencyname) => {
    Selectedcompetencies.push(Competencyname)
    console.log(Selectedcompetencies)

    console.log(index)
    if (index == -1) {
      setcheckedstate(-1)
    } else {
      setcheckedstate(index)
    }
  }

  var TotalCompetecies
  var TotalIndivualCom = 0
  var TotalGroupCom = 0
  const CompetenciesTypesArray: string[] = []
  const GroupCompetenciesNamesArray: any[] = []
  const IndividualCompetenciesNamesArray: any[] = []
  const [GroupCompArray, setGroupCompArray] = useState<any>([])
  const [IndividualCompArray, setIndividualCompArray] = useState<any>([])

  const GetAllIndiviualCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL)
      .then((resp) => {
        TotalCompetecies = resp.data.competencies
        console.log('TotalCompetecies', TotalCompetecies)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })

    // console.log('TotalCompetecies in Group', TotalCompetecies)
    for (var i = 0; i < TotalCompetecies.length; i++) {
      CompetenciesTypesArray[i] = TotalCompetecies[i]['type']

      if (CompetenciesTypesArray[i] == 'Group') {
        GroupCompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalGroupCom = TotalGroupCom + 1
      } else {
        IndividualCompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalIndivualCom = TotalIndivualCom + 1
      }
    }
    if (GroupCompetenciesNamesArray === undefined) {
      setGroupCompArray(' ')
    } else {
      const filteredArray = GroupCompetenciesNamesArray.filter((item) => item !== null && item !== undefined && item !== '')
      setGroupCompArray(filteredArray)
    }
    if (IndividualCompetenciesNamesArray === undefined) {
      setIndividualCompArray(' ')
    } else {
      const filteredArray = IndividualCompetenciesNamesArray.filter((item) => item !== null && item !== undefined && item !== '')
      setIndividualCompArray(filteredArray)
    }
  }

  useEffect(() => {
    GetAllIndiviualCompetencies()

    console.log('CompArrayCompArrayCompArrayCompArray', GroupCompArray)
  }, [])

  const updateQuestionType = (value, index) => {
    let data = [...questions]
    data[index]['type'] = value
    if (value !== 'Video Based MCQs') {
      data[index]['video'] = null
    }
    setQuestions(data)
  }

  // update question text
  const questionTextChange = (value, index) => {
    console.log('questionIndex: ', index)
    let data = [...questions]
    data[index]['text'] = value
    setQuestions(data)
  }

  const addNewOption = (value, index) => {
    let data = [...questions]

    let options = data[index]['options']
    console.log('total Op')
    // if (options) {
    if (Object.keys(options).length >= 5) {
      setMessage('Can not add more options!')
      setShowErrorMessage(true)
      return
    }
    if (Object.keys(options).length !== 0) {
      const keys = Object.keys(options)
      const lastKey = keys[keys.length - 1]
      let asciiCode = lastKey.charCodeAt(0)
      const newKey = String.fromCharCode(asciiCode + 1)

      const updatedOptions = {...options}
      // Add a new key-value pair
      console.log('updatedOptions: newKey: ', newKey)
      updatedOptions[newKey] = ''

      data[index]['options'] = updatedOptions
      setQuestions(data)
    } else {
      let newOption = {a: ''}
      data[index]['options'] = newOption
      console.log('updatedOptions: data: ', data)
      setQuestions(data)
    }
  }

  const optionTextChange = (key, value, index) => {
    let data = [...questions]
    data[index]['options'][key] = value
    setQuestions(data)
  }

  const removeOption = (key, index) => {
    let data = [...questions]
    let options = data[index]['options']
    // check is the length of options is greater than 2 or not
    const totalOptions = Object.keys(options).length
    if (totalOptions <= 2) {
      setMessage('Question should have at least two options.')
      setShowErrorMessage(true)
      return
    }
    // Check if the key exists in the options object
    if (key in options) {
      // Remove the option by deleting the key from the updatedOptions object
      delete options[key]
    }

    // update the keys of options  keys should be in alphabet sequence
    const updatedOptions = {}
    Object.entries(options).map(([key, value], i) => {
      let newKey = String.fromCharCode(97 + i)
      console.log('updatedOptions: remainingKey: ', newKey)
      updatedOptions[newKey] = value
    })

    data[index]['options'] = updatedOptions
    setQuestions(data)
  }

  const correctOptionSelection = (value, index) => {
    let data = [...questions]
    data[index]['correct_answer'] = value
    setQuestions(data)
  }

  const updateCompetencies = (value, index) => {
    // let data= [...questions];
    // data[index]['competencies'] = [...data[index]['competencies'], value];
    // console.log('updatedCompetencies:1 ', data);
    // setQuestions(data);

    let data = [...questions]
    let competencies = data[index]['competencies']

    if (competencies.includes(value)) {
      // Value already exists in competencies, so remove it
      competencies = competencies.filter((item) => item !== value)
      console.log('competencyData: valueRemoved: ', competencies)
    } else {
      // Value doesn't exist in competencies, so add it
      competencies = [...competencies, value]
      console.log('competencyData: valueAdded: ', competencies)
    }

    data[index]['competencies'] = competencies
    console.log('updatedCompetencies: ', data)
    setQuestions(data)
  }

  const removeCompetency = (value, index) => {
    let data = [...questions]
    data[index]['competencies'] = data[index]['competencies'].filter((item) => item !== value)
    console.log('updatedCompetencies:2 ', data)
    setQuestions(data)
  }

  const questionVideoChange = (fileUrl, index) => {
    console.log('questionIndex: ', index)
    let data = [...questions]
    data[index]['video'] = fileUrl
    setQuestions(data)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Create Test'})}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{fontSize: '1.5rem'}}></i>
          <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
          <Link to='/dashboard' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
          <Link to='/tests' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
            Tests
          </Link>
          <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
          <Link to='/createtest' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
            Create Test
          </Link>
        </h3>
      </div>

      {/* begin::Row */}

      <div className='card-bg shadow-sm py-6'>
        <div className='d-flex justify-content-center my-10'>
          <div className='col-md-4'>
            <div className='ms-8'>
              <label className='form-label labelse '>Test Name</label>
              <input
                // style={{ margin: '0px 5px 0px 0px', width: '328px', height: '56px' }}
                type=''
                className='form-control bg-white'
                id=''
                placeholder='Name'
                onChange={(event) => changeTestName(event.target.value)}
              />
            </div>
          </div>
          <div className='col-md-4 px-6'>
            <label className='form-label labelse '>Test Time</label>
            <select id='timeSelect' className='form-select' value={SetTimeForEachTest} onChange={SetTestTimeFunction}>
              {DifferentTime.map((time, index) => (
                <option key={index} value={time} className='select-options-txt'>
                  {time}
                </option>
              ))}
            </select>
          </div>{' '}
          <div className='col-md-4'>
            <div className='me-8'>
              <label className='form-label labelse '>Link Competency Group</label>
              <div className='TestTime-div d-flex align-items-center '>
                <input
                  type=''
                  onClick={() => DisplayCompetenciesropDown()}
                  style={{
                    margin: '0px 5px 0px 0px',
                    border: 'none',
                  }}
                  className='form-select bg-white cursor-pointer'
                  id=''
                  placeholder='Select Group'
                />{' '}
                <div className='overlayGrpCompetencies'>
                  {displayCompetencies === true ? (
                    <div className='px-4'>
                      {GroupCompArray.map((data, index) => (
                        <div className='py-2 liststylingGrpCompetemcies' key={index}>
                          <input
                            type='checkbox'
                            className='form-check-input me-2 cursor-pointer'
                            style={{height: '16px', width: '16px', borderRadius: '4px'}}
                            value={data}
                            checked={groupCompetency.includes(data)}
                            onChange={(e) => {
                              if (!groupCompetency.includes(data)) {
                                setGroupCompetency([...groupCompetency, data])
                              } else {
                                setGroupCompetency(groupCompetency.filter((item) => item !== data))
                              }
                              setdisplayCompetencies(false)
                            }}
                          />

                          <label className='form-check-label '>{data}</label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                {groupCompetency.map((competenmcy, index) => (
                  <div style={{margin: '20px 0px 0px 0px'}}>
                    <a>
                      <span className='orange-tag fs-7 me-3 fw-bolder ' key={index}>
                        {competenmcy} &nbsp;
                        <span>
                          <img
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              removeItem(index)
                            }}
                            src={imagePath.ic_cross_orange}
                          ></img>
                        </span>
                      </span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>{' '}
        </div>
      </div>

      {questions &&
        questions.map((question, index) => {
          return (
            <div key={index} style={{position: 'relative'}} className='mt-10 px-8 card-bg shadow-sm'>
              <TestQuestion
                questionNumber={index + 1}
                questionData={question}
                // pass functions to update data of question
                updateQuestionType={updateQuestionType}
                questionTextChange={questionTextChange}
                addNewOption={addNewOption}
                optionTextChange={optionTextChange}
                removeOption={removeOption}
                correctOptionSelection={correctOptionSelection}
                updateCompetencies={updateCompetencies}
                removeCompetency={removeCompetency}
                questionVideoChange={questionVideoChange}
              />

              {index !== 0 && (
                <button className='delete-button m-6 ' onClick={() => handleDelete(index)}>
                  <img src={imagePath.ic_delete_orange_cross} alt='Delete icon' className='delete-icon' />
                </button>
              )}
            </div>
          )
        })}

      <div className='center button-container'>
        <div className='candi-btn2 text-white me-4 fw-bolder' onClick={handleButtonClick}>
          <span>Add Question</span>
        </div>
        <div className='candi-btn2 text-white fw-bolder' onClick={handleSaveButtonClick}>
          <span>Save Test</span>
        </div>
      </div>

      {loading && <LoaderView message='Loading...' />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default Test
