/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './ModelStyles.css'
import { useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import ScheduleInterview from '../../../../app/modules/JobsFunneling/ScheduleInterview/ScheduleInterview'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ScheduleInterviewPopUp } from './ScheduleInterviewPopup'

const API_URL = process.env.REACT_APP_API_URL



type Props = {
  showpop: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  email: string
  user_id: string
  profile_pic: string
  position: string
  bexp: string
  jexp: string
  handleApprove: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserApplicationOnlineTest = ({ showpop, name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp, handleClose, handleApprove }: Props) => {
  const [popup, setpopup] = useState(<></>)
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleApprove(); // Wait for handleApprove to finish executing
    await handleClose();
  };

  console.log({ position })
  return createPortal(

    <Modal

      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={showpop}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' style={{ height: '15vh', width: '15vh', marginLeft: '19rem' }} src={addcompetenctimage}></img>
          <div className='fw-bolder mx-auto my-5' style={{ display: 'grid', gridTemplateRows: 'auto auto' }}>
            <span style={{
              font: 'normal normal bold 18px/29px Nunito',
              letterSpacing: '-0.02px',
              color: '#373D4A',
              opacity: 1,
              textAlign: 'center',
              marginTop: '1rem'
            }}>{`Are you sure that you want to add `}</span>
            <span style={{
              font: 'normal normal bold 18px/29px Nunito',
              letterSpacing: '-0.02px',
              color: '#373D4A',
              opacity: 1,
              textAlign: 'center'
            }}>{`${name} to the Interview Stage 1?`}</span>
          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row' >
            <form
            //  onSubmit={handleSubmit}
            >

              <div className='sureunsure' style={{
                marginTop: '-3rem'
              }}>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn my-10 px-10 bgcolorofbutoonofsubmit'
                >
                  Yes, I am sure!
                </button>
                {popup}
                <button onClick={handleClose} className='btn my-5 px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ApproveUserApplicationOnlineTest }