//import liraries
import React, { Component, useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyless.css'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import experienceIcon from './img/experience@2x.png'
import userIcon from './img/Group 9681.png'
import interviewIcon from './img/interview@3x.png'
import axios from 'axios';
import ShowError from '../../content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_SCORES = `${API_URL}/panel/get-candidate-interview-score`

type Props = {
    show: boolean
    stage: string
    jobapp_id: string
    avgScore: string
    preferredGrade: string
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const InterviewScoringDetails = ({ show, stage, jobapp_id, avgScore, preferredGrade, handleClose }: Props) => {

    const { auth, logout } = useAuth();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [work, setWork] = useState<any>();
    const [bankingExperience, setBankingExperience] = useState<any>('');
    const [totalExperience, setTotalExperience] = useState<any>();
    const [candidate, setCandidate] = useState<any>();
    const [panelMembers, setPanelMembers] = useState<any>();
    const [selectedPanelMember, setSelectedPanelMember] = useState();
    const [assignedScores, setAssignedScores] = useState<any>();
    const [criteriaScore, setCriteriaScore] = useState<any>();
    const [competenciesScore, setCompetenciesScore] = useState<any>();
    const [orderPreference, setOrderPreference] = useState<any>();
    const [bloodRelationInfo, setBloodRelationInfo] = useState<any>();
    const [aggregatedScore, setAggregatedScore] = useState('0');

    useEffect(() => {
        (() => {
            getData();
        })();
    }, []);

    const getData = async () => {
        const data = {
            "jobapp_id": jobapp_id,
            "jobapp_stage": stage
        };
        await axios.post(GET_ALL_SCORES, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            const data = res?.data;

            setTitle(data?.job_title);
            let work_experience = data?.work_experience;
            // if (work_experience) {
            //     work_experience = JSON.parse(work_experience);
            //     console.log('work_experienceData: ', work_experience);
            //     setWork(work_experience);
            //     setTotalExperience(calculateTotalYears(work_experience));
            // }
            let bankingExp = parseFloat(data?.banking_experience) || 0;
            let jobExperience = parseFloat(data?.job_experience) || 0;
            const totalExp = bankingExp + jobExperience;
            setBankingExperience(bankingExp);
            setTotalExperience(totalExp);
            setAggregatedScore(data?.aggregated_score);
            setCandidate(data?.candidate);
            setPanelMembers(data?.panel_users);
            let allPanelMember = data?.panel_users;

            const allScores = data?.assigned_scores;
            if (allScores) {
                setAssignedScores(allScores);
                const score = allScores[0];
                setSelectedPanelMember(score?.member_id);
                // if (allPanelMember) {
                //     const firstPanelMember = allPanelMember[0];
                //     setSelectedPanelMember(firstPanelMember?.user_id);
                // }

                const criterias = score?.criteria_score;
                console.log('criterias: ', criterias);
                setCriteriaScore(criterias);
                const competencies = score?.comp_score;
                setCompetenciesScore(competencies);
                setOrderPreference(score?.orderPreference);
                setBloodRelationInfo(score?.blood_relation);



            }


        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                // showToast(error.response.data.error.message);
                setMessage(error.response.data.error.message);
                setShowErrorMessage(true);
                // handleClose();
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const updateAssessmentData = (userId) => {

        if (assignedScores) {
            
            let matchedObject = assignedScores.find((item) => item.member_id === userId);
            console.log('matchedObject: ', matchedObject);
            if (matchedObject) {
                setSelectedPanelMember(userId);

                const criterias = matchedObject?.criteria_score;
                console.log('criterias: ', criterias);
                setCriteriaScore(criterias);
                const competencies = matchedObject?.comp_score;
                setCompetenciesScore(competencies);
                setOrderPreference(matchedObject?.orderPreference);
            } else {

                setMessage('Panel member did not assigned the scores yet.');
                setShowErrorMessage(true);
            }

        } else {
            setMessage('No Panel member assigned the scores yet.');
            setShowErrorMessage(true);
        }

    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            // width='104% !important'
            // style={{ width: '100%' }}
            // dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            dialogClassName='modal-dialog modal-dialog-centered mw-75'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >

            <div className=' no-header-model1234'>
                <div className='modal-header no-header-model123'>
                    <div className='d-flex justify-content-center align-items-center m-auto'>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <img src={interviewIcon} className='score-header-icon-internally' />
                            <h2 className='score-header-container-text'>Interview Assessment Detail</h2>
                        </div>
                    </div>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                        <img alt='' src={Layout}></img>
                    </div>
                </div>

                {/* candidate view */}
                <div className='card-bg shadow my-4 py-4 px-6 col-md-7 m-auto' style={{ alignSelf: 'center' }}>
                    <div className='d-flex'>
                        <div className='d-flex'>
                            <img src={candidate?.profile_pic ? candidate?.profile_pic : userIcon} width={64} height={64}
                                style={{ border: 'solid 1px #F36523', borderRadius: '50%', objectFit: 'cover', marginRight: '1rem' }} />
                            <div>
                                <label className='form-label fs-4 fw-bolder'>{`${candidate?.first_name} ${candidate?.last_name}`}</label>
                                <br></br>
                                <label className='form-label fs-7 fw-bolder grey-chip' >{`Position: ${title}`}</label>
                            </div>
                        </div>
                        {/* candidate experience view */}
                        <div style={{ marginLeft: 'auto' }}>
                            <img src={experienceIcon} width={28} className='d-flex m-auto' />
                            <div className='d-flex justify-content-center align-items-center'>
                                <label className='me-2 fw-regular fw-5 fs-5' >{'Banking Experience: '}</label>
                                <label className='me-2 fw-bolder fw-5 fs-5' >{`${bankingExperience} yrs`}</label>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <label className='me-2 fw-regular fw-5 fs-5' >{'Total Experience: '}</label>
                                <label className='me-2 fw-bolder fw-5 fs-5' >{`${totalExperience} yrs`}</label>
                            </div>
                        </div>

                    </div>
                    <div className='d-flex align-items-center mt-4'>
                        <label className='fs-7 fw-bolder me-2'>{'Agg. Score'}</label>
                        <label className='fs-7 fw-bolder me-6 green-chip'>{aggregatedScore}</label>
                        <label className='fs-7 fw-bolder me-2'>{'Panel Preferred Grade'}</label>
                        <label className='fs-7 fw-bolder me-6 green-chip'>{preferredGrade ? preferredGrade : 0}</label>
                    </div>
                </div>
                {/* panelist view */}

                <div className='d-flex flex-column justify-content-center align-items-center mt-4 mb-8'>

                    <label className='fs-3 fw-bolder form-label my-8'>{'Panelist'}</label>

                    {panelMembers ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {panelMembers.map((member, index) => (
                                <React.Fragment key={index}>
                                    <div className=' d-flex flex-column align-items-center'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => { updateAssessmentData(member?.user_id) }}>
                                        <img
                                            className={`${member?.user_id === selectedPanelMember ? 'selected-user' : 'blur-profile-view mt-1'}`}
                                            src={member?.profile_pic ? member?.profile_pic : userIcon}
                                            width={54}
                                            height={54}
                                            style={{
                                                border: 'solid 1px #F36523',
                                                borderRadius: '50%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <label className='fs-5 fw-bolder mt-2'
                                            title={`${member?.first_name} ${member?.last_name} - ${member?.employee_id}`}
                                        >{`${member?.first_name}`}</label>
                                        <label className='fs-6 fw-bolder'
                                            title={`${member?.first_name} ${member?.last_name} ${member?.employee_id}`}
                                        >{`${member?.employee_id}`}</label>
                                    </div>
                                    {index < panelMembers.length - 1 && (
                                        <hr style={{ height: '2px', width: '100px', backgroundColor: 'grey', margin: '0 5px' }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <div></div>
                    )}


                </div>

                {/* scoring views */}
                <div className='col-md-12 d-flex mb-12'>
                    {/* characteristics scoring view */}
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'>
                            <img src={experienceIcon} width={24} />
                            <label className='form-label fs-4 fw-bolder ms-2 mt-2'>{'Characteristic Assessment'}</label>
                        </div>
                        <label className='grey-chip fs-7 my-6'>{'Ranking criteria 1 = Not Satisfactory, 2 = Just Acceptable, 3 = Acceptable, 4 = Very Good, 5 = Excellent'}</label>

                        <table className='col-md-12 mb-10'
                            style={{
                                borderCollapse: 'collapse',
                                // width: '300px',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                border: '1px solid #ddd',
                                // margin: '20px',
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            backgroundColor: '#f2f2f2',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Characteristics
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            backgroundColor: '#f2f2f2',
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Score
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {criteriaScore && Object.keys(criteriaScore).map((key) => {
                                    const characteristic = criteriaScore[key];
                                    console.log('characteristicInfo: ', characteristic);
                                    return (
                                        <tr key={key}>
                                            <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                <label id='heading' className='fs-6 fw-bolder form-label'>{characteristic?.name}</label>
                                                {/* <label id='description' className='fs-6 fw-regular form-label'>{characteristic?.name}</label> */}
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{characteristic?.score}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>

                        {/* Order of Preference view */}
                        <div className='d-flex align-items-center'>
                            <img src={experienceIcon} width={24} />
                            <label className='form-label fs-4 fw-bolder ms-2 mt-2'>{'Order of Preference'}</label>
                        </div>
                        <label className='grey-chip fs-7 my-6'>{'Rank the candidate according to this criteria 1 = Top Ranked, 2 = 2nd Ranked, 3 = 3rd Ranked, 4 = 4th Ranked, 5 = Not Recommended'}</label>

                        <label
                            style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                            className='form-control bg-white w-140px'
                        >{`Selected Grade: ${orderPreference ? orderPreference : ''}`}</label>

                    </div>
                    {/* campetencies scoring view */}
                    <div className='col-md-6'>
                        <div className='d-flex align-items-center'>
                            <img src={experienceIcon} width={24} />
                            <label className='form-label fs-4 fw-bolder ms-2 mt-2'>{'Competency Assessment'}</label>
                        </div>
                        <label className='grey-chip fs-7 my-6'>{'Ranking criteria 1 = Basic, 2 = Intermediate, 3 = Advance, 4 = Expert, 5 = Excellent'}</label>
                        <table className='col-md-12 mb-10'
                            style={{
                                borderCollapse: 'collapse',
                                // width: '300px',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                border: '1px solid #ddd',
                                // margin: '20px',
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            backgroundColor: '#f2f2f2',
                                            padding: '8px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Competencies
                                    </th>
                                    <th
                                        style={{
                                            borderBottom: '1px solid #ddd',
                                            backgroundColor: '#f2f2f2',
                                            padding: '8px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Score
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {competenciesScore && Object.keys(competenciesScore).map((key) => {
                                    const characteristic = competenciesScore[key];
                                    console.log('characteristicInfo: ', characteristic);
                                    return (
                                        <tr key={key}>
                                            <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                <label id='heading' className='fs-6 fw-bolder form-label'>{characteristic?.name}</label>
                                                {/* <label id='description' className='fs-6 fw-regular form-label'>{characteristic?.name}</label> */}
                                            </td>
                                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{characteristic?.score}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        {/* Show Blood Relation info */}
                        <div>
                            <div className='d-flex align-items-center'>
                                <img src={experienceIcon} width={24} />
                                <label className='form-label fs-4 fw-bolder ms-2 mt-2'>{"Declaration 'Blood Relative'"}</label>

                            </div>

                            {bloodRelationInfo?.relativeEmployee === 'yes' ? (
                                <div>
                                    <table className='col-md-12 mb-10 mt-6'
                                        style={{
                                            borderCollapse: 'collapse',
                                            // width: '300px',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            border: '1px solid #ddd',
                                            // margin: '20px',
                                        }}>

                                        <tbody>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"Relation"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.relation}</td>
                                            </tr>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"Name"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.name}</td>
                                            </tr>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"CNIC"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.cnic}</td>
                                            </tr>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"Designation"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.designation}</td>
                                            </tr>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"Department"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.department}</td>
                                            </tr>
                                            <tr >
                                                <td className='d-flex flex-column' style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '8px', overflow: 'hidden' }}>
                                                    <label id='heading' className='fs-6 fw-bolder form-label'>{"Location"}</label>
                                                </td>
                                                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', minWidth: '140px', textAlign: 'center' }}>{bloodRelationInfo?.location}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            ) : (
                                <div>
                                    <label className='form-label fs-7 fw-bolder mt-4' style={{ color: '#F36523' }}>No any Blood Relative Employee at BOP</label>
                                </div>
                            )}

                        </div>

                    </div>

                </div>

                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
            </div>

        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default InterviewScoringDetails;
