import axios from 'axios';
import React, {useState, useEffect} from 'react'
import { useAuth } from '../../auth/core/Auth'
import VacanciesCandidateMain from './VacanciesCandidateMain'
import ApplyNowTab from './ApplyNowTab'
import ReviewVacancies from './ReviewVacancies';

const API_URL = process.env.REACT_APP_API_URL
export const ALLDATA_URL = `${API_URL}/candidate/`

const VacanBoiler = () => {
    
    const { currentUser } = useAuth()
    const user_id = currentUser?.user_id
    const [jobData, setJobData] = useState();
    const [currComp, updateCurrComp] = useState(1);
    const [jobID, setJobID] = useState('');
    const [candidateID, setCandidateID] = useState('');
    const [recuiterID, setRecuiterID] = useState('');
    const [jobPostion, setJobPosition] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [candidate, setCandidate] = useState<any>('');
    const [user, setUser] = useState<any>('');
    const [profile, setProfile] = useState<any>();


  return (
    <div>
        {
            currComp === 1 ? <VacanciesCandidateMain update={updateCurrComp} openApplyPage={updateCurrComp} job_id={setJobID} candidate_id={setCandidateID} recuiter_id={setRecuiterID} job_title={setJobPosition} created_at={setCreatedAt} setJobData={setJobData}/> :
            currComp === 2 ? <ApplyNowTab  user_profile={setProfile} update={updateCurrComp} openApplyPage={updateCurrComp} job_id={jobID} candidate_id={candidateID} recuiter_id={recuiterID} job_title={jobPostion} created_at={createdAt} setCandidateData={setCandidate} setUserData={setUser}/> :
            currComp === 3 ? <ReviewVacancies openApplyPage={updateCurrComp} candidate={candidate} user={user} user_profile={profile} job_id={jobID} candidate_id={candidateID} recuiter_id={recuiterID} job_title={jobPostion} created_at={createdAt} jobInfo={jobData}/> :
            null
        }
    </div>
  )
}

export default VacanBoiler