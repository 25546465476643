import { useRef, useState } from 'react';
// import { CreateAppModal } from '../../../../_metronic/partials';
import '../Competencies.css'
import { CreateAppModal } from '../../../../_metronic/partials';
import GroupCompetenciesDesign from '../../../../_metronic/partials/content/cards/GroupCompetenciesDesign';

type RefType = {
  GetAllIndividualCompetencies: () => Promise<void>
}

// create a component
const GroupCompetencies = () => {

  const [totalGroupComps, setTotalGroupComps] = useState(0);
  // defining reference of function of child component
  const groupResourcesRef = useRef<RefType>(null);
  // function to call child function
  const handleGetAllCompetencies = () => {
    groupResourcesRef.current?.GetAllIndividualCompetencies();
  };

  const [showCreateCompetency, setShowCreateCompetency] = useState(false);
  const openCreateCompetencyPopup = () => {
    setShowCreateCompetency(true);
  }

  return (
    <>
      <div className='row margin-top-for-individual-jobs g-5 g-xl-8'>
        
        <div className='d-flex flex-wrap flex-stack mb-6 align-items-center'>
          <h4 className='fw-bolder'>Group Competencies ({totalGroupComps})</h4>
          <div className='candi-btn2 text-white fw-bolder fs-5 ms-auto' onClick={() => openCreateCompetencyPopup()}>Create Group Competency</div>
        </div>
      </div>

      <div >
        <GroupCompetenciesDesign
          ref={groupResourcesRef}
          updateTotalGroupComp={(value) => setTotalGroupComps(value)} />
      </div>

      {showCreateCompetency && (
        <CreateAppModal
          show={true}
          handleClose={() => {
            handleGetAllCompetencies(); // fetching list of competencies again to update the views if there is any change
            setShowCreateCompetency(false);
          }}
        />
      )}
    </>
  );
};

//make this component available to the app
export default GroupCompetencies;
