import { useEffect, useState } from 'react'
import './flowchart.css'
import axios from 'axios'
import level1_image from '../../images/level1.png'
import level2_image from '../../images/level2.png'
import level3_image from '../../images/level3-sm.png'
import level4_image from '../../images/level4-sm.png'
import level5image from '../../images/level5-sm.png'
import { useAuth } from '../../../../../app/modules/auth/core/Auth'
const API_URL = process.env.REACT_APP_API_URL
export const GET_CREATE_LEVEL = `${API_URL}/level/get`

type Props = {
  placeHolderTitles: any
  placeHolderDescriptions: any
  levels: any
}

const Level5flowchart = ({placeHolderTitles, placeHolderDescriptions, levels }: Props) => {
  const { auth } = useAuth()
  console.log('levelsInfoFlowChart: ', levels);
  const images = [level1_image, level2_image, level3_image, level4_image, level5image];

  return (
    <>
      <div className='margin-topfortecompetency'>
        <div className='row  mx-auto pt-18 mb-20 justify-content-around d-flex'>
          <div>
            <label className=' mx-auto heading-competency  '>
              BOP Competency Framework
            </label>
            
          </div>
        </div>

        <div className='row boxes-flex-addition'>

          {levels.length > 0 && (
            levels.map((item, index) => (
              <div className='col-md-3 mx-4 competecnyframe-workdiv'>
                <img src={images[index]}></img>
                <div className='textforthelevcels'>
                  {item?.title ? item?.title : `${placeHolderTitles[index]}`}
                </div>
              </div>
            ))

          )}

        </div>

        {/* description views */}
        <div className='row boxes-flex-addition'>
          {levels.length > 0 && (
            levels.map((item, index) => (
              <div className='col-md-3 mx-4 competecnyframe-workdiv-gray' style={{overflow:'auto'}}>
                {item?.description ? item?.description : `Description`}
              </div>
            ))

          )}

        </div>
      </div>
    </>
  )
}

export default Level5flowchart
