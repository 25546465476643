import { useEffect, useState } from 'react'
import './insights.css'
import timeimage from '../../../_metronic/partials/widgets/images/Group 9724 (2).png'
import cardSettingImage from '../../../_metronic/partials/widgets/images/Mask Group 3.png'
import { AverageFirstYearAttrition } from '../../../_metronic/partials/widgets/tables/AverageFirstYearAttrition'
import calendar from '../../../_metronic/partials/widgets/images/Group 9630.png'
import userinmage from '../../../_metronic/partials/widgets/images/Mask Group 1.png'
import grayCalendar from '../../../_metronic/partials/widgets/images/Grey@2x.png'
import grayPictures from '../../../_metronic/partials/widgets/images/Grey@2x (1).png'
import grayletter from '../../../_metronic/partials/widgets/images/Grey@2x (2).png'

import graytime from '../../../_metronic/partials/widgets/images/Group grey.png'
// import atTatIcon from '../images/at_tat.svg'
import atTatIcon from '../../../_metronic/partials/widgets/images/at_tat.svg';
import afterTatIcon from '../../../_metronic/partials/widgets/images/after_tat.svg';

// import {SetJobsContext} from '../../App'
import { TablesWidget12 } from '../../../_metronic/partials/widgets'
import { useLabelInsightsContext } from '../../../context/useLabelInsightsContext'
import axios from 'axios'
import { useAuth } from '../auth'
import AverageOfferAcceptance from '../../../_metronic/partials/widgets/tables/AverageOfferAcceptance'
import AverageHireTime from '../../../_metronic/partials/widgets/tables/AverageHireTime'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import ActionsAtTAT from '../../../_metronic/partials/widgets/tables/ActionsAtTAT'
import ActionsAfterTAT from '../../../_metronic/partials/widgets/tables/ActionsAfterTAT'

const API_URL = process.env.REACT_APP_API_URL
const GET_INSIGHTS_URL = `${API_URL}/job/dashboard-insights`

const JobsTabs = ({ onDateChange }) => {
  const { currentUser, auth, logout } = useAuth();
  const [Active, setActive] = useState('AverageFirstYearAttention');
  const { InsightData, setInsightData } = useLabelInsightsContext()

  const [applicationsPerOpening, setApplicationsPerOpening] = useState('0');
  const [offerAcceptance, setOfferAcceptance] = useState('0');
  const [timeToHire, setTimeToHire] = useState('0');
  const [firstYearAttrition, setFirstYearAttrition] = useState('0');
  const [closedWithin30Days, setClosedWithin30Days] = useState('0');
  const [closedAfter30Days, setClosedAfter30Days] = useState('0');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (InsightData === 'AveragefirstYearAttention') {
      setActive('AverageFirstYearAttention')
    } else if (InsightData === 'AverageApplicationsOpening') {
      setActive('AverageApplicationsOpening')
    } else if (InsightData === 'AverageOfferAcceptance') {
      setActive('AverageOfferAcceptance')
    } else if (InsightData === 'AverageTime') {
      setActive('AverageTime')
    }

    return () => { }
  }, [])

  const fetchInsightsData = async () => {
    await axios.get(GET_INSIGHTS_URL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      }
    }).then((res) => {

      let insightsData = res.data;
      setFirstYearAttrition(insightsData?.firstYearAttrition);
      setApplicationsPerOpening(insightsData?.averageApplicationsPerOpening);
      setOfferAcceptance(insightsData?.averageOfTotalAcceptedJob);
      setTimeToHire(insightsData?.averageTimeToHire);
      setClosedAfter30Days(insightsData?.closedAfter30Days);
      setClosedWithin30Days(insightsData?.closedWithin30Days)

      const currentDate = new Date();
      const month = currentDate.toLocaleString('default', { month: 'short' });
      const day = currentDate.getDate();
      const hour = currentDate.getHours();
      const minute = currentDate.getMinutes();
      const formattedDateTime = `${month}, ${day} - ${hour}:${minute.toString().padStart(2, '0')}`;
      // Pass the updated date value to the parent component
      onDateChange(formattedDateTime);
    }).catch((error) => {
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    })
  }

  useEffect(() => {
    (async () => {
      await fetchInsightsData();
    })();
  }, [])

  const ChangeInsightsIndex = (ActiveState: string) => {
    if (ActiveState === 'AverageFirstYearAttention') {
      //   setInactive(false)
      setActive('AverageFirstYearAttention')
    } else if (ActiveState === 'AverageApplicationsOpening') {
      setActive('AverageApplicationsOpening')
    } else if (ActiveState === 'AverageOfferAcceptance') {
      setActive('AverageOfferAcceptance')
    } else if (ActiveState === 'AverageTime') {
      setActive('AverageTime')
    }
    setActive(ActiveState);
  }

  return (
    <>
      <div className='row g-5 g-xl-8 d-flex justify-content-center'>
        <div className='col-xl-3 col-md-3 '>
          <a
            onClick={() => {
              ChangeInsightsIndex('AverageFirstYearAttention')
            }}
            className={`card shadow fw-bold ${Active === 'AverageFirstYearAttention'
              ? 'background-color123 text-white '
              : 'text-muted  backround-color-of-insights-cards'
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>

              <div className=' fs-1  px-5'>{`${firstYearAttrition}%`}</div>
              <div className='  fs-6 , px-5'>Average first Year Attrition</div>

              <div className='d-flex flex-end mt-2 '>
                {Active === 'AverageFirstYearAttention' ? (
                  <img className='gray-calendar-width' src={calendar}></img>
                ) : (
                  <img className='gray-calendar-width' src={grayCalendar}></img>
                )}
              </div>
            </div>
          </a>
        </div>
        <div className='col-xl-3 col-md-3 '>
          <a
            onClick={() => {
              ChangeInsightsIndex('AverageApplicationsOpening')
            }}
            className={`card shadow fw-bold ${Active === 'AverageApplicationsOpening'
              ? 'background-color-second-widget text-white '
              : 'text-muted '
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>
              {/* <KTSVG path={svgIcon || ''} className={clsx(iconClass, 'svg-icon-2hx ms-n1 flex-grow-1')} /> */}
              <div className='fs-1 px-5'>{applicationsPerOpening}</div>
              <div className=' fs-6 , px-5'>
                Avg. Applicants per<br></br> openings
              </div>

              <div className='d-flex flex-end  '>
                {/* <div className={clsx(descriptionClass, 'fw-semibold fs-6')}>{description}</div> */}

                {Active === 'AverageApplicationsOpening' ? (
                  <img className='gray-calendar-width-images' src={userinmage}></img>
                ) : (
                  <img className='gray-calendar-width-images' src={grayPictures}></img>
                )}
              </div>
            </div>
          </a>
        </div>
        <div className='col-xl-3 col-md-3 '>
          <a
            onClick={() => {
              ChangeInsightsIndex('AverageOfferAcceptance')
            }}
            className={`card shadow fw-bold ${Active === 'AverageOfferAcceptance' ? ' background-color text-white ' : 'text-muted '
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>
              {/* <KTSVG path={svgIcon || ''} className={clsx(iconClass, 'svg-icon-2hx ms-n1 flex-grow-1')} /> */}
              <div className='fs-1 px-5'>{offerAcceptance}%</div>
              <div className=' fs-6 , px-5'>AVG. Offer Acceptance</div>

              <div className='d-flex flex-end '>
                {/* <div className={clsx(descriptionClass, 'fw-semibold fs-6')}>{description}</div> */}

                {Active === 'AverageOfferAcceptance' ? (
                  <img className='widthsetting-image' src={cardSettingImage}></img>
                ) : (
                  <img className='widthsetting-image' src={grayletter}></img>
                )}
              </div>
            </div>
          </a>
        </div>
        <div className='col-xl-3 col-md-3  '>
          <a
            onClick={() => {
              ChangeInsightsIndex('AverageTime')
            }}
            className={`card shadow fw-bold ${Active === 'AverageTime' ? ' bg-color-tile text-white ' : 'text-muted '
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>
              {/* <KTSVG path={svgIcon || ''} className={clsx(iconClass, 'svg-icon-2hx ms-n1 flex-grow-1')} /> */}
              <div className=' fs-1 px-5'>{timeToHire}</div>
              <div className=' fs-6 , px-5'>Avg. Time to Hire</div>

              <div className='d-flex flex-end mt-2 pt-5'>
                {/* <div className={clsx(descriptionClass, 'fw-semibold fs-6')}>{description}</div> */}
                {Active === 'AverageTime' ? (
                  <img className='widthsetting-image-time' src={timeimage}></img>
                ) : (
                  <img className='widthsetting-image-time-gray' src={graytime}></img>
                )}
              </div>
            </div>
          </a>
        </div>
        <div className='col-xl-3 col-md-3 '>
          <a
            onClick={() => {
              ChangeInsightsIndex('actionsTakenAtTime')
            }}
            className={`card shadow fw-bold ${Active === 'actionsTakenAtTime' ? ' background-color-eight-widget text-white ' : 'text-muted '
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>
              {/* <KTSVG path={svgIcon || ''} className={clsx(iconClass, 'svg-icon-2hx ms-n1 flex-grow-1')} /> */}
              <div className='fs-1 px-5'>{closedWithin30Days}</div>
              <div className=' fs-6 , px-5'>No. of Cases <br></br>completed with TAT</div>

              <div className='d-flex flex-end '>
                {/* <div className={clsx(descriptionClass, 'fw-semibold fs-6')}>{description}</div> */}

                {Active === 'actionsTakenAtTime' ? (
                  <img className='width-setting-image-countdown ' height={74} src={atTatIcon}></img>
                ) : (
                  <img className='width-setting-image-countdown ' style={{ filter: 'grayscale(100%)' }} height={74} src={atTatIcon}></img>
                )}
              </div>
            </div>
          </a>
        </div>
        <div className='col-xl-3 col-md-3  '>
          <a
            onClick={() => {
              ChangeInsightsIndex('afterTAT')
            }}
            className={`card shadow fw-bold ${Active === 'afterTAT' ? ' background-color-nine-widget text-white ' : 'text-muted '
              }`}
          >
            <div className='card-body mb-0 pb-0 px-1 d-flex flex-column justify-content-between'>

              <div className=' fs-1 px-5'>{closedAfter30Days}</div>
              <div className=' fs-6 , px-5'>No. of Cases <br></br> completed after TAT</div>
              <div className='d-flex flex-end '>
                {Active === 'afterTAT' ? (
                  <img className='width-setting-image-countdown' height={74} src={afterTatIcon}></img>
                ) : (
                  <img className='width-setting-image-countdown' style={{ filter: 'grayscale(100%)' }} height={74} src={afterTatIcon}></img>
                )}
              </div>
            </div>
          </a>
        </div>
      </div>


      <div className='row mx-1  margin-top-for-tables gy-5 gx-xl-8'>
        <div className='mx-auto col-xl-10'>
          {Active === 'AverageFirstYearAttention' ? (
            <AverageFirstYearAttrition
              title='Average First Year Attrition'
              className='card-xxl-stretch mb-5 mb-xl-8'
            />
          ) : Active === 'AverageApplicationsOpening' ? (
            <TablesWidget12 className='card-xxl-stretch mb-5 mb-xl-8' />
          ) : Active === 'AverageOfferAcceptance' ? (
            <AverageOfferAcceptance
              title='Offer Acceptance Rate'
              className='card-xxl-stretch mb-5 mb-xl-8'
            />

          ) : Active === 'AverageTime' ? (
            <AverageHireTime
              title='Average Time to Hire'
              className='card-xxl-stretch mb-5 mb-xl-8'
            />
          ) : Active === 'actionsTakenAtTime' ? (
            <ActionsAtTAT
              title='Average Actions In TAT'
              className='card-xxl-stretch mb-5 mb-xl-8'
            />
          ) : Active === 'afterTAT' ? (
            <ActionsAfterTAT
              title='Average Actions After TAT'
              className='card-xxl-stretch mb-5 mb-xl-8'
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {showErrorMessage && (
        <ShowError
          handleClose={() => setShowErrorMessage(false)}
          message={message}
        />
      )}
    </>
  )
}

export default JobsTabs
