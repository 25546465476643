import React, { useState } from 'react';
import './PopUp.css'; // Import CSS file for styling
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import icSuccessGreen from '../../../_metronic/partials/modals/JobFunnelsModels/img/ic_success_green.png.png';
import imagePath from '../../../constants/imagePath';
import icUpload from '../../../_metronic/partials/modals/JobFunnelsModels/img/ic_upload.png';
import icInfo from '../../../_metronic/partials/modals/images/information (3)@2x.png';
import { useAuth } from '../auth/core/Auth';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL
export const UPLOAD_CSV_FILE_URL = `${API_URL}/questions/upload-csv`;

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
    onClose: () => void
}

const ImportPositionForm = ({ onClose }: Props) => {
    const { auth, logout } = useAuth();
    const [file, setFile] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    // Function to handle file upload
    const handleFileChange = (event) => {
        event.preventDefault();
        const csvFile = event.target.files[0];
        if (csvFile && csvFile.name.endsWith('.csv')) {
            // setFile(csvFile);
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1]; // Use type assertion here
                setFile(base64String);
            };
            reader.onerror = () => {
                console.error('Error reading file:', reader.error);
            };
            reader.readAsDataURL(csvFile);
        } else {
            setMessage('Please select a valid CSV file.');
            setShowErrorMessage(true);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // const formData = new FormData();
        // formData.append('file', file);

        let data = {
            file: file
        }
        setLoading(true);
        axios.post(UPLOAD_CSV_FILE_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                // 'content-type': 'multipart/form-data',
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setLoading(false);

            // update the list of questions
            // handleUpdateTestsList();
            onClose();

        }).catch((err) => {
            setLoading(false);
            displayErrorMessage(err);
        });

    }

    const displayErrorMessage = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response?.data?.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            // onHide={handleClose}
            backdrop={true}

        >
            <div className="container" style={{ padding: '0', margin: '0', backgroundColor: 'white', borderRadius: '10px' }}   >
                <div>
                    <div  >
                        {/* <i onClick={onClose} className="fas fa-xmark"></i> */}
                        <img onClick={onClose}
                            src={imagePath.ic_close}
                            width={15}
                            height={15}
                            alt='Close'
                            title='Close'
                            style={{ cursor: 'pointer', position: 'absolute', marginLeft: '566px', marginTop: '18px', }}
                        />
                    </div><br />
                    <div className=' d-flex flex-column ' style={{ marginTop: '30px' }} >
                        <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <h3 className='fw-bolder mx-auto mb-5' style={{ color: file ? '#0BB783' : '#F36523' }}>{file ? 'File is ready to upload' : 'Import Questions from CSV'}</h3>
                                <img alt='' className='infoIcImg' src={file ? icSuccessGreen : imagePath.ic_upload_orange} />
                            </div>
                        </label>
                        <input type="file" id="csv-upload" accept=".csv" style={{ display: 'none' }} onChange={(event) => handleFileChange(event)} />
                    </div>
                </div>
                <div className="info mt-10 d-flex align-items-center">
                    <img
                        src={icInfo}
                        width={15}
                        height={15}
                        alt='Info'
                        title='Info'
                        style={{ marginRight: '5px' }}

                    />
                    <span className="info-text">The CSV file must contain the following data points:</span>
                </div>
                <div className="list-container">
                    <ul>
                        <li>question</li>
                        <li>type</li>
                        <li>link</li>
                        <li>answer1</li>
                        <li>answer2</li>
                        <li>answer3</li>
                        <li>answer4</li>
                        <li>answer5</li>
                        <li>correct_answer</li>
                        <li>competency</li>

                    </ul>
                </div>
                <div className="upload-button mb-10">
                    <button className="custom-file-upload"
                        onClick={handleSubmit}
                        disabled={!file}>
                        Upload
                    </button>
                </div>

                {loading && (
                    <LoaderView
                        message='Loading...'
                    />
                )}

                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

            </div>
        </Modal>,
        modalsRoot
    );
}

export { ImportPositionForm }