import React, {ForwardRefRenderFunction, forwardRef, useImperativeHandle, useRef, RefObject} from 'react'
import {useState, useEffect} from 'react'
import {RejectUserbyApplication} from '../../../_metronic/partials/modals/JobFunnelsModels/RejectUserbyApplication'
import {ApproveUserApplication} from '../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserApplication'
import {ApproveAllUserApplication} from '../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplication'
import {RejectAllUserApplication} from '../../../_metronic/partials/modals/JobFunnelsModels/RejectAllUserApplication'
import unchecked from './images/Group 10062.png'
import checked from '../auth/images/tick.png'
import './JobsFunelling.css'
import threeedota from './images/menu.png'
import axios from 'axios'
import {useAuth} from '../auth/core/Auth'
import {propTypes} from 'react-bootstrap/esm/Image'
import {object} from 'prop-types'
import AvatarIcon from './img/Group 9681.png'
import icArrowRight from '../Setting/images/ic_right_arrow_grey.png'
import {ApproveScheduleNoTest} from '../../../_metronic/partials/modals/JobFunnelsModels/ApproveScheduleNoTest'
import {ApproveUserAppWithoutTest} from '../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserAppWithoutTest'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import Select, {components} from 'react-select'
import ViewProfileApplication from '../../../_metronic/partials/modals/JobFunnelsModels/ViewProfileApplication'
import {Link} from 'react-router-dom'
import {useNavigate} from 'react-router'
import WarningTARemovePopup from '../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import ScheduleInterViewMultipleCandidatesStage1 from '../../../_metronic/partials/modals/JobFunnelsModels/ScheduleInterViewMultipleCandidatesStage1'
import {getTotalBankingExperience, getTotalYears} from '../../../constants/CommonFunctions'
import imagePath from '../../../constants/imagePath'
import clsx from 'clsx'
import CustomPagination from '../dashboards/components/CustomPagination'
import RejectedCandidates from './RejectedCandidates'
interface Resource {
  candidate_details: Object
  resource: Object
}

interface CandidateDetails {
  candidate: {
    banking_experience: string
    // other properties
  }
  // other properties
}

interface Resources {
  candidate_details: CandidateDetails
  // other properties
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const GET_TA_DATA = `${API_URL}/panel/get-tas-data`

export const CREATE_PANEL_HIRED = `${API_URL}/panel/create`

export const FILTER_BY_BANKING = `${API_URL}/jobapplication/get-filter-bankExp-data`

export const FILTER_BY_TOTAL = `${API_URL}/jobapplication/get-filter-totalExp-data`

export const FILTER_BY_UNIVERSITY = `${API_URL}/jobapplication/get-filter-uni-data`
const TA_APPROVAL_EMAIL_URL = `${API_URL}/jobapplication/ta-candidate-approve`

type Props = {
  job_id: any
  testExists: any
  trigger: any
}

const ApplicationsTable: React.FC<Props> = ({job_id, testExists, trigger}) => {
  const navigate = useNavigate()
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const [popup, setpopup] = useState(<></>)
  const [approveAllPopup, setApproveAllPopup] = useState(<></>)
  const [rejectAllPopup, setRejectAllPopup] = useState(<></>)
  const [selectedPopup, setSelectedPopup] = useState(false)
  const [Master, setMaster] = useState(false)
  const [checkedstate, setcheckedstate] = useState(false)
  const [popupTest, setpopupTest] = useState(<></>)
  const [popupProfile, setpopupProfile] = useState(<></>)
  const [filters, setFilters]: any = useState([{}])
  const [filterMenu, setFilterMenu] = useState(false)
  const [multioptiondots, setmultioptiondots] = useState(false)
  const [result, setResult] = useState<any>([])
  let getallCandidates = [{}]
  const Multioption = ['Aprove All', 'Reject All']
  const {auth, logout} = useAuth()
  const [jobTitle, setJobTitle] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [clicked, setClicked] = useState<any>(false)
  const approveButtonRefs = useRef<(HTMLButtonElement | null)[]>([])
  const [clickedButtonId, setClickedButtonId] = useState<any>(null)
  const [showWarning, setShowWarning] = useState(false)
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [selectedApplicants, setSelectedApplicants] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [scheduleMultipleCandidateInterview, setScheduleMultipleCandidateInterview] = useState(false)
  const dropdownRefAction = useRef<HTMLDivElement>(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState<any>([])
  const dropdownRefFilters = useRef<HTMLDivElement>(null)
  const [showRejectedApplicants, setShowRejectedApplicants] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    age: '',
    bankingExp: '',
    totalExp: '',
    universities: '',
  })

  let name: any
  let email: any
  let jobapp_id: any
  let user_id: any
  let recruiter: any

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRefFilters.current && !dropdownRefFilters.current.contains(event.target)) {
        setFilterMenu(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRefAction.current && !dropdownRefAction.current.contains(event.target)) {
        setmultioptiondots(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const calculateAge = (dob) => {
    const dobDate = new Date(dob)
    const currentDate = new Date()

    let age = currentDate.getFullYear() - dobDate.getFullYear()

    // Check if the birthday has occurred this year or not
    const hasBirthdayOccurred =
      currentDate.getMonth() > dobDate.getMonth() ||
      (currentDate.getMonth() === dobDate.getMonth() && currentDate.getDate() >= dobDate.getDate())

    if (!hasBirthdayOccurred) {
      age -= 1
    }

    return age
  }

  const createPopupNoTest = (name, jobapp_id, email, user_id, recuiter_id) => {
    setpopupTest(
      <ApproveUserAppWithoutTest
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        recuiter_id={recuiter_id}
        handleClose={() => {
          setpopupTest(<></>)
          GetAllJobsApplicationsbypage()
        }}
        handleSubmit={() => {
          approveScheduleNoTest(name, jobapp_id, email, user_id)
        }}
      />
    )
    console.log('popup', jobapp_id)
  }

  const approveScheduleNoTest = (name, jobapp_id, email, user_id) => {
    console.log('approveScheduleNoTest: function called')
    setpopupTest(
      <ApproveScheduleNoTest
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        email={email}
        user_id={user_id}
        handleClose={async () => {
          setpopupTest(<></>)
          await GetAllJobsApplicationsbypage()
        }}
      />
    )
    console.log('popup', jobapp_id)
  }

  const createProfilePopup = (
    name,
    jobapp_id,
    email,
    user_id,
    profile_pic,
    cnic,
    phone_number,
    education,
    skills,
    work_Experience,
    total_exp,
    banking_exp,
    nonBanking_exp,
    educations,
    certifications,
    personalData,
    profile_match,
    position,
    recuiter_id,
    videoResumeUrl,
    testExists,
    index
  ) => {
    // setOpenProfilePopup(true)
    setpopupProfile(
      <ViewProfileApplication
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        profile_pic={profile_pic}
        cnic={cnic}
        phone_number={phone_number}
        education={education}
        skills={skills}
        work_Experience={work_Experience}
        total_exp={total_exp}
        banking_exp={banking_exp}
        nonBanking_exp={nonBanking_exp}
        educations={educations}
        certifications={certifications}
        personalData={personalData}
        profile_match={profile_match}
        position={position}
        recuiter_id={recuiter_id}
        videoResumeUrl={videoResumeUrl}
        testExists={testExists}
        applicationStage={'stage'}
        index={index}
        handleClose={() => {
          setpopupProfile(<></>)
          GetAllJobsApplicationsbypage()
        }}
        recaller={() => recaller(index)}
      />
    )
  }

  const recaller = (index) => {
    setClicked(index)
  }

  useEffect(() => {
    if (clicked !== null) {
      if (approveButtonRefs.current[clicked]) {
        approveButtonRefs.current[clicked]?.click() // Use optional chaining to safely trigger the click event
      }
      setClicked(null) // Reset the clicked index
    }
  }, [clicked])

  const createApprovalPopup = (name, jobapp_id, candidate_id, recuiter_id, email) => {
    setpopupApproval(
      <ApproveUserApplication
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        candidate_id={candidate_id}
        recuiter_id={recuiter_id}
        email={email}
        handleClose={() => {
          setpopupApproval(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const createApproveAllPopup = (job_id) => {
    setApproveAllPopup(
      <ApproveAllUserApplication
        show={true}
        job_id={job_id}
        handleClose={() => {
          setApproveAllPopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const scheduleInterViewMultipleCandidatesStage1 = () => {
    if (selectedApplicants && selectedApplicants.length > 0) {
      setScheduleMultipleCandidateInterview(true)
    } else {
      setMessage('Please select one or more applications first and try again')
      setShowErrorMessage(true)
    }
  }

  const createRejectAllPopup = (jobapp_id) => {
    setRejectAllPopup(
      <RejectAllUserApplication
        show={true}
        jobapp_id={jobapp_id}
        selectedApplicants={selectedApplicants}
        handleClose={() => {
          setSelectedApplicants([])
          setRejectAllPopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const GetAllCurrentCandidatatesbyJob = async () => {
    await axios.get(GET_ALL_CANDIDATES).then((resp) => {
      getallCandidates = resp.data.data
    })
  }

  const GetAllJobsApplicationsbypage = async () => {
    try {
      setIsLoading(true) // Set isLoading to true when data fetching starts

      const response = await axios.post(
        GET_ALL_JOB_APPLICATIONS,
        {
          job_id: job_id,
          getStage: 'application',
          limit: params.limit,
          page: params.page,
          order: params.order,
          sort: params.sort,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      setIsLoading(false)
      setJobTitle(response?.data?.title)

      const jobApplications = response?.data?.jobs
      let pagination = response?.data?.pagination
      if (pagination) {
        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = {...params}
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }
        setTotalPages(pagination?.totalPages || 0)
      }
      setResult(jobApplications)

      const bankingExperiences = jobApplications.map((item) => {
        const experience = item.candidate.banking_experience
        // return isNaN(experience) ? '0' : experience
        let parsedExperience = parseFloat(experience)
        if (isNaN(parsedExperience)) {
          parsedExperience = 0
        }
        // Fix the experience to 1 decimal place without rounding
        parsedExperience = parseFloat(parsedExperience.toFixed(1))
        return parsedExperience
      })

      const uniqueExperiences = Array.from(new Set(bankingExperiences)) // Remove duplicates
      const filteredExperiences = uniqueExperiences.filter((experience) => experience !== '0.00') // Filter out '0.00'
      const sortedExperiences = filteredExperiences.sort((a, b) => Number(a) - Number(b)) // Sort in ascending order

      const universities = Array.from(new Set(jobApplications.map((item) => item?.institute)))

      const summedExperiences: any = Array.from(
        new Set(
          jobApplications.map((item) => {
            let jobExperience = item?.candidate?.job_experience || 0
            jobExperience = parseFloat(jobExperience)
            let bankingExperience = item?.candidate?.banking_experience || 0
            bankingExperience = parseFloat(bankingExperience)
            let total = jobExperience + bankingExperience
            // total = parseFloat(total.toFixed(2));
            total = Number(total.toFixed(1))
            return total
          })
        )
      ).sort((a: any, b: any) => a - b)

      const totalExperienceStrings: string[] = summedExperiences.map((exp) => exp.toString())

      const ageRange: any = Array.from(
        new Set(
          jobApplications.map((item) => {
            const dob = item?.candidateData?.dob || getCurrentDate()
            const age = calculateAge(dob)
            return age
          })
        )
      )
        .filter((age) => age !== 0)
        .sort((a: any, b: any) => a - b)

      setFilters([
        {
          Age: ageRange,
        },
        {
          'Banking Experience': sortedExperiences,
        },
        {
          'Total Experience': totalExperienceStrings,
        },
        {
          Universities: universities,
        },
      ])
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getCurrentDate = () => {
    const currentDate = new Date()

    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so we add 1 and pad with '0' if necessary
    const day = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if necessary

    return `${year}-${month}-${day}`
  }

  const totalExpereinceApi = async (value) => {
    axios.post(
      FILTER_BY_TOTAL,
      {
        job_id: job_id,
        jobapp_stage: 'application',
        totalExperience: value,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
  }

  const universityApi = async (value) => {
    axios.post(
      FILTER_BY_UNIVERSITY,
      {
        job_id: job_id,
        jobapp_stage: 'application',
        universityName: value,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    )
  }

  // useEffect(() => {
  //   if (trigger) {
  //     GetAllJobsApplicationsbypage()
  //   }
  // }, [trigger, job_id])

  const handlePageChange = (page) => {
    let data = {...params}
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    let data = {...params}
    data.limit = event.target.value
    setParams(data)
  }

  useEffect(() => {
    ;(async () => {
      if (showRejectedApplicants === false) {
        await GetAllJobsApplicationsbypage()
      }
    })()
  }, [job_id, trigger, params, showRejectedApplicants])

  useEffect(() => {
    ;(async () => {
      if (showRejectedApplicants) {
        await GetAllJobsApplicationsbypage()
      }
    })()
  }, [showRejectedApplicants])

  const checkedStateFunction = () => {
    setcheckedstate(!checkedstate)
  }

  const toggleFilterMenu = () => {
    setFilterMenu(!filterMenu)
  }

  const DisplayAllAoptiontoadopt = () => {
    setmultioptiondots(!multioptiondots)
  }
  const handlePopupClose = () => {
    setmultioptiondots(false)
  }

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener('click', handleDocumentClick)

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  const handleDocumentClick = (event) => {
    // Check if the click target is outside the popup menu
    const popupMenu = document.getElementById('popup-menu')
    if (popupMenu && !popupMenu.contains(event.target)) {
      // handlePopupClose();
    }
  }

  const sendApprovalRequest = () => {
    if (testExists) {
      sendEmailToTA('online test')
    } else {
      // show interview schedule popup schedule interview and hit API to sent approval email
      sendEmailToTA('stage1')
    }
  }

  const sendEmailToTA = (newStage) => {
    console.log('sendJobAppId: ', currentJobAppId)
    if (!currentJobAppId) {
      window.alert('Job application id not found, please try again.')
      return
    }
    let data = {
      jobapp_id: currentJobAppId,
      currentStage: 'application',
      newStage: newStage,
    }

    axios
      .post(TA_APPROVAL_EMAIL_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCurrentJobAppId('')
        // refresh the list of applications
        GetAllJobsApplicationsbypage()
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const isApplicantSelected = (candidateId) => {
    // return selectedApplicants.includes(candidateId);
    return selectedApplicants.some((applicant) => applicant?.candidateData?.user_id === candidateId)
  }

  const updateSelectedApplicants = (candidateApplication) => {
    let candidateId = candidateApplication?.candidateData?.user_id
    // Check if candidateId is already in the list
    const isSelected = selectedApplicants.some((applicant) => applicant?.candidateData?.user_id === candidateId)
    // If already selected, remove it; otherwise, add it
    if (isSelected) {
      const updatedList = selectedApplicants.filter((applicant) => applicant?.candidateData?.user_id !== candidateId)
      setSelectedApplicants(updatedList)
    } else {
      // also check is the total selected applications are 7 do not add into list and show warning message else add application into list
      if (testExists) {
        setSelectedApplicants((prevList) => [...prevList, candidateApplication])
      } else {
        const totalSelected = selectedApplicants.length
        if (totalSelected === 7) {
          setMessage('Maximum limit reached. Cannot add more applicants.')
          setShowErrorMessage(true)
          return
        }
        setSelectedApplicants((prevList) => [...prevList, candidateApplication])
      }
    }
  }

  const checkAllApplications = () => {
    if (result) {
      let allCandidates: any = []
      result.map((result, index) => {
        if (testExists) {
          allCandidates.push(result)
        } else {
          if (allCandidates.length < 7) {
            allCandidates.push(result)
          }
        }
      })
      if (checkedstate) {
        setSelectedApplicants([])
        setcheckedstate(false)
      } else {
        setSelectedApplicants(allCandidates)
        setcheckedstate(true)
      }
    }
  }

  const handleOptionClick = (option) => {
    setSelectedOption(option)
  }

  const filterChange = (filterValue, type) => {
    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)
    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, {type: type, value: filterValue}])
    }
  }

  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    filterItems()
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {}

  return (
    <>
      <div style={{margin: '8px 0px 0px 0px'}} className={`card shadow-sm mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header card-header1234864  border-0 pt-5'>
          <div>
            {/* <div
              onClick={() => checkAllApplications()}
            >
              {checkedstate === false ? (
                <>
                  {' '}
                  <img src={unchecked}></img>
                </>
              ) : (
                <>
                  {' '}
                  <img src={checked}></img>
                </>
              )}
            </div> */}
          </div>

          <div className='ms-auto d-flex align-items-center'>
            {/* <div
              className='d-flex align-items-center justify-content-center candi-btn2 px-3 me-4'
              style={{ height: '2.85rem' }}
              onClick={() => {
                // navigate(`/rejected_candidates/${job_id}`)
                setShowRejectedApplicants(!showRejectedApplicants);
              }}
            >
              <label className='text-white fw-semibold fs-6 cursor-pointer '>Rejected Applicants</label>
            </div> */}

            <div className='d-flex align-items-center me-4'>
              <label className='toggle-switch-small'>
                <input
                  type='checkbox'
                  checked={showRejectedApplicants}
                  onChange={() => {
                    setShowRejectedApplicants(!showRejectedApplicants)
                  }}
                />
                <span className='toggle-switch-small-slider'></span>
              </label>
              <label className='fs-5 fw-bolder ms-2' style={{color: '#F36523'}}>
                Rejected Applicants
              </label>
            </div>

            {/* filters view */}
            {!showRejectedApplicants && (
              <div
                ref={dropdownRefFilters}
                className={clsx(`d-flex align-items-center me-4 dropdown ${filterMenu ? 'open' : ''}`)}
                id='kt_filter_menu_toggle'
              >
                <div
                  className={clsx('cursor-pointer symbol')}
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  // data-kt-menu-placement='bottom-end'
                  data-kt-menu-placement='bottom-start'
                  data-kt-menu-flip='bottom'
                  onClick={toggleFilterMenu}
                >
                  <label className='filter-btn fs-5 fw-bolder cursor-pointer'>
                    Filters
                    <img src={imagePath.ic_filter_orange} width={14} height={14} style={{marginLeft: '4px'}} />
                  </label>
                </div>
                <div
                  className={clsx(
                    'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-175px',
                    {
                      show: filterMenu,
                    }
                  )}
                  data-kt-menu='true'
                  // onMouseLeave={() => setSelectedOption(null)}
                >
                  <ul style={{listStyleType: 'none', cursor: 'pointer'}}>
                    {filters.map((filter, index) => {
                      const option = Object.keys(filter)[0]
                      const subOptions = filter[option]
                      return (
                        <li style={{cursor: 'pointer'}} key={index} onMouseEnter={() => handleOptionClick(option)}>
                          {
                            <div className='d-flex align-items-center justify-content-center cursor-pointer'>
                              <label
                                className='form-label me-2 hoverable-text cursor-pointer'
                                style={{color: selectedOption === option ? '#F36523' : '#535260'}}
                              >
                                {option}
                              </label>
                              <img src={icArrowRight} style={{marginLeft: 'auto', marginBottom: '2px', paddingRight: '8px'}} />
                            </div>
                          }

                          {selectedOption === option && (
                            <div className='job-app-sub-filter-menu' data-kt-menu='true' onMouseLeave={() => setSelectedOption(null)}>
                              <ul style={{listStyleType: 'none'}}>
                                {subOptions ? (
                                  subOptions.map((subOption, subIndex) => {
                                    const isChecked = selectedFilters.some(
                                      (filter) => filter.type === selectedOption && filter.value === subOption
                                    )
                                    return (
                                      <li
                                        className=' d-flex hoverable-text mb-4 align-items-center'
                                        style={{cursor: 'pointer'}}
                                        key={subIndex}
                                      >
                                        <input
                                          name='termsConditions'
                                          className='form-check-input me-2'
                                          style={{height: '16px', width: '16px'}}
                                          type='checkbox'
                                          checked={isChecked}
                                          onChange={(event) => filterChange(subOption, selectedOption)}
                                        />

                                        <label className='form-check-label '>{subOption}</label>
                                      </li>
                                    )
                                  })
                                ) : (
                                  <div></div>
                                )}
                              </ul>
                            </div>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            )}
            {!showRejectedApplicants && (
              <div
                ref={dropdownRefAction}
                className={clsx(`d-flex align-items-center dropdown ${multioptiondots ? 'open' : ''}`)}
                id='kt_actions_applicants_toggle'
              >
                <div
                  className={clsx('cursor-pointer symbol')}
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  // data-kt-menu-placement='bottom-end'
                  data-kt-menu-placement='bottom-start'
                  data-kt-menu-flip='bottom'
                  onClick={DisplayAllAoptiontoadopt}
                >
                  <div className='d-flex align-items-center justify-content-center candi-btn2 px-3' style={{height: '2.85rem'}}>
                    <label className='text-white fs-5 fw-bolder cursor-pointer me-1'>Actions</label>
                    <img
                      className='cursor-pointer'
                      src={imagePath.ic_arrow_down_white}
                      width={18}
                      height={18}
                      style={{objectFit: 'cover'}}
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-100px',
                    {
                      show: multioptiondots,
                    }
                  )}
                  data-kt-menu='true'
                >
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          if (testExists) {
                            createApproveAllPopup(job_id)
                          } else {
                            scheduleInterViewMultipleCandidatesStage1()
                          }
                        }}
                        className=' liststyling  fw-semibold fs-6'
                      >
                        Approve All
                      </div>
                      <div
                        onClick={() => {
                          setmultioptiondots(false)
                          if (selectedApplicants) {
                            createRejectAllPopup(job_id)
                          } else {
                            setMessage('Please select job applications first and try again.')
                            setShowErrorMessage(true)
                          }
                        }}
                        className=' liststyling mt-2 fw-semibold fs-6'
                      >
                        Reject All
                      </div>
                    </div>
                    {approveAllPopup}
                    {rejectAllPopup}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* show chips of applied filters */}
        <div className='d-flex me-9 ms-8 ps-2 my-4 ' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
          {selectedFilters ? (
            selectedFilters.map((filterData, index) => {
              return (
                <div key={index} className='d-flex orange-tag align-items-center me-4' style={{height: '34px'}}>
                  {filterData?.value === 'none' ? 'Employee' : filterData?.value}
                  <img
                    className='ms-2'
                    style={{cursor: 'pointer'}}
                    src={imagePath.ic_cross_orange}
                    onClick={() => removeFilter(filterData.value, filterData.type)}
                  />
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>

        {showRejectedApplicants ? (
          <RejectedCandidates id={job_id} />
        ) : (
          <div className='card-body py-3'>
            <div style={{position: 'relative'}}>
              {isLoading && (
                // Show loading icon if isLoading state is true
                <div
                  className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                  style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999}}
                >
                  <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                </div>
              )}
              {/* begin::Table container */}
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4 table-borders'>
                  {/* begin::Table head */}
                  <thead>
                    {/* <tr className='fw-bold graycolor grajabg '> */}
                    <tr className='fw-bold graycolor bg-light'>
                      {/* <th className='w-25px'>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value='1'
              data-kt-check='true'
              data-kt-check-target='.widget-13-check'
            />
          </div>
        </th> */}

                      <th className='min-w-20px align-middle firstrowmarginindex'>
                        <div
                          // onClick={() => checkedStateFunction()}
                          onClick={() => checkAllApplications()}
                        >
                          {checkedstate === false ? (
                            <>
                              {' '}
                              <img src={unchecked}></img>
                            </>
                          ) : (
                            <>
                              {' '}
                              <img src={checked}></img>
                            </>
                          )}
                        </div>
                      </th>
                      <th className='min-w-100px align-items-center ps-2 align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' style={{whiteSpace: 'nowrap'}} scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>CNIC</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Total Experience</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Banking Experience</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>University</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Profile Match</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Date Applied</label>
                      </th>
                      <th className='min-w-60px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Age</label>
                      </th>
                      <th className='min-w-100px align-items-center align-middle' scope='col'>
                        <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                      </th>
                      {/* <th className='min-w-100px '></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {result &&
                      result.map((candidategData, index) => {
                        let dob = candidategData?.candidateData?.dob
                        let age = calculateAge(dob)
                        let candidateId = candidategData?.candidateData?.user_id
                        let rowSelected = isApplicantSelected(candidateId)
                        const isEvenRow = index % 2 === 0
                        return (
                          <tr style={{backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9'}}>
                            <td className='firstrowmarginindex'>
                              {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                                <div
                                  // onClick={() => checkedStateFunction()}
                                  onClick={() => updateSelectedApplicants(candidategData)}
                                >
                                  {rowSelected === false ? (
                                    <>
                                      {' '}
                                      <img src={unchecked}></img>
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      <img src={checked}></img>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-45px me-2'>
                                  <img
                                    className='profile-user-icon cursor-pointer'
                                    src={
                                      candidategData?.candidate?.profile_pic !== null
                                        ? candidategData?.candidate?.profile_pic
                                        : candidategData?.candidateData?.profile_pic !== null
                                        ? candidategData?.candidateData?.profile_pic
                                        : imagePath.userIcon
                                    }
                                    alt=''
                                    onClick={() => {
                                      createProfilePopup(
                                        candidategData?.candidateData?.fullName,
                                        candidategData?.jobapp_id,
                                        candidategData?.candidateData?.email,
                                        candidategData?.candidateData?.user_id,
                                        candidategData?.candidateData?.profile_pic,
                                        candidategData?.candidateData?.cnic,
                                        candidategData?.candidateData?.phone_number,
                                        candidategData?.institute,
                                        candidategData?.candidate?.skills,
                                        candidategData?.candidate?.work_experience,
                                        // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                        candidategData?.candidate?.totalExperience,
                                        candidategData?.banking_experience,
                                        candidategData?.job_experience,
                                        candidategData?.candidate?.qualification,
                                        candidategData?.candidate?.certification,
                                        candidategData?.candidateData?.dob,
                                        candidategData?.profile_match,
                                        jobTitle,
                                        candidategData?.recuiter_id,
                                        candidategData?.video_resume,
                                        testExists,
                                        index
                                      )
                                    }}
                                  />
                                </div>
                                <div
                                  className='d-flex justify-content-start flex-column'
                                  // onClick={() => {
                                  //   createProfilePopup(
                                  //     candidategData?.name,
                                  //     candidategData?.jobapp_id,
                                  //     candidategData?.candidateData?.email,
                                  //     candidategData?.candidateData?.user_id,
                                  //     candidategData?.candidateData?.profile_pic,
                                  //     candidategData?.candidateData?.cnic,
                                  //     candidategData?.candidateData?.phone_number,
                                  //     candidategData?.institute,
                                  //     candidategData?.candidate?.skills,
                                  //     candidategData?.candidate?.work_experience,
                                  //     // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                  //     candidategData?.candidate?.totalExperience,
                                  //     candidategData?.banking_experience,
                                  //     candidategData?.job_experience,
                                  //     candidategData?.candidate?.qualification,
                                  //     candidategData?.candidate?.certification,
                                  //     candidategData?.candidateData?.dob,
                                  //     candidategData?.profile_match,
                                  //     jobTitle,
                                  //     candidategData?.recuiter_id,
                                  //     candidategData?.video_resume,
                                  //     testExists,
                                  //     index
                                  //   )
                                  // }}
                                >
                                  <label className='job-funneling-table-content '>{candidategData?.candidateData?.fullName}</label>
                                </div>
                              </div>
                            </td>
                            <td>
                              <label className='job-funneling-table-content '>{candidategData?.candidateData?.cnic}</label>
                            </td>
                            <td>
                              <div className='job-funneling-table-content '>
                                {getTotalYears(JSON.parse(candidategData?.candidate?.work_experience))}
                              </div>
                            </td>

                            <td>
                              <div className='job-funneling-table-content '>
                                {getTotalBankingExperience(JSON.parse(candidategData?.candidate?.work_experience))}
                              </div>
                            </td>
                            <td>
                              <div className='job-funneling-table-content '>{candidategData?.institute}</div>
                            </td>

                            <td>
                              <span className='job-funneling-table-content '>
                                {candidategData?.profile_match != null && !isNaN(candidategData?.profile_match)
                                  ? `${candidategData?.profile_match}%`
                                  : '0%'}
                              </span>
                            </td>

                            <td>
                              <div className='job-funneling-table-content '>
                                {new Date(candidategData?.created_at).toLocaleDateString('en-US', {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}
                              </div>
                            </td>

                            <td>
                              <label className='job-funneling-table-content '>{`${age} yrs`}</label>
                            </td>

                            <td key={`td-${index}`}>
                              <div className='d-flex align-items-center me-4'>
                                {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                                  <button
                                    key={`button-${index}`}
                                    onClick={async () => {
                                      // check profile match if don't meet requirements show warning
                                      let profileMatch = candidategData?.profile_match ? candidategData?.profile_match : 0
                                      if (profileMatch < 50) {
                                        // if TA Approved and next stage is interview, show schedule interview popup else show warning popup
                                        if (!testExists && candidategData?.jobapp_status === 'TA Lead Approved') {
                                          // createPopupNoTest(
                                          //   candidategData?.candidateData?.fullName,
                                          //   candidategData?.jobapp_id,
                                          //   candidategData?.candidateData?.email,
                                          //   candidategData?.candidateData?.user_id,
                                          //   candidategData?.recuiter_id,
                                          // )

                                          let currntApplication: any = []
                                          currntApplication.push(candidategData)
                                          await setSelectedApplicants(currntApplication)
                                          setScheduleMultipleCandidateInterview(true)
                                          // scheduleInterViewMultipleCandidatesStage1()
                                        } else {
                                          setCurrentJobAppId(candidategData?.jobapp_id)
                                          setShowWarning(true)
                                        }
                                      } else {
                                        if (testExists) {
                                          createApprovalPopup(
                                            candidategData?.candidateData?.fullName,
                                            candidategData?.jobapp_id,
                                            candidategData?.candidateData?.user_id,
                                            candidategData?.recuiter_id,
                                            candidategData?.candidateData?.email
                                          )
                                        } else {
                                          // createPopupNoTest(
                                          //   candidategData?.candidateData?.fullName,
                                          //   candidategData?.jobapp_id,
                                          //   candidategData?.candidateData?.email,
                                          //   candidategData?.candidateData?.user_id,
                                          //   candidategData?.recuiter_id,
                                          // )

                                          let currntApplication: any = []
                                          currntApplication.push(candidategData)
                                          await setSelectedApplicants(currntApplication)
                                          setScheduleMultipleCandidateInterview(true)
                                          // scheduleInterViewMultipleCandidatesStage1()
                                        }
                                      }

                                      // setClicked(false)
                                    }}
                                    // className='Approvebutton'
                                    className={
                                      candidategData?.jobapp_status === 'Sent for TA approval'
                                        ? //  || candidategData?.jobapp_status === 'TA Lead Approved'
                                          'regejectbutton px-2'
                                        : 'Approvebutton'
                                    }
                                    disabled={candidategData?.jobapp_status === 'Sent for TA approval' ? true : false}
                                    ref={(button) => (approveButtonRefs.current[index] = button)} // Cast buttonRef to RefObject<HTMLButtonElement>
                                  >
                                    {`${
                                      candidategData?.jobapp_status === 'Sent for TA approval'
                                        ? 'Approval Pending'
                                        : candidategData?.jobapp_status === 'TA Lead Approved'
                                        ? 'TA Lead Approved'
                                        : 'Approve'
                                    }`}
                                  </button>
                                ) : (
                                  <div>
                                    <label className='red-chip fs-7 fw-bolder form-label'>Withdrawn</label>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    {Approvalpopup}
                    {popupTest}
                    {popupProfile}
                  </tbody>
                </table>
                {/* pagination */}
                {result && result.length > 0 && (
                  <div className='d-flex col-md-12 align-items-center'>
                    <div className='col-md-4'>
                      <label>{`Showing ${result ? result.length : 0} of ${params.total} results.`}</label>
                    </div>
                    <div className='mx-auto col-md-4' style={{textAlign: 'center'}}>
                      <CustomPagination
                        // totalPages={Math.ceil(allUsers.length / itemsPerPage)}
                        // pageValue={currentPage}
                        pageValue={params.page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>

                    <div className='col-md-4 '>
                      <div className='ms-auto d-flex flex-end align-items-center'>
                        <select
                          className='me-2 pagination-input'
                          style={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
                          id='selectValues'
                          // value={itemsPerPage}
                          value={params.limit}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                        </select>

                        <div className='me-2'>
                          <label>Items per page</label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {showWarning && (
        <WarningTARemovePopup
          show={true}
          message='This approval request will be forwarded to the TA Lead! Are you sure you want to approve the candidate?'
          handleClose={() => setShowWarning(false)}
          handleYesSure={() => {
            setShowWarning(false)
            sendApprovalRequest()
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {scheduleMultipleCandidateInterview && (
        <ScheduleInterViewMultipleCandidatesStage1
          show={true}
          handleClose={() => {
            setScheduleMultipleCandidateInterview(false)
          }}
          handleSuccess={() => {
            setScheduleMultipleCandidateInterview(false)
            // refresh the list of applications
            setpopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
          candidates={selectedApplicants}
          currentStage={'application'}
          newStage={`${testExists ? 'online test' : 'stage1'}`}
        />
      )}
    </>
  )
}

export default ApplicationsTable
