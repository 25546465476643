//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import imagePath from '../../../../constants/imagePath';

type Props = {
    handleClose: () => void
    handleAddGradeInfo: (liters, grade, price) => void
    handleUpdateGradeInfo: (gradeInfo) => void
    type: string
    data: any
    grades: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddUpdateGradeFuelPopup = ({ handleClose, handleAddGradeInfo, handleUpdateGradeInfo, type, data, grades }: Props) => {
    const [grade, setGrade] = useState(type === 'edit' ? data.grade : '');
    const [totalLiters, setTotalLiters] = useState(type === 'edit' ? data.liters : '');
    const [price, setPrice] = useState(type === 'edit' ? data.price : '');
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);

    useEffect(() => {
        setIsSaveEnabled(grade !== '' && totalLiters !== '' && price !== '');
    }, [grade, totalLiters, price]);

    const handleGradeChange = (e) => {
        e.preventDefault();
        setGrade(e.target.value)
    }

    const handleTotalLitersChange = (e) => {
        let value = e.target.value;
        // Regex to match a number with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;
        // Check if the value matches the regex
        if (regex.test(value)) {
            setTotalLiters(value)
        }
    };
    
    const handlePriceChange = (e) => {
        let value = e.target.value;
        // Regex to match a number with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;
        // Check if the value matches the regex
        if (regex.test(value)) {
            setPrice(value)
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123 px-6 py-4 position-relative'>
                <div className='d-flex align-items-center mx-6'>
                    <label className='fs-3 fw-bolder'>{`${type === 'add' ? 'Add Grade Fuel' : 'Update Grade Fuel'}`}</label>
                    <img className='ms-auto cursor-pointer' src={imagePath.ic_close} onClick={handleClose} />
                </div>
                <div className='mx-6'>
                    <form>
                        <div className="mt-8">
                            <label htmlFor="grade" className="form-label fs-6" style={{ color: '#373D4A' }}>Select a Grade<span style={{ color: 'red' }}>*</span></label>
                            <select
                                id='grade'
                                name='grade'
                                data-control='select2'
                                data-hide-search='true'
                                className='form-select '
                                defaultValue=''
                                placeholder='Select a grade'
                                value={grade}
                                required
                                onChange={(event) => handleGradeChange(event)}

                            >
                                <option value=''>Select a grade</option>
                                {grades ? grades.map((data) => (
                                    <option key={data} value={data}>
                                        {data}
                                    </option>
                                )) : (<div></div>)}
                            </select>
                        </div>
                        <div className="mt-8">
                            <label htmlFor="liters" className="form-label fs-6" style={{ color: '#373D4A' }}>No. of Liters<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                style={{ height: '40px' }}
                                placeholder='Enter total liters'
                                id="liters"
                                value={totalLiters}
                                onChange={handleTotalLitersChange}
                            />
                        </div>
                        <div className="mt-8">
                            <label htmlFor="priceInput" className="form-label fs-6" style={{ color: '#373D4A' }}>Price/Liter<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                style={{ height: '40px' }}
                                placeholder='Enter Price Per Liter'
                                id="priceInput"
                                value={price}
                                onChange={handlePriceChange}
                            />
                        </div>
                        <button
                            onClick={() => {
                                if (type === 'add') {
                                    handleAddGradeInfo(totalLiters, grade, price)
                                } else {
                                    data.liters = totalLiters;
                                    data.grade = grade;
                                    data.price = price;
                                    handleUpdateGradeInfo(data)
                                }
                            }}
                            type='button'
                            className='d-flex btn px-18 py-2 apply-review-button my-8 mx-auto'
                            style={{ color: 'white' }}
                            disabled={!isSaveEnabled}
                        >
                            Save
                        </button>
                    </form>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default AddUpdateGradeFuelPopup;
