import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useState, useEffect } from 'react'
import './Test.css'
// import {useContext} from 'react'
import Test from "./Test";
import arrowdown from './images/navigate_next_black_24dp.png'
import { useTestContext } from '../../../context/useTestContext'
import { TestQuestionDataProvider,TestQuestionDataContext } from '../../../context/TestQuestionDataContext';
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL


const TestWrapper = () => {
    const intl = useIntl()
    const { Test: TestCheck, setTestButton } = useTestContext()

    useEffect(() => {
        setTestButton("save")

        return () => {
            setTestButton("none")
        }
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Test' })}</PageTitle>
            {/* <DashboardPage /> */}

            {/* begin::Row */}
            <TestQuestionDataProvider><Test /></TestQuestionDataProvider>
            
        </>
    )
}

export default TestWrapper
