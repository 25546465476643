//import liraries
import React, { useEffect, useState } from 'react'
import imagePath from '../../../constants/imagePath'
import './QuestionForm.css'
import axios from 'axios'
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useAuth } from '../auth/core/Auth'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
const modalsRoot = document.getElementById('root-modals') || document.body

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
const UPLOAD_VIDEO_URL = `${API_URL}/videos`
const UPDATE_QUESTION_URL = `${API_URL}/questions/`
const GET_VIDEOS = `${API_URL}/videos`

// create a component
const UpdateQuestion = ({ handleClose, currentQuestion, updateQuestionsList, ...props }) => {
  const { auth, logout } = useAuth()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [uploadingVideo, setUploadingVideo] = useState(false)
  const [individualCompetencies, setIndividualCompetencies] = useState<any>()
  const [options, setOptions] = useState([
    {
      text: '',
      is_correct: false,
    },
    {
      text: '',
      is_correct: false,
    },
  ])

  const [competency, setCompetency] = useState<string>('')
  const [questionTime, setQuestionTime] = useState<any>()
  const [questionText, setQuestionText] = useState('')
  const [videoFile, setVideoFile] = useState<File | undefined>(undefined)
  const [videoLink, setVideoLink] = useState('')
  const [videoId, setVideoId] = useState('')
  const [videoFileName, setVideoFileName] = useState('')
  const [dragging, setDragging] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [allVideos, setAllVideos] = useState<any>();

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setDragging(false)

    const files = e.dataTransfer.files

    if (files.length > 0) {
      handleFileUpload(files)
    }
  }

  useEffect(() => {
    (() => {
      // initialize question data
      if (currentQuestion) {
        setCompetency(currentQuestion?.competency?.competency_id);
        setQuestionTime(currentQuestion?.allowed_time)
        setQuestionText(currentQuestion?.text)
        setOptions(currentQuestion?.answers)
        const url = currentQuestion?.link
        if (url) {
          setVideoLink(url)
          // Split the URL by "/"
          const urlParts = url.split('/')
          // Get the last part of the URL
          const fileNameWithParams = urlParts[urlParts.length - 1]
          // Split the last part by "?" to remove any query parameters
          const fileName = fileNameWithParams.split('?')[0]
          setVideoFileName(fileName)
        }
      }
    })()
  }, [currentQuestion, individualCompetencies])

  useEffect(() => {
    ; (() => {
      getAllCompetencies()
    })()
  }, [])

  const getAllCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        const competencies = resp.data.competencies
        // Separate into individual and group competencies
        let allIndividualCompetencies = competencies.filter((comp) => comp.type.toLowerCase() === 'individual')
        // let allGroupCompetencies = competencies.filter(comp => comp.type.toLowerCase() === 'group');

        setIndividualCompetencies(allIndividualCompetencies)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const displayErrorMessage = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
      logout()
    } else if (error.response?.data?.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        setMessage(error.response.data.error.message)
        setShowErrorMessage(true)
      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  const handleAddOption = () => {
    if (options.length < 5) {
      const newOption = {
        text: '',
        is_correct: false,
      }
      setOptions([...options, newOption])
    } else {
      setMessage('You can only add up to 5 options.')
      setShowErrorMessage(true)
    }
  }

  const removeOption = (index) => {
    if (options.length <= 2) {
      setMessage('Question should have at least two options.')
      setShowErrorMessage(true)
      return
    }
    setOptions([...options.slice(0, index), ...options.slice(index + 1)])
  }

  const handleCheckboxChange = (index) => {
    let data = [...options]
    data?.map((option, optionIndex) => {
      if (optionIndex === index) {
        if (option?.text !== null && option?.text !== undefined && option?.text !== '') {
          option.is_correct = true
        } else {
          setMessage('First enter option text and try again.');
          setShowErrorMessage(true);
        }
      } else {
        option.is_correct = false
      }
    })
    setOptions(data)
    // setSelectedOption(index);
  }

  const handleSelectCompetency = (e) => {
    let value = e.target.value
    setCompetency(value)
    // const selectedOptions = e.target.selectedOptions

    // for (let option of selectedOptions) {
    //   const competencyId = option.getAttribute("data-competency-id");
    //   // Check if competencyId is not already in selectedCompetencies
    //   if (!selectedCompetencies.some(comp => comp.competency_id === competencyId)) {
    //     let competency = allCompetencies.find(comp => comp.competency_id === competencyId);
    //     if (competency) {
    //       // Add totalQuestions and totalTime properties to the competency object
    //       competency = {
    //         ...competency,
    //         totalQuestions: '',
    //         totalTime: 0
    //       };
    //       setSelectedCompetencies(prevState => [...prevState, competency]);
    //     }
    //   }
    // }
  }

  const handleChangeQuestionTime = (e) => {
    let value = e.target.value
    setQuestionTime(value)
  }
  const handleOnTimeBlur = () => {
    if (questionTime < 30) {
      setQuestionTime('');
      setMessage('Question time Value should be 30 or more.')
      setShowErrorMessage(true);
    }
  };

  const handleChangeQuestionText = (e) => {
    setQuestionText(e.target.value)
  }

  const handleChangeOption = (e, index) => {
    let data = [...options]
    data[index]['text'] = e.target.value
    setOptions(data)
  }

  const handleFileUpload = (file) => {
    // e.preventDefault();
    // const file = e.target.files[0];

    const validFormats = ['video/mp4', 'video/avi', 'video/webm', 'video/quicktime', 'video/x-matroska']
    if (
      file
      // && validFormats.includes(file.type)
    ) {
      setVideoFile(file)
      setVideoFileName(file.name)
      uploadQuestionVideo(file)
    } else {
      setMessage('Invalid file. Please select or drop a valid video file (MP4, AVI, WebM, MOV, MKV).')
      setShowErrorMessage(true)
      return
    }
  }

  const uploadQuestionVideo = (file) => {
    if (!file) {
      setMessage('Video file not found')
      setShowErrorMessage(true)
      return
    }
    const formData = new FormData()
    // formData.append('file', videoResumeFile, 'video_resume');
    formData.append('file', file, file.name)
    setUploadingVideo(true)
    axios
      .post(UPLOAD_VIDEO_URL, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setUploadingVideo(false)
        setVideoLink(res?.data?.url)
      })
      .catch((error) => {
        setUploadingVideo(false)
        displayErrorMessage(error)
      })
  }

  const handleUpdateQuestion = (e) => {
    e.preventDefault()
    if (competency === null || competency === undefined || competency === '') {
      setMessage('Please select competency first and try again!')
      setShowErrorMessage(true)
      return
    }
    if (questionTime === null || questionTime === undefined || questionTime === '' || questionTime === 0) {
      setMessage('Invalid question time!')
      setShowErrorMessage(true)
      return
    }
    let question = questionText?.trim()
    if (question === null || question === undefined || question === '') {
      setMessage('Question text could not be empty.')
      setShowErrorMessage(true)
      return
    }

    if (options === null || options === undefined || options.length === 0) {
      setMessage('Question should have at least 2 options.')
      setShowErrorMessage(true)
      return
    }
    let emptyOptionFound = false
    let correctOptionAvailable = false
    options.map((option) => {
      let optionText: any = option?.text?.trim()
      if (optionText === null || optionText === undefined || optionText === '') {
        emptyOptionFound = true
      }
      if (option?.is_correct) {
        correctOptionAvailable = true
      }
    })
    if (emptyOptionFound) {
      setMessage('Options could not be empty!')
      setShowErrorMessage(true)
      return
    }
    if (!correctOptionAvailable) {
      setMessage('Question should have 1 correct option.')
      setShowErrorMessage(true)
      return
    }

    setLoading(true)
    let data = {
      organization_id: 3,
      competency_id: competency,
      text: questionText,
      allowed_time: questionTime,
      type: 'MCQ',
      // link: "https://www.google.com",
      link: videoLink,
      video_id: videoId,
      complexity: 1,
      score: 1,
      answers: options,
    }
    axios
      .put(UPDATE_QUESTION_URL + currentQuestion?.question_id, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // close the popup and show success popup and update the list of questions
        updateQuestionsList()
      })
      .catch((error) => {
        setLoading(false)
        displayErrorMessage(error)
      })
  }

  const handleChangeSearch = (e) => {
    let keyWord = e.target.value;
    setSearchText(keyWord);
    if (keyWord && keyWord.length >= 3) {
      let data = {
        order: 'CREATED_AT',
        sort: 'desc',
        page: 1,
        limit: 10,
        total: 0,
        search: keyWord
      }
      const filteredParams = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
      )
      axios.get(GET_VIDEOS, {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'content-type': 'multipart/form-data',
        }
      }).then((res) => {
        setAllVideos(res?.data?.data);
        setIsFocused(true);
      }).catch((error) => {
        displayErrorMessage(error);
      });
    }

    if (keyWord === null || keyWord === undefined || keyWord.length === 0) {
      setIsFocused(false);
    }
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleVideoClick = (video) => {
    setVideoLink(video?.link);
    setVideoId(video?.video_id);
    setSearchText(video?.name);
    setVideoFileName(video?.name);
    setIsFocused(false);
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={true}
      backdrop={true}
    >
      <div className='p-6'>
        <div className='d-flex align-items-center mb-8'>
          <h3 style={{ fontWeight: '700' }}>Update Question</h3>
          <img className='ms-auto cursor-pointer' onClick={handleClose} src={imagePath.ic_close} />
        </div>
        <form className='px-4'>
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center'>
              <label className='fs-6 fw-bolder me-2'>Select Competency</label>
              <select
                className='form-select py-2'
                style={{ width: '14rem' }}
                data-control='select2'
                data-hide-search='true'
                value={competency}
                placeholder='Select Competencies'
                onChange={(e) => handleSelectCompetency(e)}
              >
                <option value=''>Select</option>
                {individualCompetencies ? (
                  individualCompetencies.map((competency, index) => {
                    return (
                      <option
                        key={competency?.competency_id}
                        value={competency?.competency_id}
                        data-competency-id={competency?.competency_id}
                      >
                        {competency?.name}
                      </option>
                    )
                  })
                ) : (
                  <div></div>
                )}
              </select>
            </div>
            <div className='d-flex align-items-center ms-auto'>
              <label className='fs-6 fw-bolder me-2'>Set Time (Sec)</label>
              <input
                className='form-control py-2'
                style={{ width: '14rem' }}
                type='number'
                min={30}
                placeholder='Enter Seconds'
                value={questionTime}
                onBlur={handleOnTimeBlur}
                onChange={handleChangeQuestionTime}
              />
            </div>
          </div>

          {/* <div className="d-flex flex-wrap gap-2 mt-4">
            {selectedCompetencies && selectedCompetencies.map((competency, index) => {
              return (
                <div className="orange-tag d-flex align-items-center me-4">
                  <label style={{ whiteSpace: 'nowrap' }}>{competency?.name}</label>
                  <img className="cursor-pointer ms-2" src={imagePath.ic_cross_orange} onClick={() => {
                    removeItem(index)
                  }} />
                </div>
              )
            })}
          </div> */}

          <div className='mt-4'>
            <label className='fs-6 fw-bolder'>Question</label>
            <textarea
              className='form-control mt-2'
              style={{ resize: 'none' }}
              rows={3}
              placeholder='Question'
              value={questionText}
              onChange={handleChangeQuestionText}
            ></textarea>

            <div className='options-container'>
              {options.map((option, index) => (
                <div className='option-container mt-4 ' key={index}>
                  <span className='me-4 fs-6 fw-semibold'>{String.fromCharCode(65 + index)}:</span>
                  <input className='form-control py-2' value={option?.text} type='text' onChange={(e) => handleChangeOption(e, index)} />
                  <input
                    type='checkbox'
                    className='form-check-input ms-4 cursor-pointer'
                    style={{ borderRadius: '100%' }}
                    checked={option?.is_correct}
                    onChange={() => handleCheckboxChange(index)}
                  />

                  <img
                    className='cursor-pointer ms-4'
                    height={21}
                    width={21}
                    alt='delete'
                    title='Delete'
                    src={imagePath.ic_delete_orange_cross}
                    onClick={() => removeOption(index)}
                  />
                </div>
              ))}
            </div>
            <div style={{ marginLeft: '1.95rem', marginRight: '5.2rem' }}>
              <div
                className='form-control cursor-pointer py-3 mt-4 justify-content-center align-items-center d-flex'
                onClick={handleAddOption}
              >
                <i className='fas fa-plus'></i>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mt-4'>
            <label className='fs-6 fw-bolder me-4'>Link Video from Library</label>
            <div className='d-flex flex-column' style={{ position: 'relative' }}>
              <input className='form-control py-2'
                style={{ width: '36.4rem' }}
                type='search'
                value={searchText}
                placeholder='Search'
                onChange={handleChangeSearch}
                onFocus={handleFocus}
              />
              {searchText && isFocused && allVideos && (
                <div className='px-4 py-2' style={{ marginTop: '5px', zIndex: 2, maxHeight: '150px', overflowY: 'auto', position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', borderRadius: '6px', border: '1px solid #B2B2B2' }}>
                  {allVideos.map((item, index) => (
                    <div key={index} onClick={() => handleVideoClick(item)} style={{ cursor: 'pointer' }}>
                      <label style={{ whiteSpace: 'nowrap', cursor: 'pointer', marginBottom: '8px' }}>{item?.name}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {videoLink ? (
            <div className='d-flex align-items-center justify-content-center mt-6'>
              <div>
                <video
                  // ref={videoRef}
                  controls
                  style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '4px' }}
                >
                  {/* <source src={URL.createObjectURL(videoResumeFile)} type='video/*' /> */}
                  <source src={videoLink} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
                <div className='d-flex align-items-center mt-2'>
                  <label className='fw-semibold fs-6 me-2' style={{ whiteSpace: 'nowrap' }}>
                    {videoFileName}
                  </label>
                  <img
                    className='cursor-pointer ms-auto'
                    width={18}
                    height={18}
                    src={imagePath.ic_delete_orange_cross}
                    onClick={() => {
                      setVideoFile(undefined)
                      setVideoLink('')
                      setVideoFileName('')
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='upload-video-container p-4'>
              <div
                className='upload-btn ms-10'
                onClick={() => {
                  const uploadInput = document.getElementById('upload-input')
                  if (uploadInput) {
                    uploadInput.click()
                  }
                }}
              >
                <img
                  className=' me-2'
                  src={imagePath.ic_upload_gray}
                  width={40}
                  height={40}
                  alt='Upload'
                  style={{ cursor: 'pointer', objectFit: 'cover', color: '#ccc' }}
                  title='Upload'
                />{' '}
                <br />
                <span style={{ fontSize: '13px' }}>Upload Video</span>
                <input
                  type='file'
                  id='upload-input'
                  accept='video/*'
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      handleFileUpload(e.target.files[0])
                    }
                  }}
                />
              </div>
              <div className='or-text mx-10'>
                <span>or</span>
              </div>
              <div
                className={`drag-drop-container ${dragging ? 'drag-over' : ''}`}
                // className="drag-drop-container "
                style={{ height: '120px' }}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <img
                  className=' me-2'
                  src={imagePath.ic_drag_drop}
                  width={50}
                  height={50}
                  alt='Drag & Drop'
                  style={{ cursor: 'pointer', objectFit: 'cover', marginTop: '1px', marginBottom: '10px' }}
                  title='Drag & Drop'
                />{' '}
                <br />
                <span style={{ fontSize: '12px', color: '#414040' }}>Drag & Drop Video</span>
                <br />
                <span style={{ fontSize: '10px', color: '#696969', marginBottom: '8px' }}>
                  Accept formats include MP4, AVI, WebM, MOV, MKV
                </span>
              </div>
            </div>
          )}

          <div className='d-flex align-items-center justify-content-center mt-10'>
            <button type='button' className='delete-btn' disabled={loading || uploadingVideo} onClick={handleClose}>
              Close
            </button>
            <button type='button' className='save-btn' disabled={loading || uploadingVideo} onClick={(e) => handleUpdateQuestion(e)}>
              Save
            </button>
          </div>
        </form>
        {loading && <LoaderView message='Saving Question...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </div>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default UpdateQuestion
