import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth';
import imagePath from '../../../../constants/imagePath';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Pagination from 'react-bootstrap/Pagination';
import CustomPagination from './CustomPagination';
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';

const API_URL = process.env.REACT_APP_API_URL
export const APPLICATIONS_PER_OPENING_URL = `${API_URL}/job/avg-application-per-opening`

// const ITEMS_PER_PAGE = 5; // Set the number of items you want to display per page

type Props = {
  taLead: any
  recruiter: any
}

const ApplicationsPerOpeningTable = ({ taLead, recruiter }: Props) => {
  const { currentUser, auth } = useAuth()
  const [allApplications, setAllApplications] = useState<any>();
  const [filteredApplications, setFilteredApplications] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedRange, setSelectedRange] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleDateRangeChange = (event, picker) => {
    setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    let firstDate = picker.startDate.format('MM/DD/YYYY');
    let lastDate = picker.endDate.format('MM/DD/YYYY');
    setStartDate(firstDate);
    setEndDate(lastDate);
  };

  const handleCancelDatePicker = () => {
    setSelectedRange('');
    setStartDate('');
    setEndDate('');
  }


  const fetchDataList = () => {
    // let recruiter_id = (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') ? 'admin' : currentUser?.user_id
    // let url = `${APPLICATIONS_PER_OPENING_URL}?recruiter_id=${recruiter_id}`;

    let url = APPLICATIONS_PER_OPENING_URL;
    if (recruiter) {
      url = `${url}?recruiter_id=${recruiter?.user_id}`;
    } else if (taLead) {
      url = `${url}?ta_lead_id=${taLead?.user_id}`;
    } else {
      if (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') {
        url = `${url}?recruiter_id=admin`;
      } else if (currentUser?.role === 'ta_lead') {
        url = `${url}?ta_lead_id=${currentUser?.user_id}`;
      } else if (currentUser?.role === 'recruiter') {
        url = `${url}?recruiter_id=${currentUser?.user_id}`;
      } else {
        return;
      }
    }
    if (startDate !== null && startDate !== undefined && startDate !== '' &&
      endDate !== null && endDate !== undefined && endDate !== '') {
        url = `${url}&start_date=${startDate}&end_date=${endDate}`
    }
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      console.log('applicationsList: ', res.data);
      let data = res?.data;
      setAllApplications(data?.ApplicationData);
      setFilteredApplications(data?.ApplicationData);

    }).catch((err) => {
      console.error('err', err);
    });
  }

  useEffect(() => {

    (async () => {
      await fetchDataList();
    })();

  }, [taLead, recruiter, startDate]);


  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
    const sorted = [...filteredApplications].sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];
      if (valueA < valueB) return direction === 'asc' ? -1 : 1;
      if (valueA > valueB) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredApplications(sorted);
    setSortField(field);
    setSortDirection(direction);
  };

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
    } else {
      return <FaSort />;
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredApplications.slice(indexOfFirstItem, indexOfLastItem);

  const handleDownloadFile = () => {
    // Manually specify columns
    const columns = ['Rank', 'Positions', 'Groups', 'Grades', 'Applications', 'Posted Dates', 'Recruiters'];

    // Manually create data array
    const data = currentItems.map((row) => [
      row?.Rank || '',
      row?.Position || '',
      row?.PositionDepartment || '',
      row?.Grade || '',
      row?.Applications || '',
      row?.Date_Posted || '',
      row?.Recruiter || '',
    ]);

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data]);

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Avg. Applications Per Opening');

    // Download the Excel file
    XLSX.writeFile(wb, 'Average Applications Per Opening.xlsx');
  };

  const handleSearch = (value) => {
    setSearchKey(value);

    if (value) {
      const filtered = allApplications.filter((data) =>
        data.Position.toLowerCase().includes(value.toLowerCase()) ||
        data?.PositionDepartment?.toLowerCase().includes(value.toLowerCase()) ||
        data?.Grade?.toLowerCase().includes(value.toLowerCase()) ||
        data?.Applications?.toLowerCase().includes(value.toLowerCase()) ||
        data?.Date_Posted?.toLowerCase().includes(value.toLowerCase()) ||
        data?.Recruiter?.toLowerCase().includes(value.toLowerCase()) ||
        data?.recruiterDepartment?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredApplications(filtered);
    } else {
      setFilteredApplications(allApplications);
    }
  }

  return (
    <div>

      <div className='d-flex mb-4 '>
        <div className='d-flex align-items-center'>
          <img src={imagePath.ic_application_gray_bg} alt='position' width={24} height={24} />
          <label className='fs-4 fw-bolder ms-2 me-2'>Avg. Applications Per Openings</label>
        </div>
        <div className='ms-auto d-flex align-items-center'>
          <div className='d-flex me-4' style={{ border: 'solid 1px #F57536', borderRadius: '4px', height: '32px' }}>
            <input
              className='search-bar fs-6 ps-2 py-1'
              style={{ width: '16rem' }}
              type='text'
              placeholder='Search'
              value={searchKey}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <img className="search-icon" src={imagePath.ic_search_orange} />
          </div>
          <DateRangePicker onApply={handleDateRangeChange}
            onCancel={handleCancelDatePicker}>
            <img src={imagePath.ic_calendar_orange} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px', cursor: 'pointer' }} />
          </DateRangePicker>
          <img className='ms-4' src={imagePath.ic_download} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px', padding: '5px', cursor: 'pointer' }}
            onClick={() => handleDownloadFile()} title='Download Report' />
          <img className='ms-2' style={{ cursor: 'pointer' }} src={imagePath.ic_refresh_orange} width={24} height={24}
            onClick={() => fetchDataList()} />
        </div>
      </div>

      <div className='table-responsive' style={{ height: '28.75rem' }}>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4 table-borders'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold graycolor bg-light'>
              <th onClick={() => sortByField('Rank')}
                style={{ minWidth: '6rem' }}
                className='align-items-center'
                scope='col'  >
                <label className=' form-label fw-bolder fs-6 me-2 ms-2'>
                  Rank
                </label>
                {renderSortIcon('Rank')}
              </th>
              <th onClick={() => sortByField('Position')}
                style={{ minWidth: '14rem' }}
                className='align-items-center'
                scope='col'  >
                <label className=' form-label fw-bolder fs-6 me-2'>
                  Positions
                </label>
                {renderSortIcon('Position')}
              </th>
              <th onClick={() => sortByField('Grade')}
                style={{ minWidth: '12rem' }}
                className='align-items-center'
                scope='col'  >
                <label className=' form-label fw-bolder fs-6 me-2'>
                  Grades
                </label>
                {renderSortIcon('Grade')}
              </th>
              <th onClick={() => sortByField('Applications')}
                style={{ minWidth: '10rem' }}
                className='align-items-center'
                scope='col'  >
                <label className=' form-label fw-bolder fs-6 me-2'>
                  Applications
                </label>
                {renderSortIcon('Applications')}
              </th>
              <th onClick={() => sortByField('Date_Posted')}
                style={{ minWidth: '10rem' }}
                className='align-items-center'
                scope='col'  >
                <label className=' form-label fw-bolder fs-6 me-2'>
                  {/* Posted Dates */}
                  Posted Time
                </label>
                {renderSortIcon('Date_Posted')}
              </th>
              <th onClick={() => sortByField('Recruiter')}
                style={{ minWidth: '18rem' }}
                className='align-items-center'
                scope='col'>
                <label className=' form-label fw-bolder fs-6 me-2'>
                  Recruiters
                </label>
                {renderSortIcon('Recruiter')}
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>

            {(currentItems && currentItems.length > 0) ? currentItems.map((data, index) => {
              const isEvenRow = index % 2 === 0;
              return (
                <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                  <td>
                    <a href='#' className='text-dark fw-bolder mx-5 text-hover-primary fs-6'>
                      {data?.Rank}{' '}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {data?.Position}
                    </a>
                    <span className='text-left graycolor fw-semibold graycolor d-block fs-7'>
                      {data?.PositionDepartment}
                    </span>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {`${data?.Grade}`}
                    </a>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <span className='badge fbadgetext-seeting badge-light-danger'>{data?.Applications}</span>
                  </td>
                  <td>
                    <a
                      href='#'
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {data?.Date_Posted}{' '}
                    </a>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img
                          className='rounded-circle'
                          style={{ objectFit: 'cover', border: 'solid #F36523 1px', borderRadius: '50%' }}
                          src={data?.recruiterProfile ? data?.recruiterProfile : imagePath.userIcon}
                          alt=''
                        />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-left text-dark fw-bolder my-2 text-hover-primary fs-6'>
                          {data?.Recruiter}
                        </a>
                        <span className='text-left graycolor fw-semibold graycolor d-block fs-7'>
                          {data?.recruiterDepartment}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }) : (<div></div>)}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
        {/* <Pagination>
          {Array.from({ length: Math.ceil(filteredApplications.length / itemsPerPage) }).map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination> */}
      </div>

      <div className='d-flex col-md-12 align-items-center mt-3'>
        <div className='col-md-4'>
          <label>{`Showing ${currentItems ? currentItems.length : 0} of ${allApplications?.length || 0} results.`}</label>
        </div>
        <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
          <CustomPagination
            pageValue={currentPage}
            totalPages={Math.ceil(filteredApplications.length / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </div>

        <div className='col-md-4 '>
          <div className='ms-auto d-flex flex-end align-items-center'>
            <select
              className='me-2 pagination-input'
              style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
              id='selectValues'
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </select>
            <div className='me-2'>
              <label>Items per page</label>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
};

export default ApplicationsPerOpeningTable;