import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import './position.css'
import React, {useEffect, useState, useRef} from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calnedarimage from './images/today_black_24dp.png'
import styled from 'styled-components'
import bottomarrow from './images/Path 38012.png'
import toparrow from './images/Path 38012 (1).png'
import axios from 'axios'
import {useAuth} from '../auth/core/Auth'
import {useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
export const CREATE_POSITION_URL = `${API_URL}/position/create`
export const UPDATE_POSITION_URL = `${API_URL}/position/`
export const GET_POSITIONS_URL = `${API_URL}/position`
export const GET_POC_DETAILS = `${API_URL}/position`
export const GET_ALL_DEPARTMENTS_URL = `${API_URL}/department`

const UpdatePosition = () => {
  const [Departments, setDepartments] = useState([{}])
  const [Department, setDepartment] = useState({})
  const [EndDate, setEndDate] = useState(new Date())
  const navigate = useNavigate()
  const {auth, logout} = useAuth()
  let {id} = useParams()
  const intl = useIntl()
  const [Title, setTitle] = useState('')
  // const [Department, setDepartment] = useState('Not Selected')
  const [positionRemarks, setPositionRemarks] = useState('')
  const [grade, setgrade] = useState('SVP-1 TO SVP')
  const [Location, setLocation] = useState('Islamabad')
  const [AvaliablePositions, setAvaliablePositions] = useState(0)
  const [selectedOptionforDetailsofHiring, setSelectedOptionforDetailsofHiring] = useState('')
  const [selectedOptionforWrittenTest, setSelectedOptionforWrittenTest] = useState('')
  const {currentUser} = useAuth()
  const [dataFetched, setDataFetched] = useState(false)
  const user_id = currentUser?.user_id
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Handle Submit')

    var positionData = {
      title: Title,
      department: Department['department_name'],
      end_date: EndDate,
      status: 'Not Posted',
      //    recuiter_id: "e161189a-f23a-4abc-8fd0-aedb54b47eb0",
      no_of_positions: AvaliablePositions,
      location: Location,
      ad_mode: 'internal',
      hiring_type: selectedOptionforDetailsofHiring,
      test_require: true,
      remarks: positionRemarks,
      poc_id: user_id,
      department_id: Department['department_id'],
    }
    console.log('PositionData', positionData)
    var data = JSON.stringify(positionData)
    const url = UPDATE_POSITION_URL + id
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('res', res)
        navigate('/positions')
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const changePositionRemarks = (newRemarks) => {
    setPositionRemarks(newRemarks)
  }

  const changegrade = (newgrade) => {
    setgrade(newgrade)
  }

  // Handle the change event for the radio buttons
  const handleChangeforDetailsofHiring = (event) => {
    setSelectedOptionforDetailsofHiring(event.target.value)
  }

  const handleChangeforWrittenTest = (event) => {
    setSelectedOptionforWrittenTest(event.target.value)
  }

  const changeLocation = (newlocation) => {
    setLocation(newlocation)
  }

  const incrementAvliablePos = () => {
    setAvaliablePositions(AvaliablePositions + 1)
  }

  const decrementAvliablePos = () => {
    if (AvaliablePositions <= 0) {
      setAvaliablePositions(0)
    } else {
      setAvaliablePositions(AvaliablePositions - 1)
    }
  }

  const changeDepartment = (event) => {
    let selectedKey = JSON.parse(event.target.options[event.target.selectedIndex].getAttribute('id'))
    console.log('SELECTED KEY', selectedKey)
    setDepartment(selectedKey)
  }

  const GetAllDepartments = async () => {
    await axios
      .get(GET_ALL_DEPARTMENTS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        console.log('RESPONSE Departments', resp.data.department)
        setDepartments(resp.data.department)
        //   setPositions(resp.data.positions)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    if (!dataFetched) {
      ;(async () => {
        await GetAllDepartments()
        setDataFetched(true)
      })()
    }

    if (Departments.length > 0) {
      setDepartment(Departments[0])
    }

    return () => {}
  }, [Departments])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Create Position'})}</PageTitle>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-12'>
          <div className='card displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='card'>
                {/* begin::Beader */}
                <div className='card-header border-0 '>
                  <h3 className='card-title align-items-center flex-column'>
                    <span className='card-label text-gray-800 fw-bolder fs-3 '>Update Position Form</span>
                  </h3>

                  {/* <hr className='border-hr'></hr> */}
                </div>

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form onSubmit={handleSubmit}>
                    <div className='form-row d-flex justify-content-spacearound'>
                      <div className=' col-md-12'>
                        <label className='form-label labelse '>Position Title </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-select'
                            placeholder='Enter title'
                            value={Title}
                            onChange={(event) => setTitle(event.target.value)}
                          />
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Grade</label>
                      <div className='me-4'>
                        <select
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-select   w-140px'
                          defaultValue='SVP-1 TO SVP'
                          onChange={(event) => changegrade(event.target.value)}
                        >
                          <option value='SVP-1 TO SVP'>SVP-1 TO SVP</option>
                          <option value='OG-IV to OG-1'>OG-IV to OG-1</option>
                          <option value='AVP & Above'>AVP & Above</option>
                          <option value='In Progress'>Completed</option>
                        </select>
                      </div>{' '}
                    </div>
                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '> Avaliable Positions</label>
                      <div className='me-4 IncrementInputout'>
                        <input
                          style={{margin: '0px 5px 0px 0px', border: 'none'}}
                          type=''
                          className='form-control bg-white'
                          id=''
                          placeholder='3.00'
                          value={AvaliablePositions}
                        />
                        <div className='button-top-and-botom'>
                          {' '}
                          <a onClick={() => incrementAvliablePos()}>
                            <img src={toparrow}></img>
                          </a>
                          <a onClick={() => decrementAvliablePos()}>
                            <img src={bottomarrow}></img>
                          </a>
                        </div>
                      </div>{' '}
                    </div>
                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Location</label>
                      <div className='me-4'>
                        <select
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-select   w-140px'
                          defaultValue='Islamabad'
                          onChange={(event) => changeLocation(event.target.value)}
                        >
                          <option value='Lahore'>Lahore</option>
                          <option value='Islamabad'>Islamabad</option>
                          <option value='Karachi'>Karachi</option>
                          <option value='Rawalpindi'>Rawalpindi</option>
                        </select>
                      </div>{' '}
                    </div>

                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Department</label>
                      <div className='me-4'>
                        <select
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-select  settingpickerwidthadb selectpicker  w-140px'
                          defaultValue=''
                          onChange={(event) => changeDepartment(event)}
                        >
                          {dataFetched === false ? (
                            <option></option>
                          ) : (
                            Departments.map((option) => (
                              <option key={option['department_id']} id={JSON.stringify(option)} value={option['department_name']}>
                                {option['department_name']}
                              </option>
                            ))
                          )}
                        </select>
                      </div>{' '}
                    </div>
                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Details of Hiring</label>
                      <div className='form-row d-flex mt-5 justify-content-spacearound'>
                        <div className='form-group  col-md-3 me-4'>
                          <input
                            type='radio'
                            id='Budgeted'
                            name='options'
                            value='Budgeted'
                            checked={selectedOptionforDetailsofHiring === 'Budgeted'}
                            onChange={handleChangeforDetailsofHiring}
                          />
                          <label htmlFor='Budgeted' style={{margin: '0px 0px 0px 10px'}}>
                            Budgeted
                          </label>
                        </div>
                        <div className='form-group  col-md-3 me-4'>
                          <input
                            type='radio'
                            id='Replacement'
                            name='options'
                            value='Replacement'
                            checked={selectedOptionforDetailsofHiring === 'Replacement'}
                            onChange={handleChangeforDetailsofHiring}
                          />
                          <label htmlFor='Replacement' style={{margin: '0px 0px 0px 10px'}}>
                            Replacement
                          </label>
                        </div>
                        <div className='form-group  col-md-3 me-4'>
                          <input
                            type='radio'
                            id='Non-Budgeted'
                            name='options'
                            value='Non-Budgeted'
                            checked={selectedOptionforDetailsofHiring === 'Non-Budgeted'}
                            onChange={handleChangeforDetailsofHiring}
                          />
                          <label htmlFor='Non-Budgeted' style={{margin: '0px 0px 0px 10px'}}>
                            Non-Budgeted
                          </label>
                        </div>
                      </div>{' '}
                    </div>
                    {/* <div className='form-group mt-10 col-12 me-4'>
                 
                 <label className='form-label labelse '>Written Test Required</label>
                 <div className='form-row d-flex mt-5 justify-content-spacearound'>
                 <div className='form-group  col-md-3 me-4'>
    <input
      type="radio"
      id="No"
      name="optionsForWrittenTest"
      value="No"
      checked={selectedOptionforWrittenTest === "No"}
      onChange={handleChangeforWrittenTest}
    />
<label htmlFor="No" style={{ margin: '0px 0px 0px 10px' }}>No</label>

</div>
<div className='form-group  col-md-3 me-4'>
    <input
      type="radio"
      id="Yes"
      name="optionsForWrittenTest"
      value="Yes"
      checked={selectedOptionforWrittenTest === "Yes"}
      onChange={handleChangeforWrittenTest}
    />
<label htmlFor="Yes" style={{ margin: '0px 0px 0px 10px' }}>Yes</label>

</div>

                 </div>{' '}
              
             </div> */}

                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Last Approved Organisation Date</label>
                      <div className='me-4 w-140px'>
                        <div
                          style={{
                            padding: '10px 10px 10px 10px',
                            border: '1px solid rgb(228 230 239)',
                            height: '40px',
                            color: ' rgb(228 230 239) !important  ',
                            borderRadius: '5px',
                          }}
                          className='d-flex'
                        >
                          {' '}
                          <DatePicker wrapperClassName='mydivdate' selected={EndDate} onChange={(date) => setEndDate(date)} />
                          <img src={calnedarimage}></img>
                        </div>
                      </div>
                    </div>

                    <div className='form-group mt-10 col-12 me-4'>
                      <label className='form-label labelse '>Remarks/Justification</label>
                    </div>
                    <div className='form-row d-flex justify-content-spacearound'>
                      <textarea
                        onChange={(event) => changePositionRemarks(event.target.value)}
                        className='form-control'
                        id='positionRemarks'
                      ></textarea>
                    </div>

                    <button type='submit' className='btn my-10 px-10 bgcolorbuttonpost my-5'>
                      Post
                    </button>
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default UpdatePosition

const CustomDatePickDiv = styled.div`
  background-color: white;
  border: solid 0.1em #ffff;
  border-radius: 0.25em;
  padding: 0.3em 1.6em 0 1.6em;
`
const DatePickerDiv = styled.div`
  position: relative;
`
