import React, { useState, useEffect, useRef } from 'react';
import imagePath from '../../../constants/imagePath';
import AddQuestion from './AddQuestion'; // Import AddQuestion component
import AddVideoQuestion from './AddVideoQuestion'; // Import AddVideoQuestion component
import { ImportPositionForm } from './PopUp';
import axios from 'axios';
import { useAuth } from '../auth/core/Auth';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import UpdateQuestion from './UpdateQuestion';
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import WarningTARemovePopup from '../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup';
import CustomPagination from '../dashboards/components/CustomPagination';

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_QUESTIONS = `${API_URL}/questions`
const DELETE_QUESTION_URL = `${API_URL}/questions/`
const DELETE_MULTIPLE_QUESTION_URL = `${API_URL}/questions/batch-remove`
const UPLOAD_VIDEO_URL = `${API_URL}/videos`
const GET_VIDEOS = `${API_URL}/videos`
const DELETE_VIDEO = `${API_URL}/videos/`

const FileUploader = () => {
    const { auth, currentUser, logout } = useAuth()
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedRows, setSelectedRows] = useState<boolean[]>([]);
    const [selectedQuestions, setSelectedQuestions] = useState<any>([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [showTable, setShowTable] = useState(true); // New state to toggle between table and form
    const [activeHeader, setActiveHeader] = useState('Library'); // for active button
    const [showAddQuestion, setShowAddQuestion] = useState(false); // State to toggle AddQuestion modal
    const [showPopUp, setShowPopUp] = useState(false); // State to toggle AddQuestion modal
    const [showVideoQuestion, setShowVideoQuestion] = useState(false); // State to toggle AddQuestion modal
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [questions, setQuestions] = useState<any>();
    const [totalPages, setTotalPages] = useState(1)
    const [params, setParams] = useState<any>({
        order: 'CREATED_AT',
        sort: 'desc',
        page: 1,
        limit: 10,
        total: 0,
        search: ''
    })
    const [currentQuestion, setCurrentQuestion] = useState<any>();
    const [videoData, setVideoData] = useState<any>();
    const [showUpdateQuestionPopup, setShowUpdateQuestionPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showDeleteQuestionWarning, setShowDeleteQuestionWarning] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [videoFile, setVideoFile] = useState<File | undefined>(undefined);
    const [videoLink, setVideoLink] = useState('');
    const [videoId, setVideoId] = useState('');
    const [currentVideoId, setCurrentVideoId] = useState('');
    const [uploadingVideo, setUploadingVideo] = useState(false);
    const [allVideos, setAllVideos] = useState<any>();
    const [searchText, setSearchText] = useState('');
    const [multipleDelete, setMultipleDelete] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false)
    // const videoRefs = useRef(Array(allVideos && allVideos.length).fill(null));
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number | null>(null);
    const videoRefs = useRef<Array<HTMLVideoElement | null>>([]);

    useEffect(() => {
        videoRefs.current = Array(allVideos?.length || 0).fill(null);
    }, [allVideos]);

    useEffect(() => {
        (() => {
            if (showTable) {
                getAllQuestions();
            } else {
                getAllVideos();
            }

        })();
    }, [params, showTable])

    const getAllQuestions = async () => {
        let data = { ...params }
        const filteredParams = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
        )
        setLoadingTable(true);
        await axios.get(GET_ALL_QUESTIONS, {
            params: filteredParams,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoadingTable(false);
            let pagination = res?.data?.pagination
            if (pagination) {
                if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
                    let paramsData = { ...params }
                    paramsData.page = pagination?.page || 1
                    paramsData.limit = pagination?.limit || 10
                    paramsData.total = pagination?.total || 0
                    setParams(paramsData)
                }
                setTotalPages(pagination?.totalPages || 0)
            }

            setQuestions(res?.data?.data);
        }).catch((error) => {
            setLoadingTable(false);
            displayErrorMessage(error);
        });
    }

    const getAllVideos = () => {
        let data = { ...params }
        const filteredParams = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
        )
        axios.get(GET_VIDEOS, {
            params: filteredParams,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            let pagination = res?.data?.pagination
            if (pagination) {
                if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
                    let paramsData = { ...params }
                    paramsData.page = pagination?.page || 1
                    paramsData.limit = pagination?.limit || 10
                    paramsData.total = pagination?.total || 0
                    setParams(paramsData)
                }
                setTotalPages(pagination?.totalPages || 0)
            }
            setAllVideos(res?.data?.data);
        }).catch((error) => {
            displayErrorMessage(error);
        });
    }

    const deleteMultipleQuestions = () => {
        setShowDeleteQuestionWarning(false);
        if (selectedQuestions === null || selectedQuestions === undefined || selectedQuestions.length === 0) {
            setMessage('Please select questions first and try again.');
            setShowErrorMessage(true);
            return;
        }

        const questionIds = selectedQuestions.join(',');
        let data = {
            questionIds: questionIds
        }

        axios.post(DELETE_MULTIPLE_QUESTION_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setMultipleDelete(false);
            setSelectedQuestions([]);
            getAllQuestions();

        }).catch((error) => {
            displayErrorMessage(error);
        })

    }

    const deleteQuestion = async () => {
        setShowDeleteQuestionWarning(false)
        axios.delete(DELETE_QUESTION_URL + currentQuestion?.question_id, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            setCurrentQuestion(null);
            setMessage('Question deleted successfully.')
            setShowSuccessPopup(true);
            getAllQuestions();
        }).catch((error) => {
            displayErrorMessage(error);
        });
    }

    const displayErrorMessage = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }

    const toggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
    }

    const handleCheckboxChange = (questionId) => {
        let selectedOptions = [...selectedQuestions];
        // Check if questionId is already in the selectedQuestions array
        const index = selectedOptions.indexOf(questionId);
        if (index === -1) {
            // If not present, add it
            selectedOptions.push(questionId);
        } else {
            // If present, remove it
            selectedOptions.splice(index, 1);
        }
        setSelectedQuestions(selectedOptions);
    }

    const handleSelectAll = () => {
        // const updatedSelectedRows = Array(rowCount).fill(!selectAllChecked);
        // setSelectedRows(updatedSelectedRows);
        // setSelectAllChecked(!selectAllChecked);

        if (questions) {
            if (questions.length === selectedQuestions.length) {
                setSelectedQuestions([]);
            } else {
                let list: any = []
                questions.map((question) => {
                    list.push(question?.question_id)
                })
                setSelectedQuestions(list);
            }

        }

    };

    const deleteQuestions = () => {
        setMultipleDelete(true);
        setMessage('Are you sure you want to delete selected questions?');
        setShowDeleteQuestionWarning(true);
    }

    const openAddQuestionModal = () => {
        setShowAddQuestion(true);
    }

    const closeAddQuestionModal = () => {
        setShowAddQuestion(false);
    }

    // for popup
    const openPopUpModal = () => {
        setShowPopUp(true);
    }

    const closePopUpModal = () => {
        setShowPopUp(false);
    }

    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('asc')

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
        setSortField(field)
        setSortDirection(direction)

        let data = { ...params }
        data.order = field
        data.sort = direction === 'asc' ? 'asc' : 'desc'
        setParams(data)
    }

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
        } else {
            return <FaSort />
        }
    }

    const handlePageChange = (page) => {
        // setCurrentPage(page);
        let data = { ...params }
        data.page = page
        setParams(data)
    }
    const handleItemsPerPageChange = (event) => {
        // setItemsPerPage(event.target.value);
        let data = { ...params }
        data.limit = event.target.value
        setParams(data)
    }

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);

        const files = e.dataTransfer.files;

        if (files.length > 0) {
            const file = files[0];
            handleFileUpload(file);
        } else {
            setMessage('File not found, Please try again');
            setShowErrorMessage(true);
        }
    };

    const handleFileUpload = (file) => {
        // e.preventDefault();
        // const file = e.target.files[0];

        const validFormats = ['video/mp4', 'video/avi', 'video/webm', 'video/quicktime', 'video/x-matroska'];
        if (file
            // && validFormats.includes(file.type)
        ) {
            setVideoFile(file);
            uploadQuestionVideo(file);

        } else {
            setMessage('Invalid file. Please select or drop a valid video file (MP4, AVI, WebM, MOV, MKV).');
            setShowErrorMessage(true);
            return;
        }
    }

    const uploadQuestionVideo = (file) => {
        if (!file) {
            setMessage('Video file not found')
            setShowErrorMessage(true);
            return;
        }

        const formData = new FormData();
        // formData.append('file', videoResumeFile, 'video_resume');
        formData.append('file', file, 'Question video');
        setUploadingVideo(true);
        axios.post(UPLOAD_VIDEO_URL, formData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            setUploadingVideo(false);
            setVideoLink(res?.data?.video?.link);
            setVideoId(res?.data?.video?.video_id);
            getAllVideos();
            setMessage('Video uploaded successfully.');
            setShowSuccessPopup(true);
        }).catch((error) => {
            setUploadingVideo(false);
            displayErrorMessage(error);
        });
    }

    const deleteVideo = () => {
        setShowDeleteQuestionWarning(false)

        axios.delete(DELETE_VIDEO + currentVideoId, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            getAllVideos();
        }).catch((error) => {
            displayErrorMessage(error);
        })
    }

    const handleChangeSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        if (value === '' || value === null || value === undefined) {
            let data = { ...params }
            data.search = '';
            setParams(data);
        }

    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    const handleSearch = () => {
        let data = { ...params }
        data.search = searchText;
        setParams(data);
    }

    const handleVideoClick = (index: number) => {
        // Stop the previous video if it's playing
        if (currentVideoIndex !== null && currentVideoIndex !== index) {
            const previousVideo = videoRefs.current[currentVideoIndex];
            if (previousVideo && !previousVideo.paused) {
                previousVideo.pause();
            }
        }

        // Play or pause the clicked video
        const video = videoRefs.current[index];
        if (video) {
            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }
            setCurrentVideoIndex(index);
        }
    };

    return (
        <div className='card-bg shadow-sm p-6 mb-20' >
            <div className='d-flex align-items-center'>
                <h1 style={{ fontSize: '14px' }}>Question Repository</h1>
                <div className='ms-auto d-flex' >
                    <div>
                        <input style={{ padding: '5px', width: '280px', borderRadius: '5px', border: '1px solid #F36523' }}
                            type="search"
                            value={searchText}
                            onChange={handleChangeSearch}
                            onKeyPress={handleKeyPress}
                        />
                        <i style={{ position: 'absolute', marginLeft: '-25px', marginTop: '10px', color: '#F36523', fontSize: '13px', cursor: 'pointer' }}
                            className="fa fa-search" onClick={handleSearch}></i>
                    </div>
                    {showTable && (<div style={{ marginLeft: '20px' }}>
                        <button style={{ width: '120px', cursor: 'pointer', backgroundColor: '#F36523', border: 'none', padding: '5px', color: 'white', borderRadius: '5px', fontFamily: 'Nunito', fontSize: '14px' }}
                            onClick={toggleCheckboxes}>
                            {`${showCheckboxes ? 'Cancel' : 'Select'}`}
                        </button>
                    </div>)}
                    {showCheckboxes ? (
                        <div style={{ marginLeft: '20px' }}>
                            <button style={{ cursor: 'pointer', backgroundColor: '#F36523', border: 'none', width: '120px', padding: '5px', color: 'white', borderRadius: '5px', fontFamily: 'Nunito', fontSize: '14px' }}
                                onClick={deleteQuestions}>Delete</button>
                        </div>
                    ) : (
                        <div style={{ marginLeft: '20px' }}>
                            <button style={{ cursor: 'pointer', backgroundColor: '#F36523', border: 'none', width: '120px', padding: '5px', color: 'white', borderRadius: '5px', fontFamily: 'Nunito', fontSize: '14px' }}
                                onClick={openAddQuestionModal}>Add Question</button>
                        </div>
                    )}

                    <div style={{ marginLeft: '20px' }}>
                        <button style={{ cursor: 'pointer', backgroundColor: '#F36523', border: 'none', width: '120px', padding: '5px', color: 'white', borderRadius: '5px', fontFamily: 'Nunito', fontSize: '14px' }} onClick={openPopUpModal} >Upload CSV File</button>
                        {/* <input type="file" id="upload-btn" name="resume" accept=".pdf,.doc,.docx" style={{ display: 'none' }} /> */}

                    </div>
                    <div style={{ marginLeft: '20px', marginTop: '4px' }}>
                        {/* <i className="bi bi-arrow-clockwise" style={{ fontSize: '24px', color: 'rgb(243, 101, 35)', cursor: 'pointer' }} ></i> */}
                        <img className='cursor-pointer' src={imagePath.ic_refresh_orange}
                            onClick={() => {
                                if (showTable) {
                                    getAllQuestions();
                                } else {
                                    getAllVideos();
                                }
                            }} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: '40px' }}>
                <div style={{ marginRight: '20px' }}>
                    <h1 style={{ fontSize: '14px', cursor: 'pointer', borderBottom: activeHeader === 'Library' ? '2px solid #F36523' : 'none', paddingBottom: '10px' }} onClick={() => { setShowTable(true); setActiveHeader('Library'); }}>Library</h1>
                </div>
                <div >
                    <h1 style={{ fontSize: '14px', cursor: 'pointer', borderBottom: activeHeader === 'Videos' ? '2px solid #F36523' : 'none', paddingBottom: '10px' }} onClick={() => { setShowTable(false); setActiveHeader('Videos'); }}>Videos</h1>
                </div>
            </div>
            <div style={{ minHeight: '64vh' }}>
                {
                    showTable ? (
                        <div style={{ position: 'relative' }}>
                            {loadingTable && (
                                // Show loading icon if isLoading state is true
                                <div
                                    className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                                >
                                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                                </div>
                            )}
                            <div className='ShowQuestionTable mt-10' >
                                <table id="questionTable" style={{ borderCollapse: 'collapse', border: '1px solid #F3F6F9', width: '100%' }}>
                                    <thead style={{ backgroundColor: '#F3F6F9', borderTopLeftRadius: '20px', borderTopRightRadius: '10px' }}>
                                        <tr className='fw-bold graycolor bg-light'>
                                            <th className='align-items-center ' scope='col' style={{ width: '40px', textAlign: 'center', padding: '15px 10px', fontWeight: '600', fontSize: '14px' }}>
                                                {showCheckboxes && (
                                                    <input className='form-check-input'
                                                        type="checkbox"
                                                        onChange={handleSelectAll}
                                                        checked={questions?.length === selectedQuestions?.length}
                                                        style={{ height: '17px', width: '17px', cursor: 'pointer' }}
                                                    />
                                                )}
                                            </th>
                                            <th style={{ width: '70px', textAlign: 'left', padding: '15px 10px' }}
                                                onClick={() => sortByField('question_id')}>
                                                <label className=' form-label fw-bolder fs-6 me-2'>Q.Id</label>
                                                {renderSortIcon('question_id')}
                                            </th>
                                            <th style={{ width: '340px', textAlign: 'left', padding: '15px 10px' }}
                                                onClick={() => sortByField('question_name')}>
                                                <label className=' form-label fw-bolder fs-6 me-2'>Question</label>
                                                {renderSortIcon('question_name')}
                                            </th>
                                            <th style={{ width: '70px', textAlign: 'left', padding: '15px 10px' }}
                                            >
                                                <label className=' form-label fw-bolder fs-6 me-2'>Competency</label>

                                            </th>
                                            <th style={{ width: '140px', textAlign: 'center', padding: '15px 10px' }}>
                                                <label className=' form-label fw-bolder fs-6 me-2'>Link Video</label>
                                            </th>
                                            <th style={{ width: '140px', textAlign: 'center', padding: '15px 10px' }}>
                                                <label className=' form-label fw-bolder fs-6 me-2'>Edit/Delete</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {questions && questions.map((question, index) => {
                                            const isEvenRow = index % 2 === 0
                                            let isSelected = selectedQuestions?.includes(question?.question_id);
                                            return (
                                                <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                                                    <td style={{ width: '40px', textAlign: 'center', padding: '10px 10px' }}>
                                                        {showCheckboxes && (
                                                            <input
                                                                type="checkbox"
                                                                className='form-check-input'
                                                                checked={isSelected}
                                                                onChange={() => handleCheckboxChange(question?.question_id)}
                                                                style={{ cursor: 'pointer', height: '17px', width: '17px' }}
                                                            />
                                                        )}
                                                    </td>
                                                    <td style={{ width: '70px', textAlign: 'left', fontWeight: '600', fontSize: '14px', padding: '10px 10px' }}>
                                                        <div><span>{question?.question_id}</span></div>
                                                    </td>
                                                    <td style={{ width: '340px', textAlign: 'left', padding: '10px 10px' }}>{question?.text}</td>
                                                    <td style={{ width: '70px', textAlign: 'left', padding: '10px 10px' }}>{question?.competency?.name}</td>
                                                    <td style={{ width: '140px', textAlign: 'center', padding: '10px 10px' }}>
                                                        <img
                                                            className="cursor-pointer"
                                                            width={20}
                                                            height={20}
                                                            onClick={() => {
                                                                setCurrentQuestion(question);
                                                                setShowVideoQuestion(true);
                                                            }}
                                                            src={question?.link ? imagePath.ic_camera_orange_holo : imagePath.ic_camera_gray_holo}
                                                        />
                                                    </td>
                                                    <td style={{ width: '140px', textAlign: 'left' }}>
                                                        <div style={{ display: 'flex', textAlign: 'left', justifyContent: 'center' }} >
                                                            <div>
                                                                <img
                                                                    className='cursor-pointer me-2'
                                                                    src={imagePath.ic_edit_circle_orange}
                                                                    width={20}
                                                                    height={20}
                                                                    alt='Edit'
                                                                    style={{ cursor: 'pointer', objectFit: 'cover' }}
                                                                    title='Edit'
                                                                    onClick={() => {
                                                                        setCurrentQuestion(question);
                                                                        setShowUpdateQuestionPopup(true);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div>
                                                                <img
                                                                    className=' cursor-pointer'
                                                                    src={imagePath.ic_delete_orange_cross}
                                                                    width={18}
                                                                    height={18}
                                                                    alt='Delete'
                                                                    style={{ cursor: 'pointer', objectFit: 'contain' }}
                                                                    title='Delete'
                                                                    onClick={() => {
                                                                        setCurrentQuestion(question);
                                                                        setMessage('Are you sure you want to delete the question?')
                                                                        setShowDeleteQuestionWarning(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : (
                        <div style={{ marginTop: '20px' }}>
                            {/* Your form for Videos */}
                            <div style={{ position: 'relative' }}>

                                {uploadingVideo && (
                                    // Show loading icon if isLoading state is true
                                    <div
                                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                                    >
                                        <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                                    </div>
                                )}

                                <div
                                    className="upload-video-container p-4"
                                >
                                    <div
                                        style={{ width: '20%' }}
                                        className="upload-btn ms-10"
                                        onClick={() => {
                                            const uploadInput = document.getElementById("upload-input");
                                            if (uploadInput) {
                                                uploadInput.click();
                                            }
                                        }}
                                    >
                                        <img className=' me-2'
                                            src={imagePath.ic_upload_gray}
                                            width={40}
                                            height={40}
                                            alt='Upload'
                                            style={{ cursor: 'pointer', objectFit: 'cover', color: '#ccc' }}
                                            title='Upload' /> <br />

                                        <span style={{ fontSize: "13px" }}>Upload Video</span>
                                        <input
                                            type="file"
                                            id="upload-input"
                                            accept="video/*"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    handleFileUpload(e.target.files[0]);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="or-text mx-10"
                                        style={{ width: '20%' }}>
                                        <span>or</span>
                                    </div>
                                    <div
                                        className={`drag-drop-container ${dragging ? 'drag-over' : ''}`}
                                        // className="drag-drop-container " 
                                        style={{ height: '120px', width: '60%' }}
                                        onDragEnter={handleDragEnter}
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}>
                                        <img className=' me-2'
                                            src={imagePath.ic_drag_drop}
                                            width={50}
                                            height={50}
                                            alt='Drag & Drop'
                                            style={{ cursor: 'pointer', objectFit: 'cover', marginTop: '1px', marginBottom: '10px' }}
                                            title='Drag & Drop' /> <br />
                                        <span style={{ fontSize: "12px", color: "#414040" }}>Drag & Drop Video</span><br />
                                        <span style={{ fontSize: "10px", color: "#696969", marginBottom: '8px' }}>Accept formats include MP4, AVI, WebM, MOV, MKV</span>
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex flex-wrap align-items-center justify-content-center mt-4'>
                                {allVideos && allVideos.map((video, index) => {
                                    return (
                                        <div key={index} className='mt-8 mx-3' >
                                            <video
                                                // ref={videoRef}
                                                ref={(ref) => (videoRefs.current[index] = ref)}
                                                controls
                                                style={{ width: '320px', height: '100%', borderRadius: '4px' }}
                                                onClick={() => handleVideoClick(index)}
                                            >
                                                <source src={video?.link} type='video/mp4' />
                                                Your browser does not support the video tag.
                                            </video>
                                            <div className="d-flex align-items-center mt-2">
                                                <label className="fw-semibold fs-6 me-2" style={{ maxWidth: '17rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{video?.name}</label>
                                                <div className='ms-auto d-flex align-items-center'>
                                                    <img className="cursor-pointer " style={{ objectFit: 'contain' }} src={imagePath.ic_delete_video}
                                                        onClick={() => {
                                                            // delete video
                                                            setCurrentVideoId(video?.video_id);
                                                            setMessage('Are you sure you want to delete the video?')
                                                            setShowDeleteQuestionWarning(true);
                                                        }}
                                                    />
                                                    <img className="cursor-pointer" style={{ objectFit: 'contain' }} src={imagePath.ic_link_video}
                                                        onClick={() => {
                                                            // open link question popup
                                                            setVideoData(video)
                                                            setShowVideoQuestion(true);

                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    )
                }
            </div>
            <div className='d-flex col-md-12 align-items-center mt-8'>
                <div className='col-md-4'>
                    <label>{`Showing ${showTable ? (questions ? questions.length : 0) : (allVideos ? allVideos.length : 0)} of ${params.total} results.`}</label>
                </div>
                <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                    <CustomPagination
                        pageValue={params.page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>

                <div className='col-md-4 '>
                    <div className='ms-auto d-flex flex-end align-items-center'>
                        <select
                            className='me-2 pagination-input'
                            style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                            id='selectValues'
                            value={params.limit}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </select>

                        <div className='me-2'>
                            <label>Items per page</label>
                        </div>
                    </div>
                </div>
            </div>
            {/* AddQuestion modal */}
            <div style={{ marginTop: '40px', marginLeft: '-140px' }} >
                {showAddQuestion && <AddQuestion onClose={(action) => {
                    closeAddQuestionModal()
                    if (action === 'updateList') {
                        getAllQuestions();
                    }

                }} />}
            </div>

            {/* PopUp modal for CSV  */}

            {showPopUp && <ImportPositionForm onClose={closePopUpModal} />}


            {/* PVideo question modal for Upload video  */}

            {showVideoQuestion && <AddVideoQuestion
                questionData={currentQuestion}
                videoData={videoData}
                onClose={() => {
                    setShowVideoQuestion(false)
                    setCurrentQuestion(null);
                    setVideoData(null);
                }}
                updateQuestionsList={() => {
                    setShowVideoQuestion(false)
                    setCurrentQuestion(null);
                    setVideoData(null);
                    if (showTable) {
                        getAllQuestions();
                    }
                }}
            />}

            {
                showUpdateQuestionPopup &&
                <UpdateQuestion
                    handleClose={() => {
                        setCurrentQuestion(null);
                        setShowUpdateQuestionPopup(false)
                    }}
                    currentQuestion={currentQuestion}
                    updateQuestionsList={() => {
                        setShowUpdateQuestionPopup(false);
                        setCurrentQuestion(null);
                        setMessage('Question updated successfully.');
                        setShowSuccessPopup(true);
                        getAllQuestions();
                    }}
                />
            }
            {
                showSuccessPopup &&
                <SuccessDialog
                    message={message}
                    handleClose={() => setShowSuccessPopup(false)}
                />
            }
            {
                showDeleteQuestionWarning &&
                <WarningTARemovePopup
                    show={true}
                    message={message}
                    handleClose={() => setShowDeleteQuestionWarning(false)}
                    handleYesSure={() => {
                        if (message === 'Are you sure you want to delete the video?') {
                            deleteVideo();
                        } else {
                            if (multipleDelete) {
                                deleteMultipleQuestions();
                            } else {
                                deleteQuestion();
                            }
                        }
                    }}
                />
            }
            {showErrorMessage && <ShowError message={message} handleClose={() => setShowErrorMessage(false)} />}
        </div >
    );
}

export default FileUploader;
