//import liraries
import React, { useEffect, useState } from 'react';
import imagePath from '../../../../../constants/imagePath';
import { getFormattedDate } from '../../../../../constants/CommonFunctions';
import './componentsStyles.css';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import AddUpdateTermsPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateTermsPopup';
import axios from 'axios';
import { useAuth } from '../../../auth/core/Auth';


const API_URL = process.env.REACT_APP_API_URL;
const SAVE_TERMS_CONDITIONS_URL = `${API_URL}/configuration/add-terms-condition`;
const DELETE_TERMS_CONDITIONS_URL = `${API_URL}/configuration/delete-terms-condition`;
// create a component
const TermsAndConditions = ({ configurationId, termsAndConditions, ...props }) => {
    const { currentUser, auth, logout } = useAuth()
    const [currentDate, setCurrentDate] = useState(new Date);
    const [toggleChecked, setToggleChecked] = useState(false);
    const [active, setActive] = useState(false);
    const [termsConditionsList, setTermsConditionsList] = useState<any>([{ i: 0, name: '', description: '', active: false, date: '' }]);
    const [showAddTermsPopup, setShowAddTermsPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [type, setType] = useState('add');
    const [currentObj, setCurrentObj] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {

        if (termsAndConditions) {
            termsAndConditions = JSON.parse(termsAndConditions);
            const initialData = Object.keys(termsAndConditions).map((key) => ({
                i: key,
                name: termsAndConditions[key]['name'],
                description: termsAndConditions[key]['description'],
                active: termsAndConditions[key]['active'],
                date: termsAndConditions[key]['date']
            }));
            setTermsConditionsList(initialData);
        }
    }, [termsAndConditions]);

    const handleToggleChange = () => {
        setToggleChecked(!toggleChecked)
    }

    const handleChangeActive = (isActive, index) => {
        const currentDate = new Date();
        // Format the current date
        const formattedDate = currentDate.toISOString();
        let data = [...termsConditionsList];
        data[index]['active'] = !isActive;
        data[index]['date'] = formattedDate;
        setTermsConditionsList(data);
        updateList(data);
        // setActive(!active);
    }

    const onEdit = (index) => {
        let data = termsConditionsList[index];
        setCurrentObj(data);
        setType('edit');
        setShowAddTermsPopup(true);
    }

    const onDelete = async (index) => {
        if (index >= 0 && index < termsConditionsList.length) {
            const key = termsConditionsList[index].i;

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_TERMS_CONDITIONS_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                let terms = [...termsConditionsList];
                terms.splice(index, 1);
                setTermsConditionsList(terms);


            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }
    }

    const addTerms = (name, description) => {
        // Find the largest "i" value from the list
        const largestI = termsConditionsList.reduce((max, item) => {
            const currentItemI = parseInt(item.i, 10);
            return currentItemI > max ? currentItemI : max;
        }, -1); // Initialize with -1 in case the list is empty

        // Increment the largest "i" value by 1
        const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

        const currentDate = new Date();
        // Format the current date
        const formattedDate = currentDate.toISOString();
        // Create a new object with the incremented "i" value
        const newTermCondition = {
            "i": newI,
            "name": name, // Set the initial value as desired
            "description": description,
            "active": false,
            "date": formattedDate
        };

        // termsAndConditions.push(newTermCondition);
        // Add the new object to the list
        // setTermsConditionsList((prevFields) => [...prevFields, newTermCondition]);
        let data = [...termsConditionsList];
        data.push(newTermCondition)
        updateList(data);
    }

    const updateList = async (list) => {
        // Create the data object with the desired structure
        const data = {
            term_condition: {},
            configuration_id: configurationId,
        };

        // Convert the array to an object with keys as "i" and values as "data"
        list.forEach((item) => {
            data.term_condition[item.i] = {
                "name": item.name,
                "description": item.description,
                "active": item.active,
                "date": item.date
            };
        });

        await axios.post(SAVE_TERMS_CONDITIONS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            // fetch updated data
            // getConfigurations();
            setTermsConditionsList(list);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    return (
        <div>
            <div className='card-bg shadow-sm mt-12 py-4 position-relative'>
                <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Terms And Conditions</label>
                    <div className='ms-auto'>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            onClick={() => {
                                setType('add');
                                setShowAddTermsPopup(true);
                            }}>Add New
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-wrap mt-6 px-7 mb-4' style={{ height: '26rem', overflow: 'auto' }}>
                    {termsConditionsList ? termsConditionsList.map((data, index) => {
                        console.log('termsData: ', data);
                        return (
                            <div key={index} className='col-lg-4 col-md-6 col-sm-12 px-2 mb-4' style={{ position: 'relative', zIndex: '1' }}>
                                <div className='card-bg shadow-sm py-4' style={{ zIndex: '10', minWidth: '14rem' }}>
                                    <div className='d-flex align-items-center px-4'>
                                        <div className='d-flex align-items-center'>
                                            <img src={imagePath.ic_calendar_orange} title='calendar-icon' />
                                            <label className='fs-8 fw-regular ms-2 light-txt' style={{ minWidth: '10rem' }}>{getFormattedDate(data?.date ? data?.date : currentDate)}</label>
                                        </div>
                                        <div className='ms-auto d-flex align-items-center' style={{ position: 'relative', zIndex: '1' }}>
                                            <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={20} height={20} title='Edit'
                                                onClick={() => onEdit(index)} />
                                            <img style={{ cursor: 'pointer' }} src={imagePath.ic_delete_orange_cross} width={18} height={18} title='Delete'
                                                onClick={() => onDelete(index)} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div>
                                            <label className='fs-5 fw-bolder card-title mt-3'>{data?.name ? data?.name : 'Terms and conditions'}</label>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-4'>
                                        <label className='me-4 fs-7 light-txt fw-bolder' style={{ paddingLeft: '1.2rem' }}>Active</label>
                                        <label className="toggle-switch-small">
                                            <input type="checkbox" checked={data?.active} onChange={() => handleChangeActive(data?.active, index)} />
                                            <span className="toggle-switch-small-slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>

                {/* Positioning the image in the bottom right corner */}
                <div className='position-absolute bottom-0 end-0'>
                    <img src={imagePath.ic_terms_conditions_light} style={{ width: '260px', height: '260px', opacity: '0.1', zIndex: '0' }} />
                </div>
            </div>

            {showAddTermsPopup && (
                <AddUpdateTermsPopup
                    handleClose={() => setShowAddTermsPopup(false)}
                    handleAddTerms={(name, description) => {
                        setShowAddTermsPopup(false);
                        addTerms(name, description);
                    }}
                    editTerms={(termsData) => {
                        const currentDate = new Date();
                        // Format the current date
                        const formattedDate = currentDate.toISOString();
                        setShowAddTermsPopup(false);
                        let list = [...termsConditionsList];
                        list[termsData.i]['name'] = termsData.name;
                        list[termsData.i]['description'] = termsData.description;
                        list[termsData.i]['active'] = termsData.active;
                        list[termsData.i]['date'] = formattedDate;
                        updateList(list);
                    }}
                    type={type}
                    data={currentObj}
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};
//make this component available to the app
export default TermsAndConditions;
