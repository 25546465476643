//import liraries
import React from 'react';
import { LinearProgress } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './LoadingStyle.css';

type Props = {
    message: string
  }
// create a component
const LoaderView = ({ message }: Props) => {
    return (
        <div className='loader-container'>
          <div className='request-approvals-tile-final-step-spinner loader-desc-container'>
            <FontAwesomeIcon icon={faSpinner} spin color='#F36523' size='3x' />
            <h1 className='mt-2' style={{color:'#484848', textAlign:'center'}}>{message}</h1>
          </div>
        </div>
      );
};

//make this component available to the app
export default LoaderView;
