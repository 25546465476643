//import liraries
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/core/Auth';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import imagePath from '../../../../../constants/imagePath';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import AddUpdateProgram from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateProgram';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_PROGRAM_URL = `${API_URL}/configuration/delete-specialization`;
const SAVE_PROGRAMS_URL = `${API_URL}/configuration/add-specialization`;

// create a component
const Specialization = ({ configurationId, specialization, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [programsList, setProgramsList] = useState<any>([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showAddProgramPopup, setShowAddProgramPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [type, setType] = useState('add');
    const [currentObj, setCurrentObj] = useState<any>();

    useEffect(() => {

        if (specialization) {
            specialization = JSON.parse(specialization);
            const initialData = Object.keys(specialization).map((key) => ({
                i: key,
                data: specialization[key]
            }));
            setProgramsList(initialData);
        }
    }, [specialization]);

    const onEdit = (index) => {
        let data = programsList[index];
        setCurrentObj(data);
        setType('edit');
        setShowAddProgramPopup(true);
    }

    const onDelete = async (index) => {
        if (index >= 0 && index < programsList.length) {
            const key = programsList[index].i;

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_PROGRAM_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                let data = [...programsList];
                data.splice(index, 1);
                setProgramsList(data);

                setMessage('Programs / Specialization Deleted Successfully!');
                setShowSuccessPopup(true);

            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }
    }

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
        const sorted = [...programsList].sort((a, b) => {
            const valueA = a[field];
            const valueB = b[field];
            if (valueA < valueB) return direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setProgramsList(sorted);
        setSortField(field);
        setSortDirection(direction);
    };

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
        } else {
            return <FaSort />;
        }
    };

    const addProgram = (value) => {
        // Find the largest "i" value from the list
        const largestI = programsList.reduce((max, item) => {
            const currentItemI = parseInt(item.i, 10);
            return currentItemI > max ? currentItemI : max;
        }, -1); // Initialize with -1 in case the list is empty

        // Increment the largest "i" value by 1
        const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

        // Create a new object with the incremented "i" value
        const newProgram = {
            "i": newI,
            "data": value // Set the initial value as desired
        };

        let data = [...programsList];
        data.push(newProgram);
        updateList(data);
        // Add the new object to the list
        // setProgramsList((prevFields) => [...prevFields, newProgram]);
    }

    const updateList = async (list) => {
        const data = {
            specialization: {},
            configuration_id: configurationId,
        };
        // Convert the array to an object with keys as "i" and values as "data"
        list.forEach((item) => {
            data.specialization[item.i] = item.data;
        });

        setDisabled(true);
        await axios.post(SAVE_PROGRAMS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            // fetch updated data
            // getConfigurations();
            setDisabled(false);
            setProgramsList(list);
            setMessage('Programs / Specialization Updated Successfully!');
            setShowSuccessPopup(true);
            
        }).catch((error) => {
            setDisabled(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative'>
            <div className='d-flex align-items-center px-8'>
                <label className='form-label fs-4 fw-bolder card-heading'>Programs / Specialization</label>
                <div className='ms-auto'>
                    <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                        onClick={() => {
                            setType('add');
                            setShowAddProgramPopup(true);
                        }}>Add New</div>
                </div>
            </div>

            <div className="table-responsive mx-8 mt-6" style={{ height: '26rem', position: 'relative', zIndex: '1' }}>
                <table className="table align-middle gs-0 gy-4 table-borders">
                    <thead>
                        <tr className='fw-bold graycolor bg-light'>
                            <th onClick={() => sortByField('i')}
                                className='align-items-center ps-4'
                                scope='col'
                                style={{ width: '10%', textAlign: 'center' }}
                            >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    No
                                </label>
                                {renderSortIcon('i')}
                            </th>
                            <th onClick={() => sortByField('data')}
                                className='align-items-center'
                                scope='col'
                                style={{ width: '75%' }}
                            >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Title
                                </label>
                                {renderSortIcon('data')}
                            </th>
                            <th style={{ width: '15%', textAlign: 'center' }}>
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Action
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {programsList ? programsList.map((item, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#FCFCFC' : 'white', borderBottom: '1px solid #EDF2F9' }}>
                                <td className=' form-label fs-6 ' style={{ textAlign: 'center' }}>{item.i}</td>
                                <td className=' form-label fs-6'>{item.data}</td>
                                <td >
                                    {/* <button className="btn btn-primary me-2" onClick={() => onEdit(index)}>Edit</button> */}
                                    {/* <button className="btn btn-danger" onClick={() => onDelete(index)}>Delete</button> */}
                                    <div className='ms-auto d-flex align-items-center me-4'
                                        style={{ justifyContent: 'center', position: 'relative', zIndex: '1' }}>
                                        <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={20} height={20} title='Edit'
                                            onClick={() => onEdit(index)} />
                                        <img style={{ cursor: 'pointer' }} src={imagePath.ic_delete_orange_cross} width={18} height={18} title='Delete'
                                            onClick={() => onDelete(index)} />
                                    </div>
                                </td>
                            </tr>
                        )) : (<div></div>)}
                    </tbody>
                </table>
            </div>
            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_specialization_tag} style={{ width: '260px', height: '260px', opacity: '0.08', zIndex: '0' }} />
            </div>

            {showAddProgramPopup && (
                <AddUpdateProgram
                    handleClose={() => setShowAddProgramPopup(false)}
                    handleAddProgram={(value) => {
                        setShowAddProgramPopup(false);
                        addProgram(value);
                    }}
                    editProgram={(program) => {

                        setShowAddProgramPopup(false);
                        let list = [...programsList];
                        list[program.i]['data'] = program.data;
                        updateList(list);
                        // setProgramsList(list);
                    }}
                    type={type}
                    data={currentObj}
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );

};

//make this component available to the app
export default Specialization;
