//import liraries
import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import './users.css'
import CreateUserPopup from '../../../_metronic/partials/modals/JobFunnelsModels/CreateUserPopup'
import {useAuth} from '../auth'
import {useNavigate} from 'react-router-dom'
import {Link} from 'react-router-dom'
import axios from 'axios'
import AdminUserCard from '../../../_metronic/partials/content/cards/AdminUserCard'
import userIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
// import icArchive from '../images/ic_archive.png';
import icArchive from '../Setting/images/ic_archive.png'
import icEdit from '../Setting/images/ic_edit.png'
import WarningTARemovePopup from '../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import UserRemovePopup from '../../../_metronic/partials/modals/JobFunnelsModels/UserRemovePopup'
import ArchivedUsers from './ArchivedUsers'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'
import imagePath from '../../../constants/imagePath'
import CustomPagination from '../dashboards/components/CustomPagination'
import {FaSort, FaSortDown, FaSortUp} from 'react-icons/fa'
import clsx from 'clsx'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
// import {data} from '../dashboards/components/InsightsCard'
import ActiveUsers from './ActiveUsers'
import DummyCharts from '../analytics/DummyCharts'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_EMPLOYEES_URL = `${API_URL}/auth/user-list`
const GET_ALL_ADMINS_URL = `${API_URL}/auth/user-list`
const REMOVE_ADMIN_ROLE_URL = `${API_URL}/auth/update-admin`
const DELETE_USER_URL = `${API_URL}/auth/delete-employee/`
const DELETE_AND_REPLACE_USER_URL = `${API_URL}/auth/delete-employee`
const GET_ALL_GROUPS = `${API_URL}/group`

// create a component
const Users = () => {
  const {currentUser, auth, logout} = useAuth()
  const intl = useIntl()
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [createUpdate, setCreateUpdate] = useState('create')
  const [allUsers, setAllUsers] = useState<any>([])
  const [allAdmins, setAllAdmins] = useState<any>([])
  const [overbin, setOverbin] = useState(false)
  const [currentUserId, setCurrentUserId] = useState('')
  const [user, setUser] = useState<any>()
  const [showWarning, setShowWarning] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showUserReplace, setShowUserReplace] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [message, setMessage] = useState('')
  const [activeUsersTab, setActiveUsersTab] = useState(true)
  // const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // const [itemsPerPage, setItemsPerPage] = useState(10)
  // const [orderBy, setOrderBy] = useState('updated_at')
  // const [sortBy, setSortBy] = useState('DESC')
  // const [group, setGroup] = useState('')
  // const [role, setRole] = useState('')
  // const [search, setSearch] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [roleMenuOpen, setRoleMenuOpen] = useState(false)
  const [groupMenuOpen, setGroupMenuOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [allGroups, setAllGroups] = useState<any>([])
  const [params, setParams] = useState<any>({
    status: 'active',
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    group: '',
    role: '',
    search: '',
  })
  const [selectedFilters, setSelectedFilters] = useState<any>([])

  const allRoles = ['TA Head', 'TA Lead', 'POC', 'Recruiter', 'Employee']

  const navigate = useNavigate()

  const updateUsersList = async (successMessage) => {
    setShowCreateUser(false)
    setUser(false)
    setCreateUpdate('')
    setMessage(successMessage)
    setShowSuccessPopup(true)
    // update admin and all users list
    getAllAdmins()
    // getAllEmployees(currentPage)
    getAllEmployees(params.page)
  }

  const getAllAdmins = async () => {
    await axios
      .get(GET_ALL_ADMINS_URL, {
        params: {
          role: 'masterRecruiter',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let admins = res?.data?.data
        if (admins) {
          setAllAdmins(admins)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }
  const getAllEmployees = async (page) => {
    let data = {...params}
    let currentRole = data.role.toLowerCase().replace(/ /g, '_')
    currentRole = currentRole.replace(/employee/g, 'none')
    data.role = currentRole
    // let currentRole = params.role.toLowerCase().replace(/ /g, '_')
    // currentRole = currentRole === 'employee' ? 'none' : currentRole
    // const params = {
    //     status: 'active',
    //     order: orderBy,
    //     sort: sortBy,
    //     page,
    //     limit: itemsPerPage, // Number of users per page
    //     group: group,
    //     role: currentRole,
    //     search: search,
    // }
    // Filter out empty, undefined, or null values from params
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    await setLoadingTable(true)
    await axios
      .get(GET_ALL_EMPLOYEES_URL, {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoadingTable(false)
        let data = res?.data
        let pagination = data?.pagination

        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = {...params}
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }

        // setCurrentPage(pagination?.page || 1)
        setTotalPages(pagination?.totalPages || 0)
        // setItemsPerPage(pagination?.limit || 10)
        let users = data?.data
        if (users) {
          setAllUsers(users)
        } else {
          setAllUsers([])
        }
      })
      .catch((error) => {
        setLoadingTable(false)
        handleErrors(error)
      })
  }

  // Fetching list of groups
  const getGroups = async () => {
    try {
      const response = await axios.get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })

      setAllGroups(response.data.data)
    } catch (error) {
      // Consolidated error handling
      handleErrors(error)
    }
  }

  const handleErrors = (error) => {
    if (error.response) {
      const {status, data} = error.response

      if (status === 400) {
        setMessage(data.message)
      } else if (status === 401 && data.name === 'TokenExpiredError') {
        logout()
      } else if (data.error) {
        if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(data.error.message)
        }
      } else {
        setMessage('An unknown error occurred')
      }
    } else {
      setMessage(error.message)
    }

    setShowErrorMessage(true)
  }

  useEffect(() => {
    ;(async () => {
      setRoleMenuOpen(false)
      setGroupMenuOpen(false)
      await getAllAdmins()
      if (activeUsersTab) {
        // await getAllEmployees(currentPage)
        await getAllEmployees(params.page)
      }
      if (allGroups === null || allGroups === undefined || allGroups.length === 0) {
        getGroups()
      }
    })()
  }, [activeUsersTab, params])

  const editUser = (event, user) => {
    event.preventDefault()
    setUser(user)
    setCreateUpdate('update')
    setShowCreateUser(true)
  }

  const deleteAdmin = async (userId) => {
    console.log('adminId: ', userId)
    let data = {
      user_id: userId,
    }
    await axios
      .post(REMOVE_ADMIN_ROLE_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data

        // update admin and all users list
        getAllAdmins()
        // getAllEmployees(currentPage);
        getAllEmployees(params.page)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const deleteAndReplace = async (userId, replacementUserId) => {
    setShowUserReplace(false)
    const data = {
      user_id: userId,
      new_user: replacementUserId,
    }

    console.log('userReplacementRequestData: ', data)

    await axios
      .post(DELETE_AND_REPLACE_USER_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        updateUsersList('User has been replaced successfully')
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const deleteUser = async () => {
    const URL = DELETE_USER_URL + currentUserId
    let data = {
      user_id: currentUserId,
    }
    await axios
      .post(DELETE_USER_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        updateUsersList('User has been archived successfully')
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const ChangeInActiveIndex = (ActiveState) => {
    if (ActiveState === true) {
      // setCompetenciesButton('individualCompetency')
      setActiveUsersTab(true)
    } else if (ActiveState === false) {
      setActiveUsersTab(false)
      // setCompetenciesButton('groupCompetency')
    }
  }

  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...allUsers].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    // setAllUsers(sorted);
    setSortField(field)
    setSortDirection(direction)

    // setOrderBy(field)
    // setSortBy(direction === 'asc' ? 'ASC' : 'DESC')
    let data = {...params}
    data.order = field
    data.sort = direction === 'asc' ? 'ASC' : 'DESC'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    let data = {...params}
    data.page = page
    setParams(data)
  }

  const handleSearchKeyChange = (value) => {
    setSearchKey(value)
    // if the value of searchKey is empty set the value of search as empty too
    if (!value) {
      // setSearch('')
      let data = {...params}
      data.search = ''
      setParams(data)
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = {...params}
    if (searchKey && searchKey !== data.search) {
      // setSearch(searchKey)
      data.search = searchKey
      data.page = 1
      setParams(data)
    } else {
      // setSearch('')
      data.search = ''
      data.page = 1
      setParams(data)
    }
  }

  const toggleMenu = () => {
    setRoleMenuOpen(!roleMenuOpen)
  }
  const toggleGroupMenu = () => {
    setGroupMenuOpen(!groupMenuOpen)
  }

  const filterRoleChange = (roleValue) => {
    let data = {...params}
    if (data.role === roleValue) {
      // setRole('');
      data.role = ''
      setParams(data)
    } else {
      // setRole(roleValue)
      data.role = roleValue
      setParams(data)
    }
  }

  const filterGroupChange = (groupValue) => {
    let data = {...params}
    if (data.group === groupValue) {
      // setGroup('')
      data.group = ''
      setParams(data)
    } else {
      // setGroup(groupValue)
      data.group = groupValue
      setParams(data)
    }
  }

  const filterChange = (filterValue, type) => {
    setRoleMenuOpen(false)
    setGroupMenuOpen(false)
    // Check if data already exists in selectedFilters
    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)

    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, {type: type, value: filterValue}])
    }
  }

  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    filterItems()
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    if (roleMenuOpen) {
      setRoleMenuOpen(false)
    }
    if (groupMenuOpen) {
      setGroupMenuOpen(false)
    }
    // Extract role and group values from selectedFilters array
    const roles = selectedFilters.filter((filter) => filter.type === 'role').map((filter) => filter.value)
    const groups = selectedFilters.filter((filter) => filter.type === 'group').map((filter) => filter.value)

    // Join role and group values into comma-separated strings
    const rolesString = roles.join(',')
    const groupsString = groups.join(',')

    // Update params state with new role and group values
    setParams((prevParams) => ({
      ...prevParams,
      role: rolesString,
      group: groupsString,
    }))
  }

  const handleItemsPerPageChange = (event) => {
    let data = {...params}
    data.limit = event.target.value
    setParams(data)
  }

  const resetFilters = async () => {
    let data = {...params}
    data.order = 'updated_at'
    data.sort = 'DESC'
    data.page = 1
    data.limit = 10
    data.group = ''
    data.role = ''
    data.search = ''
    await setParams(data)

    getAllEmployees(params.page)
  }

  return (
    <>
      <div>
        <PageTitle breadcrumbs={[]}>Users</PageTitle>
        <div className='col-md-12 dashboard-top'>
          <h3>
            <i className='bi bi-house text-white' style={{fontSize: '1.5rem'}}></i>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/dashboard' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              My Dashboard
            </Link>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/users' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              Users
            </Link>
          </h3>
        </div>
        <div style={{textAlign: 'right', marginTop: '-114px'}} className='mb-20'>
          <button
            className='add_user_btn fs-6 d-flex align-items-center shadow-sm'
            style={{marginLeft: 'auto'}}
            type='button'
            onClick={() => {
              setCreateUpdate('create')
              setShowCreateUser(true)
            }}
          >
            <i className='bi bi-plus me-1' style={{color: '#F36523', fontSize: '2rem'}} />
            Add New User
          </button>
        </div>
        <div className='mt-20'>
          <div className='card-bg shadow-sm '>
            <label className='form-label fs-4 fw-bolder px-6 pt-6'>All Admins</label>
            {/* show list of admins */}
            <div className='mt-6 d-flex justify-content-center py-3 ' style={{flexWrap: 'wrap'}}>
              {allAdmins &&
                allAdmins.map((admin, index) => (
                  <div key={index} className=' mb-8 mx-4'>
                    <AdminUserCard
                      admin={admin}
                      deleteAdmin={() => {
                        deleteAdmin(admin?.user_id)
                      }}
                      showDeleteIcon={allAdmins.length === 1 || admin?.user_id === currentUser?.user_id ? false : true}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* tabs view */}
          <div className='d-flex flex-wrap  flex-stack mt-8'>
            <div className='heading-competency d-flex mx-auto  flex-wrap my-2'>
              <div className=' d-flex justify-content-center gy-5 gx-xl-8'>
                <button
                  className={`${
                    activeUsersTab === true
                      ? ' Onclicked-Active-Buttons fw-bolder fs-4   btn-lg  '
                      : ' Notclicked-Active-Buttons  fw-bolder fs-4   btn-lg '
                  } `}
                  type='button'
                  onClick={() => ChangeInActiveIndex(true)}
                >
                  Active Users
                </button>
                <button
                  className={`${
                    activeUsersTab === false
                      ? ' Onclicked-Active-Buttons borderradiusofRectuirese fw-bolder fs-4   btn-lg  '
                      : ' Notclicked-Right-Buttons  fw-bolder fs-4   btn-lg '
                  } `}
                  type='button'
                  onClick={() => ChangeInActiveIndex(false)}
                >
                  Archived Users
                </button>
              </div>
            </div>
          </div>

          {activeUsersTab ? <ActiveUsers /> : <ArchivedUsers />}
        </div>
      </div>

      {showCreateUser && (
        <CreateUserPopup
          show={true}
          createUpdate={createUpdate}
          user={user}
          handleClose={() => setShowCreateUser(false)}
          updateUsersList={updateUsersList}
        />
      )}

      {showWarning && (
        <WarningTARemovePopup
          show={true}
          message={message}
          handleClose={() => {
            setMessage('')
            setCurrentUserId('')
            setShowWarning(false)
          }}
          handleYesSure={() => {
            setMessage('')
            setCurrentUserId('')
            setShowWarning(false)
            deleteUser()
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {showUserReplace && (
        <UserRemovePopup show={true} handleClose={() => setShowUserReplace(false)} handleSubmit={deleteAndReplace} user={user} />
      )}
      {showSuccessPopup && <SuccessDialog handleClose={() => setShowSuccessPopup(false)} message={message} />}

      {/* <DummyCharts /> */}
    </>
  )
}

export default Users
