//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import imagePath from '../../../../constants/imagePath';
import { getFormattedDate } from '../../../../constants/CommonFunctions';

type Props = {
    show: boolean
    reason: any
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AdvertisementRejectionCommentsPopup = ({ show, reason, handleClose }: Props) => {
    const [user, setUser] = useState<any>();
    const [title, setTitle] = useState('');
    const [comments, setComments] = useState<any>();
    useEffect(() => {
        (() => {
            if (reason) {
                let authorityData = reason?.authority;
                setUser(authorityData?.user);
                setTitle(authorityData?.title);
                setComments(reason?.comments);
            }
        })();
    }, [reason]);

    return createPortal(

        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h2 className='fw-bolder mx-auto mt-10 px-18' style={{ textAlign: 'center' }}>Reason for Rejection</h2>
                            <div className='d-flex align-items-center mt-8 px-18'>
                                <img className='me-2' src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon} width={32} height={32} style={{ borderRadius: '15px', objectFit: 'contain' }} />
                                <div className='d-flex flex-column'>
                                    <label className='fs-5 fw-bolder'>{user?.fullName}</label>
                                    <label className='fs-6 fw-semibold'>{title}</label>
                                    <label className='fs-6 fw-semibold'>{reason?.date ? getFormattedDate(reason?.date) : ''}</label>
                                </div>
                            </div>
                            <h3 className='fw-bolder fs-5 mt-8 px-18' >
                                Comments
                            </h3>
                            <p className='fw-semibold fs-6 mt-2 px-18' style={{ color: '#475467' }} >
                                {comments}
                            </p>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button
                                            onClick={handleClose}
                                            type='button'
                                            className='btn text-white px-10 candi-btn2 fw-bolder'
                                        >
                                            Close
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default AdvertisementRejectionCommentsPopup;
