/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth/core/Auth'

import './CreateCompetency.css'
import addcompetenctimage from '../../images/971 [Converted].png'

import imageforrotate from '../images/rotate@2x.png'
import level1image from '../../../../app/modules/Setting/images/level1.png'
import level2image from '../../../../app/modules/Setting/images/level2.png'
import level3image from '../../../../app/modules/Setting/images/level3.png'
import level4image from '../../../../app/modules/Setting/images/level4.png'
import level5image from '../../../../app/modules/Setting/images/level5.png'

// import {json} from 'stream/consumers'
// import {type} from 'os'
import axios from 'axios'
import LoaderView from '../JobFunnelsModels/LoaderView'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const UPDATE_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/`
const GET_CREATED_LEVELS_URL = `${API_URL}/level/get-level`
type Props = {
  show: boolean
  handleClose: (id, name, description, behaviors) => void
  id
  name
  description
  level
  behaviours
  TotalIndivualCompetencies
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateIndividualCompetency = ({show, id, name, behaviours, description, level, handleClose, TotalIndivualCompetencies}: Props) => {
  const {auth} = useAuth()
  const [loading, setLoading] = useState(false)
  const [totalLevel, setTotalLevel] = useState(0)
  const [dataFetched, setDataFetched] = useState(false)
  const [competencyName, setCompetencyName] = useState('')
  const [competencyDescription, setCompetencyDescription] = useState('')
  const [levelsData, setLevelsData] = useState([
    {
      level: '',
      behaviors: [
        {
          id: 0,
          name: '',
        },
      ],
    },
  ])

  useEffect(() => {
    setCompetencyName(name)
    setCompetencyDescription(description)
  }, [])

  const getLevels = async () => {
    await axios
      .get(GET_CREATED_LEVELS_URL)
      .then((resp) => {
        setTotalLevel(resp.data.level.level_no)
        const data = JSON.parse(resp.data.level.level_data)
        const oldLevels = JSON.parse(behaviours)
        let fields: any = []
        data.map((item, index) => {
          let oldLevel = oldLevels[index]
          let levelBehaviors = [{id: 0, name: ''}]
          if (oldLevel) {
            levelBehaviors = oldLevel.behaviors
          }
          let newField = {
            level: Object.keys(item)[0],
            behaviors: levelBehaviors,
          }
          fields.push(newField)
        })
        setLevelsData(fields)
        setDataFetched(true)
      })
      .catch((error) => {
        // handle the error
        console.log('ERROR', error)
      })
  }

  useEffect(() => {
    if (!dataFetched) {
      ;(async () => {
        getLevels()
      })()
    }
  }, [levelsData])

  const handleSubmit = async () => {
    // e.preventDefault()
    setLoading(true)
    var levelBehaviour: any = []

    const behavioursUpdated: any = []
    for (let i: any = 0; i < levelsData.length; i++) {
      let behaviors = levelsData[i].behaviors
      let obj = {}
      for (let j = 0; j < behaviors.length; j++) {
        obj[j] = behaviors[j].name
      }
      let obj2 = {}
      obj2['level_' + eval(i + 1)] = obj
      behavioursUpdated.push(obj2)
    }

    const name = competencyName
    const description = competencyDescription
    const total_competencies = TotalIndivualCompetencies
    const behaviors = JSON.stringify(levelsData)
    const total_behaviors = behaviours.length
    const type = 'Individual'
    const icon = 'abc'

    const UpdateData = {
      name,
      description,
      level,
      type,
      total_competencies,
      total_behaviors,
      competencies: '',
      behaviors,

      icon,
    }

    var data = JSON.stringify(UpdateData)

    const url = UPDATE_INDIVIDUAL_COMPETENCY_URL + id
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        console.log('res', res)
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })

    handleClose(id, name, description, levelsData)
  }

  const handleNameChange = (e) => {
    setCompetencyName(e.target.value)
  }
  const handleDescriptionChange = (e) => {
    setCompetencyDescription(e.target.value)
  }

  const handleFormChange = (mainIndex, index, event) => {
    let arr: any = [...levelsData]
    let obj: any = arr[mainIndex].behaviors[index]
    obj['name'] = event.target.value

    arr[mainIndex].behaviors[index] = obj
    setLevelsData(arr)
  }

  const addFields = (index) => {
    if (levelsData[index].behaviors.length < 7) {
      let arr: any = [...levelsData]
      let behaviors = arr[index].behaviors
      let newField = {id: behaviors.length, name: ''}
      behaviors.push(newField)
      arr[index].behaviors = behaviors
      setLevelsData(arr)
    }
  }
  const removeButton = (index, mainIndex) => {
    let data = [...levelsData]
    let behaviors = data[mainIndex].behaviors
    behaviors.splice(index, 1)
    data[mainIndex].behaviors = behaviors
    setLevelsData(data)
  }

  const getImage = (index) => {
    switch (index) {
      case 0: {
        return imagePath.ic_level_1
      }
      case 1: {
        return imagePath.ic_level_2
      }
      case 2: {
        return imagePath.ic_level_3
      }
      case 3: {
        return imagePath.ic_level_4
      }
      case 4: {
        return imagePath.ic_level_5
      }
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      backdrop={true}
    >
      <>
        <div className=' create-competency-header '>
          <div className='modal-header  create-competency-header'>
            <h2></h2>
            {/* begin::Close */}
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              onClick={() => {
                handleClose(id, name, description, levelsData)
              }}
            >
              <img alt='' src={imagePath.ic_cross_white} />
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column no-header-model'>
            <img alt='' className='addcompetenctimage' src={addcompetenctimage}></img>
            <h3 className='fw-bolder text-white my-5'>Update Competency </h3>
          </div>
        </div>

        <div>
          <div>
            {/* begin::Beader */}

            <div className='p-8'>
              <form
                // onSubmit={handleSubmit}
                className='d-flex flex-column justify-content-center'
              >
                <div className='form-row d-flex flex-column justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Name</label>
                      <input
                        style={{height: '56px'}}
                        type=''
                        className='form-control bg-white'
                        id='competencyname'
                        placeholder='Communication'
                        autoComplete='off'
                        value={competencyName}
                        onChange={(event) => handleNameChange(event)}
                      />
                    </div>
                  </div>
                  <div className='form-group mt-5 col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Description</label>
                      <textarea
                        style={{height: 'auto', maxHeight: '140px', resize: 'vertical'}}
                        className='form-control bg-white'
                        id='competencydescription'
                        placeholder='Type Competency (e.g: Data Analyst)'
                        value={competencyDescription}
                        onChange={(event) => handleDescriptionChange(event)}
                        autoComplete='off'
                        rows={5}
                        // maxLength={1000}
                      />
                    </div>
                  </div>

                  <div className='mt-7' style={{width: '101%', marginLeft: '-8px'}}>
                    <div className='line'></div>
                  </div>

                  <label className='form-label my-7 fs-4 fw-bolder '>Levels</label>
                  {/* show multiple levels */}

                  {levelsData ? (
                    levelsData.map((item, mainIndex) => {
                      const level = item.level
                      const behaviors = item.behaviors
                      return (
                        <div className='form-group col-md-12'>
                          <div>
                            <div>
                              <div className='d-flex align-items-center mb-4'>
                                <img alt='' src={getImage(mainIndex)} width={'48px'}></img>
                                <label className='form-label fs-6 fw-bolder ms-2'> {level}</label>
                              </div>
                              <div className='d-flex align-items-center'>
                                <div className='my-3 d-flex align-items-center'>
                                  <img alt='' src={imageforrotate} />
                                  <label className='fs-6 fw-bolder ms-2'>{`Add Behaviors for ${level} Level`}</label>
                                </div>

                                <img
                                  onClick={() => addFields(mainIndex)}
                                  className='ms-auto cursor-pointer'
                                  alt='add'
                                  src={imagePath.ic_add_orange_border}
                                />
                              </div>

                              {/* {inputFields.map((input, index) => { */}
                              {behaviors?.map((input, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <div>
                                        <div className='binbeaviour'>
                                          {' '}
                                          <label className='form-label fs-6 fw-bolder mt-2'>Behavior {index + 1}</label>
                                          {index == 0 ? (
                                            <> </>
                                          ) : (
                                            <>
                                              <img
                                                style={{cursor: 'pointer'}}
                                                onClick={() => removeButton(index, mainIndex)}
                                                width={18}
                                                height={18}
                                                src={imagePath.ic_delete_orange_cross}
                                              />
                                            </>
                                          )}
                                        </div>
                                      </div>

                                      <input
                                        style={{height: '56px'}}
                                        type=''
                                        className=' mb-3 form-control bg-white'
                                        id='behaviour1'
                                        placeholder='Type here'
                                        value={input.name}
                                        autoComplete='off'
                                        onChange={(event) => handleFormChange(mainIndex, index, event)}
                                      />
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                          </div>

                          <div>
                            <hr className='mt-10 rlineer'></hr>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
                <div
                  onClick={handleSubmit}
                  className='candi-btn2 text-white fw-bolder my-10 mx-auto  '
                  style={{textAlign: 'center', alignSelf: 'center'}}
                >
                  Update
                </div>
              </form>
            </div>
          </div>
        </div>

        {loading && <LoaderView message='Loading...' />}
      </>
    </Modal>,
    modalsRoot
  )
}

export {UpdateIndividualCompetency}
