import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useAuth } from '../auth/core/Auth'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
const modalsRoot = document.getElementById('root-modals') || document.body

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
const CREATE_TEST_URL = `${API_URL}/test/add-test`
const UPDATE_TEST_URL = `${API_URL}/test/`

const CreateNewTest = ({ type, testData, fetchAllTests, handleClose, ...props }) => {
  const { auth, logout } = useAuth()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [testName, setTestName] = useState('')
  const [selectedCompetencies, setSelectedCompetencies] = useState<any>([])
  const [testTime, setTestTime] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState<any>('0')
  const [totalTimes, setTotalTimes] = useState<{ [key: string]: number }>({})
  const [allCompetencies, setAllCompetencies] = useState<any>()
  const [individualCompetencies, setIndividualCompetencies] = useState<any>()
  const [groupCompetencies, setGroupCompetencies] = useState<any>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (type === 'update' && testData) {
      setTestName(testData?.name)
      setTestTime(testData?.test_time)
      const competencies = testData?.competencies
      if (competencies !== null && competencies !== undefined && competencies.length > 0) {
        let list: any = []
        competencies.map((competency) => {
          let data = {
            competency_id: competency?.id,
            name: competency?.name,
            totalQuestions: competency?.number_of_questions,
            totalTime: competency?.number_of_questions * 30,
          }
          list.push(data)
        })
        setSelectedCompetencies(list)
      }
    }
  }, [])

  const secondsToHms = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = timeInSeconds % 60

    const formattedTime = [hours > 0 ? hours + 'hr' : '', minutes > 0 ? minutes + 'min' : '', seconds > 0 ? seconds + 'sec' : '']
      .filter(Boolean)
      .join(' ')

    return formattedTime
  }

  const handleSelectChange = (event, compType) => {
    const selectedOptions = event.target.selectedOptions

    if (selectedCompetencies && selectedCompetencies.length >= 5) {
      setMessage('Can not add more than 5 competencies!');
      setShowErrorMessage(true);
      return
    }

    for (let option of selectedOptions) {
      const competencyId = option.getAttribute('data-competency-id')
      // Check if competencyId is not already in selectedCompetencies
      if (!selectedCompetencies.some((comp) => comp.competency_id === competencyId)) {
        let competency = allCompetencies.find((comp) => comp.competency_id === competencyId)
        if (competency) {
          // Add totalQuestions and totalTime properties to the competency object
          competency = {
            ...competency,
            totalQuestions: '',
            totalTime: 0,
          }
          setSelectedCompetencies((prevState) => [...prevState, competency])
        }
      }
    }
  }

  const removeCompetency = (competency: string) => {
    setSelectedCompetencies((prevState) => prevState.filter((item) => item !== competency))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const numberOfQuestions = parseInt(event.target.value) || 0
    let data = [...selectedCompetencies]
    data[index]['totalQuestions'] = numberOfQuestions
    data[index]['totalTime'] = numberOfQuestions * 30
    setSelectedCompetencies(data)
  }

  useEffect(() => {
    ; (() => {
      calculateTotalTime()
      calculateTotalQuestions()
    })()
  }, [selectedCompetencies])

  const calculateTotalQuestions = () => {
    let data = [...selectedCompetencies]
    let questionsCount = 0
    data?.map((competency) => {
      questionsCount = questionsCount + (competency?.totalQuestions || 0)
    })
    setTotalQuestions(questionsCount)
  }

  const calculateTotalTime = () => {
    let data = [...selectedCompetencies]
    let testTime = 0
    data?.map((competency) => {
      testTime = testTime + (competency?.totalTime || 0)
    })
    setTestTime(testTime)
  }

  const handleTestNameChange = (e) => {
    setTestName(e.target.value)
  }

  const GetAllCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        const competencies = resp.data.competencies
        setAllCompetencies(competencies)
        // Separate into individual and group competencies
        let allIndividualCompetencies = competencies.filter((comp) => comp.type.toLowerCase() === 'individual')
        let allGroupCompetencies = competencies.filter((comp) => comp.type.toLowerCase() === 'group')
        console.log('allIndividualCompetencies', allIndividualCompetencies)
        setIndividualCompetencies(allIndividualCompetencies)
        setGroupCompetencies(allGroupCompetencies)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const displayErrorMessage = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
      logout()
    } else if (error.response?.data?.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        setMessage(error.response.data.error.message)
        setShowErrorMessage(true)
      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  useEffect(() => {
    ; (async () => {
      GetAllCompetencies()
    })()
  }, [])

  useEffect(() => {
    // Calculate total time for each competency group
    const calculateTotalTimes = () => {
      const newTotalTimes: { [key: string]: number } = {}
      for (const competency of selectedCompetencies) {
        newTotalTimes[competency] = totalTimes[competency] || 0
      }
      setTotalTimes(newTotalTimes)
    }

    calculateTotalTimes()
  }, [selectedCompetencies])

  const validateTotalQuestions = () => {
    let isValid = true

    selectedCompetencies.forEach((competency, index) => {
      if (!competency.totalQuestions || isNaN(competency.totalQuestions)) {
        isValid = false
        // alert(`Invalid value for totalQuestions at index ${index+1}`);
        setMessage(`Invalid value for Number of Questions of competency: ${competency?.name} `)
        setShowErrorMessage(true)
      }
    })

    return isValid
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!testName) {
      setMessage('Test name is not valid!')
      setShowErrorMessage(true)
      return
    }
    if (selectedCompetencies === null || selectedCompetencies === undefined || selectedCompetencies.length === 0) {
      setMessage('Please select the competencies first and try again.')
      setShowErrorMessage(true)
      return
    }

    if (!validateTotalQuestions()) {
      return
    }

    let questions: any = []
    selectedCompetencies?.map((competency) => {
      let question = {
        competency_id: competency?.competency_id,
        number_of_questions: competency?.totalQuestions,
        total_time: competency?.totalTime,
      }
      questions.push(question)
    })

    let data = {
      name: testName,
      questions: questions,
    }
    if (type === 'create') {
      setLoading(true)
      await axios
        .post(CREATE_TEST_URL, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async (res) => {
          await setLoading(false)

          // handleClose()
          fetchAllTests()
        })
        .catch((error) => {
          setLoading(false)
          displayErrorMessage(error)
        })
    } else {
      updateTest(data)
    }
  }

  const updateTest = (data) => {
    // let data = {
    //   name: testName,
    //   competencies: competenciesData
    // }
    setLoading(true)
    axios
      .put(UPDATE_TEST_URL + testData?.test_id, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // handleClose()
        fetchAllTests()
      })
      .catch((error) => {
        setLoading(false)
        displayErrorMessage(error)
      })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={true}
      backdrop={true}
    // style={{ background: 'none', marginLeft: '-70px', overflow: 'auto', scrollbarWidth: 'none' }}
    >
      <div className='p-6'>
        <form>
          <div className='d-flex mb-10 align-items-center'>
            <h3 style={{ fontWeight: '700' }}>{`${type === 'create' ? 'Create Test' : 'Update Test'}`}</h3>
            <img className='ms-auto cursor-pointer' onClick={() => handleClose()} src={imagePath.ic_close} aria-hidden='true'></img>
          </div>
          <div className='px-4'>
            <div className='form-group'>
              <label className='form-label fw-bolder fs-6'>
                Test Name
                <span style={{ color: 'rgb(213, 0, 0)', marginLeft: '4px' }}>*</span>
              </label>
              <input type='name' className='form-control' value={testName} onChange={(e) => handleTestNameChange(e)} />
            </div>
          </div>

          <div className='mt-8 px-4 d-flex align-items-center'>
            <div className='d-flex flex-column' style={{ width: '50%' }}>
              <label className='form-label fw-bolder fs-6'>Select Competency Group</label>
              <select
                className='form-select'
                data-control='select2'
                data-hide-search='true'
                onChange={(e) => handleSelectChange(e, 'groupComp')}
              >
                <option value='Analytics'>Select</option>
                {groupCompetencies ? (
                  groupCompetencies.map((competency, index) => {
                    return (
                      <option key={competency?.competency_id} value={competency?.name} data-competency-id={competency?.competency_id}>
                        {competency?.name}
                      </option>
                    )
                  })
                ) : (
                  <div></div>
                )}
              </select>
            </div>

            <div className='d-flex flex-column ps-6' style={{ width: '50%' }}>
              <label className='form-label fw-bolder fs-6'>
                Add Competencies
                <span style={{ color: 'rgb(213, 0, 0)', marginLeft: '4px' }}>*</span>
              </label>
              <div>
                <select
                  className='form-select '
                  data-control='select2'
                  data-hide-search='true'
                  onChange={(e) => handleSelectChange(e, 'individualComp')}
                >
                  <option value=''>Select</option>
                  {individualCompetencies ? (
                    individualCompetencies.map((competency, index) => {
                      return (
                        <option key={competency?.competency_id} value={competency?.name} data-competency-id={competency?.competency_id}>
                          {competency?.name}
                        </option>
                      )
                    })
                  ) : (
                    <div></div>
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className='d-flex flex-column px-4'>
            <div className='d-flex mt-10'>
              <label className=' form-label fw-bolder fs-6' style={{ width: '50%' }}>
                Selected Competencies<span style={{ color: 'rgb(213, 0, 0)', marginLeft: '4px' }}>*</span>
              </label>

              <div className='d-flex align-items-center ps-6' style={{ width: '50%' }}>
                <label className='form-label fw-bolder fs-6' style={{ width: '50%' }}>
                  No. of Question
                </label>

                <label className='form-label fw-bolder fs-6 ms-8' style={{ width: '50%' }}>
                  Total Time <span style={{ color: 'gray' }}>(sec)</span>
                </label>
              </div>
            </div>

            {selectedCompetencies &&
              selectedCompetencies.map((competency, index) => {
                return (
                  <div key={index} className='d-flex align-items-center mt-4 '>
                    <div style={{ width: '50%' }}>
                      <div
                        className='fs-6 px-3 py-2 fw-semibold'
                        style={{
                          display: 'inline-block',
                          backgroundColor: 'rgb(255, 235, 206)',
                          color: 'rgb(243, 101, 35)',
                          borderRadius: '20px',
                        }}
                      >
                        {competency?.name}
                        <span style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={() => removeCompetency(competency)}>
                          <i style={{ marginLeft: '5px', color: 'rgb(243, 101, 35)' }} className='fa fa-times' aria-hidden='true'></i>
                        </span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center ps-6' style={{ width: '50%' }}>
                      <div style={{ width: '50%' }}>
                        <input
                          className='form-control w-45 py-2 fs-6 text-center'
                          style={{ color: '#373D4A' }}
                          type='text'
                          value={competency?.totalQuestions || ''}
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </div>
                      <div className='ms-8' style={{ width: '50%' }}>
                        <input
                          className='form-control w-45 py-2 fs-6 text-center'
                          style={{ color: '#373D4A' }}
                          type='text'
                          value={competency?.totalTime}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>

          <div className='px-4'>
            <hr style={{ height: '2px', backgroundColor: '#B2B2B2' }}></hr>
            <div className='d-flex '>
              <label className=' form-label fw-bolder fs-6' style={{ width: '50%' }}>
                Total
              </label>

              <div className='d-flex align-items-center ps-6' style={{ width: '50%' }}>
                <label className='form-label fw-bolder fs-6 text-center' style={{ width: '50%' }}>
                  {totalQuestions}
                </label>

                <label className='form-label fw-bolder fs-6 ms-8 text-center' style={{ width: '50%' }}>
                  {testTime}{' '}
                </label>
              </div>
            </div>
          </div>

          <div className=' d-flex px-6'>
            {/* <span style={{ fontSize: '14px', color: 'rgb(243, 101, 35)' }}>{`*Total time on based of your selection: ${secondsToHms(testTime)}`}</span> */}

            <div className='d-flex align-items-center pt-6 ms-auto'>
              <button className='candi-btn2 btn text-white py-2 me-6' style={{ width: '8rem' }} type='reset' onClick={handleClose}>
                Cancel
              </button>
              <button
                className='candi-btn2 btn text-white py-2'
                style={{ width: '8rem' }}
                type='submit'
                disabled={loading}
                onClick={handleSave}
              >
                {`${type === 'create' ? 'Save' : 'Update'}`}
              </button>
            </div>
          </div>
        </form>
        {loading && <LoaderView message='Saving Test...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default CreateNewTest
