import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import './VerifyDocuments.css'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import UserIcon from './img/group-9680@3x.png'
import PdfIcon from './img/pdf@2x.png'
import ExperienceIcon from './img/experience@2x.png'
import VerifyIcon from './img/verified (1)@2x.png'
import UncheckedIcon from './img/colored@2x.png'
import PhotoIcon from './img/photo-camera.png'
import CheckedIcon from './img/Component 193 – 8@2x.png'
import icCameraChecked from './img/ic_camera_checked_green_border_.png';

import React from 'react'
import LoaderView from './LoaderView'
import {event} from 'jquery'

const API_URL = process.env.REACT_APP_API_URL

export const GET_ALL_DOCUMENT = `${API_URL}/document/get-all-docs`
export const GET_CANDIDATE_EXPERIENCE = `${API_URL}/jobapplication/show-candidate-experience`
export const UPDATE_CANDIDATE_EXPERIENCE = `${API_URL}/jobapplication/update-candidate-experience`
export const UPDATE_DOCUMENT_STATUS = `${API_URL}/document/update-doc-status`
export const VERIFY_SELECTED_DOCUMENTS = `${API_URL}/document/verify-selected-docs`
export const ADD_MISSING_DOCUMENTS = `${API_URL}/document/upload-doc`
const CREATE_DOCS_URL = `${API_URL}/document/create`
const REQUEST_AGAIN_DOCS_URL = `${API_URL}/document/request-again-doc`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  jobId: string
  profile_pic: string
  candidate_id: string
  position: string
  updateCandidatesList: () => void
}

type InputProps = {
  doc_name?: string
  doc_status?: string
}
const modalsRoot = document.getElementById('root-modals') || document.body

const VerifyDocumentsPopup = ({
  show,
  name,
  jobapp_id,
  jobId,
  profile_pic,
  candidate_id,
  position,
  handleClose,
  updateCandidatesList,
}: Props) => {
  const [dataFetched, setDataFetched] = useState(false)
  const [requestedDocuments, setRequestedDocuments] = useState<any>([])
  const [docID, setDocID] = useState([])
  const [bulkId, setBulkId] = useState<any>([])
  const {auth} = useAuth()
  const [experience, setExperience] = useState<any>({bankingExperience: 0, jobExperience: 0, totalExperience: 0})
  const [uploadfile, setFile] = useState<any>(null)
  const [selectedDocIds, setSelectedDocIds] = useState<string[]>([])
  const [experienceData, setExperienceData] = useState<any>({
    total_experience: 'requested',
    work_experience: 'requested',
    banking_experience: 'requested',
  })
  const [clicked, setClicked] = useState({
    bankingExperience: false,
    jobExperience: false,
    totalExperience: false,
  })
  const [loading, setLoading] = useState(false)

  const [extraDocs, setExtraDocs] = useState<any>([])

  const GetAllDocuments = async () => {
    await axios
      .post(
        GET_ALL_DOCUMENT,
        {
          candidate_id: candidate_id,
          jobapp_id: jobapp_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setRequestedDocuments(res.data.doc)
        const documentIds = res.data.doc.map((doc) => doc.doc_id)
        let verifiedDocs: any = []
        // getting verified document ids
        res.data.doc.map((item, index) => {
          if (item.doc_status === 'verified') {
            verifiedDocs.push(item.doc_id)
          }
        })
        if (verifiedDocs.length > 0) {
          setSelectedDocIds(verifiedDocs)
        }
        setDocID(documentIds)
      })
  }

  const GETCANDIDATEEXPERIENCE = async () => {
    await axios
      .post(
        GET_CANDIDATE_EXPERIENCE,
        {
          jobapp_id: jobapp_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('GETCANDIDATEEXPERIENCE: ', res.data)
        let experienceData = res.data
        let jobExperience = experienceData?.jobExperience
        let bankingExperience = experienceData?.bankingExperience

        let userExperience = {...experience}
        userExperience['jobExperience'] = parseInt(jobExperience)
        userExperience['bankingExperience'] = parseInt(bankingExperience)
        userExperience['totalExperience'] = parseInt(jobExperience) + parseInt(bankingExperience)
        setExperience(userExperience)
      })
  }

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    const name: any = event.currentTarget.dataset.name
    setClicked({...clicked, [name]: true})
  }

  const handleChangeBankingExpVerification = (event: React.MouseEvent<HTMLImageElement>) => {
    let data = {...experienceData}
    if (data.banking_experience === 'requested') {
      data.banking_experience = 'verified'
    } else {
      data.banking_experience = 'requested'
    }
    setExperienceData(data)
  }

  const handleChangeWorkExpVerification = (event: React.MouseEvent<HTMLImageElement>) => {
    let data = {...experienceData}
    if (data.work_experience === 'requested') {
      data.work_experience = 'verified'
    } else {
      data.work_experience = 'requested'
    }
    setExperienceData(data)
  }

  const handleChangeTotalExpVerification = (event: React.MouseEvent<HTMLImageElement>) => {
    let data = {...experienceData}
    if (data.total_experience === 'requested') {
      data.total_experience = 'verified'
    } else {
      data.total_experience = 'requested'
    }
    setExperienceData(data)
  }

  const ADDMISSINGDOCUMENTS = async (e: React.ChangeEvent<HTMLInputElement>, doc_name: string, index) => {
    const file = e.target?.files?.[0]
    if (!file) {
      return
    }
    if (!doc_name) {
      window.alert('Name of document not found, Please enter document name first')
      return
    }
    const formData = new FormData()
    formData.append('doc_name', doc_name)
    formData.append('jobapp_id', jobapp_id)
    formData.append('file', file)

    await axios
      .post(ADD_MISSING_DOCUMENTS, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        
        let allDocs = [...requestedDocuments];
        allDocs[index]['doc_status'] = 'uploaded';
        setRequestedDocuments(allDocs);
      }).catch((error)=>{

      });
  }

  const UPDATECANDIDATEXPERIENCE = async () => {
    await axios
      .post(
        UPDATE_CANDIDATE_EXPERIENCE,
        {
          jobapp_id: jobapp_id,
          job_experience: experience['bankingExperience'],
          banking_experience: experience['jobExperience'],
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setLoading(false)
        console.log({res})
        setExperience(res.data)
        // after documents verifications update the list or candidates to update the value of status
        updateCandidatesList()
        // handleClose();
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })
  }

  const UPDATEDOCUMENTSTATUS = async () => {
    setLoading(true)
    let data = {
      jobapp_id: jobapp_id,
      experience_requested: experienceData,
      docIds: selectedDocIds,
    }
    await axios
      .post(VERIFY_SELECTED_DOCUMENTS, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // setLoading(false);
        console.log({res})
        UPDATECANDIDATEXPERIENCE()
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })

    // handleClose();
  }

  const REQUESTAGAIN = async () => {
    // if (extraDocs.length > 0) {
    //createDocuments
    // fetch list of not verified ids
    let pendingDocIds = []
    if (docID.length > 0) {
      if (selectedDocIds.length > 0) {
        // Finding IDs that are not in selectedDocIds but available in docID
        pendingDocIds = docID.filter((id) => !selectedDocIds.includes(id))
      }
    }
    if (extraDocs.length === 0 && pendingDocIds.length === 0) {
      window.alert('No any pending or extra document available')
      return
    }
    setLoading(true)
    let data = {
      document: extraDocs,
      docIds: selectedDocIds,
      jobapp_id: jobapp_id,
      candidate_id: candidate_id,
      // 'docIds': pendingDocIds
    }
    await axios
      .post(REQUEST_AGAIN_DOCS_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // handleClose();
        updateCandidatesList()
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })
    // } else {
    //     // was being called before update the logic
    //     await axios.post(UPDATE_DOCUMENT_STATUS,
    //         {
    //             doc_id: bulkId,
    //             doc_status: "requested"
    //         },
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${auth?.token}`,
    //                 'Content-Type': 'application/json',
    //             },
    //         }

    //     ).then((res) => {
    //         console.log({ res })
    //     })
    // }
  }
  const verifyAllDocs = () => {
    if (requestedDocuments) {
      let docIds = [...selectedDocIds]
      requestedDocuments.map((item, index) => {
        let docId = item['doc_id']
        const idIndex = docIds.indexOf(docId)
        if (idIndex === -1) {
          docIds.push(docId)
        }
      })
      setSelectedDocIds(docIds)
      setBulkId(docIds)

      // verify experience data
      let updatedData = {...experienceData}
      // Update all variables to 'verified'
      for (const key in updatedData) {
        if (Object.prototype.hasOwnProperty.call(updatedData, key)) {
          updatedData[key] = 'verified'
        }
      }
      // Set the updated data back to the state
      setExperienceData(updatedData)
    } else {
      window.alert('Documents are not available')
    }
  }
  const selectDocument = (id) => {
    const index = selectedDocIds.indexOf(id)
    if (index === -1) {
      setSelectedDocIds([...selectedDocIds, id])
      setBulkId([...bulkId, id])
    } else {
      const newSelectedIds = [...selectedDocIds]
      newSelectedIds.splice(index, 1)
      setSelectedDocIds(newSelectedIds)

      const bulkIdsIndex = bulkId.indexOf(id)
      const newBulkIds = [...bulkId]
      newBulkIds.splice(bulkIdsIndex, 1)
      setBulkId(newBulkIds)
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    setExperience((prevExperience) => {
      const newExperience = {
        ...prevExperience,
        [name]: Number(value),
      }
      newExperience.totalExperience = parseInt(newExperience.bankingExperience) + parseInt(newExperience.jobExperience)

      return newExperience
    })
  }
  console.log({experience})

  useEffect(() => {
    ;(async () => {
      if (!dataFetched) {
        await GetAllDocuments()
        await GETCANDIDATEEXPERIENCE()
        // await UPDATECANDIDATEXPERIENCE();
        // await GETCANDIDATEEXPERIENCE();
      }
    })()
  }, [dataFetched])

  const downloadFile = (url) => {
    if (!url) return

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', url.split('/').pop() || 'file')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((error) => {
        console.log('Error downloading file:', error)
      })
  }

  console.log({uploadfile})

  const addDocumentFields = () => {
    let docs: any = []
    if (extraDocs.length > 0) {
      docs = [...extraDocs]
    }
    let document = {
      candidate_id: candidate_id,
      jobapp_id: jobapp_id,
      doc_title: '',
      doc_name: '',
      doc_status: 'requested',
    }
    docs.push(document)
    setExtraDocs(docs)
  }

  const updateDocTitle = (index, event) => {
    let value = event.target.value
    let docs = [...extraDocs]
    docs[index]['doc_title'] = value
    docs[index]['doc_name'] = value.replace(/\s/g, '_')
    setExtraDocs(docs)
  }

  const removeExtraDoc = (indexToRemove) => {
    setExtraDocs((prevExtraDocs) => {
      // Create a new array without the specified index
      const updatedExtraDocs = prevExtraDocs.filter((_, index) => index !== indexToRemove)
      return updatedExtraDocs
    })
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      height='50vh'
      // onHide={handleClose}
      backdrop={true}
    >
      <>
        <div className=' no-doc-header-verify'>
          <div className='modal-header no-header-model123'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '18rem',
              }}
            >
              <img src={VerifyIcon} className='score-header-icon-internally' />
              <h2 className='score-header-container-text'>Verify Documents</h2>
            </div>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={Layout}></img>
            </div>
          </div>
          <div className='mx-auto d-flex flex-column '>
            <div className='profile-Container-verify-doc d-flex justify-content-center align-items-center'>
              <div className='d-flex d-flex justify-content-center align-items-center'>
                <img
                  style={{
                    border: '1px solid #F36523',
                    width: '9vh',
                    height: '9vh',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    marginRight: '8px',
                  }}
                  src={profile_pic !== null ? profile_pic : UserIcon}
                  alt=''
                />
                <label className='verify-doc-middle-container-username'>{name}</label>
              </div>
              <label className='py-2 px-3 fs-5 fw-bolder' style={{borderRadius: '4px', backgroundColor: '#F3F6F9', color: '#80808f'}}>
                Position: {position}
              </label>
            </div>
          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <div className='card'>
            {/* begin::Beader */}

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <div className='verify-docs-text-cont d-flex mt-4 mb-6'>
                <label className='verify-docs-text'>Requirements</label>

                <div style={{width: '88%', margin: '0 auto'}}>
                  <i style={{float: 'right', cursor: 'pointer'}} onClick={addDocumentFields} className='bi bi-plus-lg addmore'></i>
                </div>
              </div>

              {extraDocs.length > 0 ? (
                extraDocs.map((doc, index) => (
                  <div className='mb-2 d-flex justify-content-center align-items-center'>
                    <div className='me-2'>
                      <label htmlFor={`extra-doc-${index}`} className='upload-img' style={{cursor: 'pointer'}}>
                        <img
                          src={PhotoIcon}
                          style={{
                            height: '5vh',
                            border: '1px solid #373D4A',
                            padding: '7px',
                            borderRadius: '4px',
                          }}
                        />
                      </label>
                      <input
                        type='file'
                        id={`extra-doc-${index}`}
                        style={{display: 'none'}}
                        //create document and upload file
                        // onChange={(e) => ADDMISSINGDOCUMENTS(e, doc.doc_name)}
                      />
                    </div>

                    <input
                      type='text'
                      className='form-control me-2'
                      name='doc_name'
                      placeholder='Type here'
                      value={doc['doc_title']}
                      onChange={(event) => updateDocTitle(index, event)}
                    />

                    <div className='d-flex justify-content-center align-items-center' style={{marginLeft: 'auto'}}>
                      <button
                        id={`doc-${index}`}
                        onClick={() => removeExtraDoc(index)}
                        style={{border: 'none', backgroundColor: 'transparent'}}
                        className='me-2'
                      >
                        <img
                          // src={selectedDocIds.includes(item.doc_id) ? CheckedIcon : UncheckedIcon}
                          src={Layout}
                          style={{height: '4vh'}}
                        />
                      </button>

                      <button
                        id={`doc-${index}`}
                        // onClick={() => selectDocument(item['doc_id'])}
                        style={{border: 'none', backgroundColor: 'transparent'}}
                      >
                        <img
                          // src={selectedDocIds.includes(item.doc_id) ? CheckedIcon : UncheckedIcon}
                          src={UncheckedIcon}
                          style={{height: '4vh'}}
                        />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )}

              <div className='verify-docs-doc-cont'>
                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                  <label
                    className='form-label fs-6 fw-bolder'
                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                    onClick={() => verifyAllDocs()}
                  >
                    Select All
                  </label>
                </div>

                {requestedDocuments ? (
                  requestedDocuments.map((item, index) => (
                    <div key={index} className='verify-docs-row'>
                      <div className=''>
                        <label className='verfiy-doc-name-header'>{item.doc_name}</label>

                        {item.doc_path ? (
                          <div className='verify-docs-inner-cont'>
                            <img src={PdfIcon} style={{height: '5vh'}} />
                            <div className='verify-docs-inner-cont-col'>
                              <p className='verify-doc-name'>{item.doc_path.split('/').pop()}</p>
                              <button className='verfiy-doc-btn' onClick={() => downloadFile(item.doc_path)}>
                                Download
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className='d-flex'>
                            <label htmlFor={`upload-${index}`} className='upload-img' style={{cursor: 'pointer'}}>
                              <img
                                src={item?.doc_status === 'uploaded' ? icCameraChecked : PhotoIcon}
                                style={{
                                  height: '5vh',
                                  border: '1px solid #373D4A',
                                  padding: '7px',
                                  borderRadius: '4px',
                                }}
                              />
                            </label>
                            <input
                              type='file'
                              id={`upload-${index}`}
                              style={{display: 'none'}}
                              accept='.png, .jpg, .pdf'
                              // onChange={(e) => ADDMISSINGDOCUMENTS(e, item.doc_id)}
                              onChange={(e) => ADDMISSINGDOCUMENTS(e, item.doc_name, index)}
                            />
                            {/* <div className='verify-docs-inner-cont-col'> */}
                            <p className='verify-doc-name' style={{marginLeft: '8px'}}>
                              {item.doc_name.replace(/_/g, ' ')}
                            </p>

                            {/* </div> */}
                          </div>
                        )}
                      </div>
                      <div>
                        <button
                          id={item['doc_id']}
                          onClick={() => selectDocument(item['doc_id'])}
                          style={{border: 'none', backgroundColor: 'transparent'}}
                        >
                          <img src={selectedDocIds.includes(item.doc_id) ? CheckedIcon : UncheckedIcon} style={{height: '4vh'}} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </div>
              <div className='verify-doc-dashed-line'></div>

              <div className='verify-experience-lower-cont'>
                <div className='verify-experience-cont'>
                  <img src={ExperienceIcon} style={{height: '5vh'}} />
                  <label className='verify-docs-text'>Verify Experience</label>
                </div>

                <div className='verify-experience-lower-cont-row'>
                  <label className='verfiy-doc-name-header'>Banking Experience </label>
                  <input
                    className='verify-doc-exp-input'
                    name='bankingExperience'
                    type='number'
                    min={0}
                    value={experience.bankingExperience}
                    onChange={handleInputChange}
                  />
                  <img
                    src={experienceData.banking_experience === 'verified' ? CheckedIcon : UncheckedIcon}
                    style={{height: '4vh', cursor: 'pointer'}}
                    data-name='bankingExperience'
                    onClick={handleChangeBankingExpVerification}
                  />
                </div>

                <div className='verify-experience-lower-cont-row'>
                  <label className='verfiy-doc-name-header'>Non Banking Experience </label>
                  <input
                    className='verify-doc-exp-input'
                    name='jobExperience'
                    type='number'
                    min={0}
                    value={experience.jobExperience}
                    onChange={handleInputChange}
                  />
                  <img
                    src={experienceData.work_experience === 'verified' ? CheckedIcon : UncheckedIcon}
                    style={{height: '4vh', cursor: 'pointer'}}
                    data-name='jobExperience'
                    onClick={handleChangeWorkExpVerification}
                  />
                </div>

                <div className='verify-experience-lower-cont-row'>
                  <label className='verfiy-doc-name-header'>Total Experience </label>
                  <input
                    className='verify-doc-exp-input'
                    style={{cursor: 'default'}}
                    name='totalExperience'
                    // type="number"
                    min={0}
                    value={experience.totalExperience}
                    readOnly
                  />

                  <img
                    src={experienceData.total_experience === 'verified' ? CheckedIcon : UncheckedIcon}
                    style={{height: '4vh', cursor: 'pointer'}}
                    data-name='totalExperience'
                    onClick={handleChangeTotalExpVerification}
                  />
                </div>
              </div>

              <div className='verify-doc-btn-cont'>
                <div>
                  <button className='verify-doc-request' onClick={() => REQUESTAGAIN()}>
                    Request Again
                  </button>
                </div>

                <div>
                  <button className='verify-doc-comp-verification' onClick={() => UPDATEDOCUMENTSTATUS()}>
                    Complete Verification
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <LoaderView message='Loading...' />}
      </>
    </Modal>
  )
}

export {VerifyDocumentsPopup}
