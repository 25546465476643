import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useState, useEffect, useContext} from 'react'
import './Test.css'
import MCQOption from './MCQOption'
// import {useContext} from 'react'
import arrowdown from './images/navigate_next_black_24dp.png'
import {useTestContext} from '../../../context/useTestContext'
import {TestQuestionDataContext} from '../../../context/TestQuestionDataContext'
import {useTestQuestionDataContext} from '../../../context/useTestQuestionDataContext'
import axios from 'axios'
import unchecked from './images/Group 10062.png'
import checked from './images/tick.png'
import uploadIcon from './images/upload (1).png'
import uncheckedMCQsOption from './images/tick grey.png'
import CheckedMCQOprion from './images/tick green.png'
import cross from '../../../_metronic/partials/content/images/cross.png'
import deleteIcon from './images/delete-icon.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

import {useAuth} from '../auth/core/Auth'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get?type=Competency`
const UPLOAD_QUESTION_VIDEO_URL = `${API_URL}/test/upload-video`

const TestQuestion = (props) => {
  console.log('PROPS ', props)
  const {currentUser, auth, logout} = useAuth()
  const intl = useIntl()
  const {Test, setTestButton} = useTestContext()
  const [questionType, setQuestionType] = useState('')
  const [numOptions, setNumOptions] = useState(2)
  const [selectedOption, setSelectedOption] = useState('YES')
  const [question, setQuestion] = useState('')
  const [options, setOptions] = useState<any>({a: '', b: ''})
  const [answer, setAnswer] = useState('')
  const [checkedstate, setcheckedstate] = useState(-1)
  const [displayCompetencies, setdisplayCompetencies] = useState(false)
  const [selectedCompetencies, setSelectedCompetencies]: any = useState([])
  const questionNumber = props.questionNumber
  const {testQuestionsData, setTestQuestionsData} = useTestQuestionDataContext()
  const [dataFetched, setDataFetched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const questionData = props.questionData
  console.log('upadateQuestionData: ', questionData)

  useEffect(() => {
    console.log('UpdateQuestion:questionType: ', questionData?.type)
    setQuestionType(questionData?.type)
    setQuestion(questionData?.text)
    setSelectedCompetencies(questionData?.competencies ? questionData?.competencies : [])
    setOptions(questionData?.options)
    setSelectedOption(questionData?.correct_answer)
  }, [])

  useEffect(() => {
    if (!dataFetched) {
      ;(async () => {
        await GetAllIndiviualCompetencies()
        setDataFetched(true)
      })()
    }
    const existingDataIndex = testQuestionsData.findIndex((data) => data['questionNumber'] === questionNumber)
    if (existingDataIndex !== -1) {
      const updatedData = [...testQuestionsData]
      updatedData[existingDataIndex] = {questionNumber, questionType, question, options, selectedOption, selectedCompetencies}
      setTestQuestionsData(updatedData)
    } else {
      setTestQuestionsData([...testQuestionsData, {questionNumber, questionType, question, options, selectedOption, selectedCompetencies}])
    }
    console.log('IN USE EFFECT')
  }, [questionType, question, selectedOption, selectedCompetencies, options])

  function handleQuestionChange(event) {
    let value = event.target.value
    props.questionTextChange(value, questionNumber - 1)
  }

  const removeItem = (index) => {
    setSelectedCompetencies([...selectedCompetencies.slice(0, index), ...selectedCompetencies.slice(index + 1)])
  }

  function handleOptionContent(value, content) {
    props.optionTextChange(value, content, questionNumber - 1)
  }

  function handleAddOptionClick() {
    props.addNewOption({a: ''}, questionNumber - 1)
  }

  function handleQuestionTypeChange(event) {
    let value = event.target.value
    props.updateQuestionType(value, questionNumber - 1)
  }

  const DisplayCompetenciesropDown = () => {
    setdisplayCompetencies(!displayCompetencies)
  }
  const handleOptionSelection = (letter) => {
    props.correctOptionSelection(letter, questionNumber - 1)
  }

  var TotalCompetecies
  var TotalIndivualCom = 0
  var TotalGroupCom = 0
  const CompetenciesTypesArray: string[] = []
  const GroupCompetenciesNamesArray: any[] = []
  const IndividualCompetenciesNamesArray: any[] = []
  const [GroupCompArray, setGroupCompArray] = useState<any>([])
  const [IndividualCompArray, setIndividualCompArray] = useState<any>([])

  const GetAllIndiviualCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL)
      .then((resp) => {
        TotalCompetecies = resp.data.competencies
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })

    // console.log('TotalCompetecies in Group', TotalCompetecies)
    for (var i = 0; i < TotalCompetecies.length; i++) {
      CompetenciesTypesArray[i] = TotalCompetecies[i]['type']

      if (CompetenciesTypesArray[i] === 'Group') {
        GroupCompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalGroupCom = TotalGroupCom + 1
      } else {
        IndividualCompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalIndivualCom = TotalIndivualCom + 1
      }
    }

    if (GroupCompetenciesNamesArray === undefined) {
      setGroupCompArray(' ')
    } else {
      const filteredArray = GroupCompetenciesNamesArray.filter((item) => item !== null && item !== undefined && item !== '')
      setGroupCompArray(filteredArray)
    }
    if (IndividualCompetenciesNamesArray === undefined) {
      setIndividualCompArray(' ')
    } else {
      const filteredArray = IndividualCompetenciesNamesArray.filter((item) => item !== null && item !== undefined && item !== '')
      setIndividualCompArray(filteredArray)
    }
  }

  // useEffect(() => {
  //     GetAllIndiviualCompetencies()

  //     console.log('CompArrayCompArrayCompArrayCompArray', GroupCompArray)
  // }, [])

  const removeOption = (key) => {
    // Check if the key exists in the options object
    if (key in options) {
      // Remove the option by deleting the key from the updatedOptions object
      delete options[key]
    }

    // update the keys of options  keys should be in alphabet sequence
    const updatedOptions = {}
    Object.entries(options).map(([key, value], i) => {
      let newKey = String.fromCharCode(97 + i)
      updatedOptions[newKey] = value
    })

    setOptions(updatedOptions)
  }

  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    // Check if a file was selected and if it's a video file
    if (file && isVideoFile(file)) {
      //   setSelectedFile(file);
      uploadQuestionVideo(file)
    } else {
      props.questionVideoChange(null, questionNumber - 1)
      alert('Please select a valid video file (e.g., MP4, WebM).')
    }
  }

  const handleFileDrop = (event) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    // Check if a file was dropped and if it's a video file
    if (file && isVideoFile(file)) {
      uploadQuestionVideo(file)
    } else {
      props.questionVideoChange(null, questionNumber - 1)
      alert('Please drop a valid video file (e.g., MP4, WebM).')
    }
  }

  const uploadQuestionVideo = async (file) => {
    const formData = new FormData()
    formData.append('file', file, 'question_video')
    setLoading(true)
    await axios
      .post(UPLOAD_QUESTION_VIDEO_URL, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setLoading(false)
        let url = res?.data?.data?.url
        props.questionVideoChange(url, questionNumber - 1)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const isVideoFile = (file) => {
    // You can add more video file extensions if needed
    const allowedExtensions = ['.mp4', '.webm']
    const fileName = file.name.toLowerCase()
    return allowedExtensions.some((ext) => fileName.endsWith(ext))
  }

  const handleFileDragOver = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <div className='pt-8' style={{width: '100%'}}>
        <div className='d-flex'>
          {/* <div className='me-8'>
                        <div className='TotalQuestionNumber'>
                            Q{props.questionNumber}
                        </div>
                    </div> */}
          <div className='col-md-7 pe-2'>
            <label className='form-label labelse '>Select Questions Type</label>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select '
              defaultValue='Select type'
              placeholder='Select type'
              value={questionData?.type}
              required
              onChange={handleQuestionTypeChange}
            >
              <option value='Select type'>Select type</option>
              <option value='MCQs'>MCQs</option>
              <option value='Video Based MCQs'>Video Based MCQ's</option>

              {/* <option value='Poll'>Poll</option> */}
            </select>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-7'>
            <div className=' padding-left-for-questiondesc'>
              {/* <hr className='w-100'></hr> */}
              <div className='textarea-for-queation-descriotion'>
                {' '}
                <label className='form-label labelse m-5 '> Question {props.questionNumber}</label>
              </div>
              <textarea
                onChange={(event) => handleQuestionChange(event)}
                className='form-control queation-description'
                id='QuestionDescription'
                placeholder='Finance Assessment'
                value={questionData?.text}
              />
            </div>
          </div>
          {questionData?.type === 'Video Based MCQs' && (
            <div className='col-md-5 ms-auto'>
              <div className='uploadvideo ms-auto' onDrop={handleFileDrop} onDragOver={handleFileDragOver}>
                <input
                  type='file'
                  accept='.mp4,.webm'
                  onChange={handleFileInputChange}
                  style={{display: 'none', cursor: 'pointer'}}
                  id={`fileInput ${questionNumber}`}
                />
                <label htmlFor={`fileInput ${questionNumber}`} style={{textAlign: 'center', cursor: 'pointer'}}>
                  {loading ? (
                    <div className='text-center'>
                      <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='2x' />
                    </div>
                  ) : (
                    <img src={uploadIcon} alt='Upload Icon' />
                  )}

                  <p className='DropText'>Drop Files to Upload</p>
                  <p className='DropText'>
                    or <span className='uploadText'>upload</span>
                  </p>
                </label>
                {questionData?.video && <label className='mt-2 fw-regular fs-7'>{questionData?.video}</label>}
              </div>
            </div>
          )}
        </div>

        <div className='row mt-4 '>
          <div className='col-md-7'>
            <div className='me-1 '>
              {/* <hr className='w-100'></hr> */}
              <div className='d-flex flex-column'>
                {' '}
                <label className='form-label labelse my-5'>Enter Options</label>
                <label className='form-label labelse-EnterOptions'>
                  <span style={{color: '#F36523'}}>*</span>Tick the option which is correct
                </label>
              </div>
            </div>

            <div>
              {questionData?.options &&
                Object.entries(questionData?.options).map(([key, value], i) => {
                  return (
                    <div className='row mt-3 align-items-center' key={i}>
                      <div className='col-md-2'>
                        <div className='optionsForMCQs  labelse '>{String.fromCharCode(97 + i)}</div>
                      </div>
                      <div className='col-md-7 d-flex'>
                        <input
                          type='text'
                          className='InputForMCQs px-4'
                          id='input-id'
                          placeholder='Enter your text here'
                          value={value as string}
                          required
                          onChange={(event) => handleOptionContent(String.fromCharCode(97 + i), event.target.value)}
                        />
                      </div>
                      <div className='col-md-1 align-items-center' style={{marginLeft: '1rem'}}>
                        <img
                          style={{cursor: 'pointer', width: '20px', height: '20px'}}
                          onClick={() => {
                            // removeItem(index);
                            props.removeOption(key, questionNumber - 1)
                          }}
                          src={imagePath.ic_delete_orange_cross}
                        />
                      </div>
                      <div className='col-md-1 d-flex align-items-center'>
                        <div style={{cursor: 'pointer'}} onClick={() => handleOptionSelection(String.fromCharCode(97 + i))}>
                          <img
                            src={
                              questionData?.correct_answer === String.fromCharCode(97 + i)
                                ? imagePath.ic_tic_orange_filled_circle
                                : imagePath.ic_tic_gray_filled_circle
                            }
                            alt='checked'
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>

            <div className='mb-8'>
              <div style={{cursor: 'pointer'}} className='add-Mcqs-Optiosn' onClick={handleAddOptionClick}>
                + Add Options
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className=' ms-auto' style={{maxWidth: '352px'}}>
              <label className='form-label labelse mt-5'>Link Individual Competency</label>

              <div className='TestTime-div d-flex align-items-center '>
                <input
                  type=''
                  onClick={() => setdisplayCompetencies(!displayCompetencies)}
                  style={{margin: '0px 5px 0px 0px', border: 'none', cursor: 'pointer'}}
                  className='form-select bg-white cursor-pointer'
                  id=''
                  placeholder='Select Competencies'
                />
                <div className='overlayGrpCompetencies'>
                  {displayCompetencies === true ? (
                    <div style={{backgroundColor: '#0000'}}>
                      <div>
                        {IndividualCompArray.map((data, index) => (
                          <div className='py-2 px-4 liststylingGrpCompetemcies' style={{listStyle: 'none'}} key={index}>
                            <input
                              type='checkbox'
                              className='form-check-input me-2 cursor-pointer'
                              style={{height: '16px', width: '16px', borderRadius: '4px'}}
                              value={data}
                              checked={questionData?.competencies && questionData?.competencies.includes(data)}
                              onChange={(e) => {
                                props.updateCompetencies(data, questionNumber - 1)
                                setdisplayCompetencies(false)
                              }}
                            />
                            {data}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className='d-flex flex-wrap gap-2'>
                {questionData?.competencies &&
                  questionData?.competencies.map((competenmcy, index) => (
                    <div style={{margin: '20px 0px 0px 0px'}}>
                      <a>
                        <span className='orange-tag fs-7 me-3 fw-bolder' key={index}>
                          {competenmcy} &nbsp;
                          <span>
                            <img
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                props.removeCompetency(competenmcy, questionNumber - 1)
                              }}
                              src={imagePath.ic_cross_orange}
                            ></img>
                          </span>
                        </span>
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default TestQuestion
