//import liraries
import React, {useEffect, useState} from 'react'
import '../Vacancies/vacancies.styles.css'

// import './dashboard.styles.css'
// import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import '../Dashboard/dashboard.styles.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import icInfo from '../../../../_metronic/partials/modals/JobFunnelsModels/img/information (3)@2x.png'
import {useAuth} from '../../auth/core/Auth'
import {Link, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import imagePath from '../../../../constants/imagePath'
import axios from 'axios'
import {FaSort, FaSortDown, FaSortUp} from 'react-icons/fa'
import {getFormattedDate} from '../../../../constants/CommonFunctions'
import CustomPagination from '../../dashboards/components/CustomPagination'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import ProfileStrengthCard from './ProfileStrengthCard'
import UploadRequestedDocsPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/UploadRequestedDocsPopup'
import SkillsCard from './SkillsCard'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import 'bootstrap-daterangepicker/daterangepicker.css' // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker'
import BannerView from './BannerView'

const API_URL = process.env.REACT_APP_API_URL
const VACANT_VACANCIES_URL = `${API_URL}/job/show-status-jobs`
const CHECK_PENDING_DOCS_URL = `${API_URL}/document/getdoc-by-status`

// create a component
const CandidateDashboard = () => {
  const navigate = useNavigate()
  const {currentUser, auth, logout} = useAuth()
  const intl = useIntl()
  const [greeting, setGreeting] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [vacancies, setVacancies] = useState<any>()
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [pageRefreshedAt, setPageRefreshedAt] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [inProcessJobs, setInProcessJobs] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showUploadDocs, setShowUploadDocs] = useState(false)
  const [params, setParams] = useState<any>({
    job_status: 'active',
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    search: '',
    start_date: '',
    end_date: '',
  })
  const [selectedRange, setSelectedRange] = useState('')

  // const handleDateRangeChange = (event, picker) => {
  //     setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
  // };

  const handleDateRangeChange = (event, picker) => {
    setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'))
    let startDate = picker.startDate.format('MM/DD/YYYY')
    let endDate = picker.endDate.format('MM/DD/YYYY')

    let data = {...params}
    data.start_date = startDate
    data.end_date = endDate
    setParams(data)
  }

  const handleCancelDatePicker = () => {
    setSelectedRange('')
    let data = {...params}
    data.start_date = ''
    data.end_date = ''
    setParams(data)
  }

  async function getData() {
    let data = {...params}
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    filteredParams.candidate_id = currentUser?.user_id
    setLoading(true)
    await axios
      .get(VACANT_VACANCIES_URL, {
        // params: {
        //     job_status: 'active',
        //     candidate_id: currentUser?.user_id
        // },
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setLoading(false)
        let data = response?.data
        let pagination = data?.pagination

        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = {...params}
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }

        // setCurrentPage(pagination?.page || 1)
        setTotalPages(pagination?.totalPages || 0)

        setVacancies(response.data.data)

        const currentDate = new Date()
        const month = currentDate.toLocaleString('default', {month: 'short'})
        const day = currentDate.getDate()
        const hour = currentDate.getHours()
        const minute = currentDate.getMinutes()
        const formattedDateTime = `Last Updated at: ${hour}:${minute.toString().padStart(2, '0')} - ${month}, ${day}`
        setPageRefreshedAt(formattedDateTime)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          const {status, data} = error.response

          if (status === 400) {
            setMessage(data.message)
          } else if (status === 401 && data.name === 'TokenExpiredError') {
            logout()
          } else if (data.error) {
            if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(data.error.message)
            }
          } else {
            setMessage('An unknown error occurred')
          }
        } else {
          setMessage(error.message)
        }

        setShowErrorMessage(true)
      })
  }

  // checking is there any pending document exist or not
  const checkPendingDocs = async () => {
    setShowUploadDocs(false)
    let data = {
      candidate_id: currentUser?.user_id,
      doc_status: 'requested',
    }
    axios
      .post(CHECK_PENDING_DOCS_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data
        setInProcessJobs(data?.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await checkPendingDocs()
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await getData()
    })()
  }, [params])

  useEffect(() => {
    // Get the current date and time
    const now = new Date()
    const currentHour = now.getHours()

    // Determine the greeting based on the current hour
    let currentGreeting
    if (currentHour >= 5 && currentHour < 12) {
      currentGreeting = 'Good Morning'
    } else if (currentHour >= 12 && currentHour < 18) {
      currentGreeting = 'Good Afternoon'
    } else {
      currentGreeting = 'Good Evening'
    }

    // Set the greeting message
    setGreeting(currentGreeting)
  }, [])

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...vacancies].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    // setVacancies(sorted)
    setSortField(field)
    setSortDirection(direction)

    let data = {...params}
    data.order = field
    data.sort = direction === 'asc' ? 'ASC' : 'DESC'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const isDatePassed = (validTill) => {
    // Convert the validTill string to a Date object
    const validTillDate = new Date(validTill)

    // Get the current date
    const currentDate = new Date()

    // Compare the validTill date with the current date
    if (validTillDate < currentDate) {
      // If validTill date is before the current date, it has passed
      return true
    } else {
      // If validTill date is after or equal to the current date, it has not passed
      return false
    }
  }

  const handlePageChange = (page) => {
    let data = {...params}
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = {...params}
    data.limit = event.target.value
    setParams(data)
  }

  const handleSearchKeyChange = (value) => {
    setSearchKey(value)
    // if the value of searchKey is empty set the value of search as empty too
    if (!value) {
      // setSearch('')
      let data = {...params}
      data.search = ''
      setParams(data)
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = {...params}
    if (searchKey && searchKey !== data.search) {
      // setSearch(searchKey)
      data.search = searchKey
      data.page = 1
      setParams(data)
    } else {
      // setSearch('')
      data.search = ''
      data.page = 1
      setParams(data)
    }
  }

  const resetFilters = () => {
    let data = {
      job_status: 'active',
      order: 'updated_at',
      sort: 'DESC',
      page: 1,
      limit: 10,
      search: '',
    }
    setParams(data)
  }

  return (
    <div>
      <div className='dashboard-top'>
        <h1>Dashboard</h1>
        <div className='d-flex align-items-center'>
          <h3>
            <i className='bi bi-house text-white' style={{fontSize: '1.5rem'}}></i>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/candidatedashboard' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              My Dashboard
            </Link>
          </h3>
          <h3 className='ms-auto'>
            <i className='bi bi-arrow-clockwise text-white'></i> {pageRefreshedAt}
          </h3>
        </div>
      </div>
      <div className='col-md-12 d-flex mt-18'>
        <div className='col-md-8'>
          <div>
            {/* greeting and updates */}
            <div className='card-bg shadow-sm pt-6 ps-6'>
              <div className='d-flex'>
                <div>
                  <h2 className='fw-bold card1-main-heading text-gray-800 mb-3'>
                    {/* Hey {currentUser?.role}, Good Morning 🌕 */}
                    {`Hi ${currentUser?.first_name ? currentUser?.first_name : 'Candidate'}, ${greeting}`}
                    <img style={{marginLeft: '1rem'}} src={imagePath.ic_sun} />
                  </h2>

                  <div className='text-muted candi-sub-heading fw-semibold fs-6 mb-8'>
                    Welcome to your BOP dashboard. Manage application insights and much more
                  </div>
                  {inProcessJobs.length > 0 ? (
                    <div className='m-0'>
                      <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-4'>
                          {inProcessJobs.length > 1 ? (
                            <h6 className='candi-card1-h6 pe-5' style={{display: 'inline-block'}}>
                              Pending documents for Vacancies!
                            </h6>
                          ) : (
                            <h6
                              className='candi-card1-h6 pe-5'
                              style={{display: 'inline-block'}}
                            >{`Documents Missing at ${inProcessJobs[0]?.job_name} vacancy you applied for.`}</h6>
                          )}
                          <img
                            className='me-2'
                            style={{display: 'inline-block', cursor: 'pointer'}}
                            src={icInfo}
                            width={24}
                            alt='...'
                            // onClick={showInfoPopup}
                          />
                        </div>
                        <label
                          style={{width: '10rem'}}
                          className=' candi-btn2 btn fw-semibold px-4 py-2 text-white cursor-pointer'
                          // data-bs-toggle='modal'
                          // data-bs-target='#kt_modal_create_app'
                          onClick={() => {
                            setShowUploadDocs(true)
                          }}
                        >
                          View Details
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className='ms-auto'>
                  <img src={imagePath.cycling} width={180} />
                </div>
              </div>
            </div>

            {/* banner view */}
            <div className=' mt-8 shadow-sm' style={{borderRadius: '10px'}}>
              {/* <img src={imagePath.banner_static} style={{ width: '100%', objectFit: 'cover' }} /> */}
              <BannerView />
            </div>

            {/* vacant position */}
            <div className='card-bg shadow-sm p-6 mt-8'>
              <div className='d-flex align-items-center mb-6'>
                <label className='fs-5 fw-bolder'>Join BOP</label>

                <div className='ms-auto d-flex align-items-center'>
                  <div
                    className='d-flex me-4 align-items-center'
                    style={{border: 'solid 1px #F57536', borderRadius: '4px', height: '32px'}}
                  >
                    <input
                      style={{width: '24rem'}}
                      className='search-bar fs-6 ps-2 py-1'
                      type='text'
                      placeholder='Search'
                      value={searchKey}
                      onChange={(event) => handleSearchKeyChange(event.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <div
                      className='d-flex align-items-center px-2'
                      style={{
                        cursor: 'pointer',
                        color: '#FFFFFF',
                        backgroundColor: '#F57536',
                        height: '31px',
                        borderRadius: '0px 4px 4px 0px',
                      }}
                      onClick={(e) => handleSearch(e)}
                    >
                      <label className='fs-7 fw-bolder cursor-pointer' style={{color: '#FFFFFF', marginLeft: '4px'}}>
                        Search{' '}
                      </label>
                      <img className='search-icon ms-2' src={imagePath.ic_search_white} />
                    </div>
                  </div>
                  <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDatePicker}>
                    <img
                      src={imagePath.ic_calendar_orange}
                      width={32}
                      height={32}
                      style={{borderRadius: '4px', border: 'solid #F57536 1px', cursor: 'pointer'}}
                    />
                  </DateRangePicker>
                  {/* <img src={imagePath.ic_calendar_orange} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px' }} /> */}
                  <img
                    className='ms-2'
                    style={{cursor: 'pointer'}}
                    src={imagePath.ic_refresh_orange}
                    width={24}
                    height={24}
                    onClick={() => resetFilters()}
                  />
                </div>
              </div>
              <div style={{position: 'relative'}}>
                {loading && (
                  // Show loading icon if isLoading state is true
                  <div
                    className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                    style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999}}
                  >
                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                  </div>
                )}
                <div className='table-responsive'>
                  <table className='table align-middle gs-0 gy-4 table-borders'>
                    <thead>
                      <tr className='fw-bold graycolor bg-light'>
                        <th onClick={() => sortByField('title')} className='align-items-center ps-2' scope='col'>
                          <label className=' form-label fw-bolder fs-6 me-2'>Position</label>
                          {renderSortIcon('title')}
                        </th>
                        <th onClick={() => sortByField('valid_till')} className='align-items-center' scope='col'>
                          <label className=' form-label fw-bolder fs-6 me-2'>Last Date to Apply</label>
                          {renderSortIcon('valid_till')}
                        </th>
                        <th onClick={() => sortByField('location')} className='align-items-center' scope='col'>
                          <label className=' form-label fw-bolder fs-6 me-2'>Location</label>
                          {renderSortIcon('location')}
                        </th>
                        <th onClick={() => sortByField('position_available')} className='align-items-center' scope='col'>
                          <label className=' form-label fw-bolder fs-6 me-2'>No. of Positions</label>
                          {renderSortIcon('position_available')}
                        </th>
                        <th className='align-items-center' scope='col'>
                          <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vacancies && vacancies.length > 0 ? (
                        vacancies.map((vacancy, index) => {
                          const isEvenRow = index % 2 === 0
                          const validTill = vacancy?.valid_till
                          const expired = isDatePassed(validTill)
                          return (
                            <React.Fragment key={index}>
                              <tr style={{backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9'}}>
                                <td className='ps-2'>
                                  <h4 className=' form-label fs-6'>{vacancy?.title}</h4>
                                </td>
                                <td>
                                  <h4 className=' form-label fs-6'>{getFormattedDate(validTill)}</h4>
                                </td>
                                <td>
                                  <h4 className=' form-label fs-6'>{vacancy?.location}</h4>
                                </td>
                                <td>
                                  <h4 className=' form-label fs-6'>{vacancy?.position_available}</h4>
                                </td>
                                <td className='pe-2'>
                                  <div>
                                    {vacancy?.expired ? (
                                      <div>
                                        <button
                                          className='btn fw-bolder fs-6 candi-btn1 px-2 py-2 text-center'
                                          style={{border: '1px solid #F36523', borderRadius: '4px', whiteSpace: 'nowrap', width: '10rem'}}
                                        >
                                          Due Date Passed
                                        </button>
                                      </div>
                                    ) : vacancy?.checkApplied ? (
                                      <div>
                                        <label
                                          className='orange-tag fw-bolder fs-6 px-2 py-2 text-center'
                                          style={{borderRadius: '4px', whiteSpace: 'nowrap', width: '10rem'}}
                                        >
                                          Already Applied
                                        </label>
                                      </div>
                                    ) : (
                                      <div>
                                        <button
                                          className='btn fw-bolder fs-6 candi-btn2 text-white px-2 py-2 text-center'
                                          style={{borderRadius: '4px', whiteSpace: 'nowrap', width: '10rem'}}
                                          onClick={() => {
                                            navigate('/mainvacancies')
                                          }}
                                        >
                                          View Details
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })
                      ) : (
                        <div></div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {vacancies && vacancies.length > 0 ? (
                <div className='d-flex col-md-12 align-items-center'>
                  <div className='col-md-4'>
                    <label>{`showing ${vacancies ? vacancies.length : 0} of ${params.total} results.`}</label>
                  </div>
                  <div className='mx-auto col-md-4' style={{textAlign: 'center'}}>
                    <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
                  </div>

                  <div className='col-md-4 '>
                    <div className='ms-auto d-flex flex-end align-items-center'>
                      <select
                        className='me-2 pagination-input'
                        style={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
                        id='selectValues'
                        // value={itemsPerPage}
                        value={params.limit}
                        onChange={handleItemsPerPageChange}
                      >
                        <option value='10'>10</option>
                        <option value='25'>25</option>
                        <option value='50'>50</option>
                      </select>

                      <div className='me-2'>
                        <label>Items per page</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div>
            <ProfileStrengthCard />
          </div>
          <div className='mt-8'>
            <SkillsCard />
          </div>
        </div>
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {showUploadDocs && (
        <UploadRequestedDocsPopup
          show={true}
          handleClose={() => setShowUploadDocs(false)}
          inProcessJobs={inProcessJobs}
          checkPendingDocs={checkPendingDocs}
        />
      )}
    </div>
  )
}

//make this component available to the app
export default CandidateDashboard
