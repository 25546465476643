import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import './CreateJobs.css'
import { JobcreationForm } from './JobcreationForm'
import { CompetenciesDictionary } from './CompetenciesDictionary'
import { right } from '@popperjs/core'
import React, { useEffect, useState, useRef } from 'react'
import plusimagecom from './images/icons8-plus-math-30 (1).png'
import cross from './images/cross.png'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import './CreateJobs.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calnedarimage from './images/today_black_24dp.png'
import styled from 'styled-components'
import bottomarrow from './images/Path 38012.png'
import toparrow from './images/Path 38012 (1).png'
import stage1 from './images/7899 [Converted].png'
import stage2 from './images/7899 [Converted] (1).png'
import stage3 from './images/7899 [Converted] (2).png'
import stage1Clicked from './images/7899 [Converted] (3).png'
import stage2Clicked from './images/7899 [Converted] (4).png'
import axios from 'axios'
import stage3Clicked from './images/7899 [Converted] (5).png'

import checkedgpa from './images/radio_button_checked_black_24dp.png'
import uncheckedgpa from './images/radio_button_checked_unchecked_24dp.png'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import { start } from 'repl'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'

// import checkeddiv from './images/radio_button_checked_black_24dp.png'
// import uncheckeddiv from './images/radio_button_checked_black_24dp.png'

// const styles = { backgroundColor: color };
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
export const UPDATE_JOB_URL = `${API_URL}/job/`
export const GET_TESTS_URL = `${API_URL}/test`
export const GET_POSITIONS_URL = `${API_URL}/position`
const GET_BY_JOB_ID = `${API_URL}/job/`
const GET_POSITION_DATA = `${API_URL}/position/`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`

const UpdateJobs = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [EndDate, setEndDate] = useState(new Date())
  const navigate = useNavigate()
  const [checkedstateGPA, setcheckedstateGPA] = useState(false)
  const [checkedstateDiv, setcheckedstateDiv] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [batchId, setBatchId] = useState<any>('')
  const [positionLocations, setPositionLocations] = React.useState<any>([])
  const [isExpanded, setIsExpanded] = useState(false)
  let { id } = useParams()
  let { job_id } = useParams()

  const checkedstateGPAFunction = (event) => {
    const value = event.target.value
    let jobData = { ...currentJob }
    let grade_type = jobData['grade_type']
    grade_type['type'] = value
    grade_type['value'] = ''

    jobData['grade_type'] = grade_type
    console.log('grade_type:', jobData)
    setCurrentJob(jobData)
  }

  const handleCGPAChange = (event) => {
    let value = event.target.value
    let jobData = { ...currentJob }
    let grade_type = jobData['grade_type']
    grade_type['value'] = value
    jobData['grade_type'] = grade_type
    setCurrentJob(jobData)
  }

  const handleKeyPress = (event) => {
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    if (currentJob?.grade_type?.type === 'cgpa') {
      const regexPattern = /^(?:0(?:\.[0-9]{1,2})?|[1-3](?:\.[0-9]{1,2})?|4(?:\.0{1,2})?)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else {
      const regexPattern = /^[1-3]$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    }
  }

  const checkedstateDivFunction = () => {
    setcheckedstateDiv(!checkedstateDiv)
  }

  const [Number, setNumber] = useState(0)
  const [Experience, setExperience] = useState(0)
  const [minSalary, setMinSalary] = useState('0')
  const [maxSalary, setMaxSalary] = useState('0')
  const [EndDateTest, setEndDateTest] = useState()

  const [SetStageInterview, setSetStageInterview] = useState(1)
  const [Stage1, setstage1] = useState(false)
  const [Stage2, setstage2] = useState(false)
  const [Stage3, setstage3] = useState(false)

  const [Title, setTitle] = useState({})
  const [Department, setDepartment] = useState('Not Selected')
  const [jobDescription, setJobDescription] = useState('')
  const [videoResumeDescription, setVideoResumeDescription] = useState('')
  const [MimimumEducation, setMimimumEducation] = useState('Masters')
  const [grade, setgrade] = useState('OG-IV')
  const [linktest, setlinktest] = useState<any>([])
  const [selectedtest, setselectedtest] = useState({})
  const [Location, setLocation] = useState('Not Selected')
  const [age, setage] = useState(25)

  const [minimumExpReq, setminimumExpReq] = useState(2)
  const [AvaliablePositions, setAvaliablePositions] = useState(0)
  const [getPositions, setPositions] = useState([])
  const [isVideoResumeChecked, setVideoResumeChecked] = useState(false)
  const { currentUser } = useAuth()
  const [dataFetched, setDataFetched] = useState(false)
  const user_id = currentUser?.user_id
  const [currentJob, setCurrentJob] = useState<any>()
  const [gradeType, setGradeType] = useState('cgpa')
  const [totalExperience, setTotalExperience] = useState<any>(0)
  const [ageFrom, setAgeFrom] = useState(0)
  const [ageTo, setAgeTo] = useState(0)
  const [maxBasicSalary, setmaxBasicSalary] = useState<any>(0)
  const [resumeDeadLine, setResumeDeadLine] = useState<any>(null)
  const [minEduYears, setMinEduYears] = useState<any>('')
  const [acceptInProgressEdu, setAcceptInProgressEdu] = useState(false)
  const [educationLevels, setEducationLevels] = useState<any>()

  const handleSubmit = async (e) => {
    e.preventDefault()

    let competencies = JSON.parse(currentJob?.competenices)
    competencies = competencies.filter((obj) => Object.keys(obj).length !== 0)

    if (!currentJob?.vacant_position) {
      setMessage('Please select a vacant position first and try again.')
      setShowErrorMessage(true)
      return
    }
    if (competencies.length === 0) {
      setMessage('Please add competencies first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.description) {
      setMessage('Job description not found.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.group_name) {
      setMessage('Group not found.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.eduction) {
      setMessage('Please select education level first')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.education_years) {
      setMessage('Please select minimum educational years required first')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.grade_type?.value) {
      setMessage(`Please enter minimum required value of ${currentJob?.grade_type?.type} first`)
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.grade) {
      setMessage('Grade value not found.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.banking_experience) {
      setMessage('Please enter value of minimum banking experience required first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.work_experience) {
      setMessage('Please enter value of minimum total experience required first.')
      setShowErrorMessage(true)
      return
    }

    let bankingExperience = parseInt(currentJob?.banking_experience || '0')
    let totalExp = parseInt(currentJob?.work_experience || '0')
    if (bankingExperience > totalExp) {
      setMessage('Banking Experience can not be greater than Total Experience.')
      setShowErrorMessage(true)
      return
    }
    let age_limit = currentJob?.age_limit
    if (!age_limit) {
      setMessage('Age limit not found please set age limit first.')
      setShowErrorMessage(true)
      return
    }
    // Split the string into an array based on the '-' delimiter
    let ageRangeArray = age_limit.split('-')

    // Extract values for ageFrom and ageTo
    let ageFrom = parseInt(ageRangeArray[0], 10)
    let ageTo = parseInt(ageRangeArray[1], 10)
    if (!ageFrom) {
      setMessage('Please select value of age from first.')
      setShowErrorMessage(true)
      return
    }
    if (!ageTo) {
      setMessage('Please select value of age to first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.location) {
      setMessage('Location value not found.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.position_available) {
      setMessage('Value of available positions not found.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.valid_from) {
      setMessage('Please select a date for valid from first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.valid_till) {
      setMessage('Please select a date for valid to first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.salary_range_from || currentJob?.salary_range_from === '0') {
      setMessage('Please enter minimum salary first.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.salary_range_till || currentJob?.salary_range_till === '0') {
      setMessage('Please enter maximum salary first.')
      setShowErrorMessage(true)
      return
    }
    if (parseInt(currentJob?.salary_range_from) >= parseInt(currentJob?.salary_range_till)) {
      setMessage('Minimum salary can not be greater or equal to Maximum salary.')
      setShowErrorMessage(true)
      return
    }
    if (!currentJob?.stage) {
      setMessage('Please select no of interview stages first.')
      setShowErrorMessage(true)
      return
    }

    // check is the value of banking experience and total experience is valid or not
    let banking = currentJob?.banking_experience
    let bankingNumber = parseInt(banking)
    let total = currentJob?.work_experience
    let totalNumber = parseInt(total)

    if (CompetencyArrayinDB.length === 0) {
      setCompetencyArrayinDB([''])
    }

    // let JobData = {
    //   vacant_position: Title["title"],
    //   title: Title["title"],
    //   job_description: jobDescription,
    //   department: Department,
    //   applications: 0,
    //   published: true,
    //   eduction: MimimumEducation,
    //   experience: minimumExpReq,
    //   shortlisting: false,
    //   grade_type: {},
    //   grade: grade,
    //   age_limit: age,
    //   location: Location,
    //   job_type: Title["hiring_type"],
    //   position_available: AvaliablePositions,
    //   valid_from: startDate,
    //   valid_till: EndDate,
    //   online_interview: true,
    //   applied_candidates: {},
    //   salary_range_from: minSalary,
    //   salary_range_till: maxSalary,
    //   video_resume: isVideoResumeChecked ? videoResumeDescription : null,
    //   recuiter_id: user_id,
    //   position_id: Title["position_id"],
    //   department_id: Title["department_id"],
    //   test_id: selectedtest["test_id"],
    //   stage: SetStageInterview,
    //   competenices: CompetencyArrayinDB

    // }
    console.log('JobData', currentJob)
    // let data = JSON.stringify(JobData)
    let currentJobData = { ...currentJob }
    currentJob['grade_type'] = JSON.stringify(currentJobData['grade_type'])
    let data = currentJob
    console.log('ID IS HERE', id)
    const url = UPDATE_JOB_URL + id
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('res', res)
        navigate('/jobs')
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const ages = Array.from({ length: 63 }, (_, index) => 18 + index)
  const days = Array.from({ length: 30 }, (_, index) => index + 1)
  const eduYears = Array.from({ length: 7 }, (_, index) => 10 + index)

  const handleAgeFromChange = (event) => {
    const selectedAgeFrom = event.target.value
    if (ageTo !== 0 && selectedAgeFrom >= ageTo) {
      window.alert('Age from must be less than age to')
    } else {
      let jobData = { ...currentJob }
      let ageRange = jobData['age_limit']
      let ageTo = ageRange.split('-')[1]
      let updatedAgeRange = `${selectedAgeFrom}-${ageTo}`
      jobData['age_limit'] = updatedAgeRange
      setCurrentJob(jobData)
    }
  }

  const handleAgeToChange = (event) => {
    const selectedAgeTo = event.target.value
    if (ageFrom >= selectedAgeTo && selectedAgeTo !== 0) {
      window.alert('Age from must be less than age to')
    } else {
      // setAgeTo(selectedAgeTo);

      let jobData = { ...currentJob }
      let ageRange = jobData['age_limit']
      let ageFrom = ageRange.split('-')[0]
      let updatedAgeRange = `${ageFrom}-${selectedAgeTo}`
      jobData['age_limit'] = updatedAgeRange
      setCurrentJob(jobData)
    }
  }

  const handleChangeDateFrom = (date) => {
    let jobData = { ...currentJob }
    jobData['valid_from'] = date
    setCurrentJob(jobData)
  }

  const handleChangeDateTo = (date) => {
    let jobData = { ...currentJob }
    jobData['valid_till'] = date
    setCurrentJob(jobData)
  }

  const changetitle = (selectedKey) => {
    selectedKey = JSON.parse(selectedKey)
    console.log('SELECTED KEY', selectedKey)
    // console.log(selectedKey.value)
    // console.log(newLink)
    setTitle(selectedKey)
    changeDepartment(selectedKey['department'])
    changeLocation(selectedKey['location'])
    setAvaliablePositions(selectedKey['no_of_positions'])
    //     let dateString = "2022-11-25";
    // let date = new Date(dateString);
    // console.log("DATE",date);
    //     setEndDate(date)
  }
  const changeDepartment = (newDepartment) => {
    setDepartment(newDepartment)
  }
  const changeJobDescription = (newDescription) => {
    let jobData = { ...currentJob }
    jobData['job_description'] = newDescription
    // setCurrentJob(jobData);
  }
  const changeVideoResumeDescription = (newDescription) => {
    // setVideoResumeDescription(newDescription)
    let jobData = { ...currentJob }
    jobData['video_resume'] = newDescription
    setCurrentJob(jobData)
  }
  const changeMimimumEducation = (newMimimumEducation) => {
    let jobData = { ...currentJob }
    jobData['eduction'] = newMimimumEducation
    setCurrentJob(jobData)
  }

  const changeMinimumEducationYears = (minEduYearsRequired) => {
    let jobData = { ...currentJob }
    jobData['education_years'] = minEduYearsRequired

    setCurrentJob(jobData)
  }
  const handleChangeAcceptInProgressEdu = (event) => {
    let acceptInProgress = event.target.checked
    let jobData = { ...currentJob }
    jobData['degree_inprogress'] = acceptInProgress
    setCurrentJob(jobData)
  }

  const getTestName = (testId) => {
    const foundTest = linktest.find((item) => item.test_id === testId)
    return foundTest ? foundTest.name : 'None'
  }

  const chanlinktest = (selectedKey) => {
    // selectedKey = JSON.parse(selectedKey)
    console.log('SELECTED KEY', selectedKey)
    // console.log(selectedKey.value)
    // console.log(newLink)
    // setselectedtest(selectedKey)
    let jobData = { ...currentJob }
    jobData['test_id'] = selectedKey
    setCurrentJob(jobData)
  }

  const changeLocation = (newlocation) => {
    setLocation(newlocation)
  }

  const handleVideoResumeChange = (event) => {
    let isChecked = event.target.checked
    if (isChecked) {
      let data = { ...currentJob }
      data['video_resume_deadline'] = new Date()
      setCurrentJob(data)
    } else {
      let data = { ...currentJob }
      data['video_resume_deadline'] = null
      data['video_resume'] = ''
      setCurrentJob(data)
    }
    setVideoResumeChecked(event.target.checked)

    // let jobData = {...currentJob}
    // jobData['video_resume'] = event.target.checked
    // setCurrentJob(jobData)

  }

  const handleResumeDeadLineChange = (date: Date) => {
    setResumeDeadLine(date)
    let data = { ...currentJob }
    data['video_resume_deadline'] = date
    setCurrentJob(data)
  }

  const CreateStageClicked = (value: string) => {
    let jobData = { ...currentJob }
    if (value == 'Stage1Clicked') {
      setstage1(true)
      setSetStageInterview(1)
      setstage2(false)
      setstage3(false)
      jobData['stage'] = 1
    } else if (value == 'Stage2Clicked') {
      setstage1(false)
      setSetStageInterview(2)
      setstage2(true)
      setstage3(false)
      jobData['stage'] = 2
    } else if (value == 'Stage3Clicked') {
      setstage1(false)
      setSetStageInterview(3)
      setstage2(false)
      setstage3(true)
      jobData['stage'] = 3
    } else {
      setstage1(false)
      setstage2(false)
      setSetStageInterview(1)
      setstage3(false)
      jobData['stage'] = 1
    }
    setCurrentJob(jobData)
  }

  const incrementExperience = (event) => {
    let inputValue = event.target.value
    let jobData = { ...currentJob }
    jobData['banking_experience'] = inputValue
    setCurrentJob(jobData)
  }
  const handleKeyPressExperience = (event) => {
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    const regexPattern = /^(?:[1-4]?[0-9]|50)$/
    if (!regexPattern.test(inputValue)) {
      event.preventDefault()
    }
  }

  const handleTotalExperienceChange = (event) => {
    const value = event.target.value
    const onlyNumbers = /^[0-9\b]+$/ // Regex pattern to match only numbers

    if (value === '' || onlyNumbers.test(value)) {
      let jobData = { ...currentJob }
      jobData['work_experience'] = value
      setCurrentJob(jobData)
    }
  }

  const handleTestDeadLine = (event) => {
    const selectedValue = event.target.value
    let jobData = { ...currentJob }
    jobData['online_test_deadline'] = selectedValue
    setCurrentJob(jobData)
  }

  var TotalCompetecies = []
  const CompetenciesTypesArray: string[] = []
  const [CompArray, setCompArray] = useState<any>([])
  const [CompArrayIndiviual, setCompArrayIndiviual] = useState<any>([])
  const CompetenciesNamesArrayIndiviual: any[] = []
  const [CompetencyArrayinDB, setCompetencyArrayinDB] = useState<any>([])
  const { auth, logout } = useAuth()

  const GetAllIndiviualCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL)
      .then((resp) => {
        let totalCompetencies = resp?.data?.competencies

        let individualCompetencies: any = []
        if (totalCompetencies) {
          totalCompetencies.map((competency) => {
            if (competency) {
              if (competency.type === 'Individual') {
                let competencyData = { id: competency.competency_id, name: competency.name }
                individualCompetencies.push(competencyData)
              }
            }
          })
        }
        if (individualCompetencies.length > 0) {
          console.log('individualCompetencies: ', individualCompetencies)
          setCompArrayIndiviual(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const intl = useIntl()

  const addCompetency = (competency: any) => {
    let jobData = { ...currentJob }
    let competencies = JSON.parse(jobData.competenices)
    const existingCompetencies = Object.values(competencies[0])

    // Check if the new competency already exists
    const isExistingCompetency = existingCompetencies.some((comp: any) => comp.id === competency.id)

    if (!isExistingCompetency) {
      competencies[0][Object.keys(competencies[0]).length.toString()] = competency
      jobData.competenices = JSON.stringify(competencies)
      setCurrentJob(jobData)
    }
  }

  const removeItem = (index) => {
    let jobData = { ...currentJob }
    let competencies = JSON.parse(jobData.competenices)
    console.log('removeItem:before competencies: ', competencies)
    delete competencies[0][index.toString()]
    console.log('removeItem:after competencies: ', competencies)

    //update the keys of object
    const updatedCompetencies = {}
    Object.entries(competencies[0]).map(([key, value], i) => {
      let newKey = i
      console.log('updatedCompetencies: remainingKey: ', newKey)
      updatedCompetencies[newKey] = value
    })
    let compList = [updatedCompetencies]
    jobData.competenices = JSON.stringify(compList)
    setCurrentJob(jobData)
  }

  const GetAllTests = async () => {
    await axios
      .get(GET_TESTS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        setlinktest(resp.data.data)
      })
  }

  const getJobData = async () => {
    console.log('jobId: ', id)
    axios
      .get(GET_BY_JOB_ID + id, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        let jobData = resp.data
        let gradeType = JSON.parse(jobData?.grade_type)
        jobData.grade_type = gradeType
        setCurrentJob(jobData)

        let positionId = jobData?.position_id
        getPositionInfo(positionId)

        let batchPositions = jobData?.batchPositions;
        if (batchPositions) {

          let positionsData: any = []
          batchPositions.forEach((element, index) => {
            let data = {
              code: element?.code,
              officeType: element?.office_type,
              location: element?.location,
              no_of_positions: element?.no_of_positions,
              position_id: element?.position_id,
            }
            positionsData.push(data)
          })
          setPositionLocations(positionsData)

        }

      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getPositionInfo = (positionId) => {
    let url = GET_POSITION_DATA + positionId
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data
        let position = data?.position
        setmaxBasicSalary(position?.max_basic_salary_per_month ? position?.max_basic_salary_per_month : 0)
        setBatchId(position?.batch_id)
        // setMaxSalary(position?.max_basic_salary_per_month ? position?.max_basic_salary_per_month : 0)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    if (!dataFetched) {
      (async () => {
        getJobData()
        GetAllIndiviualCompetencies()
        GetAllTests()
        getConfigurations()
        setDataFetched(true)
      })()
    }
    if (getPositions.length > 0 && linktest.length > 0) {
      changetitle(JSON.stringify(getPositions[0]))
      // chanlinktest(JSON.stringify(linktest[0]))
    }
    //  setTitle(getPositions[0])
  }, [getPositions, linktest])

  const getConfigurations = async () => {
    await axios
      .get(GET_CONFIGURATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.Configuration
        let termsConditions = data?.terms_condition
        if (termsConditions) {
          termsConditions = JSON.parse(termsConditions)
          const initialTermsAndConditions = Object.keys(termsConditions).map((key) => ({
            i: key,
            data: termsConditions[key],
          }))
        }
        let eduLevels = data?.education_level
        if (eduLevels) {
          eduLevels = JSON.parse(eduLevels)
          const initialEdu = Object.keys(eduLevels)
            .map((key) => ({
              i: key,
              data: eduLevels[key]['data'],
              active: eduLevels[key]['active'],
            }))
            .filter((level) => level.active) // Filter out objects with active === false
          setEducationLevels(initialEdu)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }


  const toggleExpand = (e) => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Update Job' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/dashboard' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/jobs' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Jobs
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='#' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Update Job
          </Link>
        </h3>
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-9'>
          <div className='card displayflex-setting '>
            <div className='col-xl-8'>
              {' '}
              <div className='card'>
                {/* begin::Beader */}
                <div className='card-header border-0 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label text-gray-800 fw-bolder fs-3 '>Update Job </span>
                  </h3>

                  {/* <hr className='border-hr'></hr> */}
                </div>

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form onSubmit={handleSubmit}>
                    <div className='form-row d-flex justify-content-spacearound'>
                      <div className=' col-md-12 d-flex flex-column'>
                        <label className='form-label labelse '>Vacant Position</label>
                        <label className='form-label me-4 w-140px' style={{ color: '#484848' }}>
                          {currentJob?.vacant_position}
                        </label>{' '}
                      </div>
                    </div>
                    <div className='form-group mt-10 col-12 me-4'>
                      <div className='me-4'>
                        <label className='form-label labelse '> Job Description</label>

                        <div
                          className='me-9'
                        // className='job-description'
                        // style={{ listStyleType: 'disc', paddingLeft: '1em' }}
                        >
                          {ReactHtmlParser(currentJob?.description)}
                        </div>
                        {/* <ReactQuill
                          value={currentJob?.job_description}
                          onChange={changeJobDescription}
                          placeholder="Enter your job description"
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, false] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              ['link'
                                // , 'image'
                                // , 'video'
                              ],
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'color',
                            'background',
                            'align',
                            'list',
                            'bullet',
                            'link',
                            // 'image',
                            // 'video',
                          ]}
                          style={{ height: '90px' }}
                        /> */}
                      </div>
                    </div>
                    {/* <div style={{height:'100px'}}></div> */}
                    <div className='form-row mt-10 d-flex justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Group</label>
                        <div className='me-4'>
                          <div className='form-control w-140px' style={{ cursor: 'default' }}>
                            {currentJob?.group_name}
                          </div>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Minimum Education Level</label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            style={{ cursor: 'pointer' }}
                            defaultValue='Select Minimum Education'
                            value={currentJob?.eduction}
                            onChange={(event) => changeMimimumEducation(event.target.value)}
                          >
                            <option style={{ cursor: 'pointer' }} value=''>
                              Select Minimum Education
                            </option>
                            {educationLevels &&
                              educationLevels.map((edu) => {
                                let index = edu?.i
                                let level = edu?.data
                                return (
                                  <option style={{ cursor: 'pointer' }} key={index} value={level}>
                                    {level}
                                  </option>
                                )
                              })}
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex justify-content-spacearound mt-10' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Min Education Years Required</label>
                        <div className='me-4'>
                          <select
                            style={{ cursor: 'pointer' }}
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select Min Edu Years Required'
                            required
                            value={currentJob?.education_years}
                            onChange={(event) => changeMinimumEducationYears(event.target.value)}
                          >
                            <option style={{ cursor: 'pointer' }} value=''>
                              Select Min Edu Years Required
                            </option>
                            {eduYears.map((years) => (
                              <option style={{ cursor: 'pointer' }} key={years} value={years}>
                                {years}
                              </option>
                            ))}
                            <option style={{ cursor: 'pointer' }} value='Greater than 16'>
                              {'Greater than 16'}
                            </option>
                          </select>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        {/* <label className='form-label labelse'>Group</label> */}
                        <div className='me-4 form-check checkbox-container d-flex' style={{ marginLeft: '-0.01rem' }}>
                          <input
                            id='degreeInProgressCheckBox'
                            type='checkbox'
                            style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                            className='form-check-input'
                            // required
                            checked={currentJob?.degree_inprogress}
                            onChange={(event) => handleChangeAcceptInProgressEdu(event)}
                          />
                          <label htmlFor='degreeInProgressCheckBox' className='form-check-label'>
                            {'Degree in progress acceptable'}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='form-row d-flex  mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-sm-6 col-md-6 col-lg-6 '>
                        {/* <label className='form-label labelse '>CGPA</label> */}
                        <div className='form-row d-flex justify-content-spacearound form-label'>
                          <div
                            className='form-group  col-md-4 me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type='radio'
                              id={'cgpaGrade'}
                              name={'education_type'}
                              value='cgpa'
                              checked={currentJob?.grade_type?.type === 'cgpa'}
                              onChange={(event) => checkedstateGPAFunction(event)}
                              className='checks-input'
                              style={{ cursor: 'pointer' }}
                            />
                            <label htmlFor='CGPA' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                              CGPA
                            </label>
                          </div>

                          <div
                            className='form-group  col-md-4 me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type='radio'
                              id={'divisionGrade'}
                              name={'education_type'}
                              value='division'
                              checked={currentJob?.grade_type?.type === 'division'}
                              onChange={(event) => checkedstateGPAFunction(event)}
                              className='checks-input'
                              style={{ cursor: 'pointer' }}
                            />
                            <label htmlFor='Division' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                              Division
                            </label>
                          </div>
                        </div>{' '}
                        <div className='me-4'>
                          <input
                            // type='number'
                            min='0'
                            max={currentJob?.grade_type?.type === 'cgpa' ? 4 : 3}
                            step={currentJob?.grade_type?.type === 'cgpa' ? 0.01 : 1}
                            name='education_value'
                            className='form-control'
                            placeholder={currentJob?.grade_type?.type === 'cgpa' ? '3.0' : '1'}
                            value={currentJob?.grade_type?.value}
                            required
                            autoComplete='off'
                            // pattern="\d*"
                            onChange={(event) => handleCGPAChange(event)}
                            onKeyPress={(event) => handleKeyPress(event)}
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Grade</label>
                        <div className='me-4'>
                          <label className='form-label form-control w-140px' style={{ cursor: 'default' }}>
                            {currentJob?.grade}
                          </label>
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3 fw-bolder '> Banking Experience Required</label>
                        <div className='me-4'>
                          <input
                            // type='number'
                            min='0'
                            max={50}
                            step={1}
                            name='education_value'
                            className='form-control'
                            placeholder={'1'}
                            autoComplete='off'
                            value={currentJob?.banking_experience}
                            required
                            // pattern="\d*"
                            onChange={(event) => incrementExperience(event)}
                            onKeyPress={(event) => handleKeyPressExperience(event)}
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3 fw-bolder '> Total Experience</label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            // type='text'
                            min='0'
                            max={50}
                            step={1}
                            className='form-control bg-white'
                            id=''
                            placeholder='3'
                            autoComplete='off'
                            value={currentJob?.work_experience}
                            onChange={handleTotalExperienceChange}
                            required
                          />
                        </div>{' '}
                      </div>
                    </div>

                    <label className='form-label labelse mt-10'>Age Limit</label>
                    <div className='form-row d-flex mt-1 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <div className='me-4'>
                          {/* <label className='form-label labelse '>Age From</label> */}

                          <label className='form-label fs-6 mt-3' htmlFor='ageFrom'>
                            Age From
                          </label>
                          <select
                            className='form-select bg-white mb-4'
                            id='ageFrom'
                            value={currentJob?.age_limit.split('-')[0]}
                            style={{ cursor: 'pointer' }}
                            onChange={handleAgeFromChange}
                            required
                          >
                            <option style={{ cursor: 'pointer' }} value={0}>
                              Select age from
                            </option>
                            {ages.map((age) => (
                              <option style={{ cursor: 'pointer' }} key={age} value={age}>
                                {age}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3' htmlFor='ageTo'>
                          Age To
                        </label>
                        <div className='me-4'>
                          <select
                            className='form-select bg-white'
                            id='ageTo'
                            style={{ cursor: 'pointer' }}
                            value={currentJob?.age_limit.split('-')[1]}
                            onChange={handleAgeToChange}
                            required
                          >
                            <option style={{ cursor: 'pointer' }} value={0}>
                              Select age to
                            </option>
                            {ages.map((age) => (
                              <option style={{ cursor: 'pointer' }} key={age} value={age}>
                                {age}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {batchId ? (
                      <div className='mt-10'>
                        <div className='d-flex mb-4'>
                          <label className='form-label labelse '>Multiple Locations</label>
                          {/* <button className='form-label labelse ms-auto fs-7' onClick={toggleExpand}>
                            {isExpanded ? 'Collapse' : 'Expand'}
                          </button> */}
                          <div className='ms-auto'>
                            {isExpanded ? (
                              <div>
                                <i style={{ float: 'right' }} onClick={toggleExpand} className='bi bi-dash-lg addmore'></i>
                              </div>
                            ) : (
                              <div>
                                <i style={{ float: 'right' }} onClick={toggleExpand} className='bi bi-plus-lg addmore'></i>
                              </div>
                            )}
                          </div>

                        </div>
                        {isExpanded ? (
                          <div>
                            {/* show all locations */}

                            {positionLocations ? (
                              positionLocations.map((entity, index) => {
                                return (
                                  <div className='form-row d-flex mt-4 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                                    <div className='form-group col-md-6'>
                                      <label className='form-label labelse '>
                                        Location <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                      </label>
                                      <div className='me-4'>
                                        <input
                                          style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                          className='form-control bg-white w-140px'
                                          value={entity?.location}
                                          required
                                        />
                                      </div>{' '}
                                    </div>

                                    <div className='form-group col-md-6'>
                                      <label className='form-label labelse '>
                                        {' '}
                                        Avaliable Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                      </label>
                                      <div className='me-4 '>
                                        <input
                                          style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                          className='form-control bg-white w-140px'
                                          value={entity?.no_of_positions}
                                          required
                                        />
                                      </div>{' '}
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {/* show only 1 location */}
                            {positionLocations && positionLocations.length > 0 ? (
                              <div>
                                <div className='form-row d-flex mt-4 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                                  <div className='form-group col-md-6'>
                                    <label className='form-label labelse '>
                                      Location <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                    </label>
                                    <div className='me-4'>
                                      <input
                                        style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                        className='form-control bg-white w-140px'
                                        value={positionLocations[0]?.location}
                                        required
                                      />
                                    </div>{' '}
                                  </div>

                                  <div className='form-group col-md-6'>
                                    <label className='form-label labelse '>
                                      {' '}
                                      Avaliable Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                    </label>
                                    <div className='me-4 '>
                                      <input
                                        style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                        className='form-control bg-white w-140px'
                                        value={positionLocations[0]?.no_of_positions}
                                        required
                                      />
                                    </div>{' '}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (

                      <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                        <div className='form-group col-md-6'>
                          <label className='form-label labelse '>Location</label>
                          <div className='me-4'>
                            <div className='form-control w-140px' style={{ cursor: 'default' }}>
                              {currentJob?.location}
                            </div>
                          </div>{' '}
                        </div>

                        <div className='form-group col-md-6'>
                          <label className='form-label labelse '> Available Positions</label>
                          <div className='me-4'>
                            <div className='form-control w-140px' defaultValue='Marketing' style={{ cursor: 'default' }}>
                              {currentJob?.position_available ? currentJob?.position_available : 0}
                            </div>
                          </div>{' '}
                        </div>
                      </div>)
                    }

                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Valid From</label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              selected={currentJob?.valid_from ? new Date(currentJob?.valid_from) : new Date()}
                              onChange={(date) => handleChangeDateFrom(date)}
                              minDate={new Date()}
                            />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Valid To</label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              selected={currentJob?.valid_till ? new Date(currentJob?.valid_till) : new Date()}
                              onChange={(date) => handleChangeDateTo(date)}
                              minDate={new Date()}
                            // readOnly={true}
                            />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <label className='form-label  fs-6 mt-3 fw-bolder '> Salary Range</label>
                    </div>

                    <div className='form-row d-flex justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3 '> Minimum Amount</label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            type='text'
                            className='form-control bg-white'
                            id=''
                            placeholder='0'
                            autoComplete='off'
                            value={currentJob?.salary_range_from ? currentJob?.salary_range_from : 0}
                            max={currentJob?.salary_range_till ? parseInt(currentJob?.salary_range_till) : 0}
                            min={0}
                            onBlur={(e) => {
                              const enteredValue = e.target.value.replace(/,/g, '') // Remove commas from the entered value
                              const parsedValue = parseInt(enteredValue)
                              const parsedMaxSalary = parseInt(maxBasicSalary)
                              console.log(`amountsInfo: minValue: ${parsedValue}   ::  maxValue: ${parsedMaxSalary}`)
                              if (parsedValue >= parsedMaxSalary) {
                                setMessage('Entered Minimum Amount should be less than Maximum Amount.')
                                setShowErrorMessage(true)
                                let jobData = { ...currentJob }
                                jobData.salary_range_from = '0'
                                setCurrentJob(jobData)
                              }
                            }}
                            onChange={(e) => {
                              let maxValue = currentJob?.salary_range_till ? parseInt(currentJob?.salary_range_till) : 0
                              let enteredValue = e.target.value
                              enteredValue = enteredValue.replace(/,/g, '')

                              if (/^\d*$/.test(enteredValue)) {
                                if (parseInt(enteredValue) <= maxValue || enteredValue === '') {
                                  let jobData = { ...currentJob }
                                  jobData.salary_range_from = enteredValue
                                  setCurrentJob(jobData)
                                } else {
                                  if (parseInt(enteredValue) >= 0 && parseInt(enteredValue) <= maxBasicSalary) {
                                    let jobData = { ...currentJob }
                                    jobData.salary_range_from = enteredValue
                                    setCurrentJob(jobData)
                                  }
                                }
                              }
                            }}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3'> Maximum Amount</label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            type='text'
                            className='form-control bg-white'
                            id=''
                            placeholder='0'
                            autoComplete='off'
                            max={maxBasicSalary}
                            min={0}
                            value={currentJob?.salary_range_till ? currentJob?.salary_range_till : 0}
                            onBlur={(e) => {
                              const enteredValue = e.target.value.replace(/,/g, '') // Remove commas from the entered value
                              const parsedValue = parseInt(enteredValue)
                              const parsedMinSalary = parseInt(minSalary)

                              if (parsedValue < parsedMinSalary) {
                                setMessage('Entered Maximum Amount should be greater than Minimum Amount.')
                                setShowErrorMessage(true)
                                let jobData = { ...currentJob }
                                jobData.salary_range_till = '0'
                                setCurrentJob(jobData)
                              }
                            }}
                            onChange={(e) => {
                              let minSalary = currentJob?.salary_range_from ? parseInt(currentJob?.salary_range_from) : 0
                              let enteredValue = e.target.value
                              enteredValue = enteredValue.replace(/,/g, '')
                              if (/^\d*$/.test(enteredValue)) {
                                if (parseInt(enteredValue) <= maxBasicSalary || enteredValue === '') {
                                  let jobData = { ...currentJob }
                                  jobData.salary_range_till = enteredValue
                                  setCurrentJob(jobData)
                                } else {
                                  if (!minSalary) {
                                    if (parseInt(enteredValue) >= 0 && parseInt(enteredValue) <= maxBasicSalary) {
                                      let jobData = { ...currentJob }
                                      jobData.salary_range_till = enteredValue
                                      setCurrentJob(jobData)
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem', alignItems: 'end' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Link Test</label>
                        <div className='me-4'>
                          {/* test can not be change its requirement,  that is why its disabled */}
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            style={{ cursor: 'pointer' }}
                            defaultValue='Link Test to Job'
                            onChange={(event) => chanlinktest(event.target.options[event.target.selectedIndex].getAttribute('id'))}
                            value={getTestName(currentJob?.test_id)}
                            disabled={true}
                          >
                            <option style={{ cursor: 'pointer' }} value='null'>
                              {' '}
                              None
                            </option>
                            {linktest.map((option) => (
                              <option style={{ cursor: 'pointer' }} key={option['test_id']} id={option['test_id']} value={option['name']}>
                                {option['name']}
                              </option>
                            ))}
                          </select>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Online Test Deadline</label>
                        <div className='me-4 w-140px'>
                          <select
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select w-140px'
                            id='testValidDays'
                            value={currentJob?.online_test_deadline}
                            onChange={handleTestDeadLine}
                            style={{ cursor: 'pointer' }}
                            required
                            disabled={true}
                          >
                            <option style={{ cursor: 'pointer' }} value={0}>
                              No of Days
                            </option>
                            {days.map((day) => (
                              <option style={{ cursor: 'pointer' }} key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='form-row d-flex my-10 justify-content-spacearound align-items-center' style={{ marginLeft: '-2rem' }}>
                      <div className='col-md-6'>
                        <input
                          className='me-2 form-check-input'
                          type='checkbox'
                          id='videoResume'
                          // checked={currentJob?.video_resume}
                          checked={currentJob?.video_resume_deadline ? true : false}
                          onChange={handleVideoResumeChange}
                        />
                        <label className='form-label fs-6 fw-bolder' htmlFor='videoResume'>
                          Include Video Resume
                        </label>
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Video Resume Deadline</label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex w-140px'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              // selected={currentJob?.video_resume_deadline || new Date()}
                              // selected={resumeDeadLine}
                              selected={currentJob?.video_resume_deadline ? new Date(currentJob?.video_resume_deadline) : null}
                              onChange={handleResumeDeadLineChange}
                            />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row d-flex justify-content-spacearound me-12'>
                      <textarea
                        onChange={(event) => changeVideoResumeDescription(event.target.value)}
                        className='form-control'
                        id='videoResume'
                        value={currentJob?.video_resume}
                      ></textarea>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <label className='form-label labelse '> How many interview Stages this Job Post has?</label>
                    </div>

                    <div className='form-row d-flex mt-2 justify-content-spacearound'>
                      <div
                        className={`${currentJob?.stage == '1' ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage1Clicked')}
                      >
                        <span className=''>
                          <span>{currentJob?.stage == '1' ? <img src={stage1Clicked}></img> : <img src={stage1}></img>}</span>
                          &nbsp; Stage 1
                        </span>
                      </div>

                      <div
                        className={`${currentJob?.stage == '2' ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage2Clicked')}
                      >
                        <span className=''>
                          <span>{currentJob?.stage == '2' ? <img src={stage2Clicked}></img> : <img src={stage2}></img>}</span>
                          &nbsp; Stage 2
                        </span>
                      </div>

                      <div
                        className={`${currentJob?.stage == '3' ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage3Clicked')}
                      >
                        <span className=''>
                          <span>{currentJob?.stage == '3' ? <img src={stage3Clicked}></img> : <img src={stage3}></img>}</span>
                          &nbsp; Stage 3
                        </span>
                      </div>
                    </div>

                    <button type='submit' className='btn my-10 px-10 bgcolorbuttonpost my-5'>
                      Update
                    </button>
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>

            <div style={{ margin: '0px 0px 0px 0px' }} className='col-xl-4'>
              {' '}
              <div className='px-6 py-6'>
                {/* begin::Beader */}

                <span className='card-label fs-6 fw-bolder ' style={{ margin: '0px 0px 0px 4px' }}>
                  Competencies Required
                </span>

                <div >
                  <div className=' d-flex flex-wrap mb-5' >
                    {currentJob?.competenices ? (
                      Object.values(JSON.parse(currentJob?.competenices)[0]).map((competency: any, index) => {
                        return (
                          <a style={{ margin: '10px 0px 0px 0rem' }}>
                            <span
                              style={{ color: ' #F57D42', backgroundColor: '#FFEBCE' }}
                              className='card-label fontweigt fs-5 fw-bold px-4 py-3 d-flex align-items-center'
                              key={index}
                            >
                              <label className='me-2' style={{ maxWidth: '12rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{competency.name} </label>
                              <span>
                                <img
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    removeItem(index)
                                  }}
                                  src={cross}
                                ></img>
                              </span>
                            </span>
                          </a>
                        )
                      })
                    ) : (
                      <div></div>
                    )}

                    {/* <p className='text-muted'>8th August 2022</p> */}
                  </div>
                </div>

                {/* end::Body */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card-bg shadow-sm p-6'>
            {/* begin::Beader */}
            <div className='d-flex flex-wrap flex-stack '>
              <h4 className='fw-bolder'>Competencies Dictionary</h4>
            </div>

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <div>
                {' '}
                <span>
                  <label className='form-label fs-7 mt-4 fw-semibold '>Click to add</label>
                </span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', flexWrap: 'wrap', maxWidth: '100%' }}>
                {CompArrayIndiviual ? (
                  CompArrayIndiviual.map((competency, index) => (
                    <div className='mb-2 me-2' key={index}>
                      <span
                        onClick={() => addCompetency(competency)}
                        style={{ color: '#666666', backgroundColor: '#F4F8FA', cursor: 'pointer' }}
                        className='grey-chip fs-5 fw-bold px-4 py-3 align-items-center d-flex'
                      >
                        <span>
                          <img src={plusimagecom}></img>
                        </span>
                        <label className='ms-2' style={{ maxWidth: '12rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> {competency.name} </label>
                      </span>
                    </div>
                  ))
                ) : (
                  <div></div>
                )}

                {/* <p className='text-muted'>8th August 2022</p> */}
              </div>
            </div>

            {/* end::Body */}
          </div>{' '}
        </div>
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default UpdateJobs

const CustomDatePickDiv = styled.div`
  background-color: white;
  border: solid 0.1em #ffff;
  border-radius: 0.25em;
  padding: 0.3em 1.6em 0 1.6em;
`
const DatePickerDiv = styled.div`
  position: relative;
`
