//import liraries
import React, { useEffect, useState } from 'react';

import '../../Settings.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useAuth } from '../../../auth/core/Auth';
import axios from 'axios';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../../constants/imagePath';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_OFFER_LETTER_CONTENT_URL = `${API_URL}/configuration/delete-offer_letter_format`;
const ADD_OFFER_LETTER_CONTENT_URL = `${API_URL}/configuration/add-offer_letter_format`;


// create a component
const OfferLetterContentCreator = ({ configurationId, offer_letter_format, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [offerLetterContent, setOfferLetterContent] = useState('')
    const [disabled, setDisabled] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if(offer_letter_format){
        let offerLetterText = JSON.parse(offer_letter_format)
        setOfferLetterContent(offerLetterText);
        }
    }, [offer_letter_format]);

    const changeJobDescription = async (content) => {
        if (content) {
            setOfferLetterContent(content);
        }
    }

    const handleSaveClick = async (event) => {
        event.preventDefault();

        if (offerLetterContent) {

            setDisabled(true);
            let data = {
                "configuration_id": configurationId,
                "offer_letter_format": offerLetterContent
            }

            await axios.post(ADD_OFFER_LETTER_CONTENT_URL, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                setDisabled(false);

                // SHOW SUCCESS POPUP
                setMessage('Offer letter content saved successfully!');
                setShowSuccessPopup(true);
            }).catch((error) => {
                setDisabled(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });
        }

    }



    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative px-8'>
            <div>
                <label className='form-label labelse fs-5 mb-4'>Offer Letter Content</label>
            </div>

            <ReactQuill
                value={offerLetterContent}
                onChange={changeJobDescription}
                placeholder="Enter your job description"
                modules={{
                    toolbar: [
                        [{ header: [1, 2, 3, 4, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        [{ align: [] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link'],
                        ['clean'],
                    ],
                }}
                formats={[
                    'header',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'color',
                    'background',
                    'align',
                    'list',
                    'bullet',
                    'link',
                ]}
                style={{ height: '220px', marginBottom: '40px', position: 'relative', zIndex: '1' }}
            />

            <div id='btnView' className='d-flex justify-content-center'>
                <div
                    className='candi-btn2 text-white m-5'
                    onClick={(e) => {
                        if (!disabled) {
                            handleSaveClick(e)
                        }
                    }}
                // disabled={disabled}
                >
                    <span style={{ fontWeight: 'bold' }}>Save</span>
                </div>
            </div>

            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_msg_orange} style={{ width: '220px', height: '220px', opacity: '0.08', zIndex: '-1' }} />
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default OfferLetterContentCreator;
