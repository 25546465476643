//import liraries
import React from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import './CreateCompetency.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import axios from 'axios'
import imagePath from '../../../../constants/imagePath';
import LoaderView from './LoaderView';
import ShowError from '../../content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL

const UPDATE_FUEL_PRICE_URL = `${API_URL}/panel/change-panel-status`;

type Props = {
    show: boolean
    price: string
    handleClose: () => void
    handleSaveFuelPrice: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body


// create a component
const PriceUpdateWarningPopup = ({show, price, handleClose, handleSaveFuelPrice }: Props) => {
    const { currentUser, auth, logout } = useAuth()
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className=' no-header-model123'>
                <div className='modal-header no-header-model123'>
                    <h2></h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                        <img alt='' src={imagePath.ic_close}></img>
                    </div>
                </div>
                {/* end::Close */}
                <div className='mx-auto d-flex flex-column'>
                    <img alt='' className='add-warning-image' src={addcompetenctimage}></img>
                    <h3 className='fw-bolder mx-auto mt-12 mw-50 text-center'>{`Do you want to update fuel price to `} <span style={{ color: '#F36523', marginLeft: '4px' }}>{`${price} pkr`}</span> ?</h3>
                </div>
            </div>

            <div className=' px-lg-8'>
                <div >
                    {/* begin::Beader */}

                    <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                        <form>
                            
                            <div className='d-flex align-items-center my-6 justify-content-center'>
                                <button onClick={handleSaveFuelPrice}
                                    type='button'
                                    className=' candi-btn1 fs-5 fw-bolder py-4 px-10 me-6'>
                                    Save
                                </button>
                                <button onClick={handleClose} className='btn fs-5 candi-btn2 text-white fw-bolder my-5 py-4 px-8 '>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && (
                    <LoaderView message='Loading...' />
                )}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </div>
        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default PriceUpdateWarningPopup;
