//import liraries
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth';
import imagePath from '../../../../constants/imagePath';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Pagination from 'react-bootstrap/Pagination';
import CustomPagination from './CustomPagination';
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';

const API_URL = process.env.REACT_APP_API_URL
export const TIME_TO_HIRE_URL = `${API_URL}/job/avg-time-to-hire`

// const ITEMS_PER_PAGE = 5; // Set the number of items you want to display per page

type Props = {
    taLead: any
    recruiter: any
}

// create a component
const ActionsAtTATTable = ({ taLead, recruiter }: Props) => {
    const { currentUser, auth } = useAuth();
    const [allRecruitersData, setAllRecruitersData] = useState<any>([]);
    const [filteredRecruiters, setFilteredRecruiters] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [selectedRange, setSelectedRange] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleDateRangeChange = (event, picker) => {
        setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        let firstDate = picker.startDate.format('MM/DD/YYYY');
        let lastDate = picker.endDate.format('MM/DD/YYYY');
        setStartDate(firstDate);
        setEndDate(lastDate);
    };

    const handleCancelDatePicker = () => {
        setSelectedRange('');
        setStartDate('');
        setEndDate('');
    }

    const getHiringData = async () => {
        // let recruiter_id = (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') ? 'admin' : currentUser?.user_id
        // let url = `${TIME_TO_HIRE_URL}?recruiter_id=${recruiter_id}`;

        let url = TIME_TO_HIRE_URL;
        if (recruiter) {
            url = `${url}?recruiter_id=${recruiter?.user_id}`;
        } else if (taLead) {
            url = `${url}?ta_lead_id=${taLead?.user_id}`;
        } else {
            if (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') {
                url = `${url}?recruiter_id=admin`;
            } else if (currentUser?.role === 'ta_lead') {
                url = `${url}?ta_lead_id=${currentUser?.user_id}`;
            } else if (currentUser?.role === 'recruiter') {
                url = `${url}?recruiter_id=${currentUser?.user_id}`;
            } else {
                return;
            }
        }
        if (startDate !== null && startDate !== undefined && startDate !== '' &&
            endDate !== null && endDate !== undefined && endDate !== '') {
                url = `${url}&start_date=${startDate}&end_date=${endDate}`
        }
        await axios.get(url, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            console.log('applicationsList: ', res.data);
            let data = res?.data;
            let averageTimeToHire = data?.AverageTimeToHire;

            let dataList: any = [];
            let index = 0;
            for (let recruiterName in averageTimeToHire) {
                index++;
                const recruiterData = averageTimeToHire[recruiterName];
                dataList.push({
                    rank: index,
                    recruiterName: recruiterName,
                    closedWithin30Days: recruiterData.closedWithin30Days,
                    department: recruiterData.department,
                    profileImage: recruiterData.profileImage
                });
            }
            if (dataList) {
                setAllRecruitersData(dataList);
                setFilteredRecruiters(dataList);
            }

        }).catch((err) => {
            console.error('err', err);
        });
    }

    useEffect(() => {
        (async () => {
            await getHiringData();
        })()
    }, [taLead, recruiter, startDate]);

    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
        const sorted = [...filteredRecruiters].sort((a, b) => {
            const valueA = a[field];
            const valueB = b[field];
            if (valueA < valueB) return direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setFilteredRecruiters(sorted);
        setSortField(field);
        setSortDirection(direction);
    };

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
        } else {
            return <FaSort />;
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredRecruiters.slice(indexOfFirstItem, indexOfLastItem);

    const handleDownloadFile = () => {
        // Manually specify columns
        const columns = ['Rank', 'Recruiters', 'Groups', 'Actions In TAT'];

        // Manually create data array
        const data = currentItems.map((row) => [
            row?.rank || '',
            row?.recruiterName || '',
            row?.department || '',
            row?.closedWithin30Days || '',
        ]);

        // Create worksheet
        const ws = XLSX.utils.aoa_to_sheet([columns, ...data]);

        // Create workbook and add the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Avg. Actions In TAT');

        // Download the Excel file
        XLSX.writeFile(wb, 'Average Actions In TAT.xlsx');
    };

    const handleSearch = (value) => {
        setSearchKey(value);

        if (value) {
            const filtered = allRecruitersData.filter((data) =>
                data.recruiterName.toLowerCase().includes(value.toLowerCase()) ||
                data.department.toLowerCase().includes(value.toLowerCase()) ||
                data.closedWithin30Days.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredRecruiters(filtered);
        } else {
            setFilteredRecruiters(allRecruitersData);
        }
    }

    return (
        <div >
            <div className='d-flex mb-4 '>
                <div className='d-flex align-items-center'>
                    <img src={imagePath.ic_timer_fill_gray_bg} alt='position' width={24} height={24} />
                    <label className=' fs-4 fw-bolder ms-2 me-2'>Avg. Actions In TAT</label>
                </div>
                <div className='ms-auto d-flex align-items-center'>
                    <div className='d-flex me-4' style={{ border: 'solid 1px #F57536', borderRadius: '4px', height: '32px' }}>
                        <input
                            style={{ width: '16rem' }}
                            className='search-bar fs-6 ps-2 py-1'
                            type='text'
                            placeholder='Search'
                            value={searchKey}
                            onChange={(event) => handleSearch(event.target.value)}
                        />
                        <img className="search-icon" src={imagePath.ic_search_orange} />
                    </div>
                    <DateRangePicker onApply={handleDateRangeChange}
                        onCancel={handleCancelDatePicker}>
                        <img src={imagePath.ic_calendar_orange} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px', cursor: 'pointer' }} />
                    </DateRangePicker>
                    <img className='ms-4' src={imagePath.ic_download} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px', padding: '5px', cursor: 'pointer' }}
                        onClick={() => handleDownloadFile()} title='Download Report' />
                    <img className='ms-2' style={{ cursor: 'pointer' }} src={imagePath.ic_refresh_orange} width={24} height={24}
                        onClick={() => getHiringData()} />
                </div>
            </div>

            <div className='table-responsive' style={{ height: '29.75rem' }}>
                {/* begin::Table */}
                <table className='table align-middle gs-0 gy-4 table-borders'>
                    {/* begin::Table head */}
                    <thead className='my-10'>
                        <tr className='fw-bold graycolor backgik bg-light'>
                            <th onClick={() => sortByField('rank')}
                                className='align-items-center'
                                style={{ minWidth: '6rem' }}
                                scope='col'  >
                                <label className=' form-label fw-bolder fs-6 me-2 ps-2'>
                                    Rank
                                </label>
                                {renderSortIcon('rank')}
                            </th>
                            <th onClick={() => sortByField('recruiterName')}
                                className='align-items-center'
                                style={{ minWidth: '14rem' }}
                                scope='col'  >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Recruiters
                                </label>
                                {renderSortIcon('recruiterName')}
                            </th>
                            <th onClick={() => sortByField('closedWithin30Days')}
                                className='align-items-center'
                                style={{ minWidth: '14rem' }}
                                scope='col'  >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Actions In TAT
                                </label>
                                {renderSortIcon('closedWithin30Days')}
                            </th>

                        </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {(currentItems && currentItems.length > 0) ? currentItems.map((data, index) => {
                            const isEvenRow = index % 2 === 0;
                            return (
                                <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                                    <td>
                                        <a href='#' className='graycolor my-2 mx-3 text-hover-primary fs-6 px-5'>
                                            {data?.rank}{' '}
                                        </a>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-45px me-5'>
                                                <img
                                                    className='rounded-circle'
                                                    style={{ objectFit: 'cover', border: 'solid #F36523 1px', borderRadius: '50%' }}
                                                    src={data?.profileImage ? data?.profileImage : imagePath.userIcon}
                                                    alt=''
                                                />
                                            </div>
                                            <div className='d-flex justify-content-start flex-column'>
                                                <a href='#' className='text-dark fw-bolder my-2 text-hover-primary fs-6'>
                                                    {data?.recruiterName}
                                                </a>
                                                <span className='graycolor fw-semibold graycolor d-block fs-7'>
                                                    {data?.department}
                                                </span>
                                            </div>
                                        </div>
                                    </td>

                                    <td className='margin-xxx'>
                                        <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3'>
                                            {data?.closedWithin30Days}
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : (<div></div>)}

                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}
                {/* <Pagination>
                    {Array.from({ length: Math.ceil(filteredRecruiters.length / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination> */}
            </div>

            <div className='d-flex col-md-12 align-items-center'>
                <div className='col-md-4'>
                    <label>{`Showing ${currentItems ? currentItems.length : 0} of ${allRecruitersData?.length} results.`}</label>
                </div>
                <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                    <CustomPagination
                        pageValue={currentPage}
                        totalPages={Math.ceil(filteredRecruiters.length / itemsPerPage)}
                        onPageChange={handlePageChange}
                    />
                </div>

                <div className='col-md-4 '>
                    <div className='ms-auto d-flex flex-end align-items-center'>
                        <select
                            className='me-2 pagination-input'
                            style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                            id='selectValues'
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </select>
                        <div className='me-2'>
                            <label>Items per page</label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

//make this component available to the app
export default ActionsAtTATTable;
