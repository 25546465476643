import React, {useEffect, useState} from 'react'
import CircularProgressWithLabel from '../Dashboard/comps/CircularWIthLabel'
import VacanCircularProgressWithLabel from './comps/VacanCircularWIthLabel'
import ApplyNowTab from './ApplyNowTab'
import exp_icon from './assets/experience@2x.png'
import './vacancies.styles.css'
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser'

import axios from 'axios'
import {useAuth} from '../../auth/core/Auth'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {getFormattedDate} from '../../../../constants/CommonFunctions'
import CustomPagination from '../../dashboards/components/CustomPagination'
const API_URL = process.env.REACT_APP_API_URL
// export const VACANCIES_API_URL = `${API_URL}/job/show-actives`
export const VACANCIES_API_URL = `${API_URL}/job/show-status-jobs`
export const GET_BY_JOB_ID = `${API_URL}/job/`
const GET_ALL_GROUPS = `${API_URL}/group`
const GET_ALL_LOCATIONS_URL = `${API_URL}/group/get-Location-Filter`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`

const VacanciesCandidateMain = (props) => {
  const {currentUser, auth, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [allGroups, setAllGroups] = useState<any>([])
  const [allLocations, setAllLocations] = useState([])
  const [isGroupMenuOpen, setIsGroupMenuOpen] = useState(false)
  const [isLocationMenuOpen, setIsLocationMenuOpen] = useState(false)
  const [isGradeMenuOpen, setIsGradeMenuOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [grades, setGrades] = useState([])
  const [vacancies, setVacancies] = useState<any>('')
  const [competenices, setCompetenices] = useState<any>('')
  const [jobDesc, setJobDesc] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [pageRefreshedAt, setPageRefreshedAt] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    job_status: 'active',
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    group_id: '',
    location: '',
    grade: '',
  })

  const getAllVacancies = async () => {
    let data = {...params}
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    filteredParams.candidate_id = currentUser?.user_id
    setLoading(true)

    await axios
      .get(VACANCIES_API_URL, {
        // params: {
        //   job_status: 'active',
        //   candidate_id: currentUser?.user_id,
        // },
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        // console.log("VACANCIES: ", response.data)
        setLoading(false)

        let paginationData = response.data?.pagination
        if (params.page !== paginationData.page || params.limit !== paginationData.limit || params.total !== paginationData.total) {
          let paramsData = {...params}
          paramsData.page = paginationData?.page || 1
          paramsData.limit = paginationData?.limit || 10
          paramsData.total = paginationData?.total || 0
          setParams(paramsData)
        }
        setTotalPages(paginationData?.totalPages || 0)

        let data = response.data.data
        if (data) {
          let jobs: any = []
          let locations: any = []
          let gradesList: any = []
          let groups: any = []
          for (let i = 0; i < data.length; i++) {
            // let jobData = data[i]?.job;
            let jobData = data[i]
            if (jobData) {
              jobs.push(jobData)

              // create list of locations
              const location = jobData.location
              if (location) {
                const locationExists = locations.includes(location)
                // Add location to the list if it doesn't exist
                if (!locationExists) {
                  locations.push(location)
                }
              }
              // create list of grades
              const grade = jobData.grade
              if (grade) {
                const gradeExists = gradesList.includes(grade)
                // Add location to the list if it doesn't exist
                if (!gradeExists) {
                  gradesList.push(grade)
                }
              }
              // create list of groups
              const group = jobData.groupName
              if (group) {
                const groupExists = groups.includes(group)
                if (!groupExists) {
                  groups.push(group)
                }
              }
            }
          }
          setVacancies(jobs)
          // setAllLocations(locations)
          // setGrades(gradesList)
          // setAllGroups(groups)
        }
        const currentDate = new Date()
        const month = currentDate.toLocaleString('default', {month: 'short'})
        const day = currentDate.getDate()
        const hour = currentDate.getHours()
        const minute = currentDate.getMinutes()
        const formattedDateTime = `Last Updated at: ${hour}:${minute.toString().padStart(2, '0')} - ${day} ${month}`
        setPageRefreshedAt(formattedDateTime)
      })
      .catch((error) => {
        setLoading(false)
        handleErrors(error)
      })
  }

  // Fetching list of groups
  const getGroups = async () => {
    try {
      const response = await axios.get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })

      setAllGroups(response.data.data)
    } catch (error) {
      // Consolidated error handling
      handleErrors(error)
    }
  }

  const getAllLocations = async () => {
    await axios
      .get(GET_ALL_LOCATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // setAllCodes(res.data.sections);
        let data = res.data.data

        const uniqueData = data.filter((item, index, self) => {
          return index === self.findIndex((t) => t.location_code === item.location_code)
        })

        setAllLocations(uniqueData)
      })
      .catch((err) => {
        handleErrors(err)
      })
  }

  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setGrades(Object.values(data))
          }
        }
      })
      .catch((err) => {
        handleErrors(err)
      })
  }

  // Handle different types of errors
  const handleErrors = (error) => {
    if (error.response) {
      const {status, data} = error.response

      if (status === 400) {
        setMessage(data.message)
      } else if (status === 401 && data.name === 'TokenExpiredError') {
        logout()
      } else if (data.error) {
        if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(data.error.message)
        }
      } else {
        setMessage('An unknown error occurred')
      }
    } else {
      setMessage(error.message)
    }

    setShowErrorMessage(true)
  }

  // api call
  useEffect(() => {
    ;(async () => {
      await getAllVacancies()
    })()
  }, [params])

  useEffect(() => {
    ;(async () => {
      getGroups()
      getAllLocations()
      getGrades()
    })()
  }, [])

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    setIsGroupMenuOpen(false)
    setIsGradeMenuOpen(false)
    setIsLocationMenuOpen(false)
    // if (selectedFilters.length > 0) {

    // Extract group, location and grade values from selectedFilters array
    const groups = selectedFilters.filter((filter) => filter.type === 'group').map((filter) => filter.value)
    const locations = selectedFilters.filter((filter) => filter.type === 'location').map((filter) => filter.value)
    const grades = selectedFilters.filter((filter) => filter.type === 'grade').map((filter) => filter.value)

    // Join groups, locations and grades values into comma-separated strings
    const groupsString = groups.join(',')
    const locationsString = locations.join(',')
    const gradesString = grades.join(',')

    // Update params state with new role and group values
    setParams((prevParams) => ({
      ...prevParams,
      group_id: groupsString,
      location: locationsString,
      grade: gradesString,
    }))

    // } else {
    //   // check show all vacancies
    //   // getAllVacancies();
    // }
  }

  const filterChange = (filterValue, type, groupName) => {
    setIsGroupMenuOpen(false)
    setIsGradeMenuOpen(false)
    setIsLocationMenuOpen(false)
    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)

    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, {type: type, value: filterValue, groupName: groupName}])
    }
  }
  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    filterItems()
  }

  const getMultipleLocations = (batchPositions) => {
    let locations = ''
    if (batchPositions) {
      locations = batchPositions.map((position) => position.location).join(', ')
    }
    return locations
  }

  const toggleGroupMenu = () => {
    setIsGroupMenuOpen(!isGroupMenuOpen)
  }
  const toggleLocationMenu = () => {
    setIsLocationMenuOpen(!isLocationMenuOpen)
  }
  const toggleGradeMenu = () => {
    setIsGradeMenuOpen(!isGradeMenuOpen)
  }

  const handlePageChange = (page) => {
    let data = {...params}
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = {...params}
    data.limit = event.target.value
    setParams(data)
  }

  return (
    <div>
      <div className='dashboard-top'>
        <h1>Dashboard</h1>
        <div className='d-flex align-items-center'>
          <h3>
            <i className='bi bi-house text-white' style={{fontSize: '1.5rem'}}></i>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/candidatedashboard' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              My Dashboard
            </Link>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/mainvacancies' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              Vacancies
            </Link>
          </h3>
          <h3 className='ms-auto'>
            <i className='bi bi-arrow-clockwise text-white'></i> {pageRefreshedAt}
          </h3>
        </div>
      </div>

      <div className='card-bg shadow-sm'>
        <div className='d-flex align-items center p-8'>
          <h3 className='fs-3 fw-bolder me-4'>Vacancies</h3>
          {/* filters view */}
          <div className='d-flex align-items-center ms-auto'>
            <div className={clsx('d-flex align-items-center')} id='kt_filter_group_menu_toggle'>
              <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                // data-kt-menu-placement='bottom-end'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom'
                onClick={toggleGroupMenu}
                // style={{ width: '13.5rem' }}
              >
                <label className='filter-btn fs-5 fw-bolder'>
                  Filter by Group
                  <img style={{marginLeft: '4px'}} src={imagePath.ic_filter_orange} width={14} height={14} />
                </label>
              </div>
              <div
                // className='filter-menu role-filter-menu'
                className={clsx(
                  ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 ',
                  {
                    show: isGroupMenuOpen,
                  }
                )}
                style={{width: '320px', maxWidth: '350px'}}
                data-kt-menu='true'
              >
                <div style={{listStyleType: 'none', cursor: 'pointer', maxHeight: '20rem', overflow: 'auto'}}>
                  {allGroups &&
                    allGroups.map((group, index) => {
                      const groupValue = group?.group_id
                      const isChecked = selectedFilters.some((filter) => filter.type === 'group' && filter.value === groupValue)
                      return (
                        <div
                          className=' hoverable-text mb-4 px-4'
                          style={{cursor: 'pointer', display: 'flex', alignItems: 'flex-start'}}
                          key={index}
                        >
                          <input
                            name='groupFilter'
                            className='form-check-input checkbox-style me-2'
                            type='checkbox'
                            // value={termsChecked}
                            checked={isChecked}
                            onChange={(event) => filterChange(group?.group_id, 'group', group?.group_name)}
                          />
                          <label className='form-check-label fs-6 ' style={{color: 'black'}}>
                            {group?.group_name}
                          </label>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>

            <div className={clsx('d-flex align-items-center')} id='kt_filter_location_menu_toggle'>
              <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                // data-kt-menu-placement='bottom-end'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom'
                onClick={toggleLocationMenu}
                // style={{ width: '14.5rem' }}
              >
                <label className='filter-btn fs-5 fw-bolder mx-4'>
                  Filter by Location
                  <img style={{marginLeft: '4px'}} src={imagePath.ic_filter_orange} width={14} height={14} />
                </label>
              </div>

              <div
                // className='filter-menu role-filter-menu'
                className={clsx(
                  ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 ',
                  {
                    show: isLocationMenuOpen,
                  }
                )}
                style={{width: '280px', maxWidth: '300px'}}
                data-kt-menu='true'
              >
                <div style={{listStyleType: 'none', cursor: 'pointer', maxHeight: '20rem', overflow: 'auto'}}>
                  {allLocations.map((location, index) => {
                    const locationValue = location['location']
                    const isChecked = selectedFilters.some((filter) => filter.type === 'location' && filter.value === locationValue)
                    return (
                      <div
                        className=' hoverable-text mb-4 px-4'
                        style={{cursor: 'pointer', display: 'flex', alignItems: 'flex-start'}}
                        key={index}
                      >
                        <input
                          name='groupFilter'
                          className='form-check-input checkbox-style me-2'
                          type='checkbox'
                          // value={termsChecked}
                          checked={isChecked}
                          onChange={(event) => filterChange(location['location'], 'location', '')}
                        />
                        <label className='form-check-label fs-6 ' style={{color: 'black'}}>
                          {location['location']}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className={clsx('d-flex align-items-center')} id='kt_filter_grade_menu_toggle'>
              <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                // data-kt-menu-placement='bottom-end'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom'
                onClick={toggleGradeMenu}
                // style={{ width: '14.5rem' }}
              >
                <label className='filter-btn fs-5 fw-bolder '>
                  Filter by Grade
                  <img style={{marginLeft: '4px'}} src={imagePath.ic_filter_orange} width={14} height={14} />
                </label>
              </div>
              <div
                // className='filter-menu role-filter-menu'
                className={clsx(
                  ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 ',
                  {
                    show: isGradeMenuOpen,
                  }
                )}
                style={{width: '260px', maxWidth: '280px'}}
                data-kt-menu='true'
              >
                <div style={{listStyleType: 'none', cursor: 'pointer', maxHeight: '20rem', overflow: 'auto'}}>
                  {grades.map((grade, index) => {
                    const isChecked = selectedFilters.some((filter) => filter.type === 'grade' && filter.value === grade)
                    return (
                      <div
                        className=' hoverable-text mb-4 px-4'
                        style={{cursor: 'pointer', display: 'flex', alignItems: 'flex-start'}}
                        key={index}
                      >
                        <input
                          name='groupFilter'
                          className='form-check-input checkbox-style me-2'
                          type='checkbox'
                          // value={termsChecked}
                          checked={isChecked}
                          onChange={(event) => filterChange(grade, 'grade', '')}
                        />
                        <label className='form-check-label fs-6 ' style={{color: 'black'}}>
                          {grade}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex mx-6 mb-6 pb-4' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
          {selectedFilters ? (
            selectedFilters.map((filterData, index) => {
              return (
                <div key={index} className='d-flex orange-tag align-items-center me-4' style={{height: '34px'}}>
                  {filterData?.type === 'group' ? filterData?.groupName : filterData?.value}
                  <img
                    className='ms-2'
                    style={{cursor: 'pointer'}}
                    src={imagePath.ic_cross_orange}
                    onClick={() => removeFilter(filterData.value, filterData.type)}
                  />
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>
        <div className='px-8'>
          {vacancies && vacancies.length > 0 ? (
            vacancies.map((val, index) => {
              // let val = item.job;
              let requiredCompetencies = JSON.parse(val.competenices)
              const batchPositions = val?.batchPositions
              return (
                <div
                  key={index}
                  // className='vacan-card-body row'
                  className='card-bg shadow-sm p-6 mb-10'
                >
                  <div className='d-flex col-md-12'>
                    <div className='col-md-3 left-vacan'>
                      <label className='job-title mb-6' style={{marginTop: '-2px', paddingTop: '0px'}}>
                        {val?.title}
                      </label>

                      <h3>
                        <img src={imagePath.ic_location_pin_orange} style={{color: '#FF8750', margin: '0 1.4rem 0 0'}} />
                        {/* {val.location} */}
                        {`${batchPositions ? getMultipleLocations(batchPositions) : val?.location ? val.location : 'Location'}`}
                      </h3>
                      <h3>
                        <img className='left-vacan-img-icon' src={exp_icon} alt='...' /> Min Experience: {val.banking_experience}
                      </h3>
                      <h3>
                        <img src={imagePath.ic_star_yellow} style={{color: '#FF8750', margin: '0 1.2rem 0 0'}} /> Grade Range: {val.grade}
                      </h3>
                      {/* <div className='vacant-date' style={{ marginTop: '2rem' }}><i className="bi vacant-date-icons bi-hourglass-top"></i> Last date to apply: {new Date(val?.valid_till).toLocaleDateString()}</div> */}
                      <div className='vacant-date' style={{marginTop: '2rem'}}>
                        <img className='bi vacant-date-icons bi-hourglass-top' src={imagePath.ic_time_left_red} /> Last date to apply:{' '}
                        {new Date(val?.valid_till).toLocaleDateString()}
                      </div>
                    </div>

                    {/* mid */}
                    <div className='mid-vacan col-md-6 mt-10'>
                      <h1>Job Description</h1>
                      <div>{ReactHtmlParser(val?.description)}</div>
                    </div>

                    <div className='col-md-3 text-center right-vacan'>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <img style={{marginRight: '8px', marginBottom: '1rem'}} src={imagePath.ic_calendar_orange} />
                        <p>Posted: {getFormattedDate(val?.valid_from)}</p>
                      </div>
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        {
                          // val?.expired ? (
                          //   <div className='right-btn-div'>
                          //     <label className='btn fw-bolder fs-5 vacancies-button right-button-vacan' style={{ width: '14rem', color: '#F36523' }} >Due Date Passed</label>
                          //   </div>
                          // ) :
                          val?.jobapp_status === 'WITHDRAWN' ? (
                            <div className='right-btn-div'>
                              <label className=' fw-bolder fs-5 grey-chip ' style={{width: '14rem'}}>
                                Withdrawn
                              </label>
                            </div>
                          ) : val?.checkApplied ? (
                            <div className='right-btn-div'>
                              <label
                                className='btn fw-bolder fs-5 vacancies-button right-button-vacan'
                                style={{width: '14rem', color: '#F36523'}}
                              >
                                Already Applied
                              </label>
                            </div>
                          ) : (
                            <div>
                              <button
                                onClick={() => {
                                  props.openApplyPage(2)
                                  props.job_id(val.job_id)
                                  props.candidate_id(user_id)
                                  props.recuiter_id(val.recuiter_id)
                                  props.job_title(val.title)
                                  props.created_at(new Date(val.createdAt).getDay())
                                  props.setJobData(val)
                                }}
                                className='fw-bolder fs-5 candi-btn2 btn text-white '
                                style={{width: '14rem'}}
                              >
                                Apply Now
                              </button>
                            </div>
                          )
                        }

                        {/* {val?.jobapp_status === 'WITHDRAWN' ? (
                          <div className='right-btn-div'>
                            <label className=' fw-bolder fs-5 grey-chip ' style={{ width: '14rem' }}>Withdrawn</label>
                          </div>)
                          :
                          (
                            val?.checkApplied ? (
                              <div className='right-btn-div'>
                                <label className='btn fw-bolder fs-5 vacancies-button right-button-vacan' style={{ width: '14rem' }} >Already Applied</label>
                              </div>
                            )
                              :
                              (<div >
                                <button
                                  onClick={() => {
                                    props.openApplyPage(2)
                                    props.job_id(val.job_id)
                                    props.candidate_id(user_id)
                                    props.recuiter_id(val.recuiter_id)
                                    props.job_title(val.title)
                                    props.created_at(new Date(val.createdAt).getDay())
                                    props.setJobData(val)
                                  }}
                                  className='fw-bolder fs-5 candi-btn2 btn text-white '
                                  style={{ width: '14rem' }}
                                >
                                  Apply Now
                                </button>
                              </div>)
                          )} */}

                        {/* {val?.checkApplied ? (
                        <div className='right-btn-div'>
                          <label className='btn fw-bolder fs-5 vacancies-button right-button-vacan' >Already Applied</label>
                        </div>
                      ) : (
                        <div className='right-btn-div'>
                          <button
                            onClick={() => {
                              props.openApplyPage(2)
                              props.job_id(val.job_id)
                              props.candidate_id(user_id)
                              props.recuiter_id(val.recuiter_id)
                              props.job_title(val.title)
                              props.created_at(new Date(val.createdAt).getDay())
                              props.setJobData(val)
                            }}
                            className='fw-bolder fs-5 candi-btn2 text-white '
                          >
                            Apply Now
                          </button>
                        </div>
                      )} */}
                      </div>
                    </div>
                  </div>

                  <div className='bottom-vacan col-md-12'>
                    <h1 className='mt-4'>Required Competencies</h1>
                    <div className='competency-chips-container' style={{paddingTop: '1rem'}}>
                      {requiredCompetencies &&
                        Object.values(requiredCompetencies[0]).map((com: any, index) => {
                          return (
                            <div key={index} className='competency-chips'>
                              {com?.name}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )
            })
          ) : loading ? (
            <div className='text-center'>
              <FontAwesomeIcon
                icon={faSpinner}
                color='#F36523'
                spin
                size='5x'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  marginTop: '15rem',
                }}
              />
            </div>
          ) : (
            <h3 style={{color: 'white'}}>Vacancies Not Found</h3>
          )}
        </div>

        {vacancies && vacancies.length > 0 ? (
          <div className='d-flex col-md-12 align-items-center px-8 pb-8'>
            <div className='col-md-4'>
              <label>{`Showing ${vacancies ? vacancies.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{textAlign: 'center'}}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{backgroundColor: '#FFFFFF', borderRadius: '2px'}}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default VacanciesCandidateMain
