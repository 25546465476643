//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import imagePath from '../../../../constants/imagePath';

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const EventsPopup = ({events, handleClose, ...props}) => {
    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>

                    </div>

                </div>
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default EventsPopup;
