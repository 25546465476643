//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import imagePath from '../../../../constants/imagePath';

type Props = {
    handleClose: () => void
    handleAddProgram: (programName: string) => void
    editProgram: (program: any) => void
    type: string
    data: any
}
const modalsRoot = document.getElementById('root-modals') || document.body


// create a component
const AddUpdateProgram = ({ handleClose, handleAddProgram, editProgram, type, data }: Props) => {
    const [programName, setProgramName] = useState(type === 'edit' ? data.data : '');

    const handleProgramNameChange = (e) => {
        setProgramName(e.target.value);
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123 px-6 py-4 position-relative'>
                <div className='d-flex align-items-center mx-6'>
                    <label className='fs-3 fw-bolder'>{`${type === 'add' ? 'Add New Program' : 'Edit Program'}`}</label>
                    <img className='ms-auto cursor-pointer' src={imagePath.ic_close} onClick={handleClose} />
                </div>
                <div className='mx-6'>
                    <form>
                        <div className="mt-8">
                            <label htmlFor="programName" className="form-label fs-6" style={{ color: '#373D4A' }}>Title<span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                style={{ height: '40px' }}
                                placeholder='Enter Program / Specialization'
                                id="programName"
                                value={programName}
                                onChange={handleProgramNameChange}
                            />
                        </div>
                        <button
                            onClick={() => {
                                if (type === 'add') {
                                    handleAddProgram(programName)
                                } else {
                                    data.data = programName;
                                    editProgram(data)
                                }
                            }}
                            type='button'
                            className='d-flex btn px-18 py-2 apply-review-button my-8 mx-auto'
                            style={{ color: 'white' }}
                            disabled={!programName}
                        >
                            Save
                        </button>
                    </form>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
};


//make this component available to the app
export default AddUpdateProgram;
