//import liraries
import React, { useState } from 'react';
import ApplicationsTab from './ApplicationsTab';
import OnlineTest from './OnlineTest';
import SolveTest from './SolveTest';
import AttemptTest from './AttemptTest';

// create a component
const ApplicationBoiler = () => {
    const [jobId, setJobId] = useState('');
    const [job, setJob] = useState<any>({});
    const [testId, setTestId] = useState('');
    const [currComp, updateCurrComp] = useState(1);
    const [questions, setQuestions] = useState([]);
    const [testTime, setTestTime] = useState('');
    const [actualNoOfQuestions, setActualNoOfQuestions] = useState(0);
    return (
        <div >
            {
                currComp === 1 ? <ApplicationsTab update={updateCurrComp} setTest={setTestId} setJobId={setJobId} setJob={setJob}/> :
                    currComp === 2 ? <OnlineTest update={updateCurrComp} test_id={testId} job_id={jobId} jobData={job} updateQuestions={setQuestions} updateTestTime={setTestTime} updateActualNoOfQuestions={setActualNoOfQuestions}/> :
                        // currComp === 3 ? <SolveTest update={updateCurrComp} job_id={jobId} jobData={job} test_id={testId} questionsList={questions} testTime={testTime} actualNoOfQuestions={actualNoOfQuestions}/> :
                        currComp === 3 ? <AttemptTest update={updateCurrComp} job_id={jobId} jobData={job} test_id={testId} questionsList={questions} testTime={testTime} actualNoOfQuestions={actualNoOfQuestions}/> :
                            null
            }
        </div>
    );
};


//make this component available to the app
export default ApplicationBoiler;
