//import liraries
import React, { useState, useEffect } from 'react';
import imagePath from '../../../../../constants/imagePath';
import { getFormattedDate } from '../../../../../constants/CommonFunctions';
import './componentsStyles.css';
import axios from 'axios';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import { useAuth } from '../../../auth/core/Auth';
import AddUpdateEduLevelPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateEduLevelPopup';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_EDU_LEVEL_URL = `${API_URL}/configuration/delete-education-level`;
const SAVE_EDU_LEVEL_URL = `${API_URL}/configuration/add-education-level`;

// create a component
const EduLevel = ({ configurationId, edu, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [currentDate, setCurrentDate] = useState(new Date);
    const [toggleChecked, setToggleChecked] = useState(false);
    const [educationLevels, setEducationLevels] = useState<any>([{ i: 0, data: '' }]);
    const [showAddEduLevelPopup, setShowAddEduLevelPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [type, setType] = useState('add');
    const [currentObj, setCurrentObj] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    const handleToggleChange = () => {
        setToggleChecked(!toggleChecked)
    }

    const handleChangeActive = (isActive, index) => {

        let data = [...educationLevels];
        data[index]['active'] = !isActive;
        setEducationLevels(data);
        updateList(data);
    }

    useEffect(() => {

        if (edu) {
            edu = JSON.parse(edu);
            const initialEdu = Object.keys(edu).map((key) => ({
                i: key,
                data: edu[key]['data'],
                active: edu[key]['active']
            }));
            console.log('eduData: ', initialEdu);
            setEducationLevels(initialEdu);
        }
    }, [edu]);

    const onEdit = (index) => {
        let data = educationLevels[index];
        setCurrentObj(data);
        setType('edit');
        setShowAddEduLevelPopup(true);
    }

    const onDelete = async (index) => {
        if (index >= 0 && index < educationLevels.length) {
            const key = educationLevels[index].i;

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_EDU_LEVEL_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                let data = [...educationLevels];
                data.splice(index, 1);
                setEducationLevels(data);

                setMessage('Educations Level Deleted Successfully!');
                setShowSuccessPopup(true);
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }
    }

    const addEduLevel = (value) => {
        // Find the largest "i" value from the list
        const largestI = educationLevels.reduce((max, item) => {
            const currentItemI = parseInt(item.i, 10);
            return currentItemI > max ? currentItemI : max;
        }, -1); // Initialize with -1 in case the list is empty

        // Increment the largest "i" value by 1
        const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

        // Create a new object with the incremented "i" value
        const newInstitute = {
            "i": newI,
            "data": value, // Set the initial value as desired
            "active": false
        };

        let data = [...educationLevels];
        data.push(newInstitute);
        updateList(data);
        // Add the new object to the list

    }

    const updateList = async (list) => {
        const data = {
            education_level: {},
            configuration_id: configurationId,
        };
        // Convert the array to an object with keys as "i" and values as "data"
        list.forEach((item) => {
            data.education_level[item.i] = {
                "data": item.data,
                "active": item.active,
            };
        });

        await axios.post(SAVE_EDU_LEVEL_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            // fetch updated data
            // getConfigurations();
            setEducationLevels(list);
            setMessage('Educations Levels Updated Successfully!');
            setShowSuccessPopup(true);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    return (
        <div>
            <div className='card-bg shadow-sm mt-12 py-4 position-relative' style={{ height: '29rem' }}>
                <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Education Level</label>
                    <div className='ms-auto' style={{ position: 'relative', zIndex: '1' }}>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            onClick={() => {
                                setType('add');
                                setShowAddEduLevelPopup(true);
                            }}
                        >
                            Add New
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-wrap mt-6 px-7 mb-4'>
                    {educationLevels ? educationLevels.map((data, index) => (
                        <div key={index} className='col-lg-4 col-md-6 col-sm-12 px-2 mb-4' style={{ position: 'relative', zIndex: '1' }}>
                            <div className='card-bg shadow-sm py-4' style={{ zIndex: '2', minWidth: '14rem' }}>

                                <div className='d-flex align-items-center'>
                                    <div>
                                        <label className='fs-5 fw-bolder card-title '>{data?.data}</label>
                                    </div>
                                    <div className='ms-auto d-flex align-items-center me-4' style={{ position: 'relative', zIndex: '1' }}>
                                        <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={20} height={20} title='Edit'
                                            onClick={() => onEdit(index)} />
                                        <img style={{ cursor: 'pointer' }} src={imagePath.ic_delete_orange_cross} width={18} height={18} title='Delete'
                                            onClick={() => onDelete(index)} />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <label className='me-4 fs-7 light-txt fw-bolder' style={{ paddingLeft: '1.2rem' }}>Active</label>
                                    <label className="toggle-switch-small">
                                        <input type="checkbox" checked={data?.active} onChange={() => handleChangeActive(data?.active, index)} />
                                        <span className="toggle-switch-small-slider"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    )) : null}
                </div>


                {/* Positioning the image in the bottom right corner */}
                <div className='position-absolute bottom-0 end-0'>
                    <img src={imagePath.ic_edu_hat} style={{ width: '260px', height: '260px', opacity: '0.08', zIndex: '0' }} />
                </div>
            </div>
            {showAddEduLevelPopup && (
                <AddUpdateEduLevelPopup
                    handleClose={() => setShowAddEduLevelPopup(false)}
                    handleAddEduLevel={(value) => {
                        setShowAddEduLevelPopup(false);
                        addEduLevel(value);
                    }}
                    editEduLevel={(eduLevel) => {

                        setShowAddEduLevelPopup(false);
                        let list = [...educationLevels];
                        list[eduLevel.i]['data'] = eduLevel.data;
                        updateList(list);
                    }}
                    type={type}
                    data={currentObj}
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};


//make this component available to the app
export default EduLevel;

