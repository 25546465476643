/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef, useContext} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import './CSS/Mixedwidget8.css'

import {LoginContext} from '../../../../app/App'
import rightarrow from '../images/keyboard_double_arrow_left_black_24dp.png'
import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {useNavigate} from 'react-router-dom'
import ProgressBar from '../../../../app/modules/Setting/SettingTabs/components/ProgressBar'
import SmallProgressBar from '../../../../app/modules/Setting/SettingTabs/components/SmallProgressBar'

const API_URL = process.env.REACT_APP_API_URL
// export const GET_JOBS_CANDIDATES_URL = `${API_URL}/job/count-total-candidates`;
export const GET_JOBS_CANDIDATES_URL = `${API_URL}/analytics/dashboard-vacant-position`

// import radial  from "./MixedWidget8.js"
type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

// const styles = { backgroundColor: color };
const MixedWidget8: React.FC<Props> = ({className, chartColor, chartHeight}) => {
  const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const {currentUser, auth} = useAuth()
  const navigate = useNavigate()
  const [ActiveJobs, setActiveJobs] = useState(0)
  const [ClosedJobs, setClosedJobs] = useState(0)

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [jobsCandidates, setJobsCandidates] = useState<any>([])

  const refreshChart = (active, totalJobs) => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, active, totalJobs))
    if (chart) {
      chart.render()
    }

    return chart
  }

  // useEffect(() => {
  //   const chart = refreshChart()

  //   return () => {
  //     if (chart) {
  //       chart.destroy()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chartRef, mode])

  const getTotalJobsAndCandidates = async () => {
    let masterRecruiter = 'masterRecruiter'
    let recruiter = 'recruiter'
    console.log('userRole: ', currentUser?.role)
    if (currentUser?.role === 'none') {
      return
    }
    axios
      .get(GET_JOBS_CANDIDATES_URL, {
        params: {
          recruiter_id: currentUser?.role === recruiter ? currentUser?.user_id : 'admin',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // let data = res.data.data;
        // if (data) {
        //   setJobsCandidates(data);
        // }
        // let active = res.data.ActiveJobs;
        // let closed = res.data.ClosedJobs;
        // let totalJobs = res.data.totalNumberOfJobs;
        // setActiveJobs(active);
        // setClosedJobs(closed);
        // const chart = refreshChart(active, totalJobs)

        let data = res.data
        let positionGroupCount = data?.positionGroupCount
        if (positionGroupCount) {
          let latestPositionsData = positionGroupCount.slice(0, 3)
          setJobsCandidates(latestPositionsData)
        }

        let active = data?.positionPostedCount
        let closed = data?.positionNotPostedCount
        let totalJobs = active + closed
        setActiveJobs(active)
        setClosedJobs(closed)
        const chart = refreshChart(active, totalJobs)

        return () => {
          if (chart) {
            chart.destroy()
          }
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    ;(async () => {
      await getTotalJobsAndCandidates()
    })()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header  '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-gray-800 fw-bolder fs-3 '>Overall Performance Summary</span>
        </h3>

        {/* <hr className='border-hr'></hr> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pb-0 mb-0 mt-0 pt-0 d-flex flex-column'>
        {/* begin::Chart */}
        <div className='my-5'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
        {/* end::Chart */}

        <div className='d-flex mb-5 pb-5 flex-row mx-auto'>
          <div className='d-flex flex-row me-10'>
            {' '}
            <span className='colored-circle ' />
            <p style={{color: '#80808F'}} className='font-setting-dasboard '>
              {ActiveJobs} In Progress Positions
            </p>
          </div>

          <div className='d-flex flex-row me-auto'>
            {' '}
            <span className='totalJobs' />
            <p className='font-setting-dasboard'>{ClosedJobs} Unattended Positions</p>
          </div>
        </div>

        {/* {jobsCandidates.length > 0 ? jobsCandidates.map((item, index) => (<div className='mt-5 px-5'>
          <div className='d-flex flex-stack mb-1'>
            <div className='d-flex align-items-center me-5'>
              <div>
                <a href='#' className='SummaryProperties '>
                  {item['title']}
                </a>
              </div>
            </div>
            <div className='badge badge-light-color '>
              <span className='mx-auto'>{item['totalApplied']}</span>
            </div>

          </div>
        </div>)) : (<div></div>)} */}

        {jobsCandidates.length > 0 ? (
          <div className='mt-5 table-responsive' style={{maxHeight: '36.2rem'}}>
            <table className='table'>
              <thead>
                <tr>
                  <th className=' form-label fw-bolder fs-7' scope='col'>
                    Groups
                  </th>
                  <th className=' form-label fw-bolder fs-7' scope='col'>
                    InProgress
                  </th>
                  <th className=' form-label fw-bolder fs-7' scope='col'>
                    Unattended
                  </th>
                  <th className=' form-label fw-bolder fs-7' scope='col'>
                    Closed
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobsCandidates.map((data, index) => {
                  let percentage = (data?.lapsedSalaryBudget / data?.yearlySalaryBudget) * 100
                  percentage = percentage > 100 ? 100 : percentage

                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td className=' form-label fs-7'>{data?.groupName}</td>
                        <td>
                          <div
                            style={{
                              backgroundColor: '#F3F6F9',
                              borderRadius: '4px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <label className=' form-label fs-7 p-1' style={{color: '#80808F'}}>
                              {data?.postedCount}
                            </label>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              backgroundColor: '#F3F6F9',
                              borderRadius: '4px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <label className=' form-label fs-7 p-1' style={{color: '#80808F'}}>
                              {data?.notPostedCount}
                            </label>
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              backgroundColor: '#F3F6F9',
                              borderRadius: '4px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                            }}
                          >
                            <label className=' form-label fs-7 p-1' style={{color: '#80808F'}}>
                              {data?.closedCount}
                            </label>
                          </div>
                        </td>
                        {/* <td >
                          <div>
                            <h4 className=' form-label fw-bolder fs-10' style={{ color: '#F36523' }}>{`PKR ${parseInt(data?.yearlyRemunerationBudget).toLocaleString()}`}</h4>
                            <SmallProgressBar value={percentage} max={100} backgroundColor="#D7F9EF" progressColor="#FFE2E5" shadow="rgba(0, 0, 0, 0.2)" />
                            <div style={{ marginLeft: 'auto', marginTop: '2px' }}>
                              <h4 className=' form-label fw-bolder fs-10' style={{ color: '#0BB783' }}>{`PKR ${parseInt(data?.remunerationAvailableBudget).toLocaleString()}`}</h4>
                              <h4 className=' form-label fw-bolder fs-10' style={{ color: '#80808F' }}>Remaining Budget</h4>
                            </div>
                          </div>
                        </td> */}
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div></div>
        )}

        {loggedIn === true ? (
          <div className='mt-8 px-5'>
            {/* begin::Item */}
            <div className='d-flex flex-stack mb-1'>
              {/* begin::Section */}
              <div className='d-flex align-items-center me-5'>
                {/* begin::Symbol */}

                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div
                    className='text- seeMore-text-color fs-7  fw-bolder'
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      if (currentUser?.role === 'none') {
                        return
                      }
                      // goto vacant positions
                      if (currentUser?.role === 'masterAdmin') {
                        navigate('/settings?tab=DepartmentBudget')
                      } else {
                        // show all vacant positions
                        navigate('/vacantpositions')
                      }
                    }}
                  >
                    See More
                    <span>
                      <img src={rightarrow}></img>
                    </span>
                  </div>
                </div>
                {/* end::Title */}
              </div>
              {/* end::Section */}

              {/* begin::Label */}
              {/* <div className='badge badge-light fw-bold my-2 fw-bold py-4 px-3'>292</div> */}
              {/* end::Label */}
            </div>
          </div>
        ) : (
          <div className='mt-1 px-5'>
            {/* begin::Item */}
            <div className='d-flex flex-stack mb-1'>
              {/* begin::Section */}
              <div className='d-flex align-items-center me-5'>
                {/* begin::Symbol */}

                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  {/* <a href='#' className='text- seeMore-text-color fs-7  fw-bolder'>
                    See More
                    <span>
                      <img src={rightarrow}></img>
                    </span>
                  </a> */}
                </div>
                {/* end::Title */}
              </div>
              {/* end::Section */}

              {/* begin::Label */}
              {/* <div className='badge badge-light fw-bold my-2 fw-bold py-4 px-3'>292</div> */}
              {/* end::Label */}
            </div>
          </div>
        )}

        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}
const chartOptions = (chartColor: string, chartHeight: string, active, totalJobs): ApexOptions => {
  let percentage = (active / totalJobs) * 100
  let formattedPercentage = Number(percentage.toFixed(0))

  return {
    series: [formattedPercentage],
    chart: {
      fontFamily: 'Nunito',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 100,
          size: '70%',
        },

        dataLabels: {
          name: {
            show: true,
            fontWeight: '700',
            color: '#888',
            offsetY: -20,
          },

          value: {
            color: 'black',

            fontSize: '30px',
            fontWeight: '700',

            offsetY: 13,
            formatter: function (val) {
              return totalJobs + ''
            },
            // show: true,
            // formatter: function (val) {
            //   return val + '%'
            // },
          },
        },
        track: {
          background: '#FFCA99',
          strokeWidth: '90%',
        },
      },
    },
    colors: ['#F36523'],
    stroke: {
      lineCap: 'round',
    },
    //   markers: {
    //     colors: ['#F44336', '#E91E63', '#9C27B0']
    //  },
    fill: {
      colors: ['#F36523'],
    },
    labels: ['Total Positions'],
  }
}

export {MixedWidget8}
