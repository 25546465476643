import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import './ViewProfile.css'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import GraduationUniversity from './img/graduation-cap (4).png'
import EmailIcon from './img/email (2).png'
import PhoneCall from './img/phone-call (3).png'
import IdCard from './img/id-card (1).png'
import Attachfile from './img/attach-file (1).png'
import DownloadIcon from './img/Component 237 – 5.png'
import SkillIcon from './img/skills_Color.png'
import BagIcon from './img/W.E_Colored.png'
import ArrowDown from './img/arrow.png'
import EducationIcon from './img/cap_ color.png'
import CertificateIcon from './img/document _ color.png'
import UserIcon from './img/P.I_ Colored.png'
import ArrowUp from './img/Group 12485 (1).png'
import UserIcons from './img/group-9680@3x.png'
import ShowError from '../../content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_DOCUMENT = `${API_URL}/document/get-all-docs`
export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

type Props = {
  show: boolean
  name: string
  jobapp_id: string
  user_id: string
  email: string
  profile_pic: string
  cnic: string
  phone_number: string
  education: string
  skills: string
  work_Experience: string
  total_exp: string
  banking_exp: any
  nonBanking_exp: any
  educations: string
  certifications: string
  personalData: any
  profile_match: string
  position: string
  handleClose: () => void
}
const getTotalYears = (workData) => {
  workData = workData[0]
  let totalYears = 0
  let totalMonths = 0
  let allKeys = Object.keys(workData?.experience)

  Object.keys(workData?.experience).map((key, index) => {
    const value = workData?.experience[key]['years']
    const experience = value?.split('.')
    const years = parseFloat(experience[0]) || 0
    const months = parseFloat(experience[1]) || 0

    totalYears += years
    totalMonths += months
  })
  // Convert excess months to years
  totalYears += Math.floor(totalMonths / 12)
  totalMonths %= 12
  let value = `${totalYears} yrs ${totalMonths} months`
  return value
}

const getTotalBankingExperience = (workData) => {
  workData = workData[0]
  let totalYears = 0
  let totalMonths = 0
  if (workData) {
    Object.keys(workData?.experience).map((key, index) => {
      const type = workData?.experience[key]['type']
      if (type === 'banking_experience') {
        const value = workData?.experience[key]['years']
        const experience = value?.split('.')
        const years = parseFloat(experience[0]) || 0
        const months = parseFloat(experience[1]) || 0

        totalYears += years
        totalMonths += months
      }
    })
  }
  // Convert excess months to years
  totalYears += Math.floor(totalMonths / 12)
  totalMonths %= 12
  let value = `${totalYears} yrs ${totalMonths} months`
  return value
}
function WorkExperience({show, work_Experience, total_exp, banking_exp, nonBanking_exp}: Props) {
  let workData: any = JSON.parse(work_Experience)
  console.log({workData})
  return (
    <>
      <div>
        <div>
        <span className='view-profile-exp-txt'>Total Experience: {getTotalYears(workData)} yrs</span>
          {/* <span className='view-profile-exp-txt'>Total Experience: {total_exp} yrs</span> */}
        </div>

        <div>
        <span className='view-profile-exp-txt-darker'>Banking Experience:  {getTotalBankingExperience(workData)} yrs</span>
          {/* <span className='view-profile-exp-txt-darker'>Banking Experience: {banking_exp} yrs</span> */}
        </div>
      </div>
      <div>
        {workData &&
          Object.keys(workData).map((key, index) => (
            <div key={index}>
              <table className='work-exp-table'>
                <tr>
                  <th className='work-exp-table-header-td'>Company</th>
                  <th className='work-exp-table-header-td'>Job Title</th>
                  <th className='work-exp-table-header-td'>Date From</th>
                  <th className='work-exp-table-header-td'>Date To</th>
                </tr>

                {Object.keys(workData[0]?.experience).map((key, index) => {
                  return (
                    <tr key={index}>
                      <td className='work-exp-table-header-th'>{workData[0]?.experience[key].name}</td>
                      <td className='work-exp-table-header-th'>{workData[0]?.experience[key].job_title}</td>
                      <td className='work-exp-table-header-th'>{workData[0]?.experience[key].Date_from}</td>
                      <td className='work-exp-table-header-th'>{workData[0]?.experience[key].Date_to}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          ))}
        <div>
          <span className='view-profile-exp-txt-darker'>
            Non Banking Experience: {Math.floor(nonBanking_exp)} yrs {Math.round((nonBanking_exp % 1) * 12)} months
          </span>
        </div>
      </div>
    </>
  )
}

function Education({show, educations}: Props) {
  console.log(JSON.parse(educations))
  let educationData = JSON.parse(educations)
  console.log({educationData})
  return (
    <>
      <div>
        <div>
          {Object.keys(educationData[0]).map((key, index) => {
            console.log('eduData: ', key)
            if (key !== 'educationalYears') {
              return (
                <div key={index}>
                  <table className='work-exp-table-header-tbl'>
                    <tr>
                      <th className='work-exp-table-header'>Academic Qualification {index + 1}</th>
                    </tr>
                    <tr className='work-exp-table-cont'>
                      <th className='work-exp-table-header-td'>Education Level</th>
                      <th className='work-exp-table-header-td'>Institution</th>
                      <th className='work-exp-table-header-td'>Program</th>
                      <th className='work-exp-table-header-td'>CGPA</th>
                      <th className='work-exp-table-header-td'>Date From</th>
                      <th className='work-exp-table-header-td'>Date To</th>
                    </tr>

                    <tr className='work-exp-table-body-cont'>
                      <td className='work-exp-table-header-th'>{educationData[0][key].education_level}</td>
                      <td className='work-exp-table-header-th'>{educationData[0][key].institution}</td>
                      <td className='work-exp-table-header-th'>{educationData[0][key].program}</td>
                      <td className='work-exp-table-header-th'>{educationData[0][key].education_value}</td>
                      <td className='work-exp-table-header-th'>{educationData[0][key].end_date}</td>
                      <td className='work-exp-table-header-th'>{educationData[0][key].start_date}</td>
                    </tr>
                  </table>
                </div>
              )
            } else {
              return <div></div>
            }
          })}
        </div>

        {Object.keys(educationData).length > 1 && (
          <div>
            {Object.keys(educationData).map((key, index) => (
              <div key={index}>
                <table className='work-exp-table-header-tbl'>
                  <tr>
                    <th className='work-exp-table-header'>Academic Qualification {1 + 1}</th>
                  </tr>
                  <tr className='work-exp-table-cont'>
                    <th className='work-exp-table-header-td'>Education Level</th>
                    <th className='work-exp-table-header-td'>Institution</th>
                    <th className='work-exp-table-header-td'>Program</th>
                    <th className='work-exp-table-header-td'>CGPA</th>
                    <th className='work-exp-table-header-td'>Date From</th>
                    <th className='work-exp-table-header-td'>Date To</th>
                  </tr>
                  <tr className='work-exp-table-body-cont'>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].education_level}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].institution}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].program}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].education_value}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].end_date}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].start_date}</td>
                  </tr>
                </table>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

function ProfessionalCertifications({certifications}: Props) {
  let certificationData: any = JSON.parse(certifications)
  console.log({certifications})
  return (
    <>
      <div>
        {Object.keys(certificationData).map((key, index) => (
          <div key={index}>
            <table className='work-exp-table'>
              <tr>
                <th className='work-exp-table-header-td'>Name of Certificate</th>
                <th className='work-exp-table-header-td'>Issuing Organization</th>
                <th className='work-exp-table-header-td'>Issuing Date</th>
              </tr>

              {Object.keys(certificationData).map((key, index) => (
                <tr key={index}>
                  <td className='work-exp-table-header-th'>{certificationData[key][Object.keys(certificationData[key])[0]].name}</td>
                  <td className='work-exp-table-header-th'>
                    {certificationData[key][Object.keys(certificationData[key])[0]].issuing_organization}
                  </td>
                  <td className='work-exp-table-header-th'>{certificationData[key][Object.keys(certificationData[key])[0]].issue_date}</td>
                </tr>
              ))}
            </table>
          </div>
        ))}
      </div>
    </>
  )
}

function PersonalInformation({personalData}: Props) {
  const birthdate = new Date(personalData.dob)
  const ageInMilliseconds = Date.now() - birthdate.getTime()
  const ageInYears = Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365)
  return (
    <>
      <div className='personalInfor-header-row-view-profile'>
        <div>
          <span className='personalInfo-view-profile-head-txt'>DOB (as per CNIC)</span>
          <td className='work-exp-table-header-th'>{personalData?.dob}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Age</span>
          <td className='work-exp-table-header-th'>{ageInYears}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Address</span>
          <td className='work-exp-table-header-th'>{personalData?.address}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>City</span>
          <td className='work-exp-table-header-th'>{personalData?.city}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Gender</span>
          <td className='work-exp-table-header-th'>{personalData?.gender}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Marital Status</span>
          <td className='work-exp-table-header-th'>{personalData?.marital_status}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Fresh Graduate</span>
          <td className='work-exp-table-header-th'>{personalData?.fresh_graduate ? 'Yes' : 'No'}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Employment Status</span>
          <td className='work-exp-table-header-th'>{personalData?.employment_status}</td>
        </div>
      </div>
      <div></div>
    </>
  )
}

const ViewProfile = ({
  show,
  name,
  jobapp_id,
  user_id,
  email,
  profile_pic,
  cnic,
  phone_number,
  education,
  skills,
  work_Experience,
  total_exp,
  banking_exp,
  nonBanking_exp,
  educations,
  certifications,
  personalData,
  profile_match,
  position,
  handleClose,
}: Props) => {
  let data: any = JSON.parse(skills)
  console.log({skills})
  console.log({data})
  const [showWorkExperience, setShowWorkExperience] = useState(false)
  const [showEducation, setShowEducation] = useState(false)
  const [showCertifications, setShowCertifications] = useState(false)
  const [showPersonalData, setShowPersonalData] = useState(false)
  const [personalDatas, setpersonsalData] = useState<any>([])
  const [isArrowDown, setIsArrowDown] = useState(true)
  const [candidateDocuments, setCandidateDocuments] = useState<any>([])
  const [message, setMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const {auth} = useAuth()

  const GetAllDocuments = async () => {
    await axios
      .post(
        GET_ALL_DOCUMENT,
        {
          candidate_id: user_id,
          jobapp_id: jobapp_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setCandidateDocuments(res?.data?.doc)
        console.log({res})
      })
  }

  const handleArrowDownClick = () => {
    setShowWorkExperience(!showWorkExperience)
    setIsArrowDown(!isArrowDown)
  }

  const handleEducationArrowDownClick = () => {
    setShowEducation(!showEducation)
    setIsArrowDown(!isArrowDown)
  }

  const handleCertificationsArrowDownClick = () => {
    setShowCertifications(!showCertifications)
    setIsArrowDown(!isArrowDown)
  }

  const handlePersonalInformationArrowDownClick = () => {
    setShowPersonalData(!showPersonalData)
    setIsArrowDown(!isArrowDown)
  }

  const candidateData = async () => {
    await axios
      .get(GET_CANDIDATE_DATA + user_id, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log({res})
        setpersonsalData(res?.data?.user)
      })
  }

  console.log({candidateDocuments})
  useEffect(() => {
    GetAllDocuments()
    candidateData()
  }, [])

  console.log({profile_match})
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
        show={true}
        height='50vh'
        onHide={handleClose}
        backdrop={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title>
          <div className='view-profile-upper-cont'>
            <div>
              <img src={profile_pic ? profile_pic : UserIcons} className='profile-user-icon-view-profile' />
            </div>
            <div className='view-profile-name-cont'>
              <span className='middle-container-username'>{name}</span>
              <span className='middle-container-role'>Position : {position}</span>
            </div>

            <div className='view-profile-middle-cont'>
              <div className='view-profile-personal-cont'>
                <img src={IdCard} />
                <span className='view-profile-personal-text'>{cnic}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={EmailIcon} />
                <span className='view-profile-personal-text'>{email}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={PhoneCall} />
                <span className='view-profile-personal-text'>{phone_number}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={GraduationUniversity} style={{marginLeft: '-3px'}} />
                <span className='view-profile-personal-text'>{education}</span>
              </div>
            </div>
            <div className='view-profile-pmatch'>
              <span className='view-profile-pmatch-header'>Profile Match</span>
              <span className='view-profile-pmatch-header'>{profile_match ? parseFloat(profile_match) : 0} %</span>
              <div className='progress h-6px w-80'>
                <div className='progress-bar bg-primary123' role='progressbar' style={{width: `${parseFloat(profile_match)}%`}}></div>
              </div>
              <img />
            </div>

            <div className='view-profile-header-btn-cont'>
              <button className='view-profile-header-btn'>ShortList</button>
            </div>
          </div>
        </Modal.Title>
        <Modal.Body>
          <div className='view-profile-attachment-cont'>
            <div className='view-profile-attachment-header-cont'>
              <img src={Attachfile} />
              <label className='view-profile-attachment-cont-header-tct'>Attachments</label>
            </div>

            <div className='view-profile-attachment-body-cont'>
              {candidateDocuments.length > 0 ? (
                candidateDocuments.map((doc) => (
                  <span key={doc.id}>
                    <a download={doc.doc_name} className='view-profile-attachment-body-inner-cont'>
                      <img
                        src={DownloadIcon}
                        onClick={(event) => {
                          event.preventDefault()
                          if(doc.doc_path){
                          const xhr = new XMLHttpRequest()
                          xhr.responseType = 'blob'
                          xhr.onload = () => {
                            const url = window.URL.createObjectURL(xhr.response)
                            const link = document.createElement('a')
                            link.href = url
                            link.download = doc.doc_name
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                          }
                          xhr.open('GET', doc.doc_path)
                          xhr.send()
                        }else{
                          setMessage('File not found');
                          setShowErrorMessage(true);
                        }
                        }}
                      />
                      <label style={{color: 'grey'}}>{doc?.doc_name}</label>
                    </a>
                  </span>
                ))
              ) : (
                <span>No Documents Available</span>
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont'>
            <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
              <img src={SkillIcon} />
              <span className='view-profile-attachment-cont-header-tct'>Skills</span>
            </div>

            {data.map((option, index) => {
              const values = Object.values(option)
              return (
                <span key={index}>
                  <span
                    style={{
                      marginRight: '1rem',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '10px',
                    }}
                  >
                    {values.map((value: any, idx) => (
                      <span className='skills-tiles' key={idx}>
                        {value}
                      </span>
                    ))}
                  </span>
                </span>
              )
            })}
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={BagIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Work Experience</span>
              </div>
              <div>
                <img src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleArrowDownClick} />
              </div>
            </div>

            <div>
              {showWorkExperience && (
                <WorkExperience
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id}
                  user_id={user_id}
                  email={email}
                  profile_pic={profile_pic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={skills}
                  work_Experience={work_Experience}
                  total_exp={total_exp}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={personalData}
                  profile_match={profile_match}
                  position={position}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={EducationIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Education</span>
              </div>
              <div>
                <img src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleEducationArrowDownClick} />
              </div>
            </div>
            <div>
              {showEducation && (
                <Education
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id}
                  user_id={user_id}
                  email={email}
                  profile_pic={profile_pic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={skills}
                  work_Experience={work_Experience}
                  total_exp={total_exp}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={personalData}
                  profile_match={profile_match}
                  position={position}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={CertificateIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Professional Certifications</span>
              </div>
              <div>
                <img src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleCertificationsArrowDownClick} />
              </div>
            </div>
            <div>
              {showCertifications && (
                <ProfessionalCertifications
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id}
                  user_id={user_id}
                  email={email}
                  profile_pic={profile_pic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={skills}
                  work_Experience={work_Experience}
                  total_exp={total_exp}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={personalData}
                  profile_match={profile_match}
                  position={position}
                  handleClose={handleClose}
                />
              )}
            </div>
            <div></div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={UserIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Personal Information</span>
              </div>
              <div>
                <img src={isArrowDown ? ArrowDown : ArrowUp} onClick={handlePersonalInformationArrowDownClick} />
              </div>
            </div>
            <div>
              {showPersonalData && (
                <PersonalInformation
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id}
                  user_id={user_id}
                  email={email}
                  profile_pic={profile_pic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={skills}
                  work_Experience={work_Experience}
                  total_exp={total_exp}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={personalDatas}
                  profile_match={profile_match}
                  position={position}
                  handleClose={handleClose}
                />
              )}
            </div>
            <div></div>
          </div>
          {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

export default ViewProfile
