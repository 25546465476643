//import liraries
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import Layout from '../images/Layout-4-blocks (3).png';
import icInfo from './img/information (3)@2x.png';
import icUpload from './img/ic_upload.png';
import icSuccessGreen from './img/ic_success_green.png.png';
import './Success.css'

import { useAuth } from '../../../../app/modules/auth/core/Auth';
import axios from 'axios';
import LoaderView from './LoaderView';
import imagePath from '../../../../constants/imagePath';
const API_URL = process.env.REACT_APP_API_URL
export const UPLOAD_CSV_FILE_URL = `${API_URL}/test/csv-upload`;

type Props = {
    show: boolean
    handleClose: () => void
    handleUpdateTestsList: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const UploadTestCSVPopup = ({ show, handleClose, handleUpdateTestsList }: Props) => {
    const { auth } = useAuth();
    const [file, setFile] = useState<any>();
    const [loading, setLoading] = useState(false);

    const handleFileChange = (event) => {
        event.preventDefault();
        const csvFile = event.target.files[0];
        if (csvFile && csvFile.name.endsWith('.csv')) {
            setFile(csvFile);
        } else {
            alert('Please select a valid CSV file.');
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', file);
        setLoading(true);
        axios.post(UPLOAD_CSV_FILE_URL, formData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            setLoading(false);

            // update the list of tests
            handleUpdateTestsList();

        }).catch((err) => {
            setLoading(false);
            console.error('err', err);
        });

    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
            backdropClassName="modal-backdrop backdrop-blur"
        >
            <>
                <div className=' no-header-model123'>
                    <div className='modal-header no-header-model123'>
                        <h2></h2>
                        {/* begin::Close */}
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                            <img alt='' src={imagePath.ic_close}></img>
                        </div>
                    </div>
                    {/* end::Close */}
                    <div className=' d-flex flex-column '>
                        <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <h3 className='fw-bolder mx-auto my-5' style={{ color: file ? '#0BB783' : '#F36523' }}>{file ? 'File is ready to upload' : 'Import Tests from CSV'}</h3>
                                <img alt='' className='infoIcImg' src={file ? icSuccessGreen : imagePath.ic_upload_orange} />
                            </div>
                        </label>
                        <input type="file" id="csv-upload" accept=".csv" style={{ display: 'none' }} onChange={(event) => handleFileChange(event)} />
                        <div className='col-md-8 mt-10' style={{ alignSelf: 'center' }}>
                            <label>
                                <img className='me-2' alt='' src={icInfo} width={'14px'} ></img>
                                The CSV file must contain the following data points:
                            </label>
                            <ul className='mt-4'>
                                <li className='d-flex' > <span className="dot">.</span> <p className='fs-5 mt-3'> test_name </p></li>
                                <li className='d-flex'> <span className="dot">.</span> <p className='fs-5 mt-3'> group_competencies</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> test_time</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> question</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> type</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> option_a</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> option_b</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> option_c</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> option_d</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> correct_answer</p></li>
                                <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> competencies</p></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <form
                    //  onSubmit={handleSubmit}
                    >
                        <div className='btn-container'>
                            <button
                                onClick={handleSubmit}
                                type='button'
                                className='btn px-10 apply-review-button text-white'
                                disabled={loading || !file}
                            >
                                Upload
                            </button>
                        </div>
                    </form>
                </div>

                {loading && (
                    <LoaderView
                        message='Loading...'
                    />
                )}
            </>
        </Modal>,
        modalsRoot
    )

};

//make this component available to the app
export default UploadTestCSVPopup;
