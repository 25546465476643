//import liraries
import React, { useEffect } from 'react'
import { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useContext } from 'react'
import { LoginContext } from '../../../../app/App'
import iconleft from '../images/icons8-chevron-left-64.png'
import iconright from '../images/icons8-forward-64.png'
import rightarrow from '../images/keyboard_double_arrow_left_black_24dp.png'
import './Tables.css'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'

const API_URL = process.env.REACT_APP_API_URL
export const OFFER_ACCEPTANCE_URL = `${API_URL}/job/avg-offer-acceptance`

type Props = {
    className: string
    title: string
}

// create a component
const AverageOfferAcceptance: React.FC<Props> = ({ className, title }) => {
    const { currentUser, auth } = useAuth()
    const [Master, setMaster] = useState(false)
    const { loggedIn, setLoggedIn } = useContext(LoginContext)
    const [panigation, setpanigation] = useState(1)
    const [allApplications, setAllApplications] = useState<any>();

    const OnclickPanigation = (value) => {
        setpanigation(value)
    }


    const getOfferAcceptanceData = async () => {
        let recruiter_id = (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') ? 'admin' : currentUser?.user_id
        let url = `${OFFER_ACCEPTANCE_URL}?recruiter_id=${recruiter_id}`;
        await axios.get(url, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            console.log('applicationsList: ', res.data);
            let data = res?.data;
            setAllApplications(data?.sortedRecruiters);

        }).catch((err) => {
            console.error('err', err);
        });
    }

    useEffect(() => {
        (async () => {
            await getOfferAcceptanceData();
        })()
    }, []);

    return (
        <div className={`card ${className} `} style={{ padding: ' 0px 10px 20px 10px' }}>
            {/* begin::Header */}

            {/* end::Header */}
            {/* begin::Body */}

            <div className='card-body py-10 '>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead className='my-10'>
                            <tr className='fw-bold graycolor backgik bg-light'>
                                <th className='mx-5 px-5 min-w-200px margin-xxx'>Rank</th>
                                <th className='min-w-140px margin-xxx'>Recruiters</th>
                                <th className='min-w-120px margin-xxx'>{title}</th>
                            </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                            {allApplications && allApplications.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <a href='#' className='graycolor my-2 mx-3 text-hover-primary fs-6 px-5'>
                                                {data?.rank}{' '}
                                            </a>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='symbol symbol-45px me-5'>
                                                    <img
                                                        className='rounded-circle'
                                                        style={{ objectFit: 'cover' }}
                                                        src={data?.profileImage ? data?.profileImage : toAbsoluteUrl('/media/avatars/300-14.jpg')}
                                                        alt=''
                                                    />
                                                </div>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a href='#' className='text-dark fw-bolder my-2 text-hover-primary fs-6'>
                                                        {data?.name}
                                                    </a>
                                                    <span className='graycolor fw-semibold graycolor d-block fs-7'>
                                                        {data?.department}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>

                                        <td className='margin-xxx'>
                                            <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3'>
                                                {data?.acceptanceRate}%
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>
                {/* end::Table container */}

                <nav aria-label='Page navigation example'>
                    <ul className='pagination justify-content-end'>
                        <li>
                            <div className='me-4'>
                                <select
                                    name='status'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select form-select-sm   w-140px'
                                    defaultValue='10'
                                >
                                    <option style={{ fontWeight: 'Bold' }} value='option-for-form Active'>
                                        10
                                    </option>
                                    <option style={{ fontWeight: 'Bold' }} value='Approved'>
                                        20{' '}
                                    </option>
                                    <option style={{ fontWeight: 'Bold' }} value='Declined'>
                                        30
                                    </option>
                                    <option style={{ fontWeight: 'Bold' }} value='In Progress'>
                                        40
                                    </option>
                                </select>
                            </div>
                        </li>
                        <li className='page-item'>
                            <a onClick={() => OnclickPanigation(panigation - 1)} className='page-link' href='#'>
                                <img width={20} src={iconleft}></img>
                                <img width={20} src={iconleft}></img>
                            </a>
                        </li>
                        {/* <li className='page-item'>
              <a onClick={() => OnclickPanigation(1)} className='page-link' href='#'>
                1
              </a>
            </li>
            <li className='page-item'>
              <a onClick={() => OnclickPanigation(2)} className='page-link' href='#'>
                2
              </a>
            </li>
            <li className='page-item'>
              <a onClick={() => OnclickPanigation(3)} className='page-link' href='#'>
                3
              </a>
            </li> */}
                        <li className='page-item'>
                            <a onClick={() => OnclickPanigation(panigation + 1)} className='page-link' href='#'>
                                <img width={20} src={iconright}></img>
                                <img width={20} src={iconright}></img>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

            {/* begin::Body */}
        </div>
    );
};


//make this component available to the app
export default AverageOfferAcceptance;
