import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import JobsTabs from './JobsTabs'
import ActiveJobs from './ActiveJobs/ActiveJobs'
import './Jobs.css'
import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useCreateJobsContext } from '../../../context/useCreateJobsContext'
import { useCreateJobsButtonContext } from '../../../context/useJobsButtonContext'

const Jobs = () => {
  // const {jobState, setjobState} = useContext(SetJobsContext)
  const { jobsState, setjobsState } = useCreateJobsContext()

  const { jobButton, setjobButton } = useCreateJobsButtonContext()

  useEffect(() => {
    document.addEventListener('scroll', (e) => {
      let scrolled = document.scrollingElement!.scrollTop
      if (scrolled >= 300) {
        console.log('moved down')
        setjobButton(true)
      } else {
        console.log('top')
        setjobButton(false)
      }
    })
  }, [])

  useEffect(() => {
    setjobsState(true)

    return () => {
      setjobsState(false)
    }
  }, [])

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Jobs'})}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/jobs" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Jobs
          </Link>
        </h3>
      </div>

      <JobsTabs />

      {/* begin::Row */}
      <div className=' d-flex justify-content-center gy-5 gx-xl-8'></div>
    </>
  )
}

export default Jobs
