/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import iconleft from '../images/icons8-chevron-left-64.png'
import iconright from '../images/icons8-forward-64.png'
import axios from 'axios'
import { useAuth } from '../../../../app/modules/auth'

const API_URL = process.env.REACT_APP_API_URL
export const APPLICATIONS_PER_OPENING_URL = `${API_URL}/job/avg-application-per-opening`

type Props = {
  className: string
}

const TablesWidget12: React.FC<Props> = ({ className }) => {
  const { currentUser, auth } = useAuth()
  const [pagination, setPagination] = useState(1);
  const [allApplications, setAllApplications] = useState<any>();

  const OnclickPagination = (value) => {
    setPagination(value)
  }


  const fetchDataList = () => {
    let recruiter_id = (currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') ? 'admin' : currentUser?.user_id
    let url = `${APPLICATIONS_PER_OPENING_URL}?recruiter_id=${recruiter_id}`;
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      console.log('applicationsList: ', res.data);
      let data = res?.data;
      setAllApplications(data?.ApplicationData);

    }).catch((err) => {
      console.error('err', err);
    });
  }

  useEffect(() => {

    (async () => {
      await fetchDataList();
    })();

  }, []);

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold graycolor bg-light'>
                <th className='ps-4 min-w-100px rounded-start'>Rank</th>
                <th className='min-w-125px'>Position</th>
                <th className='min-w-125px'>Grade</th>
                <th className='min-w-125px'>Applications</th>
                <th className='min-w-125px'>Date Posted</th>
                <th className='min-w-120px'>Recruiters</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {allApplications && allApplications.map((data, index) => {

                return (
                  <tr key={index}>
                    <td>
                      <a href='#' className='text-dark fw-bolder mx-5 text-hover-primary fs-6'>
                        {data?.Rank}{' '}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {data?.Position}
                      </a>
                      <span className='text-left graycolor fw-semibold graycolor d-block fs-7'>
                        {data?.PositionDepartment}
                      </span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {`${data?.Grade}`}
                      </a>
                    </td>
                    <td>
                      <span className='badge fbadgetext-seeting badge-light-danger'>{data?.Applications}</span>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {data?.Date_Posted}{' '}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-45px me-5'>
                          <img
                            className='rounded-circle'
                            style={{objectFit:'cover'}}
                            src={ data?.recruiterProfile ? data?.recruiterProfile : toAbsoluteUrl('/media/avatars/300-14.jpg')}
                            alt=''
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-left text-dark fw-bolder my-2 text-hover-primary fs-6'>
                            {data?.Recruiter}
                          </a>
                          <span className='text-left graycolor fw-semibold graycolor d-block fs-7'>
                            {data?.recruiterDepartment}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        <nav aria-label='Page navigation example'>
          <ul className='pagination justify-content-end'>
            <li>
              <div className='me-4'>
                <select
                  style={{ borderRadius: '2px Solid #C9562A' }}
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-sm Filter-by-group-form-select  w-140px'
                  defaultValue='10'
                >
                  <option style={{ fontWeight: 'Bold' }} value='option-for-form Active'>
                    10
                  </option>
                  <option style={{ fontWeight: 'Bold' }} value='Approved'>
                    20{' '}
                  </option>
                  <option style={{ fontWeight: 'Bold' }} value='Declined'>
                    30
                  </option>
                  <option style={{ fontWeight: 'Bold' }} value='In Progress'>
                    40
                  </option>
                </select>
              </div>
            </li>
            <li className='page-item'>
              <a onClick={() => OnclickPagination(pagination - 1)} className='page-link' href='#'>
                <img width={20} src={iconleft}></img>
                <img width={20} src={iconleft}></img>
              </a>
            </li>
            {/* <li className='page-item'>
              <a onClick={() => OnclickPagination(1)} className='page-link' href='#'>
                1
              </a>
            </li>
            <li className='page-item'>
              <a onClick={() => OnclickPagination(2)} className='page-link' href='#'>
                2
              </a>
            </li>
            <li className='page-item'>
              <a onClick={() => OnclickPagination(3)} className='page-link' href='#'>
                3
              </a>
            </li> */}
            <li className='page-item'>
              <a onClick={() => OnclickPagination(pagination + 1)} className='page-link' href='#'>
                <img width={20} src={iconright}></img>
                <img width={20} src={iconright}></img>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget12 }
