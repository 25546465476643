//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import imagePath from '../../../../constants/imagePath';

type Props = {
    show: boolean
    reason: string
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
// create a component
const CandidateReasonPopup = ({ show, reason, handleClose }: Props) => {
    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h2 className='fw-bolder mx-auto mt-10 px-18' style={{ textAlign: 'center' }}>Reason for Rejection!</h2>
                            <h3 className='fw-semibold mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>
                                {reason}
                            </h3>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button
                                            onClick={handleClose}
                                            type='button'
                                            className='btn text-white px-10 candi-btn2'
                                        >
                                            Close
                                        </button>
                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default CandidateReasonPopup;
