//import liraries
import React, { Component, useEffect, useState } from 'react';
import SalaryProgressBar from '../dashboards/components/SalaryProgressBar';
import BudgetInfoBox from '../dashboards/components/BudgetInfoBox';
import imagePath from '../../../constants/imagePath';
import axios from 'axios';
import { useAuth } from '../auth/core/Auth';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_GROUPS = `${API_URL}/group`

// create a component
const BudgetCards = ({ groupName, ...params }) => {
    const { auth, currentUser, logout } = useAuth()
    const user_id = currentUser?.user_id
    const [currentCard, setCurrentCard] = useState('basicSalary');
    const [yearlySalary, setYearlySalary] = useState(-1)
    const [yearlyRemuneration, setYearlyRemuneration] = useState(-1)
    const [yearlyRemainingSalary, setYearlyRemainingSalary] = useState(0)
    const [yearlyRemainingRemuneration, setYearlyRemainingRemuneration] = useState(0)
    const [yearlyLapsedSalary, setYearlyLapsedSalary] = useState(0)
    const [yearlyLapsedRemuneration, setYearlyLapsedRemuneration] = useState(0)
    const [yearlySalaryPercentage, setYearlySalaryPercentage] = useState(0)
    const [yearlyRemunerationPercentage, setYearlyRemunerationPercentage] = useState(0)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        (async () => {
            await getBudgetData(groupName);
        })()
    }, [groupName])

    // getting data of budget
    const getBudgetData = async (groupName) => {
        const urlWithParams = `${GET_ALL_GROUPS}?group_name=${encodeURIComponent(groupName)}`
        axios.get(urlWithParams, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
            .then((res) => {
                const group = res.data.data[0]
                let yearlySalaryBudget = group.yearly_salary_budget ? group.yearly_salary_budget : 0
                let yearlyRemunerationBudget = group.yearly_remuneration_budget ? group.yearly_remuneration_budget : 0
                let yearlyLapsedBudget = group.lapsed_salary_budget ? group.lapsed_salary_budget : 0
                let yearlyLapsedRemuneration = group.lapsed_remuneration_budget ? group.lapsed_remuneration_budget : 0

                if (yearlyLapsedBudget !== null && yearlyLapsedBudget !== undefined) {
                    let yearlySalaryRemaining = yearlySalaryBudget - yearlyLapsedBudget
                    setYearlyRemainingSalary(yearlySalaryRemaining)
                    setYearlyLapsedSalary(parseInt(yearlyLapsedBudget))

                    let salaryPercentage = (yearlyLapsedBudget / yearlySalaryBudget) * 100
                    setYearlySalaryPercentage(salaryPercentage > 100 ? 100 : salaryPercentage)
                } else {
                    setYearlyRemainingSalary(yearlySalaryBudget)
                }
                if (yearlyLapsedRemuneration !== null && yearlyLapsedRemuneration !== undefined) {
                    let yearlyRemunerationRemaining = yearlyRemunerationBudget - yearlyLapsedRemuneration
                    setYearlyRemainingRemuneration(yearlyRemunerationRemaining)
                    setYearlyLapsedRemuneration(parseInt(yearlyLapsedRemuneration))
                    let remunerationPercentage = (yearlyLapsedRemuneration / yearlyRemunerationBudget) * 100
                    setYearlyRemunerationPercentage(remunerationPercentage > 100 ? 100 : remunerationPercentage)
                } else {
                    setYearlyRemainingRemuneration(yearlyRemunerationBudget)
                }

                setYearlySalary(parseInt(yearlySalaryBudget))
                setYearlyRemuneration(parseInt(yearlyRemunerationBudget))
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message)
                    setShowErrorMessage(true)
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.name === 'TokenExpiredError'
                ) {
                    logout()
                } else if (error.response?.data?.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout()
                    } else {
                        setMessage(error.response.data.error.message)
                        setShowErrorMessage(true)
                    }
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            })
    }

    const handleClickArrow = () => {
        setCurrentCard(currentCard === 'basicSalary' ? 'remunerationSalary' : 'basicSalary');
    }

    return (
        <div className='ms-2 card-bg shadow-sm' style={{ height: '22rem' }} >
            <div className='d-flex align-items-center' style={{ borderBottom: '1px solid #E5E5E5', width: '100%' }}>
                <label className='fs-5 fw-semibold ps-3 py-4' >{currentCard === 'basicSalary' ? 'Yearly Salary Budget' : 'Yearly Remuneration Budget'}</label>
                <div className='d-flex ms-auto align-items-center me-3'>
                    <img src={imagePath.ic_arrow_left_circle}
                        width={18} height={18}
                        style={{ cursor: 'pointer', zIndex: 1, marginRight: '2px' }}
                        onClick={() => handleClickArrow()} />

                    <img src={imagePath.ic_arrow_right_circle}
                        width={18} height={18}
                        style={{ cursor: 'pointer', zIndex: 1 }}
                        onClick={() => handleClickArrow()} />
                </div>
            </div>

            {currentCard === 'basicSalary' ? (
                <div className='d-flex align-items-center py-4'>
                    <div className='justify-content-center align-items-center mx-3' style={{ width: '160px' }} >
                        <SalaryProgressBar
                            remainingBudget={yearlyRemainingSalary}
                            lapsedBudget={yearlyLapsedSalary}
                            totalBudget={yearlySalary}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-center ms-auto me-3' >
                        <BudgetInfoBox
                            type='Remaining'
                            value={yearlyRemainingSalary.toLocaleString()}
                            color='lightOrange'
                        />
                        <div className='my-2'>
                            <BudgetInfoBox
                                type='Lapsed'
                                value={yearlyLapsedSalary.toLocaleString()}
                                color='darkOrange'
                            />
                        </div>
                        <BudgetInfoBox
                            type='Total'
                            value={yearlySalary.toLocaleString()}
                            color='gray'
                        />
                    </div>
                </div>

            ) : (
                <div className=' d-flex align-items-center py-4'>
                    <div className='justify-content-center align-items-center mx-3' style={{ width: '160px' }}>
                        <SalaryProgressBar
                            remainingBudget={yearlyRemainingRemuneration}
                            lapsedBudget={yearlyLapsedRemuneration}
                            totalBudget={yearlyRemuneration}
                        />
                    </div>
                    <div className='d-flex flex-column justify-content-center ms-auto me-3 ' >
                        <BudgetInfoBox
                            type='Remaining'
                            value={yearlyRemainingRemuneration.toLocaleString()}
                            color='lightOrange'
                        />
                        <div className='my-2'>
                            <BudgetInfoBox
                                type='Lapsed'
                                value={yearlyLapsedRemuneration.toLocaleString()}
                                color='darkOrange'
                            />
                        </div>
                        <BudgetInfoBox
                            type='Total'
                            value={yearlyRemuneration.toLocaleString()}
                            color='gray'
                        />
                    </div>
                </div>)}

            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        </div>
    );
};

//make this component available to the app
export default BudgetCards;
