/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import './insights.css'
import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useCreateGenerateButtonContext} from '../../../context/useCreateGenerateReportContext'
import InsightsTabs from './InsightsTabs'
import html2pdf from 'html2pdf.js'

const Insights = () => {
  const {Genbutton, setGenbutton} = useCreateGenerateButtonContext()
  const [dateTime, setDateTime] = useState('')
  useEffect(() => {
    setGenbutton(true)
    return () => {
      setGenbutton(false)
    }
  }, [])

  const location = useLocation()

  useEffect(() => {
    // Access taLead from the state object
    const taLeadData = location.state?.taLead
  }, [location])

  const handleDateChange = (date) => {
    setDateTime(date)
  }

  const handleGenerateReportsClick = () => {
    if (Genbutton) {
      // Trigger PDF generation logic here
      generatePDF()
    }
  }

  const generatePDF = () => {
    const content = document.getElementById('insights-content')

    if (content) {
      html2pdf(content)
    }
  }

  const intl = useIntl()
  const currentDate = new Date()
  const month = currentDate.toLocaleString('default', {month: 'short'})
  const day = currentDate.getDate()
  const hour = currentDate.getHours()
  const minute = currentDate.getMinutes()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Insights'})}</PageTitle>
      <a
        className='btn CreateIndiviualCompetency'
        style={{marginTop: '-7.5rem', marginLeft: 'auto', float: 'right'}}
        onClick={handleGenerateReportsClick}
      >
        Generate Report
      </a>
      <div id='insights-content'>
        <div style={{color: 'White'}} className='ml-1 mt-2 text-white margin-bottom-for-plusiconm'>
          {`Last Updated at: ${hour}:${minute.toString().padStart(2, '0')} - ${day} ${month}`}
        </div>

        <InsightsTabs onDateChange={handleDateChange} />
      </div>
    </>
  )
}

export default Insights
