//import liraries
import {useEffect, useState} from 'react'
import {useContext} from 'react'
import {useIntl} from 'react-intl'

import userIcon from '../../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
import timeImage from '../../../../_metronic/partials/widgets/images/Group 9724 (2).png'
import {FaSort, FaSortUp, FaSortDown} from 'react-icons/fa'
import './leaderboard.css'
import axios from 'axios'
import {useAuth} from '../../auth/core/Auth'
import {LoginContext} from '../../../App'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
const GET_LEADERBOARD_DATA_URL = `${API_URL}/job/ta-leaderboard-data`

// create a component
const TaLeadLeaderboard = () => {
  const intl = useIntl()
  const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const {currentUser, auth, logout} = useAuth()
  const [taLeadOfMonth, setTaLeadOfMonth] = useState<any>()
  const [allTaLeads, setAllTaLeads] = useState<any>()
  const [currentMonthYear, setCurrentMonthYear] = useState<any>({month: '', year: ''})
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...allTaLeads].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    setAllTaLeads(sorted)
    setSortField(field)
    setSortDirection(direction)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const getLeaderboardData = async () => {
    // let id = currentUser?.role === 'masterRecruiter' ? 'admin' : currentUser?.user_id
    let id = currentUser?.user_id
    // let url = `${GET_LEADERBOARD_DATA_URL}?ta_id=${id}`;
    let url = `${GET_LEADERBOARD_DATA_URL}`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('leaderboardData: ', res.data)
        let data = res?.data
        let leaderboardData = data?.leaderboardData
        let formattedList: any = []

        for (let taLeadName in leaderboardData) {
          const taLeadData = leaderboardData[taLeadName]
          formattedList.push({
            taName: taLeadName,
            avgApplicationsPerOpening: taLeadData.avgApplicationsPerOpening,
            averageTimeToHire: taLeadData.averageTimeToHire,
            attritionRate: taLeadData.attritionRate,
            acceptanceRate: taLeadData.acceptanceRate,
            department: taLeadData.department,
            profileImage: taLeadData.profileImage,
            recruiters: taLeadData.recruiters,
            rank: taLeadData.rank,
          })
        }

        if (formattedList.length > 0) {
          console.log('recruitersData:totalLength: ', formattedList.length)
          let topRecruiter = formattedList.shift() // Get and remove the first object
          setTaLeadOfMonth(topRecruiter)
        }

        if (formattedList.length > 0) {
          console.log('recruitersData:ReducedLength: ', formattedList.length)
          setAllTaLeads(formattedList)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      const currentDate = new Date()
      const currentMonth = currentDate.toLocaleString('default', {month: 'short'})
      const currentYear = currentDate.getFullYear()
      setCurrentMonthYear({month: currentMonth, year: currentYear})

      await getLeaderboardData()
    })()
  }, [])

  return (
    <div className=' gy-5 gx-xl-8'>
      {/* leader board banner */}
      <div className='card-bg pb-8 shadow'>
        <div className='py-8' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <label className='form-label fs-4 fw-bolder mx-auto' style={{textAlign: 'center'}}>
            TA Lead of the Month
          </label>
        </div>
        <div className='d-flex'>
          <div className='col-md-4' style={{justifyContent: 'center'}}>
            <div className='d-flex px-8' style={{justifyContent: 'center'}}>
              <div className='me-8'>
                <img
                  src={taLeadOfMonth?.profileImage ? taLeadOfMonth?.profileImage : userIcon}
                  width={'100px'}
                  height={'100px'}
                  style={{borderRadius: '50px', objectFit: 'cover', border: '4px solid #F36523'}}
                />
              </div>
              <div className='d-flex flex-column'>
                <label className='form-label fs-6 fw-bolder'>{taLeadOfMonth?.taName}</label>
                <label className='form-label fs-7 fw-regular'>{taLeadOfMonth?.department}</label>
                <div style={{display: 'flex'}}>
                  {taLeadOfMonth?.recruiters &&
                    taLeadOfMonth?.recruiters.map((recruiter, index) => {
                      return (
                        <div key={index} className='me-1'>
                          <img
                            style={{objectFit: 'contain', width: '24px', height: '24px', border: 'solid 1px #F36523', borderRadius: '50%'}}
                            src={recruiter?.profile_pic ? recruiter?.profile_pic : userIcon}
                            title={recruiter?.name}
                            alt='Recruiter profile image'
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
          {/* 2 div */}
          <div className='col-md-4 d-flex' style={{justifyContent: 'center', alignItems: 'center', marginLeft:'20px', marginTop:'-10px'}}>
            <div className='d-flex flex-column me-8'>
              <label className='fs-2 fw-bolder mb-2' style={{color: '#F36523'}}>
                {taLeadOfMonth?.averageTimeToHire ? taLeadOfMonth?.averageTimeToHire : 'Days'}{' '}
              </label>
              <label className='fs-7 fw-regular'>Avg. Time to Hire</label>
            </div>
            <div>
              <img src={timeImage} width={'160px'} height={'120px'} style={{objectFit: 'contain'}} />
            </div>
          </div>

          {/* 3 div */}
          <div className='col-md-4 d-flex' style={{justifyContent: 'left', alignItems: 'center', marginLeft:'40px', marginTop:'-10px'}}>
            <div className='d-flex flex-column me-8'>
              <label className='fs-2 fw-bolder mb-2' style={{color: '#F36523'}}>
                {taLeadOfMonth?.attritionRate}%
              </label>
              <label className='fs-7 fw-regular'>Avg. First Year Attrition</label>
            </div>
            <div>
              {/* <img src={calendar} width={'88px'} height={'88px'} style={{ objectFit: 'contain' }} /> */}
              <div>
                <label className='fs-2 fw-bolder' style={{color: '#F36523'}}>
                  {currentMonthYear.month}
                </label>
              </div>
              <div>
                <label className='fs-2 fw-bolder' style={{color: '#F36523'}}>
                  {currentMonthYear.year}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* leader board table data */}
      <div className='card-bg pb-8 shadow mt-10'>
        <div className='table-responsive p-8'>
          {/* begin::Table */}
          <table style={{border:"1px solid #EDF2F9"}} className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead className='my-10 table-header'>
              <tr
              // className='fw-bold graycolor backgik bg-light'
              >
                <th
                  onClick={() => sortByField('rank')}
                  className='min-w-140px margin-xxx form-label fw-bolder fs-6'
                  style={{textAlign: 'left', paddingLeft:"15px"}}
                >
                   Rank {renderSortIcon('rank')}
                </th>
                <th
                  onClick={() => sortByField('taName')}
                  className='min-w-140px margin-xxx form-label fw-bolder fs-6'
                  style={{textAlign: 'left'}}
                >
                   TA Lead {renderSortIcon('taName')}
                </th>
                <th onClick={() => sortByField('averageTimeToHire')} className='min-w-140px margin-xxx form-label fw-bolder fs-6'>
                  Avg. Time to Hire {renderSortIcon('averageTimeToHire')}
                </th>
                <th onClick={() => sortByField('acceptanceRate')} className='min-w-140px margin-xxx form-label fw-bolder fs-6'>
                  Offer Acceptance Rate {renderSortIcon('acceptanceRate')}
                </th>
                <th onClick={() => sortByField('avgApplicationsPerOpening')} className='min-w-140px margin-xxx form-label fw-bolder fs-6'>
                   Avg. Applications per Position {renderSortIcon('avgApplicationsPerOpening')}
                </th>
                <th onClick={() => sortByField('attritionRate')} className='min-w-120px margin-xxx form-label fw-bolder fs-6'>
                 Avg. First Year Attrition  {renderSortIcon('attritionRate')}
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {allTaLeads &&
                allTaLeads.map((data, index) => {

                    // variable for row difference 
                    const evenforrow =  index % 2 ==0;
                  return (
                    <tr style={{ backgroundColor: evenforrow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9'}}  key={index}>
                      <td style={{textAlign: 'left'}}>
                        <a href='#' className='graycolor my-0 mx-0 text-hover-primary fs-6 px-5'>
                          {data?.rank}{' '}
                        </a>
                      </td>
                      <td style={{textAlign: 'center'}}>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              className='rounded-circle'
                              style={{objectFit: 'cover'}}
                              src={data?.profileImage ? data?.profileImage : userIcon}
                              alt=''
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bolder my-2 text-hover-primary fs-6'>
                              {data?.taName}
                            </a>
                            <div style={{display: 'flex'}}>
                              {data?.recruiters &&
                                data?.recruiters.map((recruiter, index) => {
                                  return (
                                    <div key={index} className='me-1'>
                                      <img
                                        style={{
                                          objectFit: 'contain',
                                          width: '24px',
                                          height: '24px',
                                          border: 'solid 1px #F36523',
                                          borderRadius: '50%',
                                        }}
                                        src={recruiter?.profile_pic ? recruiter?.profile_pic : userIcon}
                                        title={recruiter?.name}
                                        alt='Recruiter profile image'
                                      />
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td style={{textAlign: 'left'}}>
                        <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3' style={{backgroundColor:"transparent"}} >
                          {data?.averageTimeToHire}
                        </div>
                      </td>

                      <td style={{textAlign: 'left' }}>
                        <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3' style={{backgroundColor:"transparent"}} >
                          {data?.acceptanceRate}%
                        </div>
                      </td>

                      <td style={{textAlign: 'left'}}>
                        <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3' style={{backgroundColor:"transparent"}} >
                          {data?.avgApplicationsPerOpening}
                        </div>
                      </td>

                      <td className='margin-xxx' style={{textAlign: 'left'}}>
                        <div className='settingbadgecolorandfont badge badge-light  fw-bold my-2 fw-bold py-4 px-3' style={{backgroundColor:"transparent"}} >
                          {data?.attritionRate}%
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default TaLeadLeaderboard
