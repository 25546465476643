//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import './CreateCompetency.css'
import cross from '../images/Layout-4-blocks.png'
import CustomToast from './CustomToast';

type Props = {
  handleClose: () => void
  message: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const ShowError = ({ handleClose, message }: Props) => {

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleClose();
    }, 1500);

    // Clear the timeout when the component is unmounted or when handleClose is called manually
    return () => clearTimeout(timeoutId);
  }, [handleClose]);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog  mw-1000px'
      show={true}
      backdrop={true}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
      }}
    >

      <div style={{ width: '100%' }}>
        <CustomToast message={message} />
      </div>

    </Modal>,
    modalsRoot
  );
};

//make this component available to the app
export default ShowError;
