/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef, ForwardRefRenderFunction, Ref, useEffect } from 'react'
import { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useContext } from 'react'
import { LoginContext } from '../../../App'
import '../Settings.css'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import ReallocatePocRecruiter from '../../../../_metronic/partials/modals/create-app-stepper/ReallocatePocRecruiter'
// import userIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png';
import userIcon from '../../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import CustomPagination from '../../dashboards/components/CustomPagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL
// export const GET_ALL_ALLOCATED_URL = `${API_URL}/poc-ra`
export const GET_ALL_ALLOCATED_URL = `${API_URL}/group`
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`
export const GET_DEPARTMENT_URL = `${API_URL}/department/`

type Props = {
  className: string
}

type RefType = {
  GetAllResources: () => Promise<void>
}
interface Resource {
  RecData: Object
  pocData: Object
  raRow: Object
  groupData: Object
}

const RespourceTableWRTdpt: ForwardRefRenderFunction<RefType, Props> = ({ className }, ref) => {
  const [Master, setMaster] = useState(false)
  const { loggedIn, setLoggedIn } = useContext(LoginContext)
  const [dataFetched, setDataFetched] = useState(false)
  const [allResources, setAllResources] = useState([{}])
  // const [resources, setResources] = useState<Resource[]>([])
  const [resources, setResources] = useState<any>([])
  const [showReallocatePopup, setShowReallocatePopup] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState<any>({});
  const [selectedGroupHead, setSelectedGroupHead] = useState<any>({});
  const [selectedPOC, setSelectedPOC] = useState<any>({});
  const [selectedTaLead, setSelectedTaLead] = useState<any>({});
  const [selectedResourceId, setSelectedResourceId] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const { auth, logout } = useAuth()
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [totalPages, setTotalPages] = useState(1)
  const [loadingTable, setLoadingTable] = useState(false)
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })

  const GetAllResources = async () => {
    setLoadingTable(true)
    await axios
      .get(GET_ALL_ALLOCATED_URL, {
        params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        setLoadingTable(false)
        let pagination = resp?.data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        let data = resp?.data?.data
        setResources(data)
      })
      .catch((error) => {
        setLoadingTable(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // Pass the ref to the parent component
  React.useImperativeHandle(ref, () => ({
    GetAllResources,
  }))

  useEffect(() => {
    // if (!dataFetched) {
    ; (async () => {
      await GetAllResources()

      // setDataFetched(true)
    })()

    // }

    // return () => {

    // }
  }, [params])

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...resources].sort((a, b) => {
      const valueA = getFieldNestedValue(a, field)
      const valueB = getFieldNestedValue(b, field)
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    setResources(sorted)
    setSortField(field)
    setSortDirection(direction)
  }

  const getFieldNestedValue = (obj, field) => {
    const fieldParts = field.split('.') // Split field path into parts
    let value = obj
    for (const part of fieldParts) {
      value = value[part] // Access nested property
    }
    return value
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.page = 1
    data.limit = event.target.value
    setParams(data)
  }

  return (
    <>
      <div>
        {/* <div className='card-bg mt-12 g-5 g-xl-8 px-10 pb-8 shadow-sm'> */}
        {/* <div className='card mb-5 mb-xl-8 card-xxl-stretch'> */}
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <div>
            <h3 className='align-items-start flex-column'>
              <span className='card-label text-gray-800 fw-bolder fs-3 mb-1'>Resources W.R.T Groups</span>
            </h3>
          </div>
          {/* <div>
            <button className='generatereportsintable-resource'>Generate Reports</button>
          </div> */}
        </div>

        <div className='card-body py-3' style={{ position: 'relative' }}>
          {/* add loader */}
          {loadingTable && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          <div className='table-responsive'>
            <table className='table mx-auto table-row-gray-100 align-middle gs-0 gy-3 px-4 table-borders'>
              <thead>
                <tr className='fw-bold graycolor grajabg px-4'>
                  <th
                    onClick={() => sortByField('groupData.group_name')}
                    scope='col'
                    className='align-items-center ps-4'
                    style={{ width: '12rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Group</label>
                    {renderSortIcon('groupData.group_name')}
                  </th>
                  <th
                    onClick={() => sortByField('pocData.fullName')}
                    scope='col'
                    className='align-items-center ps-4'
                    style={{ width: '12rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Group Head</label>
                    {renderSortIcon('pocData.fullName')}
                  </th>
                  <th
                    onClick={() => sortByField('pocData.fullName')}
                    scope='col'
                    className='align-items-center ps-4'
                    style={{ width: '12rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>POC</label>
                    {renderSortIcon('pocData.fullName')}
                  </th>
                  <th
                    onClick={() => sortByField('RecData.fullName')}
                    scope='col'
                    className='align-items-center ps-4'
                    style={{ width: '12rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>TA Lead</label>
                    {renderSortIcon('RecData.fullName')}
                  </th>
                  <th scope='col' className='align-items-center ps-4' style={{ width: '12rem', color: '#000000' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Action </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {resources ? (
                  resources.map((resource, index) => {
                    const taLeadData: any = resource?.ta_lead
                    const groupHead = resource?.group_head
                    const pocData = resource?.poc
                    const groupData = {
                      group_name: resource?.group_name,
                      group_id: resource?.group_id,
                    }
                    const resourceId = resource?.group_id
                    const isEvenRow = index % 2 === 0
                    return (
                      <tr
                        key={index}
                        className='px-4'
                        style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}
                      >
                        <td>
                          <label style={{ padding: '0px 0px 0px 20px' }} className='serangkang my-2  fs-6'>
                            {groupData ? groupData['group_name'] : ''}
                          </label>
                        </td>
                        <td>
                          {groupHead ? (
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img
                                  className='rounded-circle'
                                  style={{ objectFit: 'cover' }}
                                  src={groupHead['profile_pic'] ? groupHead['profile_pic'] : userIcon}
                                  alt=''
                                />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <label className='serangkang my-2 fs-6'>{`${groupHead['name']}`}</label>
                              </div>
                            </div>
                          ) : (
                            <div>Not Allocated</div>
                          )}
                        </td>
                        <td>
                          {pocData ? (
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img
                                  className='rounded-circle'
                                  style={{ objectFit: 'cover' }}
                                  src={pocData['profile_pic'] ? pocData['profile_pic'] : userIcon}
                                  alt=''
                                />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <label className='serangkang my-2 fs-6'>{`${pocData['fullName']}`}</label>
                              </div>
                            </div>
                          ) : (
                            <div>Not Allocated</div>
                          )}
                        </td>
                        <td>
                          {taLeadData ? (
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-45px me-5'>
                                <img
                                  className='rounded-circle'
                                  style={{ objectFit: 'cover' }}
                                  src={taLeadData?.profile_pic ? taLeadData['profile_pic'] : userIcon}
                                  alt=''
                                />
                              </div>
                              <div className='d-flex justify-content-start flex-column'>
                                <label className='serangkang my-2 fs-6'>{`${taLeadData?.fullName}`}</label>
                              </div>
                            </div>
                          ) : (
                            <div>Not Allocated</div>
                          )}
                        </td>
                        <td>
                          <label
                            className='reallocate-text fw-bolder my-2 fs-6'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedGroup(groupData)
                              setSelectedGroupHead(groupHead)
                              setSelectedPOC(pocData)
                              setSelectedTaLead(taLeadData)
                              setSelectedResourceId(resourceId)
                              setShowReallocatePopup(true)
                            }}
                          >
                            + Reallocate
                          </label>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <div></div>
                )}
              </tbody>
            </table>
          </div>
          {(resources && resources.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
            <div className='col-md-4'>
              <label>{`Showing ${resources ? resources.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      {showReallocatePopup && (
        <ReallocatePocRecruiter
          handleClose={() => {
            setShowReallocatePopup(false)
            setSelectedGroup({})
            setSelectedPOC({})
            setSelectedTaLead({})
          }}
          updateResources={GetAllResources}
          groupData={selectedGroup}
          groupHeadData={selectedGroupHead}
          pocData={selectedPOC}
          taLeadData={selectedTaLead}
          resourceId={selectedResourceId}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default forwardRef(RespourceTableWRTdpt)
