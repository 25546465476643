import React from 'react'
import logo from '../../assets/BOP_ Logo (shadow)@2x.png'
import './Loader.styles.css'
import { LinearProgress } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Loader = (props) => {
  return (
    // <div className='loader-container'>
    //     <div className='loader-desc-container'>
    //         <img src={logo} />
    //         <LinearProgress className='loader-linear-progress'/>
    //         <h1>{props.message}</h1>
    //     </div>
    // </div>

    <div className='loader-container'>
      <div className='request-approvals-tile-final-step-spinner loader-desc-container'>
        <FontAwesomeIcon icon={faSpinner} spin color='#F36523' size='3x' />
        <h1>{props.message}</h1>
      </div>
    </div>
  )
}

export default Loader