import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import StepNavigation from './StepNavigation'
import PersonalInformation from './PersonalInformation'
import WorkExperience from './WorkExperience'
import headImg from '../assets/Group12393.png';
import boplogo from '../assets/BOP_ Logo (shadow)@2x.png'
import Education from './Education'
import Training from './Training';
import Skills from './Skills';
import { useAuth } from '../../../modules/auth'
import ProgressBar from './ProgressBar';
import ProgressBS from './ProgressBS';
import CustomizedSteppers from './MuiStepper'
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
export const ALLDATA_URL = `${API_URL}/candidate/`

const StepperComponentCV = (props) => {
  // const { comingFrom } = props;
  const location = useLocation();
  const screen = location.state && location.state.screen;
  console.log('screen: ', screen)
  let screenShiftedFrom;
  if (location.state !== undefined && location.state !== null) {
    const { comingFrom } = location?.state as Record<string, unknown>;
    screenShiftedFrom = comingFrom;
  }
  console.log('StepperComponentCV: comingFrom: ', screenShiftedFrom);
  const navigate = useNavigate();
  const labelArray = ['Personal Information', 'Work Experience', 'Education', 'Training & Certificates', 'Skills']
  const [currentStep, updateCurrentStep] = useState(
    screen === 'education' ? 3 : (screen === 'certificates' ? 4 : (screen === 'skills' ? 5 : 1))
    // 1
  )
  const [componentToShow, updateComponentToShow] = useState(
    screen === 'education' ? < Education /> : (screen === 'certificates' ? <Training /> : <PersonalInformation />)
    // < PersonalInformation />
  )
  const [workExperiences, setWorkExperiences] = useState([])
  const [university, setUniversity] = useState('')
  const [education, setEducation] = useState([])
  const [skills, setSkills] = useState([])
  const { currentUser, auth } = useAuth()

  const user_id = currentUser?.user_id

  const [profileStrength, setProfileStrength] = useState(5);
  const [name, setName] = useState('');
  const [reducer, setReducer] = useState(0)
  const [refresh, setRefresh] = useState(0)

  const [filledSteps, setFilledSteps] = useState<any>([false, false, false, false, false]);

  const handleStepFilled = (stepIndex) => {
    // Update the filledSteps array based on stepIndex
    console.log('stepIndex: ', stepIndex);
    const updatedFilledSteps = [...filledSteps];
    updatedFilledSteps[stepIndex] = true;
    console.log('stepIndex: updatedFilledSteps: ', updatedFilledSteps);
    setFilledSteps(updatedFilledSteps);
  };

  function updateStep(step) {
    updateCurrentStep(step)
    switch (step) {
      case 1:
        updateComponentToShow(<PersonalInformation update={currentStep} />)
        break
      case 2:
        updateComponentToShow(<WorkExperience />)
        break
      case 3:
        updateComponentToShow(<Education />)
        break
      case 4:
        updateComponentToShow(<Training />)
        break
      case 5:
        updateComponentToShow(<Skills />)
        break
      case 6:
        navigate('/dashboard')
        break
      default:
        break
    }
  }

  function validateValue(value) {
    if (value !== null && value !== undefined && value !== '') {
      return value;
    } else {
      return '';
    }
  }

  // ---get user data---
  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id)
        .then(response => {
          // console.log("CANDIADTE: ", response.data.candidate.work_experience)
          let currStepFound = false;
          const strength = JSON.parse(response.data.candidate.profile_strength);
          const currentStrength = strength.total ? strength.total : 5;
          if (currentStrength >= 100 && screenShiftedFrom !== 'editProfile' && !screen) {
            navigate('/candidatedashboard')
          }
          let profile = strength.profile_info;
          setProfileStrength(currentStrength > 100 ? 100 : currentStrength);
          const userData = response.data.user;
          let firstName = validateValue(userData.first_name);
          let middleName = validateValue(userData.middle_name);
          let lastName = validateValue(userData.last_name);

          const name = firstName + ' ' + middleName + ' ' + lastName;
          setName(firstName);
          let dataFilledAtIndex = [...filledSteps];
          if (typeof profile === "string") {
            profile = (strength.profile_info?.toLowerCase?.() === 'true')
            if (profile) {
              dataFilledAtIndex[0] = true;
            }
          }
          let work = strength.work_exp;
          if (typeof work === "string") {
            work = (strength.work_exp?.toLowerCase?.() === 'true')
            if (work) {
              dataFilledAtIndex[1] = true;
            }
          }
          let edu = strength.education;
          if (typeof edu === "string") {
            edu = (strength.education?.toLowerCase?.() === 'true')
            if (edu) {
              dataFilledAtIndex[2] = true;
            }
          }
          let certifi = strength.certificate;
          if (typeof certifi === "string") {
            certifi = (strength.certificate?.toLowerCase?.() === 'true')
            if (certifi) {
              dataFilledAtIndex[3] = true;
            }
          }
          let skill = strength.skills;
          if (typeof skill === "string") {
            skill = (strength.skills?.toLowerCase?.() === 'true')
            if (skill) {
              dataFilledAtIndex[4] = true;
            }
          }
          console.log('dataFilledAtIndex: ', dataFilledAtIndex)
          setFilledSteps(dataFilledAtIndex);

          if (!profile && !currStepFound) {
            currStepFound = true;

            updateCurrentStep(1);
          }
          if (!work && !currStepFound) {
            currStepFound = true;
            updateCurrentStep(2);
          }
          if (!edu && !currStepFound) {
            currStepFound = true;
            updateCurrentStep(3);
          }
          if (!certifi && !currStepFound) {
            currStepFound = true;
            updateCurrentStep(4);
          }
          if (!skill && !currStepFound) {
            currStepFound = true;
            updateCurrentStep(5);
          }

        })
    }
    getData();
  }, [reducer])

  const updateProgress = (strength) => {
    console.log('updatingProgressInParent: ', strength);
    setProfileStrength(strength);
  };

  return (
    <div className='stepper-container'>
      <div className='candi-logo-container'>
        <img className='candi-bop-logo' src={boplogo} alt='...' />
      </div>
      <div className='candi-intro-container'>
        <h1 className='candi-heading'>Hello, {name ? name.length > 2 ? name : 'Candidate' : 'Candidate'}</h1>
        <label className='stepper-header-text candi-subheading'>You're one step closer to an exciting career at Bank Of Punjab</label>
        <img className='headimg' src={headImg} alt="..." />
      </div>
      <div className='step-container'>

        <CustomizedSteppers step={currentStep - 1} curr={currentStep} updateStep={updateStep} filledSteps={filledSteps} />
      </div>

      <p className='form-label labelse'>Profile Strength</p>
      <ProgressBS percent={profileStrength} />
      {/* currentStep * 16  */}

      {/* {componentToShow} */}
      {
        currentStep == 1 ? <PersonalInformation reduce={setReducer} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress}
          workExperiences={setWorkExperiences}
          university={setUniversity}
          education={setEducation}
          skills={setSkills} /> :
          // currentStep == 2 ? <WorkExperience reduce={profileStrength} chk={name} setChk={setRefresh} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress} /> :
          currentStep == 2 ? <WorkExperience reduce={setReducer} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress}
            workExperiences={workExperiences} /> :
            currentStep == 3 ? <Education reduce={setReducer} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress}
              university={university} education={education} /> :
              currentStep == 4 ? <Training reduce={setReducer} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress} /> :
                currentStep == 5 ? <Skills reduce={setReducer} curr={currentStep} updates={updateCurrentStep} onUpdateProgress={updateProgress}
                  skills={skills} /> :
                  null
      }
    </div>
  )
}

export default StepperComponentCV
