import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { PublicRoutes } from '../PublicRouting/PublicRoute'
import VerifyAccount from '../modules/auth/components/VerifyAccount'
import ResetPassword from '../modules/auth/components/ResetPassword'
import AccountNotVerified from '../modules/auth/components/AccountNotVerified'
import OfferLetterExternal from '../modules/JobsFunneling/OfferLetterExternal'
import CompleteUserProfile from '../modules/users/CompleteUserProfile'
import { isCandidatePath } from '../../constants/CommonFunctions'
import RequisitionApproval from '../modules/JobsFunneling/RequisitionApproval'
import AdvertisementApproval from '../modules/JobsFunneling/AdvertisementApproval'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
// import CVWrapper from '../modules/Candidate Space/CVScreens/CVWrapper'

const { PUBLIC_URL } = process.env

// const AppRoutes: FC = () => {
//   const { currentUser } = useAuth()
//   console.log(currentUser);
//   return (
//     <BrowserRouter basename={PUBLIC_URL}>
//       <Routes>
//         <Route element={<App />}>
//           <Route path='error/*' element={<ErrorsPage />} />
//           {/* <Route path='auth/candidateregistration' element={<CandidateAuthPage/>} />
//           <Route element={<AuthLayout />}>
//       <Route path='auth/candidatelogin' element={<CandidateLogin />} />
//       <Route path='auth/candidateregistration' element={<CandidateRegistration />} />
//     </Route>
//     <Route element={<CandidateMasterLayout/>}>
//       <Route path='/CVCreation' element={<CVWrapper/>}></Route> */}
//     {/* </Route> */}
//           {/* <Route path='auth/candidatelogin' element={<CandidateLogin />} />
//            <Route path='auth/candidateregistration' element={<CandidateRegistration />} /> */}
//           {currentUser ? (
//             <>
//               <Route path='/*' element={<PrivateRoutes />} />
//               <Route index element={<Navigate to='/dashboard' />} />
//             </>
//           ) : (
//             <>
//               <Route path='auth/*' element={<AuthPage />} />
//               <Route path='*' element={<Navigate to='/auth' />} />
//             </>
//           )}
//           <Route path='logout' element={<Logout />} />
//         </Route>
//       </Routes>
//     </BrowserRouter>
//   )
// }

const hostname = window.location.hostname

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  let candidatePath = isCandidatePath(hostname)
  // let candidatePath = false
  // if (
  //   hostname === 'localhost' ||
  //   hostname === 'bop-candidate-qa.isaacscode.com' ||
  //   hostname === 'qa-candidate.bop.com.pk' ||
  //   hostname === 'candidate.bop.com.pk' ||
  //   hostname === 'democandidate.isaacscode.com'
  // ) {
  //   candidatePath = true
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {currentUser ? (
            <>
              {currentUser.role === 'candidate' ? (
                // || candidatePath
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/cvcreation' />} />
                </>
              ) : currentUser.role === 'none' ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/employeeDashboard' />} />
                  <Route path='/public/job-advertisement-approval/:user_id/:job_id' element={<AdvertisementApproval />} />
                </>
              ) : (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              )}
              <Route path='/auth/account/verify/:id' element={<VerifyAccount />} />
              <Route path='/auth/account/reset_password/:id' element={<ResetPassword />} />
              <Route path='/auth/account/not_verified/:email' element={<AccountNotVerified />} />
              <Route path='/public/offer_letter/:member_id/:jobapp_id/:index' element={<OfferLetterExternal />} />
              <Route element={<MasterLayout />}>
                <Route path='/public/requisition_approval/:group_head_id/:position_id' element={<RequisitionApproval />} />
              </Route>
              <Route path='/public/job-advertisement-approval/:user_id/:job_id/:approver' element={<AdvertisementApproval />} />
              {/* <Route path='complete_user_profile/:user_id' element={<CompleteUserProfile />}/> */}
              <Route path='complete_user_profile' element={<CompleteUserProfile />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='/addInterviewscoreExternal/*' element={<PublicRoutes />} />
              <Route path='/public/offer_letter/:member_id/:jobapp_id/:index' element={<OfferLetterExternal />} />
              <Route path='/public/requisition_approval/:group_head_id/:position_id' element={<RequisitionApproval />} />
              <Route path='/public/job-advertisement-approval/:user_id/:job_id/:approver' element={<AdvertisementApproval />} />
              <Route path='/requestforapprovals/*' element={<PublicRoutes />} />
              <Route path='*' element={<Navigate to='/auth' />} />
              <Route path='/auth/account/verify/:id' element={<VerifyAccount />} />
              <Route path='/auth/account/reset_password/:id' element={<ResetPassword />} />
              <Route path='/auth/account/not_verified/:email' element={<AccountNotVerified />} />
              {/* <Route path='complete_user_profile/:user_id' element={<CompleteUserProfile />}/> */}
              <Route path='complete_user_profile' element={<CompleteUserProfile />} />
            </>
          )}
          :(
          <Route path='public/*' element={<PublicRoutes />} />)
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
