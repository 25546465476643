import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './ModelStyless.css'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import ScheduleInterview from '../../../../app/modules/JobsFunneling/ScheduleInterview/ScheduleInterview'
import {useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import interviewheadericon from '../images/interview@2x copy.png'
import Box from '@mui/material/Box'
import {TextField} from '@mui/material'
import {makeStyles} from '@material-ui/core/styles'
import Usericon from './img/two-users@2x.png'
import Timericon from './img/Group 10169@2x.png'
import Copyicon from './img/interview2x.png'
import Descriptionicon from './img/description@2x.png'
import InformationIcon from './img/information (3)@2x.png'
import IconTile from './img/Group 9982.png'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import ExprienceIcon from './img/experience@2x.png'
import UserIcon from './img/Group 9681.png'
import WarningIcon from './img/warning@3x (1).png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`
export const CREATE_EMAIL_URL = `${API_URL}/panel/create`
export const SEND_EMAIL_URL = `${API_URL}/panel/send-email-to-members`
export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
// export const SAVE_INTERVIEW_STAGE1 = `${API_URL}/jobapplication/schedule-interview-stage1`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  email: string
  user_id: string
  profile_pic: string
  position: string
  bexp: string
  jexp: string
}

type InputProps = {
  members?: string
  duration?: string
  date?: string
  link?: string
  description?: string
}

interface Recruiter {
  user_id: string
  name: string
  email: string
  currentTarget: string
}

interface Submit {
  jobapp_id: string
  index: string
  name: string
  email: string
}
const modalsRoot = document.getElementById('root-modals') || document.body

const useStyles = makeStyles({
  inputField: {
    border: '2px solid black' /* Set the initial border color and style */,
    transition: 'border-color 0.3s' /* Add transition for a smooth effect */,
  },
  inputFieldFocused: {
    borderColor: 'orange' /* Change the border color when the input is focused */,
    outline: 'none' /* Remove the default focus outline if desired */,
  },
})

const ScheduleInterviewPopUp = ({show, name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp, handleClose}: Props) => {
  const classes = useStyles()
  let cand_email = email
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedInterviewers, setSelectedInterviewers] = useState<any>([])
  const [interviewers, setInterviewers] = useState<Recruiter[]>([])
  const [searchRecord, setsearchRecord] = useState<Recruiter[]>([])
  const [dataFetched, setDataFetched] = useState(false)
  const [recruiterEmail, setRecruiterEmail] = useState({})

  const [duration, setDuration] = React.useState('')
  const [value, setValue] = React.useState<Dayjs | null>(dayjs())
  const navigate = useNavigate()
  const [Poc, setPoc] = useState({})
  const [RecruitersD, setRecruitersD] = useState([{}])
  const [Recruiter, setRecruiter] = useState([{}])
  const [candidateData, setCandidateData] = useState([])
  const [formData, setFormData] = useState<InputProps>()
  const [panelName, setPanelName] = useState<any>([])
  const [panelEmail, setPanelEmail] = useState<any>([])
  const [panelId, setPanelId] = useState<any>([])
  const [items, setItems] = useState<any>([])
  const [selectedInterviewerIndex, setSelectedInterviewerIndex] = useState<any>(null)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [formInputs, setFormInputs] = React.useState({
    invitation_link: '',
    description: '',
    duration: '',
    time: '',
  })
  const [loading, setLoading] = useState(false)
  const {auth} = useAuth()
  let pocData = [{}]
  let recruiterData = [{}]
  let result: any = {}
  let candResult = {}
  let LocalResult: any = {}

  const currentDate = dayjs() // Get the current date
  console.log({currentDate})

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (selectedInterviewers === null || selectedInterviewers === undefined || selectedInterviewers.length === 0) {
      alert('Please select at least 1 interviewer first.')
      return
    }
    if (!value) {
      alert('Please select interview date first.')
      return
    }
    console.log('formInputs: ', formInputs)
    if (!formInputs?.time) {
      alert('Please select interview time first.')
      return
    }
    if (!formInputs?.duration) {
      alert('Please select interview duration first.')
      return
    }
    if (!formInputs?.['invitation_link']) {
      alert('Please enter interview invite link or address first.')
      return
    }

    // Show loader
    setLoading(true)

    try {
      await axios.post(
        ACCEPT_JOB_URL,
        {
          jobapp_id: jobapp_id,
          currentStage: 'online test',
          newStage: 'stage1',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      console.log(panelName['result'])
      await axios
        .post(
          CREATE_EMAIL_URL,
          {
            panel: panelName['result'],
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log('res: ', res?.data)
        })
        .catch((err) => {
          console.error('err: ', err)
        })

      await axios
        .post(
          SEND_EMAIL_URL,
          {
            interviewData: {
              candidate_name: name,
              job_experience: '3',
              banking_experience: '4',
              interview_date: value,
              interview_time: formInputs['time'],
              duration: formInputs['duration'],
              invitation_link: formInputs['invitation_link'],
              description: formInputs['description'],
              interview: formInputs['interview'],
            },
            jobapp_id: jobapp_id,
            name: 'stage1',
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log('res: ', res?.data)
        })
        .catch((err) => {
          console.error('err: ', err)
        })

      handleClose()
    } catch (error: any) {
      console.error('Error:', error)
    } finally {
      // Hide loader
      setLoading(false)
    }
  }

  const GetAllRecruiters = async () => {
    try {
      const resp = await axios.get(GET_ALL_RECRUITERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })

      console.log('RESPONSE RECRUITER', resp.data.recuiter)
      const recruiters = resp.data.recuiter

      const promises = recruiters.map(async (recruiter) => {
        if (recruiter['user_id'] !== LocalResult?.member_id) {
          const url = GET_RECRUITER_URL + recruiter['user_id']
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          })

          console.log('RECRUITER ALL ', response.data.recuiter)
          return response.data.recuiter as Recruiter
        }
      })

      const interviewers = await Promise.all(promises)

      // Remove duplicates based on user_id
      const uniqueInterviewers = Array.from(new Set(interviewers.map((interviewer) => interviewer.user_id))).map((user_id) =>
        interviewers.find((interviewer) => interviewer.user_id === user_id)
      )

      const filteredInterviewers = uniqueInterviewers.filter((interviewer) => interviewer.user_id !== items.user_id)
      // Remove the specific interviewer from the interviewers array
      const updatedInterviewers = uniqueInterviewers.filter((interviewer) => interviewer.user_id !== items.user_id)

      setInterviewers(updatedInterviewers)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    let localUser: any = localStorage.getItem('kt-auth-react-v')
    setItems(JSON.parse(localUser))
  }, [])

  useEffect(() => {
    if (items) {
      GetAllRecruiters()
    }
  }, [items])

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue)
    console.log(value)
  }
  const handleChangeDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string)
  }

  const handleInputs = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
    console.log('form', formData)
  }

  const removeInterviewer = (event, index) => {
    let currentInterviewers = [...selectedInterviewers]
    currentInterviewers.splice(index, 1)
    setSelectedInterviewers(currentInterviewers)

    const updatedResult = currentInterviewers.map(({user_id, email}) => ({
      member_id: user_id,
      email: email,
      jobapp_id,
      index: '0',
      name: 'stage1',
      availability: 'false',
      member_type: 'panel',
    }))

    const candResult = {
      jobapp_id,
      name: 'stage1',
      member_id: user_id,
      email: cand_email,
      index: '0',
      availability: 'false',
      member_type: 'candidate',
    }

    const jobPosterEmail = items.email
    const jobPosterID = items.user_id

    const LocalResult = {
      jobapp_id,
      name: 'stage1',
      member_id: jobPosterID,
      email: jobPosterEmail,
      index: '0',
      availability: 'false',
      member_type: 'job-poster',
    }

    const updatedPanelResult = updatedResult.concat(candResult, LocalResult)

    setPanelName((prevPanelName) => ({...prevPanelName, result: updatedPanelResult}))
  }

  const onDescriptionChange = (value) => {
    setFormInputs((prevInputs) => ({
      ...prevInputs,
      description: value,
    }))
    console.log(formInputs)
  }

  const onInputChange = (event) => {
    const {name, value} = event.target

    if (name === 'description') {
      setFormInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }))
    } else {
      formInputs[name] = value
      setFormInputs({...formInputs})
    }

    console.log('onInputChange => formInputs', formInputs)
  }

  console.log({items})
  console.log({interviewers})

  //   const searchTerm = event.target.value.toLowerCase();

  //   if (!searchTerm) {
  //     setInterviewers([]); // Clear the filtered interviewers if search term is empty
  //   } else {
  //     const filtered = interviewers?.filter((interviewer) => {
  //       if (!interviewer) {
  //         return false;
  //       }
  //       const name = interviewer['first_name']?.toLowerCase();
  //       return name?.includes(searchTerm);
  //     });
  //     setInterviewers(filtered);
  //   }
  // };
  const [searchTerm, setSearchTerm] = useState<any>('')

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (!searchTerm) {
      setsearchRecord([])
    } else {
      const filtered = interviewers?.filter((interviewer) => {
        if (!interviewer) {
          return false
        }
        const name = interviewer['first_name']?.toLowerCase()
        const id = interviewer['employee_id']?.toLowerCase()
        return name?.includes(searchTerm) || id?.includes(searchTerm)
      })
      setsearchRecord(filtered)
    }
  }

  const handleInterviewerSelect = (interviewer) => {
    setSearchTerm('')
    const userId = interviewer.user_id
    const index = selectedInterviewers.findIndex((obj) => obj.user_id === userId)

    if (index === -1) {
      setSelectedInterviewers((prevInterviewers) => {
        const updatedInterviewers = [...prevInterviewers, interviewer]
        const name = updatedInterviewers.map((item) => item.name)
        const email = updatedInterviewers.map((item) => item.email)
        const member_id = updatedInterviewers.map((item) => item.user_id)

        const candResult = {
          jobapp_id,
          name: 'stage1',
          member_id: user_id,
          email: cand_email,
          index: '0',
          availability: 'false',
          member_type: 'candidate',
        }

        const jobPosterEmail = items.email
        const jobPosterID = items.user_id

        const LocalResult = {
          jobapp_id,
          name: 'stage1',
          member_id: jobPosterID,
          email: jobPosterEmail,
          index: '0',
          availability: 'false',
          member_type: 'job-poster',
        }

        const result = updatedInterviewers.map(({user_id, email}) => ({
          member_id: user_id,
          email: email,
          jobapp_id,
          index: '0',
          name: 'stage1',
          availability: 'false',
          member_type: 'panel',
        }))

        const updatedResult = result.concat(candResult, LocalResult)
        console.log({updatedResult})
        setPanelName((prevPanelName) => ({...prevPanelName, result: updatedResult}))
        setPanelEmail((prevPanelEmail) => ({...prevPanelEmail, email}))
        setPanelId((prevPanelId) => ({...prevPanelId, member_id}))

        return updatedInterviewers
      })
    }

    setSearchTerm('')
    setsearchRecord([])
  }

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex < searchRecord.length - 1 ? prevIndex + 1 : prevIndex))
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    }
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      width='104% !important'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model1234'>
        <div className='modal-header no-header-model123'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
              marginLeft: '16rem',
            }}
          >
            <img src={interviewheadericon} className='interview-header-icon' style={{height: '5vh'}} />
            <h2 className='interview-header-container-text'>Schedule Interview #1</h2>
          </div>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
            {/* <img className='rounded-circle' src={profile_pic !== null
                        ? profile_pic
                        : Layout} alt='' /> */}
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column'>
          <div className='profile-Container'>
            <div className='left-container'>
              <div>
                <img className='profile-user-icon' src={profile_pic !== null ? profile_pic : UserIcon} alt='' />
              </div>
              <div className='middle-container-user-details'>
                <label className='middle-container-username'>{name}</label>
                <div className='middle-container-role'>
                  <label style={{whiteSpace: 'nowrap'}}>Position: {position}</label>
                </div>
              </div>
            </div>
            <div className='right-container'>
              <div className='right-container-img'>
                <img src={ExprienceIcon} style={{width: '60%'}} />
              </div>
              <div>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Banking Experience:{' '}
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {bexp} yrs
                  </span>
                </label>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Total Experience:{' '}
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {(parseFloat(bexp) + parseFloat(jexp)).toFixed(2)} yrs
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form onSubmit={handleSubmit}>
              <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                {/* <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label> */}
                <div className='Input-Field-Container'>
                  <div style={{padding: '0', margin: '0'}}>
                    <img src={Usericon} className='slider-icon' />
                    <hr className='hr-line-first-child'></hr>
                    <img src={Timericon} className='slider-icon' />
                    <hr className='hr-line-second-child'></hr>
                    <img src={Copyicon} className='slider-icon' />
                    <hr className='hr-line-third-child'></hr>
                    <img src={Descriptionicon} className='slider-icon' />
                  </div>

                  <div>
                    <div className='upper-input-container'>
                      <div className='input-container-upper-row' style={{position: 'relative'}}>
                        <Box sx={{width: 400}}>
                          <FormControl fullWidth>
                            <div style={{overflow: 'hidden'}}>
                              <input
                                type='text'
                                placeholder='Enter Interview Panelist Name/ Employee ID'
                                className='request-approval-final-step-input-field'
                                onChange={handleSearch}
                                onKeyDown={handleKeyDown}
                              />
                              {searchRecord.length > 0 ? (
                                <ul
                                  style={{
                                    border: '1px solid lightgrey',
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                    zIndex: '2',
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    top: '100%',
                                    left: 0,
                                    width: '100%',
                                  }}
                                >
                                  {searchRecord.map((interviewer, index) => (
                                    <li
                                      key={interviewer['id']}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '10px',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        backgroundColor: highlightedIndex === index ? '#FFEBCE' : 'initial',
                                        padding: '5px',
                                        transition: 'background-color 0.3s',
                                      }}
                                      onClick={() => handleInterviewerSelect(interviewer)}
                                      onMouseEnter={(e) => {
                                        ;(e.target as HTMLElement).style.backgroundColor = '#FFEBCE'
                                      }}
                                      onMouseLeave={(e) => {
                                        ;(e.target as HTMLElement).style.backgroundColor =
                                          highlightedIndex === index ? '#FFEBCE' : 'initial'
                                      }}
                                    >
                                      <img
                                        src={interviewer['profile_pic'] !== null ? interviewer['profile_pic'] : UserIcon}
                                        className='selected-interviewer-icon-sched'
                                        alt=''
                                      />
                                      {interviewer['first_name']}
                                      <hr />
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                searchTerm !== '' && <p>No results found.</p>
                              )}
                            </div>
                          </FormControl>
                        </Box>
                        <div className='user-tile'>
                          {selectedInterviewers ? (
                            selectedInterviewers.map((option, index) => (
                              <label className='user-tile-non-content' key={option.id}>
                                <img
                                  src={option.profile_pic !== null ? option.profile_pic : UserIcon}
                                  className='selected-interviewer-icon-sched'
                                  alt=''
                                />
                                {option.first_name}
                                <span>
                                  <img
                                    alt=''
                                    src={Layout}
                                    onClick={(event) => removeInterviewer(event, index)}
                                    id={JSON.stringify(option)}
                                  ></img>
                                </span>
                              </label>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div className='inner-input-contaier'>
                        <div className='upper-input-row-container'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <Box sx={{width: 228}}>
                                <DesktopDatePicker
                                  inputFormat='MM/DD/YYYY'
                                  value={value}
                                  minDate={currentDate}
                                  onChange={handleChange}
                                  renderInput={(params) => <TextField {...params} name='selectedDate' />}
                                />
                              </Box>
                            </Stack>
                          </LocalizationProvider>

                          <Box
                            sx={{
                              width: 160,
                            }}
                          >
                            <TextField
                              id='time'
                              label='Select Time'
                              type='time'
                              name='time'
                              defaultValue='00:00'
                              onChange={onInputChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </Box>
                        </div>

                        <Box
                          sx={{
                            width: 228,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>Duration</InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='Durantion'
                              name='duration'
                              required
                              onChange={onInputChange}
                            >
                              <MenuItem value={10}>10 minutes</MenuItem>
                              <MenuItem value={20}>20 minutes</MenuItem>
                              <MenuItem value={30}>30 minutes</MenuItem>
                              <MenuItem value={40}>40 minutes</MenuItem>
                              <MenuItem value={50}>50 minutes</MenuItem>
                              <MenuItem value={60}>60 minutes</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <div className='input-link-container' style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div className='schedule-interview-address'>
                          <div className='schedule-interview-address-buttons'>
                            <input
                              type='radio'
                              value='Online Interview'
                              name='interview'
                              className='schedule-interview-checkbox'
                              onChange={onInputChange}
                            />
                            <label>Online Interview</label>
                          </div>

                          <div className='schedule-interview-address-buttons'>
                            <input
                              type='radio'
                              value='Onsite Interview'
                              name='interview'
                              className='schedule-interview-checkbox'
                              onChange={onInputChange}
                            />
                            <label>Onsite Interview</label>
                          </div>
                        </div>
                        <Box
                          sx={{
                            width: 391,
                          }}
                        >
                          <TextField fullWidth name='invitation_link' required onChange={onInputChange} placeholder='Enter Credentials' />
                        </Box>
                      </div>

                      <div className='input-description-container'>
                        <Box
                          sx={{
                            width: 391,
                          }}
                        >
                          <TextField
                            fullWidth
                            label='Description'
                            onChange={handleInputs}
                            required
                            InputLabelProps={{style: {fontWeight: 'bold', color: '#373D4A'}}}
                            disabled
                          />
                        </Box>
                        <Box
                          sx={{
                            width: 391,
                            height: 80,
                          }}
                        >
                          {/* <TextField
                            fullWidth
                            placeholder='Type Here'
                            name='description'
                            onChange={onInputChange}
                            multiline
                            required
                            rows={5}
                          /> */}

                          <ReactQuill
                            value={formInputs['description']}
                            onChange={onDescriptionChange}
                            placeholder='Type Here'
                            modules={{
                              toolbar: [
                                [{header: [1, 2, 3, 4, false]}],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{color: []}, {background: []}],
                                [{align: []}],
                                [
                                  'link',
                                  // , 'image'
                                  // , 'video'
                                ],
                                ['clean'],
                              ],
                            }}
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'color',
                              'background',
                              'align',
                              'link',
                              // 'image',
                              // 'video',
                            ]}
                            style={{height: '90px'}}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='inputfield-lower-container'>
                <img src={InformationIcon} style={{width: '3%', marginTop: '2px'}} />
                <label className='inputfield-lower-container-text'>
                  By clicking on send the above interview details will be sent to Candidate & all Panel Member via email
                </label>
              </div>
              <div className='inputfield-button-container'>
                {!loading ? (
                  <input className='inputfield-btn' type='submit' name='Send' value='Submit' onClick={handleSubmit} />
                ) : (
                  <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='2x' />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {errorMessage && (
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: '10px',
            backgroundColor: '#FFE2E5',
            color: '#F64E60',
            textAlign: 'center',
            fontWeight: '700',
          }}
        >
          {errorMessage}
        </div>
      )}
    </Modal>
  )
}

export {ScheduleInterviewPopUp}
