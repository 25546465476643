//import liraries
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import './InterviewPopup.css'
import { useState } from 'react'
import './Success.css'
import Layout from '../images/Layout-4-blocks (3).png'

import { useAuth } from '../../../../app/modules/auth/core/Auth'
import RecordRTC from 'recordrtc';
import axios from 'axios';
import LoaderView from './LoaderView';
const API_URL = process.env.REACT_APP_API_URL
const UPLOAD_VIDEO_RESUME_URL = `${API_URL}/jobapplication/upload-video-resume`;


type Props = {
    show: boolean
    handleClose: () => void
    jobData: any
    applicationData: any
    updateApplications: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body


// create a component
const UploadVideoResumePopup = ({ show, handleClose, jobData, applicationData, updateApplications }: Props) => {
    const { currentUser, auth } = useAuth()
    const [videoResumeFile, setVideoResumeFile] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false); // State to manage video playback
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const handleFileUpload = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        if (file) {
            console.log('Selected file:', file);
            // Call your upload function here with the selected file if needed
            // uploadVideoResume(file);
            setVideoResumeFile(file);
            setIsPlaying(true);
        }
    }

    const handleUploadVideo = (e) => {
        e.preventDefault();

        if (videoResumeFile) {
            const now = new Date();

            const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);

            const hour12 = now.getHours() >= 12 ? 'PM' : 'AM';
            const hour = padZero(now.getHours() % 12 || 12);
            const minute = padZero(now.getMinutes());
            const second = padZero(now.getSeconds());
            const day = padZero(now.getDate());
            const month = padZero(now.getMonth() + 1);
            const year = now.getFullYear();

            const formattedDateTime = `${hour}_${minute}_${second}_${hour12}_${month}_${day}_${year}`;

            let fileName = `video_resume_${currentUser?.cnic}_${jobData?.title}_${formattedDateTime}.webm`;
            console.log('fileName: ', fileName);
            const formData = new FormData();
            formData.append('jobapp_id', applicationData?.jobapp_id);
            // formData.append('file', videoResumeFile, 'video_resume');
            formData.append('file', videoResumeFile, fileName);

            setLoading(true);

            axios.post(
                UPLOAD_VIDEO_RESUME_URL,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'content-type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(`Upload Progress: ${progress}%`);
                        // You can update a progress bar or UI element with the 'progress' value here.
                        setUploadPercentage(progress);
                    },
                }
            ).then((res) => {
                setLoading(false);
                console.log('video resume upload response: ', res.data);
                updateApplications();
            }).catch((err) => {
                setLoading(false);
                console.log('err', err);
            });

        } else {
            window.alert('Please select a video file first')
            setVideoResumeFile(undefined);
        }
    }

    const [recording, setRecording] = useState<RecordRTC | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const cameraStreamRef = useRef<MediaStream | null>(null);
    const recordedVideoRef = useRef<HTMLVideoElement | null>(null);
    const liveVideoRef = useRef<HTMLVideoElement | null>(null);

    const startRecording = async () => {
        try {
            setVideoUrl(null);
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

            // Store the camera stream
            cameraStreamRef.current = stream;

            // Create separate tracks for displaying live video and recording
            const liveVideoTrack = new MediaStream([stream.getVideoTracks()[0]]);
            const recorderStream = new MediaStream([stream.getVideoTracks()[0], stream.getAudioTracks()[0]]);

            // Attach the tracks to video elements
            if (liveVideoRef.current) {
                liveVideoRef.current.srcObject = liveVideoTrack;
            }

            const recorder = new RecordRTC(recorderStream, {
                type: 'video',
                mimeType: 'video/webm',
            });

            recorder.startRecording();

            setRecording(recorder);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = async () => {
        if (recording) {
            recording.stopRecording(() => {
                const blob = recording.getBlob();
                const videoUrl = URL.createObjectURL(blob);

                setVideoUrl(videoUrl);
                setVideoResumeFile(blob);

                // Stop the camera stream from the reference
                if (cameraStreamRef.current) {
                    cameraStreamRef.current.getTracks().forEach(track => track.stop());
                }

                // Upload the recorded video to the server using Axios
                const formData = new FormData();
                formData.append('video', blob, 'recorded_video.webm');

                // axios.post('YOUR_UPLOAD_URL', formData, {
                //     headers: {
                //         'Content-Type': 'multipart/form-data',
                //     },
                // }).then((response) => {
                //     console.log('Video upload success:', response.data);
                // }).catch((error) => {
                //     console.error('Video upload error:', error);
                // });

                setRecording(null);
            });
        }
    };

    // Use useEffect to initialize the live video element
    useEffect(() => {
        if (liveVideoRef.current) {
            liveVideoRef.current.srcObject = null;
        }
    }, []);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
            backdropClassName="modal-backdrop backdrop-blur"
        >
            <>
                {/* close icon */}
                <div className='no-header-model123' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <img alt='' src={Layout}></img>
                    </div>
                </div>
                {!videoResumeFile && (<div>
                    <div >
                        <h2 className='fw-bolder fs-2 mx-6' >Video Resume</h2>
                    </div>
                    <div className='interview-title'>
                        <h2 className='fw-bolder fs-3 mt-6' >Make a video of yourself giving an elevator pitch</h2>
                    </div>
                    {/* body */}
                    <div className='modal-body py-lg-8 px-lg-8'>
                        <div className='interview-title'>
                            <h2 className='fw-normal fs-7 my-6' style={{ color: '#8c8c8c' }}>Note: The video should be only 5 mins long, Maximum 2Mb size</h2>
                        </div>
                        <div className='d-flex flex-column'>
                            <label className='fw-bolder fs-6' style={{ marginLeft: '4rem', color: '#484848' }}>Elevator Pitch must have the following things</label>
                            <label className='fw-normal fs-6 my-4' style={{ marginLeft: '4rem', color: '#484848' }}>{jobData?.video_resume}</label>
                        </div>

                        {/* show camera view here */}

                        {!videoUrl && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <video ref={liveVideoRef} autoPlay muted playsInline />
                            </div>
                        )}

                        <div className='upload-video-div mt-8 ' style={{ display: 'flex', justifyContent: 'center' }} >
                            <button className='btn col-md-10 mt-6 vacancies-button right-button-vacan fw-bolder text-white'
                                style={{ cursor: 'pointer' }}
                                onClick={() => { recording ? stopRecording() : startRecording() }}
                            >
                                {`${recording ? 'Stop' : 'Start'} Recording`}
                            </button>
                        </div>

                        <div className='upload-video-div mb-8' style={{ display: 'flex', justifyContent: 'center' }} >
                            <input
                                type='file'
                                accept='video/mp4'
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                            />
                            <button className='btn col-md-10 mt-6 vacancies-button right-button-vacan fw-bolder text-white'
                                style={{ cursor: 'pointer' }}
                                onClick={() => fileInputRef.current?.click()}
                            >
                                Upload Video
                            </button>
                        </div>

                    </div>
                </div>)}
                {videoResumeFile && (
                    <div style={{ justifyContent: 'center', justifyItems: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* video player */}
                            <video
                                ref={videoRef}
                                controls
                                style={{ maxWidth: '100%', maxHeight: '400px', borderRadius: '4px' }}
                            >
                                {/* <source src={URL.createObjectURL(videoResumeFile)} type='video/*' /> */}
                                <source src={URL.createObjectURL(videoResumeFile)} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div className='upload-video-div my-8' style={{ display: 'flex', justifyContent: 'center' }} >
                            <button className='btn col-md-2 mt-6 vacancies-button right-button-vacan fw-bolder text-white'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => handleUploadVideo(e)}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )
                }

                {loading && (
                    <LoaderView
                        message={`Uploading Video...${uploadPercentage}%`}
                    />
                )}

            </>
        </Modal >,
        modalsRoot
    )
};

//make this component available to the app
export default UploadVideoResumePopup;
