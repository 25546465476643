//import liraries
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../auth'
import VacanCircularProgressWithLabel from '../../Vacancies/comps/VacanCircularWIthLabel'

import icTestGrey from '../../assets/ic_test_grey.png'
import icTestRed from '../../assets/ic_test_red.png'
import icInterViewGrey from '../../assets/ic_interview_grey.png'
import icInterViewRed from '../../assets/ic_interview_red.png'
import icCameraRed from '../../assets/ic_camera_red.png'
import icTickGreen from '../../assets/ic_tick_green.png'

import '../Applications.css'
import '../../../../../_metronic/css/bootstrap-glyphicon.min.css'
import InterviewPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/InterviewPopup'
import OfferLetterCandidate from '../../../../../_metronic/partials/modals/JobFunnelsModels/OfferLetterCandidate'
import UploadVideoResumePopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/UploadVideoResumePopup'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
import WarningTARemovePopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import CustomPagination from '../../../dashboards/components/CustomPagination'
import { getFormattedDate } from '../../../../../constants/CommonFunctions'
import OfferRejectionReasonPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/OfferRejectionReasonPopup'
const API_URL = process.env.REACT_APP_API_URL
export const APPLIED_JOB_API_URL = `${API_URL}/jobapplication/get-applied-jobapp`
const WITHDRAW_APPLICATION_URL = `${API_URL}/jobapplication/withdraw-application`

// create a component
const ActiveApplications = (props) => {
  const { currentUser, auth, logout } = useAuth()
  const user_id = currentUser?.user_id
  const [showUploadVideoResumePopup, setShowUploadVideoResumePopup] = useState(false)
  const [currentJobData, setCurrentJobData] = useState<any>({})
  const [currentApplicationData, setCurrentApplicationData] = useState<any>({})
  const [activeJobs, setActiveJobs] = useState<any>([
    //     {
    //     jobdata: '',
    //     jobapplicationData: ''
    // }
  ])
  const [offerLetterExpiryDate, setOfferLetterExpiryDate] = useState('')
  const [offerLetter, setOfferLetter] = useState<any>()
  const [currentJob, setCurrentJob] = useState<any>()
  const [showOfferLetter, setShowOfferLetter] = useState(false)
  const [showAddReasonPopup, setShowAddReasonPopup] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [jobAppStage, setJobAppStage] = useState('')
  const [warningMessage, setWarningMessage] = useState('')
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    status: 'active',
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
  })

  const getData = async () => {
    let data = { ...params }
    data.candidate_id = user_id

    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    setIsLoading(true)
    await axios
      .post(
        APPLIED_JOB_API_URL,
        // { candidate_id: user_id },
        filteredParams,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        // initializing active jobs list
        setIsLoading(false)
        const data = response.data

        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        const jobs: any = []
        const jobsList = data?.data
        if (jobsList !== null && jobsList !== undefined && jobsList.length > 0) {
          jobsList.map((job) => {
            let currentJobData = job.jobdata[0]
            if (currentJobData !== null && currentJobData !== undefined) {
              let newJob = {
                jobdata: job.jobdata[0],
                jobapplicationData: job.jobapplicationData,
                members: job.members,
                batchPositions: job.batchPositions,
              }
              jobs.push(newJob)
            }
          })

          // Sort the jobs based on jobapplicationData.created_at in ascending order
          jobs.sort((a, b) => {
            const dateA = new Date(a.jobapplicationData.created_at)
            const dateB = new Date(b.jobapplicationData.created_at)
            return dateB.getTime() - dateA.getTime()
          })
        }
        setActiveJobs(jobs)
        props.updatedAt()
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // fetching data from API
  useEffect(() => {
    ; (() => {
      getData()
    })()
  }, [params])

  function showTest(testId, jobId, job) {
    if (testId !== null && testId !== undefined && testId !== '' && testId !== 'none') {
      props.updateTest(testId)
      props.updateJobId(jobId)
      props.updateJob(job)
      props.update(2)
    } else {
      window.alert('Something went wrong: test_id not valid')
    }
  }

  function isTestExpired(testDays, stageActivity) {
    if (stageActivity) {
      // if (testDays.includes("Z")) {
      //     testDays = 5;
      // }
      const startTestDate = stageActivity.find((item) => item.to === 'online test')?.time
      if (startTestDate) {
        const currentDateTime = new Date()
        const deadlineDateTime = new Date(startTestDate)
        deadlineDateTime.setDate(deadlineDateTime.getDate() + parseInt(testDays, 10))

        if (currentDateTime.getTime() > deadlineDateTime.getTime()) {
          return true
        }
      }
    }

    return false
  }
  // get current action text
  function getCurrentActionText(applicationStage, testId, testAttempted, applicationStatus, job) {
    let action = 'In Process'
    if (applicationStage === 'application') {
      const jobData = job.jobdata
      const applicationData = job.jobapplicationData
      if (jobData?.video_resume_deadline && (applicationData?.video_resume === null || applicationData?.video_resume === undefined)) {
        action = 'Upload Video Resume'
      }
    } else if (applicationStage === 'online test') {
      if (testId !== null && testId !== undefined && testId !== '') {
        if (testAttempted === null) {
          action = 'Start Test'
        } else {
          testAttempted = JSON.parse(testAttempted)
          let isAttempted = testAttempted.isAttempted
          if (isAttempted) {
            action = 'Result Awaiting'
            // action = 'Start Test';
          } else {
            action = 'Start Test'
          }
        }
      }
    } else if (applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3') {
      if (applicationStatus === null) {
      }
      action = 'Interview'
    } else if (applicationStage === 'approvals') {
      action = 'Waiting Approvals'
    } else if (applicationStage === 'Offer Letter Approved' || applicationStage === 'Offer Letter Accepted') {
      // action = 'Hired'
      action = 'Review & Accept Offer Letter'
    } else if (applicationStage === 'Candidate Rejected') {
      action = 'Rejected' // offer letter rejected by candidate
    } else if (applicationStage === 'WITHDRAWN') {
      action = 'WITHDRAWN'
    }
    if (applicationStatus === 'WITHDRAWN') {
      action = 'WITHDRAWN'
    }
    return action
  }
  // calculate current application progress percentage
  function getCurrentProgress(applicationStage, testId, testAttempted, applicationStatus, isInterviewOnline) {
    let percentage = 0
    let totalStages = 5
    let currentStage = 1
    if (testId === null || testId === undefined || testId === '') {
      totalStages = 4
    }
    if (applicationStage === 'application') {
      currentStage = 1
    } else if (applicationStage === 'online test') {
      if (testAttempted !== null && testAttempted !== undefined && testAttempted !== '') {
        currentStage = 2
      } else {
        currentStage = 1
      }
    } else if (applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3') {
      currentStage = testId === null || testId === undefined || testId === '' ? 2 : 3
    } else if (applicationStage === 'approvals') {
      currentStage = testId === null || testId === undefined || testId === '' ? 3 : 4
    } else if (applicationStage === 'hired') {
      currentStage = testId === null || testId === undefined || testId === '' ? 3.5 : 4.5
    } else if (applicationStage === 'Offer Letter Pending' || applicationStage === 'offer letter pending') {
      currentStage = testId === null || testId === undefined || testId === '' ? 3.7 : 4.7
    } else if (applicationStage === 'Offer Letter Approved') {
      currentStage = testId === null || testId === undefined || testId === '' ? 3.9 : 4.9
    } else if (applicationStage === 'Offer Letter Accepted') {
      currentStage = testId === null || testId === undefined || testId === '' ? 4 : 5
    } else if (applicationStage === 'Candidate Rejected') {
      currentStage = testId === null || testId === undefined || testId === '' ? 4 : 5
    }
    let percentageValue = Math.floor((currentStage / totalStages) * 100)
    return percentageValue
  }

  // change screen according to action
  function startNextStep(action, testId, jobId, isInterviewOnline, job) {
    if (action === 'Upload Video Resume') {
      // show popup to upload video resume
      const jobData = job.jobdata
      const applicationData = job.jobapplicationData
      setCurrentJobData(jobData)
      setCurrentApplicationData(applicationData)
      setShowUploadVideoResumePopup(true)
    } else if (action === 'Start Test') {
      // show screen to start test
      showTest(testId, jobId, job)
    } else if (action === 'Interview') {
      // show interview details
      const jobData = job.jobdata
      const applicationData = job.jobapplicationData
      const stage = applicationData?.jobapp_stage
      console.log(`interviewInfo: stage: ${stage} :: interviewScore2: ${applicationData?.interview_stage2_score}`)
      if (
        // (stage === 'stage1' && (applicationData?.interview_stage1_score === null || applicationData?.interview_stage1_score === '0.00')) ||
        // (stage === 'stage2' && (applicationData?.interview_stage2_score === null || applicationData?.interview_stage2_score === '0.00')) ||
        // (stage === 'stage3' && (applicationData?.interview_stage3_score === null || applicationData?.interview_stage3_score === '0.00'))
        (stage === 'stage1' || stage === 'stage2' || stage === 'stage3') && applicationData?.jobapp_status !== 'Interview Conducted'
      ) {
        setCurrentJobData(jobData)
        setCurrentApplicationData(applicationData)
        // if (isInterviewOnline) {
        showInterviewDetails()
        // }
      } else {
        setMessage('Interview has been conducted!')
        setShowErrorMessage(true)
      }
    } else if (action === 'Review & Accept Offer Letter') {
      //show offer letter

      setCurrentJob(job)
      let jobApplicationData = job?.jobapplicationData
      let offerLetterData = jobApplicationData?.offer_letter ? JSON.parse(jobApplicationData?.offer_letter) : null
      if (offerLetterData) {
        setOfferLetterExpiryDate(jobApplicationData?.offer_letter_expiry_date)
        setOfferLetter(offerLetterData)
        setShowOfferLetter(true)
      }
    }
  }

  const [showInterviewPopup, setShowInterviewPopup] = useState(false)
  function showInterviewDetails() {
    setShowInterviewPopup(true)
  }
  function handleCloseInterviewPopup() {
    setShowInterviewPopup(false)
  }

  const withdrawApplicationWarning = (jobAppId, currentStage) => {
    setCurrentJobAppId(jobAppId)
    setJobAppStage(currentStage)
    setWarningMessage('Are you sure you want to withdraw application?')
    setShowWarningPopup(true)
  }

  const withdrawApplication = () => {
    let data = {
      jobapp_id: currentJobAppId,
      candidate_id: currentUser?.user_id,
      jobapp_stage: jobAppStage,
    }
    axios
      .post(WITHDRAW_APPLICATION_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setCurrentJobAppId('')
        setJobAppStage('')
        setWarningMessage('')
        setShowWarningPopup(false)

        getData() // update the list of active applications
      })
      .catch((error) => {
        setCurrentJobAppId('')
        setJobAppStage('')
        setWarningMessage('')
        setShowWarningPopup(false)

        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // const getMultipleLocations = (batchPositions) => {
  //     let locations = '';
  //     if (batchPositions) {
  //         locations = batchPositions.map((position) => position.location).join(', ');
  //     }
  //     return locations;
  // }

  const getMultipleLocations = (batchPositions) => {
    let locations = ''
    if (batchPositions) {
      batchPositions.forEach((position) => {
        if (!locations.includes(position.location)) {
          if (locations) {
            locations += ', '
          }
          locations += position.location
        }
      })
    }
    return locations
  }

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  return (
    <>
      <div className='col-xl-12 mt-18' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {activeJobs.length > 0 ? (
          activeJobs.map((job, index) => {
            const jobData = job?.jobdata
            const batchPositions = job?.batchPositions
            const applicationData = job.jobapplicationData
            const testId = jobData?.test_id?.trim()
            // const jobId = jobData?.job_id;
            const jobId = applicationData?.jobapp_id
            let applicationStage = applicationData?.jobapp_stage
            if (applicationStage === 'hired') {
              applicationStage = applicationData?.jobapp_status
              // applicationStage = 'Accept Offer Letter';
            }
            const testAttempted = applicationData?.attempt_test
            const applicationStatus = applicationData?.jobapp_status
            const isInterviewOnline = jobData?.online_interview
            const testDays = jobData?.online_test_deadline
            const stageActivity = applicationData?.stage_activity ? JSON.parse(applicationData?.stage_activity) : null
            const testExpired = isTestExpired(testDays, stageActivity)
            // getting video resume deadline date
            let videoResumeDeadline = jobData?.video_resume_deadline
            const deadlineDate = new Date(videoResumeDeadline)
            // Extract day, month, and year components
            const day = deadlineDate.getDate()
            const month = deadlineDate.getMonth() + 1 // Months are zero-based, so add 1
            const year = deadlineDate.getFullYear() % 100 // Get last two digits of the year
            // Create the formatted date string
            const formattedVideoResumeDeadline = `${day}/${month}/${year}`
            // applicationStage = 'stage1';
            let btnText = getCurrentActionText(applicationStage, testId, testAttempted, applicationStatus, job)
            if (applicationStage === 'online test' && testExpired) {
              btnText = 'Test Expired'
            }
            return (
              <div
                className='form-group col-md-5 col-sm-12 col-xs-12 mt-10 me-10 shadow-sm '
                style={{ borderRadius: '18px', backgroundColor: '#FFFFFF' }}
              >
                <div style={{ padding: '2rem' }}>
                  <div className='row'>
                    {/* left side of job card */}
                    <div className='col-md-7'>
                      {btnText !== 'WITHDRAWN' ? (
                        <label
                          className='fw-bolder fs-8 withdrwa-btn'
                          style={{ cursor: 'pointer' }}
                          onClick={() => withdrawApplicationWarning(jobId, applicationData?.jobapp_stage)}
                        >
                          Withdraw Application
                        </label>
                      ) : (
                        <div></div>
                      )}
                      <h2
                        className='mt-10'
                        style={{ color: '#F36523', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        title={jobData?.title ? jobData?.title : 'Job title'}
                      >
                        {jobData?.title ? jobData?.title : 'Job title'}
                      </h2>
                      <h3
                        style={{ color: '#484848', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        title={`${batchPositions ? getMultipleLocations(batchPositions) : jobData?.location ? jobData.location : 'Location'
                          }`}
                      >
                        <i className='bi bi-geo-alt-fill me-2' style={{ color: '#FF8750' }}></i>
                        {/* {"Location: " + jobData?.location ? jobData?.location : 'Location'} */}
                        {`${batchPositions ? getMultipleLocations(batchPositions) : jobData?.location ? jobData.location : 'Location'}`}
                      </h3>
                      <h3 style={{ color: '#484848' }}>
                        <i className='bi bi-star-fill me-2' style={{ color: '#FF8750' }}></i>
                        {'Grade: ' + jobData?.grade ? jobData?.grade : 'Grade'}
                      </h3>
                      <h3 className='mt-10 fw-bolder fs-3' style={{ color: '#484848' }}>
                        Requirements
                      </h3>
                    </div>

                    {/* right side of job card */}
                    <div className='col-md-5 text-center '>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <i className='bi bi-calendar-event-fill' style={{ marginRight: '4px', marginBottom: '1rem' }} />
                        <p className='applied-date-p fs-7'>
                          {applicationData?.created_at ? 'Applied on ' + getFormattedDate(applicationData?.created_at) : 'Applied on date'}
                        </p>
                      </div>
                      <label className={`fw-bolder fs-8 mt-2 ${btnText === 'WITHDRAWN' ? 'grey-chip' : 'under-review-chip'}`}>
                        {btnText === 'Rejected' ? 'Rejected' : btnText === 'WITHDRAWN' ? 'Withdrawn' : 'Under Review'}
                      </label>
                      <h3 className='text-center mt-3' style={{ color: '#484848' }}>
                        Progress
                      </h3>
                      <VacanCircularProgressWithLabel
                        value={getCurrentProgress(applicationStage, testId, testAttempted, applicationStatus, isInterviewOnline)}
                        size={70}
                      />
                    </div>
                  </div>

                  {/* show requirements chips */}
                  <div className='row mb-4'>
                    <div className='col-md-5 mb-4'>
                      <div
                        className={`fw-bolder fs-8 ${applicationStage === 'application' ? 'withdrwa-btn' : 'green-chip'} `}
                        style={{ textAlign: 'center' }}
                      >
                        {applicationStage !== 'application' ? <img className='me-2' src={icTickGreen} alt='online-test' /> : <div></div>}
                        Online Resume
                      </div>
                    </div>

                    {jobData?.video_resume && (
                      <div className='col-md-7 text-center mb-4'>
                        <div className={`fw-bolder fs-8 ${applicationData?.video_resume ? 'green-chip' : 'red-chip'} `}>
                          <img className='me-2' src={applicationData?.video_resume ? icTickGreen : icCameraRed} alt='video-resume' />
                          {`Video Resume: Deadline ${formattedVideoResumeDeadline}`}
                        </div>
                      </div>
                    )}

                    {testId !== null && testId !== undefined && testId !== '' && testId !== 'none' ? (
                      <div className='col-md-4 mb-4'>
                        <div
                          className={`fw-bolder fs-8 ${applicationStage === 'application'
                              ? 'grey-chip'
                              : applicationStage === 'online test'
                                ? testAttempted !== null
                                  ? 'withdrwa-btn'
                                  : 'red-chip'
                                : 'green-chip'
                            }`}
                        // onClick={() => { showTest(testId) }}
                        >
                          <img
                            className='me-2'
                            src={
                              applicationStage === 'application'
                                ? icTestGrey
                                : applicationStage === 'online test'
                                  ? testAttempted !== null
                                    ? icTestRed
                                    : icTestRed
                                  : icTickGreen
                            }
                            alt='online-test'
                          />
                          Online Test
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {isInterviewOnline && (
                      <div className='col-md-5  mb-4'>
                        <div
                          className={`fw-bolder fs-8 ${applicationStage === 'application' || applicationStage === 'online test'
                              ? 'grey-chip'
                              : applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3'
                                ? applicationStatus !== null
                                  ? 'withdrwa-btn'
                                  : 'red-chip'
                                : 'green-chip'
                            }`}
                        >
                          <img
                            className='me-2'
                            src={
                              applicationStage === 'application' || applicationStage === 'online test'
                                ? icInterViewGrey
                                : applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3'
                                  ? icInterViewRed
                                  : icTickGreen
                            }
                            alt='online-interview'
                          />
                          Online Interview
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='upload-video-div'>
                    <button
                      className='fw-bolder col-md-12 mt-2 btn'
                      disabled={
                        btnText === 'In Process' ||
                        btnText === 'Test Expired' ||
                        btnText === 'Waiting Approvals' ||
                        btnText === 'Result Awaiting' ||
                        btnText === 'Rejected' ||
                        btnText === 'WITHDRAWN'
                      }
                      onClick={() => startNextStep(btnText, testId, jobId, isInterviewOnline, job)}
                    >
                      {`${btnText === 'WITHDRAWN' ? 'Withdrawn' : btnText}`}
                    </button>
                  </div>
                </div>
              </div>
            )
          })
        ) : isLoading ? (
          <div className='text-center'>
            <FontAwesomeIcon
              icon={faSpinner}
              color='#F36523'
              spin
              size='5x'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                marginTop: '15rem',
              }}
            />
          </div>
        ) : (
          <h3 className='mt-40 fs-3 fw-bolder' style={{ color: 'black' }}>
            There are no active jobs that you have applied for.
          </h3>
        )}

        {activeJobs && activeJobs.length > 0 && (
          <div className='d-flex col-md-12 align-items-center my-8'>
            <div className='col-md-4'>
              <label style={{ color: '#484848' }}>{`Showing ${activeJobs ? activeJobs.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px', color: '#484848' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label style={{ color: '#484848' }}>Items per page</label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showUploadVideoResumePopup && (
        <UploadVideoResumePopup
          show={true}
          handleClose={() => {
            setShowUploadVideoResumePopup(false)
          }}
          jobData={currentJobData}
          applicationData={currentApplicationData}
          updateApplications={() => {
            setShowUploadVideoResumePopup(false)
            getData() // update the list of active applications
          }}
        />
      )}

      {showInterviewPopup && (
        <InterviewPopup
          show={true}
          handleClose={() => {
            handleCloseInterviewPopup()
          }}
          jobData={currentJobData}
          applicationData={currentApplicationData}
        />
      )}

      {showOfferLetter && (
        <OfferLetterCandidate
          show={true}
          offerLetterExpiryDate={offerLetterExpiryDate}
          offerLetter={offerLetter}
          job={currentJob}
          handleClose={() => setShowOfferLetter(false)}
          handleAccept={() => {
            setShowOfferLetter(false)
            props.gotoClosedTab('InActiveButtonClicked')
          }}
          updateApplications={() => {
            setShowOfferLetter(false)
            getData() // update the list of active applications
          }}
          handleReject={()=>{
            setShowOfferLetter(false)
            setShowAddReasonPopup(true);
          }}
        />
      )}

      {showAddReasonPopup && (
        <OfferRejectionReasonPopup
          show={true}
          jobAppId={currentJob?.jobapplicationData?.jobapp_id}
          handleClose={() => setShowAddReasonPopup(false)}
          updateApplications={() => {
            setShowAddReasonPopup(false)
            getData() // update the list of active applications
          }}

        />
      )}

      {showWarningPopup && (
        <WarningTARemovePopup
          show={true}
          message={warningMessage}
          handleClose={() => {
            setCurrentJobAppId('')
            setJobAppStage('')
            setWarningMessage('')
            setShowWarningPopup(false)
          }}
          handleYesSure={withdrawApplication}
        />
      )}

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

//make this component available to the app
export default ActiveApplications
