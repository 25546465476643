import React from 'react';

function ProgressBar(props) {
  const { value, max, backgroundColor, progressColor, shadow } = props;
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: '10px',
    marginBottom:'8px',
    boxShadow: shadow ? `0 2px 4px ${shadow}` : 'none',
  };
  const progressStyle = {
    backgroundColor: progressColor,
    width: `${(value / max) * 100}%`,
    borderRadius: '10px',
    height: '14px',

  };

  return (
    <div style={style}>
      <div style={progressStyle}>
      </div>
    </div>
  );
}

export default ProgressBar;
