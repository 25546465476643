//import libraries
import React, { useEffect, useState } from 'react'
import ToggleSwitch from './components/ToggleSwitch'
import VacanProgressBar from '../../Candidate Space/Vacancies/comps/VacanProgressBar'
import ReactPaginate from 'react-paginate'
import icArrow from '../images/ic_arrow_up_grey.png'
import icFilter from '../images/ic_filter.png'
import icArrowRight from '../images/ic_right_arrow_grey.png'
import icArchive from '../images/ic_archive.png'
import icUploadOrange from '../images/ic_upload_orange.png'
import icEdit from '../images/ic_edit.png'
import ProgressBar from './components/ProgressBar'
import TermsConditionsPopUp from '../../../../_metronic/partials/modals/JobFunnelsModels/TermsConditionsPopUp'
import ImportPositionsPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/ImportPositionsPopup'
import AddUpdatePosition from '../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdatePosition'
import WarningPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/WarningPopup'
import '../Settings.css'

import { useAuth } from '../../auth/core/Auth'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import SuccessDialog from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'
import imagePath from '../../../../constants/imagePath'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import clsx from 'clsx'
import CustomPagination from '../../dashboards/components/CustomPagination'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_GROUPS = `${API_URL}/group`
const GET_POSITIONS_URL = `${API_URL}/position`
export const UPDATE_BUDGET_URL = `${API_URL}/group/upload-yearly-budget`
// const GET_POSITIONS_OF_GROUP_URL = `${API_URL}/position`
const GET_FILTERS_LIST_URL = `${API_URL}/group/get-nested-data`
const GET_OVERALL_BUDGET_DATA_URL = `${API_URL}/group/get-total-group`
const UPLOAD_POSITIONS_CSV_URL = `${API_URL}/position/upload-position-csv`

// create a component
const DepartmentBudget = () => {
  const { auth, logout } = useAuth()
  const navigate = useNavigate()

  const [toggleChecked, setToggleChecked] = useState(true)
  const [yearlySalary, setYearlySalary] = useState(-1)
  const [yearlyRemuneration, setYearlyRemuneration] = useState(-1)
  const [yearlyRemainingSalary, setYearlyRemainingSalary] = useState(0)
  const [yearlyRemainingRemuneration, setYearlyRemainingRemuneration] = useState(0)
  const [yearlyLapsedSalary, setYearlyLapsedSalary] = useState(0)
  const [yearlyLapsedRemuneration, setYearlyLapsedRemuneration] = useState(0)
  const [yearlySalaryPercentage, setYearlySalaryPercentage] = useState(0)
  const [yearlyRemunerationPercentage, setYearlyRemunerationPercentage] = useState(0)
  const [groupId, setGroupId] = useState('')
  const [group, setGroup] = useState('')
  const [groupData, setGroupData] = useState<any>()
  const [filters, setFilters]: any = useState([{}])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState<any>([])

  const [showImportPositions, setShowImportPositions] = useState(false)
  const [showAddUpdatePositions, setShowAddUpdatePositions] = useState(false)
  const [currentPosition, setCurrentPosition] = useState<any>({})
  const [formType, setFormType] = useState('')
  const [showArchivePopup, setShowArchivePopup] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const [allGroups, setAllGroups] = useState([
    {
      group_name: '',
      group_id: '',
    },
  ])
  const [allPositions, setAllPositions] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [currentRows, setCurrentRows] = useState<any>([{}])
  const [budgetChanged, setBudgetChanged] = useState(false)
  const [groupQuery, setGroupQuery] = useState('')
  const [groupResults, setGroupResults] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    order: 'updated_at',
    sort: 'desc',
    page: 1,
    limit: 10,
    total: 0,
    functionalArea: '',
    subFunctionalArea: '',
    section: '',
    code: '',
    officeType: '',
    maxGradeRange: '',
    location: '',
  })


  const handleOptionClick = (option) => {
    // setSelectedOption(selectedOption === option ? null : option);
    setSelectedOption(option)
  }

  const filterChange = (filterValue, type) => {

    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)
    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, { type: type, value: filterValue }])
    }
  }

  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    filterItems()
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      // Extract role and group values from selectedFilters array
      const functionalAreas = selectedFilters.filter((filter) => filter.type === 'FunctionalAreas').map((filter) => filter.value)
      const subFunctionalAreas = selectedFilters.filter((filter) => filter.type === 'SubFunctionalAreas').map((filter) => filter.value)
      const sections = selectedFilters.filter((filter) => filter.type === 'Sections').map((filter) => filter.value)
      const codes = selectedFilters.filter((filter) => filter.type === 'Codes').map((filter) => filter.value)
      const officeTypes = selectedFilters.filter((filter) => filter.type === 'OfficeTypes').map((filter) => filter.value)
      const maxGradeRange = selectedFilters.filter((filter) => filter.type === 'MaxGradeRange').map((filter) => filter.value)
      const locations = selectedFilters.filter((filter) => filter.type === 'Locations').map((filter) => filter.value)

      // Join role and group values into comma-separated strings
      const functionalAreasString = functionalAreas.join(',')
      const subFunctionalAreasString = subFunctionalAreas.join(',')
      const sectionsString = sections.join(',')
      const codesString = codes.join(',')
      const officeTypesString = officeTypes.join(',')
      const maxGradeRangeString = maxGradeRange.join(',')
      const locationsString = locations.join(',')

      setParams((prevParams) => ({
        ...prevParams,
        functionalArea: functionalAreasString,
        subFunctionalArea: subFunctionalAreasString,
        section: sectionsString,
        code: codesString,
        officeType: officeTypesString,
        maxGradeRange: maxGradeRangeString,
        location: locationsString
      }))

    } else {
      // check show all positions toggle on or off if on fetch all positions else fetch positions of selected group
      if (toggleChecked) {
        getCreatedPositions()
      } else {
        if (groupData) {
          getPositionsOfGroup(groupData['group_name'])
        }
      }
    }
    setIsMenuOpen(false)
  }

  const updateBudget = async () => {
    if (budgetChanged) {
      setBudgetChanged(false)
      if (yearlySalary !== -1 && yearlyRemuneration !== -1 && groupId) {
        setLoading(true)
        const data = {
          yearly_salary_budget: yearlySalary.toString().replace(/,/g, ''),
          yearly_remuneration_budget: yearlyRemuneration.toString().replace(/,/g, ''),
          group_id: groupId,
        }
        axios
          .post(UPDATE_BUDGET_URL, data, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            setLoading(false)
            console.log('budgetUpdated: response: ', res.data)

            let yearlySalaryBudget = res.data.yearly_salary_budget ? res.data.yearly_salary_budget : 0
            let yearlyRemunerationBudget = res.data.yearlyRemunerationBudget ? res.data.yearlyRemunerationBudget : 0
            let yearlyLapsedBudget = res.data.lapsed_salary_budget ? res.data.lapsed_salary_budget : 0
            let yearlyLapsedRemuneration = res.data.lapsed_remuneration_budget ? res.data.lapsed_remuneration_budget : 0

            if (yearlyLapsedBudget !== null && yearlyLapsedBudget !== undefined) {
              let yearlySalaryRemaining = yearlySalaryBudget - yearlyLapsedBudget
              setYearlyRemainingSalary(yearlySalaryRemaining)
              setYearlyLapsedSalary(parseInt(yearlyLapsedBudget))

              let salaryPercentage = (yearlyLapsedBudget / yearlySalaryBudget) * 100
              setYearlySalaryPercentage(salaryPercentage > 100 ? 100 : salaryPercentage)
            } else {
              setYearlyRemainingSalary(yearlySalaryBudget)
            }
            if (yearlyLapsedRemuneration !== null && yearlyLapsedRemuneration !== undefined) {
              let yearlyRemunerationRemaining = yearlyRemunerationBudget - yearlyLapsedRemuneration
              setYearlyRemainingRemuneration(yearlyRemunerationRemaining)
              setYearlyLapsedRemuneration(parseInt(yearlyLapsedRemuneration))
              let remunerationPercentage = (yearlyLapsedRemuneration / yearlyRemunerationBudget) * 100
              setYearlyRemunerationPercentage(remunerationPercentage > 100 ? 100 : remunerationPercentage)
            } else {
              setYearlyRemainingRemuneration(yearlyRemunerationBudget)
            }

            setYearlySalary(parseInt(yearlySalaryBudget))
            setYearlyRemuneration(parseInt(yearlyRemunerationBudget))

            setShowSuccessPopup(true)
          })
          .catch((error) => {
            setLoading(false)
            if (error.response && error.response.status === 400) {
              // showToast(error.response.data.message)
              setMessage(error.response.data.message)
              setShowErrorMessage(true)
            } else if (
              error.response &&
              error.response.status === 401 &&
              error.response.data &&
              error.response.data.name === 'TokenExpiredError'
            ) {
              logout()
            } else if (error.response?.data?.error) {
              if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
              } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
              }
            } else {
              setMessage(error.message)
              setShowErrorMessage(true)
            }
          })
      } else {
        console.log('Invalid data')
      }
    }
  }

  // Fetching list of groups
  const getGroups = async () => {
    try {
      const response = await axios.get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })

      setAllGroups(response.data.data)
    } catch (error) {
      // Consolidated error handling
      handleErrors(error)
    }
  }

  // Handle different types of errors
  const handleErrors = (error) => {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400) {
        setMessage(data.message)
      } else if (status === 401 && data.name === 'TokenExpiredError') {
        logout()
      } else if (data.error) {
        if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(data.error.message)
        }
      } else {
        setMessage('An unknown error occurred')
      }
    } else {
      setMessage(error.message)
    }

    setShowErrorMessage(true)
  }

  const getPositionsOfGroup = async (groupName) => {
    // const urlWithParams = `${GET_POSITIONS_OF_GROUP_URL}?group_name=${encodeURIComponent(groupName)}`

    let data = { ...params }
    data.group_name = encodeURIComponent(groupName);
    data.functionalArea = data.functionalArea ? encodeURIComponent(data.functionalArea) : ''
    data.subFunctionalArea = data.subFunctionalArea ? encodeURIComponent(data.subFunctionalArea) : ''
    data.section = data.section ? encodeURIComponent(data.section) : ''
    data.code = data.code ? encodeURIComponent(data.code) : ''
    data.officeType = data.officeType ? encodeURIComponent(data.officeType) : ''
    data.maxGradeRange = data.maxGradeRange ? encodeURIComponent(data.maxGradeRange) : ''
    data.location = data.location ? encodeURIComponent(data.location) : ''

    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )

    await axios
      .get(GET_POSITIONS_URL, {
        // params: {
        //   group_name: groupName,
        // },
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {

        let data = res.data;
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination?.page || params.limit !== pagination?.limit || params.total !== pagination?.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        let positionsOfGroup = res.data.data
        setAllPositions(positionsOfGroup)

      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // fetching list of all positions
  const getCreatedPositions = async () => {

    let data = { ...params }
    data.status = 'REQUISITION_NOT_RAISED,POSTED,REQUISITION_RAISED';

    data.functionalArea = data.functionalArea ? encodeURIComponent(data.functionalArea) : ''
    data.subFunctionalArea = data.subFunctionalArea ? encodeURIComponent(data.subFunctionalArea) : ''
    data.section = data.section ? encodeURIComponent(data.section) : ''
    data.code = data.code ? encodeURIComponent(data.code) : ''
    data.officeType = data.officeType ? encodeURIComponent(data.officeType) : ''
    data.maxGradeRange = data.maxGradeRange ? encodeURIComponent(data.maxGradeRange) : ''
    data.location = data.location ? encodeURIComponent(data.location) : ''

    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )

    await axios
      .get(GET_POSITIONS_URL, {
        // params: {
        //   status: 'REQUISITION_NOT_RAISED,POSTED,REQUISITION_RAISED',
        // },
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {

        let data = res.data;
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination?.page || params.limit !== pagination?.limit || params.total !== pagination?.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        const positions = data?.data;
        setAllPositions(positions)

      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getFilters = async () => {
    axios
      .get(GET_FILTERS_LIST_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allFilters = res.data.filters
        setFilters(allFilters)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getOverAllBudgetData = async () => {
    axios
      .get(GET_OVERALL_BUDGET_DATA_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data

        let yearlySalaryBudget = data.totalSalaryBudget ? data.totalSalaryBudget : 0
        let yearlyRemunerationBudget = data.totalRemunerationBudget ? data.totalRemunerationBudget : 0
        let yearlyLapsedBudget = data.totalLapsedSalaryBudget ? data.totalLapsedSalaryBudget : 0
        let yearlyLapsedRemuneration = data.totalLapsedRemunerationBudget ? data.totalLapsedRemunerationBudget : 0

        if (yearlyLapsedBudget !== null && yearlyLapsedBudget !== undefined) {
          let yearlySalaryRemaining = yearlySalaryBudget - yearlyLapsedBudget
          setYearlyRemainingSalary(yearlySalaryRemaining)
          setYearlyLapsedSalary(yearlyLapsedBudget)

          let salaryPercentage = (yearlyLapsedBudget / yearlySalaryBudget) * 100
          setYearlySalaryPercentage(salaryPercentage > 100 ? 100 : salaryPercentage)
        } else {
          setYearlyRemainingSalary(yearlySalaryBudget)
        }
        if (yearlyLapsedRemuneration !== null && yearlyLapsedRemuneration !== undefined) {
          let yearlyRemunerationRemaining = yearlyRemunerationBudget - yearlyLapsedRemuneration
          setYearlyRemainingRemuneration(yearlyRemunerationRemaining)
          setYearlyLapsedRemuneration(yearlyLapsedRemuneration)
          let remunerationPercentage = (yearlyLapsedRemuneration / yearlyRemunerationBudget) * 100
          setYearlyRemunerationPercentage(remunerationPercentage > 100 ? 100 : remunerationPercentage)
        } else {
          setYearlyRemainingRemuneration(yearlyRemunerationBudget)
        }

        setYearlySalary(yearlySalaryBudget)
        setYearlyRemuneration(yearlyRemunerationBudget)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getBudgetData = async (groupId) => {
    setLoading(true)

    const urlWithParams = `${GET_ALL_GROUPS}?group_name=${encodeURIComponent(groupId)}`
    axios
      .get(urlWithParams, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false)
        const group = res.data.data[0]
        let yearlySalaryBudget = group.yearly_salary_budget ? group.yearly_salary_budget : 0
        let yearlyRemunerationBudget = group.yearly_remuneration_budget ? group.yearly_remuneration_budget : 0
        let yearlyLapsedBudget = group.lapsed_salary_budget ? group.lapsed_salary_budget : 0
        let yearlyLapsedRemuneration = group.lapsed_remuneration_budget ? group.lapsed_remuneration_budget : 0

        if (yearlyLapsedBudget !== null && yearlyLapsedBudget !== undefined) {
          let yearlySalaryRemaining = yearlySalaryBudget - yearlyLapsedBudget
          setYearlyRemainingSalary(yearlySalaryRemaining)
          setYearlyLapsedSalary(parseInt(yearlyLapsedBudget))

          let salaryPercentage = (yearlyLapsedBudget / yearlySalaryBudget) * 100
          setYearlySalaryPercentage(salaryPercentage > 100 ? 100 : salaryPercentage)
        } else {
          setYearlyRemainingSalary(yearlySalaryBudget)
        }
        if (yearlyLapsedRemuneration !== null && yearlyLapsedRemuneration !== undefined) {
          let yearlyRemunerationRemaining = yearlyRemunerationBudget - yearlyLapsedRemuneration
          setYearlyRemainingRemuneration(yearlyRemunerationRemaining)
          setYearlyLapsedRemuneration(parseInt(yearlyLapsedRemuneration))
          let remunerationPercentage = (yearlyLapsedRemuneration / yearlyRemunerationBudget) * 100
          setYearlyRemunerationPercentage(remunerationPercentage > 100 ? 100 : remunerationPercentage)
        } else {
          setYearlyRemainingRemuneration(yearlyRemunerationBudget)
        }

        setYearlySalary(parseInt(yearlySalaryBudget))
        setYearlyRemuneration(parseInt(yearlyRemunerationBudget))
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    (async () => {
      await getOverAllBudgetData()
      // await getCreatedPositions()
      await getGroups()
      await getFilters()
    })()
  }, [])

  useEffect(() => {
    (async () => {
      if (groupData) {
        await getPositionsOfGroup(groupData['group_name'])
      } else {
        await getCreatedPositions()
      }
    })()
  }, [params]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setIsMenuOpen(false)
      }
    }
    window.addEventListener('keydown', handleEscapeKey)
    return () => window.removeEventListener('keydown', handleEscapeKey)
  }, [])

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen)
    console.log('isMenuOpen: ', isMenuOpen)
    setSelectedOption(null)
  }

  const handleYearlySalaryChange = (event) => {
    const input = event.target.value.replace(/,/g, '') // Remove existing commas
    const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas every three digits

    setYearlySalary(formattedValue)
    // setYearlySalary(event.target.value);
    setBudgetChanged(true)
  }
  const handleYearlyRemunerationChange = (event) => {
    const input = event.target.value.replace(/,/g, '') // Remove existing commas
    const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas every three digits
    setYearlyRemuneration(formattedValue)
    // setYearlyRemuneration(event.target.value);
    setBudgetChanged(true)
  }

  // filter the list of Groups
  const handleGroupChange = (event) => {
    const query = event.target.value
    setGroupQuery(query)
    let filteredResults = allGroups.filter((item) => {
      let groupName = `${item['group_name']}`
      return groupName.toLowerCase().includes(query.toLowerCase())
    })
    setGroupResults(filteredResults.length > 0 ? filteredResults : allGroups)
  }

  const handleGroupClick = (item) => () => {
    let query = `${item['group_name']}`
    if (groupQuery === query) {
      setGroupResults([])
      return
    }
    setGroupQuery(query)
    setGroupResults([])

    setGroupData(item)
    setGroup(item['group_id'])
    setGroupId(item['group_id'])
    getBudgetData(item['group_name'])
    getPositionsOfGroup(item['group_name'])
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  function showImportPositionsPopUp() {
    setShowImportPositions(true)
  }

  function uploadCSV() {
    if (file !== null) {
      setLoading(true)
      console.log('upload csv file')
      let formData = new FormData()
      formData.append('file', file)
      axios
        .post(UPLOAD_POSITIONS_CSV_URL, formData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setLoading(false)
          console.log('uploadCSV_Response', res.data)
          // check show all positions toggle checked or not if checked fetch list of all positions
          // else check selected group is null or not if not fetch list of positions for selected group
          if (toggleChecked) {
            getCreatedPositions()
          } else if (groupData) {
            getPositionsOfGroup(groupData['group_name'])
          }
        })
        .catch((error) => {
          setLoading(false)
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response?.data?.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    }
    setShowImportPositions(false)
  }

  function handleCloseImportPositions() {
    setShowImportPositions(false)
    setFile(null)
  }

  function handleCloseAddUpdatePosition() {
    setShowAddUpdatePositions(false)
  }
  function showAddUpdatePosition(formType, position) {
    console.log('editpositionInfo: ', position)
    // setFormType(formType);
    // setCurrentPosition(position);
    // setShowAddUpdatePositions(true);

    // Redirect to createPositions page with the params
    const params = {
      yearly_Salary: yearlySalary.toLocaleString(),
      yearly_Remuneration: yearlyRemuneration.toLocaleString(),
      formType: formType,
      groupData: groupData,
      currentPosition: position,
      yearly_Remaining_Salary: yearlyRemainingSalary.toLocaleString(),
      yearly_Remaining_Remuneration: yearlyRemainingRemuneration.toLocaleString(),
      yearly_Lapsed_Salary: yearlyLapsedSalary.toLocaleString(),
      yearly_Lapsed_Remuneration: yearlyLapsedRemuneration.toLocaleString(),
      yearly_Salary_Percentage: yearlySalaryPercentage,
      yearly_Remuneration_Percentage: yearlyRemunerationPercentage,
    }

    // Redirect to createPositions page with the params
    navigate('/createPositions', { state: { params } })
  }
  function openArchivePopup(position) {
    setCurrentPosition(position)
    setShowArchivePopup(true)
  }
  function closeArchivePopup() {
    setShowArchivePopup(false)
  }

  function handleFileChange(event) {
    const csvFile = event.target.files[0]
    if (csvFile && csvFile.name.endsWith('.csv')) {
      setFile(csvFile)
    } else {
      // Show error message or take appropriate action
      setMessage('Only .csv file is acceptable.')
      setShowErrorMessage(true)
      // Clear file input if needed
      event.target.value = null
    }
  }

  const handleToggleChange = () => {
    setToggleChecked(!toggleChecked)
    if (!toggleChecked) {
      // hit API to fetch positions of all groups
      setGroupData(null)
      setGroupQuery('')
      getCreatedPositions()
      getOverAllBudgetData()
    } else {
      setAllPositions(null)
      setCurrentRows(null)
    }
  }

  const updateData = () => {
    if (toggleChecked) {
      getCreatedPositions()
      getOverAllBudgetData()
    } else {
      // fetch updated list of positions according to selected group
      if (groupData) {
        getBudgetData(groupData['group_name'])
        getPositionsOfGroup(groupData['group_name'])
      }
    }
  }

  const onlyNumbers = (event) => {
    // Allow only numeric characters, backspace, and delete keys
    if (
      !/\d/.test(event.key) && // Allow numeric characters
      event.key !== 'Backspace' && // Allow backspace key
      event.key !== 'Delete' && // Allow delete key
      event.key !== 'ArrowLeft' && // Allow arrow left key
      event.key !== 'ArrowRight' // Allow arrow right key
    ) {
      event.preventDefault()
    }
  }

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...allPositions].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    // setCurrentRows(sorted)
    setSortField(field)
    setSortDirection(direction)

    let data = { ...params }
    data.order = field
    data.sort = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  return (
    <>
      {/* card-bg shadow-sm mt-12 py-4  */}
      <div className=' card-bg mt-12 g-5 g-xl-8 px-10 pb-8 shadow-sm'>
        <div className='d-flex flex-wrap flex-stack pt-4 mb-4'>
          <label className='form-label fs-6 fw-bolder'>{toggleChecked ? '' : 'Select Group'}</label>
          <ToggleSwitch label='Show all Groups' checked={toggleChecked} handleChange={handleToggleChange} />
        </div>

        {toggleChecked ? (
          <div></div>
        ) : (
          <div className='mb-8'>
            <input
              type='text'
              className='search-bar-style form-control   w-140px'
              placeholder='Select a group'
              value={groupQuery}
              onChange={handleGroupChange}
              onFocus={handleGroupChange}
            />
            {groupResults.length > 0 && (
              <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                {groupResults.map((item, index) => (
                  <li
                    key={index}
                    onClick={handleGroupClick(item)}
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                    className='hover-li'
                  >{`${item['group_name']}`}</li>
                ))}
              </ul>
            )}
          </div>
        )}

        <div className='d-flex flex-column card-bg shadow-sm pb-4 mb-10' >

          <div className='col-md-12 d-flex pt-8'>
            <div className='col-md-6 col-sm-6 mb-4'>
              <h5 className='form-label fs-6'>{toggleChecked ? 'Overall BOP Salary Budget' : 'Total Years Salary Budget'}</h5>
              {toggleChecked ? (
                <div>
                  <h5 className='form-label fs-6 my-4' style={{ color: '#F36523' }}>{`PKR ${yearlySalary.toLocaleString()}`}</h5>
                </div>
              ) : (
                <div className='d-flex align-items-center my-4'>
                  <h5 className='form-label fs-6 me-4 ' style={{ color: '#F36523' }}>
                    PKR
                  </h5>
                  <input
                    style={{ color: '#F36523' }}
                    type='text'
                    min={-1}
                    step={1}
                    className='form-control fs-6 '
                    value={yearlySalary.toLocaleString()}
                    placeholder='0'
                    onKeyDown={onlyNumbers}
                    onChange={(event) => handleYearlySalaryChange(event)}
                  />
                </div>
              )}
              <ProgressBar
                value={yearlySalaryPercentage}
                max={100}
                // backgroundColor='#D7F9EF'
                // progressColor='#FFE2E5'
                backgroundColor='#FFEBCE'
                progressColor='#F36523'
                shadow='rgba(0, 0, 0, 0.2)'
              />
              <div className='col-md-12 d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <img src={icArrow} />
                  <h5 className='form-label fs-7 mt-4' style={{ color: '#F36523' }}>{`PKR ${yearlyLapsedSalary.toLocaleString()}`}</h5>
                  <h5 className='form-label fs-7'>Budget already lapsed</h5>
                </div>

                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <img src={icArrow} />
                  <h5 className='form-label fs-7 mt-4' style={{ color: '#F36523' }}>{`PKR ${yearlyRemainingSalary.toLocaleString()}`}</h5>
                  <h5 className='form-label fs-7'>Current Remaining Budget</h5>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6'>
              <h5 className='form-label fs-6'>{toggleChecked ? 'Overall BOP Remuneration Budget' : 'Total Years Remuneration Budget'}</h5>
              {toggleChecked ? (
                <div>
                  <h5 className='form-label fs-6 my-4' style={{ color: '#F36523' }}>{`PKR ${yearlyRemuneration.toLocaleString()}`}</h5>
                </div>
              ) : (
                <div className='d-flex align-items-center my-4'>
                  <h5 className='form-label fs-6 me-4 ' style={{ color: '#F36523' }}>
                    PKR
                  </h5>
                  <input
                    style={{ color: '#F36523' }}
                    type='text'
                    min={-1}
                    step={1}
                    className='form-control fs-6 '
                    value={yearlyRemuneration.toLocaleString()}
                    placeholder='0'
                    onKeyDown={onlyNumbers}
                    onChange={(event) => handleYearlyRemunerationChange(event)}
                  />
                </div>
              )}

              <ProgressBar
                value={yearlyRemunerationPercentage}
                max={100}
                // backgroundColor='#D7F9EF'
                // progressColor='#FFE2E5'
                backgroundColor='#FFEBCE'
                progressColor='#F36523'
                shadow='rgba(0, 0, 0, 0.2)'
              />
              <div className='col-md-12 d-flex align-items-center ' style={{ justifyContent: 'space-between' }}>
                <div className=' d-flex flex-column justify-content-center align-items-center'>
                  <img src={icArrow} />
                  <h5 className='form-label fs-7 mt-4' style={{ color: '#F36523' }}>{`PKR ${yearlyLapsedRemuneration.toLocaleString()}`}</h5>
                  <h5 className='form-label fs-7'>Budget already lapsed</h5>
                </div>

                <div className=' d-flex flex-column justify-content-center align-items-center'>
                  <img src={icArrow} />
                  <h5
                    className='form-label fs-7 mt-4'
                    style={{ color: '#F36523' }}
                  >{`PKR ${yearlyRemainingRemuneration.toLocaleString()}`}</h5>
                  <h5 className='form-label fs-7'>Current Remaining Budget</h5>
                </div>
              </div>
            </div>
          </div>
          {!toggleChecked && (
            <button
              className={`fw-bolder px-6 mt-4 me-8 fs-5 ${budgetChanged ? 'add-position-btn btn' : 'grey-btn'}`}
              style={{ marginLeft: 'auto', float: 'right', cursor: budgetChanged ? 'pointer' : 'default' }}
              onClick={() => updateBudget()}
            >
              Update
            </button>
          )}
        </div>

        <div className='d-flex mb-6'>
          <div className='d-flex align-items-center '>
            <h3 className='form-label fs-4 fw-bolder me-4'>{groupData ? groupData.group_name + ' Positions' : 'All Positions'}</h3>
          </div>

          <div className='ms-auto d-flex align-items-center '>
            <div className={clsx('d-flex align-items-center')} id='kt_filter_position_menu_toggle'>
              <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                // data-kt-menu-placement='bottom-end'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom'
                onClick={toggleMenu}
              >
                <label className='filter-btn fs-5 fw-bolder'>
                  Filters
                  <img src={imagePath.ic_filter_orange} width={14} height={14} style={{ marginLeft: '4px' }} />
                </label>
              </div>
              {/* {isMenuOpen && (
                // https://www.youtube.com/watch?v=u1yr_HZivzk   tutorial for filter logic
                <div id='filter-view' className='filter-menu'> */}

              <div
                // className='filter-menu role-filter-menu'
                className={clsx(
                  'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-175px',
                  {
                    show: isMenuOpen,
                  }
                )}
                data-kt-menu='true'
              // onMouseLeave={() => setSelectedOption(null)}
              >
                <ul style={{ listStyleType: 'none', cursor: 'pointer' }}>
                  {filters.map((filter, index) => {
                    const option = Object.keys(filter)[0]
                    const subOptions = filter[option]
                    return (
                      <li style={{ cursor: 'pointer' }} key={index} onMouseEnter={() => handleOptionClick(option)}>
                        {
                          <div className='d-flex align-items-center justify-content-center cursor-pointer'>
                            <label
                              className='form-label me-2 hoverable-text cursor-pointer'
                              style={{ color: selectedOption === option ? '#F36523' : '#535260' }}
                            >
                              {option}
                            </label>
                            <img src={icArrowRight} style={{ marginLeft: 'auto', marginBottom: '2px', paddingRight: '8px' }} />
                          </div>
                        }

                        {selectedOption === option && (
                          <div className='sub-filter-menu' data-kt-menu='true' onMouseLeave={() => setSelectedOption(null)}>
                            <ul style={{ listStyleType: 'none' }}>
                              {subOptions ? subOptions.map((subOption, subIndex) => {
                                const isChecked = selectedFilters.some((filter) => filter.type === selectedOption && filter.value === subOption)
                                return (
                                  <li className=' d-flex hoverable-text mb-4 align-items-center' style={{ cursor: 'pointer' }} key={subIndex}>
                                    <input
                                      name='termsConditions'
                                      className='form-check-input me-2'
                                      style={{ height: '16px', width: '16px' }}
                                      type='checkbox'
                                      // value={termsChecked}
                                      checked={isChecked}
                                      onChange={(event) => filterChange(subOption, selectedOption)}
                                    />

                                    <label className='form-check-label '>{subOption}</label>
                                  </li>
                                )
                              }) : (<div></div>)}
                            </ul>
                          </div>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
              {/* </div>
              )} */}
            </div>
            <button
              className='fw-bolder fs-5 btn text-white add-position-btn mx-4'
              onClick={() => showAddUpdatePosition('create', null)}
              disabled={yearlySalary <= 0}
            >
              {'+ Add New Position'}
            </button>
            <button className='fw-bolder fs-5 btn text-white add-position-btn' onClick={() => showImportPositionsPopUp()}>
              <img src={imagePath.ic_upload_white} className='me-2'></img> Import Positions
            </button>
          </div>
        </div>
        <div className='d-flex mb-6' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {selectedFilters ? (
            selectedFilters.map((option, index) => {
              return (
                <div key={index} className='d-flex orange-tag align-items-center me-4' style={{ height: '34px' }}>
                  {option?.value}
                  <img className='ms-2 cursor-pointer' src={imagePath.ic_cross_orange}
                    onClick={() => removeFilter(option.value, option.type)}
                  />
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>
        <div className='table-responsive'>
          <table className='table mx-auto table-row-gray-100 align-middle gs-0 gy-3 px-4 table-borders'>
            <thead>
              <tr className='fw-bold graycolor grajabg px-4'>
                <th
                  scope='col'
                  onClick={() => sortByField('title')}
                  className='align-items-center ps-4'
                  style={{ minWidth: '12rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 me-2'>Functional Designation</label>
                  {renderSortIcon('title')}
                </th>
                <th
                  scope='col'
                  onClick={() => sortByField('max_basic_salary_per_month')}
                  className='align-items-center '
                  style={{ minWidth: '14rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 me-2'>Max Basic Salary / Month</label>
                  {renderSortIcon('max_basic_salary_per_month')}
                </th>
                <th
                  scope='col'
                  onClick={() => sortByField('max_total_remuneration_per_month')}
                  className='align-items-center '
                  style={{ minWidth: '14rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 me-2'>Max Total Gross Salary / Month</label>
                  {renderSortIcon('max_total_remuneration_per_month')}
                </th>
                <th
                  scope='col'
                  onClick={() => sortByField('max_grade_range')}
                  className='align-items-center '
                  style={{ minWidth: '12rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 me-2'>Maximum Grade Range</label>
                  {renderSortIcon('max_grade_range')}
                </th>
                <th
                  scope='col'
                  onClick={() => sortByField('contract_type')}
                  className='align-items-center '
                  style={{ minWidth: '7rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 '>Contract Type</label>
                  {renderSortIcon('contract_type')}
                </th>
                <th
                  scope='col'
                  className='align-items-center '
                  style={{ minWidth: '3rem', whiteSpace: 'nowrap', color: '#000000' }}
                >
                  <label className=' form-label fw-bolder fs-6 '>Action</label>
                </th>
              </tr>
            </thead>
            <tbody>
              {allPositions ? (
                allPositions.map((row, index) => {
                  let salaryPerMonth: any = row?.max_basic_salary_per_month ? parseInt(row?.max_basic_salary_per_month) : 0
                  salaryPerMonth = salaryPerMonth.toLocaleString()
                  let remunerationPerMonth: any = row?.max_total_remuneration_per_month
                    ? parseInt(row?.max_total_remuneration_per_month)
                    : 0
                  remunerationPerMonth = remunerationPerMonth.toLocaleString()
                  const isEvenRow = index % 2 === 0
                  return (
                    <tr
                      key={index}
                      className='px-4'
                      style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}
                    >
                      <td style={{ marginLeft: '1rem', paddingLeft: '1rem' }}>{row.title}</td>
                      <td>{`PKR ${salaryPerMonth}`}</td>
                      <td>{`PKR ${remunerationPerMonth}`}</td>
                      <td>{row.max_grade_range}</td>
                      <td>
                        <div className='d-flex'>
                          <div>{row.contact_type}</div>

                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center ms-auto me-4'>
                          <img
                            className='cursor-pointer me-4'
                            style={{ objectFit: 'contain', width: '20px', height: '20px' }}
                            src={imagePath.ic_edit_circle_orange}
                            alt='Edit'
                            title='Edit'
                            onClick={() => showAddUpdatePosition('update', row)}
                          />
                          <img
                            className='cursor-pointer'
                            style={{ objectFit: 'contain', width: '28px', height: '28px' }}
                            src={imagePath.ic_archive}
                            alt='Archive'
                            title='Archive'
                            onClick={() => openArchivePopup(row)}
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <div></div>
              )}
            </tbody>
          </table>
          {(allPositions && allPositions.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
            <div className='col-md-4'>
              <label>{`Showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>
      {loading && <LoaderView message='Loading...' />}

      {showImportPositions && (
        <ImportPositionsPopup
          show={true}
          handleClose={() => {
            handleCloseImportPositions()
          }}
          uploadFile={() => {
            uploadCSV()
          }}
          handleFileChange={(event) => {
            handleFileChange(event)
          }}
          file={file}
        />
      )}

      {showAddUpdatePositions && (
        <AddUpdatePosition
          show={true}
          handleClose={() => {
            handleCloseAddUpdatePosition()
          }}
          yearly_Salary={yearlySalary.toLocaleString()}
          yearly_Remuneration={yearlyRemuneration.toLocaleString()}
          formType={formType}
          groupData={groupData}
          currentPosition={currentPosition}
          updatePositions={updateData}
          yearly_Remaining_Salary={yearlyRemainingSalary.toLocaleString()}
          yearly_Remaining_Remuneration={yearlyRemainingRemuneration.toLocaleString()}
          yearly_Lapsed_Salary={yearlyLapsedSalary.toLocaleString()}
          yearly_Lapsed_Remuneration={yearlyLapsedRemuneration.toLocaleString()}
          yearly_Salary_Percentage={yearlySalaryPercentage}
          yearly_Remuneration_Percentage={yearlyRemunerationPercentage}
        />
      )}

      {showArchivePopup && (
        <WarningPopup
          show={true}
          message={
            'This Designation will move to Archive folder and the budget of this designation will be reverted. Are you sure you want to Archive this designation'
          }
          handleClose={() => {
            closeArchivePopup()
          }}
          position={currentPosition}
          updatePositions={updateData}
        // updatePositions={getCreatedPositions}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {showSuccessPopup && <SuccessDialog handleClose={() => setShowSuccessPopup(false)} message={'Budget value updated successfully.'} />}
    </>
  )
}

export default DepartmentBudget
