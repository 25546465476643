//import liraries
import React, {useEffect, useState} from 'react'

import '../../CVScreens/CandiMain.css'
import '../../CVScreens/personalinfo.css'
import '../../CVScreens/position.css'
import '../../CVScreens/Stepper.css'

import dayjs, {Dayjs} from 'dayjs'

import Loader from '../../CVScreens/comps/Loader'
import axios from 'axios'
import {useAuth} from '../../../auth'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-education/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`

// create a component
const EducationsScreen = ({
  inputFields,
  cgpaRadio,
  percentageRadio,
  addFields,
  removeFields,
  educationalYears,
  handleFormChange,
  handleCGPAChange,
  handleKeyPress,
  handleCGPARadio,
  handleCurrentlyStudying,
  handleChangeEducationalYears,
  ...props
}) => {
  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [sendRequest, setSendRequest] = useState(false)
  const [institutes, setInstitutes] = useState<any>()
  const [educationLevels, setEducationLevels] = useState<any>()
  const [specialization, setSpecialization] = useState<any>()
  const [totaledu] = useState([{edu: 1}])
  const [format, setFormat] = useState<Dayjs | any>([])
  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const eduYears = Array.from({length: 7}, (_, index) => 10 + index)

  // end_date: dayjs('2014-08-18T21:11:54'),
  const [profileStrength, setprofileStrength] = React.useState<Dayjs | any>([
    {
      profile_info: 'false',
      work_exp: 'false',
      education: 'false',
      certificate: 'false',
      skills: 'false',
      total: '5',
    },
  ])
  const [currentProgress, setCurrentProgress] = useState(5)

  const getConfigurations = async () => {
    await axios
      .get(GET_CONFIGURATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.Configuration
        let termsConditions = data?.terms_condition
        if (termsConditions) {
          termsConditions = JSON.parse(termsConditions)
          const initialTermsAndConditions = Object.keys(termsConditions).map((key) => ({
            i: key,
            data: termsConditions[key],
          }))
        }
        let eduLevels = data?.education_level
        // if (eduLevels) {
        //   eduLevels = JSON.parse(eduLevels)
        //   const initialEdu = Object.keys(eduLevels).map((key) => ({
        //     i: key,
        //     data: eduLevels[key],
        //   }))
        //   setEducationLevels(initialEdu)
        // }

        if (eduLevels) {
          eduLevels = JSON.parse(eduLevels);
          const initialEdu = Object.keys(eduLevels)
            .map((key) => ({
              i: key,
              data: eduLevels[key]['data'],
              active: eduLevels[key]['active']
            }))
            .filter((level) => level.active); // Filter out objects with active === false
          setEducationLevels(initialEdu);
        }

        let institutes = data?.institutes
        if (institutes) {
          institutes = JSON.parse(institutes)
          const initialData = Object.keys(institutes).map((key) => ({
            i: key,
            data: institutes[key],
          }))
          setInstitutes(initialData)
        }
        let programs = data?.specialization
        if (programs) {
          programs = JSON.parse(programs)
          const initialData = Object.keys(programs).map((key) => ({
            i: key,
            data: programs[key],
          }))
          setSpecialization(initialData)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      getConfigurations()
    })()
  }, [])

  return (
    <div>
      <div style={{width: '100%', margin: '0 auto'}}>
        <i style={{float: 'right'}} onClick={addFields} className='bi bi-plus-lg addmore'></i>
      </div>
      <div className='row g-5 g-xl-8 personal-info-container' style={{ margin: '0', padding: '0' }}>
        {/* add more button  */}

        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card' style={{}}>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row ms-4'>
                  <span className='card-label section-heading fw-bolder fs-7 ' style={{color: '#d50000'}}>
                    Please enter your highest qualification first!
                  </span>
                  {/* FORM START  */}
                  <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    {inputFields.map((input, index) => {
                      console.log('edudata:',input.institution)
                      return (
                        <div className='dynamic-section' key={index}>
                          {index === 0 ? null : (
                            <div style={{width: '95%', margin: '0 auto'}}>
                              <i
                                style={{float: 'right'}}
                                onClick={() => removeFields(index)}
                                className='bi bi bi-trash-fill trash-button'
                              ></i>
                            </div>
                          )}
                          {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                          <h3 className='card-title mt-5 align-items-center flex-column'>
                            <span className='card-label text-gray-800 fw-bolder fs-3 ms-3'>
                              Academic Qualification {index + 1}
                            </span>
                          </h3>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className={`form-group ${input.institution === 'Other' ? 'col-md-3 mx-8' : 'col-md-6'}`}>
                              {/* company  */}
                              <label className='form-label labelse '>
                                Educational Level<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='education_level'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  value={input.education_level}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {educationLevels &&
                                    educationLevels.map((edu) => {
                                      let index = edu?.i
                                      let level = edu?.data
                                      return (
                                        <option style={{cursor: 'pointer'}} key={index} value={level}>
                                          {level}
                                        </option>
                                      )
                                    })}
                                </select>
                              </div>{' '}
                            </div>

                            <div className={`form-group ${input.institution === 'Other' ? 'col-md-4 me-8' : 'col-md-6'}`}>
                              <label className='form-label labelse '>
                                Institution<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='institution'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select w-140px'
                                  defaultValue='Select an option'
                                  value={input.institution}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {institutes &&
                                    institutes.map((institute) => {
                                      let index = institute?.i
                                      let data = institute?.data
                                      return (
                                        <option style={{cursor: 'pointer'}} key={index} value={data}>
                                          {data}
                                        </option>
                                      )
                                    })}
                                  <option value='Other'>Other</option>
                                </select>
                              </div>
                            </div>
                            {input.institution === 'Other' ? (
                              <div className='form-group col-md-4 me-8'>
                                <label className='form-label labelse '>
                                  Enter Institution Name<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='otherInstitute'
                                    className='form-control'
                                    placeholder='Institution'
                                    value={input.otherInstitute}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            {/* program  */}
                            <div className={`form-group ${input.program === 'Other' ? 'col-md-3 mx-8' : 'col-md-6'}`}>
                              <label className='form-label labelse '>
                                Program/ Specialization<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='program'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  value={input.program}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {specialization &&
                                    specialization.map((program) => {
                                      let index = program?.i
                                      let data = program?.data
                                      return (
                                        <option style={{cursor: 'pointer'}} key={index} value={data}>
                                          {data}
                                        </option>
                                      )
                                    })}
                                  <option value='Other'>Other</option>
                                </select>
                              </div>
                            </div>

                            {input.Program === 'Other' ? (
                              <div className='form-group col-md-4 me-8'>
                                <label className='form-label labelse '>
                                  Type Program Name<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='otherProgram'
                                    className='form-control'
                                    placeholder='Enter Program'
                                    value={input.otherProgram}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            ) : (
                              <div></div>
                            )}

                            {/* cgpa  */}
                            <div className={`form-group ${input.program === 'Other' ? 'col-md-4' : 'col-md-6'}`}>
                              {/* <label className='form-label labelse '>CGPA</label> */}
                              <div className='form-row d-flex justify-content-spacearound form-label'>
                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{alignContent: 'center', display: 'flex', alignItems: 'center'}}
                                >
                                  <span style={{color: '#d50000', marginRight: '4px'}}>*</span>
                                  <input
                                    type='radio'
                                    style={{width: '14px', height: '14px'}}
                                    id={'cgpaGrade' + index}
                                    name={'education_type' + index}
                                    value='cgpa'
                                    checked={input.education_type === 'cgpa'}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='CGPA' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                                    CGPA
                                  </label>
                                </div>
                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{alignContent: 'center', display: 'flex', alignItems: 'center'}}
                                >
                                  <input
                                    type='radio'
                                    style={{width: '14px', height: '14px'}}
                                    id={'percentageGrade' + index}
                                    name={'education_type' + index}
                                    value='percentage'
                                    checked={input.education_type === 'percentage'}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='Percentage' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                                    Percentage
                                  </label>
                                </div>
                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{alignContent: 'center', display: 'flex', alignItems: 'center', marginLeft: '20px'}}
                                >
                                  <input
                                    type='radio'
                                    style={{width: '14px', height: '14px'}}
                                    id={'divisionGrade' + index}
                                    name={'education_type' + index}
                                    value='division'
                                    checked={input.education_type === 'division'}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='Division' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                                    Division
                                  </label>
                                </div>
                              </div>{' '}
                              <div className='me-1'>
                                <input
                                  // type='number'
                                  min='0'
                                  max={input.education_type === 'cgpa' ? 4 : input.education_type === 'percentage' ? 100 : 3}
                                  step={input.education_type === 'cgpa' ? 0.01 : input.education_type === 'percentage' ? 0.01 : 1}
                                  name='education_value'
                                  className='form-control'
                                  placeholder='3.0'
                                  value={input.education_value}
                                  required
                                  onChange={(event) => handleCGPAChange(index, event)}
                                  onKeyPress={(event) => handleKeyPress(event, input.education_type)}
                                />
                              </div>{' '}
                            </div>
                          </div>

                          {/* Date picker  */}
                          {/* from  */}
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>
                                Date From<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                              </label>
                              <div className='datepicker me-1'>
                                {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DesktopDatePicker
                                                                        
                                                                        label="start_date"
                                                                        inputFormat="MM/DD/YYYY"
                                                                        value={input.start_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) => <TextField id='start_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='start_date'
                                    className='form-control'
                                    value={input.start_date}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>
                                Date To<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                              </label>
                              <div className='datepicker me-1'>
                                {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="end_date"
                                                                        value={input.end_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) =>
                                                                            <TextField name='end_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='end_date'
                                    className='form-control'
                                    value={input.end_date}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='form-group mt-10 checks-container col-md-12'>
                            <div className='form-check checkbox-container'>
                              <input
                                name='currently_studying'
                                className='form-check-input'
                                type='checkbox'
                                value={input.currently_studying}
                                checked={input.currently_studying}
                                onChange={(event) => handleCurrentlyStudying(index, event)}
                              />
                              <label className='form-check-label'>Currently studying here?</label>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-sm-6 col-md-6 col-lg-6'>
                        {/* company  */}
                        <label className='form-label labelse '>
                          Total Educational Years<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-1'>
                          <select
                            name='educationalYears'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select Total Edu Years'
                            value={educationalYears}
                            required
                            onChange={(event) => handleChangeEducationalYears(event)}
                          >
                            <option style={{cursor: 'pointer'}} value=''>
                              Select Total Educational Years
                            </option>
                            {eduYears.map((years) => (
                              <option style={{cursor: 'pointer'}} key={years} value={years}>
                                {years}
                              </option>
                            ))}
                            <option style={{cursor: 'pointer'}} value='Greater than 16'>
                              {'Greater than 16'}
                            </option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>
                  </form>
                  {/* FORM END  */}
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default EducationsScreen
