import {FC, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, RightToolbar, ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {WithChildren} from '../helpers'
import {useAuth} from '../../app/modules/auth'
import {CandidateHeaderWrapper} from './components/header/CandidateHeaderWrapper'
import {themeModeSwitchHelper, useThemeMode} from '../partials/layout/theme-mode/ThemeModeProvider'
import { isCandidatePath } from '../../constants/CommonFunctions'
// import "./MasterLayout.css"
const hostname = window.location.hostname

const MasterLayout: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()
  const {currentUser} = useAuth()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  return (
    <PageDataProvider>
      {(currentUser?.role === 'candidate' || isCandidatePath(hostname)) ? (
        <div className='page d-flex flex-row flex-column-fluid font-setting'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <CandidateHeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid' >
              <Toolbar />
              <div
                className={clsx(
                  'd-flex flex-column-fluid align-items-start',
                  classes.contentContainer.join(' ')
                )}
                id='kt_post'
              >
                <AsideDefault />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <div className='page d-flex flex-row flex-column-fluid font-setting'>
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid' style={{marginTop:'65px'}}>
              <Toolbar />
              <div
                className={clsx(
                  'd-flex flex-column-fluid align-items-start',
                  classes.contentContainer.join(' ')
                )}
                id='kt_post'
              >
                <AsideDefault />
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}
      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
