//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, format, differenceInDays, parseISO, addMilliseconds } from 'date-fns';

const API_URL = process.env.REACT_APP_API_URL
const GET_TALENT_ACTIVITY_URL = `${API_URL}/analytics/talent-activity`

// create a component
const TalentActivityTable = () => {
    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState(currentYear);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [reportData, setReportData] = useState<any>();
    const [reportUrl, setReportUrl] = useState('');

    useEffect(() => {
        (async () => {
            getTalentActivity();
            getReportUrl();
        })()
    }, [year])

    const getTalentActivity = async () => {

        let params = {};
        if (year) {
            params['year'] = year;
        }
        setLoading(true);
        await axios.get(GET_TALENT_ACTIVITY_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data;
            setReportData(data?.data);

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const getReportUrl = async () => {

        let params = {};
        if (year) {
            params['year'] = year;
            params['report'] = true;
        }
        setLoading(true);
        await axios.get(GET_TALENT_ACTIVITY_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data;
            setReportUrl(data?.reportUrl);

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (e) => {
        e.preventDefault();
        if (!reportUrl) {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(reportUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                // link.download = reportUrl.split('/').pop(); // Extracts file name from URL

                 // Use optional chaining and nullish coalescing to handle undefined
                 const filename = reportUrl.split('/').pop() ?? 'Bank Wide Talent Activity.xlsx'; // Provide a default filename
                 link.download = filename;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };


    return (
        <div>
            <div style={{ position: 'relative' }}>
                {loading && (
                    // Show loading icon if isLoading state is true
                    <div
                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                    >
                        <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                    </div>
                )}

                <div className='card-bg shadow-sm mb-10' >
                    <div className='d-flex align-items-center px-6 py-8'>
                        <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Bank Wide Talent Activity</label>
                        <div className='d-flex align-items-center ms-auto'>
                            {/* year view */}
                            <div className='d-flex align-items-center mx-4'>

                                <input
                                    className='py-2 px-2 fs-5 fw-semibold'
                                    style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                                    type='number'
                                    inputMode='numeric'
                                    placeholder='2024'
                                    value={year}
                                    max={currentYear}
                                    min={2023}
                                    onChange={(event) => handleChangeYear(event)}
                                />
                            </div>
                            {/* download report view */}
                            <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={(e) => handleDownloadFile(e)}>
                                    <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                                    <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                                </div>
                            </div>

                            {/* refresh view */}
                            <div>
                                <img className='cursor-pointer ' src={imagePath.ic_refresh_orange} width={34} height={34}
                                    onClick={() => {
                                        getTalentActivity();
                                        getReportUrl();
                                    }} />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='table-responsive'>
                            <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                                <table className='table align-middle table-borders'>
                                    <thead>
                                        <tr className='fw-bold graycolor bg-light align-items-center'>
                                            <th className='ps-6 fw-bolder my-auto' style={{
                                                color: '#475467',
                                                whiteSpace: 'nowrap',
                                                verticalAlign: 'middle',
                                                minWidth: '16rem',
                                                position: 'sticky',
                                                left: 0,
                                                background: '#F5F8FA',
                                                zIndex: 1
                                            }}>Indicators/Process</th>

                                            <th className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>In Process</th>
                                            <th className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Offered</th>
                                            <th className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Lorem Ipsum</th>
                                            <th className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Joining to Date</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(reportData) ? Object.keys(reportData).map((key, index) => {
                                            const { inProcess, offered, x, joined } = reportData[key];
                                            const isEvenRow = index % 2 === 0
                                            return (
                                                <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                    <td className='ps-6 fw-bolder' style={{ color: '#475467', border: '1px solid #EDF2F9' }} >{key}</td>
                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{inProcess}</td>
                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{offered}</td>
                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{x}</td>
                                                    <td className='ps-6' style={{ border: '1px solid #EDF2F9' }} >{joined}</td>
                                                </tr>
                                            );
                                        }) : (<div></div>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )
            }
        </div>
    );
};

//make this component available to the app
export default TalentActivityTable;
