//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import imagePath from '../../../../constants/imagePath';
import { getFormattedDate } from '../../../../constants/CommonFunctions';

type Props = {
    show: boolean
    reasons: string
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const ReasonsPopup = ({ show, reasons, handleClose }: Props) => {

    console.log('reasonInfo: ', reasons)

    const [reasonObj, setReasonObj] = useState<any>();

    useEffect(() => {

        (() => {
            if (reasons) {
                setReasonObj(JSON.parse(reasons));
            }
        })()

    }, [reasons])

    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image mb-18' src={icWarning} ></img>
                            <div >
                                {reasonObj && reasonObj?.map((reason, index) => {

                                    return (
                                        <div key={index} className='d-flex flex-column col-md-10 mx-auto'>
                                            <div className='d-flex align-items-center' >
                                                <label className='form-label fs-6 fw-bolder'>{`Comment By: ${reason?.commentBy}`}</label>
                                                <div className='d-flex align-items-center ms-auto'>
                                                    <img src={imagePath.ic_calendar_orange} width={20} height={20} />
                                                    <label className='ms-2 fs-6'>{getFormattedDate(reason?.commentTime)}</label>
                                                </div>
                                            </div>
                                            <div className='mt-4 mb-8'>
                                                <label className='form-label fs-5 fw-bolder'>{'Comments'}</label>
                                                <br></br>
                                                <p className='form-control fs-6 fw-bolder' style={{color:'#737373'}}>{reason?.reason}</p>
                                            </div>
                                            <div className='mb-8' style={{borderBottom: '1px dashed #737373'}}></div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='sureunsure'>

                                        <div onClick={handleClose} className='candi-btn2 text-white px-10 cursor-pointer fw-bolder'>
                                            Got it!
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>,
        modalsRoot
    )
};


//make this component available to the app
export default ReasonsPopup;
