//import liraries
import React from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import './CreateCompetency.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import axios from 'axios'
import imagePath from '../../../../constants/imagePath';
import LoaderView from './LoaderView';
import ShowError from '../../content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL

const REJECT_REQUISITION_URL = `${API_URL}/panel/change-panel-status`;

type Props = {
    show: boolean
    handleClose: () => void
    handleSubmit: (reason) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const RequisitionRejectionPopup = ({ show, handleClose, handleSubmit }: Props) => {
    const { currentUser, auth, logout } = useAuth()
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const isValidReason = (reason) => {
        return reason.trim().length > 0;
    };

    const handleSubmitWithReason = (e) => {
        e.preventDefault();
        if (isValidReason(reason)) {
            // handleSubmit(reason);
            // rejectOfferLetter();
            handleSubmit(reason);
        } else {
            setMessage('Please enter a valid reason.');
            setShowErrorMessage(true);
        }
    };

    const rejectOfferLetter = () => {
        // event.preventDefault();

        // setLoading(true);
        // let data = {
        //     jobapp_id: jobAppId,
        //     status: "rejected",
        //     name: "hired",
        //     member_id: currentUser?.user_id,
        //     reason: reason
        // };
        // axios.post(REJECT_OFFER_LETTER_URL, data, {
        //     headers: {
        //         Authorization: `Bearer ${auth?.token}`,
        //         'Content-Type': 'application/json',
        //     },
        // }).then((res) => {
        //     setLoading(false);
        //     let responseData = res.data;
        //     updateApplications();
        // }).catch((err) => {
        //     setLoading(false);
        //     console.error('err', err);
        // });
    };


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className=' no-header-model123'>
                <div className='modal-header no-header-model123'>
                    <h2></h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                        <img alt='' src={imagePath.ic_close}></img>
                    </div>
                </div>
                {/* end::Close */}
                <div className='mx-auto d-flex flex-column '>
                    <img alt='' className='add-warning-image' src={addcompetenctimage}></img>
                    <h3 className='fw-bolder mx-auto mt-8'>Specify Reason for Rejection </h3>
                </div>
            </div>

            <div className='modal-body px-lg-8'>
                <div className='card'>
                    {/* begin::Beader */}

                    <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                        <form>
                            <div className='form-row d-flex flex-column  justify-content-spacearound'>
                                <textarea
                                    rows={5}
                                    className='form-control mx-auto fs-5 fw-semibold'
                                    style={{ color: '#80808F', resize: 'none' }}
                                    placeholder='Type here'
                                    value={reason}
                                    onChange={handleReasonChange}
                                />
                            </div>
                            <div className='d-flex align-items-center justify-content-center my-6'>
                                <button onClick={handleClose}
                                    type='button'
                                    className=' candi-btn1 fs-5 fw-bolder py-4 px-10 me-6'>
                                    Cancel
                                </button>
                                <button onClick={handleSubmitWithReason} className='btn fs-5 candi-btn2 text-white fw-bolder my-5 py-4 px-8 '>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && (
                    <LoaderView message='Loading...' />
                )}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </div>
        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default RequisitionRejectionPopup;
