//import liraries
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'

import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import LoaderView from './LoaderView'
import imagePath from '../../../../constants/imagePath'
import defaultProfile from '../../../../app/modules/Candidate Space/assets/common@2x.png'
import ShowError from '../../content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_USER_URL = `${API_URL}/auth/register`
const UPDATE_USER_URL = `${API_URL}/auth/upload-employee-data`
const UPLOAD_IMAGE_URL = `${API_URL}/auth/upload-user-profile`
const GET_ALL_GROUPS = `${API_URL}/group`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`

type Props = {
  show: boolean
  createUpdate: string
  user: any
  handleClose: () => void
  updateUsersList: (successMessage) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const CreateUserPopup = ({ show, createUpdate, user, handleClose, updateUsersList }: Props) => {
  const { currentUser, auth, logout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<any>()
  const [employeeId, setEmployeeId] = useState('')
  const [prefix, setPrefix] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [placeOfPosting, setPlaceOfPosting] = useState('')
  const [group, setGroup] = useState('')
  const [position, setPosition] = useState('')
  const [jobLevel, setJobLevel] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('BOPTest@123456')
  const [profilePicUploading, setProfilePicUploading] = useState(false)
  const [profilePicUrl, setProfilePicUrl] = useState(null)
  const [allGroups, setAllGroups] = useState<any>([])
  const [allGrades, setAllGrades] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  // 'Executive Vice President I',
  //     'Executive Vice President',
  //     'Assistant Vice President I',
  //     'Vice President',
  //     'Vice President I',
  //     'Senior Executive Vice President',
  //     'President',
  const grades = [
    { name: 'AVP', value: 'AVP' },
    { name: 'Assistant Vice President I', value: 'Assistant Vice President I' },
    { name: 'Executive Vice President', value: 'Executive Vice President' },
    { name: 'Executive Vice President I', value: 'Executive Vice President I' },
    { name: 'OG-I', value: 'OG-I' },
    { name: 'OG-II', value: 'OG-II' },
    { name: 'OG-III', value: 'OG-III' },
    { name: 'Senior Executive Vice President', value: 'Senior Executive Vice President' },
    { name: 'SVP', value: 'SVP' },
    { name: 'SVP-I', value: 'SVP-I' },
    { name: 'Vice President', value: 'Vice President' },
    { name: 'Vice President I', value: 'Vice President I' },
    { name: 'President', value: 'President' },
  ]

  useEffect(() => {
    ; (async () => {
      getGroups()
      getGrades()
      fetchUserData()
    })()
  }, [])

  // fetching list of groups
  const getGroups = async () => {
    await axios
      .get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setAllGroups(res.data.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setAllGrades(Object.values(data))
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const fetchUserData = () => {
    if (createUpdate === 'update') {
      console.log('currentUserData: ', user)
      if (user) {
        let profilePic = user?.profile_pic
        if (profilePic) {
          setProfile(profilePic)
          setProfilePicUrl(profilePic)
        }
        let userPrefix = user?.prefix
        setPrefix(userPrefix ? userPrefix : 'Prefix')
        let userFirstName = user?.first_name
        setFirstName(userFirstName ? userFirstName : '')
        let userMiddleName = user?.middle_name
        setMiddleName(userMiddleName ? userMiddleName : '')
        let userLastName = user?.last_name
        setLastName(userLastName ? userLastName : '')
        let userEmployeeId = user?.employee_id
        setEmployeeId(userEmployeeId ? userEmployeeId : '')
        let userPhoneNo = user?.phone_number
        setContactNumber(userPhoneNo ? userPhoneNo : '')
        let userEmail = user?.email
        setEmail(userEmail ? userEmail : '')
        let userPlaceOfPosting = user?.place_of_posting
        setPlaceOfPosting(userPlaceOfPosting ? userPlaceOfPosting : '')
        let userGroup = user?.group
        setGroup(userGroup ? userGroup : '')
        let userPosition = user?.position
        setPosition(userPosition ? userPosition : '')
        let userJobLevel = user?.job_level
        setJobLevel(userJobLevel ? userJobLevel : '')
        let userRole = user?.role
        setRole(userRole ? userRole : 'Select an option')
      }
    }
  }

  const validateData = () => {
    if (!prefix) {
      setMessage('Please select prefix first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!firstName) {
      setMessage('Please enter First Name of user and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!lastName) {
      setMessage('Please enter Last Name of user and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!employeeId) {
      setMessage('Please enter Employee ID first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!contactNumber) {
      setMessage('Please enter Contact Number first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!email) {
      setMessage('Please enter Email first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!placeOfPosting) {
      setMessage('Please select place of posting first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!group) {
      setMessage('Please select a Group first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!position) {
      setMessage('Please enter position first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!jobLevel) {
      setMessage('Please select a Grade first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!role) {
      setMessage('Please select a Role first and try again.')
      setShowErrorMessage(true)
      return false
    }

    return true
  }

  const createNewUser = async (event) => {
    event.preventDefault()

    if (!validateData()) {
      return
    }

    let phoneNo = contactNumber
    const containsPlus92 = /(\+92)/.test(phoneNo)
    if (!containsPlus92) {
      phoneNo = '+92' + phoneNo
    }
    setLoading(true)

    let userData = {
      user_id: createUpdate !== 'create' ? user?.user_id : undefined,
      prefix: prefix,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      email: email,
      password: password,
      social_auth: false,
      // cnic: cnic,
      employee_id: employeeId,
      // dob: dob,
      // phone_number: '+92' + contactNumber,
      phone_number: phoneNo,
      // city: city,
      // address: address,
      // gender: gender,
      // marital_status: maritalStatus,
      // graduate: qualification,
      // religion: religion,
      place_of_posting: placeOfPosting,
      department: group,
      group: group,
      position: position,
      job_level: jobLevel,
      // salary: salary,
      role: role,
      profile_pic: profilePicUrl,
      xemployee_check: '',
      disciplinary_action: '',
      performance_rating: '',
      credential: 'false',
      joining_date: '1-10-2019',
      leaving_date: '1-3-2023',
    }
    if (createUpdate === 'create') {
      await axios.post(CREATE_USER_URL, userData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        setLoading(false)
        console.log('userCreateResponse: ', res.data)
        updateUsersList(role === 'none' ? 'User created successfully.' : 'Admin created successfully')
      }).catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else if (error.response && error.response.data && error.response.data.errors) {
          const errors = error.response.data.errors;
          if (errors && errors.length > 1) {
            setMessage(errors[0].msg || 'Something went wrong');
            setShowErrorMessage(true);
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
    } else {
      const { password, ...user } = userData
      await axios
        .post(UPDATE_USER_URL, user, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setLoading(false)
          console.log('userCreateResponse: ', res.data)
          updateUsersList('User info updated successfully')
        })
        .catch((error) => {
          setLoading(false)
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    }
  }
  const handleKeyDown = (event) => {
    // Allow only numeric characters (0-9), backspace, and arrow keys
    if (!((event.key >= '0' && event.key <= '9') || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
      event.preventDefault()
    }
  }
  const handleEmployeeIdChange = (e) => {
    setEmployeeId(e.target.value)
  }
  const handlePrefixChange = (e) => {
    setPrefix(e.target.value)
  }
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value)
  }

  const handleBlurMiddleName = () => {
    if (middleName.length < 2 || middleName.length > 28) {
      setMessage('Middle name must be between 2 and 28 characters.');
      setShowErrorMessage(true);
      setMiddleName('');
    }
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }
  const handleEmailChange = (e) => {
    const value = e.target.value
    setEmail(value)
  }
  const handleBlurEmail = () => {
    // Validate email address when the input field loses focus
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    if (!isValidEmail) {
      // Display an error message or handle invalid email address
      setMessage('Invalid email address')
      setShowErrorMessage(true)
      setEmail('')
    }
  }
  const handleContactNumberChange = (e) => {
    let inputPhone = e.target.value
    // Remove all non-digit characters from the input
    inputPhone = inputPhone.replace(/\D/g, '')
    // Regular expression to match Pakistani phone numbers without the country code
    // const phoneRegex = /^3[0-9]{2}-?[0-9]{7}$/;
    // const isValidPhone = phoneRegex.test(inputPhone);
    // if (isValidPhone) {
    // }
    setContactNumber(inputPhone)
  }
  const validatePhoneNo = () => {
    const phoneRegex = /^3[0-9]{2}-?[0-9]{7}$/
    const isValidPhone = phoneRegex.test(contactNumber)
    // Check if the phone number starts with '3'
    const startsWithThree = contactNumber.startsWith('3')
    if (!isValidPhone || !startsWithThree) {
      setContactNumber('')
      setMessage('Invalid contact number')
      setShowErrorMessage(true)
    }
  }
  const handlePlaceOfPostingChange = (event) => {
    setPlaceOfPosting(event.target.value)
  }
  const handleGroupChange = (event) => {
    setGroup(event.target.value)
  }
  const handlePositionChange = (event) => {
    setPosition(event.target.value)
  }
  const handleJobLevelChange = (event) => {
    setJobLevel(event.target.value)
  }
  const handleRoleChange = (event) => {
    setRole(event.target.value)
  }
  const handleProfilePic = async (event) => {
    const file = event.target.files[0]

    // Check if a file was selected
    if (!file) {
      return // No file selected, return early
    }

    // Regular expression to check if the file extension is .jpg, .jpeg, or .png
    const allowedExtensions = /\.(jpg|jpeg|png)$/i

    // Check if the file extension is allowed
    if (!allowedExtensions.test(file.name)) {
      alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
      event.target.value = null // Clear the file input value
      return
    }

    // Optional: You can set a maximum file size limit if needed
    const maxSizeInBytes = 5 * 1024 * 1024 // 5MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit (5MB). Please select a smaller image.')
      return
    }
    setProfile(file)
    getProfilePicUrl(file)
    const base64 = await convertBase64(file)
    // setProfile(base64);
  }

  const getProfilePicUrl = async (file) => {
    setProfilePicUploading(true)
    let formData = new FormData()
    formData.append('file', file)
    await axios
      .post(UPLOAD_IMAGE_URL, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          // 'Content-Type': 'application/json',
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log('profilePicUrl: ', res.data)
        setProfilePicUploading(false)
        setProfilePicUrl(res?.data?.profilePicture)
      })
      .catch((error) => {
        setProfilePicUploading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={show}
      // onHide={handleClose}
      backdrop={true}
    >
      <>
        <div>
          <div className=' no-header-model123'>
            <div className='modal-header no-header-model123'>
              <h2></h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <img alt='' src={Layout}></img>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <label className='form-label fs-4 fw-bolder'>{createUpdate === 'create' ? 'Create User' : 'Update User'}</label>
            </div>
          </div>

          <div className='modal-body px-lg-8'>
            <div className='card'>
              <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                <form>
                  <div>
                    <div className='profile-pic-container text-center'>
                      <img
                        className='profile-img'
                        style={{ objectFit: 'cover' }}
                        alt='Profile image'
                        src={typeof profile === 'string' ? profile : profile ? URL.createObjectURL(profile) : defaultProfile}
                        height='200'
                      />
                      <div className='pro-container text-center'>
                        <input type='file' accept='.jpg, .jpeg, .png' onChange={(event) => handleProfilePic(event)} />
                        {/* <i className='bi bi-pen-fill'></i> */}
                        <img
                          src={imagePath.ic_edit_orange}
                          style={{
                            width: '18px',
                            height: '18px',
                            marginTop: '-3.8rem',
                            zIndex: '10px',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <form encType='multipart/form-data'>
                    <h3 className='card-title align-items-center mt-10 flex-column px-8'>
                      <span className='card-label text-gray-800 fw-bolder fs-3 '>User Information</span>
                    </h3>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          First Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select
                                className='country-code'
                                id='country-code'
                                defaultValue='Prefix'
                                placeholder='Prefix'
                                value={prefix}
                                onChange={(event) => handlePrefixChange(event)}
                              >
                                <option value='Prefix'>Prefix</option>
                                <option value='Mr.'>Mr.</option>
                                <option value='Mrs.'>Mrs.</option>
                                <option value='Miss.'>Miss.</option>
                              </select>
                              <input
                                style={{ width: '79%' }}
                                type='text'
                                // name='status'
                                className='phone-no-input'
                                placeholder='First Name'
                                value={firstName}
                                maxLength={28}
                                minLength={2}
                                required
                                onChange={(event) => handleFirstNameChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Middle Name</label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Middle Name'
                            value={middleName ? middleName : ''}
                            maxLength={28}
                            minLength={2}
                            onChange={(event) => handleMiddleNameChange(event)}
                            onBlur={handleBlurMiddleName}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Last Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Last Name'
                            value={lastName}
                            maxLength={28}
                            minLength={2}
                            required
                            onChange={(event) => handleLastNameChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Employee ID<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder='e.g 1234'
                            value={employeeId}
                            required
                            onKeyDown={handleKeyDown} // Handle keydown event to allow only numeric characters
                            onChange={(event) => handleEmployeeIdChange(event)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Contact Number<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select className='country-code' id='country-code' defaultValue='+92'>
                                <option value='+92'>+92</option>
                              </select>
                              <input
                                style={{ width: '82%' }}
                                type='tel'
                                name='status'
                                className='phone-no-input'
                                autoComplete='off'
                                placeholder='3xxxxxxxxx'
                                pattern='\d*'
                                maxLength={10}
                                minLength={10}
                                value={contactNumber}
                                required
                                onBlur={validatePhoneNo}
                                onChange={(event) => handleContactNumberChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Email<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='email'
                            name='status'
                            className='form-control'
                            placeholder='Email'
                            value={email}
                            required
                            onChange={(event) => handleEmailChange(event)}
                            onBlur={handleBlurEmail}
                          />
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Place of Posting<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px '
                            defaultValue='Select an option'
                            placeholder='Select an option'
                            value={placeOfPosting}
                            required
                            onChange={(event) => handlePlaceOfPostingChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            <option value='HEAD OFFICE LAHORE'>HEAD OFFICE LAHORE</option>
                            <option value='780-TIBB Pirana Shujabad Road Multan'>780-TIBB Pirana Shujabad Road Multan</option>
                          </select>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Group<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select an option'
                            placeholder='Select an option'
                            value={group}
                            required
                            onChange={(event) => handleGroupChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            {/* <option value='Relationship Manager SME'>Relationship Manager SME</option>
                                                        <option value='Islamic Banking Division'>Islamic Banking Division</option> */}
                            {allGroups &&
                              allGroups.map((group) => (
                                <option key={group.group_id} value={group.group_name}>
                                  {group.group_name}
                                </option>
                              ))}
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Position<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder=''
                            value={position}
                            required
                            onChange={(event) => handlePositionChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Grade<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          {/* <input
                                                        type='text'
                                                        name='status'
                                                        className='form-control'
                                                        autoComplete="off"
                                                        placeholder=''
                                                        value={jobLevel}
                                                        required
                                                        onChange={(event) => handleJobLevelChange(event)}
                                                    /> */}

                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select an option'
                            placeholder='Select an option'
                            value={jobLevel}
                            required
                            onChange={(event) => handleJobLevelChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            {allGrades &&
                              allGrades.map((grade) => (
                                <option key={grade} value={grade}>
                                  {grade}
                                </option>
                              ))}
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 mb-12 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Assign Role<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px '
                            defaultValue='Select an option'
                            placeholder='Select an option'
                            value={role}
                            required
                            onChange={(event) => handleRoleChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            <option value='masterRecruiter'>Admin</option>
                            <option value='none'>User</option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>
                    {profilePicUploading && (
                      <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <label className='form-label fs-6 fw-bolder mb-1' style={{ color: '#d50000' }}>
                          Please wait profile picture is being upload...
                        </label>
                      </div>
                    )}
                    <div className='d-flex align-items-center justify-content-center '>
                      <button
                        type='button'
                        className='btn px-10 bgcolorofbutoonofsubmit mx-auto'
                        onClick={(event) => createNewUser(event)}
                        disabled={profilePicUploading}
                      >
                        {createUpdate === 'create' ? 'Create User' : 'Update User'}
                      </button>
                    </div>
                  </form>
                </form>
              </div>
            </div>
          </div>
        </div>
        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default CreateUserPopup
