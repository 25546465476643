import TextField from '@mui/material/TextField'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {useEffect, useState, useRef} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import './CandiMain.css'
import './position.css'
import './Stepper.css'
import './personalinfo.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import React from 'react'
import UploadCloud from '../assets/upload@2x.png'
import dayjs, {Dayjs} from 'dayjs'
import Loader from './comps/Loader'
import Saved from './comps/Saved'
// import { compress } from 'compressjs';
import Compressor from 'compressorjs'

import ic_cross from '../assets/ic_cross.png'
import {useAuth} from '../../auth/core/Auth'
import {useNavigate} from 'react-router-dom'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-certificates/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`

const Training = (props) => {
  const navigate = useNavigate()
  const [bankCount, setBankCount] = useState(0)
  const [currWork, setCurrWork] = useState(0)
  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  // const staticSkills = ["Data Analyst", "MERN Stack", "Front-end developer", "Node developer", "Data Scientist", "Data Engineer"];
  const [competencies, setCompetencies] = useState<any>([])
  const [inputFields, setInputFields] = React.useState<Dayjs | any>([
    {
      name: '',
      issuing_organization: '',
      specialization: '',
      issue_date: '',
      date_from: '',
      date_to: '',
      certificate: '/linkhere',
      myFile: '',
      skills: [],
      query: '',
      searchResults: [],
    },
  ])
  const [skills, setSkills] = useState<string>()
  // const [chips, setChips] = useState(['']);
  // end_date: dayjs('2014-08-18T21:11:54'),
  const [profileStrength, setprofileStrength] = React.useState<any>({
    profile_info: 'false',
    work_exp: 'false',
    education: 'false',
    certificate: 'false',
    skills: 'false',
    total: '5',
  })
  const [currentProgress, setCurrentProgress] = useState(5)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const handleFormChange = (index, event) => {
    // console.log('Change event clicked');
    // console.log("Event Name: " + event.target.name);
    // console.log("Index: " + index);
    // console.log("Value: " + event.target.value);
    // data[index][event.target.name || event.target.id] = event.target.value;

    let data = [...inputFields]
    data[index][event.target.name] = event.target.value
    setInputFields(data)
  }

  const handleDateFromChange = (index, event) => {
    let {value} = event.target

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10)

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9)
    }

    let data = [...inputFields]
    let dateToValue = data[index]['date_to']
    if (!validateDate(value, dateToValue)) {
      window.alert('Date From can not be equal or greater than Date To')
      return
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  const handleDateToChange = (index, event) => {
    let {value} = event.target

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10)

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9)
    }

    let data = [...inputFields]
    let dateFromValue = data[index]['date_from']
    if (!validateDate(dateFromValue, value)) {
      window.alert('Date From can not be equal or greater than Date To')
      return
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  function validateDate(startDate, endDate) {
    if (startDate && endDate) {
      let dateObject = new Date(endDate)
      let year = dateObject.getFullYear().toString()
      if (year.length >= 4) {
        let start = new Date(startDate).getTime()
        let end = new Date(endDate).getTime()

        let timeDiff = end - start
        if (timeDiff <= 0) {
          return false
        }
      }
    }
    return true
  }

  const handleFileChange = async (index, event) => {
    const file = event.target.files[0]
    if (!file) {
      return // No file selected, return early
    }

    // Regular expression to check if the file extension is .jpg, .jpeg, or .png
    const allowedExtensions = /\.(jpg|jpeg|png)$/i

    // Check if the file extension is allowed
    if (!allowedExtensions.test(file.name)) {
      alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
      event.target.value = null // Clear the file input value
      return
    }
    // Optional: You can set a maximum file size limit if needed
    const maxSizeInBytes = 1 * 1024 * 1024 // 1MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit (1MB). Please select a smaller image.')
      return
    }

    console.log('compressedData: file: ', file)
    new Compressor(file, {
      quality: 0.2,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      async success(result) {
        const base64 = await convertBase64(result)
        console.log('compressedData: compressedImage: ', result)
        console.log('compressedData: base64: ', base64)
        let data = [...inputFields]
        data[index][event.target.name] = base64
        setInputFields(data)
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const addFields = () => {
    let newfield = {
      name: '',
      issuing_organization: '',
      specialization: '',
      issue_date: '',
      date_from: '',
      date_to: '',
      certificate: '/linkhere',
      myFile: '',
      skills: [],
    }
    // setInputFields([...inputFields, newfield])
    setInputFields((prevFields) => [...prevFields, newfield])
  }
  const removeFields = (index) => {
    let data = [...inputFields]
    data.splice(index, 1)
    setInputFields(data)
  }

  // checking name is entered for all training entry or not
  const isNameEmpty = () => {
    let totalNameEntered = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.name !== '' && field.name !== null && field.name !== undefined) {
          totalNameEntered++
        }
      }
    }
    return totalNameEntered !== 0 && totalNameEntered === inputFields.length
  }

  // checking organization is entered for all training entry or not
  const isOrganizationEmpty = () => {
    let totalOrganizationEntered = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.issuing_organization !== '' && field.issuing_organization !== null && field.issuing_organization !== undefined) {
          totalOrganizationEntered++
        }
      }
    }
    return totalOrganizationEntered !== 0 && totalOrganizationEntered === inputFields.length
  }

  const isIssuingDateSelected = () => {
    let totalSelectedDates = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.date_to !== '' && field.date_to !== null && field.date_to !== undefined) {
          totalSelectedDates++
        }
      }
    }
    return totalSelectedDates !== 0 && totalSelectedDates === inputFields.length
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(inputFields)
    // loader component

    // if (!isNameEmpty()) {
    //     console.log('Please enter name first and try again.')
    //     window.alert('Please enter name first and try again.');
    //     return
    // }
    // if (!isOrganizationEmpty()) {
    //     console.log('Please enter organization first and try again.')
    //     window.alert('Please enter organization first and try again.');
    //     return
    // }
    // if (!isIssuingDateSelected()) {
    //     console.log('Please select an issuing date first and try again')
    //     window.alert('Please select an issuing date first and try again');
    //     return;
    // }

    if (isNameEmpty()) {
      var loader = document.getElementById('loader')
      loader?.classList.remove('d-none')

      const newCertifications = inputFields.map((cert, index) => {
        const newSkills = cert.skills.reduce((acc, skill, index) => {
          acc[index] = skill
          return acc
        }, {})

        const newCertificate = {
          [`certificate${index + 1}`]: {
            name: cert.name,
            issuing_organization: cert.issuing_organization,
            specialization: cert.specialization,
            issue_date: cert.issue_date,
            date_from: cert.date_from,
            date_to: cert.date_to,
            certificate: cert.certificate,
            skills: newSkills,
            myFile: cert.myFile,
          },
        }

        return newCertificate
      })

      const formattedCertifications = {certification: newCertifications}
      console.log('trainingsData: formattedCertifications: ', formattedCertifications)
      let certification = formattedCertifications.certification
      const transformedData = {
        certification: [
          {
            ...certification.reduce((acc, curr) => {
              return {...acc, ...curr}
            }, {}),
          },
        ],
      }
      certification = transformedData.certification
      var work = {certification, profile_strength: profileStrength}
      // var work = { certification: inputFields, profile_strength: profileStrength }
      var data = JSON.stringify(work)
      console.log('Candidate Certificates: ', data)
      const url = PERSONAL_INFORMATION_URL + user_id
      console.log('URL', url)
      console.log('CURRENT USER', currentUser)
      axios
        .put(url, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
            // 'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('res', res)
          props.reduce(4)
          // loader component
          var loader = document.getElementById('loader')
          loader?.classList.add('d-none')
          console.log('profileStrengthTotal: ', profileStrength.total)
          if (profileStrength.total == 100) {
            navigate('/candidatedashboard')
          }
        })
        .catch((error) => {
          var loader = document.getElementById('loader')
          loader?.classList.add('d-none')
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            // Handle error message from the API
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    } else {
    }
  }

  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [sendRequest, setSendRequest] = useState(false)

  const handleSkillSet = (index, skills, event) => {
    let data = [...inputFields]
    let ski = [...data[index][event.target.name]]
    ski.push(event.target.value)
    data[index][event.target.name] = ski
    setInputFields(data)
  }
  const handleSkills = (e, index) => {
    e.preventDefault()
    setSkills(e.target.value)
    console.log('SKILL SET index: ' + index)
    // chips.push(skills)
    handleSkillSet(index, skills, e)
  }

  useEffect(() => {
    if (sendRequest) {
      //send the request
      setSendRequest(false)
    }
  }, [sendRequest])

  const handleDelete = (index, sIndex) => {
    let data = [...inputFields]
    let ski = [...data[index]['skills']]
    ski.splice(sIndex, 1)
    data[index]['skills'] = ski
    setInputFields(data)
    setSendRequest(true)
  }

  const handleInputChange = (index, event) => {
    const query = event.target.value
    // setQuery(query);
    const filteredResults = competencies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    // setResults(filteredResults.length > 0 ? filteredResults : competencies);
    let data = [...inputFields]
    data[index]['query'] = query
    data[index]['searchResults'] = filteredResults.length > 0 ? filteredResults : competencies
    setInputFields(data)
  }

  // handle click on list of searched suggestion if skill not already added add new skill
  const handleItemClick = (item, index) => () => {
    let data = [...inputFields]
    let ski = [...data[index]['skills']]
    if (!chipAlreadyAvailable(item, ski) && ski.length < 5) {
      ski.push(item)
      data[index]['skills'] = ski
      // setInputFields(data);
    }
    data[index]['query'] = ''
    data[index]['searchResults'] = []
    setInputFields(data)
    // setResults([]);
  }

  // checking chip already available or not
  const chipAlreadyAvailable = (item, chips) => {
    let found = false
    for (let chip of chips) {
      if (chip === item) {
        found = true
      }
    }
    return found
  }

  // setting saved data
  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        // fetching profile strength data
        const strength = JSON.parse(response.data.candidate.profile_strength)
        console.log('workExpe strength: ', strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 20,
              profile_info: 'false',
              work_exp: 'false',
              education: 'false',
              certificate: 'true',
              skills: 'false',
            })
          } else {
            let totalProgress = 5
            let profile = strength.profile_info
            if (typeof profile === 'string') {
              profile = strength.profile_info?.toLowerCase?.() === 'true'
            }
            let work = strength.work_exp
            if (typeof work === 'string') {
              work = strength.work_exp?.toLowerCase?.() === 'true'
            }
            let edu = strength.education
            if (typeof edu === 'string') {
              edu = strength.education?.toLowerCase?.() === 'true'
            }
            let certifi = strength.certificate
            if (typeof certifi === 'string') {
              certifi = strength.certificate?.toLowerCase?.() === 'true'
            }
            let skill = strength.skills
            if (typeof skill === 'string') {
              skill = strength.skills?.toLowerCase?.() === 'true'
            }

            if (profile) {
              totalProgress += 20
            }
            if (work) {
              totalProgress += 20
            }
            if (edu) {
              totalProgress += 20
            }
            if (certifi) {
              totalProgress += 15
            }
            if (skill) {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            console.log('workExpe totalProgress: ', totalProgress)
            setprofileStrength({
              total: strength.certificate === 'false' ? totalProgress + 15 : totalProgress,
              profile_info: strength?.profile_info == undefined ? 'false' : strength.profile_info,
              work_exp: strength?.work_exp == undefined ? 'false' : strength.work_exp,
              education: strength?.education == undefined ? 'false' : strength.education,
              certificate: 'true',
              skills: strength?.skills == undefined ? 'false' : strength.skills,
            })
          }
        } else {
          setprofileStrength({
            total: 20,
            profile_info: 'false',
            work_exp: 'false',
            education: 'false',
            certificate: 'true',
            skills: 'false',
          })
        }

        // console.log("CANDIADTE: ", response.data.candidate.work_experience)
        setSavedInfo(JSON.parse(response.data.candidate.certification))
        setTempMail(response.data.user.email)

        //fetching data from API and generating multiple input fields according to number of entries
        let fields: any = []
        if (savedInfo) {
          savedInfo.map((val, index) => {
            const certificates = Object.values(val)
            for (let i = 0; i < certificates.length; i++) {
              let entity: any = certificates[i]

              console.log('TrainingData: entity: ', entity)
              let newField = {
                name: entity.name,
                issuing_organization: entity.issuing_organization,
                specialization: entity.specialization,
                issue_date: entity.issue_date,
                date_from: entity.date_from,
                date_to: entity.date_to,
                skills: entity.skills ? Object.values(entity.skills) : [],
                myFile: entity.myFile,
                query: '',
                searchResults: [],
              }
              fields.push(newField)
            }
          })
        }

        if (fields.length > 0) {
          setInputFields(fields)
        } else {
          if (inputFields.length == 0) {
            addFields()
          }
        }
      })
    }
    getData()
  }, [tempMail])

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getCompetencies()
    })()
  }, [])

  return (
    <div style={{width: '100%'}}>
      <div style={{width: '88%', margin: '0 auto'}}>
        <i style={{float: 'right'}} onClick={addFields} className='bi bi-plus-lg addmore'></i>
      </div>
      <div className='row g-5 g-xl-8 personal-info-container' style={{margin: '0 auto', padding: '0'}}>
        {/* add more button  */}

        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  {/* FORM START  */}
                  <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    {inputFields.map((input, index) => {
                      return (
                        <div className='dynamic-section' key={index}>
                          {index === 0 ? null : (
                            <div style={{width: '95%', margin: '0 auto'}}>
                              <i
                                style={{float: 'right'}}
                                onClick={() => removeFields(index)}
                                className='bi bi bi-trash-fill trash-button'
                              ></i>
                            </div>
                          )}
                          {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                          <h3 className='card-title mt-5 align-items-center flex-column'>
                            <span className='card-label section-heading text-gray-800 fw-bolder fs-3 '>
                              Training / Certification {index + 1}
                            </span>
                          </h3>

                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Certification Name</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='name'
                                  className='form-control'
                                  placeholder='Certification'
                                  value={input.name}
                                  onChange={(event) => handleFormChange(index, event)}
                                />
                              </div>{' '}
                            </div>
                            {/* Job title  */}
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Issuing Organization</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='issuing_organization'
                                  className='form-control'
                                  placeholder='Organization'
                                  value={input.issuing_organization}
                                  onChange={(event) => handleFormChange(index, event)}
                                />
                              </div>{' '}
                            </div>
                          </div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Subject / Specialization</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='text'
                                    name='specialization'
                                    className='form-control'
                                    placeholder='Subject / Specialization'
                                    value={input?.specialization}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Issue Date</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='issue_date'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input?.issue_date}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date picker  */}
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Date From</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='date_from'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input?.date_from}
                                    onChange={(event) => handleDateFromChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Date To</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='date_to'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input.date_to}
                                    onChange={(event) => handleDateToChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* certification upload  */}
                          <div className='form-group col-md-12'>
                            <div className='col-md-6'>
                              <label className='form-label labelse mt-10'>Upload Media</label>
                              <div className='drop-zone-training-file' style={{width: '100%'}}>
                                <div className='drop-wrapper'>
                                  <div className='drop-image-container'>
                                    {/* <img className='drop-zone__img' src={UploadCloud} alt="..." /> */}
                                    <img
                                      className='drop-zone__img'
                                      style={{objectFit: 'cover'}}
                                      src={
                                        typeof input.myFile === 'string'
                                          ? input.myFile
                                          : input.myFile
                                          ? URL.createObjectURL(input.myFile)
                                          : UploadCloud
                                      }
                                      alt='file'
                                    />
                                  </div>
                                  <div className='drop-image-container'>
                                    <span className='drop-zone__prompt'>Drag and Drop here</span>
                                    <span className='drop-zone__prompt'>or</span>
                                    <span className='drop-zone__prompt browse-file'>Browse Files</span>
                                    <span className='drop-zone__prompt fs-6'>File type should be .jpg, .jpeg or .png</span>
                                  </div>
                                </div>
                                <div className='cv-container'>
                                  <input
                                    type='file'
                                    name='myFile'
                                    className='drop-zone__input-training-file'
                                    accept='.jpg, .jpeg, .png'
                                    onChange={(event) => handleFileChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <label className='form-label labelse add-skills-label'>Add Skills Used</label>
                          {/* SKILLS  */}
                          <div className='form-row d-flex mt-2 justify-content-spacearound' style={{margin: '0 1.3rem 0 2rem'}}>
                            <div className='form-group col-md-12'>
                              {/* <label className='form-label labelse '> Blood Group</label> */}
                              <div className='me-4  '>
                                <input
                                  type='text'
                                  className='search-bar-style form-control   w-140px'
                                  placeholder='Search skills'
                                  value={input.query}
                                  onChange={(event) => handleInputChange(index, event)}
                                  onFocus={(event) => handleInputChange(index, event)}
                                />
                                {input.searchResults && input.searchResults.length > 0 && (
                                  <ul className='form-control   w-140px'>
                                    {input.searchResults.map((item, suggestionsIndex) => (
                                      <li
                                        key={suggestionsIndex}
                                        onClick={handleItemClick(item, index)}
                                        style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                      >
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              {/* <Chips skillset={chips} /> */}
                              <div className=' mt-4'>
                                {input.skills.map((val, i) => (
                                  <div key={i} style={{width: 'fit-content', display: 'inline-block', margin: '0 1rem 0 0'}}>
                                    <div className='chip-uxi'>
                                      {val}&nbsp;
                                      {/* <i className='bi bi-x-lg' onClick={() => handleDelete(index, i)}></i> */}
                                      <img src={ic_cross} alt='remove' onClick={() => handleDelete(index, i)}></img>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* <h3 className='card-title align-items-center flex-column'>
                                                    <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                        Suggested Competencies
                                                    </span>
                                                </h3> */}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {/* <div className='form-row d-flex mt-10 justify-content-spacearound'> */}
                      <div className='d-flex align-items-center justify-content-center mt-8'>
                        <button onClick={handleSubmit} className='candi-btn1 m-5' type='submit'>
                          <span style={{fontWeight: 'bold'}}>Save</span>
                        </button>
                        <div
                          className='candi-btn2 text-white m-5'
                          // disabled={props.curr === 5}
                          onClick={(e) => {
                            if (props.curr === 5) {
                              // disable button
                            } else {
                              handleSubmit(e)
                              props.updates(props.curr + 1)
                            }
                          }}
                        >
                          <span style={{fontWeight: 'bold'}}>Next</span>
                        </div>
                      </div>
                    {/* </div> */}
                  </form>
                  {/* FORM END  */}
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>

      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default Training
