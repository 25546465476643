import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import { useState } from 'react'
import './Success.css'
import SuccessIcon from './img/Component 193 – 8@3x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import hurrayImg from '../images/hurray_img@3x.png';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
export const SEND_ONLINE_TEST_LINK_URL = `${API_URL}/jobapplication/send-test-link`

type Props = {
  show: boolean
  handleClose: () => void
  jobapp_id: string
  message: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SuccessInterviewpopUp = ({ show, jobapp_id, handleClose, message }: Props) => {
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const { auth } = useAuth()
  const createApprovalPopup = (data) => {
    setpopupApproval(
      <SuccessInterviewpopUp
        show={true}
        jobapp_id={jobapp_id}
        handleClose={handleClose}
        message={message}
      />
    )
  }

  const handleSubmit = () => {
    console.log('closed')
    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
      backdropClassName="modal-backdrop backdrop-blur"
    >
      <div className=' no-header-model123'>
        
        {/* end::Close */}
        <div className=' d-flex flex-column mt-10 pt-10'>
          <img alt='' className='addcompetenctimage' src={hurrayImg}></img>
          <h3 className='fw-bolder mx-auto my-5'>Success!</h3>
          <p className=' mx-auto my-5 text-style col-md-8 '>{message}</p>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
      <form
            //  onSubmit={handleSubmit}
            >
              <div className='btn-container'>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn px-10 apply-review-button'
                >
                  Okay, Got it!
                </button>
              </div>
            </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { SuccessInterviewpopUp }
