import Educations from './comps/Educations'

const Education = (props) => {
    return (
        <div style={{width:'100%'}}>
            <Educations reduce={props.reduce} curr={props.curr} updates={props.updates} university={props.university} education={props.education}/>
        </div>
    )
}

export default Education

