// Context/LoginContext
import React, {useState, createContext} from 'react'

interface SelectedCompetencyProps {
  children: React.ReactNode
}
export const CreateSelectiveCompetecies = createContext({
  SlectedComp: '',
  setSlectedComp: (SlectedComp) => {},
})
export const CreateSelectedComp = ({children}: SelectedCompetencyProps) => {
  const [SlectedComp, setSlectedComp] = useState('')
  return (
    <CreateSelectiveCompetecies.Provider value={{SlectedComp, setSlectedComp}}>
      {children}
    </CreateSelectiveCompetecies.Provider>
  )
}
