/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Header } from './Header'
import { DefaultTitle } from './page-title/DefaultTitle'
import { Topbar } from './Topbar'
import './header.css'
import { useAuth } from '../../../../app/modules/auth'
const whiteLogo = process.env.REACT_APP_LOGO_WHITE
export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout()
  const { header, aside } = config
  const { currentUser } = useAuth();

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch', 'start', 'sticky-header')}
      style={{ position: 'fixed', width: '100%', zIndex: '1000' }}
      {...attributes.headerMenu}
    >
      <div className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center')}>
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}

        <div
          className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
            style={{ backgroundColor: '#FFFFFF' }}
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
          </div>
        </div>

        {/* <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'> */}
        <div className='d-flex align-items-stretch  flex-lg-grow-1'>
          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>

          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}
        </div>

        <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0'>
          <Link to={currentUser?.role === 'poc' ? '/pocdashboard' : currentUser?.role === 'candidate' ? '/candidatedashboard' : '/dashboard'}>
            <img
              alt='Logo'
              src={whiteLogo}
              className='logo-default h-35px'
            />
            <img
              alt='Logo'
              src={whiteLogo}
              className='logo-sticky h-35px'
            />
          </Link>
        </div>
      </div>
    </div>
  )
}
