import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useCreateJobsButtonContext } from '../../../context/useJobsButtonContext'
import { useCompetenciesButtonContext } from '../../../context/useCreateCompetenciesButton'
import { useCreateJobsContext } from '../../../context/useCreateJobsContext'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import './Positions.css'
import axios from 'axios'
import { useAuth } from '../auth'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import imagePath from '../../../constants/imagePath'
import clsx from 'clsx'
import CustomPagination from '../dashboards/components/CustomPagination'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const API_URL = process.env.REACT_APP_API_URL
const GET_POSITIONS_URL = `${API_URL}/position/get-vacant-position-data`
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`

const VacanPositions = () => {
  const { currentUser, auth, logout } = useAuth()
  // const {jobState, setjobState} = useContext(SetJobsContext)
  const { jobButton, setjobButton } = useCreateJobsButtonContext()
  const { CompetenciesButton, setCompetenciesButton } = useCompetenciesButtonContext()
  const { jobsState, setjobsState } = useCreateJobsContext()
  const [allPositions, setAllPositions] = useState<any>([])
  // const [filteredPositions, setFilteredPositions] = useState<any>([]);
  const [allRecruiters, setAllRecruiters] = useState<any>([])
  const [allGroups, setAllGroups] = useState<any>([])
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false)
  const [isFilterGroupMenuOpen, setIsFilterGroupMenuOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [loadingTable, setLoadingTable] = useState(false)
  const [params, setParams] = useState<any>({
    order_by: 'updated_at',
    direction: 'desc',
    page: 1,
    limit: 10,
    total: 0,
    group_name: '',
    recruiter_ids: '',
  })

  // console.log(' dash', loggedIn)
  useEffect(() => {
    setjobButton(false)
    setCompetenciesButton('')
    setjobsState(false)

    console.log('CompetenciesButton', CompetenciesButton)
    console.log('jobButton', jobButton)
    console.log('jobstate', jobsState)
    return () => {
      setjobButton(false)
      setjobsState(false)
      setCompetenciesButton('')
    }
  }, [])

  useEffect(() => {
    ; (async () => {
      await getCreatedPositions()
      await getAllRecruiters()
    })()
  }, [params])

  const getAllRecruiters = async () => {
    await axios
      .get(GET_ALL_RECRUITERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        let recruiters = resp.data.recruitersData

        recruiters = recruiters.filter((obj) => obj !== null)
        console.log('RESPONSE RECRUITER:', recruiters)
        if (recruiters !== null && recruiters !== undefined && recruiters.length > 0) {
          setAllRecruiters(recruiters)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // fetching list of all positions
  const getCreatedPositions = async () => {
    let data = { ...params }
    if (currentUser?.role === 'ta_lead') {
      data.ta_lead_id = currentUser?.user_id
    }
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    setLoadingTable(true)
    await axios
      .get(GET_POSITIONS_URL, {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoadingTable(false)
        let data = res?.data
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        const positions = data?.data
        if (positions) {
          let groups: any[] = []
          positions.map((position: any) => {
            groups.push(position?.department)
          })

          // Convert to Set to eliminate duplicates
          const uniqueGroupsSet = new Set(groups)

          // Convert back to array
          const uniqueGroupsArray = Array.from(uniqueGroupsSet)
          setAllGroups(uniqueGroupsArray)

          setAllPositions(positions)
        }
      })
      .catch((error) => {
        setLoadingTable(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    if (selectedFilters && selectedFilters.length > 0) {
      // Extract role and group values from selectedFilters array
      const recruiters = selectedFilters.filter((filter) => filter.type === 'recruiter').map((filter) => filter.value)
      const groups = selectedFilters.filter((filter) => filter.type === 'group').map((filter) => filter.value)
      // Join role and group values into comma-separated strings
      const recruitersIds = recruiters.join(',')
      const groupsString = groups.join(',')
      // Update params state with new role and group values
      setParams((prevParams) => ({
        ...prevParams,
        recruiter_ids: recruitersIds,
        group_name: groupsString,
      }))
    }
  }

  const filterChange = (filterValue, type, recruiterName) => {
    setIsFilterMenuOpen(false)
    setIsFilterGroupMenuOpen(false)
    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)

    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, { type: type, value: filterValue, recruiterName: recruiterName }])
    }
    // if (selectedFilters.includes(filterValue)) {
    //   let currentFilters = selectedFilters.filter((el) => el !== filterValue);
    //   setSelectedFilters(currentFilters);
    // } else {
    //   setSelectedFilters([...selectedFilters, filterValue]);
    // }
  }

  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    // const sorted = [...allPositions].sort((a, b) => {
    //   const valueA = a[field];
    //   const valueB = b[field];
    //   if (valueA < valueB) return direction === 'asc' ? -1 : 1;
    //   if (valueA > valueB) return direction === 'asc' ? 1 : -1;
    //   return 0;
    // });

    // setFilteredPositions(sorted);
    setSortField(field)
    setSortDirection(direction)

    console.log('sortByField: ', field);
    let data = { ...params }
    data.order_by = field
    data.direction = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const sortByRecruiterName = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    // const sorted = [...allPositions].sort((a, b) => {
    //   const valueA = a.recruiterData.fullName;
    //   const valueB = b.recruiterData.fullName;
    //   if (valueA < valueB) return direction === 'asc' ? -1 : 1;
    //   if (valueA > valueB) return direction === 'asc' ? 1 : -1;
    //   return 0;
    // });

    // setFilteredPositions(sorted);
    setSortField(field)
    setSortDirection(direction)

    let data = { ...params }
    data.order_by = field
    data.direction = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const downloadExcelReport = () => {
    // Manually specify columns
    const columns = ['Group', 'Recruiter', 'Position', 'No. of Positions', 'Location', 'Time Period', 'Status']

    // Manually create data array
    const data = allPositions.map((row) => [
      row?.department || '',
      `${row?.recruiterData?.name}` || '',
      row?.vacantPosition || '',
      row.no_of_positions || '',
      row.location || '',
      calculateTimePeriod(row?.position_created_at) || '',
      row?.status || '',
    ])

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data])

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'VacantPositions')

    // Download the Excel file
    XLSX.writeFile(wb, 'VacantPositionsReport.xlsx')
  }

  const calculateTimePeriod = (createdAt) => {
    createdAt = createdAt?.substring(0, 10)
    // Convert the string to a Date object
    const createdAtDate = new Date(createdAt)
    // Get the current date
    const currentDate = new Date()
    // Calculate the difference in milliseconds
    const timeDifference = currentDate.getTime() - createdAtDate.getTime()
    // Calculate the number of days
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
    return daysDifference ? daysDifference : ''
  }

  const toggleRecruiterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen)
  }
  const toggleGroupMenu = () => {
    setIsFilterGroupMenuOpen(!isFilterGroupMenuOpen)
  }

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    filterItems()
  }

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Vacant Positions' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/dashboard' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/vacantpositions' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Vacant Positions
          </Link>
        </h3>
      </div>

      {/* begin::Row */}
      {/* <div className=' d-flex justify-content-center gy-5 gx-xl-8'> */}
      <div className='card-bg shadow-sm p-8'>
        <div className='d-flex align-items-center mb-8'>
          <h3 className='form-label fw-bolder fs-2'>Vacant Position Insights</h3>
          {currentUser?.role === 'masterRecruiter' ? (
            <div className='d-flex align-items-center ms-auto'>
              <div className='d-flex align-items-center'>
                <div className={clsx('d-flex align-items-center')} id='kt_filter_recruiter_vacant-positions'>
                  <div
                    className={clsx('cursor-pointer symbol')}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    // data-kt-menu-placement='bottom-end'
                    data-kt-menu-placement='bottom-start'
                    data-kt-menu-flip='bottom'
                    onClick={toggleRecruiterMenu}
                    style={{ width: '14.5rem' }}
                  >
                    <label className='filter-btn fs-5 fw-bolder me-2'>
                      Filter by Recruiter
                      <img style={{ marginLeft: '4px' }} src={imagePath.ic_filter_orange} width={14} height={14} />
                    </label>
                  </div>

                  <div
                    // className="group-filter-menu"
                    className={clsx(
                      ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-175px',
                      {
                        show: isFilterMenuOpen,
                      }
                    )}
                    // style={{ width: '320px', maxWidth: '350px' }}
                    data-kt-menu='true'
                  >
                    <div className='px-4' style={{ listStyleType: 'none', cursor: 'pointer', maxHeight: '14rem', overflow: 'auto' }}>
                      {allRecruiters.map((recruiter, index) => {
                        let fullName = `${recruiter?.first_name} ${recruiter?.last_name}`
                        const isChecked = selectedFilters.some(
                          (filter) => filter.type === 'recruiter' && filter.value === recruiter?.user_id
                        )
                        return (
                          <div
                            className=' hoverable-text mb-4'
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-start' }}
                            key={index}
                          >
                            <input
                              name='groupFilter'
                              className='form-check-input checkbox-style me-2'
                              type='checkbox'
                              // value={termsChecked}
                              checked={isChecked}
                              onChange={(event) => filterChange(recruiter?.user_id, 'recruiter', fullName)}
                            />
                            <label className='form-check-label fs-6 ' style={{ color: 'black' }}>
                              {fullName}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className={clsx('d-flex align-items-center')} id='kt_filter_group_vacant-positions'>
                  <div
                    className={clsx('cursor-pointer symbol')}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    // data-kt-menu-placement='bottom-end'
                    data-kt-menu-placement='bottom-start'
                    data-kt-menu-flip='bottom'
                    onClick={toggleGroupMenu}
                    style={{ width: '12.5rem' }}
                  >
                    <label className='filter-btn fs-5 fw-bolder me-2'>
                      Filter by Group
                      <img style={{ marginLeft: '4px' }} src={imagePath.ic_filter_orange} width={14} height={14} />
                    </label>
                  </div>

                  <div
                    // className="group-filter-menu"
                    className={clsx(
                      ' menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 ',
                      {
                        show: isFilterGroupMenuOpen,
                      }
                    )}
                    style={{ maxWidth: '350px' }}
                    // style={{display:'flex', alignItems:'center', overflow:'auto', whiteSpace:'nowrap', flexWrap:'wrap', width:'fit-content'}}
                    data-kt-menu='true'
                  >
                    <div className='px-4' style={{ listStyleType: 'none', cursor: 'pointer', maxHeight: '14rem' }}>
                      {allGroups.map((group, index) => {
                        const isChecked = selectedFilters.some((filter) => filter.type === 'group' && filter.value === group)
                        return (
                          <div
                            className=' hoverable-text mb-4'
                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'flex-start' }}
                            key={index}
                          >
                            <input
                              name='groupFilter'
                              className='form-check-input checkbox-style me-2'
                              type='checkbox'
                              // value={termsChecked}
                              checked={isChecked}
                              onChange={(event) => filterChange(group, 'group', '')}
                            />
                            <label className='form-check-label fs-6 ' style={{ color: 'black' }}>
                              {group}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='fw-bolder fs-5 btn add-position-btn' onClick={downloadExcelReport}>
                Generate Report
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/* show chips of applied filters */}
        <div className='d-flex mb-6 pb-4' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {selectedFilters ? (
            selectedFilters.map((filterData, index) => {
              return (
                <div key={index} className='d-flex orange-tag align-items-center me-4' style={{ height: '34px' }}>
                  {filterData?.type === 'recruiter' ? filterData?.recruiterName : filterData?.value}
                  <img
                    className='ms-2'
                    style={{ cursor: 'pointer' }}
                    src={imagePath.ic_cross_orange}
                    onClick={() => removeFilter(filterData.value, filterData.type)}
                  />
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          {loadingTable && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-borders'>
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  <th
                    onClick={() => sortByField('group_name')}
                    className='d-flex align-items-center ps-2'
                    style={{ whiteSpace: 'nowrap' }}
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Groups</label>
                    {renderSortIcon('group_name')}
                  </th>
                  <th
                    onClick={() => sortByRecruiterName('recruiterData')}
                    className='align-items-center'
                    style={{ whiteSpace: 'nowrap' }}
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Recruiters</label>
                    {renderSortIcon('recruiterData')}
                  </th>
                  <th onClick={() => sortByField('title')} className='align-items-center' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Positions</label>
                    {renderSortIcon('title')}
                  </th>
                  <th
                    onClick={() => sortByField('no_of_positions')}
                    className='align-items-center'
                    style={{ whiteSpace: 'nowrap' }}
                    scope='col'
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>No. of Positions</label>
                    {renderSortIcon('no_of_positions')}
                  </th>
                  <th onClick={() => sortByField('location')} className='align-items-center' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Location</label>
                    {renderSortIcon('location')}
                  </th>
                  <th onClick={() => sortByField('created_at')} className='align-items-center' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Time Period</label>
                    {renderSortIcon('created_at')}
                  </th>
                  <th onClick={() => sortByField('status')} className='align-items-center' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                    {renderSortIcon('status')}
                  </th>
                  {/* <th className=' form-label fw-bolder fs-6' scope='col'>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {(allPositions && allPositions.length > 0) ? (
                  allPositions.map((row, index) => {
                    let recruiter = row?.recruiterData
                    let createdAt = row?.position_created_at
                    createdAt = createdAt?.substring(0, 10)
                    // Convert the string to a Date object
                    const createdAtDate = new Date(createdAt)
                    // Get the current date
                    const currentDate = new Date()
                    // Calculate the difference in milliseconds
                    const timeDifference = currentDate.getTime() - createdAtDate.getTime()
                    // Calculate the number of days
                    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
                    let statusValue = row?.status || 'status'
                    let updatedStatus = statusValue
                      .toLowerCase()
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase())
                    const isEvenRow = index % 2 === 0
                    return (
                      <React.Fragment key={index}>
                        <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                          <td className=' form-label fs-6 ps-2'>{row?.department}</td>
                          <td className=' form-label fs-6'>
                            <div className='d-flex align-items-center'>
                              <img
                                className='me-2'
                                src={recruiter?.profile_pic ? recruiter?.profile_pic : imagePath.userIcon}
                                width={'44px'}
                                height={'44px'}
                                style={{ borderRadius: '22px', objectFit: 'cover', border: '1px solid #F36523' }}
                              />
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label className='form-label'>{`${recruiter?.name}`}</label>
                                <label className='form-label fs-7'>{`POC: ${row?.job_poster}`}</label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h4 className=' form-label fs-6' style={{ maxWidth: '100px' }}>
                                {row?.vacantPosition}
                              </h4>
                            </div>
                          </td>
                          <td className='form-label fs-6 text-center'>{row.no_of_positions ? row.no_of_positions : '1'}</td>
                          <td className='form-label fs-6'>{row.location ? row.location : '-'}</td>
                          <td className='form-label fs-6'>
                            <h4 className=' form-label fs-7'>{`${daysDifference ? `${daysDifference} Days` : daysDifference === 0 ? 'Today' : '-'}`}</h4>
                          </td>
                          <td className={` form-label fs-6 pe-2 `} style={{ whiteSpace: 'nowrap' }}>
                            {updatedStatus}
                          </td>
                          {/* <td className=' form-label fs-6' >
                        <a href='#' className='reallocate-text fw-bolder text-hover-primary fs-6'>
                          + Reallocate
                        </a>
                      </td> */}
                        </tr>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <div>
                    <label className='form-label fw-bolder fs-3'>Vacant Positions Not Available</label>
                  </div>
                )}
              </tbody>
            </table>
            {(allPositions && allPositions.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
              <div className='col-md-4'>
                <label>{`Showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
              </div>
              <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                <CustomPagination
                  // totalPages={Math.ceil(allUsers.length / itemsPerPage)}
                  // pageValue={currentPage}
                  pageValue={params.page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>

              <div className='col-md-4 '>
                <div className='ms-auto d-flex flex-end align-items-center'>
                  <select
                    className='me-2 pagination-input'
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                    id='selectValues'
                    // value={itemsPerPage}
                    value={params.limit}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>

                  <div className='me-2'>
                    <label>Items per page</label>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
      {/* </div> */}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default VacanPositions
