/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import './cards.css'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl, KTSVG} from '../../../helpers'
// import userIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png';
import userIcon from '../../modals/JobFunnelsModels/img/group-9680@3x.png'
import bin from '../images/bin.png'
import hoverbin from '../images/bin-delete.png'
import {useState} from 'react'
import './cards.css'
import cross from '../images/cross.png'
import masesfaf from '../images/mail_black_24dp.png'
import call from '../images/Path 38454.png'
import {number} from 'prop-types'
import imagePath from '../../../../constants/imagePath'
type Props = {
  color?: string
  avatar?: string
  name: string
  employeeId: string
  email: string
  phoneNumber: string
}

const RecruiterCard: FC<Props> = ({color = '', avatar = '', name, employeeId, email, phoneNumber}) => {
  const [overbin, setOverbin] = useState(false)

  return (
    <div>
      <div className='card-header  no-border-class no-border-classno-border-class     pt-9'> </div>
      <div className='cardboyfortherecruiter d-flex flex-center flex-column '>
        <div className='mb-5'>
          <div className='symbol symbol-75px symbol-circle'>
            {color ? (
              <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>{name.charAt(0)}</span>
            ) : (
              <img alt='Pic' style={{objectFit: 'cover'}} src={avatar ? avatar : userIcon} />
            )}
          </div>
        </div>

        <a className='fs-4 text-gray-800text-hover-primaryfw-boldwe mb-0'>{name}</a>

        <a style={{color: '#80808F'}} className='btn fw-bold btn-sm btn-light'>
          Employee ID: {employeeId}
        </a>

        <div className='d-flex flex-wrap mt-3 mb-5'>
          <div className='d-flex rounded align-items-center pt-3 px-2 mx-2 mb-3'>
            <div className='mx-2'>
              <img src={imagePath.ic_mail_orange_fill}></img>
            </div>
            <div style={{color: '#80808F'}} className='fw-bold '>
              {email}
            </div>
          </div>

          <div className='d-flex align-items-center pt-3 px-2 mx-2 mb-2'>
            <div className='mx-2'>
              <img src={imagePath.ic_phone_orange_fill}></img>
            </div>
            <div style={{color: '#80808F'}} className='fw-bold'>
              {phoneNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RecruiterCard}
