//import liraries
import React, { Component, useEffect, useState } from 'react';
import { useAuth } from '../../../auth/core/Auth';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import imagePath from '../../../../../constants/imagePath';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_GRADE_URL = `${API_URL}/configuration/delete-group-head-grade`;
const ADD_GRADE_URL = `${API_URL}/configuration/add-group-head-grade`;
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`;

// create a component
const AllGroupHeadGrades = ({ configurationId, groupHeadGrades, gradesList, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [allGrades, setAllGrades] = useState<any>([]);
    const [allGroupHeadGrades, setAllGroupHeadGrades] = useState<any>([]);
    const [grade, setGrade] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const [items, setItems] = useState<any>([]);
    const handleAddItem = () => {
        if (inputValue.trim()) {
            if (!items.includes(inputValue)) {
                setItems([...items, inputValue]);
                setInputValue('');
            }else{
                setMessage('Title already exists.');
                setShowErrorMessage(true);
            }
        }
    };

    const handleRemoveItem = (index) => {
        setItems(items.filter((_, i) => i !== index));
    };


    useEffect(() => {
        if (groupHeadGrades) {
            groupHeadGrades = JSON.parse(groupHeadGrades);
            const initialData = Object.keys(groupHeadGrades).map((key) => ({
                i: key,
                data: groupHeadGrades[key]
            }));
            setAllGroupHeadGrades(initialData);
        }
        setAllGrades(gradesList)
        // getGrades();
    }, [groupHeadGrades, gradesList]);

    useEffect(() => {
        (async () => {
            if (gradesList === null || gradesList === undefined || gradesList.length === 0) {
                await getGrades();
            }
        })();
    }, [])

    const getGrades = async () => {
        await axios.get(GET_GRADES_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data?.job_grade;
            if (data) {
                data = JSON.parse(data);
                if (data) {
                    setAllGrades(Object.values(data));
                }
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })
    }

    const handleAddGroupHeadGrade = async (event) => {
        event.preventDefault();
        const value = event.target.value;
        // Check if the value already exists in the list
        const isDuplicate = allGroupHeadGrades.some((item) => item.data === value);

        if (!isDuplicate) {


            setGrade(value);

            // Find the largest "i" value from the list
            const largestI = allGroupHeadGrades.reduce((max, item) => {
                const currentItemI = parseInt(item.i, 10);
                return currentItemI > max ? currentItemI : max;
            }, -1); // Initialize with -1 in case the list is empty

            // Increment the largest "i" value by 1
            const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

            // Create a new object with the incremented "i" value
            const newProgram = {
                "i": newI,
                "data": value // Set the initial value as desired
            };

            let data = [...allGroupHeadGrades];
            data.push(newProgram);
            setAllGroupHeadGrades(data);
            setGrade('');
        } else {
            window.alert('Grade already exists in the list.');
        }
    }

    const handleRemoveGroupHeadGrade = async (index) => {

        // const updatedList = [...allGroupHeadGrades];
        // updatedList.splice(index, 1);
        // setAllGroupHeadGrades(updatedList);

        if (index >= 0 && index < allGroupHeadGrades.length) {
            const key = allGroupHeadGrades[index].i;
            setDisabled(true);
            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_GRADE_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                setDisabled(false);
                const updatedList = [...allGroupHeadGrades];
                updatedList.splice(index, 1);
                setAllGroupHeadGrades(updatedList);
                setMessage('Group head grade removed successfully');
                setShowSuccessPopup(true);
            }).catch((error) => {
                setDisabled(false);
                displayError(error);
            })

        }
    }

    const handleSaveClick = async (event) => {
        event.preventDefault();
        if (!allGroupHeadGrades || allGroupHeadGrades.length === 0) {
            setMessage('Please add group head grade first and try again.');
            setShowErrorMessage(true);
            return;
        }
        if (!configurationId) {
            setMessage('Configuration id not found, Please refresh and try again.');
            setShowErrorMessage(true);
            return;
        }
        const data = {
            group_head_grades: {},
            configuration_id: configurationId,
        };
        // Convert the array to an object with keys as "i" and values as "data"
        allGroupHeadGrades.forEach((item) => {
            data.group_head_grades[item.i] = item.data;
        });

        console.log('allGroupHeadGrades: ', data);
        setDisabled(true);
        await axios.post(ADD_GRADE_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            // fetch updated data
            // getConfigurations();
            setDisabled(false);
            setMessage('Group head grade added successfully');
            setShowSuccessPopup(true);
        }).catch((error) => {
            setDisabled(false);
            displayError(error);
        });

    }

    const displayError = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message);
            setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
            logout();
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout();
            } else {
                setMessage(error.response.data.error.message);
                setShowErrorMessage(true);
            }
        } else {
            setMessage(error.message);
            setShowErrorMessage(true);
        }
    }

    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative' style={{ minHeight: '26rem' }}>
            <div className='d-flex align-items-center px-8'>
                <label className='form-label fs-4 fw-bolder card-heading'>Select Group Head Grades</label>
            </div>

            <div className='mb-4 col-md-6'>
                <select
                    name='status'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select '
                    defaultValue='Select an option'
                    placeholder='Select an option'
                    value={grade}
                    required
                    onChange={(event) => handleAddGroupHeadGrade(event)}

                >
                    <option value='Select an option'>Select grades</option>
                    {allGrades ? allGrades.map((grade) => (
                        <option key={grade} value={grade}>
                            {grade}
                        </option>
                    )) : (<div></div>)}
                </select>
            </div>
            <div className='mx-8' style={{ height: '12rem', overflow: 'auto' }}>
                {allGroupHeadGrades.map((item, index) => (
                    <div key={index} className="badge orange-tag me-4 mb-2" style={{ position: 'relative', zIndex: '1' }}>
                        {item?.data}
                        <img className='ms-4' style={{ cursor: 'pointer' }} src={imagePath.ic_cross_orange}
                            onClick={() => {
                                if (!disabled) {
                                    handleRemoveGroupHeadGrade(index)
                                }
                            }}
                        />
                    </div>
                ))}
            </div>

            {/* <div className='d-flex align-items-center px-8'>
                <label className='form-label fs-4 fw-bolder card-heading'>Approval Authorities Title</label>
            </div>
            <div className='mx-8' style={{ zIndex: '2' }}>
                <div className='d-flex align-items-center mb-4'>
                    <input
                        className='form-control py-2 mw-50 me-4'
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        style={{ marginRight: '10px', padding: '5px', flex: 1 }}
                    />
                    <button onClick={handleAddItem} className='candi-btn2 btn text-white fw-bolder px-6 py-2'>
                        Add
                    </button>
                </div>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className="badge orange-tag me-4 mb-2" style={{ position: 'relative', zIndex: '1' }}
                        >
                            <span>{item}</span>
                            <img className='ms-2' style={{ cursor: 'pointer', marginLeft: '4px' }} src={imagePath.ic_cross_orange}
                                onClick={() => {
                                    handleRemoveItem(index)
                                }}
                            />

                        </div>
                    ))}
                </div>
            </div> */}

            <div className='d-flex justify-content-center'>
                {disabled ? (
                    <FontAwesomeIcon
                        icon={faSpinner}
                        color='#F36523'
                        spin
                        size='3x'
                    />) :
                    (<div
                        className='candi-btn2 text-white px-10 m-5'

                        onClick={handleSaveClick}
                    // disabled={disabled}
                    >
                        <span style={{ fontWeight: 'bold' }}>Save</span>
                    </div>)}
            </div>

            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_group_head} style={{ width: '220px', height: '220px', opacity: '0.08', zIndex: '-1' }} />
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

        </div>
    );
};

//make this component available to the app
export default AllGroupHeadGrades;
