import React, { useState, useEffect, useRef } from 'react'
import BOPLOGO from '../img/BOPLOGO.png'
import queryString from 'query-string'
import './RequestforApprovals.css'
import { useAuth } from '../../../../app/modules/auth'
import axios from 'axios'
import userIcon from '../img/Group 9681.png'
import ExperienceIcon from '../img/experience@2x copy.png'
import UniversityIcon from '../img/mortarboard@2x.png'
import { borderRadius } from '@mui/system'
import DownLoadIcon from '../img/Component 237 – 1.png'
import SalaryIcon from '../../../../_metronic/partials/modals/JobFunnelsModels/img/salary@2x.png'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import RefreshIcon from '../img/history_black_24dp@2x.png'
import { useNavigate } from 'react-router-dom'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
export const GET_APPROVE_DATA = `${API_URL}/panel/get-approve-data`

export const APPROVE_CANDIDATE = `${API_URL}/panel/approve-candidate`

export const REJECT_CANDIDATE = `${API_URL}/panel/panel-rejection`

export const PANEL_OBJECTION = `${API_URL}/panel/panel-objection`

export const GET_PANEL_OBJECTION = `${API_URL}/jobapplication/get-filter-objection-data`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

type ObjectionNote = {
  name: string
  profile: string
  member: {
    time: string
    message: string
  }
}

const RequestforApprovalsExternal = () => {
  const { auth, logout } = useAuth()
  const navigate = useNavigate()
  const [userData, setUserData] = useState<any>([])
  const [candData, setCandData] = useState<any>([])
  const [memberData, setMemberData] = useState<any>([])
  const [interviewData, setInterviewData] = useState<any>([])
  const [jobData, setJobData] = useState<any>([])
  const [batchPositions, setBatchPositions] = useState<any>()
  const [batchId, setBatchId] = useState('')
  const [jobDataPay, setJobDataPay] = useState<any>([])
  const [text, setText] = useState<any>('')
  const [displayedTexts, setDisplayedTexts] = useState<any>([])
  const [isApproving, setIsApproving] = useState(true)
  const [isObjectionSubmitted, setIsObjectionSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState<any>({
    basicPay: '',
    carAllowance: '',
    fuel: '',
    monthlyPackage: '',
    percentIncrease: '',
    allowanceType: '',
  })
  const [percentIncrease, setPercentIncrease] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [isEditable, setIsEditable] = useState(false)
  const [carAllowanceChecked, setCarAllowanceChecked] = useState(false)
  const [fuelChecked, setFuelChecked] = useState(false)
  const [imageURL, setImageURL] = useState<any>('')
  const [dataFetched, setDataFetched] = useState(false)
  let values
  const [jobappid, setjobappid] = useState<any>('')
  const [memberid, setmemberid] = useState<any>('')
  const [approverValue, setApproverValue] = useState('');
  const [objectionNotes, setObjectionNotes] = useState<ObjectionNote[]>([])
  const [currentUser, setCurrentUser] = useState<any>([])
  const [showSection, setShowSection] = useState<any>(false)
  const [interviewStageOne, setInterviewStageOne] = useState<any>([])
  const [interviewStageTwo, setInterviewStageTwo] = useState<any>([])
  const [interviewStageThree, setInterviewStageThree] = useState<any>([])
  const [institution, setinstitution] = useState<any>('')
  const [program, setProgram] = useState<any>('')
  const [isLoading, setIsLoading] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState('')
  const [load, setLoad] = useState(false)
  const stage = jobData?.stage || null
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [configurationId, setConfigurationId] = useState<any>('');
  const [benefits, setBenefits] = useState<any>([])
  const [fetchingBenefits, setFetchingBenefits] = useState(false);

  const getConfigurations = async () => {
    setFetchingBenefits(true);
    await axios.get(GET_CONFIGURATIONS_URL
      //   , {
      //   headers: {
      //     Authorization: `Bearer ${auth?.token}`,
      //     'Content-Type': 'application/json',
      //   },
      // }
    ).then((res) => {
      setFetchingBenefits(false);
      let data = res?.data?.Configuration;
      setConfigurationId(data?.configuration_id);
      let benefits = data?.benefits;
      if (benefits !== null && benefits !== undefined) {
        benefits = JSON.parse(benefits);
        const initialData = Object.keys(benefits).map((key) => ({
          grade: benefits[key]['name'],
          file: null,
          fileUrl: benefits[key]['link']
        }));
        setBenefits(initialData);
      }

    }).catch((error) => {
      setFetchingBenefits(false);
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })

    // Calculate the percentage increase
    if (name === 'basicPay') {
      let prevPay = parseInt(jobData?.previous_job_pay?.replace(',', ''), 10) || 0

      let basicPay = parseInt(value, 10) || 0

      let percent: any = ((basicPay - prevPay) / basicPay) * 100
      setPercentIncrease(percent.toFixed(1))
    }
    // Update the total amount
  }
  const handleInputClick = () => {
    setIsEditable(true)
  }

  useEffect(() => {
    // if (auth?.token) {
    (() => {
      getConfigurations();
    })()
    if (!dataFetched) {
      values = queryString.parse(window.location.search)
      setjobappid(values.jobapp_id)
      setmemberid(values.member_id)
      setApproverValue(values.approver)
      fetch(`${API_URL}/panel/get-verification-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobapp_id: values.jobapp_id,
          name: values.name,
          verification_code: values.Vcode,
          member_id: values.member_id,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("Success:", data);
        })
      if (values && jobappid && memberid) {
        GETAPPROVADATA()
        // GETPANELOBJECTIONS();
      }
      // setDataFetched(true);
    }
    // } else {
    //   navigate('/');
    // }
  }, [dataFetched, values, jobappid, memberid])

  const GETAPPROVADATA = async () => {
    setLoad(true)

    try {
      const res = await axios.post(GET_APPROVE_DATA, {
        jobapp_id: values?.jobapp_id,
        name: 'approvals',
        member_id: memberid,
      })

      console.log({ res })
      setUserData(res?.data?.user)
      setCandData(res?.data?.candidate)
      setMemberData(res?.data?.members)
      setInterviewData(res?.data?.jobApplication)
      setJobData(res?.data?.job)
      setBatchId(res?.data?.job?.position_id)
      setBatchPositions(res?.data?.batchPositions)
      setJobDataPay(JSON.parse(res?.data?.jobApplication?.basic_pay_calculation))
      setImageURL(res?.data?.candidate?.cv_url)
      setCurrentUser(res?.data?.currentMember)
      setInterviewStageOne(res?.data?.memberStage1)
      setInterviewStageTwo(res?.data?.memberStage2)
      setInterviewStageThree(res?.data?.memberStage3)
      let notes = res?.data?.objection_notes
      if (notes) {
        const data = JSON.parse(notes)
        setObjectionNotes(data)
      }
    } catch (error) {
      // Handle the error here
      console.error(error)
    } finally {
      setLoad(false)
    }
  }
  console.log({ jobDataPay })
  console.log({ interviewStageTwo })
  console.log({ interviewStageThree })

  const GETPANELOBJECTIONS = async () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)

    try {
      const response = await axios.post(GET_PANEL_OBJECTION, {
        jobapp_id: jobappid,
        jobapp_stage: 'approvals',
        member_id: null,
      })
      console.log(response)
      setObjectionNotes(response?.data?.objection_notes)
    } catch (error) {
      console.error(error)
      // Handle the error appropriately, e.g., show an error message
    }
  }

  const handleRefreshClick = () => {
    GETPANELOBJECTIONS()
  }

  const APPROVECANDIDATE = async () => {
    await axios
      .get(APPROVE_CANDIDATE, {
        params: {
          jobapp_id: jobappid,
          member_id: memberid,
          name: 'approvals',
          approver: approverValue
        },
        //   headers: {
        //     Authorization: `Bearer ${auth?.token}`,
        //     'Content-Type': 'application/json',
        //   },
      })
      .then((res) => {
        console.log({ res })
        closeCurrentPage()
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const closeCurrentPage = () => {
    // Wait for 3 seconds and close the current page
    setTimeout(() => {
      console.log('close current tab')
      // window.close();
      navigate('/')
    }, 3000)
  }

  const REJECTCANDIDATE = async () => {
    await axios
      .post(
        REJECT_CANDIDATE,
        {
          jobapp_id: jobappid,
          name: 'approvals',
          member_id: memberid,
        }
        // {
        //   headers: {
        //     Authorization: `Bearer ${auth?.token}`,
        //     'Content-Type': 'application/json',
        //   },
        // }
      )
      .then((res) => {
        console.log({ res })
      })
  }

  console.log(currentUser)

  const handleSubmitApprove = () => {
    APPROVECANDIDATE()
    setIsApproving(false)
    setIsLoading(true)

    setApprovalStatus('') // Reset the approval status

    setTimeout(() => {
      setIsLoading(false) // Hide the loading spinner after 3 seconds
      setApprovalStatus('Candidate Approved') // Update the approval status
    }, 3000)
  }

  const handleSubmitObjection = () => {
    setShowSection(true)
  }

  useEffect(() => {
    if (candData && candData.qualification) {
      const qualifications = JSON.parse(candData?.qualification)
      let lastQualification = qualifications[0]?.qualification1
      setinstitution(lastQualification?.institution)
      // setProgram(lastQualification?.education_level)
      setProgram(lastQualification?.education_level + ': ' + lastQualification?.program)
    }
  }, [candData])

  const handleDownload = (url) => {
    console.log('resumeUrl: ', url)
    if (url) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = 'Resume'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        })
    } else {
      window.alert('URL is not valid or resume not available!')
    }
  }

  const handleDownloadBenefitsPdf = (e) => {
    e.preventDefault();
    if (benefits !== null && benefits !== undefined && benefits.length > 0) {
      if (jobData?.grade !== null && jobData?.grade !== undefined && jobData?.grade !== '') {
        // Find the object with the specified grade
        let benefit = benefits.find(item => item.grade === jobData?.grade);
        // Extract the fileUrl value if the grade is found
        let fileUrl = benefit ? benefit.fileUrl : null;
        if (fileUrl) {
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank'; // Open the PDF in a new tab
          link.setAttribute('download', ''); // Set the download attribute to force download
          document.body.appendChild(link);
          link.click(); // Click the anchor element to initiate download
          document.body.removeChild(link); // Remove the anchor element from the document
        } else {
          setMessage('Benefits sheet not available contact admin to add Benefits sheet. ');
          setShowErrorMessage(true);
        }
      } else {
        setMessage('Grade value not found, Please try again.');
        setShowErrorMessage(true);
      }

    } else {
      setMessage('List of benefits sheets not found, Please Try again.');
      setShowErrorMessage(true);
    }

  }

  const handleDownloadClick = () => {
    const input = document.getElementById('root')!
    const images = input.getElementsByTagName('img')

    Promise.all(
      Array.from(images).map((img) => {
        return new Promise<void>((resolve, reject) => {
          if (img.complete) {
            resolve()
          } else {
            img.onload = () => resolve()
            img.onerror = reject
          }
        })
      })
    )
      .then(() => {
        html2canvas(input, {
          useCORS: true,
          windowHeight: 1300, // Set the canvas height to 1300
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'px', [canvas.width, canvas.height], true)
          pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height)
          pdf.save('Office Note.pdf')
        })
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleInputChangeObjection = (event) => {
    setText(event.target.value)
  }

  const handleSendButtonClick = async () => {
    console.log({ text })
    setDisplayedTexts([
      <div key={memberid} className='request-for-approvals-external-header-objection-chat-txt'>
        <span className='request-for-approvals-external-header-objection-msgs-cont'>{text}</span>
      </div>,
      ...displayedTexts,
    ])
    setText('')
    await OBJECTIONAPI()
    await GETPANELOBJECTIONS()
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendButtonClick()
    }
  }

  const OBJECTIONAPI = async () => {
    GETPANELOBJECTIONS()
    let objectionTime = new Date().toISOString()
    await axios
      .post(PANEL_OBJECTION, {
        member_id: memberid,
        name: 'approvals',
        jobapp_id: jobappid,
        message: text,
        objection_record: {
          time_of_objection: objectionTime,
        },
      })
      .then((res) => {
        console.log({ res })
      })
  }

  // useEffect(() => {
  //   if (values && jobappid && memberid) {
  //     GETPANELOBJECTIONS();
  //   }
  //   const interval = setInterval(() => {
  //     if (values && jobappid && memberid) {
  //       GETPANELOBJECTIONS();
  //     }
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [values, jobappid, memberid]);

  useEffect(() => {
    if (values && jobappid && memberid) {
      GETPANELOBJECTIONS()
    }
  }, [values, jobappid, memberid])

  useEffect(() => {
    const basicPay = parseFloat(formValues.basicPay) || 0
    const carAllowance = parseFloat(formValues.carAllowance) || 0
    const fuel = parseFloat(formValues.fuel) || 0
    const total = basicPay + carAllowance + fuel
    setTotalAmount(total)
  }, [formValues])

  const DisplayPreviousJobPay = () => {
    const previousJobPayItem = jobDataPay.find((item) => item.name === 'Previous Job Pay')

    return <p className='request-approval-posting-resp'>Rs. {previousJobPayItem?.value?.toLocaleString()}</p>
  }

  const DisplayBasicPay = () => {
    const basicPayItem = jobDataPay.find((item) => item.name === 'Basic Pay')

    return <p className='request-approval-posting-resp'>Rs. {basicPayItem?.value?.toLocaleString()}</p>
  }

  const DisplayCarAllowance = () => {
    const carAllowanceItem = jobDataPay.find((item) => item.name === 'Car Allowance')

    return <p className='request-approval-posting-resp'>Rs. {carAllowanceItem?.value?.toLocaleString()}</p>
  }

  const DisplayFuelAllowance = () => {
    const fuelAllowanceItem = jobDataPay.find((item) => item.name === 'fuel')

    return <p className='request-approval-posting-resp'>Rs. {fuelAllowanceItem?.value?.toLocaleString()}</p>
  }

  const DisplayBonusAllowance = () => {
    const bonusAllowanceItem = jobDataPay.find((item) => item.name === 'bonus')
    console.log('bonusAllowanceItem: ', bonusAllowanceItem);
    return <p className='request-approval-posting-resp'>Rs. {bonusAllowanceItem?.value?.toLocaleString() || 0}</p>
  }

  const DisplayGrossPay = () => {
    const grossPayItem = jobDataPay.find((item) => item.name === 'Gross Pay')

    return <p className='request-approval-posting-resp'>Rs. {grossPayItem?.value?.toLocaleString()}</p>
  }

  const DisplayPercentIncrease = () => {
    const percentIncreaseItem = jobDataPay.find((item) => item.name === 'Percent Increase')

    return <p className='request-approval-posting-resp'>{percentIncreaseItem?.value} %</p>
  }
  const DisplayHouseRentAllowance = () => {
    const houseRentItem = jobDataPay.find((item) => item.name === 'House Rent Allowance')
    return <label style={{ marginLeft: '1rem' }}>{`Rs. ${houseRentItem?.value?.toLocaleString()}`}</label>
  }
  const DisplayMedicalAllowance = () => {
    const medicalAllowanceItem = jobDataPay.find((item) => item.name === 'Medical Allowance')
    return <label style={{ marginLeft: '1rem' }}>{`Rs. ${medicalAllowanceItem?.value?.toLocaleString()}`}</label>
  }
  const DisplayUtilitiesAllowance = () => {
    const utilitiesAllowanceItem = jobDataPay.find((item) => item.name === 'Utilities Allowance')
    return <label style={{ marginLeft: '1rem' }}>{`Rs. ${utilitiesAllowanceItem?.value?.toLocaleString()}`}</label>
  }
  const DisplayConveyanceAllowance = () => {
    const conveyanceAllowanceItem = jobDataPay.find((item) => item.name === 'Conveyance Allowance')
    return <label style={{ marginLeft: '1rem' }}>{`Rs. ${conveyanceAllowanceItem?.value?.toLocaleString()}`}</label>
  }
  const DisplayAdditionalAllowance = () => {
    const additionalAllowanceItem = jobDataPay.find((item) => item.name === 'Additional Allowance')
    return <label style={{ marginLeft: '1rem' }}>{`Rs. ${additionalAllowanceItem?.value?.toLocaleString()}`}</label>
  }
  const DisplayTotalSalary = () => {
    const salaryItem = jobDataPay.find((item) => item.name === 'Total Salary')
    return (
      <div className='offer-letter-lower-bar'>
        <img src={SalaryIcon} style={{ height: '4vh' }} />
        <label>Salary Per Month: {`${salaryItem?.value?.toLocaleString()}`}</label>
      </div>
    )
  }

  const getLocationValue = () => {
    let location = ''
    if (batchId) {
      const matchingPosition = batchPositions.find((position) => position.position_id === batchId)
      if (matchingPosition) {
        location = matchingPosition.location
      }
    }
    console.log('foundLocation: ', location)
    return location
  }

  return (
    <>
      {load ? (
        <div className='text-center'>
          <FontAwesomeIcon
            icon={faSpinner}
            color='#F36523'
            spin
            size='5x'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '45%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      ) : (
        <div style={{ marginLeft: '4rem', marginRight: '4rem' }}>
          <div className='request-for-approvals-external-upper-row'>
            <hr className='request-for-approvals-external-upper-row-first-child' />
            <img src={BOPLOGO} style={{ height: '7vh', marginLeft: '5px', marginRight: '5px' }} />
            <hr className='request-for-approvals-external-upper-row-first-child' />
          </div>

          <div className='request-for-approvals-external-header-txt'>
            <label>Request for Approval</label>
          </div>

          <div>
            <div className='mx-auto d-flex flex-column '>
              <div className='profile-Container-request-approval-external-doc'>
                <div className='left-container-request-approval-external-doc'>
                  <div>
                    <img
                      src={candData?.profile_pic}
                      style={{ objectFit: 'cover', height: '64px', width: '64px' }}
                      className='profile-user-icon-request-approval-external-doc'
                    />
                  </div>
                  <div className='request-approval-external-doc-middle-container-user-details'>
                    <label className='request-approval-external-doc-middle-container-username'>{userData?.first_name}</label>
                    <div className='request-approval-external-doc-middle-container-role'>
                      <label>Position: {jobData?.title}</label>
                    </div>
                  </div>
                </div>

                <div className='request-approval-external-second-cont'>
                  <div>
                    <img src={UniversityIcon} style={{ height: '4vh' }} />
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <label className='request-approval-external-p'>{program}</label>
                    <label className='request-approval-external-label'>{institution}</label>
                  </div>
                </div>

                <div className='request-approval-last-cont'>
                  <div>
                    <img src={ExperienceIcon} style={{ height: '4vh' }} />
                  </div>
                  <div className='request-approval-external-banking'>
                    <label className='request-approval-external-label'>Banking Experience: </label>
                    <label>
                      {' '}
                      <p className='request-approval-external-p' style={{ margin: '0' }}>
                        {candData?.banking_experience}yrs
                      </p>
                    </label>
                  </div>
                  <div className='request-approval-external-banking'>
                    <label className='request-approval-external-label'>Total Experience: </label>
                    <p className='request-approval-external-p' style={{ margin: '0' }}>
                      {parseInt(candData?.banking_experience) + parseInt(candData?.job_experience)}yrs
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginLeft: '8rem',
              marginRight: '4rem',
              marginTop: '3rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <label className='model-chat-p'>Select Group Heads in sequence for Approvals</label>
            <div className='model-panel-members'>
              {memberData?.map((item, index) => {
                return (
                  <div key={index} className='model-panel-tile'>
                    <label>{index + 1}.</label>
                    <label>
                      <div>
                        {item.profilePic && (
                          <div>
                            <img
                              src={item.profilePic}
                              style={{ border: '1px solid #F36523', borderRadius: '50%', height: '3vh', width: '3vh', objectFit: 'cover' }}
                            />
                          </div>
                        )}
                        {!item.profilePic && (
                          <div>
                            <img
                              src={userIcon}
                              style={{ border: '1px solid #F36523', borderRadius: '50%', height: '3vh', width: '3vh', objectFit: 'cover' }}
                            />
                          </div>
                        )}
                      </div>
                    </label>
                    <label>{item['userName']}</label>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='request-approval-external-second-row'>
            <div className='request-approval-external-posting-details'>
              <div>
                <p className='request-approval-external-posting-txt'>Test Score</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p className='request-approval-external-posting-txt'>Interview Stages</p>
                <div className='request-approval-external-stage-txt'>
                  {stage === '3' && (
                    <div className='request-approval-external-stage-txt'>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 1</label>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 2</label>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 3</label>
                    </div>
                  )}
                  {stage === '2' && (
                    <div className='request-approval-external-stage-txt'>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 1</label>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 2</label>
                    </div>
                  )}
                  {stage === '1' && (
                    <div className='request-approval-external-stage-txt'>
                      <label style={{ whiteSpace: 'nowrap' }}>Panel 1</label>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className='request-approval-external-posting-txt'>Interview Score</p>
              </div>
            </div>

            <div className='request-approval-external-posting-details-resp'>
              <div>
                <p className='request-approval-external-score-cont'>{interviewData?.test_aggregated_result}%</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '6rem',
                  marginLeft: '5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {interviewStageOne && interviewStageOne.length > 0 && (
                  <div className='request-approval-external-interviewers'>
                    {interviewStageOne.map((item) => (
                      <div
                        key={item?.panel_id}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <div>
                          {item.profilePic ? (
                            <div>
                              <img
                                src={item.profilePic}
                                style={{
                                  border: '1px solid #F36523',
                                  borderRadius: '50%',
                                  height: '3vh',
                                  width: '3vh',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={userIcon}
                                style={{
                                  border: '1px solid #F36523',
                                  borderRadius: '50%',
                                  height: '3vh',
                                  width: '3vh',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <p
                            className='request-approval-external-interviewers-name'
                            style={{ whiteSpace: 'nowrap', padding: '0', margin: '0' }}
                          >
                            {item?.userName}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {interviewStageTwo && interviewStageTwo.length > 0 && (
                  <div className='request-approval-external-interviewers'>
                    {interviewStageTwo.map((item) => (
                      <div
                        key={item?.panel_id}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                      >
                        <div>
                          {item.profilePic ? (
                            <div>
                              <img
                                src={item.profilePic}
                                style={{
                                  border: '1px solid #F36523',
                                  borderRadius: '50%',
                                  height: '3vh',
                                  width: '3vh',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                src={userIcon}
                                style={{
                                  border: '1px solid #F36523',
                                  borderRadius: '50%',
                                  height: '3vh',
                                  width: '3vh',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          <p
                            className='request-approval-external-interviewers-name'
                            style={{ whiteSpace: 'nowrap', padding: '0', margin: '0' }}
                          >
                            {item?.userName}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className='request-approval-external-interviewers'>
                  {interviewStageThree && interviewStageThree.length > 0 && (
                    <div className='request-approval-external-interviewers'>
                      {interviewStageThree.map((item) => (
                        <div
                          key={item?.panel_id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            marginBottom: '1rem',
                          }}
                        >
                          <div>
                            {item.profilePic ? (
                              <div>
                                <img
                                  src={item.profilePic}
                                  style={{
                                    border: '1px solid #F36523',
                                    borderRadius: '50%',
                                    height: '3vh',
                                    width: '3vh',
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={userIcon}
                                  style={{
                                    border: '1px solid #F36523',
                                    borderRadius: '50%',
                                    height: '3vh',
                                    width: '3vh',
                                    objectFit: 'cover',
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div>
                            <p
                              className='request-approval-external-interviewers-name'
                              style={{ whiteSpace: 'nowrap', padding: '0', margin: '0' }}
                            >
                              {item?.userName}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p className='request-approval-external-score-cont'>
                  {(
                    (parseInt(interviewData?.interview_stage1_score || 0) +
                      parseInt(interviewData?.interview_stage2_score || 0) +
                      parseInt(interviewData?.interview_stage3_score || 0)) /
                    JSON.parse(jobData?.stage || 1)
                  ).toFixed(2)}
                  %
                </p>
              </div>
            </div>
          </div>

          <div className='request-approval-external-dashed-line'></div>

          <div style={{ marginLeft: '7rem', marginRight: '7rem', marginTop: '4rem', marginBottom: '4rem' }}>
            <div className='request-for-approvals-external-header-hiring-txt'>
              <label>Hiring</label>
            </div>

            <div className='request-approval-external-posting-details-hiring'>
              <div>
                <p className='request-approval-external-posting-txt'>Initial Place of Posting</p>
              </div>
              <div>
                <p className='request-approval-external-posting-txt'>Candidate Preferred Location</p>
              </div>
              <div>
                <p className='request-approval-external-posting-txt'>Assignment</p>
              </div>
              <div>
                <p className='request-approval-external-posting-txt'>Grade</p>
              </div>
            </div>

            <div className='request-approval-external-hire'>
              <div>
                <p className='request-approval-external-hire-txt'>
                  {/* {jobData?.location} */}
                  {`${batchPositions ? getLocationValue() : jobData?.location}`}
                </p>
              </div>
              <div>
                <p className='request-approval-external-hire-txt'>{candData?.city_preference}</p>
              </div>
              <div>
                <p className='request-approval-external-hire-txt'>{jobData?.title}</p>
              </div>
              <div>
                <p className='request-approval-external-hire-txt'>{jobData?.grade}</p>
              </div>
            </div>
          </div>

          <div className='request-approval-external-dashed-line'></div>

          <div style={{ marginLeft: '7rem', marginRight: '7rem', marginTop: '4rem', marginBottom: '4rem' }}>
            <div className='request-approval-salary-cont'>
              <label>Salary and Benefits</label>
              <label className='request-approval-salary-text'>
                Salary Range Rs. {jobData?.salary_range_from} - Rs. {jobData?.salary_range_till}
              </label>
            </div>

            <div className='request-approval-external-salary-details'>
              <div style={{ width: '8rem' }} className='request-approval-external-posting-txt-radios'>
                <p className='request-approval-external-posting-txt'>Previous Pay</p>
              </div>
              <div style={{ width: '6rem' }} className='request-approval-external-posting-txt-radios'>
                <p className='request-approval-external-posting-txt'>Basic Pay</p>
              </div>

              <div style={{ width: '12rem' }} className='request-approval-external-posting-txt-radios'>
                <label htmlFor='allowanceTypeCar'>Car Allowance</label>
              </div>

              <div style={{ width: '6rem' }} className='request-approval-external-posting-txt-radios'>
                <label htmlFor='allowanceTypeFuel'>Fuel</label>
              </div>

              <div style={{ width: '6rem' }} className='request-approval-external-posting-txt-radios'>
                <label htmlFor='allowanceTypeFuel'>Bonus</label>
              </div>

              <div style={{ width: '8rem' }}>
                <p className='request-approval-external-posting-txt'>Monthly Package including Guaranteed Bonuses</p>
              </div>
              <div style={{ width: '8rem' }}>
                <p className='request-approval-external-posting-txt'>% Increase</p>
              </div>
            </div>

            <div className='request-approval-external-salary'>
              <div style={{ width: '8rem' }}>
                {/* <p className='request-approval-posting-resp'>
                  Rs. {jobDataPay?.previous_job_pay}
                </p> */}
                <DisplayPreviousJobPay />
              </div>

              <div style={{ width: '6rem' }}>
                {/* <p className='request-approval-posting-resp'>
                  Rs. {jobDataPay?.BasicPay}
                </p> */}
                <DisplayBasicPay />
              </div>

              <div style={{ width: '12rem' }}>
                {/* {jobDataPay?.carAllowance ? (
                  <p className='request-approval-posting-resp'>Rs. {jobDataPay.carAllowance}</p>
                ) : (
                  <p className='request-approval-posting-resp' style={{ marginLeft: '5px' }}>-</p>
                )} */}
                <DisplayCarAllowance />
              </div>

              <div style={{ width: '6rem' }}>
                {/* {jobDataPay?.fuel ? (
                  <p className='request-approval-posting-resp'>Rs. {jobDataPay.fuel}</p>
                ) : (
                  <p className='request-approval-posting-resp' style={{ marginLeft: '5px' }}>-</p>
                )} */}
                <DisplayFuelAllowance />
              </div>

              <div style={{ width: '6rem' }}>
                {/* {jobDataPay?.fuel ? (
                  <p className='request-approval-posting-resp'>Rs. {jobDataPay.fuel}</p>
                ) : (
                  <p className='request-approval-posting-resp' style={{ marginLeft: '5px' }}>-</p>
                )} */}
                <DisplayBonusAllowance />
              </div>

              <div style={{ width: '8rem' }}>
                {/* <p className='request-approval-posting-resp'>
                  Rs. {JSON.parse(jobDataPay?.BasicPay || 0) + JSON.parse(jobDataPay?.carAllowance || 0) + JSON.parse(jobDataPay?.fuel || 0)}
                </p> */}
                <DisplayGrossPay />
              </div>

              <div style={{ width: '8rem' }}>
                {/* <p className='request-approval-posting-resp'>
                  {jobDataPay?.percentIncrease}%
                </p> */}
                <DisplayPercentIncrease />
              </div>
            </div>
          </div>

          <div style={{ marginLeft: '7rem', marginRight: '7rem' }}>
            <div className='offer-letter-salary-cont'>
              <label>Allowance</label>
            </div>

            <div>
              <div className='mt-6'>
                <label className='col-md-3'>House Rent Allowance</label>
                <DisplayHouseRentAllowance />
              </div>
              <div className='my-4'>
                <label className='col-md-3'>Medical Allowance</label>
                <DisplayMedicalAllowance />
              </div>
              <div>
                <label className='col-md-3'>Utilities Allowance</label>
                <DisplayUtilitiesAllowance />
              </div>
              <div className='my-4'>
                <label className='col-md-3'>Conveyance Allowance</label>
                <DisplayConveyanceAllowance />
              </div>
              <div>
                <label className='col-md-3'>Additional Benefits</label>
                <DisplayAdditionalAllowance />
              </div>
            </div>

            <DisplayTotalSalary />
            <div className='d-flex align-items-center'>
              <label className='fw-bolder fs-4 col-md-3'>Benefits & Incentives</label>
              {fetchingBenefits ? (
                <div className='d-flex align-items-center'>
                  <FontAwesomeIcon className='me-4' icon={faSpinner} spin color='#F36523' size='2x' />
                  <label>Fetching Benefits info...</label>

                </div>
              ) : (<div className='request-for-approvals-external-files-text'>
                <div>
                  <button onClick={handleDownloadBenefitsPdf} style={{ border: 'none', backgroundColor: 'transparent' }}>
                    <img src={DownLoadIcon} />
                  </button>
                </div>
                <div>
                  <label>Benefits & Incentives.pdf</label>
                </div>
              </div>)}
            </div>
          </div>
          <div className='request-approval-external-dashed-line'></div>
          <div
            className='request-for-approvals-external-end-cont'
            style={{ marginLeft: '7rem', marginRight: '7rem', marginTop: '4rem', marginBottom: '4rem' }}
          >
            <div className='request-for-approvals-external-header-hiring-txt'>
              <label>Candidate Information</label>
            </div>

            <div className='request-for-approvals-external-files-text'>
              <div>
                <button onClick={handleDownloadClick} style={{ border: 'none', backgroundColor: 'transparent' }}>
                  <img src={DownLoadIcon} />
                </button>
              </div>
              <div>
                <label>Office Note.pdf</label>
              </div>
            </div>

            {imageURL && (
              <div className='request-for-approvals-external-files'>
                <div>
                  <button onClick={() => handleDownload(imageURL)} style={{ border: 'none', backgroundColor: 'transparent' }}>
                    <img src={DownLoadIcon} />
                  </button>
                </div>
                <div>
                  <label>Resume.pdf</label>
                </div>
              </div>
            )}
          </div>

          <div className='request-for-approvals-external-files-btn-cont'>
            <div className='request-for-approvals-external-files-reject-btn-cont'>
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='2x' />
              ) : (
                approvalStatus !== 'Candidate Approved' && (
                  <button className='request-for-approvals-external-files-reject-btn' onClick={handleSubmitApprove}>
                    Approve
                  </button>
                )
              )}

              {approvalStatus === 'Candidate Approved' && (
                <button className='request-for-approvals-external-files-reject-btn'>{approvalStatus}</button>
              )}
            </div>

            <div>
              {isApproving && (
                <button className='request-for-approvals-external-files-approve-btn' onClick={handleSubmitObjection}>
                  Objection
                </button>
              )}
            </div>
          </div>
          {showSection && (
            <div className='req-approval-external-pg-objection-cont'>
              <div className='req-approval-external-pg-objection-header-cont'>
                <span>Notes</span>
              </div>

              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginLeft: '2rem',
                    overflowY: 'scroll',
                  }}
                >
                  {currentUser?.profile_pic !== null ? (
                    <img src={currentUser?.profile_pic} style={{ height: '5vh', width: '5vh', borderRadius: '50%', objectFit: 'cover' }} />
                  ) : (
                    <img src={userIcon} style={{ height: '5vh', width: '5vh', borderRadius: '50%', objectFit: 'cover' }} />
                  )}
                  <input
                    className='request-for-approvals-external-header-objection-input-field'
                    type='text'
                    value={text}
                    placeholder='Write a Note'
                    onChange={handleInputChangeObjection}
                    onKeyDown={handleInputKeyDown}
                    style={{ paddingRight: '40px' }}
                  />
                  <i className='fa fa-paper-plane' onClick={handleSendButtonClick} style={{ marginLeft: '-33px', cursor: 'pointer' }}></i>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '10px',
                    color: '#757575',
                    fontWeight: '700',
                    fontSize: '12px',
                    cursor: 'pointer',
                    marginRight: '1rem',
                    marginBottom: '-22px',
                  }}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='2x' />
                  ) : (
                    <img src={RefreshIcon} style={{ height: '4vh', cursor: 'pointer' }} onClick={handleRefreshClick} />
                  )}
                  <label onClick={handleRefreshClick} style={{ cursor: 'pointer' }}>
                    Refresh Data
                  </label>
                </div>

                <div className='request-for-approvals-external-header-objection-msgs'>
                  <div>
                    {objectionNotes
                      .slice()
                      .reverse()
                      .map((conversation, index) => {
                        const conversationTime: any = new Date(conversation?.member?.time)
                        const currentTime = new Date()
                        const timeDiffInMs = currentTime.getTime() - conversationTime.getTime()

                        // Calculate time difference in seconds, minutes, hours, and days
                        const timeDiffInSeconds = Math.floor(timeDiffInMs / 1000)
                        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60)
                        const timeDiffInHours = Math.floor(timeDiffInMinutes / 60)
                        const timeDiffInDays = Math.floor(timeDiffInHours / 24)

                        let displayTimeDiff: string

                        if (timeDiffInDays > 0) {
                          displayTimeDiff = timeDiffInDays + 'd'
                        } else if (timeDiffInHours > 0) {
                          displayTimeDiff = timeDiffInHours + 'h'
                        } else if (timeDiffInMinutes > 0) {
                          displayTimeDiff = timeDiffInMinutes + 'm'
                        } else {
                          displayTimeDiff = timeDiffInSeconds + 's'
                        }
                        return (
                          <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                            <span>
                              {conversation?.profile ? (
                                <img
                                  src={conversation?.profile}
                                  alt='Profile'
                                  style={{ height: '5vh', width: '5vh', borderRadius: '50%', objectFit: 'cover' }}
                                />
                              ) : (
                                <img src={userIcon} style={{ height: '5vh', width: '5vh', borderRadius: '50%', objectFit: 'cover' }} />
                              )}
                            </span>
                            <div className='request-for-approvals-external-header-objection-msgs-cont'>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                  marginBottom: '5px',
                                }}
                              >
                                <p style={{ margin: '0', color: '#373D4A', font: 'normal normal 650 12px/20px Nunito' }}>
                                  {conversation.name}
                                </p>
                                <p style={{ margin: '0' }}>
                                  <p style={{ margin: '0', color: '#80808F', fontSize: '10px' }}>{displayTimeDiff}</p>
                                </p>
                              </div>
                              <p style={{ margin: '0', marginLeft: '10px', color: '#80808F', font: 'normal normal normal 12px/20px Nunito' }}>
                                {conversation?.member?.message}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default RequestforApprovalsExternal
