/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './CreateCompetency.css'
import {useEffect, useState} from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
// import {AuthModel, UserModel} from '../../../../app/modules/auth/core/_models'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import Layout from '../../images/Layout-4-blocks (3).png'
import plussquarebutton from '../images/Group 9142.png'
import imageforrotate from '../images/rotate@2x.png'
import cross from '../images/Layout-4-blocks.png'
import level1image from '../../../../app/modules/Setting/images/level1.png'
import level2image from '../../../../app/modules/Setting/images/level2.png'
import level3image from '../../../../app/modules/Setting/images/level 3.png'
import axios from 'axios'
import LoaderView from '../JobFunnelsModels/LoaderView'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const GET_ALL_JOBS_RECRUITER = `${API_URL}/job`
export const DELETE_TEST = `${API_URL}/test`

type Props = {
  show: boolean
  handleClose: () => void
  id: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteTest = ({show, id, handleClose}: Props) => {
  console.log({id})
  const [over, setOver] = useState(-1)
  const [AllJobs, setAllJobs] = useState<any>([])
  const [showUpdateCompetecnyScreen, setshowUpdateCompetecnyScreen] = useState<boolean>(false)
  const [popup, setpopup] = useState(<></>)
  const [popup2, setpopup2] = useState(<></>)
  const [items, setItems] = useState<any>([])
  const [TestDet, setTestDet] = useState<any>([{}])
  const {currentUser, auth, logout} = useAuth()
  const [loading, setLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  var GetAllJobsFromDB = []
  useEffect(() => {
    let localUser: any = localStorage.getItem('kt-auth-react-v')
    setItems(JSON.parse(localUser))

    console.log(items?.role)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    await axios
      .delete(`${DELETE_TEST}/${id}`)
      .then((res) => {
        setLoading(false)
        handleClose()
      })
      .catch((error) => {
        setLoading(false)
        console.log('error', error)

        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // useEffect(() => {
  //   await axios.get
  // })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <>
        <div className=' no-header-model123' style={{marginTop: '2rem !important'}}>
          <div className='modal-header no-header-model123'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={imagePath.ic_close}></img>
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column ' style={{justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
            <img alt='' className='add-warning-image' src={addcompetenctimage}></img>
            <h3 className='fw-bolder mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>Are you sure that you want to delete the test?</h3>
          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <div className='card'>
            {/* begin::Beader */}

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form>
                <div className='d-flex justify-content-center align-items-center'>
                  <button onClick={handleSubmit} type='button' className='btn px-10 bgcolorofbutoonofsubmit me-8 text-white'>
                    Yes, I am sure!
                  </button>
                  <button onClick={handleClose} className='btn px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 text-white'>
                    No
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

export {DeleteTest}
