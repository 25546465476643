//import liraries
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import RecordRTC from 'recordrtc';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Layout from '../../../../_metronic/partials/modals/images/Layout-4-blocks (3).png'
import infoIcon from '../../../../_metronic/partials/modals/JobFunnelsModels/img/information (3)@2x.png';
import icTick from '../assets/ic_tick_green.png';
import { useAuth } from '../../auth';
import './Applications.css';
import '../../../../_metronic/css/bootstrap-glyphicon.min.css';
import { SuccessInterviewpopUp } from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessPopUp';
import icTest from '../assets/ic_test_orange.png';
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
const API_URL = process.env.REACT_APP_API_URL;
export const SAVE_ATTEMPT_TEST_DATA = `${API_URL}/jobapplication/save-attempt-test-data`;
const UPLOAD_ASSESSMENT_VIDEO_URL = `${API_URL}/jobapplication/upload-video-assessment`;
// const UPLOAD_ASSESSMENT_VIDEO_URL = `${API_URL}/test/upload-video`;

// create a component
const SolveTest = (props) => {
    const { currentUser, auth, logout } = useAuth()
    const user_id = currentUser?.user_id
    const [loading, setLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [totalSeconds, setTotalSeconds] = useState(0);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [selection, setSelection] = useState('');
    const [correctQuestion, setCorrectQuestion] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showTimer, setShowTimer] = useState(true);
    const [currentQuestion, setCurrentQuestion] = useState({
        type: '',
        text: '',
        video: null,
        options: {
            a: '',
            b: '',
            c: ''
        },
        correct_answer: '',
        selected_option: '',
        attempted: false,
        correctQuestion: false
    })
    let questions = props.questionsList;
    const testTimeValue = props.testTime;
    const jobId = props.job_id;
    const actualNoOfQuestions = props.actualNoOfQuestions;
    const [recording, setRecording] = useState<RecordRTC | null>(null);
    const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
    const videoRef = useRef(null);
    // Add a flag to track if recording is in progress
    const [isRecordingInProgress, setIsRecordingInProgress] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        if (questions !== null && questions !== undefined && questions.length > 0) {
            setCurrentQuestion(questions[currentQuestionIndex]);
        }
        console.log('testTimeValue: ', testTimeValue);
        if (testTimeValue && testTimeValue !== 'None') {
            const [timeValue, timeUnit] = testTimeValue.split(" ");
            let timeInSeconds = parseInt(timeValue, 10);

            switch (timeUnit) {
                case "seconds":
                    timeInSeconds = timeInSeconds;
                    break;
                case "minutes":
                    timeInSeconds = timeInSeconds * 60;
                    break;
                case "hours":
                    timeInSeconds = timeInSeconds * 3600;
                    break;
                default:
                    timeInSeconds = 0;
                    break;
            }
            if (timeInSeconds > 0) {
                setShowTimer(true);
            }
            setTotalSeconds(timeInSeconds);
        } else {
            setShowTimer(false);
        }
    }, [testTimeValue]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // if (totalSeconds > 0) {
            setTotalSeconds((prevTotalSeconds) => prevTotalSeconds > 0 ? prevTotalSeconds - 1 : 0);
            // }
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        // Check if the timer has ended
        if (totalSeconds === 0) {
            // Perform actions when the timer has ended
            console.log('Timer has ended!');
            // props.update(1); // goto Active Applications screen

            stopRecording();
        }
    }, [totalSeconds]);

    useEffect(() => {
        // start screen recording
        if (!recording) {
            startRecording();
        }
    }, []);

    // Add a beforeunload event listener to show a warning when the user tries to leave the page with an ongoing recording
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isRecordingInProgress) {
                event.preventDefault();
                event.returnValue = 'You have an ongoing screen recording. Are you sure you want to leave the page?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isRecordingInProgress]);


    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });

            const recorder = new RecordRTC(stream, {
                type: 'video',
                mimeType: 'video/webm',
            });

            // Add an event listener for the onstop event
            recorder.onstop = () => {
                // Handle the manual stop event here
                console.log('Screen recording manually stopped by the user');
                // Perform any additional actions you need
                // props.update(1)
            };

            recorder.startRecording();

            setRecording(recorder);
            // Set the flag to indicate recording is in progress
            setIsRecordingInProgress(true);
            // Set the screenStream to the obtained stream
            setScreenStream(stream);
        } catch (error) {
            console.error('Error starting recording:', error);
            props.update(1)
        }
    };


    const stopRecording = () => {
        if (recording) {
            recording.stopRecording(async () => {
                const blob = recording.getBlob();
                const videoUrl = URL.createObjectURL(blob);
                console.log('recordedVideoUrl: ', videoUrl);

                // Release the screen recording stream and its tracks
                if (screenStream) {
                    screenStream.getTracks().forEach(track => track.stop());
                    setScreenStream(null);
                }

                // Display the recorded video
                // videoRef.current.src = videoUrl;

                // hit api to upload recorded video
                await uploadAssessmentVideo(blob);
                // show success if the question was last
                showSuccessPopup();

                setRecording(null);
                // Clear the flag to indicate recording has stopped
                setIsRecordingInProgress(false);
            });
        }
    };

    const uploadAssessmentVideo = async (file) => {
        // fetching current date and time
        const now = new Date();

        const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);

        const hour12 = now.getHours() >= 12 ? 'PM' : 'AM';
        const hour = padZero(now.getHours() % 12 || 12);
        const minute = padZero(now.getMinutes());
        const second = padZero(now.getSeconds());
        const day = padZero(now.getDate());
        const month = padZero(now.getMonth() + 1);
        const year = now.getFullYear();

        const formattedDateTime = `${hour}_${minute}_${second}_${hour12}_${month}_${day}_${year}`;

        let jobData = props.jobData;
        let fileName = `assessment_video_${currentUser?.cnic}_${jobData?.jobdata?.title}_${formattedDateTime}.webm`;
        console.log('fileNameUpdate: fileName: ', fileName);
        const formData = new FormData();
        formData.append('jobapp_id', props.job_id);
        // formData.append('file', file, 'assessment_video');
        formData.append('file', file, fileName);

        setLoading(true);
        // setLoadingMessage(`Uploading Video...${uploadPercentage}%`)
        await axios.post(UPLOAD_ASSESSMENT_VIDEO_URL, formData,
            {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    console.log(`Upload Progress: ${progress}%`);
                    setLoadingMessage(`Uploading Video...${progress}%`)
                    // You can update a progress bar or UI element with the 'progress' value here.
                    setUploadPercentage(progress);
                },
            })
            .then((res) => {
                setLoading(false);

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })
    }

    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    const disableForm = totalSeconds === 0;

    function showNextQuestion() {
        setLoadingMessage('Loading...');
        setLoading(true);
        // hit api to save the selected answer
        //  baseUrl + /jobapplication/save-attempt-test-data
        // {
        //     "attempt_test": "", // pass (question info with selected answer) and (timer value)
        //         "jobapp_id": ""
        // }
        currentQuestion.selected_option = selection;
        currentQuestion.attempted = true;
        currentQuestion.correctQuestion = correctQuestion;

        let attempt_test = {

            'question': currentQuestion,
            'timer_value': totalSeconds
        };
        let isAttempted = (currentQuestionIndex < (questions.length - 1)) ? false : true;
        let jobapp_id = jobId;
        let totalQuestion = actualNoOfQuestions;

        let data = { attempt_test, jobapp_id, totalQuestion, isAttempted };

        axios.post(SAVE_ATTEMPT_TEST_DATA, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then(response => {
            setLoading(false);
            console.log('updatedQuestionData: ', response.data);
            const success = response.data.success;
            if (success) {
                // logic to show next question.
                if (currentQuestionIndex < questions.length - 1) {
                    setCurrentQuestion(questions[currentQuestionIndex + 1]);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                    setSelection('');
                } else {
                    // stop video recording and upload the recorded video
                    stopRecording();
                    // // show success if the question was last
                    // showSuccessPopup();
                }
                // update progress
                if (currentQuestionIndex < questions.length - 1) {
                    let percentage = ((currentQuestionIndex + 1) / questions.length) * 100;
                    setProgressPercentage(percentage);
                }
            } else {
                // test finish go back
                props.update(1)
            }


        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })

    }

    function selectedAnswer(key) {
        // if (key === currentQuestion.correct_answer) {
        setSelection(key);
        // }

        if (currentQuestion.correct_answer === key) {
            setCorrectQuestion(true);
        } else {
            setCorrectQuestion(false);
        }
    }

    const [showPopup, setShowPopup] = useState(false);

    function showSuccessPopup() {
        setShowPopup(true);
    }

    function handleClose() {
        setShowPopup(false);
        props.update(1)
    }

    return (
        <div >
            <div className='test-container shadow'>
                <div className='modal-header no-header-model123'>
                    <div id='header' className='col-md-11 d-flex justify-content-center align-items-center' style={{ marginLeft: '4rem' }}>
                        <img className='me-4' src={icTest} />
                        <h2 className='fw-bolder fs-1 ' >Online Test</h2>
                    </div>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary me-8' style={{ marginTop: '-1rem', cursor: 'pointer' }} onClick={() => props.update(1)}>
                        <img alt='' src={Layout}></img>
                    </div>

                </div>
                <div className=' testprogress-container shadow mt-6' style={{ textAlign: 'center' }}>
                    {showTimer && (<div className='timer-container align-items-center' style={{ display: 'inline-flex', textAlign: 'center' }}>
                        <img className='me-3 mb-1' src={infoIcon} width={16} />
                        <label className='label-style' style={{ color: '#F36523' }} >{minutes}:{remainingSeconds < 10 ? "0" : ""}{remainingSeconds}</label>
                    </div>)}
                    <ProgressBar className='progressbar-BS' style={{ width: '96%', height: '8px', margin: '1rem auto' }} now={progressPercentage} />
                    <label className='label-style fs-7' style={{ textAlign: 'center' }}>{`Question ${(currentQuestionIndex + 1)}/${questions.length}`}</label>
                </div>
                {/* show questions */}

                {currentQuestion && (<div>

                    {currentQuestion?.type === 'Video Based MCQs' && (
                        <div className='my-7' style={{ display: 'flex', justifyContent: 'center' }}>
                            {currentQuestion?.video ? (
                                <video controls autoPlay
                                    style={{ maxWidth: '100%', maxHeight: '400px', borderRadius: '4px' }}
                                >
                                    <source src={currentQuestion?.video} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div>Question Video Not Available</div>
                            )}
                        </div>
                    )}

                    <div id='question' className='question-container'>
                        <h3 className='my-7 fs-3'>{currentQuestion?.text}</h3>
                    </div>

                    {Object.entries(currentQuestion?.options).map(([key, value]) => {
                        return (

                            <div id='options' key={key}
                                className={`shadow mb-3 ${selection === null || selection === '' || selection !== key ? 'testprogress-container' : 'selected-answer-style'}`}
                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0.5rem', cursor: 'pointer' }}
                                onClick={() => selectedAnswer(key)}>
                                <label className='label-style fs-4' style={selection === null || selection === '' || selection !== key ? { color: '#F36523' } : { color: '#FFFFFF' }} >{value}</label>
                            </div>

                        )
                    })}
                    <div className='m-auto d-flex mt-8' style={{ width: '85%', textAlign: 'right' }}>
                        <div></div>
                        <div
                            className={`fw-bolder ms-auto text-white ${selection === null || selection === '' ? 'candi-btn-grey' : 'candi-btn2'}`}
                            onClick={() => {
                                if (selection === '' || selection === null) {

                                } else {
                                    showNextQuestion()
                                }
                            }
                            }
                        >
                            {(currentQuestionIndex + 1) == questions.length ? 'Finish' : 'Next'}
                        </div>
                    </div>
                </div>)}

            </div>

            {loading && (
                <LoaderView message={loadingMessage} />
            )}

            {showPopup && (
                <SuccessInterviewpopUp
                    show={true}
                    jobapp_id={props.job_id}
                    handleClose={() => {
                        handleClose()
                    }}
                    message={'Online test submitted'}
                />
            )}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default SolveTest;