//import liraries
import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import '../../../_metronic/partials/modals/JobFunnelsModels/ViewProfile.css'
import {useAuth} from '../auth/core/Auth'
import GraduationUniversity from '../../../_metronic/partials/modals/JobFunnelsModels/img/graduation-cap (4).png'
import EmailIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/email (2).png'
import PhoneCall from '../../../_metronic/partials/modals/JobFunnelsModels/img/phone-call (3).png'
import IdCard from '../../../_metronic/partials/modals/JobFunnelsModels/img/id-card (1).png'
import Attachfile from '../../../_metronic/partials/modals/JobFunnelsModels/img/attach-file (1).png'
import DownloadIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/Component 237 – 5.png'
import SkillIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/skills_Color.png'
import BagIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/W.E_Colored.png'
import ArrowDown from '../../../_metronic/partials/modals/JobFunnelsModels/img/arrow.png'
import EducationIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/cap_ color.png'
import CertificateIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/document _ color.png'
import UserIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/P.I_ Colored.png'
import ArrowUp from '../../../_metronic/partials/modals/JobFunnelsModels/img/Group 12485 (1).png'
import UserIcons from '../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
const GET_REJECTED_CANDIDATE_DATA_URL = `${API_URL}/jobapplication/get-rejected-page-data`
const APPROVE_CANDIDATE_URL = `${API_URL}/jobapplication/accept-rejected-job-application`

type Props = {
  show: boolean
  name: string
  jobapp_id: string
  user_id: string
  email: string
  profile_pic: string
  cnic: string
  phone_number: string
  education: string
  skills: string
  work_Experience: string
  total_exp: string
  banking_exp: any
  nonBanking_exp: any
  educations: string
  certifications: string
  personalData: any
  profile_match: string
  position: string
  recuiter_id: string
  testExists: string
  applicationStage: string
  index: string
  handleClose: () => void
  recaller: (index) => void
  // ApproveHandle: () => void
}

function WorkExperience({show, work_Experience, total_exp, banking_exp, nonBanking_exp}: Props) {
  let workData: any = JSON.parse(work_Experience)
  console.log({workData})
  return (
    <>
      <div>
        <div>
          <span className='view-profile-exp-txt'>Total Experience: {total_exp} yrs</span>
        </div>

        <div>
          <span className='view-profile-exp-txt-darker'>
            Banking Experience: {Math.floor(banking_exp)} yrs {Math.round((banking_exp % 1) * 12)} months
          </span>
        </div>
      </div>
      <div>
        {Object.keys(workData).map((key, index) => (
          <div key={index}>
            <table className='work-exp-table'>
              <tr>
                <th className='work-exp-table-header-td'>Company</th>
                <th className='work-exp-table-header-td'>Job Title</th>
                <th className='work-exp-table-header-td'>Date From</th>
                <th className='work-exp-table-header-td'>Date To</th>
              </tr>

              {Object.keys(workData).map((key, index) => (
                <tr key={index}>
                  <td className='work-exp-table-header-th'>{workData[key][Object.keys(workData[key])[0]].name}</td>
                  <td className='work-exp-table-header-th'>{workData[key][Object.keys(workData[key])[0]].job_title}</td>
                  <td className='work-exp-table-header-th'>{workData[key][Object.keys(workData[key])[0]].Date_from}</td>
                  <td className='work-exp-table-header-th'>{workData[key][Object.keys(workData[key])[0]].Date_to}</td>
                </tr>
              ))}
            </table>
          </div>
        ))}

        <div>
          <span className='view-profile-exp-txt-darker'>
            Non Banking Experience: {Math.floor(nonBanking_exp)} yrs {Math.round((nonBanking_exp % 1) * 12)} months
          </span>
        </div>
      </div>
    </>
  )
}

function Education({show, educations}: Props) {
  console.log(JSON.parse(educations))
  let educationData = JSON.parse(educations)
  console.log({educationData})
  return (
    <>
      <div>
        <div>
          {Object.keys(educationData).map((key, index) => (
            <div key={index}>
              <table className='work-exp-table-header-tbl'>
                <tr>
                  <th className='work-exp-table-header'>Academic Qualification {index + 1}</th>
                </tr>
                <tr className='work-exp-table-cont'>
                  <th className='work-exp-table-header-td'>Education Level</th>
                  <th className='work-exp-table-header-td'>Institution</th>
                  <th className='work-exp-table-header-td'>Program</th>
                  <th className='work-exp-table-header-td'>CGPA</th>
                  <th className='work-exp-table-header-td'>Date From</th>
                  <th className='work-exp-table-header-td'>Date To</th>
                </tr>

                <tr className='work-exp-table-body-cont'>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].education_level}</td>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].institution}</td>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].program}</td>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].education_value}</td>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].end_date}</td>
                  <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[0]].start_date}</td>
                </tr>
              </table>
            </div>
          ))}
        </div>

        {Object.keys(educationData).length > 1 && (
          <div>
            {Object.keys(educationData).map((key, index) => (
              <div key={index}>
                <table className='work-exp-table-header-tbl'>
                  <tr>
                    <th className='work-exp-table-header'>Academic Qualification {1 + 1}</th>
                  </tr>
                  <tr className='work-exp-table-cont'>
                    <th className='work-exp-table-header-td'>Education Level</th>
                    <th className='work-exp-table-header-td'>Institution</th>
                    <th className='work-exp-table-header-td'>Program</th>
                    <th className='work-exp-table-header-td'>CGPA</th>
                    <th className='work-exp-table-header-td'>Date From</th>
                    <th className='work-exp-table-header-td'>Date To</th>
                  </tr>
                  <tr className='work-exp-table-body-cont'>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].education_level}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].institution}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].program}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].education_value}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].end_date}</td>
                    <td className='work-exp-table-header-th'>{educationData[key][Object.keys(educationData[key])[1]].start_date}</td>
                  </tr>
                </table>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

function ProfessionalCertifications({certifications}: Props) {
  let certificationData: any = JSON.parse(certifications)
  console.log({certifications})
  return (
    <>
      <div>
        {Object.keys(certificationData).map((key, index) => (
          <div key={index}>
            <table className='work-exp-table'>
              <tr>
                <th className='work-exp-table-header-td'>Name of Certificate</th>
                <th className='work-exp-table-header-td'>Issuing Organization</th>
                <th className='work-exp-table-header-td'>Issuing Date</th>
              </tr>

              {Object.keys(certificationData).map((key, index) => (
                <tr key={index}>
                  <td className='work-exp-table-header-th'>{certificationData[key][Object.keys(certificationData[key])[0]].name}</td>
                  <td className='work-exp-table-header-th'>
                    {certificationData[key][Object.keys(certificationData[key])[0]].issuing_organization}
                  </td>
                  <td className='work-exp-table-header-th'>{certificationData[key][Object.keys(certificationData[key])[0]].issue_date}</td>
                </tr>
              ))}
            </table>
          </div>
        ))}
      </div>
    </>
  )
}

function PersonalInformation({personalData}: Props) {
  const birthdate = new Date(personalData.dob)
  const ageInMilliseconds = Date.now() - birthdate.getTime()
  const ageInYears = Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365)
  return (
    <>
      <div className='personalInfor-header-row-view-profile'>
        <div>
          <span className='personalInfo-view-profile-head-txt'>DOB (as per CNIC)</span>
          <td className='work-exp-table-header-th'>{personalData?.dob}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Age</span>
          <td className='work-exp-table-header-th'>{ageInYears}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Address</span>
          <td className='work-exp-table-header-th'>{personalData?.address}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>City</span>
          <td className='work-exp-table-header-th'>{personalData?.city}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Gender</span>
          <td className='work-exp-table-header-th'>{personalData?.gender}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Marital Status</span>
          <td className='work-exp-table-header-th'>{personalData?.marital_status}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Fresh Graduate</span>
          <td className='work-exp-table-header-th'>{personalData?.fresh_graduate ? 'Yes' : 'No'}</td>
        </div>
        <div>
          <span className='personalInfo-view-profile-head-txt'>Employment Status</span>
          <td className='work-exp-table-header-th'>{personalData?.employment_status}</td>
        </div>
      </div>
      <div></div>
    </>
  )
}

// create a component
const RejectedCandidateProfile = () => {
  const {auth, logout} = useAuth()
  const navigate = useNavigate()
  let {jobapp_id} = useParams<any>()
  const [isArrowDown, setIsArrowDown] = useState(true)
  const [candidateDocuments, setCandidateDocuments] = useState<any>([])
  const [showWorkExperience, setShowWorkExperience] = useState(false)
  const [showEducation, setShowEducation] = useState(false)
  const [showCertifications, setShowCertifications] = useState(false)
  const [showPersonalData, setShowPersonalData] = useState(false)
  const [jobData, setJobData] = useState<any>()
  const [jobApplication, setJobApplication] = useState<any>()
  const [recruiterData, setRecruiterData] = useState<any>()
  const [taLeadData, setTALeadData] = useState<any>()
  const [candidateData, setCandidateData] = useState<any>()
  const [userData, setUserData] = useState<any>()

  const [profilePic, setProfilePic] = useState<any>()
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')
  const [cnic, setCnic] = useState('')
  const [email, setEmail] = useState('')
  const [phone_number, setPhoneNumber] = useState('')
  const [education, setEducation] = useState('')
  const [profile_match, setProfileMatch] = useState('')
  const [candidateSkills, setCandidateSkills] = useState<any>()
  const [work_Experience, setWorkExperience] = useState<any>()
  const [totalExperience, setTotalExperience] = useState<any>()
  const [banking_exp, setBankingExperience] = useState<any>()
  const [nonBanking_exp, setNonBankingExp] = useState<any>()
  const [educations, setEducations] = useState<any>()
  const [certifications, setCertifications] = useState<any>()
  const [applicationStage, setApplicationStage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (auth?.token) {
      ;(() => {
        getApplicationData()
      })()
    } else {
      navigate('/')
    }
  }, [])

  const getApplicationData = async () => {
    await axios
      .post(
        GET_REJECTED_CANDIDATE_DATA_URL,
        {jobapp_id: jobapp_id},
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('candidateApplicationData: ', res.data)
        let data = res.data
        if (data) {
          setJobData(data?.job)
          setJobApplication(data?.jobApplication)
          setRecruiterData(data?.recruiter)
          setTALeadData(data?.ta_lead)
          setCandidateData(data?.candidate)
          setUserData(data?.user)
          setCandidateDocuments(data?.doc)
          initializeData(data?.job, data?.jobApplication, data?.candidate, data?.user)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const initializeData = (job, application, candidate, user) => {
    setProfilePic(candidate?.profile_pic ? candidate?.profile_pic : user?.profile_pic ? user?.profile_pic : null)
    let fullName = `${user?.first_name} ${user?.last_name}`
    setName(fullName)
    setPosition(job?.vacant_position)
    setCnic(user?.cnic)
    setEmail(user?.email)
    setPhoneNumber(user?.phone_number)
    setEducation(job?.eduction)
    setProfileMatch(application?.profile_match)

    setCandidateSkills(candidate?.skills ? JSON.parse(candidate?.skills) : null)
    setWorkExperience(candidate?.work_experience)
    let bankingExp = jobApplication?.banking_experience
    setBankingExperience(bankingExp)
    let jobExp = jobApplication?.job_experience
    setNonBankingExp(jobExp)
    let totalExp = parseFloat(bankingExp) + parseFloat(jobExp)
    setTotalExperience(totalExp)

    let qualifications = candidate?.qualification
    setEducations(qualifications)
    setCertifications(candidate?.certification)
    setApplicationStage(application?.previous_stage)
  }

  const handleArrowDownClick = () => {
    setShowWorkExperience(!showWorkExperience)
    setIsArrowDown(!isArrowDown)
  }

  const handleEducationArrowDownClick = () => {
    setShowEducation(!showEducation)
    setIsArrowDown(!isArrowDown)
  }

  const handleCertificationsArrowDownClick = () => {
    setShowCertifications(!showCertifications)
    setIsArrowDown(!isArrowDown)
  }

  const handlePersonalInformationArrowDownClick = () => {
    setShowPersonalData(!showPersonalData)
    setIsArrowDown(!isArrowDown)
  }

  const approveCandidate = async () => {
    // implement api to approve rejected candidate
    let data = {
      jobapp_id: jobapp_id,
    }
    setIsLoading(true)
    await axios
      .post(APPROVE_CANDIDATE_URL, data)
      .then((res) => {
        // setIsLoading(false);

        navigate('/')
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  return (
    <>
      <div style={{backgroundColor: '#fff', borderRadius: '10px', padding: '1rem'}}>
        <label className='form-label fs-4 fw-bolder'>Rejected Candidate</label>
        <div>
          <div className='view-profile-upper-cont'>
            <div>
              <img src={profilePic ? profilePic : UserIcons} className='profile-user-icon-view-profile' />
            </div>
            <div className='view-profile-name-cont'>
              <span className='middle-container-username'>{name}</span>
              <span className='middle-container-role'>Position : {position}</span>
            </div>

            <div className='view-profile-middle-cont'>
              <div className='view-profile-personal-cont'>
                <img src={IdCard} />
                <span className='view-profile-personal-text'>{cnic}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={EmailIcon} />
                <span className='view-profile-personal-text'>{email}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={PhoneCall} />
                <span className='view-profile-personal-text'>{phone_number}</span>
              </div>

              <div className='view-profile-personal-cont'>
                <img src={GraduationUniversity} style={{marginLeft: '-3px'}} />
                <span className='view-profile-personal-text'>{education}</span>
              </div>
            </div>
            <div className='view-profile-pmatch'>
              <span className='view-profile-pmatch-header'>Profile Match</span>
              <span className='view-profile-pmatch-header'>{profile_match ? parseFloat(profile_match) : 0} %</span>
              <div className='progress h-6px w-80'>
                <div className='progress-bar bg-primary123' role='progressbar' style={{width: `${parseFloat(profile_match)}%`}}></div>
              </div>
              <img />
            </div>

            <div className='view-profile-header-btn-cont'>
              <button className='view-profile-header-btn fw-bolder fs-6' onClick={() => approveCandidate()}>
                Approve
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className='view-profile-attachment-cont'>
            <div className='view-profile-attachment-header-cont'>
              <img src={Attachfile} />
              <label className='view-profile-attachment-cont-header-tct'>Attachments</label>
            </div>

            <div className='view-profile-attachment-body-cont'>
              {candidateDocuments.length > 0 ? (
                candidateDocuments.map((doc) => (
                  <span key={doc.id}>
                    <a download={doc.doc_name} className='view-profile-attachment-body-inner-cont'>
                      <img
                        src={DownloadIcon}
                        onClick={(event) => {
                          event.preventDefault()
                          const xhr = new XMLHttpRequest()
                          xhr.responseType = 'blob'
                          xhr.onload = () => {
                            const url = window.URL.createObjectURL(xhr.response)
                            const link = document.createElement('a')
                            link.href = url
                            link.download = doc.doc_name
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                          }
                          xhr.open('GET', doc.doc_path)
                          xhr.send()
                        }}
                      />
                      <label style={{color: 'grey'}}>{doc?.doc_name}</label>
                    </a>
                  </span>
                ))
              ) : (
                <span>No Documents Available</span>
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont'>
            <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
              <img src={SkillIcon} />
              <span className='view-profile-attachment-cont-header-tct'>Skills</span>
            </div>

            {candidateSkills &&
              candidateSkills.map((option, index) => {
                const values = Object.values(option)
                return (
                  <span key={index}>
                    <span
                      style={{
                        marginRight: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                      }}
                    >
                      {values.map((value: any, idx) => (
                        <span className='skills-tiles' key={idx}>
                          {value}
                        </span>
                      ))}
                    </span>
                  </span>
                )
              })}
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={BagIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Work Experience</span>
              </div>
              <div>
                <img style={{cursor: 'pointer'}} src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleArrowDownClick} />
              </div>
            </div>

            <div>
              {showWorkExperience && (
                <WorkExperience
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id ? jobapp_id : ''}
                  user_id={userData?.user_id}
                  email={email}
                  profile_pic={profilePic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={candidateSkills}
                  work_Experience={work_Experience}
                  total_exp={totalExperience}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={userData}
                  profile_match={profile_match}
                  position={position}
                  recuiter_id={recruiterData?.user_id}
                  testExists={''}
                  applicationStage={applicationStage}
                  index={'0'}
                  handleClose={() => {}}
                  recaller={(index) => {}}
                />
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={EducationIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Education</span>
              </div>
              <div>
                <img style={{cursor: 'pointer'}} src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleEducationArrowDownClick} />
              </div>
            </div>
            <div>
              {showEducation && (
                <Education
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id ? jobapp_id : ''}
                  user_id={userData?.user_id}
                  email={email}
                  profile_pic={profilePic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={candidateSkills}
                  work_Experience={work_Experience}
                  total_exp={totalExperience}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={userData}
                  profile_match={profile_match}
                  position={position}
                  recuiter_id={recruiterData?.user_id}
                  testExists={''}
                  applicationStage={applicationStage}
                  index={'0'}
                  handleClose={() => {}}
                  recaller={(index) => {}}
                />
              )}
            </div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={CertificateIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Professional Certifications</span>
              </div>
              <div>
                <img style={{cursor: 'pointer'}} src={isArrowDown ? ArrowDown : ArrowUp} onClick={handleCertificationsArrowDownClick} />
              </div>
            </div>
            <div>
              {showCertifications && (
                <ProfessionalCertifications
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id ? jobapp_id : ''}
                  user_id={userData?.user_id}
                  email={email}
                  profile_pic={profilePic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={candidateSkills}
                  work_Experience={work_Experience}
                  total_exp={totalExperience}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={userData}
                  profile_match={profile_match}
                  position={position}
                  recuiter_id={recruiterData?.user_id}
                  testExists={''}
                  applicationStage={applicationStage}
                  index={'0'}
                  handleClose={() => {}}
                  recaller={(index) => {}}
                />
              )}
            </div>
            <div></div>
          </div>

          <div className='view-profile-attachment-cont-edu'>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div className='view-profile-attachment-header-cont' style={{marginBottom: '15px'}}>
                <img src={UserIcon} />
                <span className='view-profile-attachment-cont-header-tct'>Personal Information</span>
              </div>
              <div>
                <img
                  style={{cursor: 'pointer'}}
                  src={isArrowDown ? ArrowDown : ArrowUp}
                  onClick={handlePersonalInformationArrowDownClick}
                />
              </div>
            </div>
            <div>
              {showPersonalData && (
                <PersonalInformation
                  show={showWorkExperience}
                  name={name}
                  jobapp_id={jobapp_id ? jobapp_id : ''}
                  user_id={userData?.user_id}
                  email={email}
                  profile_pic={profilePic}
                  cnic={cnic}
                  phone_number={phone_number}
                  education={education}
                  skills={candidateSkills}
                  work_Experience={work_Experience}
                  total_exp={totalExperience}
                  banking_exp={banking_exp}
                  nonBanking_exp={nonBanking_exp}
                  educations={educations}
                  certifications={certifications}
                  personalData={userData}
                  profile_match={profile_match}
                  position={position}
                  recuiter_id={recruiterData?.user_id}
                  testExists={''}
                  applicationStage={applicationStage}
                  index={'0'}
                  handleClose={() => {}}
                  recaller={(index) => {}}
                />
              )}
            </div>
            <div></div>
          </div>
          {/* {Approvalpopup} */}
        </div>
      </div>
      {isLoading && <LoaderView message='Loading...' />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

//make this component available to the app
export default RejectedCandidateProfile
