import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import './TestScorePopup.css'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import Skills from './img/exam.png'
import UserIcon from './img/Group 9681.png'
import CorrectIcon from './img/Component 237 – 5 (1).png'
import CompetencyIcon from './img/skill (1).png'
import DownloadIcon from './img/Component 237 – 5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const API_URL = process.env.REACT_APP_API_URL
export const GET_TEST_DATA = `${API_URL}/jobapplication/get-attempt-test-data`

interface TestData {
    questions: {
        attempted: boolean;
        competencies: string[];
        correct_answer: string;
        options: { [key: string]: string };
        selected_option: string;
        text: string;
        type: string;
    }[];
}


type Props = {
    // show: boolean
    name: string
    jobapp_id: string
    user_id: string
    email: string
    profile_pic: string
    cnic: string
    phone_number: string
    education: string
    skills: string
    work_Experience: string
    total_exp: string
    banking_exp: string
    nonBanking_exp: string
    educations: string
    certifications: string
    personalData: any
    profile_match: string
    position: string
    handleClose: () => void
}



const TestScorePopup = ({ name, jobapp_id, user_id, email, profile_pic, cnic, phone_number, education, skills, work_Experience, total_exp, banking_exp, nonBanking_exp, educations, certifications, personalData, profile_match, position, handleClose }: Props) => {

    const { auth } = useAuth()
    const [testData, setTestData] = useState<any>([]);
    const [testResult, setTestResult] = useState<any>(0)
    const [isLoading, setIsLoading] = useState(true);
    const [assessmentVideoUrl, setAssessmentVideoUrl] = useState();

    const GETTESTDETAILS = async () => {
        try {
            setIsLoading(true); // Set loading state to true before making the API request

            const response = await axios.post(GET_TEST_DATA, {
                jobapp_id: jobapp_id
            }, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = response?.data?.attemptTestData;
            const resData = response?.data?.testAggResult;
            const finalData = data?.questionsData;
            setTestResult(resData);
            setTestData(finalData);
            setAssessmentVideoUrl(response?.data?.assessment_video);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Set loading state to false after the API request is completed
        }
    };

    useEffect(() => {
        GETTESTDETAILS();
    }, [])

    // Function to extract file name from URL
    const extractFileNameFromUrl = (url) => {
        const parsedUrl = new URL(url);
        const pathArray = parsedUrl.pathname.split('/');
        return pathArray[pathArray.length - 1];
    };

    return (
        <>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
                show={true}
                height='50vh'
                onHide={handleClose}
                backdrop={true}

            >
                <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center', gap: '10px' }}>

                        <img src={Skills} alt="Skills Icon" />
                        <h2 className="test-score-header-container-text">Test Score Details</h2>
                    </div>
                </Modal.Header>


                <Modal.Title>

                </Modal.Title>
                <Modal.Body>
                    {isLoading ? (
                        <div className='text-center'>
                            <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size="2x"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    marginTop: '-1rem'
                                }} />
                        </div>
                    ) : (
                        <>
                            <div className='Testscoreheader-cont'>
                                {/* Profile Image */}
                                <div className='Testscoreheader-text'>

                                    <div>
                                        {profile_pic ? (
                                            <img src={profile_pic} style={{ width: '7vh', height: '7vh', objectFit: 'cover', borderRadius: '50%' }} />
                                        ) : (
                                            <img src={UserIcon} style={{ width: '7vh', height: '7vh', objectFit: 'cover', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    {/* User Name */}
                                    <div>
                                        <label className='Testscoreheader-username'>{name}</label>
                                    </div>

                                </div>

                                {/* Percentage  */}
                                <div className={`testscore-approval${testResult !== null && parseInt(testResult) > 80 ? '' : '-less1'}`}>
                                    <label>{testResult ? `${testResult}%` : '0%'}</label>

                                </div>


                                {/* Position */}
                                <div className='Testscoreheader-role-container'>
                                    <label>Position: {position}</label>
                                </div>

                            </div>

                            {assessmentVideoUrl ? (
                                <div className='d-flex flex-column' style={{ paddingLeft: '4rem', paddingTop: '2rem' }}>
                                    <span >
                                        {/* <a download={`Assessment_Video`} className='view-profile-attachment-body-inner-cont'>
                                            <img src={DownloadIcon} style={{ cursor: 'pointer' }} onClick={(event) => {
                                                event.preventDefault();
                                                const xhr = new XMLHttpRequest();
                                                xhr.responseType = 'blob';
                                                xhr.onload = () => {
                                                    const url = window.URL.createObjectURL(xhr.response);
                                                    const link = document.createElement('a');
                                                    // Fetch file name from the URL
                                                    const fileName = extractFileNameFromUrl(assessmentVideoUrl);
                                                    link.setAttribute('download', fileName);
                                                    link.href = url;
                                                    // link.download = 'Assessment_VideoTest.webm';
                                                    document.body.appendChild(link);
                                                    link.click();
                                                    document.body.removeChild(link);
                                                };
                                                xhr.open('GET', assessmentVideoUrl);
                                                xhr.send();
                                            }} />
                                            <label style={{ color: 'grey' }}>{`Assessment Video`}</label>
                                        </a> */}
                                        Assessment Video
                                    </span>
                                    <video
                                        // ref={videoRef}
                                        className='mx-auto mt-8'
                                        controls
                                        style={{ maxWidth: '70%', height: '350px', borderRadius: '4px' }}
                                    >
                                        {/* <source src={URL.createObjectURL(videoFile)} type='video/mp4' /> */}
                                        <source src={assessmentVideoUrl} type="video/mp4" />
                                        
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ) : (
                                <div style={{ paddingLeft: '4rem', paddingTop: '2rem' }}>
                                    <label className='fs-5 fw-bolder'>Assessment Video not available</label>
                                </div>
                            )}

                            <div className="McqsQuestionare-container">
                                {/* <div className='question-tags'>
                            {testData.map((item, index) => (
                                <div key={`question-${index}`}>
                                    <label className='question-handlers'>{`Q${index + 1}`}</label>
                                </div>
                            ))}
                        </div> */}

                                <div className='test-question-container'>

                                    {testData && testData?.map((item, index) => (
                                        <div key={`test-question-${index}`} className='test-question'>
                                            <div className='question-row-test'>
                                                <div className='question-tags'>
                                                    <label className='question-handlers'>{`Q${index + 1}`}</label>
                                                </div>
                                                <div>
                                                    <p className='test-question-statement'
                                                        style={{ margin: '0', padding: '0' }}
                                                    >{item?.question?.text}</p>
                                                </div>
                                            </div>

                                            <div className='test-question-res'>
                                                <div style={{ 'marginBottom': '20px' }}>
                                                    {item?.question?.selected_answer_id === item?.question?.correct_answer_id ? (
                                                        <label className='test-question-result'>Correct</label>
                                                    ) : (
                                                        <label className='test-question-result-negative'>Wrong</label>
                                                    )}
                                                </div>

                                                <div className='test-question-options'>

                                                    {item?.question?.answers && item?.question?.answers.map((answer, ansIndex) => {

                                                        return (
                                                            <div key={answer?.answer_id} className='test-question-row'>
                                                                {String.fromCharCode(65 + ansIndex)}.{" "}
                                                                <span className={`test-question-span${item?.question?.selected_answer_id === answer?.answer_id
                                                                    ? item?.question?.selected_answer_id === item?.question?.correct_answer_id
                                                                        ? "-right" : "-wrong"
                                                                    : item?.question?.correct_answer_id === answer?.answer_id ? "-right" : ""}`}>
                                                                    {answer?.text}
                                                                    {answer?.answer_id === item?.question?.correct_answer_id && (
                                                                        <img
                                                                            className='test-question-correct-icon ms-4'
                                                                            src={CorrectIcon}
                                                                            height={28}
                                                                            width={28}
                                                                            alt='Correct'
                                                                        />
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )
                                                    })}
                                                    {/* {Object.entries(item.question.options).map(([optionKey, optionValue]) => (
                                                        <div className='test-question-row' key={`${index}-${optionKey}`}>
                                                            {optionKey}.{" "}
                                                            <span
                                                                className={`test-question-span${item.question.selected_option === optionKey
                                                                    ? item.question.selected_option === item.question.correct_answer
                                                                        ? "-right"
                                                                        : "-wrong"
                                                                    : item.question.correct_answer === optionKey
                                                                        ? "-right"
                                                                        : ""
                                                                    }`}
                                                            >
                                                                {optionValue as string}
                                                                {item.question.correct_answer === optionKey && (
                                                                    <img
                                                                        className='test-question-correct-icon'
                                                                        src={CorrectIcon}
                                                                        alt='Correct'
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>
                                                    ))} */}
                                                </div>
                                            </div>

                                            <div className="compentancy-tags">
                                                <img src={CompetencyIcon} />
                                                <label className='compentancy-items'>{item?.question?.competency?.name}</label>
                                            </div>
                                        </div>
                                    ))}

                                    {/* <div className='test-question-btn-container'>
                                <button className='test-question-btn'>Go Back</button>
                            </div> */}
                                </div>
                            </div>
                        </>

                    )}
                </Modal.Body>

            </Modal>
        </>
    );
}

export default TestScorePopup;