// Context/LoginContext
import React, {useState, createContext} from 'react'

interface CompetenciesButtonContextProps {
  children: React.ReactNode
}
export const CreateCompetenciesButton = createContext({
  CompetenciesButton: '',
  setCompetenciesButton: (CompetenciesButton: string) => {},
})

export const CompetenciesButtonContext = ({children}: CompetenciesButtonContextProps) => {
  const [CompetenciesButton, setCompetenciesButton] = useState('')
  return (
    <CreateCompetenciesButton.Provider value={{CompetenciesButton, setCompetenciesButton}}>
      {children}
    </CreateCompetenciesButton.Provider>
  )
}
