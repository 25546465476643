/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { getUserByToken, register } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { Link, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import unchecked from '../../Candidate Space/auth/images/check-box-outline-blank-black.png'
import checked from '../../Candidate Space/auth/images/tick.png'
import letterboxImage from '../../Candidate Space/auth/images/mail.png'
import icOpenEye from '../images/ic_open_eye.png';
import icCloseEye from '../images/ic_close_eye.png';
import lock from '../../Candidate Space/auth/images/password.png'
import './Login.css'
import SignupSuccessPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/SignupSuccessPopup'
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? "google-client-id"
export const REGISTER_URL = `${API_URL}/auth/google`

const initialValues = {
  email: '',
  password: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    // .min(12, 'Minimum 12 symbols')
    // .max(50, 'Maximum 50 symbols')
    .matches(
      // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/,
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]\S{12,}$/,
      'Password should have at least 12 characters, 1 capital letter, 1 small letter, numbers, 1 or more special characters and no white spaces'
    )
    .required('Password is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [checkedState, setCheckedState] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth()

  function encryptPassword(password, secretKey) {
    if (!secretKey) {
      throw new Error('Encryption key is not defined');
    }
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if(!checkedState){
        window.alert('Please agree to the Terms and Conditions before signing up.');
        return
      }
      setLoading(true)
      const socialAuth = false
      const role = 'candidate'
      const credential = 'false';
      const password = encryptPassword(values.password, process.env.REACT_APP_SECRET_KEY);
      try {
        const response = await register(values.email, password, socialAuth, role, credential)
        setLoading(false)
        // saveAuth(response.data)
        // const { data: user } = await getUserByToken(response.data.token)
        // setCurrentUser(user)
        // show success pop up
        showSuccessPopup()
      } catch (error:any) {
        console.error(error)
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data.message; 
          console.log('LoginError:', errorMessage);
          window.alert(errorMessage);
        }  else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          window.alert(error.response.data.error.message);
          
          // handleClose();
      }else {
          console.log('An error occurred:', error.message);
          window.alert(error.message);
        }
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const checkedStateFunction = () => {
    setCheckedState(!checkedState)
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  async function registerUser(email, password, socialAuth, role, credential) {
    setLoading(true)
    try {
      const response = await register(email, password, socialAuth, role, credential)
      setLoading(false)
      saveAuth(response.data)
      const { data: user } = await getUserByToken(response.data.token)
      setCurrentUser(user)

      // redirect user to profile building screen
      navigate('/cvcreation')
    } catch (error:any) {
      console.error(error)
      saveAuth(undefined)
      // setStatus('The registration details is incorrect')
      setLoading(false)
      if (error.response && error.response.status === 400) {
        const errorMessage = error.response.data.message; 
        console.log('LoginError:', errorMessage);
        window.alert(errorMessage);
      }  else if (error.response && error.response.data && error.response.data.error) {
        // Handle error message from the API
        // showToast(error.response.data.error.message);
        window.alert(error.response.data.error.message);
        
        // handleClose();
    }else {
        console.log('An error occurred:', error.message);
        window.alert(error.message);
      }
    }
  }

  const [showPopup, setShowPopup] = useState(false);

  function showSuccessPopup() {
    setShowPopup(true);
  }

  function handleClose() {
    setShowPopup(false);
    // goto login page
    // redirect user to profile building screen
    navigate('/login')
  }

  return (
    <>
      <form
        className='form w-100 aaligndivCenter fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        // onSubmit={handleSubmit}
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-11 text-center'>
          <h1 className='Registration-text main-heading mb-3'>Sign Up</h1>
          <div className='text-white mt-10 fw-semibold labels'>
            Find your dream job in just one click
          </div>
        </div>

        {/* begin::Action */}
        <div className='widthHeightSettingForm'>
          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-label labeldfa mb-3'>Email Address</label>
            <div className='grp-image-input backinpput-color'>
              <input
                style={{ height: '56px' }}
                placeholder='Enter your Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control no-border',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              <img alt='LetterBox' src={letterboxImage}></img>
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span className='text-white' role='alert'>
                  {formik.errors.email}
                </span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-3'>
            <label className='form-label labeldfa mb-3'>Password</label>
            <div className='grp-image-input'>
              <input
                style={{ height: '56px' }}
                type={showPassword ? 'text' : 'password'}
                autoComplete='off'
                placeholder='Password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control no-border',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <img alt='' src={showPassword ? icOpenEye : icCloseEye} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className='text-white' role='alert'>
                    {formik.errors.password}
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Wrapper */}
          <div className='d-flex flex-stack flex-wrap  fs-base fw-semibold mt-3 mb-8'>
            <div className='form-check dflex-checkbox-logged-in'>
              <div onClick={() => checkedStateFunction()}>
                {checkedState === false ? (
                  <>
                    {' '}
                    <img alt='' src={unchecked}></img>
                  </>
                ) : (
                  <>
                    {' '}
                    <img alt='' src={checked}></img>
                  </>
                )}
              </div>

              <label className='form-check-label forgot-pass'>
                I agree to the terms and privacy policy{' '}
              </label>
            </div>
            <div />
          </div>
          {/* end::Wrapper */}

          {/* begin::Action */}
          <div className='d-grid mb-10'>
            <button
              style={{ height: '60px', color: 'white' }}
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary loginButtonLogin-screen'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='text-white fs-6 indicator-label'>Sign Up</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>

          <div className='form-check-label forgot-pass fw-semibold fs-6'>
            Already have an account?
            <Link to='/auth/login' className='link-primary'>
              <span style={{ color: '#F36523' }}> Login</span>
            </Link>
          </div>

          <div className='d-flex align-items-center my-10'>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
            <span className='fw-semibold text-gray-400 fs-7 mx-2'>OR</span>
            <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          </div>

          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <GoogleLogin
              shape='rectangular'
              text='signup_with'
              width='328px'
              onSuccess={(credentialResponse) => {
                const credential: any = credentialResponse?.credential
                const userData: any = jwt_decode(credential)
                console.log('userGoogleData: ', userData)
                console.log('userGoogleCredentials: ', credential)
                const email = userData.email
                const password = userData.sub
                const socialAuth = true
                const role = 'candidate'
                registerUser(email, password, socialAuth, role, credential)
              }}
              onError={() => {
                console.log('Login Failed')
              }}
            />
          </GoogleOAuthProvider>
        </div>

        {/* end::Action */}

        {/* end::Form group */}
      </form>
      {showPopup && (
        <SignupSuccessPopup
          show={true}
          handleClose={() => {
            handleClose()
          }}
          message={'A verification link has been sent to your email address, please click on the link to verify your email address'}
        />
      )}
    </>
  )
}
