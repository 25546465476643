//import liraries
import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import './users.css'
import { useAuth } from '../auth'
import axios from 'axios'
import userIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
import WarningTARemovePopup from '../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import CustomPagination from '../dashboards/components/CustomPagination'
import imagePath from '../../../constants/imagePath'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL
const GET_ARCHIVED_USERS_URL = `${API_URL}/auth/user-list`
const UNARCHIVE_USER_URL = `${API_URL}/auth/active-user`
const GET_ALL_GROUPS = `${API_URL}/group`

// create a component
const ArchivedUsers = () => {
  const { currentUser, auth, logout } = useAuth()
  const intl = useIntl()
  const [showCreateUser, setShowCreateUser] = useState(false)
  const [createUpdate, setCreateUpdate] = useState('create')
  const [allUsers, setAllUsers] = useState<any>([])
  const [allAdmins, setAllAdmins] = useState<any>([])
  const [overbin, setOverbin] = useState(false)
  const [currentUserEmail, setCurrentUserEmail] = useState('')
  const [user, setUser] = useState<any>()
  const [showWarning, setShowWarning] = useState(false)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)
  // const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [searchKey, setSearchKey] = useState('')
  const allRoles = ['TA Head', 'TA Lead', 'POC', 'Recruiter', 'Employee']
  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')
  const [roleMenuOpen, setRoleMenuOpen] = useState(false)
  const [groupMenuOpen, setGroupMenuOpen] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [allGroups, setAllGroups] = useState<any>([])
  const [params, setParams] = useState<any>({
    status: 'deleted',
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    group: '',
    role: '',
    search: '',
  })
  const [selectedFilters, setSelectedFilters] = useState<any>([])

  const getArchivedUsers = async (page) => {
    let data = { ...params }
    let currentRole = data.role.toLowerCase().replace(/ /g, '_')
    currentRole = currentRole.replace(/employee/g, 'none')
    data.role = currentRole
    // Filter out empty, undefined, or null values from params
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )
    await setLoadingTable(true)

    await axios
      .get(GET_ARCHIVED_USERS_URL, {
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoadingTable(false)
        let data = res?.data
        let pagination = data?.pagination
        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = { ...params }
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }
        // setCurrentPage(pagination?.page || 1)
        setTotalPages(pagination?.totalPages || 0)
        // setItemsPerPage(pagination?.limit || 0)
        let users = data?.data
        if (users) {
          setAllUsers(users)
        } else {
          setAllUsers([])
        }
      })
      .catch((error) => {
        setLoadingTable(false)
        handleErrors(error)
      })
  }

  // Fetching list of groups
  const getGroups = async () => {
    try {
      const response = await axios.get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })

      setAllGroups(response.data.data)
    } catch (error) {
      // Consolidated error handling
      handleErrors(error)
    }
  }

  const handleErrors = (error) => {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400) {
        setMessage(data.message)
      } else if (status === 401 && data.name === 'TokenExpiredError') {
        logout()
      } else if (data.error) {
        if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(data.error.message)
        }
      } else {
        setMessage('An unknown error occurred')
      }
    } else {
      setMessage(error.message)
    }

    setShowErrorMessage(true)
  }

  useEffect(() => {
    ; (() => {
      getArchivedUsers(params.page)
      if (allGroups === null || allGroups === undefined || allGroups.length === 0) {
        getGroups()
      }
    })()
  }, [params])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setGroupMenuOpen(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const unArchiveUser = async () => {
    const URL = UNARCHIVE_USER_URL
    const data = {
      email: currentUserEmail,
    }
    await axios
      .post(URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setMessage('User unarchive successfully')
        setShowSuccessPopup(true)

        getArchivedUsers(params.page)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setErrorMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setErrorMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setErrorMessage(error.message)
          setShowErrorMessage(true)
        }
      })

    // setMessage('');
    // setCurrentUserEmail('');
  }

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...allUsers].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    // setAllUsers(sorted);
    setSortField(field)
    setSortDirection(direction)
    let data = { ...params }
    data.order = field
    data.sort = direction === 'asc' ? 'ASC' : 'DESC'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  const handleSearchKeyChange = (value) => {
    setSearchKey(value)
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = { ...params }
    if (searchKey && searchKey !== data.search) {
      // setSearch(searchKey)
      data.search = searchKey
      data.page = 1
      setParams(data)
    } else {
      // setSearch('')
      // data.search = ''
      // data.page = 1
      // setParams(data)
    }
  }

  const toggleMenu = () => {
    setRoleMenuOpen(!roleMenuOpen)
  }
  const toggleGroupMenu = () => {
    setGroupMenuOpen(!groupMenuOpen)
  }

  const filterRoleChange = (roleValue) => {
    let data = { ...params }
    if (data.role === roleValue) {
      // setRole('');
      data.role = ''
      setParams(data)
    } else {
      // setRole(roleValue)
      data.role = roleValue
      setParams(data)
    }
  }

  const filterGroupChange = (groupValue) => {
    let data = { ...params }
    if (data.group === groupValue) {
      // setGroup('')
      data.group = ''
      setParams(data)
    } else {
      // setGroup(groupValue)
      data.group = groupValue
      setParams(data)
    }
  }

  const filterChange = (filterValue, type) => {
    setRoleMenuOpen(false)
    setGroupMenuOpen(false)
    // Check if data already exists in selectedFilters
    const index = selectedFilters.findIndex((filter) => filter.type === type && filter.value === filterValue)

    if (index !== -1) {
      // Data already exists, remove the object from the list
      setSelectedFilters((prevFilters) => prevFilters.filter((_, i) => i !== index))
    } else {
      // Data does not exist, add data into list
      setSelectedFilters((prevFilters) => [...prevFilters, { type: type, value: filterValue }])
    }
  }

  const removeFilter = async (filterValue, type) => {
    await setSelectedFilters((prevFilters) => prevFilters.filter((filter) => !(filter.type === type && filter.value === filterValue)))
    // filterItems()
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    if (roleMenuOpen) {
      setRoleMenuOpen(false)
    }
    if (groupMenuOpen) {
      setGroupMenuOpen(false)
    }
    // Extract role and group values from selectedFilters array
    const roles = selectedFilters.filter((filter) => filter.type === 'role').map((filter) => filter.value)
    const groups = selectedFilters.filter((filter) => filter.type === 'group').map((filter) => filter.value)

    // Join role and group values into comma-separated strings
    const rolesString = roles.join(',')
    const groupsString = groups.join(',')

    // Update params state with new role and group values
    setParams((prevParams) => ({
      ...prevParams,
      role: rolesString,
      group: groupsString,
    }))
  }

  const handleItemsPerPageChange = (event) => {
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  const resetFilters = async () => {
    let data = { ...params }
    data.order = 'updated_at'
    data.sort = 'DESC'
    data.page = 1
    data.limit = 10
    data.group = ''
    data.role = ''
    data.search = ''
    await setParams(data)

    getArchivedUsers(params.page)
  }

  return (
    <>
      <div className='card-bg mt-6 shadow-sm'>
        <div className='d-flex align-items-center pt-6 px-6 mb-4'>
          <label className='form-label fs-4 fw-bolder  mb-4'>All Users</label>
          <div className='d-flex align-items-center ms-auto'>
            <div className='d-flex me-4 align-items-center' style={{ border: 'solid 1px #F57536', borderRadius: '4px', height: '32px' }}>
              <input
                style={{ width: '24rem' }}
                className='search-bar fs-6 ps-2 py-1'
                type='text'
                placeholder='Search by user, email or employee id'
                value={searchKey}
                onChange={(event) => handleSearchKeyChange(event.target.value)}
                onKeyPress={handleKeyPress}
              />

              <div
                className='d-flex align-items-center px-2'
                style={{
                  cursor: 'pointer',
                  color: '#FFFFFF',
                  backgroundColor: '#F57536',
                  height: '31px',
                  borderRadius: '0px 4px 4px 0px',
                }}
                onClick={(e) => handleSearch(e)}
              >
                <label className='fs-7 fw-bolder' style={{ color: '#FFFFFF', marginLeft: '4px' }}>
                  Search
                </label>
                <img className='search-icon ms-2' src={imagePath.ic_search_white} alt='Search icon' />
              </div>
            </div>

            <div
              ref={dropdownRef}
              className={clsx(`d-flex flex-column align-items-center dropdown ${groupMenuOpen ? 'open' : ''}`)}
              id='kt_filter_group_menu_toggle_archive_users'
            >
              {/* begin::Toggle */}
              <div
                className={clsx('cursor-pointer symbol')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                // data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='bottom'
                onClick={toggleGroupMenu}
                style={{ width: '12.5rem' }}
              >
                <label className='fs-5 fw-bolder px-4 me-2 filter-bg'>
                  Filter by Group
                  <img className='ms-auto' src={imagePath.ic_filter_orange} width={14} height={14} />
                </label>
              </div>
              {/* end::Toggle */}
              {/* begin::Menu */}
              <div
                // className='archived-users-group-filter-menu'
                className={clsx(
                  'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6',
                  {
                    show: groupMenuOpen,
                  }
                )}
                style={{ width: '320px', maxWidth: '350px' }}
                data-kt-menu='true'
              >
                <div style={{ listStyleType: 'none', cursor: 'pointer', width: '320px', maxHeight: '180px', overflowY: 'auto' }}>
                  {allGroups ? (
                    allGroups.map((groupData, index) => {
                      const groupValue = groupData?.group_name
                      const isChecked = selectedFilters.some((filter) => filter.type === 'group' && filter.value === groupValue)
                      return (
                        <div
                          className=' hoverable-text mb-4 px-4 align-items-center d-flex'
                          style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                          key={index}
                        >
                          <input
                            name={groupValue}
                            className='form-check-input me-2'
                            style={{ height: '16px', width: '16px' }}
                            type='checkbox'
                            checked={isChecked}
                            onChange={(event) => filterChange(groupValue, 'group')}
                          />

                          <label className='form-check-label '>{groupValue}</label>
                        </div>
                      )
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
                {/* <div>
                                    <label
                                        className='fs-8 fw-bolder apply-filter-btn'
                                        // style={{ color: '#F36523' }}
                                        // disabled={selectedFilters === null || selectedFilters === undefined || selectedFilters.length === 0}
                                        onClick={() => {
                                            setGroupMenuOpen(false);
                                            if (!(selectedFilters === null || selectedFilters === undefined || selectedFilters.length === 0)) {
                                                filterItems();
                                            }
                                        }}
                                    >Apply
                                    </label>
                                </div> */}
              </div>
              {/* end::Menu */}
            </div>
            <div>
              <i
                className='bi bi-arrow-clockwise'
                style={{ fontSize: '28px', color: '#F36523', cursor: 'pointer' }}
                onClick={resetFilters}
              ></i>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* show chips of applied filters */}
        <div className='d-flex mx-6 mb-6 pb-4' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {selectedFilters ? (
            selectedFilters.map((filterData, index) => {
              return (
                <div key={index} className='d-flex orange-tag align-items-center me-4' style={{ height: '34px' }}>
                  {filterData?.value === 'none' ? 'Employee' : filterData?.value}
                  <img
                    className='ms-2'
                    style={{ cursor: 'pointer' }}
                    src={imagePath.ic_cross_orange}
                    onClick={() => removeFilter(filterData.value, filterData.type)}
                  />
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>
        {/* show table of users */}
        <div style={{ position: 'relative' }}>
          {loadingTable && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          <div className='table-responsive px-6 pb-8'>
            {/* begin::Table */}

            <table className='table my-table mx-auto table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 px-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold graycolor grajabg px-4'>
                  <th
                    onClick={() => sortByField('employee_id')}
                    className='align-items-center ps-4'
                    style={{ width: '10rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Employee ID</label>
                    {renderSortIcon('employee_id')}
                  </th>

                  <th
                    onClick={() => sortByField('first_name')}
                    className='align-items-center'
                    style={{ width: '14rem', marginLeft: '1rem', color: '#000000' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2 ps-2'>User</label>
                    {renderSortIcon('first_name')}
                  </th>
                  <th onClick={() => sortByField('email')} className='align-items-center' style={{ width: '14rem', color: '#000000' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Email</label>
                    {renderSortIcon('email')}
                  </th>
                  <th onClick={() => sortByField('group')} className='align-items-center' style={{ width: '14rem', color: '#000000' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Group</label>
                    {renderSortIcon('group')}
                  </th>
                  {/* <th onClick={() => sortByField('role')}
                                        className='align-items-center'
                                        style={{ width: '8rem', color: '#000000' }}>
                                        <label className=' form-label fw-bolder fs-6 me-2'>
                                            Role
                                        </label>
                                        {renderSortIcon('role')}
                                    </th> */}
                  <th className='min-w-100px text-center fs-6 fw-bolder' style={{ minWidth: '10rem', color: '#000000' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Action</label>
                  </th>
                  {/* <th className='min-w-100px '></th> */}
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers.map((userData, index) => {
                    const isEvenRow = index % 2 === 0
                    return (
                      <tr
                        key={index}
                        className='px-4'
                        style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}
                      >
                        <td style={{ width: '10rem' }}>
                          <label className='form-label fs-7 ps-4 fw-bolder'>{`${userData?.employee_id}`}</label>
                        </td>
                        <td style={{ width: '14rem' }}>
                          <div className='d-flex align-items-center'>
                            <img
                              style={{ height: '44px', width: '44px', borderRadius: '22px', marginRight: '8px', objectFit: 'cover' }}
                              src={userData?.profile_pic ? userData?.profile_pic : userIcon}
                            />
                            <label className='form-label fs-7 fw-bolder'>{`${userData?.fullName ? userData?.fullName : ''}`}</label>
                          </div>
                        </td>
                        <td style={{ width: '14rem' }}>
                          <label className='form-label fs-7 fw-bolder'>{userData?.email}</label>
                        </td>
                        <td style={{ width: '14rem' }}>
                          <label className='form-label fs-7 fw-bolder'>{userData?.group}</label>
                        </td>
                        {/* <td style={{ width: '8rem' }}>
                                                <label className='form-label fs-7 fw-bolder'>{`${userData?.roleName}`}</label>
                                            </td> */}
                        <td style={{ width: '10rem', textAlign: 'center' }}>
                          <div className='d-flex align-items-center justify-content-center'>
                            <div
                              className='candi-btn2 text-white m-1'
                              onClick={() => {
                                setCurrentUserEmail(userData?.email)
                                // setMessage('Are you sure you want to Activate the user ?');
                                setMessage(
                                  `Are you sure you want to Activate the user ${userData?.first_name ? userData?.first_name : ''} ${userData?.last_name ? userData?.last_name : ''
                                  }?`
                                )
                                setShowWarning(true)
                              }}
                            >
                              <span style={{ fontWeight: 'bold' }}>Activate</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            {(allUsers && allUsers.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
              <div className='col-md-4'>
                <label>{`Showing ${allUsers ? allUsers.length : 0} of ${params.total} results.`}</label>
              </div>
              <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
              </div>

              <div className='col-md-4 '>
                <div className='ms-auto d-flex flex-end align-items-center'>
                  <select
                    className='me-2 pagination-input'
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                    id='selectValues'
                    value={params.limit}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>

                  <div className='me-2'>
                    <label>Items per page</label>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>

      {showWarning && (
        <WarningTARemovePopup
          show={true}
          message={message}
          handleClose={() => {
            setMessage('')
            setCurrentUserEmail('')
            setShowWarning(false)
          }}
          handleYesSure={() => {
            setShowWarning(false)
            unArchiveUser()
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={errorMessage} />}
      {showSuccessPopup && <SuccessDialog handleClose={() => setShowSuccessPopup(false)} message={message} />}
    </>
  )
}

//make this component available to the app
export default ArchivedUsers
