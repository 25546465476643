//import liraries
import React from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import Layout from '../images/Layout-4-blocks (3).png';
import icInfo from './img/information (3)@2x.png';
import icUpload from './img/ic_upload.png';
import icSuccessGreen from './img/ic_success_green.png.png';
import './Success.css'
import imagePath from '../../../../constants/imagePath';

type Props = {
    show: boolean
    handleClose: () => void
    uploadFile: () => void
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    file: File | null
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const ImportPositionsPopup = ({ show, handleClose, uploadFile, handleFileChange, file }: Props) => {

    const handleSubmit = () => {
        console.log('closed')
        // handleClose();
        uploadFile();
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
            backdropClassName="modal-backdrop backdrop-blur"
        >
            <div className=' no-header-model123'>
                <div className='modal-header no-header-model123'>
                    <h2></h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                        <img alt='' src={Layout}></img>
                    </div>
                </div>
                {/* end::Close */}
                <div className=' d-flex flex-column '>
                    <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <h3 className='fw-bolder mx-auto my-5' style={{ color: file ? '#0BB783' : '#F36523' }}>{file ? 'File is ready to upload' : 'Import Positions from CSV'}</h3>
                            <img alt='' className='infoIcImg' src={file ? icSuccessGreen : imagePath.ic_upload_orange}></img>
                        </div>
                    </label>
                    <input type="file" id="csv-upload" style={{ display: 'none' }} accept=".csv" onChange={(event) => handleFileChange(event)} />
                    <div className='col-md-8 mt-10' style={{ alignSelf: 'center' }}>
                        <label>
                            <img className='me-2' alt='' src={icInfo} width={'14px'} ></img>
                            The CSV file must contain the following data points:
                        </label>
                        <ul className='mt-4'>
                            <li className='d-flex' > <span className="dot">.</span> <p className='fs-5 mt-3'> Functional Design </p></li>
                            <li className='d-flex'> <span className="dot">.</span> <p className='fs-5 mt-3'> Max Bsic Salary / Month</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Max Total Remuneration / Month</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Maximum Grade Range</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Regular / Bank Contract / Third party</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Functional Area</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Sub Functional Area</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Section</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Regional Office / Branch / Cluster Office</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Code</p></li>
                            <li className='d-flex '> <span className="dot">.</span> <p className='fs-5 mt-3'> Location</p></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
                <form
                //  onSubmit={handleSubmit}
                >
                    <div className='btn-container'>
                        <button
                            onClick={handleSubmit}
                            type='button'
                            className='btn px-10 apply-review-button text-white'
                            disabled={!file}
                        >
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default ImportPositionsPopup;
