//import liraries
import React, { Component, useEffect, useState } from 'react';
import imagePath from '../../../../../constants/imagePath';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import axios from 'axios';
import { useAuth } from '../../../auth/core/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import AddBannerDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddBannerDialog';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';

const API_URL = process.env.REACT_APP_API_URL;
const UPLOAD_DOC_URL = `${API_URL}/configuration/marketing-creatives-upload`;
const ADD_BANNERS_URL = `${API_URL}/configuration/add-marketing-creatives`

// create a component
const MarketingCreatives = ({ configurationId, banners, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [bannerList, setBannerList] = useState<any>([])
    const [showAddBannerPopup, setShowAddBannerPopup] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {

        if (banners !== null && banners !== undefined) {
            banners = JSON.parse(banners);
            const initialData = Object.keys(banners).map((key) => ({
                link: banners[key]['name'],
                file: null,
                fileUrl: banners[key]['link']
            }));
            setBannerList(initialData);
        }
    }, [banners]);


    const handleAddMarketingInfo = (e) => {
        e.preventDefault();

        if (bannerList && bannerList.length < 5) {
            setShowAddBannerPopup(true);
        } else {
            setMessage('Can not add more than 5 Marketing Creatives!');
            setShowErrorMessage(true);
        }
    }

    const handleLinkChange = (e, index) => {
        const value = e.target.value;
        const data = [...bannerList];
        data[index].link = value;
        setBannerList(data);

    }

    const handleFileChange = async (event, index) => {
        const file = event.target.files[0]
        const fileName = file.name.toLowerCase()
        // Validate file type

        if (!['.jpeg', '.jpg', '.png', '.svg'].some((ext) => fileName.toLowerCase().endsWith(ext))) {
            setMessage('Invalid file type. Please upload a jpeg, jpg, png, or svg image file.');
            setShowErrorMessage(true);
            return;
        }

        if (file !== null && file !== undefined) {
            const formData = new FormData();
            formData.append("file", file)
            await axios.post(UPLOAD_DOC_URL, formData, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                },
            }).then((res) => {
                // fetch updated data
                // getConfigurations();
                setDisabled(false);

                let data = [...bannerList];
                data[index].file = file;
                data[index].fileUrl = res.data?.marketingUrl;
                setBannerList(data)

            }).catch((error) => {
                setDisabled(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });

        } else {
            setMessage('File not found Please upload file first.');
            setShowErrorMessage(true);
        }
    }

    function getFileNameFromUrl(url) {
        // Use the URL constructor to parse the URL
        const parsedUrl = new URL(url);

        // Access the pathname of the URL, which contains the file name
        const pathName = parsedUrl.pathname;

        // Split the pathname by '/' to get an array of path segments
        const pathSegments = pathName.split('/');

        // The last segment in the array should be the file name
        const fileName = pathSegments[pathSegments.length - 1];

        return fileName;
    }

    const removeFields = (index) => {
        let data = [...bannerList];
        data.splice(index, 1)
        setBannerList(data)
    }

    const handleSaveClick = async (e) => {
        e.preventDefault();

        const extraDocs = bannerList.map((field, index) => ({
            [index]: {
                name: field.link,
                link: field.fileUrl,
            },
        }));
        const formattedExtraDocs = Object.assign({}, ...extraDocs);
        let data = {
            configuration_id: configurationId,
            marketing_creatives: formattedExtraDocs
        }
        setDisabled(true);
        await axios.post(ADD_BANNERS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setDisabled(false);
            // show success popup
            setMessage('Marketing Creatives Saved Successfully!');
            setShowSuccessPopup(true);
        }).catch((error) => {
            setDisabled(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });

    }

    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative' style={{ minHeight: '26rem' }}>

            <div >
                <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Marketing Creatives</label>
                    <div className='ms-auto'>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            style={{ position: 'relative', zIndex: '1' }}
                            onClick={(e) => {
                                if (!disabled) {
                                    handleAddMarketingInfo(e)
                                }

                            }}
                        >
                            Add New
                        </div>
                    </div>
                </div>
                <div className='mx-4 d-flex mt-6 justify-content-center' style={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                    {bannerList.map((item, index) => (
                        <div key={index} className="extra-doc-card-size px-4 py-4 me-2 " style={{ backgroundColor: 'white', position: 'relative', zIndex: '1' }}>

                            <div className='shadow-sm p-2' style={{ borderRadius: '8px' }}>
                                <div >
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Paste CTA link here'
                                        value={item?.link ? item?.link : ''}
                                        onChange={(event) => handleLinkChange(event, index)}
                                    />
                                </div>
                                <div className='drop-zone_extra_doc mt-4 mb-2' >
                                    <div className='drop-wrapper'>
                                        <div className='drop-image-container'>
                                            <img
                                                className='drop-zone__img'
                                                src={imagePath.ic_drag_drop}
                                                alt='Document File'
                                            />
                                        </div>
                                        <div className='drop-image-container'>
                                            <span className='fs-7 fw-bolder' style={{ color: '#373D4A' }}>Drag & Drop Replace Document</span>

                                            <span className='drop-zone__prompt fs-9 mt-4'>
                                                Accepted formats includes .jpeg, .jpg, .png and .svg
                                            </span>
                                        </div>
                                    </div>
                                    <div className='cv-container'>
                                        <input
                                            type='file'
                                            accept='.jpeg,.jpg,.png,.svg'
                                            name='myFile'
                                            className='drop-zone__input'
                                            onChange={(event) => handleFileChange(event, index)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    {(item?.file || item?.fileUrl) ? (

                                        <label className='light-txt fs-6 fw-regular'
                                            style={{ maxWidth: '18rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            title={`${item?.file ? ((item?.file).name) : getFileNameFromUrl(item?.fileUrl)}`}>
                                            <span className='fs-6 ' style={{ color: '#484848' }}>Selected file: </span>
                                            {`${item?.file ? ((item?.file).name) : getFileNameFromUrl(item?.fileUrl)}`}
                                        </label>

                                    ) : (<div style={{ height: '1.55rem' }}></div>)}
                                    <button
                                        style={{ marginLeft: 'auto', color: '#F36523' }}
                                        className="btn-close btn-sm "
                                        onClick={() => removeFields(index)}
                                    ></button>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

                <div className='d-flex justify-content-center mt-auto'>
                    {disabled ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            color='#F36523'
                            spin
                            size='3x'
                        />) :
                        (<div
                            className='candi-btn2 text-white m-5'
                            // type='button'
                            onClick={(e) => {
                                if (!disabled) {
                                    handleSaveClick(e)
                                }

                            }}
                        >
                            <span style={{ fontWeight: 'bold' }}>Save</span>
                        </div>)}
                </div>
            </div>

            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_campaign_orange} style={{ width: '220px', height: '220px', zIndex: '-1' }} />
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}

            {showAddBannerPopup && (
                <AddBannerDialog
                    handleClose={() => { setShowAddBannerPopup(false) }}
                    handleSave={async (link, selectedFile, fileUrl) => {
                        let newDoc = {
                            link: link,
                            file: selectedFile,
                            fileUrl: fileUrl
                        };
                        await setBannerList([...bannerList, newDoc]);
                        setShowAddBannerPopup(false)
                    }}
                />
            )}
            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

        </div>
    );
};

//make this component available to the app
export default MarketingCreatives;
