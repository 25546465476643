//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import imagePath from '../../../../constants/imagePath';
import './ModelStyles.css';
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import ShowError from '../../content/toasts/ShowError';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const API_URL = process.env.REACT_APP_API_URL;
const UPLOAD_DOC_URL = `${API_URL}/configuration/doc-upload`;

type Props = {
    handleClose: () => void
    handleSave: (documentName: string, documentType: string, selectedFile: File | null, fileUrl: string) => void
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddExtraDocDialog = ({ handleClose, handleSave }: Props) => {
    const { currentUser, auth, logout } = useAuth();
    const [documentName, setDocumentName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileUrl, setFileUrl] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDocumentNameChange = (e) => {
        setDocumentName(e.target.value);
    };

    const handleDocumentTypeChange = (e) => {
        setDocumentType(e.target.value)
    }

    // const handleFileDrop = (e) => {
    //     e.preventDefault();
    //     const file = e.dataTransfer.files[0];
    //     setSelectedFile(file);
    // };

    const handleFileSelect = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const fileName = file.name.toLowerCase()
        // Validate file type

        if (!['.pdf', '.doc', '.docx'].some((ext) => fileName.endsWith(ext))) {
            alert('Invalid file type. Please upload a .pdf, .doc, or .docx file.')
            setMessage('Invalid file type. Please upload a .pdf, .doc, or .docx file.');
            setShowErrorMessage(true);
            return
        }

        if (file !== null && file !== undefined) {
            const formData = new FormData();
            formData.append("file", file)
            setLoading(true);
            await axios.post(UPLOAD_DOC_URL, formData, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                },
            }).then((res) => {
                setLoading(false);

                setSelectedFile(file);
                setFileUrl(res.data?.docUrl);

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });

        } else {
            window.alert('File not found Please upload file first.')
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123 px-6 py-4 position-relative'>
                <div className='d-flex align-items-center mx-6'>
                    <label className='fs-3 fw-bolder'>Extra Document</label>
                    <img className='ms-auto cursor-pointer' src={imagePath.ic_close} onClick={handleClose} />
                </div>
                {/* end::Close */}
                <div className='mx-6'>
                    <form>
                        <div className='d-flex align-items-center mt-8 col-md-12'>
                            <div className="me-4">
                                <label htmlFor="documentName" className="form-label fs-6" style={{ color: '#373D4A' }}>Title<span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ height: '40px' }}
                                    placeholder='Enter Document Name'
                                    id="documentName"
                                    value={documentName}
                                    onChange={handleDocumentNameChange}
                                />
                            </div>
                            <div className="ms-4" >
                                <label htmlFor="documentType" className="form-label fs-6" style={{ color: '#373D4A' }}>Document Type<span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-select"
                                    style={{ height: '40px' }}
                                    placeholder='Select Document Type'
                                    id="documentType"
                                    value={documentType}
                                    onChange={handleDocumentTypeChange}
                                >
                                    <option value="">Select Document Type</option>
                                    <option value="pdf">PDF</option>
                                    <option value="doc">DOC</option>
                                    <option value="docx">DOCX</option>
                                    <option value="png">PNG</option>
                                    <option value="jpg">JPG</option>
                                </select>
                            </div>
                        </div>
                        <div
                            className="drop-zone-extra-doc-popup my-6"
                            onDrop={handleFileSelect}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <div className='drop-image-container'>
                                <div className='drop-image-container'>
                                    {loading ? (
                                        <div style={{ display: 'flex', height: '64px', width: '64px', justifyContent: 'center', alignItems: 'center' }}>
                                            <FontAwesomeIcon
                                                icon={faSpinner}
                                                color='#F36523'
                                                spin
                                                size='3x'
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    // height: '64px',
                                                    // width: '64px',
                                                }}
                                            /></div>) : (
                                        <img
                                            className='drop-zone__img'
                                            src={imagePath.ic_drag_drop}
                                            alt='Document File'
                                            style={{ height: '64px', width: '64px' }}
                                        />
                                    )}

                                </div>
                                <span className='fs-7 fw-bolder' style={{ color: '#373D4A' }}>Drag & Drop Document</span>

                                <span className='drop-zone__prompt fs-9 mt-4'>
                                    Accepted formats includes .pdf, .doc or .docx
                                </span>
                            </div>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileSelect}
                                className="drop-zone-input"
                            />
                        </div>
                        <label htmlFor="documentName" className="form-label fs-6" style={{ color: '#373D4A' }}>Selected File<span style={{ color: 'red' }}>*</span></label>
                        {selectedFile ? (
                            <div className="form-control align-items-center d-flex" style={{ height: '40px' }} >
                                <label className='fs-6' style={{ maxWidth: '28rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    {selectedFile.name}
                                </label>
                                <img className='cursor-pointer ms-auto'
                                    src={imagePath.ic_delete_orange_cross} height={18} width={18}
                                    onClick={() => setSelectedFile(null)} />
                            </div>
                        ) : (<div style={{ height: '40px' }}></div>)}
                        <button
                            onClick={() => handleSave(documentName, documentType, selectedFile, fileUrl)}
                            type='button'
                            className='d-flex btn px-18 py-2 apply-review-button my-8 mx-auto'
                            style={{ color: 'white' }}
                            disabled={!documentName || !selectedFile || !documentType}
                        >
                            Save
                        </button>
                    </form>
                </div>

                {/* Positioning the image in the bottom right corner */}
                <div className='position-absolute bottom-0 end-0'>
                    <img src={imagePath.ic_docs_orange} style={{ width: '220px', height: '220px', opacity: '0.08', zIndex: '-1' }} />
                </div>

                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default AddExtraDocDialog;
