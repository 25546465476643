//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyleDoc.css'
import icCameraGrey from './img/ic_camera_grey_border.png';
import icCameraChecked from './img/ic_camera_checked_green_border_.png';
import LoaderView from './LoaderView';

import addcompetenctimage from './img/22 [Converted]@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import icPreviousGrey from './img/ic_previous_grey.png';
import icPreviousOrange from './img/ic_previous_orange.png';
import icNextGrey from './img/ic_next_grey.png';
import icNextOrange from './img/ic_next_orange.png';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import ShowError from '../../content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
const API_URL = process.env.REACT_APP_API_URL
export const UPLOAD_DOCUMENTS = `${API_URL}/document/upload-docs`



const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
    show: boolean
    handleClose: () => void
    inProcessJobs: any
    checkPendingDocs: () => void
}

// create a component
const UploadRequestedDocsPopup = ({ show, handleClose, inProcessJobs, checkPendingDocs }: Props) => {

    const { auth, logout } = useAuth()
    const { currentUser } = useAuth()
    const [requiredDocs, setRequiredDocs] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [allFilesAvailable, setAllFilesAvailable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        setRequiredDocs(inProcessJobs);
    }, [])

    const handleNext = () => {
        if (activeIndex < requiredDocs.length - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleUploadPhoto = (event, index) => {
        const file = event.target.files[0];
        // Check if a file was selected
        if (!file) {
            return;
        }

        // Regular expression to check if the file extension is valid
        const allowedExtensions = /\.(jpg|png|pdf)$/i;

        // Check if the file extension is allowed
        if (!allowedExtensions.test(file.name)) {
            setMessage('Invalid file type. Please upload a .jpg, .png, or .pdf file.');
            setShowErrorMessage(true)
            return;
        }

        const updatedDocs = [...requiredDocs];

        // let title = updatedDocs[activeIndex]['documents'][index]['doc']['doc_name'];
        // // Extract file extension
        // const fileExtension = title ? title.split('.').pop().toLowerCase() : '';

        // // Define accept format based on file extension
        // let acceptFormat = '.jpg, .png, .pdf, .doc, .docx';
        // if (fileExtension) {
        //     acceptFormat = `.${fileExtension}`;
        // }

        // // Get the MIME type of the selected file
        // const fileType = file.type;
        // // Check if the file extension or MIME type matches any of the accepted formats
        // if (!acceptFormat.split(',').some(format => fileType.includes(format.trim()) || file.name.endsWith(format.trim()))) {
        //     // File type doesn't match the accepted formats, show warning
        //     setMessage(`Invalid file type. Please select a file with one of the following extensions: ${acceptFormat}`);
        //     setShowErrorMessage(true);
        //     return;
        // }

        updatedDocs[activeIndex]['documents'][index]['doc']['photo'] = file;
        setRequiredDocs(updatedDocs);
    };

    const downloadFile = (url) => {
        if (!url) {
            setMessage('Form not available!');
            setShowErrorMessage(true)
            return;
        }

        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', url.split('/').pop() || 'file');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.log('Error downloading file:', error);
            });
    };

    const checkAllFilesAvailable = () => {
        //checking all required documents have files or not
        let fileNotFound = false;
        if (requiredDocs.length > 0) {
            requiredDocs[activeIndex]['documents'].map((document) => {
                let doc = document?.doc;
                let photo = doc.photo;
                if (photo === undefined || photo === null) {
                    console.log('photoFile: ', photo);
                    fileNotFound = true;
                }
            });
        }

        console.log('fileNotFound: ', fileNotFound);
        if (!fileNotFound) {
            setAllFilesAvailable(true);
        } else {
            setAllFilesAvailable(false);
        }
    }

    useEffect(() => {
        checkAllFilesAvailable();

    }, [requiredDocs, activeIndex])

    const handleUpload = (e) => {

        if (requiredDocs.length > 0) {
            const user_id: any = currentUser?.user_id
            if (!user_id) {
                return;
            }
            const form = new FormData();
            let jobapp_id = requiredDocs[activeIndex]?.jobapp_id;
            form.append('candidate_id', user_id);
            form.append('jobapp_id', jobapp_id);
            const documents = requiredDocs[activeIndex]?.documents;
            if (documents) {
                setLoading(true);
                documents.map((document, index) => {
                    let doc = document?.doc;
                    if (doc) {
                        form.append(doc.doc_name, doc.photo);
                    }
                });
                console.log('docsForm: ', form);
                axios.post(UPLOAD_DOCUMENTS, form, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'content-type': 'multipart/form-data',
                    },
                }).then((res) => {
                    console.log('RESPONSE FROM DOCUMENTS UPLOAD: ' + res)
                    setLoading(false)
                    // update list of required documents
                    if (activeIndex < requiredDocs.length - 1) {
                        setActiveIndex(activeIndex + 1);
                    } else {
                        handleClose();
                    }
                    // checkPendingDocs()

                }).catch((error) => {
                    setLoading(false);
                    if (error.response && error.response.status === 400) {
                        // showToast(error.response.data.message)
                        setMessage(error.response.data.message)
                        setShowErrorMessage(true)
                    } else if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data &&
                        error.response.data.name === 'TokenExpiredError'
                    ) {
                        logout()
                    } else if (error.response?.data?.error) {
                        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                            logout()
                        } else {
                            setMessage(error.response.data.error.message)
                            setShowErrorMessage(true)
                        }
                    } else {
                        setMessage(error.message)
                        setShowErrorMessage(true)
                    }
                })
            }

        }


    }


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            //   onHide={handleClose}
            backdrop={true}
        >
            <>
                <div className=' upload-doc-header' style={{ borderRadius: '8px' }}>
                    <div className='modal-header no-header-model123'>
                        <h2></h2>
                        {/* begin::Close */}
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                            <img alt='' src={imagePath.ic_cross_white}></img>
                        </div>
                    </div>
                    {/* end::Close */}
                    <div className='mx-auto d-flex flex-column '>
                        <img alt='' src={addcompetenctimage} height={102} width={160} style={{ objectFit: 'cover', marginLeft: 'auto', marginRight: 'auto' }}></img>
                        <h3 className='req-doc-text'>
                            Upload Documents
                        </h3>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <div className='d-flex flex-column mb-6' style={{ alignItems: 'center' }}>
                        <label id='position-label' className='form-label fw-bolder fs-6 px-3 py-1' style={{ color: '#80808F', borderRadius: '4px', backgroundColor: '#F3F6F9', whiteSpace: 'nowrap' }}>
                            {`Position: ${requiredDocs.length > 0 ? (requiredDocs[activeIndex]?.job_name) : 'Name'}`}</label>
                    </div>
                    <div className='d-flex flex-column'>
                        <label className='form-label fw-bolder fs-4'>Requirements</label>
                        <label className='form-label fs-7'>File type should be .jpg, .png or .pdf</label>
                    </div>
                    <div className="d-flex  align-items-center ">
                        <div id='previous-btn'>
                            <img
                                style={{ cursor: 'pointer' }}
                                onClick={handlePrevious}
                                // disabled={activeIndex === 0}
                                src={activeIndex === 0 ? icPreviousGrey : icPreviousOrange}
                            />
                        </div>
                        <div id='content-div' style={{ flex: '1' }}>

                            {requiredDocs.length > 0 ? requiredDocs[activeIndex].documents ?
                                requiredDocs[activeIndex].documents.map((document, index) => {
                                    console.log('documentData: ', document.doc);
                                    let doc = document?.doc;
                                    let title = doc?.doc_name;
                                    // Extract file extension
                                    const fileExtension = title ? title.split('.').pop().toLowerCase() : '';

                                    // Define accept format based on file extension
                                    // let acceptFormat = '.jpg, .png, .pdf, .doc, .docx';
                                    // if (fileExtension) {
                                    //     acceptFormat = `.${fileExtension}`;
                                    // }
                                    return (
                                        <div className='d-flex align-items-center mx-4 mb-4'>

                                            <div className='me-2'>
                                                <label htmlFor={`extra-doc-${index}`} className='upload-img' style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={doc?.photo ? icCameraChecked : icCameraGrey}

                                                    />
                                                </label>
                                                <input type='file'
                                                    id={`extra-doc-${index}`}
                                                    accept=".jpg, .png, .pdf"
                                                    // accept={acceptFormat}
                                                    style={{ display: 'none' }}
                                                    //create document and upload file 
                                                    onChange={(e) => handleUploadPhoto(e, index)}
                                                />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <label className='fw-bolder fs-7'> {doc['doc_name'].replace(/_/g, ' ')} </label>
                                                {doc?.doc_type === 'extra' ? (
                                                    <label className='fw-bolder fs-7' style={{ color: '#F36523', cursor: 'pointer' }}
                                                        onClick={() => downloadFile(doc.doc_link)}
                                                    > Download Form
                                                    </label>
                                                ) : (<div></div>)}
                                            </div>

                                        </div>
                                    )
                                }) : (<div></div>)
                                : (<div></div>)}

                            <div className='req-btn-cont mt-10'>
                                <button
                                    // className={'fw-bolder px-8 grey-btn'}
                                    className={`fw-bolder px-8 ${allFilesAvailable ? 'req-btn' : 'grey-btn'} `}
                                    // disabled={!(photograph && cnicScan && salarySlip && evidenceBenefits && experienceCertificate && degree && covidCertificate)}
                                    onClick={(e) => handleUpload(e)}>
                                    Upload
                                </button>
                            </div>
                        </div>
                        <div id='next-btn'>
                            <img
                                style={{ cursor: 'pointer' }}
                                onClick={handleNext}
                                // disabled={activeIndex === requiredDocs.length - 1}
                                src={activeIndex === requiredDocs.length - 1 ? icNextGrey : icNextOrange}
                            >
                            </img>
                        </div>
                    </div>




                </div>
                {loading && (
                    <LoaderView
                        message='Loading...'
                    />
                )}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </>
        </Modal>,
        modalsRoot
    )
};
//make this component available to the app
export default UploadRequestedDocsPopup;
