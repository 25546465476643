import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {Card2} from '../../../../_metronic/partials/content/cards/Card2'
import './ActiveJobs.css'
import {useNavigate} from 'react-router-dom'

function ActiveJobs() {
  const intl = useIntl()
  const navigate = useNavigate()
  const [items, setItems] = useState<any>([])

  return (
    <div className='row mt-20 g-5 g-xl-8'>
      {/* <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-1'>
          Active Posts &nbsp;
          <span className='fs-6 text-gray-400 fw-semibold ms-1'>Active</span>
        </h3>
      </div> */}

      {/* <div className='me-6'>
        <ApplicationsTable className= 'table-responsive'/>
      </div> */}
      <div>
        <Card2
          icon='/media/svg/brand-logos/plurk.svg'
          badgeColor='primary'
          status='In Progress'
          statusColor='primary'
          title='Fitnes App'
          description='CRM App application to HR efficiency'
          date='November 10, 2021'
          budget='$284,900.00'
          progress={50}
        />
      </div>
    </div>
  )
}

export default ActiveJobs
