import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import './JobsFunelling.css'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import ApplicationsTable from './ApplicationsTable'
import { Link, useParams } from 'react-router-dom'
import dropdownarroe from './images/drop down arrow white.png'
import InterviewMainScreen from './InterviewPanel/Components/InterviewMainScreen'
import ApplicationsTableOnlineTest from './OnlineTest/OnlineTestNewPage'
import ApprovalTable from './Approvals/ApprovalTable'
import HiredMainScreen from './Hired/HiredMainScreen'
import { useAuth } from '../auth/core/Auth'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { type } from 'os'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const GET_JOBS = `${API_URL}/job/`
export const GET_ALL_JOBS_RECRUITER = `${API_URL}/job`
const GET_ALL_JOBS_ADMIN = `${API_URL}/job/show-status-jobs`

export const GET_ALL_JOBS = `${API_URL}/job`

const JobFunnelingMainScreen = () => {
  const { auth, logout } = useAuth()
  const [display, setdisplay] = useState(false)
  const [jobTitleList, setJobTitleList] = useState<any>([])
  const [JobTitleSettingDropDown, setJobTitleSettingDropDown] = useState<any>([])
  const [JobApplicationTab, setJobApplicationTab] = useState(true)
  const [OnlineTestTab, setOnlineTestTab] = useState(false)
  const [ApprovaTabs, setApprovaTabs] = useState(false)
  const [HiredTab, setHiredTab] = useState(false)
  const [InterviewTab, setInterviewTab] = useState(false)
  const [testExists, setTestExists] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState('')
  const [fetchedId, setFetchedId] = useState<any>()
  const [items, setItems] = useState<any>([])
  let { id } = useParams<any>()
  const [trigger, setTrigger] = useState(0)
  const [activeTab, setActiveTab] = useState('JobApplicationTab')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const jobsRef = useRef<HTMLButtonElement>(null);

  const handleDocumentClick = (event: MouseEvent) => {
    if (jobsRef.current && !jobsRef.current.contains(event.target as Node)) {
      // setRecruiterResults([])
      setdisplay(false)
    }
  }

  // Attach click event listener to the document
  // This is to detect clicks outside the component and hide the dropdown results
  // Make sure to clean up the event listener when the component is unmounted
  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    let localUser: any = localStorage.getItem('kt-auth-react-v')
    setItems(JSON.parse(localUser))
  }, [])

  console.log({ id })

  const getAllJobs = async () => {
    await axios
      .get(GET_ALL_JOBS_ADMIN, {
        params: {
          job_status: 'active',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        const titles = res?.data.data.map((item) => ({
          title: item.title,
          job_id: item.job_id,
        }))
        setJobTitleList(titles)

        const matchedJobTitle = titles.find((title) => title.job_id === id)
        if (matchedJobTitle) {
          setJobTitleSettingDropDown([matchedJobTitle])
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getAllJobsRecruiter = async () => {
    await axios
      .get(GET_ALL_JOBS_RECRUITER, {
        params: {
          job_status: 'active',
          recruiter_id: items?.user_id,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        console.log('fetchedJobs:', res )
        const titles = res?.data.jobs.map((item) => ({
          title: item.title,
          job_id: item.job_id,
        }))
        setJobTitleList(titles)

        const matchedJobTitle = titles.find((title) => title.job_id === id)
        if (matchedJobTitle) {
          setJobTitleSettingDropDown([matchedJobTitle])
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getJobs = async () => {
    axios
      .get(GET_JOBS + id, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data
        let testExistsProp = data?.test_id ? true : false
        setTestExists(testExistsProp)
        setJobTitleSettingDropDown({ title: res?.data?.title, job_id: res?.data?.job_id })
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const SetInterviewTabActiveFunction = () => {
    setApprovaTabs(false)
    setHiredTab(false)
    setJobApplicationTab(false)
    // setHiredTab(false)
    setInterviewTab(true)
    setOnlineTestTab(false)
    // setActiveTab('InterviewTab');
  }

  const SetApprovalTabsActiveFunction = () => {
    setApprovaTabs(true)
    setHiredTab(false)
    setJobApplicationTab(false)
    // setHiredTab(false)
    setInterviewTab(false)
    setOnlineTestTab(false)
    // setActiveTab('ApprovaTabs');
  }
  const SetHiredTabActiveFunction = () => {
    setApprovaTabs(false)
    setHiredTab(true)
    setJobApplicationTab(false)
    // setHiredTab(true)
    setInterviewTab(false)
    setOnlineTestTab(false)
    // setActiveTab('HiredTab');
  }
  const SetJobTabtoActiveFunction = () => {
    setApprovaTabs(false)
    setHiredTab(false)
    setJobApplicationTab(true)
    // setHiredTab(false)
    setInterviewTab(false)
    setOnlineTestTab(false)
    // setActiveTab('JobApplicationTab');
  }

  const SetOnlineTestTabFunction = () => {
    setApprovaTabs(false)
    setHiredTab(false)
    setJobApplicationTab(false)
    // setHiredTab(false)
    setInterviewTab(false)
    setOnlineTestTab(true)
    // setActiveTab('OnlineTestTab');
  }

  const displaySuggestions = (e) => {
    e.preventDefault()
    setdisplay(!display)
    // console.log(display)
  }

  type RefType = { GetAllJobsApplicationsbypage: () => Promise<void> }
  const groupResourcesRef = useRef<RefType>(null)
  const SetJobTitle = (e, selectedJobTitle) => {
    const updatedDropDown = [selectedJobTitle]
    setJobTitleSettingDropDown(updatedDropDown)
    setdisplay(false)
    setFetchedId(selectedJobTitle?.job_id)
    setTrigger((trigger) => trigger + 1)
  }

  useEffect(() => {
    if (id) {
      setFetchedId(id)
    }
  }, [id])

  useEffect(() => {
    ; (async () => {
      // if (items?.role === 'masterRecruiter') {
      //   await getAllJobs()
      // } else 
      if (items?.role === 'recruiter') {
        await getAllJobsRecruiter()
      } else {
        await getAllJobs()
      }
    })()
    getJobs()
  }, [testExists])

  const childRef = useRef<RefType>(null)

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Jobs  ' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/jobs" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Jobs
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="#" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Job Funneling
          </Link>
        </h3>
      </div>

      <div className=' d-flex justify-content-center gy-5 gx-xl-8'>
        <div>
          <button
            // style={{border: 'none', backgroundColor: 'transparent', textDecoration: 'underline'}}
            onClick={(e) => displaySuggestions(e)}
            // className='SelecttheJobFromDropDown'
            className='bg-white btn fw-bolder fs-4 btn-lg d-flex align-items-center'
            style={{ color: '#C9562A', whiteSpace: 'nowrap' }}
            ref={jobsRef}
          >
            {JobTitleSettingDropDown.length > 0 ? JobTitleSettingDropDown[0].title : jobTitleList.length > 0 && jobTitleList[0].title}

            <span>
              <img src={imagePath.ic_arrow_down_orange} alt='dropdown arrow' style={{ float: 'right' }}></img>{' '}
            </span>
          </button>{' '}
          <div className='overlay'>
            {display === true ? (
              <div style={{ backgroundColor: '#0000', maxHeight: '20rem', overflow: 'auto' }}>
                <ul>
                  {jobTitleList?.map((data, index) => (
                    <li
                      onClick={(e) => {
                        e.preventDefault()
                        SetJobTitle(e, data)
                      }}
                      className='py-2 px-4 liststyling'
                      style={{ listStyle: 'none' }}
                    >
                      {data?.title}{' '}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className=' d-flex justify-content-center gy-5 gx-xl-8'>
          {' '}
          <div className='fortheFunnelsTabks'>
            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => SetJobTabtoActiveFunction()}
              className={`${JobApplicationTab === true ? ' backgroundimageSettingOrange ' : '  backgroundimageSettingSimple'} `}
            >
              <div className={`${JobApplicationTab === true ? '   textofArrowsHighlighted' : 'textofArrowsSimple '} `}>Applicants</div>
            </button>

            {testExists ? (
              <button
                style={{ border: 'none', backgroundColor: 'transparent' }}
                onClick={() => SetOnlineTestTabFunction()}
                className={`${OnlineTestTab === true ? 'backgroundimageSettingOrange ' : 'backgroundimageSettingSimple '}onlineTestExists`}
              >
                <div className={`${OnlineTestTab === true ? 'textofArrowsHighlighted' : 'textofArrowsSimple'} `}>Online Test</div>
              </button>
            ) : null}

            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => SetInterviewTabActiveFunction()}
              className={`${InterviewTab === true ? ' backgroundimageSettingOrange ' : '  backgroundimageSettingSimple'} `}
            >
              <div className={`${InterviewTab === true ? '   textofArrowsHighlighted' : 'textofArrowsSimple '} `}>Interview</div>
            </button>

            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => SetApprovalTabsActiveFunction()}
              className={`${ApprovaTabs === true ? ' backgroundimageSettingOrange ' : '  backgroundimageSettingSimple'} `}
            //   className='backgroundimageSettingSimple'
            >
              <div className={`${ApprovaTabs === true ? '   textofArrowsHighlighted' : 'textofArrowsSimple '} `}>Approvals</div>
            </button>

            <button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={() => SetHiredTabActiveFunction()}
              className={`${HiredTab === true ? ' backgroundimageSettingOrange ' : '  backgroundimageSettingSimple'} `}
            //   className='backgroundimageSettingSimple'
            >
              <div className={`${HiredTab === true ? '   textofArrowsHighlighted' : 'textofArrowsSimple '} `}>Hired</div>
            </button>
          </div>
          { }
        </div>
      </div>

      {JobApplicationTab === true ? (
        <>
          <ApplicationsTable job_id={fetchedId} testExists={testExists} trigger={trigger} />
        </>
      ) : (
        <></>
      )}
      {OnlineTestTab === true ? (
        <>
          <ApplicationsTableOnlineTest job_id={fetchedId} testExists={testExists} trigger={trigger} />
        </>
      ) : (
        <></>
      )}
      {InterviewTab === true ? (
        <>
          <InterviewMainScreen job_id={fetchedId} testExists={testExists} trigger={trigger} />
        </>
      ) : (
        <></>
      )}
      {ApprovaTabs === true ? (
        <>
          <ApprovalTable job_id={fetchedId} testExists={testExists} trigger={trigger} />
        </>
      ) : (
        <></>
      )}
      {HiredTab === true ? (
        <>
          <HiredMainScreen job_id={fetchedId} testExists={testExists} trigger={trigger} />
        </>
      ) : (
        <></>
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default JobFunnelingMainScreen
