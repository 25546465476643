/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './CreateCompetency.css'
import { useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
// import {AuthModel, UserModel} from '../../../../app/modules/auth/core/_models'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import plussquarebutton from '../images/Group 9142.png'
import imageforrotate from '../images/rotate@2x.png'
import cross from '../images/Layout-4-blocks.png'
import level1image from '../../../../app/modules/Setting/images/aas.png'
import level2image from '../../../../app/modules/Setting/images/level2.png'
import level3image from '../../../../app/modules/Setting/images/level 3.png'
import axios from 'axios'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const GET_POSITONS_BY_id = `${API_URL}/job/change-status`

type Props = {
  show: boolean
  id: string
  handleRefreshData: () => void
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SetJobtoActive = ({ show, id, handleRefreshData, handleClose }: Props) => {
  var TotalCompetecies
  var PostedJobs = {}
  const [JobData, setJobData] = useState<any>({})
  const { auth, logout } = useAuth();
  const [reason, setReason] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault()
    const url = GET_POSITONS_BY_id
    const data = {
      "published": true,
      "job_id": id,
      "job_status": "active",
      "reason": reason
    }

    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        handleClose();
        handleRefreshData();
      })
      .catch((error) => {

        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message);
          setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
          logout();
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout();
          } else {
            setMessage(error.response.data.error.message);
            setShowErrorMessage(true);
          }
        } else if (error.response && error.response.status === 500) {
          setMessage(error.response.error.message);
          setShowErrorMessage(true);
        }
        else {
          setMessage(error.message);
          setShowErrorMessage(true);
        }

      })


  }

  const handleChangeReason = (e) => {
    let value = e.target.value;
    setReason(value);
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      // onHide={handleClose}
      backdrop={true}
    >
      <>
        <div >
          <div className='d-flex'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary ms-auto' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={imagePath.ic_close}></img>
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column mt-10 '>
            <img alt='' className='addcompetenctimage' src={addcompetenctimage} style={{ height: '64px' }}></img>
            <h3 className='fw-bolder mx-auto my-5'>Publish Job Post </h3>
          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <div className='card'>
            {/* begin::Beader */}

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form
              //  onSubmit={handleSubmit}
              >
                <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                  <label style={{ color: '#80808F' }} className='form-label mx-auto fs-5 fw-semibold '>
                    Are you sure you want to Publish this Job Post?
                  </label>
                </div>

                <div className='d-flex justify-content-center align-items-center col-md-8 mx-auto mt-4' style={{ alignSelf: 'center', borderRadius: '6px' }}>
                  <textarea
                    style={{ width: '400px' }}
                    placeholder='Enter reason to un hold the position'
                    value={reason}
                    onChange={(e) => handleChangeReason(e)}
                    rows={4}
                  />
                </div>
              </form>
            </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <button
              onClick={handleSubmit}
              type='button'
              className='candi-btn2 btn text-white my-10 px-10 py-3 me-8 bgcolorofbutoonofsubmit'
            >
              Yes, I am sure!
            </button>
            <button onClick={handleClose} className=' btn text-white py-3 px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter '>
              No
            </button>
          </div>
        </div>
        {showErrorMessage && (
          <ShowError
            handleClose={() => setShowErrorMessage(false)}
            message={message}
          />
        )}
      </>
    </Modal>,
    modalsRoot
  )
}

export { SetJobtoActive }
