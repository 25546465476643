import React, { useEffect, useRef, useState } from 'react'
import './vacancies.styles.css'

import edu_icons from '../assets/cap_ color@2x.png'
import info_icons from '../assets/P.I_ Colored@2x.png'
import exp_icons from '../assets/W.E_Colored@2x.png'
import certi_icons from '../assets/document _ color@2x.png'
import skills_icons from '../assets/skills_Color@2x.png'
import defaultProfile from '../assets/common@2x.png'

import PersonalInformation from '../CVScreens/PersonalInformation'
import WorkExperience from '../CVScreens/WorkExperience'
import Education from '../CVScreens/Education'
import Skills from '../CVScreens/Skills'
import Training from '../CVScreens/Training'

import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'

import { useAuth } from '../../auth/core/Auth'
import PersonalInfo from './MyCvScreens/PersonalInfo'
import WorkExpScreen from './MyCvScreens/WorkExpScreen'
import EducationsScreen from './MyCvScreens/EducationsScreen'
import TrainingCertifi from './MyCvScreens/TrainingCertifi'
import SkillsScreen from './MyCvScreens/SkillsScreen'
import { SuccessInterviewpopUp } from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessPopUp'
import { RequestDocumentPopup } from '../../../../_metronic/partials/modals/JobFunnelsModels/RequestDocuments'
import UploadDocumentsPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/UploadDocumentsPopup'
import Loader from '../CVScreens/comps/Loader'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import WarningTARemovePopup from '../../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'

const API_URL = process.env.REACT_APP_API_URL
export const ALLDATA_URL = `${API_URL}/candidate/`
export const JOB_APP__URL = `${API_URL}/jobapplication/apply-for-job`
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/`
const UPDATE_PROFILE_PIC_URL = `${API_URL}/candidate/add-profile-image`

const ReviewVacancies = (props) => {
  const { auth, logout } = useAuth()
  const { currentUser, setCurrentUser } = useAuth()
  const navigate = useNavigate()

  const user_id = currentUser?.user_id
  const [loading, setLoading] = useState(false)
  const [profileStrength, setprofileStrength] = React.useState<Dayjs | any>([
    {
      profile_info: 'false',
      work_exp: 'false',
      education: 'false',
      certificate: 'false',
      skills: 'false',
      total: '5',
    },
  ])
  const [currentProgress, setCurrentProgress] = useState(5)
  const [savedInfo, setSavedInfo] = useState<any>('')
  const [candidateObj, setCandidateObj] = useState<any>();
  const [userObj, setUserObj] = useState<any>();
  const [candidateInfo, setCandidateInfo] = useState<any>()
  const [cv, setCv] = useState<any>()
  const [profile, setProfile] = useState<any>()

  const [prefix, setPrefix] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [cnic, setCnic] = useState('')
  const [cnicExpiry, setCnicExpiry] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [cityPreference, setCityPreference] = useState('')
  const [dob, setdob] = useState('')
  const [age, setAge] = useState(Number)
  const [freshGraduate, setFreshGraduate] = useState('Yes')
  const [status, setStatus] = useState('')
  const [gender, setGender] = useState('Male')
  const [cgpa, setCgpa] = useState(true)
  const [percentage, setPercentage] = useState(false)
  const [division, setDivision] = useState(false)
  const [maritalStatus, setMaritalStatus] = useState('')
  const [religion, setReligion] = useState('')
  const [otherReligion, setOtherReligion] = useState('')
  const [spouseName, setSpouseName] = useState('')
  const [spouseCnic, setSpouseCnic] = useState('')
  const [spouseBOD, setSpouseBOD] = useState('')
  const [childInfo, setChildInfo] = useState([
    {
      name: '',
      cnic: '',
      dob: '',
    },
  ])
  const [identificationMark, setIdentificationMark] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  // work_exp step fields
  const [workExpInfo, setworkExpInfo] = useState<any>([])
  const [bankCount, setBankCount] = useState(0)
  const [workExpInputFields, setWorkExpInputFields] = React.useState<Dayjs | any>([
    {
      // banking_experience: false,
      type: '',
      job_experience: '',
      name: '',
      job_title: '',
      job_grade: '',
      Date_from: '',
      Date_to: '',
      end_date: '',
      start_date: '',
      currently_working: false,
      skills: [],
      years: 0,
      months: 0,
    },
  ])
  const [previouslyEmployed, setPreviouslyEmployed] = useState('')
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [employeeGrade, setEmployeeGrade] = useState('')
  const [employeeDesignation, setEmployeeDesignation] = useState('')
  const [employeeGroup, setEmployeeGroup] = useState('')

  // education step fields
  const [format, setFormat] = useState<Dayjs | any>([])
  const [eduInfo, setEduInfo] = useState<any>([])
  const [educationalYears, setEducationalYears] = useState<any>()
  const [eduInputFields, setEduInputFields] = React.useState<Dayjs | any>([
    {
      education_level: '',
      institution: '',
      otherInstitute: '',
      program: '',
      otherProgram: '',
      education_type: '',
      education_value: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
    },
  ])

  // training certification step fields
  const [trainingInfo, setTrainingInfo] = useState<any>([])
  const [trainingInputFields, setTrainingInputFields] = React.useState<Dayjs | any>([
    {
      name: '',
      issuing_organization: '',
      specialization: '',
      issue_date: '',
      date_from: '',
      date_to: '',
      certificate: '/linkhere',
      myFile: '',
      skills: [],
    },
  ])

  // skills step field
  const [chips, setChips] = useState<string[]>([])
  const [skillsInfo, setSkillsInfo] = useState<any>([])

  const profileRef = useRef(null)
  const workExperienceRef = useRef(null)
  const eduRef = useRef(null)
  const skillsRef = useRef(null)

  const scrollToView = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const validateData = () => {
    // calculate and validate age for job apply
    let jobData = props.jobInfo
    let validTill = jobData?.valid_till

    console.log('validateData: jobData: ', jobData)

    if (!prefix) {
      scrollToView(profileRef)
      setMessage('Please select Prefix first.');
      setShowErrorMessage(true);
      return false
    }
    if (!firstName) {
      scrollToView(profileRef)
      setMessage('First name is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!lastName) {
      scrollToView(profileRef)
      setMessage('Last name is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!cnic) {
      scrollToView(profileRef)
      setMessage('CNIC is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!dob) {
      scrollToView(profileRef)
      setMessage('Date of birth is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!contactNumber) {
      scrollToView(profileRef)
      setMessage('Contact number is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!address) {
      scrollToView(profileRef)
      setMessage('Address is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!city) {
      scrollToView(profileRef)
      setMessage('City is not valid');
      setShowErrorMessage(true);
      return false
    }
    if (!gender) {
      scrollToView(profileRef)
      setMessage('Please select gender first');
      setShowErrorMessage(true);
      return false
    }
    if (!religion) {
      scrollToView(profileRef)
      setMessage('Please select religion first');
      setShowErrorMessage(true);
      return false
    }

    let calculatedAge = 0
    if (dob !== null && dob !== undefined && dob !== '') {
      const dateOfBirth = new Date(dob.split('/').reverse().join('-'))
      const finalDate = new Date(validTill)
      const diffMs = finalDate.getTime() - dateOfBirth.getTime()
      calculatedAge = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365))
    }
    let ageRange = jobData?.age_limit
    // Extract minimum and maximum age from the age_limit string
    const [minAge, maxAge] = ageRange.split('-').map(Number)

    // Check if calculatedAge is within the range
    const isAgeWithinLimit = calculatedAge >= minAge && calculatedAge <= maxAge
    if (!isAgeWithinLimit) {
      setMessage(`Sorry you can't apply to this job, Age limit for this job is ${ageRange}`);
      setShowErrorMessage(true);
      return false
    }

    // check data of work experience
    if (workExpInputFields) {
      for (let i = 0; i < workExpInputFields.length; i++) {
        const { name, job_title, Date_from, Date_to } = workExpInputFields[i]
        if (!name || !job_title || !Date_from || !Date_to) {
          scrollToView(workExperienceRef)
          alert('Please fill in all required fields of work experience.')
          return false
        }
      }
    } else {
      scrollToView(workExperienceRef)
      alert('Work experience can not be empty')
      return false
    }
    if (eduInputFields) {
      for (let i = 0; i < eduInputFields.length; i++) {
        const { education_level, institution, program, education_value, start_date, end_date } = eduInputFields[i]
        if (!education_level || !institution || !program || !education_value || !start_date || !end_date) {
          scrollToView(eduRef)
          alert('Please fill in all required fields of education.')
          return false
        }
      }
    } else {
      scrollToView(eduRef)
      alert('Work experience can not be empty')
      return false
    }
    if (chips.length === 0) {
      scrollToView(skillsRef)
      alert('Please add skills first')
      return false
    }

    return true
  }

  // check is data changed and saved or not 
  const isDataChanged = () => {
    // check data of personal info
    if (prefix !== userObj?.prefix) {
      return true;
    }
    if (firstName !== userObj?.first_name) {
      return true;
    }
    if (middleName !== userObj?.middle_name) {
      return true;
    }
    if (lastName !== userObj?.last_name) {
      return true;
    }
    if (fatherName !== userObj?.father_name) {
      return true;
    }
    if (cnic !== userObj?.cnic) {
      return true;
    }
    const cnicEXP = candidateObj?.cnic_expiration;
    const formattedExpiration = new Date(cnicEXP).toISOString().substring(0, 10)
    if (cnicExpiry !== formattedExpiration) {
      return true;
    }
    if (dob !== userObj?.dob) {
      return true;
    }
    if (contactNumber !== formatPhoneNo(userObj?.phone_number)) {
      return true;
    }
    if (email !== userObj?.email) {
      return true;
    }
    if (address !== userObj?.address) {
      return true;
    }
    if (city !== userObj?.city) {
      return true;
    }
    if (cityPreference !== candidateObj?.city_preference) {
      return true;
    }
    if (gender !== userObj?.gender) {
      return true;
    }
    if (religion !== candidateObj?.religion) {
      return true;
    }
    if (freshGraduate !== userObj?.fresh_graduate) {
      return true;
    }
    if (status !== userObj?.employment_status) {
      return true;
    }
    if (maritalStatus !== userObj?.marital_status) {
      return true;
    }

    // let workExp = candidateObj?.work_experience
    // if (workExp) {
    //   workExp = JSON.parse(candidateObj?.work_experience)
    //   workExp = workExp[0]?.experience
    //   const hasChanged = compareWorkExperiences(workExp, workExpInfo);
    //   if (hasChanged) {
    //     return true;
    //   }
    // }
    let qualificationData = candidateObj?.qualification
    if (qualificationData) {
      qualificationData = JSON.parse(qualificationData);
    }
    console.log('compareData: qualificationData: ', qualificationData);
    console.log('compareData: eduInfo: ', eduInfo);
    return false;
  }

  function compareWorkExperiences(workExp1, workExp2) {
    // Loop through each key-value pair of workExp1
    for (const key1 in workExp1) {
      if (workExp1.hasOwnProperty(key1)) {
        // Check if the key exists in workExp2
        if (workExp2.hasOwnProperty(key1)) {
          // Loop through each key-value pair of the current object in workExp1
          for (const field1 in workExp1[key1]) {
            if (workExp1[key1].hasOwnProperty(field1)) {
              // Check if the field exists in the corresponding object in workExp2
              if (workExp2[key1].hasOwnProperty(field1)) {
                // Compare the values of the field in both objects
                if (workExp1[key1][field1] !== workExp2[key1][field1]) {
                  // If the values are different, return true
                  return true;
                }
              } else {
                // If the field does not exist in workExp2, return true
                return true;
              }
            }
          }
        } else {
          // If the key does not exist in workExp2, return true
          return true;
        }
      }
    }

    // If no differences found, return false
    return false;
  }

  // ---handle apply for vacancy
  const HandleApplyFor = async () => {
    // e.preventDefault()

    // checking all required data of candidate is available or not
    if (!validateData()) {
      return
    }

    setLoading(true)

    var date = new Date()
    var data = {
      job_id: props.job_id,
      candidate_id: props.candidate_id,
      recuiter_id: props.recuiter_id,
      jobapp_stage: 'application',
      application_start_date: date,
    }

    var sendData = JSON.stringify(data)

    axios.post(JOB_APP__URL, sendData, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      let data = res.data
      let jobapp = data?.jobapp
      let jobappId = jobapp?.jobapp_id
      setJobAppId(jobappId)
      setLoading(false)
      askForAttachments()
    }).catch((error) => {
      setLoading(false)
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message)
        setShowErrorMessage(true)
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.name === 'TokenExpiredError'
      ) {
        logout()
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
        }
      } else {
        setMessage(error.message)
        setShowErrorMessage(true)
      }
    })
  }

  function formatPhoneNo(phoneNo) {
    if (/^92/.test(phoneNo)) {
      // REMOVE FIRST 92
      console.log('Phone number starts with 92')
      phoneNo = phoneNo.slice(2)
      return phoneNo
    } else {
      return phoneNo
    }
  }

  function calculateAge(dobString) {
    let calculatedAge = 0
    if (dobString !== null && dobString !== undefined && dobString !== '') {
      const dob = new Date(dobString.split('/').reverse().join('-'))
      const diffMs = Date.now() - dob.getTime()
      calculatedAge = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365))
    }
    return calculatedAge
  }

  useEffect(() => {
    const allReligions = ['Islam', 'Christianity', 'Hinduism', 'Sikhism', 'Buddhism', 'Folk religions', 'Judaism', 'Irreligion', 'Other']
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        setCandidateObj(response?.data?.candidate);
        setUserObj(response?.data?.user);
        // console.log("CANDIADTE: ", response.data)
        setSavedInfo(response.data.user)
        setPrefix(savedInfo.prefix)
        setFirstName(savedInfo.first_name)
        setMiddleName(savedInfo.middle_name)
        setLastName(savedInfo.last_name)
        setFatherName(response.data.candidate?.father_name)
        // setCnic(formatCnicNumber(savedInfo.cnic))
        setCnic(savedInfo.cnic)
        let cnicExpiration = response.data.candidate?.cnic_expiration
        if (cnicExpiration) {
          const formattedExpiration = new Date(cnicExpiration).toISOString().substring(0, 10)
          console.log('formattedExpiration: ', formattedExpiration)
          setCnicExpiry(formattedExpiration)
        }
        setdob(savedInfo.dob)
        setAge(calculateAge(savedInfo.dob))
        setEmail(savedInfo.email)
        setContactNumber(formatPhoneNo(savedInfo.phone_number))
        // setContactNumber(savedInfo.phone_number)
        setAddress(savedInfo.address)
        setGender(savedInfo.gender)
        setCity(savedInfo.city)
        setCityPreference(response.data.candidate?.city_preference)
        setMaritalStatus(savedInfo.marital_status)
        setFreshGraduate(savedInfo.fresh_graduate)
        setStatus(savedInfo.employment_status)
        const religionValue = response.data.candidate.religion || ''
        if (allReligions.includes(religionValue)) {
          setReligion(religionValue)
        } else {
          setReligion('Other')
          setOtherReligion(religionValue)
        }
        setIdentificationMark(response.data.candidate.identification_mark)
        setCandidateInfo(response.data.candidate.profile_strength)
        let maritalData = response.data?.candidate?.marital_data
        if (maritalData) {
          maritalData = JSON.parse(maritalData)
        }
        setSpouseName(maritalData?.spouseName)
        setSpouseCnic(maritalData?.spouseCnic)
        setSpouseBOD(maritalData?.spouseBOD)
        setChildInfo(maritalData?.childInfo)
        // let profileUrl = response.data.candidate.profile_pic;
        let profileUrl = response.data.user.profile_pic
        if (!profileUrl) {
          profileUrl = response.data.candidate.profile_pic
        }
        const profileUrl_base64 = btoa(profileUrl)
        setProfile(profileUrl)
        let cvUrl = response.data.candidate.cv_url
        const cv_base64 = btoa(cvUrl)
        setCv(cvUrl)

        // setPersonalInfo(response.data.candidate.personal_information)

        const strength = JSON.parse(response.data.candidate.profile_strength)
        console.log('strengthTest: ', strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 5,
              profile_info: 'false',
              work_exp: 'false',
              education: 'false',
              certificate: 'false',
              skills: 'false',
            })
          } else {
            let totalProgress = 5
            if (strength.profile_info === 'true') {
              totalProgress += 20
            }
            if (strength.work_exp === 'true') {
              totalProgress += 20
            }
            if (strength.education === 'true') {
              totalProgress += 20
            }
            if (strength.certificate === 'true') {
              totalProgress += 15
            }
            if (strength.skills === 'true') {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            setprofileStrength({
              total: totalProgress,
              profile_info: strength?.profile_info == undefined ? 'false' : strength.profile_info,
              work_exp: strength?.work_exp == undefined ? 'false' : strength.work_exp,
              education: strength?.education == undefined ? 'false' : strength.education,
              certificate: strength?.certificate == undefined ? 'false' : strength.certificate,
              skills: strength?.skills == undefined ? 'false' : strength.skills,
            })
          }
        }

        // save work experience data
        let workExperience = response.data?.candidate?.work_experience
        if (workExperience) {
          workExperience = JSON.parse(workExperience)
          const experience = workExperience[0]?.experience
          setworkExpInfo(experience)
          console.log('workExpInfo: ', workExpInfo)
          setPreviouslyEmployed(workExperience[0]?.previously_employed)
          let employeeData = workExperience[0]?.bop_employee
          if (employeeData) {
            setEmployeeNumber(employeeData?.employeeNumber)
            setEmployeeGrade(employeeData?.grade)
            setEmployeeDesignation(employeeData?.designation)
            setEmployeeGroup(employeeData?.group)
          }
          //fetching data from API and generating multiple input fields according to number of entries
          let workExpFields: any = []
          Object.keys(workExpInfo)?.map((companyKey, index) => {
            const entity: any = workExpInfo[companyKey]
            // for (let i = 0; i < companies.length; i++) {
            //     let entity: any = companies[i]
            // console.log('workExpEntity: ', entity);
            let currentlyWorking = entity.currently_working
            let bankingExprience = entity.banking_experience
            if (typeof currentlyWorking === 'string') {
              currentlyWorking = entity.currently_working?.toLowerCase?.() === 'true'
            }
            if (typeof bankingExprience === 'string') {
              bankingExprience = entity.banking_experience?.toLowerCase?.() === 'true'
            }
            let yearsMonths = entity.years
            let [yearsPart, monthsPart] = yearsMonths?.split('.')
            let yearsNumber = parseInt(yearsPart)
            let monthsNumber = parseInt(monthsPart)

            let newField = {
              job_title: entity.job_title,
              job_grade: entity.job_grade,
              name: entity.name,
              end_date: entity.Date_to,
              start_date: entity.Date_from,
              Date_to: entity.Date_to,
              Date_from: entity.Date_from,
              currently_working: currentlyWorking,
              grossSalary: entity.grossSalary,
              benefits: entity.benefits,
              type: entity.type,
              skills: entity.skills ? Object.values(entity.skills) : [],
              years: yearsNumber,
              months: monthsNumber,
            }
            workExpFields.push(newField)

            if (entity.banking_experience) {
              setBankCount(bankCount + 1)
            }
            // }
          })
          // if (fields.length > 0) {
          setWorkExpInputFields(workExpFields)
          // } else {
          //     if (inputFields.length == 0) {
          //         addFields();
          //     }
          // }
        }

        // save education step data
        setEduInfo(JSON.parse(response.data.candidate.qualification))
        console.log('qualificationData: ', eduInfo);
        //fetching data from API and generating multiple input fields according to number of entries
        let fields: any = []
        eduInfo.map((val, index) => {
          const qualification = Object.values(val)
          for (let i = 0; i < qualification.length; i++) {
            let entity: any = qualification[i]
            console.log('EducationData: entity: ', entity)
            if (typeof entity === 'object') {
              let newField = {
                education_level: entity.education_level,
                institution: entity.institution,
                otherInstitute: entity.otherInstitute,
                program: entity.program,
                otherProgram: entity.otherProgram,
                education_type: entity.education_type,
                education_value: entity.education_value,
                end_date: entity.end_date,
                start_date: entity.start_date,
                currently_studying: entity.currently_studying,
              }
              fields.push(newField)
            }
          }
        })
        // if (fields.length > 0) {
        setEduInputFields(fields)
        // } else {
        // addFields();
        // }

        const qualificationArray = JSON.parse(response.data?.candidate?.qualification)
        let educationalYearsValue = qualificationArray[0].educationalYears
        setEducationalYears(educationalYearsValue)

        // save training certification step data
        setTrainingInfo(JSON.parse(response.data.candidate.certification))
        //fetching data from API and generating multiple input fields according to number of entries
        let trainingFields: any = []
        trainingInfo.map((val, index) => {
          const certificates = Object.values(val)
          for (let i = 0; i < certificates.length; i++) {
            let entity: any = certificates[i]
            let newField = {
              name: entity.name,
              issuing_organization: entity.issuing_organization,
              specialization: entity.specialization,
              issue_date: entity.issue_date,
              date_from: entity.date_from,
              date_to: entity.date_to,
              myFile: entity.myFile,
              skills: entity.skills ? Object.values(entity.skills) : [],
            }
            trainingFields.push(newField)
          }
        })
        // if (fields.length > 0) {
        setTrainingInputFields(trainingFields)
        // } else {
        //     addFields();
        // }

        // save skills step data
        let savedSkills = JSON.parse(response.data.candidate.skills)
        setSkillsInfo(savedSkills)
        console.log('SKILLS DATA: ', savedSkills)
        console.log(typeof savedSkills)
        // for (let i in savedSkills) {
        //     // setSkills(savedSkills[i].val)
        //     if (!skillChipAlreadyAvailable(savedSkills[i])) {
        //         chips.push(savedSkills[i])
        //     }
        // }
        savedSkills.map((val, index) => {
          const skillInfos = Object.values(val)
          for (let i = 0; i < skillInfos.length; i++) {
            let entity: any = skillInfos[i]
            console.log('skillData: entity: ', entity)
            if (!skillChipAlreadyAvailable(entity)) {
              chips.push(entity)
            }
          }
        })
      })
    }
    getData()
  }, [email, currentProgress])

  function formatCnicNumber(cnic) {
    if (!cnic) return ''
    if (cnic.length !== 13) return cnic // CNIC number must be 13 digits long
    if (cnic.indexOf('-') !== -1) return cnic // CNIC number already has hyphens

    const part1 = cnic.substring(0, 5)
    const part2 = cnic.substring(5, 12)
    const part3 = cnic.substring(12)

    return `${part1}-${part2}-${part3}`
  }

  // get data of personal info step
  const handlePrefixChange = (e) => {
    setPrefix(e.target.value)
  }
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value)
  }
  const handleFatherNameChange = (e) => {
    setFatherName(e.target.value)
  }
  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }
  const handleCnicChange = (e) => {
    let inputCNIC = e.target.value

    // Validate the input format of the CNIC number
    // const regex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
    // if (regex.test(inputCNIC)) {
    //   setCnic(inputCNIC)
    // }

    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }

    setCnic(inputCNIC)
  }
  const handleChangeCnicExpiryDate = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setCnicExpiry(value)
  }
  const handleDate = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setdob(value)
    setAge(calculateAge(value))
  }
  const handleContactNumberChange = (e) => {
    let inputPhone = e.target.value
    // Remove all non-digit characters from the input
    inputPhone = inputPhone.replace(/\D/g, '')
    // Regular expression to match Pakistani phone numbers without the country code
    const phoneRegex = /^3[0-9]{2}-?[0-9]{7}$/
    const isValidPhone = phoneRegex.test(inputPhone)
    if (isValidPhone) {
    }
    setContactNumber(inputPhone)
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }
  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }
  const handleChangeCityPreference = (e) => {
    setCityPreference(e.target.value)
  }
  const handleCityChange = (e) => {
    setCity(e.target.value)
  }
  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }
  const handleMaritalStatusChange = (event) => {
    setMaritalStatus(event.target.value)
  }
  const handleReligionChange = (event) => {
    setReligion(event.target.value)
  }
  const handleOtherReligionChange = (event) => {
    setOtherReligion(event.target.value)
  }
  const handleIdentitficationMark = (event) => {
    setIdentificationMark(event.target.value)
  }
  const handleFreshGraduateChange = (e) => {
    setFreshGraduate(e.target.value)
  }
  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleSpouseDOB = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setSpouseBOD(value)
  }

  const handleSpouseNameChange = (event) => {
    setSpouseName(event.target.value)
  }

  const handleSpouseCnicChange = (event) => {
    let inputCNIC = event.target.value
    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }
    setSpouseCnic(inputCNIC)
  }

  const addChildFields = () => {
    let newfield = {
      name: '',
      cnic: '',
      dob: '',
    }
    // setInputFields([...inputFields, newfield])
    setChildInfo((prevFields) => [...prevFields, newfield])
  }

  const removeChildFields = (index) => {
    let data = [...childInfo]
    data.splice(index, 1)
    setChildInfo(data)
  }

  const handleChildInfoChange = (index, event) => {
    let value = event.target.value
    let data = [...childInfo]
    data[index][event.target.name] = value
    setChildInfo(data)
  }

  const handleChildCnicChange = (index, event) => {
    let inputCNIC = event.target.value
    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }

    let data = [...childInfo]
    data[index]['cnic'] = inputCNIC
    setChildInfo(data)
  }

  // getting and initializing data of Work & Experience step

  const addWorkExpFields = () => {
    let newfield = {
      // banking_experience: false,
      type: '',
      job_experience: '',
      name: '',
      job_title: '',
      job_grade: '',
      Date_from: '',
      Date_to: '',
      end_date: '',
      start_date: '',
      currently_working: false,
      skills: [],
    }
    setWorkExpInputFields((prevFields) => [...prevFields, newfield])
    // inputFields.push(newfield)
  }
  const removeWorkExpFields = (index) => {
    let data = [...workExpInputFields]
    data.splice(index, 1)
    setWorkExpInputFields(data)
  }
  function handleBankingExp(index, event) {
    let data = [...workExpInputFields]
    data[index]['banking_experience'] = event.target.checked
    setWorkExpInputFields(data)
    if (event.target.checked) {
      console.log('countBankExp: add 1 to bank Exp: ' + (bankCount + 1))
      setBankCount(bankCount + 1)
    } else {
      console.log('countBankExp: subtract 1 to bank Exp: ' + (bankCount - 1))
      setBankCount(bankCount - 1)
    }
  }
  function handleExperienceTypeChange(index, event) {
    let value = event.target.value
    let data = [...workExpInputFields]
    data[index]['type'] = event.target.value
    // data[index]['banking_experience'] = value === 'Banking Experience' ? true : false
    setWorkExpInputFields(data)
    if (value === 'Banking Experience') {
      console.log('countBankExp: add 1 to bank Exp: ' + (bankCount + 1))
      setBankCount(bankCount + 1)
    } else {
      console.log('countBankExp: subtract 1 to bank Exp: ' + (bankCount - 1))
      setBankCount(bankCount - 1)
    }
  }
  function handleCurrWork(index, event) {
    // let data = [...workExpInputFields];
    // data[index]["currently_working"] = event.target.checked;
    // setWorkExpInputFields(data);

    let data = [...workExpInputFields]

    let currentDate = new Date().toLocaleDateString('en-GB')
    console.log('currentDateValue: ', currentDate)
    const [day, month, year] = currentDate.split('/')
    const formattedDate = `${year}-${month}-${day}`
    let currWorking = event.target.checked
    if (currWorking) {
      data[index]['Date_to'] = formattedDate
    }
    data[index]['currently_working'] = currWorking

    let dateFromValue = data[index]['Date_from']
    let dateToValue = formattedDate
    if (!validateDate(dateFromValue, dateToValue)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }

    //calculate years and months
    if (dateFromValue && dateToValue) {
      let diff = getDateDiffInYearsAndMonths(dateFromValue, dateToValue)
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    setWorkExpInputFields(data)
  }

  const handleFormChange = (index, event) => {
    let data = [...workExpInputFields]
    data[index][event.target.name] = event.target.value
    setWorkExpInputFields(data)
  }
  const handleDateFromChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    let data = [...workExpInputFields]

    if (index > 0) {
      // checking is the value of dateFrom at current index is greater then the value of dateTo of previous index
      let previousDateToValue = data[index - 1]['Date_to']
      if (!validateDate(previousDateToValue, value)) {
        setMessage('Date From should be greater than Date To of previous experience');
        setShowErrorMessage(true);
        return
      }
    }

    let dateToValue = data[index]['Date_to']
    if (!validateDate(value, dateToValue)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }

    //calculate years and months
    if (value && dateToValue) {
      let diff = getDateDiffInYearsAndMonths(value, dateToValue)
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    data[index][event.target.name] = value
    setWorkExpInputFields(data)
  }
  const handleDateToChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }

    let data = [...workExpInputFields]
    let dateFromValue = data[index]['Date_from']
    if (!dateFromValue) {
      setMessage('Please enter or select Date From first');
      setShowErrorMessage(true);
      return
    }
    let dateObject = new Date(value)
    let year = dateObject.getFullYear().toString()
    console.log('handleFormChangeWorkExp: dateToYearLength: ', year.length)
    if (!validateDate(dateFromValue, value)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }
    //calculate years and months
    if (dateFromValue && value) {
      let diff = getDateDiffInYearsAndMonths(dateFromValue, value)
      console.log('calculatedYearsMonths:  ' + diff.years + ' years, ' + diff.months + ' months')
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    data[index][event.target.name] = value
    setWorkExpInputFields(data)
  }

  // checking end date is greater than start date or not
  function validateDate(startDate, endDate) {
    if (startDate && endDate) {
      let start = new Date(startDate).getTime()
      let end = new Date(endDate).getTime()

      let timeDiff = end - start
      if (timeDiff < 0) {
        return false
      }
    }
    return true
  }

  function getDateDiffInYearsAndMonths(startDate, endDate) {
    let start = new Date(startDate).getTime()
    let end = new Date(endDate).getTime()
    let timeDiff = end - start
    let years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365))
    let months = Math.floor((timeDiff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30))
    if (months === 12) {
      years = years + 1
      months = 0
    }
    console.log(`calculatedValue:2 years: ${years} :: months: ${months}`)
    return { years: years, months: months }
  }

  // handle click on list of searched suggestion if skill not already added add new skill
  const handleItemClick = (item, index) => () => {
    let data = [...workExpInputFields]
    let ski = [...data[index]['skills']]
    if (!chipAlreadyAvailable(item, ski) && ski.length < 5) {
      ski.push(item)
      data[index]['skills'] = ski
      setWorkExpInputFields(data)
    }
  }

  // checking chip already available or not
  const chipAlreadyAvailable = (item, chips) => {
    let found = false
    for (let chip of chips) {
      if (chip === item) {
        found = true
      }
    }
    return found
  }

  const handleDelete = (index, sIndex) => {
    let data = [...workExpInputFields]
    let ski = [...data[index]['skills']]
    ski.splice(sIndex, 1)
    data[index]['skills'] = ski
    setWorkExpInputFields(data)
  }
  const handlePreviouslyEmployedChange = (event) => {
    setPreviouslyEmployed(event.target.value)
  }

  const handleEmployeeNoChange = (event) => {
    setEmployeeNumber(event.target.value)
  }

  const handleEmployeeGradeChange = (event) => {
    setEmployeeGrade(event.target.value)
  }

  const handleEmployeeDesignationChange = (event) => {
    setEmployeeDesignation(event.target.value)
  }

  const handleEmployeeGroupChange = (event) => {
    setEmployeeGroup(event.target.value)
  }

  // getting and initializing data of Education step
  const addEduFields = () => {
    let newfield = {
      education_level: '',
      institution: '',
      otherInstitute: '',
      program: '',
      otherProgram: '',
      education_type: '',
      education_value: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
    }
    // setInputFields([...inputFields, newfield])
    setEduInputFields((prevFields) => [...prevFields, newfield])
  }
  const removeEduFields = (index) => {
    let data = [...eduInputFields]
    data.splice(index, 1)
    setEduInputFields(data)
  }
  const handleEduFormChange = (index, event) => {
    let name = event.target.name
    let value = event.target.value;

    if (name === 'start_date' || name === 'end_date') {
      // Restrict to only the first 10 characters (YYYY-MM-DD format)
      value = value.slice(0, 10);

      // Check if the year part has more than 4 characters
      if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
        // If the year part is not a valid number or has more than 4 characters, remove the last character
        value = value.slice(0, 9);
      }
      let data = [...eduInputFields]
      data[index][event.target.name] = value
      setEduInputFields(data)

    } else {

      let data = [...eduInputFields]
      data[index][event.target.name] = event.target.value
      setEduInputFields(data)
    }
  }
  const handleCGPAChange = (index, event) => {
    let cgpaValue = event.target.value
    // Remove all non-digit characters from the input
    // cgpaValue = cgpaValue.replace(/\D/g, '');
    let data = [...eduInputFields]
    data[index][event.target.name] = cgpaValue
    setEduInputFields(data)
  }
  const handleKeyPress = (event, type) => {
    // Only allow numeric input
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    if (type === 'percentage') {
      const regexPattern = /^(?:[1-9][0-9]?|100)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else if (type === 'cgpa') {
      const regexPattern = /^(?:0(?:\.[0-9]{1,2})?|[1-3](?:\.[0-9]{1,2})?|4(?:\.0{1,2})?)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else {
      const regexPattern = /^[1-3]$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    }
  }
  const handleCGPARadio = (index, event) => {
    const value = event.target.value
    let data = [...eduInputFields]
    // data[index][event.target.name] = value;
    data[index]['education_type'] = value
    setEduInputFields(data)

    // setCgpa(event.target.value)
    // setPercentage(false);
    // setDivision(false);
  }
  const handleCurrentlyStudying = (index, event) => {
    let data = [...eduInputFields]
    data[index][event.target.name] = event.target.checked
    setEduInputFields(data)
  }

  const handleChangeEducationalYears = (event) => {
    setEducationalYears(event.target.value)
  }

  // getting and initializing data of training and certification step
  const handleTrainingFormChange = (index, event) => {
    let data = [...trainingInputFields]
    data[index][event.target.name] = event.target.value
    setTrainingInputFields(data)
  }

  const handleTrainingDateFromChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }

    let data = [...trainingInputFields]
    let dateToValue = data[index]['date_to']
    if (!validateDate(value, dateToValue)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }

    data[index][event.target.name] = value
    setTrainingInputFields(data)
  }

  const handleTrainingDateToChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }

    let data = [...trainingInputFields]
    let dateFromValue = data[index]['date_from']
    if (!validateDate(dateFromValue, value)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }

    data[index][event.target.name] = value
    setTrainingInputFields(data)
  }

  const addTrainingFields = () => {
    let newfield = {
      name: '',
      issuing_organization: '',
      specialization: '',
      issue_date: '',
      date_from: '',
      date_to: '',
      certificate: '/linkhere',
      myFile: '',
      skills: [],
    }
    setTrainingInputFields((prevFields) => [...prevFields, newfield])
  }
  const removeTrainingFields = (index) => {
    let data = [...trainingInputFields]
    data.splice(index, 1)
    setTrainingInputFields(data)
  }

  const handleDeleteTraining = (index, sIndex) => {
    let data = [...trainingInputFields]
    let ski = [...data[index]['skills']]
    ski.splice(sIndex, 1)
    data[index]['skills'] = ski
    setTrainingInputFields(data)
  }

  // handle click on list of searched suggestion if skill not already added add new skill
  const handleTrainingItemClick = (item, index) => () => {
    let data = [...trainingInputFields]
    let ski = [...data[index]['skills']]
    if (!chipAlreadyAvailable(item, ski) && ski.length < 5) {
      ski.push(item)
      data[index]['skills'] = ski
      setTrainingInputFields(data)
    }
  }

  const handleCertifiFileChange = async (index, event) => {
    const file = event.target.files[0]

    if (!file) {
      return // No file selected, return early
    }

    // Regular expression to check if the file extension is .jpg, .jpeg, or .png
    const allowedExtensions = /\.(jpg|jpeg|png)$/i

    // Check if the file extension is allowed
    if (!allowedExtensions.test(file.name)) {
      alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
      event.target.value = null // Clear the file input value
      return
    }
    // Optional: You can set a maximum file size limit if needed
    const maxSizeInBytes = 1 * 1024 * 1024 // 1MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit (1MB). Please select a smaller image.')
      return
    }

    const base64 = await convertBase64(file)

    let data = [...trainingInputFields]
    data[index][event.target.name] = base64
    // console.log("CV check", event.target.files[0])
    setTrainingInputFields(data)
    // setCv(base64);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  // getting and initializing data of skills step
  const skillChipAlreadyAvailable = (item) => {
    let found = false
    for (let chip of chips) {
      if (chip === item) {
        found = true
      }
    }
    return found
  }

  const handleSkillItemClick = (item) => () => {
    if (!skillChipAlreadyAvailable(item) && chips.length < 5) {
      setChips((prevItems) => [...prevItems, item])
      // chips.push(item)
    }
  }
  const addNewSkill = (item) => {
    if (!skillChipAlreadyAvailable(item) && chips.length < 5) {
      setChips((prevItems) => [...prevItems, item])
      // chips.push(item)
    }
  }
  const handleSkillDelete = (index) => {
    const currentSkills = [...chips]
    const updatedChips = currentSkills.splice(index, 1)
    setChips(currentSkills)
    // setSendRequest(true)
  }

  const isThreeLevelEducationAvailable = () => {
    if (eduInputFields.length >= 3) {
      return true
    }
    return false
  }

  const getCountValueOfType = (type) => {
    let count = 0
    workExpInputFields.map((field) => {
      if (field.type === type) {
        count++
      }
    })
    return count
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(inputFields)
    // loader component
    setLoading(true)

    const transformedData = {
      work_experience: [
        ...workExpInputFields.map((experience, index) => ({
          [`company${index + 1}`]: {
            name: experience.name,
            job_title: experience.job_title,
            job_grade: experience.job_grade,
            type: experience.type,
            Date_from: experience.Date_from,
            Date_to: experience.Date_to,
            start_date: experience.Date_from,
            end_date: experience.Date_to,
            currently_working: experience.currently_working,
            grossSalary: experience.grossSalary,
            benefits: experience.benefits,
            years: `${experience.years}.${experience.months}`,
            skills: experience.skills.reduce((obj, skill, index) => {
              obj[index] = skill
              return obj
            }, {}),
          },
        })),
      ],
    }

    let work_experience = transformedData.work_experience
    // Merge company objects into one
    const mergedCompanies = Object.assign({}, ...Object.values(work_experience))
    // Create final work experience object with merged companies
    let bopEmployee = {
      employeeNumber: employeeNumber,
      grade: employeeGrade,
      designation: employeeDesignation,
      group: employeeGroup,
    }
    const finalWorkExperience = {
      work_experience: [
        {
          previously_employed: previouslyEmployed,
          bop_employee: previouslyEmployed === 'Yes' ? bopEmployee : null,
          experience: mergedCompanies,
        },
      ],
    }
    work_experience = finalWorkExperience.work_experience

    // getting education data
    // checking is three level of education is available or not
    if (!isThreeLevelEducationAvailable()) {
      setLoading(false)
      setMessage('Please enter 03 educational entries (Matric Level, FA Level, Degree Level) first.');
      setShowErrorMessage(true);
      return
    }

    var indexx = 0
    setFormat([])
    var sortedFields = eduInputFields.sort((a, b) => parseFloat(b.end_date.replace('-', '')) - parseFloat(a.end_date.replace('-', '')))
    // console.log('educationData: SortedFields: ', sortedFields);
    sortedFields.map((val, index) => {
      var nIdx = 'education' + index
      indexx++
      var newData = { ['qualification' + indexx]: val }
      // console.log('educationData: qualification: ', newData);
      format.push(newData)
    })

    const transformedEducationData = {
      qualification: [
        {
          ...format.reduce((acc, curr) => {
            return { ...acc, ...curr }
          }, {}),
          educationalYears: educationalYears,
        },
      ],
    }

    const qualification = transformedEducationData.qualification

    // getting and formating training data

    const newCertifications = trainingInputFields.map((cert, index) => {
      const newSkills = cert.skills.reduce((acc, skill, index) => {
        acc[index] = skill
        return acc
      }, {})

      const newCertificate = {
        [`certificate${index + 1}`]: {
          name: cert.name,
          issuing_organization: cert.issuing_organization,
          issue_date: cert.issue_date,
          date_from: cert.date_from,
          date_to: cert.date_to,
          certificate: cert.certificate,
          skills: newSkills,
          myFile: cert.myFile,
        },
      }

      return newCertificate
    })

    const formattedCertifications = { certification: newCertifications }

    let certification = formattedCertifications.certification
    const transformedTrainingData = {
      certification: [
        {
          ...certification.reduce((acc, curr) => {
            return { ...acc, ...curr }
          }, {}),
        },
      ],
    }
    certification = transformedTrainingData.certification

    let phoneNo = contactNumber
    if (/^92/.test(phoneNo)) {
      console.log('Phone number starts with 92')
    } else {
      console.log('Phone number does not start with 92')
      phoneNo = '92' + contactNumber
    }

    const skillsObject = chips.reduce((acc, val, idx) => {
      acc[idx] = val.trim()
      return acc
    }, {})

    let skills: any = []
    skills.push(skillsObject)
    let totalBankingExperience = getCountValueOfType('banking_experience')
    let totalJobExperience = getCountValueOfType('work_experience')
    let totalInternships = getCountValueOfType('internship')
    let religionValue = religion
    if (religion === 'Other') {
      religionValue = otherReligion
    }
    var work = {
      prefix: prefix,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      father_name: fatherName,
      email: email,
      // cnic: cnic.replace(/-/g, ''),
      cnic: cnic,
      dob: dob.toString(),
      phone_number: phoneNo,
      address: address,
      gender: gender,
      city: city,
      marital_status: maritalStatus,
      fresh_graduate: freshGraduate === 'Yes' ? true : false,
      employment_status: status,
      religion: religionValue,
      // otherReligion: otherReligion,
      identification_mark: identificationMark,
      banking_experience: totalBankingExperience,
      job_experience: totalJobExperience,
      internship_experience: totalInternships,
      profile_strength: profileStrength,
      work_experience,
      qualification,
      certification,
      skills: skills,
    }
    var data = JSON.stringify(work)
    const url = `${PERSONAL_INFORMATION_URL}${user_id}`
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // console.log('res ', res);
        // loader component
        // props.reduce(2);
        setLoading(false)

        setPopupText('Your Profile information Updated')
        showSuccessPopup()
        // askForAttachments();
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleProfilePic = async (event) => {
    console.log('handleProfilePic called');
    const file = event.target.files[0]

    // Check if a file was selected
    if (!file) {
      return // No file selected, return early
    }

    // Regular expression to check if the file extension is .jpg, .jpeg, or .png
    const allowedExtensions = /\.(jpg|jpeg|png)$/i

    // Check if the file extension is allowed
    if (!allowedExtensions.test(file.name)) {
      alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
      event.target.value = null // Clear the file input value
      return
    }

    // Optional: You can set a maximum file size limit if needed
    const maxSizeInBytes = 5 * 1024 * 1024 // 5MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit (5MB). Please select a smaller image.')
      return
    }

    setProfile(file)
    // console.log("Profile check", event.target.files[0])
    // const base64 = await convertBase64(file);
    // setProfile(base64);
    updateProfilePic(file)
  }

  const updateProfilePic = (profilePic) => {
    const candidateId: any = user_id ? user_id : ''
    const formData = new FormData()
    formData.append('profile_image', profilePic)
    formData.append('candidate_id', candidateId)
    setLoading(true)
    axios
      .post(UPDATE_PROFILE_PIC_URL, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          // 'Content-Type': 'application/json',
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setLoading(false)
        const data = res.data
        console.log('profileUpdatedData: ', data)
        const profilePicUrl = data?.picUrl
        if (profilePicUrl) {
          let userData: any = { ...currentUser, profile_pic: profilePicUrl }
          setCurrentUser(userData)
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const [jobAppId, setJobAppId] = useState('')
  const [popupText, setPopupText] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false)

  function showSuccessPopup() {
    setShowPopup(true)
  }

  function handleClose() {
    setShowPopup(false)
  }

  function askForAttachments() {
    setShowAttachmentPopup(true)
  }
  function closeAttachmentPopup() {
    setShowAttachmentPopup(false)
  }

  return (
    <>
      <div >
        <div className='dashboard-top' >
          <h1>Dashboard</h1>
          <div className='d-flex align-items-center'>
            <h3>
              <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              <Link to="/candidatedashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                My Dashboard
              </Link>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              <Link to="/mainvacancies" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                onClick={() => props.openApplyPage(1)}>
                Vacancies
              </Link>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              {props.job_title}
            </h3>
            {/* <h3 className='ms-auto'>
              <i className='bi bi-arrow-clockwise text-white'></i> Last update: 5 mins ago
            </h3> */}
          </div>
        </div>
        <div className='card-bg shadow-sm p-8 mt-18'>
          <div className='review-top-container text-center'>
            {/* <img className='profile-img' alt="..." src={props.user.avatar_location} /> */}
            <div className='profile-pic-container text-center'>
              <img
                className='profile-img'
                style={{ objectFit: 'cover' }}
                alt='Profile image'
                src={typeof profile === 'string' ? profile : profile ? URL.createObjectURL(profile) : defaultProfile}
                height='200'
              />

              <div
                className='profile-container text-center'
              >
                <input className='cursor-pointer' type='file' accept='.jpg, .jpeg, .png' onChange={(event) => handleProfilePic(event)} />
                {/* <i className='bi bi-pen-fill' style={{zIndex:'2', top:'-1.7rem', position:'relative'}}></i> */}
                <img src={imagePath.ic_edit_circle_orange}
                  style={{ zIndex: '2', top: '-2rem', position: 'relative', width: '32px', height: '32px', objectFit: 'cover' }}
                />
              </div>
            </div>
            <h1 style={{ marginTop: '-3rem' }}>{props.user.name}</h1>
            <div className='mt-8'>
              <button onClick={handleSubmit} type='button' className='btn apply-edit-button me-4 text-center' style={{ width: '8rem', color: '#F36523' }} >
                Save
              </button>
              <button
                className='btn apply-review-button text-center text-white'
                style={{ width: '8rem' }}
                onClick={(e) => {
                  if (isDataChanged()) {
                    setMessage('Are you sure you want to submit application without saving the updated profile?');
                    setShowWarningPopup(true);

                  } else {
                    HandleApplyFor()
                  }
                }} >
                Submit
              </button>
            </div>
          </div>
          <div className='review-forms-container'>
            <div className='accordion' id='accordionExample'>
              {/* <div className="accordion-item"> */}
              <div className='acc-item-inner'>
                <h2 className='accordion-header' id='headingOne'>
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseOne'
                    aria-expanded='true'
                    aria-controls='collapseOne'
                  >
                    <img className='acc-icons' src={info_icons} alt='..' /> Personal Information
                  </button>
                </h2>
                <div
                  id='collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='headingOne'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body' ref={profileRef}>
                    {/* <PersonalInformation /> */}
                    <PersonalInfo
                      prefix={prefix}
                      handlePrefixChange={handlePrefixChange}
                      firstName={firstName}
                      handleFirstNameChange={handleFirstNameChange}
                      middleName={middleName}
                      handleMiddleNameChange={handleMiddleNameChange}
                      lastName={lastName}
                      handleLastNameChange={handleLastNameChange}
                      fatherName={fatherName}
                      handleFatherNameChange={handleFatherNameChange}
                      cnic={cnic}
                      handleCnicChange={handleCnicChange}
                      cnicExpiry={cnicExpiry}
                      handleChangeCnicExpiryDate={handleChangeCnicExpiryDate}
                      dob={dob}
                      handleDate={handleDate}
                      age={age}
                      contactNumber={contactNumber}
                      handleContactNumberChange={handleContactNumberChange}
                      email={email}
                      handleEmailChange={handleEmailChange}
                      address={address}
                      handleAddressChange={handleAddressChange}
                      city={city}
                      handleCityChange={handleCityChange}
                      cityPreference={cityPreference}
                      handleChangeCityPreference={handleChangeCityPreference}
                      gender={gender}
                      handleGenderChange={handleGenderChange}
                      maritalStatus={maritalStatus}
                      handleMaritalStatusChange={handleMaritalStatusChange}
                      religion={religion}
                      handleReligionChange={handleReligionChange}
                      otherReligion={otherReligion}
                      handleOtherReligionChange={handleOtherReligionChange}
                      identificationMark={identificationMark}
                      handleIdentitficationMark={handleIdentitficationMark}
                      freshGraduate={freshGraduate}
                      handleFreshGraduateChange={handleFreshGraduateChange}
                      status={status}
                      handleStatusChange={handleStatusChange}
                      spouseName={spouseName}
                      handleSpouseNameChange={handleSpouseNameChange}
                      spouseCnic={spouseCnic}
                      handleSpouseCnicChange={handleSpouseCnicChange}
                      spouseBOD={spouseBOD}
                      handleSpouseDOB={handleSpouseDOB}
                      childInfo={childInfo}
                      addChildFields={addChildFields}
                      removeChildFields={removeChildFields}
                      handleChildInfoChange={handleChildInfoChange}
                      handleChildCnicChange={handleChildCnicChange}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* <div className="accordion-item"> */}
              <div className='acc-item-inner'>
                <h2 className='accordion-header' id='headingTwo'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseTwo'
                    aria-expanded='false'
                    aria-controls='collapseTwo'
                  >
                    <img className='acc-icons' src={exp_icons} alt='..' /> Work & Experience
                  </button>
                </h2>
                <div
                  id='collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingTwo'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body' ref={workExperienceRef}>
                    {/* <WorkExperience /> */}
                    <WorkExpScreen
                      inputFields={workExpInputFields}
                      addFields={addWorkExpFields}
                      removeFields={removeWorkExpFields}
                      handleBankingExp={handleBankingExp}
                      handleExperienceTypeChange={handleExperienceTypeChange}
                      handleCurrWork={handleCurrWork}
                      handleFormChange={handleFormChange}
                      handleDateFromChange={handleDateFromChange}
                      handleDateToChange={handleDateToChange}
                      handleItemClick={handleItemClick}
                      handleDelete={handleDelete}
                      previouslyEmployed={previouslyEmployed}
                      handlePreviouslyEmployedChange={handlePreviouslyEmployedChange}
                      employeeNumber={employeeNumber}
                      handleEmployeeNoChange={handleEmployeeNoChange}
                      employeeGrade={employeeGrade}
                      handleEmployeeGradeChange={handleEmployeeGradeChange}
                      employeeDesignation={employeeDesignation}
                      handleEmployeeDesignationChange={handleEmployeeDesignationChange}
                      employeeGroup={employeeGroup}
                      handleEmployeeGroupChange={handleEmployeeGroupChange}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* <div className="accordion-item"> */}
              <div className='acc-item-inner'>
                <h2 className='accordion-header' id='headingThree'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseThree'
                    aria-expanded='false'
                    aria-controls='collapseThree'
                  >
                    <img className='acc-icons' src={edu_icons} alt='..' /> Education
                  </button>
                </h2>
                <div
                  id='collapseThree'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingThree'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body' ref={eduRef}>
                    {/* <Education /> */}
                    <EducationsScreen
                      inputFields={eduInputFields}
                      cgpaRadio={cgpa}
                      percentageRadio={percentage}
                      addFields={addEduFields}
                      removeFields={removeEduFields}
                      educationalYears={educationalYears}
                      handleFormChange={handleEduFormChange}
                      handleCGPAChange={handleCGPAChange}
                      handleKeyPress={handleKeyPress}
                      handleCGPARadio={handleCGPARadio}
                      handleCurrentlyStudying={handleCurrentlyStudying}
                      handleChangeEducationalYears={handleChangeEducationalYears}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* <div className="accordion-item"> */}
              <div className='acc-item-inner'>
                <h2 className='accordion-header' id='headingFour'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFour'
                    aria-expanded='false'
                    aria-controls='collapseFour'
                  >
                    <img className='acc-icons' src={certi_icons} alt='..' /> Training & Certificates
                  </button>
                </h2>
                <div
                  id='collapseFour'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFour'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body'>
                    {/* <Training /> */}
                    <TrainingCertifi
                      inputFields={trainingInputFields}
                      handleFormChange={handleTrainingFormChange}
                      handleDateFromChange={handleTrainingDateFromChange}
                      handleDateToChange={handleTrainingDateToChange}
                      handleFileChange={handleCertifiFileChange}
                      addFields={addTrainingFields}
                      removeFields={removeTrainingFields}
                      handleDelete={handleDeleteTraining}
                      handleItemClick={handleTrainingItemClick}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* <div className="accordion-item"> */}
              <div className='acc-item-inner'>
                <h2 className='accordion-header' id='headingFive'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseFive'
                    aria-expanded='false'
                    aria-controls='collapseFive'
                  >
                    <img className='acc-icons' src={skills_icons} alt='..' /> Skills
                  </button>
                </h2>
                <div
                  id='collapseFive'
                  className='accordion-collapse collapse'
                  aria-labelledby='headingFive'
                  data-bs-parent='#accordionExample'
                >
                  <div className='accordion-body' ref={skillsRef}>
                    {/* <Skills /> */}
                    <SkillsScreen
                      chips={chips}
                      handleItemClick={handleSkillItemClick}
                      addNewSkill={addNewSkill}
                      handleDelete={handleSkillDelete}
                    />
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      {loading && <LoaderView message='Loading...' />}
      {showPopup && (
        <SuccessInterviewpopUp
          show={true}
          jobapp_id={props.job_id}
          handleClose={() => {
            setShowPopup(false)
            // redirect to applications tab
            if (popupText === 'Your online application has been submitted') {
              navigate('/candidateapplications')
            }
          }}
          message={popupText}
        />
      )}
      {showAttachmentPopup && (
        <UploadDocumentsPopup
          show={true}
          showSuccess={() => {
            setPopupText('Your online application has been submitted')
            showSuccessPopup()
          }}
          handleClose={() => {
            closeAttachmentPopup()
          }}
          candidateId={user_id}
          jobappId={jobAppId}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {showWarningPopup && (
        <WarningTARemovePopup
          show={true}
          message={message}
          handleClose={() => setShowWarningPopup(false)}
          handleYesSure={() => {
            setShowWarningPopup(false)
            HandleApplyFor()
          }}
        />
      )}
    </>
  )
}

export default ReviewVacancies
