import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import ic_plus from '../../../_metronic/layout/components/images/add_white_24dp (2).png'
import CreateNewTest from './CreateNewTest';
import QuestionShow from './QuestionShow';
import AddQuestion from './AddQuestion';
import { ImportPositionForm } from './PopUp';
import Tests from './Tests';

interface AddQuestionProps {
  handleClose: () => void;
}

const TestMain = () => {
  const intl = useIntl();
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility
  // const [showQuestionRepository, setShowQuestionRepository] = useState(false); // State to toggle Question Repository visibility
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const [showSecondDiv, setShowSecondDiv] = useState(false);
  const handleFirstButtonClick = () => {
    setShowFirstDiv(true);
    setShowSecondDiv(false);
  };

  const handleSecondButtonClick = () => {
    setShowFirstDiv(false);
    setShowSecondDiv(true);
  };

  const handleButtonClick = () => {
    setShowForm(true); // Show the form when button is clicked
  };

  const getAllTests = ()=>{

    // call function of child component to fetch list of all tests
    window.location.reload(); // Refresh the page
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Tests' })}</PageTitle>
      <div className='col-md-12 dashboard-top d-flex align-items-center'>
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/tests" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Tests
          </Link>
        </h3>
        <div className='ms-auto' >
          <button className='btn CreateIndiviualCompetency'
            // style={{ textAlign: 'center', border: 'none', padding: '10px 25px', backgroundColor: 'white', color: '#F36523 ', borderRadius: '10px', fontWeight: 'bold', fontFamily: 'Nunito', fontSize: '14px' }} 
            onClick={handleButtonClick}
          >
            <span>
              <img className='ml-1 margin-bottom-for-plusiconm' src={ic_plus}></img>
            </span>
            Add New Test
          </button>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px', marginBottom: '20px' }} >
        <button className={`button ${showFirstDiv ? 'active' : 'inactive'}`}
          onClick={handleFirstButtonClick} style={{ borderBottomLeftRadius: '10px', borderTopLeftRadius: '10px', borderBottomRightRadius: '0px', borderTopRightRadius: '0px' }} >Tests</button>

        <button className={`button ${showSecondDiv ? 'active' : 'inactive'}`}
          onClick={handleSecondButtonClick} style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }} >Question Repository</button>

        {/* for active button styling  */}
        <style>{`
        .button {
          padding: 12px 20px;
          border: none;
          cursor: pointer;
          width: 15vw;
          font-weight: 700;
          font-size: 16px;
         
        }
        .active {
          background-color: #ffffff;
          color: #C9562A;
          cursor: pointer;
        }
        .inactive {
          background-color: rgba(255, 255, 255, 0.24);
          color: white;
          cursor: pointer;
        }
        
        
      `}</style>

      </div>
      <div>

        {showForm && (
          <CreateNewTest
            type={'create'}
            testData={null}
            fetchAllTests={() => {
              setShowForm(false);
              getAllTests();
            }}
            handleClose={() => {
              setShowForm(false);
              // getAllTests();
            }}
          />
        )}
      </div>
      {showFirstDiv && (
        <div>
          <Tests />
        </div>
      )}
      {showSecondDiv && (
        <div style={{ marginBottom: '150px' }} >
          <QuestionShow />
        </div>

      )}
    </div>
  );
};

export default TestMain;
