//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import './CreateCompetency.css'
import cross from '../images/Layout-4-blocks.png'
import hurrayImg from '../images/hurray_img@3x.png';

type Props = {
    handleClose: () => void
    message: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const SuccessDialog = ({ handleClose, message }: Props) => {
    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         handleClose();
    //     }, 3000);

    //     // Clear the timeout when the component is unmounted or when handleClose is called manually
    //     return () => clearTimeout(timeoutId);
    // }, [handleClose]);

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123'>

                {/* end::Close */}
                <div className=' d-flex flex-column mt-10 pt-10'>
                    <img alt='' className='addcompetenctimage' src={hurrayImg}></img>
                    <h3 className='fw-bolder mx-auto my-5'>Success!</h3>
                    <p className=' mx-auto my-5 text-style col-md-8 '>{message}</p>
                </div>
            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
                <form
                >
                    <div className='btn-container'>
                        <button
                            onClick={handleClose}
                            type='button'
                            className='btn px-10 apply-review-button text-white'
                        >
                            Okay, Got it!
                        </button>
                    </div>
                </form>
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default SuccessDialog;
