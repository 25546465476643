//import liraries
import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core';
import Reports from './Reports';
import Leaderboard from '../Jobs/Leaderboard/Leaderboard';
import TabularReports from './TabularReports';
// import { PageTitle } from '../../../../_metronic/layout/core'
// create a component
const Analytics = () => {
    const intl = useIntl()


    const [reportsTab, setReportsTab] = useState(true)

    const ChangeInActiveIndex = (ActiveState) => {
        if (ActiveState === 'ActiveButtonClicked') {
            // setCompetenciesButton('individualCompetency')
            setReportsTab(true)
        } else if (ActiveState === 'InActiveButtonClicked') {
            setReportsTab(false)
            // setCompetenciesButton('groupCompetency')
        }
    }

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Analytics' })}</PageTitle>
            <div className='col-md-12 dashboard-top'>
                {/* <h1>Dashboard</h1> */}
                <h3>
                    <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        My Dashboard
                    </Link>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                    <Link to="/analytics" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        Analytics
                    </Link>
                </h3>
            </div>

            <div className='d-flex justify-content-center gy-5 gx-xl-8 mb-10'>
                <button
                    className={`${reportsTab === true
                        ? 'Onclicked-Active-Button btn-bg-white'
                        : 'Notclicked-Active-Button text-white'
                        } btn fw-bolder Activejobs-Leftbutton fs-4 custom-button-size btn-lg`}
                    type='button'
                    onClick={() => ChangeInActiveIndex('ActiveButtonClicked')}
                >
                    Reports
                </button>
                <button
                    type='button'
                    onClick={() => ChangeInActiveIndex('InActiveButtonClicked')}
                    className={`${reportsTab === true
                        ? 'group-competencies-btn-not-active text-white'
                        : 'group-competencies-btn-active btn-bg-white'
                        } btn fw-bolder fs-4 custom-button-size btn-lg`}
                >
                    Leaderboard
                </button>
            </div>

            {reportsTab ? (
                <div>
                    {/* <Reports /> */}
                    <TabularReports />
                </div>
            ) : (
                <div>
                    <Leaderboard />
                </div>
            )}


        </div>
    );
};


//make this component available to the app
export default Analytics;
