/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'
import {getFormattedDate} from '../../../../constants/CommonFunctions'
interface HeaderNotificationsMenuProps {
  notifications: any[] // Define the type of the 'notifications' prop
}

const HeaderNotificationsMenu: FC<HeaderNotificationsMenuProps> = ({notifications, ...props}) => {
  const {currentUser} = useAuth()
  return (
    <div className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px' data-kt-menu='true'>
      <div className='d-flex flex-column bgi-no-repeat rounded-top'></div>

      <div className='tab-content'>
        {/* Alerts */}
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications &&
              notifications.map((alert, index) => {
                let dateTime = alert?.activity_time
                // Extract the time portion
                let time = dateTime?.substring(11, 16)
                return (
                  <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light-${'warning'}`)}>
                          {' '}
                          <KTSVG path={`/media/${alert.icon}`} className={`svg-icon-2 svg-icon-${'warning'}`} />
                        </span>
                      </div>

                      <div className='mb-0 me-2'>
                        <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                          {alert.activity_heading}
                        </a>
                        <div className='text-gray-400 fs-7'>{alert.activity_content}</div>
                      </div>
                    </div>

                    <span className='badge badge-light fs-8'>
                      {time} <br /> {getFormattedDate(alert.activity_time)}
                    </span>
                  </div>
                )
              })}
          </div>
          <div className='py-3 text-center border-top'>
            {currentUser?.role === 'candidate' ? (
              <Link to='/candidatedashboard' className='btn btn-color-gray-600 btn-active-color-primary'>
                View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </Link>
            ) : (
              <Link to='/' className='btn btn-color-gray-600 btn-active-color-primary'>
                View All <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
