//import liraries
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './CreateCompetency.css'
import cross from '../images/Layout-4-blocks.png'
import bannerimage from '../../../../app/modules/Setting/images/group.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { RecruiterCard } from '../../content/cards/RecruiterCard'
import LoaderView from '../JobFunnelsModels/LoaderView'
import CustomToast from '../../content/toasts/CustomToast'

import userIcon from '../JobFunnelsModels/img/group-9680@3x.png'
import ShowError from '../../content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_POC_URL = `${API_URL}/poc-ra/get-pocs`
// export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_ALL_TA_URL = `${API_URL}/auth/user-list`
const REALLOCATE_RESOURCE_URL = `${API_URL}/poc-ra/resource-allocate`
export const GET_ALL_USERS_URL = `${API_URL}/auth/user-list`

type Props = {
  handleClose: () => void
  updateResources: () => void
  groupData: any
  groupHeadData: any
  pocData: any
  taLeadData: any
  resourceId: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const ReallocatePocRecruiter = ({ handleClose, updateResources, groupData, groupHeadData, taLeadData, pocData, resourceId }: Props) => {
  const [taLeadD, setTaLeadD] = useState<any>([])
  const [taLead, setTaLead] = useState<any>()
  const [pocs, setPocs] = useState([{}])
  const [poc, setPoc] = useState<any>()
  const [groupHeads, setGroupHeads] = useState([{}])
  const [groupHead, setGroupHead] = useState<any>()
  const [group, setGroup] = useState<any>()
  const { auth, logout } = useAuth()

  const [groupHeadQuery, setGroupHeadQuery] = useState('')
  const [groupHeadResults, setGroupHeadResults] = useState<any>([])
  const [pocQuery, setPocQuery] = useState('')
  const [pocResults, setPocResults] = useState<any>([])
  const [taLeadQuery, setTaLeadQuery] = useState('')
  const [taLeadResults, setTaLeadResults] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    setGroup(groupData)
    setGroupHead(groupHeadData)
    setPoc(pocData)
    setTaLead(taLeadData)
  }, [])

  useEffect(() => {
    (async () => {
      getAllUsers()
      GetAllPOC()
      getAllTALeads()
    })()
  }, [])

  const GetAllPOC = async () => {
    await axios
      .get(GET_ALL_POC_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        // setPocs(resp.data.selectedPoc)
        setPocs(resp.data?.pocs)
      })
      .catch((error) => {
        handleError(error);
      })
  }

  // get all users
  const getAllUsers = async () => {
    await axios.get(GET_ALL_USERS_URL, {
      params: {
        limit: 'all',
        role:'none'
      },
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setGroupHeads(res.data?.data)
    }).catch((error) => {
      handleError(error);
    });
  }

  const getAllTALeads = async () => {
    await axios.get(GET_ALL_TA_URL, {
      params: {
        role: 'ta_lead',
      },
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        let taList: any = res.data?.data
        setTaLeadD(taList)
      })
      .catch((error) => {
        handleError(error);
      })
  }

  const handleError = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.name === 'TokenExpiredError'
    ) {
      logout()
    } else if (error.response?.data?.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        setMessage(error.response.data.error.message)
        setShowErrorMessage(true)
      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    let data = {
      group_id: group?.group_id,
      group_head_id: groupHead?.user_id,
      poc_id: poc?.user_id,
      ta_lead_id: taLead?.user_id,
      resource_id: resourceId,
    }
    await axios
      .post(REALLOCATE_RESOURCE_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        updateResources()
        handleClose()
      })
      .catch((error) => {
        setLoading(false)
        handleError(error);
      })
  }

  // filter the list of Group Heads
  const handleGroupHeadChange = (event) => {
    const query = event.target.value;
    setGroupHeadQuery(query);
    if (query === null || query === undefined || query === '') {
      setGroupHead(null);
    }
    let filteredResults = groupHeads.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    setGroupHeadResults(filteredResults.length > 0 ? filteredResults : groupHeads)
  }

  const handleGroupHeadClick = (item) => () => {
    // check is the selected user is already selected as poc or ta lead or not if not set as group head else show error message
    if (item?.user_id === poc?.user_id) {
      setMessage('POC and Group head could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if (item?.user_id === taLead?.user_id) {
      setMessage('TA Lead and Group head could not be same.');
      setShowErrorMessage(true);
      return;
    }
    let query = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
    setGroupHeadQuery(query)
    setGroupHeadResults([])
    setGroupHead(item)
  }

  // filter the list of POCs
  const handlePocChange = (event) => {
    const query = event.target.value
    setPocQuery(query)
    let filteredResults = pocs.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    console.log('filteredResults: ', filteredResults)
    setPocResults(filteredResults.length > 0 ? filteredResults : pocs)
  }

  const handlePocClick = (item) => () => {
    // check is the selected user is already selected as group head or ta lead or not if yes show error message else set as poc
    if (item?.user_id === groupHead?.user_id) {
      setMessage('Group head and POC could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if (item?.user_id === taLead?.user_id) {
      setMessage('TA Lead and POC could not be same.');
      setShowErrorMessage(true);
      return;
    }

    setPoc(item)
    let query = `${item['first_name']} ${item['last_name']}`
    setPocQuery(query)
    setPocResults([])
  }

  // filter the list of recruiters
  const handleRecruiterChange = (event) => {
    const query = event.target.value
    setTaLeadQuery(query)
    let filteredResults = taLeadD.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    console.log('filteredResults: ', filteredResults)
    setTaLeadResults(filteredResults.length > 0 ? filteredResults : taLeadD)
  }

  const handleRecruiterClick = (item) => () => {
     // check is the selected user is already selected as group head or poc or not if yes show error message else set as ta lead
     if(item?.user_id === groupHead?.user_id){
      setMessage('Group head and TA Lead could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if(item?.user_id === poc?.user_id){
      setMessage('POC and TA Lead could not be same.');
      setShowErrorMessage(true);
      return;
    }
    setTaLead(item)
    let query = `${item['first_name']} ${item['last_name']}`
    setTaLeadQuery(query)
    setTaLeadResults([])
  }

  const showToast = (message) => {
    // Show the custom toast
    const toastContainer = document.getElementById('custom-toast-container')
    if (toastContainer) {
      toastContainer.innerHTML = '' // Clear any existing toast
      const customToast = document.createElement('div')
      toastContainer.appendChild(customToast)

      ReactDOM.render(<CustomToast message={message} />, customToast)
      customToast.scrollIntoView({ behavior: 'smooth', block: 'end' }) // Scroll to the toast message

      setTimeout(() => {
        customToast.remove() // Remove the toast after a certain duration
      }, 3000)
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={true}
      backdrop={true}
    >
      <>
        <div>
          <div className=' no-header-model no-header-modelllad '>
            <div className='modal-header  no-header-model no-header-modelllad'>
              <h2></h2>
              {/* begin::Close */}
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <img src={cross}></img>
              </div>
            </div>
            {/* end::Close */}
            <div className='mx-auto d-flex flex-column no-header-model'>
              <img className='addcompetenctimage' src={bannerimage}></img>
              <h3 className='fw-bolder fontsetrinh text-white my-5'>Reallocation </h3>
            </div>
          </div>

          {/* show group head info */}

          <div className='modal-body py-lg-8 px-lg-8'>
            {/* begin::Beader */}
            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form>
                <div className='form-row d-flex flex-column  mt-5 justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div className='me-4'>
                      <label className='form-label fs-6 fw-bolder '>Group Head Name/ Employee ID</label>

                      <input
                        type='text'
                        className='search-bar-style form-control   w-140px'
                        placeholder='Enter Group Head Name/Employee ID'
                        value={groupHeadQuery}
                        onChange={handleGroupHeadChange}
                        onFocus={handleGroupHeadChange}
                      />
                      {groupHeadResults.length > 0 && (
                        <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                          {groupHeadResults.map((item, index) => (
                            <li
                              key={index}
                              onClick={handleGroupHeadClick(item)}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              className='hover-li'
                            >
                              {`${item['fullName'] ? item['fullName'] : ''}  - ${item['employee_id'] ? item['employee_id'] : ''}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div>
            <RecruiterCard
              // avatar='/media/avatars/300-14.jpg'
              avatar={groupHead ? (groupHead['profile_pic'] ? groupHead['profile_pic'] : userIcon) : userIcon}
              name={groupHead ? groupHead['fullName'] : 'Name'}
              employeeId={groupHead ? groupHead['employee_id'] : 'Employee ID'}
              email={groupHead ? groupHead['email'] : 'Email'}
              phoneNumber={groupHead ? groupHead['phone_number'] : 'Phone No'}
            />
          </div>

          {/* show poc info */}

          <div className='modal-body py-lg-8 px-lg-8'>
            {/* begin::Beader */}
            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form>
                <div className='form-row d-flex flex-column  mt-5 justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div className='me-4'>
                      <label className='form-label fs-6 fw-bolder '>POC Name/ Employee ID</label>

                      <input
                        type='text'
                        className='search-bar-style form-control   w-140px'
                        placeholder='Enter POC Name/Employee ID'
                        value={pocQuery}
                        onChange={handlePocChange}
                        onFocus={handlePocChange}
                      />
                      {pocResults.length > 0 && (
                        <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                          {pocResults.map((item, index) => (
                            <li
                              key={index}
                              onClick={handlePocClick(item)}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              className='hover-li'
                            >
                              {`${item['fullName'] ? item['fullName'] : ''}  - ${item['employee_id'] ? item['employee_id'] : ''}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div>
            <RecruiterCard
              // avatar='/media/avatars/300-14.jpg'
              avatar={poc ? (poc['profile_pic'] ? poc['profile_pic'] : userIcon) : userIcon}
              name={poc ? poc['fullName'] : 'Name'}
              employeeId={poc ? poc['employee_id'] : 'Employee ID'}
              email={poc ? poc['email'] : 'Email'}
              phoneNumber={poc ? poc['phone_number'] : 'Phone No'}
            />
          </div>

          {/* show ta lead info */}
          <div className='modal-body py-lg-8 px-lg-8'>
            {/* begin::Beader */}
            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form>
                <div className='form-row d-flex flex-column  mt-5 justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div className='me-4'>
                      <label className='form-label fs-6 fw-bolder '>TA Lead Name/ Employee ID</label>

                      <input
                        type='text'
                        className='search-bar-style form-control   w-140px'
                        placeholder='Enter TA Lead Name/Employee ID'
                        value={taLeadQuery}
                        onChange={handleRecruiterChange}
                        onFocus={handleRecruiterChange}
                      />
                      {taLeadResults.length > 0 && (
                        <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                          {taLeadResults.map((item, index) => (
                            <li
                              key={index}
                              onClick={handleRecruiterClick(item)}
                              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                              className='hover-li'
                            >
                              {`${item['fullName'] ? item['fullName'] : ''} - ${item['employee_id'] ? item['employee_id'] : ''}`}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div>
            <RecruiterCard
              // avatar='/media/avatars/300-14.jpg'
              avatar={taLead ? (taLead['profile_pic'] ? taLead['profile_pic'] : userIcon) : userIcon}
              name={taLead ? taLead['fullName'] : 'Name'}
              employeeId={taLead ? taLead['employee_id'] : 'Employee ID'}
              email={taLead ? taLead['email'] : 'Email'}
              phoneNumber={taLead ? taLead['phone_number'] : 'Phone No'}
            />
          </div>

          <div className='modal-body py-lg-8 px-lg-8'>
            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form>
                <div className='d-flex align-items-center justify-content-center'>
                  <button
                    // type='submit'
                    className='btn my-15 px-10 btnStyle my-5 text-white'
                    onClick={(event) => handleSubmit(event)}
                  >
                    Reallocate
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='mb-8' id='custom-toast-container'></div>

        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default ReallocatePocRecruiter
