//import liraries
import React, { useEffect, useState } from 'react'
import './JobsFunelling.css'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import unchecked from './images/Group 10062.png'
import checked from '../auth/images/tick.png'
import AvatarIcon from './img/Group 9681.png'
import axios from 'axios'
import { useAuth } from '../auth'
import ViewProfileApplication from '../../../_metronic/partials/modals/JobFunnelsModels/ViewProfileApplication'
import GetApprovalReasonPopup from '../../../_metronic/partials/modals/JobFunnelsModels/GetApprovalReasonPopup'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'

const API_URL = process.env.REACT_APP_API_URL

const REJECTED_CANDIDATES_URL = `${API_URL}/jobapplication/get-rejected-candidates`
const APPROVE_CANDIDATE_URL = `${API_URL}/jobapplication/accept-rejected-job-application-mail`

// create a component
const RejectedCandidates = ({ id, ...params }) => {
  // let {id} = useParams<any>()
  const { currentUser, auth, logout } = useAuth()
  const [jobId, setJobId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [jobTitle, setJobTitle] = useState('Job Title')
  const [rejectedCandidates, setRejectedCandidates] = useState<any>()
  const [checkedState, setCheckedState] = useState(false)
  const [applicationData, setApplicationData] = useState<any>()
  const [applicationIndex, setApplicationIndex] = useState<any>(0)
  const [showProfile, setShowProfile] = useState(false)
  const [checkedstate, setcheckedstate] = useState(false)
  const [selectedApplicants, setSelectedApplicants] = useState<any>([])
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [showGetReasonPopup, setShowGetReasonPopup] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (id) {
      setJobId(id)
    }
  }, [id])

  useEffect(() => {
    ; (() => {
      fetchRejectedCandidates()
    })()
  }, [])

  // fetching list of rejected candidates
  const fetchRejectedCandidates = () => {
    let data = {
      job_id: id,
    }
    setIsLoading(true)
    axios
      .post(REJECTED_CANDIDATES_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setIsLoading(false)
        console.log('rejectedCandidates: ', res.data)
        let title = res.data?.title
        setJobTitle(title)
        setRejectedCandidates(res.data?.jobs)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const checkedStateFunction = () => {
    setCheckedState(!checkedState)
  }

  const findInstitute = (qualificationData) => {
    // Find the value of "Institution" where "education_level" is "Bachelors"
    let institutionForBachelors = '-'

    if (qualificationData && Array.isArray(qualificationData)) {
      for (const qualifications of qualificationData) {
        for (const key in qualifications) {
          const qualification = qualifications[key]
          if (qualification.education_level === 'Bachelors') {
            institutionForBachelors = qualification.institution || ''
            break // No need to continue the loop once found
          }
        }
        if (institutionForBachelors && institutionForBachelors !== '-') {
          break // No need to continue the outer loop once found
        }
      }
    }

    return institutionForBachelors
  }

  const showReasonRequiredPopup = (jobAppId) => {
    setCurrentJobAppId(jobAppId)
    setShowGetReasonPopup(true)
  }

  const approveCandidate = async (jobAppId, reason) => {
    let data = {
      jobapp_id: jobAppId,
      reason: reason,
    }
    setIsLoading(true)
    await axios
      .post(APPROVE_CANDIDATE_URL, data)
      .then((res) => {
        // setIsLoading(false);
        setCurrentJobAppId('')
        setShowGetReasonPopup(false)
        setShowSuccessPopup(true);
        fetchRejectedCandidates()
        console.log('approveRejectedCandidate: ', res.data)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const showProfilePopup = (application, index) => {
    setApplicationData(application)
    setApplicationIndex(index)
    setShowProfile(true)
  }

  const checkAllApplications = () => {
    if (rejectedCandidates) {
      let allCandidates: any = []
      rejectedCandidates.map((result, index) => {
        allCandidates.push(result)
      })
      if (checkedstate) {
        setSelectedApplicants([])
        setcheckedstate(false)
      } else {
        setSelectedApplicants(allCandidates)
        setcheckedstate(true)
      }
    }
  }

  const isApplicantSelected = (candidateId) => {
    // return selectedApplicants.includes(candidateId);
    return selectedApplicants.some((applicant) => applicant?.candidate?.user_id === candidateId)
  }

  const updateSelectedApplicants = (jobData) => {
    let candidateId = jobData?.candidate?.user_id
    // Check if candidateId is already in the list
    const isSelected = selectedApplicants.some((applicant) => applicant?.candidate?.user_id === candidateId)
    // If already selected, remove it; otherwise, add it
    if (isSelected) {
      const updatedList = selectedApplicants.filter((applicant) => applicant?.candidate?.user_id !== candidateId)
      setSelectedApplicants(updatedList)
    } else {
      // also check is the total selected applications are 7 do not add into list and show warning message else add application into list
      setSelectedApplicants((prevList) => [...prevList, jobData])

    }
  }

  const intl = useIntl()
  return (
    <div>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Rejected Candidates'})}</PageTitle>
      
      <div className='col-md-12 dashboard-top'>
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/jobs" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Jobs
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="#" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Rejected Candidates
          </Link>
        </h3>
      </div> */}
      <div >
        {/* <div>
          <label className='form-label fs-4 fw-bolder' style={{color: 'white'}}>
            Job: {jobTitle}
          </label>
        </div> */}
        <div style={{ position: 'relative' }}>
          {isLoading && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          {/* begin::Table container */}
          <div className='table-responsive px-8'>
            {/* begin::Table */}

            <table className='table my-table mx-auto table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold graycolor grajabg '>
                  <th className='min-w-20px align-middle firstrowmarginindex'>
                    {/* <div
                      // onClick={() => checkedStateFunction()}
                      onClick={() => checkAllApplications()}
                    >
                      {checkedstate === false ? (
                        <>
                          {' '}
                          <img src={unchecked}></img>
                        </>
                      ) : (
                        <>
                          {' '}
                          <img src={checked}></img>
                        </>
                      )}
                    </div> */}
                  </th>
                  <th className='min-w-100px align-items-center ps-2 align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Total Experience</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Banking Experience</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>University</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Profile Match</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Age</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Stage</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Action</label>
                  </th>
                  {/* <th className='min-w-100px '></th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {rejectedCandidates &&
                  rejectedCandidates.map((jobData, index) => {
                    let application = jobData?.application
                    const jobApplicationId = application?.jobapp_id
                    let candidate = jobData?.candidate
                    let user = jobData?.user

                    // find the institute
                    let qualification = JSON.parse(candidate?.qualification)
                    let institute = findInstitute(qualification)
                    let candidateId = candidate?.user_id
                    let rowSelected = isApplicantSelected(candidateId)
                    let stage = application?.previous_stage
                    if (stage === 'stage1' || stage === 'stage2' || stage === 'stage3') {
                      stage = 'Interview'
                    }
                    return (
                      <tr>
                        <td className='firstrowmarginindex'>
                          {/* <div
                            // onClick={() => checkedStateFunction()}
                            onClick={() => updateSelectedApplicants(jobData)}
                          >
                            {rowSelected === false ? (
                              <>
                                {' '}
                                <img src={unchecked}></img>
                              </>
                            ) : (
                              <>
                                {' '}
                                <img src={checked}></img>
                              </>
                            )}
                          </div> */}
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              <img
                                className='profile-user-icon'
                                src={candidate['profile_pic'] !== null ? candidate['profile_pic'] : AvatarIcon}
                                alt=''
                              />
                              {/* <img className='rounded-circle' src={candidate['profile_pic']} alt='' /> */}
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <label className='serangkang234 my-2 '>
                                {/* {candidategData['candidate_details']['name']} */}
                                {user?.fullName}
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className=' experienceJobstable '>
                            <td>
                              {/* {
                                  isNaN(parseFloat(candidate['job_experience'])) || isNaN(parseFloat(candidate['banking_experience'])) ? 0
                                    : (parseFloat(candidate['job_experience']) + parseFloat(candidate['banking_experience'])).toFixed(2)
                                } */}
                              {parseFloat(application['totalExperience']).toFixed(2)}
                              {/* {(candidate['jobExperience']).toFixed(2)} */} yrs
                            </td>
                          </div>
                        </td>

                        <td>
                          <div className=' experienceJobstable '>
                            <td>{application['banking_experience']! == null ? <>0</> : application['banking_experience']} yrs</td>
                          </div>
                        </td>
                        <td>
                          <div className='experienceJobstable'>
                            {application['institute']}
                            {/* {institute} */}
                          </div>
                        </td>

                        <td className='text-end'>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='text-mutedjgfk me-2 fs-7 fw-semibold'>
                                {application['profile_match'] != null && !isNaN(application['profile_match'])
                                  ? `${application['profile_match']}%`
                                  : '0%'}
                              </span>
                            </div>
                            <div className='progress h-6px w-50'>
                              <div
                                className='progress-bar bg-primary123'
                                role='progressbar'
                                style={{ width: `${application['profile_match'] || 0}%` }}
                              ></div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='dateApplicationtable'>
                            {new Date(application['created_at']).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}
                          </div>
                        </td>

                        <td>
                          <label className='experienceJobstable'>{`${stage}`}</label>
                        </td>

                        <td
                          key={`td-${index}`}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            marginTop: '12px',
                            marginLeft: '2rem',
                            marginRight: '2rem',
                            border: 'none',
                            backgroundColor: 'transparent',
                          }}
                        >
                          <button
                            key={`button-${index}`}
                            className='Approvebutton'
                            // onClick={() => approveCandidate(jobApplicationId)}
                            onClick={() => showReasonRequiredPopup(jobApplicationId)}
                          >
                            Approve
                          </button>

                          <button
                            className=' ViewUserProfileTag '
                            style={{ border: 'none', backgroundColor: 'transparent', marginLeft: '1rem' }}
                            onClick={() => showProfilePopup(jobData, index)}
                          >
                            View Profile
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>

        {showGetReasonPopup && (
          <GetApprovalReasonPopup
            show={true}
            handleClose={() => {
              setCurrentJobAppId('')
              setShowGetReasonPopup(false)
            }}
            handleSubmit={async (reason) => {
              await approveCandidate(currentJobAppId, reason)
              // setCurrentJobAppId('')
              // setShowGetReasonPopup(false)
            }}
          />
        )}

        {showProfile && (
          <ViewProfileApplication
            show={true}
            name={`${applicationData?.user?.first_name} ${applicationData?.user?.last_name}`}
            jobapp_id={applicationData?.application?.jobapp_id}
            user_id={applicationData?.user?.user_id}
            email={applicationData?.user?.email}
            profile_pic={applicationData?.user?.profile_pic}
            cnic={applicationData?.user?.cnic}
            phone_number={applicationData?.user?.phone_number}
            education={applicationData?.application?.institute}
            skills={applicationData?.candidate?.skills}
            work_Experience={applicationData?.candidate?.work_experience}
            total_exp={applicationData?.application?.totalExperience}
            banking_exp={applicationData?.application?.bankingExperience}
            nonBanking_exp={applicationData?.application?.jobExperience}
            educations={applicationData?.candidate?.qualification}
            certifications={applicationData?.candidate?.certification}
            personalData={applicationData?.user?.dob}
            profile_match={applicationData?.application?.profile_match}
            position={jobTitle}
            recuiter_id={applicationData?.application?.recuiter_id}
            videoResumeUrl={''}
            testExists={'false'}
            applicationStage={applicationData?.application?.jobapp_stage}
            index={applicationIndex}
            handleClose={() => {
              setShowProfile(false)
            }}
            recaller={() => {
              // recaller(index)
            }}
          />
        )}

        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        {showSuccessPopup && (
          <SuccessDialog
            message={'Request Email to reconsider rejected candidate has been sent successfully!'}
            handleClose={() => {
              setShowSuccessPopup(false);
            }}
          />
        )}
      </div>
    </div>
  )
}

//make this component available to the app
export default RejectedCandidates
