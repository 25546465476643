//import liraries
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core';
import { Link } from 'react-router-dom'
import imagePath from '../../../constants/imagePath';
import axios from 'axios';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import { useAuth } from '../auth/core/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { getFormattedDate } from '../../../constants/CommonFunctions';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import RequisitionRejectionPopup from '../../../_metronic/partials/modals/JobFunnelsModels/RequisitionRejectionPopup';
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';


const API_URL = process.env.REACT_APP_API_URL
const GET_POSITION_DATA_URL = `${API_URL}/position/`
const APPROVE_REQUISITION_URL = `${API_URL}/position/`

// create a component
const RequisitionApproval = () => {
    // let positionId = '350bd874-c059-4fc5-8ea3-5d603db33f9c'
    const { currentUser, auth, logout } = useAuth()
    const navigate = useNavigate()
    const intl = useIntl()
    let { group_head_id, position_id } = useParams();
    const [position, setPosition] = useState<any>();
    const [batchPositions, setBatchPositions] = useState<any>([]);
    const [taLead, setTaLead] = useState<any>();
    const [replacementEmployees, setReplacementEmployees] = useState<any>([]);
    const [documents, setDocuments] = useState<any>();
    const [advertisementMode, setAdvertisementMode] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {
        (() => {
            if (position_id) {
                getPositionData();
            }
        })()
    }, [position_id])

    const getPositionData = () => {
        axios.get(GET_POSITION_DATA_URL + position_id, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res.data;
            let positionData = data?.position;
            let replacements = positionData?.replacements;
            if (replacements) {
                setReplacementEmployees(JSON.parse(replacements))
            }
            setPosition(positionData);
            setBatchPositions(data?.batch);
            setTaLead(data?.ta_lead);
            let docs = positionData?.documents;
            if (docs) {
                docs = JSON.parse(docs);
                setDocuments(docs)
            }
            let advertisementTypes = positionData?.mode_of_advertisement;
            if (advertisementTypes) {
                advertisementTypes = JSON.parse(advertisementTypes);
                setAdvertisementMode(advertisementTypes)
            }
        }).catch((error) => {
            displayError(error)
        });
    }

    const displayError = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            if (error.response.status === 401) {
                setMessage('User not authenticated, Please login and try again')
                setShowErrorMessage(true)
                setTimeout(() => {
                    navigate('/auth');
                }, 3000); // 3000 milliseconds = 3 seconds
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        }
    }

    const downloadDocument = async (url, fileName) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const urlBlob = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = urlBlob;
            link.download = fileName; // getFileNameFromUrl(url);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('There was an error downloading the document:', error);
        }
    };

    const getFileNameFromUrl = (url) => {
        return url.split('/').pop();
    };

    const approveRequisition = (reason) => {
        let reasonObj = {
            reason: reason,
            date: new Date()
        }
        if (position?.batch_id) {

            raiseMultipleRequisitions(reason);

        } else {

            const data = {
                group_name: position['group_name'],
                title: position['title'],
                max_basic_salary_per_month: position['max_basic_salary_per_month'],
                max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
                max_grade_range: position['max_grade_range'],
                contact_type: position['contact_type'],
                functional_area: position['functional_area'],
                sub_functional_area: position['sub_functional_area'],
                section: position['section'],
                office_type: position['office_type'],
                code: position['code'],
                location: position['location'],
                position_type: position['position_type'],
                status: reason ? 'REQUISITION_NOT_RAISED' : 'REQUISITION_RAISED',
                rejection_reason: reason ? JSON.stringify(reasonObj) : reason,
                remarks: position['remarks'],
                job_description: position['job_description'],
                ta_lead_id: taLead?.user_id,
                replacements: position['replacements'],
                mode_of_advertisement: position['mode_of_advertisement'],
                documents: position['documents']
            }
            setLoading(true)

            axios.put(APPROVE_REQUISITION_URL + position.position_id, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                setLoading(false)
                // show success popup
                setMessage(reason ? 'Requisition Rejected Successfully.' : 'Requisition Raised Successfully.')
                setShowSuccessPopup(true);
                // handleClose()
            }).catch((error) => {
                setLoading(false)
                displayError(error)
            })
        }
    }

    const raiseMultipleRequisitions = async (reason) => {
        if (batchPositions !== null && batchPositions !== undefined && batchPositions.length > 0) {
            let reasonObj = {
                reason: reason,
                date: new Date()
            }
            setLoading(true)

            try {
                const totalPositions = batchPositions.length
                let completedApiCalls = 0

                for (const positionLocData of batchPositions) {
                    console.log('Requisition of position: ', positionLocData?.position_id)
                    const data = {
                        group_name: position['group_name'],
                        title: position['title'],
                        max_basic_salary_per_month: position['max_basic_salary_per_month'],
                        max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
                        max_grade_range: position['max_grade_range'],
                        contact_type: position['contact_type'],
                        functional_area: position['functional_area'],
                        sub_functional_area: position['sub_functional_area'],
                        section: position['section'],
                        office_type: positionLocData['office_type'],
                        code: positionLocData['code'],
                        location: positionLocData['location'],
                        position_type: positionLocData['position_type'],
                        status: reason ? 'REQUISITION_NOT_RAISED' : 'REQUISITION_RAISED',
                        remarks: positionLocData['remarks'],
                        rejection_reason: reason ? JSON.stringify(reasonObj) : reason,
                        job_description: positionLocData['job_description'],
                        ta_lead_id: taLead?.user_id,
                        mode_of_advertisement: positionLocData['mode_of_advertisement'],
                        documents: positionLocData['documents']
                    }

                    const res = await axios.put(APPROVE_REQUISITION_URL + positionLocData.position_id, data, {
                        headers: {
                            Authorization: `Bearer ${auth?.token}`,
                            'Content-Type': 'application/json',
                        },
                    })

                    // console.log(`API call for ${position.location} completed: `, res.data)
                    // Increment the counter for completed API calls
                    completedApiCalls++

                    // Check if this is the last API call
                    if (completedApiCalls === totalPositions) {
                        setLoading(false)
                        // show success popup
                        setMessage(reason ? 'Requisition Rejected Successfully.' : 'Requisition Raised Successfully.')
                        setShowSuccessPopup(true);
                        //   handleClose()
                        //   navigate('/positions')
                    }
                }
            } catch (err) {
                setLoading(false)
                displayError(err)
            }
        } else {

        }
    }

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className='col-md-12 dashboard-top'>
                {/* <h1>Dashboard</h1> */}
                <h3>
                    <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                    <Link to="/auth" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        My Dashboard
                    </Link>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>Requisition Approval
                    {/* <Link to="/" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        Requisition Approval
                    </Link> */}
                </h3>
            </div>
            <div className='card-bg shadow-sm pt-8 px-10 py-6' style={{ backgroundColor: '#FFFFFF', height: '100%' }}>
                <div id='candidate-offer-letter-view mx-10 my-6 col-md-8'>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                        }}
                    >
                        <img src={imagePath.ic_notes_orange} className="score-header-icon-internally" alt="Job Header Icon" />
                        <label className='form-label fs-3 fw-bolder' style={{ color: '#F36523', marginLeft: '8px' }}>Requisition Approval</label>
                    </div>
                </div>
                <div className=' d-flex flex-column '>
                    <div
                        className='col-md-12'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div className='col-md-11 form-row d-flex mt-10 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Group</label>
                                <label className='form-control label-style fs-5'>{position?.group_name}</label>
                            </div>

                            <div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Functional Area</label>
                                <label className='form-control label-style fs-5'>{position?.functional_area}</label>
                            </div>

                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Sub-Functional Area</label>
                                <label className='form-control label-style fs-5'>{position?.sub_functional_area}</label>
                            </div>
                        </div>
                        <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Section</label>
                                <label className='form-control label-style fs-5'>{position?.section}</label>
                            </div>

                            <div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Functional Designation</label>
                                <label className='form-control label-style fs-5'>{position?.title}</label>
                            </div>

                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Maximum Grade Range</label>
                                <label className='form-control label-style fs-5'>{position?.max_grade_range}</label>
                            </div>
                        </div>

                        <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>

                            {!position?.batch_id && (<div className='form-group col-md-4'>
                                <label className='form-label labelse '>No Of Positions</label>
                                <label className='form-control label-style fs-5'>{position?.no_of_positions}</label>
                            </div>)}

                            {!position?.batch_id && (<div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Location</label>
                                <label className='form-control label-style fs-5'>{position?.location}</label>
                            </div>)}

                        </div>

                        <div className='col-md-11 mt-6 justify-content-spacearound p-4' style={{ alignItems: 'baseline', borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse '>Hiring Nature</label>
                            <div className='col-md-9 d-flex flex-column mt-4 '>
                                {position?.position_type === 'budgeted' ? (
                                    <div className='form-group col-md-4 mb-4 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                                            New Requirement
                                        </label>
                                    </div>) : (
                                    <div className='form-group col-md-5 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                                            Replacement/Transfer/Resignation
                                        </label>
                                    </div>
                                )}


                            </div>
                            {position?.position_type === 'replacement' ? (
                                <div className='col-md-11 mt-10 d-flex flex-column'>
                                    <label className='form-label labelse '>Last Approved Organogram</label>

                                    {(replacementEmployees && replacementEmployees.length > 0) && replacementEmployees.map((employee, index) => {

                                        return (
                                            <div key={employee?.user_id} className='col-auto mb-4'>
                                                <label className='fs-6 fw-semibold mt-4'>{`${getFormattedDate(employee?.leaving_date)} / In replacement of "${employee?.fullName} # ${employee?.employee_id}"`}</label>
                                                <div className='d-flex align-items-center mt-2'>
                                                    <img style={{ height: '44px', width: '44px', objectFit: 'cover', borderRadius: '50%' }} src={employee?.profile_pic ? employee?.profile_pic : imagePath.userIcon} />
                                                    <div className='ms-2 d-flex flex-column'>
                                                        <label className='fw-bolder fs-6'>{employee?.fullName}</label>
                                                        <label className='fw-regular fs-7'>{employee?.email}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>) : (<div></div>)}

                        </div>

                        {/* {position?.batch_id && (
                        <div className='table-responsive col-md-11 justify-content-spacearound'>
                            <table className='table custom-table' style={{ borderRadius: '10px' }}>
                                <thead style={{ borderRadius: '10px' }}>
                                    <tr className='table-header' style={{ borderRadius: '10px' }}>
                                        <th scope='col' className=' form-label fw-bolder fs-6 ps-4'>
                                            Position
                                        </th>
                                        <th scope='col' className=' form-label fw-bolder fs-6'>
                                            Code
                                        </th>
                                        <th scope='col' className=' form-label fw-bolder fs-6'>
                                            Region
                                        </th>
                                        <th scope='col' className=' form-label fw-bolder fs-6'>
                                            Location
                                        </th>
                                        <th scope='col' className=' form-label fw-bolder fs-6'>
                                            No. of Positions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(positionLocations && positionLocations.length > 0) ?
                                        positionLocations.map((row, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td className='pt-3 ps-4'>{position?.title}</td>
                                                    <td className='pt-3'>{row?.code}</td>
                                                    <td className='pt-3'>{row?.officeType}</td>
                                                    <td className='pt-3'>{row.location}</td>
                                                    <td className='pt-3'>{row.no_of_positions}</td>
                                                </tr>
                                            )
                                        })
                                        : (<div></div>)}
                                </tbody>
                            </table>
                        </div>
                    )} */}

                        <div className='col-md-11 mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-6'>Necessary Documents</label>
                            {(documents && documents.length > 0) ? documents.map((doc, index) => {
                                return (<div key={index} className='d-flex align-items-center col-md-12 mb-4'>
                                    <label className='form-label labelse col-md-3'>{doc?.title}</label>
                                    <button className='candi-btn2 btn fw-bolder text-white px-4 py-2'
                                        onClick={() => downloadDocument(doc?.url, doc?.title)}
                                    >Download</button>
                                </div>)
                            }) : (<div>Documents are not available</div>)}
                        </div>
                        <div className='col-md-11 mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-8'> Job Description</label>
                            <div  >
                                {ReactHtmlParser(position?.job_description || "")}
                            </div>
                        </div>
                        <div className='col-md-11 d-flex mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <div className='d-flex flex-column col-md-5'>
                                <label className='form-label labelse mb-8'> Mode of Job Advertisement</label>
                                {advertisementMode ? Object.keys(advertisementMode).map((key, index) => (
                                    advertisementMode[key] && (
                                        <div key={index} className='d-flex align-items-center mb-4'>
                                            <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                            <label className='fs-5 fw-semibold ms-4 '>{`${key === 'internal' ? 'Internal' : (key === 'rozee' ? 'Rozee.pk' : (key === 'bop' ? 'BOP Career Portal' : 'Other'))}`}</label>
                                        </div>
                                    )
                                )) : (<div></div>)}
                            </div>
                            <div>
                                <label className='form-label labelse mb-8'> Details of Hiring</label>
                                {position?.position_type === 'budgeted' ? (
                                    <div className='form-group col-md-4 mb-4 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label className='fs-5 fw-semibold' style={{ margin: '0px 0px 0px 10px' }}>
                                            Budgeted
                                        </label>
                                    </div>) : (
                                    <div className='form-group col-md-5 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label className='fs-5 fw-semibold' style={{ margin: '0px 0px 0px 10px' }}>
                                            Replacement
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        {position?.batch_id && (
                            <div className='table-responsive col-md-11 mt-8 justify-content-spacearound'>
                                <table className='table custom-table' style={{ borderRadius: '10px' }}>
                                    <thead style={{ borderRadius: '10px' }}>
                                        <tr className='table-header' style={{ borderRadius: '10px' }}>
                                            <th scope='col' className=' form-label fw-bolder fs-6 ps-4'>
                                                Position
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Code
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Region
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Location
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                No. of Positions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(batchPositions && batchPositions.length > 0) ?
                                            batchPositions.map((row, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td className='pt-3 ps-4'>{position?.title}</td>
                                                        <td className='pt-3'>{row?.code}</td>
                                                        <td className='pt-3'>{row?.office_type}</td>
                                                        <td className='pt-3'>{row.location}</td>
                                                        <td className='pt-3'>{row.no_of_positions}</td>
                                                    </tr>
                                                )
                                            })
                                            : (<div></div>)}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className='col-md-11 d-flex flex-column mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-6'>Remarks/Justification</label>
                            <div >
                                <p className='fs-5 fw-semibold ' >{position?.remarks}</p>
                            </div>
                        </div>
                        <div className='col-md-11 mt-10'>
                            <div className='col-md-4 d-flex flex-column'>
                                <label className='form-label labelse'>TA Lead</label>
                                <div className='d-flex' >
                                    <div className='d-flex align-items-center mt-2'>
                                        <img style={{ height: '44px', width: '44px', objectFit: 'cover', borderRadius: '50%' }} src={taLead?.profile_pic ? taLead?.profile_pic : imagePath.userIcon} />
                                        <div className='ms-2 d-flex flex-column'>
                                            <label className='fw-bolder fs-6'>{taLead?.name}</label>
                                            <label className='fw-regular fs-7'>{taLead?.email}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-11 mt-10 d-flex align-items-center justify-content-center pb-10'>
                            <button className='fw-bolder fs-5 px-8 btn me-8' style={{ backgroundColor: '#FFF6ED', borderRadius: '6px', color: '#C4320A' }}
                                onClick={() => setShowReasonPopup(true)}>
                                Reject
                            </button>
                            <button className='candi-btn2 btn text-white fw-bolder'
                                onClick={() => { approveRequisition(null) }}>
                                Approve
                            </button>
                        </div>
                    </div>
                </div>

                {loading && <LoaderView message='Loading...' />}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
                {showReasonPopup && (
                    <RequisitionRejectionPopup
                        show={true}
                        handleClose={() => setShowReasonPopup(false)}
                        handleSubmit={(reason) => {
                            setShowReasonPopup(false);
                            approveRequisition(reason)
                        }}
                    />
                )}
                {showSuccessPopup && (
                    <SuccessDialog
                        message={message}
                        handleClose={() => {
                            setShowSuccessPopup(false)
                            navigate('/auth')
                        }}
                    />
                )}
            </div>
        </div>
    );
};

//make this component available to the app
export default RequisitionApproval;
