//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import icWarning from '../../images/warning@2x.png'
import './ModelStyles.css'
import imagePath from '../../../../constants/imagePath';

type Props = {
    handleClose: () => void
    handleYesSure: (objectionNode: string) => void

}
const modalsRoot = document.getElementById('root-modals') || document.body
// create a component
const OfferLetterObjectionPopup = ({ handleClose, handleYesSure }: Props) => {

    const [note, setNote] = useState('');

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    }

    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            // onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h3 className='mx-auto fw-bolder pb-10 mt-10'>Specify Reason for Rejection</h3>
                        </div>
                        <div className='mx-10 d-flex flex-column' style={{ borderRadius: '10px', border: 'solid 1px #D6D6E0' }}>
                            <label className='fw-bolder fs-5 p-6' style={{ borderBottom: 'solid 1px #D6D6E0' }} >Write rejection reason in detail</label>
                            <textarea
                                className='mt-4 mb-4 mx-6'
                                style={{ border: 'none', resize: 'none' }}
                                value={note}
                                placeholder='Type here'
                                onChange={handleNoteChange}
                                rows={4}
                            />
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div>

                                    </div>
                                    <div className='btn-container d-flex'>

                                        <button onClick={handleClose} className='px-10 py-4 hollow-btn fw-bolder fs-5 me-4'>
                                            Cancel
                                        </button>
                                        <button
                                            onClick={() => {
                                                handleYesSure(note)
                                            }}
                                            type='button'
                                            className='btn px-10 apply-review-button text-white'
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default OfferLetterObjectionPopup;
