import React, { ChangeEvent, ChangeEventHandler } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import './RequestForApprovals.css'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import UserIcon from './img/group-9680@3x.png'
import ApprovalIcon from './img/notes (1)@2x.png'
import CheckedIcon from './img/Component 193 – 8@2x.png'
import ExperienceIcon from './img/experience@2x.png'
import { Button } from 'bootstrap'
import InfromationIcom from './img/information (3)@2x.png'
import VerifyApprovalsModals from './VerifyApprovalsModals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SuccessIcon from './img/online_job_interview [Converted]@2x.png'
import DownLoadIcon from '../../../../app/modules/JobsFunneling/img/Component 237 – 1.png'
import Cap from './img/mortarboard.png'
import SalaryIcon from './img/salary@2x.png'
import imagePath from '../../../../constants/imagePath'
import { MenuItem, Select } from '@mui/material'
import ShowError from '../../content/toasts/ShowError'
import OfficeNoteApprovalStep2 from './OfficeNoteApprovalStep2'

const API_URL = process.env.REACT_APP_API_URL

export const GET_CANDIDATE_EXPERIENCE = `${API_URL}/jobapplication/show-candidate-experience`

export const GET_JOB_DETAILS = `${API_URL}/job/`

export const GET_JOB_PREVIOUS_PAY = `${API_URL}/jobapplication/update-previous-pay`

export const GET_ALL_RECRUITERS_URL = `${API_URL}/jobapplication/get-group-heads`
const GET_PANEL_MEMBERS_URL = `${API_URL}/panel/show-all-members`

export const GET_RECRUITER_URL = `${API_URL}/recruiter/`

export const CREATE_PANEL = `${API_URL}/panel/create`

export const SEND_EMAIL_TO_PANEL_MEMBERS = `${API_URL}/panel/send-and-approve`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  job_id: string
  onNextStep: () => void
  onSendEmail: () => void
  onVerifyClick: () => void
  candidateData: any
  candidate_id: string
  profile_pic: string
  position: string
  uni: string
  bexp: string
  jexp: string
  degree: string
  cityPreference: string
  updateCandidatesList: () => void
}

interface Recruiter {
  user_id: string
  name: string
  email: string
  currentTarget: string
  employee_id: string
  first_name: string
  fullName: string
  profile_pic: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

function Step1({ show, name, jobapp_id, job_id, candidateData, onNextStep, handleClose, updateCandidatesList, degree, cityPreference }: Props) {
  const { auth, logout } = useAuth()
  const [jobDetails, setJobDetails] = useState<any>([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const [batchPositionId, setBatchPositionId] = useState('')
  const [batchPositions, setBatchPositions] = useState<any>()
  const [formValues, setFormValues] = useState<any>({
    basicPay: '',
    carAllowance: '',
    fuel: '',
    bonus: '',
    monthlyPackage: '',
    percentIncrease: '',
    allowanceType: '',
  })

  const [carAllowanceChecked, setCarAllowanceChecked] = useState(false)
  const [fuelChecked, setFuelChecked] = useState(false)
  const [bonusChecked, setBonusChecked] = useState(false);
  const [percentIncrease, setPercentIncrease] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [basicpay, setBasicpay] = useState(0)
  const [carAllowance, setCarAllowance] = useState(0)
  const [isEditable, setIsEditable] = useState(false)
  const [storedData, setStoredData] = useState<any>({})
  const [basicPayMedical, setBasicPayMedical] = useState<any>()
  const [basicPayUtilities, setBasicPayUtilities] = useState<any>()
  const [selectedRadio, setSelectedRadio] = useState('percentage')
  const [allowances, setAllowances] = useState<any>([])
  const [selectedValue, setSelectedValue] = useState<any>('10')
  const [houseRentPercentageChecked, setHouseRentPercentageChecked] = useState(false)
  const [medicalPercentageChecked, setMedicalPercentageChecked] = useState(false)
  const [utilitiesPercentageChecked, setUtilitiesPercentageChecked] = useState(false)
  const [conveyancePercentageChecked, setConveyancePercentageChecked] = useState(false);
  const [additionalPercentageChecked, setAdditionalPercentageChecked] = useState(false);
  const [houseRentFlatChecked, setHouseRentFlatageChecked] = useState(false)
  const [medicalFlatChecked, setMedicalFlatChecked] = useState(false)
  const [utilitiesFlatChecked, setUtilitiesFlatChecked] = useState(false)
  const [houseRentPercentage, setHouseRentPercentage] = useState<any>('40')
  const [medicalPercentage, setMedicalPercentage] = useState<any>('10')
  const [utilitiesPercentage, setUtilitiesPercentage] = useState<any>('10')
  const [conveyancePercentage, setConveyancePercentage] = useState<any>('10')
  const [additionalPercentage, setAdditionalPercentage] = useState<any>('10')
  const [houseRentPercentageValue, setHouseRentPercentageValue] = useState<any>(0)
  const [medicalPercentageValue, setMedicalPercentageValue] = useState<any>(0)
  const [utilitiesPercentageValue, setUtilitiesPercentageValue] = useState<any>(0)
  const [conveyancePercentageValue, setConveyancePercentageValue] = useState<any>(0)
  const [additionalPercentageValue, setAdditionalPercentageValue] = useState<any>(0)
  const [selectedValueMedical, setSelectedValueMedical] = useState<any>('10')
  const [selectedValueUtilities, setSelectedValueUtilities] = useState<any>('10')
  const [houseRentFlat, setHouseRent] = useState<any>(0)
  const [medicalAllowanceFlat, setMedicalAllowance] = useState<any>(0)
  const [utilitiesAllowanceFlat, setUtilitiesAllowance] = useState<any>(0)
  const [allowanceSums, setAllowanceSum] = useState(0)
  const [totalAllowanceValue, setTotalAllowanceValue] = useState(0)
  const [previousPay, setPreviousPay] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [fetchingBenefits, setFetchingBenefits] = useState(false);
  const [percentageAllowances, setPercentageAllowances] = useState<any>({
    houseRent: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    },
    medical: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    },
    utilities: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    },
    conveyance: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    },
    additional: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    }
  })
  const [flatAllowances, setFlatAllowances] = useState<any>({
    houseRent: {
      checked: false,
      amount: 0,
    },
    medical: {
      checked: false,
      amount: 0,
    },
    utilities: {
      checked: false,
      amount: 0,
    },
    conveyance: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    },
    additional: {
      checked: false,
      amount: 0,
      selectedValue: '0',
    }
  })
  const [configurationId, setConfigurationId] = useState<any>('');
  const [benefits, setBenefits] = useState<any>([])
  const [gradeFuelData, setGradeFuelData] = useState<any>();

  useEffect(() => {
    (async () => {

      // find the total liters of fuel and price per liter and calculate total price (according to job grade)
      if (gradeFuelData !== null && gradeFuelData !== undefined && gradeFuelData.length > 0) {
        if (jobDetails?.grade !== null && jobDetails?.grade !== undefined && jobDetails?.grade !== '') {
          // Find the object with the specified grade
          let gradeFuelObj = gradeFuelData.find(item => item.grade === jobDetails?.grade);
          let liters = gradeFuelObj?.liters | 0;
          let price = gradeFuelObj?.price | 0;
          let fuelPrice = liters * price;
          fuelPrice = parseFloat(fuelPrice.toFixed(2));
          // Update the formValues state
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            fuel: fuelPrice,
          }));

        }
      }


    })();
  }, [gradeFuelData, jobDetails]);

  const getConfigurations = async () => {
    setFetchingBenefits(true);
    await axios.get(GET_CONFIGURATIONS_URL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setFetchingBenefits(false);
      let data = res?.data?.Configuration;
      setConfigurationId(data?.configuration_id);
      let benefits = data?.benefits;
      if (benefits !== null && benefits !== undefined) {
        benefits = JSON.parse(benefits);
        const initialData = Object.keys(benefits).map((key) => ({
          grade: benefits[key]['name'],
          file: null,
          fileUrl: benefits[key]['link']
        }));
        setBenefits(initialData);
      }
      let fuelData = data?.fuel_allowance
      if (fuelData !== null && fuelData !== undefined) {
        fuelData = JSON.parse(fuelData);
        const initialData = Object.keys(fuelData).map((key) => ({
          liters: fuelData[key]['liters'],
          grade: fuelData[key]['grade'],
          price: fuelData[key]['price'],
          active: fuelData[key]['active']
        }))
        setGradeFuelData(initialData)
      }

    }).catch((error) => {
      setFetchingBenefits(false);
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    });
  };

  const handleInputClick = () => {
    setIsEditable(true)
  }

  const allowanceSum = allowances.reduce((acc, allowance) => acc + allowance.value, 0)

  const GETJOBDETAILS = async () => {
    await axios
      .get(GET_JOB_DETAILS + job_id, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setStoredData(JSON.parse(res?.data?.previous_job_pay))
        setJobDetails(res.data)
        let allPositions = res?.data?.batchPositions
        if (allPositions) {
          let list: any = [];
          allPositions.map((position) => {
            let hired = position?.hired || 0;
            let availablePositions = position?.no_of_positions;

            if (availablePositions - hired > 0) {
              list.push(position);
            }

          })
          setBatchPositions(list);
        } else {
          setSelectedLocation(res?.data?.location);
          // setBatchPositionId(res?.data?.position_id);
        }
        // setBatchPositions(res?.data?.batchPositions)

        getCandidateLatestPay();
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const HandleSave = async () => {
    if (batchPositions) {
      if (!batchPositionId) {
        setMessage('Please select location first and try again');
        setShowErrorMessage(true);
        return
      }
    }
    let houseRentAllowance = {
      name: 'House Rent Allowance',
      value: selectedRadio === 'percentage' ? percentageAllowances.houseRent.amount : flatAllowances.houseRent.amount,
      selectedValue: selectedRadio === 'percentage' ? percentageAllowances.houseRent.selectedValue : 0,
    }
    let medicalAllowance = {
      name: 'Medical Allowance',
      value: selectedRadio === 'percentage' ? percentageAllowances.medical.amount : flatAllowances.medical.amount,
      selectedValue: selectedRadio === 'percentage' ? percentageAllowances.medical.selectedValue : 0,
    }
    let utilitiesAllowance = {
      name: 'Utilities Allowance',
      value: selectedRadio === 'percentage' ? percentageAllowances.utilities.amount : flatAllowances.utilities.amount,
      selectedValue: selectedRadio === 'percentage' ? percentageAllowances.utilities.selectedValue : 0,
    }
    let conveyanceAllowance = {
      name: 'Conveyance Allowance',
      value: selectedRadio === 'percentage' ? percentageAllowances.conveyance.amount : flatAllowances.conveyance.amount,
      selectedValue: selectedRadio === 'percentage' ? percentageAllowances.conveyance.selectedValue : 0,
    }
    let additionalAllowance = {
      name: 'Additional Allowance',
      value: selectedRadio === 'percentage' ? percentageAllowances.additional.amount : flatAllowances.additional.amount,
      selectedValue: selectedRadio === 'percentage' ? percentageAllowances.additional.selectedValue : 0,
    }
    let previousJobPay = {
      name: 'Previous Job Pay',
      // value: basicpay,
      value: previousPay,
    }
    let grossPay = {
      name: 'Gross Pay',
      value: totalAmount,
    }
    let basicPayObject = {
      name: 'Basic Pay',
      value: basicpay,
    }
    let carAllowanceObject = {
      name: 'Car Allowance',
      value: formValues?.carAllowance,
    }
    let fuelAllowance = {
      name: 'fuel',
      value: formValues?.fuel,
    }
    let bonusAllowance = {
      name: 'bonus',
      value: formValues?.bonus,
    }
    let percentIncreaseObject = {
      name: 'Percent Increase',
      value: percentIncrease,
    }
    let totalSalaryObject = {
      name: 'Total Salary',
      value: totalAmount + totalAllowanceValue,
    }
    let basic_job_pay = [
      houseRentAllowance,
      medicalAllowance,
      utilitiesAllowance,
      conveyanceAllowance,
      additionalAllowance,
      previousJobPay,
      grossPay,
      basicPayObject,
      carAllowanceObject,
      fuelAllowance,
      bonusAllowance,
      percentIncreaseObject,
      totalSalaryObject,
    ]

    let data = {
      jobapp_id: jobapp_id,
      position_id: batchPositionId,
      posting_place: selectedLocation,
      basic_job_pay,
    }

    await axios
      .post(GET_JOB_PREVIOUS_PAY, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log({ res })
        onNextStep()
      })
      .catch((err) => {
        console.error('err', err)
      })
  }

  useEffect(() => {
    (async () => {
      await getConfigurations();
      GETJOBDETAILS();
    })()
  }, []);

  useEffect(() => {
    (() => {
      const basicPay = parseFloat(formValues?.basicPay) || 0
      const carAllowance = parseFloat(formValues?.carAllowance) || 0
      const fuel = parseFloat(formValues?.fuel) || 0
      const bonus = parseFloat(formValues?.bonus) || 0
      const total = basicPay + (carAllowanceChecked ? carAllowance : 0) + (fuelChecked ? fuel : 0)
        + (bonusChecked ? bonus : 0);
      setBasicpay(basicPay)
      setTotalAmount(total)
    })();

  }, [formValues, fuelChecked, carAllowanceChecked, bonusChecked])

  const getOtherAllowancesValue = () => {
    let otherAllowances = 0
    if (selectedRadio === 'percentage') {
      otherAllowances =
        (houseRentPercentageChecked ? houseRentPercentageValue : 0) +
        (medicalPercentageChecked ? medicalPercentageValue : 0) +
        (utilitiesPercentageChecked ? utilitiesPercentageValue : 0)
    } else if (selectedRadio === 'flat') {
      otherAllowances =
        (houseRentFlatChecked ? houseRentFlat : 0) +
        (medicalFlatChecked ? medicalAllowanceFlat : 0) +
        (utilitiesFlatChecked ? utilitiesAllowanceFlat : 0)
    }
    return otherAllowances
  }

  const handleAllowanceChange = (event) => {
    const { name, value } = event.target
    const enteredValue = parseInt(value.replace(/[^0-9]/g, '')) // Parse the entered value as an integer
    if (name === 'carAllowance') {
      let totalValue =
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        enteredValue +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        getOtherAllowancesValue()
      if (totalValue <= jobDetails?.max_total_remuneration_per_month) {
        setFormValues({ ...formValues, [name]: enteredValue })
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    }
    if (name === 'fuel') {
      let totalValue =
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        enteredValue +
        (carAllowanceChecked ? (formValues?.carAllowance ? formValues?.carAllowance : 0) : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        getOtherAllowancesValue()
      if (totalValue <= jobDetails?.max_total_remuneration_per_month) {
        setFormValues({ ...formValues, [name]: enteredValue })
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    }
    if (name === 'bonus') {
      let totalValue =
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (carAllowanceChecked ? (formValues?.carAllowance ? formValues?.carAllowance : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0) +
        enteredValue +
        getOtherAllowancesValue()
      if (totalValue <= jobDetails?.max_total_remuneration_per_month) {
        setFormValues({ ...formValues, [name]: enteredValue })
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormValues({ ...formValues, [name]: value })

    // Calculate the percentage increase
    // if (name === 'basicPay') {
    //     let prevPay = parseInt(storedData?.BasicPay) || 0;

    //     let basicPay = parseInt(value, 10) || 0;

    //     let percent: any = ((basicPay - prevPay) / basicPay) * 100;
    //     setPercentIncrease(percent.toFixed(1));
    // }
    // Update the total amount
  }
  console.log({ formValues })

  const handleBasicPayBlur = (basicPayValue) => {
    const minSalary = parseInt(jobDetails?.salary_range_from)
    const maxSalary = parseInt(jobDetails?.salary_range_till)
    if (minSalary && basicPayValue < minSalary) {
      let data = { ...formValues }
      data.basicPay = ''
      setFormValues(data)
      window.alert(`Basic salary can not be less than minimum salary: ${minSalary}`)

      return
    } else if (basicPayValue > maxSalary) {
      let data = { ...formValues }
      data.basicPay = ''
      setFormValues(data)
      window.alert(`Basic salary can not be greater than maximum salary: ${maxSalary}`)
      return
    }
  }

  const handleInputChanges = (event) => {
    const { name, value } = event.target
    console.log('value: ', value)
    // if (name === 'basicPay') {
    //     const maxSalary = parseInt(jobDetails?.salary_range_till);
    //     const enteredValue = parseInt(value.replace(/[^0-9]/g, ''));
    //     if (enteredValue > maxSalary) {
    //         let data = { ...formValues };
    //         data.basicPay = '';
    //         setFormValues(data);
    //         window.alert(`Basic salary can not be greater than maximum salary: ${maxSalary}`);
    //         return;
    //     }
    // }

    const enteredValue = parseInt(value.replace(/[^0-9]/g, '')) // Parse the entered value as an integer
    // if (!isNaN(enteredValue) && enteredValue <= jobDetails?.salary_range_till) {
    if (!isNaN(enteredValue)) {
      // Update the state only if the entered value is a valid number and less than or equal to maxBasicSalary

      setFormValues({ ...formValues, [name]: enteredValue })

      // check if the allowance type percentage then calculate the percentage values and calculate total allowances
      if (selectedRadio === 'percentage') {
        let houseRentValue = percentageValue(enteredValue, houseRentPercentage)
        setHouseRentPercentageValue(houseRentValue)
        let medicalAllowanceValue = percentageValue(enteredValue, medicalPercentage)
        setMedicalPercentageValue(medicalAllowanceValue)
        let utilitiesAllowanceValue = percentageValue(enteredValue, utilitiesPercentage)
        setUtilitiesPercentageValue(utilitiesAllowanceValue)
        let conveyanceAllowanceValue = percentageValue(enteredValue, conveyancePercentage)
        setConveyancePercentageValue(conveyanceAllowanceValue)
        let additionalAllowanceValue = percentageValue(enteredValue, additionalPercentage)
        setAdditionalPercentageValue(additionalAllowanceValue)

        let allowances = { ...percentageAllowances }
        if (houseRentPercentageChecked) {
          //set house rent percentage allowance value
          allowances.houseRent.amount = houseRentValue
          setPercentageAllowances(allowances)
        }
        if (medicalPercentageChecked) {
          //set house rent percentage allowance value
          allowances.medical.amount = medicalAllowanceValue
          setPercentageAllowances(allowances)
        }
        if (utilitiesPercentageChecked) {
          //set house rent percentage allowance value
          allowances.utilities.amount = utilitiesAllowanceValue
          setPercentageAllowances(allowances)
        }
        if (conveyancePercentageChecked) {
          //set house rent percentage allowance value
          allowances.conveyance.amount = conveyanceAllowanceValue
          setPercentageAllowances(allowances)
        }
        if (additionalPercentageChecked) {
          //set house rent percentage allowance value
          allowances.additional.amount = additionalAllowanceValue
          setPercentageAllowances(allowances)
        }
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      }
    } else {
    }

    if (name === 'basicPay') {
      // let prevPay = storedData ? parseInt(storedData?.BasicPay || '0') : previousPay
      let prevPay = previousPay
      const cleanedValue = value.replace(/,/g, '');
      let basicPay = parseInt(cleanedValue, 10) || 0
      let percent: any = ((basicPay - prevPay) / basicPay) * 100
      // Check if the percentage is less than 0 and update it to 0 if true
      if (percent < 0) {
        percent = 0
      }

      setPercentIncrease(percent.toFixed(1))
    }
  }

  const handleRadioChanges = (value) => {
    setSelectedRadio(value)
    // reset the allowances to 0 when allowance calculation type changes from percentage to flat or flat to percentage
    resetAllowances()
  }

  const resetAllowances = () => {
    setTotalAllowanceValue(0)
    let allowances = { ...percentageAllowances }
    allowances.houseRent.checked = false
    allowances.houseRent.amount = 0
    allowances.houseRent.selectedValue = '0'
    allowances.medical.checked = false
    allowances.medical.amount = 0
    allowances.medical.selectedValue = '0'
    allowances.utilities.checked = false
    allowances.utilities.amount = 0
    allowances.utilities.selectedValue = '0'
    setPercentageAllowances(allowances)

    let flatAllowancesData = { ...flatAllowances }
    flatAllowancesData.houseRent.checked = false
    flatAllowancesData.houseRent.amount = 0
    flatAllowancesData.medical.checked = false
    flatAllowancesData.medical.amount = 0
    flatAllowancesData.utilities.checked = false
    flatAllowancesData.utilities.amount = 0
    setFlatAllowances(flatAllowancesData)

    setHouseRentPercentageChecked(false)
    setMedicalPercentageChecked(false)
    setUtilitiesPercentageChecked(false)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    let allowanceValue = 0

    if (selectedRadio === 'percentage') {
      let allowances = { ...percentageAllowances }

      if (name === 'House Rent Allowance') {
        setHouseRentPercentageChecked(checked)

        allowances.houseRent.checked = checked
        if (checked) {
          allowances.houseRent.amount = houseRentPercentageValue
          allowances.houseRent.selectedValue = houseRentPercentage
        } else {
          allowances.houseRent.amount = 0
          allowances.houseRent.selectedValue = '0'
        }
      } else if (name === 'Medical Allowance') {
        setMedicalPercentageChecked(checked)

        allowances.medical.checked = checked
        if (checked) {
          allowances.medical.amount = medicalPercentageValue
          allowances.medical.selectedValue = medicalPercentage
        } else {
          allowances.medical.amount = 0
          allowances.medical.selectedValue = '0'
        }
      } else if (name === 'Utilities Allowance') {
        setUtilitiesPercentageChecked(checked)
        allowances.utilities.checked = checked
        if (checked) {
          allowances.utilities.amount = utilitiesPercentageValue
          allowances.utilities.selectedValue = utilitiesPercentage
        } else {
          allowances.utilities.amount = 0
          allowances.utilities.selectedValue = '0'
        }
      } else if (name === 'Conveyance Allowance') {
        setConveyancePercentageChecked(checked)
        allowances.conveyance.checked = checked
        if (checked) {
          allowances.conveyance.amount = conveyancePercentageValue
          allowances.conveyance.selectedValue = conveyancePercentage
        } else {
          allowances.conveyance.amount = 0
          allowances.conveyance.selectedValue = '0'
        }
      } else if (name === 'Additional Allowance') {
        setAdditionalPercentageChecked(checked)
        allowances.additional.checked = checked
        if (checked) {
          allowances.additional.amount = additionalPercentageValue
          allowances.additional.selectedValue = additionalPercentage
        } else {
          allowances.additional.amount = 0
          allowances.additional.selectedValue = '0'
        }
      }
      console.log('percentageAllowances: ', allowances)
      // checking the total amount is greater than max total salary or not
      let totalAmount = 0
      totalAmount =
        (allowances.houseRent.checked ? allowances.houseRent.amount : 0) +
        (allowances.medical.checked ? allowances.medical.amount : 0) +
        (allowances.utilities.checked ? allowances.utilities.amount : 0) +
        (allowances.conveyance.checked ? allowances.conveyance.amount : 0) +
        (allowances.additional.checked ? allowances.additional.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0) // add check if fuel allowed than add fuel value else it should be 0
      console.log('salaryCalculation: totalCalculated: ', totalAmount)
      console.log('salaryCalculation: ActualTotal: ', jobDetails?.max_total_remuneration_per_month)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))

        // if (name === "House Rent Allowance") {
        //     setHouseRentPercentageChecked(checked);
        // } else if (name === "Medical Allowance") {
        //     setMedicalPercentageChecked(checked);
        // }else if (name === "Utilities Allowance") {
        //     setUtilitiesPercentageChecked(checked);
        // }
      } else {
        if (name === 'House Rent Allowance') {
          setHouseRentPercentageChecked(false)
          allowances.houseRent.amount = 0
          allowances.houseRent.selectedValue = '0'
        } else if (name === 'Medical Allowance') {
          setMedicalPercentageChecked(false)
          allowances.medical.amount = 0
          allowances.medical.selectedValue = '0'
        } else if (name === 'Utilities Allowance') {
          setUtilitiesPercentageChecked(false)
          allowances.utilities.amount = 0
          allowances.utilities.selectedValue = '0'
        } else if (name === 'Conveyance Allowance') {
          setConveyancePercentageChecked(false)
          allowances.conveyance.amount = 0
          allowances.conveyance.selectedValue = '0'
        } else if (name === 'Additional Allowance') {
          setAdditionalPercentageChecked(false)
          allowances.additional.amount = 0
          allowances.additional.selectedValue = '0'
        }
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else if (selectedRadio === 'flat') {
      let allowances = { ...flatAllowances }
      if (name === 'House Rent Allowance') {
        setHouseRentFlatageChecked(checked)
        allowances.houseRent.checked = checked
        if (checked) {
          allowances.houseRent.amount = houseRentFlat
        } else {
          allowances.houseRent.amount = 0
        }
      } else if (name === 'Medical Allowance') {
        setMedicalFlatChecked(checked)

        allowances.medical.checked = checked
        if (checked) {
          allowances.medical.amount = medicalAllowanceFlat
        } else {
          allowances.medical.amount = 0
        }
      } else if (name === 'Utilities Allowance') {
        setUtilitiesFlatChecked(checked)
        allowances.utilities.checked = checked
        if (checked) {
          allowances.utilities.amount = utilitiesAllowanceFlat
        } else {
          allowances.utilities.amount = 0
        }
      }
      console.log('flatAllowances: ', allowances)
      // checking the total amount is greater than max total salary or not
      let totalAmount = 0
      totalAmount =
        (allowances.houseRent.checked ? allowances.houseRent.amount : 0) +
        (allowances.medical.checked ? allowances.medical.amount : 0) +
        (allowances.utilities.checked ? allowances.utilities.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setFlatAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    }
  }

  // Function to calculate total amount
  const calculateTotalAllowanceAmount = (allowances) => {
    let total = 0

    for (const key in allowances) {
      if (allowances[key].checked) {
        total += allowances[key].amount
      }
    }

    return total
  }

  const calculatePercentageValue = (percent) => {
    let pay = formValues?.basicPay || 0
    const percentage = parseInt(percent) / 100
    const allowanceValue = pay * percentage
    return allowanceValue
  }

  const percentageValue = (pay, percent) => {
    const percentage = parseInt(percent) / 100
    const allowanceValue = pay * percentage
    return allowanceValue
  }

  const handleHouseRentPercentageChange = (event) => {
    let percent = event.target.value
    // calculate the percentage value of house rent
    let houseRentValue = calculatePercentageValue(percent)
    if (houseRentPercentageChecked) {
      // calculate total amount and check is the total amount is less than max salary or not
      let totalAmount = 0
      totalAmount =
        houseRentValue +
        (percentageAllowances.medical.checked ? percentageAllowances.medical.amount : 0) +
        (percentageAllowances.utilities.checked ? percentageAllowances.utilities.amount : 0) +
        (percentageAllowances.conveyance.checked ? percentageAllowances.conveyance.amount : 0) +
        (percentageAllowances.additional.checked ? percentageAllowances.additional.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      console.log('salaryCalculation: totalCalculated: ', totalAmount)
      console.log('salaryCalculation: ActualTotal: ', jobDetails?.max_total_remuneration_per_month)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setHouseRentPercentage(percent)
        setHouseRentPercentageValue(houseRentValue)

        //set house rent percentage allowance value
        let allowances = { ...percentageAllowances }
        allowances.houseRent.amount = houseRentValue
        allowances.houseRent.selectedValue = percent
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else {
      setHouseRentPercentage(percent)
      setHouseRentPercentageValue(houseRentValue)
    }
  }

  const handleMedicalPercentageChange = (event) => {
    let percent = event.target.value

    // calculate the percentage value of medical allowance
    let medicalAllowanceValue = calculatePercentageValue(percent)
    if (medicalPercentageChecked) {
      // calculate total amount and check is the total amount is less than max salary or not
      let totalAmount = 0
      totalAmount =
        medicalAllowanceValue +
        (percentageAllowances.houseRent.checked ? percentageAllowances.houseRent.amount : 0) +
        (percentageAllowances.utilities.checked ? percentageAllowances.utilities.amount : 0) +
        (percentageAllowances.conveyance.checked ? percentageAllowances.conveyance.amount : 0) +
        (percentageAllowances.additional.checked ? percentageAllowances.additional.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setMedicalPercentage(percent)
        setMedicalPercentageValue(medicalAllowanceValue)
        //set medical percentage allowance value
        let allowances = { ...percentageAllowances }
        allowances.medical.amount = medicalAllowanceValue
        allowances.medical.selectedValue = percent
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else {
      setMedicalPercentage(percent)
      setMedicalPercentageValue(medicalAllowanceValue)
    }
  }

  const handleUtilitiesPercentageChange = (event) => {
    let percent = event.target.value

    // calculate the percentage value of medical allowance
    let utilitiesAllowanceValue = calculatePercentageValue(percent)

    // check is the check box for utilities percentage allowance enabled or not
    // if enabled calculate the total allowance Value

    if (utilitiesPercentageChecked) {
      // calculate total amount and check is the total amount is less than max salary or not
      let totalAmount = 0
      totalAmount =
        utilitiesAllowanceValue +
        (percentageAllowances.houseRent.checked ? percentageAllowances.houseRent.amount : 0) +
        (percentageAllowances.medical.checked ? percentageAllowances.medical.amount : 0) +
        (percentageAllowances.conveyance.checked ? percentageAllowances.conveyance.amount : 0) +
        (percentageAllowances.additional.checked ? percentageAllowances.additional.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setUtilitiesPercentage(percent)
        setUtilitiesPercentageValue(utilitiesAllowanceValue)

        //set house rent percentage allowance value
        let allowances = { ...percentageAllowances }
        allowances.utilities.amount = utilitiesAllowanceValue
        allowances.utilities.selectedValue = percent
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else {
      setUtilitiesPercentage(percent)
      setUtilitiesPercentageValue(utilitiesAllowanceValue)
    }
  }

  const handleConveyancePercentageChange = (event) => {
    let percent = event.target.value

    // calculate the percentage value of conveyance allowance
    let conveyanceAllowanceValue = calculatePercentageValue(percent)

    // check is the check box for conveyance percentage allowance enabled or not
    // if enabled calculate the total allowance Value

    if (conveyancePercentageChecked) {
      // calculate total amount and check is the total amount is less than max salary or not
      let totalAmount = 0
      totalAmount =
        conveyanceAllowanceValue +
        (percentageAllowances.houseRent.checked ? percentageAllowances.houseRent.amount : 0) +
        (percentageAllowances.medical.checked ? percentageAllowances.medical.amount : 0) +
        (percentageAllowances.utilities.checked ? percentageAllowances.utilities.amount : 0) +
        (percentageAllowances.additional.checked ? percentageAllowances.additional.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setConveyancePercentage(percent)
        setConveyancePercentageValue(conveyanceAllowanceValue)

        //set house rent percentage allowance value
        let allowances = { ...percentageAllowances }
        allowances.conveyance.amount = conveyanceAllowanceValue
        allowances.conveyance.selectedValue = percent
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else {
      setConveyancePercentage(percent)
      setConveyancePercentageValue(conveyanceAllowanceValue)
    }
  }

  const handleAdditionalPercentageChange = (event) => {
    let percent = event.target.value

    // calculate the percentage value of conveyance allowance
    let additionalAllowanceValue = calculatePercentageValue(percent)

    // check is the check box for conveyance percentage allowance enabled or not
    // if enabled calculate the total allowance Value

    if (additionalPercentageChecked) {
      // calculate total amount and check is the total amount is less than max salary or not
      let totalAmount = 0
      totalAmount =
        additionalAllowanceValue +
        (percentageAllowances.houseRent.checked ? percentageAllowances.houseRent.amount : 0) +
        (percentageAllowances.medical.checked ? percentageAllowances.medical.amount : 0) +
        (percentageAllowances.utilities.checked ? percentageAllowances.utilities.amount : 0) +
        (percentageAllowances.conveyance.checked ? percentageAllowances.conveyance.amount : 0) +
        (formValues?.basicPay ? formValues?.basicPay : 0) +
        (formValues?.carAllowance ? formValues?.carAllowance : 0) +
        (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
        (fuelChecked ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
      if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
        setAdditionalPercentage(percent)
        setAdditionalPercentageValue(additionalAllowanceValue)

        //set additional benefits percentage allowance value
        let allowances = { ...percentageAllowances }
        allowances.additional.amount = additionalAllowanceValue
        allowances.additional.selectedValue = percent
        setPercentageAllowances(allowances)
        // calculate the total value of allowances
        setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
      } else {
        window.alert('Total Salary could not be greater than Max Gross Salary')
      }
    } else {
      setAdditionalPercentage(percent)
      setAdditionalPercentageValue(additionalAllowanceValue)
    }
  }

  const handleHouseRentChange = (e) => {
    const inputValue = parseFloat(e.target.value)
    const newValue = isNaN(inputValue) ? 0 : inputValue
    setHouseRent(newValue)
    let allowances = { ...flatAllowances }
    if (allowances.houseRent.checked) {
      allowances.houseRent.amount = newValue
      setFlatAllowances(allowances)
      setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
    }
  }

  const handleMedicalAllowanceChange = (e) => {
    const inputValue = parseFloat(e.target.value)
    const newValue = isNaN(inputValue) ? 0 : inputValue
    setMedicalAllowance(newValue)
    let allowances = { ...flatAllowances }
    if (allowances.medical.checked) {
      allowances.medical.amount = newValue
      setFlatAllowances(allowances)
      setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
    }
  }

  const handleUtilitiesAllowanceChange = (e) => {
    const inputValue = parseFloat(e.target.value)
    const newValue = isNaN(inputValue) ? 0 : inputValue
    setUtilitiesAllowance(newValue)
    let allowances = { ...flatAllowances }
    if (allowances.utilities.checked) {
      allowances.utilities.amount = newValue
      setFlatAllowances(allowances)
      setTotalAllowanceValue(calculateTotalAllowanceAmount(allowances))
    }
  }

  const onlyNumbers = (event) => {
    // Allow only numeric characters, backspace, and delete keys
    // if (
    //     !/\d/.test(event.key) && // Allow numeric characters
    //     event.key !== 'Backspace' && // Allow backspace key
    //     event.key !== 'Delete' && // Allow delete key
    //     event.key !== 'ArrowLeft' && // Allow arrow left key
    //     event.key !== 'ArrowRight' // Allow arrow right key
    // ) {
    //     event.preventDefault();
    // }

    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End']

    // Allow only numeric characters and certain special keys
    if (
      !/\d/.test(event.key) && // Allow numeric characters
      !allowedKeys.includes(event.key)
    ) {
      event.preventDefault()
    }
  }

  const handleLocationChange = (event) => {
    // setSelectedLocation(event.target.value);

    const selectedValue = event.target.value
    const selectedPosition = batchPositions.find((position) => position.position_id === selectedValue)
    if (selectedPosition) {
      setSelectedLocation(selectedPosition.location)
      setBatchPositionId(selectedValue)
      // Now you have the position_id, you can use it as needed
    } else {
      // setSelectedLocation('')
      // setBatchPositionId('')
    }
  }

  const handleDownloadBenefitsPdf = (e) => {
    e.preventDefault();
    if (benefits !== null && benefits !== undefined && benefits.length > 0) {
      if (jobDetails?.grade !== null && jobDetails?.grade !== undefined && jobDetails?.grade !== '') {
        // Find the object with the specified grade
        let benefit = benefits.find(item => item.grade === jobDetails?.grade);
        // Extract the fileUrl value if the grade is found
        let fileUrl = benefit ? benefit.fileUrl : null;
        if (fileUrl) {
          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = fileUrl;
          link.target = '_blank'; // Open the PDF in a new tab
          link.setAttribute('download', ''); // Set the download attribute to force download
          document.body.appendChild(link);
          link.click(); // Click the anchor element to initiate download
          document.body.removeChild(link); // Remove the anchor element from the document
        } else {
          setMessage('Benefits sheet not available contact admin to add Benefits sheet.');
          setShowErrorMessage(true);
        }
      } else {
        setMessage('Grade value not found, Please try again.');
        setShowErrorMessage(true);
      }

    } else {
      setMessage('List of benefits sheets not found, Please Try again.');
      setShowErrorMessage(true);
    }

  }

  const getCandidateLatestPay = () => {
    let value = 0;
    if (candidateData) {
      let workExperience = candidateData.work_experience;
      if (workExperience) {
        workExperience = JSON.parse(workExperience);
        let experience = workExperience[0]['experience'];
        if (experience) {
          console.log('candidateData: experience: ', experience);

          let highestNumber = 0;
          let highestGrossSalary = 0;

          for (let key in experience) {
            const companyNumber = parseInt(key.replace('company', ''), 10);
            if (companyNumber > highestNumber) {
              highestNumber = companyNumber;
              highestGrossSalary = experience[key].grossSalary;
            }
          }
          value = highestGrossSalary;
        }
      }


    }
    // return value;
    setPreviousPay(value);
  }

  return (
    <div className='modal-body py-lg-8 px-lg-8'>
      <div className='request-approval-hiring-text'>
        <label>Hiring Details</label>
      </div>

      <div className='request-approval-posting-details'>
        <div>
          <p className='request-approval-posting-txt'>Initial Place of Posting</p>
        </div>
        <div>
          <p className='request-approval-posting-txt'>Candidate Preferred Location</p>
        </div>
        <div>
          <p className='request-approval-posting-txt'>Assignment</p>
        </div>
        <div>
          <p className='request-approval-posting-txt'>Grade</p>
        </div>
      </div>

      <div className='request-approval-posting-details-resp'>
        {batchPositions ? (
          <div className='d-flex'>
            <Select
              value={batchPositionId}
              onChange={handleLocationChange}
              label='Location'
              displayEmpty
              style={{ border: '1px solid #ced4da', borderRadius: '4px', height: '40px' }}
            >
              <MenuItem disabled value=''>
                Select a Location
              </MenuItem>
              {batchPositions.map((position) => {
                let hired = position?.hired || 0;
                let availablePositions = position?.no_of_positions;
                return (
                  <MenuItem key={position.position_id} value={position.position_id}>
                    <div className='d-flex align-items-center w-100' >
                      <label className='me-8 request-approval-posting-resp'>{position.location}</label>
                      <div className='d-flex ms-auto'>
                        <img className='me-2' src={imagePath.ic_chair} style={{ objectFit: 'cover', height: '24px' }} />
                        <label className='me-2 form-label'>{(availablePositions - hired)}</label>
                      </div>
                    </div>
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        ) : (
          <div>
            <p className='request-approval-posting-resp'>{jobDetails?.location}</p>
          </div>
        )}
        <div>
          <p className='request-approval-posting-resp'>{cityPreference}</p>
        </div>
        <div>
          <p className='request-approval-posting-resp'>{jobDetails?.title}</p>
        </div>
        <div>
          <p className='request-approval-posting-resp'>{jobDetails?.grade}</p>
        </div>
      </div>

      <div className='request-approval-salary-cont'>
        <label>Salary and Benefits</label>
        <div className='d-flex'>
          <label className='request-approval-salary-text me-8'>
            Salary Range Rs. {jobDetails?.salary_range_from ? parseInt(jobDetails?.salary_range_from).toLocaleString() : 0} - Rs.{' '}
            {jobDetails?.salary_range_till ? parseInt(jobDetails?.salary_range_till).toLocaleString() : 0}
          </label>

          <label className='request-approval-salary-text ms-auto'>
            Max Gross Salary Rs.{' '}
            {jobDetails?.max_total_remuneration_per_month ? parseInt(jobDetails?.max_total_remuneration_per_month).toLocaleString() : 0}
          </label>
        </div>
      </div>

      <form>
        <div className='request-approval-salary-details'>
          <div style={{ width: '8rem' }}>
            <p className='request-approval-posting-txt'>Previous Pay</p>
          </div>
          <div style={{ width: '6rem' }}>
            <p className='request-approval-posting-txt'>Basic Pay</p>
          </div>
          <div style={{ width: '12rem' }} className='request-approval-external-posting-txt'>
            {/* <p className='request-approval-external-posting-txt'> */}
            <input
              className='form-check-input'
              type='checkbox'
              name='allowanceTypeCar'
              checked={carAllowanceChecked}
              onClick={() => setCarAllowanceChecked(!carAllowanceChecked)}
            />
            <label
              htmlFor='allowanceTypeCar'
              style={{
                marginLeft: '10px',
              }}
            >
              Car Allowance
            </label>
          </div>
          <div style={{ width: '6rem' }}>
            <p className='request-approval-external-posting-txt'>
              <input className='form-check-input' type='checkbox' name='allowanceTypeFuel' checked={fuelChecked} onClick={() => {
                let currentCheckedValue = !fuelChecked
                let totalAmount = 0
                totalAmount =
                (percentageAllowances.additional.checked ? percentageAllowances.additional.amount : 0) +
                  (percentageAllowances.houseRent.checked ? percentageAllowances.houseRent.amount : 0) +
                  (percentageAllowances.medical.checked ? percentageAllowances.medical.amount : 0) +
                  (percentageAllowances.utilities.checked ? percentageAllowances.utilities.amount : 0) +
                  (percentageAllowances.conveyance.checked ? percentageAllowances.conveyance.amount : 0) +
                  (formValues?.basicPay ? formValues?.basicPay : 0) +
                  (formValues?.carAllowance ? formValues?.carAllowance : 0) +
                  (bonusChecked ? (formValues?.bonus ? formValues?.bonus : 0) : 0) +
                  (currentCheckedValue ? (formValues?.fuel ? formValues?.fuel : 0) : 0)
                if (totalAmount <= jobDetails?.max_total_remuneration_per_month) {
                  setFuelChecked(currentCheckedValue)
                } else {
                  window.alert('Total Salary could not be greater than Max Gross Salary')
                  setFuelChecked(fuelChecked)
                }

              }} />
              <label
                htmlFor='allowanceTypeFuel'
                style={{
                  marginLeft: '10px',
                }}
              >
                Fuel
              </label>
            </p>
          </div>
          <div style={{ width: '6rem' }}>
            <p className='request-approval-external-posting-txt'>
              <input className='form-check-input' type='checkbox' name='allowanceTypeBonus' checked={bonusChecked} onClick={() => setBonusChecked(!bonusChecked)} />
              <label
                htmlFor='allowanceTypeBonus'
                style={{
                  marginLeft: '10px',
                }}
              >
                Bonus
              </label>
            </p>
          </div>
          <div style={{ width: '8rem' }}>
            <p className='request-approval-posting-txt'>Monthly Package including Guaranteed Bonuses</p>
          </div>
          <div style={{ width: '8rem' }}>
            <p className='request-approval-posting-txt'>% Increase</p>
          </div>
        </div>
        <div className='request-approval-salary-details-body'>
          <div style={{ width: '8rem' }}>
            {/* {storedData?.BasicPay ? (
              <p className='request-approval-posting-resp'>Rs. {parseInt(storedData?.BasicPay).toLocaleString()}</p>
            ) : ( */}
            <p
              className='request-approval-posting-resp'
              style={{
                borderRadius: '8px',
                color: '#F36523',
                padding: '10px',
                fontSize: '14px',
                fontWeight: '700',
                marginTop: '-8px',
              }}
            >
              Rs. {previousPay ? previousPay.toLocaleString() : 'N/A'}
            </p>
            {/* )} */}
          </div>
          <div style={{ width: '6rem' }}>
            <p className='request-approval-posting-resp'>
              <input
                type='text'
                className='form-control py-1 request-approval-posting-input'
                name='basicPay'
                value={formValues?.basicPay.toLocaleString()}
                min={0}
                step={1}
                placeholder='0'
                max={jobDetails?.salary_range_till ? jobDetails?.salary_range_till : 0}
                onChange={handleInputChanges}
                onKeyDown={onlyNumbers}
                onBlur={() => handleBasicPayBlur(formValues?.basicPay)}
              />
            </p>
          </div>

          <div style={{ width: '12rem' }}>
            {carAllowanceChecked && (
              <p
              // className='request-approval-external-hire-txt'
              >
                <input
                  type='text'
                  className='form-control py-1 request-approval-posting-input'
                  name='carAllowance'
                  value={formValues?.carAllowance ? parseInt(formValues?.carAllowance).toLocaleString() : 0}
                  onChange={handleAllowanceChange}
                  onKeyDown={onlyNumbers}
                />
              </p>
            )}
          </div>
          <div style={{ width: '6rem' }}>
            {fuelChecked && (
              <p
              // className='request-approval-external-hire-txt'
              >
                {/* <input
                  type='text'
                  className='form-control py-1 request-approval-posting-input'
                  name='fuel'
                  value={formValues?.fuel ? parseInt(formValues?.fuel).toLocaleString() : 0}
                  onChange={handleAllowanceChange}
                  onKeyDown={onlyNumbers}
                /> */}
                <label className='fs-6 fw-bold'>{`Rs. ${formValues?.fuel.toLocaleString()}`}</label>
              </p>
            )}
          </div>
          <div style={{ width: '6rem' }}>
            {bonusChecked && (
              <p
              // className='request-approval-external-hire-txt'
              >
                <input
                  type='text'
                  className='form-control py-1 request-approval-posting-input'
                  name='bonus'
                  value={formValues?.bonus ? parseInt(formValues?.bonus).toLocaleString() : 0}
                  onChange={handleAllowanceChange}
                  onKeyDown={onlyNumbers}
                />
              </p>
            )}
          </div>
          <div style={{ width: '8rem' }}>
            <p className='request-approval-posting-resp'>
              <input
                type='text'
                className='form-control py-1 request-approval-posting-input'
                name='monthlyPackage'
                value={totalAmount ? totalAmount.toLocaleString() : 0}
                onChange={handleInputChange}
                readOnly={!isEditable}
                onClick={handleAllowanceChange}
                onKeyDown={onlyNumbers}
              />
            </p>
          </div>
          <div style={{ width: '8rem' }}>
            <div>
              <p className='request-approval-posting-resp'>
                <label
                  className='form-label'
                  style={{
                    borderRadius: '6px',
                    backgroundColor: '#FFEBCE',
                    color: '#F36523',
                    padding: '5px 10px',
                    fontSize: '14px',
                    fontWeight: '700',
                    // marginTop: '-8px',
                  }}
                >
                  {percentIncrease ? percentIncrease : 0}%
                </label>
              </p>
            </div>
          </div>
        </div>

        {/* add Allowances view */}
        <div className='offer-letter-salary-cont'>
          <label>Allowance</label>
          <div>
            <div className='offer-letter-radio-btn-cont'>
              <div className='schedule-interview-address-buttons'>
                <input
                  type='radio'
                  value='percentage'
                  name='interview'
                  className='schedule-interview-checkbox'
                  // checked={selectedRadio === "percentage"}
                  checked={true}
                  onChange={(e) => handleRadioChanges(e.target.value)} // pass the selected radio button value to handleRadioChange
                />
                <label>Percentage Value</label>
              </div>

              {/* <div className='schedule-interview-address-buttons'>
                                <input
                                    type='radio'
                                    value="flat"
                                    name='interview'
                                    className='schedule-interview-checkbox'
                                    checked={selectedRadio === "flat"}
                                    onChange={(e) => handleRadioChanges(e.target.value)} // pass the selected radio button value to handleRadioChange
                                />
                                <label>Flat Value</label>
                            </div> */}
            </div>
          </div>
        </div>

        <div>
          {selectedRadio === 'percentage' && (
            <>
              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='House Rent Allowance' checked={houseRentPercentageChecked} onChange={handleCheckboxChange} />

                <label>House Rent Allowance</label>

                <div className='offer-letter-input-div' style={{ marginLeft: '3.2rem' }}>
                  <form>
                    <select
                      value={houseRentPercentage}
                      onChange={handleHouseRentPercentageChange}
                      style={{ height: '5vh', width: '5rem', border: '1px solid #c7c7c7' }}
                      defaultValue={40}
                    >
                      <option value='10' selected>
                        10%
                      </option>
                      <option value='20'>20%</option>
                      <option value='40'>40%</option>
                    </select>
                  </form>
                  <input
                    className='form-control offer-letter-flat-value'
                    value={`Rs. ${houseRentPercentageValue?.toLocaleString()}`}
                    onChange={handleInputChanges}
                  />
                </div>
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Medical Allowance' checked={medicalPercentageChecked} onChange={handleCheckboxChange} />

                <label>Medical Allowance</label>

                <div className='offer-letter-input-div'>
                  <form>
                    <select
                      value={medicalPercentage}
                      onChange={handleMedicalPercentageChange}
                      style={{ height: '5vh', width: '5rem', border: '1px solid #c7c7c7' }}
                    >
                      <option value='10' selected>
                        10%
                      </option>
                      <option value='20'>20%</option>
                      <option value='40'>40%</option>
                    </select>
                  </form>
                  <input
                    className='form-control offer-letter-flat-value'
                    value={`Rs. ${medicalPercentageValue?.toLocaleString()}`}
                  // onChange={handleInputChangesMedical}
                  />
                </div>
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Utilities Allowance' checked={utilitiesPercentageChecked} onChange={handleCheckboxChange} />

                <label>Utilities Allowance</label>

                <div className='offer-letter-input-div'>
                  <form>
                    <select
                      value={utilitiesPercentage}
                      onChange={handleUtilitiesPercentageChange}
                      style={{ height: '5vh', width: '5rem', border: '1px solid #c7c7c7' }}
                    >
                      <option value='10' selected>
                        10%
                      </option>
                      <option value='20'>20%</option>
                      <option value='40'>40%</option>
                    </select>
                  </form>
                  <input
                    className='form-control offer-letter-flat-value'
                    value={`Rs. ${utilitiesPercentageValue?.toLocaleString()}`}
                  // onChange={handleInputChangesUtilities}
                  />
                </div>
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Conveyance Allowance' checked={conveyancePercentageChecked} onChange={handleCheckboxChange} />

                <label>Conveyance Allowance</label>

                <div className='offer-letter-input-div' style={{ marginLeft: '3rem' }}>
                  <form>
                    <select
                      value={conveyancePercentage}
                      onChange={handleConveyancePercentageChange}
                      style={{ height: '5vh', width: '5rem', border: '1px solid #c7c7c7' }}
                    >
                      <option value='10' selected>
                        10%
                      </option>
                      <option value='20'>20%</option>
                      <option value='40'>40%</option>
                    </select>
                  </form>
                  <input
                    className='form-control offer-letter-flat-value'
                    value={`Rs. ${conveyancePercentageValue?.toLocaleString()}`}
                  // onChange={handleInputChangesUtilities}
                  />
                </div>
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Additional Allowance' checked={additionalPercentageChecked} onChange={handleCheckboxChange} />

                <label>Additional Benefits</label>

                <div className='offer-letter-input-div' style={{ marginLeft: '4.8rem' }}>
                  <form>
                    <select
                      value={additionalPercentage}
                      onChange={handleAdditionalPercentageChange}
                      style={{ height: '5vh', width: '5rem', border: '1px solid #c7c7c7' }}
                    >
                      <option value='10' selected>
                        10%
                      </option>
                      <option value='20'>20%</option>
                      <option value='40'>40%</option>
                    </select>
                  </form>
                  <input
                    className='form-control offer-letter-flat-value'
                    value={`Rs. ${additionalPercentageValue?.toLocaleString()}`}
                  // onChange={handleInputChangesUtilities}
                  />
                </div>
              </div>

            </>
          )}

          {selectedRadio === 'flat' && (
            <>
              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='House Rent Allowance' onChange={handleCheckboxChange} />

                <label>House Rent Allowance</label>

                <div className='offer-letter-input-div'>
                  <input
                    className='form-control offer-letter-flat-value'
                    style={{ marginLeft: '-1.59rem' }}
                    value={houseRentFlat}
                    onChange={handleHouseRentChange}
                  />
                </div>
                {/* </div> */}
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Medical Allowance' onChange={handleCheckboxChange} />

                <label>Medical Allowance</label>

                <div className='offer-letter-input-div'>
                  <input className='form-control offer-letter-flat-value' value={medicalAllowanceFlat} onChange={handleMedicalAllowanceChange} />
                </div>
              </div>

              <div className='offer-letter-allowance-body'>
                <input className='form-check-input' type='checkbox' name='Utilities Allowance' onChange={handleCheckboxChange} />

                <label>Utilities Allowance</label>

                <div className='offer-letter-input-div'>
                  <input className='form-control offer-letter-flat-value' value={utilitiesAllowanceFlat} onChange={handleUtilitiesAllowanceChange} />
                </div>
              </div>
            </>
          )}
        </div>

        <div className='offer-letter-lower-bar'>
          <img src={SalaryIcon} style={{ height: '4vh' }} />
          <label>Total Salary: {`${(totalAmount + totalAllowanceValue).toLocaleString()}`}</label>
        </div>

        <div className='d-flex align-items-center mb-8'>
          <label className='fw-bolder fs-4 col-md-3'>Benefits & Incentives</label>
          {fetchingBenefits ? (
            <div className='d-flex align-items-center'>
              <FontAwesomeIcon className='me-4' icon={faSpinner} spin color='#F36523' size='2x' />
              <label>Fetching Benefits info...</label>

            </div>
          ) : (<div className='request-for-approvals-external-files-text'>
            <div>
              <button onClick={handleDownloadBenefitsPdf} style={{ border: 'none', backgroundColor: 'transparent' }}>
                <img src={DownLoadIcon} />
              </button>
            </div>
            <div>
              <label>Benefits & Incentives.pdf</label>
            </div>
          </div>)}
        </div>

        <div className='request-approval-btn-cont'>
          <input type='button' value='Save' name='Submit' className='request-approval-btn-state-one' onClick={HandleSave} />
        </div>
      </form>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

function Step2({ show, name, jobapp_id, candidateData, onNextStep, onVerifyClick, candidate_id, handleClose, updateCandidatesList }: Props) {
  const { auth, currentUser, logout } = useAuth()
  const [interviewers, setInterviewers] = useState<Recruiter[]>([])
  const [filteredInterviewers, setFilteredInterviewers] = useState<Recruiter[]>([])
  const [selectedInterviewers, setSelectedInterviewers] = useState<Recruiter[]>([])
  const [panelAlreadyExists, setPanelAlreadyExists] = useState(false)
  const [dragInterviewers, setDragInterviewers] = useState<Recruiter[]>([])
  const [showVerifyApprovals, setShowVerifyApprovals] = useState(false)
  const [showButtons, setShowButtons] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [isRearrangeClicked, setIsRearrangeClicked] = useState(false)
  const [isDragLocked, setIsDragLocked] = useState(false)
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [items, setItems] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const GetAllRecruiters = async () => {
    try {
      const resp = await axios.get(GET_ALL_RECRUITERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      // console.log('RESPONSE RECRUITER', resp?.data);
      const recruiters = resp.data.groupHeads
      // console.log({ recruiters })
      setInterviewers(recruiters)
    } catch (error) {
      console.log(error)
    }
  }

  const getPanelMembers = async () => {
    let data = {
      jobapp_id: jobapp_id,
      name: 'approvals',
    }
    await axios
      .post(GET_PANEL_MEMBERS_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let panelMembers: Recruiter[] = []
        let members = res.data
        members.map((member, index) => {
          let currentUserId = currentUser?.user_id
          if (currentUserId !== member.member_id) {
            let user: Recruiter = {
              user_id: member.member_id,
              name: member.userName,
              email: member.email,
              currentTarget: '',
              employee_id: member.member_id,
              first_name: member.userName,
              fullName: member.userName,
              profile_pic: member.profilePic,
            }
            panelMembers.push(user)
          }
        })
        console.log('panelMembersLength: ', panelMembers.length)
        if (panelMembers.length > 0) {
          setPanelAlreadyExists(true)
          setSelectedInterviewers(panelMembers)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const members = selectedInterviewers.map((interviewer, index) => {
    return {
      member_id: interviewer['user_id'],
      email: interviewer['email'],
    }
  })

  useEffect(() => {
    let localUser: any = localStorage.getItem('kt-auth-react-v')
    setItems(JSON.parse(localUser))
  }, [])

  const CREATEPANEL = async () => {
    // setShowLoader(true);
    if (panelAlreadyExists) {
      axios
        .post(
          SEND_EMAIL_TO_PANEL_MEMBERS,
          {
            candidate_id: candidate_id,
            jobapp_id: jobapp_id,
            index: '0',
            name: 'approvals',
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log({ res })
          updateCandidatesList()
          handleClose()
        })
        .catch((err) => {
          console.log('err', err)
        })
    } else {
      await axios
        .post(
          CREATE_PANEL,
          {
            panel: [
              // {
              //     member_id: items['user_id'],
              //     email: items['email'],
              //     jobapp_id: jobapp_id,
              //     index: 3,
              //     name: 'approvals',
              //     availability: true,
              //     member_type: 'recruiter',
              //     assigned_score: 'null'

              // },
              ...members?.map((member, index) => ({
                member_id: member?.member_id,
                email: member?.email,
                jobapp_id: jobapp_id,
                index: index,
                name: 'approvals',
                availability: 'true',
                member_type: 'group head',
                assigned_score: 'null',
              })),
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log('API-2')
          console.log({ res })
          axios
            .post(
              SEND_EMAIL_TO_PANEL_MEMBERS,
              {
                candidate_id: candidate_id,
                jobapp_id: jobapp_id,
                index: '0',
                name: 'approvals',
              },
              {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((res) => {
              console.log({ res })
              updateCandidatesList()
              handleClose()
            })
            .catch((err) => {
              console.log('err', err)
            })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase()

    if (!searchTerm) {
      setFilteredInterviewers([])
    } else {
      const filtered = interviewers?.filter((interviewer) => {
        if (!interviewer) {
          return false
        }
        const name = interviewer.first_name?.toLowerCase()
        const id = interviewer.employee_id?.toLowerCase()
        return name?.includes(searchTerm) || id?.includes(searchTerm)
      })
      setFilteredInterviewers(filtered)
    }
  }

  const handleSelectInterviewer = (selectedInterviewer) => {
    // Check if the selectedInterviewer is already in selectedInterviewers
    if (selectedInterviewers.some(interviewer => interviewer.user_id === selectedInterviewer.user_id)) {
      setMessage('This group head is already selected.')
      setShowErrorMessage(true);
      return; // Do not proceed further
    }

    if (filteredInterviewers.some((interviewer) => interviewer.employee_id === selectedInterviewer.employee_id)) {
      setSelectedInterviewers([...selectedInterviewers, selectedInterviewer])
      setFilteredInterviewers([
        ...filteredInterviewers.filter((interviewer) => interviewer.employee_id !== selectedInterviewer.employee_id),
      ])
      setDragInterviewers([...dragInterviewers, selectedInterviewer]) // add selected interviewer to dragInterviewers array
    } else if (interviewers.some((interviewer) => interviewer.employee_id === selectedInterviewer.employee_id)) {
      if (!selectedInterviewers.some((interviewer) => interviewer.employee_id === selectedInterviewer.employee_id)) {
        setSelectedInterviewers([...selectedInterviewers, selectedInterviewer])
        setInterviewers([...interviewers.filter((interviewer) => interviewer.employee_id !== selectedInterviewer.employee_id)])
        setDragInterviewers([...dragInterviewers, selectedInterviewer]) // add selected interviewer to dragInterviewers array
      }
    } else {
      setSelectedInterviewers([
        ...selectedInterviewers.filter((interviewer) => interviewer.employee_id !== selectedInterviewer.employee_id),
      ])
      setInterviewers([...interviewers, selectedInterviewer])
      setDragInterviewers([...dragInterviewers.filter((interviewer) => interviewer.employee_id !== selectedInterviewer.employee_id)]) // remove deselected interviewer from dragInterviewers array
    }
  }

  const handleDeleteInterviewer = (employeeIdToDelete) => {
    const updatedInterviewers = interviewers.filter((interviewer) => interviewer.employee_id !== employeeIdToDelete)
    setInterviewers(updatedInterviewers)
  }

  const handleDeleteInterviewers = (employeeIdToDelete) => {
    const updatedSelectedInterviewers = selectedInterviewers.filter((interviewer) => interviewer.employee_id !== employeeIdToDelete)
    setSelectedInterviewers(updatedSelectedInterviewers)
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e, index) => {
    e.preventDefault()
    const sourceIndex = e.dataTransfer.getData('text/plain')
    const updatedInterviewers = [...selectedInterviewers]
    const [removed] = updatedInterviewers.splice(sourceIndex, 1)
    updatedInterviewers.splice(index, 0, removed)
    setSelectedInterviewers(updatedInterviewers)
    e.target.style.borderTop = 'none'
  }

  const handleSendEmailClick = () => {
    setShowButtons(false)
    setShowLoader(true) // Show loader when "Send Email" button is clicked
    setTimeout(() => {
      setShowVerifyApprovals(true)
      setShowButtons(false)
      setShowLoader(false) // Hide loader after a short dela
      setIsDragLocked(true)
    }, 2000) // Simulate a 2 second delay before showing the new buttons
  }

  function handleRearrangeClick() {
    // Get all the tile divs
    const tileDivs = document.querySelectorAll('.request-approvals-tile-final-step-ul')

    // Toggle the "vibrate" class for each tile div
    tileDivs.forEach((tileDiv) => {
      tileDiv.classList.toggle('vibrate')
      setTimeout(() => tileDiv.classList.toggle('vibrate'), 1000)
    })

    // Toggle the isDragLocked state
    setIsDragLocked((prevState) => !prevState)
  }

  useEffect(() => {
    GetAllRecruiters()
    getPanelMembers()
  }, [])


  return (
    <div className='modal-body py-lg-8 px-lg-8'>
      <div>
        <label className='request-approval-final-step-header-text'>Select Group Head in sequence of Approvals</label>
      </div>
      <div className='request-approval-final-step-info-cont'>
        <img src={InfromationIcom} style={{ height: '2vh' }} />
        <label className='request-approval-final-step-info-text'>
          By clicking on submit the respected head will receive all the candidate information via email
        </label>
      </div>
      <div>
        <input
          type='text'
          placeholder='Enter Head Name/ Employee ID'
          className='request-approval-final-step-input-field'
          onChange={handleSearch}
        />
      </div>
      <div className='request-approvals-final-step-tile-cont'>
        {filteredInterviewers.map((interviewer) => (
          <div key={interviewer?.employee_id}>
            <label
              className='request-approvals-tile-final-step mb-4'
              style={{ cursor: 'pointer' }}
              onClick={() => handleSelectInterviewer(interviewer)}
            >
              <img className='profile-user-icon-reqs' src={interviewer?.profile_pic ? interviewer?.profile_pic : UserIcon} />
              {interviewer?.fullName}
              <img src={Layout} onClick={() => handleDeleteInterviewer(interviewer.employee_id)} />
            </label>
          </div>
        ))}
      </div>
      <div>
        <label className='request-approval-final-step-header-suggested-text'>Suggested Group heads</label>
      </div>

      <div className='request-approvals-final-step-tile-cont'>
        {interviewers.slice(0, 7).map((interviewer) => (
          <div key={interviewer?.employee_id}>
            <label
              style={{ cursor: 'pointer' }}
              className='request-approvals-tile-final-step mb-4'
              onClick={() => handleSelectInterviewer(interviewer)}
            >
              <img src={interviewer?.profile_pic} className='profile-user-icon-reqs' />
              {interviewer?.fullName}
              <img style={{ cursor: 'pointer' }} src={Layout} onClick={() => handleDeleteInterviewer(interviewer.employee_id)} />
            </label>
          </div>
        ))}
      </div>

      <div>
        <label className='request-approval-final-step-header-drag-text'>
          {isDragLocked ? 'Sequence Locked' : 'Drag and Drop to change the sequence'}
        </label>
        <ul style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {selectedInterviewers.map((interviewer, index) => (
            <div
              key={interviewer.employee_id}
              draggable={!isDragLocked}
              onDragStart={!isDragLocked ? (e) => handleDragStart(e, index) : undefined}
              onDragOver={!isDragLocked ? handleDragOver : undefined}
              onDrop={!isDragLocked ? (e) => handleDrop(e, index) : undefined}
              className='request-approvals-tile-final-step-ul'
            >
              <div className='request-approvals-tile-final-step-numbers'>{index + 1}</div>
              <div>
                <li className='request-approvals-tile-final-step-list'>
                  <img src={interviewer?.profile_pic} className='profile-user-icon-reqs' />
                  {interviewer?.fullName}
                  {!isDragLocked && (
                    <img style={{ cursor: 'pointer' }} src={Layout} onClick={() => handleDeleteInterviewers(interviewer.employee_id)} />
                  )}
                </li>
              </div>
            </div>
          ))}
        </ul>
      </div>
      <div className='request-approvals-tile-final-step-btn'>
        {showButtons && !showLoader && (
          <button
            className={`request-approvals-tile-final-step-sendbtn ${selectedInterviewers.length === 0 ? 'request-approvals-tile-final-step-sendbtn-disabled' : ''
              }`}
            data-toggle='modal'
            data-target='#verifyModal'
            disabled={selectedInterviewers.length === 0}
            onClick={handleSendEmailClick}
          >
            Send Email
          </button>
        )}
        {showLoader && (
          <div className='request-approvals-tile-final-step-spinner'>
            <FontAwesomeIcon icon={faSpinner} spin color='#F36523' size='3x' />
          </div>
        )}
      </div>
      <div>
        {showVerifyApprovals && (
          <div className='request-approvals-tile-final-step-verify-btn'>
            <div className='request-approvals-tile-final-step-verify-btn-upper-row'>
              {/* <div>
                                <button className='request-approvals-tile-final-step-sendbtn-verify'
                                    onClick={() => {
                                        handleRearrangeClick();
                                    }}
                                >Rearrange</button>
                            </div> */}
              <div>
                <button className='request-approvals-tile-final-step-sendbtn' onClick={CREATEPANEL}>
                  Verify
                </button>
                {/* {showLoader && (
                                    <div className='request-approvals-tile-final-step-spinner'>
                                        <FontAwesomeIcon icon={faSpinner} spin color='#F36523' size='3x' />
                                    </div>
                                )} */}
                {/* {showEmailSent && (
                                    <button className='request-approvals-tile-final-step-sendbtn-disabled'>
                                        Email Sent
                                    </button>
                                )} */}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
              <img src={InfromationIcom} style={{ height: '2vh' }} />
              {/* <label className='request-approval-final-step-header-post-email-text'>
                                An e-mail will be sent to the 1st individual
                                in the sequence for approval. Would you like
                                to proceed or re-arrange the sequence?</label> */}
              <label className='request-approval-final-step-header-post-email-text'>
                An e-mail will be sent to the selected Group Head. Would you like to proceed?
              </label>
            </div>
          </div>
        )}
      </div>

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

    </div>
  )
}

const RequestforApprovalsPopup = ({
  show,
  name,
  jobapp_id,
  job_id,
  onNextStep,
  candidateData,
  candidate_id,
  profile_pic,
  position,
  uni,
  bexp,
  jexp,
  degree,
  cityPreference,
  handleClose,
  updateCandidatesList,
}: Props) => {
  const { auth } = useAuth()
  const [experience, setExperience] = useState<any>({ bankingExperience: 0, jobExperience: 0, totalExperience: 0 })
  const [activeStep, setActiveStep] = useState(1)
  const [showImage, setShowImage] = useState(false)
  const [showFirstModal, setShowFirstModal] = useState(true)
  const [showVerifyApprovals, setShowVerifyApprovals] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showBigText, setShowBigText] = useState(false)
  const handleCloses = () => setShowModal(false)
  const handleShow = () => setShowFirstModal(true)
  const toggleText = () => setShowBigText(!showBigText)
  const [showText, setShowText] = useState(true)
  const [modalClassName, setModalClassName] = useState('modal-dialog modal-dialog-centered mw-1000px')
  const handleVerify = () => setModalClassName('modal-dialog modal-dialog-centered mw-600px')
  const [showHeader, setShowHeader] = useState(true)

  const handleVerifyClick = () => {
    setShowText(false)
    // setShowHeader(false);
    // handleVerify();
  }

  const handleStep1Click = () => {
    setActiveStep(1)
  }

  const handleStep2Click = () => {
    setActiveStep(2)
    // setShowImage(true);
  }


  return (
    <>
      <Modal show={show} dialogClassName={modalClassName} onHide={handleClose}>
        <Modal.Header closeButton>
          {showHeader && (
            <Modal.Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: '31rem',
                  marginTop: '3rem',
                }}
              >
                <img src={ApprovalIcon} className='score-header-icon-internally' />
                <h2 className='score-header-container-text'>Office Note</h2>
              </div>
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {!showText ? (
            <div className='request-approval-final-success-cont'>
              <div>
                <img src={SuccessIcon} style={{ height: '20vh' }} />
              </div>
              <div>
                <label className='request-approval-final-step-header-suggested-text'>Success</label>
              </div>
              <div>
                <button onClick={handleClose} className='request-approval-final-step-success-btn'>
                  Got it!
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className='request-approvals-stepper'>
                <div className='request-approvals-initial-state-col'>
                  <button
                    onClick={handleStep1Click}
                    className={`steps ${activeStep === 1 ? 'request-approvals-initial-state' : 'request-approvals-initial-state-inactive'}`}
                  >
                    {showImage ? <img src={CheckedIcon} alt='Checked Icon' /> : '1'}
                  </button>
                  <label
                    className={`steps ${activeStep === 1 ? 'request-approvals-initial-state-text' : 'request-approvals-initial-state-text-inactive'
                      }`}
                  >
                    Office Note Review
                  </label>
                </div>
                <div className='request-approvals-line'></div>
                <div className='request-approvals-initial-state-col'>
                  <button
                    onClick={handleStep2Click}
                    className={`steps ${activeStep === 2 ? 'request-approvals-initial-state' : 'request-approvals-initial-state-inactive'}`}
                  >
                    2{' '}
                  </button>
                  <label
                    className={`steps ${activeStep === 2 ? 'request-approvals-initial-state-text' : 'request-approvals-initial-state-text-inactive'
                      }`}
                  >
                    Send for Approval
                  </label>
                </div>
              </div>

              <div className='mx-auto d-flex flex-column '>
                <div className='profile-Container-request-approval-doc'>
                  <div className='left-container-request-approval-doc'>
                    <div>
                      <img className='profile-user-icon-app' src={profile_pic !== null ? profile_pic : UserIcon} alt='' />
                    </div>
                    <div className='request-approval-doc-middle-container-user-details'>
                      <label className='request-approval-doc-middle-container-username' style={{ whiteSpace: 'nowrap' }}>
                        {name}
                      </label>
                      <div className='request-approval-doc-middle-container-role'>
                        <label>Position: {position}</label>
                      </div>
                    </div>
                  </div>
                  <div className='request-approval-second-cont'>
                    <img src={Cap} />
                    <label className='request-approval-p'>{degree}</label>
                    <label className='request-approval-label'>{uni}</label>
                  </div>
                  <div className='request-approval-last-cont'>
                    <div>
                      <img src={ExperienceIcon} style={{ height: '4vh' }} />
                    </div>
                    <div className='request-approval-banking'>
                      <label className='request-approval-label'>Banking Experience: </label>
                      <label>
                        {' '}
                        <p className='request-approval-p' style={{ margin: '0' }}>
                          {bexp} yrs
                        </p>
                      </label>
                    </div>
                    <div className='request-approval-banking'>
                      <label className='request-approval-label'>Total Experience: </label>
                      <p className='request-approval-p' style={{ margin: '0' }}>
                        {(parseFloat(bexp) + parseFloat(jexp)).toFixed(2)} yrs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='modal-body py-lg-8 px-lg-8'> */}
              {/* <div className='card'>
                  <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'> */}
              <div>
                {activeStep === 1 && (
                  <Step1
                    show={true}
                    name={name}
                    jobapp_id={jobapp_id}
                    job_id={job_id}
                    handleClose={handleClose}
                    onNextStep={handleStep2Click}
                    onSendEmail={() => setShowVerifyApprovals(true)}
                    onVerifyClick={handleVerifyClick}
                    candidateData={candidateData}
                    candidate_id={candidate_id}
                    profile_pic={profile_pic}
                    position={position}
                    uni={uni}
                    bexp={bexp}
                    jexp={jexp}
                    degree={degree}
                    cityPreference={cityPreference}
                    updateCandidatesList={updateCandidatesList}
                  />
                )}
                {activeStep === 2 && (
                  <OfficeNoteApprovalStep2
                    jobapp_id={jobapp_id}
                    job_id={job_id}
                    candidate_id={candidate_id}
                    handleClose={handleClose}
                    updateCandidatesList={() => {
                      handleClose();
                      updateCandidatesList();
                    }}
                  />

                  // <Step2
                  //   show={true}
                  //   name={name}
                  //   jobapp_id={jobapp_id}
                  //   job_id={job_id}
                  //   handleClose={handleClose}
                  //   onNextStep={handleStep2Click}
                  //   onSendEmail={() => setShowVerifyApprovals(true)}
                  //   onVerifyClick={handleVerifyClick}
                  //   candidateData={candidateData}
                  //   candidate_id={candidate_id}
                  //   profile_pic={profile_pic}
                  //   position={position}
                  //   uni={uni}
                  //   bexp={bexp}
                  //   jexp={jexp}
                  //   degree={degree}
                  //   cityPreference={cityPreference}
                  //   updateCandidatesList={updateCandidatesList}
                  // />
                )}
              </div>
              {/* </div>
                </div> */}
              {/* </div> */}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export { RequestforApprovalsPopup }
