import { useEffect, useState } from 'react'

import Level3Form from './CompetencyTabsForms/Level3Form'
import Level4Form from './CompetencyTabsForms/Level4Form'
import Level5Form from './CompetencyTabsForms/Level5Form'
import imageLevel3 from '../images/level3.png'
import imageLevel3Gray from '../images/level3-grey.png'
import imageLevel4 from '../images/level4.png'
import imageLevel4Gray from '../images/level4-grey.png'
import imageLevel5 from '../images/level5.png'
import imageLevel5Gray from '../images/level5-grey.png'

import Level3Flowchart from './CompetencyTabsForms/Level3Flowchart'
import Level4Flowchart from './CompetencyTabsForms/Level4flowchart'
import Level5Flowchart from './CompetencyTabsForms/Level5Flowchart'
import axios from 'axios'
import { useAuth } from '../../auth'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const GET_LEVELS = `${API_URL}/level/get-level`

function Competencies() {
  const { auth, logout } = useAuth();
  const [Active, setActive] = useState('');
  const [totalLevels, setTotalLevels] = useState(3);
  const [levels, setLevels] = useState<any>([]);
  // const placeHolderTitles = ['Basic', 'Intermediate', 'Advance', 'Professional', 'Expert'];
  const placeHolderTitles = ['Level One', 'Level Two', 'Level Three', 'Level Four', 'Level Five'];
  const placeHolderDescriptions = ['Basic Knowledge of the industry',
    'Actively seeks information on new environment. Takes a broader view. Evaluates external practices',
    'Proactively considers and researches the broader industry environment. Rigorously test ideas from a variety of sources',
    'Thinks globally and beyond traditional views and how these affect the organization',
    'Demonstrates strong judgement & insights, leveraging the knowledge of market factors'];
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState('');

  const ChangeInActiveIndex = (ActiveState: string) => {
    console.log('first')
    if (ActiveState === 'level3') {
      setTotalLevels(3);
      setActive('level3')
    } else if (ActiveState === 'level4') {
      setTotalLevels(4);
      setActive('level4')
    } else if (ActiveState === 'level5') {
      setTotalLevels(5);
      setActive('level5')
    }
  }

  const createLevelsFramework = () => {
    let levelsData: any = [];
    for (let i = 0; i < totalLevels; i++) {
      let level = { title: '', description: '' }
      levelsData.push(level);
    }
    console.log('levelsDataBeingSet: ', levelsData)
    // debugger;
    setLevels(levelsData);
  }

  useEffect(() => {
    updateLevelsFramework();
  }, [Active])

  const updateLevelsFramework = async () => {
    // hit API to fetch updated data of levels
    await axios.get(GET_LEVELS, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      console.log('levels5FormData: ', res.data)
      let noOfLevels = res?.data?.level?.level_no;
      if (Active === '') {

        if (noOfLevels === 3) {
          setTotalLevels(3);
          setActive('level3')
        } else if (noOfLevels === 4) {
          setTotalLevels(4);
          setActive('level4')
        } else {
          setTotalLevels(5);
          setActive('level5')
        }
      }
      const currentLevelsData = res?.data?.level?.level_data;
      let data = JSON.parse(currentLevelsData)
      if (data) {
        let levelsData: any = []
        data.map((item, index) => {
          let title = Object.keys(item)[0];
          let description = item[title];
          let level = { title: title, description: description }
          console.log(`levelsInfo: level: ${level}`)
          levelsData.push(level);
        })
        console.log(`levelsInfo: levelsData: ${levelsData}`)
        console.log(`levelsInfo: levelsDataLength: ${levelsData.length} :  totalLevels: ${totalLevels}`)
        if (levelsData.length === totalLevels) {
          setLevels(levelsData);
        } else {
          createLevelsFramework();
        }
      } else {
        createLevelsFramework();
      }
    }).catch((error) => {
      createLevelsFramework();
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    });
  }

  return (
    <>
      {/* <div className='row bg-white margin-top bg-white g-5 g-xl-8 shadow'> */}
      <div className='card-bg shadow-sm mt-12 py-4 '>
        <div>
          <div className='row text-center '>
            <label className='heading-competency text-center  my-10  my-2'>
              How many levels does your competency model have?
            </label>
          </div>
          <div className=' d-flex mx-auto mb-14 justify-content-center'>
            {/* <div className='col-md-3'></div> */}
            <div
              // className=' col-md-2'
              style={{ cursor: 'pointer' }}
              onClick={() => ChangeInActiveIndex('level3')}
            >
              {Active === 'level3' ? (
                <div
                  // className='levels-divs'
                  className='levels-divs-gray shadow-sm'
                >
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_3}></img>
                  </div>
                  <div className='fs-5 font-level'>3 Levels</div>
                </div>
              ) : (
                <div className='levels-divs-gray'>
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_3_gray}></img>
                  </div>
                  <div className='fs-5 font-level-gray'>3 Levels</div>
                </div>
              )}
            </div>
            <div
              className=' mx-6 '
              style={{ cursor: 'pointer' }}
              onClick={() => ChangeInActiveIndex('level4')}
            >
              {Active === 'level4' ? (
                <div 
                // className='levels-divs'
                className='levels-divs-gray shadow-sm'>
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_4}></img>
                  </div>
                  <div className='fs-5 font-level'>4 Levels</div>
                </div>
              ) : (
                <div className='levels-divs-gray'>
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_4_gray}></img>
                  </div>
                  <div className='fs-5 font-level-gray'>4 Levels</div>
                </div>
              )}
            </div>
            <div
              // className=' col-md-2'
              style={{ cursor: 'pointer' }}
              onClick={() => ChangeInActiveIndex('level5')}
            >
              {Active === 'level5' ? (
                <div 
                // className='levels-divs'
                className='levels-divs-gray shadow-sm'>
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_5}></img>
                  </div>
                  <div className='fs-5 font-level'>5 Levels</div>
                </div>
              ) : (
                <div className='levels-divs-gray'>
                  <div>
                    <img style={{objectFit:'cover'}} width={88} height={88} src={imagePath.ic_level_5_gray}></img>
                  </div>
                  <div className='fs-5 font-level-gray'>5 Levels</div>
                </div>
              )}
            </div>
            {/* <div className='col-md-3'></div> */}
          </div>
        </div>

        <div className='g-6 g-xl-9'>
          {Active === 'level3' ? (
            <>
              {' '}
              <Level3Form
                placeHolderTitles={placeHolderTitles}
                placeHolderDescriptions={placeHolderDescriptions}
                updateLevelsFramework={updateLevelsFramework}
              />
            </>
          ) : Active === 'level4' ? (
            <>
              {' '}
              <Level4Form
                placeHolderTitles={placeHolderTitles}
                placeHolderDescriptions={placeHolderDescriptions}
                updateLevelsFramework={updateLevelsFramework} />
            </>
          ) : Active === 'level5' ? (
            <>
              {' '}
              <Level5Form
                placeHolderTitles={placeHolderTitles}
                placeHolderDescriptions={placeHolderDescriptions}
                updateLevelsFramework={updateLevelsFramework}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className=' my-10 card-bg shadow-sm'>
        <div className='g-6 g-xl-9'>
          <Level5Flowchart
            placeHolderTitles={placeHolderTitles}
            placeHolderDescriptions={placeHolderDescriptions}
            levels={levels}
          />
        </div>
      </div>
      {showErrorMessage && (
        <ShowError
          handleClose={() => setShowErrorMessage(false)}
          message={message}
        />
      )}
    </>
  )
}

export default Competencies
