//import liraries
import { FC } from 'react'
import './cards.css'

import bin from '../images/bin.png'
import hoverbin from '../images/bin-delete.png'
import { useState } from 'react'
import cross from '../images/cross.png'
import masesfaf from '../images/mail_black_24dp.png'
import call from '../images/Path 38454.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { Link } from 'react-router-dom'
import WarningTARemovePopup from '../../modals/JobFunnelsModels/WarningTARemovePopup'
import avatarIcon from '../../../../app/modules/JobsFunneling/img/Group 9681.png';
import ShowError from '../toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const DELETE_RECRUITER_URL = `${API_URL}/ta-ra/`;


type Props = {
    admin: any,
    deleteAdmin: () => void,
    showDeleteIcon: any
}

// create a component
const AdminUserCard: FC<Props> = ({ admin, deleteAdmin, showDeleteIcon }) => {
    const { auth, currentUser } = useAuth();
    const [overbin, setOverbin] = useState(false)
    const [showWarningPopup, setShowWarningPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    const avatar = admin?.profile_pic;

    const fullName = `${admin?.first_name ? (admin?.first_name) : ''} ${admin?.last_name ? (admin?.last_name) : ''}`

    return (
        <div className='card card-border-admin'>
            {/* <div className='card-header  no-border-class no-border-class'>
                <span className='cardsettingfrotheTAHEAD  px-4 py-3 mt-4' style={{ maxHeight: '40px' }}>
                    Admin
                </span>

                {showDeleteIcon && (<div
                    className='card-toolbar'
                    onMouseOver={() => setOverbin(true)}
                    onMouseOut={() => setOverbin(false)}
                >
                    <Link to=''>
                        {' '}
                        <img className='backround-hover' src={overbin ? hoverbin : bin}
                            onClick={() => {
                                if (admin?.user_id !== currentUser?.user_id) {
                                    setShowWarning(true)
                                } else {
                                    setMessage('User can not remove his own admin role');
                                    setShowErrorMessage(true);
                                }
                            }}
                        />
                    </Link>

                </div>)}
            </div> */}

            <div className='cardboyfortherecruiter d-flex flex-center flex-column '>
                <div className='mb-5'>
                    <div className='symbol symbol-75px symbol-circle'>
                        <img style={{ objectFit: 'cover', border: '1.5px #F36523 solid' }} alt='Pic' src={avatar ? avatar : imagePath.userIcon} />
                    </div>
                </div>

                <label className='fs-5 text-gray-800text-hover-primaryfw-boldwe mb-0'>
                    {fullName}
                </label>

                <label
                    style={{ color: '#80808F', padding: '0.4rem 0.4rem', borderRadius: '2px' }}
                    className='btn fw-bold btn-sm btn-light fs-8 mt-2'>
                    Employee ID: {`${admin?.employee_id}`}
                </label>

                <div className='d-flex pt-3 px-2 mx-2 mb-2'>
                    <div className='mx-2'>
                        <img src={imagePath.ic_mail_orange_fill}></img>
                    </div>
                    <label style={{ color: '#80808F', maxWidth:'13rem',whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis' }} title={admin?.email} className='fw-bold '>
                        {`${admin?.email}`}
                    </label>
                </div>

                <div className='d-flex px-2 mx-2 mb-4'>
                    <div className='mx-2'>
                        <img src={imagePath.ic_phone_orange_fill}></img>
                    </div>
                    <label style={{ color: '#80808F' }} className='fw-bold'>
                        {`${admin?.phone_number}`}
                    </label>
                </div>
                {/* {showDeleteIcon && ( */}
                <div
                    className='candi-btn2 text-white fw-bolder'
                    style={{ padding: '0.5rem 1rem' }}
                    // disabled={showDeleteIcon}
                    onClick={() => {
                        if (admin?.user_id !== currentUser?.user_id) {
                            setShowWarning(true)
                        } else {
                            setMessage('User can not remove his own admin role');
                            setShowErrorMessage(true);
                        }
                    }}>
                    Remove Role
                </div>
                {/* )} */}
            </div>

            {showWarning && (
                <WarningTARemovePopup
                    show={true}
                    message='Are you sure you want to remove admin role of user?'
                    handleClose={() => {
                        setShowWarning(false);
                    }}
                    handleYesSure={() => {
                        setShowWarning(false);
                        deleteAdmin();

                    }}
                />
            )}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default AdminUserCard;
