//import liraries
import React from 'react';

type Props = {
    type: string
    value: any
    color: string
}

// create a component
const BudgetInfoBox = ({ type, value, color }: Props) => {
    return (
        <div className='position-info-card px-2 py-3' >
            <div className='d-flex align-items-center'>
                <div className={` me-1 ${color === 'lightOrange' ? 'light-orange-box' : (color === 'darkOrange' ? 'dark-orange-box' : 'gray-box')}`}></div>
                <label className='fs-9 fw-bolder'>{type}</label>
            </div>
            <label className='fs-7 fw-bolder mt-2'>{value}</label>
        </div >
    );
};

//make this component available to the app
export default BudgetInfoBox;
