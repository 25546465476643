/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import ApplicationsTableOnlineTest from '../../../../app/modules/JobsFunneling/OnlineTest/OnlineTestNewPage'
// import {KTSVG} from '../../../helpers'
import './ModelStyles.css'
import {useEffect, useState} from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-all-job-application`

type Props = {
  show: boolean
  handleClose: () => void
  //   name: string
  job_id: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveAllUserApplication = ({show, job_id, handleClose}: Props) => {
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const {auth} = useAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(
      ACCEPT_JOB_URL,
      {
        job_id: job_id,
        currentStage: 'application',
        newStage: 'online test',
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then(

    )

    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={imagePath.ic_close}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' className='add-warning-image' src={addcompetenctimage}></img>
          <h3 className='fw-bolder mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>Online test link will be sent to All selected Job Applicants</h3>
        </div>
      </div>

      <div className='modal-body px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              {/* <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label>
              </div> */}
              <div className='d-flex justify-content-center align-items-center'>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn text-white px-10 bgcolorofbutoonofsubmit me-8'
                >
                  Yes, I am sure!
                </button>
                <button onClick={handleClose} className='btn text-white px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ApproveAllUserApplication}
