import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {GoogleLogin} from '@react-oauth/google'
import jwt_decode from 'jwt-decode'

import { getUserByToken, login, cvcheck } from '../core/_requests'
import { useAuth } from '../core/Auth'

import lock from '../images/password.png'
import unchecked from '../images/check-box-outline-blank-black.png'
import checked from '../images/tick.png'
import letterboxImage from '../images/mail.png'
import './Login.css'

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? 'google-client-id'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(8, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(12, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function CandidateLogin() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [Model, setModel] = useState(false)
  const [Email, setEmail] = useState('email')
  const [password, setPassword] = useState('password')
  const [checkedState, setCheckedState] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const email = values.email
      const password = values.password
      const credential = 'false'
      try {
        const {data: auth} = await login(email, password, credential)
        saveAuth(auth)

        const { data: user } = await getUserByToken(auth.token)

        if (user?.role === 'candidate') {
          const resp = await cvcheck(user?.user_id, auth.token)
          user.cvcheck = resp.data.value
          setCurrentUser(user)
        } else {
          setCurrentUser(user)
        }
      } catch (error) {
        setModel(true)
        console.log('Error')
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        setTimeout(() => {
          setModel(false)
        }, 3000)
      }
    },
  })

  const checkedStateFunction = () => {
    setCheckedState(!checkedState)
  }

  async function loginUser(email, password, credential) {
    setLoading(true)
    try {
      const {data: auth} = await login(email, password, credential)
      saveAuth(auth)

      const {data: user} = await getUserByToken(auth.token)

      if (user?.role === 'candidate') {
        const resp = await cvcheck(user?.user_id, auth.token)
        user.cvcheck = resp.data.value
      }
      setCurrentUser(user)
      setLoading(false)
    } catch (error) {
      setModel(true)
      console.log('Error')
      console.error(error)
      saveAuth(undefined)
      setLoading(false)
      setTimeout(() => {
        setModel(false)
      }, 3000)
    }
  }

  return (
    <form
      className='form w-100 aaligndivCenter'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className=' Registration-text main-heading mb-3'>Log In</h1>
        <div className='text-white mt-10 fw-semibold labels'>
          Find your dream job in just one click
        </div>
      </div>
      {/* begin::Heading */}

      <div className='widthHeightSettingForm'>
        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label labeldfa mb-3'>Email Address</label>
          <div className='grp-image-input backinpput-color'>
            <input
              style={{height: '56px'}}
              placeholder='Enter your Email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control no-border',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='of'
            />
            <img alt='' src={letterboxImage}></img>
            {/* <i className=' custom-class bi-envelope'></i> */}
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span className='text-white' role='alert'>
                {formik.errors.email}
              </span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label labeldfa mb-3'>Password</label>
          <div className='grp-image-input'>
            <input
              style={{height: '56px'}}
              type='password'
              autoComplete='off'
              placeholder='Enter your Password'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control no-border',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />

            <img src={lock} alt=''></img>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='text-white' role='alert'>
                  {formik.errors.password}
                </span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap  fs-base fw-semibold mt-3 mb-8'>
          <div className='form-check dflex-checkbox-logged-in'>
            <div onClick={() => checkedStateFunction()}>
              {checkedState === false ? (
                <>
                  {' '}
                  <img src={unchecked}></img>
                </>
              ) : (
                <>
                  {' '}
                  <img src={checked}></img>
                </>
              )}
            </div>

            <label className='form-check-label forgot-pass'>Keep me logged in</label>
          </div>
          <div />

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link- forgot-pass text-white'>
            <u>Forgot Password?</u>
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            style={{height: '60px', color: 'white'}}
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary loginButtonLogin-screen'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='text-white fs-6 indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        <div className='form-check-label forgot-pass fw-semibold fs-6'>
          Don't have an account?{' '}
          <Link to='/auth/registration' className='link-primary'>
            Sign up
          </Link>
        </div>

        <div className='d-flex align-items-center my-10'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <span className='fw-semibold text-gray-400 fs-7 mx-2'>OR</span>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>

        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <GoogleLogin
            shape='rectangular'
            text='signin_with'
            width='328px'
            onSuccess={(credentialResponse) => {
              const credential: any = credentialResponse?.credential
              const userData: any = jwt_decode(credential)
              const email = userData.email
              const password = userData.sub
              loginUser(email, password, credential)
            }}
            onError={() => {
              console.log('Login Failed')
            }}
          />
        </GoogleOAuthProvider>
      </div>
      {/* end::Action */}
    </form>
  )
}
