//import liraries
import React, {useEffect, useState} from 'react'

import '../../CVScreens/CandiMain.css'
import '../../CVScreens/personalinfo.css'
import '../../CVScreens/position.css'
import '../../CVScreens/Stepper.css'
import ic_cross from '../../assets/ic_cross.png'

import UploadCloud from '../../assets/upload@2x.png'
import dayjs, {Dayjs} from 'dayjs'
import Loader from '../../CVScreens/comps/Loader'
import {useAuth} from '../../../auth'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-certificates/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`

// create a component
const TrainingCertifi = ({
  inputFields,
  handleFormChange,
  handleDateFromChange,
  handleDateToChange,
  handleFileChange,
  addFields,
  removeFields,
  handleDelete,
  handleItemClick,
  ...props
}) => {
  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [sendRequest, setSendRequest] = useState(false)

  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  // const staticSkills = ["Data Analyst", "MERN Stack", "Front-end developer", "Node developer", "Data Scientist", "Data Engineer"];
  const [competencies, setCompetencies] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const [profileStrength, setprofileStrength] = React.useState<Dayjs | any>([
    {
      profile_info: 'false',
      work_exp: 'false',
      education: 'false',
      certificate: 'false',
      skills: 'false',
      total: '5',
    },
  ])
  const [currentProgress, setCurrentProgress] = useState(5)

  const handleInputChange = (event) => {
    const query = event.target.value
    setQuery(query)
    // if (query.length > 0) {
    const filteredResults = competencies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    // setResults(filteredResults);
    setResults(filteredResults.length > 0 ? filteredResults : competencies)
    // } else {
    // setResults([]);
    // }
  }

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getCompetencies()
    })()
  }, [])

  return (
    <div style={{width: '100%'}}>
      <div style={{width: '100%', margin: '0 auto'}}>
        <i style={{float: 'right'}} onClick={addFields} className='bi bi-plus-lg addmore'></i>
      </div>
      <div className='row g-5 g-xl-8 personal-info-container' style={{margin: '0', padding: '0'}}>
        {/* add more button  */}

        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row ms-4'>
                  {/* FORM START  */}
                  <form>
                    {inputFields.map((input, index) => {
                      return (
                        <div className='dynamic-section' key={index}>
                          {index === 0 ? null : (
                            <div style={{width: '95%', margin: '0 auto'}}>
                              <i
                                style={{float: 'right'}}
                                onClick={() => removeFields(index)}
                                className='bi bi bi-trash-fill trash-button'
                              ></i>
                            </div>
                          )}
                          {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                          <h3 className='card-title mt-5 align-items-center flex-column'>
                            <span className='card-label text-gray-800 fw-bolder fs-3 ms-3'>Training / Certification {index + 1}</span>
                          </h3>

                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Certification Name</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='name'
                                  className='form-control'
                                  placeholder='Issacs Code'
                                  value={input.name}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                />
                              </div>{' '}
                            </div>
                            {/* Job title  */}
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Issuing Organization</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='issuing_organization'
                                  className='form-control'
                                  placeholder='Full Stack Developer'
                                  value={input.issuing_organization}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                />
                              </div>{' '}
                            </div>
                          </div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Subject / Specialization</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='text'
                                    name='specialization'
                                    className='form-control'
                                    placeholder='Subject / Specialization'
                                    value={input?.specialization}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Issue Date</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='issue_date'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input?.issue_date}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Date picker  */}
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Date From</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='date_from'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input?.date_from}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Date To</label>
                              <div className='datepicker'>
                                <div className='form-group me-1'>
                                  <input
                                    type='date'
                                    name='date_to'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input.date_to}
                                    onChange={(event) => handleDateToChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* cv upload  */}
                          <div className='form-group col-md-12'>
                            <div className='col-md-6'>
                              <label className='form-label labelse mt-10'>Upload Media</label>
                              <div className='drop-zone-training-file' style={{width: '100%'}}>
                                <div className='drop-wrapper'>
                                  <div className='drop-image-container'>
                                    {/* <img className='drop-zone__img' src={UploadCloud} alt="..." /> */}
                                    <img
                                      className='drop-zone__img'
                                      style={{objectFit: 'cover'}}
                                      src={
                                        typeof input.myFile === 'string'
                                          ? input.myFile
                                          : input.myFile
                                          ? URL.createObjectURL(input.myFile)
                                          : UploadCloud
                                      }
                                      alt='file'
                                    />
                                  </div>
                                  <div className='drop-image-container'>
                                    <span className='drop-zone__prompt'>Drop and Drop here</span>
                                    <span className='drop-zone__prompt'>or</span>
                                    <span className='drop-zone__prompt browse-file'>Browse Files</span>
                                    <span className='drop-zone__prompt fs-6'>File type should be .jpg, .jpeg or .png</span>
                                  </div>
                                </div>
                                <div className='cv-container'>
                                  <input
                                    type='file'
                                    name='myFile'
                                    className='drop-zone__input'
                                    accept='.jpg, .jpeg, .png'
                                    onChange={(event) => handleFileChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <label className='form-label labelse add-skills-label'>Add Skills Used</label>
                          {/* SKILLS  */}
                          <div className='form-row d-flex mt-2 justify-content-spacearound' style={{margin: '0 1.3rem 0 2rem'}}>
                            <div className='form-group col-md-12'>
                              {/* <label className='form-label labelse '> Blood Group</label> */}
                              <div className='me-4  '>
                                <input
                                  type='text'
                                  className='search-bar-style form-control   w-140px'
                                  placeholder='Search skills'
                                  value={query}
                                  onChange={handleInputChange}
                                  onFocus={handleInputChange}
                                />
                                {results.length > 0 && (
                                  <ul className='form-control   w-140px'>
                                    {results.map((item, suggestionsIndex) => (
                                      <li key={suggestionsIndex} onClick={handleItemClick(item, index)}>
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              {/* <Chips skillset={chips} /> */}
                              <div className=' mt-4'>
                                {input.skills.map((val, i) => (
                                  <div key={i} style={{width: 'fit-content', display: 'inline-block', margin: '0 1rem 0 0'}}>
                                    <div className='chip-uxi'>
                                      {val}&nbsp;
                                      {/* <i className='bi bi-x-lg' onClick={() => handleDelete(index, i)}></i> */}
                                      <img src={ic_cross} alt='remove' onClick={() => handleDelete(index, i)}></img>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {/* <h3 className='card-title align-items-center flex-column'>
                                                    <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                        Suggested Competencies
                                                    </span>
                                                </h3> */}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </form>
                  {/* FORM END  */}
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>

      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default TrainingCertifi
