import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import ApplicationsTableOnlineTest from '../../../../app/modules/JobsFunneling/OnlineTest/OnlineTestNewPage'
import './ModelStyles.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { ScheduleInterviewPopUpStage2 } from './ScheduleInterciewStage2'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  email: string
  candidate_id: string
  interviewStages: any
  profilePic: string
  position: string
  bexp: string
  jexp: string
  // handleApprove: () => void

}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserAppInterviewStag2App = ({ show, name, jobapp_id, email, candidate_id, handleClose, interviewStages }: Props) => {
  const [popup, setpopup] = useState(<></>)
  const { auth } = useAuth()
  console.log({ interviewStages })

  // const createPopup = (name, jobapp_id, email, user_id) => {
  //   setpopup(
  //     <ScheduleInterviewPopUpStage2
  //       show={true}
  //       name={name}
  //       jobapp_id={jobapp_id}
  //       email={email}
  //       user_id={user_id}
  //       handleClose={() => {
  //         setpopup(<></>)
  //       }}
  //     />
  //   )
  //   console.log('popup', jobapp_id)

  // }



  const handleSubmits = async (e) => {
    e.preventDefault()
    await axios.post(
      ACCEPT_JOB_URL,
      {
        jobapp_id: jobapp_id,
        currentStage: 'stage2',
        newStage: 'approvals',
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then((res) => {
      console.log("approved to approvals")
    })

    handleClose()
  }
  const handleSubmit = async (e) => {
    if (parseInt(interviewStages) === 2) {
      handleSubmits(e)
      // await handleApprove();
      handleClose();
    }
    else {
      e.preventDefault()
      handleClose();
      // await handleApprove();
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' style={{ height: '18vh', width: '18vh', marginLeft: '18rem' }} src={addcompetenctimage}></img>
          {parseInt(interviewStages) === 2 ? (
            <div className='fw-bolder mx-auto my-5' style={{ display: 'grid', gridTemplateRows: 'auto auto' }}>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center'
              }}>{`Are You sure you want to select `}</span>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center'
              }}>{`${name} to next stage?`}</span>
            </div>
          ) : (
            <div className='fw-bolder mx-auto my-5' style={{ display: 'grid', gridTemplateRows: 'auto auto' }}>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center'
              }}>{`Are You sure you want to select `}</span>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center'
              }}>{`${name} for Online interview stage 3?`}</span>
            </div>
          )}
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>

        {/* begin::Beader */}

        <div  >
          <form
          //  onSubmit={handleSubmit}
          >
            <div className='sureunsure' style={{
              marginTop: '-3rem'
            }}>
              <button
                onClick={handleSubmit}
                type='button'
                className=' btn my-10 px-10 bgcolorofbutoonofsubmit '
              >
                Yes, I am sure!
              </button>
              {popup}
              <button onClick={handleClose} className='btn my-5 px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ApproveUserAppInterviewStag2App }
