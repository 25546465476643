/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import clsx from 'clsx'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useCreateJobsButtonContext} from '../../../../context/useJobsButtonContext'
import bellicon from './images/notifications_black_24dp.png'
import axios from 'axios'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const FETCH_NOTIFICATIONS_URL = `${API_URL}/activity/all-activities`

const toolbarButtonMarginClass = 'ms-1 ms-lg-5',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const {currentUser, auth} = useAuth()
  const user_id = currentUser?.user_id
  const {jobButton} = useCreateJobsButtonContext()
  const [notifications, setNotifications] = useState([])

  const fetchNotifications = async () => {
    if (currentUser?.role === 'none') {
      return
    }

    try {
      const response = await axios.get(FETCH_NOTIFICATIONS_URL, {
        params: {
          user_id: user_id,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      const notificationData = response.data?.data // Assuming the API response contains notification data.
      setNotifications(notificationData)
    } catch (error) {
      console.error('Error fetching notifications: ', error)
    }
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {jobButton === true ? (
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>{/* Create Job */}</div>
        ) : (
          <>
            <div></div>
          </>
        )}

        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}

        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)} id='kt_header_user_menu_toggle'>
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            // data-kt-menu-placement='bottom-end'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-50px w-50px rounded-circle'
              style={{objectFit: 'cover', border: 'solid 1px #F36523'}}
              // src={currentUser?.profile_pic || currentUser?.avatar_location}
              src={currentUser?.profile_pic || imagePath.userIcon}
              alt='tc'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>

        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          <div
            className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-start'
            data-kt-menu-flip='bottom'
            onClick={fetchNotifications}
          >
            <img src={bellicon}></img>
          </div>
          <HeaderNotificationsMenu notifications={notifications} />
          {/* end::Menu wrapper */}
        </div>
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div> */}
      </div>
    </div>
  )
}

export {Topbar}
