// Context/TestContext
import React, { useState, createContext } from 'react'
interface TestProviderProps {
  children: React.ReactNode
}
export const TestContext = createContext({ Test: "none", setTestButton: (Test: string) => { } })
export const TestProvider = ({ children }: TestProviderProps) => {
  const [Test, setTestButton] = useState("none")
  return <TestContext.Provider value={{ Test, setTestButton }}>{children}</TestContext.Provider>
}
