import React, {useEffect} from 'react'
import {Chart} from 'react-google-charts'

// create a component
const DummyCharts = () => {
  const data = [
    ['Year', 'Insights'],
    ['2003', 120],
    ['2004', 200],
    ['2005', 180],
    ['2006', 260],
    ['2007', 260],
  ]

  const options = {
    curveType: 'function',
    colors: ['#66C87B'], // Change line color to red
    hAxis: {
      textPosition: 'none', // Remove x-axis labels
      gridlines: {
        color: 'transparent', // Remove x-axis gridlines
      },
      baselineColor: 'none', // Hide x-axis baseline
    },
    vAxis: {
      textPosition: 'none', // Remove y-axis labels
      gridlines: {
        color: 'transparent', // Remove y-axis gridlines
      },
      baselineColor: 'none', // Hide y-axis baseline
    },
    legend: 'none', // Hide legend
    backgroundColor: 'transparent',
  }

  // data for second chart
  const dataPieChart = [
    ['Status', 'Count', {role: 'style'}],
    ['InProgress', 10, '#FFD2A8'],
    ['Closed', 25, '#F57536'],
    ['Unattended', 30, '#F2F2F2'],
  ]

  const optionsPieChart = {
    pieHole: 0.7,
    is3D: false,
    legend: 'none',
    pieSliceText: 'none',
    colors: ['#FFD2A8', '#F57536', '#F2F2F2'],
    chartArea: {width: '100%', height: '100%'}, // Adjust the chart area
    margin: 0,
    backgroundColor: 'transparent',
  }

  return (
    <div className='d-flex flex-column' style={{display: 'none'}}>
      <h1>Line Chart</h1>
      <div className='shadow-sm mt-10 mb-10' style={{height: '10rem', width: '40rem', borderRadius: '10px', backgroundColor: 'white'}}>
        <Chart className='ms-auto mt-4' chartType='LineChart' width='100%' height='60px' data={data} options={options} />
      </div>
      <h1>Pie Chart</h1>
      <div className='shadow-sm mt-10 mb-10' style={{height: '20rem', width: '40rem', borderRadius: '10px', backgroundColor: 'white'}}>
        <div style={{height: '16rem', width: '100%'}}>
          <Chart chartType='PieChart' width='100%' height='100%' data={dataPieChart} options={optionsPieChart} />
          <div
            id='totalPositions'
            style={{position: 'relative', transform: 'translate(-50%, -50%)', textAlign: 'center', marginLeft: '50%', marginTop: '-9rem'}}
          >
            <div>
              <label className='fs-6 fw-bolder' style={{color: '#616670'}}>
                Total Positions
              </label>
            </div>
            <div>
              <label className='fs-5 fw-bolder' style={{color: '#2B2B2B'}}>
                {65}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//make this component available to the app
export default DummyCharts
