//import liraries
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import './Success.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import ic_cross from '../../../../app/modules/Candidate Space/assets/ic_cross.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { useNavigate } from 'react-router'
import LoaderView from './LoaderView'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_POSITION_URL = `${API_URL}/position/`
const GET_EMPLOYEE_LIST_URL = `${API_URL}/position/get-employees`
const GET_RECRUITERS_URL = `${API_URL}/ta-ra/get-recruiters`
const GET_ALL_GROUPS = `${API_URL}/group`

type Props = {
    handleClose: () => void
    position: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const RequisitionTaLeadFormPopup = ({ handleClose, position }: Props) => {
    console.log('currentPositionData: ', position)
    const { currentUser, auth, logout } = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [recruitersList, setRecruitersList] = useState<any>()
    const [recruiterResults, setRecruiterResults] = useState<any>([]);
    const [recruiter, setRecruiter] = useState<any>()
    const [recruiterQuery, setRecruiterQuery] = useState('')
    const recruiterRef = useRef<HTMLInputElement>(null);
    const [positionLocations, setPositionLocations] = React.useState<any>([])
    const [documents, setDocuments] = useState<any>();
    const [advertisementMode, setAdvertisementMode] = useState<any>();

    const handleDocumentClick = (event: MouseEvent) => {
        if (recruiterRef.current && !recruiterRef.current.contains(event.target as Node)) {
            setRecruiterResults([])
        }
    }

    // Attach click event listener to the document
    // This is to detect clicks outside the component and hide the group results
    // Make sure to clean up the event listener when the component is unmounted
    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick)
        return () => {
            document.removeEventListener('click', handleDocumentClick)
        }
    }, [])

    useEffect(() => {
        if (position) {
            //   setPositionInfo(position.position_type)

            const batchPositions = position.batch_positions;
            if (batchPositions) {
                let positionsData: any = [];
                const locations = batchPositions.locations
                if (locations) {
                    let positionsData: any = []
                    locations.forEach((element, index) => {
                        let data = {
                            code: batchPositions.codes[index],
                            officeType: batchPositions.office_types[index],
                            location: element,
                            no_of_positions: batchPositions.no_of_positions[index],
                            position_id: batchPositions.position_ids[index],
                        }
                        positionsData.push(data)
                    })
                    setPositionLocations(positionsData)
                }
            }

            let docs = position?.documents;
            if (docs) {
                docs = JSON.parse(docs);
                setDocuments(docs)
            }
            let advertisementTypes = position?.mode_of_advertisement;
            if (advertisementTypes) {
                advertisementTypes = JSON.parse(advertisementTypes);
                setAdvertisementMode(advertisementTypes)
            }

        }
    }, [position])

    useEffect(() => {
        (async () => {
            getRecruiters()
        })()
    }, [])

    const getRecruiters = async () => {
        await axios
            .get(GET_RECRUITERS_URL, {
                params: {
                    ta_lead_id: currentUser?.user_id,
                },
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((res) => {
                setRecruitersList(res?.data?.data)
            })
            .catch((error) => {
                displayError(error)
            })
    }

    const displayError = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }

    const handleRecruiterChange = (event) => {
        const query = event.target.value
        setRecruiterQuery(query)
        let filteredResults = recruitersList.filter((item) => {
            let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
            return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
        })
        setRecruiterResults(filteredResults.length > 0 ? filteredResults : recruitersList)
    }

    const handleRecruiterClick = (item) => () => {
        setRecruiter(item)
        let query = `${item['first_name']} ${item['last_name']}`
        setRecruiterQuery(query)
        setRecruiterResults([])
    }

    const handleSubmit = () => {
        if (!recruiter) {
            setMessage('Recruiter data not found, Please select a Recruiter and again')
            setShowErrorMessage(true)
            return
        }

        if (position?.batch_id) {

            console.log('raise requisition for batch position')
            raiseMultipleRequisitions();

        } else {

            const recruiterId = recruiter?.user_id
            const data = {
                group_name: position['group_name'],
                title: position['title'],
                max_basic_salary_per_month: position['max_basic_salary_per_month'],
                max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
                max_grade_range: position['max_grade_range'],
                contact_type: position['contact_type'],
                functional_area: position['functional_area'],
                sub_functional_area: position['sub_functional_area'],
                section: position['section'],
                office_type: position['office_type'],
                code: position['code'],
                location: position['location'],
                position_type: position?.position_type,
                // status: 'REQUISITION_RAISED',
                employee_id: position?.employee_id,
                remarks: position?.remarks || '',
                job_description: position?.job_description || '',
                recruiter_id: recruiterId,
                // ta_lead_id: taLead?.user_id
            }
            setLoading(true)

            axios
                .put(CREATE_POSITION_URL + position.position_id, data, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                })
                .then((res) => {
                    setLoading(false)
                    console.log('createPositionResponse: ', res.data)
                    handleClose()
                    navigate('/positions')
                })
                .catch((error) => {
                    setLoading(false)
                    displayError(error)
                })
        }

    }

    const raiseMultipleRequisitions = async () => {
        setLoading(true)

        try {
            const totalPositions = positionLocations.length
            let completedApiCalls = 0
            const recruiterId = recruiter?.user_id
            for (const positionLocData of positionLocations) {
                // let employeeId = null
                // if (employee && positionInfo === 'replacement') {
                //     employeeId = employee.user_id
                // }

                // const recruiterId = recruiter?.user_id
                const data = {
                    group_name: position['group_name'],
                    title: position['title'],
                    max_basic_salary_per_month: position['max_basic_salary_per_month'],
                    max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
                    max_grade_range: position['max_grade_range'],
                    contact_type: position['contact_type'],
                    functional_area: position['functional_area'],
                    sub_functional_area: position['sub_functional_area'],
                    section: position['section'],
                    office_type: positionLocData['officeType'],
                    code: positionLocData['code'],
                    location: positionLocData['location'],
                    // position_type: positionInfo,
                    // status: 'REQUISITION_RAISED',
                    // employee_id: employeeId,
                    // remarks: remarks,
                    // job_description: jobDescription,
                    recruiter_id: recruiterId,

                }

                const res = await axios.put(CREATE_POSITION_URL + positionLocData.position_id, data, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                })

                console.log(`API call for ${position.location} completed: `, res.data)
                // Increment the counter for completed API calls
                completedApiCalls++

                // Check if this is the last API call
                if (completedApiCalls === totalPositions) {
                    setLoading(false)
                    // show success popup
                    // setShowSuccess(true)
                    handleClose()
                    navigate('/positions')
                }
            }
        } catch (err) {
            setLoading(false)
            displayError(err)
        }
    }

    const downloadDocument = async (url, fileName) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const urlBlob = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = urlBlob;
            link.download = fileName; // getFileNameFromUrl(url);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('There was an error downloading the document:', error);
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
            show={true}
            onHide={handleClose}
            backdrop={true}
            backdropClassName='modal-backdrop backdrop-blur'
        >
            <div >
                <div className=' no-header-model123'>
                    <div className='modal-header no-header-model123'>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <label className='form-label fs-5 fw-bolder' style={{ color: '#F36523', marginLeft: '8px' }}>
                                {' '}
                                Requisition Opening Form
                            </label>
                        </div>

                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <img alt='' src={imagePath.ic_close}></img>
                        </div>
                    </div>
                </div>

                <div className=' d-flex flex-column '>
                    <form
                        className='col-md-12'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Group</label>
                                <label className='form-control label-style fs-5'>{position['department']}</label>
                            </div>

                            <div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Functional Area</label>
                                <label className='form-control label-style fs-5'>{position['functional_area']}</label>
                            </div>

                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Sub-Functional Area</label>
                                <label className='form-control label-style fs-5'>{position['sub_functional_area']}</label>
                            </div>
                        </div>
                        <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Section</label>
                                <label className='form-control label-style fs-5'>{position['section']}</label>
                            </div>

                            <div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Functional Designation</label>
                                <label className='form-control label-style fs-5'>{position['vacantPosition']}</label>
                            </div>

                            <div className='form-group col-md-4'>
                                <label className='form-label labelse '>Maximum Grade Range</label>
                                <label className='form-control label-style fs-5'>{position['max_grade_range']}</label>
                            </div>
                        </div>
                        <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>

                            {!position?.batch_id && (<div className='form-group col-md-4'>
                                <label className='form-label labelse '>No Of Positions</label>
                                <label className='form-control label-style fs-5'>{position['no_of_positions']}</label>
                            </div>)}

                            {!position?.batch_id && (<div className='form-group col-md-4 px-4'>
                                <label className='form-label labelse '>Location</label>
                                <label className='form-control label-style fs-5'>{position['location']}</label>
                            </div>)}

                        </div>
                        <div className='col-md-11 mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-8'> Job Description</label>
                            <div  >
                                {ReactHtmlParser(position?.job_description || "")}
                            </div>
                        </div>
                        <div className='col-md-11 d-flex justify-content-spacearound mt-6 p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <div className='d-flex flex-column col-md-5'>
                                <label className='form-label labelse mb-8'> Mode of Job Advertisement</label>
                                {advertisementMode ? Object.keys(advertisementMode).map((key, index) => (
                                    advertisementMode[key] && (
                                        <div key={index} className='d-flex align-items-center mb-4'>
                                            <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                            <label className='fs-5 fw-semibold ms-4 '>{`${key === 'internal' ? 'Internal' : (key === 'rozee' ? 'Rozee.pk' : (key === 'bop' ? 'BOP Career Portal' : 'Other'))}`}</label>
                                        </div>
                                    )
                                )) : (<div></div>)}
                            </div>
                            <div>
                                <label className='form-label labelse mb-8'> Details of Hiring</label>
                                {position?.position_type === 'budgeted' ? (
                                    <div className='form-group col-md-4 mb-4 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label className='fs-5 fw-semibold' style={{ margin: '0px 0px 0px 10px' }}>
                                            Budgeted
                                        </label>
                                    </div>) : (
                                    <div className='form-group col-md-5 d-flex align-items-center' >
                                        <img src={imagePath.ic_checkbox_orange} width={18} height={18} />
                                        <label className='fs-5 fw-semibold' style={{ margin: '0px 0px 0px 10px' }}>
                                            Replacement
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='col-md-11 mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-6'>Necessary Documents</label>
                            {(documents && documents.length > 0) ? documents.map((doc, index) => {
                                return (<div key={index} className='d-flex align-items-center col-md-12 mb-4'>
                                    <label className='form-label labelse col-md-3'>{doc?.title}</label>
                                    <button className='candi-btn2 btn fw-bolder text-white px-4 py-2'
                                        onClick={() => downloadDocument(doc?.url, doc?.title)}
                                    >Download</button>
                                </div>)
                            }) : (<div>Documents are not available</div>)}
                        </div>
                        {position?.batch_id && (
                            <div className='table-responsive col-md-11 mt-8 justify-content-spacearound'>
                                <table className='table custom-table' style={{ borderRadius: '10px' }}>
                                    <thead style={{ borderRadius: '10px' }}>
                                        <tr className='table-header' style={{ borderRadius: '10px' }}>
                                            <th scope='col' className=' form-label fw-bolder fs-6 ps-4'>
                                                Position
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Code
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Region
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                Location
                                            </th>
                                            <th scope='col' className=' form-label fw-bolder fs-6'>
                                                No. of Positions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(positionLocations && positionLocations.length > 0) ?
                                            positionLocations.map((row, index) => {

                                                return (
                                                    <tr key={index}>
                                                        <td className='pt-3 ps-4'>{position?.title}</td>
                                                        <td className='pt-3'>{row?.code}</td>
                                                        <td className='pt-3'>{row?.officeType}</td>
                                                        <td className='pt-3'>{row.location}</td>
                                                        <td className='pt-3'>{row.no_of_positions}</td>
                                                    </tr>
                                                )
                                            })
                                            : (<div></div>)}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        <div className='d-flex col-md-11 mt-10'>
                            <label className='form-label labelse me-6'>{`Basic Salary Per Month: PKR ${position?.max_basic_salary_per_month ? parseInt(position?.max_basic_salary_per_month).toLocaleString() : 0
                                }`}</label>
                            <label className='form-label labelse '>{`Total Remuneration Per Month: PKR ${position?.max_total_remuneration_per_month ? parseInt(position?.max_total_remuneration_per_month).toLocaleString() : 0
                                }`}</label>
                        </div>
                        <div className='col-md-11 d-flex flex-column mt-6 justify-content-spacearound p-4' style={{ borderRadius: '8px', border: '1px #D0D5DD solid' }}>
                            <label className='form-label labelse mb-6'>Remarks/Justification</label>
                            <div >
                                <p className='fs-5 fw-semibold ' >{position?.remarks}</p>
                            </div>
                        </div>

                        <div className='col-md-11 mt-10'>
                            <div className='col-md-4 d-flex flex-column'>
                                <label className='form-label labelse'>Select Recruiter</label>

                                <input
                                    type='text'
                                    className='py-4 form-control  fs-5'
                                    placeholder='Enter Recruiter Name/Employee ID'
                                    value={recruiterQuery}
                                    onChange={handleRecruiterChange}
                                    onFocus={handleRecruiterChange}
                                    ref={recruiterRef}
                                />
                                {recruiterResults.length > 0 && (
                                    <ul className='form-control w-140px' style={{
                                        maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto',
                                        position: 'absolute',
                                        width: 'calc(30.65%)', // Adjust padding and border width if necessary
                                        top: '90%',
                                        left: '4.1%',
                                        zIndex: 10,
                                        border: '1px solid #ced4da', // Border color for consistency
                                        borderRadius: '0.25rem', // Border radius for consistency
                                        backgroundColor: '#fff', // Background color for consistency
                                    }}>
                                        {recruiterResults.map((item, index) => (
                                            <li
                                                key={index}
                                                onClick={handleRecruiterClick(item)}
                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                className='hover-li'
                                            >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                    </form>
                </div>
                <div className='modal-body py-lg-8 px-lg-8'>
                    <form>
                        <div className='btn-container'>
                            <button
                                onClick={handleSubmit}
                                type='button'
                                className='btn px-10 apply-review-button text-white'
                            // disabled={(positionInfo === 'replacement' && !employee)}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                {loading && <LoaderView message='Loading...' />}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </div>
        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default RequisitionTaLeadFormPopup;
