import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyleDoc.css'
import { useState } from 'react'
import addcompetenctimage from './img/22 [Converted]@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EditIcon from './img/editableIcon.png';
import { orange } from '@mui/material/colors'
import EdiText from 'react-editext'
import React from 'react'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
export const REQUEST_ALL_DOCUMENT_URL = `${API_URL}/document/create`


type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  profile_pic: string
}

type InputProps = {
  doc_name?: string,
  doc_status?: string
}
const modalsRoot = document.getElementById('root-modals') || document.body



const RequestDocumentPopup = ({ show, name, jobapp_id, profile_pic, handleClose }: Props) => {

  const [val, setVal] = useState([]);
  const [formData, setFormData] = useState<InputProps>()
  const [Data, setData] = useState([])
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const { auth } = useAuth()
  const [fstate, setFState] = React.useState("")

  let onSave = val => {
    console.log('Edited Value -> ', val)
  }
  function handleChange(evnt) {
    console.log("value - ", evnt.target.value);
  }
  console.log("dfsdf", fstate)
  console.log('type', typeof (fstate))

  const handleAdd = (e) => {
    e.preventDefault();
    let abc: any = [...val, []]
    setVal(abc)
  }



  const handleSubmits = async (e) => {
    e.preventDefault();

    await axios.post(
      REQUEST_ALL_DOCUMENT_URL,
      {
        document: [{
          jobapp_id: jobapp_id,
          doc_status: "requested",
          doc_name: "cv",
        },
        {
          jobapp_id: jobapp_id,
          doc_status: "requested",
          doc_name: "id card",
        },
        {
          jobapp_id: jobapp_id,
          doc_status: "requested",
          doc_name: "internship certificate",
        },
        {
          jobapp_id: jobapp_id,
          doc_status: "requested",
          doc_name: "internship certificate",
        }
        ]


      }

    )
    console.log('this works!')

    handleClose()
  }
  const handleSendSelection = (event) => {
    let doc_name = event.target.name;
    setFormData({ ...formData, doc_name })




  }

  console.log('formData', formData)


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-doc-header'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' className='doc-img' src={addcompetenctimage}></img>
          <h3 className='req-doc-text'>
            Request for Documents
          </h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <div className='req-doc-add-btn'>
              <label className='doc-req-txt'>Requirements</label>

              <button onClick={handleAdd}
                style={{ 'border': 'none', 'backgroundColor': 'transparent' }}
              ><img src={EditIcon} /></button>

            </div>

            <form>
              <div className='sureunsure'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    sx={{
                      color: orange,
                      '&.Mui-checked': {
                        color: orange[600],
                      },
                    }}
                    name="doc"
                    value="Id card"
                  />} label="Soft Copy of ID Card" />

                  <FormControlLabel control={<Checkbox
                    sx={{
                      color: orange,
                      '&.Mui-checked': {
                        color: orange[600],
                      },
                    }}
                    name="doc1"
                    value='educational documents'
                  />} label="Copy of Education Documents (Transcripts / Degrees)" />

                  <FormControlLabel control={<Checkbox
                    sx={{
                      color: orange,
                      '&.Mui-checked': {
                        color: orange[600],
                      },
                    }}
                    name="doc2"
                    value='intership certificate'
                  />} label="Copies of Any previous work / internship certificates" />


                  {val.map((data, i) => {
                    return (
                      <div>
                        <FormControlLabel control={<Checkbox
                        sx={{
                          color: orange,
                          '&.Mui-checked': {
                            color: orange[600],
                          },
                        }}
                        value= {fstate}
                        name="doc3"
                        onChange={(evnt)=>handleChange(evnt)}
                      />} label="Type here" contentEditable
                        suppressContentEditableWarning={true}
                        color='#FF8750'
                      />
                        
                        


                      </div>
                    )
                  })}

                </FormGroup>
              </div>
              <div className='req-btn-cont'>
                <button className='req-btn' onClick={(e) => handleSubmits(e)}>Request</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { RequestDocumentPopup }
