import { FC, useState, useEffect } from 'react'
import './cards.css'
import calendar from '../images/today_black_24dp.png'
import axios from 'axios'
import leadership from '../images/leadership@2x.png'
import skills from '../images/skill.png'
import QuestionIcon from '../images/help@2x.png'
import TimerIcon from '../images/timer_black_24dp@2x.png'
import { DeleteTest } from '../../modals/create-app-stepper/DeleteTest'
import UpdateTest from '../../modals/JobFunnelsModels/UpdateTest'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { getFormattedDate } from '../../../../constants/CommonFunctions'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../toasts/ShowError'
import CustomPagination from '../../../../app/modules/dashboards/components/CustomPagination'
import CreateNewTest from '../../../../app/modules/updatedTest/CreateNewTest'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_TEST = `${API_URL}/test`
export const GET_TEST_BY_ID = `${API_URL}/test`
export const GET_ACTIVE_TESTS = `${API_URL}/job/total-active-test`

type Props = {
  showCreateTestBtn: () => void
  updateTotalTestsCount: (total) => void
}

const TestCard: FC<Props> = ({ showCreateTestBtn, updateTotalTestsCount }) => {
  const { auth, currentUser, logout } = useAuth()
  const [CardClickedState, setCardClicked] = useState(-1)
  const [AllTests, setAllTests] = useState<any>([])
  const [showUpdateCompetecnyScreen, setshowUpdateCompetecnyScreen] = useState<boolean>(false)
  const [popup, setpopup] = useState(<></>)
  const [popup2, setpopup2] = useState(<></>)
  const [questionData, setQuestionData] = useState<any>([])
  const [jobName, setJobName] = useState<any>('')
  const [testName, setTestName] = useState<any>('')
  const [testData, setTestData] = useState<any>([])
  const [showUpdatePopup, setShowUpdatePopup] = useState(false)
  const [currentTestId, setCurrentTestId] = useState('')
  const [currentTest, setCurrentTest] = useState<any>();
  const [totalActiveTests, setTotalActiveTests] = useState(0)
  const [totalJobsWithTests, setTotalJobsWithTests] = useState(0)
  const [activeTests, setActiveTests] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [loadingTable, setLoadingTable] = useState(false)
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })

  const createPopup = (id) => {
    setpopup(
      <DeleteTest
        show={true}
        id={id}
        handleClose={() => {
          setpopup(<></>)
          GetAllTests()
        }}
      />
    )
  }

  // const createPopup2 = (value) => {
  //   <JobFunnelingMainScreen />
  // }

  var GetAllTestsFromDB
  var TotalTests = 0
  var PostedTests = []

  const GetAllTests = async () => {
    await axios
      .get(GET_ALL_TEST, {
        params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        setLoadingTable(false)
        let pagination = resp?.data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          // setCurrentPage(pagination?.page || 1)
          setTotalPages(pagination?.totalPages || 0)
          updateTotalTestsCount(pagination?.total)
        }
        let tests = resp.data.data
        if (tests) {
          setAllTests(tests)
          // updateTotalTestsCount(tests.length)
        }
      })
      .catch((error) => {
        setLoadingTable(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getActiveTests = async () => {
    axios
      .get(GET_ACTIVE_TESTS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data
        setTotalActiveTests(data?.totalActiveTests)
        setTotalJobsWithTests(data?.totalJobsWithTests)
        setActiveTests(data?.tests)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    ; (async () => {
      GetAllTests()
      getActiveTests()
    })()
  }, [params])

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.page = 1
    data.limit = event.target.value
    setParams(data)
  }

  const getTotalQuestions = (questions) => {
    let count = 0;
    if (questions !== null && questions !== undefined && questions.length > 0) {
      questions.forEach(subList => {
        if (subList !== null && subList !== undefined && subList.length > 0) {
          count += subList.length;
        }
      });
    }
    return count;
  }

  return (
    <>
      <div className='test-component-main-cont'>
        <div className=' g-xl-9 col-md-9'>
          <div className='row px-8' style={{ minHeight: '65vh' }}>
            {AllTests ? (
              AllTests.map((data, indexmain) => {
                const totalQuestions = getTotalQuestions(data?.questions);
                return (
                  <div key={indexmain} className='col-md-12 col-xl-6 '>
                    <div className='card-bg shadow-sm mb-8 me-2'>
                      <div className='d-flex align-items-center pt-4 px-4'>
                        <div className=' card-toolbar'>
                          <div className='d-flex align-items-center'>
                            <img src={imagePath.ic_calendar_orange} height={25} width={24} />
                            <label className=' fs-8 fw-regular ms-2 light-txt'>{getFormattedDate(data?.created_at)}</label>
                          </div>
                        </div>

                        <div className='d-flex align-items-center ms-auto'>
                          <img
                            className='cursor-pointer me-2'
                            onClick={() => {
                              setCurrentTestId(data.test_id)
                              setCurrentTest(data);
                              setShowUpdatePopup(true)
                            }}
                            src={imagePath.ic_edit_circle_orange}
                            width={20}
                            height={20}
                          />
                          <img
                            className='cursor-pointer'
                            onClick={() => createPopup(data?.test_id)}
                            src={imagePath.ic_delete_orange_cross}
                            width={18}
                            height={18}
                          />
                        </div>
                      </div>

                      <div className=' m-0 px-4' style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className='symbol  '>
                          <img src={leadership} alt='card2' className='header-image-grp-com' />
                        </div>
                      </div>

                      <div className='px-4' style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                        <span className='card-label cardlabelcompetenctgrp '>{data?.name}</span>
                      </div>

                      <div className='px-4' style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '2rem' }}>
                        <span
                          // style={{
                          //   color: '#80808F',
                          //   backgroundColor: '#F3F6F9',
                          //   padding: '10px',
                          //   cursor: 'pointer',
                          //   fontWeight: 'bold'
                          // }}
                          className='orange-tag py-2 px-3'
                        >
                          <span>
                            <img src={imagePath.ic_timer_orange} style={{ height: '3vh' }}></img>
                          </span>
                          &nbsp; {data?.test_time}
                        </span>
                      </div>

                      <div className='test-card-lower-containers px-4 pb-4'>
                        <div className='test-card-lower-continue me-4'>
                          <span className='card-label'>{data?.competencies?.length}</span>
                          <span
                            style={{
                              color: '#80808F',
                              padding: '10px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                          >
                            <img src={skills}></img>
                            &nbsp; Competency Groups
                          </span>
                        </div>

                        <div className='test-card-lower-continue'>
                          {/* <span className='card-label'>{data.questions.length}</span> */}
                          <span className='card-label'>{totalQuestions}</span>
                          <span
                            style={{
                              color: '#80808F',
                              padding: '10px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                          >
                            <img src={QuestionIcon} style={{ height: '3vh' }} />
                            &nbsp; Total Questions
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div></div>
            )}
          </div>
          {(AllTests && AllTests.length > 0) && (<div className='d-flex col-md-12 align-items-center px-8'>
            <div className='col-md-4'>
              <label>{`showing ${AllTests ? AllTests.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>)}
        </div>

        <div className='col-md-3'>
          <div
            className='card-bg shadow-sm'
            style={{
              minHeight: '65vh',
              // , maxHeight:'120vh', overflow:'auto'
            }}
          >
            <div className='card-side-test-comp-header pb-10'>
              <label className='card-side-test-comp-header-text'>Active Tests</label>
              <label className='card-side-test-comp-header-text-inner'>
                {totalActiveTests} Tests are currently active in {totalJobsWithTests} Job Posts
              </label>
            </div>

            <div className='mt-4' style={{ maxHeight: '36rem', overflow: 'auto' }}>
              {activeTests.length > 0 ? (
                activeTests.map((test, index) => (
                  <div className='card-side-test-comp-tile-cont ' key={index}>
                    <div className='card-bg shadow-sm' style={{ marginTop: '2rem' }}>
                      <div className='card-side-test-comp-inner-cont px-2'>
                        <div>
                          <label className='card-label-cardlabelcompetenctgrp1 mx-auto'>{test?.testName}</label>
                        </div>

                        <div>
                          <label className='card-side-test-comp-inner-cont-active mt-2'>Active In: </label>
                        </div>
                        {test?.jobTitles ? (
                          test?.jobTitles.map((title, titleIndex) => (
                            <div className='card-side-test-comp-inner-cont-tile mb-2'>
                              <label className='card-side-test-comp-inner-cont-tile-text'>{title}</label>
                            </div>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showUpdatePopup && (
        // <UpdateTest
        //   show={true}
        //   handleClose={() => {
        //     setShowUpdatePopup(false)
        //     setCurrentTestId('')
        //     showCreateTestBtn()
        //   }}
        //   updateTestList={GetAllTests}
        //   testId={currentTestId}
        // />
        <CreateNewTest
          type={'update'}
          testData={currentTest}
          fetchAllTests={() => {
            setShowUpdatePopup(false);
            GetAllTests();
          }}
          handleClose={() => {
            setShowUpdatePopup(false)
            // GetAllTests();
          }}
        />
      )}
      {popup}

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export { TestCard }
