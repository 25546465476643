// Context/LoginContext
import React, {useState, createContext} from 'react'

interface LablesInsightsProps {
  children: React.ReactNode
}
export const CreateLablesInsights = createContext({
  InsightData: '',
  setInsightData: (InsightData) => {},
})
export const LabelInsightsContext = ({children}: LablesInsightsProps) => {
  const [InsightData, setInsightData] = useState('')
  return (
    <CreateLablesInsights.Provider value={{InsightData, setInsightData}}>
      {children}
    </CreateLablesInsights.Provider>
  )
}
