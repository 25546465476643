//import liraries
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import './ModelStyless.css'
import imagePath from '../../../../constants/imagePath';
import interviewheadericon from '../images/interview@2x copy.png'
import UserIcon from './img/Group 9681.png'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import axios from 'axios';
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import ShowError from '../../content/toasts/ShowError';
import LoaderView from './LoaderView';

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_USERS_URL = `${API_URL}/auth/user-list`;
const GET_PANEL_INFO_URL = `${API_URL}/panel/get`;
const CREATE_PANEL_URL = `${API_URL}/panel/create-multiple`;
const REMOVE_PANEL_MEMBER_URL = `${API_URL}/panel/delete-panel-member`;

type Props = {
    show: boolean
    candidateId: string
    jobAppId: string
    handleClose: () => void
    handleSuccess: () => void
}

interface Recruiter {
    user_id: string;
    name: string;
    email: string;
    currentTarget: string

}

// create a component
const AddPanelMemberPopup = ({ show, candidateId, jobAppId, handleClose, handleSuccess }: Props) => {
    const { currentUser, auth, logout } = useAuth()
    const [allUsers, setAllUsers] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [searchKey, setSearchKey] = useState('');
    const [searchRecord, setsearchRecord] = useState<Recruiter[]>([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [selectedPanelMembers, setSelectedPanelMembers] = useState<any>([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const [candidate, setCandidate] = useState<any>();
    const [oldPanelMembers, setOldPanelMembers] = useState<any>();
    const [jobPoster, setJobPoster] = useState<any>();
    const [jobAppList, setJobAppList] = useState<any>();
    const [newStage, setNewStage] = useState('');

    useEffect(() => {

        (async () => {
            getPanelInfo();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // getPanelInfo();
            await getAllUsers();
        })();
    }, [searchKey]);

    useEffect(() => {
        (() => {
            if (oldPanelMembers && allUsers && selectedPanelMembers.length === 0) {
                let oldPanel: any = []
                oldPanelMembers.map((panelMember, index) => {
                    allUsers.map((user, userIndex) => {
                        if (user.user_id === panelMember?.member_id) {
                            // oldPanel.push(user);
                            // Create a new object combining properties from both panelMember and user
                            const combinedObject = {
                                ...user,
                                panelMember,
                                // "members": "old"
                            };
                            console.log('combinedObject: ', combinedObject)
                            oldPanel.push(combinedObject);
                        }
                    })
                })
                setSelectedPanelMembers(oldPanel);

            }
        })()
    }, [oldPanelMembers, allUsers])

    const getPanelInfo = async () => {
        await axios.get(GET_PANEL_INFO_URL, {
            params: { jobapp_list: jobAppId },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res?.data?.data;
            if (data !== null && data !== undefined && data.length > 0) {
                // Separate objects based on member_type
                const candidateList = data.filter(item => item.member_type === 'candidate');
                // Remove duplicate objects from candidateList
                const uniqueCandidateList = candidateList.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.member_id === item.member_id
                    ))
                );
                // Find the candidate object from uniqueCandidateList
                const candidateObject = uniqueCandidateList.find(candidate => candidate.member_id === candidateId);
                const panelList = data.filter(item => item.member_type === 'panel');
                // Fetch single object with member_type 'job-poster'
                const jobPosterInfo = data.find(item => item.member_type === 'job-poster');
                setCandidate(candidateObject);
                setOldPanelMembers(panelList);
                setJobPoster(jobPosterInfo);
                if (uniqueCandidateList !== null && uniqueCandidateList !== undefined && uniqueCandidateList.length > 0) {
                    setJobAppList(uniqueCandidateList[0].jobapp_list)
                    setNewStage(uniqueCandidateList[0].name);
                }
            }

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                logout()
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        })
    }

    const getAllUsers = async () => {
        await axios.get(GET_ALL_USERS_URL, {
            params: {
                role: 'all',
                limit: 'all',
                search: searchKey
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let users = res?.data?.data;
            if (!searchKey) {
                setAllUsers(users);
            }
            if (searchKey) {
                setsearchRecord(users);
            }
        }).catch((err) => {
            console.error('err: ', err);
        })
    }

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        if (!searchTerm) {
            setSearchKey('');
            setsearchRecord([]);
        } else {
            if (searchTerm.length >= 3) {
                setSearchKey(searchTerm);
            }

            // const filtered = allUsers?.filter((user) => {
            //     if (!user) {
            //         return false;
            //     }
            //     const name = user['first_name']?.toLowerCase();
            //     const id = user['employee_id']?.toLowerCase();
            //     return name?.includes(searchTerm) || id?.includes(searchTerm);
            // });
            // setsearchRecord(filtered);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex < searchRecord.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        }
    };

    const handleInterviewerSelect = (interviewer) => {
        setSearchTerm('');
        const userId = interviewer.user_id;
        const index = allUsers.findIndex((obj) => obj.user_id === userId);

        if (selectedPanelMembers.length >= 5) {
            setMessage("Can't select panelists more than 5.");
            setShowErrorMessage(true);
            setSearchKey('');
            setSearchTerm('');
            setsearchRecord([]);
            return;
        }
        // Check if the interviewer is already in selectedPanelMembers
        const isAlreadySelected = selectedPanelMembers.some((member) => member.user_id === userId);
        if (!isAlreadySelected) {
            // If not already selected, add the interviewer to the list
            const updatedPanelMembers = [...selectedPanelMembers, interviewer];
            setSelectedPanelMembers(updatedPanelMembers);
        }
        setSearchTerm('');
        setsearchRecord([]);

    };

    const removeInterviewer = (event, index) => {
        event.preventDefault();

        // Create a copy of the selectedPanelMembers array
        const updatedPanelMembers = [...selectedPanelMembers];
        let member = updatedPanelMembers[index];
        if (member.panelMember) {
            let data = {
                jobapp_id: jobAppId,
                name: newStage,
                member_id: member?.user_id
            }
            console.log('memberInfo: ', data);
            // call API to remove panel member
            setLoading(true);
            axios.post(REMOVE_PANEL_MEMBER_URL, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                setLoading(false);
                // Use splice to remove the user at the specified index
                updatedPanelMembers.splice(index, 1);
                // Update the state with the modified array
                setSelectedPanelMembers(updatedPanelMembers);

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    setMessage(error.response.data.message)
                    setShowErrorMessage(true)
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.name === 'TokenExpiredError'
                ) {
                    logout()
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout()
                    } else {
                        setMessage(error.response.data.error.message)
                        setShowErrorMessage(true)
                    }
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            });
        } else {
            // Use splice to remove the user at the specified index
            updatedPanelMembers.splice(index, 1);
            // Update the state with the modified array
            setSelectedPanelMembers(updatedPanelMembers);
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('update panel members');
        let panel: any = [];

        if (!candidate) {
            setMessage('Candidate not available, Please select a candidate first and try again.');
            setShowErrorMessage(true);
            return;
        }
        if (!selectedPanelMembers || selectedPanelMembers.length <= 0) {
            setMessage('Please select one or more Panel Members first and try again.');
            setShowErrorMessage(true);
            return;
        }

        // let candidateAvailability = 'false';
        // if (candidate?.availability !== null && candidate?.availability !== undefined) {
        //     candidateAvailability = candidate?.availability === true ? 'true' : 'false';
        // }
        // let candidateMemberInfo = {
        //     // jobapp_list: jobAppList ? JSON.parse(jobAppList) : [],
        //     jobapp_list: [jobAppId],
        //     name: candidate?.name,
        //     member_id: candidate?.member_id,
        //     email: candidate?.email,
        //     index: '0',
        //     availability: candidateAvailability,
        //     member_type: 'candidate',
        // }
        // panel.push(candidateMemberInfo)

        selectedPanelMembers?.map((panelMember) => {
            let member = panelMember?.panelMember
            let availability = 'false';
            if (member?.availability !== null && member?.availability !== undefined) {
                availability = member?.availability === true ? 'true' : 'false';
            }
            let memberInfo = {
                // jobapp_list: jobAppList ? JSON.parse(jobAppList) : [],
                jobapp_list: [jobAppId],
                name: newStage,
                member_id: panelMember?.user_id,
                email: panelMember?.email,
                index: '0',
                availability: availability,
                member_type: 'panel',
            }
            panel.push(memberInfo)
        });

        // adding current recruiter as job  poster
        // let availability = 'false';
        // if (jobPoster?.availability !== null && jobPoster?.availability !== undefined) {
        //     availability = jobPoster?.availability === true ? 'true' : 'false';
        // }
        // let memberInfo = {
        //     // jobapp_list: jobAppList ? JSON.parse(jobAppList) : [],
        //     jobapp_list: [jobAppId],
        //     name: jobPoster?.name,
        //     member_id: jobPoster?.member_id,
        //     email: jobPoster?.email,
        //     index: '0',
        //     availability: availability,
        //     member_type: 'job-poster',
        // }
        // panel.push(memberInfo)

        setLoading(true);
        let data = {
            // jobapp_list: jobAppList ? JSON.parse(jobAppList) : [],
            jobapp_list: [jobAppId],
            getStage: newStage,
            "members": "New",
            panel: panel
        };

        await axios.post(CREATE_PANEL_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            // sendEmails();

            handleSuccess();

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });

    }

    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            // width='104% !important'
            dialogClassName='modal-dialog modal-dialog-centered mw-700px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <div >
                <div className='d-flex '>
                    <div></div>
                    <img className='ms-auto cursor-pointer me-4 mt-4' src={imagePath.ic_close}
                        onClick={handleClose} />
                </div>
                <div className='modal-header no-header-model123 d-flex'>
                    <div
                        className='mx-auto'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={interviewheadericon} className='interview-header-icon'
                            style={{ height: '5vh' }}
                        />
                        <label className='fs-4 fw-bolder '>Update Panel Members</label>
                    </div>
                </div>
                <form
                    // onSubmit={handleSubmit}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                >
                    <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>

                        <div className='Input-Field-Container'>

                            <div>
                                <div className='upper-input-container'>
                                    <div className='d-flex '>
                                        <img src={imagePath.ic_panel_members} width={48} height={48} className=' me-10' />
                                        <div className='mb-8' style={{ position: 'relative' }}>
                                            <Box sx={{ width: 462 }}>
                                                <FormControl fullWidth>
                                                    <div style={{ overflow: 'hidden' }}>
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Interview Panelist Name/ Employee ID"
                                                            className="request-approval-final-step-input-field"
                                                            value={searchTerm}
                                                            onChange={handleSearch}
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                        {searchRecord.length > 0 ? (
                                                            <ul
                                                                style={{
                                                                    border: '1px solid lightgrey',
                                                                    padding: '10px',
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    gap: '4px',
                                                                    zIndex: '2',
                                                                    backgroundColor: 'white',
                                                                    position: 'absolute',
                                                                    top: '100%',
                                                                    left: 0,
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {searchRecord.map((interviewer, index) => (
                                                                    <li
                                                                        key={index}
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            gap: '10px',
                                                                            alignItems: 'center',
                                                                            cursor: 'pointer',
                                                                            backgroundColor: highlightedIndex === index ? '#FFEBCE' : 'initial',
                                                                            padding: '5px',
                                                                            transition: 'background-color 0.3s',
                                                                        }}
                                                                        onClick={() => handleInterviewerSelect(interviewer)}
                                                                        onMouseEnter={(e) => {
                                                                            (e.target as HTMLElement).style.backgroundColor = '#FFEBCE';
                                                                        }}
                                                                        onMouseLeave={(e) => {
                                                                            (e.target as HTMLElement).style.backgroundColor = highlightedIndex === index ? '#FFEBCE' : 'initial';
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={interviewer['profile_pic'] !== null ? interviewer['profile_pic'] : UserIcon}
                                                                            className="selected-interviewer-icon-sched"
                                                                            alt=""
                                                                        />
                                                                        {`${interviewer['first_name']} - ${interviewer['employee_id']}`}
                                                                        <hr />
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            searchTerm !== '' && <p>No results found.</p>
                                                        )}
                                                    </div>
                                                </FormControl>
                                            </Box>
                                            <label className='form-label fs-7 fw-bolder' style={{ color: '#F64E60' }}>Max 5 panelists can be selected per panel</label>
                                            <div className="user-tile mb-4">
                                                {selectedPanelMembers ? (
                                                    selectedPanelMembers.map((option, index) => {
                                                        let member = option?.panelMember;

                                                        return (
                                                            <label className="user-tile-non-content d-flex align-items-center" key={index} style={{ borderRadius: '4px' }}>
                                                                <img
                                                                    src={option.profile_pic !== null ? option.profile_pic : UserIcon}
                                                                    className="selected-interviewer-icon-sched me-2"
                                                                    alt=""
                                                                />
                                                                {`${option.first_name} - ${option.employee_id}`}
                                                                <span>
                                                                    <img
                                                                        className='cursor-pointer ms-2'
                                                                        alt=""
                                                                        src={imagePath.ic_cross_orange}
                                                                        onClick={(event) => removeInterviewer(event, index)}
                                                                        id={JSON.stringify(option)}
                                                                    ></img>
                                                                </span>
                                                            </label>
                                                        )
                                                    })
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div className='d-flex flex-1 mt-8'>
                                                <button className='mx-auto btn candi-btn2 text-white fw-bold fs-5'
                                                    onClick={handleSubmit}>
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
                {loading && (<LoaderView message='Loading...' />)}
            </div>

        </Modal>
    );
};

//make this component available to the app
export default AddPanelMemberPopup;
