import {useState, useEffect} from 'react'
import axios from 'axios'
import {useAuth} from '../../auth/core/Auth'
import '../Competencies.css'
import {IndividualCompetenciesCard} from '../../../../_metronic/partials/content/cards/IndividualCompetenciesCard'
import IndividualCompetency from '../../../../_metronic/partials/content/cards/IndividualCompetency'
import {UpdateIndividualCompetency} from '../../../../_metronic/partials/modals/create-app-stepper/UpdateIndividualCompetency'
import {CreateIndiviualComtency} from '../../../../_metronic/partials/modals/create-app-stepper/CreateIndiviualComtency'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get?type=Competency`
const DELETE_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency`

function CompetenciesIndividual() {
  const {auth} = useAuth()

  const [showCreateCompetency, setShowCreateCompetency] = useState(false)
  const [loading, setLoading] = useState(false)
  const [CompData, setCompData] = useState([])

  const GetAllIndividualCompetenciesCardData = async () => {
    try {
      const resp = await axios.get(GET_ALL_INDIVIDUAL_COMPETENCY_URL)
      let allCompetencies = resp.data.competencies
      let individualCompetencies: any = []
      if (allCompetencies !== null && allCompetencies !== undefined) {
        allCompetencies = allCompetencies.filter((obj) => obj !== null && obj !== undefined)
        if (allCompetencies.length > 0) {
          for (let i = 0; i < allCompetencies.length; i++) {
            const competency = allCompetencies[i]
            let type = competency.type
            if (type === 'Individual') {
              individualCompetencies.push(competency)
            }
          }
        }
      }
      if (individualCompetencies) {
        setCompData(individualCompetencies)
      }
      // setCompData(resp.data)
    } catch (error) {
      console.error('Error fetching individual competencies data:', error)
    }
  }

  useEffect(() => {
    GetAllIndividualCompetenciesCardData()
  }, [])

  const [competency, setCompetency] = useState({
    competency_id: '',
    name: '',
    behaviors: '',
    description: '',
    level: '',
    total_competencies: '',
  })
  const [updatePopup, setUpdatePopup] = useState(false)

  function updateCompetency(index, competency) {
    // show update competency popup
    console.log('selectedCompetencyData: ', competency)
    setCompetency(competency)
    setUpdatePopup(true)
  }

  async function deleteCompetency(index, competency) {
    // delete competency and remove deleted competency from list
    setLoading(true)
    let value = competency.competency_id
    try {
      const url = `${DELETE_INDIVIDUAL_COMPETENCY_URL}/${value}`
      const data = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      console.log('competency deleted: ', data)
      const updatedItems = CompData.filter((item) => item['competency_id'] !== value)
      if (updatedItems) {
        setCompData(updatedItems)
      }
    } catch (err) {
      console.log('ERROR', err)
    } finally {
      setLoading(false)
    }
  }

  function updateList(id, name, description, levelsData) {
    let arr: any = [...CompData]
    for (let i = 0; i < arr.length; i++) {
      const data = arr[i]
      if (data.competency_id === id) {
        data.name = name
        data.description = description
        data.behaviors = JSON.stringify(levelsData)
        arr[i] = data
      }
    }
    setCompData(arr)
  }

  const openCreateCompetencyPopup = () => {
    setShowCreateCompetency(true)
  }

  return (
    <>
      <div className='row margin-top-for-individual-jobs g-5 g-xl-8'>
        <div className='d-flex flex-wrap flex-stack mb-6 align-items-center'>
          <h3 className='fw-bolder my-1'>Individual Competencies ({CompData.length})</h3>
          <div className='candi-btn2 text-white fw-bolder fs-5 ms-auto' onClick={() => openCreateCompetencyPopup()}>
            Create New Competency
          </div>
        </div>

        <div className='d-flex overflow-auto nowrap flex-wrap justify-content-center'>
          {CompData ? (
            CompData.map((data, index) => (
              <IndividualCompetency
                competency={data}
                index={index}
                updateCompetency={updateCompetency}
                deleteCompetency={deleteCompetency}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {showCreateCompetency && (
        <CreateIndiviualComtency
          show={true}
          handleClose={() => {
            setShowCreateCompetency(false)
          }}
          updateCompetencies={GetAllIndividualCompetenciesCardData}
        />
      )}

      {updatePopup && competency && (
        <UpdateIndividualCompetency
          show={true}
          id={competency.competency_id}
          name={competency.name}
          behaviours={competency.behaviors}
          description={competency.description}
          level={competency.level}
          handleClose={(id, name, description, levelsData) => {
            console.log('updatedCompetencyId: ', id)
            updateList(id, name, description, levelsData)
            setUpdatePopup(false)
          }}
          TotalIndivualCompetencies={() => competency.total_competencies}
        />
      )}
      {loading && <LoaderView message='Loading...' />}
    </>
  )
}

export default CompetenciesIndividual
