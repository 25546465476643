import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import { useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { ScheduleInterviewPopUpStage2 } from './ScheduleInterciewStage2'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  email: string
  user_id: string
  profilePic: string
  position: string
  bexp: string
  jexp: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserApplicationInterview = ({ show, name, jobapp_id, email, user_id, handleClose }: Props) => {
  const { auth } = useAuth()
  const [popup, setpopup] = useState(<></>)



  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   await axios.post(
  //     ACCEPT_JOB_URL,
  //     {
  //       jobapp_id: jobapp_id,
  //       currentStage: 'Stage1',
  //       newStage: 'Stage2',
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   )

  //   handleClose()
  // }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await handleClose()
    // createPopup(name,jobapp_id, email, user_id)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div>
        <div className=' no-header-model123'>
          <div className='modal-header no-header-model123'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={Layout}></img>
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column '>
          <img alt='' src={addcompetenctimage} style={{ height: '15vh', width: '15vh', marginLeft: '19rem' }} ></img>
            <div className='fw-bolder mx-auto my-5' style={{ display: 'grid', gridTemplateRows: 'auto auto', }}>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center',
                marginTop: '1rem'
              }}>{`Are you sure that you want to add `}</span>
              <span style={{
                font: 'normal normal bold 18px/29px Nunito',
                letterSpacing: '-0.02px',
                color: '#373D4A',
                opacity: 1,
                textAlign: 'center'
              }}>{`${name} to the Interview Stage 2?`}</span>
            </div>

          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <div className='card'>
            {/* begin::Beader */}

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <form
              //  onSubmit={handleSubmit}
              >
                {/* <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label>
              </div> */}
                <div className='sureunsure' style = {{
                  marginTop: '-3rem'
                }}>
                  <button
                    onClick={handleSubmit}
                    type='button'
                    className='btn my-10 px-10 bgcolorofbutoonofsubmit'
                  >
                    Yes, I am sure!
                  </button>
                  {popup}
                  <button onClick={handleClose} className='btn my-5 px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123  '>
                    No
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ApproveUserApplicationInterview }
