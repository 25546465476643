/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import ApplicationsTableOnlineTest from '../../../../app/modules/JobsFunneling/OnlineTest/OnlineTestNewPage'
// import {KTSVG} from '../../../helpers'
import './ModelStyles.css'
import {useEffect, useState} from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-all-job-application`

type Props = {
  show: boolean
  handleClose: () => void
  //   name: string
  jobapp_id: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveAllUserApplicationInterviewStage2 = ({show, jobapp_id, handleClose}: Props) => {
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const {auth} = useAuth()

  useEffect(() => {
    console.log('HERE NOW')
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(
      ACCEPT_JOB_URL,
      {
        job_id: jobapp_id,
        currentStage: 'Stage2',
        newStage: 'Stage3',
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then(

    )

    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' className='addcompetenctimage' src={addcompetenctimage}></img>
          <h3 className='fw-bolder mx-auto my-5'>All Applicants will be approved and sent to the Interview Stage 2</h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              {/* <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label>
              </div> */}
              <div className='sureunsure'>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn my-10 px-10 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '
                >
                  Yes, I am sure!
                </button>
                <button onClick={handleClose} className='btn my-5 px-5 bgcolorofbutoonofsubmit '>
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ApproveAllUserApplicationInterviewStage2}
