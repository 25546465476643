//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './reports.css'
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Chart } from 'react-google-charts';

const API_URL = process.env.REACT_APP_API_URL
const GET_ENTRY_LEVEL_ASSESSMENT_REPORT_URL = `${API_URL}/analytics/entry-level-assessment`

// create a component
const AssessmentsConductedTable = () => {
    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState(currentYear);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState<any>();
    const [reportUrl, setReportUrl] = useState('');

    useEffect(() => {
        (async () => {
            getReportData();
            getReportUrl();
        })()
    }, [year]);

    const getReportData = async () => {
        let params = {};
        if (year) {
            params['year'] = year;
        }
        setLoading(true);
        await axios.get(GET_ENTRY_LEVEL_ASSESSMENT_REPORT_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data;
            let groupReportData = data?.data;
            const formattedChartData = [
                ["Group", "Taken", "Passed", "Failed"],
            ];
            groupReportData.forEach((item) => {
                formattedChartData.push([item.group_name, item.taken, item.passed, item.failed]);
            });
            setChartData(formattedChartData);

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }
    const getReportUrl = async () => {
        let params = {};
        if (year) {
            params['year'] = year;
            params['report'] = true;
        }
        await axios.get(GET_ENTRY_LEVEL_ASSESSMENT_REPORT_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data;
            setReportUrl(data?.reportUrl);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const options = {
        chart: {
            title: "Group Performance",
        },
        bars: "vertical",
        colors: ["#F36523", "#FF9F23", "#475467"],
        hAxis: {
            // title: "Assessments",
            textPosition: "out", // Display all labels outside the chart area
            slantedText: true // Disable slanted text
        },
        vAxis: {
            title: "Total"
        },
        legend: { position: "none" },
        dataOpacity: 1,
    };

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (e) => {
        e.preventDefault();
        if (!reportUrl) {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(reportUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                // link.download = reportUrl.split('/').pop(); // Extracts file name from URL
                // Use optional chaining and nullish coalescing to handle undefined
                const filename = reportUrl.split('/').pop() ?? 'Entry Level Assessments Conducted.xlsx'; // Provide a default filename
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    return (
        <div>
            <div style={{ position: 'relative' }}>
                {loading && (
                    // Show loading icon if isLoading state is true
                    <div
                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                    >
                        <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                    </div>
                )}

                <div className='card-bg shadow-sm mb-10' >
                    <div className='d-flex align-items-center px-6 py-8'>
                        <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Entry Level Assessments Conducted</label>
                        <div className='d-flex align-items-center ms-auto'>
                            {/* legend view */}
                            <div className='d-flex align-items-center'>
                                <div style={{ backgroundColor: '#F36523', borderRadius: '4px', height: '1rem', width: '1rem' }}></div>
                                <label className='fs-7 ms-2 me-4'>Test Conducted</label>
                                <div style={{ backgroundColor: '#FF9F23', borderRadius: '4px', height: '1rem', width: '1rem' }}></div>
                                <label className='fs-7 ms-2 me-4'>Pass</label>
                                <div style={{ backgroundColor: '#475467', borderRadius: '4px', height: '1rem', width: '1rem' }}></div>
                                <label className='fs-7 ms-2'>Fail</label>
                            </div>
                            {/* year view */}
                            <div className='d-flex align-items-center mx-4'>

                                <input
                                    className='py-2 px-2 fs-5 fw-semibold'
                                    style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                                    type='number'
                                    inputMode='numeric'
                                    placeholder='2024'
                                    value={year}
                                    max={currentYear}
                                    min={2023}
                                    onChange={(event) => handleChangeYear(event)}
                                />
                            </div>
                            {/* download report view */}
                            <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={(e) => handleDownloadFile(e)}>
                                    <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                                    <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                                </div>
                            </div>

                            {/* refresh view */}
                            <div>
                                <img className='cursor-pointer ' src={imagePath.ic_refresh_orange} width={34} height={34}
                                    onClick={() => {
                                        getReportData();
                                        // getReportUrl();
                                    }} />
                            </div>
                        </div>
                    </div>

                    {(chartData && chartData.length > 0) ? (
                        <div className="chart-container" style={{ paddingBottom: '2rem' }}>
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                height="400px"
                                data={chartData}
                                options={options}
                            />
                        </div>) : (<div></div>)}

                </div>
            </div>

            {
                showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )
            }
        </div>
    );
};

//make this component available to the app
export default AssessmentsConductedTable;
