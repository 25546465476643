//import liraries
import React, { Component, useEffect, useState } from 'react';
import imagePath from '../../../../constants/imagePath';
import axios from 'axios';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import { useNavigate } from 'react-router'

const API_URL = process.env.REACT_APP_API_URL
export const ALLDATA_URL = `${API_URL}/candidate/`

// create a component
const ProfileStrengthCard = () => {
    const navigate = useNavigate()
    const { auth, currentUser, logout } = useAuth()
    const user_id = currentUser?.user_id

    const [currentStrength, setCurrentStrength] = useState(5)
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        (() => {
            getCandidateData();
        })();
    }, []);

    const getCandidateData = async () => {
        await axios.get(ALLDATA_URL + currentUser?.user_id).then((response) => {
            // console.log("CANDIADTE: ", response.data.candidate.work_experience)
            const strength = JSON.parse(response.data?.candidate?.profile_strength)
            const totalStrength = strength?.total || 5
            setCurrentStrength(totalStrength);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })
    }


    const CircularProgressBar = ({ percentage }) => {
        const size = 150;
        const textPosition = size / 2;
        // Calculate the radius of the circle
        const radius = (size - 20) / 2;
        // Calculate the circumference of the circle
        const circumference = 2 * Math.PI * radius;
        // Calculate the dash offset to represent the progress
        const dashOffset = circumference - (percentage / 100) * circumference;

        return (
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                {/* Background circle */}
                <circle cx={size / 2} cy={size / 2} r={radius} fill="transparent" stroke="#D9E7EE" strokeWidth="10" />

                {/* Progress circle */}
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    fill="transparent"
                    stroke="#F57536"
                    strokeWidth="14"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                    strokeLinecap="round"
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />

                {/* Text showing percentage */}
                <text x="50%" y={textPosition} textAnchor="middle" dominantBaseline="middle" fill="#2B2B2B" fontSize={size / 6} fontFamily='Nunito'>
                    {percentage}%
                </text>
            </svg>
        );
    };

    return (
        <div className='card-bg shadow-sm ms-4 p-6' style={{ width: '100%' }}>
            <div className='d-flex align-items-center'>
                <img src={imagePath.ic_person_holo_circle_orange} />
                <label className='ms-2 fs-5 fw-semibold'>Profile Strength</label>
            </div>
            <hr />
            <div className='d-flex align-items-center justify-content-center '>
                <CircularProgressBar percentage={currentStrength} />
            </div>

            <div className='d-flex align-items-center justify-content-center mt-8'>
                <div className='p-2 text-center cursor-pointer' style={{ border: '1px solid #E5E5E5', borderRadius: '8px', width: '7rem' }}
                    onClick={() => {
                        // goto cvcreation screen
                        navigate('/cvcreation', { state: { screen: 'education' } })
                    }}>
                    <label className='fs-6 text-center cursor-pointer'>Add<br></br>Education</label>
                </div>
                <div className='p-2 mx-8 text-center cursor-pointer' style={{ border: '1px solid #E5E5E5', borderRadius: '8px', width: '7rem' }}
                    onClick={() => {
                        // goto cvcreation screen
                        navigate('/cvcreation', { state: { screen: 'certificates' } })
                    }}>
                    <label className='fs-6 text-center cursor-pointer'>Add<br></br>Certificates</label>
                </div>
                <div className='p-2 text-center cursor-pointer' style={{ border: '1px solid #E5E5E5', borderRadius: '8px', width: '7rem' }}
                    onClick={() => {
                        // goto cvcreation screen
                        navigate('/cvcreation', { state: { screen: 'personalInfo' } })
                    }}>
                    <label className='fs-6 text-center cursor-pointer'>Add<br></br>Resume</label>
                </div>
            </div>

            {showErrorMessage && (
                <ShowError
                    message={message}
                    handleClose={() => setShowErrorMessage(false)}
                />
            )}

        </div>
    );
};

//make this component available to the app
export default ProfileStrengthCard;
