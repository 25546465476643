//import liraries
import React, {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './CreateCompetency.css'

import cross from '../images/Layout-4-blocks.png'
import bannerimage from '../../../../app/modules/Setting/images/group.png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import {RecruiterCard} from '../../content/cards/RecruiterCard'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_TA_URL = `${API_URL}/auth/user-list`
export const GET_ALL_RECRUITERS_URL = `${API_URL}/ta-ra/get-recruiters`
export const CREATE_TA_RESOURCE_URL = `${API_URL}/ta-ra/create`
const UPDATE_TA_RESOURCE_URL = `${API_URL}/ta-ra/update-ta-resource`
export const ALLOCATE_TA_RESOURCE_URL = `${API_URL}/ta-ra/resouce-allocate`

type Props = {
  show: boolean
  resource: any
  handleClose: () => void
  updateResources: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddTALeadPopup = ({show, resource, handleClose, updateResources}: Props) => {
  const [RecruitersD, setRecruitersD] = useState<any>([])
  const [Recruiter, setRecruiter] = useState<any>()
  const [Pocs, setPocs] = useState([{}])
  const [Poc, setPoc] = useState({})
  const [TAs, setTAs] = useState<any>([{}])
  const [TA, setTA] = useState<any>({})
  const [dataFetched, setDataFetched] = useState(false)
  const [recruiterCheck, setRecruiterCheck] = useState(false)
  const [taCheck, setTACheck] = useState(false)
  const {auth} = useAuth()

  const [recruiterQuery, setRecruiterQuery] = useState('')
  const [recruiterResults, setRecruiterResults] = useState<any>([])

  const [taQuery, setTAQuery] = useState('')
  const [taResults, setTAResults] = useState<any>([])
  const [recruiterSelected, setRecruiterSelected] = useState(false)
  const [taSelected, setTaSelected] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log('Handle Submit')
    //  console.log("Department",Department)
    console.log('TA', TA)
    console.log('Recruiter', Recruiter)
    let recresourceId = resource.raRow.recresource_id
    var allocationData = {
      ta_id: TA['user_id'],
      recruiter_id: Recruiter['user_id'],
      recresource_id: recresourceId,
    }
    console.log('allocationData', allocationData)
    var data = JSON.stringify(allocationData)
    let url = UPDATE_TA_RESOURCE_URL
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // update list of Recruiters
        updateResources()
      })
      .catch((err) => console.log('err', err))
    handleClose()
  }

  const GetAllTA = async () => {
    await axios
      .get(GET_ALL_TA_URL, {
        params: {
          role: 'ta_lead,none',
          limit: 5000,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        let selectedTA = resp.data.data
        selectedTA = selectedTA.filter((obj) => obj !== null)
        if (selectedTA !== null && selectedTA !== undefined && selectedTA.length > 0) {
          setTAs(selectedTA)
        }
      })
  }

  const GetAllRecruiters = async () => {
    await axios
      .get(GET_ALL_RECRUITERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        let selectedRec = resp.data.data
        selectedRec = selectedRec.filter((obj) => obj !== null)
        if (selectedRec !== null && selectedRec !== undefined && selectedRec.length > 0) {
          setRecruitersD(selectedRec)
        }
      })
  }

  const displayTADiv = (event) => {
    event.preventDefault()
    setRecruiterCheck(true)
  }
  const displaySubmitButton = (event) => {
    event.preventDefault()
    setTACheck(true)
  }

  useEffect(() => {
    console.log('resourceData: ', resource)
    if (resource) {
      setRecruiter(resource?.RecData)
      setRecruiterCheck(true)
    }
  }, [])

  useEffect(() => {
    if (!dataFetched) {
      ;(async () => {
        await GetAllRecruiters()
        await GetAllTA()
        setDataFetched(true)
      })()
    } else {
      console.log('RecruitersD ELSE', RecruitersD)
      console.log('TTTT ELSE', TAs)
    }
    return () => {}
  }, [RecruitersD, Pocs, TAs])

  // filter the list of recruiters
  const handleRecruiterChange = (event) => {
    const query = event.target.value
    setRecruiterQuery(query)
    let filteredResults = RecruitersD.filter((item) => {
      let fullNameEmployeeId = `${item['name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    console.log('filteredResults: ', filteredResults)
    setRecruiterResults(filteredResults.length > 0 ? filteredResults : RecruitersD)
  }

  const handleRecruiterClick = (item) => () => {
    setRecruiter(item)
    let query = `${item['name']}`
    setRecruiterQuery(query)
    setRecruiterResults([])
    setRecruiterSelected(true)
  }

  // filter the list of TA
  const handleTAChange = (event) => {
    const query = event.target.value
    setTAQuery(query)
    let filteredResults = TAs.filter((item) => {
      let fullNameEmployeeId = `${item['name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    console.log('filteredTAResults: ', filteredResults)
    setTAResults(filteredResults.length > 0 ? filteredResults : TAs)
  }

  const handleTAClick = (item) => () => {
    setTA(item)
    let query = `${item['name']}`
    setTAQuery(query)
    setTAResults([])
    setTaSelected(true)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      // onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model no-header-modelllad '>
        <div className='modal-header  no-header-model no-header-modelllad'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <img src={cross}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column no-header-model'>
          <img className='addcompetenctimage' src={bannerimage}></img>
          <h3 className='fw-bolder fontsetrinh text-white my-5'>Update Resources </h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form>
              <div className='form-row d-flex flex-column  mt-5 justify-content-spacearound'>
                <div className='form-group   col-md-12'>
                  <div className='me-4'>
                    {/* <label className='form-label fs-6 fw-bolder '>Recruiter Name/ Employee ID</label> */}
                    <label className='form-label fs-6 fw-bolder '>Recruiter</label>

                    {/* <input type="text" className='search-bar-style form-control   w-140px'
                                            placeholder='Enter Recruiter Name/Employee ID'
                                            value={recruiterQuery}
                                            onChange={handleRecruiterChange}
                                            onFocus={handleRecruiterChange}
                                        />
                                        {recruiterResults.length > 0 && (<ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                                            {recruiterResults.map((item, index) => (
                                                <li key={index} onClick={handleRecruiterClick(item)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} className="hover-li">{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                                            ))}
                                        </ul>)} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {recruiterCheck ? (
        <div>
          <RecruiterCard
            // avatar='/media/avatars/300-14.jpg'
            avatar={Recruiter['profile_pic']}
            name={Recruiter['name']}
            employeeId={Recruiter['employee_id']}
            email={Recruiter['email']}
            phoneNumber={Recruiter['phone_number']}
          />
          <div className='modal-body py-lg-8 px-lg-8'>
            <div className='card'>
              {/* begin::Beader */}

              <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                <form>
                  <div className='form-row d-flex flex-column  mt-5 justify-content-spacearound'>
                    <div className='form-group   col-md-12'>
                      <div className='me-4'>
                        <label className='form-label fs-6 fw-bolder '>Link TA Lead</label>

                        <input
                          type='text'
                          className='search-bar-style form-control   w-140px'
                          placeholder='Enter TA Name/Employee ID'
                          value={taQuery}
                          onChange={handleTAChange}
                          onFocus={handleTAChange}
                        />
                        {taResults.length > 0 && (
                          <ul className='form-control   w-140px' style={{maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto'}}>
                            {taResults.map((item, index) => (
                              <li
                                key={index}
                                onClick={handleTAClick(item)}
                                style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                className='hover-li'
                              >{`${item['name']} - ${item['employee_id']}`}</li>
                            ))}
                          </ul>
                        )}

                        {/* <select
                              name='status'
                              data-control='select2'
                              data-hide-search='true'
                              className='form-control'
                              defaultValue=''
                              placeholder='Select a TA'
                              // value={`${TA["first_name"]} ${TA["last_name"]}`}
                              onChange={(event) => changeTA(event)}
                            >
                              <option value='Select a TA'>Select a TA</option>
                              {TAs.length > 0 ? TAs.map((ta) => (
                                <option
                                  key={ta["user_id"]}
                                  id={ta["user_id"]}
                                  value={ta["user_id"]}>
                                  {`${ta["first_name"]} ${ta["last_name"]}`}
                                </option>
                              )) : <option></option>}
                            </select> */}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                    {taCheck ? null : (
                      <button
                        // type='submit'
                        // className='btn my-15 px-10 bgcolorofbutoonofsubmitoftheaddingnewrecruiter my-5'
                        className={
                          taSelected
                            ? 'btn my-15 px-10 btnStyle my-5'
                            : 'btn my-15 px-10 bgcolorofbutoonofsubmitoftheaddingnewrecruiter my-5'
                        }
                        onClick={(event) => displaySubmitButton(event)}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {taCheck ? (
        <div>
          <RecruiterCard
            avatar={TA['profile_pic']}
            name={TA['name']}
            employeeId={TA['employee_id']}
            email={TA['email']}
            phoneNumber={TA['phone_number']}
          />
          <div className='modal-body py-lg-8 px-lg-8'>
            <div className='card'>
              {/* begin::Beader */}

              <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                <form>
                  <div className='d-flex align-items-center justify-content-center'>
                    <button
                      // type='submit'
                      className='btn my-15 px-10 fw-bolder btnStyle my-5'
                      onClick={(event) => handleSubmit(event)}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default AddTALeadPopup
