/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../helpers'
import { CreateAppModal } from '../../../partials'
import { CreateIndiviualComtency } from '../../../partials/modals/create-app-stepper/CreateIndiviualComtency'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import Plusicon from '../images/add_white_24dp (2).png'
import { useCreateJobsContext } from '../../../../context/useCreateJobsContext'
import { useCompetenciesButtonContext } from '../../../../context/useCreateCompetenciesButton'
import { useContextForCreateTestButton } from '../../../../context/useContextForCreateTestButton'
import { useContextForCreatePositionButton } from '../../../../context/useContextForCreatePositionButton'
// import CreateJobs from '../../../../app/modules/Jobs/CreateJobs/CreateJobs'
import { Link, useNavigate } from 'react-router-dom'
import './toolbar.css'
import { useCreateGenerateButtonContext } from '../../../../context/useCreateGenerateReportContext'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

// import {useContextForCreateTestButton} from '../../../../context/useContextForCreateTestButton'
const API_URL = process.env.REACT_APP_API_URL
// export const CREATE_LEVEL = `${API_URL}/level/create/`

export const GET_CREATE_LEVEL = `${API_URL}/level/get`

// export const Delete_level = `${API_URL}/level/`

const Toolbar1 = () => {
  const {currentUser} = useAuth()
  const { classes } = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateIndiviualComtency, setCreateIndiviualComtency] = useState<boolean>(false)

  const { jobsState, setjobsState } = useCreateJobsContext()
  const { CompetenciesButton, setCompetenciesButton } = useCompetenciesButtonContext()
  const { Genbutton, setGenbutton } = useCreateGenerateButtonContext()
  const { Test, setTestButton } = useContextForCreateTestButton()
  const { Position, setPositionButton } = useContextForCreatePositionButton()
  const [items, setItems] = useState<any>([])
  // console.log('testbutton in top in akdhg useeffect ', testButton)
  useEffect(() => {
    let localUser: any = localStorage.getItem("kt-auth-react-v")
    setItems(JSON.parse(localUser))
  }, []);

  
  return (
    <>
      <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          {/* begin::Actions */}
          <div className='d-flex align-items-center py-1'>
            {/* begin::Wrapper */}
            <div className='me-4'>
              {/* begin::Menu */}
              {/* <a
              href='#'
              className='btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary fw-bold'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />
              Filter
            </a> */}

              {/* end::Menu */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Button */}

            {jobsState === true && currentUser?.role === 'recruiter' ? (
              <>
                {/* <Link to='/createjobs'> */}
                <Link to='/createjobs'>
                  <a
                    className='btn CreateIndiviualCompetency'
                  // style={{background: 'rgba(255, 255, 255, 0.24)', color: 'white'}}
                  >
                    <span>
                      <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
                    </span>
                    Post New Jobs
                  </a>
                </Link>
              </>
            ) 
            // : Genbutton === true ? (
            //   <>
            //     <Link to=''>
            //       <a
            //         className='btn CreateIndiviualCompetency'
            //       // style={{background: 'rgba(255, 255, 255, 0.24)', color: 'white'}}
            //       >
            //         Generate Reports
            //       </a>

            //     </Link>
            //   </>
            // // ) : CompetenciesButton === 'indiviualCompetency' ? (
            //   ) 
            //   : CompetenciesButton === 'individualCompetency' ? (
            //   <>
            //     <a
            //       onClick={() => setCreateIndiviualComtency(true)}
            //       className='btn  CreateIndiviualCompetency'
            //     >
            //       <span>
            //         <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
            //       </span>
            //       Create New Competency
            //     </a>
            //   </>
            // ) 
            // : CompetenciesButton === 'groupCompetency' ? (
            //   <>
            //     <a
            //       onClick={() => setShowCreateAppModal(true)}
            //       className='btn fs-5  CreateIndiviualCompetency'
            //     >
            //       <span>
            //         <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
            //       </span>
            //       Create Group Competency
            //     </a>
            //   </>
            // )
            :
            Test === "createPosition" ? (
              <>
                <Link to='/createposition'>
                  <a
                    className='btn CreateIndiviualCompetency'
                  // style={{background: 'rgba(255, 255, 255, 0.24)', color: 'white'}}
                  >
                    <span>
                      <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
                    </span>
                    Create New Position
                  </a>
                </Link>
              </>) 
              :
              Test === "create" ? (
                <>
                  <Link to='/createtest'>
                    <a
                      className='btn CreateIndiviualCompetency'
                    // style={{background: 'rgba(255, 255, 255, 0.24)', color: 'white'}}
                    >
                      <span>
                        <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
                      </span>
                      Create New Test
                    </a>
                  </Link>
                </>) 
                // :
                // Test === "save" ? (
                //   <>
                //     <Link to='/createtest'>
                //       <a
                //         className='btn CreateIndiviualCompetency'
                //       // style={{background: 'rgba(255, 255, 255, 0.24)', color: 'white'}}
                //       >
                //         <span>
                //           <img className='ml-1 margin-bottom-for-plusiconm' src={Plusicon}></img>
                //         </span>
                //         Save Test
                //       </a>
                //     </Link>
                //   </>)

                  : (
                    <></>
                  )}

            {/* end::Button */}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
      {/* <CreateIndiviualComtency
        show={showCreateIndiviualComtency}
        handleClose={() => setCreateIndiviualComtency(false)}
        updateCompetencies={()=>{}}
      /> */}
    </>
  )
}

export { Toolbar1 }
