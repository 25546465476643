//import liraries
import React from 'react';
import '../dashboard.css';

type Props = {
    positionType: string
    positionValue: any
}
// create a component
const PositionInfoCard = ({ positionType, positionValue }: Props) => {
    return (
        <div className='col-md-4'>
            <div className='position-info-card px-2 py-3 mx-2' >
                <div className='d-flex align-items-center'>
                    <div className={` me-1 ${positionType === 'InProgress' ? 'light-orange-box' : (positionType === 'Closed' ? 'dark-orange-box' : 'gray-box')}`}></div>
                    <label className='fs-9 fw-bolder'>{positionType}</label>
                </div>
                <label className='fs-4 fw-bolder mt-2'>{positionValue}</label>
            </div >
        </div>
    );
};

//make this component available to the app
export default PositionInfoCard;
