/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './CreateCompetency.css'
import addcompetenctimage from '../../images/971 [Converted].png'
import insertimage from '../../images/Group 9922.png'
import plusimagecom from '../../images/icons8-plus-math-30 (1).png'
import cross from '../../content/images/cross.png'
import axios from 'axios'
import cross12 from '../images/Layout-4-blocks.png'
import LoaderView from '../JobFunnelsModels/LoaderView'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import imagePath from '../../../../constants/imagePath'

type Props = {
  show: boolean
  handleClose: () => void
  id
  description
  name
  CompData
  fetchCompetenciesList: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
const API_URL = process.env.REACT_APP_API_URL
export const UPDATE_COMPETENCY_URL = `${API_URL}/competency/`
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`

// create a component
const UpdateGroupCompetency = ({show, handleClose, id, description, name, CompData, fetchCompetenciesList}: Props) => {
  const {auth} = useAuth()
  const [CompArray, setCompArray] = useState<any>([])
  const [display, setdisplay] = useState(false)
  const [CompetencyArrayinDB, setCompetencyArrayinDB] = useState<any>([])
  const [firstName, setFirstName] = useState(name)
  const [desc, setdesc] = useState(description)
  const [updatedname, setupdatedname] = useState(firstName)
  const [updateddesc, setupdateddesc] = useState(desc)
  const [loading, setLoading] = useState(false)

  var TotalCompetecies
  const CompetenciesNamesArray: any[] = []
  const CompetenciesTypesArray: string[] = []

  const inputRef = useRef(null)

  const handledescription = (event) => {
    setdesc(event.target.value)
  }

  const handlename = (event) => {
    setFirstName(event.target.value)
  }

  const handleClick = async (e) => {
    setLoading(true)
    setupdatedname(firstName)
    setupdateddesc(desc)

    const CompetencyData = {
      name: firstName,
      description: desc,
      total_competencies: CompetencyArrayinDB.length,
      level: 0,
      total_behaviors: 0,
      type: 'Group',
      competencies: JSON.stringify(CompetencyArrayinDB),
      behaviors: '',
    }
    var data = JSON.stringify(CompetencyData)
    const BaseURL = UPDATE_COMPETENCY_URL + id
    axios
      .put(BaseURL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setLoading(false)
        console.log(JSON.stringify(response.data))
        fetchCompetenciesList()
        handleClose()
      })
      .catch(function (error) {
        setLoading(false)
        console.log(error)
      })
  }

  const removeCompetency = (index: any) => {
    // return
    // setCompetencyArrayinDB([
    //   ...CompetencyArrayinDB.slice(0, index),
    //   ...CompetencyArrayinDB.slice(index + 1),
    // ])

    // CompData.pop(index)

    let arr: any[] = [...CompetencyArrayinDB]
    let arr2: any[] = []
    let j = 0
    for (let i = 0; i < arr.length; i++) {
      let g = {}
      g['group_' + j] = {
        id: j,
        name: arr[i]['group_' + i].name,
      }
      if (i != index) {
        arr2.push(g)
        j++
      }
    }
    setCompetencyArrayinDB(arr2)
  }

  var TotalCompetecies
  var TotalIndivualCom = 0
  const GetAllIndiviualCompetencies = async () => {
    await axios.get(GET_ALL_INDIVIDUAL_COMPETENCY_URL).then((resp) => {
      TotalCompetecies = resp.data.competencies
    })
    for (var i = 0; i < TotalCompetecies.length; i++) {
      CompetenciesTypesArray[i] = TotalCompetecies[i]['type']

      if (CompetenciesTypesArray[i] === 'Individual') {
        CompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalIndivualCom = TotalIndivualCom + 1
      }
    }
    if (CompetenciesNamesArray === undefined) {
      setCompArray(' ')
    } else {
      setCompArray(CompetenciesNamesArray)
    }
  }

  const displaySuggestions = () => {
    setdisplay(!display)
  }

  useEffect(() => {
    setCompetencyArrayinDB(CompData)
    GetAllIndiviualCompetencies()
  }, [])

  const addCompetency = (index: any) => {
    console.log(index)
    let arr = [...CompetencyArrayinDB]

    for (let i = 0; i < arr.length; i++) {
      if (arr[i]['group_' + i].name == index) return
    }

    let g = {}
    g['group_' + arr.length] = {
      id: arr.length,
      name: index,
    }
    arr.push(g)

    let s = new Set(arr)

    setCompetencyArrayinDB(Array.from(s))
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      // onHide={handleClose}
      backdrop={true}
    >
      <>
        <div className=' create-competency-header '>
          <div className='modal-header  create-competency-header'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <img src={imagePath.ic_cross_white} />
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column no-header-model'>
            <img className='addcompetenctimage' src={addcompetenctimage}></img>
            <h3 className='fw-bolder text-white my-5'>Update Competencies Group</h3>
          </div>
        </div>

        <div>
          <div>
            {/* begin::Beader */}
            {/* <div className='card-header mx-auto border-0 '>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label text-gray-800 fw-bolder fs-3 '>
                <div className='mx-auto wrapper'>
                  <div className='mx-auto btnimg'>
                    <img src={insertimage}></img>
                  </div>
                  <input type='file' />
                </div>
              </span>
            </h3>

            <hr className='border-hr'></hr>
          </div> */}

            <div className='p-8'>
              <form className='d-flex flex-column justify-content-center'>
                <div className='form-row d-flex flex-column justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div className='me-4'>
                      <label className='form-label fs-6 fw-bolder '>Name</label>
                      <input
                        style={{height: '56px'}}
                        type=''
                        className='form-control bg-white'
                        id='GroupCompetancyName'
                        placeholder='Communication'
                        defaultValue={name}
                        onChange={handlename}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <div className='form-group mt-5 col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder'>Description</label>
                      <textarea
                        style={{height: 'auto', maxHeight: '140px', resize: 'vertical'}}
                        className='form-control bg-white'
                        id='competencydescription'
                        placeholder='Type Competency (e.g: Data Analyst)'
                        value={desc}
                        onChange={handledescription}
                        rows={5}
                        // maxLength={1000}
                      />
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Add Competencies</label>
                      <input
                        style={{height: '56px'}}
                        type=''
                        className=' mb-3 form-select bg-white cursor-pointer'
                        id='competencydescription'
                        placeholder='type here'
                        onClick={() => displaySuggestions()}
                        autoComplete='off'
                        // defaultValue={}
                        // onChange={(event) => setdesc(event.target.value)}
                      />
                      <div>
                        {display === true ? (
                          <ul className='form-control'>
                            {CompArray.map((competenmcy, index) => (
                              <li
                                onClick={() => addCompetency(competenmcy)}
                                className='liststyling fs-6 mb-2'
                                style={{listStyle: 'none', display: 'flex', alignItems: 'center'}}
                              >
                                {competenmcy}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    <div className='mt-5 paddingforcomDict'>
                      {CompetencyArrayinDB.map((competenmcy, index) => (
                        <span className='orange-tag fs-7 me-3 mb-2 fw-bolder px-4 py-3 ' key={index}>
                          {competenmcy['group_' + index].name} &nbsp;
                          <span>
                            <img
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                removeCompetency(index)
                              }}
                              src={imagePath.ic_cross_orange}
                            />
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div className='me-4'>
                      <label className='form-label  fs-7 text-muted fw-bolder '>Suggested Competencies</label>
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div className='paddingforcomDict'>
                      {CompArray.map((friend, index) => (
                        <div className='mb-2 me-2' onClick={() => addCompetency(friend)}>
                          <span className='grey-chip fs-5 fw-bold px-4 py-3 align-items-center d-flex'>
                            <img style={{cursor: 'Pointer'}} src={plusimagecom} />

                            <span key={index}> &nbsp;{friend}</span>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='d-flex mt-2 flex-column'>
                  <div
                    onClick={handleClick}
                    className='candi-btn2 text-white fw-bolder my-10 mx-auto  '
                    style={{textAlign: 'center', alignSelf: 'center'}}
                  >
                    Update
                  </div>
                </div>
                {/* <button type='submit' className='btn my-15 px-20 bgcolorbuttonpost my-5'>
                  Create
                </button> */}
              </form>
            </div>
          </div>
        </div>

        {loading && <LoaderView message='Loading...' />}
      </>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export {UpdateGroupCompetency}
