import React, { useState, useEffect } from 'react';
import './CircularProgressBar.css'; // Import the CSS file for styling
import { Chart } from "react-google-charts";
import imagePath from '../../../../constants/imagePath';

type Props = {
    remainingBudget: any
    lapsedBudget: any
    totalBudget: any
}
// create a component
const SalaryProgressBar = ({ remainingBudget, lapsedBudget, totalBudget }: Props) => {

    const data = [
        ["Status", "Count", { role: "style" }],
        ["lapsedBudget", lapsedBudget, "#F57536"],
        ["remainingBudget", remainingBudget, "#FFD2A8"],
    ];

    const options = {
        pieHole: 0.7,
        is3D: false,
        legend: "none",
        pieSliceText: "none",
        colors: ['#F57536', '#FFD2A8'],
        chartArea: { width: '100%', height: '100%' }, // Adjust the chart area
        margin: 0,
        backgroundColor: 'transparent',
    };

    return (
        <div >
            <div style={{ height: '16rem', width: '100%' }}>

                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={options}
                />
                <div id='totalPositions' style={{ position: 'relative', transform: 'translate(-50%, -50%)', textAlign: 'center', marginLeft: '50%', marginTop: '-8rem' }}>
                    <img src={imagePath.ic_money_orange} width={24} />
                </div>
            </div>

        </div>
    );
};

//make this component available to the app
export default SalaryProgressBar;
