// Context/LoginContext
import React, {useState, createContext} from 'react'
interface JobsPageContextProps {
  children: React.ReactNode
}
export const CreateJobsContext = createContext({
  jobsState: false,
  setjobsState: (jobsState: boolean) => {},
})
export const JobsPageContext = ({children}: JobsPageContextProps) => {
  const [jobsState, setjobsState] = useState(false)
  return (
    <CreateJobsContext.Provider value={{jobsState, setjobsState}}>
      {children}
    </CreateJobsContext.Provider>
  )
}
