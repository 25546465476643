// import { useIntl } from 'react-intl'
// import { PageTitle } from '../../../_metronic/layout/core'
// import { StepperComponent } from '../../../../_metronic/assets/ts/components/_StepperComponent'
// import bottomarrow from './images/Path 38012.png'
// import toparrow from './images/Path 38012 (1).png'
// import { useNavigate } from 'react-router-dom'
// import Checkbox from '@mui/material/Checkbox';

import './CandiMain.css'
import './position.css'
import './Stepper.css'
import './personalinfo.css'
import Loader from './comps/Loader'
import UploadCloud from '../assets/upload@2x.png'
import {useEffect, useState, useRef} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calnedarimage from './images/today_black_24dp.png'
import styled from 'styled-components'
import React from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {useAuth} from '../../auth/core/Auth'
import ic_cross from '../assets/ic_cross.png'
import {date} from 'yup'
import TermsConditionsPopUp from '../../../../_metronic/partials/modals/JobFunnelsModels/TermsConditionsPopUp'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-skills/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`

const Skills = (props) => {
  const [savedInfo, setSavedInfo] = useState<any>([])
  const navigate = useNavigate()
  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [skills, setSkills] = useState('')
  const [chips, setChips] = useState<string[]>([])
  const [sendRequest, setSendRequest] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [tempMail, setTempMail] = useState('')

  const [profileStrength, setprofileStrength] = React.useState<any>({
    profile_info: 'false',
    work_exp: 'false',
    education: 'false',
    certificate: 'false',
    skills: 'false',
    total: '5',
  })
  const [currentProgress, setCurrentProgress] = useState(5)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  // const staticSkills = ["Data Analyst", "MERN Stack", "Front-end developer", "Node developer", "Data Scientist", "Data Engineer"];
  const [competencies, setCompetencies] = useState<any>([])
  const [termsChecked, setTermsChecked] = useState(false)

  // console.log('CURRENT USER: ' + JSON.stringify(currentUser))
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!termsChecked) {
      window.alert('Check the terms and conditions first')
      return
    }

    const skillsObject = chips.reduce((acc, val, idx) => {
      acc[idx] = val.trim()
      return acc
    }, {})

    let skills: any = []
    skills.push(skillsObject)
    // removing all empty objects from list
    skills = skills.filter((skill) => Object.keys(skill).length !== 0)
    console.log('skillsList: ', skills)
    if (skills === null || skills === undefined || skills.length === 0) {
      setMessage('Please add skills first and try again.')
      setShowErrorMessage(true)
      return
    }
    var skillss = {
      skills,
      profile_strength: profileStrength,
    }
    var data = JSON.stringify(skillss)
    const url = PERSONAL_INFORMATION_URL + user_id
    // loader component
    var loader = document.getElementById('loader')
    loader?.classList.remove('d-none')
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('res', res)
        props.reduce(5)
        // loader component
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        console.log('profileStrengthTotal: ', profileStrength.total)
        // if(profileStrength.total == 100){
        navigate('/candidatedashboard')
        // }
      })
      .catch((error) => {
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleSkills = (e) => {
    e.preventDefault()
    setSkills(e.target.value)
    console.log('SKILL SET: ' + skills)
    chips.push(e.target.value)
  }

  const handleInputChange = (event) => {
    const query = event.target.value
    setQuery(query)
    // if (query.length > 0) {
    const filteredResults = competencies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    // setResults(filteredResults);
    setResults(filteredResults.length > 0 ? filteredResults : competencies)
    // } else {
    // setResults(staticSkills);
    // }
  }

  useEffect(() => {
    if (sendRequest) {
      //send the request
      setSendRequest(false)
    }
  }, [sendRequest])

  const handleDelete = (index) => {
    chips.splice(index, 1)
    setSendRequest(true)
  }
  const handleItemClick = (item) => () => {
    if (!chipAlreadyAvailable(item) && chips.length < 5) {
      setChips((prevItems) => [...prevItems, item])
      // chips.push(item)
      setQuery('')
    }
    setResults([])
  }
  const chipAlreadyAvailable = (item) => {
    let found = false
    for (let chip of chips) {
      if (chip === item) {
        found = true
      }
    }
    return found
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && query.trim() !== '') {
      if (!chipAlreadyAvailable(query) && chips.length < 5) {
        setChips((prevItems) => [...prevItems, query])
        setQuery('') // Clear the input after adding a new skill
      }
      setResults([])
      event.preventDefault() // Prevent the form from submitting on Enter key
    }
  }

  // ---adding saved skills---
  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        // console.log("CANDIADTE: ", response.data.candidate.work_experience)
        const strength = JSON.parse(response.data.candidate.profile_strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 25,
              profile_info: 'false',
              work_exp: 'false',
              education: 'false',
              certificate: 'false',
              skills: 'true',
            })
          } else {
            let totalProgress = 5
            let profile = strength.profile_info
            if (typeof profile === 'string') {
              profile = strength.profile_info?.toLowerCase?.() === 'true'
            }
            let work = strength.work_exp
            if (typeof work === 'string') {
              work = strength.work_exp?.toLowerCase?.() === 'true'
            }
            let edu = strength.education
            if (typeof edu === 'string') {
              edu = strength.education?.toLowerCase?.() === 'true'
            }
            let certifi = strength.certificate
            if (typeof certifi === 'string') {
              certifi = strength.certificate?.toLowerCase?.() === 'true'
            }
            let skill = strength.skills
            if (typeof skill === 'string') {
              skill = strength.skills?.toLowerCase?.() === 'true'
            }

            if (profile) {
              totalProgress += 20
            }
            if (work) {
              totalProgress += 20
            }
            if (edu) {
              totalProgress += 20
            }
            if (certifi) {
              totalProgress += 15
            }
            if (skill) {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            setprofileStrength({
              total: strength.skills === 'false' ? totalProgress + 20 : totalProgress,
              profile_info: strength?.profile_info == undefined ? 'false' : strength.profile_info,
              work_exp: strength?.work_exp == undefined ? 'false' : strength.work_exp,
              education: strength?.education == undefined ? 'false' : strength.education,
              certificate: strength?.certificate == undefined ? 'false' : strength.certificate,
              skills: 'true',
            })
          }
        } else {
          setprofileStrength({
            total: 25,
            profile_info: 'false',
            work_exp: 'false',
            education: 'false',
            certificate: 'false',
            skills: 'true',
          })
        }

        let savedSkills = JSON.parse(response.data.candidate.skills)
        setSavedInfo(savedSkills)
        setTempMail(response.data.user.email)

        // console.log("SKILLS DATA: ", savedSkills)
        console.log('skillData: type', typeof savedSkills)
        // for (let i in savedSkills) {
        //     // setSkills(savedSkills[i].val)
        //     // console.log('skillData: ',savedSkills[i].val )
        //     if (!chipAlreadyAvailable(savedSkills[i]?.val)) {
        //         chips.push(savedSkills[i]?.val)
        //     }
        // }
        // console.log('chips: ' + chips)

        savedSkills.map((val, index) => {
          const skillInfos = Object.values(val)
          for (let i = 0; i < skillInfos.length; i++) {
            let entity: any = skillInfos[i]
            console.log('skillData: entity: ', entity)
            if (!chipAlreadyAvailable(entity)) {
              chips.push(entity)
            }
          }
        })
      })
    }
    getData()
  }, [tempMail])

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getCompetencies()
    })()
  }, [])

  const [showPopup, setShowPopup] = useState(false)

  function showTermsPopUp() {
    setShowPopup(true)
  }

  function handleClose() {
    setShowPopup(false)
  }

  function handleTermsCondition(event) {
    setTermsChecked(event.target.checked) // Update termsChecked state
  }

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Position' })}</PageTitle> */}

      <div className='row g-5 g-xl-8 personal-info-container'>
        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    <h3 className='card-title align-items-center flex-column'>
                      <label className='form-label fw-bolder fs-5 ms-3 '>
                        Add Skills Set<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                      </label>
                    </h3>
                    {/* skills  */}

                    <div className='form-row d-flex mt-2 justify-content-spacearound' style={{margin: '0 1.3rem 0 2rem'}}>
                      <div className='form-group col-md-12'>
                        {/* <label className='form-label labelse '> Blood Group</label> */}
                        <div className='me-4  '>
                          <input
                            type='text'
                            className='search-bar-style form-control   w-140px'
                            placeholder='Search skills'
                            value={query}
                            onChange={handleInputChange}
                            onFocus={handleInputChange}
                            onKeyDown={handleKeyDown}
                          />
                          {results.length > 0 && (
                            <ul className='form-control   w-140px'>
                              {results.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={handleItemClick(item)}
                                  style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                        {/* <Chips skillset={chips} /> */}
                        <div className=' mt-4'>
                          {chips.map((val, index) => (
                            <div key={index} style={{width: 'fit-content', display: 'inline-block', margin: '0 1rem 1rem 0 '}}>
                              <div className='chip-uxi'>
                                {val}&nbsp;
                                {/* <i className='bi bi-x-lg' onClick={() => handleDelete(index)}></i> */}
                                <img src={ic_cross} alt='remove' onClick={() => handleDelete(index)}></img>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* <h3 className='card-title align-items-center flex-column'>
                                                    <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                        Suggested Competencies
                                                    </span>
                                                </h3> */}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group checks-container col-md-12'>
                        <div className='form-check checkbox-container'>
                          <input type='hidden' value={tempMail} onChange={(e) => setTempMail(e.target.value)} />

                          <input
                            name='termsConditions'
                            className='form-check-input'
                            type='checkbox'
                            // value={termsChecked}
                            checked={termsChecked}
                            onChange={(event) => handleTermsCondition(event)}
                          />
                          <label className='form-check-label cursor-pointer ' onClick={showTermsPopUp}>
                            <u style={{color: '#F36523'}}> *I agree with Terms and Conditions</u>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* Buttons  */}
                    {/* <div className='form-row d-flex mt-10 justify-content-spacearound'> */}
                      <div className='d-flex align-items-center justify-content-center mt-8'>
                        <button className='candi-btn1 m-5' type='submit'>
                          <span style={{fontWeight: 'bold'}}>Save</span>
                        </button>
                        <div
                          className={` text-white ${termsChecked ? 'candi-btn2  m-5' : 'candi-btn-grey m-5'}`}
                          // type='button'
                          // disabled={props.curr === 5}
                          // disabled={!termsChecked}
                          onClick={(e) => {
                            if (termsChecked) {
                              handleSubmit(e)
                            }
                            // props.updates(props.curr + 1)
                            // navigate('/candidatedashboard')
                          }}
                        >
                          <span style={{fontWeight: 'bold'}}>Finish</span>
                        </div>
                      </div>
                    {/* </div> */}
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Building Profile...' />
      </div>

      {showPopup && (
        <TermsConditionsPopUp
          show={true}
          handleClose={() => {
            handleClose()
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default Skills
