import ic_calendar_black from '../assets/images/ic_calendar_black.png'
import ic_location_pin_orange from '../assets/images/ic_location_pin_orange.png'
import ic_star_yellow from '../assets/images/ic_star_yellow.png'
import ic_time_left_red from '../assets/images/ic_time_left_red.png'
import ic_delete_black_holo from '../assets/images/ic_delete_black_holo.png'
import ic_edit_orange from '../assets/images/ic_edit_orange.png'
import ic_open_file from '../assets/images/ic_open_file.png'
import ic_print from '../assets/images/ic_print.svg'
import ic_download from '../assets/images/ic_download.svg'
import ic_report from '../assets/images/ic_report.svg'
import ic_comment from '../assets/images/ic_comment.png'
import userIcon from '../_metronic/partials/modals/JobFunnelsModels/img/group-9680@3x.png'
import ic_chair from '../assets/images/ic_chair.svg'
import ic_arrow_left_circle from '../assets/images/ic_arrow_left_circle.svg'
import ic_arrow_right_circle from '../assets/images/ic_arrow_right_circle.svg'
import ic_sun from '../assets/images/ic_sun.svg'
import cycling from '../assets/images/cycling.gif'
import ic_group_orange_filled from '../assets/images/ic_group_orange_filled.svg'
import ic_clock_gray_bg from '../assets/images/ic_clock_gray_bg.svg'
import ic_msg_gray_bg from '../assets/images/ic_msg_gray_bg.svg'
import ic_application_gray_bg from '../assets/images/ic_application_gray_bg.svg'
import ic_timer_fill_gray_bg from '../assets/images/ic_timer_fill_gray_bg.svg'
import ic_timer_empty_gray_bg from '../assets/images/ic_timer_empty_gray_bg.svg'
import ic_attrition_gray_bg from '../assets/images/ic_attrition_gray_bg.svg'
import ic_group_orange_holo from '../assets/images/ic_group_orange_holo.svg'
import ic_close_circle from '../assets/images/ic_close_circle.svg'
import ic_search from '../assets/images/ic_search.svg'
import ic_calendar_orange from '../assets/images/ic_calendar_orange.svg'
import ic_refresh_orange from '../assets/images/ic_refresh_orange.svg'
import ic_right_pagination_arrow from '../assets/images/ic_right_pagination_arrow.svg'
import ic_left_pagination_arrow from '../assets/images/ic_left_pagination_arrow.svg'
import ic_arrow_up_green from '../assets/images/ic_arrow_up_green.svg'
import ic_search_orange from '../assets/images/ic_search_orange.svg'
import ic_mail from '../assets/images/ic_mail.svg'
import ic_lock from '../assets/images/ic_lock.svg'
import ic_eye_open from '../assets/images/ic_eye_open.svg'
import ic_eye_close from '../assets/images/ic_eye_close.svg'
import ic_edit_circle_orange from '../assets/images/ic_edit_circle_orange.svg'
import ic_archive from '../assets/images/ic_archive.svg'
import ic_search_white from '../assets/images/ic_search_white.svg'
import ic_mail_orange_fill from '../assets/images/ic_mail_orange_fill.svg'
import ic_phone_orange_fill from '../assets/images/ic_phone_orange_fill.svg'
import ic_filter_orange from '../assets/images/ic_filter_orange.svg'
import ic_delete_orange_cross from '../assets/images/ic_delete_orange_cross.svg'
import ic_terms_conditions_light from '../assets/images/ic_terms_conditions_light.svg'
import ic_edu_hat from '../assets/images/ic_edu_hat.svg'
import ic_institute from '../assets/images/ic_institute.svg'
import ic_specialization_tag from '../assets/images/ic_specialization_tag.svg'
import ic_grades from '../assets/images/ic_grades.svg'
import ic_cross_orange from '../assets/images/ic_cross_orange.svg'
import ic_group_head from '../assets/images/ic_group_head.svg'
import ic_drag_drop from '../assets/images/ic_drag_drop.svg'
import ic_docs_orange from '../assets/images/ic_docs_orange.svg'
import ic_msg_orange from '../assets/images/ic_msg_orange.svg'
import ic_close from '../assets/images/ic_close.svg'
import ic_upload_white from '../assets/images/ic_upload_white.svg'
import ic_terms_conditions_menu_gray from '../assets/images/ic_terms_conditions_menu_gray.svg'
import ic_search_gray from '../assets/images/ic_search_gray.svg'
import ic_add_orange_border from '../assets/images/ic_add_orange_border.svg'
import ic_cross_white from '../assets/images/ic_cross_white.svg'
import ic_timer_orange from '../assets/images/ic_timer_orange.svg'
import ic_tic_orange_filled_circle from '../assets/images/ic_tic_orange_filled_circle.svg'
import ic_tic_gray_filled_circle from '../assets/images/ic_tic_gray_filled_circle.svg'
import ic_level_1 from '../assets/images/ic_level_1.svg'
import ic_level_2 from '../assets/images/ic_level_2.svg'
import ic_level_3 from '../assets/images/ic_level_3.svg'
import ic_level_4 from '../assets/images/ic_level_4.svg'
import ic_level_5 from '../assets/images/ic_level_5.svg'
import ic_edu_hat_gray from '../assets/images/ic_edu_hat_gray.svg'
import ic_building_gray_holo from '../assets/images/ic_building_gray_holo.svg'
import ic_tag_gray_holo from '../assets/images/ic_tag_gray_holo.svg'
import ic_grade_gray from '../assets/images/ic_grade_gray.svg'
import ic_group_gray from '../assets/images/ic_group_gray_.svg'
import ic_doc_gray_holo from '../assets/images/ic_doc_gray_holo.svg'
import ic_mail_gray_holo from '../assets/images/ic_mail_gray_holo.svg'
import ic_level_1_gray from '../assets/images/ic_level_1_gray.svg'
import ic_level_2_gray from '../assets/images/ic_level_2_gray.svg'
import ic_level_3_gray from '../assets/images/ic_level_3_gray.svg'
import ic_level_4_gray from '../assets/images/ic_level_4_gray.svg'
import ic_level_5_gray from '../assets/images/ic_level_5_gray.svg'
import ic_terms_conditions_menu_orange from '../assets/images/ic_terms_conditions_menu_orange.svg'
import ic_edu_hat_orange from '../assets/images/ic_edu_hat_orange.svg'
import ic_building_orange_holo from '../assets/images/ic_building_orange_holo.svg'
import ic_tag_orange_holo from '../assets/images/ic_tag_orange_holo.svg'
import ic_grade_orange from '../assets/images/ic_grade_orange.svg'
import ic_group_orange from '../assets/images/ic_group_orange.svg'
import ic_doc_orange_holo from '../assets/images/ic_doc_orange_holo.svg'
import ic_mail_orange_holo from '../assets/images/ic_mail_orange_holo.svg'
import ic_arrow_up_circle_orange from '../assets/images/ic_arrow_up_circle_orange.svg'
import ic_arrow_down_circle_orange from '../assets/images/ic_arrow_down_circle_orange.svg'
import banner_static from '../assets/images/banner_static.svg'
import ic_person_holo_circle_orange from '../assets/images/ic_person_holo_circle_orange.svg'
import ic_skills from '../assets/images/ic_skills.svg'
import ic_campaign_orange from '../assets/images/ic_campaign_orange.svg'
import ic_campaign_gray from '../assets/images/ic_campaign_gray.svg'
import ic_campaign_orange_holo from '../assets/images/ic_campaign_orange_holo.svg'
import ic_upload_orange from '../assets/images/ic_upload_orange.svg'
import ic_money_orange from '../assets/images/ic_money_orange.svg'
import ic_arrow_down_orange from '../assets/images/ic_arrow_down_orange.svg'
import ic_arrow_down_white from '../assets/images/ic_arrow_down_white.svg'
import gray_bg_inner_shadow from '../assets/images/gray_bg_inner_shadow.svg'
import ic_upload_gray from '../assets/images/ic_upload_gray.svg'
import ic_camera_orange_holo from '../assets/images/ic_camera_orange_holo.svg'
import ic_camera_gray_holo from '../assets/images/ic_camera_gray_holo.svg'
import ic_delete_video from '../assets/images/ic_delete_video.svg'
import ic_link_video from '../assets/images/ic_link_video.svg'
import ic_warning_orange from '../assets/images/ic_warning_orange.svg'
import ic_arrow_down_red from '../assets/images/ic_arrow_down_red.svg'
import ic_panel_members from '../assets/images/ic_panel_members.svg'
import ic_time_orange_bg_circle from '../assets/images/ic_time_orange_bg_circle.svg'
import ic_location_oranve_bg_circle from '../assets/images/ic_location_oranve_bg_circle.svg'
import ic_doc_orange_bg_circle from '../assets/images/ic_doc_orange_bg_circle.svg'
import ic_benefits_orange from '../assets/images/ic_benefits_orange.svg'
import ic_benefits_grey from '../assets/images/ic_benefits_grey.svg'
import ic_benefits_orange_menu from '../assets/images/ic_benefits_orange_menu.svg'
import ic_fuel_grey_holo from '../assets/images/ic_fuel_grey_holo.svg'
import ic_fuel_orange_holo from '../assets/images/ic_fuel_orange_holo.svg'
import ic_notes_orange from '../assets/images/ic_notes_orange.svg'
import ic_checkbox_orange from '../assets/images/ic_checkbox_orange.svg'
import ic_warning_red_circle from '../assets/images/ic_warning_red_circle.svg'
import ic_warning_gray_circle from '../assets/images/ic_warning_gray_circle.svg'
import ic_orange_tick_circle from '../assets/images/ic_orange_tick_circle.svg'
import ic_comment_gray_holo from '../assets/images/ic_comment_gray_holo.svg'
import ic_comment_red_holo from '../assets/images/ic_comment_red_holo.svg'
import ic_layers_orange_holo from '../assets/images/ic_layers_orange_holo.svg'
import ic_warning_triangle_orange from '../assets/images/ic_warning_triangle_orange.svg'

const imagePath = {
  ic_calendar_black: ic_calendar_black,
  ic_location_pin_orange: ic_location_pin_orange,
  ic_star_yellow: ic_star_yellow,
  ic_time_left_red: ic_time_left_red,
  ic_add_orange_border: ic_add_orange_border,
  ic_delete_black_holo: ic_delete_black_holo,
  ic_edit_orange: ic_edit_orange,
  ic_open_file: ic_open_file,
  ic_print: ic_print,
  ic_download: ic_download,
  ic_report: ic_report,
  ic_comment: ic_comment,
  userIcon: userIcon,
  ic_chair: ic_chair,
  ic_arrow_left_circle: ic_arrow_left_circle,
  ic_arrow_right_circle: ic_arrow_right_circle,
  ic_sun: ic_sun,
  cycling: cycling,
  ic_group_orange_filled: ic_group_orange_filled,
  ic_clock_gray_bg: ic_clock_gray_bg,
  ic_msg_gray_bg: ic_msg_gray_bg,
  ic_application_gray_bg: ic_application_gray_bg,
  ic_timer_fill_gray_bg: ic_timer_fill_gray_bg,
  ic_timer_empty_gray_bg: ic_timer_empty_gray_bg,
  ic_attrition_gray_bg: ic_attrition_gray_bg,
  ic_group_orange_holo: ic_group_orange_holo,
  ic_close_circle: ic_close_circle,
  ic_search: ic_search,
  ic_calendar_orange: ic_calendar_orange,
  ic_refresh_orange: ic_refresh_orange,
  ic_right_pagination_arrow: ic_right_pagination_arrow,
  ic_left_pagination_arrow: ic_left_pagination_arrow,
  ic_arrow_up_green: ic_arrow_up_green,
  ic_search_orange: ic_search_orange,
  ic_mail: ic_mail,
  ic_lock: ic_lock,
  ic_eye_open: ic_eye_open,
  ic_eye_close: ic_eye_close,
  ic_edit_circle_orange: ic_edit_circle_orange,
  ic_archive: ic_archive,
  ic_search_white: ic_search_white,
  ic_mail_orange_fill: ic_mail_orange_fill,
  ic_phone_orange_fill: ic_phone_orange_fill,
  ic_filter_orange: ic_filter_orange,
  ic_delete_orange_cross: ic_delete_orange_cross,
  ic_terms_conditions_light: ic_terms_conditions_light,
  ic_edu_hat: ic_edu_hat,
  ic_institute: ic_institute,
  ic_specialization_tag: ic_specialization_tag,
  ic_grades: ic_grades,
  ic_cross_orange: ic_cross_orange,
  ic_group_head: ic_group_head,
  ic_drag_drop: ic_drag_drop,
  ic_docs_orange: ic_docs_orange,
  ic_msg_orange: ic_msg_orange,
  ic_close: ic_close,
  ic_upload_white: ic_upload_white,
  ic_terms_conditions_menu_gray: ic_terms_conditions_menu_gray,
  ic_search_gray: ic_search_gray,
  ic_cross_white: ic_cross_white,
  ic_timer_orange: ic_timer_orange,
  ic_tic_orange_filled_circle: ic_tic_orange_filled_circle,
  ic_tic_gray_filled_circle: ic_tic_gray_filled_circle,
  ic_level_1: ic_level_1,
  ic_level_2: ic_level_2,
  ic_level_3: ic_level_3,
  ic_level_4: ic_level_4,
  ic_level_5: ic_level_5,
  ic_edu_hat_gray: ic_edu_hat_gray,
  ic_building_gray_holo: ic_building_gray_holo,
  ic_tag_gray_holo: ic_tag_gray_holo,
  ic_grade_gray: ic_grade_gray,
  ic_group_gray: ic_group_gray,
  ic_doc_gray_holo: ic_doc_gray_holo,
  ic_mail_gray_holo: ic_mail_gray_holo,
  ic_level_1_gray: ic_level_1_gray,
  ic_level_2_gray: ic_level_2_gray,
  ic_level_3_gray: ic_level_3_gray,
  ic_level_4_gray: ic_level_4_gray,
  ic_level_5_gray: ic_level_5_gray,
  ic_terms_conditions_menu_orange: ic_terms_conditions_menu_orange,
  ic_edu_hat_orange: ic_edu_hat_orange,
  ic_building_orange_holo: ic_building_orange_holo,
  ic_tag_orange_holo: ic_tag_orange_holo,
  ic_grade_orange: ic_grade_orange,
  ic_group_orange: ic_group_orange,
  ic_doc_orange_holo: ic_doc_orange_holo,
  ic_mail_orange_holo: ic_mail_orange_holo,
  ic_arrow_up_circle_orange: ic_arrow_up_circle_orange,
  ic_arrow_down_circle_orange: ic_arrow_down_circle_orange,
  banner_static: banner_static,
  ic_person_holo_circle_orange: ic_person_holo_circle_orange,
  ic_skills: ic_skills,
  ic_campaign_orange: ic_campaign_orange,
  ic_campaign_gray: ic_campaign_gray,
  ic_campaign_orange_holo: ic_campaign_orange_holo,
  ic_upload_orange: ic_upload_orange,
  ic_money_orange: ic_money_orange,
  ic_arrow_down_orange: ic_arrow_down_orange,
  ic_arrow_down_white: ic_arrow_down_white,
  gray_bg_inner_shadow: gray_bg_inner_shadow,
  ic_upload_gray: ic_upload_gray,
  ic_camera_orange_holo: ic_camera_orange_holo,
  ic_camera_gray_holo: ic_camera_gray_holo,
  ic_delete_video: ic_delete_video,
  ic_link_video: ic_link_video,
  ic_warning_orange: ic_warning_orange,
  ic_arrow_down_red: ic_arrow_down_red,
  ic_panel_members: ic_panel_members,
  ic_time_orange_bg_circle: ic_time_orange_bg_circle,
  ic_location_oranve_bg_circle: ic_location_oranve_bg_circle,
  ic_doc_orange_bg_circle: ic_doc_orange_bg_circle,
  ic_benefits_orange: ic_benefits_orange,
  ic_benefits_grey: ic_benefits_grey,
  ic_benefits_orange_menu: ic_benefits_orange_menu,
  ic_fuel_grey_holo: ic_fuel_grey_holo,
  ic_fuel_orange_holo: ic_fuel_orange_holo,
  ic_notes_orange: ic_notes_orange,
  ic_checkbox_orange: ic_checkbox_orange,
  ic_warning_red_circle: ic_warning_red_circle,
  ic_warning_gray_circle: ic_warning_gray_circle,
  ic_orange_tick_circle: ic_orange_tick_circle,
  ic_comment_gray_holo: ic_comment_gray_holo,
  ic_comment_red_holo: ic_comment_red_holo,
  ic_layers_orange_holo: ic_layers_orange_holo,
  ic_warning_triangle_orange: ic_warning_triangle_orange,
  
}

export default imagePath
