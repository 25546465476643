//import liraries
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './RequestForApprovals.css'
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import ShowError from '../../content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { getFormattedDate } from '../../../../constants/CommonFunctions';
import LoaderView from './LoaderView';

const API_URL = process.env.REACT_APP_API_URL
const GET_JOB_DETAILS = `${API_URL}/job/`
const SEND_EMAIL_TO_PANEL_MEMBERS = `${API_URL}/panel/send-and-approve`
const GET_APPLICATION_STATUS_URL = `${API_URL}/panel/get-members-status`;

type Props = {
    jobapp_id: string
    job_id: string
    candidate_id: string
    handleClose: () => void
    updateCandidatesList: () => void
}

// create a component
const OfficeNoteApprovalStep2 = ({ jobapp_id, job_id, candidate_id, handleClose, updateCandidatesList }: Props) => {
    const { auth, logout } = useAuth()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [jobDetails, setJobDetails] = useState<any>();
    const [jobGrade, setJobGrade] = useState('');
    const [approvals, setApprovals] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (() => {
            getJobDetails();
            // getConfigurations();
            fetchMembersStatus();
        })()
    }, [])

    const fetchMembersStatus = () => {
        let data = {
            "jobapp_id": jobapp_id,
            "name": 'approvals'
        }
        axios.post(GET_APPLICATION_STATUS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data;
            let users: any = [];
            const membersList = data?.members;

            let taLead = data?.ta;
            let taHead = data?.taHead;
            let approvalsData = data?.approvals;
            let approvingAuthorities: any = [];
            let taLeadObj = {
                title: 'TA Lead',
                user: taLead
            };
            let taHeadObj = {
                title: 'TA Head',
                user: taHead
            }

            approvingAuthorities.push(taLeadObj);
            approvingAuthorities.push(taHeadObj);
            if (Array.isArray(approvalsData) && approvalsData.length > 0) {
                approvalsData.forEach(approval => {
                    approvingAuthorities.push(approval);
                });
            }

            setApprovals(approvingAuthorities)

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                logout()
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        });
    };


    const getJobDetails = async () => {
        await axios
            .get(GET_JOB_DETAILS + job_id, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                let data = res?.data;
                setJobDetails(data)
                setJobGrade(data?.grade);

                let taLead = data?.ta;
                let taHead = data?.taHead;
                let approvalsData = data?.approvals;
                let approvingAuthorities: any = [];
                let taLeadObj = {
                    title: 'TA Lead',
                    user: taLead
                };
                let taHeadObj = {
                    title: 'TA Head',
                    user: taHead
                }

                approvingAuthorities.push(taLeadObj);
                approvingAuthorities.push(taHeadObj);
                if (Array.isArray(approvalsData) && approvalsData.length > 0) {
                    approvalsData.forEach(approval => {
                        approvingAuthorities.push(approval);
                    });
                }
                // setApprovals(approvingAuthorities)

            })
            .catch((err) => {
                handleErrorMessage(err);
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(SEND_EMAIL_TO_PANEL_MEMBERS,
            {
                candidate_id: candidate_id,
                jobapp_id: jobapp_id,
                index: '0',
                name: 'approvals',
                "approver": "TA"
            },
            {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }
        ).then((res) => {
            setLoading(false);
            console.log({ res })
            updateCandidatesList()

        }).catch((err) => {
            setLoading(false);
            handleErrorMessage(err);
        })

    }

    const handleErrorMessage = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message);
            setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
            logout();
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout();
            } else {
                setMessage(error.response.data.error.message);
                setShowErrorMessage(true);
            }
        } else {
            setMessage(error.message);
            setShowErrorMessage(true);
        }
    }

    return (
        <div>
            <div className='request-approval-hiring-text d-flex align-items-center mt-8'>
                <label className='me-4'>Approval Authorities</label>
                <img src={imagePath.ic_warning_gray_circle} width={24} height={24} title='Approval Sequence from left to right' />
            </div>

            <div className=' mt-4 d-flex align-items-center pb-8 mx-auto col-md-12 overflow-auto'>
                {(approvals && approvals.length > 0) ? approvals.map((data, index) => {
                    let user = data?.user;
                    return (
                        <div key={index} className='d-flex align-items-center justify-content-center'>
                            {/* <div className='d-flex flex-column align-items-center justify-content-center'> */}
                            <div className='d-flex align-items-center mt-4'>
                                <img className='me-2' src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon} width={32} height={32} style={{ borderRadius: '15px', objectFit: 'contain' }} />
                                <div className='d-flex flex-column'>
                                    <label className='fs-5 fw-bolder' style={{ whiteSpace: 'nowrap' }}>{user?.fullName}</label>
                                    <label className='fs-6 fw-semibold' style={{ whiteSpace: 'nowrap' }}>{user?.employee_id}</label>
                                    <label className='fs-6 fw-semibold' style={{ whiteSpace: 'nowrap' }}>{data?.title}</label>
                                </div>
                            </div>

                            {/* </div> */}
                            {(index < approvals.length - 1) && (
                                <div className='mx-6' style={{ height: '2px', backgroundColor: '#CBC9C9', width: '4rem' }}>

                                </div>
                            )}
                        </div>
                    )
                }) : (<div></div>)}
            </div>

            <div className='d-flex justify-content-center mt-10'>
                <button className='candi-btn2 btn text-white'
                    onClick={handleSubmit} >
                    Send Approval Request
                </button>
            </div>

            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            {loading && <LoaderView message='Loading...' />}
        </div>
    );
};
//make this component available to the app
export default OfficeNoteApprovalStep2;
