//import liraries
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';

import Layout from '../images/Layout-4-blocks (3).png'
import icInfo from './img/information (3)@2x.png'
import './Success.css'
import axios from 'axios';
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import imagePath from '../../../../constants/imagePath';
const API_URL = process.env.REACT_APP_API_URL
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const TermsConditionsPopUp = ({ show, handleClose }: Props) => {

  const { auth } = useAuth();
  const [termsConditions, setTermsConditions] = useState<any>();

  const getConfigurations = async () => {
    await axios.get(GET_CONFIGURATIONS_URL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      let data = res?.data?.Configuration;
      let terms = data?.terms_condition
      if (terms) {
        terms = JSON.parse(terms);

        const filteredTerms: any = [];

        // Iterate over the properties of the object
        for (const key in terms) {
          if (terms.hasOwnProperty(key)) {
            const term = terms[key];
            // Check if the term is active
            if (term.active) {
              // If active, add it to the filtered list
              filteredTerms.push({
                name: term.name,
                description: term.description
              });
            }
          }
        }

        setTermsConditions(filteredTerms);

        // const initialTermsAndConditions = Object.keys(terms).map((key) => ({
        //   i: key,
        //   data: terms[key]
        // }));
        // setTermsConditions(initialTermsAndConditions);
      }


    }).catch((err) => {
      console.error('err: ', err);
    });
  };

  useEffect(() => {
    (async () => {
      getConfigurations();
    })();
  }, []);

  const handleSubmit = () => {
    console.log('closed')
    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
      backdropClassName="modal-backdrop backdrop-blur"
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={imagePath.ic_close}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className=' d-flex flex-column '>
          <img alt='' className='infoIcImg' src={icInfo}></img>
          <h3 className='fw-bolder mx-auto my-5'>Terms & Conditions</h3>
          <ul className='mt-8'>
            {termsConditions && termsConditions.map((termCondition, index) => {
              let data = termCondition?.name;
              return (
                <li key={index} className='d-flex align-items-center mb-6'>
                  {/* <span className="dot">.</span> */}
                  <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#F36523', marginRight: '0.3rem' }}></div>
                  <p className=' p-margin'> {data || 'Terms And Conditions'}</p>
                </li>
              )
            })}

            {/* <li className='d-flex'> <span className="dot">.</span> <p className='mb-6 p-margin'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p></li>
            <li className='d-flex'> <span className="dot">.</span> <p className='mb-6 p-margin'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p></li>
            <li className='d-flex'> <span className="dot">.</span> <p className='mb-6 p-margin'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p></li> */}
          </ul>
          {/* <p><span className='dot'>.</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p> */}
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <form
        //  onSubmit={handleSubmit}
        >
          <div className='btn-container'>
            <button
              onClick={handleSubmit}
              type='button'
              className='btn px-10 apply-review-button'
            >
              Got it!
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
};


//make this component available to the app
export default TermsConditionsPopUp;
// function useEffect(arg0: () => void, arg1: never[]) {
//   throw new Error('Function not implemented.');
// }

