//import libraries
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import './Success.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import Layout from '../images/Layout-4-blocks (3).png'
import ic_cross from '../../../../app/modules/Candidate Space/assets/ic_cross.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { useNavigate } from 'react-router'
import LoaderView from './LoaderView'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import { getFormattedDate } from '../../../../constants/CommonFunctions'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_POSITION_URL = `${API_URL}/position/`
const GET_EMPLOYEE_LIST_URL = `${API_URL}/position/get-employees`
const GET_RECRUITERS_URL = `${API_URL}/position/poc-recruiters`
const GET_ALL_GROUPS = `${API_URL}/group`
const UPLOAD_FILE_URL = `${API_URL}/position/upload-document`

interface Attachment {
  documentName: string;
  file: File | null;
  fileUrl: string | null;
}

type Props = {
  show: boolean
  handleClose: () => void
  updatePositions: () => void
  position: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const RequisitionFormPopup: React.FC<Props> = ({ show, handleClose, updatePositions, position }: Props) => {
  // console.log('currentPositionData: ', position)
  const { currentUser, auth, logout } = useAuth()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [remarks, setRemarks] = useState('')
  const [positionInfo, setPositionInfo] = useState('budgeted')
  const [allEmployees, setAllEmployees] = useState<any>([])
  const [employee, setEmployee] = useState<any>(null)
  const [jobDescription, setJobDescription] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [recruitersList, setRecruitersList] = useState<any>()
  const [recruiter, setRecruiter] = useState<any>()
  const [recruiterQuery, setRecruiterQuery] = useState('')
  const [recruiterResults, setRecruiterResults] = useState<any>([]);
  const [positionLocations, setPositionLocations] = React.useState<any>([])
  const [taLead, setTaLead] = useState<any>();
  const [groupHead, setGroupHead] = useState<any>();
  const [replacementEmployees, setReplacementEmployees] = useState<any>([]);
  const [rejectionReason, setRejectionReason] = useState<any>(null);
  // State to manage checkbox values
  const [checkboxes, setCheckboxes] = useState({
    internal: false,
    rozee: false,
    bop: false,
    others: false
  });
  const [attachments, setAttachments] = useState<Attachment[]>([{ documentName: '', file: null, fileUrl: null }]);

  useEffect(() => {
    if (position) {
      setPositionInfo(position.position_type)
      let replacements = position.replacements;
      if (replacements) {
        setReplacementEmployees(JSON.parse(replacements))
      }
      let reason = position?.rejection_reason;
      if (reason) {
        setRejectionReason(JSON.parse(reason));
      }
      const batchPositions = position.batch_positions;
      if (batchPositions) {
        let positionsData: any = [];
        const locations = batchPositions.locations
        if (locations) {
          let positionsData: any = []
          locations.forEach((element, index) => {
            let data = {
              code: batchPositions.codes[index],
              officeType: batchPositions.office_types[index],
              location: element,
              no_of_positions: batchPositions.no_of_positions[index],
              position_id: batchPositions.position_ids[index],
            }
            positionsData.push(data)
          })
          setPositionLocations(positionsData)
        }
      }

      (async () => {
        await getGroupData(position?.group_name)
      })()

    }
  }, [position])

  useEffect(() => {
    (async () => {
      await getEmployeeList()
      getRecruiters()
    })()
  }, [])

  // fetching list of employees
  const getEmployeeList = async () => {
    await axios
      .get(GET_EMPLOYEE_LIST_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setAllEmployees(res?.data?.getEmployeeData)
      })
      .catch((error) => {
        displayError(error)
      })
  }

  const getRecruiters = async () => {
    await axios
      .get(GET_RECRUITERS_URL, {
        params: {
          poc_id: currentUser?.user_id,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setRecruitersList(res?.data?.recruiters)
      })
      .catch((error) => {
        displayError(error)
      })
  }

  const getGroupData = async (groupName) => {
    const urlWithParams = `${GET_ALL_GROUPS}?group_name=${encodeURIComponent(groupName)}`
    axios.get(urlWithParams, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((res) => {
        const groups = res.data?.data;
        if (groups) {
          groups.map((group, index) => {
            if (group?.group_name === groupName) {
              setTaLead(group?.ta_lead);
              setGroupHead(group?.group_head)
            }
          })
        }

      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleDelete = () => {
    setEmployee(null)
  }

  const handlePositionInfoChange = (event) => {
    setPositionInfo(event.target.value)
  }

  const changeJobDescription = (newDescription) => {
    setJobDescription(newDescription)
  }

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked
    }));
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value)
  }

  const handleSubmit = () => {
    if (!taLead) {
      setMessage('TA Lead data not found, Please try again')
      setShowErrorMessage(true)
      return
    }
    if (!jobDescription) {
      setMessage('Job description can not be empty.')
      setShowErrorMessage(true)
      return
    }

    // if (positionInfo === 'replacement' && !employee) {
    //   setMessage('Please select position is replacement of which employee first and try again!')
    //   setShowErrorMessage(true)
    //   return
    // }

    if (position?.batch_id) {

      console.log('raise requisition for batch position')
      raiseMultipleRequisitions();

    } else {
      let employeeId = null
      if (employee && positionInfo === 'replacement') {
        employeeId = employee.user_id
      }

      let documents: any = [];
      if (attachments && attachments.length > 0) {
        attachments.map((attachment) => {
          if (attachment?.fileUrl) {
            let docData = {
              title: attachment?.documentName,
              url: attachment?.fileUrl
            }
            documents.push(docData);
          }
        })
      }

      const recruiterId = taLead?.user_id
      const data = {
        group_name: position['group_name'],
        title: position['title'],
        max_basic_salary_per_month: position['max_basic_salary_per_month'],
        max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
        max_grade_range: position['max_grade_range'],
        contact_type: position['contact_type'],
        functional_area: position['functional_area'],
        sub_functional_area: position['sub_functional_area'],
        section: position['section'],
        office_type: position['office_type'],
        code: position['code'],
        location: position['location'],
        // position_type: positionInfo,
        position_type: position['position_type'],
        // status: 'REQUISITION_RAISED',
        status: 'GROUP_HEAD_APPROVAL',
        // employee_id: employeeId,
        remarks: remarks,
        job_description: jobDescription,
        // recruiter_id: recruiterId,
        ta_lead_id: taLead?.user_id,
        replacements: position['replacements'],
        mode_of_advertisement: JSON.stringify(checkboxes),
        documents: JSON.stringify(documents)
      }
      setLoading(true)

      axios
        .put(CREATE_POSITION_URL + position.position_id, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setLoading(false)
          // handleClose()
          // // navigate('/positions')
          // navigate('/pocdashboard')
          updatePositions()
        })
        .catch((error) => {
          setLoading(false)
          displayError(error)
        })
    }

  }

  const raiseMultipleRequisitions = async () => {
    setLoading(true)

    try {
      const totalPositions = positionLocations.length
      let completedApiCalls = 0

      for (const positionLocData of positionLocations) {
        let employeeId = null
        if (employee && positionInfo === 'replacement') {
          employeeId = employee.user_id
        }

        let documents: any = [];
        if (attachments && attachments.length > 0) {
          attachments.map((attachment) => {
            if (attachment?.fileUrl) {
              let docData = {
                title: attachment?.documentName,
                url: attachment?.fileUrl
              }
              documents.push(docData);
            }
          })
        }

        const recruiterId = recruiter?.user_id
        const data = {
          group_name: position['group_name'],
          title: position['title'],
          max_basic_salary_per_month: position['max_basic_salary_per_month'],
          max_total_remuneration_per_month: position['max_total_remuneration_per_month'],
          max_grade_range: position['max_grade_range'],
          contact_type: position['contact_type'],
          functional_area: position['functional_area'],
          sub_functional_area: position['sub_functional_area'],
          section: position['section'],
          office_type: positionLocData['officeType'],
          code: positionLocData['code'],
          location: positionLocData['location'],
          position_type: positionInfo,
          // status: 'REQUISITION_RAISED',
          status: 'GROUP_HEAD_APPROVAL',
          employee_id: employeeId,
          remarks: remarks,
          job_description: jobDescription,
          // recruiter_id: recruiterId,
          ta_lead_id: taLead?.user_id,
          mode_of_advertisement: JSON.stringify(checkboxes),
          documents: JSON.stringify(documents)
        }

        const res = await axios.put(CREATE_POSITION_URL + positionLocData.position_id, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })

        console.log(`API call for ${position.location} completed: `, res.data)
        // Increment the counter for completed API calls
        completedApiCalls++

        // Check if this is the last API call
        if (completedApiCalls === totalPositions) {
          setLoading(false)
          // show success popup
          // setShowSuccess(true)
          // handleClose()
          // navigate('/positions')
          updatePositions()
        }
      }
    } catch (err) {
      setLoading(false)
      displayError(err)
    }
  }

  const displayError = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.name === 'TokenExpiredError'
    ) {
      logout()
    } else if (error.response && error.response.data && error.response.data.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        setMessage(error.response.data.error.message)
        setShowErrorMessage(true)
      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  // filter the list of recruiters
  const handleRecruiterChange = (event) => {
    const query = event.target.value
    setRecruiterQuery(query)
    let filteredResults = recruitersList.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    setRecruiterResults(filteredResults.length > 0 ? filteredResults : recruitersList)
  }

  const handleChangeDocumentName = (e, index) => {
    let value = e.target.value;
    let data = [...attachments];
    data[index]['documentName'] = value;
    setAttachments(data);
  }

  const handleFileChange = (e, index) => {
    if (!attachments[index].file) {
      const file = e.target.files[0];
      if (file) {
        const fileType = file.type;
        const invalidFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (invalidFileTypes.includes(fileType)) {
            // Show error message or handle invalid file type case
            setMessage('Invalid file type. Please upload a different file.');
            setShowErrorMessage(true);
            return;
        }
        const formData = new FormData();
        // Append the file to the FormData object
        formData.append('file', file);
        axios.post(UPLOAD_FILE_URL, formData, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }).then((res) => {

          let data = res.data;
          const updatedAttachments = [...attachments];
          updatedAttachments[index].file = file;
          updatedAttachments[index].fileUrl = data?.url;
          setAttachments(updatedAttachments);

        }).catch((error) => {

        });

      }
    }
  };

  const handleRemoveDocument = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1); // Remove the element at the specified index
    setAttachments(updatedAttachments);
  }

  const handleAddNewAttachment = () => {
    let data = [...attachments];
    let newAttachment: Attachment = { documentName: '', file: null, fileUrl: null }
    data.push(newAttachment);
    setAttachments(data);
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={show}
      onHide={handleClose}
      backdrop={true}
      backdropClassName='modal-backdrop backdrop-blur'
    >
      <>
        <div className=' no-header-model123'>
          <div className='modal-header no-header-model123'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <label className='form-label fs-5 fw-bolder' style={{ color: '#F36523', marginLeft: '8px' }}>
                {' '}
                Requisition Opening Form{' '}
              </label>
            </div>

            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <img alt='' src={imagePath.ic_close}></img>
            </div>
          </div>
          <div className=' d-flex flex-column '>
            <form
              className='col-md-12'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>Group</label>
                  <label className='form-control label-style fs-5'>{position['group_name']}</label>
                </div>

                <div className='form-group col-md-4 px-4'>
                  <label className='form-label labelse '>Functional Area</label>
                  <label className='form-control label-style fs-5'>{position['functional_area']}</label>
                </div>

                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>Sub-Functional Area</label>
                  <label className='form-control label-style fs-5'>{position['sub_functional_area']}</label>
                </div>
              </div>
              <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>Section</label>
                  <label className='form-control label-style fs-5'>{position['section']}</label>
                </div>

                <div className='form-group col-md-4 px-4'>
                  <label className='form-label labelse '>Functional Designation</label>
                  <label className='form-control label-style fs-5'>{position['title']}</label>
                </div>

                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>Maximum Grade Range</label>
                  <label className='form-control label-style fs-5'>{position['max_grade_range']}</label>
                </div>
              </div>

              <div className='col-md-11 form-row d-flex mt-6 justify-content-spacearound' style={{ alignItems: 'baseline' }}>

                {!position?.batch_id && (<div className='form-group col-md-4'>
                  <label className='form-label labelse '>No Of Positions</label>
                  <label className='form-control label-style fs-5'>{position['no_of_positions']}</label>
                </div>)}

                {!position?.batch_id && (<div className='form-group col-md-4 px-4'>
                  <label className='form-label labelse '>Location</label>
                  <label className='form-control label-style fs-5'>{position['location']}</label>
                </div>)}

                {/* <div className='form-group col-md-4'>
                  <label className='form-label labelse '>Select Recruiter</label>
                  <input
                    type='text'
                    className='py-4 form-control  fs-5'
                    placeholder='Enter Recruiter Name/Employee ID'
                    value={recruiterQuery}
                    onChange={handleRecruiterChange}
                    onFocus={handleRecruiterChange}
                  />
                  {recruiterResults.length > 0 && (
                    <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                      {recruiterResults.map((item, index) => (
                        <li
                          key={index}
                          onClick={handleRecruiterClick(item)}
                          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                          className='hover-li'
                        >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                      ))}
                    </ul>
                  )}
                </div> */}
              </div>
              <div className='col-md-11 mt-10 '>
                <label className='form-label labelse '>Hiring Nature</label>
                <div className='col-md-9 d-flex flex-column mt-4 '>
                  <div className='form-group col-md-4 mb-4' style={{ display: 'flex' }}>
                    <input
                      type='radio'
                      id='new-requirement-radio'
                      name='hiring-nature-new-requirement'
                      value='budgeted'
                      // checked={positionInfo === 'budgeted'}
                      checked={position.position_type === 'budgeted'}
                      // onChange={(event) => handlePositionInfoChange(event)}
                      className='checks-input'
                    // disabled={true}
                    />
                    <label htmlFor='new-requirement-radio' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                      New Requirement
                    </label>
                  </div>

                  <div className='form-group col-md-5' style={{ display: 'flex' }}>
                    <input
                      type='radio'
                      id='replacement-transfer-radio'
                      name='hiring-nature-replacement'
                      value='replacement'
                      // checked={positionInfo === 'replacement'}
                      checked={position.position_type === 'replacement'}
                      // onChange={(event) => handlePositionInfoChange(event)}
                      className='checks-input'
                    // disabled={true}
                    />
                    <label htmlFor='replacement-transfer-radio' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                      Replacement/Transfer/Resignation
                    </label>
                  </div>
                </div>

              </div>
              {positionInfo === 'replacement' ? (<div className='col-md-11 mt-10 d-flex flex-column'>
                <label className='form-label labelse '>Last Approved Organogram</label>

                {(replacementEmployees && replacementEmployees.length > 0) && replacementEmployees.map((employee, index) => {
                  console.log('replacementEmployee: ', employee);
                  return (
                    <div key={employee?.user_id} className='col-auto mb-4'>
                      <label className='fs-6 fw-bolder mt-4'>{`${getFormattedDate(employee?.leaving_date)} / In replacement of "${employee?.fullName} # ${employee?.employee_id}"`}</label>
                      <div className='d-flex align-items-center mt-2'>
                        <img style={{ height: '44px', width: '44px', objectFit: 'cover', borderRadius: '50%' }} src={employee?.profile_pic ? employee?.profile_pic : imagePath.userIcon} />
                        <div className='ms-2 d-flex flex-column'>
                          <label className='fw-bolder fs-6'>{employee?.fullName}</label>
                          <label className='fw-regular fs-7'>{employee?.email}</label>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>) : (<div></div>)}
              <div className='col-md-11 my-10 justify-content-spacearound'>
                <label className='form-label labelse '> Job Description</label>

                <ReactQuill
                  value={jobDescription}
                  onChange={changeJobDescription}
                  placeholder='Enter your job description'
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ color: [] }, { background: [] }],
                      [{ align: [] }],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['link'],
                      ['clean'],
                    ],
                  }}
                  formats={['header', 'bold', 'italic', 'underline', 'strike', 'color', 'background', 'align', 'list', 'bullet', 'link']}
                  style={{ height: '120px' }}
                />
              </div>

              <div className='col-md-11 d-flex mt-10 '>

                <div className='d-flex flex-column col-md-6' >
                  <label className='form-label labelse ' style={{ marginLeft: '-2rem' }}>Mode of Job Advertisement</label>
                  <div className='form-check my-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='internal'
                      name='internal'
                      checked={checkboxes.internal}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label fw-bolder' htmlFor='internal'>
                      Internal
                    </label>
                  </div>
                  <div className='form-check mb-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='rozee'
                      name='rozee'
                      checked={checkboxes.rozee}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label fw-bolder' htmlFor='rozee'>
                      Rozee.pk
                    </label>
                  </div>
                  <div className='form-check mb-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='bop'
                      name='bop'
                      checked={checkboxes.bop}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label fw-bolder' htmlFor='bop'>
                      BOP Career Portal
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='others'
                      name='others'
                      checked={checkboxes.others}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label fw-bolder' htmlFor='others'>
                      Others
                    </label>
                  </div>
                </div>
                <div className='d-flex flex-column col-md-4'>
                  <label className='form-label labelse '>Details of Hiring</label>
                  <div className=' d-flex flex-column'>
                    <div className='form-group mt-4' style={{ display: 'flex' }}>
                      <input
                        type='radio'
                        id='budgeted-radio'
                        name='options'
                        value='budgeted'
                        checked={position.position_type === 'budgeted'}
                        className='checks-input'
                      // disabled={true}
                      />
                      <label htmlFor='budgeted-radio' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                        Budgeted
                      </label>
                    </div>

                    <div className='form-group mt-4' style={{ display: 'flex' }}>
                      <input
                        type='radio'
                        id='replacement-radio'
                        name='options'
                        value='replacement'
                        checked={position.position_type === 'replacement'}
                        className='checks-input'
                      // disabled={true}
                      />
                      <label htmlFor='replacement-radio' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                        Replacement
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              <div className='col-md-11 mt-10'>
                <label className='form-label labelse '> Upload Necessary Documents</label>
                {(attachments && attachments.length > 0) && attachments.map((attachment, index) => (
                  <div className='d-flex align-items-center mt-4'>
                    <input
                      className='form-control mw-25'
                      type='text'
                      placeholder='Document Label'
                      value={attachment.documentName}
                      onChange={(e) => handleChangeDocumentName(e, index)}
                    />

                    <div className={`mx-4 px-4 fw-bolder ${attachment?.file ? 'file-uploaded ' : 'candi-btn2 text-white cursor-pointer '}`} style={{ padding: '0.9rem 1rem', width: '8rem', textAlign: 'center' }}>
                      {attachment?.file ? 'Uploaded' : (
                        <label>
                          Upload
                          <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileChange(e, index)}
                          />
                        </label>
                      )}
                    </div>

                    {attachment?.file && (
                      <div className='px-3 py-2 fw-semibold d-flex align-items-center' style={{ borderRadius: '18px', backgroundColor: '#FFF6ED', color: '#C4320A' }}>
                        {attachment.file?.name}
                        <img className='ms-2 cursor-pointer' src={imagePath.ic_cross_orange} width={14} height={14}
                          onClick={() => handleRemoveDocument(index)} />
                      </div>
                    )}

                  </div>
                ))}
                <div className='mw-25 mt-4 justify-content-center cursor-pointer d-flex fs-1 py-1' style={{ borderRadius: '6px', border: '1px #98A2B3 dotted', color: '#98A2B3' }}
                  onClick={() => handleAddNewAttachment()}>
                  +
                </div>
              </div>

              {positionInfo === 'replacement' ? (
                <div className='col-md-11 mb-6 justify-content-spacearound'>

                </div>
              ) : (
                <div className='d-flex col-md-11 mt-10'>
                  <label className='form-label labelse me-6'>{`Basic Salary Per Month: PKR ${position?.max_basic_salary_per_month ? parseInt(position?.max_basic_salary_per_month).toLocaleString() : 0
                    }`}</label>
                  <label className='form-label labelse '>{`Total Remuneration Per Month: PKR ${position?.max_total_remuneration_per_month ? parseInt(position?.max_total_remuneration_per_month).toLocaleString() : 0
                    }`}</label>
                </div>
              )}
              {position?.batch_id && (
                <div className='table-responsive col-md-11 justify-content-spacearound'>
                  <table className='table custom-table' style={{ borderRadius: '10px' }}>
                    <thead style={{ borderRadius: '10px' }}>
                      <tr className='table-header' style={{ borderRadius: '10px' }}>
                        <th scope='col' className=' form-label fw-bolder fs-6 ps-4'>
                          Position
                        </th>
                        <th scope='col' className=' form-label fw-bolder fs-6'>
                          Code
                        </th>
                        <th scope='col' className=' form-label fw-bolder fs-6'>
                          Region
                        </th>
                        <th scope='col' className=' form-label fw-bolder fs-6'>
                          Location
                        </th>
                        <th scope='col' className=' form-label fw-bolder fs-6'>
                          No. of Positions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(positionLocations && positionLocations.length > 0) ?
                        positionLocations.map((row, index) => {

                          return (
                            <tr key={index}>
                              <td className='pt-3 ps-4'>{position?.title}</td>
                              <td className='pt-3'>{row?.code}</td>
                              <td className='pt-3'>{row?.officeType}</td>
                              <td className='pt-3'>{row.location}</td>
                              <td className='pt-3'>{row.no_of_positions}</td>
                            </tr>
                          )
                        })
                        : (<div></div>)}
                    </tbody>
                  </table>
                </div>
              )}
              <div className='col-md-11 mt-10 '>
                <label className='form-label labelse '>Remarks/Justification</label>
                <textarea
                  className='form-control'
                  placeholder='Type Here'
                  rows={5}
                  value={remarks}
                  onChange={(event) => handleRemarksChange(event)}
                />
              </div>
              <div className='col-md-11 mt-10 d-flex'>
                <div className='col-md-3 d-flex flex-column'>
                  <label className='form-label labelse'>Group Head</label>
                  <div className='d-flex' >
                    {/* <div className='orange-tag d-flex align-items-center' style={{ display: 'inline-block' }}>
                      <img src={groupHead?.profile_pic ? groupHead?.profile_pic : imagePath.userIcon} style={{ border: '1px solid #F36523', borderRadius: '50%', objectFit: 'cover', width: '48px', height: '48px' }} />
                      <label className='ms-4 fs-5 fw-semibold'>{groupHead?.ame}</label>
                    </div> */}
                    <div className='d-flex align-items-center mt-2'>
                      <img style={{ height: '44px', width: '44px', objectFit: 'cover', borderRadius: '50%' }} src={groupHead?.profile_pic ? groupHead?.profile_pic : imagePath.userIcon} />
                      <div className='ms-2 d-flex flex-column'>
                        <label className='fw-bolder fs-6'>{groupHead?.name}</label>
                        <label className='fw-regular fs-7'>{groupHead?.email}</label>
                      </div>
                    </div>
                  </div>
                </div>
                {rejectionReason && (
                  <div className='col-md-8 d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <label className='fs-5 fw-bolder me-4'>Comments</label>
                      <label className='fs-7 fw-regular' style={{color:'#98A2B3'}}>{ getFormattedDate(rejectionReason?.date)}</label>
                    </div>
                    <p className='fs-6 fw-semibold'>{rejectionReason?.reason}</p>
                  </div>
                )}
              </div>
            </form>

          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <form>
            <div className='btn-container'>
              <button
                onClick={handleSubmit}
                type='button'
                className='btn px-10 apply-review-button text-white'
              // disabled={(positionInfo === 'replacement' && !employee)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default RequisitionFormPopup
