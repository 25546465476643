//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyleDoc.css'
import Layout from '../images/Layout-4-blocks (3).png'
// import AvatarIcon from '../img/Group 9681.png'
import AvatarIcon from '../../../../app/modules/JobsFunneling/img/Group 9681.png';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { layouts } from 'chart.js';
import imagePath from '../../../../constants/imagePath';
import ShowError from '../../content/toasts/ShowError';
const API_URL = process.env.REACT_APP_API_URL;
const GET_APPLICATION_STATUS_URL = `${API_URL}/panel/get-members-status`;

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
    show: boolean
    jobAppId: string
    stage: string
    handleClose: () => void
}

// create a component
const ShowCurrentStatus = ({ show, jobAppId, stage, handleClose }: Props) => {
    const { auth, logout } = useAuth()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [members, setMembers] = useState<any>();

    const fetchMembersStatus = () => {
        let data = {
            "jobapp_id": jobAppId,
            "name": stage
        }
        axios.post(GET_APPLICATION_STATUS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data;
            let users: any = [];
            const membersList = data?.members;
            let status = 'Pending';
            if (membersList) {
                let member = membersList[0];
                if (member) {
                    let statusValue = member?.status;
                    if (statusValue) {
                        if (statusValue === 'NOT APPROVED') {
                            status = 'Rejected';
                        } else if (statusValue === 'APPROVED') {
                            status = 'Approved';
                        } else {
                            status = 'Approved';
                        }
                    } else {
                        status = 'Pending';
                    }
                }
            }
            // interview stage status
            if (stage === 'stage1' || stage === 'stage2' || stage === 'stage3') {
                // let status = data?.status
                let approvalsData = data?.approvals;
                if (Array.isArray(approvalsData) && approvalsData.length > 0) {
                    approvalsData.forEach(approval => {

                        let currentUser = approval?.user;
                        let user = {
                            status: status,
                            // role: approval?.title,
                            role: 'Panel Approver',
                            profile_pic: currentUser?.profile_pic,
                            member_name: currentUser?.fullName,
                            email: currentUser?.email,
                            member_phone_number: currentUser?.phone_number
                        }

                        users.push(user);
                    });
                }

                if (membersList) {
                    membersList.map((member, index) => {
                        let user = {
                            status: (member?.status === 'Confirmed' || member?.status === 'score assigned') ? member?.status : status,
                            // role: member?.role === 'none' ? 'Employee' : member?.role,
                            role: 'Interviewer',
                            profile_pic: member?.profile_pic,
                            member_name: member?.member_name,
                            email: member?.email,
                            member_phone_number: member?.member_phone_number
                        }

                        users.push(user);
                    });
                }
                setMembers(users)

            }

            // if (membersList && membersList !== 'approvals') {
            //     setMembers(membersList);
            // }

            // office note approval stage status
            else if (stage === 'approvals') {
                let taLead = data?.ta;
                let taHead = data?.taHead;
                let approvalsData = data?.approvals;
                let approvingAuthorities: any = [];
                let taLeadObj = {
                    title: 'TA Lead',
                    user: taLead
                };
                let taHeadObj = {
                    title: 'TA Head',
                    user: taHead
                }

                approvingAuthorities.push(taLeadObj);
                approvingAuthorities.push(taHeadObj);
                if (Array.isArray(approvalsData) && approvalsData.length > 0) {
                    approvalsData.forEach(approval => {
                        approvingAuthorities.push(approval);
                    });
                }
                // Add status to each object in approvingAuthorities based on currentStatus
                switch (data?.status) {
                    case 'APPROVED':
                        approvingAuthorities = approvingAuthorities.map(approval => ({
                            ...approval,
                            status: 'Approved'
                        }));
                        break;
                    case 'ON_PENDING_TA_APPROVAL':
                        approvingAuthorities = approvingAuthorities.map(approval => ({
                            ...approval,
                            status: 'Pending'
                        }));
                        break;
                    case 'ON_PENDING_TA_HEAD_APPROVAL':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index === 0 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_FIRST_APPROVAL':
                    case 'ON_PENDING_1_APPROVAL':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 1 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_SECOND_APPROVAL':
                    case 'ON_PENDING_2_APPROVAL':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 2 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_THIRD_APPROVAL':
                    case 'ON_PENDING_3_APPROVAL':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 3 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_PENDING_PI_APPROVAL_1':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 4 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_PENDING_PI_APPROVAL_2':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 5 ? 'Approved' : 'Pending'
                        }));
                        break;
                    case 'ON_PENDING_PI_APPROVAL_3':
                        approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                            ...approval,
                            status: index <= 6 ? 'Approved' : 'Pending'
                        }));
                        break;
                    default:
                        if (data?.approval_status) {
                            approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                                ...approval,
                                // status: index < parseInt(currentStatus.split('_')[1]) ? 'approved' : 'pending'
                                status: index < parseInt(data?.approval_status.split('_')[2]) ? 'Approved' : 'Pending'
                            }));
                        } else {
                            approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                                ...approval,
                                status: 'Pending'
                            }));
                        }
                        break;
                }
                let users: any = [];
                if (approvingAuthorities) {
                    approvingAuthorities.map((authority, index) => {
                        let title = authority?.title;
                        if (title === 'Concerned Group Head') {
                            let user = {
                                status: authority?.status,
                                role: 'Group Head',
                                profile_pic: null,
                                member_name: 'Concerned Group Head',
                                email: '',
                                member_phone_number: ''
                            }
                            users.push(user);
                        }
                        // else if (title === 'Concerned Approving Authorities of that Grade'){
                        //     let user = {
                        //         status: authority?.status,
                        //         role: 'Group Head',
                        //         profile_pic: null,
                        //         member_name: 'Concerned Group Head',
                        //         email: '',
                        //         member_phone_number: ''
                        //     }
                        //     users.push(user);
                        // }
                        else {
                            let currentUser = authority?.user;
                            let user = {
                                status: authority?.status,
                                role: currentUser?.role === 'none' ? 'Employee' : currentUser?.role,
                                profile_pic: currentUser?.profile_pic,
                                member_name: currentUser?.fullName,
                                email: currentUser?.email,
                                member_phone_number: currentUser?.phone_number
                            }
                            users.push(user);
                        }
                    })
                }
                setMembers(users)
            } else {
                if (membersList) {
                    setMembers(membersList);
                }
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                logout()
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        });
    };

    useEffect(() => {
        (async () => {
            fetchMembersStatus();
        })();
    }, [])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-800px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div className=' ' style={{ borderRadius: '8px' }}>
                    <div className='modal-header no-header-model123'>
                        <h2></h2>
                        {/* begin::Close */}
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                            <img alt='' src={imagePath.ic_close}></img>
                        </div>
                    </div>
                    {/* end::Close */}
                    <div className='mx-auto d-flex flex-column'>
                        <h3 className='form-label fw-bolder fs-4 mx-auto' style={{ color: '#484848', }}>
                            Application Status
                        </h3>
                    </div>
                </div>

                <div className='p-10'>
                    {/* <label className='fw-bolder fs-5'>Approval Authorities</label> */}

                    <div className=" mt-10 d-flex flex-wrap justify-content-center" >
                        <div className='d-flex flex-nowrap overflow-auto'>
                            {members && members.map((member, index) => {
                                const status = member?.status ? (member?.status) : 'Pending';
                                let role = member?.role;
                                if (role) {
                                    role = role === 'poc' ? 'POC' : (role === 'ta_lead' ? 'TA Lead' : (role === 'ta_head' ? 'TA Head' : (role === 'recruiter' ? 'Recruiter' : role)))
                                }
                                return (
                                    <div className='card card-border-admin me-6 mb-6'>
                                        <div className='cardboyfortherecruiter d-flex flex-center flex-column '>
                                            <div className='mb-5'>
                                                <div className='symbol symbol-75px symbol-circle'>
                                                    <img style={{ objectFit: 'cover', border: '1.5px #F36523 solid' }} alt='Pic' src={member?.profile_pic ? member?.profile_pic : imagePath.userIcon} />
                                                </div>
                                            </div>

                                            <label className='fs-5 text-gray-800text-hover-primaryfw-boldwe mb-0'>
                                                {member?.member_name}
                                            </label>

                                            <label
                                                style={{ color: '#80808F', padding: '0.4rem 0.4rem', borderRadius: '2px' }}
                                                className='btn fw-bolder btn-sm btn-light fs-7 mt-2'>
                                                Role: {`${role}`}
                                            </label>

                                            <div className='d-flex pt-3 px-2 mx-2 mb-2'>
                                                <div className='mx-2'>
                                                    <img src={imagePath.ic_mail_orange_fill}></img>
                                                </div>
                                                <label style={{ color: '#80808F', maxWidth: '13rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                    title={member?.email} className='fw-bold '>
                                                    {`${member?.email}`}
                                                </label>
                                            </div>

                                            <div className='d-flex px-2 mx-2 mb-4'>
                                                <div className='mx-2'>
                                                    <img src={imagePath.ic_phone_orange_fill}></img>
                                                </div>
                                                <label style={{ color: '#80808F' }} className='fw-bold'>
                                                    {`${member?.member_phone_number}`}
                                                </label>
                                            </div>
                                            <div
                                                className='fw-bolder'
                                                style={{ padding: '0.5rem 1rem', backgroundColor: '#FFD2A8', borderRadius: '4px', color: '#373D4A' }}
                                            >
                                                {status}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>

                </div>
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default ShowCurrentStatus;
