//import libraries
import React, {useEffect, useState, useContext} from 'react'
import imagePath from '../../../../constants/imagePath'
import axios from 'axios'
import {useAuth} from '../../auth/core/Auth'
import {LoginContext} from '../../../App'

const API_URL = process.env.REACT_APP_API_URL
const GET_USERS_URL = `${API_URL}/auth/user-list`
const GET_ACTIVITIES_URL = `${API_URL}/activity/all-activities`

// create a component
const RecentActivities = () => {
  const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const {currentUser, auth} = useAuth()
  const [allUsers, setAllUsers] = useState<any>([])
  const [selectedUser, setSelectedUser] = useState()
  const [activities, setActivities] = useState<any>()

  const getUsers = async () => {
    if (currentUser?.role === 'none') {
      return
    }
    await axios
      .get(GET_USERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let users = res?.data?.data
        if (users) {
          let userId = users[0]?.user_id
          setAllUsers(users)
          if (currentUser?.role === 'recruiter' || currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_lead' || currentUser?.role === 'ta_head') {
            userId = currentUser?.user_id
          }
          setSelectedUser(userId)
          getActivities(userId)
        }
      })
      .catch((err) => {
        console.error('err', err)
      })
  }
  const getActivities = async (userId) => {
    // e.preventDefault();

    setSelectedUser(userId)
    await axios
      .get(GET_ACTIVITIES_URL, {
        params: {
          user_id: userId,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allActivities = res?.data?.data
        if (allActivities) {
          setActivities(allActivities)
        }
      })
      .catch((err) => {
        console.error('err:', err)
      })
  }
  useEffect(() => {
    ;(async () => {
      if (currentUser?.role !== 'recruiter') {
        await getUsers()
      }
      if (currentUser?.role === 'recruiter') {
        getActivities(currentUser?.user_id)
      }
    })()
  }, [])

  const showActivity = (activity, index) => {
    let dateTime = activity?.activity_time
    // Extract the time portion
    let time = dateTime?.substring(11, 16)
    // Define an array of month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    // Parse the date string into a Date object
    const date = new Date(dateTime)
    // Get the month and date
    const month = monthNames[date.getMonth()] // Get the month (0-based index, so use getMonth())
    const day = date.getDate() // Get the day of the month

    // Format the result as 'Sep, 18'
    const formattedDate = `${month} ${day}, ${time}`
    return (
      <div className='d-flex px-4'>
        <div className='d-flex flex-column'>
          {/* <div className='justify-content-center'>
                    <img src={imagePath.userIcon} style={{ height: '44px', width: '44px', borderRadius: '50%', border: '0.86px #EF7031 solid' }} />

                    <div style={{ width: 6.83, height: 57, position: 'relative' }}>
                        <div style={{ width: 54.88, height: 0, left: 3.50, top: 1.33, position: 'absolute', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1.06px #737373 dotted' }}></div>
                        <div style={{ width: 6, height: 6, left: 0, top: 51, position: 'absolute' }}>
                            <div style={{ width: 6, height: 6, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(0deg,  0%,  100%), linear-gradient(0deg, #F57536 0%, #F57536 100%)', borderRadius: 9999 }} />
                            <div style={{ width: 4, height: 4, left: 1.83, top: 1, position: 'absolute', background: '#F57536', borderRadius: 9999 }} />
                        </div>
                        <div style={{ width: 6, height: 6, left: 0.83, top: 0, position: 'absolute' }}>
                            <div style={{ width: 6, height: 6, left: 0, top: 0, position: 'absolute', background: 'linear-gradient(0deg,  0%,  100%), linear-gradient(0deg, #F57536 0%, #F57536 100%)', borderRadius: 9999 }} />
                            <div style={{ width: 4, height: 4, left: 0.08, top: 1.72, position: 'absolute', background: '#F57536', borderRadius: 9999 }} />
                        </div>
                    </div>
                </div> */}
          <div
            style={{
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 5.28,
              display: 'inline-flex',
            }}
          >
            <div style={{width: 43, height: 43, position: 'relative'}}>
              <div style={{width: 43, height: 43, left: 0, top: 0, position: 'absolute', background: '#F5F5F5', borderRadius: 9999}} />
              <img
                style={{
                  width: 42.73,
                  height: 42.73,
                  left: 0,
                  top: 0,
                  position: 'absolute',
                  borderRadius: 9999,
                  border: '0.86px #EF7031 solid',
                }}
                src={imagePath.userIcon}
              />
            </div>
            <div style={{width: 6.83, height: 57, position: 'relative'}}>
              <div
                style={{
                  width: 54.88,
                  height: 0,
                  left: 3.5,
                  top: 1.33,
                  position: 'absolute',
                  transform: 'rotate(90deg)',
                  transformOrigin: '0 0',
                  // border: '1.06px #737373 dotted',
                  border: '1.06px #737373 dashed',
                }}
              ></div>
              <div style={{width: 6, height: 6, left: 0, top: 51, position: 'absolute'}}>
                <div
                  style={{
                    width: 6,
                    height: 6,
                    left: 0,
                    top: 0,
                    position: 'absolute',
                    background: 'linear-gradient(0deg,  0%,  100%), linear-gradient(0deg, #F57536 0%, #F57536 100%)',
                    borderRadius: 9999,
                  }}
                />
                <div style={{width: 4, height: 4, left: 0.5, top: 1, position: 'absolute', background: '#F57536', borderRadius: 9999}} />
              </div>
              <div style={{width: 6, height: 6, left: 0.83, top: 0, position: 'absolute'}}>
                <div
                  style={{
                    width: 6,
                    height: 6,
                    left: 0,
                    top: 0,
                    position: 'absolute',
                    background: 'linear-gradient(0deg,  0%,  100%), linear-gradient(0deg, #F57536 0%, #F57536 100%)',
                    borderRadius: 9999,
                  }}
                />
                <div
                  style={{width: 4, height: 4, left: -0.25, top: 1.72, position: 'absolute', background: '#F57536', borderRadius: 9999}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column ms-3'>
          {/* <div><label className='fs-6 fw-bolder'>Name - 2342</label> </div>
                    <div>
                        <label className='fs-8 fw-regular '>Role: admin</label>
                    </div> */}
          <div>
            <label className='fs-6 fw-bolder mt-3 mb-2'>{activity?.activity_heading}</label>
          </div>
          <div>
            <label className='fs-7 fw-regular mb-1'>{activity?.activity_content}</label>
          </div>
          <div>
            <label className='fs-7 fw-regular '>{formattedDate}</label>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='card-bg shadow' style={{height: '38rem'}}>
        {/* Recent Activities header */}
        <div className='d-flex px-6 pt-6'>
          <label className='fs-6 fw-bolder '>Recent Activities</label>
        </div>
        <hr className='mx-6' style={{backgroundColor: '#484848', height: '1px'}}></hr>
        {/* users view */}
        {(currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head' || currentUser?.role === 'ta_lead') && (
          <div className='d-flex align-items-center mx-4 mb-8 pb-4' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
            {allUsers &&
              allUsers.map((user, index) => {
                return (
                  <div key={index} className='d-flex align-items-center'>
                    <img
                      style={{width: 44, height: 44, borderRadius: '22px', objectFit: 'cover'}}
                      className={`${user?.user_id === selectedUser ? 'selected-user-profile' : 'user-profile'}`}
                      onClick={(e) => getActivities(user?.user_id)}
                      src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon}
                    />
                    {index < allUsers.length - 1 && (
                      <div className='mx-2' style={{backgroundColor: '#EF7031', height: '1px', width: '24px'}}></div>
                    )}
                  </div>
                )
              })}
          </div>
        )}
        <div className='d-flex flex-column' style={{maxHeight: '25rem'}}>
          <div style={{overflow: 'auto'}}>
            {activities && activities.length > 0 ? (
              activities.map((activity, index) => {
                return showActivity(activity, index)
              })
            ) : (
              <div>
                <label className='fs-5 fw-bolder'>No Activities Found</label>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

//make this component available to the app
export default RecentActivities
