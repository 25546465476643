/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState, useRef, useEffect, Fragment} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import './CreateCompetency.css'
import cross12 from '../images/Layout-4-blocks.png'
import axios from 'axios'
import camera from '../../images/Cam Icon.png'
import {useCreateSelectiveCompetecies} from '../../../../context/useCreateSlectiveCompetecies'

import addcompetenctimage from '../../images/971 [Converted].png'
import insertimage from '../../images/Group 9922.png'
import plusimagecom from '../../images/icons8-plus-math-30 (1).png'
import cross from '../../content/images/cross.png'
import LoaderView from '../JobFunnelsModels/LoaderView'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get?type=Competency`
export const CREATE_GROUP_COMPETENCY_URL = `${API_URL}/competency/create`

export const UPLOAD_GROUP_COMPETENCY_ICON = `${API_URL}/competency/upload-icon`

type Props = {
  show: boolean
  handleClose: () => void
}
const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({show, handleClose}: Props) => {
  const {auth} = useAuth()
  const [CompArray, setCompArray] = useState<any>([])
  const [CompetencyArrayinDB, setCompetencyArrayinDB] = useState<any>([])
  const {SlectedComp, setSlectedComp} = useCreateSelectiveCompetecies()
  const [display, setdisplay] = useState(false)
  const CompetenciesNamesArray: any[] = []
  const CompetenciesTypesArray: string[] = []
  const [loading, setLoading] = useState(false)
  const [imageLiiink, setimageLiiink] = useState<any>(' ')

  var TotalCompetecies
  var TotalIndivualCom = 0

  const GetAllIndiviualCompetencies = async () => {
    await axios.get(GET_ALL_INDIVIDUAL_COMPETENCY_URL).then((resp) => {
      TotalCompetecies = resp.data.competencies
      console.log(TotalCompetecies)
    })

    // console.log('TotalCompetecies in Group', TotalCompetecies)
    for (var i = 0; i < TotalCompetecies.length; i++) {
      CompetenciesTypesArray[i] = TotalCompetecies[i]['type']

      if (CompetenciesTypesArray[i] === 'Individual') {
        CompetenciesNamesArray[i] = TotalCompetecies[i]['name']
        TotalIndivualCom = TotalIndivualCom + 1
      }
    }
    if (CompetenciesNamesArray === undefined) {
      setCompArray(' ')
    } else {
      setCompArray(CompetenciesNamesArray)
    }
  }

  const addCompetency = (index: any) => {
    console.log(index)
    setdisplay(false)
    // setCompetencyArrayinDB(index)

    let arr = [index, ...CompetencyArrayinDB]
    let s = new Set(arr)

    setCompetencyArrayinDB(Array.from(s))
    // setCompetencyArrayinDB((oldArray) => [index, ...oldArray])
  }

  const removeItem = (index) => {
    setCompetencyArrayinDB([...CompetencyArrayinDB.slice(0, index), ...CompetencyArrayinDB.slice(index + 1)])
  }

  const displaySuggestions = () => {
    setdisplay(!display)
    console.log(display)
  }

  const [filebase64, setFileBase64] = useState<string>('')

  useEffect(() => {
    GetAllIndiviualCompetencies()
  }, [])

  function convertFile(files: FileList | null) {
    if (files) {
      let file = files[0]
      // Check if a file was selected
      if (!file) {
        return // No file selected, return early
      }

      // Regular expression to check if the file extension is .jpg, .jpeg, or .png
      const allowedExtensions = /\.(jpg|jpeg|png)$/i

      // Check if the file extension is allowed
      if (!allowedExtensions.test(file.name)) {
        alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
        return
      }

      // Optional: You can set a maximum file size limit if needed
      const maxSizeInBytes = 5 * 1024 * 1024 // 5MB
      if (file.size > maxSizeInBytes) {
        alert('File size exceeds the limit (5MB). Please select a smaller image.')
        return
      }

      const fileRef = files[0] || ''
      const fileType: string = fileRef.type || ''
      const reader = new FileReader()
      reader.readAsBinaryString(fileRef)
      reader.onload = (ev: any) => {
        // convert it to base64
        setFileBase64(`data:${fileType};base64,${btoa(ev.target.result)}`)
      }
      const urlforIcon = UPLOAD_GROUP_COMPETENCY_ICON
      var imagedata = JSON.stringify({icon: filebase64})

      axios
        .post(urlforIcon, imagedata, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => console.log(res.data))
        .catch((err) => console.log('err', err))
    }
  }

  const [MuteSubmit, setMuteSubmit] = useState(true)

  const [nameValidation, setnameValidation] = useState(false)
  const [descriptionValidation, setdescriptionValidation] = useState(false)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const allValid = (name, description, competencies) => {
    if (name === null || name === undefined || name === '') {
      setMessage('Name of group competency is invalid.')
      setShowErrorMessage(true)
      return false
    }

    if (description === null || description === undefined || description === '') {
      setMessage('Description of group competency is invalid.')
      setShowErrorMessage(true)
      return false
    }

    if (competencies === null || competencies === undefined || competencies.length === 0) {
      setMessage('Link individual competencies first and try again.')
      setShowErrorMessage(true)
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const {groupcompetencydescription, GroupCompetancyName} = e.target.elements

    const name = GroupCompetancyName.value
    const description = groupcompetencydescription.value
    const total_competencies = CompetencyArrayinDB?.length

    setMuteSubmit(true)
    const level = 0
    const behaviors = {}
    const total_behaviors = 0
    const type = 'Group'
    const icon = 'abc'

    let competencies = CompetencyArrayinDB

    let finalComp: any[] = []
    for (let i = 0; i < competencies.length; i++) {
      let g = {}

      g['group_' + i] = {
        id: i,
        name: competencies[i],
      }

      finalComp.push(g)
    }
    competencies = finalComp
    if (!allValid(name, description, competencies)) {
      return
    }
    setLoading(true)
    const CompetencyData = {
      name,
      description,
      total_competencies,
      total_behaviors,
      type,
      behaviors,
      competencies,
      level,
      icon,
    }
    var data = JSON.stringify(CompetencyData)
    const url = CREATE_GROUP_COMPETENCY_URL
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        handleClose()
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      backdrop={true}
    >
      <>
        <div className=' create-competency-header '>
          <div className='modal-header  create-competency-header'>
            <h2></h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <img src={imagePath.ic_cross_white} />
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column no-header-model'>
            <img className='addcompetenctimage' src={addcompetenctimage}></img>
            <h3 className='fw-bolder text-white my-5'>Create Competencies Group</h3>
          </div>
        </div>

        <div>
          <div>
            <div className='p-8'>
              <form onSubmit={handleSubmit} className='d-flex flex-column justify-content-center'>
                <div className='form-row d-flex flex-column justify-content-spacearound'>
                  <div className='form-group   col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Name</label>
                      <input
                        style={{height: '56px'}}
                        type=''
                        className='form-control bg-white'
                        id='GroupCompetancyName'
                        placeholder='Leadership'
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <div className='form-group mt-5 col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Description</label>
                      <textarea
                        style={{height: 'auto', maxHeight: '140px', resize: 'vertical'}}
                        className='form-control bg-white'
                        id='groupcompetencydescription'
                        placeholder='Type Competency (e.g: Data Analyst)'
                        autoComplete='off'
                        rows={5}
                      />
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div>
                      <label className='form-label fs-6 fw-bolder '>Add Competencies</label>
                      <input
                        style={{height: '56px'}}
                        type=''
                        className=' mb-3 form-select bg-white cursor-pointer'
                        id='competencydescription'
                        placeholder='Select Competency'
                        onClick={() => displaySuggestions()}
                        autoComplete='off'
                      />
                      <div>
                        {display === true ? (
                          <ul className='form-control'>
                            {CompArray.map((competenmcy, index) => (
                              <li
                                key={index}
                                onClick={() => addCompetency(competenmcy)}
                                className='liststyling fs-6 mb-2'
                                style={{listStyle: 'none', display: 'flex', alignItems: 'center'}}
                              >
                                {competenmcy}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                    <div className='mt-5 paddingforcomDict'>
                      {CompetencyArrayinDB.length == 0 ? (
                        <></>
                      ) : (
                        CompetencyArrayinDB.map((competenmcy, index) => (
                          <span key={index} className='orange-tag fs-7 me-3 mb-2 fw-bolder px-4 py-3 '>
                            {competenmcy} &nbsp;
                            <span>
                              <img
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                  removeItem(index)
                                }}
                                src={imagePath.ic_cross_orange}
                              />
                            </span>
                          </span>
                        ))
                      )}
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div className='me-4'>
                      <label className='form-label  fs-7 text-muted fw-bolder '>Suggested Competencies</label>
                    </div>
                  </div>

                  <div className='form-group mt-5  col-md-12'>
                    <div className=' paddingforcomDict'>
                      {CompArray.map((friend, index) => (
                        <div key={index} className='mb-2 me-2' onClick={() => addCompetency(friend)}>
                          <span className='grey-chip fs-5 fw-bold px-4 py-3 align-items-center d-flex'>
                            <img style={{cursor: 'Pointer'}} src={plusimagecom}></img>

                            <span key={index}> &nbsp;{friend}</span>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className='d-flex mt-2 flex-column'>
                  <button
                    type='submit'
                    className=' candi-btn2 btn text-white fw-bolder my-10 mx-auto  '
                    style={{textAlign: 'center', alignSelf: 'center'}}
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

export {CreateAppModal}
