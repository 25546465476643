//import liraries
import React, { Component, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { useAuth } from '../auth/core/Auth'
import imagePath from '../../../constants/imagePath'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import axios from 'axios'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import CustomPagination from '../dashboards/components/CustomPagination'
import RequisitionFormPopup from '../../../_metronic/partials/modals/JobFunnelsModels/RequisitionFormPopup'
import * as XLSX from 'xlsx'
import SalaryProgressBar from '../dashboards/components/SalaryProgressBar'
import PositionInfoCard from '../dashboards/components/PositionInfoCard'
import BudgetInfoBox from '../dashboards/components/BudgetInfoBox'
import BudgetCards from './BudgetCards'
import CircularProgressBar from '../dashboards/components/CircularProgressBar'
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';

const API_URL = process.env.REACT_APP_API_URL
// const GET_POSITIONS_URL = `${API_URL}/position/get-poc-positions`;
const GET_POSITIONS_URL = `${API_URL}/position`
const GET_JOBS_CANDIDATES_URL = `${API_URL}/analytics/dashboard-vacant-position`
const GET_POC_DATA_URL = `${API_URL}/poc/`

// create a component
const DashboardPoc = () => {
  const { auth, currentUser, logout } = useAuth()
  const user_id = currentUser?.user_id
  const intl = useIntl()
  const [greeting, setGreeting] = useState('')
  const [groupName, setGroupName] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [showRequisitionForm, setShowRequisitionForm] = useState(false)
  const [position, setPosition] = useState<any>({})
  const [allPositions, setAllPositions] = useState<any>([])
  const [selectedRange, setSelectedRange] = useState('');
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    status: 'REQUISITION_NOT_RAISED,GROUP_HEAD_APPROVAL',
    order: 'updated_at',
    sort: 'desc',
    search: '',
    page: 1,
    limit: 10,
    total: 0,
    start_date: '',
    end_date: ''
  })
  const [activeJobs, setActiveJobs] = useState(0)
  const [closedJobs, setClosedJobs] = useState(0)
  const [unattendedJobs, setUnattendedJobs] = useState(0)
  const [groupPositions, setGroupPositions] = useState<any>([])

  useEffect(() => {
    // Get the current date and time
    const now = new Date()
    const currentHour = now.getHours()

    // Determine the greeting based on the current hour
    let currentGreeting
    if (currentHour >= 5 && currentHour < 12) {
      currentGreeting = 'Good Morning'
    } else if (currentHour >= 12 && currentHour < 18) {
      currentGreeting = 'Good Afternoon'
    } else {
      currentGreeting = 'Good Evening'
    }

    // Set the greeting message
    setGreeting(currentGreeting)
  }, [])

  // fetching data of POC
  const getPOCData = async () => {
    let poc = user_id
    axios
      .get(GET_POC_DATA_URL + poc, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('pocData: ', res.data)
        let groupData = res.data.groupData

        setGroupName(groupData?.group_name)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // fetching list of all positions
  const getCreatedPositions = async () => {
    let data = { ...params }
    data.poc_id = user_id
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )

    await axios
      .get(GET_POSITIONS_URL, {
        // params: {
        //   poc_id: user_id,
        //   status: 'REQUISITION_NOT_RAISED',
        // },
        params: filteredParams,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        let data = res?.data
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination.page || 1
            paramsData.limit = pagination.limit || 10
            paramsData.total = pagination.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination.totalPages || 0)
        }
        const positions = data?.data

        if (positions) {
          setAllPositions(positions)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ; (async () => {
      await getPOCData()
      await getTotalJobsAndCandidates()
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      await getCreatedPositions()
    })()
  }, [params])

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  const openRequisitionForm = (currentPosition) => {
    console.log('currentPosition: ', currentPosition)
    if (currentPosition && Object.keys(currentPosition).length !== 0) {
      setPosition(currentPosition)
      setShowRequisitionForm(true)
    }
  }

  const [sortField, setSortField] = useState('')
  const [sortDirection, setSortDirection] = useState('asc')

  const sortByField = (field) => {
    const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
    const sorted = [...allPositions].sort((a, b) => {
      const valueA = a[field]
      const valueB = b[field]
      if (valueA < valueB) return direction === 'asc' ? -1 : 1
      if (valueA > valueB) return direction === 'asc' ? 1 : -1
      return 0
    })

    setAllPositions(sorted)
    setSortField(field)
    setSortDirection(direction)

    let data = { ...params }
    data.order = field
    data.sort = direction === 'asc' ? 'asc' : 'desc'
    setParams(data)
  }

  const renderSortIcon = (field) => {
    if (field === sortField) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
    } else {
      return <FaSort />
    }
  }

  const handleSearchKeyChange = (value) => {
    setSearchKey(value)
    // if the value of searchKey is empty set the value of search as empty too
    if (!value) {
      // setSearch('')
      let data = { ...params }
      data.search = ''
      setParams(data)
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      handleSearch(event)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = { ...params }
    if (searchKey && searchKey !== data.search) {
      // setSearch(searchKey)
      data.search = searchKey
      data.page = 1
      setParams(data)
    } else {
      // setSearch('')
      data.search = ''
      data.page = 1
      setParams(data)
    }
  }

  const handleDownloadFile = () => {
    // Manually specify columns
    const columns = ['Recruiter', 'Position', 'Group', 'Remuneration Budget', 'Status']

    // Manually create data array
    const data = allPositions.map((row) => [
      `${row?.recruiterData?.first_name} ${row?.recruiterData?.last_name}` || '',
      row?.vacantPosition || '',
      row?.department || '',
      row?.maxTotalRemunerationPerMonth || '',
      row?.status || '',
    ])

    // Create worksheet
    const ws = XLSX.utils.aoa_to_sheet([columns, ...data])

    // Create workbook and add the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'VacantPositions')

    // Download the Excel file
    XLSX.writeFile(wb, 'VacantPositionsReport.xlsx')
  }

  const getTotalJobsAndCandidates = async () => {
    let masterRecruiter = 'masterRecruiter'
    let recruiter = 'recruiter'
    console.log('userRole: ', currentUser?.role)
    if (currentUser?.role === 'none') {
      return
    }
    axios
      .get(GET_JOBS_CANDIDATES_URL, {
        params: {
          recruiter_id: currentUser?.role === recruiter ? currentUser?.user_id : 'admin',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res.data
        let positionGroupCount = data?.positionGroupCount
        if (positionGroupCount) {
          let latestPositionsData = positionGroupCount.slice(0, 3)
          // setGroupPositions(latestPositionsData)
          setGroupPositions(positionGroupCount)
        }

        let active = data?.positionsPosted || 0
        let closed = data?.positionsHired || 0
        let positionsNotPosted = data?.positionsNotPosted || 0;
        let positionsNotRaised = data?.positionsNotRaised || 0;
        let unattended = positionsNotPosted + positionsNotRaised;
        let totalJobs = active + closed + unattended
        setActiveJobs(active)
        setClosedJobs(closed)
        setUnattendedJobs(unattended)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const handleDateRangeChange = (event, picker) => {
    setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    let startDate = picker.startDate.format('MM/DD/YYYY');
    let endDate = picker.endDate.format('MM/DD/YYYY');

    let data = { ...params };
    data.start_date = startDate;
    data.end_date = endDate;
    setParams(data);
  };

  const handleCancelDatePicker = () => {
    setSelectedRange('');
    let data = { ...params };
    data.start_date = '';
    data.end_date = '';
    setParams(data);
  }

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>My Dashboard
        </h3>
      </div>

      <div className='col-md-12 d-flex mt-18'>
        {/* greeting card */}
        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
          <div className=' shadow-sm card-bg d-flex me-4 ' style={{ height: '22rem', padding: '0px', margin: '0px' }}>
            <div className='ps-6 pt-6 align-items-center my-auto'>
              <div>
                { }
                <h2 className='fw-bolder text-color mb-3 fs-1'>
                  {`Hi ${currentUser?.first_name ? currentUser?.first_name : 'POC'}`}
                  <br />
                  {greeting}
                  <span>
                    <img style={{ marginLeft: '1rem' }} src={imagePath.ic_sun}></img>
                  </span>
                </h2>

                <div className='text-muted fw-bold fs-6 mb-2'>{groupName}</div>
              </div>
            </div>
            <div className='ms-auto mt-auto' style={{ marginBottom: '-2.45rem' }}>
              <img src={imagePath.cycling} width={180} style={{ borderBottomRightRadius: '10px' }} />
            </div>
          </div>
        </div>
        {/* positions info card */}
        <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
          <div className='me-2 card-bg shadow-sm' style={{ height: '22rem' }}>
            <div>
              <label className='fs-5 fw-semibold ps-3 py-4' style={{ borderBottom: '1px solid #E5E5E5', width: '100%' }}>
                Overall Performance Summary
              </label>
            </div>
            <div className='d-flex align-items-center py-4'>
              <div className='justify-content-center align-items-center ms-3' style={{ width: '160px' }}>
                {/* <SalaryProgressBar
                                    remainingBudget={activeJobs}
                                    lapsedBudget={closedJobs}
                                    totalBudget={unattendedJobs}
                                /> */}
                <CircularProgressBar inProgress={activeJobs} closedPositions={closedJobs} unAttendedPositions={unattendedJobs} />
              </div>
              <div className='d-flex flex-column justify-content-center ms-auto me-3'>
                <BudgetInfoBox type='InProgress' value={activeJobs} color='lightOrange' />
                <div className='my-2'>
                  <BudgetInfoBox type='Closed' value={closedJobs} color='darkOrange' />
                </div>
                <BudgetInfoBox type='Unattended' value={unattendedJobs} color='gray' />
              </div>
            </div>
          </div>
        </div>
        {/* budget info card */}
        <div className='col-6 col-sm-6 col-md-3 col-lg-3'>
          <BudgetCards groupName={groupName} />
        </div>
      </div>

      {/* positions view */}
      <div className='col-md-12'>
        <div className=' card-bg shadow-sm pt-8 px-8 mt-8 pb-10' style={{ borderRadius: '1rem', alignSelf: 'center' }}>
          <div className='d-flex mb-4 align-items-center'>
            <img src={imagePath.ic_chair} alt='position' width={24} height={24} />
            <label className='fs-4 fw-bolder ms-2 me-2'>Positions</label>
            <div className='ms-auto d-flex align-items-center'>
              <div className='d-flex me-4' style={{ border: 'solid 1px #F57536', borderRadius: '4px', height: '32px' }}>
                <input
                  style={{ width: '16rem' }}
                  className='search-bar fs-6 ps-2 py-1'
                  type='text'
                  placeholder='Search'
                  value={searchKey}
                  onChange={(event) => handleSearchKeyChange(event.target.value)}
                  onKeyPress={handleKeyPress}
                />
                <div
                  className='d-flex align-items-center px-2'
                  style={{
                    cursor: 'pointer',
                    color: '#FFFFFF',
                    backgroundColor: '#F57536',
                    height: '31px',
                    borderRadius: '0px 4px 4px 0px',
                  }}
                  onClick={(e) => handleSearch(e)}
                >
                  <label className='fs-7 fw-bolder' style={{ color: '#FFFFFF', marginLeft: '4px' }}>
                    Search{' '}
                  </label>
                  <img className='search-icon ms-2' src={imagePath.ic_search_white} />
                </div>
              </div>
              <DateRangePicker
                onApply={handleDateRangeChange}
                onCancel={handleCancelDatePicker}>
                <img src={imagePath.ic_calendar_orange} width={32} height={32} style={{ borderRadius: '4px', border: 'solid #F57536 1px' }} />
              </DateRangePicker>
              <img
                className='ms-4'
                src={imagePath.ic_download}
                width={32}
                height={32}
                style={{ borderRadius: '4px', border: 'solid #F57536 1px', padding: '5px', cursor: 'pointer' }}
                onClick={() => handleDownloadFile()}
                title='Download Report'
              />
              <img
                className='ms-2'
                style={{ cursor: 'pointer' }}
                src={imagePath.ic_refresh_orange}
                width={24}
                height={24}
                onClick={() => getCreatedPositions()}
              />
            </div>
          </div>

          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-borders'>
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  <th
                    onClick={() => sortByField('title')}
                    scope='col'
                    className='align-items-center ps-4'
                    style={{ width: '10rem', color: '#000000', whiteSpace: 'nowrap' }}
                  >
                    <label className=' form-label fw-bolder fs-6 me-2'>Positions</label>
                    {renderSortIcon('title')}
                  </th>
                  <th onClick={() => sortByField('max_basic_salary_per_month')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>
                      Max Monthly <br /> Salary
                    </label>
                    {renderSortIcon('max_basic_salary_per_month')}
                  </th>
                  <th onClick={() => sortByField('max_total_remuneration_per_month')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>
                      Max Monthly <br /> Remuneration
                    </label>
                    {renderSortIcon('max_total_remuneration_per_month')}
                  </th>
                  <th onClick={() => sortByField('max_grade_range')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>
                      Maximum Grade <br /> Range
                    </label>
                    {renderSortIcon('max_grade_range')}
                  </th>
                  <th onClick={() => sortByField('no_of_positions')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>No. of Positions</label>
                    {renderSortIcon('no_of_positions')}
                  </th>
                  <th onClick={() => sortByField('location')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Location</label>
                    {renderSortIcon('location')}
                  </th>
                  <th onClick={() => sortByField('contact_type')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Contract Type</label>
                    {renderSortIcon('contact_type')}
                  </th>
                  <th scope='col' className=' form-label fw-bolder fs-6'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Action</label>
                  </th>
                </tr>
              </thead>
              <tbody>
                {allPositions &&
                  allPositions.map((row, index) => {
                    let salaryPerMonth: any = row?.max_basic_salary_per_month ? parseInt(row?.max_basic_salary_per_month) : 0
                    salaryPerMonth = salaryPerMonth.toLocaleString()
                    let RemunerationPerMonth: any = row?.max_total_remuneration_per_month
                      ? parseInt(row?.max_total_remuneration_per_month)
                      : 0
                    RemunerationPerMonth = RemunerationPerMonth.toLocaleString()
                    const isEvenRow = index % 2 === 0
                    let locationInfo = '';
                    if (row?.batch_positions !== null && row?.batch_positions !== undefined) {
                      let locations = row?.batch_positions?.locations;
                      locationInfo = locations?.map((location) => location).join(', ');
                    } else {
                      locationInfo = row?.location
                    }
                    return (
                      <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                        <td className=' fs-6 ps-2'>{row.title}</td>
                        <td>{`PKR ${salaryPerMonth}`}</td>
                        <td>{`PKR ${RemunerationPerMonth}`}</td>
                        <td>{row.max_grade_range}</td>
                        <td>{row.no_of_positions ? row.no_of_positions : '1'}</td>
                        <td>{locationInfo}</td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <span className='me-4'>{row.contact_type}</span>
                          </div>
                        </td>
                        <td className='pe-2'>
                          <button
                            className={`fw-bolder btn fs-6 ${row?.status === 'REQUISITION_NOT_RAISED' ? 'candi-btn2 text-white text-white':'orange-tag'}`}
                            style={{ whiteSpace: 'nowrap', width:'12rem' }}
                            onClick={() => {
                              if (row?.status === 'REQUISITION_NOT_RAISED') {
                                openRequisitionForm(row)
                              } else {

                              }
                            }}
                          >
                            {`${row?.status === 'REQUISITION_NOT_RAISED' ? 'Raise Requisition' : 'Approval Pending'}`}
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
          <div className='d-flex col-md-12 align-items-center mt-6'>
            <div className='col-md-4'>
              <label>{`showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showRequisitionForm && (
        <RequisitionFormPopup
          show={true}
          handleClose={() => {
            setShowRequisitionForm(false)
          }}
          updatePositions={() => {
            setShowRequisitionForm(false)
            getCreatedPositions()
          }}
          position={position}
        />
      )}

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default DashboardPoc
