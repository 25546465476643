/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './CreateCompetency.css'
import { useState } from 'react'
import Layout from '../images/Layout-4-blocks (3).png'

import addcompetenctimage from '../../images/warning@2x.png'
// import {AuthModel, UserModel} from '../../../../app/modules/auth/core/_models'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

import cross from '../images/Layout-4-blocks.png'

import axios from 'axios'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const CREATE_LEVEL = `${API_URL}/level/create/`

export const GET_CREATE_LEVEL = `${API_URL}/level/get`

export const Delete_level = `${API_URL}/level/`

type Props = {
  show: boolean
  handleClose: () => void
  handleUpdateLevels: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const LevelChangePopUp5 = ({
  show,
  handleClose,
  handleUpdateLevels,
}: Props) => {
  const { auth } = useAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    handleUpdateLevels();
  }

  // useEffect(() => {
  //   await axios.get
  // })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' noheadermofeaf no-header-model123'>
        <div className='modal-header  noheadermofeaf'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={imagePath.ic_close}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' className='addcompetenctimage123' src={addcompetenctimage}></img>
          <h3 className='fw-bolder mx-auto my-5'>System Level Change </h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label className='text-centerform-labelmx-autofs-5  '>
                  This is a system level change and may have an impact upon other elements of the
                  recruitment system like assessments, interviews, etc.
                </label>

                <br></br>
                <label className='text-centerform-labelmx-autofs-5  '>
                  Are you sure you want to make make this change?
                </label>
              </div>
              <div
                // className='sureunsure'
                className='d-flex justify-content-center align-items-center my-10'
              >
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn px-8 me-8 bgcolorofbutoonofsubmitoftheaddingnewrecruiter '
                  style={{height:'48px'}}
                >
                  Yes, I am sure!
                </button>
                <button onClick={handleClose} className='btn px-8 bgcolorofbutoonofsubmit '
                style={{height:'48px'}}>
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { LevelChangePopUp5 }
