import {useState} from 'react'
import './Competencies.css'
// import CompetenciesGroup from './Pages/CompetenciesGroup'
import IndividualCompetencies from './Pages/CompetenciesIndividual'
import {useCompetenciesButtonContext} from '../../../context/useCreateCompetenciesButton'
import GroupCompetencies from './Pages/GroupCompetencies'

const CompetenciesTabs = () => {
  const [IndividualTab, setIndividualTab] = useState(true)
  const {CompetenciesButton, setCompetenciesButton} = useCompetenciesButtonContext()

  const ChangeInActiveIndex = (ActiveState) => {
    if (ActiveState === 'ActiveButtonClicked') {
      // setCompetenciesButton('individualCompetency')
      setIndividualTab(true)
    } else if (ActiveState === 'InActiveButtonClicked') {
      setIndividualTab(false)
      // setCompetenciesButton('groupCompetency')
    }
  }

  return (
    <>
      {/* begin::Row */}
      <div className='d-flex justify-content-center gy-5 gx-xl-8'>
        <button
          className={`${
            IndividualTab === true
              ? 'Onclicked-Active-Button btn-bg-white'
              : 'Notclicked-Active-Button text-white'
          } btn fw-bolder Activejobs-Leftbutton fs-4 custom-button-size btn-lg`}
          style={{minWidth:'18vw'}}
          type='button'
          onClick={() => ChangeInActiveIndex('ActiveButtonClicked')}
        >
          Individual Competencies
        </button>
        <button
          type='button'
          onClick={() => ChangeInActiveIndex('InActiveButtonClicked')}
          className={`${
            IndividualTab === true
              ? 'group-competencies-btn-not-active text-white'
              : 'group-competencies-btn-active btn-bg-white'
          } btn fw-bolder fs-4 custom-button-size btn-lg`}
          style={{minWidth:'18vw'}}
        >
          Group Competencies
        </button>
      </div>
      {IndividualTab === true ? (
        <>
          <IndividualCompetencies />
        </>
      ) : (
        <>
          {/* <CompetenciesGroup /> */}
          <GroupCompetencies />
        </>
      )}
    </>
  )
}

export default CompetenciesTabs