import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import CompetenciesTabs from './CompetenciesTabs'
import { useCompetenciesButtonContext } from '../../../context/useCreateCompetenciesButton'

const CompetenciesWrapper = () => {
  const intl = useIntl()
  const { CompetenciesButton, setCompetenciesButton } = useCompetenciesButtonContext()

  useEffect(() => {
    setCompetenciesButton('individualCompetency')

    return () => {
      setCompetenciesButton('')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Competencies' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/competencies" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Competencies
          </Link>
        </h3>
      </div>
      {/* begin::Row */}
      <CompetenciesTabs />
    </>
  )
}

export default CompetenciesWrapper
