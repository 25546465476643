/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef } from 'react'
import { useState, useEffect } from 'react'
import { RejectUserbyApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/RejectUserApplicationInterview'
import { ApproveUserApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserApplicationInterview'
import { ApproveAllUserApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplicationInterview'
import { ApproveAllUserApplicationInterviewStage2 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplicationInterviewStage2'
import { ApproveAllUserApplicationInterviewStage3 } from '../../../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplicationInterviewStage3'
import { RejectAllUserApplicationInterview } from '../../../../../_metronic/partials/modals/JobFunnelsModels/RejectAllUserApplicationInterview'
import { AddScoreInterviewpopup } from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddScoreInterviewPop'
import unchecked from '../../images/Group 10062.png'
import checked from '../../../auth/images/tick.png'
import './InterViewMainScreen.css'
import threeedota from '../../images/menu.png'
import axios from 'axios'
import InterviewStages from './InterviewStages'
import { useAuth } from '../../../auth'
import AvatarIcon from '../../img/Group 9681.png'
import { useNavigate } from 'react-router-dom'
import StageProgress from '../../img/Stage1Active.png'
import StageProgress2 from '../../img/Stage2Active.png'
import StageProgress3 from '../../img/Stage3Active.png'
import stage1Inctive from '../../img/stag1Inactive.png'
import stage2Inactive from '../../img/stage2Inactive.png'
import stage3Inactive from '../../img/stage3Inactive.png'
import Stage1, { Stage1Ref } from './InterviewStages'
import Stage2, { Stage2Ref } from './InterviewStage2'
import Stage3, { Stage3Ref } from './InterviewStage3'
import { GET_TEST_DETAILS } from '../../../../../_metronic/partials/content/cards/Card2'
import RefreshIcon from '../../img/history_black_24dp@2x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
import ScheduleInterViewMultipleCandidatesStage1 from '../../../../../_metronic/partials/modals/JobFunnelsModels/ScheduleInterViewMultipleCandidatesStage1'
import WarningTARemovePopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import imagePath from '../../../../../constants/imagePath'
import clsx from 'clsx'

interface Resource {
  candidate_details: Object
  resource: Object
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const FETCH_AVG_SCORE = `${API_URL}/jobapplication/set-stages-interview-average-score`

export const JOB_STAGES_DETAILS = `${API_URL}/jobapplication/get-all-stages`
export const ACCEPT_ALL_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application-multiple`
const CANCEL_INTERVIEWS_URL = `${API_URL}/panel/cancel-schedule`

type Props = {
  job_id: any
  testExists: any
  trigger: any
}

const InterviewMainScreen: React.FC<Props> = ({ job_id, trigger }) => {
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const [popup, setpopup] = useState(<></>)
  const [approveAllPopupStage1, setApproveAllPopupStage1] = useState(<></>)
  const [approveAllPopupStage2, setApproveAllPopupStage2] = useState(<></>)
  const [approveAllPopupStage3, setApproveAllPopupStage3] = useState(<></>)
  const [rejectAllPopup, setRejectAllPopup] = useState(<></>)
  const [addScorepop, setaddScorepop] = useState(<></>)
  const [checkedstate, setcheckedstate] = useState(false)
  const [multioptiondots, setmultioptiondots] = useState(false)
  const [StateMulti, setsetState] = useState()
  const [popshow, setpopshow] = useState(true)
  const Multioption = ['Aprove All', 'Reject All']
  const [dataFetched, setDataFetched] = useState(false)
  const { auth, logout } = useAuth()
  const [activeStep, setActiveStep] = useState(1)
  const [approveAllPopup, setApproveAllPopup] = useState(<></>)
  const [interviewStages, setInterviewStages] = useState<any>(0)
  const [avgScore, setAvgScore] = useState<any>(['Confirmation Pending'])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedApplicants, setSelectedApplicants] = useState<any>([])
  const [interviewConductedFound, setInterviewConductedFound] = useState(false);
  const [scheduleMultipleCandidateInterview, setScheduleMultipleCandidateInterview] = useState(false)
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const [action, setAction] = useState('')
  const [currentStage, setCurrentStage] = useState('')
  const [nextStage, setNextStage] = useState('')
  const updateApplicationsStage1Ref = useRef<Stage1Ref>(null)
  const updateApplicationsStage2Ref = useRef<Stage2Ref>(null)
  const updateApplicationsStage3Ref = useRef<Stage3Ref>(null)
  const dropdownRefAction = useRef<HTMLDivElement>(null)

  const handleRefreshClicked = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }

  const GETSTAGESDATA = async () => {
    await axios
      .post(
        JOB_STAGES_DETAILS,
        {
          job_id: job_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setInterviewStages(res?.data?.numberOfStages)
        console.log('res-stages', { res })
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const createApproveAllPopup = (jobapp_id) => {
    setApproveAllPopup(
      <ApproveAllUserApplicationInterview
        show={true}
        jobapp_id={jobapp_id}
        handleClose={() => {
          setApproveAllPopup(<></>)
          setDataFetched(false)
        }}
      />
    )

  }

  const createRejectAllPopup = (jobapp_id) => {
    setRejectAllPopup(
      <RejectAllUserApplicationInterview
        show={true}
        jobapp_id={jobapp_id}
        handleClose={() => {
          setRejectAllPopup(<></>)
          setDataFetched(false)
        }}
      />
    )
  }

  const setMultiState = (value) => {
    setsetState(value)
    if (value === 'Aprove All') {
      setcheckedstate(true)
    }

    if (value === 'Reject All') {
      setcheckedstate(true)
      createRejectAllPopup('Test')
      // setSelectedPopup(rejectAllPopup)
      createRejectAllPopup('')
    }
    setmultioptiondots(!multioptiondots)
  }

  const DisplayAllAoptiontoadopt = () => {
    setmultioptiondots(!multioptiondots)
  }

  const checkedStateFunction = () => {
    setcheckedstate((prev) => !prev)
  }

  const handleStage1Click = () => {
    setActiveStep(1)
    setCurrentStage('stage1')
    if (interviewStages === '1') {
      setNextStage('approvals')
    } else {
      setNextStage('stage2')
    }
    setSelectedApplicants([])
  }

  const handleStage2Click = () => {
    setActiveStep(2)
    setCurrentStage('stage2')
    if (interviewStages === '2') {
      setNextStage('approvals')
    } else {
      setNextStage('stage3')
    }
    setSelectedApplicants([])
  }

  const handleStage3Click = () => {
    setActiveStep(3)
    setCurrentStage('stage3')
    setNextStage('approvals')
    setSelectedApplicants([])
  }

  const FETCHSCORE = async () => {
    setLoading(true)
    let jobapp_stage
    if (activeStep === 1) {
      jobapp_stage = 'stage1'
      setCurrentStage('stage1')
      if (interviewStages === '1') {
        setNextStage('approvals')
      } else {
        setNextStage('stage2')
      }
    } else if (activeStep === 2) {
      jobapp_stage = 'stage2'
      setCurrentStage('stage2')
      if (interviewStages === '2') {
        setNextStage('approvals')
      } else {
        setNextStage('stage3')
      }
    } else if (activeStep === 3) {
      jobapp_stage = 'stage3'
      setCurrentStage('stage3')
      setNextStage('approvals')
    }

    try {
      const response = await axios.post(
        FETCH_AVG_SCORE,
        {
          job_id: job_id,
          jobapp_stage: jobapp_stage,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      setLoading(false)
      setAvgScore(response.data)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const handleRefreshClick = () => {
    FETCHSCORE()

    // setTimeout(() => {
    //   setLoading(false)
    // }, 2000)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRefAction.current && !dropdownRefAction.current.contains(event.target)) {
        setmultioptiondots(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    (async () => {
      await GETSTAGESDATA()
      FETCHSCORE()
    })()
  }, [activeStep, interviewStages])

  useEffect(() => {
    (() => {
      // check either interview has been conducted for selected applicant or not
      if (selectedApplicants !== null && selectedApplicants !== undefined && selectedApplicants.length > 0) {
        let isInterviewConducted = selectedApplicants.some(applicant => applicant.jobapp_status === "Interview Conducted");
        setInterviewConductedFound(isInterviewConducted);
      }else{
        setInterviewConductedFound(false);
      }
    })();
  }, [selectedApplicants]);

  const updateSelectedApplicants = (candidateApplication) => {
    console.log('selected: candidateApplication: ', candidateApplication);
    setSelectedApplicants(candidateApplication)
  }

  const approveMultiple = async () => {
    setAction('')
    // call API to approve multiple candidates
    let jobapp_list: any = []
    console.log('selectedApplicants: ', selectedApplicants);
    // if (!selectedApplicants) {
    //   return
    // }
    setWarningMessage('')
    setShowWarningPopup(false)
    selectedApplicants?.map((candidateApplication) => {
      jobapp_list.push(candidateApplication?.jobapp_id)
    })
    setLoading(true)
    const data = {
      jobapp_id: jobapp_list,
      currentStage: currentStage,
      newStage: nextStage,
    }
    await axios
      .post(ACCEPT_ALL_JOB_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        setSelectedApplicants([])
        // updated the list of applications
        // refresh the list of applications
        if (activeStep === 1) {
          updateApplicationsStage1Ref.current?.GetAllJobsApplicationsbypage()
        } else if (activeStep === 2) {
          updateApplicationsStage2Ref.current?.GetAllJobsApplicationsbypage()
        } else {
          updateApplicationsStage3Ref.current?.GetAllJobsApplicationsbypage()
        }
      })
      .catch((error) => {
        setLoading(false)

        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const cancelInterviews = async () => {
    setAction('')
    let jobapp_list: any = []
    if (!selectedApplicants) {
      return
    }
    setWarningMessage('')
    setShowWarningPopup(false)
    selectedApplicants?.map((candidateApplication) => {
      jobapp_list.push(candidateApplication?.jobapp_id)
    })
    setLoading(true)
    const data = {
      // jobapp_id: jobapp_list[0],
      jobapp_list: jobapp_list,
      getStage: currentStage,
      // newStage: nextStage,
    }
    await axios
      .post(CANCEL_INTERVIEWS_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // updated the list of applications
        if (activeStep === 1) {
          updateApplicationsStage1Ref.current?.GetAllJobsApplicationsbypage()
        } else if (activeStep === 2) {
          updateApplicationsStage2Ref.current?.GetAllJobsApplicationsbypage()
        } else {
          updateApplicationsStage3Ref.current?.GetAllJobsApplicationsbypage()
        }
      })
      .catch((error) => {
        setLoading(false)

        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  return (
    <>
      <div style={{ margin: '8px 0px 0px 0px' }} className={`card shadow-sm mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header card-header1234864  border-0 pt-5'>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: '1',
            }}
          >
            <div className='Interview-stages-container'>
              {interviewStages >= 1 && (
                <div className={`Interview-stage-icon-${activeStep === 1 ? 'active' : 'inactive'}`}>
                  <label className={`cursor-pointer Interview-stage-text-${activeStep === 1 ? 'active' : 'inactive'}`} onClick={handleStage1Click}>
                    <span>
                      <img style={{ height: '2vh' }} src={activeStep === 1 ? StageProgress : stage1Inctive} />
                    </span>
                    Stage 1
                  </label>
                </div>
              )}
              {interviewStages >= 2 && (
                <div className={`Interview-stage-icon-${activeStep === 2 ? 'active' : 'inactive'}`}>
                  <label className={`cursor-pointer Interview-stage-text-${activeStep === 2 ? 'active' : 'inactive'}`} onClick={handleStage2Click}>
                    <span>
                      <img style={{ height: '2vh' }} src={activeStep === 2 ? StageProgress2 : stage2Inactive} />
                    </span>
                    Stage 2
                  </label>
                </div>
              )}
              {interviewStages >= 3 && (
                <div className={`Interview-stage-icon-${activeStep === 3 ? 'active' : 'inactive'}`}>
                  <label className={`cursor-pointer Interview-stage-text-${activeStep === 3 ? 'active' : 'inactive'}`} onClick={handleStage3Click}>
                    <span>
                      <img style={{ height: '2vh' }} src={activeStep === 3 ? StageProgress3 : stage3Inactive} />
                    </span>
                    Stage 3
                  </label>
                </div>
              )}
            </div>

            <div className='d-flex align-items-center'>

              <img className='me-4 cursor-pointer'
                title='Refresh data'
                src={imagePath.ic_refresh_orange}
                width={32}
                height={32}
                onClick={handleRefreshClick} />

              <div
                ref={dropdownRefAction}
                className={clsx(`d-flex align-items-center dropdown ${multioptiondots ? 'open' : ''}`)} id='kt_actions_applicants_toggle'>
                <div
                  className={clsx('cursor-pointer symbol')}
                  data-kt-menu-trigger='click'
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  // data-kt-menu-placement='bottom-start'
                  data-kt-menu-flip='bottom'
                  onClick={DisplayAllAoptiontoadopt}
                >
                  <div className='d-flex align-items-center justify-content-center candi-btn2 px-3' style={{ height: '2.85rem' }}>
                    <label className='text-white fs-5 fw-bolder cursor-pointer me-1'>Actions</label>
                    <img className='cursor-pointer' src={imagePath.ic_arrow_down_white} width={18} height={18} style={{ objectFit: 'cover' }} />
                  </div>
                </div>

                <div
                  className={clsx(
                    'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-175px',
                    {
                      show: multioptiondots,
                    }
                  )}
                  data-kt-menu='true'
                >
                  <div style={{ backgroundColor: '#0000' }}>
                    <div>
                      <div
                        onClick={() => {
                          // createApproveAllPopup(job_id)

                          if (selectedApplicants && selectedApplicants.length > 0) {
                            if (nextStage === 'approvals') {
                              setAction('approval')
                              setWarningMessage('Are you sure you want to approve selected Candidates')
                              setShowWarningPopup(true)
                            } else {
                              setScheduleMultipleCandidateInterview(true)
                            }
                          } else {
                            setMessage('Please select one or more applications first and try again')
                            setShowErrorMessage(true)
                          }
                          DisplayAllAoptiontoadopt()
                        }}
                        className='py-2 px-4 liststyling'
                        style={{ listStyle: 'none' }}
                      >
                        Approve All
                      </div>
                      <div
                        onClick={() => {
                          createRejectAllPopup(job_id)
                          DisplayAllAoptiontoadopt()
                        }}
                        className='py-2 px-4 liststyling'
                        style={{ listStyle: 'none' }}
                      >
                        Reject All
                      </div>
                      {!interviewConductedFound && (<div
                        onClick={() => {
                          if (selectedApplicants && selectedApplicants.length > 0) {
                            setAction('cancelInterviews')
                            setWarningMessage('Are you sure you want to cancel interviews of selected Candidates?')
                            setShowWarningPopup(true)
                          } else {
                            setMessage('Please select one or more applications first and try again')
                            setShowErrorMessage(true)
                          }
                          DisplayAllAoptiontoadopt()
                        }}
                        className='py-2 px-4 liststyling'
                        style={{ listStyle: 'none' }}
                      >
                        Cancel Interview For All
                      </div>)}
                      {!interviewConductedFound && (<div
                        onClick={() => {
                          if (selectedApplicants && selectedApplicants.length > 0) {
                            setAction('reScheduleInterviews')
                            setWarningMessage('Are you sure you want to Reschedule interview of selected Candidates?')
                            setShowWarningPopup(true)
                          } else {
                            setMessage('Please select one or more applications first and try again')
                            setShowErrorMessage(true)
                          }
                          DisplayAllAoptiontoadopt()
                        }}
                        className='py-2 px-4 liststyling'
                        style={{ listStyle: 'none' }}
                      >
                        Reschedule For All
                      </div>)}
                    </div>
                  </div>
                </div>
                {approveAllPopup}
                {rejectAllPopup}

              </div>
            </div>

          </div>
        </div>

        <div className='mt-8'>
          {activeStep === 1 && (
            <Stage1
              ref={updateApplicationsStage1Ref}
              job_id={job_id}
              interviewStages={interviewStages}
              trigger={trigger}
              avgScore={avgScore}
              getSelectedApplications={updateSelectedApplicants}
              showScheduleInterview={async (actionType) => {
                await setAction(actionType)
                if (actionType === 'approval') {
                  setAction('approval')

                  if (nextStage === 'approvals') {
                    setWarningMessage('Are you sure you want to approve selected Candidates')
                    setShowWarningPopup(true)
                  } else {
                    setScheduleMultipleCandidateInterview(true)
                  }
                } else {
                  setAction('reScheduleInterviews')
                  setScheduleMultipleCandidateInterview(true)
                }
              }}
              cancelInterviewIndividual={() => {
                if (selectedApplicants && selectedApplicants.length > 0) {
                  setAction('cancelInterviews')
                  setWarningMessage('Are you sure you want to cancel interviews of selected Candidates?')
                  setShowWarningPopup(true)
                } else {
                  setMessage('Please select one or more applications first and try again')
                  setShowErrorMessage(true)
                }
              }}
            />
          )}
          {activeStep === 2 && (
            <Stage2
              ref={updateApplicationsStage2Ref}
              job_id={job_id}
              interviewStages={interviewStages}
              trigger={trigger}
              avgScore={avgScore}
              getSelectedApplications={updateSelectedApplicants}
              showScheduleInterview={async (actionType) => {
                await setAction(actionType)
                if (actionType === 'approval') {
                  if (nextStage === 'approvals') {
                    setWarningMessage('Are you sure you want to approve selected Candidates')
                    setShowWarningPopup(true)
                  } else {
                    setScheduleMultipleCandidateInterview(true)
                  }
                } else {
                  setAction('reScheduleInterviews')
                  setScheduleMultipleCandidateInterview(true)
                }
              }}
              cancelInterviewIndividual={() => {
                if (selectedApplicants && selectedApplicants.length > 0) {
                  setAction('cancelInterviews')
                  setWarningMessage('Are you sure you want to cancel interviews of selected Candidates?')
                  setShowWarningPopup(true)
                } else {
                  setMessage('Please select one or more applications first and try again')
                  setShowErrorMessage(true)
                }
              }}
            />
          )}
          {activeStep === 3 && (
            <Stage3
              ref={updateApplicationsStage3Ref}
              job_id={job_id}
              trigger={trigger}
              avgScore={avgScore}
              getSelectedApplications={updateSelectedApplicants}
              showScheduleInterview={async (actionType) => {
                await setAction(actionType)
                if (actionType === 'approval') {
                  if (nextStage === 'approvals') {
                    setWarningMessage('Are you sure you want to approve selected Candidates')
                    setShowWarningPopup(true)
                  } else {
                    setScheduleMultipleCandidateInterview(true)
                  }
                } else {
                  setAction('reScheduleInterviews')
                  setScheduleMultipleCandidateInterview(true)
                }
              }}
              cancelInterviewIndividual={() => {
                if (selectedApplicants && selectedApplicants.length > 0) {
                  setAction('cancelInterviews')
                  setWarningMessage('Are you sure you want to cancel interviews of selected Candidates?')
                  setShowWarningPopup(true)
                } else {
                  setMessage('Please select one or more applications first and try again')
                  setShowErrorMessage(true)
                }
              }}
            />
          )}
        </div>

        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

        {scheduleMultipleCandidateInterview && (
          <ScheduleInterViewMultipleCandidatesStage1
            show={true}
            handleClose={() => {
              setAction('')
              setScheduleMultipleCandidateInterview(false)
            }}
            handleSuccess={() => {
              setAction('')
              setScheduleMultipleCandidateInterview(false)
              // refresh the list of applications
              if (activeStep === 1) {
                updateApplicationsStage1Ref.current?.GetAllJobsApplicationsbypage()
              } else if (activeStep === 2) {
                updateApplicationsStage2Ref.current?.GetAllJobsApplicationsbypage()
              } else {
                updateApplicationsStage3Ref.current?.GetAllJobsApplicationsbypage()
              }
            }}
            candidates={selectedApplicants}
            currentStage={currentStage}
            newStage={action === 'reScheduleInterviews' ? currentStage : nextStage}
          />
        )}

        {showWarningPopup && (
          <WarningTARemovePopup
            show={true}
            message={warningMessage}
            handleClose={() => {
              setWarningMessage('')
              setShowWarningPopup(false)
            }}
            handleYesSure={() => {
              console.log('nextActions: ', action)
              if (action === 'approval') {
                approveMultiple()
                // setAction('');
              } else if (action === 'reScheduleInterviews') {
                setWarningMessage('')
                setShowWarningPopup(false)
                setScheduleMultipleCandidateInterview(true)
                // setAction('');
              } else {
                cancelInterviews()
                // setAction('');
              }
            }}
          />
        )}
      </div>
    </>
  )
}
export default InterviewMainScreen
