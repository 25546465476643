/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './ModelStyles.css'
import { useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserApplicationInterviewStage3 = ({ show, name, jobapp_id, handleClose }: Props) => {
  const { auth } = useAuth()
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const createApprovalPopup = (data) => {
    setpopupApproval(
      <ApproveUserApplicationInterviewStage3
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        handleClose={handleClose}
      />
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(
      ACCEPT_JOB_URL,
      {
        jobapp_id: jobapp_id,
        currentStage: 'stage3',
        newStage: 'approvals',
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    )

    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' style = {{height: '18vh', width: '18vh', marginLeft: '18rem'}} src={addcompetenctimage}></img>
          <div className='fw-bolder mx-auto my-5' style={{ display: 'grid', gridTemplateRows: 'auto auto', }}>
            <span style={{
              font: 'normal normal bold 18px/29px Nunito',
              letterSpacing: '-0.02px',
              color: '#373D4A',
              opacity: 1,
              textAlign: 'center',
              marginTop: '1rem'
            }}>{`Are you sure that you want to add`}</span>
            <span style={{
              font: 'normal normal bold 18px/29px Nunito',
              letterSpacing: '-0.02px',
              color: '#373D4A',
              opacity: 1,
              textAlign: 'center'
            }}>{`${name} to the Approvals Stage?`}</span>
          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              {/* <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label>
              </div> */}
              <div className='sureunsure' style={{marginTop: '-2rem'}}>
                <button
                  type='button'
                  onClick={handleSubmit}
                  className='btn my-10 px-10 bgcolorofbutoonofsubmit'>
                  Yes, I am sure!
                </button>
                <button
                  type='button'
                  className='btn my-5 px-5  bgcolorofbutoonofsubmitoftheaddingnewrecruiter123  '
                  onClick={handleClose}
                >
                  No
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ApproveUserApplicationInterviewStage3 }
