//import liraries
import React, { Component } from 'react';
import '../dashboard.css';
import ProgressBar from 'react-customizable-progressbar'

type Props = {
    groupPositions: any
}
// create a component
const GroupSummary = ({ groupPositions }: Props) => {

    const showProgress = (value, total, colorCode) => {
        let progress: any = (value / total) * 100;
        if (progress) {
            progress = progress.toFixed(0) || 0;

        } else {
            progress = 0;
        }
        // progress = 90
        return (
            <div style={{height:'6rem', width:'6rem'}}>
                <ProgressBar
                    radius={24}
                    progress={progress}
                    strokeWidth={8}
                    strokeColor={colorCode}
                    strokeLinecap="butt"
                    trackStrokeWidth={8}
                    trackStrokeLinecap="butt"
                    cut={0}
                    rotate={-90}
                >
                    <div className="indicator-container" style={{marginTop:'-3.5rem'}}>
                        <div className="indicator">
                            <div>{value}</div>
                        </div>
                    </div>
                </ProgressBar>
            </div>
        )
    }

    
    const showClosedProgress = (value, total, colorCode) => {
        let progress: any = (value / total) * 100;
        if (progress) {
            progress = progress.toFixed(0) || 0;

        } else {
            progress = 0;
        }
        // progress = 90
        return (
            <div>
                <ProgressBar
                    radius={18}
                    progress={progress}
                    strokeWidth={8}
                    strokeColor={colorCode}
                    strokeLinecap="butt"
                    trackStrokeWidth={8}
                    trackStrokeLinecap="butt"
                    cut={0}
                    rotate={-90}
                >
                    <div className="indicator-container">
                        <div className="indicator">
                            <div>{progress}</div>
                        </div>
                    </div>
                </ProgressBar>
            </div>
        )
    }
    const showUnattendedProgress = (value, total, colorCode) => {
        let progress: any = (value / total) * 100;
        if (progress) {
            progress = progress.toFixed(0) || 0;

        } else {
            progress = 0;
        }
        // progress = 90
        return (
            <div>
                <ProgressBar
                    radius={18}
                    progress={progress}
                    strokeWidth={8}
                    strokeColor={colorCode}
                    strokeLinecap="butt"
                    trackStrokeWidth={8}
                    trackStrokeLinecap="butt"
                    cut={0}
                    rotate={-90}
                >
                    <div className="indicator-container">
                        <div className="indicator">
                            <div>{progress}</div>
                        </div>
                    </div>
                </ProgressBar>
            </div>
        )
    }

    return (
        <div className='mx-4' >
            <div className='d-flex'>
                <label className='fs-7'>Groups</label>
                <div className='d-flex ms-auto'>
                    <label className='fs-7' style={{minWidth:'4rem'}}>Closed</label>
                    <label className='fs-7 mx-4' style={{minWidth:'4rem'}}>In Progress</label>
                    <label className='fs-7 me-3' style={{minWidth:'5rem'}}>Unattended</label>
                </div>
            </div>
            <div style={{ overflow: 'auto', maxHeight: '20rem', overflowX:'hidden' }}>
                {groupPositions ? groupPositions.map((position, index) => {
                    const posted = position?.postedCount;
                    const closed = position?.closedCount;
                    const unattended = position?.notPostedCount;
                    const total = posted + closed + unattended;

                    return (
                        <div key={index} className=' d-flex align-items-center'>
                            <label className='fs-7 fw-bolder' style={{ maxWidth: '8rem' }}>{position?.groupName}</label>
                            <div className='d-flex ms-auto'>
                                <div>
                                    {showProgress(closed, total, '#F57536')}
                                </div>
                                <div >
                                    {showProgress(posted, total, '#FFD2A8')}
                                </div>
                                <div >
                                    {showProgress(unattended, total, '#F2F2F2')}
                                </div>
                            </div>
                        </div>
                    )
                }) : (<div></div>)}
            </div>
        </div>
    );
};

//make this component available to the app
export default GroupSummary;
