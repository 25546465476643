/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import './cards.css'
import {Link} from 'react-router-dom'

import {toAbsoluteUrl, KTSVG} from '../../../helpers'

import userIcon from '../../modals/JobFunnelsModels/img/group-9680@3x.png'
import bin from '../images/bin.png'
import hoverbin from '../images/bin-delete.png'
import {useState} from 'react'
import cross from '../images/cross.png'
import masesfaf from '../images/mail_black_24dp.png'
import call from '../images/Path 38454.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import DeleteRecruiterWarning from '../../modals/JobFunnelsModels/DeleteRecruiterWarning'
import LoaderView from '../../modals/JobFunnelsModels/LoaderView'
import WarningTARemovePopup from '../../modals/JobFunnelsModels/WarningTARemovePopup'
import UserRemovePopup from '../../modals/JobFunnelsModels/UserRemovePopup'
import TALeadReplacementPopup from '../../modals/JobFunnelsModels/TALeadReplacementPopup'
import ShowError from '../toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
const API_URL = process.env.REACT_APP_API_URL
export const DELETE_RECRUITER_URL = `${API_URL}/ta-ra/`
const DELETE_TA_LEAD_URL = `${API_URL}/ta-ra/delete-ta-lead`
const UPDATE_TA_LEAD_URL = `${API_URL}/ta-ra/update-ta-lead`

type Props = {
  color?: string
  avatar?: string
  name: string
  employeeId: string
  email: string
  phoneNumber: string
  TA: string
  taId: string
  raId: string
  recruiterId: string
  addTALead: () => void
  updateResources: () => void
}

const Card3: FC<Props> = ({
  color = '',
  avatar = '',
  name,
  employeeId,
  email,
  phoneNumber,
  TA,
  taId,
  raId,
  recruiterId,
  addTALead,
  updateResources,
}) => {
  const [overbin, setOverbin] = useState(false)
  const {auth, logout} = useAuth()
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [removeTAWarning, setRemoveTAWarning] = useState(false)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showReplacementPopup, setShowReplacementPopup] = useState(false)

  const deleteRecruiter = async () => {
    setShowWarningPopup(false)
    // show loading
    setLoading(true)
    let ra = raId
    axios
      .delete(DELETE_RECRUITER_URL + ra, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // fetch list of all recruiters to update the list
        updateResources()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const removeTA = async () => {
    setRemoveTAWarning(false)
    // hit API to remove TA lead from recruiter
    setLoading(true)
    let data = {
      recruiter_id: recruiterId,
      ta_id: taId,
    }
    axios
      .post(DELETE_TA_LEAD_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // after removing ta lead from recruiter update list of recruiters and ta lead
        updateResources()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const replaceTALead = (replacementUserId) => {
    setShowReplacementPopup(false)
    setLoading(true)
    let data = {
      recresource_id: raId,
      recruiter_id: recruiterId,
      ta_id: taId,
      new_ta_lead: replacementUserId,
    }
    console.log('userReplacementRequestData: ', data)
    axios
      .post(UPDATE_TA_LEAD_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        // after removing ta lead from recruiter update list of recruiters and ta lead
        updateResources()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  return (
    <>
      <div className='card card-border-recruiter me-8 mb-8'>
        <div className='mt-4 px-4 d-flex align-items-center'>
          {' '}
          {TA ? (
            <span className='orange-tag px-4 py-3 align-items-center'>
              {/* {competenmcy} &nbsp; */}
              TA: {TA}
              <span>
                <img
                  style={{cursor: 'pointer', marginLeft: '1rem'}}
                  onClick={() => {
                    setShowReplacementPopup(true)
                  }}
                  src={imagePath.ic_cross_orange}
                ></img>
              </span>
            </span>
          ) : (
            <a style={{margin: '7px 0px 0px 0px', cursor: 'pointer'}}>
              <span
                className='grey-chip px-4 py-3 fs-7'
                onClick={() => {
                  addTALead()
                }}
              >
                <span>
                  <FontAwesomeIcon icon={faPlus} className='me-2' />
                </span>
                Add TA Lead
              </span>
            </a>
          )}
          <div className='ms-auto'>
            <img
              className='cursor-pointer'
              src={imagePath.ic_delete_orange_cross}
              onClick={() => setShowWarningPopup(true)}
              width={18}
              height={18}
            />
            {/* <Link to=''>
              {' '}
              <img className='backround-hover' src={overbin ? hoverbin : bin} onClick={() => setShowWarningPopup(true)} />
            </Link> */}
          </div>
        </div>
        <div className='cardboyfortherecruiter d-flex flex-center flex-column mt-8 '>
          <div className='mb-5'>
            <div className='symbol symbol-75px symbol-circle'>
              {color ? (
                <span className={`symbol-label bg-light-${color} text-${color} fs-5 fw-bolder`}>{name ? name.charAt(0) : ''}</span>
              ) : (
                <img style={{objectFit: 'cover'}} alt='Pic' src={avatar ? avatar : userIcon} />
              )}
            </div>
          </div>

          <a href='#' className='fs-4 text-gray-800text-hover-primaryfw-boldwe mb-0'>
            {name ? name : 'Name'}
          </a>

          <a href='#' style={{color: '#80808F'}} className='btn fw-bold btn-sm btn-light'>
            Employee ID: {employeeId ? employeeId : 'Employee ID'}
          </a>
        </div>
        <div className='d-flex mt-3 mb-4 mx-4'>
          <div className='d-flex pt-3'>
            <div className='me-2'>
              <img src={imagePath.ic_mail_orange_fill}></img>
            </div>
            <div style={{color: '#80808F', maxWidth: '15rem'}} className='fw-bold '>
              {email ? email : 'Email'}
            </div>
          </div>

          <div className='d-flex pt-3 ms-auto'>
            <div className='mx-2'>
              <img src={imagePath.ic_phone_orange_fill}></img>
            </div>
            <div style={{color: '#80808F'}} className='fw-bold'>
              {phoneNumber ? phoneNumber : 'Phone No'}
            </div>
          </div>
        </div>
      </div>

      {showWarningPopup && (
        <DeleteRecruiterWarning
          show={true}
          message={'Are you sure you want to delete the recruiter?'}
          handleClose={() => {
            setShowWarningPopup(false)
          }}
          deleteRecruiter={deleteRecruiter}
        />
      )}

      {removeTAWarning && (
        <WarningTARemovePopup
          show={true}
          message='Are you sure you want to remove TA Lead?'
          handleClose={() => {
            setRemoveTAWarning(false)
          }}
          handleYesSure={removeTA}
          // handleYesSure={() => {
          //   setShowReplacementPopup(true);
          // }}
        />
      )}

      {showReplacementPopup && (
        <TALeadReplacementPopup show={true} handleClose={() => setShowReplacementPopup(false)} handleSubmit={replaceTALead} />
      )}

      {loading && <LoaderView message='Loading...' />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export {Card3}
