//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import imagePath from '../../../../constants/imagePath';
import './ModelStyles.css';
import { useAuth } from '../../../../app/modules/auth/core/Auth';
import ShowError from '../../content/toasts/ShowError';
import LoaderView from './LoaderView';

const API_URL = process.env.REACT_APP_API_URL;
const ADD_DOCUMENT_URL = `${API_URL}/configuration/add-job_documents`;

type Props = {
    configurationId: string
    documentList: any
    handleClose: () => void
    handleSave: (allDocs: any) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
// create a component
const AddRequiredDocDialog = ({ configurationId, documentList, handleClose, handleSave }: Props) => {

    const { currentUser, auth, logout } = useAuth();
    const [documentName, setDocumentName] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDocumentNameChange = (e) => {
        setDocumentName(e.target.value);
    };

    const handleDocumentTypeChange = (e) => {
        setDocumentType(e.target.value)
    }

    const handleAddDocument = async (event) => {

        event.preventDefault();


        if (documentName.trim() !== '') {
            // Convert both the documentName and existing elements to lowercase
            const lowercaseDocumentName = documentName.toLowerCase();
            const lowercaseDocumentList = documentList.map((item) => item?.data.toLowerCase());

            // Check if the lowercaseDocumentName is already in the lowercaseDocumentList
            if (!lowercaseDocumentList.includes(lowercaseDocumentName)) {


                // Find the largest "i" value from the list
                const largestI = documentList.reduce((max, item) => {
                    const currentItemI = parseInt(item.i, 10);
                    return currentItemI > max ? currentItemI : max;
                }, -1); // Initialize with -1 in case the list is empty

                // Increment the largest "i" value by 1
                const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

                // Create a new object with the incremented "i" value
                const newProgram = {
                    "i": newI,
                    "data": `${documentName}.${documentType}` // Set the initial value as desired
                };


                // Add the new object to the list
                let allDocs = [...documentList];
                allDocs.push(newProgram);
                // setDocumentList(allDocs);
                setDocumentName('');
                setDocumentType('');

                const data = {
                    job_documents: {},
                    configuration_id: configurationId,
                };
                // Convert the array to an object with keys as "i" and values as "data"
                allDocs.forEach((item) => {
                    data.job_documents[item.i] = item.data;
                });
                setLoading(true);
                await axios.post(ADD_DOCUMENT_URL, data, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    setLoading(false);
                    // fetch updated data
                    // getConfigurations();
                    handleSave(allDocs);

                }).catch((error) => {
                    setLoading(false);
                    if (error.response && error.response.status === 400) {
                        // showToast(error.response.data.message)
                        setMessage(error.response.data.message);
                        setShowErrorMessage(true);
                    } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                        logout();
                    } else if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                            logout();
                        } else {
                            setMessage(error.response.data.error.message);
                            setShowErrorMessage(true);
                        }
                    } else {
                        setMessage(error.message);
                        setShowErrorMessage(true);
                    }
                });

            } else {
                // Handle the case where documentName is a duplicate (e.g., display an error message)

                setMessage('Document name already exists in the list.');
                setShowErrorMessage(true);
            }
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123 px-6 py-4 position-relative'>
                <div className='d-flex align-items-center mx-6' style={{ position: 'relative', zIndex: '10' }}>
                    <label className='fs-3 fw-bolder'>Required Document</label>
                    <img className='ms-auto cursor-pointer' src={imagePath.ic_close} onClick={() => {
                        console.log('close popup:')
                        handleClose()
                    }} />
                </div>
                {/* end::Close */}
                <div className='mx-6' style={{ position: 'relative', zIndex: '10' }}>
                    <form>
                        <div className='d-flex align-items-center mt-8 col-md-12'>
                            <div className="me-4">
                                <label htmlFor="documentName" className="form-label fs-6" style={{ color: '#373D4A' }}>Title<span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ height: '40px' }}
                                    placeholder='Enter Document Name'
                                    id="documentName"
                                    value={documentName}
                                    onChange={handleDocumentNameChange}
                                />
                            </div>
                            <div className="ms-4" >
                                <label htmlFor="documentType" className="form-label fs-6" style={{ color: '#373D4A' }}>Document Type<span style={{ color: 'red' }}>*</span></label>
                                <select
                                    className="form-select"
                                    style={{ height: '40px' }}
                                    placeholder='Select Document Type'
                                    id="documentType"
                                    value={documentType}
                                    onChange={handleDocumentTypeChange}
                                >
                                    <option value="">Select Document Type</option>
                                    <option value="pdf">PDF</option>
                                    <option value="doc">DOC</option>
                                    <option value="docx">DOCX</option>
                                    <option value="png">PNG</option>
                                    <option value="jpg">JPG</option>
                                </select>
                            </div>
                        </div>
                        <button
                            onClick={(e) => handleAddDocument(e)}
                            type='button'
                            className='d-flex btn px-18 py-2 apply-review-button my-8 mx-auto'
                            style={{ color: 'white' }}
                            disabled={!documentName || !documentType}
                        >
                            Save
                        </button>
                    </form>
                </div>

                {/* Positioning the image in the bottom right corner */}
                <div className='position-absolute bottom-0 end-0'>
                    <img src={imagePath.ic_docs_orange} style={{ width: '220px', height: '220px', opacity: '0.08', zIndex: '-1' }} />
                </div>

                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
                {loading && <LoaderView message='Loading...' />}
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default AddRequiredDocDialog;
