import React, { useEffect } from 'react'
import { useState } from 'react'
import '../../Settings.css'
import axios from 'axios'
import { LevelChangePopUp } from '../../../../../_metronic/partials/modals/create-app-stepper/LevelChangePopUp'
import { useAuth } from '../../../../../app/modules/auth/core/Auth'
import { LevelChangePopUp5 } from '../../../../../_metronic/partials/modals/create-app-stepper/LevelChangePopUp5'
import LoaderView from '../../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
import { numberToEnglishWord } from '../../../../../constants/CommonFunctions'

const API_URL = process.env.REACT_APP_API_URL
export const CREATE_LEVEL = `${API_URL}/level/create/`
export const GET_CREATE_LEVEL = `${API_URL}/level/get-level`

type Props = {
  placeHolderTitles: any
  placeHolderDescriptions: any
  updateLevelsFramework: () => void
}

const Level4 = ({ placeHolderTitles, placeHolderDescriptions, updateLevelsFramework }: Props) => {
  const { auth, logout } = useAuth()

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [levels, setLevels] = useState<any>([])
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    axios.get(GET_CREATE_LEVEL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      console.log('levels5FormData: ', res.data)
      let data = JSON.parse(res?.data?.level?.level_data)

      if (data) {
        let levelsData: any = []
        data.map((item, index) => {
          let title = Object.keys(item)[0];
          let description = item[title];
          let level = { title: title, description: description }
          levelsData.push(level);
        })
        if (levelsData.length === 4) {
          setLevels(levelsData);
        } else {
          createLevelsInputViews();
        }
      } else {
        createLevelsInputViews();
      }
    }).catch((error) => {

      createLevelsInputViews();
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    });

  }, [])

  const createLevelsInputViews = () => {
    let levelsData: any = [];
    for (let i = 0; i < 4; i++) {
      let level = { title: '', description: '' }
      levelsData.push(level);
    }
    setLevels(levelsData);
  }

  const updateLevels = async () => {
    setShowCreateAppModal(false)
    setLoading(true);
    // Convert the format of levels array
    let objnew = levels.map((level) => ({
      [level.title]: level.description,
    }));

    const url = CREATE_LEVEL
    const LEVEL1Data = {
      level_no: 4,
      level_data: objnew,
    }

    var data = JSON.stringify(LEVEL1Data)
    console.log(data)
    await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setLoading(false);
      console.log('res', res);
      //update the data of bottom framework of levels
      updateLevelsFramework();
    }).catch((error) => {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message);
        setShowErrorMessage(true);
      } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
        logout();
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout();
        } else {
          setMessage(error.response.data.error.message);
          setShowErrorMessage(true);
        }
      } else {
        setMessage(error.message);
        setShowErrorMessage(true);
      }
    });
  }

  const handleTitleChange = (index, event) => {
    const titleValue = event.target.value;
    let data = [...levels];
    data[index]['title'] = titleValue;
    setLevels(data);
  }

  const handleDescriptionChange = (index, event) => {
    const descriptionValue = event.target.value;
    let data = [...levels];
    data[index]['description'] = descriptionValue;
    setLevels(data);
  }

  return (
    <>
      <div>
        <div className='  margin-leftforthelevels4 justify-content-center '>
          {levels.length > 0 && (
            levels.map((item, index) => (
              // <div className='widthforcol2level4'>
              <div className=' mx-4'>
                <div>
                  {' '}
                  <div className='fs-5 level-forms-name'>{`Level ${numberToEnglishWord(index+1)} Name`}</div>
                </div>
                <div>
                  <form>
                    <div className='form-group mt-5 '>
                      <div >
                        <input
                          className="form-control"
                          placeholder={`${placeHolderTitles[index]}`}
                          id="basic"
                          onChange={event => handleTitleChange(index, event)}
                          value={item.title}
                        />
                      </div>
                    </div>

                    <div className='form-group mt-5 '>
                      <div >
                        <textarea
                          className='heightof-textares form-control'
                          // placeholder={`${placeHolderDescriptions[index]}`}
                          placeholder='Description'
                          id='comment'
                          onChange={(event) => handleDescriptionChange(index, event)}
                          value={item.description}
                        ></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            ))

          )}


        </div>

        <div className='mx-auto my-10 d-flex justify-content-center '>
          <div onClick={() => setShowCreateAppModal(true)} 
          // className='btn  update-button-levels'
          className='candi-btn2 text-white fw-bolder fs-5'>
            Update
          </div>
        </div>

        <LevelChangePopUp5
          show={showCreateAppModal}
          handleClose={() => setShowCreateAppModal(false)}
          handleUpdateLevels={updateLevels}
        />{' '}
      </div>
      {loading && (
        <LoaderView message='Loading...' />
      )}
      {showErrorMessage && (
        <ShowError
          handleClose={() => setShowErrorMessage(false)}
          message={message}
        />
      )}
    </>
  )
}

export default Level4
