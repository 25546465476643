//import liraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import '../../../../_metronic/partials/modals/JobFunnelsModels/ModelStyles.css'
import '../../../../_metronic/partials/modals/JobFunnelsModels/Success.css';
import hurrayImg from '../../../../_metronic/partials/modals/images/hurray_img@3x.png';
import icOpenEye from '../images/ic_open_eye.png';
import icCloseEye from '../images/ic_close_eye.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset/`

// create a component
const ResetPassword = () => {
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const { id } = useParams<{ id: string }>()
    console.log('fetchedId: ', id);

    function encryptPassword(password, secretKey) {
        if (!secretKey) {
            throw new Error('Encryption key is not defined');
        }
        return CryptoJS.AES.encrypt(password, secretKey).toString();
    }

    const loginSchema = Yup.object().shape({
        password: Yup.string()
            // .min(12, 'Minimum 12 symbols')
            .max(50, 'Maximum 50 symbols')
            .matches(
                // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{12,}$/,
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]\S{12,}$/,
                'Password should have at least 12 characters, 1 capital letter, 1 small letter, numbers, 1 or more special characters and no white spaces'
            )
            .required('Password is required'),
        confirmPassword: Yup.string()
            // .min(12, 'Minimum 12 symbols')
            // .max(50, 'Maximum 50 symbols')

            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
            //     'Password should have at least 12 characters, 1 capital letter, 1 small letter, numbers, and special characters'
            // )
            .oneOf([Yup.ref('password'), null], 'Passwords must match')

            .required('Confirm Password is required'),
    })

    const navigate = useNavigate();
    const handleBack = () => {
        console.log('goto login screen')
        // redirect to login screen and remove current page from back stack
        navigate('/auth/login', { replace: true });
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true)
            let passwordSetting
            let confirmPasswordSetting

            // passwordSetting = values.password
            // confirmPasswordSetting = values.confirmPassword
            passwordSetting = encryptPassword(values.password, process.env.REACT_APP_SECRET_KEY);
            confirmPasswordSetting = encryptPassword(values.confirmPassword, process.env.REACT_APP_SECRET_KEY);

            let token = id;
            let data = {
                "password": passwordSetting,
                "password_confirm": confirmPasswordSetting
            }
            let url = RESET_PASSWORD_URL + token;
            await axios.post(url, data).then((res) => {
                console.log('accountVerifyResponse: ', res.data)
                setLoading(false);
                setSubmitting(false);
                navigate('/auth/login', { replace: true });

            }).catch((err) => {
                setLoading(false);
                console.log('err', err);
                setStatus('The password or confirm password are incorrect');
                setSubmitting(false);
            });


        },

    });

    return (

        <div>
            <div style={{ position: 'absolute', zIndex: '1', marginTop: '3rem', marginLeft: '3rem' }} >
                <label className='form-label fw-bolder fs-1'
                    style={{ color: '#FFFFFF', cursor: 'pointer' }}
                    onClick={() => handleBack()}>
                    Back
                </label>
            </div>

            <div className='signup-success-bg'>
                <div className='d-flex flex-column'>
                    <div className=' mt-12'>
                        <div className=' d-flex flex-column '>
                            <h1 className='fw-bolder mx-auto my-5' style={{ color: '#FFFFFF' }}>Reset Password</h1>
                            <p className=' mx-auto my-5 text-style ' style={{ color: '#FFFFFF' }}>Reset the new password of your account so<br></br>you can login and access all the features</p>
                        </div>
                    </div>
                    <form
                        className='form w-100 aaligndivCenter'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_reset_password_form'
                    >
                        <div className='widthHeightSettingForm'>
                            {/* begin::Form group */}
                            <div className='fv-row mb-4'>
                                <label className='form-label labeldfa mb-3'>New Password</label>
                                <div className='grp-image-input backinpput-color'>
                                    <input
                                        style={{ height: '56px' }}
                                        placeholder='Password'
                                        {...formik.getFieldProps('password')}
                                        className={clsx(
                                            'form-control no-border',
                                            {
                                                'is-invalid': formik.touched.password && formik.errors.password,
                                            },
                                            {
                                                'is-valid': formik.touched.password && !formik.errors.password,
                                            }
                                        )}
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        autoComplete='off'
                                    />
                                    <img alt='' src={showPassword ? icOpenEye : icCloseEye} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                                    {/* <i className=' custom-class bi-envelope'></i> */}
                                </div>
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-white' role='alert'>
                                                {formik.errors.password}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group */}
                            <div className='fv-row mb-3'>
                                <label className='form-label labeldfa mb-3'>Confirm Password</label>
                                <div className='grp-image-input'>
                                    <input
                                        style={{ height: '56px' }}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        autoComplete='off'
                                        placeholder='Confirm Password'
                                        {...formik.getFieldProps('confirmPassword')}
                                        className={clsx(
                                            'form-control no-border',
                                            {
                                                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                                            },
                                            {
                                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                                            }
                                        )}
                                        name='confirmPassword'
                                    />

                                    <img src={showConfirmPassword ? icOpenEye : icCloseEye} alt='' style={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-white' role='alert'>
                                                {formik.errors.confirmPassword}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}

                            {/* begin::Action */}
                            <div className='d-grid mb-6 mt-10'>
                                <button
                                    style={{ height: '60px', color: 'white' }}
                                    type='submit'
                                    id='kt_sign_in_submit'
                                    className='btn btn-primary loginButtonLogin-screen'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='text-white fs-6 indicator-label'>Reset Password</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait....
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
};

//make this component available to the app
export default ResetPassword;
