//import liraries
import React, { useState } from 'react';
import imagePath from '../../../../constants/imagePath';
import '../dashboard.css';

// create a component
function CustomPagination({ pageValue, totalPages, onPageChange }) {
    const [currentPage, setCurrentPage] = useState(pageValue||1);

    const handleInputChange = (e) => {
        let page = parseInt(e.target.value);
        if (page < 1) {
            page = 1;
        } else if (page > totalPages) {
            page = totalPages;
        }
        setCurrentPage(page);
        onPageChange(page);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        // onPageChange(currentPage - 1);
        let page = currentPage - 1;
        if (page > 0) {
            onPageChange(page);
        }
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        let page = currentPage + 1;
        if (page <= totalPages) {
            onPageChange(page);
        }
    };

    // Dynamically adjust input width based on the number of digits
    const inputWidth = {
        width: `${((currentPage.toString().length + 1) * 10) + 24}px`, // Adjust width according to the number of digits
    };

    return (
        <div className="pagination justify-content-center align-items-center">
            {/* <img style={{ cursor: 'pointer' }} src={imagePath.ic_left_pagination_arrow} onClick={handlePrevPage} /> */}
            <input
                className='pagination-input'
                style={{...inputWidth,
                color:'#484848'}} // Apply dynamic width style
                // style={{
                //     ...inputWidth,
                //     appearance: 'textfield', // Hide arrows
                //     WebkitAppearance: 'textfield', // For Webkit-based browsers (Chrome, Safari)
                //     MozAppearance: 'textfield', // For Mozilla-based browsers (Firefox)
                // }}
                type="number"
                min="1"
                max={totalPages}
                value={currentPage}
                onChange={handleInputChange}
            />
            {/* <img style={{ cursor: 'pointer' }} src={imagePath.ic_right_pagination_arrow} onClick={handleNextPage} /> */}
            <label style={{color:'#484848'}} >
                of {totalPages} pages
            </label>
        </div>
    );
};

//make this component available to the app
export default CustomPagination;
