//import liraries
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL
const GRADE_HIRING_REPORT_URL = `${API_URL}/position/report`;
const GET_GROUPS_URL = `${API_URL}/group`;
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`

// create a component
const GradeHiringTable = () => {
    const intl = useIntl();
    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState();
    const [hiringReportURL, setHiringReportURL] = useState<any>('');
    const [hiringReport, setHiringReport] = useState<any>();
    const [allGroups, setAllGroups] = useState<any>([]);
    const [allGrades, setAllGrades] = useState<any>([])
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const dropdownRefGrade = useRef<HTMLDivElement>(null)
    const [isOpenTotal, setIsOpenTotal] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<any>([])
    const [selectedGrade, setSelectedGrade] = useState('');

    useEffect(() => {
        (async () => {
            await getGroups();
            await getGrades();
        })()
    }, []);

    useEffect(() => {
        (async () => {
            await gradeHiringReport();
        })()
    }, [year]);

    const gradeHiringReport = async () => {
        setLoading(true);
        await axios.get(GRADE_HIRING_REPORT_URL, {
            params: {
                year: year
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            const data = res?.data;
            if (data?.reportUrl) {
                setHiringReportURL(data?.reportUrl);
                const analytics = data?.analytics;
                if (analytics) {
                    setHiringReport(analytics);
                    const firstKey = Object.keys(analytics)[0];
                    // setSelectedGrade(firstKey);
                }
            }

        }).catch((error) => {
            setLoading(false);
            handleErrors(error)
        })
    }

    // fetching list of grades
    const getGrades = async () => {
        await axios
            .get(GET_GRADES_URL, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                let data = res?.data?.job_grade
                if (data) {
                    data = JSON.parse(data)
                    if (data) {
                        setAllGrades(Object.values(data))
                    }
                }
            })
            .catch((err) => {
                handleErrors(err)
            });
    }

    const getGroups = async () => {
        await axios.get(GET_GROUPS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data?.data;
            if (data) {
                setAllGroups(data);
            }
        }).catch((error) => {
            handleErrors(error)
        })
    }

    const handleErrors = (error) => {
        if (error.response) {
            const { status, data } = error.response

            if (status === 400) {
                setMessage(data.message)
            } else if (status === 401 && data.name === 'TokenExpiredError') {
                logout()
            } else if (data.error) {
                if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(data.error.message)
                }
            } else {
                setMessage('An unknown error occurred')
            }
        } else {
            setMessage(error.message)
        }

        setShowErrorMessage(true)
    }

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (url) => {
        if (!url) {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = url.split('/').pop(); // Extracts file name from URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
                handleErrors(error)
            });
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRefGrade.current && !dropdownRefGrade.current.contains(event.target)) {
                setIsOpenTotal(false)
            }
        }

        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    const filterChange = (filterValue) => {
        setIsOpenTotal(false);

        // check if filterValue already available in selectedFilters remove that value else add into list
        setSelectedFilters((prevSelectedFilters) => {
            if (prevSelectedFilters.includes(filterValue)) {
                // Remove the filter value if it already exists
                return prevSelectedFilters.filter((filter) => filter !== filterValue);
            } else {
                // Add the filter value if it doesn't exist
                return [...prevSelectedFilters, filterValue];
            }
        });

    }
    const toggleDropdownTotal = () => {
        setIsOpenTotal(!isOpenTotal)
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                // Show loading icon if isLoading state is true
                <div
                    className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                >
                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                </div>
            )}

            <div className='card-bg shadow-sm mb-10' >
                <div className='d-flex align-items-center px-6 py-8'>
                    <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Talent Acquisition Grade Wise Hiring Budget - {currentYear}</label>
                    <div className='d-flex align-items-center ms-auto'>
                        {/* filter view */}
                        <div ref={dropdownRefGrade}>
                            <div className={`dropdown ${isOpenTotal ? 'open' : ''}`}>
                                <div className='d-flex align-items-center py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}
                                    onClick={toggleDropdownTotal}>
                                    <img className='me-4' src={imagePath.ic_filter_orange} />
                                    <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Filter By Grade</label>
                                </div>
                                <div className='dropdown-menu' style={{ zIndex: '4' }}>
                                    {isOpenTotal && (
                                        <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '180px', overflowY: 'auto' }}>
                                            {allGrades && allGrades.map((option, index) => {
                                                const isChecked = selectedFilters.includes(option);
                                                return (
                                                    <div key={option} className='d-flex hoverable-text mb-2 align-items-center ' style={{ whiteSpace: 'nowrap' }}>
                                                        <input
                                                            className='form-check-input me-2'
                                                            style={{ height: '16px', width: '16px' }}
                                                            type='checkbox'
                                                            value={option}
                                                            // checked={selectedValue.includes(option)}
                                                            // onChange={handleGradeChange} 
                                                            checked={isChecked}
                                                            onChange={(event) => filterChange(option)}
                                                        />
                                                        <label className='form-check-label '>{option}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* year view */}
                        <div className='d-flex align-items-center mx-4'>

                            <input
                                className='py-2 px-2 fs-5 fw-semibold'
                                style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                                type='number'
                                inputMode='numeric'
                                placeholder='2024'
                                value={year}
                                max={currentYear}
                                min={2023}
                                onChange={(event) => handleChangeYear(event)}
                            />
                        </div>
                        {/* download report view */}
                        <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                            <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => handleDownloadFile(hiringReportURL)}>
                                <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                                <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                            </div>
                        </div>
                        <div>
                            <img className='cursor-pointer ms-4' src={imagePath.ic_refresh_orange} width={34} height={34}
                                onClick={() => { gradeHiringReport() }} />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='table-responsive'>
                        <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                            <div className='scrollable-table-container'>
                                <table className='table align-middle table-borders' style={{ minWidth: '100%' }}>
                                    <thead>
                                        <tr className='fw-bold graycolor bg-light align-items-center'>
                                            <th className='ps-6 fw-bolder my-auto sticky-header' style={{ color: '#475467', whiteSpace: 'nowrap', verticalAlign: 'middle', minWidth: '16rem', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 2, borderRight: '1px solid #EDF2F9' }}>{`Group/Grade`}</th>
                                            {hiringReport && Object.keys(hiringReport).map((key, index) => {
                                                if (selectedFilters !== null && selectedFilters !== undefined && selectedFilters.length > 0) {
                                                    const isMatched = selectedFilters.includes(key);
                                                    if (isMatched) {
                                                        return (
                                                            <th key={index} style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 2 }}
                                                                className='sticky-header ps-6 fw-bolder'>
                                                                <div className='text-center'>
                                                                    <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>
                                                                        {key}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Budget</div>
                                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Actual</div>
                                                                    </div>
                                                                </div>

                                                            </th>
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <th key={index} className='sticky-header ps-6 fw-bolder' style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 1 }}>
                                                            <div className='text-center'>
                                                                <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>
                                                                    {key}
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Budget</div>
                                                                    <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Actual</div>
                                                                </div>
                                                            </div>

                                                        </th>
                                                    )
                                                }
                                            })}

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {allGroups && hiringReport && (
                                            allGroups.map((group, groupIndex) => {
                                                const isEvenRow = groupIndex % 2 === 0
                                                return (
                                                    <tr key={groupIndex} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                        <td className='ps-6 fw-bolder sticky-column' style={{ color: '#1D2939', background: isEvenRow ? 'white' : '#FCFCFC', borderRight: '1px solid #EDF2F9', height: '4rem', minWidth: '16rem', left: 0, zIndex: 1 }}>
                                                            {group?.group_name}
                                                        </td>
                                                        {Object.keys(hiringReport).map((key, keyIndex) => {
                                                            let data = hiringReport[key][group?.group_name];
                                                            if (selectedFilters !== null && selectedFilters !== undefined && selectedFilters.length > 0) {
                                                                const isMatched = selectedFilters.includes(key);
                                                                if (isMatched) {
                                                                    return (
                                                                        <td key={keyIndex} className='text-center sticky-column' style={{ border: '1px solid #EDF2F9' }}>
                                                                            <div className='d-flex'>
                                                                                <div className='px-2 py-4' style={{ width: '50%' }}>{data?.budget}</div>
                                                                                <div className='px-2 py-4' style={{ width: '50%' }}>{data?.actual}</div>
                                                                            </div>
                                                                        </td>
                                                                    );
                                                                }
                                                            } else {
                                                                return (
                                                                    <td key={keyIndex} className='text-center' style={{ border: '1px solid #EDF2F9' }}>
                                                                        <div className='d-flex'>
                                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{data?.budget}</div>
                                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{data?.actual}</div>
                                                                        </div>
                                                                    </td>
                                                                );
                                                            }
                                                        })}
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div >
    );
};

//make this component available to the app
export default GradeHiringTable;
