//import liraries
import React, { Component, useEffect, useRef, useState } from 'react';
import RecordRTC from 'recordrtc';
import './Applications.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import { Link } from 'react-router-dom'
import imagePath from '../../../../constants/imagePath';
import axios from 'axios';
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import { SuccessInterviewpopUp } from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessPopUp';
import { useAuth } from '../../auth/core/Auth';
import EndTestWarningPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/EndTestWarningPopup';

const API_URL = process.env.REACT_APP_API_URL;
const GET_TEST_DATA = `${API_URL}/test/`;
const SAVE_ATTEMPT_TEST_DATA = `${API_URL}/jobapplication/save-attempt-test-data`;
const UPLOAD_ASSESSMENT_VIDEO_URL = `${API_URL}/jobapplication/upload-video-assessment`;


// create a component
const AttemptTest = (props) => {

    const { currentUser, auth, logout } = useAuth()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [testName, setTestName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [competencies, setCompetencies] = useState<any>();
    const [allQuestions, setAllQuestions] = useState<any>([]);
    const [remainingQuestion, setRemainingQuestion] = useState<any>();
    const [remainingQuestionIndex, setRemainingQuestionIndex] = useState(0);
    const [testTime, setTestTime] = useState('');
    const [testTimer, setTestTimer] = useState<number>(0);
    const [questionTimer, setQuestionTimer] = useState<number>(0);
    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [recording, setRecording] = useState<RecordRTC | null>(null);
    const [isRecordingInProgress, setIsRecordingInProgress] = useState(false);
    const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    let testTimerIntervalId = useRef<NodeJS.Timeout | null>(null);
    let questionTimerIntervalId = useRef<NodeJS.Timeout | null>(null);
    const [showWarningTestEnd, setShowWarningTestEnd] = useState(false);

    function showSuccessPopup() {
        setShowPopup(true);
    }

    function handleClose() {
        setShowPopup(false);
        props.update(1)
    }

    useEffect(() => {
        if (testTime !== null && testTime !== undefined && testTime !== '') {
            testTimerIntervalId.current = setInterval(() => {
                // setTestTimer(prevTime => prevTime - 1); // Decrement testTime by 1 second

                setTestTimer(prevTime => {
                    if (prevTime <= 0) {
                        // Clear interval when test time reaches 0
                        if (testTimerIntervalId.current !== null) {
                            clearInterval(testTimerIntervalId.current);
                        }
                        handleTestTimerEnd(); // Call function to show warning
                        return 0;
                    } else {
                        return prevTime - 1; // Decrement testTime by 1 second
                    }
                })

            }, 1000); // Update timer every second

            // Cleanup function to clear the interval when component unmounts or when testTime reaches 0
            // return () => clearInterval(testTimerIntervalId.current);
            return () => {
                if (testTimerIntervalId.current !== null) {
                    clearInterval(testTimerIntervalId.current);
                }
            };
        }
    }, [testTime]); // Run effect only once on component mount

    const handleTestTimerEnd = () => {
        stopRecording()
    }

    useEffect(() => {
        if (allQuestions.length > 0) {
            questionTimerIntervalId.current = setInterval(() => {
                // setTestTimer(prevTime => prevTime - 1); // Decrement testTime by 1 second

                setQuestionTimer(prevTime => {
                    if (prevTime < 1) {
                        // Clear interval when test time reaches 0
                        if (questionTimerIntervalId.current !== null) {
                            clearInterval(questionTimerIntervalId.current);
                        }
                        handleQuestionTimerEnd(); // Call function to show warning
                        return 0;
                    } else {
                        return prevTime - 1; // Decrement testTime by 1 second
                    }
                })

            }, 1000); // Update timer every second

            // Cleanup function to clear the interval when component unmounts or when testTime reaches 0
            return () => {
                if (questionTimerIntervalId.current !== null) {
                    clearInterval(questionTimerIntervalId.current);
                }
            }
        }

    }, [remainingQuestionIndex, allQuestions])

    const handleQuestionTimerEnd = () => {
        handleSaveAnswer('lapsed');
    }

    useEffect(() => {
        // start screen recording
        if (!recording) {
            startRecording();
        }
    }, []);

    // Add a beforeunload event listener to show a warning when the user tries to leave the page with an ongoing recording
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isRecordingInProgress) {
                event.preventDefault();
                event.returnValue = 'You have an ongoing screen recording. Are you sure you want to leave the page?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isRecordingInProgress]);


    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });

            const recorder = new RecordRTC(stream, {
                type: 'video',
                // mimeType: 'video/webm',
                mimeType: 'video/mp4',
            });

            // Add an event listener for the onstop event
            recorder.onstop = () => {
                // Handle the manual stop event here
                console.log('Screen recording manually stopped by the user');
                // Perform any additional actions you need
                // props.update(1)
            };

            recorder.startRecording();

            setRecording(recorder);
            // Set the flag to indicate recording is in progress
            setIsRecordingInProgress(true);
            // Set the screenStream to the obtained stream
            setScreenStream(stream);
        } catch (error) {
            console.error('Error starting recording:', error);
            props.update(1)
        }
    };


    const stopRecording = () => {

        // Stop the timer interval
        if (testTimerIntervalId.current !== null) {
            clearInterval(testTimerIntervalId.current);
        }
        if (questionTimerIntervalId.current !== null) {
            clearInterval(questionTimerIntervalId.current);
        }

        if (recording) {
            recording.stopRecording(async () => {
                const blob = recording.getBlob();
                const videoUrl = URL.createObjectURL(blob);

                // Release the screen recording stream and its tracks
                if (screenStream) {
                    screenStream.getTracks().forEach(track => track.stop());
                    setScreenStream(null);
                }

                // Display the recorded video
                // videoRef.current.src = videoUrl;

                // hit api to upload recorded video
                await uploadAssessmentVideo(blob);
                // show success if the question was last
                showSuccessPopup();

                setRecording(null);
                // Clear the flag to indicate recording has stopped
                setIsRecordingInProgress(false);
            });
        }
    };

    const uploadAssessmentVideo = async (file) => {
        // fetching current date and time
        const now = new Date();

        const padZero = (num: number) => (num < 10 ? `0${num}` : `${num}`);

        const hour12 = now.getHours() >= 12 ? 'PM' : 'AM';
        const hour = padZero(now.getHours() % 12 || 12);
        const minute = padZero(now.getMinutes());
        const second = padZero(now.getSeconds());
        const day = padZero(now.getDate());
        const month = padZero(now.getMonth() + 1);
        const year = now.getFullYear();

        const formattedDateTime = `${hour}_${minute}_${second}_${hour12}_${month}_${day}_${year}`;

        let jobData = props.jobData;
        // let fileName = `assessment_video_${currentUser?.cnic}_${jobData?.jobdata?.title}_${formattedDateTime}.webm`;
        let fileName = `assessment_video_${currentUser?.cnic}_${jobData?.jobdata?.title}_${formattedDateTime}.mp4`;
        const formData = new FormData();
        formData.append('jobapp_id', props.job_id);
        // formData.append('file', file, 'assessment_video');
        formData.append('file', file, fileName);

        setLoading(true);
        // setLoadingMessage(`Uploading Video...${uploadPercentage}%`)
        await axios.post(UPLOAD_ASSESSMENT_VIDEO_URL, formData,
            {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setLoadingMessage(`Uploading Video...${progress}%`)
                    // You can update a progress bar or UI element with the 'progress' value here.
                    setUploadPercentage(progress);
                },
            })
            .then((res) => {
                setLoading(false);

            }).catch((error) => {
                setLoading(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })
    }

    useEffect(() => {
        (() => {
            let jobData = props.jobData;
            let title = jobData?.jobdata?.title;
            setJobTitle(title);
            getTestData();
        })();
    }, []);

    const getTestData = async () => {
        let testId = props?.test_id;
        if (testId) {
            setLoading(true);
            await axios.get(GET_TEST_DATA + testId, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                setLoading(false);
                const testData = res?.data?.test
                setTestTime(testData?.test_time);
                setTestTimer(testData?.test_time ? parseInt(testData?.test_time, 10) : 0);
                setTestName(testData?.name);
                setCompetencies(testData?.competencies);

                let listOfQuestions = testData?.questions
                // Filter out null or empty objects
                const filteredQuestions = listOfQuestions
                    ?.filter(sublist => sublist && sublist.length > 0) // Check if sublist is not null or undefined
                    ?.flatMap(sublist => sublist
                        ?.filter(question => question && Object.keys(question).length > 0) // Check if question is not null or undefined
                    );

                if (filteredQuestions) {
                    // Add status property to each question
                    filteredQuestions.forEach(question => {
                        question.status = 'remaining';
                    });
                }
                setAllQuestions(filteredQuestions);
                fetchFirstRemainingQuestion(filteredQuestions);
            }).catch((error) => {
                setLoading(false);
                displayErrorMessage(error)
            });
        }
    }

    const displayErrorMessage = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
            logout()
        } else if (error.response?.data?.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }

    const getTestTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60); // Calculate minutes
        return `${minutes} min${minutes !== 1 ? 's' : ''}`; // Return formatted time
    }

    // Format time in hh:mm:ss
    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const fetchFirstRemainingQuestion = (questions) => {
        if (!questions) return; // Check if allQuestions is not null or undefined

        // Find the first question with status 'remaining'
        const remainingQuestionIndex = questions.findIndex(question => question.status === 'remaining');
        if (remainingQuestionIndex !== -1) {
            // Get the first remaining question using its index
            const question = questions[remainingQuestionIndex];
            // Set the remaining question and its index into new state variables
            setRemainingQuestion(question);
            setRemainingQuestionIndex(remainingQuestionIndex);
            let questionTime = parseInt(question?.allowed_time) || 30
            setQuestionTimer(questionTime);
        } else {
            // stop video recording and upload the recorded video
            stopRecording();
        }
    };

    const handleAnswerSelection = (answerId) => {
        setSelectedAnswer(answerId);
    }
    const handleSaveAnswer = (questionStatus) => {
        let questionsList = [...allQuestions];
        if (remainingQuestionIndex < allQuestions.length) {
            questionsList[remainingQuestionIndex]['status'] = questionStatus;
        }
        setAllQuestions(questionsList);

        const questionData = { ...remainingQuestion, selected_answer_id: selectedAnswer };

        let attempt_test = {

            'question': questionData,
            'timer_value': testTimer
        };
        let isAttempted = (remainingQuestionIndex < (allQuestions.length - 1)) ? false : true;
        let jobapp_id = props.job_id;
        let totalQuestion = allQuestions.length;

        let data = { attempt_test, jobapp_id, totalQuestion, isAttempted };
        setLoading(true);
        axios.post(SAVE_ATTEMPT_TEST_DATA, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then(response => {
            setLoading(false);
            const success = response.data.success;
            // if (success) {
            setSelectedAnswer('');
            fetchFirstRemainingQuestion(questionsList);

            // } else {
            //     // test finish go back
            //     props.update(1)
            // }


        }).catch((error) => {
            setLoading(false);
            displayErrorMessage(error);
        })



    }

    return (
        <div>
            <div className='dashboard-top'>
                <h1>Dashboard</h1>
                <div className='d-flex align-items-center'>
                    <h3>
                        <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                        <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                        <Link to="/candidatedashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                            My Dashboard
                        </Link>
                        <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                        <Link to="/candidateapplications" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                            Applications
                        </Link>
                        <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                        <Link to="#" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                            Online Test
                        </Link>
                    </h3>

                </div>

            </div>
            <div className='card-bg shadow-sm p-6 mt-10'>
                <div className='card-bg shadow-sm p-6 d-flex align-items-center'>
                    <div className='d-flex align-items-center me-8'>
                        <img src={imagePath.ic_timer_orange} width={34} height={34} style={{ objectFit: 'contain' }} />
                        <label className='fs-6 fw-bolder mx-2' style={{ color: '#373D4A' }}>Total Time:</label>
                        <label className='fs-6 fw-bolder me-2' style={{ color: '#F36523' }}>{getTestTime(testTime)}</label>
                    </div>
                    <div className='d-flex align-items-center me-8'>
                        <img src={imagePath.ic_timer_orange} width={34} height={34} style={{ objectFit: 'contain' }} />
                        <label className='fs-6 fw-bolder mx-2' style={{ color: '#373D4A' }}>Remaining Time:</label>
                        <label className='fs-6 fw-bolder me-2' style={{ color: '#F36523' }}>{formatTime(testTimer)}</label>
                        <label className='fs-7 fw-semibold me-2' style={{ color: '#CBC9C9' }}>hh:mm:ss</label>
                    </div>
                    {/* <button className='btn candi-btn2 ms-auto px-10 text-white'
                        onClick={() => setShowWarningTestEnd(true)}>
                        End Test
                    </button> */}
                </div>

                <div className='col-md-12 d-flex mt-8'>
                    <div className='col-md-4 pe-4'>
                        <div className='card-bg shadow-sm p-4'>
                            <div className='d-flex align-items-center'>
                                <label className='fs-6 fw-bolder' style={{ color: '#F36523' }}>Test</label>
                                <label className='fs-6 fw-bolder ms-auto' style={{ color: '#80808F' }}>{testName}</label>
                            </div>
                            <div className='d-flex align-items-center my-4'>
                                <label className='fs-6 fw-bolder' style={{ color: '#F36523' }}>Position</label>
                                <label className='fs-6 fw-bolder ms-auto' style={{ color: '#80808F' }}>{jobTitle}</label>
                            </div>
                            <div className='d-flex '>
                                <label className='fs-6 fw-bolder me-4' style={{ color: '#F36523' }}>Skills</label>
                                <div className='ms-auto'>
                                    <div className='d-flex flex-wrap'>
                                        {competencies && competencies.map((competency, index) => {
                                            return (
                                                <div key={index} className='orange-tag px-2 py-1 ms-2 mb-2'>
                                                    <label className='fs-8 fw-bold'>{competency?.name}</label>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* questions summary view */}
                        <div className='card-bg shadow-sm p-4 mt-4'>
                            <div className='d-flex align-items-center justify-content-center mb-8'>
                                <div style={{ height: '12px', width: '12px', borderRadius: '4px', backgroundColor: '#F36523' }}></div>
                                <label className='ms-2 me-6 fs-7 fw-bold'>Attempted</label>
                                <div style={{ height: '12px', width: '12px', borderRadius: '4px', backgroundColor: '#D9D9D9' }}></div>
                                <label className='ms-2 me-6 fs-7 fw-bold'>Lapsed</label>
                                <div style={{ height: '12px', width: '12px', borderRadius: '4px', border: 'solid 1px #F36523' }}></div>
                                <label className='ms-2 fs-7 fw-bold'>Remaining</label>
                            </div>
                            {/* show question numbers and status */}
                            <div className='d-flex flex-wrap justify-content-center' >
                                {allQuestions && allQuestions.map((question, index) => {
                                    const status = question?.status;
                                    return (
                                        <div>
                                            {status === 'remaining' && (
                                                <div className='remaining-question fs-5 fw-bolder mx-2 mb-4'>
                                                    {index < 9 ? `0${index + 1}` : `${index + 1}`}
                                                </div>
                                            )}
                                            {status === 'lapsed' && (
                                                <div className='lapsed-question fs-5 fw-bolder mx-2 mb-4'>
                                                    {index < 9 ? `0${index + 1}` : `${index + 1}`}
                                                </div>
                                            )}
                                            {status === 'attempted' && (
                                                <div className='attempted-question fs-5 fw-bolder mx-2 mb-4'>
                                                    {index < 9 ? `0${index + 1}` : `${index + 1}`}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    </div>
                    <div className='col-md-8' >
                        <div className='card-bg shadow-sm'>
                            <div className='py-4'>
                                <div className='d-flex align-items-center px-6'>
                                    <label className='fs-5 fw-bolder' style={{ color: '#F36523' }} >{`Question ${remainingQuestionIndex + 1}`}</label>
                                    <div className='d-flex align-items-center ms-auto'>
                                        <label className='fs-6 fw-bolder' style={{ color: '#80808F' }}>Question Time Remaining: </label>
                                        <label className='fs-6 fw-bolder mx-2' style={{ color: '#F36523' }}>{`${questionTimer} `}</label>
                                        <label className='fs-6 fw-bolder' style={{ color: '#80808F' }}> ss </label>
                                    </div>
                                </div>
                                <hr style={{ height: '2px' }} />
                                <div className='d-flex flex-column px-6'>
                                    {remainingQuestion?.link && (
                                        <video  autoPlay
                                        style={{ maxWidth: '100%', maxHeight: '400px', borderRadius: '4px' }}
                                        className='mb-6'
                                    >
                                        <source src={remainingQuestion?.link} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    )}

                                    <label className='fs-6 fw-bolder mb-10'>{remainingQuestion?.text}</label>

                                    {remainingQuestion?.answers && remainingQuestion?.answers.map((answer, index) => {
                                        return (
                                            <div key={index} className='d-flex align-items-center mb-8'>
                                                <input
                                                    className='me-4'
                                                    type="radio"
                                                    id={`answer-${index}`}
                                                    name="answer"
                                                    value={answer?.text}
                                                    onChange={() => handleAnswerSelection(answer?.answer_id)}
                                                    checked={selectedAnswer === answer?.answer_id} // Assuming selectedAnswer is a state variable storing the selected answer
                                                />
                                                <label className='fs-6 fw-bolder' htmlFor={`answer-${index}`}>{answer?.text}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='d-flex mt-20 px-6'>
                                    <div></div>
                                    <button className='candi-btn2 btn text-white ms-auto px-10'
                                        onClick={() => {
                                            if (selectedAnswer) {
                                                // save the answer and fetch next question if remaining else finish test
                                                handleSaveAnswer('attempted');

                                                // }
                                            } else {
                                                handleSaveAnswer('lapsed');
                                            }

                                        }}>
                                        {remainingQuestionIndex === (allQuestions.length - 1) ? 'Finish' : 'Next'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {showPopup && (
                <SuccessInterviewpopUp
                    show={true}
                    jobapp_id={props.job_id}
                    handleClose={() => {
                        handleClose()
                    }}
                    message={'Online test Completed'}
                />
            )}
            {showWarningTestEnd &&
                <EndTestWarningPopup
                    handleClose={() => setShowWarningTestEnd(false)}
                    handleYesSure={() => {
                        setShowWarningTestEnd(false)
                        stopRecording()
                    }}
                />
            }
            {loading && <LoaderView message='Loading...' />}
            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        </div>
    );
};

//make this component available to the app
export default AttemptTest;
