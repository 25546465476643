//import liraries
import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressBar from '../Setting/SettingTabs/components/ProgressBar'
import icArrow from '../Setting/images/ic_arrow_up_grey.png'
import icForm from '../../../_metronic/partials/modals/JobFunnelsModels/img/ic_form.png'
// import { useAuth } from '../../../../app/modules/auth/core/Auth';
import { useAuth } from '../auth'
import axios from 'axios'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import { v4 as uuidv4 } from 'uuid'
import { useIntl } from 'react-intl'
// import { PageTitle } from '../../../_metronic/layout/core'
import { PageTitle } from '../../../_metronic/layout/core'
import imagePath from '../../../constants/imagePath'
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'
import { getFormattedDate } from '../../../constants/CommonFunctions'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_GROUPS = `${API_URL}/group`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`
const GET_GROUP_LINKED_DATA_URL = `${API_URL}/group/get-linked-data`
const GET_ALL_AREAS_URL = `${API_URL}/group/get-functional-area`
const GET_ALL_SUB_AREAS_URL = `${API_URL}/group/get-subfunctional-area`
const GET_ALL_SECTIONS_URL = `${API_URL}/group/get-section`
const GET_ALL_CODES_URL = `${API_URL}/group/get-code`
const GET_CODE_FILTERS_URL = `${API_URL}/group/get-Location-Filter`
const GET_ALL_LOCATIONS_URL = `${API_URL}/group/get-Location`
const GET_OFFICES_URL = `${API_URL}/group/get-office-type`
const CREATE_POSITION_URL = `${API_URL}/position/create`
const UPDATE_POSITION_URL = `${API_URL}/position/`
const GET_EMPLOYEE_LIST_URL = `${API_URL}/position/get-employees`

type Props = {
  show: boolean
  handleClose: () => void
  yearly_Salary: any
  yearly_Remuneration: any
  formType: string
  groupData: any
  currentPosition: any
  updatePositions: () => void
  yearly_Remaining_Salary: any
  yearly_Remaining_Remuneration: any
  yearly_Lapsed_Salary: any
  yearly_Lapsed_Remuneration: any
  yearly_Salary_Percentage: number
  yearly_Remuneration_Percentage: number
}

// create a component
const CreatePositions = () =>
// { show, handleClose, yearly_Salary, yearly_Remuneration, formType, groupData, currentPosition, updatePositions,
// yearly_Remaining_Salary, yearly_Remaining_Remuneration, yearly_Lapsed_Salary, yearly_Lapsed_Remuneration, yearly_Salary_Percentage, yearly_Remuneration_Percentage }: Props
// props
{
  const navigate = useNavigate()
  const useLoc = useLocation()
  const params = useLoc.state?.params
  const {
    yearly_Salary,
    yearly_Remuneration,
    formType,
    groupData,
    currentPosition,
    yearly_Remaining_Salary,
    yearly_Remaining_Remuneration,
    yearly_Lapsed_Salary,
    yearly_Lapsed_Remuneration,
    yearly_Salary_Percentage,
    yearly_Remuneration_Percentage,
  } = params
  const { auth, logout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const [linkedData, setLinkedData] = useState<any>()
  const [allGroups, setAllGroups] = useState<any>([{}])
  const [allGrades, setAllGrades] = useState<any>([])
  const [allAreas, setAllAreas] = useState<any>([])
  const [allSubAreas, setAllSubAreas] = useState<any>([])
  const [allSections, setAllSections] = useState<any>([])
  const [offices, setOffices] = useState([])
  const [allCodes, setAllCodes] = useState([])
  const [allLocations, setAllLocations] = useState([])
  const [group, setGroup] = useState('')
  const [designation, setDesignation] = useState('')
  const [basicSalary, setBasicSalary] = useState<any>('')
  const [remuneration, setRemuneration] = useState<any>('')
  const [grade, setGrade] = useState('')
  const [positionType, setPositionType] = useState('')
  const [area, setArea] = useState('')
  const [subArea, setSubArea] = useState('')
  const [section, setSection] = useState('')
  const [officeType, setOfficeType] = useState('')
  const [code, setCode] = useState('')
  const [location, setLocation] = useState('')
  const [positionInfo, setPositionInfo] = useState('budgeted')
  const [isFormDisabled, setIsFormDisabled] = useState(false)

  const [yearlySalary, setYearlySalary] = useState(-1)
  const [yearlyRemuneration, setYearlyRemuneration] = useState(-1)
  const [yearlyRemainingSalary, setYearlyRemainingSalary] = useState(0)
  const [yearlyRemainingRemuneration, setYearlyRemainingRemuneration] = useState(0)
  const [yearlyLapsedSalary, setYearlyLapsedSalary] = useState(0)
  const [yearlyLapsedRemuneration, setYearlyLapsedRemuneration] = useState(0)
  const [yearlySalaryPercentage, setYearlySalaryPercentage] = useState(0)
  const [yearlyRemunerationPercentage, setYearlyRemunerationPercentage] = useState(0)
  const [multiplePositions, setMultiplePositions] = useState(false)
  const [batchId, setBatchId] = useState('')
  const [noOfPositions, setNoOfPositions] = useState<any>(1)
  const [positionLocations, setPositionLocations] = React.useState<any>([
    {
      code: '',
      officeType: '',
      location: '',
      no_of_positions: '',
      position_id: '',
    },
  ])
  const [allEmployees, setAllEmployees] = useState<any>([])
  const [employee, setEmployee] = useState<any>(null)
  const [replacementEmployees, setReplacementEmployees] = useState<any>([]);

  const groupRef = useRef(null)
  const designationRef = useRef(null)
  const basicSalaryRef = useRef(null)
  const remunerationRef = useRef(null)
  const gradeRangeRef = useRef(null)
  const regularContractPartyRef = useRef(null)
  const functionalRef = useRef(null)
  const subFunctionalRef = useRef(null)
  const sectionRef = useRef(null)
  const regionalBranchClusterRef = useRef(null)
  const codeRef = useRef(null)
  const locationRef = useRef(null)
  const budgetRef = useRef(null)
  const replacementRef = useRef(null)

  const handleKeyDown = (event, ref) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      ref.current.focus()
    }
  }

  useEffect(() => {
    if (currentPosition) {
      console.log('currentPositionData: ', currentPosition)
      setGroup(currentPosition.group_name)
      updateFunctionalAreas(currentPosition.group_name)
      setDesignation(currentPosition.title)
      setBasicSalary(
        currentPosition?.max_basic_salary_per_month ? parseInt(currentPosition?.max_basic_salary_per_month).toLocaleString() : 0
      )
      setRemuneration(
        currentPosition?.max_total_remuneration_per_month
          ? parseInt(currentPosition?.max_total_remuneration_per_month).toLocaleString()
          : 0
      )
      setGrade(currentPosition.max_grade_range)
      setPositionType(currentPosition.contact_type)
      setArea(currentPosition.functional_area)
      setSubArea(currentPosition.sub_functional_area)
      setSection(currentPosition.section)
      setPositionInfo(currentPosition.position_type)
      const batchId = currentPosition?.batch_id
      if (batchId) {
        const batchPositions = currentPosition?.batch_positions
        console.log('batchPositionInfo: ', batchPositions)
        setMultiplePositions(true)
        setBatchId(batchId)
        setNoOfPositions(batchPositions.total_count || 1)
        const locations = batchPositions.locations
        if (locations) {
          let positionsData: any = []
          locations.forEach((element, index) => {
            let data = {
              code: batchPositions.codes[index],
              officeType: batchPositions.office_types[index],
              location: element,
              no_of_positions: batchPositions.no_of_positions[index],
              position_id: batchPositions.position_ids[index],
            }
            positionsData.push(data)
          })
          setPositionLocations(positionsData)
        }
      } else {
        setOfficeType(currentPosition.office_type)
        setCode(currentPosition.code)
        setLocation(currentPosition.location)
      }
    }

    if (linkedData && area) {
      handleAreaChange(area)
    }
    if (linkedData && subArea) {
      handleSubAreaChange(subArea)
    }
    if (linkedData && section) {
      handleSectionChange(section)
    }
  }, [linkedData, area, subArea, section])

  useEffect(() => {
    console.log('groupData: ', groupData);
    if (groupData) {
      setGroup(groupData.group_name)
      // fetch updated budget data of a group
      getBudgetData(groupData.group_name)
      updateFunctionalAreas(groupData.group_name)
    } else {
      // setIsFormDisabled(true);
      if (formType === 'update') {
        setGroup(currentPosition.group_name)
        getBudgetData(currentPosition?.group_name)
        updateFunctionalAreas(currentPosition.group_name)
        let replacements = currentPosition?.replacements;
        if(replacements){
          setReplacementEmployees(JSON.parse(replacements))
        }
      } else {
        setIsFormDisabled(true);
      }
    }

    setYearlySalary(yearly_Salary)
    setYearlyRemuneration(yearly_Remuneration)
    setYearlyRemainingSalary(yearly_Remaining_Salary)
    setYearlyRemainingRemuneration(yearly_Remaining_Remuneration)
    setYearlyLapsedSalary(yearly_Lapsed_Salary)
    setYearlyLapsedRemuneration(yearly_Lapsed_Remuneration)
    setYearlySalaryPercentage(yearly_Salary_Percentage)
    setYearlyRemunerationPercentage(yearly_Remuneration_Percentage)
  }, [currentPosition])

  const handleGroupChange = (event) => {
    let groupName = event.target.value
    setGroup(groupName)
    updateFunctionalAreas(groupName)
    // fetch budget data of a group
    getBudgetData(groupName)
  }
  const handleDesignationChange = (event) => {
    setDesignation(event.target.value)
  }
  const handleSalaryChange = (event) => {
    const input = event.target.value.replace(/,/g, '') // Remove existing commas
    const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas every three digits
    setBasicSalary(formattedValue)
    // setBasicSalary(event.target.value);
  }
  const handleRemunerationChange = (event) => {
    const input = event.target.value.replace(/,/g, '') // Remove existing commas
    const formattedValue = input.replace(/\B(?=(\d{3})+(?!\d))/g, ',') // Add commas every three digits
    setRemuneration(formattedValue)
    // setRemuneration(event.target.value);
  }
  const handleGradeChange = (event) => {
    setGrade(event.target.value)
  }
  const handlePositionTypeChange = (event) => {
    setPositionType(event.target.value)
  }
  const handleAreaChange = (selectedArea) => {
    // let selectedArea = event.target.value;
    setArea(selectedArea)
    console.log('subAreas: ', Object.keys(linkedData[group][selectedArea]))
    setAllSubAreas(Object.keys(linkedData[group][selectedArea]) || [])
    setSubArea('')
    setSection('')
    setAllSections([])
  }
  const handleSubAreaChange = (selectedSubArea) => {
    // let selectedSubArea = event.target.value
    setSubArea(selectedSubArea)
    setAllSections(selectedSubArea ? linkedData[group][area][selectedSubArea] : [])
    setSection('')
  }
  const handleSectionChange = (value) => {
    setSection(value)
  }
  const handleOfficeTypeChange = (event) => {
    setOfficeType(event.target.value)
  }

  const addFields = () => {
    if (positionLocations?.length < noOfPositions) {
      let newfield = {
        code: '',
        officeType: '',
        location: '',
        no_of_positions: '',
      }
      // setInputFields([...inputFields, newfield])
      setPositionLocations((prevFields) => [...prevFields, newfield])
    } else {
      setMessage('Can not add more positions')
      setShowErrorMessage(true)
    }
  }

  const removeFields = (index) => {
    let data = [...positionLocations]
    data.splice(index, 1)
    setPositionLocations(data)
  }

  const handleTotalPositionChange = (event) => {
    const value = event.target.value
    if (value > 0 || value === '') {
      setNoOfPositions(value)
    }
  }

  const handleMultiplePositionCodeChange = (event, index) => {
    const selectedValue = event.target.value

    // Find the selected option data based on the selectedValue
    const selectedOption = allCodes.find((data) => data['location_code'] === selectedValue)

    if (selectedOption) {
      const locationCode = selectedOption['location_code']
      const location = selectedOption['location']
      const city = selectedOption['city']

      let data = [...positionLocations]
      data[index]['code'] = selectedValue
      data[index]['officeType'] = location
      data[index]['location'] = city

      setPositionLocations(data)
    } else {
      // Handle the case where the selected option data is not found
      console.error('Selected option data not found')
    }
  }

  const handleChangePositionsAtLocations = (event, index) => {
    const value = event.target.value
    if (value > 0 || value === '') {
      let data = [...positionLocations]
      data[index]['no_of_positions'] = value
      setPositionLocations(data)
    }
  }
  const handlePositionAtLocation = (event, index) => {
    let data = [...positionLocations]
    if (data) {
      let totalCount = 0
      data.map((position) => {
        totalCount = totalCount + parseInt(position?.no_of_positions || '0')
      })
      console.log('totalCount: ', totalCount)
      if (totalCount > noOfPositions) {
        data[index]['no_of_positions'] = ''
        setPositionLocations(data)
        setMessage('No of positions can not greater than total no of positions available')
        setShowErrorMessage(true)
      }
    }
  }

  const handleCodeChange = (event) => {
    // setCode(event.target.value);
    const selectedValue = event.target.value

    // Find the selected option data based on the selectedValue
    const selectedOption = allCodes.find((data) => data['location_code'] === selectedValue)

    if (selectedOption) {
      const locationCode = selectedOption['location_code']
      const location = selectedOption['location']
      const city = selectedOption['city']

      // Now you can use locationCode, location, and city as needed
      // console.log("SelectedData: Location Code:", locationCode);
      // console.log("SelectedData: Location:", location);
      // console.log("SelectedData: City:", city);
      setOfficeType(location)
      setLocation(city)
      setCode(selectedValue)
    } else {
      // Handle the case where the selected option data is not found
      console.error('Selected option data not found')
    }
  }
  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  }
  const handlePositionInfoChange = (event) => {
    let value = event.target.value;
    setPositionInfo(value)
    if (value === 'budgeted') {
      setEmployee(null);
      setReplacementEmployees([]);
    }
  }

  const handleEmployeeChange = (event) => {
    let userId = event.target.value

    if (allEmployees) {
      for (let userData of allEmployees) {
        if (userData.user_id === userId) {
          // Check if the employee already exists in the list
          const employeeExists = replacementEmployees.some(employee => employee.user_id === userId);

          if (!employeeExists) {
            // check are the multiple replacement positions are being create or single replacement is being create
            if (multiplePositions) {
              if (replacementEmployees.length < noOfPositions) {
                let data = [...replacementEmployees];
                data.push(userData)
                setReplacementEmployees(data);
              } else {
                setMessage('Can not select more replacements than total positions!');
                setShowErrorMessage(true);
              }
            } else {

              let data: any = []
              data.push(userData);
              setReplacementEmployees(data);
            }
          }
          // setEmployee(userData)
        }
      }
    }
  }

  const handleDelete = (userId: string) => {
    setReplacementEmployees((prevEmployees) => prevEmployees.filter((employee) => employee.user_id !== userId));
  };

  const displayError = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.name === 'TokenExpiredError'
    ) {
      logout()
    } else if (error.response && error.response.data && error.response.data.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        if (error.response.data.error.message) {
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
        } else {
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        }

      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  // fetching list of groups
  const getGroups = async () => {
    await axios
      .get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setAllGroups(res.data.data)
      })
      .catch((err) => {
        displayError(err)
      })
  }

  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setAllGrades(Object.values(data))
          }
        }
      })
      .catch((err) => {
        displayError(err)
      })
  }

  // fetching list of functional area, sub functional area and section
  const getLinkedData = async () => {
    await axios
      .get(GET_GROUP_LINKED_DATA_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (res) => {
        let data = res?.data?.data
        await setLinkedData(data)
        console.log('group: ', group)
        if (group) {
          updateFunctionalAreas(group)
        }
      })
      .catch((err) => {
        displayError(err)
      })
  }

  useEffect(() => {
    console.log(`status: groupName: ${group} :: linkedData: ${linkedData}`)
    if (group && linkedData) {
      updateFunctionalAreas(group)
    }
  }, [group, linkedData])

  const updateFunctionalAreas = async (selectedGroup) => {
    if (linkedData) {
      let functionalAreas = Object.keys(linkedData[selectedGroup])
      setAllAreas(functionalAreas ? functionalAreas : []);
      setArea('')
      setSubArea('')
      setSection('')
      setAllSubAreas([])
      setAllSections([])
    }
  }

  // // fetching list of areas
  // const getAllAreas = async () => {
  //     await axios.get(GET_ALL_AREAS_URL, {
  //         headers: {
  //             Authorization: `Bearer ${auth?.token}`,
  //             'Content-Type': 'application/json',
  //         },
  //     }).then((res) => {
  //         setAllAreas(res.data.functionalAreas);
  //     }).catch((err) => {
  //         displayError(err);
  //     });
  // }

  // // fetching list of sub areas
  // const getAllSubAreas = async () => {
  //     await axios.get(GET_ALL_SUB_AREAS_URL, {
  //         headers: {
  //             Authorization: `Bearer ${auth?.token}`,
  //             'Content-Type': 'application/json',
  //         },
  //     }).then((res) => {
  //         setAllSubAreas(res.data.subFunctionalAreas);
  //     }).catch((err) => {
  //         displayError(err);
  //     });
  // }

  // // fetching list of sections
  // const getSections = async () => {
  //     await axios.get(GET_ALL_SECTIONS_URL, {
  //         headers: {
  //             Authorization: `Bearer ${auth?.token}`,
  //             'Content-Type': 'application/json',
  //         },
  //     }).then((res) => {
  //         setAllSections(res.data.sections);
  //     }).catch((err) => {
  //         displayError(err);
  //     });
  // }

  // const getAllCodes = async () => {
  //     await axios.get(GET_ALL_CODES_URL, {
  //         headers: {
  //             Authorization: `Bearer ${auth?.token}`,
  //             'Content-Type': 'application/json',
  //         },
  //     }).then((res) => {
  //         setAllCodes(res.data.sections);
  //     }).catch((err) => {
  //         displayError(err);
  //     });
  // }

  const getCodeFilters = async () => {
    await axios
      .get(GET_CODE_FILTERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // setAllCodes(res.data.sections);
        let data = res.data.data

        const uniqueData = data.filter((item, index, self) => {
          return index === self.findIndex((t) => t.location_code === item.location_code)
        })

        setAllCodes(uniqueData)
      })
      .catch((err) => {
        displayError(err)
      })
  }

  const getAllOffices = async () => {
    await axios
      .get(GET_OFFICES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setOffices(res.data.sections)
      })
      .catch((err) => {
        displayError(err)
      })
  }

  const getAllLocations = async () => {
    await axios
      .get(GET_ALL_LOCATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setAllLocations(res.data.sections)
      })
      .catch((err) => {
        displayError(err)
      })
  }

  useEffect(() => {
    (async () => {
      await getGroups()
      await getLinkedData()
      await getGrades()
      // await getAllAreas();
      // await getAllSubAreas();
      // await getSections();
      await getAllOffices()
      // await getAllCodes();
      await getCodeFilters()
      await getAllLocations()
      await getEmployeeList()
    })()
  }, [])


  // fetching list of employees
  const getEmployeeList = async () => {
    await axios
      .get(GET_EMPLOYEE_LIST_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setAllEmployees(res?.data?.getEmployeeData)
      })
      .catch((error) => {
        displayError(error)
      })
  }

  // reset the values of locations for multiple positions
  useEffect(() => {
    setNoOfPositions(1)
    setPositionLocations([
      {
        code: '',
        officeType: '',
        location: '',
        no_of_positions: '',
      },
    ])
  }, [multiplePositions])

  const getBudgetData = async (groupId) => {
    setLoading(true)
    const urlWithParams = `${GET_ALL_GROUPS}?group_name=${encodeURIComponent(groupId)}`
    axios
      .get(urlWithParams, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((res) => {
        setLoading(false)
        const group = res.data.data[0]
        let yearlySalaryBudget = group.yearly_salary_budget ? group.yearly_salary_budget : 0
        let yearlyRemunerationBudget = group.yearly_remuneration_budget ? group.yearly_remuneration_budget : 0
        console.log(yearlyRemunerationBudget)
        let yearlyLapsedBudget = group.lapsed_salary_budget ? group.lapsed_salary_budget : 0
        let yearlyLapsedRemuneration = group.lapsed_remuneration_budget ? group.lapsed_remuneration_budget : 0

        if (yearlyLapsedBudget !== null && yearlyLapsedBudget !== undefined) {
          let yearlySalaryRemaining = yearlySalaryBudget - yearlyLapsedBudget
          setYearlyRemainingSalary(yearlySalaryRemaining)
          setYearlyLapsedSalary(parseInt(yearlyLapsedBudget))

          let salaryPercentage = (yearlyLapsedBudget / yearlySalaryBudget) * 100
          setYearlySalaryPercentage(salaryPercentage > 100 ? 100 : salaryPercentage)
        } else {
          setYearlyRemainingSalary(yearlySalaryBudget)
        }
        if (yearlyLapsedRemuneration !== null && yearlyLapsedRemuneration !== undefined) {
          let yearlyRemunerationRemaining = yearlyRemunerationBudget - yearlyLapsedRemuneration
          setYearlyRemainingRemuneration(yearlyRemunerationRemaining)
          setYearlyLapsedRemuneration(parseInt(yearlyLapsedRemuneration))
          let remunerationPercentage = (yearlyLapsedRemuneration / yearlyRemunerationBudget) * 100
          setYearlyRemunerationPercentage(remunerationPercentage > 100 ? 100 : remunerationPercentage)
        } else {
          setYearlyRemainingRemuneration(yearlyRemunerationBudget)
        }

        setYearlySalary(parseInt(yearlySalaryBudget))
        setYearlyRemuneration(parseInt(yearlyRemunerationBudget))

        if (yearlySalaryBudget === 0 || yearlyRemunerationBudget === 0) {
          window.alert('Please add budget data first')
          setIsFormDisabled(true)
        } else {
          setIsFormDisabled(false)
        }
      })
      .catch((err) => {
        setLoading(false)
        displayError(err)
      })
  }

  const validateCodeLocation = () => {
    let data = [...positionLocations]
    if (data) {
      data.map((position) => {
        if (position) {
          if (!position.code) {
            setMessage('Code value is missing , Please add code value first.')
            setShowErrorMessage(true)
            return false
          }
        } else {
          setMessage('Locations information is not available, Please add location first.')
          setShowErrorMessage(true)
          return false
        }
      })
    } else {
      setMessage('Locations for positions are not available, Please add location first.')
      setShowErrorMessage(true)
      return false
    }

    return true
  }

  const validateNoOfPositions = () => {
    let equal = true
    let data = [...positionLocations]
    if (data) {
      let totalCount = 0
      data.map((position) => {
        totalCount = totalCount + parseInt(position?.no_of_positions || '0')
      })
      const availablePositions = parseInt(noOfPositions)
      console.log('totalPositionType: totalCount: ', totalCount)
      console.log('totalPositionType: availablePositions:2 ', availablePositions)
      if (totalCount !== availablePositions) {
        setMessage('No of Positions can not greater or less than Total Positions available')
        setShowErrorMessage(true)
        equal = false
      }
    }
    return equal
  }

  const handleSubmit = (e) => {
    if (validateData()) {
      if (formType === 'create') {
        if (multiplePositions) {
          if (validateCodeLocation() && validateNoOfPositions()) {
            createMultiplePositions(e)
          }
        } else {
          createNewPosition(e)
        }
      } else if (formType === 'update') {
        if (multiplePositions) {
          if (validateCodeLocation() && validateNoOfPositions()) {
            updateMultiplePositions(e)
          }
        } else {
          updatePosition(e)
        }
      } else {
        // go to previous screen
        // handleClose();
      }
    }
  }

  const validateData = () => {
    if (!group) {
      setMessage('Please select group first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!designation) {
      setMessage('Designation can not be empty.')
      setShowErrorMessage(true)
      return false
    }
    if (!basicSalary) {
      setMessage('Basic salary can not be empty.')
      setShowErrorMessage(true)
      return false
    }
    if (!remuneration) {
      setMessage('Gross salary can not be empty.')
      setShowErrorMessage(true)
      return false
    }
    if (!grade) {
      setMessage('Please select Grade first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!positionType) {
      setMessage('Please select Position Type as Regular / Bank Contract or Third party first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!area) {
      setMessage('Please select Functional Area first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!subArea) {
      setMessage('Please select Sub-Functional Area first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!section) {
      setMessage('Please select Section first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (!code && !multiplePositions) {
      setMessage('Please select Code of Location first and try again.')
      setShowErrorMessage(true)
      return false
    }

    return true
  }

  // to get random id
  const generateUUID = () => {
    return uuidv4()
  }

  // create new position
  const createNewPosition = async (e) => {
    e.preventDefault()

    let replacements: any = [];
    if (positionInfo === 'replacement') {
      if (replacementEmployees.length > 0) {
        replacements.push(replacementEmployees[0])
      } else {
        setMessage('Please select a replacement employee first and try again.');
        setShowErrorMessage(true);
        return;
      }
    }

    setLoading(true)

    const data = {
      group_name: group,
      title: designation,
      max_basic_salary_per_month: basicSalary.replace(/,/g, ''),
      max_total_remuneration_per_month: remuneration.replace(/,/g, ''),
      max_grade_range: grade,
      contact_type: positionType,
      functional_area: area,
      sub_functional_area: subArea,
      section: section,
      office_type: officeType,
      code: code,
      location: location,
      position_type: positionInfo,
      ad_mode: '',
      remarks: '',
      status: 'REQUISITION_NOT_RAISED',
      no_of_positions: '1',
      replacements: JSON.stringify(replacements),
      mode_of_advertisement:'',
      // batch_id: uuid
    }
    axios
      .post(CREATE_POSITION_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        console.log('createPositionResponse: ', res.data)
        // show success popup
        setShowSuccess(true)
        // call function of parent component to update list of positions
        // updatePositions();

        // go to previous screen
        // handleClose();
      })
      .catch((err) => {
        setLoading(false)
        displayError(err)
      })
  }

  // create multiple new positions
  const createMultiplePositions = async (e) => {
    e.preventDefault()

    let replacements: any = [];
    if (positionInfo === 'replacement') {
      if (replacementEmployees.length === noOfPositions) {
        replacementEmployees.map((employee) => {
          if (employee) {
            replacements.push(employee)
          }
        });

      } else {
        setMessage('No of positions and total replacement employees should be same.');
        setShowErrorMessage(true);
        return;
      }
    }

    setLoading(true)

    try {
      const totalPositions = positionLocations.length
      let completedApiCalls = 0
      // Check if all fields in each position are valid
      const isValidPositions = positionLocations.every(
        (position) => position.code && position.officeType && position.location && position.no_of_positions
      )

      if (!isValidPositions) {
        console.error('Invalid position data. Aborting API calls.')
        setLoading(false)
        return
      }

      const uuid = generateUUID()
      for (const position of positionLocations) {
        const data = {
          group_name: group,
          title: designation,
          max_basic_salary_per_month: basicSalary.replace(/,/g, ''),
          max_total_remuneration_per_month: remuneration.replace(/,/g, ''),
          max_grade_range: grade,
          contact_type: positionType,
          functional_area: area,
          sub_functional_area: subArea,
          section: section,
          office_type: position.officeType,
          code: position.code,
          location: position.location,
          position_type: positionInfo,
          ad_mode: '',
          remarks: '',
          status: 'REQUISITION_NOT_RAISED',
          no_of_positions: position.no_of_positions,
          batch_id: uuid,
          replacements: JSON.stringify(replacements),
          mode_of_advertisement:'',
        }

        const res = await axios.post(CREATE_POSITION_URL, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })

        console.log(`API call for ${position.location} completed: `, res.data)
        // Increment the counter for completed API calls
        completedApiCalls++

        // Check if this is the last API call
        if (completedApiCalls === totalPositions) {
          setLoading(false)
          // show success popup
          setShowSuccess(true)
        }
      }
    } catch (err) {
      setLoading(false)
      displayError(err)
    }
  }

  // update current position
  const updatePosition = async (e) => {
    e.preventDefault()
    let replacements: any = [];
    if (positionInfo === 'replacement') {
      if (replacementEmployees.length > 0) {
        replacements.push(replacementEmployees[0])
      } else {
        setMessage('Please select a replacement employee first and try again.');
        setShowErrorMessage(true);
        return;
      }
    }
    setLoading(true)
    const data = {
      title: designation,
      group_name: group,
      location: location,
      code: code,
      max_basic_salary_per_month: basicSalary.replace(/,/g, ''),
      max_total_remuneration_per_month: remuneration.replace(/,/g, ''),
      max_grade_range: grade,
      contact_type: positionType,
      functional_area: area,
      sub_functional_area: subArea,
      section: section,
      status: 'REQUISITION_NOT_RAISED',
      position_type: positionInfo,
      employee_id: employee ? employee.user_id : null,
      office_type: officeType,
      remarks: null,
      replacements: JSON.stringify(replacements),
      mode_of_advertisement: currentPosition?.mode_of_advertisement,
    }

    console.log('jobUpdateData: ', data)

    axios
      .put(UPDATE_POSITION_URL + currentPosition.position_id, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        console.log('positionUpdatedResponse: ', res.data)
        // show success popup
        setShowSuccess(true)

        // call function of parent component to update list of positions
        // updatePositions();

        // go to previous screen
        // handleClose();
      })
      .catch((err) => {
        setLoading(false)
        displayError(err)
      })
  }

  const updateMultiplePositions = async (e) => {
    e.preventDefault()

    let replacements: any = [];
    if (positionInfo === 'replacement') {
      if (replacementEmployees.length === noOfPositions) {
        replacementEmployees.map((employee) => {
          if (employee) {
            replacements.push(employee)
          }
        });

      } else {
        setMessage('No of positions and total replacement employees should be same.');
        setShowErrorMessage(true);
        return;
      }
    }
    setLoading(true)

    try {
      const totalPositions = positionLocations.length
      let completedApiCalls = 0
      // Check if all fields in each position are valid
      const isValidPositions = positionLocations.every(
        (position) => position.code && position.officeType && position.location && position.no_of_positions && position.position_id
      )

      if (!isValidPositions) {
        console.error('Invalid position data. Aborting API calls.')
        setLoading(false)
        return
      }

      const uuid = generateUUID()
      console.log('batchId: batchIdUpdate: ', batchId)
      for (const position of positionLocations) {
        const data = {
          group_name: group,
          title: designation,
          max_basic_salary_per_month: basicSalary.replace(/,/g, ''),
          max_total_remuneration_per_month: remuneration.replace(/,/g, ''),
          max_grade_range: grade,
          contact_type: positionType,
          functional_area: area,
          sub_functional_area: subArea,
          section: section,
          office_type: position.officeType,
          code: position.code,
          location: position.location,
          position_type: positionInfo,
          ad_mode: '',
          remarks: '',
          status: 'REQUISITION_NOT_RAISED',
          no_of_positions: position.no_of_positions,
          batch_id: batchId,
          replacements: JSON.stringify(replacements),
          mode_of_advertisement: position.mode_of_advertisement,
        }

        const res = await axios.put(UPDATE_POSITION_URL + position.position_id, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })

        console.log(`API call for ${position.location} completed: `, res.data)
        // Increment the counter for completed API calls
        completedApiCalls++

        // Check if this is the last API call
        if (completedApiCalls === totalPositions) {
          setLoading(false)
          // show success popup
          setShowSuccess(true)
        }
      }
    } catch (err) {
      setLoading(false)
      displayError(err)
    }
  }

  const onlyNumbers = (event) => {
    // Allow only numeric characters, backspace, and delete keys
    if (
      !/\d/.test(event.key) && // Allow numeric characters
      event.key !== 'Backspace' && // Allow backspace key
      event.key !== 'Delete' && // Allow delete key
      event.key !== 'ArrowLeft' && // Allow arrow left key
      event.key !== 'ArrowRight' // Allow arrow right key
    ) {
      event.preventDefault()
    }
  }

  const intl = useIntl()

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Positions' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/settings" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Settings
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/settings?tab=DepartmentBudget" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Department Budget
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/createPositions" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Create Positions
          </Link>
        </h3>
      </div>
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123 col-md-11'>
          <label className='fs-4 form-label fw-bolder ms-14' style={{ color: 'white' }}>
            Budget Info
          </label>
        </div>
        <div className=' d-flex flex-column '>
          <div className='col-md-11 row bg-white bg-white g-5 g-xl-8 shadow my-4' style={{ borderRadius: '1rem', alignSelf: 'center' }}>
            <div className='col-md-12 d-flex'>
              <div className='col-md-6 col-sm-6 mb-4'>
                <h5 className='form-label fs-6'>Total Years Salary Budget</h5>
                <h5 className='form-label fs-6 my-4' style={{ color: '#F36523' }}>{`PKR ${yearlySalary.toLocaleString()}`}</h5>

                <ProgressBar
                  value={yearlySalaryPercentage}
                  max={100}
                  backgroundColor='#FFEBCE'
                  progressColor='#F36523'
                  shadow='rgba(0, 0, 0, 0.2)'
                />
                <div className='col-md-12 d-flex align-items-center' style={{ justifyContent: 'space-between' }}>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img src={icArrow} />
                    <h5 className='form-label fs-7 mt-4' style={{ color: '#F36523' }}>{`PKR ${yearlyLapsedSalary.toLocaleString()}`}</h5>
                    <h5 className='form-label fs-7'>Budget already lapsed</h5>
                  </div>

                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img src={icArrow} />
                    <h5 className='form-label fs-7 mt-4' style={{ color: '#F36523' }}>{`PKR ${yearlyRemainingSalary.toLocaleString()}`}</h5>
                    <h5 className='form-label fs-7'>Current Remaining Budget</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-sm-6'>
                <h5 className='form-label fs-6'>Total Years Remuneration Budget</h5>
                <h5 className='form-label fs-6 my-4' style={{ color: '#F36523' }}>{`PKR ${yearlyRemuneration.toLocaleString()}`}</h5>

                <ProgressBar
                  value={yearlyRemunerationPercentage}
                  max={100}
                  backgroundColor='#FFEBCE'
                  progressColor='#F36523'
                  shadow='rgba(0, 0, 0, 0.2)'
                />
                <div className='col-md-12 d-flex align-items-center ' style={{ justifyContent: 'space-between' }}>
                  <div className=' d-flex flex-column justify-content-center align-items-center'>
                    <img src={icArrow} />
                    <h5
                      className='form-label fs-7 mt-4'
                      style={{ color: '#F36523' }}
                    >{`PKR ${yearlyLapsedRemuneration.toLocaleString()}`}</h5>
                    <h5 className='form-label fs-7'>Budget already lapsed</h5>
                  </div>

                  <div className=' d-flex flex-column justify-content-center align-items-center'>
                    <img src={icArrow} />
                    <h5
                      className='form-label fs-7 mt-4'
                      style={{ color: '#F36523' }}
                    >{`PKR ${yearlyRemainingRemuneration.toLocaleString()}`}</h5>
                    <h5 className='form-label fs-7'>Current Remaining Budget</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form className='col-md-12' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div className='col-md-11 form-row d-flex mt-10 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
              <div className='form-group col-md-4'>
                <label className='form-label labelse '>
                  Group<span style={{ color: 'red' }}>*</span>
                </label>
                {/* <label className='form-control'>{groupData ? groupData?.group_name : currentPosition ? currentPosition.group_name : 'Group not selected'}</label> */}
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={group}
                  required
                  disabled={formType === 'update'}
                  onChange={(event) => handleGroupChange(event)}
                  onKeyDown={(event) => handleKeyDown(event, designationRef)}
                  ref={groupRef}
                >
                  <option value='Select an option'>Select an option</option>
                  {allGroups &&
                    allGroups.map((group) => (
                      <option key={group.group_id} value={group.group_name}>
                        {group.group_name}
                      </option>
                    ))}
                </select>
              </div>

              <div className='form-group col-md-4 px-4'>
                <label className='form-label labelse '>
                  Functional Designation<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  // name='status'
                  className='form-control'
                  placeholder='Functional Designation'
                  value={designation}
                  disabled={isFormDisabled}
                  onChange={(event) => handleDesignationChange(event)}
                  onKeyDown={(event) => handleKeyDown(event, basicSalaryRef)}
                  ref={designationRef}
                  required
                />
              </div>

              <div className='form-group col-md-4'>
                <label className='form-label labelse '>
                  Max Basic Salary / Month<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  // name='status'
                  className='form-control'
                  placeholder='Max Basic Salary / Month'
                  value={basicSalary}
                  onKeyDown={onlyNumbers}
                  required
                  disabled={isFormDisabled || formType === 'update'}
                  onChange={(event) => handleSalaryChange(event)}
                  ref={basicSalaryRef}
                />
              </div>
            </div>

            <div className='col-md-11 form-row d-flex mt-10 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
              <div className='form-group col-md-4'>
                {/* <label className='form-label labelse '>Max Total Remuneration / Month</label> */}
                <label className='form-label labelse '>
                  Max Gross Salary / Month<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type='text'
                  // name='status'
                  className='form-control'
                  // placeholder='Max Total Remuneration / Month'
                  placeholder='Max Gross Salary / Month'
                  value={remuneration}
                  required
                  disabled={isFormDisabled || formType === 'update'}
                  onKeyDown={onlyNumbers}
                  onChange={(event) => handleRemunerationChange(event)}
                  ref={remunerationRef}
                />
              </div>

              <div className='form-group col-md-4 px-4'>
                <label className='form-label labelse '>
                  Maximum Grade Range<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={grade}
                  required
                  disabled={isFormDisabled}
                  onChange={(event) => handleGradeChange(event)}
                  onKeyDown={(event) => handleKeyDown(event, regularContractPartyRef)}
                  ref={gradeRangeRef}
                >
                  <option value='Select an option'>Select an option</option>
                  {allGrades &&
                    allGrades.map((grade) => (
                      <option key={grade} value={grade}>
                        {grade}
                      </option>
                    ))}
                </select>
              </div>

              <div className='form-group col-md-4'>
                <label className='form-label labelse '>
                  Regular / Bank Contract / Third party<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={positionType}
                  required
                  disabled={isFormDisabled}
                  onChange={(event) => handlePositionTypeChange(event)}
                  onKeyDown={(event) => handleKeyDown(event, functionalRef)}
                  ref={regularContractPartyRef}
                >
                  <option value='Select an option'>Select an option</option>
                  <option value='Regular'>Regular</option>
                  <option value='Contractual'>Contractual</option>
                  <option value='Bank Contract/Third Party'>Bank Contract/Third Party</option>
                </select>
              </div>
            </div>

            <div className='col-md-11 form-row d-flex mt-10 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
              <div className='form-group col-md-4'>
                <label className='form-label labelse '>
                  Functional Area<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={area}
                  required
                  disabled={isFormDisabled}
                  onChange={(event) => handleAreaChange(event.target.value)}
                  onKeyDown={(event) => handleKeyDown(event, subFunctionalRef)}
                  ref={functionalRef}
                >
                  <option value='Select an option'>Select an option</option>
                  {allAreas &&
                    allAreas.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
              </div>

              <div className='form-group col-md-4 px-4'>
                <label className='form-label labelse '>
                  Sub-Functional Area<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={subArea}
                  required
                  disabled={isFormDisabled}
                  onChange={(event) => handleSubAreaChange(event.target.value)}
                  onKeyDown={(event) => handleKeyDown(event, sectionRef)}
                  ref={subFunctionalRef}
                >
                  <option value='Select an option'>Select an option</option>
                  {allSubAreas &&
                    allSubAreas.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
              </div>

              <div className='form-group col-md-4'>
                <label className='form-label labelse '>
                  Section<span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select   w-140px'
                  defaultValue='Select an option'
                  placeholder='Select an option'
                  value={section}
                  required
                  disabled={isFormDisabled}
                  onChange={(event) => handleSectionChange(event.target.value)}
                  onKeyDown={(event) => handleKeyDown(event, codeRef)}
                  ref={sectionRef}
                >
                  <option value='Select an option'>Select an option</option>
                  {allSections &&
                    allSections.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div></div>

            <div className='col-md-11 form-row d-flex mt-10 justify-content-spacearound ms-10' style={{ alignItems: 'baseline' }}>
              <label className='form-check checkbox-container form-label labelse'>
                <input
                  className='form-check-input me-2'
                  type='checkbox'
                  checked={multiplePositions}
                  onChange={() => {
                    setMultiplePositions(!multiplePositions)
                    setReplacementEmployees([]);
                  }}
                  disabled={isFormDisabled || formType === 'update'}
                />
                Multiple Positions
              </label>
            </div>

            {multiplePositions && (
              <div className='col-md-11 form-row mt-10 d-flex justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>
                    Enter Total Positions<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type='text'
                    // name='status'
                    className='form-control'
                    placeholder='0'
                    value={noOfPositions}
                    onKeyDown={onlyNumbers}
                    required={multiplePositions}
                    disabled={isFormDisabled}
                    onChange={(event) => handleTotalPositionChange(event)}
                  />
                </div>
                <div style={{ width: '88%', margin: '0 auto' }}>
                  {/* <i style={{ float: 'right' }} onClick={addFields} className='bi bi-plus-lg addmore'></i> */}
                  <img style={{ float: 'right', cursor: 'pointer' }} onClick={addFields} src={imagePath.ic_add_orange_border} />
                </div>
              </div>
            )}

            {/* add view to get location and no of positions at location*/}

            {positionLocations &&
              multiplePositions &&
              positionLocations.map((inputData, index) => {
                return (
                  <div key={index} className='col-md-11 mt-10 justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                    {index === 0 ? null : (
                      <div>
                        {/* <i style={{ float: 'right' }} onClick={() => removeFields(index)} className='bi bi bi-trash-fill trash-button'></i> */}
                        <label></label>
                        <img
                          style={{ float: 'right', cursor: 'pointer', marginLeft: 'auto' }}
                          src={imagePath.ic_delete_black_holo}
                          onClick={() => removeFields(index)}
                        />
                      </div>
                    )}
                    <div className='col-md-12 form-row d-flex justify-content-spacearound' style={{ alignItems: 'baseline' }}>
                      <div className='form-group col-md-2'>
                        <label className='form-label labelse '>
                          Code<span style={{ color: 'red' }}>*</span>
                        </label>
                        <select
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-select   w-140px'
                          defaultValue='Select an option'
                          placeholder='Select an option'
                          value={inputData?.code}
                          required
                          disabled={isFormDisabled}
                          onChange={(event) => handleMultiplePositionCodeChange(event, index)}
                          onKeyDown={(event) => handleKeyDown(event, regionalBranchClusterRef)}
                        >
                          <option value='Select an option'>Select an option</option>
                          {allCodes &&
                            allCodes.map((data) => {
                              let locationCode = data['location_code']
                              let location = data['location']
                              let city = data['city']
                              return (
                                <option key={locationCode} value={locationCode}>
                                  {locationCode}
                                </option>
                              )
                            })}
                        </select>
                      </div>

                      <div className='form-group col-md-4 px-4'>
                        <label className='form-label labelse '>
                          Regional Office / Branch / Cluster Office<span style={{ color: 'red' }}>*</span>
                        </label>
                        <label className='form-control w-140px' style={{ minHeight: '3.3rem' }}>
                          {inputData?.officeType}
                        </label>
                      </div>

                      <div className='form-group col-md-4 me-4'>
                        <label className='form-label labelse '>
                          Location<span style={{ color: 'red' }}>*</span>
                        </label>
                        <label className='form-control w-140px' style={{ minHeight: '3.3rem' }}>
                          {inputData?.location}
                        </label>
                      </div>

                      <div className='form-group col-md-2'>
                        <label className='form-label labelse '>
                          No of Positions<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type='text'
                          // name='status'
                          className='form-control'
                          placeholder='0'
                          value={inputData?.no_of_positions}
                          onKeyDown={onlyNumbers}
                          required={multiplePositions}
                          disabled={isFormDisabled}
                          onBlur={(event) => handlePositionAtLocation(event, index)}
                          onChange={(event) => handleChangePositionsAtLocations(event, index)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}

            {!multiplePositions && (
              <div
                id='locationInfo'
                className='col-md-11 form-row d-flex mt-10 justify-content-spacearound'
                style={{ alignItems: 'baseline' }}
              >
                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>
                    Code<span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    name='status'
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select   w-140px'
                    defaultValue='Select an option'
                    placeholder='Select an option'
                    value={code}
                    required
                    disabled={isFormDisabled}
                    onChange={(event) => handleCodeChange(event)}
                    onKeyDown={(event) => handleKeyDown(event, regionalBranchClusterRef)}
                    ref={codeRef}
                  >
                    <option value='Select an option'>Select an option</option>
                    {allCodes &&
                      allCodes.map((data) => {
                        let locationCode = data['location_code']
                        let location = data['location']
                        let city = data['city']
                        return (
                          <option key={locationCode} value={locationCode}>
                            {locationCode}
                          </option>
                        )
                      })}
                  </select>
                </div>

                <div className='form-group col-md-4 px-4'>
                  <label className='form-label labelse '>
                    Regional Office / Branch / Cluster Office<span style={{ color: 'red' }}>*</span>
                  </label>
                  <label className='form-control w-140px' style={{ minHeight: '3.3rem' }}>
                    {officeType}
                  </label>
                  {/* <select
                                        name='status'
                                        data-control='select2'
                                        data-hide-search='true'
                                        className='form-select   w-140px'
                                        defaultValue='Select an option'
                                        placeholder='Select an option'
                                        value={officeType}
                                        required
                                        disabled={isFormDisabled}
                                        onChange={(event) => handleOfficeTypeChange(event)}
                                        onKeyDown={(event) => handleKeyDown(event, locationRef)}
                                        ref={regionalBranchClusterRef}
                                    >
                                        <option value='Select an option'>Select an option</option>
                                        {offices && (offices.map((data) => (
                                            <option key={data} value={data}>
                                                {data}
                                            </option>
                                        )))}
                                    </select> */}
                </div>

                <div className='form-group col-md-4'>
                  <label className='form-label labelse '>
                    Location<span style={{ color: 'red' }}>*</span>
                  </label>
                  <label className='form-control w-140px' style={{ minHeight: '3.3rem' }}>
                    {location}
                  </label>
                  {/* <select
                                        name='status'
                                        data-control='select2'
                                        data-hide-search='true'
                                        className='form-select   w-140px'
                                        defaultValue='Select an option'
                                        placeholder='Select an option'
                                        value={location}
                                        required
                                        disabled={isFormDisabled}
                                        onChange={(event) => handleLocationChange(event)}
                                        onKeyDown={(event) => handleKeyDown(event, budgetRef)}
                                        ref={locationRef}
                                    >
                                        <option value='Select an option'>Select an option</option>
                                        {allLocations && (allLocations.map((data) => (
                                            <option key={data} value={data}>{data}</option>
                                        )))}

                                    </select> */}
                </div>
              </div>
            )}

            <div className='col-md-11 mt-10 '>
              <div className='col-md-5 form-row d-flex '>
                <div className='form-group col-md-4' style={{ display: 'flex' }}>
                  <input
                    type='radio'
                    id='budgeted'
                    name='options'
                    value='budgeted'
                    checked={positionInfo === 'budgeted'}
                    onChange={(event) => handlePositionInfoChange(event)}
                    className='checks-input'
                    disabled={isFormDisabled}
                    onKeyDown={(event) => handleKeyDown(event, replacementRef)}
                    ref={budgetRef}
                  />
                  <label htmlFor='budgeted' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                    Budgeted
                  </label>
                </div>

                <div className='form-group  col-md-5 ' style={{ display: 'flex' }}>
                  <input
                    type='radio'
                    id='replacement'
                    name='options'
                    value='replacement'
                    checked={positionInfo === 'replacement'}
                    onChange={(event) => handlePositionInfoChange(event)}
                    className='checks-input'
                    disabled={isFormDisabled}
                    ref={replacementRef}
                  />
                  <label htmlFor='replacement' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                    Replacement
                  </label>
                </div>
              </div>
            </div>

            {positionInfo === 'replacement' && (
              <div className='col-md-11 mb-6 justify-content-spacearound'>
                <div className='col-md-12 mt-10 '>
                  <label className='form-label labelse '>Add Employee</label>
                  <select
                    data-control='select2'
                    data-hide-search='true'
                    className='form-select   w-140px'
                    disabled={isFormDisabled}
                    defaultValue='Select an option'
                    placeholder='Select an option'
                    // value={employee ? employee.user_id : ''}
                    onChange={(event) => handleEmployeeChange(event)}
                  >
                    <option value='Select an option'>Select an option</option>
                    {allEmployees &&
                      allEmployees.map((user) => (
                        <option key={user?.user_id} value={user?.user_id}>
                          <div className='d-flex'>
                            <div className='me-4'>
                              <label className='form-label fs-7'>{`${user?.fullName} - ${user?.employee_id} / last date: ${getFormattedDate(user?.leaving_date)}`}</label>
                            </div>
                            {/* <div className='ms-auto' style={{marginLeft:'24px'}}>
                                                        <div className='d-flex align-items-center'>
                                                            <i className="bi bi-calendar-event-fill me-1" />
                                                            <label className='form-label fs-7'>{user.leaving_date}</label>
                                                        </div>
                                                    </div> */}
                          </div>
                        </option>
                      ))}
                  </select>
                </div>
                {/* {employee && (
                  <div className='col-md-11 mt-4'>
                    <div
                      style={{
                        width: 'fit-content',
                        display: 'inline-block',
                        margin: '0 1rem 1rem 0 ',
                      }}
                    >
                      <div className='chip-uxi'>
                        {`${employee['fullName']}`}&nbsp;
                        <img className='ms-2' src={imagePath.ic_cross_orange} alt='remove' onClick={() => handleDelete()}></img>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* {(replacementEmployees && replacementEmployees.length > 0) && replacementEmployees.map((employee, index) => {

                  return (
                    <div key={employee?.user_id} className='col-md-11 mt-4'>
                      <div
                        style={{
                          width: 'fit-content',
                          display: 'inline-block',
                          margin: '0 1rem 1rem 0 ',
                        }}
                      >
                        <div className='chip-uxi'>
                          {`${employee['fullName']}`}&nbsp;
                          <img className='ms-2' src={imagePath.ic_cross_orange} alt='remove' onClick={() => handleDelete()}></img>
                        </div>
                      </div>

                    </div>
                  )

                })} */}

                <div className='row'>
                  {(replacementEmployees && replacementEmployees.length > 0) && replacementEmployees.map((employee, index) => (
                    <div key={employee?.user_id} className='col-auto mt-4'>
                      <div className='chip-uxi d-inline-flex align-items-center'>
                        {`${employee['fullName']}`}&nbsp;
                        <img
                          className='ms-2'
                          src={imagePath.ic_cross_orange}
                          alt='remove'
                          onClick={() => handleDelete(employee.user_id)}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                  ))}
                </div>

              </div>
            )}
          </form>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <form
        //  onSubmit={handleSubmit}
        >
          <div className='btn-container'>
            <button onClick={handleSubmit} type='button' className='btn px-10 apply-review-button text-white' disabled={isFormDisabled}>
              {formType === 'create' ? 'Create' : 'Update'}
            </button>
          </div>
        </form>
      </div>

      {loading && <LoaderView message='Loading...' />}
      {showSuccess && (
        <SuccessDialog
          handleClose={() => {
            setShowSuccess(false)
            navigate('/settings?tab=DepartmentBudget', { replace: true })
          }}
          message={`${multiplePositions ? 'Positions created successfully' : 'Position created successfully'}`}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default CreatePositions
