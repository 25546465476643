//import liraries
import React, { Component, useEffect, useState } from 'react';
import { useAuth } from '../../../auth/core/Auth';
import axios from 'axios';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../../constants/imagePath';
import './componentsStyles.css';
import AddExtraDocDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddExtraDocDialog';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import AddRequiredDocDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddRequiredDocDialog';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_DOCUMENT_URL = `${API_URL}/configuration/delete-job_documents`;
const ADD_DOCUMENT_URL = `${API_URL}/configuration/add-job_documents`;
const UPLOAD_DOC_URL = `${API_URL}/configuration/doc-upload`;
const ADD_EXTRA_DOCS_URL = `${API_URL}/configuration/add-extra-doc`;

// create a component
const RequiredDocumentsCard = ({ configurationId, docs, extraDocs, ...props }) => {
    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [documentName, setDocumentName] = useState('');
    const [documentList, setDocumentList] = useState<any>([]);
    const [extraDocsFields, setExtraDocsFields] = useState<any>([{
        docName: '',
        docFile: null,
        docUrl: ''
    }])
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showAddExtraDocPopup, setShowAddExtraDocPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [showAddRequiredDocPopup, setShowAddRequiredDocPopup] = useState(false);

    useEffect(() => {
        if (docs) {
            docs = JSON.parse(docs);
            const initialData = Object.keys(docs).map((key) => ({
                i: key,
                data: docs[key]
            }));
            setDocumentList(initialData);
        }
        if (extraDocs) {
            extraDocs = JSON.parse(extraDocs);
            const initialData = Object.keys(extraDocs).map((key) => ({
                docName: extraDocs[key]['name'],
                docFile: null,
                docUrl: extraDocs[key]['link']
            }));
            console.log('extraDocsData: ', initialData);
            setExtraDocsFields(initialData);
        }
    }, [docs, extraDocs]);

    const handleDocumentNameChange = (e) => {
        setDocumentName(e.target.value);
    };

    const handleAddDocument = async (event) => {

        event.preventDefault();


        if (documentName.trim() !== '') {
            // Convert both the documentName and existing elements to lowercase
            const lowercaseDocumentName = documentName.toLowerCase();
            const lowercaseDocumentList = documentList.map((item) => item?.data.toLowerCase());

            // Check if the lowercaseDocumentName is already in the lowercaseDocumentList
            if (!lowercaseDocumentList.includes(lowercaseDocumentName)) {


                // Find the largest "i" value from the list
                const largestI = documentList.reduce((max, item) => {
                    const currentItemI = parseInt(item.i, 10);
                    return currentItemI > max ? currentItemI : max;
                }, -1); // Initialize with -1 in case the list is empty

                // Increment the largest "i" value by 1
                const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

                // Create a new object with the incremented "i" value
                const newProgram = {
                    "i": newI,
                    "data": documentName // Set the initial value as desired
                };


                // Add the new object to the list
                let allDocs = [...documentList];
                allDocs.push(newProgram);
                setDocumentList(allDocs);
                setDocumentName('');



                const data = {
                    job_documents: {},
                    configuration_id: configurationId,
                };
                // Convert the array to an object with keys as "i" and values as "data"
                allDocs.forEach((item) => {
                    data.job_documents[item.i] = item.data;
                });

                setDisabled(true);
                await axios.post(ADD_DOCUMENT_URL, data, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((res) => {
                    // fetch updated data
                    // getConfigurations();
                    setDisabled(false);

                }).catch((error) => {
                    setDisabled(false);
                    if (error.response && error.response.status === 400) {
                        // showToast(error.response.data.message)
                        setMessage(error.response.data.message);
                        setShowErrorMessage(true);
                    } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                        logout();
                    } else if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                            logout();
                        } else {
                            setMessage(error.response.data.error.message);
                            setShowErrorMessage(true);
                        }
                    } else {
                        setMessage(error.message);
                        setShowErrorMessage(true);
                    }
                });

            } else {
                // Handle the case where documentName is a duplicate (e.g., display an error message)

                setMessage('Document name already exists in the list.');
                setShowErrorMessage(true);
            }
        }
    };

    const handleRemoveDocument = async (index) => {

        if (index >= 0 && index < documentList.length) {
            const key = documentList[index].i;

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_DOCUMENT_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                const updatedList = [...documentList];
                updatedList.splice(index, 1);
                setDocumentList(updatedList);
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }

    };

    const handleAddExtraDocument = async (event) => {

        event.preventDefault();

        // let newDoc = {
        //     docName: '',
        //     docFile: null,
        //     docUrl: ''
        // };
        // setExtraDocsFields([...extraDocsFields, newDoc]);

        // show popup to create new entry
        setShowAddExtraDocPopup(true);
    };

    const removeFields = (index) => {
        let data = [...extraDocsFields];
        data.splice(index, 1)
        setExtraDocsFields(data)
    }

    const handleDocNameChange = (event, index) => {
        let docName = event.target.value;
        let data = [...extraDocsFields];
        data[index].docName = docName;
        setExtraDocsFields(data);
    }

    const handleFileChange = async (event, index) => {
        console.log('fileIndexValue: ', index);
        const file = event.target.files[0]
        console.log('fileUploadedData: ', file)
        const fileName = file.name.toLowerCase()
        // Validate file type

        if (!['.pdf', '.doc', '.docx'].some((ext) => fileName.endsWith(ext))) {
            setMessage('Invalid file type. Please upload a .pdf, .doc, or .docx file.');
            setShowErrorMessage(true);
            return
        }

        if (file !== null && file !== undefined) {
            const formData = new FormData();
            formData.append("file", file)
            await axios.post(UPLOAD_DOC_URL, formData, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                },
            }).then((res) => {
                // fetch updated data
                // getConfigurations();
                setDisabled(false);

                let data = [...extraDocsFields];
                data[index].docFile = file;
                data[index].docUrl = res.data?.docUrl;
                setExtraDocsFields(data)

            }).catch((error) => {
                setDisabled(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });

        } else {
            setMessage('File not found Please upload file first.');
            setShowErrorMessage(true);
        }
    }

    const handleSaveClick = async (event) => {
        event.preventDefault();
        const extraDocs = extraDocsFields.map((field, index) => ({
            [index]: {
                name: field.docName,
                link: field.docUrl,
            },
        }));
        const formattedExtraDocs = Object.assign({}, ...extraDocs);
        let data = {
            configuration_id: configurationId,
            extra_documents: formattedExtraDocs
        }
        setDisabled(true);
        await axios.post(ADD_EXTRA_DOCS_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setDisabled(false);
            // show success popup
            setMessage('Extra documents saved successfully!');
            setShowSuccessPopup(true);
        }).catch((error) => {
            setDisabled(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });

    }

    function getFileNameFromUrl(url) {
        // Use the URL constructor to parse the URL
        const parsedUrl = new URL(url);

        // Access the pathname of the URL, which contains the file name
        const pathName = parsedUrl.pathname;

        // Split the pathname by '/' to get an array of path segments
        const pathSegments = pathName.split('/');

        // The last segment in the array should be the file name
        const fileName = pathSegments[pathSegments.length - 1];

        return fileName;
    }

    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative' >
            <div>
                {/* <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Required Documents</label>
                </div> */}

                <div className="mb-3 d-flex mx-8 align-items-center">
                    {/* <div className="col-6">
                        <input
                            type="text"
                            className='form-control'
                            placeholder="Enter document name"
                            value={documentName}
                            onChange={handleDocumentNameChange}
                        />
                    </div> */}

                    <label className='form-label fs-4 fw-bolder card-heading'>Required Documents</label>

                    <div className='ms-auto'>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            onClick={(e) => {
                                setShowAddRequiredDocPopup(true);
                                // if (!disabled) {
                                //     handleAddDocument(e)

                                // }
                            }}
                        // disabled={disabled}
                        >
                            Add New
                        </div>
                    </div>
                </div>

                <div className='mx-8 mt-6'>
                    {documentList ? documentList.map((item, index) => (
                        <div key={index} className="badge orange-tag me-4 mb-2" style={{ position: 'relative', zIndex: '1' }}>
                            {item?.data}
                            <img style={{ cursor: 'pointer', marginLeft: '4px' }} src={imagePath.ic_cross_orange}
                                onClick={() => {
                                    if (!disabled) {
                                        handleRemoveDocument(index)
                                    }
                                }}
                            />

                        </div>
                    )) : (<div></div>)}
                </div>
            </div>

            <div className='mx-8 mt-6' style={{height:'1px', backgroundColor:'#CBC9C9'}}></div>

            {/* extra documents view */}
            <div className='mt-10'>
                <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Extra Documents</label>
                    <div className='ms-auto'>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            style={{ position: 'relative', zIndex: '1' }}
                            onClick={(e) => {
                                if (!disabled) {
                                    handleAddExtraDocument(e)
                                }

                            }}
                        // disabled={disabled}
                        >
                            Add New
                        </div>
                    </div>
                </div>
                <div className='mx-4 d-flex mt-6 justify-content-center' style={{ flexWrap: 'wrap', overflow: 'hidden' }}>
                    {extraDocsFields.map((item, index) => (
                        <div key={index} className="extra-doc-card-size px-4 py-4 me-2 " style={{ backgroundColor: 'white', position: 'relative', zIndex: '1' }}>

                            <div className='shadow-sm p-2' style={{ borderRadius: '8px' }}>
                                <div >
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter document name'
                                        value={item?.docName ? item?.docName : ''}
                                        onChange={(event) => handleDocNameChange(event, index)}
                                    />
                                </div>
                                <div className='drop-zone_extra_doc mt-4 mb-2' >
                                    <div className='drop-wrapper'>
                                        <div className='drop-image-container'>
                                            <img
                                                className='drop-zone__img'
                                                src={imagePath.ic_drag_drop}
                                                alt='Document File'
                                            />
                                        </div>
                                        <div className='drop-image-container'>
                                            <span className='fs-7 fw-bolder' style={{ color: '#373D4A' }}>Drag & Drop Replace Document</span>

                                            <span className='drop-zone__prompt fs-9 mt-4'>
                                                Accepted formats includes .pdf, .doc or .docx
                                            </span>
                                        </div>
                                    </div>
                                    <div className='cv-container'>
                                        <input
                                            type='file'
                                            accept='.pdf,.doc,.docx'
                                            name='myFile'
                                            className='drop-zone__input'
                                            onChange={(event) => handleFileChange(event, index)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    {(item?.docFile || item?.docUrl) ? (

                                        <label className='light-txt fs-6 fw-regular'
                                            style={{ maxWidth: '18rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            title={`${item?.docFile ? ((item?.docFile).name) : getFileNameFromUrl(item?.docUrl)}`}>
                                            <span className='fs-6 ' style={{ color: '#484848' }}>Selected file: </span>
                                            {`${item?.docFile ? ((item?.docFile).name) : getFileNameFromUrl(item?.docUrl)}`}
                                        </label>

                                    ) : (<div style={{ height: '1.55rem' }}></div>)}
                                    <button
                                        style={{ marginLeft: 'auto', color: '#F36523' }}
                                        className="btn-close btn-sm "
                                        onClick={() => removeFields(index)}
                                    ></button>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                {/* <div className='mx-4 mt-6 row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4'>
                    {extraDocsFields.map((item, index) => (
                        <div key={index} className="extra-doc-card-size px-4 py-4 mb-3 ">
                            <div className='shadow-sm p-2' style={{ borderRadius: '8px' }}>
                                <div>
                                    <input
                                        type='text'
                                        className='form-control'
                                        placeholder='Enter document name'
                                        value={item?.docName ? item?.docName : ''}
                                        onChange={(event) => handleDocNameChange(event, index)}
                                    />
                                </div>
                                <div className='drop-zone_extra_doc mt-4 mb-2'>
                                    <div className='drop-wrapper'>
                                        <div className='drop-image-container'>
                                            <img
                                                className='drop-zone__img'
                                                src={imagePath.ic_drag_drop}
                                                alt='Document File'
                                            />
                                        </div>
                                        <div className='drop-image-container'>
                                            <span className='fs-7 fw-bolder' style={{ color: '#373D4A' }}>Drag & Drop Replace Document</span>
                                            <span className='drop-zone__prompt fs-9 mt-4'>Accepted formats includes .pdf, .doc or .docx</span>
                                        </div>
                                    </div>
                                    <div className='cv-container'>
                                        <input
                                            type='file'
                                            accept='.pdf,.doc,.docx'
                                            name='myFile'
                                            className='drop-zone__input'
                                            onChange={(event) => handleFileChange(event, index)}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    {(item?.docFile || item?.docUrl) ? (
                                        <label className='light-txt fs-6 fw-regular'
                                            style={{ maxWidth: '18rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            title={`${item?.docFile ? ((item?.docFile).name) : getFileNameFromUrl(item?.docUrl)}`}>
                                            <span className='fs-6 ' style={{ color: '#484848' }}>Selected file: </span>
                                            {`${item?.docFile ? ((item?.docFile).name) : getFileNameFromUrl(item?.docUrl)}`}
                                        </label>
                                    ) : (<div style={{ height: '1.55rem' }}></div>)}
                                    <button
                                        style={{ marginLeft: 'auto', color: '#F36523' }}
                                        className="btn-close btn-sm "
                                        onClick={() => removeFields(index)}
                                    ></button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}


                <div className='d-flex justify-content-center'>
                    {disabled ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            color='#F36523'
                            spin
                            size='3x'
                        />) :
                        (<div
                            className='candi-btn2 text-white m-5'
                            // type='button'
                            onClick={(e) => {
                                if (!disabled) {
                                    handleSaveClick(e)
                                }

                            }}
                        // disabled={disabled}
                        >
                            <span style={{ fontWeight: 'bold' }}>Save</span>
                        </div>)}
                </div>
            </div>

            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_docs_orange} style={{ width: '220px', height: '220px', opacity: '0.08', zIndex: '-1' }} />
            </div>

            {showAddRequiredDocPopup && (
                <AddRequiredDocDialog
                    configurationId={configurationId}
                    documentList={documentList}
                    handleClose={() => setShowAddRequiredDocPopup(false)}
                    handleSave={(allDocs) => {
                        setDocumentList(allDocs);
                        setShowAddRequiredDocPopup(false)
                    }}
                />
            )}
            {showAddExtraDocPopup && (
                <AddExtraDocDialog
                    handleClose={() => setShowAddExtraDocPopup(false)}
                    handleSave={async (documentName, documentType, selectedFile, fileUrl) => {
                        let newDoc = {
                            docName: `${documentName}.${documentType}`,
                            docFile: selectedFile,
                            docUrl: fileUrl
                        };
                        await setExtraDocsFields([...extraDocsFields, newDoc]);
                        setShowAddExtraDocPopup(false)
                    }}
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}

        </div>
    );
};

//make this component available to the app
export default RequiredDocumentsCard;
