//import liraries
import React from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import './InterviewPopup.css'
import { useState } from 'react'
import './Success.css'
import SuccessIcon from './img/Component 193 – 8@3x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import hurrayImg from '../images/hurray_img@3x.png';
import icTest from '../../../../app/modules/Candidate Space/assets/ic_test_orange.png';
import icTimer from '../../../../app/modules/Candidate Space/assets/ic_timer_orange.png';
import icHappy from '../../../../app/modules/Candidate Space/assets/happiness.png';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { getFormattedDate } from '../../../../constants/CommonFunctions';
import imagePath from '../../../../constants/imagePath';

type Props = {
    show: boolean
    handleClose: () => void
    jobData: any
    applicationData: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const InterviewPopup = ({ show, handleClose, jobData, applicationData }: Props) => {

    // initializing job data
    const title = jobData.title ? jobData.title : 'Title';
    const competenciesArray = JSON.parse(jobData?.competenices);
    const competencies: string[] = [];
    competenciesArray.map((val, index) => {
        const allCompetencies = Object.values(val);
        for (let i = 0; i < allCompetencies.length; i++) {
            let entity: any = allCompetencies[i]
            competencies.push(entity.name);
        }
    })
    // let onlineInterview = jobData?.online_interview;
    let onlineInterview = false;
    
    let interviewTime = 'Time';
    let interviewLink = 'Link';
    let date = 'Date';
    const currentStage = applicationData?.jobapp_stage;
    if (currentStage === 'stage1') {
        const stageData = JSON.parse(applicationData?.schedule_interview_data_stage1);
        interviewLink = stageData?.interview_address;
        date = stageData?.interviewDate;
        interviewTime = stageData?.interview_time;
        console.log('interviewType: ', stageData.interview)
        if(stageData.interview_type === 'Onsite Interview'){
            onlineInterview = false;
        }else if(stageData.interview_type === 'Online Interview'){
            onlineInterview = true;
        }
    } else if (currentStage === 'stage2') {
        const stageData = JSON.parse(applicationData?.schedule_interview_data_stage2);
        interviewLink = stageData?.interview_address;
        date = stageData?.interviewDate;
        interviewTime = stageData?.interview_time;
        if(stageData.interview_type === 'Onsite Interview'){
            onlineInterview = false;
        }else if(stageData.interview_type === 'Online Interview'){
            onlineInterview = true;
        }
    } else if (currentStage === 'stage3') {
        const stageData = JSON.parse(applicationData?.schedule_interview_data_stage3);
        interviewLink = stageData?.interview_address;
        date = stageData?.interviewDate;
        interviewTime = stageData?.interview_time;
        if(stageData.interview_type === 'Onsite Interview'){
            onlineInterview = false;
        }else if(stageData.interview_type === 'Online Interview'){
            onlineInterview = true;
        }
    }
    
    // const dateInfo = new Date(date);
    // const formatedDate = `${dateInfo.getDate()}-${dateInfo.getMonth()+1}-${dateInfo.getFullYear()}`;
    const formatedDate = getFormattedDate(date);
    
    interviewTime = `Date: ${formatedDate} Time: ${interviewTime}`
    console.log('onlineInterview:dateInfo ', interviewTime)
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
            backdropClassName="modal-backdrop backdrop-blur"
        >
            {/* close icon */}
            <div className='no-header-model123' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <img alt='' src={imagePath.ic_close}></img>
                </div>
            </div>
            {/* header */}
            <div className='interview-title'>
                <img className='me-4' src={icTest} width={34} />
                <h2 className='fw-bolder fs-3 ' >Online Interview</h2>
            </div>
            {/* body */}
            <div className='modal-body py-lg-8 px-lg-8'>
                <h4 className='heading-orange fs-4 mb-4' >{title}</h4>
                <h4 className='subheading-black fs-7 ' >Competencies Tested</h4>
                {/* show list of competencies */}
                {competencies && (<div >
                    {competencies.map((val, index) => {
                        return (
                            <div key={index} style={{ width: 'fit-content', display: 'inline-block', margin: '0 1rem 1rem 0 ' }}>
                                <div className='withdrwa-btn'>{val}&nbsp;</div>
                            </div>
                        )
                    })}
                </div>)}
                <h4 className='subheading-black fs-7 ' >{onlineInterview ? 'Click on the link to join' : 'Address'}</h4>
                {onlineInterview && (<a className='link-style fs-7 ' href={interviewLink} target='_blank' rel='noopener noreferrer'
                    style={{ textDecoration: 'underline' }}>
                    {interviewLink}
                </a>)}
                {!onlineInterview && (<p className='link-style fs-7 '> {interviewLink}</p>)}
                <div className='my-4' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img className='me-4 mb-2' src={icTimer} width={18} />
                    <h2 className='fw-normal fs-6 ' style={{ color: '#484848' }} >{interviewTime}</h2>
                </div>
                <hr className='horizontal-line my-4' />
                <h4 className='subheading-black fs-7 ' >Instructions</h4>
                <p className='paragraph-style fs-8' >Make sure to join from a laptop or desktop computer, connect from a stable internet connection and ensure that your mic and camera are working properly.</p>
                <div className='mt-8 mb-4' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center' }}>
                    <img className='me-4 mb-2' src={icHappy} width={18} />
                    <h2 className='fw-normal fs-6 ' style={{ color: '#484848' }} >Good Luck!</h2>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default InterviewPopup;
