import React from 'react'
import { useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RequestDocumentPopup } from '../../../../_metronic/partials/modals/JobFunnelsModels/RequestDocuments'
import './Approval.css'
import axios from 'axios'
import { useAuth } from '../../auth'
import AvatarIcon from '../img/Group 9681.png'
import RequestDocs from '../images/icon@2x.png'
import icDocVerifyRedCircle from '../images/ic_docs_verify_red_circle.png'
import icDocsVerified from '../images/ic_docs_verified.png'
import icApprovalsGreen from '../images/ic_approvals_green.png'
import icApprovalRedCircle from '../images/ic_approvals_red_circle.png'
import icRefresh from '../images/ic_refresh.png'
import VerfiyDocs from '../images/verifyDocs.png'
import NotesIcon from '../img/notes (1).png'
import TickIcon from '../img/Group 12283.png'
import ApprovalsIcon from '../images/Component 219 – 3@2x.png'
import { VerifyDocumentsPopup } from '../../../../_metronic/partials/modals/JobFunnelsModels/VerifyDocuments'
import { RequestforApprovalsPopup } from '../../../../_metronic/partials/modals/JobFunnelsModels/RequestForApprovals'
import ViewProfile from '../../../../_metronic/partials/modals/JobFunnelsModels/ViewProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ApporvalsObjection } from '../../../../_metronic/partials/modals/JobFunnelsModels/ApprovalsObjection'
import ShowCurrentStatus from '../../../../_metronic/partials/modals/JobFunnelsModels/ShowCurrentStatus'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import { getTotalBankingExperience, getTotalYears } from '../../../../constants/CommonFunctions'
import imagePath from '../../../../constants/imagePath'
import CustomPagination from '../../dashboards/components/CustomPagination'
interface Resource {
  candidate_details: Object
  resource: Object
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const JOB_STAGES_DETAILS = `${API_URL}/jobapplication/get-all-stages`

export const FETCH_AVG_INTER = `${API_URL}/jobapplication/set-total-avg-score`
export const FETCH_AVG_SCORE = `${API_URL}/jobapplication/set-status-approvals`

type Props = {
  job_id: any
  testExists: any
  trigger: any
}
const ApprovalTable: React.FC<Props> = ({ job_id, testExists, trigger }) => {
  console.log({ testExists })
  const [requestdocumentPopup, setRequestedDocumentPopup] = useState(<></>)
  const [verifydocpopup, setVerifydocpopup] = useState(<></>)
  const [result, setResult] = useState<any>([])
  const [CandidatesData, setCandidatesData] = useState<any>([])
  let getallCandidates = [{}]
  const { auth, logout } = useAuth()
  const [showPopup, setShowPop] = useState(true)
  const [approvalRequest, setApprovalRequest] = useState(<></>)
  const [activeStep, setActiveStep] = useState(1)
  const [nextpop, setNextpop] = useState(false)
  const [prevpop, setPrevpop] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [buttonText, setButtonText] = useState('Request for Documents')
  const [buttonTextTwo, setButtonTextTwo] = useState('Verify Documents')
  const [buttonTextThree, setButtonTextThree] = useState('Request for Approval')
  const [step, setStep] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [institution, setinstitution] = useState<any>('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [jobTitle, setJobTitle] = useState<any>()
  const [interviewStages, setInterviewStages] = useState<any>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [avgScore, setAvgScore] = useState<any>(['Verification Pending'])
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [showObjection, setShowObjection] = useState(false);
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [currentJobApplication, setCurrentJobApplication] = useState<any>();
  const [degree, setDegree] = useState('');
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [params, setParams] = useState<any>({
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    age: '',
    bankingExp: '',
    totalExp: '',
    universities: ''
  })

  function handleRequestDocuments() {
    setButtonText('Verify Documents')
    // ... logic to show requestdocumentPopup ...
  }

  function handleVerifyDocuments() {
    setButtonText('Request for Approvals')
    // ... logic to show verifydocpopup ...
  }

  function handleRequestApprovals() {
    setButtonText('Request for Documents')
    // ... logic to show approvalRequest ...
  }

  const GETSTAGESDATA = async () => {
    await axios
      .post(
        JOB_STAGES_DETAILS,
        {
          job_id: job_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setInterviewStages(res?.data?.numberOfStages)
      })
  }

  const [activeButton, setActiveButton] = useState('request')
  const [showRequestPopup, setShowRequestPopup] = useState(false)

  const handleRequestClick = () => {
    setActiveButton('request')
  }

  const handleVerifyClick = () => {
    setActiveButton('verify')
  }

  const handleApproveClick = () => {
    setActiveButton('approve')
  }

  const handleRequestPopupClose = () => {
    setActiveButton('verify')
  }

  const handleShowRequestPopup = () => {
    setShowRequestPopup(true)
  }

  const createPopupDoc = (name, jobapp_id, profile_pic) => {
    setRequestedDocumentPopup(
      <RequestDocumentPopup
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        profile_pic={profile_pic}
        handleClose={() => {
          setRequestedDocumentPopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
    console.log('popup', jobapp_id)
  }

  const createVerifyDocumentPopup = (name, jobapp_id, jobId, profile_pic, candidate_id, position) => {
    setVerifydocpopup(
      <VerifyDocumentsPopup
        show={showPopup}
        name={name}
        jobapp_id={jobapp_id}
        jobId={jobId}
        profile_pic={profile_pic}
        candidate_id={candidate_id}
        position={position}
        handleClose={() => {
          setVerifydocpopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
        updateCandidatesList={async () => {
          setVerifydocpopup(<></>)
          await FETCHSCORE()
          await GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const GetAllJobsApplicationsbypage = async () => {
    try {
      setIsLoading(true);
      axios
        .post(
          GET_ALL_JOB_APPLICATIONS,
          {
            job_id: job_id,
            getStage: 'approvals',
            limit: params.limit,
            page: params.page,
            order: params.order,
            sort: params.sort,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then(async (resp) => {
          setIsLoading(false);
          setJobTitle(resp?.data?.title)
          const jobApplications = resp?.data?.jobs

          let pagination = resp?.data?.pagination
          if (pagination) {
            if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
              let paramsData = { ...params }
              paramsData.page = pagination?.page || 1
              paramsData.limit = pagination?.limit || 10
              paramsData.total = pagination?.total || 0
              setParams(paramsData)
            }
            setTotalPages(pagination?.totalPages || 0)
          }
          setResult(jobApplications)
          // const qualifications = JSON.parse(resource[0]?.candidate?.qualification)
          // const lastIndex = Object.keys(qualifications[0]).length
          // const institution =
          //   qualifications[0][`qualification${lastIndex}`]?.institution || qualifications[0]['qualification1']['institution']
          // setinstitution(institution)
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    } catch (error) {
      setIsLoading(false);
      console.log(error)
    } finally {
      // setIsLoading(false);
    }
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  useEffect(() => {
    (async () => {
      await GetAllJobsApplicationsbypage()
      setCandidatesData(getallCandidates.slice(1))

    })()
  }, [trigger, params])

  useEffect(() => {
    GETSTAGESDATA()
    FETCHSCORE()
  }, [])

  const [isHoveredVerifyDoc, setIsHoveredVerifyDoc] = useState(false)
  const [isHoveredApproval, setIsHoveredApproval] = useState(false)

  const handleMouseEnterVerifyDoc = () => {
    setIsHoveredVerifyDoc(true)
  }

  const handleMouseLeaveVerifyDoc = () => {
    setIsHoveredVerifyDoc(false)
  }

  const handleMouseEnterApproval = () => {
    setIsHoveredApproval(true)
  }

  const handleMouseLeaveApproval = () => {
    setIsHoveredApproval(false)
  }

  const getCandidateLatestDegree = (candidate) => {
    let qualification = JSON.parse(candidate?.qualification)
    qualification = qualification[0]?.qualification1
    console.log('candidate: ', candidate)
    const program = qualification?.education_level + ': ' + qualification?.program
    return program
  }

  const doAction = (candidategData) => {
    let status = candidategData?.jobapp_status
    console.log('status: ', status)
    if (status === 'Verification Pending' || status === 'Interview Conducted' || status === 'Documents Pending') {
      createVerifyDocumentPopup(
        candidategData?.candidateData?.fullName,
        candidategData?.jobapp_id,
        candidategData?.job_id,
        candidategData?.candidate?.profile_pic,
        candidategData?.candidate?.user_id,
        jobTitle
      )
    } else if (status === 'Approvals Pending' || status === 'Objection') {
      const degreeInfo = getCandidateLatestDegree(candidategData?.candidate)
      setDegree(degreeInfo);
      setCurrentJobApplication(candidategData);
      setShowApprovalPopup(true);
      
    } else if (status === 'Documents Pending') {
      setMessage('Documnents already requested');
      setShowErrorMessage(true);
    } else {
    }
  }

  const FETCHSCORE = async () => {
    setIsLoading(true)
    axios
      .post(
        FETCH_AVG_SCORE,
        {
          job_id: job_id,
          jobapp_stage: 'approvals',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        setIsLoading(false)
        console.log({ response })
        setAvgScore(response.data)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  return (
    <div style={{ margin: '8px 0px 0px 0px' }} className={`card shadow-sm mb-xl-8`}>
      {/* begin::Body */}

      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div style={{ position: 'relative' }}>
          {isLoading && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          <div className='table-responsive'>
            {/* begin::Table */}
            <div className='d-flex'>
              <h1></h1>
              <img className='me-4 cursor-pointer ms-auto'
                title='Refresh data'
                src={imagePath.ic_refresh_orange}
                width={32}
                height={32}
                onClick={async () => {
                  await FETCHSCORE()
                  GetAllJobsApplicationsbypage()
                }} />
              {/* <div
              className='d-flex justify-content-center align-items-center'
              style={{ marginLeft: 'auto', cursor: 'pointer', marginRight: '8px' }}
              onClick={async () => {
                await FETCHSCORE()
                GetAllJobsApplicationsbypage()
              }}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='2x' />
              ) : (
                <img
                  style={{ marginLeft: 'auto', marginRight: '4px', marginBottom: '4px', cursor: 'pointer', objectFit: 'cover' }}
                  width={24}
                  src={icRefresh}
                />
              )}
              <label className='form-label' style={{ cursor: 'pointer' }}>
                Refresh Data
              </label>
            </div> */}
            </div>
            <table className='table align-middle gs-0 gy-4 mt-8 table-borders'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}

                  {/*  className='min-w-20px '></th> */}
                  <th className='min-w-100px align-items-center ps-2 align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>CNIC</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Total Experience</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Banking Experience</label>
                  </th>
                  {testExists ? <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Test Score</label>
                  </th> : null}
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Interview Stages</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Interview Score</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                  </th>
                  {/* <th className='min-w-100px '></th> */}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {result && result?.map((candidategData, index) => {
                  const isEvenRow = index % 2 === 0
                  return (
                    <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                      <td>
                        <div className='d-flex align-items-center ps-2'>
                          <div className='symbol symbol-45px me-2'>
                            <img
                              className='profile-user-icon cursor-pointer'
                              src={
                                candidategData?.candidate?.profile_pic !== null
                                  ? candidategData?.candidate?.profile_pic
                                  : candidategData?.candidateData?.profile_pic !== null
                                    ? candidategData?.candidateData?.profile_pic
                                    : imagePath.userIcon
                              }
                              alt=''
                              onClick={() => {
                                setCurrentJobApplication(candidategData);
                                setShowCandidateProfile(true);

                              }}
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <label className='job-funneling-table-content '>
                              {candidategData?.candidateData?.fullName}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <label className='job-funneling-table-content ' >{candidategData?.candidateData?.cnic}</label>
                      </td>
                      <td>
                        <div className='job-funneling-table-content '>
                          {getTotalYears(JSON.parse(candidategData?.candidate?.work_experience))}
                        </div>
                      </td>

                      <td>
                        <div className='job-funneling-table-content '>
                          {getTotalBankingExperience(JSON.parse(candidategData?.candidate?.work_experience))}
                        </div>
                      </td>

                      {testExists ? (
                        <td>
                          <div className=' dateApplicationtable '>
                            {candidategData?.test_aggregated_result ? (
                              <label
                                style={{ whiteSpace: 'nowrap' }}
                                className={`testscore-approval${candidategData?.test_aggregated_result !== null &&
                                  parseInt(candidategData?.test_aggregated_result) > 80
                                  ? ''
                                  : '-less'
                                  }`}
                              >
                                {candidategData?.test_aggregated_result}%
                              </label>
                            ) : (
                              <label className='test-score-await' style={{ whiteSpace: 'nowrap' }}>
                                Result Awaited
                              </label>
                            )}
                          </div>
                        </td>
                      ) : null}

                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span style={{ display: 'flex', flexDirection: 'row', gap: '2px', marginTop: '3px' }}>
                              {Array.from({ length: interviewStages }).map((_, index) => (
                                <img key={index} src={TickIcon} style={{ height: '3vh' }} />
                              ))}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className=' experienceJobstable '>
                          <label className='testscore-approval'>
                            {parseFloat(candidategData?.interview_stage1_score) ||
                              0 + parseFloat(candidategData?.interview_stage2_score) ||
                              0 + parseFloat(candidategData?.interview_stage3_score) ||
                              0}
                            %
                          </label>
                        </div>
                      </td>

                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-mutedjgfk me-2'>
                              {avgScore.map((score) => {
                                if (score.jobapp_id === candidategData?.jobapp_id) {
                                  return (
                                    <div
                                      key={score.jobapp_id}
                                      className={`fs-7 fw-bolder ${score.jobapp_status === 'Approvals Requested' ? 'green-chip' : 'orange-chip'
                                        }`}
                                      onClick={() => {
                                        setCurrentJobAppId(candidategData?.jobapp_id)
                                        setShowStatusPopup(true)
                                      }}
                                    >
                                      {score.jobapp_status}
                                    </div>
                                  )
                                }
                              })}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td
                        className='d-flex align-items-center '
                      >
                        <div>
                          <div className='me-1'>
                            {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                              <button
                                className='AskforDocumentbtn '
                                onClick={() => doAction(candidategData)}
                                disabled={candidategData?.jobapp_status === 'Approvals Requested'}
                              >
                                {candidategData?.jobapp_status === 'Verification Pending'
                                  ? 'Verify Documents'
                                  : candidategData?.jobapp_status === 'Approvals Pending'
                                    ? 'Request Approval'
                                    : candidategData?.jobapp_status === 'Objection'
                                      ? 'Request Approval'
                                      : candidategData?.jobapp_status === 'Approvals Requested'
                                        ? 'Approval Pending'
                                        : 'Verify Documents'}
                              </button>
                            ) : (
                              <div>
                                <label className='red-chip fs-7 fw-bolder form-label'>Withdrawn</label>
                              </div>
                            )}
                          </div>
                          <div className='doc-icon-approval-div'>
                            {requestdocumentPopup}
                            <div className='docs-icon me-4'>
                              <div>
                                <img
                                  src={
                                    candidategData?.jobapp_status === 'Approvals Pending' ||
                                      candidategData?.jobapp_status === 'Approvals Requested' ||
                                      candidategData?.jobapp_status === 'Objection'
                                      ? icDocsVerified
                                      : isHoveredVerifyDoc
                                        ? icDocVerifyRedCircle
                                        : VerfiyDocs
                                  }
                                  width={24}
                                  alt='Verify Documents'
                                  onMouseEnter={handleMouseEnterVerifyDoc}
                                  onMouseLeave={handleMouseLeaveVerifyDoc}
                                />
                                {isHoveredVerifyDoc && <div className='alt-menu'>Verify Docs</div>}
                              </div>
                            </div>
                            {verifydocpopup}
                            <div className='docs-icon'>
                              <div>
                                <img
                                  src={
                                    candidategData?.jobapp_status === 'Approvals Requested'
                                      ? icApprovalsGreen
                                      : isHoveredApproval
                                        ? icApprovalRedCircle
                                        : ApprovalsIcon
                                  }
                                  width={24}
                                  alt='Approve Documents'
                                  onMouseEnter={handleMouseEnterApproval}
                                  onMouseLeave={handleMouseLeaveApproval}
                                />
                                {isHoveredApproval && <div className='alt-menu'>Approvals</div>}
                              </div>

                            </div>
                            {approvalRequest}
                          </div>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <div key={index}>
                              <button
                                onClick={() => {
                                  setCurrentJobApplication(candidategData);
                                  setShowObjection(true);

                                }}
                                className='obj-btn-approval'
                              >
                                <img src={NotesIcon} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* pagination */}
            {(result && result.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
              <div className='col-md-4'>
                <label>{`Showing ${result ? result.length : 0} of ${params.total} results.`}</label>
              </div>
              <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                <CustomPagination
                  pageValue={params.page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>

              <div className='col-md-4 '>
                <div className='ms-auto d-flex flex-end align-items-center'>
                  <select
                    className='me-2 pagination-input'
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                    id='selectValues'
                    // value={itemsPerPage}
                    value={params.limit}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>

                  <div className='me-2'>
                    <label>Items per page</label>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>
      {showApprovalPopup && currentJobApplication && (

        < RequestforApprovalsPopup
          show={true}
          name={currentJobApplication?.candidateData?.fullName}
          job_id={currentJobApplication?.job_id}
          jobapp_id={currentJobApplication?.jobapp_id}
          // onNextStep={activeStep}
          // onSendEmail={nextpop}
          // onVerifyClick={prevpop}
          onNextStep={() => { }}
          onSendEmail={() => { }}
          onVerifyClick={() => { }}
          candidateData={currentJobApplication?.candidate}
          candidate_id={currentJobApplication?.candidateData?.user_id}
          profile_pic={currentJobApplication?.candidate?.profile_pic}
          position={jobTitle}
          uni={currentJobApplication?.institute}
          bexp={currentJobApplication?.banking_experience}
          jexp={currentJobApplication?.job_experience}
          degree={degree}
          cityPreference={currentJobApplication?.candidate?.city_preference}
          handleClose={() => {
            setShowApprovalPopup(false);
            setCurrentJobApplication(null);
          }}
          updateCandidatesList={async () => {
            setVerifydocpopup(<></>)
            GetAllJobsApplicationsbypage()
            setShowApprovalPopup(false);
            setCurrentJobApplication(null);
            await FETCHSCORE()
            await GetAllJobsApplicationsbypage()
          }}
        />
      )}
      {showCandidateProfile && currentJobApplication && (

        <ViewProfile
          show={true}
          name={currentJobApplication?.candidateData?.fullName}
          jobapp_id={currentJobApplication?.jobapp_id}
          user_id={currentJobApplication?.candidateData?.user_id}
          email={currentJobApplication?.candidateData?.email}
          profile_pic={currentJobApplication?.candidate?.profile_pic}
          cnic={currentJobApplication?.candidateData?.cnic}
          phone_number={currentJobApplication?.candidateData?.phone_number}
          education={currentJobApplication?.institute}
          skills={currentJobApplication?.candidate?.skills}
          work_Experience={currentJobApplication?.candidate?.work_experience}
          total_exp={currentJobApplication?.candidate?.totalExperience}
          banking_exp={currentJobApplication?.banking_experience}
          nonBanking_exp={currentJobApplication?.job_experience}
          educations={currentJobApplication?.candidate?.qualification}
          certifications={currentJobApplication?.candidate?.certification}
          personalData={currentJobApplication?.candidateData?.dob}
          profile_match={currentJobApplication?.profile_match}
          position={jobTitle}
          handleClose={() => {
            setShowCandidateProfile(false);
            setCurrentJobApplication(null);
            GetAllJobsApplicationsbypage()
          }}
        />
      )}
      {showObjection && currentJobApplication && (
        <ApporvalsObjection
          show={true}
          jobapp_id={currentJobApplication?.jobapp_id}
          position={jobTitle}
          candidate_id={currentJobApplication?.candidateData?.user_id}
          name={currentJobApplication?.candidateData?.fullName}
          banking_experience={currentJobApplication?.banking_experience}
          total_experience={`${(parseInt(currentJobApplication?.job_experience) + parseInt(currentJobApplication?.banking_experience))}`}
          insitution={institution}
          profile_pic={currentJobApplication?.candidate?.profile_pic}
          recruiter_id={currentJobApplication?.recuiter_id}
          handleClose={() => {
            setCurrentJobApplication(null);
            setShowObjection(false);
            GetAllJobsApplicationsbypage()
          }}
        />
      )}
      {showStatusPopup && (
        <ShowCurrentStatus
          show={true}
          jobAppId={currentJobAppId}
          stage={'approvals'}
          handleClose={() => {
            setShowStatusPopup(false)
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default ApprovalTable
