/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './ModelStyles.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import hurrayImg from '../images/hurray_img@3x.png';
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`

export const SEND_ONLINE_TEST_LINK_URL = `${API_URL}/jobapplication/send-test-link`

export const GET_TA_DATA = `${API_URL}/panel/get-tas-data`

export const CREATE_PANEL_HIRED = `${API_URL}/panel/create`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  candidate_id: string
  recuiter_id: string
  email: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserApplication = ({ show, name, jobapp_id, candidate_id, recuiter_id, email, handleClose }: Props) => {
  const { auth } = useAuth()
  const [taHead, setTaHead] = useState<any>([])
  const [taLead, setTaLead] = useState<any>([])
  const [items, setItems] = useState<any>([]);
  const [emailSent, setEmailSent] = useState(false);

  const GetAllTADATA = async () => {
    console.log({ recuiter_id })

    // Make first API request
    await axios.post(
      GET_TA_DATA,
      {
        recruiter_id: recuiter_id,
      },
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }
    ).then(async (res) => {
      console.log('hey-panel', res?.data)
      setTaHead(res?.data?.ta_Head)
      setTaLead(res?.data?.TaLead)
    })
  }
  console.log(taLead)

  useEffect(() => {
    GetAllTADATA();
  }, [])

  useEffect(() => {
    let localUser: any = localStorage.getItem("kt-auth-react-v")
    setItems(JSON.parse(localUser))

  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res1 = await axios.post(
        ACCEPT_JOB_URL,
        {
          jobapp_id: jobapp_id,
          currentStage: "application",
          newStage: "online test",
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // const res2 = await axios.post(
      //   SEND_ONLINE_TEST_LINK_URL,
      //   {
      //     candidate_id: candidate_id,
      //     jobapp_id: jobapp_id
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${auth?.token}`,
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      await setEmailSent(true);

      if (taHead) {
        const res3 = await axios.post(
          CREATE_PANEL_HIRED,
          {
            panel: [
              {
                jobapp_id: jobapp_id,
                name: "hired",
                index: 0,
                member_id: taLead?.user_id,
                email: taLead?.email,
                availability: true,
                member_type: "ta_lead",
              },
              {
                jobapp_id: jobapp_id,
                name: "hired",
                index: 1,
                member_id: taHead?.user_id,
                email: taHead?.email,
                availability: true,
                member_type: "ta_head",
              },
              {
                jobapp_id: jobapp_id,
                name: "hired",
                index: 2,
                member_id: candidate_id,
                email: email,
                availability: true,
                member_type: "candidate",
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        // console.log("panel created", res3);
      }
    } catch (error) {
      console.error(error);
    }



    // handleClose();
  };



  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123' style={{ marginTop: '-1rem' }}>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={imagePath.ic_close} ></img>
          </div>
        </div>
        {/* end::Close */}
        {emailSent ? (
          <div>
            <div className=' no-header-model123'>

              {/* end::Close */}
              <div className=' d-flex flex-column mt-10 pt-10'>
                <img alt='' className='addcompetenctimage' src={hurrayImg}></img>
                <h3 className='fw-bolder mx-auto my-5'>Success!</h3>
                <p className=' mx-auto my-5 text-style col-md-8 '>Email has been sent to the candidate successfully!</p>
              </div>
            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
              <form
              >
                <div className='btn-container'>
                  <button
                    onClick={handleClose}
                    type='button'
                    className='btn px-10 apply-review-button'
                  >
                    Okay, Got it!
                  </button>
                </div>
              </form>
            </div>
          </div>) : (<div>
            <div className='mx-auto d-flex flex-column '>
              <img alt='' src={addcompetenctimage} className='add-warning-image' />
              <div className='d-flex flex-column align-items-center mx-auto my-5'>
                <div className='fw-bolder text-center' style={{
                  font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-nunito)',
                  letterSpacing: 'var(--unnamed-character-spacing--0-02)',
                  color: 'var(--secondary-1-text-dark)',
                  textAlign: 'center',
                }}>
                  Are You sure you want to select
                </div>
                <div className='fw-bolder text-center' style={{
                  marginBottom: '1rem',
                  font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-nunito)',
                  letterSpacing: 'var(--unnamed-character-spacing--0-02)',
                  color: 'var(--secondary-1-text-dark)',
                  textAlign: 'center',
                }}>
                  {name} for next Online Test round?
                </div>
                <p style={{
                  font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-nunito)',
                  letterSpacing: 'var(--unnamed-character-spacing-0)',
                  opacity: 1,
                  textAlign: 'center',
                }}><span style={{ color: '#F36523', marginTop: '10px' }}>*</span>Online test link will be sent to {name} via email</p>
              </div>
            </div>


            <div className='modal-body py-lg-8 px-lg-8'>
              <div className='card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form
                  >

                    <div className='d-flex justify-content-center align-items-center mb-8'>
                      <button
                        onClick={handleSubmit}
                        type='button'
                        className='btn text-white px-10 bgcolorofbutoonofsubmit me-8'

                      >
                        Yes, I am sure!
                      </button>
                      <button onClick={handleClose} className='btn text-white px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                        No
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>)}
      </div>
    </Modal>
  )
}

export { ApproveUserApplication }
