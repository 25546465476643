// Context/LoginContext
import React, {useState, createContext} from 'react'

interface JobsButtonContextProps {
  children: React.ReactNode
}
export const CreateJobsButtonContext = createContext({
  jobButton: false,
  setjobButton: (jobButton: boolean) => {},
})
export const JobsButtonContext = ({children}: JobsButtonContextProps) => {
  const [jobButton, setjobButton] = useState(false)
  return (
    <CreateJobsButtonContext.Provider value={{jobButton, setjobButton}}>
      {children}
    </CreateJobsButtonContext.Provider>
  )
}
