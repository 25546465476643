import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { JobcreationForm } from './JobcreationForm'
import { CompetenciesDictionary } from './CompetenciesDictionary'
import { right } from '@popperjs/core'
import React, { useEffect, useState, useRef } from 'react'
import plusimagecom from './images/icons8-plus-math-30 (1).png'
import cross from './images/cross.png'
import { Link, useNavigate } from 'react-router-dom'

import './CreateJobs.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calnedarimage from './images/today_black_24dp.png'
import styled from 'styled-components'
import bottomarrow from './images/Path 38012.png'
import toparrow from './images/Path 38012 (1).png'
import stage1 from './images/7899 [Converted].png'
import stage2 from './images/7899 [Converted] (1).png'
import stage3 from './images/7899 [Converted] (2).png'
import stage1Clicked from './images/7899 [Converted] (3).png'
import stage2Clicked from './images/7899 [Converted] (4).png'
import axios from 'axios'
import stage3Clicked from './images/7899 [Converted] (5).png'

import checkedgpa from './images/radio_button_checked_black_24dp.png'
import uncheckedgpa from './images/radio_button_checked_unchecked_24dp.png'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import { start } from 'repl'
import { event } from 'jquery'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../constants/imagePath'
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'

// import checkeddiv from './images/radio_button_checked_black_24dp.png'
// import uncheckeddiv from './images/radio_button_checked_black_24dp.png'

// const styles = { backgroundColor: color };
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
export const CREATE_JOB_URL = `${API_URL}/job/create`
export const GET_TESTS_URL = `${API_URL}/test`
export const GET_POSITIONS_URL = `${API_URL}/position`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`

const CreateJobs = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [EndDate, setEndDate] = useState(new Date())
  const [EndDateTest, setEndDateTest] = useState()
  const navigate = useNavigate()

  // const [gradeType, setGradeType] = useState('cgpa');
  const [gradeType, setGradeType] = useState({ type: 'cgpa', value: '' })

  const [Number, setNumber] = useState('')
  const [Experience, setExperience] = useState('')
  const [minSalary, setMinSalary] = useState('0')
  const [maxSalary, setMaxSalary] = useState('0')

  const [SetStageInterview, setSetStageInterview] = useState(1)
  const [Stage1, setstage1] = useState(true)
  const [Stage2, setstage2] = useState(false)
  const [Stage3, setstage3] = useState(false)

  const [Title, setTitle] = useState({})
  const [Department, setDepartment] = useState<any>([])
  const [jobDescription, setJobDescription] = useState('')
  const [contactType, setContactType] = useState()
  const [videoResumeDescription, setVideoResumeDescription] = useState('')
  const [minimumEducation, setMinimumEducation] = useState()
  const [educationLevels, setEducationLevels] = useState<any>()
  const [minEduYears, setMinEduYears] = useState<any>('')
  const [acceptInProgressEdu, setAcceptInProgressEdu] = useState(false)
  const [linktest, setlinktest] = useState([])
  const [selectedtest, setselectedtest] = useState<any>()
  const [age, setage] = useState(25)
  const [grade, setGrade] = useState<any>('')
  const [minimumExpReq, setminimumExpReq] = useState<any>(0)
  const [AvaliablePositions, setAvailablePositions] = useState<string>('1')
  const [PositionCode, setPositionCode] = useState<Number>(0)
  const [getPositions, setPositions] = useState<any>([])
  const [isVideoResumeChecked, setVideoResumeChecked] = useState(false)
  const { currentUser } = useAuth()
  const [dataFetched, setDataFetched] = useState(false)
  const [group, setGroup] = useState<any>('')
  const [group_id, setGroupID] = useState<any>('')
  const [location, setLocation] = useState<any>('')
  const [isExpanded, setIsExpanded] = useState(false)
  const [batchId, setBatchId] = useState<any>('')
  const [recruiterId, setRecruiterId] = useState('')
  const [positionLocations, setPositionLocations] = React.useState<any>([])
  const [vaccantPosition, setvaccantPosition] = useState<any>('')
  const CompetenciesTypesArray: string[] = []
  const [CompArray, setCompArray] = useState<any>([])
  const [CompArrayIndiviual, setCompArrayIndiviual] = useState<any>([])
  const CompetenciesNamesArrayIndiviual: any[] = []
  const [CompetencyArrayinDB, setCompetencyArrayinDB] = useState<any>([])
  const { auth, logout } = useAuth()
  const user_id = currentUser?.user_id
  const [positionId, setPositionId] = useState<any>('')
  const [totalExperience, setTotalExperience] = useState<any>(0)
  const [resumeDeadLine, setResumeDeadLine] = useState<any>(null)
  const [maxBasicSalary, setmaxBasicSalary] = useState<any>(0)
  const [renumenation, setrenumenation] = useState<any>('')
  const [ageFrom, setAgeFrom] = useState(0)
  const [ageTo, setAgeTo] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()

    // if (CompetencyArrayinDB?.length === 0) {
    //   setCompetencyArrayinDB([''])
    // }
    if (!vaccantPosition) {
      setMessage('Please select a vacant position first and try again.')
      setShowErrorMessage(true)
      return
    }
    if (!CompetencyArrayinDB || CompetencyArrayinDB.length === 0) {
      setMessage('Please add competencies first.')
      setShowErrorMessage(true)
      return
    }
    if (!jobDescription) {
      setMessage('Job description not found.')
      setShowErrorMessage(true)
      return
    }
    if (!group) {
      setMessage('Group not found.')
      setShowErrorMessage(true)
      return
    }
    if (!minimumEducation) {
      setMessage('Please select education level first')
      setShowErrorMessage(true)
      return
    }
    if (!minEduYears) {
      setMessage('Please select minimum educational years required first')
      setShowErrorMessage(true)
      return
    }
    if (!gradeType?.value) {
      setMessage(`Please enter minimum required value of ${gradeType?.type} first`)
      setShowErrorMessage(true)
      return
    }
    if (!grade) {
      setMessage('Grade value not found.')
      setShowErrorMessage(true)
      return
    }
    if (!Experience) {
      setMessage('Please enter value of minimum banking experience required first.')
      setShowErrorMessage(true)
      return
    }
    if (!totalExperience) {
      setMessage('Please enter value of minimum total experience required first.')
      setShowErrorMessage(true)
      return
    }

    let bankingExperience = parseInt(Experience)
    let totalExp = parseInt(totalExperience)
    if (bankingExperience > totalExp) {
      setMessage('Banking Experience can not be greater than Total Experience.')
      setShowErrorMessage(true)
      return
    }
    if (!ageFrom) {
      setMessage('Please select value of age from first.')
      setShowErrorMessage(true)
      return
    }
    if (!ageTo) {
      setMessage('Please select value of age to first.')
      setShowErrorMessage(true)
      return
    }
    if (!location) {
      setMessage('Location value not found.')
      setShowErrorMessage(true)
      return
    }
    if (!AvaliablePositions) {
      setMessage('Value of available positions not found.')
      setShowErrorMessage(true)
      return
    }
    if (!startDate) {
      setMessage('Please select a date for valid from first.')
      setShowErrorMessage(true)
      return
    }
    if (!EndDate) {
      setMessage('Please select a date for valid to first.')
      setShowErrorMessage(true)
      return
    }
    if (!minSalary || minSalary === '0') {
      setMessage('Please enter minimum salary first.')
      setShowErrorMessage(true)
      return
    }
    if (!maxSalary || maxSalary === '0') {
      setMessage('Please enter maximum salary first.')
      setShowErrorMessage(true)
      return
    }
    if (parseInt(minSalary) >= parseInt(maxSalary)) {
      setMessage('Minimum salary can not be greater or equal to Maximum salary.')
      setShowErrorMessage(true)
      return
    }
    if (!SetStageInterview) {
      setMessage('Please select no of interview stages first.')
      setShowErrorMessage(true)
      return
    }
    // const transformedCompetencies = CompetencyArrayinDB.map((competency, index) => ({
    //   [index]: competency
    // }));

    const transformedCompetencies = CompetencyArrayinDB.reduce((result, competency, index) => {
      result[index] = competency
      return result
    }, {})

    let competenciesList = [transformedCompetencies]
    let testId = selectedtest ? selectedtest['test_id'] : null

    var JobData = {
      vacant_position: vaccantPosition,
      title: vaccantPosition,
      job_description: jobDescription,
      department: group,
      group_name: group,
      applications: 1,
      job_status: 'active',
      online_test_deadline: EndDateTest,
      video_resume_deadline: isVideoResumeChecked ? resumeDeadLine : null,
      eduction: minimumEducation,
      education_years: minEduYears,
      degree_inprogress: acceptInProgressEdu,
      // educationScore: Number,
      work_experience: totalExperience,
      banking_experience: Experience,
      shortlisting: false,
      grade_type: gradeType,
      grade: grade,
      // age_limit: age,
      age_limit: `${ageFrom}-${ageTo}`,
      location: location,
      job_type: 'on-site',
      position_available: AvaliablePositions,
      valid_from: startDate,
      valid_till: EndDate,
      online_interview: true,
      applied_candidates: {},
      salary_range_from: minSalary,
      salary_range_till: maxSalary,
      video_resume: isVideoResumeChecked ? videoResumeDescription : null,
      // recuiter_id: user_id,
      recuiter_id: recruiterId,
      // recruiter_id: recruiterId,
      position_id: positionId,
      group_id: group_id,
      department_id: group_id,
      test_id: testId,
      stage: SetStageInterview,
      competenices: competenciesList,
      batch_id: batchId ? batchId : '',
    }

    var data = JSON.stringify(JobData)
    setLoading(true)
    const url = CREATE_JOB_URL
    axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setLoading(false)
      setMessage('Approval Request for Job Advertisement has been sent to TA Lead Successfully.');
      setShowSuccessPopup(true);
      // navigate('/jobs')
    }).catch((error) => {
      setLoading(false)
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message)
        setShowErrorMessage(true)
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.name === 'TokenExpiredError'
      ) {
        logout()
      } else if (error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
        }
      } else {
        setMessage(error.message)
        setShowErrorMessage(true)
      }
    })
  }

  const checkedstateGPAFunction = (event) => {
    const value = event.target.value
    let gradeData = { ...gradeType }
    gradeData.type = value
    gradeData.value = ''
    setGradeType(gradeData)
  }

  const handleCGPAChange = (event) => {
    let cgpaValue = event.target.value
    // Remove all non-digit characters from the input
    // cgpaValue = cgpaValue.replace(/\D/g, '');
    // console.log('eduData: cgpaVal: ', cgpaValue);
    setNumber(cgpaValue)

    let gradeData = { ...gradeType }
    gradeData.value = cgpaValue
    setGradeType(gradeData)
  }

  const handleKeyPress = (event) => {
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    if (gradeType.type === 'percentage') {
      const regexPattern = /^(?:[1-9][0-9]?|100)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else if (gradeType.type === 'cgpa') {
      const regexPattern = /^(?:0(?:\.[0-9]{1,2})?|[1-3](?:\.[0-9]{1,2})?|4(?:\.0{1,2})?)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else {
      const regexPattern = /^[1-3]$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    }
  }

  const handleKeyPressExperience = (event) => {
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    const regexPattern = /^(?:[1-4]?[0-9]|50)$/
    if (!regexPattern.test(inputValue)) {
      event.preventDefault()
    }
  }

  const incrementExperience = (event) => {
    let inputValue = event.target.value
    setExperience(inputValue)
  }

  const changeJobDescription = (newDescription) => {
    setJobDescription(newDescription)
  }
  const changeVideoResumeDescription = (newDescription) => {
    setVideoResumeDescription(newDescription)
  }
  const changeMinimumEducation = (newMimimumEducation) => {
    setMinimumEducation(newMimimumEducation)
  }
  const changeMinimumEducationYears = (minEduYearsRequired) => {
    setMinEduYears(minEduYearsRequired)
  }
  const handleChangeAcceptInProgressEdu = (event) => {
    let acceptInProgress = event.target.checked
    setAcceptInProgressEdu(acceptInProgress)
  }
  // const changegrade = (newgrade) => {
  //   setgrade(newgrade)
  // }
  const chanlinktest = (selectedKey) => {
    selectedKey = JSON.parse(selectedKey)
    setselectedtest(selectedKey)
  }

  const handleVideoResumeChange = (event) => {
    setVideoResumeChecked(event.target.checked)
    if (!event.target.checked) {
      setResumeDeadLine(null)
    }
  }

  const CreateStageClicked = (value: string) => {
    if (value == 'Stage1Clicked') {
      setstage1(true)
      setSetStageInterview(1)
      setstage2(false)
      setstage3(false)
    } else if (value == 'Stage2Clicked') {
      setstage1(false)
      setSetStageInterview(2)
      setstage2(true)
      setstage3(false)
    } else if (value == 'Stage3Clicked') {
      setstage1(false)
      setSetStageInterview(3)
      setstage2(false)
      setstage3(true)
    } else {
      setstage1(false)
      setstage2(false)
      setSetStageInterview(1)
      setstage3(false)
    }
  }

  const GetAllIndiviualCompetencies = async () => {
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL)
      .then((resp) => {
        let totalCompetencies = resp?.data?.competencies
        // console.log(TotalCompetecies)

        let individualCompetencies: any = []
        if (totalCompetencies) {
          totalCompetencies.map((competency) => {
            if (competency) {
              if (competency.type === 'Individual') {
                let competencyData = { id: competency.competency_id, name: competency.name }
                individualCompetencies.push(competencyData)
              }
            }
          })
        }
        if (individualCompetencies.length > 0) {
          setCompArrayIndiviual(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const intl = useIntl()

  const addCompetency = (competency: any) => {
    let arr = [competency, ...CompetencyArrayinDB]
    let s = new Set(arr)
    setCompetencyArrayinDB(Array.from(s))
  }

  const removeItem = (index) => {
    setCompetencyArrayinDB([...CompetencyArrayinDB.slice(0, index), ...CompetencyArrayinDB.slice(index + 1)])
  }

  const GetAllTests = async () => {
    await axios
      .get(GET_TESTS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        // console.log("RESPONSE TESTS", resp.data.tests)
        setlinktest(resp?.data?.data)
        console.log(linktest)
      })
  }

  const getRecruiterPositions = async () => {
    await axios
      .get(GET_POSITIONS_URL, {
        params: {
          recruiter_id: user_id,
          status: 'REQUISITION_RAISED',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPositions(response?.data?.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // const GetAllPositions = async () => {
  //   try {
  //     const resp = await axios.post(GET_POSITIONS_URL
  //       , {
  //         recruiter_id: user_id
  //       }, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     })

  //     const extractedPositions = resp?.data?.getAllPositions.map(position => ({
  //       location: position.location,
  //       vacantPosition: position.title,
  //       grade: position.max_grade_range,
  //       maxBasicSalaryPerMonth: position.max_basic_salary_per_month,
  //       group: position.group_name,
  //       positionId: position.position_id,
  //       renumenationAmount: position.max_total_remuneration_per_month,
  //     }));

  //     setPositions(resp?.data?.getAllPositions);

  //   } catch (error) {
  //     console.error(error)
  //     // Handle the error or set the state with default values
  //   }
  // }

  useEffect(() => {
    if (!dataFetched) {
      ; (async () => {
        GetAllIndiviualCompetencies()
        GetAllTests()
        // GetAllPositions()
        getRecruiterPositions()
        getConfigurations()
        setDataFetched(true)
      })()
    }
    if (getPositions?.length > 0 && linktest?.length > 0) {
      // changetitle(JSON.stringify(getPositions[0]))
      // chanlinktest(JSON.stringify(linktest[0]))
    }
    //  setTitle(getPositions[0])
  }, [getPositions, linktest])

  const getConfigurations = async () => {
    await axios
      .get(GET_CONFIGURATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.Configuration
        let termsConditions = data?.terms_condition
        if (termsConditions) {
          termsConditions = JSON.parse(termsConditions)
          const initialTermsAndConditions = Object.keys(termsConditions).map((key) => ({
            i: key,
            data: termsConditions[key],
          }))
        }
        let eduLevels = data?.education_level
        if (eduLevels) {
          eduLevels = JSON.parse(eduLevels)
          const initialEdu = Object.keys(eduLevels)
            .map((key) => ({
              i: key,
              data: eduLevels[key]['data'],
              active: eduLevels[key]['active'],
            }))
            .filter((level) => level.active) // Filter out objects with active === false
          setEducationLevels(initialEdu)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleExperienceChange = (event) => {
    const value = event.target.value
    setminimumExpReq(parseFloat(value) || 0)
  }

  const handleTotalExperienceChange = (event) => {
    const value = event.target.value
    const onlyNumbers = /^[0-9\b]+$/ // Regex pattern to match only numbers

    if (value === '' || onlyNumbers.test(value)) {
      setTotalExperience(parseFloat(value) || 0)
    }
  }

  const handleResumeDeadLineChange = (date: Date) => {
    setResumeDeadLine(date)
  }

  const handlePositionChange = (event) => {
    const selectedPosition = event.target.value
    const matchingPosition = getPositions.find((positionData) => positionData?.title === selectedPosition)
    setvaccantPosition(selectedPosition)
    setGroup(matchingPosition ? matchingPosition.group_name : '')
    setGrade(matchingPosition ? matchingPosition.max_grade_range : '')
    setLocation(matchingPosition ? matchingPosition.location : '')
    setAvailablePositions(matchingPosition ? (matchingPosition.no_of_positions ? matchingPosition.no_of_positions : '1') : '1')
    setPositionId(matchingPosition ? matchingPosition.position_id : '')
    setmaxBasicSalary(matchingPosition ? matchingPosition.max_basic_salary_per_month : '')
    setMaxSalary(matchingPosition ? matchingPosition.max_basic_salary_per_month : 0)
    setrenumenation(matchingPosition ? matchingPosition.max_total_remuneration_per_month : '')
    setGroupID(matchingPosition ? matchingPosition.group_id : '')
    setJobDescription(matchingPosition ? matchingPosition.job_description : '')
    setBatchId(matchingPosition ? matchingPosition.batch_id : '')
    setRecruiterId(matchingPosition ? matchingPosition.recruiter_id : '')

    const batchPositions = matchingPosition?.batch_positions
    if (batchPositions) {
      const locations = batchPositions.locations
      if (locations) {
        let positionsData: any = []
        locations.forEach((element, index) => {
          let data = {
            code: batchPositions.codes[index],
            officeType: batchPositions.office_types[index],
            location: element,
            no_of_positions: batchPositions.no_of_positions[index],
            position_id: batchPositions.position_ids[index],
          }
          positionsData.push(data)
        })
        setPositionLocations(positionsData)
      }
    }
  }

  const getMaxAge = () => {
    if (contactType === 'Regular') {
      return 50 // Max age for Regular
    } else if (contactType === 'Contractual') {
      return 60 // Max age for Contractual
    } else if (contactType === 'Bank Contract/Third Party') {
      return 70 // Max age for Bank Contract/Third Party
    } else {
      return 80 // Default max age
    }
  }

  // const ages = Array.from({ length: 63 }, (_, index) => 18 + index);
  const ages = Array.from({ length: getMaxAge() - 17 }, (_, index) => 18 + index)
  const days = Array.from({ length: 30 }, (_, index) => index + 1)
  const eduYears = Array.from({ length: 7 }, (_, index) => 10 + index)

  useEffect(() => {
    // Update the ageTo value based on the contactType
    if (contactType === 'Regular') {
      setAgeTo(Math.min(ageTo, 50))
    } else if (contactType === 'Contractual') {
      setAgeTo(Math.min(ageTo, 60))
    } else if (contactType === 'Bank Contract/Third Party') {
      setAgeTo(Math.min(ageTo, 70))
    }
  }, [contactType])

  const handleAgeFromChange = (event) => {
    const selectedAgeFrom = event.target.value
    if (ageTo !== 0 && selectedAgeFrom >= ageTo) {
      window.alert('Age from must be less than age to')
    } else {
      setAgeFrom(selectedAgeFrom)
    }
  }

  const handleAgeToChange = (event) => {
    const selectedAgeTo = event.target.value
    if (ageFrom >= selectedAgeTo && selectedAgeTo !== 0) {
      window.alert('Age from must be less than age to')
    } else {
      setAgeTo(selectedAgeTo)
    }
  }

  const handleValidToDateChange = (date) => {
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    setEndDate(date)
  }

  const handleTestDeadLine = (event) => {
    // date.setHours(23);
    // date.setMinutes(59);
    // date.setSeconds(59);
    // setEndDateTest(date);

    const selectedValue = event.target.value
    setEndDateTest(selectedValue)
  }

  const toggleExpand = (e) => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Post Job' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/dashboard' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='/jobs' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Jobs
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to='#' style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Post Job
          </Link>
        </h3>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-9'>
          <div className='card shadow displayflex-setting '>
            <div className='col-xl-8'>
              {' '}
              <div className='card'>
                {/* begin::Beader */}
                <div className='card-header border-0 '>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label text-gray-800 fw-bolder fs-3 '>Job Posting</span>
                  </h3>

                  {/* <hr className='border-hr'></hr> */}
                </div>

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form onSubmit={handleSubmit}>
                    <div className='form-row d-flex justify-content-spacearound'>
                      <div className=' col-md-12'>
                        <label className='form-label labelse '>
                          {' '}
                          Select Vacant Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-12'>
                          <select
                            style={{ cursor: 'pointer' }}
                            className='form-select bg-white w-140px'
                            value={vaccantPosition}
                            required
                            onChange={handlePositionChange}
                          >
                            <option style={{ cursor: 'pointer' }} value=''>
                              Select Position
                            </option>
                            {getPositions ? (
                              getPositions.map((position, index) => {
                                // console.log('currentPosition: ', position);
                                const positionData = position
                                return (
                                  <option key={index} value={positionData.title} style={{ color: 'black', cursor: 'pointer' }}>
                                    {positionData.title}
                                  </option>
                                )
                              })
                            ) : (
                              <div>No Positions</div>
                            )}

                            {/* {getPositions.map((group, index) => (
                              group.position.map((position, subIndex) => (
                                <option key={subIndex} value={position.title} style={{ color: 'black', cursor: 'pointer' }}>
                                  {position.title}
                                </option>
                              ))
                            ))} */}
                          </select>
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-group mt-10 col-12 me-4'>
                      <div className='me-4'>
                        <label className='form-label labelse '>
                          {' '}
                          Job Description <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>

                        <div
                          className='me-9'
                        // className='job-description'
                        // style={{ listStyleType: 'disc', paddingLeft: '1em' }}
                        >
                          {ReactHtmlParser(jobDescription)}
                        </div>
                        {/* <ReactQuill
                          value={jobDescription}
                          onChange={changeJobDescription}
                          placeholder="Enter your job description"
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, false] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              ['link'],
                              ['clean'],
                            ],
                          }}
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'color',
                            'background',
                            'align',
                            'list',
                            'bullet',
                            'link',
                          ]}
                          style={{ height: '90px' }}
                        /> */}
                      </div>
                    </div>
                    {/* <div style={{ height: '100px' }}></div> */}
                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse'>
                          Group <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                            className='form-control bg-white w-140px'
                            value={group}
                            required
                          />
                        </div>
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label labelse '>
                          {' '}
                          Minimum Education Level <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            style={{ cursor: 'pointer' }}
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select Minimum Education Level'
                            required
                            value={minimumEducation}
                            onChange={(event) => changeMinimumEducation(event.target.value)}
                          >
                            <option style={{ cursor: 'pointer' }} value='Select Minimum Education Level'>
                              Select Minimum Education Level
                            </option>
                            {educationLevels &&
                              educationLevels.map((edu) => {
                                let index = edu?.i
                                let level = edu?.data
                                return (
                                  <option style={{ cursor: 'pointer' }} key={index} value={level}>
                                    {level}
                                  </option>
                                )
                              })}
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex justify-content-spacearound mt-10' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Min Education Years Required <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            style={{ cursor: 'pointer' }}
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select Min Edu Years Required'
                            required
                            value={minEduYears}
                            onChange={(event) => changeMinimumEducationYears(event.target.value)}
                          >
                            <option style={{ cursor: 'pointer' }} value=''>
                              Select Min Edu Years Required
                            </option>
                            {eduYears.map((years) => (
                              <option style={{ cursor: 'pointer' }} key={years} value={years}>
                                {years}
                              </option>
                            ))}
                            <option style={{ cursor: 'pointer' }} value='Greater than 16'>
                              {'Greater than 16'}
                            </option>
                          </select>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        {/* <label className='form-label labelse'>Group</label> */}
                        <div className=' form-check checkbox-container d-flex me-4' style={{ marginLeft: '-0.01rem' }}>
                          <input
                            id='degreeInProgressCheckBox'
                            type='checkbox'
                            style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                            className='form-check-input'
                            // required
                            checked={acceptInProgressEdu}
                            onChange={(event) => handleChangeAcceptInProgressEdu(event)}
                          />
                          <label htmlFor='degreeInProgressCheckBox' className='form-check-label'>
                            {'Degree in progress acceptable'}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='form-row d-flex  mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-sm-6 col-md-6 col-lg-6 '>
                        {/* <label className='form-label labelse '>CGPA</label> */}
                        <div className='form-row d-flex justify-content-spacearound form-label'>
                          <span style={{ color: '#d50000', marginRight: '4px' }}>*</span>
                          <div
                            className='form-group me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              id={'cgpaGrade'}
                              name={'education_type'}
                              value='cgpa'
                              checked={gradeType.type === 'cgpa'}
                              onChange={(event) => checkedstateGPAFunction(event)}
                              className='checks-input'
                            />
                            <label htmlFor='CGPA' style={{ fontWeight: 'bold', margin: '0px 0px 0px 4px' }}>
                              CGPA
                            </label>
                          </div>

                          <div
                            className='form-group me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              id={'percentageGrade'}
                              name={'education_type'}
                              value='percentage'
                              checked={gradeType.type === 'percentage'}
                              onChange={(event) => checkedstateGPAFunction(event)}
                              className='checks-input'
                            />
                            <label htmlFor='Percentage' style={{ fontWeight: 'bold', margin: '0px 0px 0px 4px' }}>
                              Percentage
                            </label>
                          </div>

                          <div
                            className='form-group me-2'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              style={{ cursor: 'pointer' }}
                              type='radio'
                              id={'divisionGrade'}
                              name={'education_type'}
                              value='division'
                              checked={gradeType.type === 'division'}
                              onChange={(event) => checkedstateGPAFunction(event)}
                              className='checks-input'
                            />
                            <label htmlFor='Division' style={{ fontWeight: 'bold', margin: '0px 0px 0px 4px' }}>
                              Division
                            </label>
                          </div>
                        </div>{' '}
                        <div className='me-4'>
                          <input
                            // type='number'
                            min='0'
                            // max={gradeType.type === 'cgpa' ? 4 : 3}
                            // step={gradeType.type === 'cgpa' ? 0.01 : 1}
                            max={gradeType.type === 'cgpa' ? 4 : gradeType.type === 'percentage' ? 100 : 3}
                            step={gradeType.type === 'cgpa' || gradeType.type === 'percentage' ? 0.01 : 1}
                            name='education_value'
                            className='form-control'
                            placeholder={gradeType.type === 'cgpa' ? '3.0' : '1'}
                            // value={Number}
                            value={gradeType.value}
                            required
                            // pattern="\d*"
                            onChange={(event) => handleCGPAChange(event)}
                            onKeyPress={(event) => handleKeyPress(event)}
                            autoComplete='off'
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label labelse '>
                          Grade <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                            className='form-control bg-white w-140px'
                            value={grade}
                            required
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3 fw-bolder '>
                          {' '}
                          Banking Experience Required <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            // type='number'
                            min='0'
                            max={50}
                            step={1}
                            name='education_value'
                            className='form-control'
                            placeholder={'1'}
                            value={Experience}
                            required
                            // pattern="\d*"
                            onChange={(event) => incrementExperience(event)}
                            onKeyPress={(event) => handleKeyPressExperience(event)}
                            autoComplete='off'
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label fs-6 mt-3 fw-bolder '>
                          {' '}
                          Total Experience <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            type='text'
                            step='0.5'
                            min={0}
                            className='form-control bg-white'
                            id=''
                            placeholder='3'
                            value={totalExperience || ''}
                            onChange={handleTotalExperienceChange}
                            required
                            autoComplete='off'
                          />
                          {/* <div className='button-top-and-botom'>
                            {' '}
                            <a onClick={() => incrementExperience()}>
                              <img src={toparrow}></img>
                            </a>
                            <a onClick={() => decrementExperience()}>
                              <img src={bottomarrow}></img>
                            </a>
                          </div> */}
                        </div>{' '}
                      </div>
                    </div>
                    <label className='form-label labelse mt-10'>Age Limit</label>
                    <div className='form-row d-flex mt-1 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <div className='me-4'>
                          {/* <label className='form-label labelse '>Age From</label> */}

                          <label className='form-label fs-6 mt-3' htmlFor='ageFrom'>
                            Age From <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                          </label>
                          <div className='me-4'>
                            <select
                              className='form-select bg-white'
                              style={{ cursor: 'pointer' }}
                              id='ageFrom'
                              value={ageFrom}
                              onChange={handleAgeFromChange}
                              required
                            >
                              <option style={{ cursor: 'pointer' }} value={0}>
                                Select age from
                              </option>
                              {ages.map((age) => (
                                <option style={{ cursor: 'pointer' }} key={age} value={age}>
                                  {age}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label fs-6 mt-3' htmlFor='ageTo'>
                          Age To <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            className='form-select bg-white'
                            style={{ cursor: 'pointer' }}
                            id='ageTo'
                            value={ageTo}
                            onChange={handleAgeToChange}
                            required
                          >
                            <option style={{ cursor: 'pointer' }} value={0}>
                              Select age to
                            </option>
                            {ages.map((age) => (
                              <option style={{ cursor: 'pointer' }} key={age} value={age}>
                                {age}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {batchId ? (
                      <div className='mt-10'>
                        <div className='d-flex mb-4'>
                          <label className='form-label labelse '>Multiple Locations</label>
                          {/* <button className='form-label labelse ms-auto fs-7' onClick={toggleExpand}>
                            {isExpanded ? 'Collapse' : 'Expand'}
                          </button> */}
                          <div className='ms-auto'>
                            {isExpanded ? (
                              <div>
                                <i style={{ float: 'right' }} onClick={toggleExpand} className='bi bi-dash-lg addmore'></i>
                              </div>
                            ) : (
                              <div>
                                <i style={{ float: 'right' }} onClick={toggleExpand} className='bi bi-plus-lg addmore'></i>
                              </div>
                            )}
                          </div>

                        </div>
                        {isExpanded ? (
                          <div>
                            {/* show all locations */}

                            {positionLocations ? (
                              positionLocations.map((entity, index) => {
                                return (
                                  <div className='form-row d-flex mt-4 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                                    <div className='form-group col-md-6'>
                                      <label className='form-label labelse '>
                                        Location <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                      </label>
                                      <div className='me-4'>
                                        <input
                                          style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                          className='form-control bg-white w-140px'
                                          value={entity?.location}
                                          required
                                        />
                                      </div>{' '}
                                    </div>

                                    <div className='form-group col-md-6 ms-8'>
                                      <label className='form-label labelse '>
                                        {' '}
                                        Avaliable Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                      </label>
                                      <div className='me-4 '>
                                        <input
                                          style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                          className='form-control bg-white w-140px'
                                          value={entity?.no_of_positions}
                                          required
                                        />
                                      </div>{' '}
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div></div>
                            )}
                          </div>
                        ) : (
                          <div>
                            {/* show only 1 location */}
                            {positionLocations && positionLocations.length > 0 ? (
                              <div>
                                <div className='form-row d-flex mt-4 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                                  <div className='form-group col-md-6'>
                                    <label className='form-label labelse '>
                                      Location <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                    </label>
                                    <div className='me-4'>
                                      <input
                                        style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                        className='form-control bg-white w-140px'
                                        value={positionLocations[0]?.location}
                                        required
                                      />
                                    </div>{' '}
                                  </div>

                                  <div className='form-group col-md-6 ms-8'>
                                    <label className='form-label labelse '>
                                      {' '}
                                      Avaliable Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                    </label>
                                    <div className='me-4 '>
                                      <input
                                        style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                                        className='form-control bg-white w-140px'
                                        value={positionLocations[0]?.no_of_positions}
                                        required
                                      />
                                    </div>{' '}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                        <div className='form-group col-md-6'>
                          <label className='form-label labelse '>
                            Location <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                          </label>
                          <div className='me-4'>
                            <input
                              style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                              className='form-control bg-white w-140px'
                              value={location}
                              required
                            />
                          </div>{' '}
                        </div>

                        <div className='form-group col-md-6 ms-8'>
                          <label className='form-label labelse '>
                            {' '}
                            Avaliable Positions <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                          </label>
                          <div className='me-4 '>
                            <input
                              style={{ margin: '0px 5px 0px 0px', cursor: 'default' }}
                              className='form-control bg-white w-140px'
                              value={AvaliablePositions}
                              required
                            />
                          </div>{' '}
                        </div>
                      </div>
                    )}

                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Valid From <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              minDate={new Date()}
                            />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label labelse '>
                          Valid To <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              selected={EndDate}
                              onChange={(date) => handleValidToDateChange(date)}
                              minDate={new Date(startDate)}
                            />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <label className='form-label  fs-6 mt-3 fw-bolder '> Salary Range</label>
                    </div>

                    {maxBasicSalary && renumenation ? (
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                        <label className='form-label  fs-6 mt-3 fw-bolder ' style={{ color: '#FF8750' }}>
                          {' '}
                          Maximum Basic Salary - {parseInt(maxBasicSalary).toLocaleString()}
                        </label>
                        <label className='form-label ms-8 fs-6 mt-3 fw-bolder ' style={{ color: '#FF8750' }}>
                          {' '}
                          Maximum Gross Salary Per Month - {parseInt(renumenation).toLocaleString()}
                        </label>
                      </div>
                    ) : null}

                    <div className='form-row d-flex justify-content-spacearound' style={{ marginLeft: '-2rem' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label fs-6 mt-3 '>
                          {' '}
                          Minimum Amount <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            type='text'
                            className='form-control bg-white'
                            id=''
                            placeholder='0'
                            autoComplete='off'
                            value={minSalary ? parseInt(minSalary).toLocaleString() : minSalary}
                            max={maxSalary}
                            onBlur={(e) => {
                              const enteredValue = e.target.value.replace(/,/g, '') // Remove commas from the entered value
                              const parsedValue = parseInt(enteredValue)
                              const parsedMaxSalary = parseInt(maxSalary)

                              if (parsedValue >= parsedMaxSalary) {
                                setMessage('Entered Minimum Amount should be less than Maximum Amount.')
                                setShowErrorMessage(true)
                                setMinSalary('0')
                              }
                            }}
                            onChange={(e) => {
                              let enteredValue = e.target.value
                              enteredValue = enteredValue.replace(/,/g, '')
                              if (/^\d*$/.test(enteredValue)) {
                                if (parseInt(enteredValue) <= parseInt(maxSalary) || enteredValue === '') {
                                  setMinSalary(enteredValue)
                                } else {
                                  if (parseInt(enteredValue) >= 0 && parseInt(enteredValue) <= maxBasicSalary) {
                                    setMinSalary(enteredValue)
                                  }
                                }
                              }
                            }}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label fs-6 mt-3'>
                          {' '}
                          Maximum Amount <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4 IncrementInputout'>
                          <input
                            style={{ margin: '0px 5px 0px 0px', border: 'none' }}
                            type='text'
                            className='form-control bg-white'
                            id=''
                            placeholder='0'
                            autoComplete='off'
                            value={maxSalary ? parseInt(maxSalary).toLocaleString() : maxSalary}
                            max={maxBasicSalary}
                            onBlur={(e) => {
                              const enteredValue = e.target.value.replace(/,/g, '') // Remove commas from the entered value
                              const parsedValue = parseInt(enteredValue)
                              const parsedMinSalary = parseInt(minSalary)

                              if (parsedValue < parsedMinSalary) {
                                setMessage('Entered Maximum Amount should be greater than Minimum Amount.')
                                setShowErrorMessage(true)
                                setMaxSalary('0')
                              }
                            }}
                            onChange={(e) => {
                              let enteredValue = e.target.value
                              enteredValue = enteredValue.replace(/,/g, '')
                              if (/^\d*$/.test(enteredValue)) {
                                if (parseInt(enteredValue) <= maxBasicSalary || enteredValue === '') {
                                  setMaxSalary(enteredValue) // Update the state only if the entered value is a valid number and less than or equal to maxBasicSalary
                                } else {
                                  if (!minSalary) {
                                    if (parseInt(enteredValue) >= 0 && parseInt(enteredValue) <= maxBasicSalary) {
                                      setMaxSalary(enteredValue)
                                    }
                                  }
                                  // console.log('enteredValue: ', enteredValue);
                                }
                              }
                            }}
                          />
                        </div>{' '}
                      </div>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem', alignItems: 'end' }}>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Link Test</label>
                        <div className='me-4'>
                          <select
                            style={{ cursor: 'pointer' }}
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Link Test to Job'
                            onChange={(event) => chanlinktest(event.target.options[event.target.selectedIndex].getAttribute('id'))}
                          >
                            <option style={{ cursor: 'pointer' }} value='null'>
                              {' '}
                              None
                            </option>
                            {linktest.map((option) => (
                              <option
                                style={{ cursor: 'pointer' }}
                                key={option['test_id']}
                                id={JSON.stringify(option)}
                                value={option['name']}
                              >
                                {option['name']}
                              </option>
                            ))}
                          </select>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label labelse '>Online Test Deadline</label>
                        <div className='me-4 w-140px'>
                          {/* <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex'
                          >
                            {' '}
                            <DatePicker
                              wrapperClassName='mydivdate'
                              selected={EndDateTest}
                              onChange={(date) => handleTestDeadLine(date)}
                            // readOnly={true}
                            />
                            <img src={calnedarimage}></img>
                          </div>
                           */}
                          <select
                            className='form-select bg-white'
                            style={{ cursor: 'pointer' }}
                            id='testValidDays'
                            value={EndDateTest}
                            onChange={handleTestDeadLine}
                            required
                          >
                            <option style={{ cursor: 'pointer' }} value={0}>
                              No of Days
                            </option>
                            {days.map((day) => (
                              <option style={{ cursor: 'pointer' }} key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='form-row d-flex my-10 justify-content-spacearound align-items-center' style={{ marginLeft: '-2rem' }}>
                      <div className='col-md-6'>
                        <input
                          className='me-2 form-check-input'
                          type='checkbox'
                          id='videoResume'
                          checked={isVideoResumeChecked}
                          onChange={handleVideoResumeChange}
                        />
                        <label className='form-label fs-6 fw-bolder' htmlFor='videoResume'>
                          Include Video Resume
                        </label>
                      </div>

                      {/* <div className='form-row d-flex my-10 justify-content-spacearound'
                        style={{ marginLeft: '-2rem', alignItems: 'end' }}> */}
                      <div className='form-group col-md-6 ms-8'>
                        <label className='form-label labelse '>Video Resume Deadline</label>
                        <div className='me-4 w-140px'>
                          <div
                            style={{
                              padding: '10px 10px 10px 10px',
                              border: '1px solid rgb(228 230 239)',
                              height: '40px',
                              color: ' rgb(228 230 239) !important  ',
                              borderRadius: '5px',
                            }}
                            className='d-flex w-140px'
                          >
                            {' '}
                            <DatePicker wrapperClassName='mydivdate' selected={resumeDeadLine} onChange={handleResumeDeadLineChange} />
                            <img src={imagePath.ic_calendar_orange}></img>
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>
                    <div className='form-row d-flex justify-content-spacearound me-12'>
                      <textarea
                        onChange={(event) => changeVideoResumeDescription(event.target.value)}
                        className='form-control'
                        placeholder='What should be included in elevator pitch?'
                        id='videoResume'
                      ></textarea>
                    </div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound' style={{ marginLeft: '-2rem' }}></div>

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <label className='form-label labelse '>
                        {' '}
                        How many interview Stages this Job Post has? <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                      </label>
                    </div>

                    <div className='form-row d-flex mt-2 justify-content-spacearound'>
                      <div
                        className={`${Stage1 === true ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage1Clicked')}
                      >
                        <span className=''>
                          <span>{Stage1 === false ? <img src={stage1}></img> : <img src={stage1Clicked}></img>}</span>
                          &nbsp; Stage 1
                        </span>
                      </div>

                      <div
                        className={`${Stage2 === true ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage2Clicked')}
                      >
                        <span className=''>
                          <span>{Stage2 === false ? <img src={stage2}></img> : <img src={stage2Clicked}></img>}</span>
                          &nbsp; Stage 2
                        </span>
                      </div>

                      <div
                        className={`${Stage3 === true ? 'stage_selected' : 'stage_unselected'
                          } Cursor_pointer fw-bold me-auto px-4 py-3 rounded-border0`}
                        onClick={() => CreateStageClicked('Stage3Clicked')}
                      >
                        <span className=''>
                          <span>{Stage3 === false ? <img src={stage3}></img> : <img src={stage3Clicked}></img>}</span>
                          &nbsp; Stage 3
                        </span>
                      </div>
                    </div>

                    <button type='submit' className='btn my-10 px-10 bgcolorbuttonpost my-5'>
                      Post
                    </button>
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>

            <div style={{ margin: '0px 0px 0px 0px' }} className='col-xl-4'>
              {' '}
              <div className='px-6 py-6'>
                {/* begin::Beader */}

                <span className='card-label fs-6 fw-bolder ' style={{ margin: '0px 0px 0px 4px' }}>
                  Competencies Required <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                </span>

                <div >
                  <div className=' d-flex flex-wrap mb-5' >
                    {CompetencyArrayinDB.map((competenmcy, index) => (
                      <a style={{ margin: '10px 0px 0px 0rem' }}>
                        <span
                          style={{ color: ' #F57D42', backgroundColor: '#FFEBCE' }}
                          className='card-label fontweigt fs-5 fw-bold px-4 py-3 d-flex align-items-center '
                          key={index}
                        >
                          <label className='me-2' style={{ maxWidth: '12rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> {competenmcy.name}</label>
                          <span>
                            <img
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                removeItem(index)
                              }}
                              src={cross}
                            ></img>
                          </span>
                        </span>
                      </a>
                    ))}

                    {/* <p className='text-muted'>8th August 2022</p> */}
                  </div>
                </div>

                {/* end::Body */}
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-3'>
          <div className='card-bg shadow-sm p-6'>
            {/* begin::Beader */}
            <div className='d-flex flex-wrap flex-stack '>
              <h4 className='fw-bolder'>Competencies Dictionary</h4>
            </div>

            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
              <div>
                {' '}
                <span>
                  <label className='form-label fs-7 mt-4 fw-semibold '>Click to add</label>
                </span>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', flexWrap: 'wrap', maxWidth: '100%' }}>
                {CompArrayIndiviual.map((competency, index) => (
                  <div className='mb-2 me-2' key={index}>
                    <span
                      onClick={() => addCompetency(competency)}
                      style={{ color: '#666666', backgroundColor: '#F4F8FA', cursor: 'pointer' }}
                      className='grey-chip fs-5 fw-bold px-4 py-3 align-items-center d-flex'
                    >
                      <span>
                        <img src={plusimagecom}></img>
                      </span>
                      <label className='ms-2' style={{ maxWidth: '12rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> {competency.name}</label>
                    </span>
                  </div>
                ))}

                {/* <p className='text-muted'>8th August 2022</p> */}
              </div>
            </div>

            {/* end::Body */}
          </div>{' '}
        </div>
      </div>

      {loading && <LoaderView message='Loading...' />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {showSuccessPopup && <SuccessDialog message={message}
        handleClose={() => {
          setShowSuccessPopup(false)
          navigate('/jobs')
        }}
      />}
    </>
  )
}

export default CreateJobs

const CustomDatePickDiv = styled.div`
  background-color: white;
  border: solid 0.1em #ffff;
  border-radius: 0.25em;
  padding: 0.3em 1.6em 0 1.6em;
`
const DatePickerDiv = styled.div`
  position: relative;
`
