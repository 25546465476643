//import liraries
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../auth'

import '../../CVScreens/CandiMain.css'
import Loader from '../../CVScreens/comps/Loader'
import '../../CVScreens/personalinfo.css'
import '../../CVScreens/position.css'
import '../../CVScreens/Stepper.css'
import ic_cross from '../../assets/ic_cross.png'

import dayjs, {Dayjs} from 'dayjs'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-skills/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`

// create a component
const SkillsScreen = ({chips, handleItemClick, addNewSkill, handleDelete, ...props}) => {
  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [skills, setSkills] = useState('')
  const [sendRequest, setSendRequest] = useState(false)
  const [profileStrength, setprofileStrength] = React.useState<Dayjs | any>([
    {
      profile_info: 'false',
      work_exp: 'false',
      education: 'false',
      certificate: 'false',
      skills: 'false',
      total: '5',
    },
  ])
  const [currentProgress, setCurrentProgress] = useState(5)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  // const staticSkills = ["Data Analyst", "MERN Stack", "Front-end developer", "Node developer", "Data Scientist", "Data Engineer"];
  const [competencies, setCompetencies] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const handleInputChange = (event) => {
    const query = event.target.value
    setQuery(query)
    // if (query.length > 0) {
    const filteredResults = competencies.filter((item) => item.includes(query))
    // setResults(filteredResults);
    setResults(filteredResults.length > 0 ? filteredResults : competencies)
    // } else {
    // setResults([]);
    // }
  }

  const handleKeyDown = (event) => {
    // Check if the Enter key is pressed
    if (event.key === 'Enter' && query.trim() !== '') {
      console.log('enteredValue: ', query)

      // Add the entered skill to chips
      addNewSkill(query)
      // setQuery(''); // Clear the input after adding a new skill
    }
    // console.log('enteredValue: ', query);
  }

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getCompetencies()
    })()
  }, [])

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Position' })}</PageTitle> */}

      <div className='row g-5 g-xl-8 personal-info-container'>
        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row mx-16'>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <h3
                      className=' align-items-center flex-column'
                      // style={{ marginLeft: '3.2%' }}
                    >
                      <label className='card-label text-gray-800 fw-bolder fs-4'>
                        Add Skills Used<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                      </label>
                    </h3>
                    {/* skills  */}
                    <div
                      className='form-row d-flex mt-2 justify-content-spacearound'
                      // style={{ marginLeft: '6.4%' }}
                    >
                      <div className='form-group col-md-12'>
                        {/* <label className='form-label labelse '> Blood Group</label> */}
                        {/* <div className='me-1  '> */}
                        <input
                          type='text'
                          className='search-bar-style form-control   w-140px'
                          placeholder='Search skills'
                          value={query}
                          onChange={handleInputChange}
                          onFocus={handleInputChange}
                          onKeyDown={handleKeyDown}
                        />
                        {results.length > 0 && (
                          <ul className='form-control   w-140px'>
                            {results.map((item, index) => (
                              <li style={{cursor: 'pointer'}} key={index} onClick={handleItemClick(item)}>
                                {item}
                              </li>
                            ))}
                          </ul>
                        )}
                        {/* </div> */}
                        {/* <Chips skillset={chips} /> */}
                        <div className=' mt-4'>
                          {chips?.map((val, index) => (
                            <div key={index} style={{width: 'fit-content', display: 'inline-block', margin: '0 1rem 1rem 0 '}}>
                              <div className='chip-uxi'>
                                {val}&nbsp;
                                {/* <i className='bi bi-x-lg' onClick={() => handleDelete(index)}></i> */}
                                <img src={ic_cross} alt='remove' onClick={() => handleDelete(index)}></img>
                              </div>
                            </div>
                          ))}
                        </div>
                        {/* <h3 className='card-title align-items-center flex-column'>
                                                    <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                        Suggested Competencies
                                                    </span>
                                                </h3> */}
                      </div>
                    </div>
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Building Profile...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

//make this component available to the app
export default SkillsScreen
