//import liraries
import React, { useEffect, useState } from 'react';
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../../App';
import { useAuth } from '../../auth/core/Auth';
import imagePath from '../../../../constants/imagePath';

type Props = {
    news?: any
}
// create a component
const GreetingCard = ({ news }: Props) => {
    const navigate = useNavigate();
    // const {master, setmaster} = useContext(LoginContext)
    const { loggedIn, setLoggedIn } = useContext(LoginContext)
    const { auth } = useAuth()
    const { currentUser } = useAuth()
    const [greeting, setGreeting] = useState('');

    useEffect(() => {

        // Get the current date and time
        const now = new Date();
        const currentHour = now.getHours();

        // Determine the greeting based on the current hour
        let currentGreeting;
        if (currentHour >= 5 && currentHour < 12) {
            currentGreeting = 'Good Morning';
        } else if (currentHour >= 12 && currentHour < 18) {
            currentGreeting = 'Good Afternoon';
        } else {
            currentGreeting = 'Good Evening';
        }

        // Set the greeting message
        setGreeting(currentGreeting);
    }, []);

    return (
        <div className=' shadow-sm card-bg d-flex'>
            <div className='px-6 pt-6'>
                <div className='me-2'>
                    { }
                    <h2 className='fw-bolder text-color mb-3 fs-1'>
                        {`Hi ${currentUser?.first_name ? currentUser?.first_name : 'Name'}, ${greeting}`}
                        <span>
                            <img style={{ marginLeft: '1rem' }} src={imagePath.ic_sun}></img>
                        </span>
                    </h2>

                    {(news && news.length > 0) ? news.map((data, index) => (
                        <div key={index} className='text-muted fw-bold fs-6 mb-2'>
                            {`${data?.jobTitle} has received ${data?.newCandidates} new applications over the past 24 hours`}
                        </div>
                    )) : (<div></div>)}

                </div>

                {currentUser?.role !== 'none' ? (<div className='m-0'>
                    <label
                        className='btn text-white  short-list-button fw-bold fs-6 px-6 py-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        onClick={() => {
                            navigate('/jobs');
                        }}
                    >
                        View Jobs
                    </label>
                </div>) : (<div></div>)}
            </div>
            <div className='ms-auto'>
                <img src={imagePath.cycling} width={180} />
            </div>

        </div>
    );
};

//make this component available to the app
export default GreetingCard;
