import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { ScheduleInterviewPopUp } from './ScheduleInterviewPopup'
// import { ApproveScheduleNoTest } from './ApproveScheduleNoTest'


const API_URL = process.env.REACT_APP_API_URL

export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
export const SEND_ONLINE_TEST_LINK_URL = `${API_URL}/jobapplication/send-test-link`
export const GET_TA_DATA = `${API_URL}/panel/get-tas-data`
export const CREATE_PANEL_HIRED = `${API_URL}/panel/create`


type Props = {
  show: boolean
  name: string
  jobapp_id: string
  user_id: string
  email: string
  recuiter_id: string
  handleClose: () => void
  handleSubmit: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ApproveUserAppWithoutTest = ({ show, name, jobapp_id, user_id, email, recuiter_id, handleClose, handleSubmit }: Props) => {
  console.log({ name })
  console.log({ jobapp_id })
  console.log({ user_id })
  console.log({ email })

  const [popup, setpopup] = useState(<></>)
  const { auth } = useAuth()
  const [taHead, setTaHead] = useState<any>([])
  const [items, setItems] = useState<any>([]);

  // const GetAllTADATA = async () => {
  //   console.log({ recuiter_id })

  //   // Make first API request
  //   await axios.post(
  //     GET_TA_DATA,
  //     {
  //       recruiter_id: recuiter_id,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     }
  //   ).then(async (res) => {
  //     console.log('hey-panel', res?.data)
  //     setTaHead(res?.data?.ta_Head)
  //   })
  // }
  // console.log(taHead?.email)

  // useEffect(()=>{
  //   GetAllTADATA();
  // },[])

  useEffect(() => {
    let localUser: any = localStorage.getItem("kt-auth-react-v")
    setItems(JSON.parse(localUser))

  }, []);

  // const createPopup = (name, jobapp_id, email, user_id) => {
  //   setpopup(
  //     <ApproveScheduleNoTest
  //       show={true}
  //       name={name}
  //       jobapp_id={jobapp_id}
  //       email={email}
  //       user_id={user_id}
  //       handleClose={() => {
  //         setpopup(<></>)
  //       }}
  //     />
  //   )
  //   console.log('popup', jobapp_id)

  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault()
  //   createPopup(name, jobapp_id, email, user_id)
  // }
  console.log({ taHead })

  useEffect(() => {
    const GetAllTADATA = async () => {
      try {
        const res = await axios.post(
          GET_TA_DATA,
          {
            recruiter_id: recuiter_id,
          },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const taHeadData = res.data.ta_Head;
        console.log(res);
        setTaHead(taHeadData);

        if (taHeadData) {
          console.log(taHeadData?.email)
          console.log(taHeadData?.user_id)


          const res3 = await axios.post(
            CREATE_PANEL_HIRED,
            {
              panel: [
                {
                  jobapp_id: jobapp_id,
                  name: "hired",
                  index: 0,
                  member_id: "75717cc6-b15b-45f5-b614-ac22356d8a04",
                  email: "umair761saeed@gmail.com",
                  availability: true,
                  member_type: "ta_lead",
                },
                {
                  jobapp_id: jobapp_id,
                  name: "hired",
                  index: 1,
                  member_id: taHeadData?.user_id || "",
                  email: taHeadData?.email || "",
                  availability: true,
                  member_type: "ta_head",
                },
                {
                  jobapp_id: jobapp_id,
                  name: "hired",
                  index: 2,
                  member_id: user_id,
                  email: email,
                  availability: true,
                  member_type: "candidate",
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log("panel created", res3);
        }

      } catch (error) {
        console.log(error);
      }
    };

    GetAllTADATA();
  }, []);

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header no-header-model123' style={{ marginTop: '-1rem' }}>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout} ></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='' src={addcompetenctimage} style={{ height: '15vh', width: '15vh', marginLeft: '19rem' }} ></img>
          <div className='d-flex flex-column align-items-center mx-auto my-5'>
            <div className='fw-bolder text-center' style={{
              font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-nunito)',
              letterSpacing: 'var(--unnamed-character-spacing--0-02)',
              color: 'var(--secondary-1-text-dark)',
              textAlign: 'center',
            }}>
              Are You sure you want to select
            </div>
            <div className='fw-bolder text-center' style={{
              marginBottom: '1rem',
              font: 'var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-nunito)',
              letterSpacing: 'var(--unnamed-character-spacing--0-02)',
              color: 'var(--secondary-1-text-dark)',
              textAlign: 'center',
            }}>
              {name} for Online Interview Stage 1?
            </div>

          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              {/* <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label>
              </div> */}
              <div className='sureunsure' style={{ marginTop: '-4rem' }}>
                <button
                  onClick={handleSubmit}
                  type='button'
                  className='btn my-10 px-10 bgcolorofbutoonofsubmit'

                >
                  Yes, I am sure!
                </button>
                <button onClick={handleClose} className='btn my-10 px-10 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123'>
                  No
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { ApproveUserAppWithoutTest }
