//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import Layout from '../images/Layout-4-blocks (3).png'
import JobHeaderIcon from './img/job.png'
import BOPLOGO from './img/BOP_ Logo (shadow)@2x.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import LoaderView from './LoaderView';
import ReactPDF from '@react-pdf/renderer';
import DownLoadIcon from '../../../../app/modules/JobsFunneling/img/Component 237 – 1.png'
import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import ShowError from '../../content/toasts/ShowError';
import JSZip from 'jszip';
import OfferRejectionReasonPopup from './OfferRejectionReasonPopup';

const API_URL = process.env.REACT_APP_API_URL
// const ACCEPT_OFFER_LETTER_URL = `${API_URL}/panel/approve-candidate`;
const ACCEPT_OFFER_LETTER_URL = `${API_URL}/panel/change-panel-status`;
const UPLOAD_OFFER_LETTER = `${API_URL}/jobapplication/upload-offerLetter`;
const REJECT_OFFER_LETTER_URL = `${API_URL}/panel/change-panel-status`;
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
    show: boolean
    offerLetterExpiryDate: any
    offerLetter: any
    job: any
    handleClose: () => void
    handleAccept: () => void
    updateApplications: () => void
    handleReject: () => void
}
// create a component
const OfferLetterCandidate = ({ show, offerLetterExpiryDate, offerLetter, job, handleClose, handleAccept, updateApplications, handleReject }: Props) => {
    console.log('offerLetterExpiryData: ', job);
    // const { auth } = useAuth()
    const { currentUser, auth, logout } = useAuth()
    const profile_pic = currentUser?.profile_pic;
    const name = `${currentUser?.first_name} ${currentUser?.last_name}`;
    const userAddress: any = currentUser?.address ? currentUser?.address : 'Address';
    const user_id = currentUser?.user_id;
    const members = job?.members;
    const taHead = members ? members[0] : null;
    console.log('jobDataOfferLetter: ', taHead);

    const [loading, setLoading] = useState(false);
    const [joiningDate, setJoiningDate] = useState<Dayjs | null>(dayjs());
    const [batchPositions, setBatchPositions] = useState<any>();
    const [batchId, setBatchId] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [configurationId, setConfigurationId] = useState<any>('');
    const [benefits, setBenefits] = useState<any>([])
    const [benefitsFileUrl, setBenefitsFileUrl] = useState('');
    const [fetchingBenefits, setFetchingBenefits] = useState(false);
    const [disableAcceptBtn, setDisableAcceptBtn] = useState(true);

    const getConfigurations = async () => {
        setFetchingBenefits(true);
        await axios.get(GET_CONFIGURATIONS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setFetchingBenefits(false);
            let data = res?.data?.Configuration;
            setConfigurationId(data?.configuration_id);
            let benefits = data?.benefits;
            if (benefits !== null && benefits !== undefined) {
                benefits = JSON.parse(benefits);
                const initialData = Object.keys(benefits).map((key) => ({
                    grade: benefits[key]['name'],
                    file: null,
                    fileUrl: benefits[key]['link']
                }));
                setBenefits(initialData);

                if (initialData !== null && initialData !== undefined && initialData.length > 0) {
                    if (job?.jobdata?.grade !== null && job?.jobdata?.grade !== undefined && job?.jobdata?.grade !== '') {
                        // Find the object with the specified grade
                        let benefit = initialData.find(item => item.grade === job?.jobdata?.grade);
                        // Extract the fileUrl value if the grade is found
                        let fileUrl = benefit ? benefit.fileUrl : null;
                        setBenefitsFileUrl(fileUrl);
                    } else {
                        setMessage('Grade value not found, Please try again.');
                        setShowErrorMessage(true);
                    }

                } else {
                    setMessage('List of benefits sheets not found, Please Try again.');
                    setShowErrorMessage(true);
                }
            }

        }).catch((error) => {
            setFetchingBenefits(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    useEffect(() => {
        setBatchId(job?.jobdata?.position_id);
        if (job?.batchPositions) {
            setBatchPositions(job?.batchPositions);
        }
        (() => {
            getConfigurations();
        })()

    }, []);
    const rejectOfferLetter = (reason) => {
        // event.preventDefault();

        let jobapp_id = job?.jobapplicationData?.jobapp_id
        setLoading(true);
        let data = {
            jobapp_id: jobapp_id,
            status: "rejected",
            name: "hired",
            member_id: user_id,
            reason: reason
        };
        axios.post(REJECT_OFFER_LETTER_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let responseData = res.data;
            updateApplications();
        }).catch((err) => {
            setLoading(false);
            console.error('err', err);
        });
    };

    const downloadOfferLetter2 = (e) => {
        e.preventDefault();
        const input = document.getElementById('candidate-offer-letter-view')!;

        // Create a new jsPDF instance with 'p' for portrait orientation and 'pt' for points as units
        const pdf = new jsPDF('p', 'pt', 'a4');
        let pdfDoc;
        // Options to set PDF margins
        const margins = {
            top: 40,
            bottom: 40,
            left: 40,
            width: 522, // A4 width in points (595 - 40 - 40 - 40)
            height: 842,
        };

        // Convert the HTML element to a canvas
        html2canvas(input, {
            scale: 1, // Set the scale to 1 for better quality
            scrollY: -window.scrollY, // Fix scroll position
            useCORS: true, // Enable cross-origin requests
        }).then(async (canvas) => {
            const contentHeight = canvas.height;

            // Calculate the height of the content for the first page
            const firstPageHeight = Math.min(contentHeight, margins.height);

            // Add the content for the first page to the PDF document
            pdf.addImage(canvas, 'PNG', margins.left, margins.top, margins.width, 0);

            // If there is remaining content, add a new page and add the remaining content to it
            if (contentHeight > margins.height) {
                pdf.addPage();
                const remainingHeight = contentHeight - firstPageHeight;
                pdf.addImage(canvas, 'PNG', margins.left, -1 * (firstPageHeight + margins.top), margins.width, remainingHeight);
            }
            // fetch pdf file from url and add into above pdf
            // let fileUrl = benefitsFileUrl;

            const response = await fetch(benefitsFileUrl);
            const pdfBytes = await response.arrayBuffer();
            // Create a new JSZip instance
            const zip = new JSZip();
            // Add the HTML-to-PDF and fetched PDF to the zip file
            zip.file('Offer_letter.pdf', pdf.output('blob'));
            zip.file('Benefits.pdf', pdfBytes);
            // Generate the zip file
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            // Download the zip file
            saveAs(zipBlob, 'offer_letter.zip');

            // Save the PDF file with the name 'offer_letter.pdf'
            // pdf.save('offer_letter.pdf');
        });
    };

    function saveAs(blob, fileName) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    // const downloadOfferLetter2 = async (event) => {
    //     event.preventDefault();
    //     const input = document.getElementById('candidate-offer-letter-view')!;
    //     const images = input.getElementsByTagName('img');
    //     setLoading(true);

    //     try {
    //         await Promise.all(
    //             Array.from(images).map((img) => {
    //                 return new Promise<void>((resolve, reject) => {
    //                     if (img.complete) {
    //                         resolve();
    //                     } else {
    //                         img.onload = () => resolve();
    //                         img.onerror = reject;
    //                     }
    //                 });
    //             })
    //         );

    //         const canvasWidth = input.offsetWidth; // Use the actual width of the container
    //         const canvasHeight = input.offsetHeight; // Use the actual height of the container

    //         html2canvas(input, {
    //             useCORS: true,
    //             width: canvasWidth,
    //             height: canvasHeight,
    //         }).then((canvas) => {
    //             setLoading(false);
    //             const imgData = canvas.toDataURL('image/png');
    //             const pdf = new jsPDF('p', 'px', [canvasWidth, canvasHeight], true);
    //             pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
    //             pdf.save('offer_letter.pdf');
    //         }).catch((err) => {
    //             setLoading(false);
    //             console.error('err', err);
    //         });
    //     } catch (error) {
    //         setLoading(false);
    //         console.error('Error capturing canvas:', error);
    //     }
    // }

    const downloadOfferLetter = async (event) => {
        event.preventDefault();
        // setLoading(true);
        // const element: any = document.getElementById('candidate-offer-letter-view'); // Replace 'pdf-content' with the ID of the element you want to download as PDF
        // html2canvas(element).then((canvas) => {
        //     setLoading(false);
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     const imgData = canvas.toDataURL('image/png');
        //     const width = pdf.internal.pageSize.getWidth();
        //     const height = (canvas.height * width) / canvas.width;
        //     pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        //     pdf.save('offer_letter.pdf');
        //     console.log('downloadData: pdf: ', pdf)

        // }).catch((err) => {
        //     setLoading(false);
        //     console.log('downloadErr: ', err)
        // });

        setLoading(true);
        const element: any = document.getElementById('candidate-offer-letter-view');

        html2canvas(element).then(async (canvas) => {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const scaleFactor = pdfWidth / canvas.width;
            const scaledCanvasHeight = canvas.height * scaleFactor;
            const pages = Math.ceil(scaledCanvasHeight / pdfHeight);

            let currentPage = 1;
            let yOffset = 0;

            while (currentPage <= pages) {
                const canvasData = canvas.toDataURL('image/jpeg', 1.0);
                const imageHeight = canvas.height - yOffset > pdfHeight ? pdfHeight : canvas.height - yOffset;
                const imageData = await fetch(canvasData);
                const blob = await imageData.blob();
                const url = URL.createObjectURL(blob);

                pdf.addPage();
                pdf.setPage(currentPage);
                pdf.addImage(url, 'JPEG', 0, 0, pdfWidth, imageHeight);
                yOffset += pdfHeight;
                currentPage++;
            }

            pdf.save('offer_letter.pdf');
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log('downloadErr: ', err);
        });

    }

    const downloadFile = (event) => {
        event.preventDefault();
        let offerLetterUrl = job?.jobapplicationData?.offer_letter_url
        if (offerLetterUrl) {
            let url = offerLetterUrl;
            if (!url) return;

            fetch(url)
                .then((response) => response.blob())
                .then((blob) => {
                    const fileURL = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    // link.setAttribute('download', url.split('/').pop() || 'file');
                    link.setAttribute('download', 'offer_letter.pdf');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log('Error downloading file:', error);
                });
        } else {
            window.alert('URL to download offer letter not valid');
        }
    };

    const acceptOfferLetter = async (event) => {
        event.preventDefault();

        if (!joiningDate) {
            alert('Please enter a joining date.');
            return;
        }
        if (joiningDate.isSame(currentDate, 'day') || joiningDate.isBefore(currentDate, 'day')) {
            alert('Joining date cannot be today or in the past.');
            return;
        }

        setLoading(true);
        let jobapp_id = job?.jobapplicationData?.jobapp_id
        let data = {
            "jobapp_id": jobapp_id,
            "name": "hired",
            "member_id": user_id,
            "status": "accepted",
            "joining_date": joiningDate
        }
        axios.post(ACCEPT_OFFER_LETTER_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then(async (res) => {
            // await uploadOfferLetter();
            await uploadOfferLetter2();
        }).catch((err) => {
            setLoading(false);
            console.log('err', err);
        })
    }

    const uploadOfferLetter2 = async () => {
        const input = document.getElementById('candidate-offer-letter-view')!;

        // Create a new jsPDF instance with 'p' for portrait orientation and 'pt' for points as units
        const pdf = new jsPDF('p', 'pt', 'a4');
        // Options to set PDF margins
        const margins = {
            top: 40,
            bottom: 40,
            left: 40,
            width: 522, // A4 width in points (595 - 40 - 40 - 40)
            height: 842,
        };

        // Convert the HTML element to a canvas
        html2canvas(input, {
            scale: 1, // Set the scale to 1 for better quality
            scrollY: -window.scrollY, // Fix scroll position
            useCORS: true, // Enable cross-origin requests
        }).then(async (canvas) => {
            const contentHeight = canvas.height;

            // Calculate the height of the content for the first page
            const firstPageHeight = Math.min(contentHeight, margins.height);

            // Add the content for the first page to the PDF document
            pdf.addImage(canvas, 'PNG', margins.left, margins.top, margins.width, 0);

            // If there is remaining content, add a new page and add the remaining content to it
            if (contentHeight > margins.height) {
                pdf.addPage();
                const remainingHeight = contentHeight - firstPageHeight;
                pdf.addImage(canvas, 'PNG', margins.left, -1 * (firstPageHeight + margins.top), margins.width, remainingHeight);
            }
            // fetch pdf file from url and add into above pdf
            // let fileUrl = benefitsFileUrl;

            const response = await fetch(benefitsFileUrl);
            const pdfBytes = await response.arrayBuffer();
            // Create a new JSZip instance
            const zip = new JSZip();
            // Add the HTML-to-PDF and fetched PDF to the zip file
            zip.file('Offer_letter.pdf', pdf.output('blob'));
            zip.file('Benefits.pdf', pdfBytes);
            // Generate the zip file
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            // Download the zip file
            // saveAs(zipBlob, 'offer_letter.zip');


            let jobapp_id = job?.jobapplicationData?.jobapp_id
            const formData = new FormData();
            formData.append('jobapp_id', jobapp_id);
            formData.append('jobapp_stage', 'hired');
            formData.append('file', zipBlob, 'offer_letter.zip');
            // setLoading(true);
            axios.post(UPLOAD_OFFER_LETTER, formData, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    // 'Content-Type': 'application/json',
                    'content-type': 'multipart/form-data',
                },
            }).then((res) => {
                console.log('offer letter upload response: ', res.data);
                setLoading(false);

                // redirect to closed jobs
                handleAccept();

            }).catch((err) => {
                console.log('err', err);
                setLoading(false);

            })

        });
    }

    const uploadOfferLetter = async () => {

        const input = document.getElementById('candidate-offer-letter-view')!;
        const images = input.getElementsByTagName('img');
        setLoading(true);

        try {
            await Promise.all(
                Array.from(images).map((img) => {
                    return new Promise<void>((resolve, reject) => {
                        if (img.complete) {
                            resolve();
                        } else {
                            img.onload = () => resolve();
                            img.onerror = reject;
                        }
                    });
                })
            );

            const canvasWidth = input.offsetWidth; // Use the actual width of the container
            const canvasHeight = input.offsetHeight; // Use the actual height of the container

            html2canvas(input, {
                useCORS: true,
                width: canvasWidth,
                height: canvasHeight,
            }).then((canvas) => {
                setLoading(false);
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'px', [canvasWidth, canvasHeight], true);
                pdf.addImage(imgData, 'PNG', 0, 0, canvasWidth, canvasHeight);
                pdf.save('offer_letter.pdf');
                // Generate the PDF as a Blob
                const pdfBlob = pdf.output('blob');
                // Create a FormData object to send the PDF file to the server
                let jobapp_id = job?.jobapplicationData?.jobapp_id
                const formData = new FormData();
                formData.append('jobapp_id', jobapp_id);
                formData.append('jobapp_stage', 'hired');
                formData.append('file', pdfBlob, 'offer_letter.pdf');

                axios.post(UPLOAD_OFFER_LETTER, formData, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        // 'Content-Type': 'application/json',
                        'content-type': 'multipart/form-data',
                    },
                }).then((res) => {
                    console.log('offer letter upload response: ', res.data);
                    setLoading(false);

                    // redirect to closed jobs
                    handleAccept();

                }).catch((err) => {
                    console.log('err', err);
                    setLoading(false);

                })


            }).catch((err) => {
                setLoading(false);
                console.error('err', err);
            });
        } catch (error) {
            setLoading(false);
            console.error('Error capturing canvas:', error);
        }



        // const element: any = document.getElementById('candidate-offer-letter-view');

        // html2canvas(element).then(async (canvas) => {
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = pdf.internal.pageSize.getHeight();
        //     const scaleFactor = pdfWidth / canvas.width;
        //     const scaledCanvasHeight = canvas.height * scaleFactor;
        //     const pages = Math.ceil(scaledCanvasHeight / pdfHeight);

        //     let currentPage = 1;
        //     let yOffset = 0;

        //     while (currentPage <= pages) {
        //         const canvasData = canvas.toDataURL('image/jpeg', 1.0);
        //         const imageHeight = canvas.height - yOffset > pdfHeight ? pdfHeight : canvas.height - yOffset;
        //         const imageData = await fetch(canvasData);
        //         const blob = await imageData.blob();
        //         const url = URL.createObjectURL(blob);

        //         pdf.addPage();
        //         pdf.setPage(currentPage);
        //         pdf.addImage(url, 'JPEG', 0, 0, pdfWidth, imageHeight);
        //         yOffset += pdfHeight;
        //         currentPage++;
        //     }

        //     // Generate the PDF as a Blob
        //     const pdfBlob = pdf.output('blob');

        //     // Create a FormData object to send the PDF file to the server
        //     let jobapp_id = job?.jobapplicationData?.jobapp_id
        //     const formData = new FormData();
        //     formData.append('jobapp_id', jobapp_id);
        //     formData.append('jobapp_stage', 'hired');
        //     formData.append('file', pdfBlob, 'offer_letter.pdf');

        //     axios.post(UPLOAD_OFFER_LETTER, formData, {
        //         headers: {
        //             Authorization: `Bearer ${auth?.token}`,
        //             // 'Content-Type': 'application/json',
        //             'content-type': 'multipart/form-data',
        //         },
        //     }).then((res) => {
        //         console.log('offer letter upload response: ', res.data);
        //         setLoading(false);

        //         // redirect to closed jobs
        //         handleAccept();

        //     }).catch((err) => {
        //         console.log('err', err);
        //         setLoading(false);

        //     })

        // }).catch((err) => {

        //     console.log('downloadErr: ', err);
        // });
    }

    const handleChangecalender = (newValue: Dayjs | null) => {
        console.log('dateValue: ', newValue)
        setJoiningDate(newValue)

        if (!newValue || newValue.isSame(currentDate, 'day') || newValue.isBefore(currentDate, 'day')) {
            setDisableAcceptBtn(true);
        }else{
            setDisableAcceptBtn(false)
        }

    }

    // convert date format
    function formatDate(dateString) {
        // const date = new Date(dateString);
        // const formatedDate = `${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear().toString().slice(-2)}`;
        // return formatedDate;

        const offerLetterExpiryDate = new Date(dateString);
        const day = offerLetterExpiryDate.getUTCDate().toString().padStart(2, '0');
        const month = (offerLetterExpiryDate.getUTCMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed
        const year = offerLetterExpiryDate.getUTCFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        console.log(formattedDate);
        return formattedDate
    }

    const getLocationValue = () => {
        let location = '';
        if (job?.jobdata?.position_id) {
            const matchingPosition = batchPositions.find((position) => position.position_id === job?.jobdata?.position_id);
            if (matchingPosition) {
                location = matchingPosition.location;
            }
        }
        return location;
    };

    const handleDownloadBenefitsPdf = (e) => {
        e.preventDefault();
        if (benefits !== null && benefits !== undefined && benefits.length > 0) {
            if (job?.jobdata?.grade !== null && job?.jobdata?.grade !== undefined && job?.jobdata?.grade !== '') {
                // Find the object with the specified grade
                let benefit = benefits?.find(item => item.grade === job?.jobdata?.grade);
                // Extract the fileUrl value if the grade is found
                let fileUrl = benefit ? benefit.fileUrl : null;
                setBenefitsFileUrl(fileUrl);
                if (fileUrl) {
                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.target = '_blank'; // Open the PDF in a new tab
                    link.setAttribute('download', ''); // Set the download attribute to force download
                    document.body.appendChild(link);
                    link.click(); // Click the anchor element to initiate download
                    document.body.removeChild(link); // Remove the anchor element from the document
                } else {
                    setMessage('Benefits sheet not available contact admin to add Benefits sheet.');
                    setShowErrorMessage(true);
                }
            } else {
                setMessage('Grade value not found, Please try again.');
                setShowErrorMessage(true);
            }

        } else {
            setMessage('List of benefits sheets not found, Please Try again.');
            setShowErrorMessage(true);
        }

    }

    const currentDate = dayjs(); // Get the current date
    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
            backdropClassName="modal-backdrop backdrop-blur"
        >
            <>
                <div>
                    <div className='modal-header no-header-model123'>
                        <h2></h2>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <img alt='' src={Layout}></img>
                        </div>
                    </div>

                    <div id='candidate-offer-letter-view'>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            <img src={JobHeaderIcon} className="score-header-icon-internally" alt="Job Header Icon" />
                            <h2 className="offer-letter-header-container-text mt-1">Offer Letter</h2>
                        </div>
                        <div className=' no-header-model123'>
                            <div className=' d-flex flex-column my-8 '>
                                <img alt='' style={{ objectFit: 'cover', width: '84px', alignSelf: 'center' }} src={BOPLOGO}></img>
                            </div>
                        </div>
                        <div className='d-flex align-items-center px-10'>
                            <img className='profile-user-icon-request-approval-doc me-4' src={profile_pic} width={44} height={44} style={{ objectFit: 'cover' }} />
                            <label className='fw-bolder fs-4'>{name}</label>
                            {/* <label className='fw-bolder fs-7' style={{ marginLeft: 'auto' }}>{`Issued Date: ${new Date(offerLetter?.issueDate).toLocaleDateString('en-GB')}`}</label> */}
                            <label className='fw-bolder fs-7' style={{ marginLeft: 'auto' }}>{`Issued Date: ${new Date(job?.jobapplicationData?.offer_letter_issuance_time).toLocaleDateString('en-GB')}`}</label>
                        </div>
                        <label className='fw-normal fs-7 px-10 mt-4 mb-10'>{userAddress}</label>
                        <div className='px-10'>
                            <label className='offer-letter-address-cont'>Dear Mr. {name}</label>
                            <br />
                            <div className='offer-letter-detailed-text'>
                                We are pleased to offer you employment with the Bank as
                                <span className='offer-letter-address-cont'
                                    style={{ marginLeft: '10px' }}
                                >
                                    {job?.jobdata?.title}
                                </span>
                            </div>
                            <label className='offer-letter-detailed-text'>This offer is subjected to acceptability of the following terms & conditions:</label>

                            <div className='offer-letter-final-body'>
                                <div>
                                    <label className='offer-letter-address-cont'>Initial Place of Posting</label>
                                    <br />
                                    <p className='offer-letter-detailed-text'>
                                        {/* {job?.jobdata?.location} */}
                                        {/* {`${batchPositions ? getLocationValue() : job?.jobdata?.location}`} */}
                                        {`${job?.jobapplicationData?.posting_place}`}
                                    </p>
                                </div>

                                <div>
                                    <label className='offer-letter-address-cont'>Salary & Allowance </label>
                                    <>
                                        <div>
                                            <div className='offer-letter-extra-allowance'>
                                                <p className='offer-letter-allowance-name'>Basic Pay (BP)</p>
                                                <p className='offer-letter-value'>Rs.{offerLetter?.BasicPay?.toLocaleString()}</p>
                                            </div>
                                            <>
                                                {offerLetter?.allowance.length > 0 ? offerLetter?.allowance.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className='offer-letter-extra-allowance'>
                                                                {item.selectedValue !== 0 ? (
                                                                    <>
                                                                        <p className='offer-letter-allowance-name'>
                                                                            {item.name} @ {item.selectedValue}% of BP
                                                                        </p>
                                                                        <p className='offer-letter-value'>Rs.{item?.value ? (item?.value?.toLocaleString()) : 0}</p>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p className='offer-letter-allowance-name'>{item.name} of BP</p>
                                                                        <p className='offer-letter-value'>Rs.{item?.value ? (item?.value?.toLocaleString()) : 0}</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                }) : (<div></div>)}
                                            </>
                                        </div>

                                        <div className='offer-letter-lower-bar'>
                                            <label>Gross Salary:  Rs.  {offerLetter?.GrossSalary?.toLocaleString()} </label>
                                        </div>
                                    </>
                                    <>

                                        <div className='offer-letter-extra-allowance'>
                                            <div className='offer-letter-allowance-name'>Fuel Facility </div>
                                            <div className='offer-letter-value'>Rs.{offerLetter?.FuelFacility ? (offerLetter?.FuelFacility?.toLocaleString()) : 0}</div>
                                        </div>
                                        <div className='offer-letter-extra-allowance'>
                                            <div className='offer-letter-allowance-name'> Car Allowance  </div>
                                            <div className='offer-letter-value'> Rs.{offerLetter?.CarAllowance ? (offerLetter?.CarAllowance?.toLocaleString()) : 0}</div>
                                        </div>
                                        <div className='offer-letter-extra-allowance'>
                                            <div className='offer-letter-allowance-name'> Bonus  </div>
                                            <div className='offer-letter-value'> Rs.{offerLetter?.bonus ? (offerLetter?.bonus?.toLocaleString()) : 0}</div>
                                        </div>


                                        <div className='offer-letter-lower-bar'>
                                            <label> Salary Per Month:  Rs. {offerLetter?.Salary?.toLocaleString()}</label>
                                        </div>
                                    </>
                                    <div className='d-flex align-items-center mb-8'>
                                        <label className='fw-bolder fs-4 col-md-3'>Benefits & Incentives</label>
                                        {fetchingBenefits ? (
                                            <div className='d-flex align-items-center'>
                                                <FontAwesomeIcon className='me-4' icon={faSpinner} spin color='#F36523' size='2x' />
                                                <label>Fetching Benefits info...</label>

                                            </div>
                                        ) : (
                                            <div className='request-for-approvals-external-files-text'>
                                                <div>
                                                    <button onClick={handleDownloadBenefitsPdf} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                                        <img src={DownLoadIcon} />
                                                    </button>
                                                </div>
                                                <div>
                                                    <label>Benefits & Incentives.pdf</label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* <div>
                                    <label className='offer-letter-address-cont'>Perks & Benefits </label>
                                    <div >
                                        <div className='offer-letter-perks'>
                                            <p className='offer-letter-address-cont'>Fuel Facility: </p>
                                            <span className='offer-letter-detailed-text'> You will be entitled to reimbursement of fuel facility</span>
                                        </div>

                                    </div>
                                </div> */}


                            </div>

                            {/* <div>
                                <label className='offer-letter-detailed-text'
                                    style={{ marginTop: '3rem' }}
                                >
                                    Please note that any statement given by you, if found incorrect, will render your services liable for termination
                                </label>
                            </div>

                            <div style={{ marginTop: '3rem' }}>
                                <label className='offer-letter-detailed-text'
                                    style={{ marginBottom: '1rem' }}
                                >Sincerely,</label> <br />
                                <label className='offer-letter-detailed-text'>For & on behalf of; <br />
                                    The Bank of Punjab</label>

                            </div>

                            <div>
                                <label className='offer-letter-address-cont'
                                    style={{ marginTop: '3rem', marginBottom: '3rem' }}
                                >Talent Head Acquistion </label>
                            </div> */}

                            <div
                                className='me-8'
                                style={{ paddingLeft: '1em' }}>
                                {ReactHtmlParser(offerLetter?.offerLetterContent)}
                            </div>
                            <div className='d-flex'>
                                <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label className='form-label fw-normal fs-5' style={{ display: 'block' }}>Sincerely,</label>
                                    <label className='form-label fw-normal fs-5 mt-10' style={{ display: 'block' }}>For & on behalf of;</label>
                                    <label className='form-label fw-normal fs-5 mb-6' style={{ display: 'block' }}>The Bank of Punjab,</label>
                                    {taHead?.member_signature ? (
                                        <img src={taHead?.member_signature}
                                            alt="Signature"
                                            style={{ objectFit: 'contain', maxWidth: '120px', maxHeight: '80px', width: '100%', height: '100%', }}
                                        />) : (
                                        <label className='form-label fw-bolder fs-5' style={{ color: '#F36523' }}>Signatures Not Available</label>
                                    )}
                                    <label className='form-label fw-bolder fs-5 mt-4' style={{ display: 'block' }}>Head Talent Acquisition</label>
                                </div>
                                {/* <div className='col-md-6' style={{ display: 'flex' }}>
                                    <img src={JobHeaderIcon} className="score-header-icon-internally" alt="Job Header Icon" style={{ alignSelf: 'flex-end' }} />
                                </div> */}
                            </div>
                            <div>
                                <div className='offer-letter-lower-header-txt'>
                                    <label>Tentative Joining Date</label>
                                </div>
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={3}>
                                            <Box sx={{ width: 343 }}>
                                                <DesktopDatePicker
                                                    inputFormat='MM/DD/YYYY'
                                                    value={joiningDate}
                                                    // minDate={currentDate}
                                                    minDate={currentDate.add(1, 'day')}
                                                    onChange={handleChangecalender}
                                                    renderInput={(params) => (
                                                        <TextField {...params} name='selectedDate' />
                                                    )}
                                                />
                                            </Box>
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className='offer-letter-joining-date-cont'>
                                {/* <label className='offer-letter-joining-date'> * Joining Date: {offerLetter?.joining_date}*</label> */}
                                {/* <label className='offer-letter-joining-date'> * Joining Date: {new Date(offerLetter?.joining_date).toLocaleDateString('en-GB')}*</label> */}
                                <label className='offer-letter-joining-date'> * Expiry Date: {offerLetterExpiryDate ? formatDate(offerLetterExpiryDate) : ''}*</label>
                            </div>
                        </div>
                    </div>
                    <div className='modal-body py-lg-8 px-lg-8'>
                        <form >
                            <div className='btn-container d-flex'>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        // rejectOfferLetter(event)
                                        handleReject();
                                    }}
                                    className=' px-10 py-4 hollow-btn fw-bolder fs-5 me-4'
                                // disabled={disabledBtn}
                                >
                                    Reject
                                </button>
                                <button
                                    onClick={(event) => downloadOfferLetter2(event)}
                                    // onClick={(event) => downloadFile(event)}
                                    className=' px-6 py-4 hollow-btn fw-bolder fs-5 me-4'
                                >
                                    Download
                                </button>
                                <button
                                    onClick={(event) => acceptOfferLetter(event)}
                                    className='btn px-10 apply-review-button text-white'
                                    disabled={disableAcceptBtn}
                                >
                                    Accept
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && (
                    <LoaderView message='Loading...' />
                )}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

            </>
        </Modal>,
        modalsRoot
    )
};


//make this component available to the app
export default OfferLetterCandidate;
