import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import '../CandiMain.css'
import '../position.css'
import '../Stepper.css'
import '../personalinfo.css'
import '../../../../../_metronic/css/bootstrap-glyphicon.min.css'
import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useAuth } from '../../../../modules/auth/core/Auth'
import Loader from '../comps/Loader'
import Saved from './Saved'
import { useNavigate } from 'react-router-dom'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-education/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`

const Educations = (props) => {
  const navigate = useNavigate()
  const { auth, currentUser, logout } = useAuth()
  const user_id = currentUser?.user_id
  const [sendRequest, setSendRequest] = useState(false)
  const [totaledu] = useState([{ edu: 1 }])
  const [format, setFormat] = useState<Dayjs | any>([])
  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [cgpa, setCgpa] = useState(true)
  const [percentage, setPercentage] = useState(false)
  const [division, setDivision] = useState(false)
  const [inputFields, setInputFields] = React.useState<Dayjs | any>([
    {
      education_level: '',
      institution: '',
      otherInstitute: '',
      program: '',
      otherProgram: '',
      education_type: 'cgpa',
      education_value: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
    },
  ])
  // end_date: dayjs('2014-08-18T21:11:54'),
  const [profileStrength, setprofileStrength] = React.useState<any>({
    profile_info: 'false',
    work_exp: 'false',
    education: 'false',
    certificate: 'false',
    skills: 'false',
    total: '5',
  })
  const eduYears = Array.from({ length: 7 }, (_, index) => 10 + index)
  const [educationalYears, setEducationalYears] = useState<any>()
  const [currentProgress, setCurrentProgress] = useState(5)
  const [educationLevels, setEducationLevels] = useState<any>()
  const [institutes, setInstitutes] = useState<any>()
  const [specialization, setSpecialization] = useState<any>()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const handleFormChange = (index, event) => {
    // console.log('Change event clicked');
    // console.log("Event Name: " + event.target.name);
    // console.log("Index: " + index);
    // console.log("Value: " + event.target.value);
    // data[index][event.target.name || event.target.id] = event.target.value;

    let data = [...inputFields]
    data[index][event.target.name] = event.target.value
    setInputFields(data)
  }

  const handleDateFromChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }

    let data = [...inputFields]
    let dateToValue = data[index]['end_date']
    if (!validateDate(value, dateToValue)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }

    //calculate years and months
    if (value && dateToValue) {
      let diff = getDateDiffInYearsAndMonths(value, dateToValue)
      console.log('calculatedYearsMonths:  ' + diff.years + ' years, ' + diff.months + ' months')
      //   data[index]["years"] = diff.years;
      //   data[index]["months"] = diff.months;
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  const handleDateToChange = (index, event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }

    let data = [...inputFields]
    let dateFromValue = data[index]['start_date']
    if (!dateFromValue) {

      setMessage('Please enter or select Date From first');
      setShowErrorMessage(true);
      return
    }
    if (!validateDate(dateFromValue, value)) {
      setMessage('Date From can not be equal or greater than Date To');
      setShowErrorMessage(true);
      return
    }
    //calculate years and months
    if (dateFromValue && value) {
      let diff = getDateDiffInYearsAndMonths(dateFromValue, value)
      console.log('calculatedYearsMonths:  ' + diff.years + ' years, ' + diff.months + ' months')
      //   data[index]["years"] = diff.years;
      //   data[index]["months"] = diff.months;
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  function validateDate(startDate, endDate) {
    if (startDate && endDate) {
      let dateObject = new Date(endDate)
      let year = dateObject.getFullYear().toString()
      if (year.length >= 4) {
        let start = new Date(startDate).getTime()
        let end = new Date(endDate).getTime()

        let timeDiff = end - start
        if (timeDiff <= 0) {
          return false
        }
      }
    }
    return true
  }

  function getDateDiffInYearsAndMonths(startDate, endDate) {
    let start = new Date(startDate).getTime()
    let end = new Date(endDate).getTime()
    let timeDiff = end - start
    let years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365))
    let months = Math.floor((timeDiff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30))
    if (months === 12) {
      years = years + 1
      months = 0
    }

    return { years: years, months: months }
  }

  const handleCGPAChange = (index, event) => {
    let cgpaValue = event.target.value
    // Remove all non-digit characters from the input
    // cgpaValue = cgpaValue.replace(/\D/g, '');
    // console.log('eduData: cgpaVal: ', cgpaValue);
    let data = [...inputFields]
    data[index][event.target.name] = cgpaValue
    setInputFields(data)
  }
  const handleKeyPress = (event, type) => {
    let inputValue = event.target.value
    if (inputValue) {
      if (event.key === '.') {
        inputValue = inputValue + event.key + '0'
      } else {
        inputValue += event.key
      }
    } else {
      inputValue = event.key
    }

    if (type === 'percentage') {
      const regexPattern = /^(?:[1-9][0-9]?|100)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else if (type === 'cgpa') {
      const regexPattern = /^(?:0(?:\.[0-9]{1,2})?|[1-3](?:\.[0-9]{1,2})?|4(?:\.0{1,2})?)$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    } else {
      const regexPattern = /^[1-3]$/
      if (!regexPattern.test(inputValue)) {
        event.preventDefault()
      }
    }
  }

  const handleCGPARadio = (index, event) => {
    const value = event.target.value
    console.log('handleCGPARadio: value: ', value)
    let data = [...inputFields]
    // data[index][event.target.name] = value;
    data[index]['education_type'] = value
    setInputFields(data)

    // const value = event.target.value;
    // const updatedFields = inputFields.map((input, i) => {
    //   if (i === index) {
    //     return {
    //       ...input,
    //       education_type: value,
    //     };
    //   } else {
    //     return input;
    //   }
    // });
    // setInputFields(updatedFields);
  }
  const handlePercentageRadio = (event) => {
    setPercentage(event.target.value)
    setCgpa(false)
    setDivision(false)
  }
  const handleDivisionRadio = (event) => {
    setPercentage(false)
    setCgpa(false)
    setDivision(event.target.value)
  }

  const handleCurrentlyStudying = (index, event) => {
    let data = [...inputFields]
    data[index][event.target.name] = event.target.checked
    setInputFields(data)
  }

  const handleChangeEducationalYears = (event) => {
    setEducationalYears(event.target.value)
  }

  // start_date: dayjs('2014-08-18T21:11:54'),
  const addFields = () => {
    let newfield = {
      education_level: '',
      institution: '',
      otherInstitute: '',
      program: '',
      education_type: 'cgpa',
      education_value: '',
      start_date: '',
      end_date: '',
      currently_studying: false,
    }
    // setInputFields([...inputFields, newfield])
    setInputFields((prevFields) => [...prevFields, newfield])
  }
  const removeFields = (index) => {
    let data = [...inputFields]
    data.splice(index, 1)
    setInputFields(data)
  }

  // checking is three level of education is available or not
  const isThreeLevelEducationAvailable = () => {
    if (inputFields.length >= 3) {
      return true
    }
    return false
  }

  // checking education level is selected for all education entry or not

  const isEducationLevelSelected = () => {
    let totalSelected = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.education_level !== '' && field.education_level !== null && field.education_level !== undefined) {
          totalSelected++
        }
      }
    }
    return totalSelected !== 0 && totalSelected === inputFields.length
  }

  // checking institution is selected for all education entry or not
  const isInstitutionSelected = () => {
    let totalSelected = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.institution !== '' && field.institution !== null && field.institution !== undefined) {
          totalSelected++
        }
      }
    }
    return totalSelected !== 0 && totalSelected === inputFields.length
  }

  // checking progream is selected for all education entry or not
  const isProgramSelected = () => {
    let totalSelected = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.program !== '' && field.program !== null && field.program !== undefined) {
          totalSelected++
        }
      }
    }
    return totalSelected !== 0 && totalSelected === inputFields.length
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!educationalYears) {
      setMessage('Please enter total educational years first');
      setShowErrorMessage(true);
      return
    }

    if (!isThreeLevelEducationAvailable()) {
      setMessage('Please enter 03 educational entries (Matric Level, FA Level, Degree Level) first.');
      setShowErrorMessage(true);
      return
    }

    if (!isEducationLevelSelected()) {
      setMessage('Please select Education Level first and try again.');
      setShowErrorMessage(true);
      return
    }

    if (!isInstitutionSelected()) {
      setMessage('Please select Institution first and try again.');
      setShowErrorMessage(true);
      return
    }
    if (!isProgramSelected()) {
      setMessage('Please select Program first and try again.');
      setShowErrorMessage(true);
      return
    }
    // console.log(inputFields)
    // loader component
    var loader = document.getElementById('loader')
    loader?.classList.remove('d-none')

    // var Qualifications = { qualification: inputFields };
    // console.log("Q: " + JSON.stringify(inputFields))
    // var data = JSON.stringify(Qualifications)
    // console.log('Candidate Education: ', data)
    var indexx = 0
    setFormat([])
    var sortedFields = inputFields.sort((a, b) => parseFloat(b.end_date.replace('-', '')) - parseFloat(a.end_date.replace('-', '')))
    // console.log('educationData: SortedFields: ', sortedFields);
    sortedFields.map((val, index) => {
      var nIdx = 'education' + index
      indexx++
      var newData = { ['qualification' + indexx]: val }
      // console.log('educationData: qualification: ', newData);
      format.push(newData)
      console.log('educationData: format: ', format)
    })

    const transformedData = {
      qualification: [
        {
          ...format.reduce((acc, curr) => {
            return { ...acc, ...curr }
          }, {}),
          educationalYears: educationalYears,
        },
      ],
    }

    console.log('educationData: transformedData: ', transformedData)
    const qualification = transformedData.qualification
    // console.log("NEW FORMAT: " + JSON.stringify(format));
    var Qualifications = { qualification, profile_strength: profileStrength }
    var data = JSON.stringify(Qualifications)
    // console.log("SORTED: " + JSON.stringify(sortedFields))
    console.log('NEW DATA: ' + data)

    const url = PERSONAL_INFORMATION_URL + user_id
    console.log('URL', url)
    console.log('CURRENT USER', currentUser)
    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('res', res)
        props.reduce(3)
        // loader component
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        console.log('profileStrengthTotal: ', profileStrength.total)
        if (profileStrength.total == 100) {
          navigate('/candidatedashboard')
        }
      })
      .catch((error) => {
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // setting saved data
  useEffect(() => {
    let institute = props.university
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        // fetching profile strength data
        const strength = JSON.parse(response.data.candidate.profile_strength)
        console.log('workExpe strength: ', strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 25,
              profile_info: 'false',
              work_exp: 'false',
              education: 'true',
              certificate: 'false',
              skills: 'false',
            })
          } else {
            let totalProgress = 5
            let profile = strength.profile_info
            if (typeof profile === 'string') {
              profile = strength.profile_info?.toLowerCase?.() === 'true'
            }
            let work = strength.work_exp
            if (typeof work === 'string') {
              work = strength.work_exp?.toLowerCase?.() === 'true'
            }
            let edu = strength.education
            if (typeof edu === 'string') {
              edu = strength.education?.toLowerCase?.() === 'true'
            }
            let certifi = strength.certificate
            if (typeof certifi === 'string') {
              certifi = strength.certificate?.toLowerCase?.() === 'true'
            }
            let skill = strength.skills
            if (typeof skill === 'string') {
              skill = strength.skills?.toLowerCase?.() === 'true'
            }

            if (profile) {
              totalProgress += 20
            }
            if (work) {
              totalProgress += 20
            }
            if (edu) {
              totalProgress += 20
            }
            if (certifi) {
              totalProgress += 15
            }
            if (skill) {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            console.log('workExpe totalProgress: ', totalProgress)
            setprofileStrength({
              total: strength.education === 'false' ? totalProgress + 20 : totalProgress,
              profile_info: strength?.profile_info == undefined ? 'false' : strength.profile_info,
              work_exp: strength?.work_exp == undefined ? 'false' : strength.work_exp,
              education: 'true',
              certificate: strength?.certificate == undefined ? 'false' : strength.certificate,
              skills: strength?.skills == undefined ? 'false' : strength.skills,
            })
          }
        } else {
          setprofileStrength({
            total: 25,
            profile_info: 'false',
            work_exp: 'false',
            education: 'true',
            certificate: 'false',
            skills: 'false',
          })
        }

        // console.log("CANDIADTE: ", response.data.candidate.work_experience)
        setSavedInfo(JSON.parse(response.data.candidate.qualification))
        setTempMail(response.data.user.email)

        //fetching data from API and generating multiple input fields according to number of entries
        let fields: any = []
        savedInfo.map((val, index) => {
          const qualification = Object.values(val)
          for (let i = 0; i < qualification.length; i++) {
            let entity: any = qualification[i]
            if (typeof entity === 'object') {
              let newField = {
                education_level: entity.education_level,
                institution: institute !== null && institute !== undefined && institute !== '' ? institute : entity.institution,
                otherInstitute: entity.otherInstitute,
                program: entity.program,
                // cgpa: entity.cgpa,
                other_program: entity.other_program,
                education_type: entity.education_type,
                education_value: entity.education_value,
                end_date: entity.end_date,
                start_date: entity.start_date,
                currently_studying: entity.currently_studying,
              }
              fields.push(newField)
            }
          }
        })
        if (fields.length > 0) {
          setInputFields(fields)
        } else {
          if (inputFields.length == 0) {
            addFields()
          }
        }

        const qualificationArray = JSON.parse(response.data?.candidate?.qualification)
        let educationalYearsValue = qualificationArray[0].educationalYears
        setEducationalYears(educationalYearsValue)

        // console.log('Education: inputFieldsLength: ', inputFields.length);
        // if (inputFields.length <= 0) {
        //     addFields();
        // }
      })
    }
    getData()
  }, [tempMail])

  const getConfigurations = async () => {
    await axios
      .get(GET_CONFIGURATIONS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.Configuration
        let termsConditions = data?.terms_condition
        if (termsConditions) {
          termsConditions = JSON.parse(termsConditions)
          const initialTermsAndConditions = Object.keys(termsConditions).map((key) => ({
            i: key,
            data: termsConditions[key],
          }))
        }
        let eduLevels = data?.education_level
        // if (eduLevels) {
        //   eduLevels = JSON.parse(eduLevels)
        //   const initialEdu = Object.keys(eduLevels).map((key) => ({
        //     i: key,
        //     data: eduLevels[key]['data'],
        //     active: eduLevels[key]['active']
        //   }))
        //   setEducationLevels(initialEdu)
        // }
        if (eduLevels) {
          eduLevels = JSON.parse(eduLevels);
          const initialEdu = Object.keys(eduLevels)
            .map((key) => ({
              i: key,
              data: eduLevels[key]['data'],
              active: eduLevels[key]['active']
            }))
            .filter((level) => level.active); // Filter out objects with active === false
          setEducationLevels(initialEdu);
        }

        let institutes = data?.institutes
        if (institutes) {
          institutes = JSON.parse(institutes)
          const initialData = Object.keys(institutes).map((key) => ({
            i: key,
            data: institutes[key],
          }))
          setInstitutes(initialData)
        }
        let programs = data?.specialization
        if (programs) {
          programs = JSON.parse(programs)
          const initialData = Object.keys(programs).map((key) => ({
            i: key,
            data: programs[key],
          }))
          setSpecialization(initialData)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    (async () => {
      getConfigurations()
    })()
  }, [])

  return (
    <div>
      <div style={{ width: '88%', margin: '0 auto' }}>
        <i style={{ float: 'right' }} onClick={addFields} className='bi bi-plus-lg addmore'></i>
      </div>
      <div className='row g-5 g-xl-8 personal-info-container' style={{ margin: '0 auto', padding: '0' }}>
        {/* add more button  */}

        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <span className='card-label section-heading fw-bolder fs-7 ' style={{ color: '#d50000' }}>
                    Please enter your highest qualification first!
                  </span>
                  {/* FORM START  */}
                  <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    {inputFields.map((input, index) => {
                      console.log('currently_studying: ' + input.currently_studying + ' atIndex: ' + index)
                      const isCgpaChecked = input.education_type === 'cgpa'
                      const isPercentageChecked = input.education_type === 'percentage'
                      const isDivisionChecked = input.education_type === 'division'

                      return (
                        <div className='dynamic-section' key={index}>
                          {index === 0 ? null : (
                            <div className='me-10' style={{ width: '95%', margin: '0 auto' }}>
                              <i
                                style={{ float: 'right' }}
                                onClick={() => removeFields(index)}
                                className='bi bi bi-trash-fill trash-button'
                              ></i>
                            </div>
                          )}
                          {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                          <h3 className='card-title mt-5 align-items-center flex-column'>
                            <span className='card-label section-heading text-gray-800 fw-bolder fs-3 '>
                              Academic Qualification {index + 1}
                            </span>
                          </h3>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className={`form-group ${input.institution === 'Other' ? 'col-md-3 mx-8' : 'col-md-6'}`}>
                              {/* company  */}
                              <label className='form-label labelse '>
                                Educational Level<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='education_level'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  value={input.education_level}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value=''>Select an option</option>
                                  {educationLevels &&
                                    educationLevels.map((edu) => {
                                      let index = edu?.i
                                      let level = edu?.data
                                      return (
                                        <option style={{ cursor: 'pointer' }} key={index} value={level}>
                                          {level}
                                        </option>
                                      )
                                    })}
                                  {/* <option value='Secondary School Certificate ( SSC )'>Secondary School Certificate ( SSC )</option>
                                                                    <option value='Higher Secondary School Certificate (HSSC)'>Higher Secondary School Certificate (HSSC)</option>
                                                                    <option value='Bachelors'>Bachelors</option>
                                                                    <option value='Masters'>Masters</option>
                                                                    <option value='PhD'>PhD</option> */}
                                </select>
                              </div>{' '}
                            </div>

                            <div className={`form-group ${input.institution === 'Other' ? 'col-md-4 me-8' : 'col-md-6'}`}>
                              <label className='form-label labelse '>
                                Institution<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='institution'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select w-140px'
                                  value={input.institution}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value=''>Select an option</option>
                                  {institutes &&
                                    institutes.map((institute) => {
                                      let index = institute?.i
                                      let data = institute?.data
                                      return (
                                        <option style={{ cursor: 'pointer' }} key={index} value={data}>
                                          {data}
                                        </option>
                                      )
                                    })}
                                  <option value='Other'>Other</option>
                                  {/* <option value='Bahria University'>Bahria University</option>
                                                                    <option value='NUST'>NUST</option>
                                                                    <option value='CUST'>CUST</option> */}
                                </select>
                              </div>
                            </div>
                            {input.institution === 'Other' ? (
                              <div className='form-group col-md-4 me-8'>
                                <label className='form-label labelse '>
                                  Enter Institution Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='otherInstitute'
                                    className='form-control'
                                    placeholder='Institution'
                                    value={input.otherInstitute}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            {/* program  */}
                            <div className={`form-group ${input.program === 'Other' ? 'col-md-3 mx-8' : 'col-md-6'}`}>
                              <label className='form-label labelse '>
                                Program/ Specialization<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                              </label>
                              <div className='me-1'>
                                <select
                                  name='program'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  value={input.program}
                                  required
                                  onChange={(event) => handleFormChange(index, event)}
                                >
                                  <option value=''>Select an option</option>
                                  {specialization &&
                                    specialization.map((program) => {
                                      let index = program?.i
                                      let data = program?.data
                                      return (
                                        <option style={{ cursor: 'pointer' }} key={index} value={data}>
                                          {data}
                                        </option>
                                      )
                                    })}
                                  {/* <option value='Computer Science'>Computer Science</option>
                                                                    <option value='Software Engineering'>Software Engineering</option>
                                                                    <option value='Business and administration'>Business and administration</option> */}
                                  <option value='Other'>Other</option>
                                </select>
                              </div>
                            </div>
                            {input.program === 'Other' ? (
                              <div className='form-group col-md-4 me-8'>
                                <label className='form-label labelse '>
                                  Enter Program Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='otherProgram'
                                    className='form-control'
                                    placeholder='Enter Program'
                                    value={input.otherProgram}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {/* cgpa  */}
                            <div className={`form-group ${input.program === 'Other' ? 'col-md-4' : 'col-md-6'}`}>
                              {/* <label className='form-label labelse '>CGPA</label> */}
                              <div className='form-row d-flex justify-content-spacearound form-label'>
                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                                >
                                  <span style={{ color: '#d50000', marginRight: '4px' }}>*</span>
                                  <input
                                    type='radio'
                                    id={'cgpaGrade' + index}
                                    name={'education_type' + index}
                                    value='cgpa'
                                    checked={isCgpaChecked}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='CGPA' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                                    CGPA
                                  </label>
                                </div>
                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                                >
                                  <input
                                    type='radio'
                                    id={'percentageGrade' + index}
                                    name={'education_type' + index}
                                    value='percentage'
                                    checked={isPercentageChecked}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='Percentage' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                                    Percentage
                                  </label>
                                </div>

                                <div
                                  className='form-group  col-md-3 me-4'
                                  style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                                >
                                  <input
                                    type='radio'
                                    id={'divisionGrade' + index}
                                    name={'education_type' + index}
                                    value='division'
                                    checked={isDivisionChecked}
                                    onChange={(event) => handleCGPARadio(index, event)}
                                    className='checks-input'
                                  />
                                  <label htmlFor='Division' style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}>
                                    Division
                                  </label>
                                </div>
                              </div>{' '}
                              <div className='me-1'>
                                <input
                                  // type='number'
                                  min='0'
                                  max={input.education_type === 'cgpa' ? 4 : input.education_type === 'percentage' ? 100 : 3}
                                  step={input.education_type === 'cgpa' || input.education_type === 'percentage' ? 0.01 : 1}
                                  name='education_value'
                                  className='form-control'
                                  placeholder='3.0'
                                  value={input.education_value}
                                  required
                                  // pattern="\d*"
                                  onChange={(event) => handleCGPAChange(index, event)}
                                  onKeyPress={(event) => handleKeyPress(event, input.education_type)}
                                />
                              </div>{' '}
                            </div>
                          </div>

                          {/* Date picker  */}
                          {/* from  */}
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-sm-6 col-md-6 col-lg-6'>
                              <label className='form-label labelse '>
                                Date From<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                              </label>
                              <div className='datepicker me-1'>
                                {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DesktopDatePicker
                                                                        
                                                                        label="start_date"
                                                                        inputFormat="MM/DD/YYYY"
                                                                        value={input.start_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) => <TextField id='start_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='start_date'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={input.start_date}
                                    required
                                    onChange={(event) => handleDateFromChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='form-group col-sm-6 col-md-6 col-lg-6'>
                              <label className='form-label labelse '>
                                Date To<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                              </label>
                              <div className='datepicker me-1'>
                                {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="end_date"
                                                                        value={input.end_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) =>
                                                                            <TextField name='end_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='end_date'
                                    className='form-control'
                                    // max={new Date().toISOString().split("T")[0]}
                                    value={input.end_date}
                                    required
                                    onChange={(event) => handleDateToChange(index, event)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='form-group mt-10 checks-container col-md-12'>
                            <div className='form-check checkbox-container'>
                              <input
                                name='currently_studying'
                                className='form-check-input'
                                type='checkbox'
                                value={input.currently_studying}
                                checked={input.currently_studying}
                                onChange={(event) => handleCurrentlyStudying(index, event)}
                              />
                              <label className='form-check-label'>Currently studying here?</label>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-sm-6 col-md-6 col-lg-6'>
                        {/* company  */}
                        <label className='form-label labelse '>
                          Total Educational Years<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-1'>
                          <select
                            name='educationalYears'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Select Total Edu Years'
                            value={educationalYears}
                            required
                            onChange={(event) => handleChangeEducationalYears(event)}
                          >
                            <option style={{ cursor: 'pointer' }} value=''>
                              Select Total Educational Years
                            </option>
                            {eduYears.map((years) => (
                              <option style={{ cursor: 'pointer' }} key={years} value={years}>
                                {years}
                              </option>
                            ))}
                            <option style={{ cursor: 'pointer' }} value='Greater than 16'>
                              {'Greater than 16'}
                            </option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    {/* <div className='form-row d-flex mt-10 justify-content-spacearound'> */}
                    <div className='d-flex align-items-center justify-content-center mt-8'>
                      <button
                        onClick={handleSubmit}
                        className='candi-btn1 m-5'
                      // type='submit'
                      >
                        <span style={{ fontWeight: 'bold' }}>Save</span>
                      </button>
                      <div
                        className='candi-btn2 text-white m-5'

                        // disabled={props.curr === 5}
                        onClick={(e) => {
                          if (props.curr === 5) {
                            // diable button
                          } else {
                            handleSubmit(e)
                            props.updates(props.curr + 1)
                          }
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>Next</span>
                      </div>
                    </div>
                    {/* </div> */}
                  </form>
                  {/* FORM END  */}
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: 0, position: 'absolute' }} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default Educations
