//import liraries
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { RecruiterCard } from '../../content/cards/RecruiterCard';
import axios from 'axios';
import ShowError from '../../content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL;
const GET_RECRUITERS_URL = `${API_URL}/auth/get-recruiters`;
const GET_REPLACEMENT_USERS_URL = `${API_URL}/auth/get-replacements`;

type Props = {
    show: boolean
    handleClose: () => void
    handleSubmit: (userId, replacementUserId) => void
    user: any
}
const modalsRoot = document.getElementById('root-modals') || document.body
// create a component
const UserRemovePopup = ({ show, handleClose, handleSubmit, user }: Props) => {
    const { logout, auth } = useAuth();
    const [areYouSure, setAreYouSure] = useState(false);
    const [allUsers, setAllUsers] = useState<any>();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState<any>([]);
    const [replacement, setReplacement] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        (() => {
            getUsers(user);
        })();
    }, [user])

    const getUsers = (user) => {
        // const role = user?.role?.toLowerCase();
        const role = user?.role?.toLowerCase().trim().replace(/\s+/g, '_');
        const userId = user?.user_id;
        const data = {
            delete_role: role,
            user_id: userId
        };
        axios.get(GET_REPLACEMENT_USERS_URL, {
            params: data,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {

            console.log('fetchedUsers: ', res?.data);
            let data = res?.data?.data;
            if (data) {
                setAllUsers(data);
            }

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                // showToast(error.response.data.error.message);
                setMessage(error.response.data.error.message);
                setShowErrorMessage(true);
                // handleClose();
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });

        // switch (role) {
        //     case 'Recruiter': {
        //         // Handle logic for Recruiter role
        //         getRecruiters(user?.user_id, '');
        //         break;
        //     }

        //     case 'POC': {
        //         // Handle logic for POC role
        //         break;
        //     }
        //     case 'TA Lead': {
        //         // Handle logic for TA Lead role
        //         break;
        //     }
        //     case 'TA Head': {
        //         // Handle logic for TA Head role
        //         break;
        //     }
        //     case 'Employee': {
        //         // Handle logic for Employee role
        //         break;
        //     }
        //     default: {
        //         // Handle default case (if role doesn't match any of the above)
        //         break;
        //     }
        // }
    };

    const getRecruiters = async (recruiterId, TALeadId) => {
        let data = {
            ta_lead_id: recruiterId,
            recruiter_id: recruiterId
        }
        await axios.get(GET_RECRUITERS_URL,
            {
                params: data,
                headers: {
                    Authorization:
                        `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                console.log('fetchedUsers: ', res?.data);

            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    // showToast(error.response.data.error.message);
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                    // handleClose();
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });
    }

    const handleYesSure = (e) => {
        e.preventDefault();

        setAreYouSure(true);

    }

    const handleUserChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        let filteredResults = allUsers.filter((item) => {
            //   let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`;
            let fullNameEmployeeId = `${item['first_name']} ${item['last_name']}`;
            return (
                fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
            )
        });
        console.log('filteredResults: ', filteredResults);
        setSearchResults(filteredResults.length > 0 ? filteredResults : allUsers);
    };

    const handleItemClick = (item) => () => {
        setReplacement(item);
        let query = `${item['first_name']} ${item['last_name']}`;
        setSearchQuery(query);
        setSearchResults([]);
    };

    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={Layout}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h3 className='fw-bolder mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>
                                {`${areYouSure ? 'Please select user for replacement' : 'Are you sure you want to Archive this user?'}`}
                            </h3>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    {!areYouSure ? (<div className='sureunsure'>
                                        <button
                                            onClick={handleYesSure}
                                            type='button'
                                            className='btn px-10 bgcolorofbutoonofsubmit'
                                        >
                                            Yes, I am sure!
                                        </button>

                                        <button onClick={handleClose} className='btn px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                                            No
                                        </button>
                                    </div>) : (
                                        <form>
                                            <div className='form-group   col-md-12'>
                                                <div className='me-4'>
                                                    <label className='form-label fs-6 fw-bolder '>
                                                        User Name/ Employee ID
                                                    </label>

                                                    <input type="text" className='search-bar-style form-control   w-140px'
                                                        placeholder='Enter User Name/Employee ID'
                                                        value={searchQuery}
                                                        onChange={handleUserChange}
                                                        onFocus={handleUserChange}
                                                    />
                                                    {searchResults.length > 0 && (<ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                                                        {searchResults.map((item, index) => (
                                                            <li key={index}
                                                                onClick={handleItemClick(item)}
                                                                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                                className="hover-li">
                                                                {`${item?.first_name} ${item?.last_name} - ${item?.employee_id}`}
                                                            </li>
                                                        ))}
                                                    </ul>)}

                                                </div>
                                                {replacement && (<div style={{ marginTop: '-4rem' }}>
                                                    <RecruiterCard
                                                        // avatar='/media/avatars/300-14.jpg'
                                                        avatar={undefined}
                                                        name={`${replacement?.first_name}`}
                                                        employeeId={`${replacement?.employee_id}`}
                                                        email={`${replacement?.email}`}
                                                        phoneNumber={`${replacement?.phone_number}`}
                                                    />
                                                </div>)}
                                                <div className='d-flex justify-content-center my-4'>
                                                    <button
                                                        onClick={()=>{handleSubmit(user?.user_id, replacement?.user_id)}}
                                                        type='button'
                                                        className='btn px-10 bgcolorofbutoonofsubmit'
                                                        disabled={!replacement}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )}
            </>
        </Modal>,
        modalsRoot
    )
};


//make this component available to the app
export default UserRemovePopup;
