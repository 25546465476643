//import liraries
import React, { useEffect, useState } from 'react';
import './allReports/reports.css';
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import MonthlyAchievementTable from './allReports/MonthlyAchievementTable';
import GradeHiringTable from './allReports/GradeHiringTable';
import InterviewSchedulingCalendarTable from './allReports/InterviewSchedulingCalendarTable';
import WorkplaceDiversityTable from './allReports/WorkplaceDiversityTable';
import TalentActivityTable from './allReports/TalentActivityTable';
import TrackingHiringProcedureTable from './allReports/TrackingHiringProcedureTable';
import RecruitmentStageTrackingTable from './allReports/RecruitmentStageTrackingTable';
import YearlyRecruitmentAnalysisTable from './allReports/YearlyRecruitmentAnalysisTable';
import StaffingReportGradeWiseTable from './allReports/StaffingReportGradeWiseTable';
import AssessmentsConductedTable from './allReports/AssessmentsConductedTable';

// create a component
const TabularReports = () => {
    const intl = useIntl();
    const allReportsTitle = ['Recruitment KPI Monthly Achievement Chart', 'Talent Acquisition Grade Wise Hiring Budget', 'Talent Acquisition Interview Scheduling Calendar',
        'Recruitment Report Indicating Workplace Diversity', 'Bank Wide Talent Activity', 'Recruitment Tracking Report For Hiring Procedure', 'Recruitment Stage Tracking Report',
        'Yearly Recruitment Analysis Report', 'Yearly Staffing Report Grade Wise', 'Entry Level Assessments Conducted']
    const [activeReport, setActiveReport] = useState('Recruitment KPI Monthly Achievement Chart');

    // Smooth scroll to top whenever activeReport changes
    useEffect(() => {
        const element = document.getElementById('tabularReportsWrapper');
        if (element) {
            element.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [activeReport]);

    return (
        <div id="tabularReportsWrapper" >
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Reports' })}</PageTitle>
            <div className='d-flex '  >
                {/* reports menu */}
                <div style={{ width: '20%' }}>
                    <div className='d-flex flex-column bg-white me-8 shadow-sm' style={{ position: 'absolute', left: 0, marginTop: '3.65rem', width: '20rem' }}>
                        {allReportsTitle.map((reportTitle, index) => {
                            return (
                                <div key={index} >

                                    <div >
                                        {/* {<div className='me-6' style={{ width: '6px', backgroundColor: '#F36523' }}></div>} */}
                                        <label className={`fs-5 fw-bolder py-6 pe-4 cursor-pointer ${activeReport === reportTitle ? ' active-report-heading' : 'inactive-report-heading'}`}
                                            onClick={() => setActiveReport(reportTitle)}>
                                            {reportTitle}
                                        </label>
                                    </div>
                                    <div style={{ height: '1px', backgroundColor: '#CBC9C9' }}></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* reports view */}
                <div className='px-4' style={{ position: 'absolute', right: 0, marginTop: '6rem', width: '80%' }}>
                    {activeReport === 'Recruitment KPI Monthly Achievement Chart' && (
                        <MonthlyAchievementTable />
                    )}

                    {activeReport === 'Talent Acquisition Grade Wise Hiring Budget' && (
                        <GradeHiringTable />
                    )}

                    {activeReport === 'Talent Acquisition Interview Scheduling Calendar' && (
                        <InterviewSchedulingCalendarTable />
                    )}

                    {activeReport === 'Recruitment Report Indicating Workplace Diversity' && (
                        <WorkplaceDiversityTable />
                    )}
                    {activeReport === 'Bank Wide Talent Activity' && (
                        <TalentActivityTable />
                    )}
                    {activeReport === 'Recruitment Tracking Report For Hiring Procedure' && (
                        <TrackingHiringProcedureTable />
                    )}
                    {activeReport === 'Recruitment Stage Tracking Report' && (
                        <RecruitmentStageTrackingTable />
                    )}
                    {activeReport === 'Yearly Recruitment Analysis Report' && (
                        <YearlyRecruitmentAnalysisTable />
                    )}
                    {activeReport === 'Yearly Staffing Report Grade Wise' && (
                        <StaffingReportGradeWiseTable />
                    )}
                    {activeReport === 'Entry Level Assessments Conducted' && (
                        <AssessmentsConductedTable />
                    )}
                </div>
            </div>
        </div>
    );
};

//make this component available to the app
export default TabularReports;
