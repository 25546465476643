import { useIntl } from 'react-intl'
//import { PageTitle } from '../../../_metronic/layout/core'
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import './CandiMain.css'
import './position.css'
import './Stepper.css'
import './personalinfo.css'
import Loader from './comps/Loader'
import Saved from './comps/Saved'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'

import dayjs, { Dayjs } from 'dayjs'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import UploadCloud from '../assets/upload@2x.png'
import defaultProfile from '../assets/common@2x.png'

import React, { useEffect, useState, useRef } from 'react'
import { StepperComponent } from '../../../../_metronic/assets/ts/components/_StepperComponent'
// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import calnedarimage from './images/today_black_24dp.png'
import styled from 'styled-components'
import bottomarrow from './images/Path 38012.png'
import toparrow from './images/Path 38012 (1).png'
import pdfIcon from './images/ic_pdf.png'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import { useNavigate } from 'react-router-dom'
import { useRowState } from 'react-table'
import { Certificate } from 'crypto'
import Skills from './Skills'
import { UserModel } from '../../auth'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL

export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-info/`
export const PROFILE_UPLOAD_URL = `${API_URL}/candidate/add-profile-image`
export const CV_URL = `${API_URL}/candidate/upload-files/`
// export const CV_URL = `${API_URL}candidate/upload-cv/`
// export const PROFILE_IMG_URL = `${API_URL}candidate/upload-profile/`
export const ALLDATA_URL = `${API_URL}/candidate/`

const PersonalInformation = (props) => {
  const navigate = useNavigate()
  const [savedInfo, setSavedInfo] = useState<any>('')
  const [candidateInfo, setCandidateInfo] = useState<any>()

  const { auth, currentUser, logout } = useAuth()
  const { saveAuth, setCurrentUser } = useAuth()
  const [dataFetched, setDataFetched] = useState(false)
  const user_id = currentUser?.user_id
  const [prefix, setPrefix] = useState('')
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddleName] = useState('')
  const [lastName, setLastName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [cnic, setCnic] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [cityPreference, setCityPreference] = useState('');
  const [dob, setdob] = useState('')
  const [cnicExpiry, setCnicExpiry] = useState('')
  const [age, setAge] = useState(Number)
  const [freshGraduate, setFreshGraduate] = useState('')
  const [status, setStatus] = useState('')
  const [gender, setGender] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [religion, setReligion] = useState('Islam');
  const [otherReligion, setOtherReligion] = useState('');
  const [disability, setDisability] = useState('none');
  const [otherDisability, setOtherDisability] = useState('');
  const [spouseName, setSpouseName] = useState('');
  const [spouseCnic, setSpouseCnic] = useState('');
  const [spouseBOD, setSpouseBOD] = useState('');
  const [childInfo, setChildInfo] = useState([
    {
      name: '',
      cnic: '',
      dob: ''
    }
  ])
  // const [identificationMark, setIdentificationMark] = useState('')
  //---------
  const [cvFileName, setcvFileName] = useState('')
  const [cv, setCv] = useState<any>()
  const [profile, setProfile] = useState<any>()
  const [personalInfo, setPersonalInfo] = useState(false)
  const [profileStrength, setprofileStrength] = React.useState<any>({
    profile_info: 'false',
    work_exp: 'false',
    education: 'false',
    certificate: 'false',
    skills: 'false',
    total: '5',
  })
  const [currentProgress, setCurrentProgress] = useState(5)
  const [workExperiences, setWorkExperiences] = useState([])
  const [university, setUniversity] = useState('')
  const [education, setEducation] = useState([])
  const [skills, setSkills] = useState([])
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [message, setMessage] = useState('');
  const formData = new FormData()
  const allReligions = ['Islam', 'Christianity', 'Hinduism', 'Sikhism', 'Buddhism', 'Folk religions', 'Judaism', 'Irreligion', 'Other'];
  const allDisabilities = ['None',
    'Visual Impairment',
    'Hearing Impairment',
    'Physical Disability',
    'Intellectual Disability',
    'Mental Health Condition',
    'Neurological Disorder',
    'Chronic Illness',
    'Speech Impairment',
    'Learning Disability',
    'Other']

  const validateRequiredData = () => {
    if (prefix === null || prefix === undefined || prefix === '' || prefix === 'Prefix') {
      setMessage('Please Select a prefix first and try again.');
      setShowErrorMessage(true);
      return false;
    }

    if (firstName === null || firstName === undefined || firstName === '') {
      setMessage('First name is not valid');
      setShowErrorMessage(true);
      return false;
    }
    if (lastName === null || lastName === undefined || lastName === '') {
      setMessage('Last name is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (fatherName === null || fatherName === undefined || fatherName === '') {
      setMessage('Father name is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (cnic === null || cnic === undefined || cnic === '') {
      setMessage('CNIC is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (!cnicExpiry) {
      setMessage('Please enter CNIC Expiry first and try again.');
      setShowErrorMessage(true);
      return false;
    }

    if (!dob) {
      setMessage('Please enter Date of Birth first and try again.');
      setShowErrorMessage(true);
      return false;
    }

    if (contactNumber === null || contactNumber === undefined || contactNumber === '') {
      setMessage('Contact Number is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (address === null || address === undefined || address === '') {
      setMessage('Address is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (city === null || city === undefined || city === '') {
      setMessage('City is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (cityPreference === null || cityPreference === undefined || cityPreference === '') {
      setMessage('City of Preference is not valid');
      setShowErrorMessage(true);
      return false;
    }

    if (gender === null || gender === undefined || gender === '') {
      setMessage('Please select Gender first and try again');
      setShowErrorMessage(true);
      return false;
    }

    if (religion === null || religion === undefined || religion === '') {
      setMessage('Please select Religion first and try again');
      setShowErrorMessage(true);
      return false;
    }

    if (freshGraduate === null || freshGraduate === undefined || freshGraduate === '') {
      setMessage('Please select one answer for Fresh Graduate first and try again.');
      setShowErrorMessage(true);
      return false;
    }

    if (status === null || status === undefined || status === '') {
      setMessage('Please select Employment Status first and try again.');
      setShowErrorMessage(true);
      return false;
    }
    if (maritalStatus === null || maritalStatus === undefined || maritalStatus === '') {
      setMessage('Please select Marital Status first and try again.');
      setShowErrorMessage(true);
      return false;
    }
    if (disability === null || disability === undefined || disability === '') {
      setMessage('Please select disability first if any, and try again.');
      setShowErrorMessage(true);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e, action) => {

    const isValid = validateRequiredData();
    if (!isValid) {
      return;
    }

    e.preventDefault()
    // pass info to other components that fetched from resume
    if (workExperiences !== null && workExperiences !== undefined && workExperiences.length > 0) {
      props.workExperiences(workExperiences)
    }
    if (university !== null && university !== undefined) {
      props.university(university)
    }
    if (education !== null && education !== undefined && education.length > 0) {
      props.education(education)
    }
    if (skills !== null && skills !== undefined && skills.length > 0) {
      props.skills(skills)
    }

    formData.append('prefix', prefix)
    formData.append('first_name', firstName)
    formData.append('middle_name', middleName)
    formData.append('last_name', lastName)
    formData.append('father_name', fatherName)
    formData.append('email', currentUser ? currentUser.email : '')
    // formData.append('cnic', cnic.replace(/-/g, ''))
    formData.append('cnic', cnic)
    formData.append('cnic_expiration', cnicExpiry)
    formData.append('dob', dob)
    formData.append('phone_number', '92' + contactNumber)
    formData.append('address', address)
    formData.append('gender', gender)
    formData.append('marital_status', maritalStatus)
    formData.append('fresh_graduate', freshGraduate === 'Yes' ? 'true' : 'false')
    formData.append('employment_status', status)
    if (religion === 'Other') {
      formData.append('religion', otherReligion)
    } else {
      formData.append('religion', religion)
    }
    if (disability === 'Other') {
      formData.append('disability', otherDisability)
    } else {
      formData.append('disability', disability)
    }
    formData.append('city', city)
    formData.append('city_preference', cityPreference)
    formData.append('marital_data', JSON.stringify({ spouseName, spouseCnic, spouseBOD, childInfo }))
    formData.append('profile_strength', JSON.stringify(profileStrength))
    formData.append('profile_image', typeof profile !== 'string' ? profile : profile)

    const url = PERSONAL_INFORMATION_URL + user_id

    // loader component
    var loader = document.getElementById('loader')
    loader?.classList.remove('d-none')
    axios
      .put(url, formData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          // 'Content-Type': 'application/json',
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // console.log('RESPONSE FROM CLIENT: ', res);
        let user = res.data.user
        props.reduce(1)
        setCurrentProgress(profileStrength.total)
        // loader component
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        // setTimeout(function () {
        //   loader?.classList.add('d-none');
        // }, 4000)
        // update profile_pic of user
        let profilePicUrl = user.profile_pic
        if (profilePicUrl) {
          let userData: any = { ...currentUser, profile_pic: profilePicUrl }
          setCurrentUser(userData)
        }
        if (profileStrength.total == 100) {
          navigate('/candidatedashboard')
        } else {
          if (action === 'next') {
            props.updates(props.curr + 1)
          }
        }
      })
      .catch((error) => {
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message);
          setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
          logout();
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout();
          } else {
            setMessage(error.response.data.error.message);
            setShowErrorMessage(true);
          }
        } else {
          setMessage(error.message);
          setShowErrorMessage(true);
        }
      })
    // }, 2500);
    // navigate('/positions')
  }

  const handlePrefixChange = (e) => {
    setPrefix(e.target.value)
  }
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value)
  }
  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }
  const handleFatherNameChange = (e) => {
    setFatherName(e.target.value)
  }
  const handleCnicChange = (e) => {
    let inputCNIC = e.target.value

    // Validate the input format of the CNIC number
    // const regex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
    // if (regex.test(inputCNIC)) {
    //   setCnic(inputCNIC)
    // }

    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }

    setCnic(inputCNIC)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleContactNumberChange = (e) => {
    let inputPhone = e.target.value
    // Remove all non-digit characters from the input
    inputPhone = inputPhone.replace(/\D/g, '')
    // Regular expression to match Pakistani phone numbers without the country code
    const phoneRegex = /^3[0-9]{2}-?[0-9]{7}$/
    const isValidPhone = phoneRegex.test(inputPhone)
    if (isValidPhone) {
    }
    setContactNumber(inputPhone)
  }

  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }

  const handleCityChange = (e) => {
    setCity(e.target.value)
  }

  const handleChangeCityPreference = (e) => {
    setCityPreference(e.target.value);
  }

  const handleFreshGraduateChange = (e) => {
    setFreshGraduate(e.target.value)
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const handleMaritalStatusChange = (event) => {
    setMaritalStatus(event.target.value)
  }

  const handleReligionChange = (event) => {
    setReligion(event.target.value)
  }
  const handleOtherReligionChange = (event) => {
    setOtherReligion(event.target.value);
  }
  const handleDisabilityChange = (event) => {
    setDisability(event.target.value);
  }
  const handleOtherDisabilityChange = (event) => {
    setOtherDisability(event.target.value);
  }
  // ------------
  const handleChangeCnicExpiryDate = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setCnicExpiry(value)
  }
  const handleBlurCnicExpiryDate = (event) => {
    const enteredDate = new Date(event.target.value);
    const currentDate = new Date();

    if (enteredDate < currentDate) {
      // If the entered date is in the past, show an error message
      setMessage('Expiry date cannot be in the past');
      setShowErrorMessage(true);
      // Set the date to the current date
      setCnicExpiry(new Date().toISOString().split('T')[0]);
    }
  };

  const handleDate = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setdob(value)
    setAge(calculateAge(value))
  }

  const handleBlurDOB = (event) => {
    const enteredDate = new Date(event.target.value);
    const currentDate = new Date();
    const minDateOfBirth = new Date('1990-01-01');

    if (enteredDate > currentDate) {
      // If the entered date of birth is in the future, show an error message
      setMessage('Date of birth cannot be in the future');
      setShowErrorMessage(true);
      setdob(''); // Set date of birth to 01/01/1990
      setAge(0)
    }
  };

  const handleSpouseDOB = (event) => {
    let { value } = event.target;

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10);

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9);
    }
    setSpouseBOD(value)
  }

  const handleBlurDOBSpouse = (event) => {
    const enteredDate = new Date(event.target.value);
    const currentDate = new Date();
    const minDateOfBirth = new Date('1990-01-01');

    if (enteredDate > currentDate) {
      // If the entered date of birth is in the future, show an error message
      setMessage('Date of birth cannot be in the future');
      setShowErrorMessage(true);
      setSpouseBOD(''); // Set date of birth to 01/01/1990
    }
  };

  const handleSpouseNameChange = (event) => {
    setSpouseName(event.target.value);
  }

  const handleSpouseCnicChange = (event) => {
    let inputCNIC = event.target.value;
    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }
    setSpouseCnic(inputCNIC);
  }

  const addChildFields = () => {
    let newfield = {
      name: '',
      cnic: '',
      dob: ''
    }
    // setInputFields([...inputFields, newfield])
    if (childInfo) {
      setChildInfo((prevFields) => [...prevFields, newfield])
    } else {
      let childData: any = [];
      childData.push(newfield);
      setChildInfo(childData);
    }
  }

  const removeChildFields = (index) => {
    let data = [...childInfo]
    data.splice(index, 1)
    setChildInfo(data)
  }

  const handleChildInfoChange = (index, event) => {
    let value = event.target.value;
    let data = [...childInfo]
    data[index][event.target.name] = value
    setChildInfo(data);
  }

  const handleChildCnicChange = (index, event) => {
    let inputCNIC = event.target.value;
    // Remove all non-digit characters from the input
    inputCNIC = inputCNIC.replace(/\D/g, '')
    // Add hyphen after 5 digits
    if (inputCNIC.length > 5) {
      inputCNIC = inputCNIC.slice(0, 5) + '-' + inputCNIC.slice(5)
    }
    // Add hyphen before last digit.
    if (inputCNIC.length > 13) {
      inputCNIC = inputCNIC.slice(0, 13) + '-' + inputCNIC.slice(13)
    }

    let data = [...childInfo]
    data[index]['cnic'] = inputCNIC
    setChildInfo(data);
  }

  const handleCVChange = async (event) => {
    const file = event.target.files[0]
    console.log('fileUploadedData: ', file)
    const fileName = file.name.toLowerCase()
    // Validate file type
    // if (file.type !== 'application/pdf') {
    // if (file && (file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
    // if ((file.type === "application/pdf" && fileName.endsWith(".pdf")) || (file.type === "application/msword" && (fileName.endsWith(".doc") || fileName.endsWith(".dot"))) || (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (fileName.endsWith(".docx") || fileName.endsWith(".dotx") || fileName.endsWith(".docm") || fileName.endsWith(".dotm")))) {
    // alert('Please upload a PDF or doc file.');
    // return;
    // }
    if (!['.pdf', '.doc', '.docx'].some((ext) => fileName.endsWith(ext))) {
      alert('Invalid file type. Please upload a .pdf, .doc, or .docx file.')
      return
    }
    setcvFileName(file.name)
    setCv(file)
    // console.log("CV check", event.target.files[0])
    const base64 = await convertBase64(file)
    // setCv(base64);

    if (file !== null && file !== undefined) {
      handleCVSubmit(file)
    } else {
      window.alert('Please upload resume file first')
    }
  }
  const handleProfilePic = async (event) => {
    const file = event.target.files[0]

    // Check if a file was selected
    if (!file) {
      return // No file selected, return early
    }

    // Regular expression to check if the file extension is .jpg, .jpeg, or .png
    const allowedExtensions = /\.(jpg|jpeg|png)$/i

    // Check if the file extension is allowed
    if (!allowedExtensions.test(file.name)) {
      alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.')
      event.target.value = null // Clear the file input value
      return
    }

    // Optional: You can set a maximum file size limit if needed
    const maxSizeInBytes = 5 * 1024 * 1024 // 5MB
    if (file.size > maxSizeInBytes) {
      alert('File size exceeds the limit (5MB). Please select a smaller image.')
      return
    }
    setProfile(file)
    // console.log("Profile check", event.target.files[0])
    const base64 = await convertBase64(file)
    // setProfile(base64);
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  var color = '#c44242'

  const theme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color,
          },
        },
      },
    },
  })

  function formatCnicNumber(cnic) {
    if (!cnic) return ''
    if (cnic.length !== 13) return cnic // CNIC number must be 13 digits long
    if (cnic.indexOf('-') !== -1) return cnic // CNIC number already has hyphens

    const part1 = cnic.substring(0, 5)
    const part2 = cnic.substring(5, 12)
    const part3 = cnic.substring(12)

    return `${part1}-${part2}-${part3}`
  }

  function formatPhoneNo(phoneNo) {
    // if (/^92/.test(phoneNo)) {
    //   // REMOVE FIRST 92
    //   console.log('Phone number starts with 92');
    //   phoneNo = phoneNo.slice(2);
    //   return phoneNo;
    // } else {
    //   return phoneNo;
    // }

    if (/^(\+92|92|0)/.test(phoneNo)) {
      // REMOVE +92, 92, OR 0
      console.log('Phone number starts with +92, 92, or 0')
      phoneNo = phoneNo.replace(/^(\+92|92|0)/, '')
      return phoneNo
    } else {
      return phoneNo
    }
  }

  function calculateAge(dobString) {
    let calculatedAge = 0
    if (dobString !== null && dobString !== undefined && dobString !== '') {
      const dob = new Date(dobString.split('/').reverse().join('-'))
      const diffMs = Date.now() - dob.getTime()
      calculatedAge = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365))
    }
    return calculatedAge
  }

  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        setSavedInfo(response.data.user)
        setPrefix(savedInfo.prefix)
        setFirstName(savedInfo.first_name)
        setMiddleName(savedInfo.middle_name)
        setLastName(savedInfo.last_name)
        setFatherName(response.data.candidate?.father_name)
        // setCnic(formatCnicNumber(savedInfo.cnic))
        setCnic(savedInfo.cnic)
        setdob(savedInfo.dob)
        let cnicExpiration = response.data.candidate?.cnic_expiration;
        if (cnicExpiration) {
          const formattedExpiration = new Date(cnicExpiration).toISOString().substring(0, 10);
          console.log('formattedExpiration: ', formattedExpiration);
          setCnicExpiry(formattedExpiration);
        }
        setAge(calculateAge(savedInfo.dob))
        setEmail(savedInfo.email)
        setContactNumber(formatPhoneNo(savedInfo.phone_number))
        setAddress(savedInfo.address)
        setGender(savedInfo.gender)
        setCity(savedInfo.city)
        setCityPreference(response.data.candidate?.city_preference);
        setMaritalStatus(savedInfo.marital_status)
        setFreshGraduate(savedInfo.fresh_graduate)
        setStatus(savedInfo.employment_status)
        const religionValue = response.data.candidate.religion || '';
        if (religionValue) {
          if (allReligions.includes(religionValue)) {
            setReligion(religionValue)
          } else {
            setReligion('Other')
            setOtherReligion(religionValue)
          }
        }
        const disabilityValue = response.data.candidate.disability || '';
        if (disabilityValue) {
          if (allDisabilities.includes(disabilityValue)) {
            setDisability(disabilityValue)
          } else {
            setDisability('Other')
            setOtherDisability(disabilityValue)
          }
        }
        setCandidateInfo(response.data?.candidate.profile_strength)
        let maritalData = response.data?.candidate?.marital_data
        if (maritalData) {
          maritalData = JSON.parse(maritalData);
        }
        setSpouseName(maritalData?.spouseName);
        setSpouseCnic(maritalData?.spouseCnic);
        setSpouseBOD(maritalData?.spouseBOD);
        setChildInfo(maritalData?.childInfo);

        let profileUrl = response?.data?.user?.profile_pic
        // if (!profileUrl) {
        //   profileUrl = response.data.candidate.profile_pic
        // }
        console.log('profilePic: ', profileUrl);
        setProfile(profileUrl)
        let cvUrl = response.data.candidate.cv_url
        const cv_base64 = btoa(cvUrl)
        setCv(cvUrl)

        // setPersonalInfo(response.data.candidate.personal_information)

        let strength = JSON.parse(response.data.candidate.profile_strength)
        console.log('strengthTest: ', strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 25,
              profile_info: 'true',
              work_exp: 'false',
              education: 'false',
              certificate: 'false',
              skills: 'false',
            })
          } else {
            let totalProgress = 5
            let profile = strength.profile_info
            if (typeof profile === 'string') {
              profile = strength.profile_info?.toLowerCase?.() === 'true'
            }
            console.log('strengthTest: profile: ', profile)
            let work = strength.work_exp
            if (typeof work === 'string') {
              work = strength.work_exp?.toLowerCase?.() === 'true'
            }
            console.log('strengthTest: work: ', work)
            let edu = strength.education
            if (typeof edu === 'string') {
              edu = strength.education?.toLowerCase?.() === 'true'
            }
            console.log('strengthTest: edu: ', edu)
            let certifi = strength.certificate
            if (typeof certifi === 'string') {
              certifi = strength.certificate?.toLowerCase?.() === 'true'
            }
            console.log('strengthTest: certifi: ', certifi)
            let skill = strength.skills
            if (typeof skill === 'string') {
              skill = strength.skills?.toLowerCase?.() === 'true'
            }
            console.log('strengthTest: skill: ', skill)
            if (profile) {
              totalProgress += 20
            }
            if (work) {
              totalProgress += 20
            }
            if (edu) {
              totalProgress += 20
            }
            if (certifi) {
              totalProgress += 15
            }
            if (skill) {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            setprofileStrength({
              total:
                strength.profile_info === 'false' ||
                  strength.profile_info === false ||
                  strength.profile_info === undefined ||
                  strength.profile_info === null
                  ? totalProgress + 20
                  : totalProgress,
              profile_info: 'true',
              work_exp: strength?.work_exp == undefined ? 'false' : strength.work_exp,
              education: strength?.education == undefined ? 'false' : strength.education,
              certificate: strength?.certificate == undefined ? 'false' : strength.certificate,
              skills: strength?.skills == undefined ? 'false' : strength.skills,
            })
          }

          // setCurrentProgress(5);
          // setprofileStrength({
          //   total: 5,
          //   profile_info: 'false',
          //   work_exp: 'false',
          //   education: 'false',
          //   certificate: 'false',
          //   skills: 'false',
          // })
        } else {
          setprofileStrength({
            total: 25,
            profile_info: 'true',
            work_exp: 'false',
            education: 'false',
            certificate: 'false',
            skills: 'false',
          })
        }
      })
    }
    getData()

    props?.onUpdateProgress(currentProgress)
  }, [props, currentProgress])

  async function handleCVSubmit(file) {
    const formCv = new FormData()

    const url = CV_URL + user_id

    if (file === null || file === undefined) {
      window.alert('Resume file not found, Please upload resume in pdf, doc or docx format.')
      return
    }
    // formCv.append("cv_file", cv)
    formCv.append('cv_file', file)
    // console.log(typeof formCv);

    axios
      .put(url, formCv, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          // 'Content-Type': 'application/json',
          'content-type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const extractedInfo = res.data.extractedInfo
        const name = extractedInfo?.name
        if (name !== null && name !== undefined) {
          setFirstName(name)
        }
        const email = extractedInfo?.email
        if (email !== null && email !== undefined) {
          // setEmail(email)
        }
        const phone = extractedInfo?.phone
        if (phone !== null && phone !== undefined) {
          setContactNumber(formatPhoneNo(phone))
        }
        const address = extractedInfo?.address
        if (address !== null && address !== undefined) {
          setAddress(address)
        }

        const institute = extractedInfo?.university
        if (institute !== null && institute !== undefined) {
          setUniversity(institute)
        }
        const workExp = extractedInfo?.workExperiences
        if (workExp !== null && workExp !== undefined && workExp.length > 0) {
          setWorkExperiences(workExp)
        }
        const edu = extractedInfo?.education
        if (edu !== null && edu !== undefined && edu.length > 0) {
          setEducation(edu)
        }
        const skillsList = extractedInfo?.skills
        if (skillsList !== null && skillsList !== undefined && skillsList.length > 0) {
          setSkills(skillsList)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message);
          setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
          logout();
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout();
          } else {
            setMessage(error.response.data.error.message);
            setShowErrorMessage(true);
          }
        } else {
          setMessage(error.message);
          setShowErrorMessage(true);
        }
      })

    // try {
    //   // Set the Authorization header with the bearer token
    //   // const headers = {
    //   //   'Authorization': `Bearer ${auth?.token}`,
    //   //   'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data for file uploads
    //   // };
    //   // Make PUT request with Axios, passing the FormData and headers
    //   // const response = await axios.put(url, formCv, { headers });

    //   const headers = new Headers();
    //   headers.append('Authorization', `Bearer ${auth?.token}`);
    //   // Make the PUT request with fetch
    //   const response = await fetch(url, {
    //     method: 'PUT',
    //     headers: headers,
    //     body: formCv
    //   });

    //   console.log('RESPONSE FROM CV UPLOAD:' + response)
    // } catch (error) {
    //   console.error(error);
    //   // Handle error or other logic here
    // }
  }

  function removeCV() {
    setCv(null)
  }

  const handleNextClick = (e) => {
    if (props.curr === 5) {
      alert('You cannot proceed further.')
    } else {
      handleSubmit(e, 'next') // Call handleSubmit function

    }
  }

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Position' })}</PageTitle> */}

      <div className='row g-5 g-xl-8 personal-info-container'>
        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                {/* begin::Beader */}
                <div className='card-header border-0 text-center'>
                  {/* Profile Upload  */}
                  {/* <input type="file"  /> */}

                  {/* resume area  */}
                  {/* <h5 className='text-center'>Resume</h5> */}

                  {/* <hr className='border-hr'></hr> */}
                </div>

                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                  <form encType='multipart/form-data'
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    {/* sam soft */}
                    {/* profile upload  */}
                    <div className='profile-pic-container text-center'>
                      <img
                        className='profile-img'
                        style={{ objectFit: 'cover' }}
                        alt='Profile image'
                        src={
                          typeof profile === 'string'
                            ? profile
                            : profile
                              ? URL.createObjectURL(profile)
                              : defaultProfile
                        }
                        height='200'
                      />
                      <div className='pro-container text-center'>
                        <input
                          type='file'
                          accept='.jpg, .jpeg, .png'
                          onChange={(event) => handleProfilePic(event)}
                        />
                        {/* <i className='bi bi-pen-fill'></i> */}
                        <img
                          src={imagePath.ic_edit_orange}
                          style={{
                            width: '18px',
                            height: '18px',
                            marginTop: '-3.8rem',
                            zIndex: '10px',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                        />
                      </div>
                    </div>
                    {/* cv upload  */}
                    <div className='drop-zone' style={{ width: '96%' }}>
                      <div className='drop-wrapper'>
                        <div className='drop-image-container'>
                          {/* <img className='drop-zone__img' src={typeof cv === 'string' ? cv : cv ? URL.createObjectURL(cv) : UploadCloud} alt="..." /> */}
                          <img
                            className='drop-zone__img'
                            src={cv ? UploadCloud : UploadCloud}
                            alt='...'
                          />
                        </div>
                        <div className='drop-image-container'>
                          <span className='drop-zone__prompt'>Drag and Drop Resume Here</span>
                          <span className='drop-zone__prompt'>or</span>
                          <span className='drop-zone__prompt browse-file'>Browse Files</span>
                          <span className='drop-zone__prompt fs-6'>
                            File type should be .pdf, .doc or .docx
                          </span>
                        </div>
                      </div>
                      <div className='cv-container'>
                        {/* <input type="file" accept="application/pdf" name="myFile" className="drop-zone__input" onChange={(event) => handleCVChange(event)} /> */}
                        <input
                          type='file'
                          accept='.pdf,.doc,.docx'
                          name='myFile'
                          className='drop-zone__input'
                          onChange={(event) => handleCVChange(event)}
                        />
                      </div>
                    </div>
                    {/* <button type='submit' className='btn btn-success'>SAVE CV</button> */}
                  </form>

                  {/* show cv uploaded view */}
                  {cv && (
                    <div
                      className='card-title align-items-center px-8'
                      style={{ marginTop: '-28px' }}
                    >
                      <div className='grid-container '>
                        <div className='grid-item'>
                          <img className='img-max-width' src={pdfIcon} alt='pdf-file' />
                        </div>
                        <div className='grid-item'>
                          <div className='text-row'>
                            <h4 className='text-gray-800 fs-6'>
                              {cvFileName ? cvFileName : 'file.pdf'}
                            </h4>
                          </div>
                          <div
                            className='text-row'
                            style={{ display: 'inline-block' }}
                            onClick={removeCV}
                          >
                            <h6 style={{ color: '#F36523' }}>Delete</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <form onSubmit={(e) => e.preventDefault()} encType='multipart/form-data'
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                    <h3 className='card-title align-items-center mt-10 flex-column px-8'>
                      <span className='card-label text-gray-800 fw-bolder fs-3 '>
                        Personal Information
                      </span>
                    </h3>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          First Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select
                                className='country-code'
                                id='country-code'
                                defaultValue='Prefix'
                                placeholder='Prefix'
                                value={prefix}
                                onChange={(event) => handlePrefixChange(event)}
                              >
                                <option value='Prefix'>Prefix</option>
                                <option value='Mr.'>Mr.</option>
                                <option value='Mrs.'>Mrs.</option>
                                <option value='Miss.'>Miss.</option>
                              </select>
                              <input
                                style={{ width: '84%' }}
                                type='text'
                                // name='status'
                                className='phone-no-input'
                                placeholder='First Name'
                                value={firstName}
                                required
                                onChange={(event) => handleFirstNameChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Middle Name</label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Middle Name'
                            value={middleName ? middleName : ''}
                            onChange={(event) => handleMiddleNameChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Last Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Last Name'
                            value={lastName}
                            required
                            onChange={(event) => handleLastNameChange(event)}
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Father Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Father Name'
                            value={fatherName}
                            required
                            onChange={(event) => handleFatherNameChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Enter CNIC<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            // pattern="\d*"
                            autoComplete='off'
                            maxLength={15}
                            minLength={15}
                            className='form-control'
                            placeholder='____-_______-_'
                            value={cnic}
                            required
                            onChange={(event) => handleCnicChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          CNIC Expiry Date
                          <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='datepicker me-4'>
                          <div className='form-group'>
                            <input
                              type='date'
                              name='Date_from'
                              className='form-control'
                              min={new Date().toISOString().split('T')[0]}
                              value={cnicExpiry}
                              required
                              onChange={(event) => handleChangeCnicExpiryDate(event)}
                              onBlur={handleBlurCnicExpiryDate}
                            />
                          </div>

                          {/* </ThemeProvider> */}
                        </div>
                      </div>

                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          DOB ( as on CNIC )
                          <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='datepicker me-4'>
                          <div className='form-group'>
                            <input
                              type='date'
                              name='Date_from'
                              className='form-control'
                              max={new Date().toISOString().split('T')[0]}
                              value={dob}
                              required
                              onChange={(event) => handleDate(event)}
                              onBlur={handleBlurDOB}
                            />
                          </div>

                          {/* </ThemeProvider> */}
                        </div>
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Age<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <label placeholder='0'>{age}</label>
                          </div>
                        </div>{' '}
                      </div>

                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Contact Number<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select className='country-code' id='country-code' defaultValue='+92'>
                                <option value='+92'>+92</option>
                              </select>
                              <input
                                style={{ width: '86%' }}
                                type='tel'
                                name='status'
                                className='phone-no-input'
                                autoComplete='off'
                                placeholder='3xxxxxxxxx'
                                pattern='\d*'
                                maxLength={10}
                                minLength={10}
                                value={contactNumber}
                                required
                                onChange={(event) => handleContactNumberChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Email<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <label className='form-control me-4'>{currentUser?.email}</label>
                        {/* <div className='me-4'>
                          <input
                            type='email'
                            name='status'
                            className='form-control'
                            placeholder='Enter Email'
                            value={email}
                            required
                            onChange={(event) => handleEmailChange(event)}
                          />
                        </div> */}{' '}
                      </div>

                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Address<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            autoComplete='off'
                            className='form-control'
                            placeholder=''
                            value={address}
                            required
                            onChange={(event) => handleAddressChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          City<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder=''
                            value={city}
                            required
                            onChange={(event) => handleCityChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          City of Preference<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder=''
                            value={cityPreference}
                            required
                            onChange={(event) => handleChangeCityPreference(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Gender<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='form-row d-flex mt-5 justify-content-spacearound'>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type='radio'
                              id='Male'
                              name='options'
                              value='Male'
                              checked={gender === 'Male'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label
                              htmlFor='Male'
                              style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}
                            >
                              Male
                            </label>
                          </div>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type='radio'
                              id='Female'
                              name='options'
                              value='Female'
                              checked={gender === 'Female'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label
                              htmlFor='Female'
                              style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}
                            >
                              Female
                            </label>
                          </div>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{ alignContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <input
                              type='radio'
                              id='Others'
                              name='options'
                              value='Others'
                              checked={gender === 'Others'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label
                              htmlFor='Others'
                              style={{ fontWeight: 'bold', margin: '0px 0px 0px 10px' }}
                            >
                              Others
                            </label>
                          </div>
                        </div>{' '}
                      </div>

                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className={`form-group ${religion === 'Other' ? 'col-md-2 me-10 ms-8' : 'col-md-6'}`}>
                        <label className='form-label labelse '>
                          {' '}
                          Religion<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='religion'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            placeholder='Select an option'
                            defaultValue={'Islam'}
                            value={religion}
                            required
                            onChange={(event) => handleReligionChange(event)}
                          >
                            <option value=''>Select an option</option>
                            {allReligions.map((religionOption) => (
                              <option key={religionOption} value={religionOption}>
                                {religionOption}
                              </option>
                            ))}
                            {/* <option value='Islam'>Islam</option>
                            <option value='Christianity'>Christianity</option>
                            <option value='Hinduism'>Hinduism</option>
                            <option value='Sikhism'>Sikhism</option>
                            <option value='Buddhism'>Buddhism</option>
                            <option value='Folk religions'>Folk religions</option>
                            <option value='Judaism'>Judaism</option>
                            <option value='Irreligion'>Irreligion</option>
                            <option value='Other'>Other</option> */}
                          </select>
                        </div>
                      </div>
                      {religion === 'Other' ? (
                        <div className='form-group col-md-3 me-6'>
                          <label className='form-label labelse '>Enter Religion</label>
                          <div className='me-1'>
                            <input
                              type='text'
                              name='otherInstitute'
                              className='form-control'
                              placeholder='Religion'
                              value={otherReligion}
                              required
                              onChange={(event) => handleOtherReligionChange(event)}
                            />
                          </div>{' '}
                        </div>
                      ) : (<div></div>)}

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Fresh Graduate<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px '
                            placeholder='Select an option'
                            value={freshGraduate}
                            required
                            onChange={(event) => handleFreshGraduateChange(event)}
                          >
                            <option value=''>Select an option</option>
                            <option value='true'>Yes</option>
                            <option value='false'>No</option>
                          </select>
                        </div>{' '}
                      </div>

                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Employment Status
                          <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            placeholder='Select an option'
                            value={status}
                            required
                            onChange={(event) => handleStatusChange(event)}
                          >
                            <option value=''>Select an option</option>
                            <option value='Employed'>Employed</option>
                            <option value='Self Employed'>Self Employed</option>
                            <option value='Looking for Opportunities'>Looking for Opportunities</option>
                          </select>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          {' '}
                          Marital Status<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            placeholder='Select an option'
                            value={maritalStatus}
                            required
                            onChange={(event) => handleMaritalStatusChange(event)}
                          >
                            <option value=''>Select an option</option>
                            <option value='Married'>Married</option>
                            <option value='Single'>Single</option>
                            <option value='Divorced'>Divorced</option>
                            <option value='Widowed'>Widowed</option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    {(maritalStatus && maritalStatus !== 'Single' && maritalStatus !== 'Select an option') ? (
                      <div className='form-row mt-10 justify-content-spacearound mx-8'>
                        <label className='form-label labelse'>Family Info (Optional)</label>
                        <div className='mt-4' style={{ border: 'solid 1px #8C8C8C', borderRadius: '10px' }}>
                          <div className='form-row d-flex mt-4 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>
                                Spouse Name
                              </label>
                              <div className='me-4'>
                                <input
                                  type='text'
                                  // name='status'
                                  className='form-control'
                                  placeholder='Full Name'
                                  value={spouseName}
                                  required
                                  onChange={(event) => handleSpouseNameChange(event)}
                                />
                              </div>{' '}
                            </div>

                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>
                                Enter CNIC
                              </label>
                              <div className='me-4'>
                                <input
                                  type='text'
                                  name='status'
                                  // pattern="\d*"
                                  autoComplete='off'
                                  maxLength={15}
                                  minLength={15}
                                  className='form-control'
                                  placeholder='____-_______-_'
                                  value={spouseCnic}
                                  required
                                  onChange={(event) => handleSpouseCnicChange(event)}
                                />
                              </div>{' '}
                            </div>

                          </div>
                          <div className='form-row d-flex mt-4 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>
                                Spouse DOB

                              </label>
                              <div className='datepicker me-4'>
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='Date_from'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={spouseBOD}
                                    required
                                    onChange={(event) => handleSpouseDOB(event)}
                                    onBlur={handleBlurDOBSpouse}
                                  />
                                </div>

                                {/* </ThemeProvider> */}
                              </div>
                            </div>
                          </div>

                          {/* child info */}
                          <div className='d-flex flex-column'>
                            <div style={{ width: '88%', margin: '0 auto' }}>
                              <img
                                style={{ float: 'right', cursor: 'pointer' }}
                                onClick={addChildFields}
                                src={imagePath.ic_add_orange_border}
                              />
                            </div>
                            <div className='form-row mt-4 justify-content-spacearound'>
                              {childInfo && childInfo.map((child, index) => {

                                return (
                                  <div key={index} className='d-flex flex-column mb-8'>
                                    <label className='form-label labelse ms-8'>{`Child # ${(index + 1)}`}</label>
                                    {index === 0 ? null : (
                                      <div style={{ width: '88%', margin: '0 auto' }}>
                                        {/* <i style={{ float: 'right' }} onClick={() => removeFields(index)} className='bi bi bi-trash-fill trash-button'></i> */}
                                        <img
                                          style={{ float: 'right', cursor: 'pointer' }}
                                          src={imagePath.ic_delete_black_holo}
                                          onClick={() => removeChildFields(index)}
                                        />
                                      </div>
                                    )}
                                    <div >
                                      <div className='form-row d-flex justify-content-spacearound'>
                                        <div className='form-group col-md-6'>
                                          <label className='form-label labelse '>
                                            Child Name
                                          </label>
                                          <div className='me-4'>
                                            <input
                                              type='text'
                                              name='name'
                                              className='form-control'
                                              placeholder='Full Name'
                                              value={child?.name}
                                              required
                                              onChange={(event) => handleChildInfoChange(index, event)}
                                            />
                                          </div>{' '}
                                        </div>

                                        <div className='form-group col-md-6'>
                                          <label className='form-label labelse '>
                                            Child CNIC
                                          </label>
                                          <div className='me-4'>
                                            <input
                                              type='text'
                                              name='cnic'
                                              // pattern="\d*"
                                              autoComplete='off'
                                              maxLength={15}
                                              minLength={15}
                                              className='form-control'
                                              placeholder='____-_______-_'
                                              value={child?.cnic}
                                              required
                                              onChange={(event) => handleChildCnicChange(index, event)}
                                            />
                                          </div>{' '}
                                        </div>

                                      </div>
                                      <div className='form-row d-flex mt-4 justify-content-spacearound'>
                                        <div className='form-group col-md-6'>
                                          <label className='form-label labelse '>
                                            Child DOB

                                          </label>
                                          <div className='datepicker me-4'>
                                            <div className='form-group'>
                                              <input
                                                type='date'
                                                name='dob'
                                                className='form-control'
                                                max={new Date().toISOString().split('T')[0]}
                                                value={child?.dob}
                                                required
                                                onChange={(event) => handleChildInfoChange(index, event)}
                                              />
                                            </div>

                                            {/* </ThemeProvider> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (<div></div>)}

                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className={`form-group col-md-6`}>
                        <label className='form-label labelse '>
                          {' '}
                          Disability<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='disability'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select  me-4'
                            placeholder='Select an option'
                            defaultValue={'none'}
                            value={disability}
                            required
                            onChange={(event) => handleDisabilityChange(event)}
                          >
                            <option value=''>Select an option</option>
                            {allDisabilities.map((label, index) => (
                              <option key={index} value={label === 'Select an option' ? '' : label}>
                                {label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {disability === 'Other' ? (
                        <div className='form-group col-md-6 me-6'>
                          <label className='form-label labelse '>Enter Disability</label>
                          <div className='me-1'>
                            <input
                              type='text'
                              name='otherInstitute'
                              className='form-control'
                              placeholder='Disability'
                              value={otherDisability}
                              required
                              onChange={(event) => handleOtherDisabilityChange(event)}
                            />
                          </div>{' '}
                        </div>
                      ) : (<div></div>)}
                    </div>

                    <div className='d-flex align-items-center justify-content-center mt-8'>
                      <button className='candi-btn1 m-5'
                        // type='submit'
                        onClick={(e) => handleSubmit(e, 'save')}>
                        <span style={{ fontWeight: 'bold' }}>Save</span>
                      </button>
                      <div
                        className='candi-btn2 text-white m-5'
                        // type='button'
                        // disabled={props.curr === 5}
                        onClick={(e) => {
                          if (props.curr === 5) {

                          } else {
                            handleNextClick(e);
                          }
                        }}
                      >
                        <span style={{ fontWeight: 'bold' }}>Next</span>
                      </div>
                    </div>
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: 0, position: 'absolute' }} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && (
        <ShowError
          handleClose={() => setShowErrorMessage(false)}
          message={message}
        />
      )}

      {/* <div style={{ margin: 0, position: 'absolute' }} id='loader' className="d-none col-md-12">
        <Saved />
      </div> */}
    </>
  )
}

export default PersonalInformation
