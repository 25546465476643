/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import './List.css'

import {useContext} from 'react'
import {LoginContext} from '../../../../app/App'
import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
const GET_USERS_URL = `${API_URL}/auth/user-list`
const GET_ACTIVITIES_URL = `${API_URL}/activity/all-activities`

type Props = {
  className: string
}
const ListsWidget5: React.FC<Props> = ({className}) => {
  const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const {currentUser, auth} = useAuth()
  const [allUsers, setAllUsers] = useState<any>([])
  const [selectedUser, setSelectedUser] = useState()
  const [activities, setActivities] = useState<any>()

  const getUsers = async () => {
    if (currentUser?.role === 'none') {
      return
    }
    await axios
      .get(GET_USERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let users = res?.data?.data
        if (users) {
          let userId = users[0]?.user_id
          setAllUsers(res.data?.users)
          if (currentUser?.role === 'recruiter' || currentUser?.role === 'masterRecruiter') {
            userId = currentUser?.user_id
          }
          setSelectedUser(userId)
          getActivities(userId)
        }
      })
      .catch((err) => {
        console.error('err', err)
      })
  }
  const getActivities = async (userId) => {
    // e.preventDefault();

    setSelectedUser(userId)

    await axios
      .get(GET_ACTIVITIES_URL, {
        params: {
          user_id: userId,
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allActivities = res?.data?.data
        if (allActivities) {
          setActivities(allActivities)
        }
      })
      .catch((err) => {
        console.error('err:', err)
      })
  }
  useEffect(() => {
    ;(async () => {
      await getUsers()
    })()
  }, [])

  return (
    <div className={`card shadow ${className}`}>
      {/* begin::Header */}
      <div className='card-header card-hieght234 align-items-center border-1 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-gray-800 fw-bolder fs-3'>Recent Activities</span>
          {/* <span className='text-muted fw-semibold fs-7'>890,344 Sales</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      {loggedIn === true ? (
        <div className='card-body pt-5'>
          {/* users view */}
          {(currentUser?.role === 'masterRecruiter' || currentUser?.role === 'ta_head') && (
            <div className='d-flex align-items-center pt-4 px-1' style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
              {allUsers &&
                allUsers.map((user, index) => {
                  return (
                    <div key={index} className='d-flex align-items-center mb-8'>
                      <img
                        width={'40px'}
                        height={'40px'}
                        style={{borderRadius: '20px', objectFit: 'cover'}}
                        className={`${user?.user_id === selectedUser ? 'selected-user' : 'blur-profile-view mt-1'}`}
                        onClick={(e) => getActivities(user?.user_id)}
                        // src={user?.profile_pic ? user?.profile_pic : toAbsoluteUrl('/media/avatars/300-14.jpg')} />
                        src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon}
                      />
                      {index < allUsers.length - 1 && <div style={{backgroundColor: '#80808f', height: '1px', width: '20px'}}></div>}
                    </div>
                  )
                })}
            </div>
          )}

          {/* activities views */}

          <div style={{maxHeight: '30rem', overflow: 'auto'}}>
            {activities && activities.length > 0 ? (
              activities.map((activity, index) => {
                let dateTime = activity?.activity_time
                // Extract the time portion
                let time = dateTime?.substring(11, 16)
                // Define an array of month names
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                // Parse the date string into a Date object
                const date = new Date(dateTime)
                // Get the month and date
                const month = monthNames[date.getMonth()] // Get the month (0-based index, so use getMonth())
                const day = date.getDate() // Get the day of the month
                const formattedDate = `${month}, ${day}`

                return (
                  <div key={index} className='timeline-label'>
                    <div className={`timeline-item ${index < activities.length - 1 ? 'pb-10' : ''}`}>
                      <div>
                        <div className='timeline-label '>{formattedDate}</div>
                        <div className='timeline-label time'>{time}</div>
                      </div>
                      <div className='timeline-badge'>
                        <i className='fa fa-genderless text-success fs-1'></i>
                      </div>
                      <div style={{color: '#80808F'}} className='fw-normal timeline-content   ps-3'>
                        {activity?.activity_content}{' '}
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div>
                <label className='fs-5 fw-bolder'>No Activities Found</label>
              </div>
            )}
          </div>

          {/* <div className='timeline-label'>

            <div className='timeline-item'>
              <div className='timeline-label time'>08:42</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div style={{ color: '#80808F' }} className='fw-normal timeline-content   ps-3'>
                Ali Khan Posted Bank Teller job post{' '}
              </div>
            </div>

            <div className='timeline-item'>
              
              <div className='timeline-label time'>10:00</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              <div className='timeline-content d-flex'>
                <span style={{ color: '#80808F' }} className='fw-normal timeline-content  ps-3'>
                  Sara Shafi joined BOP as Bank Manger{' '}
                </span>
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label time'>14:37</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless circle-for-the-list-recent-activity  fs-1'></i>
              </div>
              <div style={{ color: '#80808F' }} className='fw-normal timeline-content  ps-3'>
                Marketing Intern job post closed with 33 applicantions{' '}
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label time'>16:50</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-success fs-1'></i>
              </div>
              <div style={{ color: '#80808F' }} className='fw-normal timeline-content  ps-3'>
                Hamza Asif Posted Sales Operation job post{' '}
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label time'>21:03</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div style={{ color: '#80808F' }} className='fw-normal timeline-content  ps-3'>
                Bank Teller vacancy closed{' '}
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label time'>21:03</div>
              <div className='timeline-badge'>
                <i className='fa fa-genderless text-warning fs-1'></i>
              </div>
              <div style={{ color: '#80808F' }} className='fw-normal timeline-content ps-3'>
                Usman Iqbal joined BOP as Marketing Intern{' '}
              </div>
            </div>

          </div> */}
        </div>
      ) : (
        <div></div>
      )}

      {/* end: Card Body */}
    </div>
  )
}

export {ListsWidget5}
