import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import HandymanIcon from '@mui/icons-material/Handyman';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';

import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import tick from './images/tick.png'

import edu2x from '../assets/cap_grey@2x.png';
import work2s from './images/E_Grey@2x.png';
import certi2x from '../assets/documentgrey@2x.png';
import skill2x from '../assets/Skills_grey@2x.png'

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { url } from 'inspector';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}
// ----------------------------------------
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 180deg,#FF8B56 0%,#F36523 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 180deg,#FF8B56 0%,#F36523 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
  filled: boolean; // Add the filled prop
}>(({ theme, ownerState, filled }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  // color: '#FFFFFF', // Update the color based on filled prop
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active ? ({
    background: "#ffff",
    border: '3px solid #F36523',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#F36523',
  }) : filled === true ? ({
    backgroundImage: `linear-gradient( 180deg,#FF8B56 0%,#F36523 100%)`,
    color: '#FFFFFF'
  }) : ({
    backgroundColor: '#ccc',
    color: '#FFFFFF'
  })),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient( 180deg,#FF8B56 0%,#F36523 100%)`,
  }),
  // ...(filled && {
  //   backgroundImage: `linear-gradient( 180deg,#FF8B56 0%,#F36523 100%)`,
  // }),
}));


function ColorlibStepIcon(props: StepIconProps & { filled: boolean }) {
  const { active, completed, filled, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <WorkIcon />,
    3: <SchoolIcon />,
    4: <CardMembershipIcon />,
    5: <HandymanIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} filled={filled} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


const steps = ['Personal Information', 'Work Experience', 'Education', 'Training & Certificates', 'Skills'];


export default function CustomizedSteppers(props) {
  let { filledSteps } = props;
  if(filledSteps === undefined || filledSteps === null){
    filledSteps = [false, false, false, false, false];
  }
  const StepIconComponent = (stepProps) => {
    const { active, completed, icon } = stepProps;
    return (
      <ColorlibStepIcon
        active={active}
        completed={completed}
        filled={filledSteps[icon - 1]}
        icon={icon}
      />
    );
  };

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={props.step} connector={<ColorlibConnector />}>
        {steps.map((label, key) => (
          <Step key={label} onClick={() => props.updateStep(key + 1)}>
            <StepLabel StepIconComponent={StepIconComponent} icon={key + 1}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

