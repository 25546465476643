import CompetenciesTabs from './SettingTabs/Competencies'
import ResourceAllocation from './SettingTabs/ResourceAllocation'
import Activity from './SettingTabs/Activity'
import { useEffect, useState } from 'react'
import './Settings.css'
import DepartmentBudget from './SettingTabs/DepartmentBudget'
import { useLocation, useNavigate } from 'react-router-dom'
import Configuration from './SettingTabs/ConfigurationModules/Configuration'
import ConfigurationNew from './SettingTabs/ConfigurationNew'
function SettingTabs() {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('tab') || 'Competency'; // Default to the first tab if no search parameter is provided

  const [Active, setActive] = useState(activeTab)

  const navigate = useNavigate();

  const ChangeInActiveIndex = (ActiveState: string) => {
    console.log('first')
    if (ActiveState === 'Competency') {
      setActive('Competency')
      navigate('/settings?tab=Competency');
    } else if (ActiveState === 'DepartmentBudget') {
      setActive('DepartmentBudget')
      navigate('/settings?tab=DepartmentBudget');
    }
    else if (ActiveState === 'ResourceAllocation') {
      setActive('ResourceAllocation')
      navigate('/settings?tab=ResourceAllocation');
    } else if (ActiveState === 'Configuration') {
      setActive('Configuration')
      navigate('/settings?tab=Configuration');
    }
  }

  return (
    <>
      {/* begin::Row */}
      <div className=' d-flex  gy-5 gx-xl-8'>
        <div
          style={{ cursor: 'pointer', marginLeft:'1px' }}
          className={`${Active === 'Competency' ? ' Onclicked-Button   ' : 'coloroftabs Notclicked-Button  '
            }  fw-bolder me-5 fs-4  `}
          onClick={() => ChangeInActiveIndex('Competency')}
        >
          {' '}
          Competencies
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className={`${Active === 'DepartmentBudget'
            ? ' Onclicked-Button   '
            : 'coloroftabs Notclicked-Button  '
            }  fw-bolder mx-5  fs-4  `}
          onClick={() => ChangeInActiveIndex('DepartmentBudget')}
        >
          {' '}
          Department Budget
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className={`${Active === 'ResourceAllocation'
            ? ' Onclicked-Button   '
            : 'coloroftabs Notclicked-Button  '
            }  fw-bolder mx-5  fs-4  `}
          onClick={() => ChangeInActiveIndex('ResourceAllocation')}
        >
          {' '}
          Resource Allocation
        </div>

        <div
          style={{ cursor: 'pointer' }}
          className={`${Active === 'Configuration' ? ' Onclicked-Button   ' : 'coloroftabs Notclicked-Button '
            }  fw-bolder  mx-5 fs-4  `}
          onClick={() => ChangeInActiveIndex('Configuration')}
        >
          {' '}
          Configuration
        </div>
      </div>
      {Active === 'Competency' ? (
        <>
          <CompetenciesTabs />
        </>
      )
        : Active === 'DepartmentBudget' ? (
          <>
            <DepartmentBudget />
          </>
        )
          : Active === 'ResourceAllocation' ? (
            <>
              <ResourceAllocation />
            </>
          )
            : Active === 'Configuration' ? (
              // <Configuration />
              <ConfigurationNew />
            )
              : (
                <></>
              )
      }
    </>
  )
}

export default SettingTabs
