//import liraries
import ReactDOM, { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyles.css'
import './Success.css'
import hurrayImg from '../images/hurray_img@3x.png';

type Props = {
    show: boolean
    handleClose: () => void
    message: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const SignupSuccessPopup = ({ show, handleClose, message }: Props) => {

    const handleSubmit = () => {
        console.log('closed')
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-100'
            show={true}
            backdrop={true}
            // style={{ height: '100%' }}
        >

        <div className='signup-success-bg'>
            <div className='popup-content shadow'>
                <div className=' mt-12'>
                    <div className=' d-flex flex-column '>
                        <img alt='' className='addcompetenctimage' src={hurrayImg}></img>
                        <h3 className='fw-bolder mx-auto my-5' style={{ color: '#FFFFFF' }}>Success!</h3>
                        <p className=' mx-auto my-5 text-style col-md-10 ' style={{ color: '#FFFFFF' }}>{message}</p>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <form
                    //  onSubmit={handleSubmit}
                    >
                        <div className='btn-container'>
                            <button
                                onClick={handleSubmit}
                                type='button'
                                className='btn px-10 apply-review-button'
                            >
                                Okay, Got it!
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        </Modal>,
        modalsRoot
    );
};


//make this component available to the app
export default SignupSuccessPopup;
