//import liraries
import React, { useEffect } from 'react';
import '../../../../_metronic/partials/modals/JobFunnelsModels/ModelStyles.css'
import '../../../../_metronic/partials/modals/JobFunnelsModels/Success.css';
import hurrayImg from '../../../../_metronic/partials/modals/images/hurray_img@3x.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL
export const VERIFY_ACCOUNT_URL = `${API_URL}/auth/account/verify/`

// create a component
const VerifyAccount = () => {
    const { id } = useParams<{ id: string }>()
    
    const navigate = useNavigate();
    const handleSubmit = () => {
        // redirect to login screen and remove current page from back stack
        navigate('/auth/login', { replace: true });
    }


    useEffect(() => {
        async function verifyUserAccount() {
            let token = id;
            await axios.get(VERIFY_ACCOUNT_URL + token).then((res) => {
                // console.log('accountVerifyResponse: ',res.data)

            }).catch((err) => console.log('err', err));
        }
        verifyUserAccount();
    }, [])

    return (

        <div className='signup-success-bg'>
            <div className='popup-content shadow'>
                <div className=' mt-12'>
                    <div className=' d-flex flex-column '>
                        <img alt='' className='addcompetenctimage' src={hurrayImg}></img>
                        <h3 className='fw-bolder mx-auto my-5' style={{ color: '#FFFFFF' }}>Success!</h3>
                        <p className=' mx-auto my-5 text-style col-md-10 ' style={{ color: '#FFFFFF' }}>Your email has been verified</p>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <form
                    //  onSubmit={handleSubmit}
                    >
                        <div className='btn-container'>
                            <button
                                onClick={handleSubmit}
                                type='button'
                                className='btn px-10 apply-review-button'
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
};


//make this component available to the app
export default VerifyAccount;
