//import liraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import '../../../_metronic/partials/modals/JobFunnelsModels/Success.css';
import '../../../_metronic/partials/modals/JobFunnelsModels/ModelStyles.css';
import hurrayImg from '../../../_metronic/partials/modals/images/hurray_img@3x.png';
// import icOpenEye from '../images/ic_open_eye.png';
import icOpenEye from '../auth/images/ic_open_eye.png';
import icCloseEye from '../auth/images/ic_close_eye.png';
import icUploadCloud from '../Candidate Space/assets/upload@2x.png';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import { isCandidatePath } from '../../../constants/CommonFunctions';

const API_URL = process.env.REACT_APP_API_URL
export const COMPLETE_PROFILE_URL = `${API_URL}/auth/update-pass-signature`;

const hostname = window.location.hostname
// create a component
const CompleteUserProfile = () => {
    let candidatePath = isCandidatePath(hostname);
    const [loading, setLoading] = useState(false)
    const [showDefaultPassword, setShowDefaultPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [signatureFile, setSignatureFile] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    // const { id } = useParams<{ id: string }>()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('user_id');
    console.log('fetchedId: ', id);

    const loginSchema = Yup.object().shape({
        defaultPassword: Yup.string()
            .max(50, 'Maximum 50 symbols')
            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
            //     'Password should be 12 characters long including minimum 1 capital letter, 1 small letter, 1 number and 1 special character.'
            // )
            .required('System Generated Password is required'),
        password: Yup.string()
            // .min(12, 'Minimum 12 symbols')
            .max(50, 'Maximum 50 symbols')
            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
            //     'Password should be 12 characters long including minimum 1 capital letter, 1 small letter, 1 number and 1 special character.'
            // )
            .required('Password is required'),
        confirmPassword: Yup.string()
            // .min(12, 'Minimum 12 symbols')
            // .max(50, 'Maximum 50 symbols')

            // .matches(
            //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
            //     'Password should have at least 12 characters, 1 capital letter, 1 small letter, numbers, and special characters'
            // )
            .oneOf([Yup.ref('password'), null], 'Passwords must match')

            .required('Confirm Password is required'),
    })

    const navigate = useNavigate();
    const handleBack = () => {
        console.log('goto login screen')
        // redirect to login screen and remove current page from back stack
        navigate('/auth/login', { replace: true });
    }

    const formik = useFormik({
        initialValues: {
            defaultPassword: '',
            password: '',
            confirmPassword: ''
        },
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {

            let defaultPassword
            let passwordSetting
            let confirmPasswordSetting

            defaultPassword = values.defaultPassword
            passwordSetting = values.password
            confirmPasswordSetting = values.confirmPassword

            if (!signatureFile && !candidatePath) {
                setMessage('Please upload your signatures first.')
                setShowErrorMessage(true);
                return;
            }
            setLoading(true)
            const formData = new FormData();
            formData.append("current", defaultPassword);
            formData.append("password", passwordSetting);
            formData.append("confirmPass", confirmPasswordSetting);
            if (signatureFile && !candidatePath) {
                formData.append("signature", signatureFile);
            }
            formData.append("user_id", id ? id : '');

            await axios.post(COMPLETE_PROFILE_URL, formData).then((res) => {
                console.log('accountVerifyResponse: ', res.data)
                setLoading(false);
                setSubmitting(false);
                navigate('/auth/login', { replace: true });

            }).catch((error) => {
                setLoading(false);
                setStatus(`Something went wrong: ${error}`);
                setSubmitting(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    // showToast(error.response.data.error.message);
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                    // handleClose();
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            });


        },

    });

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) {
            return; // No file selected, return early
        }

        // Regular expression to check if the file extension is .jpg, .jpeg, or .png
        const allowedExtensions = /\.(jpg|jpeg|png)$/i;

        // Check if the file extension is allowed
        if (!allowedExtensions.test(file.name)) {
            alert('Invalid file type. Please upload a .jpg, .jpeg, or .png file.');
            event.target.value = null; // Clear the file input value
            return;
        }
        // Optional: You can set a maximum file size limit if needed
        const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
        if (file.size > maxSizeInBytes) {
            alert('File size exceeds the limit (1MB). Please select a smaller image.');
            return;
        }

        console.log('compressedData: file: ', file);
        setSignatureFile(file);
        new Compressor(file, {
            quality: 0.2,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            async success(result) {
                const base64 = await convertBase64(result);
                // setSignatureFile(result);
                // console.log('compressedData: compressedImage: ', result);
                // console.log('compressedData: base64: ', base64);
            },
            error(err) {
                console.log(err.message);
            },
        });

    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div>
            <div style={{ position: 'absolute', zIndex: '1', marginTop: '3rem', marginLeft: '3rem' }} >
                <label className='form-label fw-bolder fs-1'
                    style={{ color: '#FFFFFF', cursor: 'pointer' }}
                    onClick={() => handleBack()}>
                    Back
                </label>
            </div>

            <div className='signup-success-bg scrollable-container'>
                <div className='d-flex flex-column'>
                    <div className=' mt-12'>
                        <div className=' d-flex flex-column '>
                            <h1 className='fw-bolder mx-auto my-5' style={{ color: '#FFFFFF' }}>Reset Password</h1>
                            {!candidatePath ? (<p className=' mx-auto my-5 text-style ' style={{ color: '#FFFFFF' }}>Reset the new password of your account <br></br> and upload your signatures so you can login <br></br> and access all the features</p>) :
                                (<p className=' mx-auto my-5 text-style ' style={{ color: '#FFFFFF' }} >Reset the new password of your account <br></br>to access all the features </p>)}
                        </div>
                    </div>
                    <form
                        className='form w-100 aaligndivCenter'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_reset_password_form'
                    >
                        <div className='widthHeightSettingForm'>
                            {/* begin::Form group */}
                            <div className='fv-row mb-4'>
                                <label className='form-label labeldfa mb-3'>System Generated Password</label>
                                <div className='grp-image-input backinpput-color'>
                                    <input
                                        style={{ height: '56px' }}
                                        placeholder='System Generated Password'
                                        {...formik.getFieldProps('defaultPassword')}
                                        className={clsx(
                                            'form-control no-border',
                                            {
                                                'is-invalid': formik.touched.defaultPassword && formik.errors.defaultPassword,
                                            },
                                            {
                                                'is-valid': formik.touched.defaultPassword && !formik.errors.defaultPassword,
                                            }
                                        )}
                                        type={showDefaultPassword ? 'text' : 'password'}
                                        name='defaultPassword'
                                        autoComplete='off'
                                    />
                                    <img alt='' src={showDefaultPassword ? icOpenEye : icCloseEye} style={{ cursor: 'pointer' }} onClick={() => setShowDefaultPassword(!showDefaultPassword)} />
                                    {/* <i className=' custom-class bi-envelope'></i> */}
                                </div>
                                {formik.touched.defaultPassword && formik.errors.defaultPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-white' role='alert'>
                                                {formik.errors.defaultPassword}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}
                            {/* begin::Form group */}
                            <div className='fv-row mb-4'>
                                <label className='form-label labeldfa mb-3'>New Password</label>
                                <div className='grp-image-input backinpput-color'>
                                    <input
                                        style={{ height: '56px' }}
                                        placeholder='Password'
                                        {...formik.getFieldProps('password')}
                                        className={clsx(
                                            'form-control no-border',
                                            {
                                                'is-invalid': formik.touched.password && formik.errors.password,
                                            },
                                            {
                                                'is-valid': formik.touched.password && !formik.errors.password,
                                            }
                                        )}
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        autoComplete='off'
                                    />
                                    <img alt='' src={showPassword ? icOpenEye : icCloseEye} style={{ cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)} />
                                    {/* <i className=' custom-class bi-envelope'></i> */}
                                </div>
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-white' role='alert'>
                                                {formik.errors.password}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}

                            {/* begin::Form group */}
                            <div className='fv-row mb-3'>
                                <label className='form-label labeldfa mb-3'>Confirm Password</label>
                                <div className='grp-image-input'>
                                    <input
                                        style={{ height: '56px' }}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        autoComplete='off'
                                        placeholder='Confirm Password'
                                        {...formik.getFieldProps('confirmPassword')}
                                        className={clsx(
                                            'form-control no-border',
                                            {
                                                'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                                            },
                                            {
                                                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                                            }
                                        )}
                                        name='confirmPassword'
                                    />

                                    <img src={showConfirmPassword ? icOpenEye : icCloseEye} alt='' style={{ cursor: 'pointer' }} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-white' role='alert'>
                                                {formik.errors.confirmPassword}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Form group */}

                            {/* upload signatures */}
                            {!candidatePath && (<div className='form-group col-md-12'>
                                <div className='col-md-12'>
                                    <label className='form-label labelse mt-10' style={{ color: '#fff' }}>Upload Signature</label>
                                    <div className="drop-zone-training-file" style={{ width: '100%' }}>
                                        <div className='drop-wrapper'>
                                            <div className='drop-image-container'>
                                                <img className='drop-zone__img'
                                                    style={{ objectFit: 'cover' }}
                                                    src={signatureFile ? URL.createObjectURL(signatureFile) : icUploadCloud} alt="file" />
                                            </div>
                                            <div className='drop-image-container' >
                                                <span className="drop-zone__prompt">Drop and Drop here</span>
                                                <span className="drop-zone__prompt">or</span>
                                                <span className="drop-zone__prompt browse-file">Browse Files</span>
                                                <span className="drop-zone__prompt fs-6">File type should be .png</span>
                                            </div>
                                        </div>
                                        <div className='signature-drop-container' >
                                            <input
                                                type="file"
                                                name="myFile"
                                                className="drop-zone__input-training-file"
                                                accept=".png"
                                                onChange={event => handleFileChange(event)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>)}

                            {/* begin::Action */}
                            <div className='d-grid mb-6 mt-10'>
                                <button
                                    style={{ height: '60px', color: 'white' }}
                                    type='submit'
                                    id='kt_sign_in_submit'
                                    className='btn btn-primary loginButtonLogin-screen'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='text-white fs-6 indicator-label'>Submit</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait....
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default CompleteUserProfile;
