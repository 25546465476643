import React from 'react';
import './toast.css'; // Import the CSS file for styling

import icWarning from '../images/ic_red_warning.png';

const CustomToast = ({ message }) => {
    return (
        <div className="custom-toast-container">
            <div className="custom-toast d-flex">
                <img className='me-4' style={{objectFit: 'cover'}} src={icWarning} width={26}/>
                <span className="custom-toast-message">{message ? message : 'Something went wrong!'}</span>
            </div>
        </div>
    );
};

export default CustomToast;
