import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './ModelStyless.css'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import ScheduleInterview from '../../../../app/modules/JobsFunneling/ScheduleInterview/ScheduleInterview'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import interviewheadericon from '../images/interview@2x copy.png'
import Box from '@mui/material/Box'
import { TextField } from '@mui/material'
import Usericon from './img/two-users@2x.png'
import Timericon from './img/Group 10169@2x.png'
import Copyicon from './img/interview2x.png'
import Descriptionicon from './img/description@2x.png'
import InformationIcon from './img/information (3)@2x.png'
import IconTile from './img/Group 9982.png'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExprienceIcon from './img/experience@2x.png'
import UserIcon from './img/Group 9681.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`
export const CREATE_EMAIL_URL = `${API_URL}/panel/reschedule-interview`
export const SEND_EMAIL_URL = `${API_URL}/panel/send-email-to-members`
export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`
export const GET_ALL_PANEL_MEMBER = `${API_URL}/panel/get-stage-panel-data`



type Props = {
    show: boolean
    handleClose: () => void
    name: string
    jobapp_id: string
    email: string
    user_id: string
    interviewStage1: string
    profile_pic: string
    position: string
    bexp: string
    jexp: string
}

type InputProps = {
    members?: string
    duration?: string
    date?: string
    link?: string
    description?: string
}

interface Recruiter {
    user_id: string;
    name: string;
    email: string;
    currentTarget: string
}

interface Submit {
    jobapp_id: string;
    index: string;
    name: string;
    email: string;

}
const modalsRoot = document.getElementById('root-modals') || document.body

const ReScheduleInterviewStage3 = ({ show, name, jobapp_id, email, user_id, interviewStage1, profile_pic, position, bexp, jexp, handleClose }: Props) => {
    console.log({ user_id })
    console.log({ jobapp_id })
    console.log({ name })


    console.log({ interviewStage1 })
    const data = JSON.parse(interviewStage1);
    console.log({ data })
    const [loading, setLoading] = useState(false);
    let cand_email = email;
    const [selectedInterviewers, setSelectedInterviewers] = useState<any>([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [searchRecord, setsearchRecord] = useState<Recruiter[]>([]);
    const [interviewers, setInterviewers] = useState<Recruiter[]>([]);
    const [interviewerData, setInterviewersData] = useState<Recruiter[]>([]);
    const [dataFetched, setDataFetched] = useState(false)
    const [recruiterEmail, setRecruiterEmail] = useState({})
    const [panelMembers, setPanelMembers] = useState<any>([])
    const [duration, setDuration] = React.useState('')
    const [value, setValue] = React.useState<Dayjs | null>(dayjs(data?.interview_date))
    const navigate = useNavigate()
    const [Poc, setPoc] = useState({})
    const [RecruitersD, setRecruitersD] = useState([{}])
    const [Recruiter, setRecruiter] = useState([{}])
    const [candidateData, setCandidateData] = useState([])
    const [formData, setFormData] = useState<InputProps>()
    const [panelName, setPanelName] = useState<any>([])
    const [panelEmail, setPanelEmail] = useState<any>([])
    const [panelId, setPanelId] = useState<any>([])
    const [items, setItems] = useState<any>([]);



    const [formInputs, setFormInputs] = React.useState({
         description: '', duration: '', time: '', interview: '', invitation_link: ''
    });


    const { auth } = useAuth()
    let pocData = [{}]
    let recruiterData = [{}]
    let result: any = {}
    let candResult = {}
    let LocalResult = {}


    const GETALLPANELMEMBERS = async () => {
        await axios
          .post(
            GET_ALL_PANEL_MEMBER,
            {
              jobapp_id,
              name: "stage3",
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            let panelData: any[] = res.data;
            console.log({ panelData });
      
            const filteredMembers = res.data.members
      
            const updatedMembers = filteredMembers.map((member) => ({
              ...member,
              assigned_score: null,
              status: null,
              availability: false,
            }));
      
            setPanelMembers(filteredMembers);
            setSelectedInterviewers(updatedMembers);
          });
      };
      

    console.log({ selectedInterviewers })
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const createEmailResponse = await axios.post(
                CREATE_EMAIL_URL,
                {
                    jobapp_id: jobapp_id,
                    name: "stage3",
                    panel: panelName['result'] || selectedInterviewers
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log({ panelName })

            console.log('RES-PANEL-CREATE', createEmailResponse);

            const sendEmailResponse = await axios.post(
                SEND_EMAIL_URL,
                {
                    interviewData: {
                        candidate_name: name,
                        job_experience: '3',
                        banking_experience: '4',
                        interview_date: value,
                        interview_time: formInputs['interview_time'],
                        duration: formInputs['duration'],
                        invitation_link: formInputs['invitation_link'],
                        description: formInputs['description'],
                        interview: formInputs['interview']
                    },
                    jobapp_id: jobapp_id,
                    name: 'stage3',
                },
                {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('RES-SEND-EMAIL', sendEmailResponse);

            handleClose();
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    console.log({ formInputs })

    const GetAllRecruiters = async () => {
        try {
            const resp = await axios.get(GET_ALL_RECRUITERS_URL, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('RESPONSE RECRUITER', resp.data.recuiter);
            const recruiters = resp.data.recuiter;

            const promises = recruiters.map(async (recruiter) => {
                if (recruiter['user_id'] !== LocalResult['member_id']) {
                    const url = GET_RECRUITER_URL + recruiter['user_id'];
                    const response = await axios.get(url, {
                        headers: {
                            Authorization: `Bearer ${auth?.token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    console.log('RECRUITER ALL ', response.data.recuiter);
                    return response.data.recuiter as Recruiter;
                }
            });

            const interviewers = await Promise.all(promises);

            // Remove duplicates based on user_id
            const uniqueInterviewers = Array.from(new Set(interviewers.map((interviewer) => interviewer.user_id)))
                .map((user_id) => interviewers.find((interviewer) => interviewer.user_id === user_id));

            const filteredInterviewers = uniqueInterviewers.filter((interviewer) => interviewer.user_id !== items.user_id);
            const updatedInterviewers = filteredInterviewers.filter((interviewer) => {
                // Check if user_id is present in panelMembers
                const matchingPanelMember = panelMembers.find((member) => member.member_id === interviewer.user_id);
                return !matchingPanelMember;
            });

            console.log({ updatedInterviewers });
            setInterviewers(updatedInterviewers);
        } catch (error) {
            console.log(error);
        }
    };

    const currentDate = dayjs(); // Get the current date
  console.log({currentDate})

    useEffect(() => {
        let localUser: any = localStorage.getItem("kt-auth-react-v");
        setItems(JSON.parse(localUser));
    }, []);

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue)
        setFormInputs((prevInputs) => ({
            ...prevInputs,
            interview_date: newValue,
        }));
        console.log(formInputs);
        console.log(value)
    }
    const handleChangeDuration = (event: SelectChangeEvent) => {
        setDuration(event.target.value as string)
    }

    const handleChangeDescription = (event) => {
        formInputs['description'] = event.target.value;
        setFormInputs(formInputs);
    }

    const onDescriptionChange = (value) => {
        setFormInputs((prevInputs) => ({
            ...prevInputs,
            description: value,
        }));
        console.log(formInputs);
    };

    console.log({ items })

    // const removeInterviewer = (event, index) => {

    //     let currentInterviewers = [...selectedInterviewers]

    //     currentInterviewers.splice(index, 1)
    //     setSelectedInterviewers(currentInterviewers)

    // }

    const removeInterviewer = (event, index) => {
        event.preventDefault();

        // Remove the selected panel member from the panelMembers array
        const updatedPanelMembers = selectedInterviewers.filter((_, i) => i !== index);

        // Update the state with the updated panel members
        setSelectedInterviewers(updatedPanelMembers);

        // Prepare the updated panel members data for handleSubmit
        const updatedPanelData = updatedPanelMembers.map(({ user_id, member_id, email }) => ({
            member_id: user_id || member_id,
            email: email,
            jobapp_id,
            index: '0',
            name: 'stage3',
            availability: 'false',
            member_type: 'panel',
            assigned_score: null,
            status: null,
        }));

        // Trigger the handleSubmit function with the updated panel members data
        // handleSubmit(updatedPanelData);
        const candResult = {
            jobapp_id,
            name: 'stage3',
            member_id: user_id,
            email: cand_email,
            index: '0',
            availability: 'false',
            member_type: 'candidate',
            assigned_score: null,
            status: null,
        };

        const jobPosterEmail = items.email;
        const jobPosterID = items.user_id;

        const LocalResult = {
            jobapp_id,
            name: 'stage3',
            member_id: jobPosterID,
            email: jobPosterEmail,
            index: '0',
            availability: 'false',
            member_type: 'job-poster',
            assigned_score: null,
            status: null,
        };

        console.log({ updatedPanelData })
        const updatedPanelResult = updatedPanelData.concat(candResult, LocalResult);
        console.log({ updatedPanelResult })

        setPanelName((prevPanelName) => ({ ...prevPanelName, result: updatedPanelResult }));
    };

    console.log({ selectedInterviewers })

    useEffect(() => {
        setFormInputs(data);
    }, []);

    const onInputChange = (event) => {
        const { name, value } = event.target;
        setFormInputs((prevInputs) => ({
            ...prevInputs,
            [name]: value,
        }));
    };


    useEffect(() => {
        if (items) {
            GetAllRecruiters();
        }
    }, [items]);

    useEffect(() => {
        GETALLPANELMEMBERS()
    }, [items])

    console.log('dsds', selectedInterviewers)
    console.log("PanelName", panelName)
    console.log({ result })
    console.log({ panelMembers })

    const [searchTerm, setSearchTerm] = useState<any>('');

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        if (!searchTerm) {
            setsearchRecord([]);
        } else {
            const filtered = interviewers?.filter((interviewer) => {
                if (!interviewer) {
                    return false;
                }
                const name = interviewer['first_name']?.toLowerCase();
                const id = interviewer['employee_id']?.toLowerCase();
                return name?.includes(searchTerm) || id?.includes(searchTerm);
            });
            setsearchRecord(filtered);
        }
    };

    const handleInterviewerSelect = (interviewer) => {
        setSearchTerm('');
        const userId = interviewer.user_id;
        const index = selectedInterviewers.findIndex((obj) => obj.user_id === userId);

        if (index === -1) {
            setSelectedInterviewers((prevInterviewers) => {
                const updatedInterviewers = [...prevInterviewers, interviewer];
                const name = updatedInterviewers.map((item) => item.name);
                const email = updatedInterviewers.map((item) => item.email);
                const member_id = updatedInterviewers.map((item) => item.user_id);

                const candResult = {
                    jobapp_id,
                    name: 'stage3',
                    member_id: user_id,
                    email: cand_email,
                    index: '0',
                    availability: 'false',
                    member_type: 'candidate',
                    assigned_score: null,
                    status: null,
                };

                const jobPosterEmail = items.email;
                const jobPosterID = items.user_id;

                const LocalResult = {
                    jobapp_id,
                    name: 'stage3',
                    member_id: jobPosterID,
                    email: jobPosterEmail,
                    index: '0',
                    availability: 'false',
                    member_type: 'job-poster',
                    assigned_score: null,
                    status: null,
                };
                console.log({ updatedInterviewers })
                const result = updatedInterviewers.map(({ user_id, member_id, email }) => ({
                    member_id: user_id || member_id,
                    email: email,
                    jobapp_id,
                    index: '0',
                    name: 'stage3',
                    availability: 'false',
                    member_type: 'panel',
                    assigned_score: null,
                    status: null,
                }));

                const updatedResult = result.concat(candResult, LocalResult);
                console.log({ updatedResult })
                setPanelName((prevPanelName) => ({ ...prevPanelName, result: updatedResult }));
                setPanelEmail((prevPanelEmail) => ({ ...prevPanelEmail, email }));
                setPanelId((prevPanelId) => ({ ...prevPanelId, member_id }));

                return updatedInterviewers;
            });
        }

        setSearchTerm('');
        setsearchRecord([]);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex < searchRecord.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        }
    };

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            width='104% !important'
            dialogClassName='modal-dialog modal-dialog-centered mw-700px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className=' no-header-model1234'>
                <div className='modal-header no-header-model123'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            marginLeft: '15rem',
                        }}
                    >
                        <img src={interviewheadericon} style={{ height: '4vh', marginLeft: '2rem' }} />
                        <h2 className='interview-header-container-text'>Reschedule Interview</h2>
                    </div>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
                        <img alt='' src={Layout}></img>
                    </div>
                </div>
                {/* end::Close */}
                <div className='mx-auto d-flex flex-column '>
                    <div className='profile-Container'>
                        <div className='left-container'>
                            <div>
                                <img src={profile_pic ? profile_pic : UserIcon} className='profile-user-icon' />
                            </div>
                            <div className='middle-container-user-details'>
                                <label className='middle-container-username'>{name}</label>
                                <div className='middle-container-role'>
                                    <label>Position: {position}</label>
                                </div>
                            </div>
                        </div>
                        <div className='right-container'>
                            <div className='right-container-img'>
                                <img src={ExprienceIcon} style={{ width: '60%' }} />
                            </div>
                            <div>
                                <label className='right-container-text' style={{ whiteSpace: 'nowrap' }}>
                                    Banking Experience :<span className='right-container-span' style={{ whiteSpace: 'nowrap' }}>{bexp} {' '}yrs</span>
                                </label>
                                <label className='right-container-text' style={{ whiteSpace: 'nowrap' }}>
                                    Total Experience :<span className='right-container-span' style={{ whiteSpace: 'nowrap' }}>{(parseFloat(bexp) + parseFloat(jexp)).toFixed(2)}{' '}yrs</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal-body py-lg-8 px-lg-8'>
                <div className='card'>
                    {/* begin::Beader */}

                    <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                        <form
                            onSubmit={handleSubmit}
                        >
                            <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                                {/* <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label> */}
                                <div className='Input-Field-Container'>
                                    <div>
                                        <img src={Usericon} className='slider-icon' />
                                        <hr className='hr-line-first-child'></hr>
                                        <img src={Timericon} className='slider-icon' />
                                        <hr className='hr-line-second-child'></hr>
                                        <img src={Copyicon} className='slider-icon' />
                                        <hr className='hr-line-third-child'></hr>
                                        <img src={Descriptionicon} className='slider-icon' />
                                    </div>

                                    <div>
                                        <div className='upper-input-container'>
                                            <div className="input-container-upper-row" style={{ position: 'relative' }}>
                                                <Box sx={{ width: 400 }}>
                                                    <FormControl fullWidth>
                                                        <div style={{ overflow: 'hidden' }}>
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Interview Panelist Name/ Employee ID"
                                                                className="request-approval-final-step-input-field"
                                                                onChange={handleSearch}
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                            {searchRecord.length > 0 ? (
                                                                <ul
                                                                    style={{
                                                                        border: '1px solid lightgrey',
                                                                        padding: '10px',
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        gap: '10px',
                                                                        zIndex: '2',
                                                                        backgroundColor: 'white',
                                                                        position: 'absolute',
                                                                        top: '100%',
                                                                        left: 0,
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {searchRecord.map((interviewer, index) => (
                                                                        <li
                                                                            key={interviewer['id']}
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                gap: '10px',
                                                                                alignItems: 'center',
                                                                                cursor: 'pointer',
                                                                                backgroundColor: highlightedIndex === index ? '#FFEBCE' : 'initial',
                                                                                padding: '5px',
                                                                                transition: 'background-color 0.3s',
                                                                            }}
                                                                            onClick={() => handleInterviewerSelect(interviewer)}
                                                                            onMouseEnter={(e) => {
                                                                                (e.target as HTMLElement).style.backgroundColor = '#FFEBCE';
                                                                            }}
                                                                            onMouseLeave={(e) => {
                                                                                (e.target as HTMLElement).style.backgroundColor = highlightedIndex === index ? '#FFEBCE' : 'initial';
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={interviewer['profile_pic'] !== null ? interviewer['profile_pic'] : UserIcon}
                                                                                className="selected-interviewer-icon-sched"
                                                                                alt=""
                                                                            />
                                                                            {interviewer['first_name']}
                                                                            <hr />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                searchTerm !== '' && <p>No results found.</p>
                                                            )}
                                                        </div>
                                                    </FormControl>
                                                </Box>
                                                <div className="user-tile">
                                                    {selectedInterviewers ? (
                                                        selectedInterviewers
                                                            .filter(member => member.member_id !== items.user_id && member.member_type !== 'candidate')
                                                            .map((option, index) => (
                                                                <label className="user-tile-non-content" key={option.id}>
                                                                    <img
                                                                        src={option.profile_pic !== null || option.profilePic !== null ? option.profile_pic || option.profilePic : UserIcon}
                                                                        className="selected-interviewer-icon-sched"
                                                                        alt=""
                                                                    />
                                                                    {option.first_name || option.userName}
                                                                    <span>
                                                                        <img
                                                                            alt=""
                                                                            src={Layout}
                                                                            onClick={(event) => removeInterviewer(event, index)}
                                                                            id={JSON.stringify(option)}
                                                                        ></img>
                                                                    </span>
                                                                </label>
                                                            ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>

                                                {/* <div className="user-tile">
                                                    {panelMembers ? (
                                                        panelMembers.map((option, index) => (
                                                            <label className="user-tile-content" key={option.id}>
                                                                <img
                                                                    src={option.profilePic !== null ? option.profilePic : UserIcon}
                                                                    className="selected-interviewer-icon-sched"
                                                                    alt=""
                                                                />
                                                                {option.userName}
                                                                <span>
                                                                    <img
                                                                        alt=""
                                                                        src={Layout}
                                                                        onClick={(event) => removeInterviewer(event, index)}
                                                                        id={JSON.stringify(option)}
                                                                    ></img>
                                                                </span>
                                                            </label>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div> */}
                                            </div>

                                            <div className='inner-input-contaier'>
                                                <div className='upper-input-row-container'>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={3}>
                                                            <Box sx={{ width: 228 }}>
                                                                <DesktopDatePicker
                                                                    inputFormat='MM/DD/YYYY'
                                                                    value={formInputs['interview_date']}
                                                                    minDate={currentDate}
                                                                    onChange={handleChange}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} name='selectedDate' />
                                                                    )}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </LocalizationProvider>

                                                    <Box
                                                        sx={{
                                                            width: 160,
                                                        }}
                                                    >
                                                        <TextField
                                                            id="time"
                                                            label='Time'
                                                            type="time"
                                                            name="interview_time"
                                                            value={formInputs['interview_time'] || ''}
                                                            onChange={onInputChange}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                step: 300, // 5 min
                                                            }}
                                                        />
                                                    </Box>
                                                </div>

                                                <Box
                                                    sx={{
                                                        width: 228,
                                                    }}
                                                >
                                                    <FormControl fullWidth>
                                                        <InputLabel id='demo-simple-select-label'> minutes</InputLabel>
                                                        <Select
                                                            labelId='demo-simple-select-label'
                                                            id='demo-simple-select'
                                                            label='Duration'
                                                            name="duration"
                                                            required
                                                            value={formInputs.duration || ''}
                                                            onChange={onInputChange}
                                                        >
                                                            <MenuItem value={10}>10 minutes</MenuItem>
                                                            <MenuItem value={20}>20 minutes</MenuItem>
                                                            <MenuItem value={30}>30 minutes</MenuItem>
                                                            <MenuItem value={40}>40 minutes</MenuItem>
                                                            <MenuItem value={50}>50 minutes</MenuItem>
                                                            <MenuItem value={60}>60 minutes</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>

                                            <div className='input-link-container'>
                                                <div className='schedule-interview-address' style={{ marginBottom: '1rem' }}>
                                                    <div className='schedule-interview-address-buttons'>
                                                        <input
                                                            type='radio'
                                                            value="Online Interview"
                                                            name='interview'
                                                            className='schedule-interview-checkbox'
                                                            onChange={onInputChange}
                                                            checked={formInputs?.interview === 'Online Interview'}
                                                        />
                                                        <label>Online Interview</label>
                                                    </div>

                                                    <div className='schedule-interview-address-buttons'>
                                                        <input
                                                            type='radio'
                                                            value="Onsite Interview"
                                                            name='interview'
                                                            className='schedule-interview-checkbox'
                                                            onChange={onInputChange}
                                                            checked={formInputs?.interview === 'Onsite Interview'}
                                                        />
                                                        <label>Onsite Interview</label>
                                                    </div>

                                                </div>
                                                <Box
                                                    sx={{
                                                        width: 391,
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        name='invitation_link'
                                                        required
                                                        onChange={onInputChange}
                                                        value={formInputs?.invitation_link}
                                                    // placeholder={formInputs?.invitation_link}
                                                    />
                                                </Box>
                                            </div>

                                            <div className='input-description-container'>
                                                <Box
                                                    sx={{
                                                        width: 391,
                                                    }}
                                                >
                                                    <TextField
                                                        fullWidth
                                                        label='Description'
                                                        onChange={onDescriptionChange}
                                                        required
                                                        InputLabelProps={{ style: { fontWeight: 'bold', color: '#373D4A' } }}
                                                        disabled
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: 391,
                                                        height: 80,
                                                    }}
                                                >
                                                    {/* <TextField
                                                        fullWidth
                                                        defaultValue={data?.description}
                                                        name='description'
                                                        onChange={onInputChange}
                                                        multiline
                                                        required
                                                        rows={5}
                                                    /> */}

                                                    <ReactQuill
                                                        value={formInputs?.description}
                                                        // onChange={handleChangeDescription}
                                                        onChange={onDescriptionChange}
                                                        // name="description"
                                                        placeholder="Enter description"
                                                        modules={{
                                                            toolbar: [
                                                                [{ header: [1, 2, 3, 4, false] }],
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{ color: [] }, { background: [] }],
                                                                [{ align: [] }],
                                                                ['link'
                                                                    // , 'image'
                                                                    // , 'video'
                                                                ],
                                                                ['clean'],
                                                            ],
                                                        }}
                                                        formats={[
                                                            'header',
                                                            'bold',
                                                            'italic',
                                                            'underline',
                                                            'strike',
                                                            'color',
                                                            'background',
                                                            'align',
                                                            'link',
                                                            // 'image',
                                                            // 'video',
                                                        ]}
                                                        style={{ height: '90px' }}
                                                    />

                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='inputfield-lower-container'>
                                <img src={InformationIcon} style={{width: '3%'}} />
                                <label className='inputfield-lower-container-text'>
                                    By clicking on send the above interview details will be sent to Candidate & all
                                    Panel Member via email
                                </label>
                            </div>
                            <div className='inputfield-button-container'>
                                {!loading ? (
                                    <input className='inputfield-btn' type='submit' name='Send' value='Submit' onClick={handleSubmit} />
                                ) : (
                                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size="2x" />
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { ReScheduleInterviewStage3 }