import React, { createContext, useState, useEffect } from 'react';

export const TestQuestionDataContext = createContext({
  testQuestionsData: [{}],
  setTestQuestionsData: (testQuestionsData) => {}
});


export const TestQuestionDataProvider = ({ children }) => {
  const [testQuestionsData, setTestQuestionsData] = useState([{}]);

  return (
    <TestQuestionDataContext.Provider value={{ testQuestionsData, setTestQuestionsData }}>
      {children}
    </TestQuestionDataContext.Provider>
  );
};
