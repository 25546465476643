import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import imagePath from '../../../../constants/imagePath';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type Props = {
    handleClose: () => void
    handleAddTerms: (termsName: string, description: string) => void
    editTerms: (termsObj: any) => void
    type: string
    data: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddUpdateTermsPopup = ({ handleClose, handleAddTerms, editTerms, type, data }: Props) => {
    const [termsName, setTermsName] = useState(type === 'edit' ? data.name : '');
    const [termsAndConditions, setTermsAndConditions] = useState(type === 'edit' ? data.description : '');

    const handleTermsNameChange = (e) => {
        setTermsName(e.target.value);
    };

    const changeTermsConditions = (content) => {
        if (content) {
            setTermsAndConditions(content);
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-800px'
            show={true}
            backdrop={true}
        >

            <div className=' no-header-model123 px-6 py-4 position-relative'>
                <div className='d-flex align-items-center mx-6'>
                    <label className='fs-3 fw-bolder'>{`${type === 'add' ? 'Add Terms And Conditions' : 'Edit Terms And Conditions'}`}</label>
                    <img className='ms-auto cursor-pointer' src={imagePath.ic_close} onClick={handleClose} />
                </div>
                <div className='mx-6'>
                    <form>
                        <div className="mt-8 col-md-12">
                            <div className="d-flex flex-column">
                                <label htmlFor="eduName" className="form-label fs-6" style={{ color: '#373D4A' }}>Title<span style={{ color: 'red' }}>*</span></label>
                                <div className="col-md-6 p-0">
                                    <input
                                        type="text"
                                        className="form-control"
                                        style={{ height: '40px' }}
                                        placeholder='Terms and conditions'
                                        id="eduName"
                                        value={termsName}
                                        onChange={handleTermsNameChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <label htmlFor="eduName" className="form-label fs-6 mt-6" style={{ color: '#373D4A' }}>Description<span style={{ color: 'red' }}>*</span></label>
                        <ReactQuill
                            value={termsAndConditions}
                            onChange={changeTermsConditions}
                            placeholder="Enter your job description"
                            modules={{
                                toolbar: [
                                    [{ header: [1, 2, 3, 4, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ color: [] }, { background: [] }],
                                    [{ align: [] }],
                                    [{ list: 'ordered' }, { list: 'bullet' }],
                                    ['link'],
                                    ['clean'],
                                ],
                            }}
                            formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'color',
                                'background',
                                'align',
                                'list',
                                'bullet',
                                'link',
                            ]}
                            style={{ height: '220px', marginBottom: '60px' }}
                        />
                        <button
                            onClick={() => {
                                if (type === 'add') {
                                    handleAddTerms(termsName, termsAndConditions)
                                } else {
                                    data.name = termsName;
                                    data.description = termsAndConditions;
                                    editTerms(data)
                                }
                            }}
                            type='button'
                            className='d-flex btn px-18 py-2 apply-review-button mb-8 mt-10 mx-auto'
                            style={{ color: 'white' }}
                            disabled={!termsName || !termsAndConditions}
                        >
                            Save
                        </button>
                    </form>
                </div>
            </div>

        </Modal>,
        modalsRoot
    );
};

//make this component available to the app
export default AddUpdateTermsPopup;
