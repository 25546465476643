//import liraries
import React, {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './ModelStyleDoc.css'
import Layout from '../images/Layout-4-blocks (3).png'
import icPreviousGrey from './img/ic_previous_grey.png'
import icPreviousOrange from './img/ic_previous_orange.png'
import icNextGrey from './img/ic_next_grey.png'
import icNextOrange from './img/ic_next_orange.png'
import frontBg from './img/employee_card_front_bg.png'
import backBg from './img/employee_card_back_bg.png'
import icPhone from './img/ic_phone_orange@3x.png'
import icEmail from './img/ic_email_orange@3x.png'
import icCard from './img/ic_card@3x.png'
import icAddress from './img/ic_pin@3x.png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import {useRef, RefObject} from 'react'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
const GET_EMPLOYEE_CARD_URL = `${API_URL}/jobapplication/get-employee-card-data`

const modalsRoot = document.getElementById('root-modals') || document.body

type Props = {
  show: boolean
  jobAppId: string
  handleClose: () => void
}
// create a component
const EmployeeCardPopUp = ({show, jobAppId, handleClose}: Props) => {
  const {auth} = useAuth()
  const {currentUser} = useAuth()
  const [activeIndex, setActiveIndex] = useState(0)
  const [candidateData, setCandidateData] = useState<any>()
  const [jobData, setJobData] = useState<any>()
  // const frontSideRef: RefObject<HTMLDivElement> = useRef(null);
  // const backSideRef: RefObject<HTMLDivElement> = useRef(null);

  const frontSideRef = useRef(null)
  const backSideRef = useRef(null)

  const getEmployeeCardData = async () => {
    let data = {
      jobapp_id: jobAppId,
    }
    axios
      .post(GET_EMPLOYEE_CARD_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        console.log('employeeCardData: ', res.data)
        let data = res.data
        setCandidateData(data?.candidateData)
        setJobData(data?.jobData)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    ;(async () => {
      getEmployeeCardData()
    })()
  }, [])

  const handleNext = () => {
    if (activeIndex == 0) {
      setActiveIndex(1)
    }
  }

  const handlePrevious = () => {
    if (activeIndex === 1) {
      setActiveIndex(0)
    }
  }

  const downloadEmployeeCard = (event) => {
    event.preventDefault()
    const frontSideElement: any = frontSideRef.current
    const backSideElement: any = backSideRef.current
    const element = activeIndex === 0 ? frontSideElement : backSideElement
    // if (frontSideElement && backSideElement) {
    Promise.all([
      html2canvas(element),
      // html2canvas(backSideElement)
    ]).then((canvasArray) => {
      const frontSideCanvas = canvasArray[0]
      // const backSideCanvas = canvasArray[1];

      // Create a new jsPDF instance
      const pdf = new jsPDF('p', 'mm', 'a4')

      // Calculate the dimensions and positions for front-side and back-side
      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()
      const frontSideWidth = pageWidth
      const frontSideHeight = pageHeight
      const backSideWidth = pageWidth
      const backSideHeight = pageHeight

      // Add front-side to PDF
      pdf.addImage(frontSideCanvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, frontSideWidth, frontSideHeight)

      // Add a new page for back-side
      // pdf.addPage();
      // Add back-side to PDF
      // pdf.addImage(backSideCanvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, backSideWidth, backSideHeight);

      // Download the PDF
      pdf.save('employee_card.pdf')
    })
    // } else {
    //     console.error('Invalid element provided');
    // }
  }

  const printEmployeeCard = () => {
    const frontSideElement: any = frontSideRef.current
    const backSideElement: any = backSideRef.current

    // Select the front side or back side based on the activeIndex
    const element = activeIndex === 0 ? frontSideElement : backSideElement

    if (element) {
      const printWindow = window.open('', '', 'width=600,height=1200')
      if (printWindow) {
        printWindow.document.open()
        // printWindow.document.write('<html><head><title>Employee Card</title></head><body>');
        printWindow.document.write(element.innerHTML)
        // printWindow.document.write('</body></html>');
        printWindow.document.close()
        printWindow.print()
        printWindow.close()
      } else {
        console.error('Failed to open a new window for printing.')
      }
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={show}
      //   onHide={handleClose}
      backdrop={true}
    >
      <>
        <div className=' ' style={{borderRadius: '8px'}}>
          <div className='modal-header no-header-model123'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={Layout}></img>
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column '>
            <h3 className='form-label fw-bolder fs-4 mx-auto' style={{color: '#484848'}}>
              Employee ID Generation
            </h3>
          </div>
        </div>

        <div className='modal-body py-lg-8 px-lg-8'>
          <div className='d-flex  align-items-center '>
            <div id='previous-btn'>
              <img
                style={{cursor: 'pointer'}}
                onClick={handlePrevious}
                // disabled={activeIndex === 0}
                src={activeIndex === 0 ? icPreviousGrey : icPreviousOrange}
              />
            </div>

            <div id='content-div' style={{flex: '1'}}>
              {activeIndex === 0 ? (
                <div id='front-side' ref={frontSideRef} style={{display: 'flex', flex: '1', position: 'relative'}}>
                  <img id='bg-img' src={frontBg} style={{marginLeft: 'auto', marginRight: 'auto'}} />
                  <div
                    id='card-content'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={candidateData?.profile_pic}
                      style={{width: '56px', height: '56px', borderRadius: '28px', objectFit: 'cover'}}
                    />
                    <label className='form-label fw-bolder fs-3 mt-2'>{`${candidateData?.first_name} ${candidateData?.last_name}`}</label>
                    <label className='form-label fw-normal fs-7'>{`Employee ID: ${candidateData?.employee_id}`}</label>
                    <label
                      className='form-label fw-normal fs-7 p-1'
                      style={{borderRadius: '4px', backgroundColor: '#F3F6F9', color: '#80808F'}}
                    >{`${jobData?.title}`}</label>
                    <div>
                      <div className='d-flex align-items-center'>
                        <img className='me-2' src={icPhone} width={16} height={16} style={{objectFit: 'cover'}} />
                        <label className='form-label fw-normal fs-7 mt-2'>{candidateData?.phone_number}</label>
                      </div>
                      <div className='d-flex align-items-center'>
                        <img className='me-2' src={icEmail} width={16} height={16} style={{objectFit: 'cover'}} />
                        <label className='form-label fw-normal fs-7'>{candidateData?.email}</label>
                      </div>
                      <div className='d-flex align-items-center'>
                        <img className='me-2' src={icCard} width={16} height={16} style={{objectFit: 'cover'}} />
                        <label className='form-label fw-normal fs-7'>{candidateData?.cnic}</label>
                      </div>
                      <div className='d-flex align-items-center '>
                        <img className='me-2' src={icAddress} width={16} height={16} style={{objectFit: 'cover'}} />
                        <label className='form-label fw-normal fs-7'>{candidateData?.address}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div id='back-side' ref={backSideRef} style={{display: 'flex', flex: '1', position: 'relative'}}>
                  <img id='bg-img' src={backBg} style={{marginLeft: 'auto', marginRight: 'auto'}} />
                  <div
                    id='card-content'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <label className='form-label fw-normal fs-8 mt-10' style={{maxWidth: '280px'}}>
                      This ID card is property of Bank of Punjab, Employee must carry this card while on duty. Loss of this card must be
                      reported immediately to Security Department This card is non-transferable.
                    </label>
                    <label className='form-label fw-normal fs-7' style={{maxWidth: '280px'}}>
                      {' '}
                      At the end of employee's service this card is to be returned to Security Department, The Bank of Punjab.
                    </label>
                    <div style={{maxWidth: '280px', textAlign: 'left'}}>
                      <label className='form-label fw-normal fs-7'>{`If found, please return to The Bank of Punjab BOP`} </label>
                      {/* <label className='form-label fw-normal fs-7'>The Bank of Punjab BOP</label> */}
                      <label className='form-label fw-normal fs-7'>Tower, 10-B, Block E-||</label>
                      <label className='form-label fw-normal fs-7'>Main Boulevard Gullberg |||. Lahore</label>
                      <label className='form-label fw-normal fs-7'>Tel: 042-35783700-10</label>
                    </div>
                  </div>
                </div>
              )}

              <div className='d-flex' style={{justifyContent: 'center', alignItems: 'center'}}>
                <button className='fw-bolder px-8 req-btn me-4' onClick={(event) => downloadEmployeeCard(event)}>
                  Download
                </button>
                <button className='fw-bolder px-8 req-btn' onClick={printEmployeeCard}>
                  Print
                </button>
              </div>
            </div>
            <div id='next-btn'>
              <img
                style={{cursor: 'pointer'}}
                onClick={handleNext}
                // disabled={activeIndex === requiredDocs.length - 1}
                src={activeIndex === 1 ? icNextGrey : icNextOrange}
              ></img>
            </div>
          </div>
        </div>
        {/* {loading && (
                    <LoaderView
                        message='Loading...'
                    />
                )} */}
      </>
    </Modal>,
    modalsRoot
  )
}

//make this component available to the app
export default EmployeeCardPopUp
