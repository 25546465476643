//import liraries
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/core/Auth';
import axios from 'axios';
import Carousel from 'react-bootstrap/Carousel';

const API_URL = process.env.REACT_APP_API_URL;
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

// create a component
const BannerView = () => {
    const { currentUser, auth, logout } = useAuth();
    const [configurationId, setConfigurationId] = useState<any>('');
    const [bannerList, setBannerList] = useState<any>();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        (async () => {
            getConfigurations();
        })();
    }, []);

    const getConfigurations = async () => {
        await axios.get(GET_CONFIGURATIONS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res?.data?.Configuration;
            setConfigurationId(data?.configuration_id);
            let banners = data?.marketing_creatives;
            if (banners) {
                banners = JSON.parse(banners);
                const initialData = Object.keys(banners).map((key) => ({
                    link: banners[key]['name'],
                    file: null,
                    fileUrl: banners[key]['link']
                }));
                setBannerList(initialData);
                console.log('banersData: ', initialData);
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    return (
        <div >
            <Carousel style={{borderRadius:'10px'}}>
                {bannerList && bannerList.map((banner, index) => (
                    <Carousel.Item interval={5000} key={index}>
                        <a href={banner.link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={banner.fileUrl}
                                alt={`Slide ${index + 1}`}
                                style={{ width: '100%', height: 'auto', borderRadius:'10px', objectFit:'cover' }}
                            />
                        </a>
                        <Carousel.Caption>
                            {/* Add caption content if needed */}
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};


//make this component available to the app
export default BannerView;
