import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/auth`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const CANDIDATE_CV_CHECK = `${API_URL}/candidate/cv-value-check`

// Server should return AuthModel
export function login(email, password, credential) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    credential
  })
}

export function cvcheck(user_id, token) {
  return axios.post(
    CANDIDATE_CV_CHECK,
    {
      candidate_id: user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
}

// Server should return AuthModel
// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string,
//   social_auth: boolean,
//   role: string
// ) {
//   return axios.post(REGISTER_URL, {
//     email,
//     first_name: firstname,
//     last_name: lastname,
//     password,
//     password_confirmation,
//     social_auth,
//     role,
//   })
// }

// Server should return AuthModel
export function register(
  email: string,
  password: string,
  social_auth: boolean,
  role: string,
  credential: string
) {
  return axios.post(REGISTER_URL, {
    email,
    password,
    social_auth,
    role,
    credential,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  const instance = axios.create({
    baseURL: GET_USER_BY_ACCESS_TOKEN_URL,
    timeout: 10000,
    headers: {Authorization: 'Bearer ' + token},
  })

  return instance.get('/me')
}