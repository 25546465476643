//import liraries
import React, { useEffect, useState } from 'react';
import '../../../../_metronic/partials/modals/JobFunnelsModels/ModelStyles.css'
import '../../../../_metronic/partials/modals/JobFunnelsModels/Success.css';
import warningImg from '../../../../_metronic/partials/images/warning@2x.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SignupSuccessPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/SignupSuccessPopup';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL
export const VERIFY_ACCOUNT_URL = `${API_URL}/auth/account/verify-link/`

// create a component
const AccountNotVerified = () => {
    const [showPopup, setShowPopup] = useState(false);
    const { email } = useParams();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    const navigate = useNavigate();
    const handleSubmit = () => {
        // redirect to login screen and remove current page from back stack
        // navigate('/auth/login', { replace: true });

        let url = VERIFY_ACCOUNT_URL + email;
        axios.get(url).then((res) => {
            setShowPopup(true);
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                // logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                // showToast(error.response.data.error.message);
                setMessage(error.response.data.error.message);
                setShowErrorMessage(true);
                // handleClose();
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })

        // setShowPopup(true);
    }

    return (
        <div className='signup-success-bg'>
            <div className='popup-content shadow'>
                <div className=' mt-12'>
                    <div className=' d-flex flex-column '>
                        <img alt='' style={{ width: '90px', height: '180', objectFit: 'contain', marginLeft: 'auto', marginRight: 'auto' }} src={warningImg}></img>
                        <h3 className='fw-bolder mx-auto my-5' style={{ color: '#FFFFFF' }}>Account not Verified!</h3>
                        <p className=' mx-auto my-5 text-style col-md-10 ' style={{ color: '#FFFFFF' }}>Your account needs to be verified in order to login</p>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <form
                    //  onSubmit={handleSubmit}
                    >
                        <div className='btn-container'>
                            <button
                                onClick={handleSubmit}
                                type='button'
                                className='btn px-10 apply-review-button'
                            >
                                Send Verification Link Again
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {showPopup && (
                <SignupSuccessPopup
                    show={true}
                    handleClose={() => {
                        setShowPopup(false);
                        navigate('/login');
                    }}
                    message={'A verification link has been sent to your email address, please click on the link to verify your email address'}
                />
            )}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default AccountNotVerified;
