//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import imagePath from '../../../../constants/imagePath';

type Props = {
    handleClose: () => void
    handleYesSure: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
// create a component
const EndTestWarningPopup = ({ handleClose, handleYesSure }: Props) => {
    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <h3 className='mx-auto fw-bolder pb-10' style={{ color: '#F36523' }}>Do you want to cancel test?</h3>
                            <img alt='' className='add-warning-image' src={imagePath.ic_warning_orange} ></img>
                            <h4 className='fw-semibold mx-auto mt-8 px-18' style={{ textAlign: 'center' }}>
                                You would not be able to start the test again if session expired.
                            </h4>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div style={{ width: '30%' }}></div>
                                        <div style={{ width: '40%' }} className='d-flex align-items-center justify-content-center'>
                                            <button onClick={handleClose} className='btn px-8 py-5 fs-4 candi-btn2 text-white fw-bolder '>
                                                GO BACK
                                            </button>
                                        </div>
                                        <div style={{ width: '30%' }}>
                                            <button
                                                onClick={handleYesSure}
                                                type='button'
                                                style={{ backgroundColor: '#FF8750' }}
                                                className='btn px-5 py-3 fs-6 text-white'
                                            >
                                                END TEST
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default EndTestWarningPopup;
