import React, { useEffect, useRef, useState } from 'react';
import imagePath from '../../../constants/imagePath';
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import { useAuth } from '../auth/core/Auth';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';

const modalsRoot = document.getElementById('root-modals') || document.body;


const API_URL = process.env.REACT_APP_API_URL
const UPLOAD_VIDEO_URL = `${API_URL}/videos`
const GET_VIDEOS = `${API_URL}/videos`
const UPDATE_QUESTION_URL = `${API_URL}/questions/`
const GET_ALL_QUESTIONS = `${API_URL}/questions`

const VideoUploader = ({ onClose, questionData, videoData, updateQuestionsList, ...porps }) => {
    const { auth, logout } = useAuth()
    const [question, setQuestion] = useState<any>();
    const [questionText, setQuestionText] = useState('');
    const [competency, setCompetency] = useState<any>();
    const [questionTime, setQuestionTime] = useState<any>();
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [videoId, setVideoId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dragging, setDragging] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [allVideos, setAllVideos] = useState<any>();
    const [searchText, setSearchText] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [searchQuestionText, setSearchQuestionText] = useState('');
    const [isQuestionFocused, setIsQuestionFocused] = useState(false);
    const [allQuestions, setAllQuestions] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        (() => {
            if (questionData) {
                setQuestion(questionData);
                setQuestionText(questionData?.text);
                setSearchQuestionText(questionData?.text);
                setCompetency(questionData?.competency);
                setVideoUrl(questionData?.link);
                setQuestionTime(questionData?.allowed_time);
                setVideoId(questionData?.video_id);
            }
            if (videoData) {
                setVideoId(videoData?.video_id);
                setVideoUrl(videoData?.link);
                setSearchText(videoData?.name);
            }
        })();
    }, []);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);

    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setDragging(false);
        const files = e.dataTransfer.files;

        if (files.length > 0) {
            const file = files[0];
            handleFileUpload(file);
        } else {
            setMessage('File not found, Please try again');
            setShowErrorMessage(true);
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileUpload = (file) => {
        // e.preventDefault();
        // const file = e.target.files[0];

        const validFormats = ['video/mp4', 'video/avi', 'video/webm', 'video/quicktime', 'video/x-matroska'];
        if (file
            // && validFormats.includes(file.type)
        ) {
            uploadQuestionVideo(file);

        } else {
            setMessage('Invalid file. Please select or drop a valid video file (MP4, AVI, WebM, MOV, MKV).');
            setShowErrorMessage(true);
            return;
        }
    }

    const uploadQuestionVideo = (file) => {
        if (!file) {
            setMessage('Video file not found')
            setShowErrorMessage(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file, 'Question video');
        setIsLoading(true);
        axios.post(UPLOAD_VIDEO_URL, formData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            }
        }).then((res) => {
            setIsLoading(false);
            setVideoUrl(res?.data?.video?.link);
            setVideoId(res?.data?.video?.video_id);
        }).catch((error) => {
            setIsLoading(false);
            displayErrorMessage(error);
        });
    }

    const displayErrorMessage = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response?.data?.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }

    const handlePaperclipClick = () => {
        const videoInput = document.getElementById('videoInput');
        if (videoInput) {
            videoInput.click();
        }
    };

    const handleRemoveVideo = () => {
        setVideoUrl(null);
        setVideoId('');
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleFocusQuestion = () => {
        setIsQuestionFocused(true);
    }

    const handleQuestionClick = (questionData) => {
        if (questionData) {
            setQuestion(questionData);
            setQuestionText(questionData?.text);
            setCompetency(questionData?.competency);
            // setVideoUrl(questionData?.link);
            setQuestionTime(questionData?.allowed_time);
            // setVideoId(questionData?.video_id);
            setSearchQuestionText(questionData?.text);
        }
        setIsQuestionFocused(false);
    }

    const handleChangeSearchQuestion = async (e) => {
        let keyWord = e.target.value;
        setSearchQuestionText(keyWord);
        if (keyWord && keyWord.length >= 3) {
            let data = {
                order: 'CREATED_AT',
                sort: 'desc',
                page: 1,
                limit: 10,
                total: 0,
                search: keyWord
            }
            const filteredParams = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
            )
            await axios.get(GET_ALL_QUESTIONS, {
                params: filteredParams,
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {

                setAllQuestions(res?.data?.data);

            }).catch((error) => {
                displayErrorMessage(error);
            });
        }
        if (keyWord === null || keyWord === undefined || keyWord.length === 0) {
            setIsQuestionFocused(false);
        }
    }

    const handleChangeSearch = (e) => {
        let keyWord = e.target.value;
        setSearchText(keyWord);
        if (keyWord && keyWord.length >= 3) {
            let data = {
                order: 'CREATED_AT',
                sort: 'desc',
                page: 1,
                limit: 10,
                total: 0,
                search: keyWord
            }
            const filteredParams = Object.fromEntries(
                Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
            )
            axios.get(GET_VIDEOS, {
                params: filteredParams,
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'content-type': 'multipart/form-data',
                }
            }).then((res) => {
                setAllVideos(res?.data?.data);
            }).catch((error) => {
                displayErrorMessage(error);
            });
        }

        if (keyWord === null || keyWord === undefined || keyWord.length === 0) {
            setIsFocused(false);
        }
    }

    const handleVideoClick = (video) => {
        setVideoUrl(video?.link);
        setSearchText(video?.name);
        setIsFocused(false);
    }

    const handleUpdateQuestion = (e) => {
        e.preventDefault();

        if (questionTime === null || questionTime === undefined || questionTime === '' || questionTime === 0) {
            setMessage('Invalid question time!');
            setShowErrorMessage(true);
            return;
        }
        let text = questionText?.trim();
        if (text === null || text === undefined || text === '') {
            setMessage('Question text could not be empty.');
            setShowErrorMessage(true);
            return;
        }

        let data = {
            organization_id: 3,
            competency_id: question?.competency_id,
            text: question?.text,
            allowed_time: questionTime,
            type: "MCQ",
            link: videoUrl,
            video_id: videoId,
            complexity: 1,
            score: 1,
            answers: question?.answers
        }
        axios.put(UPDATE_QUESTION_URL + question?.question_id, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            }
        }).then((res) => {

            // close the popup and show success popup and update the list of questions 
            updateQuestionsList();
        }).catch((error) => {

            displayErrorMessage(error);
        });

    }

    const handleChangeQuestionTime = (e) => {
        setQuestionTime(e.target.value)
    }

    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={true}
            backdrop={true}
            style={{ marginLeft: '-70px', overflow: 'auto', scrollbarWidth: 'none' }}
        >
            <div style={{ width: '750px', padding: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
                <form action="">
                    <div style={{ display: 'flex' }}>
                        <span style={{ fontSize: '20px', fontWeight: '600', color: '#383838' }}>Link Question to Video</span>
                        <img onClick={onClose} src={imagePath.ic_close} style={{ marginLeft: '680px', position: 'absolute', marginTop: '-5px', cursor: 'pointer' }} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <div className='mt-8 d-flex align-items-center'>
                                <label style={{ width: '120px' }} htmlFor="">Question</label>
                                {/* <input className='form-control py-2 px-3 ms-auto' style={{ width: '14rem' }}
                                    type="text"
                                    placeholder="Question"
                                    value={questionText}
                                    readOnly
                                /> */}

                                <div className='d-flex flex-column ms-auto' style={{ position: 'relative' }}>
                                    <input className='form-select py-2 px-3 ms-auto pe-10'
                                        style={{ width: '14rem' }}
                                        type="text"
                                        placeholder="Search Question"
                                        value={searchQuestionText}
                                        onChange={handleChangeSearchQuestion}
                                        onFocus={handleFocusQuestion}
                                        readOnly={!!questionData}
                                        disabled={!!questionData}
                                    />
                                    {searchQuestionText && isQuestionFocused && allQuestions && (
                                        <div className='px-4 py-2' style={{ marginTop: '5px', zIndex: 2, maxHeight: '150px', overflowY: 'auto', position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', borderRadius: '6px', border: '1px solid #B2B2B2' }}>
                                            {allQuestions.map((item, index) => (
                                                <div key={index} onClick={() => handleQuestionClick(item)} style={{ cursor: 'pointer' }}>
                                                    <label style={{ whiteSpace: 'nowrap', cursor: 'pointer', marginBottom: '8px' }}>{item?.text}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className='mt-8 d-flex align-items-center'>
                                <label style={{ width: '150px' }} htmlFor="">Competency</label>
                                <input className='form-control py-2 px-3 ms-auto' style={{ width: '14rem' }}
                                    type="text"
                                    placeholder="Competency"
                                    value={competency?.name}
                                    readOnly
                                />
                            </div>
                            <div className='mt-8 d-flex align-items-center'>
                                <label style={{ width: '100px' }} htmlFor="">time (Sec)</label>
                                <input className='form-control py-2 px-3 ms-auto' style={{ width: '14rem' }}
                                    type="number"
                                    placeholder="180 "
                                    value={questionTime}
                                    onChange={handleChangeQuestionTime}
                                    min={0} />
                            </div>
                            <div className='mt-8 d-flex align-items-center'>
                                <label style={{ width: '150px' }} htmlFor="">Video From Library</label>
                                <div className='d-flex flex-column ms-auto' style={{ position: 'relative' }}>
                                    <input className='form-select py-2 px-3 pe-10 ms-auto'
                                        style={{ width: '10.6rem' }}
                                        type="search"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={handleChangeSearch}
                                        onFocus={handleFocus}
                                        readOnly={!!videoData}
                                        disabled={!!videoData}
                                    />
                                    {searchText && isFocused && allVideos && (
                                        <div className='px-4 py-2' style={{ marginTop: '5px', zIndex: 2, maxHeight: '150px', overflowY: 'auto', position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', borderRadius: '6px', border: '1px solid #B2B2B2' }}>
                                            {allVideos.map((item, index) => (
                                                <div key={index} onClick={() => handleVideoClick(item)} style={{ cursor: 'pointer' }}>
                                                    <label style={{ whiteSpace: 'nowrap', cursor: 'pointer', marginBottom: '8px' }}>{item?.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ textAlign: 'center', width: '300px', border: '1px dotted gray', height: '165px', marginLeft: '30px', marginTop: '30px', borderRadius: '5px', paddingTop: '50px' }}
                            className={` ${dragging ? 'drag-over' : ''}`}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            onClick={handleUploadClick}
                        >
                            {videoUrl ? (
                                <>
                                    <div style={{ marginTop: '-51px', background: 'black', width: '300px', borderRadius: '5px', position: 'absolute' }}  >

                                        <div onClick={handleRemoveVideo} style={{ top: '4px', right: '1px', cursor: 'pointer', textAlign: 'right' }}>
                                            <i className="fas fa-times-circle" style={{ fontSize: '20px' }}></i>
                                        </div>
                                        <video style={{ marginLeft: '-2px', width: '296px', marginTop: '-2px', height: '165px' }} controls>
                                            <source style={{ marginLeft: '-2px', width: '296px', marginTop: '-2px', height: '165px' }} src={videoUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </>
                            ) : (
                                <div onClick={handlePaperclipClick}>
                                    {isLoading ? (
                                        <div style={{ height: '50px', color: '#F36523' }}>
                                            Loading...
                                        </div>
                                    ) : (
                                        <img className=' me-2'
                                            src={imagePath.ic_drag_drop}
                                            width={50}
                                            height={50}
                                            alt='Drag & Drop'
                                            style={{ cursor: 'pointer', objectFit: 'cover', marginBottom: '2px' }}
                                            title='Drag & Drop' />)}
                                    <div style={{ marginTop: '10px' }}><span style={{ fontSize: '13px', color: '#414040', opacity: '1px' }}>Drag & Drop Video</span></div>
                                    <div style={{ marginTop: '5px' }}><span style={{ fontSize: '11px', color: '#696969' }}>Accept formats include MP4, AVI, WebM, MOV, MKV</span></div>
                                    {/* Hidden input element for file upload */}
                                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={(e) => {
                                        const files = e.target.files;
                                        if (files && files?.length > 0) {
                                            const file = files[0];
                                            handleFileUpload(file);
                                        }
                                    }}
                                        accept="video/mp4,video/x-m4v,video/*" />
                                </div>
                            )}

                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-10'>
                        <div style={{ marginRight: '10px' }}>
                            <button type='reset' style={{ backgroundColor: '#F36523', border: 'none', borderRadius: '5px', padding: '7px 35px', color: 'wheat', fontSize: '14px', fontWeight: '600' }}
                                onClick={onClose}
                                disabled={isLoading}
                            >Close</button>
                        </div>
                        <div style={{ marginRight: '10px' }}>
                            <button style={{ backgroundColor: '#F36523', border: 'none', borderRadius: '5px', padding: '7px 35px', color: 'wheat', fontSize: '14px', fontWeight: '600' }}
                                onClick={handleUpdateQuestion}
                                disabled={isLoading}
                            >Save</button>
                        </div>
                        {/* <div style={{ marginRight: '10px' }}>
                            <button style={{ width: '120px', backgroundColor: '#F36523', border: 'none', borderRadius: '5px', padding: '7px 15px', color: 'wheat', fontSize: '14px', fontWeight: '600' }} disabled={isLoading}>Add More</button>
                        </div> */}
                    </div>
                </form>

                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </div>
        </Modal>
    );
}

export default VideoUploader;
