//import liraries
import React, { useEffect, useState } from 'react';
import AddUpdateApprovingAuthorityPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateApprovingAuthorityPopup';
import axios from 'axios';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import imagePath from '../../../../constants/imagePath';
import { getFormattedDate } from '../../../../constants/CommonFunctions';
import SuccessDialog from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';

const API_URL = process.env.REACT_APP_API_URL;
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;
const DELETE_APPROVING_AUTHORITY_URL = `${API_URL}/configuration/delete-approving-authorities`;
const SAVE_APPROVING_AUTHORITIES_URL = `${API_URL}/configuration/add-approving-authorities`;

// create a component
const ApprovingAuthoritiesTab = () => {
    const { currentUser, auth, logout } = useAuth();
    const [showPopupAddUpdateEntry, setShowPopupAddUpdateEntry] = useState(false);
    const [formType, setFormType] = useState('create');
    const [allAuthorities, setAllAuthorities] = useState<any>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [configurationId, setConfigurationId] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {
        (() => {
            getConfigurations();
        })();
    }, []);

    const getConfigurations = async () => {
        setLoading(true);
        await axios.get(GET_CONFIGURATIONS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data?.Configuration;
            setConfigurationId(data?.configuration_id);
            let approvingAuthorities = data?.approving_authorities;
            if (approvingAuthorities) {
                approvingAuthorities = JSON.parse(approvingAuthorities);
                let dataList = Object.values(approvingAuthorities);
                setAllAuthorities(dataList);
            }

        }).catch((error) => {
            setLoading(false);
            handleErrorResponse(error);
        });
    };

    const onDelete = async (index) => {
        if (index >= 0 && index < allAuthorities.length) {
            setLoading(true);
            const key = index;
            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_APPROVING_AUTHORITY_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                setLoading(false);
                let terms = [...allAuthorities];
                terms.splice(index, 1);
                setAllAuthorities(terms);


            }).catch((error) => {
                setLoading(false);
                handleErrorResponse(error);
            })

        }
    }

    const handleChangeActive = (isActive, index) => {
        const currentDate = new Date();
        // Format the current date
        const formattedDate = currentDate.toISOString();
        let data = [...allAuthorities];
        data[index]['active'] = !isActive;
        data[index]['date'] = formattedDate;
        setAllAuthorities(data);
        updateList(data);
    }

    const updateList = async (data) => {

        let transformedData = {};
        data.forEach((item, index) => {
            transformedData[index] = item;
        });

        let postData = {
            configuration_id: configurationId,
            // approving_authorities: JSON.stringify(transformedData)
            approving_authorities: transformedData
        }
        setLoading(true);
        await axios.post(SAVE_APPROVING_AUTHORITIES_URL, postData, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false)
            // fetch updated data
            // getConfigurations();
            setMessage('Updated Successfully!');
            setShowSuccessPopup(true);

        }).catch((error) => {
            setLoading(false)
            handleErrorResponse(error);
        });

    }

    const handleErrorResponse = (error: any) => {
        if (error.response && error.response.status === 400) {
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            setMessage(error.message)
            setShowErrorMessage(true)
        }
    }


    return (
        <div className='d-fle flex-column mt-8' >
            <div className='d-flex'>
                <div></div>
                <button className='ms-auto candi-btn2 btn text-white fw-bolder'
                    onClick={() => {
                        setFormType('create');
                        setShowPopupAddUpdateEntry(true)
                    }}>
                    + Add New Approving Hierarchy
                </button>
            </div>
            <div className='d-flex flex-wrap mt-10'>
                {(allAuthorities && allAuthorities.length > 0) ? allAuthorities.map((data, index) => {
                    let grades = data?.grades
                    let authorities = data?.authorities;
                    return (
                        <div key={index} className='col-md-4'>
                            <div className='card-bg shadow-sm p-4 mx-2 my-2'>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                        <img className='me-2' src={imagePath.ic_calendar_orange} width={20} height={20} />
                                        <label className='fs-7 fw-semibold'>{getFormattedDate(data?.date)}</label>
                                    </div>
                                    <div className='d-flex align-items-center ms-auto'>
                                        <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={18} height={18}
                                            onClick={() => {
                                                setCurrentIndex(index);
                                                setFormType('update');
                                                setShowPopupAddUpdateEntry(true);
                                            }} />
                                        <img className=' cursor-pointer' src={imagePath.ic_delete_orange_cross} width={16} height={16}
                                            onClick={() => onDelete(index)} />
                                    </div>
                                </div>
                                {/* <div>
                                    <label className='form-label fs-4 fw-bolder my-6'>{data?.label}</label>
                                </div> */}
                                <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                    <label className='form-label fs-4 fw-bolder my-6' style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                        {data?.label}
                                    </label>
                                </div>
                                <div style={{ height: '8rem' }}>
                                    <div className='fs-6 fw-bolder mb-2'>Grades</div>
                                    <div className='d-flex flex-wrap'>
                                        {(grades && grades.length > 0) ? grades.map((grade) => {
                                            return (
                                                <div key={grade}>
                                                    <div className='px-2 py-1 fs-7 me-4 mb-2' style={{ backgroundColor: '#FFF6ED', color: '#F36523', borderRadius: '12px', whiteSpace: 'nowrap' }}>{grade}</div>
                                                </div>
                                            )
                                        }) : (<div></div>)}
                                    </div>
                                </div>
                                <div style={{ height: '3rem' }}>
                                    <div className='fs-6 fw-bolder mb-2 mt-4'>Approving Authority Type</div>
                                    <div className='d-flex flex-wrap'>
                                        <div className='px-2 py-1 fs-7 me-4 mb-2' style={{ backgroundColor: '#FFF6ED', color: '#F36523', borderRadius: '12px', whiteSpace: 'nowrap' }}>{data?.type}</div>
                                    </div>
                                </div>
                                <div className=' d-flex align-items-center mt-6 mb-4'>
                                    <label className='fs-6 fw-bolder me-4'>Approving Authorities</label>
                                    <img src={imagePath.ic_warning_gray_circle} width={24} height={24} title='Approving hierarchy from top to bottom' />
                                </div>
                                <div style={{ height: '12.5rem', overflow: 'auto' }}>
                                    {(authorities && authorities.length > 0) ? authorities.map((authorityData, authorityIndex) => {
                                        let user = authorityData?.user
                                        return (
                                            <div key={authorityIndex} className='d-flex align-items-center mb-5'>
                                                <div className='me-6 d-flex flex-column justify-content-center align-items-center'>
                                                    <img src={imagePath.ic_layers_orange_holo} width={28} height={28} />
                                                    {(authorityIndex < authorities.length - 1) && (<div style={{ width: '100%', height: '100%', transform: 'rotate(90deg)', transformOrigin: '0 0', border: '1px #F36523 dotted', marginLeft: '2.2rem' }}></div>)}
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img className='me-2' src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon} width={32} height={32} style={{ borderRadius: '20px', objectFit: 'contain' }} />
                                                    <div className='d-flex flex-column'>
                                                        {(authorityData?.title !== 'Concerned Group Head' && authorityData?.title !== 'Concerned Approving Authorities of that Grade') &&
                                                            (<div className='fs-6 fw-bolder'>{user?.fullName ? user?.fullName : 'Name'}</div>)}
                                                        <div className='fs-7 fw-semibold' style={{ color: '#475467' }}>{authorityData?.title ? authorityData?.title : 'Title'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : (<div></div>)}
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <label className="toggle-switch-small">
                                        <input type="checkbox" checked={data?.active} onChange={() => handleChangeActive(data?.active, index)} />
                                        <span className="toggle-switch-small-slider"></span>
                                    </label>
                                    <label className='me-4 fs-7 light-txt fw-bolder' style={{ paddingLeft: '1.2rem' }}>Active</label>
                                </div>
                            </div>

                        </div>
                    )
                }) : (<div></div>)}
            </div>

            {showPopupAddUpdateEntry && (
                <AddUpdateApprovingAuthorityPopup
                    show={true}
                    formType={formType}
                    configurationId={configurationId}
                    currentIndex={currentIndex}
                    allAuthorities={allAuthorities}
                    handleClose={() => setShowPopupAddUpdateEntry(false)}
                    updateAuthoritiesList={() => {
                        setShowPopupAddUpdateEntry(false)
                        setMessage(formType === 'create' ? 'Approving Authorities Created Successfully' : 'Updated Successfully!');
                        setShowSuccessPopup(true);
                        getConfigurations()
                    }}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}

            {loading && (
                <LoaderView
                    message='Loading...'
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    message={message}
                    handleClose={() => setShowSuccessPopup(false)}
                />
            )}

        </div>
    );
};

//make this component available to the app
export default ApprovingAuthoritiesTab;
