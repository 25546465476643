//import liraries
import React, { useRef, useState, useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useAuth } from '../../modules/auth/core/Auth'
import axios from 'axios'
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import CustomPagination from '../dashboards/components/CustomPagination'
import clsx from 'clsx'
import imagePath from '../../../constants/imagePath'
import positionsImg from './images/positions_static.png'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
const API_URL = process.env.REACT_APP_API_URL
const GET_POSITIONS_URL = `${API_URL}/position`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`

// create a component
const TALeadPositions = () => {
    const intl = useIntl()
    const { auth, currentUser, logout } = useAuth()
    const user_id = currentUser?.user_id
    const dropdownRefGrades = useRef<HTMLDivElement>(null)
    const [allPositions, setAllPositions] = useState<any>([])
    const [isGradeMenuOpen, setIsGradeMenuOpen] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const [grades, setGrades] = useState<any>([])
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [totalPages, setTotalPages] = useState(1)
    const [params, setParams] = useState<any>({
        status: 'POSTED,REQUISITION_RAISED,CLOSED,HIRED',
        order: 'updated_at',
        sort: 'desc',
        page: 1,
        limit: 10,
        total: 0,
        grade: '',
    })

    // fetching list of all positions
    const getCreatedPositions = async () => {

        let data = { ...params }
        data.ta_lead_id = user_id;
        const filteredParams = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
        )

        await axios
            .get(GET_POSITIONS_URL, {
                // params: {
                //   poc_id: user_id,
                // },
                params: filteredParams,
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((res) => {
                let data = res.data;
                let pagination = data?.pagination
                if (pagination) {
                    if (params.page !== pagination?.page || params.limit !== pagination?.limit || params.total !== pagination?.total) {
                        let paramsData = { ...params }
                        paramsData.page = pagination?.page || 1
                        paramsData.limit = pagination?.limit || 10
                        paramsData.total = pagination?.total || 0
                        setParams(paramsData)
                    }
                    setTotalPages(pagination?.totalPages || 0)
                }
                const positions = data?.data

                let availablePositions: any = []
                if (positions) {
                    setAllPositions(positions)

                    // positions.map((position) => {
                    //   if (position) {
                    //     let status = position.status
                    //     if (status && status !== 'REQUISITION_NOT_RAISED') {
                    //       availablePositions.push(position)
                    //     }
                    //   }
                    // })
                }
                // if (availablePositions.length > 0) {
                //   setAllPositions(availablePositions)

                // }
            })
            .catch((error) => {
                handleErrors(error)
            })
    }

    // fetching list of grades
    const getGrades = async () => {
        await axios
            .get(GET_GRADES_URL, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                let data = res?.data?.job_grade
                if (data) {
                    data = JSON.parse(data)
                    if (data) {
                        setGrades(Object.values(data))
                    }
                }
            })
            .catch((err) => {
                handleErrors(err)
            });
    }

    const handleErrors = (error) => {
        if (error.response) {
            const { status, data } = error.response

            if (status === 400) {
                setMessage(data.message)
            } else if (status === 401 && data.name === 'TokenExpiredError') {
                logout()
            } else if (data.error) {
                if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(data.error.message)
                }
            } else {
                setMessage('An unknown error occurred')
            }
        } else {
            setMessage(error.message)
        }

        setShowErrorMessage(true)
    }

    const filterChange = (subOption) => {
        setIsGradeMenuOpen(false)
        if (selectedFilters.includes(subOption)) {
            let currentFilters = selectedFilters.filter((el) => el !== subOption)
            setSelectedFilters(currentFilters)
        } else {
            setSelectedFilters([...selectedFilters, subOption])
        }
    }

    const filterItems = () => {
        if (selectedFilters && selectedFilters.length > 0) {
            const gradesString = selectedFilters.join(',')

            setParams((prevParams) => ({
                ...prevParams,
                grade: gradesString
            }))

        } else {
            // check show all vacancies
            setParams((prevParams) => ({
                ...prevParams,
                grade: ''
            }))
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRefGrades.current && !dropdownRefGrades.current.contains(event.target)) {
                setIsGradeMenuOpen(false)
            }
        }

        document.addEventListener('click', handleOutsideClick)

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    useEffect(() => {
        filterItems()
    }, [selectedFilters])

    useEffect(() => {
        (async () => {
            await getGrades();
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await getCreatedPositions()
        })()
    }, [params])

    const handlePageChange = (page) => {
        let data = { ...params }
        data.page = page
        setParams(data)
    }

    const handleItemsPerPageChange = (event) => {
        let data = { ...params }
        data.limit = event.target.value
        setParams(data)
    }

    const toggleMenu = () => {
        setIsGradeMenuOpen(!isGradeMenuOpen)
    }

    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('asc')

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
        // const sorted = [...allPositions].sort((a, b) => {
        //   const valueA = a[field]
        //   const valueB = b[field]
        //   if (valueA < valueB) return direction === 'asc' ? -1 : 1
        //   if (valueA > valueB) return direction === 'asc' ? 1 : -1
        //   return 0
        // })

        // setAllPositions(sorted)
        setSortField(field)
        setSortDirection(direction)

        let data = { ...params }
        data.order = field
        data.sort = direction === 'asc' ? 'asc' : 'desc'
        setParams(data)
    }

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
        } else {
            return <FaSort />
        }
    }

    return (
        <div >
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Positions' })}</PageTitle>
            <div className='col-md-12 dashboard-top' >
                <h3>
                    <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                    <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        My Dashboard
                    </Link>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
                    <Link to="#" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                        Positions
                    </Link>
                </h3>
            </div>

            <div className='card-bg margin-top shadow-sm p-6'>

                <div className='d-flex align-items-center' >
                    <h3 className='fw-bolder '>My Positions &nbsp;</h3>
                    <div className='ms-auto'>
                        {allPositions.length > 0 ? (
                            <div ref={dropdownRefGrades} className={clsx(`d-flex align-items-center dropdown ${isGradeMenuOpen ? 'open' : ''}`)} id='kt_filter_grade_menu_toggle'>
                                <div
                                    className={clsx('cursor-pointer symbol')}
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-attach='parent'
                                    data-kt-menu-placement='bottom-end'
                                    // data-kt-menu-placement='bottom-start'
                                    data-kt-menu-flip='bottom'
                                    onClick={toggleMenu}
                                    style={{ width: '12.5rem' }}
                                >
                                    <label className='fs-5 fw-bolder px-4  filter-bg'>
                                        Filter by grade
                                        <img className='ms-auto' src={imagePath.ic_filter_orange} width={14} height={14} />
                                    </label>
                                </div>
                                <div
                                    // className='filter-menu role-filter-menu'
                                    className={clsx(
                                        'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-250px',
                                        {
                                            show: isGradeMenuOpen,
                                        }
                                    )}
                                    data-kt-menu='true'
                                >
                                    <div style={{ listStyleType: 'none', cursor: 'pointer' }}>
                                        {grades.map((grade, index) => {
                                            return (

                                                <div
                                                    className=' hoverable-text px-4 mb-4 align-items-center d-flex'
                                                    style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                                                    key={index}
                                                >
                                                    <input
                                                        name='groupFilter'
                                                        className='form-check-input me-2'
                                                        style={{ height: '16px', width: '16px' }}
                                                        type='checkbox'
                                                        // value={termsChecked}
                                                        checked={selectedFilters?.includes(grade)}
                                                        onChange={(event) => filterChange(grade)}
                                                    />
                                                    <label className='form-check-label  ' >
                                                        {grade}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>

                {/* positions views */}
                {allPositions.length > 0 ? (
                    <div className=' my-2'>
                        <div className='table-responsive mt-6 pb-8'>
                            <table className='table mx-auto table-row-gray-100 align-middle gs-0 gy-3 px-4 table-borders'>
                                <thead>
                                    <tr className='fw-bold graycolor grajabg px-4'>
                                        <th
                                            onClick={() => sortByField('title')}
                                            scope='col' className='align-items-center ps-4'
                                            style={{ width: '10rem', color: '#000000', whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Functional Designation
                                            </label>
                                            {renderSortIcon('title')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('max_basic_salary_per_month')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Max Basic Salary / Month
                                            </label>
                                            {renderSortIcon('max_basic_salary_per_month')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('max_total_remuneration_per_month')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Max Gross Salary / Month
                                            </label>
                                            {renderSortIcon('max_total_remuneration_per_month')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('max_grade_range')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Maximum Grade Range
                                            </label>
                                            {renderSortIcon('max_grade_range')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('no_of_positions')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                No. of Positions
                                            </label>
                                            {renderSortIcon('no_of_positions')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('location')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Location
                                            </label>
                                            {renderSortIcon('location')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('contact_type')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Contract Type
                                            </label>
                                            {renderSortIcon('contact_type')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('recruiter')}
                                            scope='col'
                                            style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Recruiter
                                            </label>
                                            {renderSortIcon('recruiter')}
                                        </th>
                                        <th
                                            onClick={() => sortByField('status')}
                                            scope='col' style={{ whiteSpace: 'nowrap' }}>
                                            <label className=' form-label fw-bolder fs-6 me-2'>
                                                Status
                                            </label>
                                            {renderSortIcon('status')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPositions &&
                                        allPositions.map((row, index) => {
                                            let salaryPerMonth: any = row?.max_basic_salary_per_month ? parseInt(row?.max_basic_salary_per_month) : 0
                                            salaryPerMonth = salaryPerMonth.toLocaleString()
                                            let RemunerationPerMonth: any = row?.max_total_remuneration_per_month
                                                ? parseInt(row?.max_total_remuneration_per_month)
                                                : 0
                                            RemunerationPerMonth = RemunerationPerMonth.toLocaleString()
                                            const locations = row?.batch_positions?.locations
                                            let formattedLocations = locations
                                                ? locations.map((location) => location.charAt(0).toUpperCase() + location.slice(1).toLowerCase()).join(', ')
                                                : '-'
                                            let statusValue = row?.status || 'status'
                                            let updatedStatus = statusValue
                                                .toLowerCase()
                                                .replace(/_/g, ' ')
                                                .replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase())
                                            const recruiter = row?.recruiter
                                            const poc = row?.poc;
                                            const isEvenRow = index % 2 === 0
                                            return (
                                                <tr key={index}
                                                    className='px-4'
                                                    style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}
                                                >
                                                    <td>
                                                        <label className='form-label fs-7 ps-4 fw-bolder'>{row.title}</label>

                                                    </td>
                                                    <td>
                                                        <label className='form-label fs-7 fw-bolder'>{`PKR ${salaryPerMonth}`}</label>
                                                    </td>
                                                    <td>
                                                        <label className='form-label fs-7 fw-bolder'>{`PKR ${RemunerationPerMonth}`}</label>
                                                    </td>
                                                    <td>
                                                        <label className='form-label fs-7 fw-bolder'>{row.max_grade_range}</label>
                                                    </td>
                                                    <td>
                                                        <label className='form-label fs-7 fw-bolder'>{row.no_of_positions ? row.no_of_positions : '1'}</label>
                                                    </td>
                                                    <td>{row?.batch_id ? <div>
                                                        <label className='form-label fs-7 fw-bolder'>{formattedLocations} </label></div> : <div>
                                                        <label className='form-label fs-7 fw-bolder'>{row.location}</label></div>}</td>
                                                    <td><label className='form-label fs-7 fw-bolder'>{row.contact_type}</label></td>
                                                    <td className=' form-label fs-6'>
                                                        <div className='d-flex align-items-center'>
                                                            <img
                                                                className='me-2'
                                                                src={recruiter?.profile_pic ? recruiter?.profile_pic : imagePath.userIcon}
                                                                width={'44px'}
                                                                height={'44px'}
                                                                style={{ borderRadius: '22px', objectFit: 'cover', border: 'solid 1px #F36523' }}
                                                            />
                                                            <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                                                                <label className=' fs-7 fw-bolder'>{`${recruiter?.fullName}`}</label>
                                                                <label className=' fs-7 fw-bolder'>{`POC: ${poc?.fullName}`} </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                        <div
                                                            className={`form-label align-items-center me-4 
                      ${row?.status === 'REQUISITION_RAISED' ? 'orange-chip' : 'green-chip'}`}
                                                        >
                                                            {/* {row.status} */}
                                                            {updatedStatus}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex col-md-12 mt-6 align-items-center'>
                            <div className='col-md-4'>
                                <label>{`Showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
                            </div>
                            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                                <CustomPagination
                                    pageValue={params.page}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                            </div>

                            <div className='col-md-4 '>
                                <div className='ms-auto d-flex flex-end align-items-center'>
                                    <select
                                        className='me-2 pagination-input'
                                        style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                                        id='selectValues'
                                        // value={itemsPerPage}
                                        value={params.limit}
                                        onChange={handleItemsPerPageChange}
                                    >
                                        <option value='10'>10</option>
                                        <option value='25'>25</option>
                                        <option value='50'>50</option>
                                    </select>

                                    <div className='me-2'>
                                        <label>Items per page</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    // show static screen
                    <div className='d-flex flex-column justify-content-center align-items-center mb-12'>
                        <img style={{ objectFit: 'cover' }} src={positionsImg} />
                        <label className='form-label fs-5 mt-6'>{'Currently you have zero positions'}</label>
                    </div>
                )}
            </div>
            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

        </div>
    );
};

//make this component available to the app
export default TALeadPositions;
