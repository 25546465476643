//import liraries
import React, { Component, useEffect, useState } from 'react';
import { useAuth } from '../../auth/core/Auth';
import axios from 'axios';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { useNavigate } from 'react-router'
// import { useNavigation } from '@react-navigation/native';

const API_URL = process.env.REACT_APP_API_URL
export const GET_SKILLS = `${API_URL}/candidate/get-candidate-skills`

// create a component
const SkillsCard = () => {
    const navigate = useNavigate()
    const { auth, currentUser, logout } = useAuth()
    const user_id = currentUser?.user_id

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    const [competencies, setCompetencies] = useState<string[]>([])

    useEffect(() => {
        async function getData() {
            let data = { candidate_id: user_id }
            await axios.post(GET_SKILLS, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                const skills = JSON.parse(response.data.skills)
                skills.map((val, index) => {
                    const skillInfos = Object.values(val)
                    let chips: string[] = []
                    for (let i = 0; i < skillInfos.length; i++) {
                        let entity: any = skillInfos[i]
                        chips.push(entity)
                    }
                    setCompetencies(chips)
                })
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })
        }
        getData()
    }, [])

    return (
        <div className='card-bg shadow-sm ms-4 p-6' style={{ width: '100%' }}>
            <div className='d-flex align-items-center'>
                <img src={imagePath.ic_skills} />
                <label className='ms-2 fs-5 fw-semibold'>My Skills</label>
            </div>
            <hr />

            <div className='competency-chips-container'>
                {competencies && competencies?.map((val, index) => (
                    <div className='competency-chips'>{val}</div>
                ))}
            </div>
            <div className='d-flex '>
                <div></div>
                <button className='candi-btn2 btn text-white px-4 py-2 fw-semibold ms-auto'
                    style={{ width: '10rem' }}
                    onClick={() => {
                        // goto cvcreation screen
                        navigate('/cvcreation', { state: { screen: 'skills' } })
                    }} >
                    Add
                </button>
            </div>

            {showErrorMessage && (
                <ShowError
                    message={message}
                    handleClose={() => setShowErrorMessage(false)}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default SkillsCard;
