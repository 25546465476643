//import liraries
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import './ModelStyleDoc.css'
import './UploadDocument.css'
import icCameraGrey from './img/ic_camera_grey_border.png';
import icCameraChecked from './img/ic_camera_checked_green_border_.png';
import addcompetenctimage from './img/22 [Converted]@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios';
import LoaderView from './LoaderView';
import imagePath from '../../../../constants/imagePath';
import ShowError from '../../content/toasts/ShowError';
const API_URL = process.env.REACT_APP_API_URL
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;
const UPLOAD_DOCUMENTS = `${API_URL}/document/upload-docs`


type Props = {
    show: boolean
    showSuccess: () => void
    handleClose: () => void
    candidateId: any
    jobappId: any
}
const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const UploadDocumentsPopup = ({ show, showSuccess, handleClose, candidateId, jobappId }: Props) => {

    const { auth, logout } = useAuth()
    const [photograph, setPhotograph] = useState<any>();
    const [cnicScan, setCnicScan] = useState<any>();
    const [salarySlip, setSalarySlip] = useState<any>();
    const [evidenceBenefits, setEvidenceBenefits] = useState<any>();
    const [experienceCertificate, setExperienceCertificate] = useState<any>();
    const [degree, setDegree] = useState<any>();
    const [covidCertificate, setCovidCertificate] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState<any>();
    const [docsMissing, setDocsMissing] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    const getConfigurations = async () => {
        await axios.get(GET_CONFIGURATIONS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res?.data?.Configuration;
            let jobDocs = data?.job_documents;
            if (jobDocs) {
                jobDocs = JSON.parse(jobDocs);

                const initialData = Object.keys(jobDocs).map((key) => ({
                    i: key,
                    docName: jobDocs[key],
                    docFile: null
                }));
                setDocs(initialData);
            }


        }).catch((err) => {
            console.error('err: ', err);
        });
    };

    useEffect(() => {
        (async () => {
            getConfigurations();
        })();
    }, []);

    const handleSubmits = async (e) => {
        e.preventDefault();
        setLoading(true)
        const form = new FormData();
        form.append('candidate_id', candidateId);
        form.append('jobapp_id', jobappId);
        docs.map((doc) => {
            let title = doc?.docName;
            // title = title.replace(/\s/g, '_');
            title = title;
            let file = doc?.docFile;
            form.append(title, file);
        });

        axios.post(UPLOAD_DOCUMENTS, form, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'content-type': 'multipart/form-data',
            },
        }).then((res) => {
            setLoading(false)
            showSuccess();
            handleClose();

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message)
                setShowErrorMessage(true)
            } else if (
                error.response &&
                error.response.status === 401 &&
                error.response.data &&
                error.response.data.name === 'TokenExpiredError'
            ) {
                logout()
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                }
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        })

    }

    const handleFileChange = (event, index) => {
        event.preventDefault();
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }

        let data = [...docs];
        // let title = data[index]['docName'];
        // // Extract file extension
        // const fileExtension = title ? title.split('.').pop().toLowerCase() : '';

        // // Define accept format based on file extension
        // let acceptFormat = '.jpg, .png, .pdf';
        // if (fileExtension) {
        //     acceptFormat = `.${fileExtension}`;
        // }

        // // Get the MIME type of the selected file
        // const fileType = file.type;
        // // Check if the file extension or MIME type matches any of the accepted formats
        // if (!acceptFormat.split(',').some(format => fileType.includes(format.trim()) || file.name.endsWith(format.trim()))) {
        //     // File type doesn't match the accepted formats, show warning
        //     setMessage(`Invalid file type. Please select a file with one of the following extensions: ${acceptFormat}`);
        //     setShowErrorMessage(true);
        //     return;
        // }


        // const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        // if (validTypes.indexOf(file.type) === -1) {
        //     alert('Only .jpg, .png, and .pdf files are allowed.');
        //     event.target.value = null; // Clear the input value
        //     return false;
        // }

        data[index]['docFile'] = file
        setDocs(data);

    };

    useEffect(() => {
        (() => {
            setDocsMissing(checkDocsMissing());
        })();
    }, [docs]);

    const checkDocsMissing = () => {
        let docMissing = false;
        if (docs) {
            docs.map((doc) => {
                let file = doc?.docFile;
                if (!file) {
                    docMissing = true;
                }
            });
        }
        return docMissing;
    };

    const handlePhotograph = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setPhotograph(file);

    }
    const handleCnic = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setCnicScan(file);

    }
    const handleSalarySlip = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setSalarySlip(file);

    }
    const handleEvidence = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setEvidenceBenefits(file);

    }
    const handleExperienceCertificate = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setExperienceCertificate(file);

    }
    const handleDegree = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setDegree(file);
    }
    const handleCovidCertificate = async (event) => {
        const file = event.target.files[0];
        if (!isValidFile(event, file)) {
            return;
        }
        setCovidCertificate(file);
    }

    const isValidFile = (event, file) => {
        if (!file) {
            return false;
        }

        // Regular expression to check if the file extension is valid
        const allowedExtensions = /\.(jpg|png|pdf)$/i;

        // Check if the file extension is allowed
        if (!allowedExtensions.test(file.name)) {
            alert('Invalid file type. Please upload a .jpg, .png or .pdf file.');
            event.target.value = null; // Clear the file input value
            return false;
        }
        return true;
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <>
                <div className=' header-bg'>
                    <div className='modal-header no-header-model123'>
                        <h2></h2>
                        {/* begin::Close */}
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <img alt='' src={imagePath.ic_cross_white} style={{ filter: 'brightness(0) saturate(100%) invert(100%)' }}></img>
                        </div>
                    </div>
                    {/* end::Close */}
                    <div className='mx-auto d-flex flex-column '>
                        <img alt='' className='doc-img' src={addcompetenctimage}></img>
                        <h3 className='req-doc-text'>
                            Upload Documents
                        </h3>
                    </div>
                </div>

                <div className='modal-body py-lg-8 px-lg-8'>
                    <div className='card'>
                        {/* begin::Beader */}

                        <div className=' flex-row'>
                            <div className='d-flex flex-column'>
                                <label className='fw-bolder fs-2 mb-2 heading'>Requirements</label>
                                <label className='form-label fs-7 mb-6'>File type should be .jpg, .png, or .pdf</label>
                            </div>
                            <form>
                                <div className='mb-8' >
                                    {docs && docs.map((doc, index) => {

                                        let i = doc?.i;
                                        let title = doc?.docName;
                                        let file = doc?.docFile;

                                        // Extract file extension
                                        const fileExtension = title ? title.split('.').pop().toLowerCase() : '';

                                        // Define accept format based on file extension
                                        // let acceptFormat = '.jpg, .png, .pdf, .doc, .docx';
                                        // if (fileExtension) {
                                        //     acceptFormat = `.${fileExtension}`;
                                        // }

                                        return (
                                            <div key={index} className='document-view' >
                                                <input className='file-input-style'
                                                    type='file'
                                                    accept=".jpg, .png, .pdf"
                                                    // accept={acceptFormat}
                                                    required
                                                    onChange={(event) => handleFileChange(event, index)} />
                                                <img className='me-4 mb-4' src={file ? icCameraChecked : icCameraGrey} width={38} height={38} />
                                                <p className='fs-5 document-type'>{title}</p>
                                            </div>
                                        )
                                    })}

                                </div>

                                <div className='req-btn-cont'>

                                    <button
                                        className={`fw-bolder px-8 ${docsMissing ? 'grey-btn' : 'req-btn'} `}
                                        disabled={docsMissing}
                                        onClick={(e) => handleSubmits(e)}>Upload</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {loading && (
                    <LoaderView message='Loading...' />
                )}
                {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default UploadDocumentsPopup;
