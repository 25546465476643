//import liraries
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../auth'

import icTestGrey from '../../assets/ic_test_grey.png'
import icTestRed from '../../assets/ic_test_red.png'
import icInterViewGrey from '../../assets/ic_interview_grey.png'
import icInterViewRed from '../../assets/ic_interview_red.png'
import icCameraRed from '../../assets/ic_camera_red.png'
import icTickGreen from '../../assets/ic_tick_green.png'

import '../Applications.css'
import '../../../../../_metronic/css/bootstrap-glyphicon.min.css'
import LoaderView from '../../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
const API_URL = process.env.REACT_APP_API_URL
export const CLOSED_JOB_API_URL = `${API_URL}/job/show-candidate-close-job`
// create a component
const CloseApplications = (props) => {
  const {currentUser, auth, logout} = useAuth()
  const user_id = currentUser?.user_id
  const [jobs, setJobs] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const getClosedApplications = async () => {
    setIsLoading(true)
    let data = {
      candidate_id: user_id,
    }
    axios
      .post(CLOSED_JOB_API_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setIsLoading(false)
        // console.log('closedApplications: ', res.data);
        let data = res.data?.acceptedJobs
        setJobs(data)
        props.updatedAt()
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getClosedApplications()
    })()
  }, [])

  // convert date format
  function formatDate(dateString) {
    const date = new Date(dateString)
    const formatedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear().toString().slice(-2)}`
    return formatedDate
    // const options = {
    //   year: '2-digit',
    //   month: '2-digit',
    //   day: '2-digit',
    //   timeZone: 'UTC'
    // };
    // return date.toLocaleDateString('en-GB', options);
  }

  const downloadOfferLetter = (event, offerLetterUrl) => {
    event.preventDefault()
    console.log('offerLetterUrl: ', offerLetterUrl)
    if (offerLetterUrl) {
      setLoading(true)
      axios({
        url: offerLetterUrl,
        method: 'GET',
        responseType: 'blob', // This is important to receive binary data (e.g., a PDF)
      })
        .then((response) => {
          setLoading(false)
          // Create a temporary download link and click it to trigger the download
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'offer_letter.zip')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          setLoading(false)
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    } else {
      window.alert('URL to download offer letter not valid')
    }
  }

  return (
    <>
      <div className='col-xl-12 mt-12' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
        {jobs && jobs.length > 0 ? (
          jobs.map((job, index) => {
            const jobData = job.jobdata
            const applicationData = job.jobapplicationData
            const testId = jobData?.test_id?.trim()
            // const jobId = jobData?.job_id;
            const jobId = applicationData?.jobapp_id
            let applicationStage = applicationData?.jobapp_stage
            if (applicationStage === 'hired') {
              applicationStage = applicationData?.jobapp_status
              // applicationStage = 'Accept Offer Letter';
            }
            const testAttempted = applicationData?.attempt_test
            const applicationStatus = applicationData?.jobapp_status
            const isInterviewOnline = jobData?.online_interview
            const testDays = jobData?.online_test_deadline
            const stageActivity = applicationData?.stage_activity ? JSON.parse(applicationData?.stage_activity) : null
            let offerLetterUrl = applicationData?.offer_letter_url
            return (
              <div
                className='form-group col-md-5 col-sm-12 col-xs-12 mt-10 me-10 shadow '
                style={{borderRadius: '18px', backgroundColor: '#FFFFFF'}}
              >
                <div style={{padding: '2rem'}}>
                  <div className='row'>
                    {/* left side of job card */}
                    <div className='col-md-7'>
                      <h2 className='mt-10' style={{color: '#F36523'}}>
                        {jobData?.title ? jobData?.title : 'Job title'}
                      </h2>
                      <h3 style={{color: '#484848'}}>
                        <i className='bi bi-geo-alt-fill' style={{color: '#FF8750', margin: '0 0.3rem 0 0'}}></i>{' '}
                        {'Location: ' + jobData?.location ? jobData?.location : 'Location'}
                      </h3>
                      <h3 style={{color: '#484848'}}>
                        <i className='bi bi-star-fill' style={{color: '#FF8750', margin: '0 0.3rem 0 0'}}></i>{' '}
                        {'Grade: ' + jobData?.grade ? jobData?.grade : 'Grade'}
                      </h3>
                      <h3 className='mt-10 fw-bolder fs-3' style={{color: '#484848'}}>
                        Requirements
                      </h3>
                    </div>

                    {/* right side of job card */}
                    <div className='col-md-5 text-center '>
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <i className='bi bi-calendar-event-fill' style={{marginRight: '4px', marginBottom: '1rem'}} />
                        <p className='applied-date-p fs-7'>
                          {applicationData?.createdAt ? 'Applied on ' + formatDate(applicationData?.createdAt) : 'Applied on date'}
                        </p>
                      </div>
                      <label
                        className={`fw-bolder fs-8 mt-2 ${
                          applicationStage === 'Offer Letter Accepted' ? 'green-chip' : 'under-review-chip'
                        }`}
                      >
                        {applicationStage === 'Offer Letter Accepted' ? 'Hired' : applicationStage}
                      </label>
                    </div>
                  </div>

                  {/* show requirements chips */}
                  <div className='row mb-4'>
                    <div className='col-md-5 mb-4'>
                      <div
                        className={`fw-bolder fs-8 ${applicationStage === 'application' ? 'withdrwa-btn' : 'green-chip'} `}
                        style={{textAlign: 'center'}}
                      >
                        {applicationStage !== 'application' ? <img className='me-2' src={icTickGreen} alt='online-test' /> : <div></div>}
                        Online Resume
                      </div>
                    </div>

                    {/* <div className='col-md-7 text-center mb-4' >
                                    <div className='fw-bolder fs-8 red-chip'>
                                        <img className='me-2' src={icCameraRed} alt='video-resume' />
                                        Video Resume: Deadline 10/21/23
                                    </div>
                                </div> */}

                    {testId !== null && testId !== undefined && testId !== '' && testId !== 'none' ? (
                      <div className='col-md-4 mb-4'>
                        <div
                          className={`fw-bolder fs-8 ${
                            applicationStage === 'application'
                              ? 'grey-chip'
                              : applicationStage === 'online test'
                              ? testAttempted !== null
                                ? 'withdrwa-btn'
                                : 'red-chip'
                              : 'green-chip'
                          }`}
                          // onClick={() => { showTest(testId) }}
                        >
                          <img
                            className='me-2'
                            src={
                              applicationStage === 'application'
                                ? icTestGrey
                                : applicationStage === 'online test'
                                ? testAttempted !== null
                                  ? icTestRed
                                  : icTestRed
                                : icTickGreen
                            }
                            alt='online-test'
                          />
                          Online Test
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    {isInterviewOnline && (
                      <div className='col-md-5  mb-4'>
                        <div
                          className={`fw-bolder fs-8 ${
                            applicationStage === 'application' || applicationStage === 'online test'
                              ? 'grey-chip'
                              : applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3'
                              ? applicationStatus !== null
                                ? 'withdrwa-btn'
                                : 'red-chip'
                              : 'green-chip'
                          }`}
                        >
                          <img
                            className='me-2'
                            src={
                              applicationStage === 'application' || applicationStage === 'online test'
                                ? icInterViewGrey
                                : applicationStage === 'stage1' || applicationStage === 'stage2' || applicationStage === 'stage3'
                                ? icInterViewRed
                                : icTickGreen
                            }
                            alt='online-interview'
                          />
                          Online Interview
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='upload-video-div'>
                    {applicationStage === 'Offer Letter Accepted' || applicationStage === 'Candidate Joined' ? (
                      <button className='fw-bolder col-md-12 mt-2 btn' onClick={(event) => downloadOfferLetter(event, offerLetterUrl)}>
                        Download Offer Letter
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        ) : isLoading ? (
          <div className='text-center'>
            <FontAwesomeIcon
              icon={faSpinner}
              color='#F36523'
              spin
              size='5x'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                // left: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
                transform: 'translate(-50%, -50%)',
                marginTop: '15rem',
              }}
            />
          </div>
        ) : (
          <h3 className='mt-40 fs-3 fw-bolder' style={{color: 'black'}}>
            No Closed Jobs.
          </h3>
        )}
        {loading && <LoaderView message='Fetching Offer Letter...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </div>
    </>
  )
}

//make this component available to the app
export default CloseApplications
