//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'

import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios';
import LoaderView from './LoaderView';
import ShowError from '../../content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';

const API_URL = process.env.REACT_APP_API_URL;
const CREATE_POSITION_URL = `${API_URL}/position/`;

type Props = {
    show: boolean
    message: string
    handleClose: () => void
    position: any
    updatePositions: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const WarningPopup = ({ show, message, handleClose, position, updatePositions }: Props) => {
    const [popup, setpopup] = useState(<></>)
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [Approvalpopup, setpopupApproval] = useState(<></>)
    const { auth } = useAuth()


    const handleSubmit = async () => {
        
        // let data = {
        //     ...position, // Copy all properties from pData
        //     status: 'archive', // Update the status property
        // };

        let { updated_by, updatedAt,updated_at, created_at, created_by, createdAt, deleted_at, deletedAt, ...newPosition } = position;

        let data = {
            ...newPosition,
            status: 'archive',
        };

        console.log('archivingPositionData: ', data);
        setLoading(true);
        axios.put(CREATE_POSITION_URL + newPosition.position_id, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            console.log('createPositionResponse: ', res.data);
            updatePositions();
            handleClose();

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setErrorMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.data && error.response.data.error) {
                // Handle error message from the API
                // showToast(error.response.data.error.message);
                setErrorMessage(error.response.data.error.message);
                setShowErrorMessage(true);
                // handleClose();
            } else {
                setErrorMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    return createPortal(

        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={imagePath.ic_close}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h3 className='fw-bolder mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>
                                {message}
                            </h3>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <button
                                            onClick={handleSubmit}
                                            type='button'
                                            className='btn text-white px-10 bgcolorofbutoonofsubmit me-8'
                                        >
                                            Yes, I am sure!
                                        </button>
                                        {popup}
                                        <button onClick={handleClose} className='btn text-white px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter123 '>
                                            No
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (
                    <LoaderView message='Loading...' />
                )}
                {showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={errorMessage}
                    />
                )}
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default WarningPopup;
