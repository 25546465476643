import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './AddScoreInterview.css'
import {useState, useEffect} from 'react'
import addcompetenctimage from '../../images/warning@2x.png'
import UsersIcon from './img/group-9680@3x.png'
import InterviewIcon from './img/interview@3x.png'
import ExperienceIcon from './img/experience@2x.png'
import Levelfirt from './img/below a@2x.png'
import Levelsecond from './img/below b@2x.png'
import LevelThird from './img/below c@2x.png'
import LevelForth from './img/below d@2x.png'
import LevelFifth from './img/lev5.png'
import Initiallevel from './img/Initiallevel 1@2x.png'
import InitiallevelTwo from './img/Level 2@2x.png'
import InitiallevelThree from './img/level 3@2x.png'
import InitialLevelFour from './img/level 4@2x1.png'
import InitiallevelFive from './img/level 5@2x (1).png'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import Box from '@mui/material/Box'
import {TextField} from '@mui/material'
import {SuccessInterviewpopUp} from './SuccessPopUp'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/job`
export const GET_ALL_COMPETENCY_BY_ID = `${API_URL}/competency/get`
export const GET_BY_JOB_ID = `${API_URL}/job/`
export const GET_ALL_PANEL_MEMBER = `${API_URL}/panel/get-all-members`
export const GET_SCORE_INTERVIEW = `${API_URL}/panel/get-all-members`
export const ADD_SCORE_INTERVIEW = `${API_URL}/panel/add-score`
export const FETCH_ALL_LEVEL = `${API_URL}/level/get-level`
export const FETCH_PANEL_PROFILE = `${API_URL}/panel/get-panel-images`
// export const GET_INTERVIEWER_PERCENTAGES = `${API_URL}/jobapplication/get-average-score`

type Props = {
  handleClose: () => void
  name: string
  jobapp_id: string
  job_id: any
  profile_pic: string
  position: string
  bexp: string
  jexp: string
  fetchScore: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddScoreInterviewPopupStage3 = ({name, jobapp_id, job_id, profile_pic, position, bexp, jexp, handleClose, fetchScore}: Props) => {
  const {auth} = useAuth()
  let scoreArray: any = []
  let compArrs = ['HEEL', 'Innovation', 'detail']
  let total_comp
  let total_level
  let total_comp_score
  let sum
  let percentage
  let member_id
  let arrayOfIds
  // let level = 3;
  let competencyScore: any = {}
  const [level, setLevel] = useState(3)
  const [levelData, setLevelData] = useState<any>([])
  const [levelDataKeys, setLevelDataKeys] = useState<any>([])
  const [levelImagesObj, setlevelImagesObj] = useState<{key: string; value: string}[]>([])
  const [checked, setChecked] = useState(false)
  const [addScorepop, setaddScorepop] = useState(<></>)
  const [dataFetched, setDataFetched] = useState(false)
  const [panelmembers, setPanelMembers] = useState<any>([])
  const [sortedScore, setSortedScore] = useState<any>([])
  const [individualPercentages, setIndividualPercentages] = useState<any>('0%')
  const [panelId, setPanelId] = useState<any>([])
  const [compArr, setCompArr] = useState<any>([])
  const [allMembersScore, setAllMembersScore] = useState<any>([])
  const [selectedMemberScore, setSelectedMemberScore] = useState<any>([])
  const [buttonData, setButtonData] = useState<any>(null)
  const [doChange, setDoChange] = useState(true)
  const [popshow, setpopshow] = useState(true)
  const [previewImage, setPreviewImage] = useState<any>('')
  const [hoveredIndex, setHoveredIndex] = useState<any>(-1)
  const [previewImageTwo, setPreviewImageTwo] = useState<any>('')
  const [hoveredIndexTwo, setHoveredIndexTwo] = useState<any>(-1)
  const [previewImageThree, setPreviewImageThree] = useState<any>('')
  const [hoveredIndexThree, setHoveredIndexThree] = useState<any>(-1)
  const [previewImageFour, setPreviewImageFour] = useState<any>('')
  const [hoveredIndexFour, setHoveredIndexFour] = useState<any>(-1)
  const [previewImageFifth, setPreviewImageFifth] = useState<any>('')
  const [hoveredIndexFifth, setHoveredIndexFifth] = useState<any>(-1)
  const [formInputs, setFormInputs] = useState({
    description: '',
  })
  const [items, setItems] = useState<any>([])
  const [sucesspopup, setsucesspopup] = useState(<></>)
  const [responsePercentage, setResponsePercentage] = useState<any>([])
  let comptencyArray: any = []
  let competency_id
  let emptyScore
  const [isLoading, setIsLoading] = useState<any>(false)
  const [clickedIndex, setClickedIndex] = useState(-1)
  const [isResponseRecorded, setIsResponseRecorded] = useState<any>(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [localUser, setLocalUser] = useState<any>([])
  const [panelIcon, setPanelIcon] = useState<any>([])
  const levelImages = [{dataUrl: Levelfirt}, {dataUrl: Levelsecond}, {dataUrl: LevelThird}, {dataUrl: LevelForth}, {dataUrl: LevelFifth}]
  let comps = ['1', 'b', 'c']
  console.log({profile_pic})
  const GetAllJobsApplicationsbypage = async () => {
    axios
      .get(GET_BY_JOB_ID + job_id, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        console.log({resp})
        competency_id = JSON.parse(resp.data.competenices)

        console.log({competency_id})
        const names: any = Object.values(competency_id).map((item: any) => item.name || '')
        console.log({names})
        let first = competency_id[0]
        let fData = Object.values(first)
        console.log({fData})
        let jobCompetencies = JSON.parse(resp.data.competenices)
        let competencyNames: any = []
        if (jobCompetencies !== null) {
          for (let competency of jobCompetencies) {
            for (let key in competency) {
              let competencyData: any = competency[key]
              let competencyName: any = competencyData.name
              competencyNames.push(competencyName)
            }
          }
        }
        // let first = competency_id[0]
        // let fData = Object.values(first)
        // for (let i = 0; i < fData.length; i++) {
        //   let entity: any = fData[i]
        //   let data: any = entity["name"]
        //   comptencyArray.push(data)
        setCompArr(competencyNames)

        // }
      })
  }
  console.log({compArr})

  const GETALLLEVEL = async () => {
    await axios
      .get(FETCH_ALL_LEVEL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resLevel) => {
        console.log('hey-level')
        console.log({resLevel})
        setLevel(resLevel?.data?.level?.level_no)
        setLevelDataKeys(JSON.parse(resLevel?.data?.level?.level_data))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    const keys = levelDataKeys.map((obj) => Object.keys(obj)[0])
    setLevelData(keys)
  }, [levelDataKeys])

  useEffect(() => {
    const merged = levelData.map((key, i) => ({key, value: levelImages[i].dataUrl}))
    setlevelImagesObj(merged)
  }, [levelData])

  console.log({level})
  console.log({levelDataKeys})
  console.log({levelData})
  console.log({levelImagesObj})

  const MergeArray = () => {}

  const GETALLPANELMEMBERS = async () => {
    await axios
      .post(
        GET_ALL_PANEL_MEMBER,
        {
          jobapp_id,
          name: 'stage3',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (res) => {
        let panelData: any[] = res.data
        console.log({panelData})
        arrayOfIds = panelData.map((obj) => obj.member_id)

        let localUser: any = JSON.parse(localStorage.getItem('kt-auth-react-v') || '{}')
        let localUserId: string = localUser?.id || ''

        // Find the index of the local user in the panelData
        const localUserIndex = panelData.findIndex((member) => member.member_id === localUserId)

        // Move the local user to the first index
        if (localUserIndex !== -1) {
          const localUserData = panelData.splice(localUserIndex, 1)[0]
          panelData.unshift(localUserData)
        }

        setPanelMembers(panelData)
      })
  }

  console.log({panelmembers})
  console.log({localUser})
  console.log({panelId})

  const GETSCORES = async () => {
    await axios
      .post(
        GET_SCORE_INTERVIEW,
        {
          jobapp_id,
          name: 'stage3',
          member_id: arrayOfIds,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setAllMembersScore(res.data)
        let localUser: any = localStorage.getItem('kt-auth-react-v')
        let ParselocalUser: any = JSON.parse(localUser)
        setLocalUser(ParselocalUser?.user_id)

        const userIndex = res.data.findIndex((obj) => obj.userName === JSON.parse(localUser).name)
        if (userIndex !== -1) {
          setSelectedMemberScore(res.data[userIndex])
          const score = JSON.parse(res.data[userIndex].assigned_score)
          setSortedScore(score?.comp ? score.comp : [])
        } else {
          setSelectedMemberScore(res.data[0])
          const score = JSON.parse(res.data[0].assigned_score)
          setSortedScore(score?.comp ? score.comp : [])
        }
      })
  }

  const SENDSCORE = async () => {
    setIsLoading(true)

    try {
      const response = await axios.post(
        ADD_SCORE_INTERVIEW,
        {
          jobapp_id,
          name: 'stage3',
          assigned_score: {
            comp: sortedScore,
            total_comp: total_comp,
            total_levels: level,
            total_comp_score: total_comp * level,
            obtained_score: sum,
            percentage: percentage,
            description: formInputs['description'],
          },
          member_id: selectedMemberScore.member_id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      setIsResponseRecorded(true)
      setStatusMessage('Response recorded')
      setButtonData(true)
      fetchScore()
      setTimeout(handleClose, 2000) // Trigger handleClose after 5 seconds
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // const GETINTERVIEWERPERCENTAGES = async () => {
  //   await axios.post(
  //     GET_INTERVIEWER_PERCENTAGES,
  //     {
  //       jobapp_id: jobapp_id,
  //       jobapp_stage: "stage1"
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //         "Content-Type": "application/json"
  //       }
  //     }
  //   ).then((res) => {
  //     setResponsePercentage(res.data?.interviewStage1)
  //   })
  // }

  const FETCHUSERPROFILE = async () => {
    axios
      .post(
        FETCH_PANEL_PROFILE,
        {
          job_id,
          jobapp_stage: 'stage3',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((res) => {
        setPanelIcon(res?.data?.panels[0]?.members)
      })
  }
  console.log({panelIcon})

  const onPanelMemberChange = (i) => {
    const selectedMember = allMembersScore[i]
    const score = JSON.parse(selectedMember?.assigned_score)
    const percentage = score?.percentage
    if (percentage != null) {
      setIndividualPercentages(percentage)
    } else if (percentage === 0) {
      setIndividualPercentages(0)
    } else {
      setIndividualPercentages(null)
    }
    setSelectedMemberScore(selectedMember)
    setSortedScore(score?.comp ? score.comp : [])
    setButtonData(selectedMember?.assigned_score)
    setDoChange(false)
  }

  const ChangeIcon = (e, i, name) => {
    const tempArr = [...sortedScore]
    const nameIndex = tempArr.findIndex((obj) => obj.name === name)
    if (nameIndex !== -1) {
      tempArr[i]['score'] = e.target.value
      setSortedScore(tempArr)
    } else {
      setSortedScore((result) => [...result, {name: e.target.id, score: e.target.value}])
    }
    setClickedIndex(i) // Set the hovered index to the current compIndex
  }
  total_comp = compArr.length

  sum = sortedScore.reduce((acc, obj) => acc + parseInt(obj.score), 0)
  let dividant = total_comp * level
  percentage = parseFloat(((sum / dividant) * 100).toFixed(2))

  competencyScore = {
    assigned_score: {
      scoreArray,
      total_comp: total_comp,
      total_level: level,
      total_comp_score: total_comp * level,
      Obtained_score: sum,
      percentage: percentage,
    },
  }

  const ImageSrc = (level) => {
    const ImageIndex = sortedScore.findIndex((ind) => ind.name === level)
    return ImageIndex
  }

  const onInputChange = (event) => {
    formInputs[event.target.name] = event.target.value
    setFormInputs(formInputs)
  }

  useEffect(() => {
    ;(async () => {
      if (!dataFetched) {
        await GetAllJobsApplicationsbypage()
        await GETALLPANELMEMBERS()
        await FETCHUSERPROFILE()
        await GETSCORES()

        if (levelDataKeys) {
          await MergeArray()
        }
        // await GETINTERVIEWERPERCENTAGES();
      }
    })()
  }, [dataFetched])

  useEffect(() => {
    GETALLLEVEL()
  }, [dataFetched])

  useEffect(() => {
    if (allMembersScore.length > 0) {
      const selectedMember = allMembersScore[0]
      const score = JSON.parse(selectedMember?.assigned_score)
      const percentage = score?.percentage
      if (percentage != null) {
        setIndividualPercentages(percentage)
      } else {
        setIndividualPercentages(null)
      }
      setSelectedMemberScore(selectedMember)
      setSortedScore(score?.comp ? score.comp : [])
      setButtonData(selectedMember?.assigned_score)
      setDoChange(false)
    }
  }, [allMembersScore])
  useEffect(() => {
    if (doChange) {
      setButtonData(allMembersScore[0]?.assigned_score)
    }
  })

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px'
      show={popshow}
      onHide={handleClose}
    >
      <div className=' no-header-model1234'>
        <div className='modal-header no-header-model123'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '25rem',
            }}
          >
            <img src={InterviewIcon} className='score-header-icon-internally' />
            <h2 className='score-header-container-text'>Interview Score Details</h2>
          </div>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <div className='profile-Container-add-score-interview'>
            <div className='left-container-add-score-interview'>
              <div>
                {profile_pic ? (
                  <img src={profile_pic} className='profile-user-icon' />
                ) : (
                  <img src={UsersIcon} className='profile-user-icon' />
                )}
              </div>
              <div className='add-score-middle-container-user-details'>
                <label className='add-score-middle-container-username'>{name}</label>
                <div className='add-score-middle-container-role'>
                  <label>Position: {position}</label>
                </div>
              </div>
            </div>
            <div className='right-container'>
              <div className='right-container-img'>
                <img src={ExperienceIcon} style={{width: '60%'}} />
              </div>
              <div>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Banking Experience :
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {bexp} yrs
                  </span>
                </label>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Total Experience :
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {(parseFloat(bexp) + parseFloat(jexp)).toFixed(2)} yrs
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '2rem',
            }}
          >
            {panelmembers.map((item, index) => (
              <div
                key={index}
                className={`panel-member-item ${localUser === item.member_id ? 'active' : 'inactive'}`}
                onClick={() => onPanelMemberChange(index)}
              >
                <div className='panel-member-content-addscore'>
                  {item.profilePic ? (
                    <img
                      src={item.profilePic}
                      className={
                        selectedMemberScore.member_id === item.member_id
                          ? 'clicked-addscore-internal-stepper-icon'
                          : 'addscore-internal-stepper-icon'
                      }
                    />
                  ) : (
                    <img
                      src={UsersIcon}
                      className={
                        selectedMemberScore.member_id === item.member_id
                          ? 'clicked-addscore-internal-stepper-icon'
                          : 'addscore-internal-stepper-icon'
                      }
                    />
                  )}
                  <p
                    className={
                      selectedMemberScore.member_id === item.member_id
                        ? 'checked-addscore-internal-stepper-name'
                        : 'addscore-internal-stepper-name'
                    }
                  >
                    {localUser === item.member_id ? 'You' : item.userName}
                  </p>
                  <p
                    className={
                      selectedMemberScore.member_id === item.member_id
                        ? 'add-score-response-percentage'
                        : 'unchecked-add-score-response-percentage'
                    }
                  >
                    {item.assigned_score ? `${JSON.parse(item.assigned_score).percentage}%` : '0%'}
                  </p>
                </div>
                {index !== panelmembers.length - 1 && <hr className='vertical-line' />}
              </div>
            ))}
          </div>

          <div
            className='add-score-internally-info-box'
            style={{
              minWidth: '98%',
              paddingLeft: '31px',
              marginLeft: '0.5rem',
            }}
          >
            <div className='add-score-internally-info-text'>
              <label className='level-upper-external-text '>
                Rank the candidate on {level} levels scale ranging from 1-{level} according to following criteria
              </label>

              <div className='add-score-info-img-cont'>
                {levelData?.map((key, index) => (
                  <div className='level-cont' key={index}>
                    <img src={levelImagesObj.find((obj) => obj.key === key)?.value} style={{height: '5vh'}} />
                    <label className='level-upper-external-text'>{key}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='competency-level-internally'>
            {compArr.map((comp, compIndex) => {
              const compId = `comp-${compIndex}`
              const levelOneId = `levelOne-${compIndex} - ${comp} `
              const levelTwoId = `levelTwo-${compIndex} - ${comp} `
              const levelThreeId = `levelThree-${compIndex} - ${comp} `
              const levelOneName: any = `${comp} `
              const levelTwoName: any = `${comp} `
              const levelThreeName: any = `${comp} `
              const levelFourName: any = `${comp} `
              const levelFiveName: any = `${comp} `

              return (
                <div key={compIndex} className='comp-cont-internally'>
                  <p
                    className='competency-step-text'
                    style={{
                      color: 'rgb(243, 101, 35)',
                      fontWeight: '700',
                      paddingLeft: '10px',
                    }}
                  >
                    {comp}
                  </p>

                  <form className='react-step-internally'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                      }}
                    >
                      <label>
                        <input
                          type='radio'
                          value='1'
                          name='item'
                          id={levelOneName}
                          onClick={(e) => ChangeIcon(e, compIndex, levelOneName)}
                          style={{display: 'none'}}
                        />

                        <img
                          src={
                            hoveredIndex === compIndex
                              ? previewImage
                              : sortedScore.length
                              ? sortedScore[ImageSrc(levelOneName)]?.score === '1'
                                ? Levelfirt
                                : Initiallevel
                              : Initiallevel
                          }
                          style={{
                            height: '6vh',
                            cursor: 'pointer',
                            opacity: hoveredIndex === compIndex ? 0.7 : 1,
                          }}
                          onMouseEnter={() => {
                            setHoveredIndex(compIndex)
                            setPreviewImage(Levelfirt) // Set the preview image URL to the post-hover image
                          }}
                          onMouseLeave={() => {
                            setHoveredIndex(-1)
                            setPreviewImage('') // Reset the preview image URL
                          }}
                          onClick={() => setClickedIndex(compIndex)}
                          alt='Level Image'
                        />
                      </label>

                      <label>
                        <input
                          type='radio'
                          name='item'
                          value='2'
                          id={levelTwoName}
                          onClick={(e) => ChangeIcon(e, compIndex, levelTwoName)}
                          style={{display: 'none'}}
                        />

                        <img
                          src={
                            hoveredIndexTwo === compIndex
                              ? previewImageTwo
                              : sortedScore.length
                              ? sortedScore[ImageSrc(levelTwoName)]?.score === '2'
                                ? Levelsecond
                                : InitiallevelTwo
                              : InitiallevelTwo
                          }
                          style={{
                            height: '6vh',
                            cursor: 'pointer',
                            opacity: hoveredIndexTwo === compIndex ? 0.7 : 1,
                          }}
                          onMouseEnter={() => {
                            setHoveredIndexTwo(compIndex)
                            setPreviewImageTwo(Levelsecond)
                          }}
                          onMouseLeave={() => {
                            setHoveredIndexTwo(-1)
                            setPreviewImageTwo('')
                          }}
                          onClick={() => {
                            setClickedIndex(compIndex)
                            setPreviewImageTwo(Levelsecond)
                          }}
                          alt='Level Image'
                        />
                      </label>

                      {level >= 3 && (
                        <label>
                          <input
                            type='radio'
                            name='item'
                            value='3'
                            id={levelThreeName}
                            onClick={(e) => ChangeIcon(e, compIndex, levelThreeName)}
                            style={{display: 'none'}}
                          />

                          <img
                            src={
                              hoveredIndexThree === compIndex
                                ? previewImageThree
                                : sortedScore.length
                                ? sortedScore[ImageSrc(levelThreeName)]?.score === '3'
                                  ? LevelThird
                                  : InitiallevelThree
                                : InitiallevelThree
                            }
                            style={{
                              height: '6vh',
                              cursor: 'pointer',
                              opacity: hoveredIndexThree === compIndex ? 0.7 : 1,
                            }}
                            onMouseEnter={() => {
                              setHoveredIndexThree(compIndex)
                              setPreviewImageThree(LevelThird)
                            }}
                            onMouseLeave={() => {
                              setHoveredIndexThree(-1)
                              setPreviewImageThree('')
                            }}
                            onClick={() => {
                              setClickedIndex(compIndex)
                              setPreviewImageThree(LevelThird)
                            }}
                            alt='Level Image'
                          />
                        </label>
                      )}

                      {level >= 4 && (
                        <label>
                          <input
                            type='radio'
                            name='item'
                            value='4'
                            id={levelFourName}
                            onClick={(e) => ChangeIcon(e, compIndex, levelFourName)}
                            style={{display: 'none'}}
                          />

                          <img
                            src={
                              hoveredIndexFour === compIndex
                                ? previewImageFour
                                : sortedScore.length
                                ? sortedScore[ImageSrc(levelFourName)]?.score === '4'
                                  ? LevelForth
                                  : InitialLevelFour
                                : InitialLevelFour
                            }
                            style={{
                              height: '6vh',
                              cursor: 'pointer',
                              opacity: hoveredIndexFour === compIndex ? 0.7 : 1,
                            }}
                            onMouseEnter={() => {
                              setHoveredIndexFour(compIndex)
                              setPreviewImageFour(LevelForth)
                            }}
                            onMouseLeave={() => {
                              setHoveredIndexFour(-1)
                              setPreviewImageFour('')
                            }}
                            onClick={() => {
                              setClickedIndex(compIndex)
                              setPreviewImageFour(LevelForth)
                            }}
                            alt='Level Image'
                          />
                        </label>
                      )}

                      {level >= 5 && (
                        <label>
                          <input
                            type='radio'
                            name='item'
                            value='5'
                            id={levelFiveName}
                            onClick={(e) => ChangeIcon(e, compIndex, levelFiveName)}
                            style={{display: 'none'}}
                          />

                          <img
                            src={
                              hoveredIndexFifth === compIndex
                                ? previewImageFifth
                                : sortedScore.length
                                ? sortedScore[ImageSrc(levelFiveName)]?.score === '5'
                                  ? LevelFifth
                                  : InitiallevelFive
                                : InitiallevelFive
                            }
                            style={{
                              height: '6vh',
                              cursor: 'pointer',
                              opacity: hoveredIndexFifth === compIndex ? 0.7 : 1,
                            }}
                            onMouseEnter={() => {
                              setHoveredIndexFifth(compIndex)
                              setPreviewImageFifth(LevelFifth)
                            }}
                            onMouseLeave={() => {
                              setHoveredIndexFifth(-1)
                              setPreviewImageFifth('')
                            }}
                            onClick={() => {
                              setClickedIndex(compIndex)
                              setPreviewImageFifth(LevelFifth)
                            }}
                            alt='Level Image'
                          />
                        </label>
                      )}
                    </div>
                  </form>
                </div>
              )
            })}
          </div>
          <div className='Add-score-remarks-cont'>
            <label className='Add-score-remarks-text'>Final Remarks</label>

            <Box
              sx={{
                width: '97%',
                height: 80,
              }}
            >
              <TextField fullWidth placeholder='Type Here' name='description' onChange={onInputChange} multiline required rows={5} />
            </Box>
          </div>

          <div className='add-score-internal-cross-btn-cont'>
            {!isLoading && !statusMessage && (
              <button
                className='external-score-btn'
                disabled={
                  !auth?.token ||
                  selectedMemberScore?.member_id !== localUser ||
                  (buttonData && selectedMemberScore?.member_id === localUser)
                }
                onClick={SENDSCORE}
              >
                Submit
              </button>
            )}
            {(isLoading || statusMessage) && (
              <div className='loading-spinner'>
                {isLoading && (
                  <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <FontAwesomeIcon icon={faSpinner} spin color='#F36523' size='3x' />
                    <span style={{color: '#F36523', fontSize: '16px'}}>Recording Response...</span>
                  </div>
                )}
                {statusMessage && !isLoading && (
                  <button className='external-score-btn' disabled>
                    Response submitted
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {AddScoreInterviewPopupStage3}
