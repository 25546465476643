//import liraries
import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/core/Auth';
import axios from 'axios';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../constants/imagePath';
import { TestCard } from '../../../_metronic/partials/content/cards/TestCard';
import UploadTestCSVPopup from '../../../_metronic/partials/modals/JobFunnelsModels/UploadTestCSVPopup';

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_TEST = `${API_URL}/test`
// create a component
const Tests = () => {
    const { auth, currentUser, logout } = useAuth()
    const [AllTests, setAllTests] = useState<any>([])
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [showUploadTestPopup, setShowUploadTestPopup] = useState(false);
    const [totalTests, setTotalTests] = useState<any>(0);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [params, setParams] = useState<any>({
        page: 1,
        limit: 10,
        total: 0,
    });
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        (() => {
            fetchAllTests();
        })();
    }, [params])

    const fetchAllTests = async () => {
        axios.get(GET_ALL_TEST, {
            // params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => {
                setLoading(false)
                let pagination = resp?.data?.pagination
                if (pagination) {
                    if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
                        let paramsData = { ...params }
                        paramsData.page = pagination?.page || 1
                        paramsData.limit = pagination?.limit || 10
                        paramsData.total = pagination?.total || 0
                        setParams(paramsData)
                    }
                    setTotalPages(pagination?.totalPages || 0)
                }
                let tests = resp?.data?.data
                if (tests) {
                    setAllTests(tests)
                }
            })
            .catch((error) => {
                setLoading(false)
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message)
                    setShowErrorMessage(true)
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.name === 'TokenExpiredError'
                ) {
                    logout()
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout()
                    } else {
                        setMessage(error.response.data.error.message)
                        setShowErrorMessage(true)
                    }
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            })
    }

    return (
        <div >
            <div className='row  margin-top-for-indiviual-jobs g-5 g-xl-8'>
                <div className='d-flex flex-wrap flex-stack mb-6' style={{ marginTop: '8rem' }} >
                    <span className=' text-gray-800 fw-bolder fs-3 mx-10'>Tests ({totalTests})</span>
                    {/* <div className=' col-md-3 candi-btn2 text-white fw-bolder fs-5 ms-auto align-items-center text-center'
                        style={{ color: 'white' }}
                        onClick={() => setShowUploadTestPopup(true)}>
                        <img src={imagePath.ic_upload_white} className="me-2"></img>
                        Import Tests From CSV
                    </div> */}
                </div>
            </div>
            <TestCard
                showCreateTestBtn={() => {
                    // setTestButton("create")
                }}
                updateTotalTestsCount={(total) => setTotalTests(total || 0)}
            />
            {showUploadTestPopup && (
                <UploadTestCSVPopup
                    show={true}
                    handleClose={() => {
                        setShowUploadTestPopup(false);
                    }}
                    handleUpdateTestsList={() => {
                        setShowUploadTestPopup(false);
                        window.location.reload(); // Refresh the page
                    }}
                />
            )}
            {loading && <LoaderView message='Loading...' />}
            {showErrorMessage && <ShowError message={message} handleClose={() => setShowErrorMessage(false)} />}
        </div>
    );
};

//make this component available to the app
export default Tests;
