// Context/LoginContext
import React, {useState, createContext} from 'react'

interface GenerateContextProps {
  children: React.ReactNode
}
export const CreateGenerateButtonContext = createContext({
  Genbutton: false,
  setGenbutton: (jobButton: boolean) => {},
})
export const GenReportButtonContext = ({children}: GenerateContextProps) => {
  const [Genbutton, setGenbutton] = useState(false)
  return (
    <CreateGenerateButtonContext.Provider value={{Genbutton, setGenbutton}}>
      {children}
    </CreateGenerateButtonContext.Provider>
  )
}
