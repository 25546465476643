import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { CandidateMasterLayout } from '../../_metronic/layout/CandidateMasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import Jobs from '../modules/Jobs/Jobs'
import Positions from '../modules/Position/Positions'
import CompetenciesWrapper from '../modules/Competencies/Competencies'
import TestWrapper from '../modules/Test/TestMain'
import CreateTestWrapper from '../modules/Test/TestWrapper'
import CreatePosition from '../modules/Position/CreatePositions'
import UpdatePosition from '../modules/Position/UpdatePosition'
import LeaderboardWrapper from '../modules/Jobs/Leaderboard/Leaderboard'
import CreateJobWrapper from '../modules/Create Jobs/CreateJobs'
import UpdateJobWrapper from '../modules/Create Jobs/UpdateJobs'
import Insights from '../modules/Insights/Insights'
import SettingPage from '../modules/Setting/Setting'
import VacantPositon from '../modules/VacantPositions/Vacantpositions'
import JobFunnelingMainScreen from '../modules/JobsFunneling/JobFunnelingMainScreen'
import OnlineTestNewPage from '../modules/JobsFunneling/OnlineTest/OnlineTestNewPage'
import StepperComponentCV from '../modules/Candidate Space/CVScreens/StepperComponentCV'
import DashboardCandidate from '../modules/Candidate Space/Dashboard/DashboardCandidate'
import VacanciesCandidateMain from '../modules/Candidate Space/Vacancies/VacanciesCandidateMain'
import ApplyNowTab from '../modules/Candidate Space/Vacancies/ApplyNowTab'
import VacanBoiler from '../modules/Candidate Space/Vacancies/VacanBoiler'
// import {MenuTestPage} from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { useNavigate } from 'react-router-dom'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import ScheduleInterview from '../modules/JobsFunneling/ScheduleInterview/ScheduleInterview'
// import ApplicationsTab from '../modules/Candidate Space/Applications/ApplicationsTab'
import ApplicationBoiler from '../modules/Candidate Space/Applications/ApplicationBoiler'
import OnlineTest from '../modules/Candidate Space/Applications/OnlineTest'
import SolveTest from '../modules/Candidate Space/Applications/SolveTest'
import { PocDashboard } from '../pages/dashboard/PocDashboard'
import RejectedCandidates from '../modules/JobsFunneling/RejectedCandidates'
import Users from '../modules/users/Users'
import RejectedCandidateProfile from '../modules/JobsFunneling/RejectedCandidateProfile'
import CompleteUserProfile from '../modules/users/CompleteUserProfile'
import CreatePositions from '../modules/CreatePositions/CreatePositions'
import Analytics from '../modules/analytics/Analytics'
import { isCandidatePath } from '../../constants/CommonFunctions'
import Dashboard from '../modules/dashboards/Dashboard'
import CandidateDashboard from '../modules/Candidate Space/candidateDashboard/CandidateDashboard'
import DashboardPoc from '../modules/pocDashboard/DashboardPoc'
import TALeadPositions from '../modules/Position/TALeadPositions'
import TestMain from '../modules/updatedTest/TestMain'
import EmployeeDashboard from '../modules/dashboards/EmployeeDashboard'
import RequisitionApproval from '../modules/JobsFunneling/RequisitionApproval'

const API_URL = process.env.REACT_APP_API_URL

export const CANDIDATE_CV_CHECK = `${API_URL}/candidate/cv-value-check`

// import {LoginContext} from '../App'
const hostname = window.location.hostname
const PrivateRoutes = () => {
  const { currentUser, auth } = useAuth()
  const [cvCheck, setCvCheck] = useState(false)
  const token = auth?.token || ''
  const navigate = useNavigate()
  let candidatePath = isCandidatePath(hostname)
  // let candidatePath = false
  // if (
  //   hostname === 'localhost' ||
  //   hostname === 'bop-candidate-qa.isaacscode.com' ||
  //   hostname === 'qa-candidate.bop.com.pk' ||
  //   hostname === 'candidate.bop.com.pk' ||
  //   hostname === 'democandidate.isaacscode.com'
  // ) {
  //   candidatePath = true
  // }

  // useEffect(() => {

  //   const isTokenExpired = () => {
  //     try {
  //       const decodedToken: any = jwtDecode(token);

  //       // Check if the token has an expiration time
  //       if (decodedToken.exp) {
  //         // Compare the current time with the token's expiration time
  //         return decodedToken.exp * 1000 < Date.now(); // Convert seconds to milliseconds
  //       }

  //       // If the token does not have an expiration time, consider it not expired
  //       return false;
  //     } catch (error) {
  //       // Handle decoding errors (e.g., invalid token format)
  //       console.error('Error decoding token:', error);
  //       return true; // Consider the token expired in case of decoding errors
  //     }
  //   };

  //   // Check if the token is expired
  //   const tokenExpired = isTokenExpired();
  //   console.log('isTokenExpired: ', tokenExpired);
  //   if (tokenExpired) {
  //     // Redirect to the login screen when the token is expired
  //     // You may want to clear the token from localStorage or perform other actions
  //     // to handle the expiration appropriately
  //     navigate('/auth/login'); // Use the appropriate login route
  //   }

  //   // Your other logic...

  // }, [token]); // Add any dependencies if needed
  return (
    <Routes>
      {currentUser?.role === 'candidate' || candidatePath ? (
        <Route>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'candidate' || candidatePath ? (
                cvCheck ? (
                  <Navigate to='/candidatedashboard' />
                ) : (
                  <Navigate to='/cvcreation' />
                )
              ) : (
                // JSON.parse(currentUser.candidate.profile_strength).total == 100 ? (
                //   <Navigate to='/candidatedashboard' />
                // ) : (
                //   <Navigate to='/cvcreation' />
                // )
                <Route path='*' element={<Navigate to='/error/404' />} />
              )
            }
          />
          {/* Pages */}
          <Route path='cvcreation' element={<StepperComponentCV />}></Route>
          <Route element={<CandidateMasterLayout />}>
            {/* <Route path='candidatedashboard' element={<DashboardCandidate />}></Route> */}
            <Route path='candidatedashboard' element={<CandidateDashboard />}></Route>
            <Route path='candidatevacancies' element={<VacanciesCandidateMain />}></Route>
            <Route path='candidateapplications' element={<ApplicationBoiler />}></Route>
            <Route path='/applyjob' element={<ApplyNowTab />}></Route>
            <Route path='/mainvacancies' element={<VacanBoiler />}></Route>
            <Route path='/onlinetest' element={<OnlineTest />}></Route>
            <Route path='/solvetest' element={<SolveTest />}></Route>
          </Route>
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : currentUser?.role === 'poc' ? (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'poc' ? (
                <Navigate to='/pocdashboard' />
              ) : (
                <Navigate to='/dashboard' />
              )
            }
          />
          {/* Pages */}

          {/* Lazy Modules */}
          {/* <Route path='pocdashboard' element={<PocDashboard />} /> */}
          <Route path='pocdashboard' element={<DashboardPoc />} />
          <Route path='positions' element={<Positions />} />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      ) : currentUser?.role === 'ta_lead' ? (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'ta_lead' ? (
                <Navigate to='/dashboard' />
              ) : (
                <Navigate to='/dashboard' />
              )
            }
          />
          {/* Pages */}

          <>
            {' '}
            {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
            <Route path='dashboard' element={<Dashboard />} />
            {/* Lazy Modules */}
            <Route path='positions' element={<TALeadPositions />} />
            <Route path='vacantpositions' element={<VacantPositon />} />
            <Route path='jobs' element={<Jobs />} />
            <Route path='competencies' element={<CompetenciesWrapper />} />
            <Route path='tests' element={<TestMain />} />
            <Route path='settings' element={<SettingPage />} />
            <Route path='analytics' element={<Analytics />} />
            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        </Route>
      ) : currentUser?.role === 'recruiter' ? (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'recruiter' ? (
                <Navigate to='/dashboard' />
              ) : (
                <Navigate to='/dashboard' />
              )
            }
          />
          {/* Pages */}

          <>
            {' '}
            {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
            <Route path='dashboard' element={<Dashboard />} />
            {/* Lazy Modules */}
            <Route path='jobs' element={<Jobs />} />
            <Route path='createjobs' element={<CreateJobWrapper />} />
            <Route path='updatejobs/:id' element={<UpdateJobWrapper />} />
            <Route path='leaderboard' element={<LeaderboardWrapper />} />
            <Route path='vacantpositions' element={<VacantPositon />} />
            <Route path='jobfunnelingmainscreen/:id' element={<JobFunnelingMainScreen />} />
            <Route path='jobs/rejected_candidate_profile/:jobapp_id' element={<RejectedCandidateProfile />} />
            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        </Route>
      ) : currentUser?.role === 'none' ? (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'none' ? (
                <Navigate to='/employeeDashboard' />
              ) : (
                <Navigate to='/dashboard' />
              )
            }
          />
          {/* Pages */}

          <>
            <Route path='employeeDashboard' element={<EmployeeDashboard />} />
            <Route path='/public/requisition_approval/:group_head_id/:position_id' element={<RequisitionApproval />} />
            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </>
        </Route>
      ) : (
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route
            path='auth/*'
            element={
              currentUser?.role === 'candidate' || candidatePath ? (
                cvCheck ? (
                  <Navigate to='/candidatedashboard' />
                ) : (
                  <Navigate to='/cvcreation' />
                )
              ) : currentUser?.role === 'none' ? (
                <Navigate to='/employeeDashboard' />
              ) : (
                <Navigate to='/dashboard' />
              )
            }
          />
          {/* Pages */}

          <>
            {' '}
            {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
            <Route path='dashboard' element={<Dashboard />} />
          </>
          <>
            {/* <Route path='employeeDashboard' element={<EmployeeDashboard />} /> */}
          </>
          {/* <Route path='cvcreation' element={<StepperComponentCV />}></Route> */}
          <Route path='competencies' element={<CompetenciesWrapper />} />

          {/* Lazy Modules */}
          <Route path='jobs' element={<Jobs />} />
          {/* <Route path='pocdashboard' element={<PocDashboard />} /> */}
          {/* <Route path='pocdashboard' element={<DashboardPoc />} /> */}
          {/* <Route path='positions' element={<Positions />} /> */}
          <Route path='positions' element={<TALeadPositions />} />
          {/* <Route path='tests' element={<TestWrapper />} /> */}
          <Route path='tests' element={<TestMain />} />
          <Route path='leaderboard' element={<LeaderboardWrapper />} />
          <Route path='analytics' element={<Analytics />} />
          <Route path='createjobs' element={<CreateJobWrapper />} />
          <Route path='createPositions' element={<CreatePositions />} />
          <Route path='createtest' element={<CreateTestWrapper />} />
          <Route path='createposition' element={<CreatePosition />} />
          <Route path='updatejobs/:id' element={<UpdateJobWrapper />} />
          <Route path='updateposition/:id' element={<UpdatePosition />} />
          <Route path='insights' element={<Insights />} />
          <Route path='settings' element={<SettingPage />} />
          <Route path='users' element={<Users />} />
          <Route path='vacantpositions' element={<VacantPositon />} />
          <Route path='jobfunnelingmainscreen/:id' element={<JobFunnelingMainScreen />} />
          {/* <Route path='rejected_candidates/:id' element={<RejectedCandidates />} /> */}
          {/* <Route path='jobs/rejected_candidate_profile/:candidate_id/:member_id/:job_id/:jobapp_id' element={<RejectedCandidateProfile />}/> */}
          <Route path='jobs/rejected_candidate_profile/:jobapp_id' element={<RejectedCandidateProfile />} />
          {/* <Route path='complete_user_profile/:user_id' element={<CompleteUserProfile />}/> */}
          {/* <Route path='Onlinetestfunnelingmainscreen' element={<OnlineTestApplicationsTable />} /> */}

          {/* */}
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      )}
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
