//import liraries
import React, { Component, useEffect, useState } from 'react';
import imagePath from '../../../../constants/imagePath';
import { Chart } from "react-google-charts";
import axios from 'axios';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';

const API_URL = process.env.REACT_APP_API_URL
const GET_TIME_TO_HIRE_GRAPH = `${API_URL}/analytics/time-to-hire-graph`
const GET_APPLICATIONS_OPENING_GRAPH = `${API_URL}/analytics/applications-graph`
const GET_OFFER_ACCEPTANCE_GRAPH_URL  = `${API_URL}/analytics/tat-graph`

export

    type Props = {
        title: string
        value: string
        unit: string
        isActive: boolean
        onClickCard?: () => void;
    }

// create a component
const InsightsCard = ({ title, value, unit, isActive, onClickCard }: Props) => {
    const { currentUser, auth, logout } = useAuth();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [graphType, setGraphType] = useState('up');
    const [graphData, setGraphData] = useState<any>();
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        (() => {
            getGraphData();
        })();
    }, [])

    const getGraphData = () => {
        switch (title) {
            case 'Avg. Time To Hire':
                getTimeToHireGraphInfo();
                break;
            case 'Avg. Applications Per Openings':
                getApplicationsGraphInfo();
                break;
            case 'No. of Cases Completed with TAT':
                getCasesCompletedWithTatGraphInfo();
                break;
            case 'No. of Cases Completed After TAT':
                getCasesCompletedAfterTatGraphInfo();
                break;
            case 'Avg. Offer Acceptance':
                getOfferAcceptanceGraphInfo();
                break;
            case 'Avg. First Year Attrition':
                getAvgYearAttritionGraphInfo();
                break;
            default:
            // Code to execute if none of the above cases match
        }
    }

    const getTimeToHireGraphInfo = () => {
        axios.get(GET_TIME_TO_HIRE_GRAPH, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data?.data?.yearly;
            if (data) {
                const years = Object.keys(data).sort();
                const latestYear = years[years.length - 1];
                const previousYear = years[years.length - 2];

                const currentAverage = data[latestYear]?.average_time_to_hire;
                const previousAverage = data[previousYear]?.average_time_to_hire;

                let comparisonResult;
                // let percentageChange = 0;

                if (currentAverage > previousAverage) {
                    comparisonResult = 'up';
                    // percentageChange = ((currentAverage - previousAverage) / previousAverage) * 100;
                } else if (currentAverage < previousAverage) {
                    comparisonResult = 'down';
                    // percentageChange = ((previousAverage - currentAverage) / previousAverage) * 100;
                } else {
                    comparisonResult = 'same';
                }
                setGraphType(comparisonResult);

                // Transforming the data
                const transformedData = Object.keys(data).map(year => [year, data[year].average_time_to_hire]);
                // Adding ["Year", title] at the first index
                transformedData.unshift(["Year", title]);
                setGraphData(transformedData);

                setPercentage(data[latestYear].percent_increase ?? 0)
            }


        }).catch((error) => {
            handleErrors(error)
        });
    }

    const getApplicationsGraphInfo = () => {
        axios.get(GET_APPLICATIONS_OPENING_GRAPH, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data?.data?.yearly;
            if (data) {
                const years = Object.keys(data).sort();
                const latestYear = years[years.length - 1];
                const previousYear = years[years.length - 2];

                const currentAverage = data[latestYear]?.averageApplications;
                const previousAverage = data[previousYear]?.averageApplications;

                let comparisonResult;
                // let percentageChange = 0;

                if (currentAverage > previousAverage) {
                    comparisonResult = 'up';
                    // percentageChange = ((currentAverage - previousAverage) / previousAverage) * 100;
                } else if (currentAverage < previousAverage) {
                    comparisonResult = 'down';
                    // percentageChange = ((previousAverage - currentAverage) / previousAverage) * 100;
                } else {
                    comparisonResult = 'same';
                }
                setGraphType(comparisonResult);

                // Transforming the data
                const transformedData = Object.keys(data).map(year => [year, data[year].averageApplications]);
                // Adding ["Year", title] at the first index
                transformedData.unshift(["Year", title]);
                setGraphData(transformedData);
                // setPercentage(comparisonResult === 'same' ? 0 : parseFloat(percentageChange.toFixed(1)))
                setPercentage(data[latestYear].percentIncrease ?? 0)
            }


        }).catch((error) => {
            handleErrors(error)
        });
    }

    const getCasesCompletedWithTatGraphInfo = () => {
        axios.get(GET_OFFER_ACCEPTANCE_GRAPH_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let dataObject = res?.data?.data;
            let data = dataObject?.tat_less_30
            
            if (data) {
                const years = Object.keys(data).sort();
                const latestYear = years[years.length - 1];
                const previousYear = years[years.length - 2];

                const currentAverage = data[latestYear]?.average_time_to_hire;
                const previousAverage = data[previousYear]?.average_time_to_hire;
                
                let comparisonResult;
                // let percentageChange = 0;

                if (currentAverage > previousAverage) {
                    comparisonResult = 'up';
                    // percentageChange = ((currentAverage - previousAverage) / previousAverage) * 100;
                } else if (currentAverage < previousAverage) {
                    comparisonResult = 'down';
                    // percentageChange = ((previousAverage - currentAverage) / previousAverage) * 100;
                } else {
                    comparisonResult = 'same';
                }
                setGraphType(comparisonResult);

                // Transforming the data
                const transformedData = Object.keys(data).map(year => [year, data[year].average_time_to_hire]);
                // Adding ["Year", title] at the first index
                transformedData.unshift(["Year", title]);
                setGraphData(transformedData);
                // setPercentage(comparisonResult === 'same' ? 0 : parseFloat(percentageChange.toFixed(1)))
                setPercentage(data[latestYear].percent_increase ?? 0)
            }

        }).catch((error) => {
            handleErrors(error)
        });
    }

    const getCasesCompletedAfterTatGraphInfo = () => {
        axios.get(GET_OFFER_ACCEPTANCE_GRAPH_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let dataObject = res?.data?.data;
            let data = dataObject?.tat_gte_30
            if (data) {
                const years = Object.keys(data).sort();
                const latestYear = years[years.length - 1];
                const previousYear = years[years.length - 2];

                const currentAverage = data[latestYear]?.average_time_to_hire;
                const previousAverage = data[previousYear]?.average_time_to_hire;

                let comparisonResult;
                // let percentageChange = 0;

                if (currentAverage > previousAverage) {
                    comparisonResult = 'up';
                    // percentageChange = ((currentAverage - previousAverage) / previousAverage) * 100;
                } else if (currentAverage < previousAverage) {
                    comparisonResult = 'down';
                    // percentageChange = ((previousAverage - currentAverage) / previousAverage) * 100;
                } else {
                    comparisonResult = 'same';
                }
                setGraphType(comparisonResult);

                // Transforming the data
                const transformedData = Object.keys(data).map(year => [year, data[year].average_time_to_hire]);
                // Adding ["Year", title] at the first index
                transformedData.unshift(["Year", title]);
                setGraphData(transformedData);
                // setPercentage(comparisonResult === 'same' ? 0 : parseFloat(percentageChange.toFixed(1)))
                setPercentage(data[latestYear].percent_increase ?? 0)
            }

        }).catch((error) => {
            handleErrors(error)
        });
    }

    const getOfferAcceptanceGraphInfo = () => {
        
    }

    const getAvgYearAttritionGraphInfo = () => {

    }

    const handleErrors = (error) => {
        if (error.response) {
            const { status, data } = error.response

            if (status === 400) {
                setMessage(data.message)
            } else if (status === 401 && data.name === 'TokenExpiredError') {
                logout()
            } else if (data.error) {
                if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(data.error.message)
                }
            } else {
                setMessage('An unknown error occurred')
            }
        } else {
            setMessage(error.message)
        }

        setShowErrorMessage(true)
    }

    const data = [
        ["Year", title],
        ["2003", 120],
        ["2004", 200],
        ["2005", 180],
        ["2006", 260],
        ["2007", 260],
    ];

    const downGraphData = [
        ["Year", title],
        ["2007", 260],
        ["2006", 260],
        ["2005", 180],
        ["2004", 200],
        ["2003", 120],
    ]

    const options = {
        curveType: "function",
        colors: ["#66C87B"], // Change line color to red
        hAxis: {
            textPosition: "none", // Remove x-axis labels
            gridlines: {
                color: "transparent", // Remove x-axis gridlines
            },
            baselineColor: 'none', // Hide x-axis baseline
        },
        vAxis: {
            textPosition: "none", // Remove y-axis labels
            gridlines: {
                color: "transparent", // Remove y-axis gridlines
            },
            baselineColor: 'none', // Hide y-axis baseline
        },
        legend: 'none', // Hide legend
        backgroundColor: 'transparent',
    };

    const optionsDownGraph = {
        curveType: "function",
        colors: ["#FF5151"], // Change line color to red
        hAxis: {
            textPosition: "none", // Remove x-axis labels
            gridlines: {
                color: "transparent", // Remove x-axis gridlines
            },
            baselineColor: 'none', // Hide x-axis baseline
        },
        vAxis: {
            textPosition: "none", // Remove y-axis labels
            gridlines: {
                color: "transparent", // Remove y-axis gridlines
            },
            baselineColor: 'none', // Hide y-axis baseline
        },
        legend: 'none', // Hide legend
        backgroundColor: 'transparent',
    };

    const getIcon = () => {
        switch (title) {
            case 'Avg. Time To Hire': {
                return imagePath.ic_clock_gray_bg;
            };
            case 'Avg. Applications Per Openings': {
                return imagePath.ic_application_gray_bg;
            };
            case 'Avg. Offer Acceptance': {
                return imagePath.ic_msg_gray_bg;
            };
            case 'Avg. First Year Attrition': {
                return imagePath.ic_attrition_gray_bg;
            };
            case 'No. of Cases Completed with TAT': {
                return imagePath.ic_timer_fill_gray_bg;
            };

            case 'No. of Cases Completed After TAT': {
                return imagePath.ic_timer_empty_gray_bg;
            };
        }
    }

    return (

        <div className='col-md-3'>
            <div className={`card-bg shadow-sm px-2 py-4 ms-4 mt-4 mb-6 ${isActive ? 'card-border-orange' : ''}`}
                style={{ height: '10.5rem', cursor: 'pointer', position: 'relative' }} // Add position relative
                onClick={onClickCard}>
                <div className='d-flex mb-4 align-items-center' style={{ height: '2.5rem' }}>
                    <img src={getIcon()} height={28} width={28} style={{ objectFit: 'contain' }} />
                    <div>
                        <label className='fs-7 ms-2'>{title}</label>
                    </div>
                </div>

                <div className='d-flex' style={{ position: 'relative', justifyContent: 'space-between', alignItems: 'center', height: '80%' }}>
                    <div id='values-div' className='d-flex ' style={{ alignItems: 'center', minWidth: '6rem' }}>
                        <label className='fs-2 fw-bolder ms-2' style={{ color: graphType === 'up' ? '#66C87B' : '#FF5151', maxWidth: '6rem' }}>{Math.ceil(parseFloat(value || '0'))}</label>
                        <label className='fs-8 ms-2' style={{ color: graphType === 'up' ? '#66C87B' : '#FF5151' }}>{unit}</label>
                    </div>
                    {/* line chart */}
                    <div id='chart-div' style={{ position: 'absolute', bottom: 0, right: -20, zIndex: 1 }}>
                        <div style={{ position: 'relative', width: '9rem' }}>
                            <div style={{ position: 'absolute', marginTop: '-10%', left: '28%', zIndex: '1' }}>
                                <img src={graphType === 'up' ? imagePath.ic_arrow_up_green : imagePath.ic_arrow_down_red} width={18} height={18} />
                                <label className='fs-8' style={{ color: graphType === 'up' ? '#66C87B' : '#FF5151' }}>{percentage}%</label>
                            </div>
                            <div style={{ width: '100%', height: '60px' }}>
                                {graphData && (
                                    <Chart
                                        className='ms-auto mt-4'
                                        chartType="LineChart"
                                        width="100%"
                                        height="60px"
                                        // data={graphType === 'up' ? data : downGraphData}
                                        data={graphData}
                                        options={graphType === 'up' ? options : optionsDownGraph}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>

    );
};

//make this component available to the app
export default InsightsCard;
