//import liraries
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './CreateCompetency.css'
import bannerimage from '../../../../app/modules/Setting/images/group.png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import axios from 'axios'
import { RecruiterCard } from '../../content/cards/RecruiterCard'
import ShowError from '../../content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_TA_URL = `${API_URL}/auth/user-list`
export const CREATE_TA_RESOURCE_URL = `${API_URL}/ta-ra/create`

type Props = {
    show: boolean
    handleClose: () => void
    updateResources: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const AddNewTALead = ({ show, handleClose, updateResources }: Props) => {
    const [dataFetched, setDataFetched] = useState(false)
    const [TAs, setTAs] = useState<any>([])
    const [TA, setTA] = useState<any>()
    const [taCheck, setTACheck] = useState(false)
    const { auth, logout } = useAuth()
    const [taQuery, setTAQuery] = useState('')
    const [taResults, setTAResults] = useState<any>([])
    const [taSelected, setTaSelected] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')

    const GetAllTA = async () => {
        await axios
            .get(GET_ALL_TA_URL, {
                params: {
                    role: 'ta_lead,none',
                    limit: 5000,
                },
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then(async (resp) => {
                let selectedTA = resp.data.data
                selectedTA = selectedTA.filter((obj) => obj !== null)
                if (selectedTA !== null && selectedTA !== undefined && selectedTA.length > 0) {
                    setTAs(selectedTA)
                }
            })
    }

    useEffect(() => {
        if (!dataFetched) {
            ; (async () => {
                await GetAllTA()
                setDataFetched(true)
            })()
        }
        return () => { }
    }, [TAs])

    // filter the list of TA
    const handleTAChange = (event) => {
        const query = event.target.value
        setTAQuery(query)
        let filteredResults = TAs.filter((item) => {
            let fullNameEmployeeId = `${item['name']} ${item['employee_id']}`
            return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
        })
        console.log('filteredTAResults: ', filteredResults)
        setTAResults(filteredResults.length > 0 ? filteredResults : TAs)
    }

    const handleTAClick = (item) => () => {

        setTA(item)
        let query = `${item['name']}`
        setTAQuery(query)
        setTAResults([])
        setTaSelected(true)
    }
    const displaySubmitButton = (event) => {
        event.preventDefault()
        setTACheck(true)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!TA) {
            setMessage('Please select TA Lead first and try again.')
            setShowErrorMessage(true)
            return
        }

        var allocationData = {
            ta_id: TA['user_id'],
        }
        var data = JSON.stringify(allocationData)
        let url = CREATE_TA_RESOURCE_URL
        axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => {
                console.log('res', res)

                // update list of Recruiters
                updateResources()
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message)
                    setShowErrorMessage(true)
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.name === 'TokenExpiredError'
                ) {
                    logout()
                } else if (error.response && error.response.data && error.response.data.error) {
                    // Handle error message from the API
                    // showToast(error.response.data.error.message);
                    setMessage(error.response.data.error.message)
                    setShowErrorMessage(true)
                    // handleClose();
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            })
        // handleClose()
        applyDefaultValues()
    }

    const applyDefaultValues = () => {
        setTAQuery('')
        setTA(null)
        setTACheck(false)
        setTaSelected(false)

        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <div >

                <div className=' no-header-model no-header-modelllad '>
                    <div className='modal-header  no-header-model no-header-modelllad'>
                        <h2></h2>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={applyDefaultValues}>
                            <img src={imagePath.ic_cross_white}></img>
                        </div>
                    </div>
                    <div className='mx-auto d-flex flex-column no-header-model'>
                        <img className='addcompetenctimage' src={bannerimage}></img>
                        <h3 className='fw-bolder fontsetrinh text-white my-5'>Add New TA Lead </h3>
                    </div>
                </div>

                <div className=' mb-10 px-lg-8'>
                    <div className='card'>
                        {/* begin::Beader */}

                        <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                            <form>
                                <div className='form-row d-flex flex-column mt-5 justify-content-center'>
                                    <div className='form-group col-md-12'>
                                        <div className='me-4'>
                                            <label className='form-label fs-6 fw-bolder '>Select a user</label>

                                            <input
                                                type='text'
                                                className='search-bar-style form-control w-140px'
                                                placeholder='Enter TA Name/Employee ID'
                                                value={taQuery}
                                                onChange={handleTAChange}
                                                onFocus={handleTAChange}
                                            />
                                            {taResults.length > 0 && (
                                                <ul className='form-control   w-140px' style={{ maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto' }}>
                                                    {taResults.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={handleTAClick(item)}
                                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                            className='hover-li'
                                                        >{`${item['name']} - ${item['employee_id']}`}</li>
                                                    ))}
                                                </ul>
                                            )}


                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    {taCheck ? null : (
                                        <button
                                            // type='submit'
                                            className='btn my-15 px-10 btnStyle my-5 fw-bolder text-white'
                                            // className={taSelected ? 'btn my-15 px-10 btnStyle my-5' : 'btn my-15 px-10 bgcolorofbutoonofsubmitoftheaddingnewrecruiter my-5'}
                                            onClick={(event) => displaySubmitButton(event)}
                                            disabled={taSelected ? false : true}
                                        >
                                            Next
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {taCheck ? (
                    <div>
                        <RecruiterCard
                            avatar={TA['profile_pic']}
                            name={TA['name']}
                            employeeId={TA['employee_id']}
                            email={TA['email']}
                            phoneNumber={TA['phone_number']}
                        />

                        <div >
                            <form>
                                <div className='d-flex justify-content-center'>
                                    <button
                                        // type='submit'
                                        className='btn px-10 btnStyle mb-10 fw-bolder text-white'
                                        onClick={(event) => handleSubmit(event)}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                ) : null}
            </div>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default AddNewTALead;
