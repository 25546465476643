import React, { useRef } from 'react'
// import
import resourceallocation from '../images/group.png'
import popup123 from '../images/information.png'
import { useState, useEffect } from 'react'
import RespourceTableWRTdpt from '../RespourceTableWRTdpt/Resourcetablewrtdpt'
import { useIntl } from 'react-intl'
import '../Settings.css'
import { AddNewRecruiter } from '../../../../_metronic/partials/modals/create-app-stepper/AddNewRecruiter'
import { POCpopupModel } from '../../../../_metronic/partials/modals/create-app-stepper/POCpopupModel'
import Recruiters from './Recruiters'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import ReactDOM from 'react-dom'
import CustomToast from '../../../../_metronic/partials/content/toasts/CustomToast'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import SuccessDialog from '../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog'
import ApprovingAuthoritiesTab from './ApprovingAuthoritiesTab'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_GROUPS_URL = `${API_URL}/group`
export const GET_ALL_POC_URL = `${API_URL}/poc-ra/get-pocs`
// export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_ALL_USERS_URL = `${API_URL}/auth/user-list`
export const GET_ALL_TA_URL = `${API_URL}/auth/user-list`
export const CREATE_RESOURCE_URL = `${API_URL}/poc-ra`
export const ALLOCATE_RESOURCE_URL = `${API_URL}/poc-ra/resource-allocate`

type RefType = {
  GetAllResources: () => Promise<void>
}

function ResourceAllocation() {
  const groupResourcesRef = useRef<RefType>(null)
  // function to call child function
  const handleGetAllResources = () => {
    groupResourcesRef.current?.GetAllResources()
  }

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModalPOPUP, setshowCreateAppModalPOPUP] = useState<boolean>(false)
  const [groupQuery, setGroupQuery] = useState('')
  const [groupResults, setGroupResults] = useState<any>([])
  const [groupHeadQuery, setGroupHeadQuery] = useState('')
  const [groupHeadResults, setGroupHeadResults] = useState<any>([])
  const [pocQuery, setPocQuery] = useState('')
  const [pocResults, setPocResults] = useState<any>([])
  const [taLeadQuery, setTaLeadQuery] = useState('')
  const [taLeadResults, setTaLeadResults] = useState<any>([])
  const groupRef = useRef<HTMLInputElement>(null)
  const groupHeadRef = useRef<HTMLInputElement>(null)
  const pocRef = useRef<HTMLInputElement>(null)
  const recruiterRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const intl = useIntl()
  const { auth, logout } = useAuth()
  const [active, setActive] = useState('POCs')
  const [Departments, setDepartments] = useState([
    {
      group_id: '',
      group_name: '',
    },
  ])
  const [Department, setDepartment] = useState<any>({
    group_id: '',
    group_name: '',
  })

  const [groupHeads, setGroupHeads] = useState<any>([{}])
  const [groupHead, setGroupHead] = useState<any>()
  const [Pocs, setPocs] = useState<any>([{}])
  const [Poc, setPoc] = useState<any>()
  const [taLeadD, setTaLeadD] = useState<any>([{}])
  const [taLead, setTaLead] = useState<any>()
  const [dataFetched, setDataFetched] = useState(false)

  const ChangeInActiveIndex = (ActiveState: string) => {
    setActive(ActiveState)
    if (ActiveState === 'POCs') {
      getAllUsers();
      GetAllPOC()
      getAllTALeads()
      GetAllDepartments()
    } else if (ActiveState == 'TA Team') {

    } else if (ActiveState === 'Approving Authorities') {

    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!Department.group_id) {
      setMessage("Group can't be empty.")
      setShowErrorMessage(true)
      return
    }
    if (!groupHead) {
      setMessage("Group Head can't be empty.")
      setShowErrorMessage(true)
      return
    }
    if (!Poc) {
      setMessage("POC can't be empty.")
      setShowErrorMessage(true)
      return
    }
    if (!taLead) {
      setMessage("Recruiter can't be empty.")
      setShowErrorMessage(true)
      return
    }

    var allocationData = {
      group_id: Department.group_id,
      group_head_id: groupHead.user_id,
      poc_id: Poc.user_id,
      ta_lead_id: taLead['user_id'],
    }
    setLoading(true)
    let url = CREATE_RESOURCE_URL
    axios
      .post(url, allocationData, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setGroupQuery('')
        setDepartment({
          group_id: '',
          group_name: '',
        })
        setGroupHead(null)
        setPoc(null)
        setTaLead(null)
        setGroupHeadQuery('')
        setPocQuery('')
        setTaLeadQuery('')
        setLoading(false)
        setSuccessMessage('Resource allocated successfully')
        setShowSuccessMessage(true)
        //  update the list of Resources w.r.t Groups
        handleGetAllResources()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
    //  navigate('/Positions')
  }

  const handleGroupClick = (item) => () => {
    let query = `${item['group_name']}`
    setGroupQuery(query)
    setGroupResults([])

    setDepartment({
      ...Department,
      group_id: item['group_id'],
      group_name: item['group_name'],
    })
  }

  const handleGroupHeadClick = (item) => () => {
    // check is the selected user is already selected as poc or ta lead or not if not set as group head else show error message
    if (item?.user_id === Poc?.user_id) {
      setMessage('POC and Group head could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if (item?.user_id === taLead?.user_id) {
      setMessage('TA Lead and Group head could not be same.');
      setShowErrorMessage(true);
      return;
    }
    let query = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
    setGroupHeadQuery(query)
    setGroupHeadResults([])
    setGroupHead(item)
  }

  const handlePocClick = (item) => () => {
    // check is the selected user is already selected as group head or ta lead or not if yes show error message else set as poc
    if (item?.user_id === groupHead?.user_id) {
      setMessage('Group head and POC could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if (item?.user_id === taLead?.user_id) {
      setMessage('TA Lead and POC could not be same.');
      setShowErrorMessage(true);
      return;
    }

    let query = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
    setPocQuery(query)
    setPocResults([])
    setPoc(item)
  }

  const handleTaLeadClick = (item) => () => {
    // check is the selected user is already selected as group head or poc or not if yes show error message else set as ta lead
    if (item?.user_id === groupHead?.user_id) {
      setMessage('Group head and TA Lead could not be same.');
      setShowErrorMessage(true);
      return;
    }
    if (item?.user_id === Poc?.user_id) {
      setMessage('POC and TA Lead could not be same.');
      setShowErrorMessage(true);
      return;
    }
    let query = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
    setTaLeadQuery(query)
    setTaLeadResults([])
    setTaLead(item)
  }

  // filter the list of Groups
  const handleGroupChange = (event) => {
    const query = event.target.value
    setGroupQuery(query)
    if (query === null || query === undefined || query === '') {
      setDepartment({
        group_id: '',
        group_name: '',
      })
    }
    let filteredResults = Departments.filter((item) => {
      let groupName = `${item['group_name']}`
      return groupName.toLowerCase().includes(query.toLowerCase())
    })
    setGroupResults(filteredResults.length > 0 ? filteredResults : Departments)
  }

  // filter the list of Group Heads
  const handleGroupHeadChange = (event) => {
    const query = event.target.value;
    setGroupHeadQuery(query);
    if (query === null || query === undefined || query === '') {
      setGroupHead(null);
    }
    let filteredResults = groupHeads.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    setGroupHeadResults(filteredResults.length > 0 ? filteredResults : groupHeads)
  }

  // filter the list of POCs
  const handlePOCChange = (event) => {
    const query = event.target.value
    setPocQuery(query)
    if (query === null || query === undefined || query === '') {
      setPoc(null);
    }
    let filteredResults = Pocs.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    setPocResults(filteredResults.length > 0 ? filteredResults : Pocs)
  }

  // filter the list of TA Lead
  const handleTaLeadChange = (event) => {
    const query = event.target.value
    setTaLeadQuery(query)
    if (query === null || query === undefined || query === '') {
      setTaLead(null);
    }
    let filteredResults = taLeadD.filter((item) => {
      let fullNameEmployeeId = `${item['first_name']} ${item['last_name']} ${item['employee_id']}`
      return fullNameEmployeeId.toLowerCase().includes(query.toLowerCase())
    })
    setTaLeadResults(filteredResults.length > 0 ? filteredResults : taLeadD)
  }

  const GetAllDepartments = async () => {
    await axios
      .get(GET_ALL_GROUPS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        setDepartments(resp.data.data)
        //   setPositions(resp.data.positions)
      }).catch((error) => {
        handleError(error);
      })
  }

  const GetAllPOC = async () => {
    await axios
      .get(GET_ALL_POC_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {

        setPocs(resp.data.pocs)

      })
      .catch((error) => {
        handleError(error);
      })
  }

  // get all users
  const getAllUsers = async () => {
    await axios.get(GET_ALL_USERS_URL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setGroupHeads(res.data?.data)
    }).catch((error) => {
      handleError(error);
    });
  }
  // get all ta lead
  const getAllTALeads = async () => {
    await axios
      .get(GET_ALL_TA_URL, {
        params: {
          role: 'ta_lead',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let taList: any = res.data?.data
        setTaLeadD(taList)
      })
      .catch((error) => {
        handleError(error);

      })
  }

  const handleError = (error) => {
    if (error.response && error.response.status === 400) {
      // showToast(error.response.data.message)
      setMessage(error.response.data.message)
      setShowErrorMessage(true)
    } else if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.name === 'TokenExpiredError'
    ) {
      logout()
    } else if (error.response?.data?.error) {
      if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
        logout()
      } else {
        setMessage(error.response.data.error.message)
        setShowErrorMessage(true)
      }
    } else {
      setMessage(error.message)
      setShowErrorMessage(true)
    }
  }

  const handleDocumentClick = (event: MouseEvent) => {
    if (groupRef.current && !groupRef.current.contains(event.target as Node)) {
      setGroupResults([]) // Hide the group results if clicked outside the input
    }
    if (groupHeadRef.current && !groupHeadRef.current.contains(event.target as Node)) {
      setGroupHeadResults([])
    }
    if (pocRef.current && !pocRef.current.contains(event.target as Node)) {
      setPocResults([])
    }
    if (recruiterRef.current && !recruiterRef.current.contains(event.target as Node)) {
      setTaLeadResults([])
    }
  }

  // Attach click event listener to the document
  // This is to detect clicks outside the component and hide the group results
  // Make sure to clean up the event listener when the component is unmounted
  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        setGroupResults([])
        setPocResults([])
        setTaLeadResults([])
      }
    }
    window.addEventListener('keydown', handleEscapeKey)
    return () => window.removeEventListener('keydown', handleEscapeKey)
  }, [])

  useEffect(() => {
    // if (!dataFetched) {
    (async () => {
      await getAllUsers()
      await GetAllPOC()
      await getAllTALeads()
      await GetAllDepartments()
      // setDataFetched(true)
    })()
    // }
  }, [])
  // }, [Pocs, taLeadD, Departments])

  return (
    <>
      <div className='card-bg mt-12 g-5 g-xl-8 px-10 pb-8 shadow-sm pt-8'>
        <div className='d-flex flex-wrap  flex-stack '>
          <div className='heading-competency d-flex mx-auto  flex-wrap my-2'>
            <div className=' d-flex justify-content-center gy-5 gx-xl-8'>
              <button
                className={`${active === 'POCs'
                  ? ' Onclicked-Active-Buttons fw-bolder fs-4   btn-lg  '
                  : ' Notclicked-Active-Buttons  fw-bolder fs-4   btn-lg '
                  } `}
                type='button'
                onClick={() => ChangeInActiveIndex('POCs')}
              >
                POCs
              </button>
              <button
                className={` ta-team-tab-borders ${active === 'TA Team'
                  ? ' Onclicked-Active-Buttons fw-bolder fs-4   btn-lg  '
                  : ' Notclicked-Right-Buttons  fw-bolder fs-4   btn-lg '
                  } `}
                type='button'
                onClick={() => ChangeInActiveIndex('TA Team')}
              >
                TA Team
              </button>
              <button
                className={`${active === 'Approving Authorities'
                  ? ' Onclicked-Active-Buttons borderradiusofRectuirese fw-bolder fs-4   btn-lg  '
                  : ' Notclicked-Right-Buttons  fw-bolder fs-4   btn-lg '
                  } `}
                type='button'
                onClick={() => ChangeInActiveIndex('Approving Authorities')}
              >
                Approving Authorities
              </button>
            </div>
          </div>
        </div>

        {/* POCs TAB */}
        {active === 'POCs' ? (
          <div>
            <div className='row my-10 justify-content-around   '>
              <div className='resourceallocation-image'>
                {' '}
                <img src={resourceallocation}></img>
              </div>
            </div>

            <div className='card-body pb-0 mb-10 mt-0 pt-0 '>
              <form>
                <div>
                  <div className=' form-row col-md-12' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='form-row mt-10 col-md-12 d-flex '>
                      <div className='form-group col-md-6 pe-4 d-flex flex-column '>
                        <label className='form-label  fs-6 fw-bolder '>Select Group</label>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <input
                            type='text'
                            className='search-bar-style form-control '
                            placeholder='Search  / Select a group'
                            value={groupQuery}
                            onChange={handleGroupChange}
                            onFocus={handleGroupChange}
                            ref={groupRef}
                            required
                          />
                          {groupResults.length > 0 && (
                            <ul className='form-control col-md-11  ' style={{
                              maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto', position: 'absolute',
                              width: 'calc(100% - 2px)',
                              top: '100%',
                              left: 0,
                              zIndex: 999,
                              border: '1px solid #ced4da',
                              borderRadius: '0.25rem',
                              backgroundColor: '#fff',
                            }}>
                              {groupResults.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={handleGroupClick(item)}
                                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                  className='hover-li'
                                >{`${item['group_name']}`}</li>
                              ))}
                            </ul>
                          )}
                        </div>{' '}
                      </div>
                      {/* <h3 className='col-md-2'></h3> */}
                      <div className='form-group col-md-6 ps-4 d-flex flex-column'>
                        <div className='d-flex align-items center'>
                          <label className='form-label fs-6 fw-bolder '>Allocate Group Head</label>

                        </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <input
                            type='text'
                            className='search-bar-style form-control   w-140px'
                            placeholder='Search / Select a Group Head'
                            value={groupHeadQuery}
                            onChange={handleGroupHeadChange}
                            onFocus={handleGroupHeadChange}
                            ref={groupHeadRef}
                            required
                          />
                          {groupHeadResults.length > 0 && (
                            <ul className='form-control   w-140px' style={{
                              maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto', position: 'absolute',
                              width: 'calc(100% - 2px)', // Adjust padding and border width if necessary
                              top: '100%',
                              left: 0,
                              zIndex: 999,
                              border: '1px solid #ced4da', // Border color for consistency
                              borderRadius: '0.25rem', // Border radius for consistency
                              backgroundColor: '#fff', // Background color for consistency
                            }}>
                              {groupHeadResults.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={handleGroupHeadClick(item)}
                                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                  className='hover-li'
                                >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=' form-row col-md-12' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='form-row mt-10 col-md-12 d-flex '>

                      {/* <h3 className='col-md-1'></h3> */}
                      <div className='form-group col-md-6 pe-4 d-flex flex-column'>
                        <div className='d-flex align-items center'>
                          <label className='form-label fs-6 fw-bolder '>Allocate POC</label>
                          <img
                            onClick={() => setshowCreateAppModalPOPUP(true)}
                            className='mx-2'
                            style={{ cursor: 'pointer', objectFit: 'cover' }}
                            src={popup123}
                            width={'18px'}
                            height={'18px'}
                          />
                        </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <input
                            type='text'
                            className='search-bar-style form-control   w-140px'
                            placeholder='Search / Select a POC'
                            value={pocQuery}
                            onChange={handlePOCChange}
                            onFocus={handlePOCChange}
                            ref={pocRef}
                            required
                          />
                          {pocResults.length > 0 && (
                            <ul className='form-control   w-140px' style={{
                              maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto', position: 'absolute',
                              width: 'calc(100% - 2px)', // Adjust padding and border width if necessary
                              top: '100%',
                              left: 0,
                              zIndex: 999,
                              border: '1px solid #ced4da', // Border color for consistency
                              borderRadius: '0.25rem', // Border radius for consistency
                              backgroundColor: '#fff', // Background color for consistency
                            }}>
                              {pocResults.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={handlePocClick(item)}
                                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                  className='hover-li'
                                >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                      {/* <h3 className='col-md-2'></h3> */}
                      <div className='form-group col-md-6 ps-4 d-flex flex-column'>
                        <label className='form-label fs-6 fw-bolder '> Allocate TA Lead</label>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <input
                            type='text'
                            className='search-bar-style form-control   w-140px'
                            placeholder='Search / Select a TA Lead'
                            value={taLeadQuery}
                            onChange={handleTaLeadChange}
                            onFocus={handleTaLeadChange}
                            ref={recruiterRef}
                            required
                          />
                          {taLeadResults.length > 0 && (
                            <ul className='form-control   w-140px' style={{
                              maxHeight: '200px', whiteSpace: 'nowrap', overflow: 'auto', position: 'absolute',
                              width: 'calc(100% - 2px)', // Adjust padding and border width if necessary
                              top: '100%',
                              left: 0,
                              zIndex: 999,
                              border: '1px solid #ced4da', // Border color for consistency
                              borderRadius: '0.25rem', // Border radius for consistency
                              backgroundColor: '#fff', // Background color for consistency
                            }}>
                              {taLeadResults.map((item, index) => (
                                <li
                                  key={index}
                                  onClick={handleTaLeadClick(item)}
                                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                  className='hover-li'
                                >{`${item['first_name']} ${item['last_name']} - ${item['employee_id']}`}</li>
                              ))}
                            </ul>
                          )}
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className='mx-auto d-flex justify-content-center my-10'>
              <button
                type='submit'
                className='btn fw-bolder update-button-levels mt-10'
                onClick={handleSubmit}
                disabled={!Department.group_id || !groupHead || !Poc || !taLead}
                style={{ color: 'white' }}>
                Submit
              </button>
            </div>
            <div className='mb-8' id='custom-toast-container'></div>
          </div >
        ) : active === 'TA Team' ? (
          <div>
            <Recruiters />
          </div>
        ) : active === 'Approving Authorities' ? (
          <div>
            <ApprovingAuthoritiesTab />
          </div>
        ) : (<></>)
        }
      </div >


      {active === 'POCs' ? (
        <>
          <div className='card-bg mt-12 g-5 g-xl-8 px-10 pb-8 shadow-sm'>
            {' '}
            <RespourceTableWRTdpt ref={groupResourcesRef} className='card-xxl-stretch mb-5 mb-xl-8 shadow' />
          </div>
        </>
      ) : active === 'TA Team' ? (
        <> </>
      ) : (
        <></>
      )}

      {showCreateAppModalPOPUP && <POCpopupModel show={true} handleClose={() => setshowCreateAppModalPOPUP(false)} />}

      {loading && <LoaderView message='Loading...' />}
      {showSuccessMessage && <SuccessDialog handleClose={() => setShowSuccessMessage(false)} message={successMessage} />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export default ResourceAllocation
