
import React, { useState, useEffect } from 'react';
import './CircularProgressBar.css'; // Import the CSS file for styling
import { Chart } from "react-google-charts";

type Props = {
    inProgress: any
    closedPositions: any
    unAttendedPositions: any
}

const CircularProgressBar = ({ inProgress, closedPositions, unAttendedPositions }: Props) => {
    // const [inProgress, setInProgress] = useState(10);
    // const [closedPositions, setClosedPositions] = useState(10);
    // const [unAttendedPositions, setUnAttendedPositions] = useState(10);

    const [totalPositions, setTotalPositions] = useState(0);
    const [inProgressPercentage, setInProgressPercentage] = useState(0);
    const [closedPositionsPercentage, setClosedPositionsPercentage] = useState(0);
    const [unAttendedPositionsPercentage, setUnAttendedPositionsPercentage] = useState(0);

    const radius = 50;
    const strokeWidth = 14;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const total = inProgress + closedPositions + unAttendedPositions;
        setTotalPositions(total);

        const inProgressPercent = (inProgress / total) * 100;
        setInProgressPercentage(inProgressPercent);

        const closedPositionsPercent = (closedPositions / total) * 100;
        setClosedPositionsPercentage(closedPositionsPercent);

        const unAttendedPositionsPercent = (unAttendedPositions / total) * 100;
        setUnAttendedPositionsPercentage(unAttendedPositionsPercent);
    }, [inProgress, closedPositions, unAttendedPositions]);

    const data = [
        ["Status", "Count", { role: "style" }],
        ["InProgress", inProgress, "#FFD2A8"],
        ["Closed", closedPositions, "#F57536"],
        ["Unattended", unAttendedPositions, "#F2F2F2"],

    ];

    const options = {
        pieHole: 0.7,
        is3D: false,
        legend: "none",
        pieSliceText: "none",
        colors: ['#FFD2A8', '#F57536', '#F2F2F2'],
        chartArea: { width: '100%', height: '100%' }, // Adjust the chart area
        margin: 0,
        backgroundColor: 'transparent',
    };

    return (
        // <div style={{ position: 'relative', marginTop:'-6rem', textAlign:'center' }}>
        //     <svg width="350" height="350" viewBox="0 0 200 200">

        //         <circle
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             cx="100"
        //             cy="100"
        //             r={radius}
        //             fill="transparent"
        //             stroke="#F2F2F2"
        //             strokeWidth={strokeWidth}
        //             strokeLinecap="round"
        //             strokeDasharray={circumference}
        //             strokeDashoffset={
        //                 circumference -
        //                 (inProgressPercentage / 100) * circumference -
        //                 (closedPositionsPercentage / 100) * circumference -
        //                 (unAttendedPositionsPercentage / 100) * circumference
        //             }
        //         />

        //         {/* Closed Positions circle */}
        //         <circle
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             cx="100"
        //             cy="100"
        //             r={radius}
        //             fill="transparent"
        //             stroke="#F57536"
        //             strokeWidth={strokeWidth}
        //             strokeLinecap="round"
        //             strokeDasharray={circumference}
        //             strokeDashoffset={
        //                 circumference -
        //                 (inProgressPercentage / 100) * circumference -
        //                 (closedPositionsPercentage / 100) * circumference
        //             }
        //         />

        //         {/* In Progress circle */}
        //         <circle
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             cx="100"
        //             cy="100"
        //             r={radius}
        //             fill="transparent"
        //             stroke="#FFD2A8"
        //             strokeWidth={strokeWidth}
        //             strokeLinecap="round"
        //             strokeDasharray={circumference}
        //             strokeDashoffset={circumference - (inProgressPercentage / 100) * circumference}
        //         />
        //         {/* Text for Background circle */}
        //         {unAttendedPositionsPercentage > 0 ? (<text
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             x={100 + radius * Math.cos(((closedPositionsPercentage + inProgressPercentage + unAttendedPositionsPercentage - 2) / 100) * 2 * Math.PI)}
        //             y={100 + radius * Math.sin(((closedPositionsPercentage + inProgressPercentage + unAttendedPositionsPercentage - 2) / 100) * 2 * Math.PI)}
        //             textAnchor="middle" fontSize="5" fontWeight="600" fill="red">
        //             {unAttendedPositionsPercentage.toFixed(0)}%
        //         </text>) : (<div></div>)}
        //         {/* Text for Closed Positions circle */}
        //         {closedPositionsPercentage > 0 ? (<text
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             x={100 + radius * Math.cos(((closedPositionsPercentage + inProgressPercentage - 2) / 100) * 2 * Math.PI)}
        //             y={100 + radius * Math.sin(((closedPositionsPercentage + inProgressPercentage - 2) / 100) * 2 * Math.PI)}
        //             textAnchor="middle" fontSize="5" fontWeight="600" fill="blue">
        //             {closedPositionsPercentage.toFixed(0)}%
        //         </text>) : (<div></div>)}
        //         {/* Text for In Progress Positions circle */}
        //         {inProgressPercentage > 0 ? (<text
        //             // className={animationStarted ? 'animated-circle' : ''}
        //             x={100 + radius * Math.cos(((inProgressPercentage - 2) / 100) * 2 * Math.PI)}
        //             y={100 + radius * Math.sin(((inProgressPercentage - 2) / 100) * 2 * Math.PI)}
        //             textAnchor="middle" fontSize="5" fontWeight="600" fill="blue">
        //             {inProgressPercentage.toFixed(0)}%
        //         </text>) : (<div></div>)}

        //         {/* Total value text */}
        //         <text x="50%" y="50%" textAnchor="middle" dy="0.3em" fontSize="8" fontWeight="600" fill="#484848">
        //             Total Positions
        //         </text>
        //         <text x="50%" y="55%" textAnchor="middle" dy="0.3em" fontSize="10" fontWeight="700" fill="#484848">
        //             {totalPositions}
        //         </text>
        //     </svg>
        // </div>

        <div >
            <div style={{ height: '16rem', width: '100%' }}>

                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="100%"
                    data={data}
                    options={options}
                />
                <div id='totalPositions' style={{ position: 'relative', transform: 'translate(-50%, -50%)', textAlign: 'center', marginLeft:'50%', marginTop:'-9rem' }}>
                    <div>
                        <label className='fs-6 fw-bolder' style={{ color: "#616670" }}>Total Positions</label>
                    </div>
                    <div>
                        <label className='fs-5 fw-bolder' style={{ color: "#2B2B2B" }}>{totalPositions}</label>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default CircularProgressBar;
