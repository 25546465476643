//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL
const HIRING_DIVERSITY_REPORT_URL = `${API_URL}/analytics/hiring-diversity`;

// create a component
const WorkplaceDiversityTable = () => {
    const intl = useIntl();
    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [diversityReport, setDiversityReport] = useState<any>();
    const [reportUrl, setReportUrl] = useState('');

    useEffect(() => {
        (async () => {
            await hiringDiversityReport();
            await getReportUrl();
        })()
    }, [year]);

    const getReportUrl = async () => {
        await axios.get(HIRING_DIVERSITY_REPORT_URL, {
            params: {
                year: year,
                report: true
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            const data = res?.data;
            setReportUrl(data?.reportUrl);

        }).catch((error) => {
            handleErrors(error)
        })
    }

    const hiringDiversityReport = async () => {
        setLoading(true);
        await axios.get(HIRING_DIVERSITY_REPORT_URL, {
            params: {
                year: year
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            const data = res?.data;
            setDiversityReport(data?.data);

        }).catch((error) => {
            setLoading(false);
            handleErrors(error)
        })
    }

    const handleErrors = (error) => {
        if (error.response) {
            const { status, data } = error.response

            if (status === 400) {
                setMessage(data.message)
            } else if (status === 401 && data.name === 'TokenExpiredError') {
                logout()
            } else if (data.error) {
                if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
                    logout()
                } else {
                    setMessage(data.error.message)
                }
            } else {
                setMessage('An unknown error occurred')
            }
        } else {
            setMessage(error.message)
        }

        setShowErrorMessage(true)
    }

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (e) => {
        e.preventDefault();
        if (reportUrl === null || reportUrl === undefined || reportUrl === '') {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(reportUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                // link.download = reportUrl.split('/').pop(); // Extracts file name from URL

                // Use optional chaining and nullish coalescing to handle undefined
                const filename = reportUrl.split('/').pop() ?? 'Recruitment_Report_Indicating_Workplace_Diversity.xlsx'; // Provide a default filename
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                // Show loading icon if isLoading state is true
                <div
                    className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                >
                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                </div>
            )}

            <div className='card-bg shadow-sm mb-10' >
                <div className='d-flex align-items-center px-6 py-8'>
                    <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '24rem' }}>Recruitment Report Indicating Workplace Diversity - {currentYear}</label>
                    <div className='d-flex align-items-center ms-auto'>

                        {/* year view */}
                        <div className='d-flex align-items-center mx-4'>

                            <input
                                className='py-2 px-2 fs-5 fw-semibold'
                                style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                                type='number'
                                inputMode='numeric'
                                placeholder='2024'
                                value={year}
                                max={currentYear}
                                min={2023}
                                onChange={(event) => handleChangeYear(event)}
                            />
                        </div>
                        {/* download report view */}
                        <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                            <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={(event) => handleDownloadFile(event)}>
                                <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                                <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                            </div>
                        </div>
                        <div>
                            <img className='cursor-pointer ms-4' src={imagePath.ic_refresh_orange} width={34} height={34}
                                onClick={() => {
                                    hiringDiversityReport();
                                    getReportUrl();
                                }} />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='table-responsive'>
                        <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                            <div className='scrollable-table-container'>
                                <table className='table align-middle table-borders' style={{ minWidth: '100%' }}>
                                    <thead>
                                        <tr className='fw-bold graycolor bg-light align-items-center'>
                                            <th className='ps-6 fw-bolder my-auto sticky-header' style={{ color: '#475467', whiteSpace: 'nowrap', verticalAlign: 'middle', maxWidth: '8rem', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 2, borderRight: '1px solid #EDF2F9' }}>{`Diversity`}</th>
                                            <th className='sticky-header ps-6 fw-bolder' style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 1 }}>
                                                <div className='text-center'>
                                                    <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Applications</div>
                                                    <div className='d-flex'>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Received</div>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Approved</div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='sticky-header ps-6 fw-bolder' style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 1 }}>
                                                <div className='text-center'>
                                                    <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Test</div>
                                                    <div className='d-flex'>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Assigned</div>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Passed</div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='sticky-header ps-6 fw-bolder' style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 1 }}>
                                                <div className='text-center'>
                                                    <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Interview</div>
                                                    <div className='d-flex'>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Applicants</div>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Selected</div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className='sticky-header ps-6 fw-bolder' style={{ border: '1px solid #EDF2F9', position: 'sticky', background: '#F5F8FA', zIndex: 1 }}>
                                                <div className='text-center'>
                                                    <div className='px-2 py-4' style={{ color: '#475467', borderBottom: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>Offer</div>
                                                    <div className='d-flex'>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%', borderRight: '1px solid #EDF2F9' }}>Issued</div>
                                                        <div className='px-2 py-4' style={{ color: '#475467', width: '50%' }}>Joined</div>
                                                    </div>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {diversityReport && Object.keys(diversityReport).map((key, index) => {
                                            const isEvenRow = index % 2 === 0
                                            const received = diversityReport[key]['applicationsReceived'];
                                            const approved = diversityReport[key]['applicationsApproved'];
                                            const assigned = diversityReport[key]['testAssigned'];
                                            const passed = diversityReport[key]['testPassed'];
                                            const applicants = diversityReport[key]['interviewApplicants'];
                                            const selected = diversityReport[key]['interviewSelected'];
                                            const issued = diversityReport[key]['offerIssued'];
                                            const joined = diversityReport[key]['Joined'];

                                            return (
                                                <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                    <td className='ps-6 fw-bolder' style={{ color: '#1D2939', background: isEvenRow ? 'white' : '#FCFCFC', borderRight: '1px solid #EDF2F9', height: '4rem', maxWidth: '8rem', left: 0, zIndex: 1 }}>
                                                        {key ? key : ''}
                                                    </td>
                                                    <td className='text-center ' style={{ border: '1px solid #EDF2F9' }}>
                                                        <div className='d-flex'>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{received}</div>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{approved}</div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center ' style={{ border: '1px solid #EDF2F9' }}>
                                                        <div className='d-flex'>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{assigned}</div>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{passed}</div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center ' style={{ border: '1px solid #EDF2F9' }}>
                                                        <div className='d-flex'>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{applicants}</div>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{selected}</div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center ' style={{ border: '1px solid #EDF2F9' }}>
                                                        <div className='d-flex'>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{issued}</div>
                                                            <div className='px-2 py-4' style={{ width: '50%' }}>{joined}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default WorkplaceDiversityTable;
