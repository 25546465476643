//import liraries
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap-daterangepicker/daterangepicker.css'; // Import DateRangePicker CSS
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, format, differenceInDays, parseISO, addMilliseconds } from 'date-fns';

const API_URL = process.env.REACT_APP_API_URL
const GET_EVENTS_URL = `${API_URL}/analytics/events`

// create a component
const InterviewSchedulingCalendarTable = () => {

    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedRange, setSelectedRange] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addDays(new Date(), 6));
    const [dates, setDates] = useState([]);
    const [positions, setPositions] = useState<any>([]);
    const [reportUrl, setReportUrl] = useState('');

    useEffect(() => {

        (async () => {
            fetchData();
        })();


    }, [startDate, endDate]);


    const fetchData = async () => {
        if (startDate && endDate) {
            const newDates: any = [];
            const daysDifference = differenceInDays(new Date(endDate), new Date(startDate));

            for (let i = 0; i <= daysDifference; i++) {
                // newDates.push(addDays(new Date(startDate), i));
                newDates.push(addDays(startDate, i));
            }

            setDates(newDates);
            await getAllEvents();
        } else {
            // const newDates: any = [];
            // for (let i = 0; i < 7; i++) {
            //     newDates.push(addDays(startDate, i));
            // }
            // setDates(newDates);
        }
    }

    // const positions = ["Position 1", "Position 2", "Position 3", "Position 4"];

    const getAllEvents = async () => {

        let params = {};
        let role = currentUser?.role;
        // Determine parameters based on the role
        if (role === 'ta_lead') {
            params['ta_lead_id'] = currentUser?.user_id;
        } else if (role === 'recruiter') {
            params['recruiter_id'] = currentUser?.user_id;
        } else if (role === 'poc') {
            params['poc_id'] = currentUser?.user_id;
        }
        if (startDate) {
            params['startDate'] = startDate.toLocaleDateString();
        }
        if (endDate) {
            params['endDate'] = endDate.toLocaleDateString();
        }
        setLoading(true);
        await axios.get(GET_EVENTS_URL, {
            params,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            let data = res?.data;
            setPositions(data?.data);
            setReportUrl(data?.reportUrl);

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const handleDownloadFile = (e) => {
        e.preventDefault();
        if (!reportUrl) {
            setMessage('Report URL not available!');
            setShowErrorMessage(true);
            return;
        }
        fetch(reportUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                // link.download = reportUrl.split('/').pop(); // Extracts file name from URL
                // Use optional chaining and nullish coalescing to handle undefined
                const filename = reportUrl.split('/').pop() ?? 'Talent_Acquisition_Interview_Scheduling_Calendar.xlsx'; // Provide a default filename
                link.download = filename;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    const handleDateRangeChange = (event, picker) => {
        setSelectedRange(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        let firstDate = picker.startDate.format('MM/DD/YYYY');
        let lastDate = picker.endDate.format('MM/DD/YYYY');

        setStartDate(new Date(firstDate));
        setEndDate(new Date(lastDate));

    };

    const handleCancelDatePicker = () => {
        setSelectedRange('');

    }


    return (
        <div>
            <div style={{ position: 'relative' }}>
                {loading && (
                    // Show loading icon if isLoading state is true
                    <div
                        className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                    >
                        <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                    </div>
                )}

                <div className='card-bg shadow-sm mb-10' >
                    <div className='d-flex align-items-center px-6 py-8'>
                        <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Talent Acquisition Interview Scheduling Calendar</label>
                        <div className='d-flex align-items-center ms-auto'>

                            {/* download report view */}
                            <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                                <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={(e) => handleDownloadFile(e)}>
                                    <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Calendar</label>
                                    <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                                </div>
                            </div>
                            {/* calendar view */}
                            <DateRangePicker onApply={handleDateRangeChange}
                                onCancel={handleCancelDatePicker}>
                                <img className='cursor-pointer ms-4' src={imagePath.ic_calendar_orange} width={34} height={34}
                                    onClick={() => { }} />
                            </DateRangePicker>
                            {/* refresh view */}
                            <div>
                                <img className='cursor-pointer ' src={imagePath.ic_refresh_orange} width={34} height={34}
                                    onClick={() => {
                                        fetchData();
                                    }} />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='table-responsive'>
                            <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                                <table className='table align-middle table-borders'>
                                    <thead>
                                        <tr className='fw-bold graycolor bg-light align-items-center'>
                                            <th className='ps-6 fw-bolder my-auto' style={{
                                                color: '#475467',
                                                whiteSpace: 'nowrap',
                                                verticalAlign: 'middle',
                                                minWidth: '16rem',
                                                position: 'sticky',
                                                left: 0,
                                                background: '#F5F8FA',
                                                zIndex: 1
                                            }}>{`Position`}</th>
                                            {dates.map((date, index) => (
                                                <th className='ps-6 fw-bolder' key={index} style={{ border: '1px solid #EDF2F9', whiteSpace: 'nowrap' }}>
                                                    <div className='text-center'>
                                                        <div className='px-2 py-4' style={{
                                                            color: '#475467',
                                                            borderBottom: '1px solid #EDF2F9'
                                                        }}>
                                                            {format(date, 'eee')}
                                                        </div>
                                                        <div className='px-2 py-4' style={{ color: '#475467' }}>
                                                            {format(date, 'dd-MMM-yy')}
                                                        </div>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(positions && positions.length > 0) && positions.map((data, positionIndex) => {
                                            let jobData = data?.job
                                            let scheduledTime = data?.scheduled_time
                                            let duration = data?.duration
                                            const scheduledDate = parseISO(scheduledTime);
                                            const scheduledDateString = format(scheduledDate, 'yyyy-MM-dd');
                                            const timeFrom = format(scheduledDate, 'hh:mm a');
                                            // Calculate the end time by adding the duration to the scheduled time
                                            const endDate = addMilliseconds(scheduledDate, duration);

                                            // Format the end time to HH:mm
                                            const timeTo = format(endDate, 'hh:mm a');

                                            return (
                                                <tr key={positionIndex} style={{ borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                    <td className='ps-6 fw-bolder' style={{
                                                        color: '#1D2939',
                                                        borderRight: '1px solid #EDF2F9',
                                                        height: '4rem',
                                                        minWidth: '16rem',
                                                        position: 'sticky',
                                                        left: 0,
                                                        background: 'white',
                                                        zIndex: 1,
                                                    }}>
                                                        {jobData?.title}
                                                    </td>
                                                    {dates.map((date, dateIndex) => {
                                                        // match dates here 
                                                        const currentDateString = format(date, 'yyyy-MM-dd');
                                                        const match = currentDateString === scheduledDateString;

                                                        return (
                                                            <td key={dateIndex} className='text-center' style={{ border: '1px solid #EDF2F9' }}>
                                                                <div className='d-flex justify-content-center'>
                                                                    {/* <div id='time-frame' className='px-2 py-4'>{match ? `${timeFrom} - ${timeTo}` : '-'}</div> */}

                                                                    {match ? (
                                                                        <div className='px-2 py-4 d-flex flex-column'>
                                                                            <div>{timeFrom}</div>
                                                                            <div>{timeTo}</div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='px-2 py-4'>-</div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                showErrorMessage && (
                    <ShowError
                        handleClose={() => setShowErrorMessage(false)}
                        message={message}
                    />
                )
            }
        </div >
    );
};

//make this component available to the app
export default InterviewSchedulingCalendarTable;
