export const isCandidatePath = (hostname) => {
  if (
    // hostname === 'localhost' ||
    hostname === 'bop-candidate-qa.isaacscode.com' ||
    hostname === 'qa-candidate.bop.com.pk' ||
    hostname === 'candidate.bop.com.pk' ||
    hostname === 'democandidate.isaacscode.com'
  ) {
    return true
  } else {
    return false
  }
}

// returns date into this format 8th Aug 23
export const getFormattedDate = (dateString) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const date = new Date(dateString)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear().toString().substr(-2) // Get the last two digits of the year

  let daySuffix
  switch (day) {
    case 1:
    case 21:
    case 31:
      daySuffix = 'st'
      break
    case 2:
    case 22:
      daySuffix = 'nd'
      break
    case 3:
    case 23:
      daySuffix = 'rd'
      break
    default:
      daySuffix = 'th'
      break
  }

  let formattedDate = `${day}${daySuffix} ${month}, ${year}`;
  return formattedDate;
}


export const numberToEnglishWord = (index) => {
  const numbersInEnglish = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
  return numbersInEnglish[index] || '';
}

export const getTotalYears = (workData) => {
  workData = workData[0]
  let totalYears = 0
  let totalMonths = 0
  let allKeys = Object.keys(workData?.experience)

  Object.keys(workData?.experience).map((key, index) => {
    const value = workData?.experience[key]['years']
    const experience = value?.split('.')
    const years = parseFloat(experience[0]) || 0
    const months = parseFloat(experience[1]) || 0

    totalYears += years
    totalMonths += months
  })
  // Convert excess months to years
  totalYears += Math.floor(totalMonths / 12)
  totalMonths %= 12
  let yearsString = (totalYears && totalYears !== 0) ? `${totalYears} yrs` : ''
  let monthsString = (totalMonths && totalMonths !== 0) ? `${totalMonths} mo` : ''
  let value = `${yearsString} ${monthsString}`
  return (yearsString || monthsString) ? value : '-'
}

export const getTotalBankingExperience = (workData) => {
  workData = workData[0]
  let totalYears = 0
  let totalMonths = 0
  if (workData) {
    Object.keys(workData?.experience).map((key, index) => {
      const type = workData?.experience[key]['type']
      if (type === 'banking_experience') {
        const value = workData?.experience[key]['years']
        const experience = value?.split('.')
        const years = parseFloat(experience[0]) || 0
        const months = parseFloat(experience[1]) || 0

        totalYears += years
        totalMonths += months
      }
    })
  }
  // Convert excess months to years
  totalYears += Math.floor(totalMonths / 12)
  totalMonths %= 12
  let yearsString = (totalYears && totalYears !== 0) ? `${totalYears} yrs` : ''
  let monthsString = (totalMonths && totalMonths !== 0) ? `${totalMonths} mo` : ''
  let value = `${yearsString} ${monthsString}`
  return (yearsString || monthsString) ? value : '-'
}