//import liraries
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError'
import { useNavigate, useParams } from 'react-router-dom';
import CustomPagination from '../../modules/dashboards/components/CustomPagination'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'
import { useAuth } from '../auth/core/Auth';
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
// const GET_POSITIONS_URL = `${API_URL}/position/get-poc-positions`;
const GET_POSITIONS_URL = `${API_URL}/position`


// create a component
const EmployeeDashboard = () => {
    const { auth, currentUser, logout } = useAuth()
    const navigate = useNavigate()
    const user_id = currentUser?.user_id

    const intl = useIntl()
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [allPositions, setAllPositions] = useState<any>([])
    const [totalPages, setTotalPages] = useState(1)
    const [params, setParams] = useState<any>({
        status: 'GROUP_HEAD_APPROVAL',
        order: 'updated_at',
        sort: 'DESC',
        page: 1,
        limit: 10,
        total: 0,
    })
    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState('asc')

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc'
        const sorted = [...allPositions].sort((a, b) => {
            const valueA = a[field]
            const valueB = b[field]
            if (valueA < valueB) return direction === 'asc' ? -1 : 1
            if (valueA > valueB) return direction === 'asc' ? 1 : -1
            return 0
        })

        setAllPositions(sorted)
        setSortField(field)
        setSortDirection(direction)

        let data = { ...params }
        data.order = field
        data.sort = direction === 'asc' ? 'ASC' : 'DESC'
        setParams(data)
    }

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />
        } else {
            return <FaSort />
        }
    }

    // fetching list of all positions
    const getCreatedPositions = async () => {
        let data = { ...params }
        data.group_head_id = user_id
        const filteredParams = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
        )

        await axios.get(GET_POSITIONS_URL, {
            params: filteredParams,
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
            .then((res) => {
                let data = res?.data
                let pagination = data?.pagination

                if (params.page !== pagination?.page || params.limit !== pagination?.limit || params.total !== pagination?.total) {
                    let paramsData = { ...params }
                    paramsData.page = pagination?.page || 1
                    paramsData.limit = pagination?.limit || 10
                    paramsData.total = pagination?.total || 0
                    setParams(paramsData)
                }
                setTotalPages(pagination?.totalPages || 0)

                const positions = data?.data

                if (positions) {
                    setAllPositions(positions)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message)
                    setShowErrorMessage(true)
                } else if (
                    error.response &&
                    error.response.status === 401 &&
                    error.response.data &&
                    error.response.data.name === 'TokenExpiredError'
                ) {
                    logout()
                } else if (error.response?.data?.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout()
                    } else {
                        setMessage(error.response.data.error.message)
                        setShowErrorMessage(true)
                    }
                } else {
                    setMessage(error.message)
                    setShowErrorMessage(true)
                }
            })
    }

    useEffect(() => {
        (async () => {
            await getCreatedPositions()
        })()
    }, [params])

    const handlePageChange = (page) => {
        let data = { ...params }
        data.page = page
        setParams(data)
    }

    const handleItemsPerPageChange = (event) => {
        let data = { ...params }
        data.limit = event.target.value
        setParams(data)
    }

    return (
        <div>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <div className='col-md-12 dashboard-top'>
                {/* <h1>Dashboard</h1> */}
                <h3>
                    <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
                    <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>My Dashboard
                </h3>
            </div>

            <div className='col-md-12 card-bg shadow-sm pt-8 px-8 mt-10 pb-10' style={{ borderRadius: '1rem', alignSelf: 'center' }}>
                <label className='fs-4 fw-bolder mb-8'>Requisition Approval Pending</label>
                <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-4 table-borders'>
                        <thead>
                            <tr className='fw-bold graycolor bg-light'>
                                <th
                                    onClick={() => sortByField('title')}
                                    scope='col'
                                    className='align-items-center ps-4'
                                    style={{ width: '10rem', color: '#000000', whiteSpace: 'nowrap' }}
                                >
                                    <label className=' form-label fw-bolder fs-6 me-2'>Functional Designation</label>
                                    {renderSortIcon('title')}
                                </th>
                                <th onClick={() => sortByField('max_basic_salary_per_month')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Max Basic Salary / Month</label>
                                    {renderSortIcon('max_basic_salary_per_month')}
                                </th>
                                <th onClick={() => sortByField('max_total_remuneration_per_month')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Max Gross Salary / Month</label>
                                    {renderSortIcon('max_total_remuneration_per_month')}
                                </th>
                                <th onClick={() => sortByField('max_grade_range')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Maximum Grade Range</label>
                                    {renderSortIcon('max_grade_range')}
                                </th>
                                <th onClick={() => sortByField('no_of_positions')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>No. of Positions</label>
                                    {renderSortIcon('no_of_positions')}
                                </th>
                                <th onClick={() => sortByField('location')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Location</label>
                                    {renderSortIcon('location')}
                                </th>
                                <th onClick={() => sortByField('contact_type')} scope='col' style={{ whiteSpace: 'nowrap' }}>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Contract Type</label>
                                    {renderSortIcon('contact_type')}
                                </th>
                                <th scope='col' className=' form-label fw-bolder fs-6'>
                                    <label className=' form-label fw-bolder fs-6 me-2'>Action</label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPositions &&
                                allPositions.map((row, index) => {
                                    let salaryPerMonth: any = row?.max_basic_salary_per_month ? parseInt(row?.max_basic_salary_per_month) : 0
                                    salaryPerMonth = salaryPerMonth.toLocaleString()
                                    let RemunerationPerMonth: any = row?.max_total_remuneration_per_month
                                        ? parseInt(row?.max_total_remuneration_per_month)
                                        : 0
                                    RemunerationPerMonth = RemunerationPerMonth.toLocaleString()
                                    const isEvenRow = index % 2 === 0
                                    return (
                                        <tr key={index} style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                                            <td className=' fs-6 ps-2'>{row.title}</td>
                                            <td>{`PKR ${salaryPerMonth}`}</td>
                                            <td>{`PKR ${RemunerationPerMonth}`}</td>
                                            <td>{row.max_grade_range}</td>
                                            <td>{row.no_of_positions ? row.no_of_positions : '1'}</td>
                                            <td>{row.location}</td>
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <span className='me-4'>{row.contact_type}</span>
                                                </div>
                                            </td>
                                            <td className='pe-2'>
                                                <button
                                                    className='candi-btn2 text-white btn text-white fw-bolder fs-6'
                                                    style={{ whiteSpace: 'nowrap' }}
                                                    onClick={() => {
                                                        // let positionId = row?.batch_id ? row?.batch_id : row.position_id
                                                        // goto requisition approval page and pass group_head_id and position_id in url
                                                        navigate(`/public/requisition_approval/${user_id}/${row.position_id}`);
                                                    }}
                                                >
                                                    Review Requisition
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex col-md-12 align-items-center mt-6'>
                    <div className='col-md-4'>
                        <label>{`Showing ${allPositions ? allPositions.length : 0} of ${params.total} results.`}</label>
                    </div>
                    <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                        <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
                    </div>

                    <div className='col-md-4 '>
                        <div className='ms-auto d-flex flex-end align-items-center'>
                            <select
                                className='me-2 pagination-input'
                                style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                                id='selectValues'
                                // value={itemsPerPage}
                                value={params.limit}
                                onChange={handleItemsPerPageChange}
                            >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                            </select>

                            <div className='me-2'>
                                <label>Items per page</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
        </div>
    );
};

//make this component available to the app
export default EmployeeDashboard;
