//import liraries
import React, { useEffect, useState } from 'react';

import './AddScoreInterviewExternal.css';
import interViewHeaderIcon from '../../../_metronic/partials/modals/images/interview@2x copy.png';
import UserIcon from '../../../_metronic/partials/modals/JobFunnelsModels/img/Group 9681.png';
import ViewCandidateProfile from '../../../_metronic/partials/modals/JobFunnelsModels/ViewCandidateProfile';
import { useAuth } from '../auth/core/Auth';
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';

// import { useAuth } from '../../../../app/modules/auth/core/Auth'

const API_URL = process.env.REACT_APP_API_URL
const GET_DATA_URL = `${API_URL}/jobapplication/show-external-page-data-multiple`;
const ADD_SCORE_MULTIPLE_URL = `${API_URL}/panel/add-score-multiple`;

// create a component
const InterviewScoringExternal = () => {
    const { currentUser, auth, logout } = useAuth();
    const navigate = useNavigate();
    const [showCandidateProfile, setShowCandidateProfile] = useState(false);
    const [currentCandidate, setCurrentCandidate] = useState<any>();
    const [jobData, setJobData] = useState<any>();
    const [candidates, setCandidates] = useState<any>();
    const [allJobApplicationIds, setAllJobApplicationIds] = useState<any>([]);
    const [currentStage, setCurrentStage] = useState('');
    const [memberId, setMemberId] = useState('');
    const [seekConsent, setSeekConsent] = useState('No');
    // const [isRelativeEmployee, setIsRelativeEmployee] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [characteristicList, setCharacteristicList] = useState<any>([
        {
            heading: 'Appearance & outward personality',
            description: 'Appropriately dressed and presentable, Body language etc.'
        },
        {
            heading: 'Educational background',
            description: 'Quality of qualifications both academic and professional for job in question.'
        },
        {
            heading: 'Intellectual disposition',
            description: 'Articulate, intelligent, aware of current affairs/issues and generally well read.'
        },
        {
            heading: 'Leadership potential',
            description: 'Vision, strategic thinking & planning skills. Is self-motivated & can motivate others.'
        },
        {
            heading: 'Open-ness and inter-personal skills',
            description: 'Open to divergent views. Positive attitude and have sound interpersonal skills.'
        },
        {
            heading: 'Assertiveness & self confidence',
            description: 'Sure of himself/herself clear about personal & organization goals & objectives'
        },
        {
            heading: 'Versatility & innovativeness',
            description: 'Variety of exposure in academics, job, voluntary work, sports, hobbies, travel, languages etc.'
        },
        {
            heading: 'Professional knowledge',
            description: 'In banking generally or in any specific area as required by job in question.'
        },
        {
            heading: 'Relevance of previous experience',
            description: 'In job related areas.'
        },
    ])
    const [competencies, setCompetencies] = useState<any>();
    const [scoreData, setScoreData] = useState<any>();

    const handleCharacteristicChange = (event, characteristic, candidate, index) => {
        event.preventDefault();
        let selectedScore = event.target.value;
        let jobAppId = candidate?.jobapp;
        let characteristicHeading = characteristic?.heading;

        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                // Find the characteristic in criteria_score and update its score
                let criteria_score = candidateData.criteria_score;
                // Find the object with a matching name in criteria_score
                // Get the keys of the object
                let keys = Object.keys(criteria_score);
                // Iterate over the keys
                for (let key of keys) {
                    // Check if the characteristicHeading matches the name
                    if (criteria_score[key].name === characteristicHeading) {
                        // Update the score
                        criteria_score[key].score = selectedScore;
                        break; // Stop iterating once the update is done
                    }
                }

                // Calculate total scores
                let totalObtainScores = 0;
                for (let key in criteria_score) {
                    if (criteria_score.hasOwnProperty(key)) {
                        totalObtainScores += parseInt(criteria_score[key].score);
                    }
                }
                candidateData.criteria_obtained_score = totalObtainScores;
            }
            console.log('newData: ', newData);
            return newData;
        });
    };

    const getAvgResult = (candidate) => {
        let jobAppId = candidate?.jobapp;
        let newData = [...scoreData];

        // Find the candidate by jobapp_id
        let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

        let criteriaPercentage = '0';
        if (candidateData) {

            let totalObtainedCriteriaScore = candidateData.criteria_obtained_score;
            let totalPossibleCriteriaScore = candidateData.total_criteria_score;
            criteriaPercentage = ((totalObtainedCriteriaScore / totalPossibleCriteriaScore) * 100 || 0).toFixed(2);
            console.log('criteriaPercentage: ', candidateData)
        }


        return criteriaPercentage;
    }

    const handleCompetenciesChange = (event, competency, candidate, index) => {
        event.preventDefault();
        let selectedScore = event.target.value;
        let jobAppId = candidate?.jobapp;


        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                // Find the characteristic in criteria_score and update its score
                let comp_score = candidateData.comp_score;
                // Find the object with a matching name in criteria_score
                // Get the keys of the object
                let keys = Object.keys(comp_score);
                // Iterate over the keys
                for (let key of keys) {
                    // Check if the competency matches the name
                    if (comp_score[key].name === competency) {
                        // Update the score
                        comp_score[key].score = selectedScore;
                        break; // Stop iterating once the update is done
                    }
                }
            }

            return newData;
        });
    };
    const onCommentChange = (event, candidate, index) => {
        let value = event.target.value;

        let jobAppId = candidate?.jobapp;
        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                candidateData.comment = value;
            }

            return newData;
        });
    }

    const isRelativeEmployee = (candidate) => {
        let jobAppId = candidate?.jobapp;
        let data = [...scoreData];
        // Find the candidate by jobapp_id
        let candidateData = data.find(evaluation => evaluation.jobapp_id === jobAppId);
        let value = candidateData.blood_relation['relativeEmployee']
        return value === 'yes';
    }

    const handleChangeIsRelative = (event, candidate) => {
        let value = event.target.value;
        let jobAppId = candidate?.jobapp;

        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                candidateData.blood_relation['relativeEmployee'] = value;
            }

            return newData;
        });
    }

    const handleChangeRelationInfo = (event, candidate) => {
        let { name, value } = event.target;
        if (name === 'cnic') {
            // Remove all non-digit characters from the input
            value = value.replace(/\D/g, '')
            // Add hyphen after 5 digits
            if (value.length > 5) {
                value = value.slice(0, 5) + '-' + value.slice(5)
            }
            // Add hyphen before last digit.
            if (value.length > 13) {
                value = value.slice(0, 13) + '-' + value.slice(13)
            }
        }
        let jobAppId = candidate?.jobapp;

        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                candidateData.blood_relation[name] = value;
            }

            return newData;
        });

    }

    // const handleRadioChange = (value) => {
    //     setIsRelativeEmployee(value === 'yes');
    // };

    const handleSeekConsentChange = (event) => {
        setSeekConsent(event.target.value);
    }

    const handlePreferenceChange = (event, candidate, index) => {
        let value = event.target.value;

        let jobAppId = candidate?.jobapp;
        setScoreData(prevData => {
            // Create a copy of the array
            let newData = [...prevData];

            // Find the candidate by jobapp_id
            let candidateData = newData.find(evaluation => evaluation.jobapp_id === jobAppId);

            if (candidateData) {
                candidateData.orderPreference = value;
            }

            return newData;
        });
    }

    const handleSave = async (event) => {
        event.preventDefault();
        setIsSubmitted(true);
        let allScores = [...scoreData];
        // calculating obtained scores
        allScores.forEach((candidate) => {
            let criteriaScores = Object.values(candidate.criteria_score);
            let compScores = Object.values(candidate.comp_score);

            // Calculate total score for criteria_score
            let totalObtainedCriteriaScore: any = criteriaScores.reduce((total: any, score: any) => total + parseInt(score.score,), 0);
            candidate.criteria_obtained_score = totalObtainedCriteriaScore;

            // Calculate total score for comp_score
            let totalObtainedCompScore: any = compScores.reduce((total: any, score: any) => total + parseInt(score.score), 0);
            candidate.comp_obtained_score = totalObtainedCompScore;

            // Calculate percentage for criteria_score
            let totalPossibleCriteriaScore = candidate.total_criteria_score;
            let criteria_percentage = (totalObtainedCriteriaScore / totalPossibleCriteriaScore) * 100 || 0;

            // Calculate percentage for comp_score
            let totalPossibleCompScore = candidate.total_comp_score;
            let comp_percentage = (totalObtainedCompScore / totalPossibleCompScore) * 100 || 0;

            let overallPercentage = (criteria_percentage + comp_percentage) / 2;
            candidate.percentage = overallPercentage;
        });

        const formattedData = allScores.reduce((result, item, index) => {
            result[index] = { ...item };
            return result;
        }, {});

        let formInfo = {
            mustSeekConsent: seekConsent,
            candidatesScores: formattedData
        }

        let data = {
            jobapp_list: allJobApplicationIds,
            name: currentStage,
            member_id: memberId,

            assigned_score: formInfo
        };

        setLoading(true);
        await axios.post(ADD_SCORE_MULTIPLE_URL, data,
            //     {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setLoading(false);
            console.log('response', res)
            closeCurrentPage();

        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });

    }

    const closeCurrentPage = () => {
        // Wait for 3 seconds and close the current page
        setTimeout(() => {
            // window.close();
            navigate('/');
        }, 3000);
    };

    const getAllData = async () => {
        // Get the URL search parameters
        const searchParams = new URLSearchParams(window.location.search);

        // Get the values for jobapp_id and name
        const jobappIds = searchParams.get('jobapp_id');
        const stage: any = searchParams.get('name');
        const currentMemberId: any = searchParams.get('member_id');

        // Update your state or perform any other actions with the values

        const fetchedJobAppIds = jobappIds?.split(',');

        let data = {
            jobapp_list: fetchedJobAppIds,
            stage: stage
        }
        await axios.post(GET_DATA_URL, data,
            //     {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {

            setAllJobApplicationIds(fetchedJobAppIds);
            setCurrentStage(stage);
            setMemberId(currentMemberId);

            let job = res?.data?.externalPageData?.jobData;
            setJobData(job);
            let candidatesInfo = res?.data?.externalPageData?.candidates;
            if (candidatesInfo) {
                let candidateScores: any = [];
                candidatesInfo.map((candidate) => {
                    let data = {
                        "jobapp_id": candidate?.jobapp,
                        "total_comp": job?.competencyNames?.length,
                        "total_criteria": characteristicList?.length,
                        "total_comp_score": (job?.competencyNames?.length * 5),
                        "total_criteria_score": (characteristicList?.length * 5),
                        "criteria_obtained_score": 0,
                        "comp_obtained_score": 0,
                        "percentage": 0,
                        "blood_relation": {
                            "name": "",
                            "relation": "",
                            "cnic": "",
                            "relativeEmployee": "no",
                            "designation": "",
                            "department": "",
                            "location": ""
                        },
                        "criteria_score": (() => {
                            const result = {};
                            characteristicList?.forEach((characteristic, index) => {
                                result[index] = {
                                    "name": characteristic?.heading,
                                    "score": "0",
                                };
                            });
                            return result;
                        })(),
                        "comp_score": (() => {
                            const result = {};
                            job?.competencyNames?.forEach((comp, index) => {
                                result[index] = {
                                    "name": comp,
                                    "score": "0",
                                }
                            });
                            return result
                        })(),
                        "comment": "",
                        "orderPreference": "0"
                    };
                    candidateScores.push(data);
                })
                setScoreData(candidateScores);
            }
            setCandidates(candidatesInfo);
            setCompetencies(job?.competencyNames);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })
    }

    useEffect(() => {
        (() => {
            getAllData();
        })();
    }, []);



    return (
        <>
            <div style={{ backgroundColor: 'white', height: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '3rem',
                        justifyContent: 'center',
                    }}
                >
                    <img src={interViewHeaderIcon} className='interview-header-icon me-4'
                        style={{ height: '5vh' }}
                    />
                    <h2 className='interview-header-container-text'>Candidate Assessment Form</h2>
                </div>
                {/* job description view */}
                <div className='bordered-card shadow-sm mt-10 mx-12'>

                    <div className='d-flex flex-column justify-content-center '>
                        <div className='grey-chip px-10 mt-6 mx-auto '>
                            <div className='d-flex align-items-center'>
                                <label className='form-label fs-5 fw-bolder me-4' style={{width:'4rem'}}>Position: </label>
                                <label className='form-label fs-5 fw-bolder '>{` ${jobData?.position_title}`}</label>
                            </div>
                            <div className='d-flex align-items-center'>
                                <label className='form-label fs-5 fw-bolder me-4' style={{width:'4rem'}}>Grade: </label>
                                <label className='form-label fs-5 fw-bolder '>{`${jobData?.grade}`}</label>
                            </div>
                        </div>
                    </div>
                    <hr style={{ backgroundColor: 'lightgray', height: '1px' }} />
                    <div className='d-flex align-items-center px-8'>
                        <img src={interViewHeaderIcon} width={24} />
                        <label className='fw-bolder fs-6 form-label ms-4'>Job Description</label>
                    </div>
                    <hr style={{ backgroundColor: 'lightgray', height: '1px' }} />
                    <div className='px-8 mb-8'>
                        {/* <label className='fs-7 fw-regular'>{jobData?.job_description}</label> */}
                        {ReactHtmlParser(jobData?.job_description)}
                    </div>
                </div>

                {/* heading view */}
                <div className='d-flex mx-12 mt-12'>
                    <img src={interViewHeaderIcon} width={26} />
                    <label className='fw-bolder fs-4 form-label ms-4'>Characteristic Assessment</label>
                </div>
                <div style={{ width: '100%' }}>
                    <label style={{ width: '94%' }} className='grey-chip fw-bolder fs-6 mx-12 mt-8'>{'Rank the candidate according to this criteria 1 = Not Satisfactory, 2 = Just Acceptable, 3 = Acceptable, 4 = Very Good, 5 = Excellent'}</label>
                </div>
                <div className='bordered-card shadow-sm mt-4 mx-12 '>
                    <div className='table-responsive px-8'>
                        <table className="table px-4 my-2" >
                            <thead>
                                <tr>
                                    <th rowSpan={2} className='form-label fs-4 fw-bolder'>Characteristic</th>
                                    <th colSpan={candidates ? candidates.length : 0} className='justify-content-center align-items-center form-label fs-4 fw-bolder text-center'>Candidates</th>
                                </tr>
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <th key={index}>
                                                <div className='d-flex justify-content-center align-items-center' >
                                                    <img
                                                        src={candidate['profilePic'] !== null ? candidate['profilePic'] : UserIcon}
                                                        style={{ cursor: 'pointer', objectFit: 'cover', width: '34px', height: '34px', border: '1px solid #F36523', borderRadius: '17px' }}
                                                        className="selected-interviewer-icon-sched me-2"
                                                        alt=""
                                                        onClick={() => {
                                                            setCurrentCandidate(candidate);
                                                            setShowCandidateProfile(true);
                                                        }}
                                                    />
                                                    <div className='d-flex flex-column'>
                                                        <label className='fs-5 fw-bolder'>{`${candidate['first_name']} ${candidate['last_name']}`}</label>
                                                        <label className='fs-7 fw-semibold'>{`${candidateAvailable ? 'Available' : 'Not Available'}`}</label>
                                                    </div>
                                                </div>
                                            </th>
                                        )
                                    }) : (<div></div>)}
                                </tr>
                            </thead>

                            <tbody >
                                {characteristicList && characteristicList.map((characteristic, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='d-flex flex-column' style={{ maxWidth: '290px', overflow: 'hidden' }}>
                                                <label id='heading' className='fs-6 fw-bolder form-label'>{characteristic?.heading}</label>
                                                <label id='description' className='fs-6 fw-regular form-label'>{characteristic?.description}</label>
                                            </td>
                                            {candidates ? candidates.map((candidate, index) => {
                                                let candidateAvailable = true;
                                                if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                                    candidateAvailable = false;
                                                }
                                                return (
                                                    <td key={candidate?.id}>
                                                        <select
                                                            // name='status'
                                                            data-control='select2'
                                                            data-hide-search='true'
                                                            className='form-select   w-140px'
                                                            defaultValue='Select an option'
                                                            placeholder='Select an option'
                                                            // value={maritalStatus}
                                                            disabled={!candidateAvailable}
                                                            required
                                                            onChange={(event) => handleCharacteristicChange(event, characteristic, candidate, index)}
                                                        >
                                                            <option value='Select an option'>Select an option</option>
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                        </select>
                                                    </td>
                                                )
                                            }) : (<div></div>)}
                                        </tr>
                                    )
                                })}
                                {/* show average result */}
                                <tr >
                                    <td className='d-flex flex-column' style={{ maxWidth: '290px', overflow: 'hidden' }}>
                                        <label id='heading' className='fs-6 fw-bolder form-label'>{'Overall Assessment'}</label>
                                        <label id='description' className='fs-6 fw-regular form-label'>{"Candidate’s overall assessment for job."}</label>
                                    </td>
                                    {candidates ? candidates.map((candidate, index) => {
                                        return (
                                            <td key={candidate?.id}>
                                                <label className='form-control w-140px'>{getAvgResult(candidate)}</label>
                                            </td>
                                        )
                                    }) : (<div></div>)}
                                </tr>

                                <tr>
                                    <td className='d-flex flex-column'>
                                        <label className='fs-6 fw-bolder form-label'>Comments</label>
                                    </td>
                                    {candidates ? candidates.map((candidate, index) => {
                                        return (
                                            <td key={index}>
                                                <textarea
                                                    id="commentsTextarea"
                                                    placeholder='Type Here'
                                                    value={candidate['comments']}
                                                    onChange={(event) => onCommentChange(event, candidate, index)}
                                                    rows={4} // You can set the number of visible rows
                                                    cols={20} // You can set the number of visible columns
                                                />

                                            </td>
                                        )
                                    }) : (<div></div>)}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                {/* competencies grading view */}
                <div className='d-flex mx-12 mt-12'>
                    <img src={interViewHeaderIcon} width={26} />
                    <label className='fw-bolder fs-4 form-label ms-4'>{`Competency Assessment (Optional)`}</label>
                </div>
                <div style={{ width: '100%' }}>
                    <label style={{ width: '94%' }} className='grey-chip fw-bolder fs-6 mx-12 mt-8'>{'Rank the candidate according to this criteria 1 = Basic, 2 = Intermediate, 3 = Advance, 4 = Expert, 5 = Excellent'}</label>
                </div>
                <div className='bordered-card shadow-sm mt-4 mx-12 '>
                    <div className='table-responsive px-8'>
                        <table className="table px-4 my-2">
                            <thead>
                                <tr>
                                    <th rowSpan={2} className='form-label fs-4 fw-bolder'>Competencies</th>
                                    <th colSpan={candidates ? candidates.length : 0} className='justify-content-center align-items-center form-label fs-4 fw-bolder text-center'>Candidates</th>
                                </tr>
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <th key={index}>
                                                <div className='d-flex justify-content-center align-items-center' >
                                                    <img
                                                        src={candidate['profilePic'] !== null ? candidate['profilePic'] : UserIcon}
                                                        style={{ cursor: 'pointer', objectFit: 'cover', width: '34px', height: '34px', border: '1px solid #F36523', borderRadius: '17px' }}
                                                        className="selected-interviewer-icon-sched me-2"
                                                        alt=""
                                                        onClick={() => {
                                                            setCurrentCandidate(candidate);
                                                            setShowCandidateProfile(true);
                                                        }}
                                                    />
                                                    <div className='d-flex flex-column'>
                                                        <label className='fs-5 fw-bolder'>{`${candidate['first_name']} ${candidate['last_name']}`}</label>
                                                        <label className='fs-7 fw-semibold'>{`${candidateAvailable ? 'Available' : 'Not Available'}`}</label>
                                                    </div>
                                                </div>
                                            </th>
                                        )
                                    }) : (<div></div>)}
                                </tr>
                            </thead>

                            <tbody >
                                {competencies ? competencies.map((competency, index) => {

                                    return (
                                        <tr key={index}>
                                            <td className='d-flex flex-column' style={{ maxWidth: '290px', overflow: 'hidden' }}>
                                                <label id='heading' className='fs-6 fw-bolder form-label'>{competency}</label>
                                            </td>
                                            {candidates ? candidates.map((candidate, index) => {
                                                let candidateAvailable = true;
                                                if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                                    candidateAvailable = false;
                                                }
                                                return (
                                                    <td key={index}>
                                                        <select
                                                            data-control='select2'
                                                            data-hide-search='true'
                                                            className='form-select   w-140px'
                                                            defaultValue='Select an option'
                                                            placeholder='Select an option'
                                                            disabled={!candidateAvailable}
                                                            // value={maritalStatus}
                                                            required
                                                            onChange={(event) => handleCompetenciesChange(event, competency, candidate, index)}
                                                        >
                                                            <option value='Select an option'>Select an option</option>
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                        </select>
                                                    </td>
                                                )
                                            }) : (<div></div>)}
                                        </tr>
                                    )
                                }) : (<div></div>)}

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='d-flex mx-12 mt-12'>
                    <img src={interViewHeaderIcon} width={26} />
                    <label className='fw-bolder fs-4 form-label ms-4'>{"Declaration 'Blood Relative' (If Yes)"}</label>
                </div>
                <div className='mb-8' style={{ width: '100%' }}>
                    <label style={{ width: '94%' }}
                        className='grey-chip fw-bolder fs-6 mx-12 mt-8'>
                        {"Relatives are defined as immediate family members, blood relatives / in-laws / 1st cousin of existing employee(s)"}
                    </label>
                </div>
                {/* relative info form */}

                <div className='bordered-card shadow-sm mt-4 mx-12 mb-12 '>
                    <div className='table-responsive px-8'>
                        <table className="table px-4 my-2">
                            <thead>
                                <tr>
                                    {/* <th rowSpan={2} className='form-label fs-4 fw-bolder'>Competencies</th> */}
                                    <th colSpan={candidates ? candidates.length : 0} className='justify-content-center align-items-center form-label fs-4 fw-bolder text-center'>Candidates</th>
                                </tr>
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <th key={index}>
                                                <div className='d-flex justify-content-center align-items-center' >
                                                    <img
                                                        src={candidate['profilePic'] !== null ? candidate['profilePic'] : UserIcon}
                                                        style={{ cursor: 'pointer', objectFit: 'cover', width: '34px', height: '34px', border: '1px solid #F36523', borderRadius: '17px' }}
                                                        className="selected-interviewer-icon-sched me-2"
                                                        alt=""
                                                        onClick={() => {
                                                            setCurrentCandidate(candidate);
                                                            setShowCandidateProfile(true);
                                                        }}
                                                    />
                                                    <div className='d-flex flex-column'>
                                                        <label className='fs-5 fw-bolder'>{`${candidate['first_name']} ${candidate['last_name']}`}</label>
                                                        <label className='fs-7 fw-semibold'>{`${candidateAvailable ? 'Available' : 'Not Available'}`}</label>
                                                    </div>
                                                </div>
                                            </th>
                                        )
                                    }) : (<div></div>)}
                                </tr>
                            </thead>

                            <tbody >
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        const isEmployee = isRelativeEmployee(candidate);
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <td key={index}>
                                                <div className='align-items-center ms-3'>
                                                    <p className='form-label fs-6 fw-bolder' style={{ color: '#F36523' }}>Is blood relative already an employee of BOP?</p>
                                                    <div className='d-flex my-2'>
                                                        <label className='me-8 form-label fs-6 fw-bolder d-flex'>
                                                            <input
                                                                type="radio"
                                                                className='me-4 '
                                                                // name="relativeEmployee"
                                                                // name={`relativeEmployee-${index}`}
                                                                name={`relativeEmployee-${index}-yes`}
                                                                disabled={!candidateAvailable}
                                                                value="yes"
                                                                checked={isEmployee === true}
                                                                onChange={(event) => handleChangeIsRelative(event, candidate)}
                                                            // onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                            />
                                                            Yes
                                                        </label>

                                                        <label className='form-label fs-6 fw-bolder d-flex'>
                                                            <input
                                                                type="radio"
                                                                className='me-4'
                                                                // name="relativeEmployee"
                                                                name={`relativeEmployee-${index}-no`}
                                                                disabled={!candidateAvailable}
                                                                value="no"
                                                                checked={isEmployee === false}
                                                                onChange={(event) => handleChangeIsRelative(event, candidate)}
                                                            // onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                            />
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                {isEmployee ? (<div className='bordered-card shadow-sm px-8' >
                                                    <div className=' mt-10 justify-content-spacearound'>
                                                        <div className='form-group'>
                                                            <label className='form-label labelse '>
                                                                Relation<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='relation'
                                                                    autoComplete='off'
                                                                    className='form-control'
                                                                    placeholder=''
                                                                    // value={relativeData?.relation}
                                                                    required={isEmployee}
                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='form-group '>
                                                            <label className='form-label labelse '>
                                                                Name<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='name'
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    placeholder=''
                                                                    // value={relativeData?.name}
                                                                    required={isEmployee}
                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='form-group '>
                                                            <label className='form-label labelse '>
                                                                CNIC
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='cnic'
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    placeholder='____-_______-_'
                                                                    // value={relativeData?.name}
                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='form-group '>
                                                            <label className='form-label labelse '>
                                                                Designation
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='designation'
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    placeholder=''
                                                                    // value={relativeData?.designation}

                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 justify-content-spacearound'>
                                                        <div className='form-group '>
                                                            <label className='form-label labelse '>
                                                                Department
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='department'
                                                                    autoComplete='off'
                                                                    className='form-control'
                                                                    placeholder=''
                                                                    // value={relativeData?.department}

                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='form-group '>
                                                            <label className='form-label labelse '>
                                                                Location
                                                            </label>
                                                            <div className='mx-4'>
                                                                <input
                                                                    type='text'
                                                                    name='location'
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                    placeholder=''
                                                                    // value={relativeData?.location}

                                                                    onChange={(event) => handleChangeRelationInfo(event, candidate)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) : (<div></div>)}
                                            </td>
                                        )
                                    }) : (<div></div>)}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                {/* note view */}
                <div className='d-flex mx-12 mt-12'>
                    <img src={interViewHeaderIcon} width={26} />
                    <label className='fw-bolder fs-4 form-label ms-4'>Note</label>
                </div>
                <div className='bordered-card shadow-sm mt-4 mx-12 mb-12 table-responsive px-8 py-6'>
                    <label className='fs-6 fw-bolder form-label'>Incumbents for the position of BMs and BOMs must undergo psychometric assessment during the course of hiring.</label>
                    <br></br>
                    <label className='fs-6 fw-bolder form-label'>Hence, the Interview Panel must seek consent of the selected candidates.</label>

                    <select
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select   w-140px'
                        defaultValue='Select an option'
                        placeholder='Select an option'
                        // value={maritalStatus}
                        required
                        onChange={(event) => handleSeekConsentChange(event)}
                    >
                        <option value='Select an option'>Select an option</option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </select>
                </div>

                {/* Order of Preference view */}
                <div className='d-flex mx-12 mt-12'>
                    <img src={interViewHeaderIcon} width={26} />
                    <label className='fw-bolder fs-4 form-label ms-4'>Order of Preference</label>
                </div>
                <div style={{ width: '100%' }}>
                    <label style={{ width: '94%' }} className='grey-chip fw-bolder fs-6 mx-12 mt-8'>{'Rank the candidate according to this criteria 1 = Top Ranked, 2 = 2nd Ranked, 3 = 3rd Ranked, 4 = 4th Ranked, 5 = Not Recommended'}</label>
                </div>
                <div className='bordered-card shadow-sm mt-4 mx-12 mb-12'>
                    <div className='table-responsive px-8'>
                        <table className="table px-4 my-2" >
                            <thead>
                                <tr>
                                    {/* <th rowSpan={2} className='form-label fs-4 fw-bolder'>Competencies</th> */}
                                    <th colSpan={candidates ? candidates.length : 0} className='justify-content-center align-items-center form-label fs-4 fw-bolder text-center'>Candidates</th>
                                </tr>
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <th key={index}>
                                                <div className='d-flex justify-content-center align-items-center' >
                                                    <img
                                                        src={candidate['profilePic'] !== null ? candidate['profilePic'] : UserIcon}
                                                        style={{ cursor: 'pointer', objectFit: 'cover', width: '34px', height: '34px', border: '1px solid #F36523', borderRadius: '17px' }}
                                                        className="selected-interviewer-icon-sched me-2"
                                                        alt=""
                                                        onClick={() => {
                                                            setCurrentCandidate(candidate);
                                                            setShowCandidateProfile(true);
                                                        }}
                                                    />
                                                    <div className='d-flex flex-column'>
                                                        <label className='fs-5 fw-bolder'>{`${candidate['first_name']} ${candidate['last_name']}`}</label>
                                                        <label className='fs-7 fw-semibold'>{`${candidateAvailable ? 'Available' : 'Not Available'}`}</label>
                                                    </div>
                                                </div>
                                            </th>
                                        )
                                    }) : (<div></div>)}
                                </tr>
                            </thead>

                            <tbody >
                                <tr>
                                    {candidates ? candidates.map((candidate, index) => {
                                        let candidateAvailable = true;
                                        if (candidate?.status === 'Not Confirmed' && candidate?.availability === false) {
                                            candidateAvailable = false;
                                        }
                                        return (
                                            <td key={index}>
                                                <select
                                                    name='status'
                                                    data-control='select2'
                                                    data-hide-search='true'
                                                    className='form-select   w-140px'
                                                    defaultValue='Select an option'
                                                    placeholder='Select an option'
                                                    disabled={!candidateAvailable}
                                                    // value={maritalStatus}
                                                    required
                                                    onChange={(event) => handlePreferenceChange(event, candidate, index)}
                                                >
                                                    <option value='Select an option'>Select an option</option>
                                                    <option value='1'>1</option>
                                                    <option value='2'>2</option>
                                                    <option value='3'>3</option>
                                                    <option value='4'>4</option>
                                                    <option value='5'>5</option>
                                                </select>
                                            </td>
                                        )
                                    }) : (<div></div>)}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='d-flex justify-content-center'>
                    <div
                        className='candi-btn2 text-white m-5'
                        onClick={handleSave}
                    // disabled={isSubmitted}
                    >
                        <span style={{ fontWeight: 'bold' }}>Save</span>
                    </div>
                </div>

            </div>
            {showCandidateProfile && (
                <ViewCandidateProfile
                    show={true}
                    user_id={currentCandidate?.id}
                    jobapp_id=''
                    handleClose={() => {
                        setShowCandidateProfile(false);
                        setCurrentCandidate(null);
                    }}
                />
                // <ScheduleInterViewMultipleCandidatesStage1
                //     show={true}
                //     handleClose={() => {
                //         setShowCandidateProfile(false);
                //         setCurrentCandidate(null);
                //     }}
                //     candidates={['asfd','adf']}
                // />
            )}
            {loading && (
                <LoaderView message='Loading...' />
            )}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </>
    );
};

//make this component available to the app
export default InterviewScoringExternal;
