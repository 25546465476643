import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
// import { CandidateRegistration } from '../Candidate Space/auth/components/Registration'
import {CandidateLogin} from '../Candidate Space/auth/components/Login'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import './Auth.css'
const logo = process.env.REACT_APP_LOGO

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className='d-flex main-wrapper   flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='mybody d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        <div className='header'>
          <img src={logo} className='BOPLOGO ms-auto' alt='Logo'></img>
        </div>

        {/* begin::Form */}
        <div
          style={{margin: '0px 0px 130px 0px'}}
          className='d-flex flex-center flex-column flex-lg-row-fluid'
        >
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}

        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}

      {/* end::Aside */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='candidatelogin' element={<CandidateLogin />} />
      <Route path='registration' element={<Registration />} />
      {/* <Route path='registration' element={<CandidateRegistration />} /> */}
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
      {/* <Route index element={<Registration />} /> */}
      {/* <Route index element={<CandidateRegistration />} /> */}
    </Route>
  </Routes>
)

export {AuthPage}
