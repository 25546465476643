import React from 'react'
// import
import resourceallocation from '../images/group.png'
import popup123 from '../images/information (3).png'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Card3 } from '../../../../_metronic/partials/content/cards/Card3'
import '../Settings.css'
import { AddNewRecruiter } from '../../../../_metronic/partials/modals/create-app-stepper/AddNewRecruiter'
import { POCpopupModel } from '../../../../_metronic/partials/modals/create-app-stepper/POCpopupModel'
// import '../Settings.css'
import cross from '../../../../_metronic/partials/content/images/cross.png'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import { AddNewTA } from '../../../../_metronic/partials/modals/create-app-stepper/AddNewTA'
import WarningTARemovePopup from '../../../../_metronic/partials/modals/JobFunnelsModels/WarningTARemovePopup'
import AddTALeadPopup from '../../../../_metronic/partials/modals/create-app-stepper/AddTALeadPopup'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import CustomPagination from '../../dashboards/components/CustomPagination'
import AddNewTALead from '../../../../_metronic/partials/modals/create-app-stepper/AddNewTALead'
const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_ALLOCATED_URL = `${API_URL}/ta-ra`
export const GET_ALL_DEPARTMENTS_URL = `${API_URL}/department`
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`
export const GET_TA_URL = `${API_URL}/ta/`
export const CREATE_RESOURCE_URL = `${API_URL}/poc-ra`
export const ALLOCATE_RESOURCE_URL = `${API_URL}/poc-ra/resource-allocate`
export const GET_ALL_TA_URL = `${API_URL}/auth/user-list`
export const GET_TA_HEAD_URL = `${API_URL}/auth/user-list`
const REMOVE_TA_URL = `${API_URL}/ta-ra/delete-ta-from-ra`
const REMOVE_TA_HEAD_URL = `${API_URL}/ta-ra/delete-ta-head`

interface Resource {
  RecData: Object
  taData: Object
  raRow: Object
}

function Recruiters() {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateTALead, setShowCreateTALead] = useState(false)
  const [showTACreatePopup, setShowTACreatePopup] = useState(false)
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [showAddTALead, setShowAddTALead] = useState(false)
  const [selectedTALead, setSelectedTALead] = useState('')
  const [currentResource, setCurrentResource] = useState<any>()
  const [showCreateAppModalPOPUP, setshowCreateAppModalPOPUP] = useState<boolean>(false)
  const { currentUser, auth, logout } = useAuth()
  const intl = useIntl()
  const [resources, setResources] = useState<Resource[]>([])
  const [dataFetched, setDataFetched] = useState(false)
  const [uniqueTA, setUniqueTAs] = useState<any>([])
  const [taHead, setTAHead] = useState<any>({})
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [loadingTable, setLoadingTable] = useState(false)
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    total: 0,
  })

  const updateResources = async () => {
    getTAHead()
    getAllTA()
    GetAllResources()
  }

  const addTALead = (resource) => {
    setCurrentResource(resource)
    setShowAddTALead(true)
  }

  const GetAllResources = async () => {
    setLoadingTable(true)
    await axios
      .get(GET_ALL_ALLOCATED_URL, {
        params,
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        setLoadingTable(false)
        let pagination = resp?.data?.pagination

        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = { ...params }
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }

        // setCurrentPage(pagination?.page || 1)
        setTotalPages(pagination?.totalPages || 0)

        let data = resp.data.data
        if (data !== null && data !== undefined && data.length > 0) {
          // let allEntities: any = [];
          // data.map((entity, index) => {
          //   if (entity?.RecData && entity?.taData && entity?.raRow) {
          //     allEntities.push(entity)
          //   }
          // })
          // setResources(allEntities);
          setResources(data)
        }
      })
      .catch((error) => {
        setLoadingTable(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getTAHead = async () => {
    await axios
      .get(GET_TA_HEAD_URL, {
        params: {
          role: 'ta_head',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let taHead = res.data?.data[0]
        setTAHead(taHead)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  // get all ta lead
  const getAllTA = async () => {
    await axios
      .get(GET_ALL_TA_URL, {
        params: {
          role: 'ta_lead',
        },
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let taList: any = res.data.data
        setUniqueTAs(taList)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    // if (!dataFetched) {
    ; (async () => {
      getTAHead()
      getAllTA()
      // GetAllResources()
      // setDataFetched(true)
    })()
    // }
  }, [])

  useEffect(() => {
    ; (async () => {
      GetAllResources()
    })()
  }, [params])

  const removeTAHead = async () => {
    await axios
      .get(REMOVE_TA_HEAD_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // remove TA Head
        setTAHead(null)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const deleteTALead = async () => {
    let data = {
      ta_id: selectedTALead,
    }
    await axios
      .post(REMOVE_TA_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        // update list of TA
        setSelectedTALead('')
        updateResources()
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const removeTA = async (resource, index) => {
    setSelectedTALead(resource.user_id)
    setShowWarningPopup(true)
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }

  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.page = 1
    data.limit = event.target.value
    setParams(data)
  }

  return (
    <div>
      {' '}
      <div className='px-4'>
        {/* begin::Header */}

        <div className='d-flex flex-column mt-8'>
          <div className='mb-8 d-flex align-items-center'>
            <h3 className=' align-items-start flex-column'>
              <span className=' text-gray-800 fw-bolder fs-3'>TA Head</span>
            </h3>
            {currentUser?.role === 'masterRecruiter' && (<div className='d-flex ' style={{ marginLeft: 'auto' }}>
              {!taHead && (
                <button className='add-new-recruiter me-6' onClick={() => setShowTACreatePopup(true)} disabled={taHead}>
                  + Add TA Head
                </button>
              )}
              <button onClick={() => setShowCreateTALead(true)} className='add-new-recruiter me-6'>
                + Add New TA Lead
              </button>
              <button onClick={() => setShowCreateAppModal(true)} className='add-new-recruiter'>
                + Add New Recruiter
              </button>
            </div>)}
          </div>
          <div className='d-flex flex-column' style={{ width: '100%' }}>
            <div className='d-flex justify-content-center' style={{ width: '100%' }}>
              {taHead && (
                // <div className='me-4 mb-8' >
                //   <span
                //     className='orange-tag px-4 py-3 align-items-center'
                //   >
                //     {taHead['name']}
                //     {currentUser?.role === 'masterRecruiter' && (<span>
                //       <img style={{ cursor: 'pointer', marginLeft: '1rem' }} src={imagePath.ic_cross_orange} onClick={() => setShowWarningPopup(true)} />
                //     </span>)}
                //   </span>
                // </div>

                <div className='d-flex flex-wrap justify-content-center align-items-center'>
                  <div
                    className='card card-border-recruiter me-8 mb-8'
                  // style={{ marginLeft: '1.6rem' }}
                  >
                    <div className='mt-4 px-4 d-flex align-items-center'>
                      {currentUser?.role === 'masterRecruiter' && (<div className='ms-auto'>
                        <img
                          className='cursor-pointer'
                          src={imagePath.ic_delete_orange_cross}
                          onClick={() => setShowWarningPopup(true)}
                          width={18}
                          height={18}
                        />
                      </div>)}
                    </div>
                    <div className='cardboyfortherecruiter d-flex flex-center flex-column mt-8 '>
                      <div className='mb-5'>
                        <div className='symbol symbol-75px symbol-circle'>
                          <img
                            style={{ objectFit: 'cover' }}
                            alt='Pic'
                            src={taHead?.profile_pic ? taHead?.profile_pic : imagePath.userIcon}
                          />
                        </div>
                      </div>

                      <label className='fs-4 text-gray-800text-hover-primaryfw-boldwe mb-0'>{taHead['name']}</label>

                      <label style={{ color: '#80808F' }} className='btn fw-bold btn-sm btn-light'>
                        Employee ID: {taHead?.employee_id ? taHead?.employee_id : 'Employee ID'}
                      </label>
                    </div>
                    <div className='d-flex mt-3 mb-4 mx-4'>
                      <div className='d-flex pt-3'>
                        <div className='me-2'>
                          <img src={imagePath.ic_mail_orange_fill}></img>
                        </div>
                        <div style={{ color: '#80808F', maxWidth: '15rem' }} className='fw-bold '>
                          {taHead?.email ? taHead?.email : 'Email'}
                        </div>
                      </div>

                      <div className='d-flex pt-3 ms-auto'>
                        <div className='mx-2'>
                          <img src={imagePath.ic_phone_orange_fill}></img>
                        </div>
                        <div style={{ color: '#80808F' }} className='fw-bold'>
                          {taHead?.phone_number ? taHead?.phone_number : 'Phone No'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='mb-6'>
          <h3 className=' align-items-start flex-column'>
            <span className=' text-gray-800 fw-bolder fs-3'>All TA Leads</span>
          </h3>
        </div>

        {/* <div className=' settingTAHEADSMAINDIV '>
          {uniqueTA
            ? uniqueTA.map((resource, index) => {
              return (
                <div className='me-4 mb-8'>
                  <span
                    className='orange-tag px-4 py-3 align-items-center'
                  >
                    {resource['name']}
                    <span>
                      <img style={{ cursor: 'pointer', marginLeft: '1rem' }} src={imagePath.ic_cross_orange} onClick={() => removeTA(resource, index)}></img>
                    </span>
                  </span>
                </div>
              )
            })
            : null}
        </div> */}

        <div className='d-flex flex-wrap justify-content-center'>
          {uniqueTA ? (
            uniqueTA.map((resource, index) => {
              return (
                <div className='card card-border-recruiter me-8 mb-8' key={index}>
                  <div className='mt-4 px-4 d-flex align-items-center'>
                    {currentUser?.role === 'masterRecruiter' && (<div className='ms-auto'>
                      <img
                        className='cursor-pointer'
                        src={imagePath.ic_delete_orange_cross}
                        onClick={() => removeTA(resource, index)}
                        width={18}
                        height={18}
                      />
                    </div>)}
                  </div>
                  <div className='cardboyfortherecruiter d-flex flex-center flex-column mt-8 '>
                    <div className='mb-5'>
                      <div className='symbol symbol-75px symbol-circle'>
                        <img
                          style={{ objectFit: 'cover' }}
                          alt='Pic'
                          src={resource?.profile_pic ? resource?.profile_pic : imagePath.userIcon}
                        />
                      </div>
                    </div>

                    <a href='#' className='fs-4 text-gray-800text-hover-primaryfw-boldwe mb-0'>
                      {resource?.fullName ? resource?.fullName : 'Name'}
                    </a>

                    <a href='#' style={{ color: '#80808F' }} className='btn fw-bold btn-sm btn-light'>
                      Employee ID: {resource?.employee_id ? resource?.employee_id : 'Employee ID'}
                    </a>
                  </div>
                  <div className='d-flex mt-3 mb-4 mx-4'>
                    <div className='d-flex pt-3'>
                      <div className='me-2'>
                        <img src={imagePath.ic_mail_orange_fill}></img>
                      </div>
                      <div style={{ color: '#80808F', maxWidth: '15rem' }} className='fw-bold '>
                        {resource?.email ? resource?.email : 'Email'}
                      </div>
                    </div>

                    <div className='d-flex pt-3 ms-auto'>
                      <div className='mx-2'>
                        <img src={imagePath.ic_phone_orange_fill}></img>
                      </div>
                      <div style={{ color: '#80808F' }} className='fw-bold'>
                        {resource?.phone_number ? resource?.phone_number : 'Phone No'}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div></div>
          )}
        </div>

        <h3 className='align-items-start flex-column mt-2'>
          <span className=' text-gray-800 fw-bolder fs-3 '>All Recruiters ({resources.length}) </span>
        </h3>

        <div className=' py-3'>
          <div className='d-flex flex-wrap justify-content-center'>
            {resources
              ? resources.map((resource, index) => {
                const recruiter: any = resource.RecData
                const taData: any = resource.taData
                const raId = resource.raRow['recresource_id']

                return (
                  <div key={index}>
                    <Card3
                      avatar={recruiter?.profile_pic}
                      name={recruiter?.name}
                      employeeId={recruiter?.employee_id}
                      email={recruiter?.email}
                      phoneNumber={recruiter?.phone_number}
                      TA={taData?.name}
                      taId={taData?.user_id}
                      raId={raId}
                      recruiterId={recruiter?.user_id}
                      addTALead={() => addTALead(resource)}
                      updateResources={updateResources}
                    />
                  </div>
                )
              })
              : null}
          </div>
          {(resources && resources.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
            <div className='col-md-4'>
              <label>{`Showing ${resources ? resources.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination pageValue={params.page} totalPages={totalPages} onPageChange={handlePageChange} />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>)}
        </div>
      </div>
      <AddNewRecruiter show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} updateResources={updateResources} />
      <AddNewTA show={showTACreatePopup} handleClose={() => setShowTACreatePopup(false)} updateResources={updateResources} />
      {showCreateTALead && (
        <AddNewTALead show={showCreateTALead} handleClose={() => setShowCreateTALead(false)} updateResources={updateResources} />
      )}
      {showWarningPopup && (
        <WarningTARemovePopup
          show={true}
          message='Are you sure you want to remove?'
          handleClose={() => {
            setShowWarningPopup(false)
          }}
          handleYesSure={() => {
            if (selectedTALead) {
              deleteTALead()
            } else {
              removeTAHead()
            }
            setShowWarningPopup(false)
          }}
        />
      )}
      {showAddTALead && (
        <AddTALeadPopup
          show={true}
          resource={currentResource}
          handleClose={() => setShowAddTALead(false)}
          updateResources={updateResources}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default Recruiters
