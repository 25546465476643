import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { CardClosed } from '../../../../_metronic/partials/content/cards/CardClosed'
import './ClosedJobs.css'
import { useNavigate } from 'react-router-dom'

const ClosedJobs = () => {

  return (
    <div className='row mt-20 g-5 g-xl-8'>

      {/* <div className='me-6'>
        <ApplicationsTable className= 'table-responsive'/>
      </div> */}
      <div>
        <CardClosed
          icon='/media/svg/brand-logos/plurk.svg'
          badgeColor='primary'
          status='In Progress'
          statusColor='primary'
          title='Fitnes App'
          description='CRM App application to HR efficiency'
          date='November 10, 2021'
          budget='$284,900.00'
          progress={50}

        />
      </div>

    </div>
  )
}

export default ClosedJobs