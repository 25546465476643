import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useState} from 'react'
import {MixedWidget13} from '../../../../_metronic/partials/widgets/mixed/MixedWidget13'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  MixedWidget7,
  MixedWidget8,
  TilesWidget1,
  TilesWidget4,
  TilesWidget2,
  TilesWidget5,
  StatisticsWidget1,
  TablesWidget13,
  TilesWidget3,
} from '../../../../_metronic/partials/widgets'
import {InactiveJobsScreen} from '../../../../_metronic/partials/content/cards/InactiveJobsScreen'

import './InActiveJobs.css'
function InActiveJobs() {
  const intl = useIntl()

  return (
    <div className='row mt-20 g-5 g-xl-8'>
      {/* <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-1'>
          Inactive Posts &nbsp;
          <span className='fs-6 text-gray-400 fw-semibold ms-1'>InActive</span>
        </h3>

        
      </div> */}

      <InactiveJobsScreen
        icon='/media/svg/brand-logos/plurk.svg'
        badgeColor='primary'
        status='In Progress'
        statusColor='primary'
        title='Fitnes App'
        description='CRM App application to HR efficiency'
        date='November 10, 2021'
        budget='$284,900.00'
        progress={50}
      />
    </div>
  )
}

export default InActiveJobs
