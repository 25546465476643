//import liraries
import React, { useEffect, useRef, useState } from 'react';
import '../Settings.css'
import TermsAndConditions from './components/TermsAndConditions';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import axios from 'axios';
import EduLevel from './components/EduLevel';
import InstitutionsCard from './components/InstitutionsCard';
import Specialization from './components/Specialization';
import GradesCard from './components/GradesCard';
import AllGroupHeadGrades from './components/AllGroupHeadGrades';
import RequiredDocumentsCard from './components/RequiredDocumentsCard';
import OfferLetterContentCreator from './ConfigurationModules/OfferLetterContentCreator';
import imagePath from '../../../../constants/imagePath';
import MarketingCreatives from './components/MarketingCreatives';
import BenefitsIncentives from './components/BenefitsIncentives';
import GradeFuels from './components/GradeFuels';

const API_URL = process.env.REACT_APP_API_URL;
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`;

// create a component
const ConfigurationNew = () => {
    const { currentUser, auth, logout } = useAuth();
    const [configurationId, setConfigurationId] = useState<any>('');
    // const [termsAndConditions, setTermsAndConditions] = useState<any>([{ i: 0, data: '' }]);
    const [termsAndConditions, setTermsAndConditions] = useState<any>();
    const [educationLevels, setEducationLevels] = useState<any>();
    const [institutes, setInstitutes] = useState<any>();
    const [specialization, setSpecialization] = useState<any>();
    const [grades, setGrades] = useState<any>();
    const [groupHeadGrades, setGroupHeadGrades] = useState<any>();
    const [docs, setDocs] = useState<any>();
    const [extraDocs, setExtraDocs] = useState<any>();
    const [bannerList, setBannerList] = useState<any>();
    const [offerLetterFormat, setOfferLetterFormat] = useState('');
    const [termsConditionsHover, setTermsConditionsHover] = useState(false);
    const [eduHover, setEduHover] = useState(false);
    const [institutionHover, setInstitutionHover] = useState(false);
    const [specializationHover, setSpecializationHover] = useState(false);
    const [gradeHover, setGradeHover] = useState(false);
    const [groupHeadHover, setGroupHeadHover] = useState(false);
    const [docHover, setDocHover] = useState(false);
    const [marketingHover, setMarketingHover] = useState(false);
    const [offerLetterHover, setOfferLetterHover] = useState(false);
    const [benefitsHover, setBenefitsHover] = useState(false);
    const [fuelHover, setFuelHover] = useState(false);
    const [allGrades, setAllGrades] = useState<any>([]);
    const [benefits, setBenefits] = useState<any>()
    const [fuelList, setFuelList] = useState<any>();

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        (async () => {
            getConfigurations();
            getGrades();
        })();
    }, []);

    const getConfigurations = async () => {
        await axios.get(GET_CONFIGURATIONS_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {

            let data = res?.data?.Configuration;
            setConfigurationId(data?.configuration_id);
            setTermsAndConditions(data?.terms_condition);
            setEducationLevels(data?.education_level);
            setInstitutes(data?.institutes);
            setSpecialization(data?.specialization);
            setGrades(data?.job_grade);
            setGroupHeadGrades(data?.group_head_grades);
            setDocs(data?.job_documents);
            setExtraDocs(data?.extra_documents);
            setBannerList(data?.marketing_creatives);
            setOfferLetterFormat(data?.offer_letter_format);
            setBenefits(data?.benefits);
            setFuelList(data?.fuel_allowance);

        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    };

    const getGrades = async () => {
        console.log('fetching latest grades')
        await axios.get(GET_GRADES_URL, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            let data = res?.data?.job_grade;
            if (data) {
                data = JSON.parse(data);
                if (data) {
                    setAllGrades(Object.values(data));
                }
            }
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        })
    }

    // Create refs for each component
    const termsAndConditionsRef = useRef(null);
    const eduLevelRef = useRef(null);
    const institutionsCardRef = useRef(null);
    const specializationRef = useRef(null);
    const gradesCardRef = useRef(null);
    const allGroupHeadGradesRef = useRef(null);
    const requiredDocumentsCardRef = useRef(null);
    const marketingRef = useRef(null);
    const offerLetterContentCreatorRef = useRef(null);
    const benefitsRef = useRef(null);
    const fuelRef = useRef(null);

    // Function to scroll to a specific component
    const scrollToRef = (ref, padding = 100) => {
        if (ref && ref.current) {
            // window.scrollTo(0, ref.current.offsetTop);
            window.scrollTo({
                top: ref.current.offsetTop - padding,
                behavior: 'smooth' // Optional: Add smooth scrolling behavior
            });
        }
    };

    return (
        <div>
            {/* <div className='d-flex'> */}
            {/* Left-side menu */}
            <div id='left-goto-items-menu' className='d-flex flex-column left-config-menu' >

                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(termsAndConditionsRef)}
                    onMouseEnter={() => setTermsConditionsHover(true)}
                    onMouseLeave={() => setTermsConditionsHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={termsConditionsHover ? imagePath.ic_terms_conditions_menu_orange : imagePath.ic_terms_conditions_menu_gray} alt="Icon" />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2' >Terms and Conditions</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(eduLevelRef)}
                    onMouseEnter={() => setEduHover(true)}
                    onMouseLeave={() => setEduHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={eduHover ? imagePath.ic_edu_hat_orange : imagePath.ic_edu_hat_gray} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Education Levels</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(institutionsCardRef)}
                    onMouseEnter={() => setInstitutionHover(true)}
                    onMouseLeave={() => setInstitutionHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={institutionHover ? imagePath.ic_building_orange_holo : imagePath.ic_building_gray_holo} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Institutions</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(specializationRef)}
                    onMouseEnter={() => setSpecializationHover(true)}
                    onMouseLeave={() => setSpecializationHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={specializationHover ? imagePath.ic_tag_orange_holo : imagePath.ic_tag_gray_holo} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Specializations</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(gradesCardRef)}
                    onMouseEnter={() => setGradeHover(true)}
                    onMouseLeave={() => setGradeHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={gradeHover ? imagePath.ic_grade_orange : imagePath.ic_grade_gray} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Grades</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(allGroupHeadGradesRef)}
                    onMouseEnter={() => setGroupHeadHover(true)}
                    onMouseLeave={() => setGroupHeadHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={groupHeadHover ? imagePath.ic_group_orange : imagePath.ic_group_gray} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Group Head Grades</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(requiredDocumentsCardRef)}
                    onMouseEnter={() => setDocHover(true)}
                    onMouseLeave={() => setDocHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={docHover ? imagePath.ic_doc_orange_holo : imagePath.ic_doc_gray_holo} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Required Documents</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(marketingRef)}
                    onMouseEnter={() => setMarketingHover(true)}
                    onMouseLeave={() => setMarketingHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={marketingHover ? imagePath.ic_campaign_orange_holo : imagePath.ic_campaign_gray} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Marketing Creatives</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(offerLetterContentCreatorRef)}
                    onMouseEnter={() => setOfferLetterHover(true)}
                    onMouseLeave={() => setOfferLetterHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={offerLetterHover ? imagePath.ic_mail_orange_holo : imagePath.ic_mail_gray_holo} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Offer Letter Content Creator</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(benefitsRef)}
                    onMouseEnter={() => setBenefitsHover(true)}
                    onMouseLeave={() => setBenefitsHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={benefitsHover ? imagePath.ic_benefits_orange_menu : imagePath.ic_benefits_grey} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Benefits & Incentives</label>
                    </div>
                </div>
                <div className='d-flex align-items-center config-menu-item' onClick={() => scrollToRef(fuelRef)}
                    onMouseEnter={() => setFuelHover(true)}
                    onMouseLeave={() => setFuelHover(false)}>
                    <div className='config-menu-item-container'>
                        <img className='config-menu-icon' src={fuelHover ? imagePath.ic_fuel_orange_holo : imagePath.ic_fuel_grey_holo} />
                        <label className='fs-4 fw-bolder config-menu-item-txt px-2'>Fuel</label>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-column'>
                <div
                    ref={termsAndConditionsRef}>
                    <TermsAndConditions
                        configurationId={configurationId}
                        termsAndConditions={termsAndConditions}
                    />
                </div>
                <div
                    ref={eduLevelRef}>
                    <EduLevel
                        configurationId={configurationId}
                        edu={educationLevels}
                    />
                </div>
                <div
                    ref={institutionsCardRef}>
                    <InstitutionsCard
                        configurationId={configurationId}
                        institutes={institutes}
                    />
                </div>
                <div
                    ref={specializationRef}>
                    <Specialization
                        configurationId={configurationId}
                        specialization={specialization}
                    />
                </div>
                <div
                    ref={gradesCardRef}>
                    <GradesCard
                        configurationId={configurationId}
                        grades={grades}
                        updateData={getGrades}
                    />
                </div>
                <div
                    ref={allGroupHeadGradesRef}>
                    <AllGroupHeadGrades
                        configurationId={configurationId}
                        groupHeadGrades={groupHeadGrades}
                        gradesList={allGrades}
                    />
                </div>
                <div
                    ref={requiredDocumentsCardRef}>
                    <RequiredDocumentsCard
                        configurationId={configurationId}
                        docs={docs}
                        extraDocs={extraDocs}
                    />
                </div>
                <div
                    ref={marketingRef}>
                    <MarketingCreatives
                        configurationId={configurationId}
                        banners={bannerList}
                    />
                </div>
                <div
                    ref={offerLetterContentCreatorRef}>
                    <OfferLetterContentCreator
                        configurationId={configurationId}
                        offer_letter_format={offerLetterFormat}
                    />
                </div>
                <div
                    ref={benefitsRef}>
                    <BenefitsIncentives
                        configurationId={configurationId}
                        benefits={benefits}
                        gradesList={allGrades}
                    />
                </div>
                <div
                    ref={fuelRef}>
                    <GradeFuels
                        configurationId={configurationId}
                        fuelList={fuelList}
                        gradesList={allGrades}
                    />
                </div>
            </div>
            {/* </div> */}
            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default ConfigurationNew;
