//import liraries
import React, { Component, useEffect, useState } from 'react';
import imagePath from '../../../../../constants/imagePath';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import './componentsStyles.css';
import axios from 'axios';
import { useAuth } from '../../../auth/core/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import PriceUpdateWarningPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/PriceUpdateWarningPopup';
import AddUpdateGradeFuelPopup from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateGradeFuelPopup';

const API_URL = process.env.REACT_APP_API_URL;
const ADD_GRADE_FUEL_URL = `${API_URL}/configuration/add-fuel-allowance`;
const DELETE_GRADE_FUEL_URL = `${API_URL}/configuration/delete-fuel-allowance`;

// create a component
const GradeFuels = ({ configurationId, fuelList, gradesList, ...props }) => {

    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [fuelPrice, setFuelPrice] = useState('')
    const [showPriceUpdateWarning, setShowPriceUpdateWarning] = useState(false);
    const [showAddUpdateGradeFuel, setShowAddUpdateGradeFuel] = useState(false);
    const [popupType, setPopupType] = useState('add');
    const [allGrades, setAllGrades] = useState<any>([]);
    const [gradeData, setGradeData] = useState<any>({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [gradeFuelList, setGradeFuelList] = useState<any>([]);

    useEffect(() => {
        if (gradesList) {
            setAllGrades(gradesList)
        }
        if (fuelList) {


            fuelList = JSON.parse(fuelList);
            const initialData = Object.keys(fuelList).map((key) => ({
                liters: fuelList[key]['liters'],
                grade: fuelList[key]['grade'],
                price: fuelList[key]['price'],
                active: fuelList[key]['active']
            }));
            console.log('eduData: ', initialData);
            setGradeFuelList(initialData);
            // setGradeFuelList(fuelList);
        }
    }, [gradesList, fuelList]);

    useEffect(() => {
        setAllGrades(gradesList)

    }, [gradesList]);

    const handleFuelPriceChange = (e) => {
        let value = e.target.value;

        // Regex to match a number with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;

        // Check if the value matches the regex
        if (regex.test(value)) {
            setFuelPrice(value);
        }
    }

    const handleShowPriceUpdateWarning = (e) => {
        e.preventDefault();

        if (!fuelPrice) {
            setMessage('Please add fuel price first and try again.');
            setShowErrorMessage(true);
            return;
        }

        setShowPriceUpdateWarning(true);
    }

    const handleAddNewGradeInfo = (liters, grade, price) => {
        setShowAddUpdateGradeFuel(false);

        let data = {
            liters: liters,
            grade: grade,
            price: price,
            active: false
        }
        let list = [...gradeFuelList];
        list.push(data);
        setGradeFuelList(list);
    }
    const handleUpdateGradeInfo = (gradeInfo) => {
        let data = [...gradeFuelList];
        data[currentIndex] = gradeInfo
        setGradeFuelList(data);
        setCurrentIndex(0);
        setGradeData(null);
        setShowAddUpdateGradeFuel(false);
    }

    const onEdit = (index) => {
        if (disabled) {
            return;
        }
        let data = gradeFuelList[index];
        setCurrentIndex(index);
        setGradeData(data);
        setPopupType('edit');
        setShowAddUpdateGradeFuel(true);
    }

    const onDelete = async (index) => {
        if (disabled) {
            return;
        }
        if (index >= 0 && index < gradeFuelList.length) {
            const key = index.toString();

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            setDisabled(true);
            await axios.post(DELETE_GRADE_FUEL_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                setDisabled(false);
                let data = [...gradeFuelList];
                data.splice(index, 1);
                setGradeFuelList(data);

                setMessage('Educations Level Deleted Successfully!');
                setShowSuccessPopup(true);
            }).catch((error) => {
                setDisabled(false);
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }
    }

    const handleChangeActive = (isActive, index) => {

        let data = [...gradeFuelList];
        data[index]['active'] = !isActive;
        setGradeFuelList(data);
        saveList(data);
    }

    const updateFuelPrice = async () => {
        setShowPriceUpdateWarning(false)
        const updatedList = gradeFuelList.map(item => ({
            ...item, // Spread the existing properties of the item
            price: fuelPrice // Update the price property to the value of fuelPrice
        }));

        // Update the state with the new array
        setGradeFuelList(updatedList);
        await saveList(updatedList);
        // update the list of grades and show success popup
        setMessage('Fuel price updated successfully');
        setShowSuccessPopup(true);
    }

    const handleSaveClick = (e) => {
        e.preventDefault();
        let gradesInfo = [...gradeFuelList];
        saveList(gradesInfo);

    }

    const saveList = async (gradesInfo) => {
        // let fuelAllowance: any = [];
        // if (gradesInfo) {
        //     gradesInfo.map((gradeData) => {
        //         let obj = {
        //             grade: gradeData?.grade,
        //             price: (gradeData?.price ? parseFloat(gradeData?.price) : 0),
        //             liters: (gradeData?.liters ? parseFloat(gradeData?.liters) : 0),
        //             active: gradeData?.active
        //         }
        //         fuelAllowance.push(obj);
        //     })
        // }

        let fuelAllowance: any = {};
        if (gradesInfo) {
            gradesInfo.forEach((gradeData, index) => {
                let obj = {
                    grade: gradeData?.grade,
                    price: (gradeData?.price ? parseFloat(gradeData?.price) : 0),
                    liters: (gradeData?.liters ? parseFloat(gradeData?.liters) : 0),
                    active: gradeData?.active
                };
                fuelAllowance[index] = obj;
            });
        }

        let data = {
            configuration_id: configurationId,
            fuel_allowance: fuelAllowance
        }
        setDisabled(true);
        await axios.post(ADD_GRADE_FUEL_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setDisabled(false);

        }).catch((error) => {
            setDisabled(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative' style={{ minHeight: '26rem' }}>

            <div >
                <div className='d-flex align-items-center px-8'>
                    <label className='form-label fs-4 fw-bolder card-heading'>Fuel</label>
                    <div className='ms-auto'>
                        <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                            style={{ position: 'relative', zIndex: '1' }}
                            onClick={(e) => {
                                setPopupType('add');
                                setGradeData(null);
                                setShowAddUpdateGradeFuel(true);
                            }}
                        >
                            Add New
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center px-8 mt-4'>
                    <label className='fw-semibold fs-6 me-8'>Price / Liter<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span></label>
                    <input
                        type='text'
                        name='status'
                        autoComplete='off'
                        maxLength={9}
                        min={0}
                        className='form-control mw-25 me-8'
                        placeholder='Enter fuel price per liter'
                        value={fuelPrice}
                        required
                        onChange={(event) => handleFuelPriceChange(event)}
                    />
                    <button
                        className='candi-btn2 btn text-white fw-bolder fs-6 px-10'
                        // type='button'
                        onClick={(e) => {
                            handleShowPriceUpdateWarning(e)
                        }}
                    >
                        Save
                    </button>
                </div>

                <div className='d-flex flex-wrap mt-8 px-7 mb-4'>
                    {gradeFuelList ? gradeFuelList.map((data, index) => {
                        return (
                            <div key={index} className='col-lg-4 col-md-6 col-sm-12 px-2 mb-4' style={{ position: 'relative', zIndex: '1' }}>
                                <div className='card-bg shadow-sm py-4' style={{ zIndex: '2', minWidth: '14rem' }}>

                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <label className='fs-5 fw-bolder card-title '>{data?.grade}</label>
                                        </div>
                                        <div className='ms-auto d-flex align-items-center me-4' style={{ position: 'relative', zIndex: '1' }}>
                                            <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={20} height={20} title='Edit'
                                                onClick={() => onEdit(index)} />
                                            <img style={{ cursor: 'pointer' }} src={imagePath.ic_delete_orange_cross} width={18} height={18} title='Delete'
                                                onClick={() => { onDelete(index) }} />
                                        </div>
                                    </div>
                                    <div className='mt-4' style={{ marginLeft: '1.2rem' }}>
                                        <div className='d-flex align-items-center'>
                                            <label className='fs-7 fw-bold me-2'>No. of Liters Per Month: </label>
                                            <label className='fs-7 fw-bold text-black'>{`${data?.liters} Liters`} </label>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <label className='fs-7 fw-bold me-2'>Price/Liter: </label>
                                            <label className='fs-7 fw-bold text-black'>{`${data?.price}/- pkr`} </label>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center mt-4'>
                                        <label className='me-4 fs-7 light-txt fw-bolder' style={{ paddingLeft: '1.2rem' }}>Active</label>
                                        <label className="toggle-switch-small">
                                            <input type="checkbox" checked={data?.active} onChange={() => handleChangeActive(data?.active, index)} />
                                            <span className="toggle-switch-small-slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    }) : (<div></div>)}

                </div>
                <div className='d-flex justify-content-center mt-auto'>
                    {disabled ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            color='#F36523'
                            spin
                            size='3x'
                        />) :
                        (<div
                            className='candi-btn2 text-white m-5'
                            // type='button'
                            onClick={(e) => {
                                if (!disabled) {
                                    handleSaveClick(e)
                                }

                            }}
                        >
                            <span style={{ fontWeight: 'bold' }}>Save</span>
                        </div>)}
                </div>
                {/* Positioning the image in the bottom right corner */}
                <div className='position-absolute bottom-0 end-0'>
                    <img src={imagePath.ic_fuel_orange_holo} style={{ width: '200px', height: '200px', opacity: '0.08', zIndex: '0', marginBottom: '-1.8rem', marginRight: '-2.5rem' }} />
                </div>
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => {
                        setShowSuccessPopup(false)
                        setDisabled(false);
                    }}
                    message={message}
                />
            )}
            {showPriceUpdateWarning && (
                <PriceUpdateWarningPopup
                    show={true}
                    price={fuelPrice}
                    handleClose={() => setShowPriceUpdateWarning(false)}
                    handleSaveFuelPrice={() => {
                        updateFuelPrice();

                    }}
                />
            )}
            {showAddUpdateGradeFuel && (
                <AddUpdateGradeFuelPopup
                    type={popupType}
                    handleClose={() => setShowAddUpdateGradeFuel(false)}
                    data={gradeData}
                    grades={gradesList}
                    handleAddGradeInfo={(liters, grade, price) => handleAddNewGradeInfo(liters, grade, price)}
                    handleUpdateGradeInfo={(gradeInfo) => handleUpdateGradeInfo(gradeInfo)}
                />

            )}
        </div>
    );
};

//make this component available to the app
export default GradeFuels;
