import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'

import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useCreateJobsButtonContext } from '../../../context/useJobsButtonContext'
import { useCompetenciesButtonContext } from '../../../context/useCreateCompetenciesButton'
import { useCreateJobsContext } from '../../../context/useCreateJobsContext'
import SettingTabs from './SettingTabs'
const Setting = () => {
  // const {jobState, setjobState} = useContext(SetJobsContext)
  const { jobButton, setjobButton } = useCreateJobsButtonContext()
  const { CompetenciesButton, setCompetenciesButton } = useCompetenciesButtonContext()
  const { jobsState, setjobsState } = useCreateJobsContext()
  // console.log(' dash', loggedIn)
  useEffect(() => {
    setjobButton(false)
    setCompetenciesButton('')
    setjobsState(false)

    console.log('CompetenciesButton', CompetenciesButton)
    console.log('jobButton', jobButton)
    console.log('jobstate', jobsState)
    return () => {
      setjobButton(false)
      setjobsState(false)
      setCompetenciesButton('')
    }
  }, [])
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Settings' })}</PageTitle>
      <div className='col-md-12 dashboard-top'>
        {/* <h1>Dashboard</h1> */}
        <h3>
          <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/dashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            My Dashboard
          </Link>
          <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
          <Link to="/settings" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
            Settings
          </Link>
        </h3>
      </div>
      <SettingTabs />
      {/* begin::Row */}
      <div className=' d-flex justify-content-center gy-5 gx-xl-8'></div>
    </>
  )
}

export default Setting
