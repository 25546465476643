//import liraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import infoIcon from '../../../../app/modules/Setting/images/information.png';
import './ModelStyles.css'
import icWarning from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import { useAuth } from '../../../../app/modules/auth/core/Auth'

type Props = {
    show: boolean
    handleClose: () => void
    handleSubmit: (reason) => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

// create a component
const GetApprovalReasonPopup = ({ show, handleClose, handleSubmit }: Props) => {

    const [reason, setReason] = useState('');

    const handleTextareaChange = (event) => {
        setReason(event.target.value);
    };

    const handleSave = (e) => {
        e.preventDefault();

        if (reason) {
            handleSubmit(reason)
        } else {
            window.alert('Please enter reason first')
        }
    };

    return createPortal(
        <Modal

            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-600px'
            show={show}
            // onHide={handleClose}
            backdrop={true}
        >
            <>
                <div>
                    <div className=' no-header-model123'>
                        <div className='modal-header no-header-model123'>
                            <h2></h2>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>

                                <img alt='' src={Layout}></img>
                            </div>
                        </div>
                        <div className='mx-auto d-flex flex-column '>
                            <img alt='' className='add-warning-image' src={icWarning} ></img>
                            <h3 className='fw-bolder mx-auto mt-5 px-18' style={{ textAlign: 'center' }}>
                                Add Reason
                            </h3>
                        </div>
                        <div>
                            <label className='fw-regular mt-5 ps-7' style={{ textAlign: 'center' }}>
                                <img src={infoIcon} style={{ height: '18px', width: '18px', marginRight: '1rem' }} />
                                These comments would go to approving authorities for reconsideration
                            </label>
                            <div className='mx-auto d-flex flex-column text-center mt-6'> 
                                <textarea
                                    rows={4} // You can adjust the number of rows as needed
                                    cols={50} // You can adjust the number of columns as needed
                                    style={{ marginTop: '0.5rem', width: '90%', padding: '0.5rem', margin: '0 auto' }}
                                    placeholder="Enter your comments here..."
                                    value={reason} // Bind the value to the state variable
                                    onChange={handleTextareaChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='modal-body px-lg-8'>
                        <div className='card'>
                            <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                                <form>
                                    <div className='sureunsure'>
                                        <button
                                            onClick={handleSave}
                                            type='button'
                                            className='btn px-10 bgcolorofbutoonofsubmit'
                                        >
                                            Submit Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Modal>,
        modalsRoot
    )
};

//make this component available to the app
export default GetApprovalReasonPopup;
