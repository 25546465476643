//import liraries
import React, {FC, useState, useEffect, forwardRef, ForwardRefRenderFunction} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'

import {KTSVG} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import './cards.css'

import plus from '../images/plus.png'
import cross from '../images/cross.png'
import leadership from '../images/leadership@2x.png'
import skills from '../images/skill.png'
import bin from '../images/bin.png'
import hoverBin from '../images/bin-delete.png'
import editJob from '../images/edit-icon.png'
import editJobHover from '../images/edit-hover.png'
import {UpdateGroupCompetency} from '../../modals/create-app-stepper/UpdateGroupCompetency'
import WarningTARemovePopup from '../../modals/JobFunnelsModels/WarningTARemovePopup'
import LoaderView from '../../modals/JobFunnelsModels/LoaderView'
import ShowError from '../toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import SuccessDialog from '../../modals/JobFunnelsModels/SuccessDialog'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
const DELETE_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/`

type Props = {
  updateTotalGroupComp: (value) => void
}
type RefType = {
  GetAllIndividualCompetencies: () => Promise<void>
}
// create a component
const GroupCompetenciesDesign: ForwardRefRenderFunction<RefType, Props> = ({updateTotalGroupComp}, ref) => {
  const [popup, setPopup] = useState(<></>)
  const {auth, logout} = useAuth()
  const [selectedCompetency, setSelectedCompetency] = useState('')
  const [showWarningPopup, setShowWarningPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [currentGroupCompetencyData, setCurrentGroupCompetencyData] = useState<any>()
  const [showEditGroupCompetencyPopup, setShowEditGroupCompetencyPopup] = useState(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState(false)

  // const createPopup = (data) => {
  //   setPopup(
  //     <UpdateGroupCompetency
  //       show={true}
  //       id={data.competency_id}
  //       description={data.description}
  //       CompData={JSON.parse(data.competencies)}
  //       name={data.name}
  //       handleClose={() => setPopup(<></>)}
  //       fetchCompetenciesList={GetAllIndividualCompetencies}
  //     />
  //   )
  // }

  const [over, setOver] = useState(-1)
  const [overbin, setOverbin] = useState(-1)
  const [CardClickedState, setCardClicked] = useState(-1)
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [IndividualCompetenciesButtons, setIndividualCompetenciesButtons] = useState<any>([])
  const [CompaArrayFromDB, setCompaArrayFromDB] = useState<any>([])
  const icon = 'leadersip'
  const [CompetencyId, setCompetencyId] = useState('')

  var TotalCompetencies = []
  const CompetenciesTypesArray: string[] = []
  var TotalIndividualCom = 0
  const [CompArray, setCompArray] = useState<any>([])
  const [CompArrayIndividual, setCompArrayIndividual] = useState<any>([])
  const [allIndividualCompetencies, setAllIndividualCompetencies] = useState<any>([])

  const CompetenciesNamesArray: any[] = []
  const CompetenciesNamesArrayIndividual: any[] = []
  const [WidgetID, setWidgetID] = useState(-1)
  const fetchIndividualCompetencies: any[] = []
  const [CompData, setCompData] = useState<any[]>([])
  const [description, setDescription] = useState<any>()
  const [name, setCompetencyName] = useState<any>()
  const [DataForUpdate, setDataForUpdate] = useState<any>({})

  // Pass the ref to the parent component
  React.useImperativeHandle(ref, () => ({
    GetAllIndividualCompetencies,
  }))

  const GetAllIndividualCompetencies = async () => {
    // console.log('fetching list of group competencies');
    await axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        TotalCompetencies = resp.data.competencies
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
    let totalGroupCount = 0;
    for (var i = 0; i < TotalCompetencies.length; i++) {
      CompetenciesTypesArray[i] = TotalCompetencies[i]['type']

      if (TotalCompetencies[i]['type'] === 'Group') {
        CompetenciesNamesArray[i] = TotalCompetencies[i]
        TotalIndividualCom = TotalIndividualCom + 1
        totalGroupCount = totalGroupCount+1;
      }
      if (TotalCompetencies[i]['type'] === 'Individual') {
        CompetenciesNamesArrayIndividual[i] = TotalCompetencies[i]['name']
      }
    }
    if (CompetenciesNamesArray === undefined) {
      setCompArray(' ')
      updateTotalGroupComp(0)
    } else {
      setCompArray(CompetenciesNamesArray)
      // updateTotalGroupComp(CompetenciesNamesArray.length)
      updateTotalGroupComp(totalGroupCount)
      setCompArrayIndividual(CompetenciesNamesArrayIndividual)
      setAllIndividualCompetencies(CompetenciesNamesArrayIndividual)
      setIndividualCompetenciesButtons(fetchIndividualCompetencies)
    }
  }
  const DeleteCompetency = async (value) => {
    setLoading(true)
    const url = DELETE_INDIVIDUAL_COMPETENCY_URL + value
    await axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        setLoading(false)
        if (resp.status === 200 || resp.status === 204) {
        }

        GetAllIndividualCompetencies()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const removeCompetency = (index: any, data: any, CompetencyArrayDB, compID: any) => {
    let arr: any[] = [...CompetencyArrayDB]
    let arr2: any[] = []
    let j = 0
    for (let i = 0; i < arr.length; i++) {
      let g = {}
      g['group_' + j] = {
        id: j,
        name: arr[i]['group_' + i].name,
      }
      console.log('indivi_g: ', g)
      if (i !== index) {
        arr2.push(g)
        j++
      }
    }
    const CompetencyData = {
      name: data.name,
      description: data.description,
      competencies: JSON.stringify(arr2),
      behaviors: '',
    }
    var competencyDataDB = JSON.stringify(CompetencyData)
    const url = DELETE_INDIVIDUAL_COMPETENCY_URL
    const BaseURL = url + compID
    axios
      .put(BaseURL, competencyDataDB, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        // updating list of competencies in group competency
        let competenciesData: any = [...CompArray]
        competenciesData = competenciesData.filter((obj) => obj !== null && obj !== undefined)
        for (let i = 0; i < competenciesData.length; i++) {
          let data = competenciesData[i]
          if (data !== null && data !== undefined) {
            let id = data.competency_id
            if (id === compID) {
              competenciesData[i].competencies = JSON.stringify(arr2)
              setCompArray(competenciesData)
            }
          }
        }

        setCompaArrayFromDB([])
        setWidgetID(-1)
        setDataForUpdate({})
        setCardClicked(-1)

        setMessage('Group Competency Updated Successfully')
        setShowSuccessPopup(true)
      })
      .catch(function (error) {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response?.data?.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const AddCompetencyToWidget = async (index) => {
    if (WidgetID !== -1) {
      let arr = [...CompaArrayFromDB]
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]['group_' + i].name === index) return
      }
      // console.log('arr: ', arr)
      let g = {}
      g['group_' + arr.length] = {
        id: arr.length,
        name: index,
      }
      arr.push(g)
      console.log('arr: ', arr)
      let s = new Set(arr)
      let final = Array.from(s)
      setCompaArrayFromDB(final)

      const CompetencyDataUpdateIndividual = {
        name: DataForUpdate.name,
        description: DataForUpdate.description,
        competencies: JSON.stringify(final),
        behaviors: '',
      }

      var data = JSON.stringify(CompetencyDataUpdateIndividual)

      const url = DELETE_INDIVIDUAL_COMPETENCY_URL
      const BaseURL = url + WidgetID
      axios
        .put(BaseURL, data, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        })
        .then(function (response) {
          // updating list of competencies in group competency
          let competenciesData: any = [...CompArray]
          competenciesData = competenciesData.filter((obj) => obj !== null && obj !== undefined)
          for (let i = 0; i < competenciesData.length; i++) {
            let data = competenciesData[i]
            let id = data.competency_id
            if (id === WidgetID) {
              competenciesData[i].competencies = JSON.stringify(arr)
              setCompArray(competenciesData)
            }
          }

          setMessage('Group Competency Updated Successfully!')
          setShowSuccessPopup(true)
        })
        .catch(function (error) {
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response?.data?.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    } else {
      setMessage('Please select any card of group competencies first!')
      setShowErrorMessage(true)
      return
    }
  }

  useEffect(() => {
    ;(async () => {
      GetAllIndividualCompetencies()
    })()
  }, [])

  const CardClicked = (value: any, data: any, id: any, competencyArray) => {
    setCardClicked(value)
    setWidgetID(id)
    setCompaArrayFromDB(competencyArray)
    setDataForUpdate(data)
  }

  const searchCompetencies = (event) => {
    let keyword = event.target.value.toLowerCase()
    if (keyword) {
      const filteredItems = allIndividualCompetencies.filter((item) => item.toLowerCase().includes(keyword))
      if (filteredItems.length > 0) {
        setCompArrayIndividual(filteredItems)
      } else {
        setCompArrayIndividual(allIndividualCompetencies)
      }
    } else {
      setCompArrayIndividual(allIndividualCompetencies)
    }
  }

  return (
    <>
      <div className='d-flex mt-6'>
        <div className='col-xl-9 showing-sompetencies'>
          {CompArray ? (
            CompArray.map((data, indexmain) => (
              <div key={indexmain} className='width-setting-forgrpCards '>
                <div
                  onClick={() => CardClicked(indexmain, data, data.competency_id, JSON.parse(data.competencies))}
                  style={{backgroundColor: 'white'}}
                  className={`cursor-pointer notcliked p-6 shadow-sm ${
                    CardClickedState === indexmain ? 'card-clicked-hover' : 'card-bg '
                  }  `}
                >
                  {/* card-clicked-hover */}
                  <div className=''>
                    <div className='d-flex align-items-center '>
                      <img src={leadership} alt='Group competency icon' className='header-image-grp-com' />

                      <div className='d-flex align-items-center ms-auto'>
                        <img
                          className='cursor-pointer me-2'
                          onClick={() => {
                            // createPopup(data)
                            setCurrentGroupCompetencyData(data)
                            setShowEditGroupCompetencyPopup(true)
                          }}
                          src={imagePath.ic_edit_circle_orange}
                          width={20}
                          height={20}
                        />
                        <img
                          className=' cursor-pointer'
                          src={imagePath.ic_delete_orange_cross}
                          onClick={() => {
                            setSelectedCompetency(data.competency_id)
                            setMessage('Are you sure you want to delete group competency?')
                            setShowWarningPopup(true)
                          }}
                          width={18}
                          height={18}
                        />
                      </div>
                    </div>
                    <div className='pt-6'>
                      <span className=' card-label-individual-name text-gray-800 fw-bolder fs-3 '>{data.name}</span>
                    </div>

                    <div
                      style={{margin: '10px 0px 0px 0px', whiteSpace: 'nowrap', display: 'inline-block'}}
                      className='grey-chip px-3 py-2  '
                    >
                      <span
                        style={{
                          color: '#666666',
                        }}
                        className='fs-8 fw-bold d-flex align-items-center '
                      >
                        <span>
                          <img src={skills} />
                        </span>
                        &nbsp; Competencies {JSON.parse(data.competencies).length}
                      </span>
                    </div>

                    <div className='mt-6'>
                      <div className='description-container'>
                        <p className='description-black-text'>{data?.description ? data?.description : ''}</p>
                        {(data?.description ? data?.description.length : 0) > 90 ? (
                          <label
                            onClick={() => {
                              // createPopup(data)
                              setCurrentGroupCompetencyData(data)
                              setShowEditGroupCompetencyPopup(true)
                            }}
                            className='form-label fs-7  fw-semibold'
                            style={{cursor: 'pointer', height: '1rem'}}
                          >
                            See more
                          </label>
                        ) : (
                          <label style={{height: '1rem'}}> </label>
                        )}
                      </div>
                    </div>

                    <hr className='hr mt-3'></hr>

                    <div>
                      <div className=' badgescompetencies  '>
                        <div className='flex-seeting-forgrp me-2' style={{overflow: 'auto', maxHeight: '140px'}}>
                          {data.competencies
                            ? JSON.parse(data?.competencies).map((df, index) => (
                                <span key={index} className='orange-tag fs-7 fw-bolder px-3 py-2 mt-4 me-4 d-flex align-items-center'>
                                  {df['group_' + index].name}
                                  <span>
                                    <img
                                      className='cursor-pointer ms-2'
                                      onClick={() => {
                                        removeCompetency(index, data, JSON.parse(data.competencies), data.competency_id)
                                      }}
                                      src={imagePath.ic_cross_orange}
                                    />
                                  </span>
                                </span>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {popup}
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>

        <div className='col-xl-3'>
          <div className='card-bg shadow-sm p-6 mt-3'>
            {/* begin::Beader */}
            <div className='d-flex flex-wrap flex-stack '>
              <h4 className='fw-bolder'>Competencies Dictionary</h4>
            </div>

            <div className='mt-4'>
              <div>
                <span>
                  <div className='' id='kt_chat_contacts_header'>
                    <form className=' position-relative' autoComplete='off'>
                      <img
                        src={imagePath.ic_search_gray}
                        width={18}
                        height={18}
                        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-3 translate-middle-y'
                      />
                      <input
                        type='text'
                        className='form-control  ps-10 pe-3'
                        name='search'
                        placeholder='Search..'
                        onChange={(event) => searchCompetencies(event)}
                      />
                    </form>
                    <label className='form-label fs-7 mt-4 fw-semibold '>Click to add</label>
                  </div>
                </span>
              </div>

              <div
                // className='flex-seeeting-for-indiviual '
                style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', flexWrap: 'wrap', maxWidth: '100%'}}
              >
                {CompArrayIndividual.map((name, index) => (
                  <div key={index} className='mb-2 me-2'>
                    <span
                      onClick={() => AddCompetencyToWidget(name)}
                      className='grey-chip fs-5 fw-bold px-4 py-3 align-items-center d-flex '
                    >
                      <span style={{cursor: 'pointer'}}>
                        <img src={plus}></img>
                      </span>
                      {/* &nbsp; Data Analytics */}
                      {name}
                    </span>
                  </div>
                ))}

                {/* <p className='text-muted'>8th August 2022</p> */}
              </div>
            </div>

            {/* end::Body */}
          </div>

          {/* <CompetenciesDictionary /> */}
        </div>
      </div>

      {showEditGroupCompetencyPopup && (
        <UpdateGroupCompetency
          show={true}
          id={currentGroupCompetencyData?.competency_id}
          description={currentGroupCompetencyData?.description}
          CompData={JSON.parse(currentGroupCompetencyData?.competencies)}
          name={currentGroupCompetencyData?.name}
          handleClose={() => setShowEditGroupCompetencyPopup(false)}
          fetchCompetenciesList={GetAllIndividualCompetencies}
        />
      )}

      {showWarningPopup && (
        <WarningTARemovePopup
          show={true}
          message={message}
          handleClose={() => setShowWarningPopup(false)}
          handleYesSure={() => {
            setShowWarningPopup(false)
            DeleteCompetency(selectedCompetency)
          }}
        />
      )}
      {loading && <LoaderView message='Loading...' />}
      {showSuccessPopup && <SuccessDialog message={message} handleClose={() => setShowSuccessPopup(false)} />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

//make this component available to the app
export default forwardRef(GroupCompetenciesDesign)
