import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import './ModelStyless.css'
import addcompetenctimage from '../../images/warning@2x.png'
import Layout from '../images/Layout-4-blocks (3).png'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import ScheduleInterview from '../../../../app/modules/JobsFunneling/ScheduleInterview/ScheduleInterview'
import {useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import interviewheadericon from '../images/interview@2x copy.png'
import Box from '@mui/material/Box'
import {TextField} from '@mui/material'
import Usericon from './img/two-users@2x.png'
import Timericon from './img/Group 10169@2x.png'
import Copyicon from './img/interview2x.png'
import Descriptionicon from './img/description@2x.png'
import InformationIcon from './img/information (3)@2x.png'
import IconTile from './img/Group 9982.png'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import ExprienceIcon from './img/experience@2x.png'
import UserIcon from './img/Group 9681.png'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_RECRUITERS_URL = `${API_URL}/recruiter/get`
export const GET_RECRUITER_URL = `${API_URL}/recruiter/`
export const CREATE_EMAIL_URL = `${API_URL}/panel/reschedule-interview`
export const SEND_EMAIL_URL = `${API_URL}/panel/send-email-to-members`
export const ACCEPT_JOB_URL = `${API_URL}/jobapplication/accept-current-job-application`
// export const SAVE_INTERVIEW_STAGE1 = `${API_URL}/jobapplication/schedule-interview-stage1`
export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`
export const GET_ALL_PANEL_MEMBER = `${API_URL}/panel/get-all-members`

type Props = {
  show: boolean
  handleClose: () => void
  name: string
  jobapp_id: string
  email: string
  user_id: string
  interviewStage1: string
  profile_pic: string
  position: string
  bexp: string
  jexp: string
}

type InputProps = {
  members?: string
  duration?: string
  date?: string
  link?: string
  description?: string
}

interface Recruiter {
  user_id: string
  name: string
  email: string
  currentTarget: string
}

interface Submit {
  jobapp_id: string
  index: string
  name: string
  email: string
}
const modalsRoot = document.getElementById('root-modals') || document.body

const InterviewDetailsStage3 = ({
  show,
  name,
  jobapp_id,
  email,
  user_id,
  interviewStage1,
  profile_pic,
  position,
  bexp,
  jexp,
  handleClose,
}: Props) => {
  console.log({user_id})
  console.log({jobapp_id})
  console.log({name})

  console.log({interviewStage1})
  const data = JSON.parse(interviewStage1)
  console.log({data})

  let cand_email = email
  const [selectedInterviewers, setSelectedInterviewers] = useState<any>([])
  const [interviewers, setInterviewers] = useState<Recruiter[]>([])
  const [interviewerData, setInterviewersData] = useState<Recruiter[]>([])
  const [dataFetched, setDataFetched] = useState(false)
  const [recruiterEmail, setRecruiterEmail] = useState({})

  const [duration, setDuration] = React.useState('')
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(data?.interview_date))
  const navigate = useNavigate()
  const [Poc, setPoc] = useState({})
  const [RecruitersD, setRecruitersD] = useState([{}])
  const [Recruiter, setRecruiter] = useState([{}])
  const [candidateData, setCandidateData] = useState([])
  const [formData, setFormData] = useState<InputProps>()
  const [panelName, setPanelName] = useState<any>([])
  const [panelEmail, setPanelEmail] = useState<any>([])
  const [panelId, setPanelId] = useState<any>([])
  const [items, setItems] = useState<any>([])

  const [formInputs, setFormInputs] = React.useState({
    link: '',
    description: '',
    duration: '',
    time: '',
  })

  const {auth} = useAuth()
  let pocData = [{}]
  let recruiterData = [{}]
  let result: any = {}
  let candResult = {}
  let LocalResult = {}
  const [alreadyPanelMembers, setAlreadyPanelMembers] = useState<any>([])

  const GETALLPANELMEMBERS = async () => {
    await axios
      .post(
        GET_ALL_PANEL_MEMBER,
        {
          jobapp_id,
          name: 'stage1',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (res) => {
        console.log('RES', res)
        const alreadySelectedPanelMembers = res?.data?.map((member) => ({
          userName: member?.userName,
          profilePic: member?.profilePic,
        }))
        setAlreadyPanelMembers(alreadySelectedPanelMembers)
      })
  }
  console.log({alreadyPanelMembers})

  const handleSubmit = async (e) => {
    e.preventDefault()

    await axios
      .post(
        CREATE_EMAIL_URL,
        {
          panel: panelName['result'],
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      .then(async (res) => {
        console.log('RES-PANEL-CREATE', res)
      })

      .then(async (res) => {
        axios
          .post(
            SEND_EMAIL_URL,
            {
              interviewData: {
                candidate_name: name,
                job_experience: '3',
                banking_experience: '4',
                interview_date: value,
                interview_time: data?.interview_time,
                duration: data?.duration,
                invitation_link: data?.invitation_link,
                description: data?.description,
              },
              jobapp_id: jobapp_id,
              name: 'stage3',
            },
            {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
              },
            }
          )
          .then(async (res) => {
            console.log('RES-SEND-EMAIL', res)
          })
      })

    handleClose()
  }

  const GetAllRecruiters = async () => {
    try {
      const resp = await axios.get(GET_ALL_RECRUITERS_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      console.log('RESPONSE RECRUITER', resp.data.recuiter)
      const recruiters = resp.data.recuiter
      const promises = recruiters.map(async (recruiter) => {
        const url = GET_RECRUITER_URL + recruiter['user_id']
        const response = await axios.get(
          url,
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
              'Content-Type': 'application/json',
            },
          }

          // console.log('recs', recruiterEmail)
        )
        console.log('RECRUITER ALL ', response.data.recuiter)
        return response.data.recuiter as Recruiter
        console.log('recs', Recruiter)
      })
      const interviewers = await Promise.all(promises)
      setInterviewers(interviewers)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue)
    console.log(value)
  }
  const handleChangeDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string)
  }

  const handleInputs = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
    console.log('form', formData)
  }

  const selectInterviewer = (e) => {
    console.log(e.target.id)
    let userId = e.target.value
    const index: any = selectedInterviewers?.findIndex((obj) => obj.user_id === userId)
    if (index === -1) {
      let arr1 = interviewers.filter((temp) => temp.user_id === userId)[0]
      console.log('arr1', arr1)

      let temp: any = [...selectedInterviewers, arr1]

      setSelectedInterviewers(temp)

      var name: any = temp.map((item) => item['name'])
      var email: any = temp.map((item) => item['email'])
      var member_id: any = temp.map((item) => item['user_id'])

      candResult = {
        jobapp_id,
        name: 'stage3',
        member_id: user_id,
        email: cand_email,
        index: '0',
        availability: 'true',
        member_type: 'candidate',
      }

      let jobPosterEmail = items['email']
      let jobPosterID = items['user_id']

      LocalResult = {
        jobapp_id,
        name: 'stage3',
        member_id: jobPosterID,
        email: jobPosterEmail,
        index: '0',
        availability: 'true',
        member_type: 'job-poster',
      }

      result = temp.map(({user_id, email}) => ({
        member_id: user_id,
        email: email,
        jobapp_id,
        index: '0',
        name: 'stage3',
        availability: 'true',
        member_type: 'panel',
      }))
      result = result.concat(candResult, LocalResult)
      console.log({result})

      setPanelName({...panelName, result})
      setPanelEmail({...panelEmail, email})
      setPanelId({...panelId, member_id})
    }
  }

  const removeInterviewer = (event, index) => {
    let currentInterviewers = [...selectedInterviewers]

    currentInterviewers.splice(index, 1)
    setSelectedInterviewers(currentInterviewers)
  }

  const onInputChange = (event) => {
    formInputs[event.target.name] = event.target.value
    setFormInputs(formInputs)
    console.log('onInputChange => formInputs', formInputs)
  }

  const handleChangeDescription = (event) => {
    formInputs['description'] = event.target.value
    setFormInputs(formInputs)
  }

  useEffect(() => {
    GETALLPANELMEMBERS()
    let localUser: any = localStorage.getItem('kt-auth-react-v')
    setItems(JSON.parse(localUser))
    if (interviewers.length === 0) {
      GetAllRecruiters()
    }
  }, [interviewers, setSelectedInterviewers])

  console.log('dsds', selectedInterviewers)
  console.log('PanelName', panelName)
  console.log({result})
  console.log({jobapp_id})

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      width='104% !important'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model1234'>
        <div className='modal-header no-header-model123'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              alignItems: 'center',
              marginLeft: '15rem',
            }}
          >
            <img src={interviewheadericon} style={{height: '4vh', marginLeft: '2rem'}} />
            <h2 className='interview-header-container-text'> Interview Details</h2>
          </div>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={Layout}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <div className='profile-Container'>
            <div className='left-container'>
              <div>
                <img className='profile-user-icon' src={profile_pic !== null ? profile_pic : UserIcon} alt='' />
              </div>
              <div className='middle-container-user-details'>
                <label className='middle-container-username'>{name}</label>
                <div className='middle-container-role'>
                  <label>Position: {position}</label>
                </div>
              </div>
            </div>
            <div className='right-container'>
              <div className='right-container-img'>
                <img src={ExprienceIcon} style={{width: '60%'}} />
              </div>
              <div>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Banking Experience :
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {bexp} yrs
                  </span>
                </label>
                <label className='right-container-text' style={{whiteSpace: 'nowrap'}}>
                  Total Experience :
                  <span className='right-container-span' style={{whiteSpace: 'nowrap'}}>
                    {(parseFloat(bexp) + parseFloat(jexp)).toFixed(2)} yrs
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form onSubmit={handleSubmit}>
              <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                {/* <label style={{color: '#80808F'}} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to unplish this Job Post?
                </label> */}
                <div className='Input-Field-Container'>
                  <div>
                    <img src={Usericon} className='slider-icon' />
                    <hr className='hr-line-first-child'></hr>
                    <img src={Timericon} className='slider-icon' />
                    <hr className='hr-line-second-child'></hr>
                    <img src={Copyicon} className='slider-icon' />
                    <hr className='hr-line-third-child'></hr>
                    <img src={Descriptionicon} className='slider-icon' />
                  </div>

                  <div>
                    <div className='upper-input-container'>
                      <div className='input-container-upper-row'>
                        <Box
                          sx={{
                            width: 400,
                          }}
                        >
                          <FormControl fullWidth placeholder='Select Panel Members'>
                            <InputLabel id='demo-simple-select-label'>Select Panel Members</InputLabel>
                            <Select
                              name='Select Panel Members'
                              value=''
                              placeholder='Select Panel Members'
                              label='Select Panel Members'
                              onChange={(e) => selectInterviewer(e)}
                            >
                              {interviewers.length > 0 ? (
                                interviewers.map((option, i) => (
                                  <MenuItem
                                    key={option['user_id']}
                                    value={option['user_id']}
                                    id={option['user_id']}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: '10px',
                                    }}
                                  >
                                    <img
                                      src={option['profile_pic'] !== null ? option['profile_pic'] : UserIcon}
                                      className='interviewer-icon-sched'
                                      alt=''
                                    />
                                    {option['first_name']}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>No Recruiers Available</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                        <>
                          <div className='user-tile'>
                            {alreadyPanelMembers ? (
                              alreadyPanelMembers.map((option, index) => (
                                <label className='user-tile-content'>
                                  <img
                                    src={option['profilePic'] !== null ? option['profilePic'] : UserIcon}
                                    className='selected-interviewer-icon-sched'
                                    alt=''
                                  />
                                  {option['userName']}
                                  <span>
                                    <img
                                      alt=''
                                      src={Layout}
                                      onClick={(event) => removeInterviewer(event, index)}
                                      id={JSON.stringify(option)}
                                    ></img>
                                  </span>
                                </label>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className='user-tile'>
                            {selectedInterviewers ? (
                              selectedInterviewers.map((option, index) => (
                                <label className='user-tile-content'>
                                  <img
                                    src={option['profile_pic'] !== null ? option['profile_pic'] : UserIcon}
                                    className='selected-interviewer-icon-sched'
                                    alt=''
                                  />
                                  {option['first_name']}
                                  <span>
                                    <img
                                      alt=''
                                      src={Layout}
                                      onClick={(event) => removeInterviewer(event, index)}
                                      id={JSON.stringify(option)}
                                    ></img>
                                  </span>
                                </label>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      </div>

                      <div className='inner-input-contaier'>
                        <div className='upper-input-row-container'>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <Box sx={{width: 228}}>
                                <DesktopDatePicker
                                  inputFormat='MM/DD/YYYY'
                                  value={value}
                                  onChange={handleChange}
                                  disabled
                                  className='red-background'
                                  renderInput={(params) => <TextField {...params} name='selectedDate' />}
                                />
                              </Box>
                            </Stack>
                          </LocalizationProvider>

                          <Box
                            sx={{
                              width: 160,
                            }}
                          >
                            <TextField
                              id='time'
                              label='Time'
                              type='time'
                              name='time'
                              defaultValue={data?.interview_time}
                              onChange={onInputChange}
                              disabled
                              className='red-background'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </Box>
                        </div>

                        <Box
                          sx={{
                            width: 228,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>{data?.duration} minutes</InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='Duration'
                              name='duration'
                              required
                              disabled
                              onChange={onInputChange}
                              className='red-background'
                            >
                              <MenuItem value={10}>10 minutes</MenuItem>
                              <MenuItem value={20}>20 minutes</MenuItem>
                              <MenuItem value={30}>30 minutes</MenuItem>
                              <MenuItem value={40}>40 minutes</MenuItem>
                              <MenuItem value={50}>50 minutes</MenuItem>
                              <MenuItem value={60}>60 minutes</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>

                      <div className='input-link-container'>
                        <div className='schedule-interview-address' style={{marginBottom: '1rem'}}>
                          <div className='schedule-interview-address-buttons'>
                            <input type='radio' value='Online Interview' name='interview' className='schedule-interview-checkbox' />
                            <label>Online Interview</label>
                          </div>

                          <div className='schedule-interview-address-buttons'>
                            <input type='radio' value='Onsite Interview' name='interview' className='schedule-interview-checkbox' />
                            <label>Onsite Interview</label>
                          </div>
                        </div>
                        <Box
                          sx={{
                            width: 391,
                          }}
                        >
                          <TextField
                            fullWidth
                            name='link'
                            required
                            disabled
                            onChange={onInputChange}
                            placeholder={data?.invitation_link}
                            className='red-background'
                          />
                        </Box>
                      </div>

                      <div className='input-description-container'>
                        <Box
                          sx={{
                            width: 391,
                          }}
                        >
                          <TextField
                            fullWidth
                            label='Description'
                            onChange={handleInputs}
                            required
                            InputLabelProps={{style: {fontWeight: 'bold', color: '#373D4A'}}}
                            disabled
                          />
                        </Box>
                        <Box
                          sx={{
                            width: 391,
                            height: 80,
                          }}
                        >
                          {/* <TextField
                                                        fullWidth
                                                        defaultValue={data?.description}
                                                        name='description'
                                                        onChange={onInputChange}
                                                        multiline
                                                        disabled
                                                        required
                                                        rows={5}
                                                        className="red-background"

                                                    /> */}

                          <ReactQuill
                            value={data?.description}
                            onChange={handleChangeDescription}
                            placeholder='Enter description'
                            modules={{
                              toolbar: [
                                [{header: [1, 2, 3, 4, false]}],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{color: []}, {background: []}],
                                [{align: []}],
                                [
                                  'link',
                                  // , 'image'
                                  // , 'video'
                                ],
                                ['clean'],
                              ],
                            }}
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'color',
                              'background',
                              'align',
                              'link',
                              // 'image',
                              // 'video',
                            ]}
                            style={{height: '90px'}}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='inputfield-lower-container'>
                <img src={InformationIcon} style={{width: '3%', marginTop: '2px'}} />
                <label className='inputfield-lower-container-text'>
                  By clicking on send the above interview details will be sent to Candidate & all Panel Member via email
                </label>
              </div>
              <div className='inputfield-button-container'>
                <input className='inputfield-btn-details' type='submit' name='Send' value='Update & Send' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {InterviewDetailsStage3}
