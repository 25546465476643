import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useState, useEffect } from 'react'
import './Jobs.css'
import ActiveJobs from './ActiveJobs/ActiveJobs'
import InActiveJobs from './InactiveJobs/InactiveJobs'
import ClosedJobs from './ClosedJobs/ClosedJobs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const JobsTabs = () => {
  const [Active, setActive] = useState('true')
  const [PageLoaded, setPageLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  const ChangeInActiveIndex = (ActiveState: string) => {
    if (PageLoaded) {
      if (ActiveState === 'ActiveButtonClicked') {
        setActive('true')
      } else if (ActiveState === 'InActiveButtonClicked') {
        setActive('false')
      } else if (ActiveState === 'ClosedButtonClicked') {
        setActive('closed')
      }
    }
  }

  useEffect(() => {
    setPageLoaded(true)
  }, [])
  const intl = useIntl()
  return (
    <>

      <div className=' d-flex justify-content-center gy-5 gx-xl-8' style={{ gap: '3px' }}>
        <button
          className={`${Active === 'true'
            ? ' Onclicked-Active-Button  btn-bg-white '
            : ' Notclicked-Active-Button text-white'
            } btn fw-bolder Activejobs-Leftbutton fs-4  custom-button-size btn-lg`}
          type='button'
          onClick={() => ChangeInActiveIndex('ActiveButtonClicked')}
        >
          Active Jobs
        </button>
        <button
          type='button'
          onClick={() => ChangeInActiveIndex('InActiveButtonClicked')}
          className={`${Active === 'false' ? 'Onclicked-Active-Button btn-bg-white' : 'Notclicked-Active-Button text-white'
            } btn fw-bolder Activejobs-Rightbutton fs-4  custom-button-size btn-lg`}
        >
          InActive Jobs
        </button>
        <button
          type='button'
          onClick={() => ChangeInActiveIndex('ClosedButtonClicked')}
          className={`${Active === 'closed'
            ? ' Onclicked-Active-Button  btn-bg-white '
            : ' Notclicked-Active-Button text-white'
            } btn fw-bolder Closedjobs-rightbutton fs-4  custom-button-size btn-lg`}
        >
          Closed Jobs
        </button>
      </div>
      {Active === 'true' ? (
        <>
          <ActiveJobs />
        </>
      ) : Active === 'false' ? (
        <>
          <InActiveJobs />
        </>
      ) : (
        <>
          <ClosedJobs />
        </>
      )}
{/* 
      {isLoading && (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size="10x"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    marginTop: '15rem'
                  }} />
        </div>
      )} */}

    </>
  )
}

export default JobsTabs
