import React from 'react'
import { useState, useEffect, useRef, RefObject } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useContext } from 'react'
// import {LoginContext} from '../../../App'
// import '../Settings.css'
// import rightarrow from '../images/keyboard_double_arrow_left_black_24dp.png'
// import './Tables.css'
import { RejectUserbyApplicationOnlineTest } from '../../../../_metronic/partials/modals/JobFunnelsModels/RejectUserApplicationOnlineTest'
import { ApproveUserApplicationOnlineTest } from '../../../../_metronic/partials/modals/JobFunnelsModels/ApproveUserApplicationOnlineTest'
import { ApproveAllUserApplicationOnlineTest } from '../../../../_metronic/partials/modals/JobFunnelsModels/ApproveAllUserApplicationOnlineTest'
import { RejectAllUserApplicationOnlineTest } from '../../../../_metronic/partials/modals/JobFunnelsModels/RejectAllUserApplicationOnlineTest'
import unchecked from '../images/Group 10062.png'
import checked from '../../auth/images/tick.png'
import './Onlinetest.css'
import threeedota from '../images/menu.png'
import AvatarIcon from '../img/Group 9681.png'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import { propTypes } from 'react-bootstrap/esm/Image'
import { object } from 'prop-types'
import ScheduleInterview from '../ScheduleInterview/ScheduleInterview'
import { useNavigate } from 'react-router-dom'
import { ScheduleInterviewPopUp } from '../../../../_metronic/partials/modals/JobFunnelsModels/ScheduleInterviewPopup'
import TestScorePopup from '../../../../_metronic/partials/modals/JobFunnelsModels/TestScorePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import WarningIcon from '../img/warning@3x (1).png'
import debounce from 'lodash/debounce'
import ViewProfileOnlineTest from '../../../../_metronic/partials/modals/JobFunnelsModels/ViewProfileOnlineTest'
import ScheduleInterViewMultipleCandidatesStage1 from '../../../../_metronic/partials/modals/JobFunnelsModels/ScheduleInterViewMultipleCandidatesStage1'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import { getTotalBankingExperience, getTotalYears } from '../../../../constants/CommonFunctions'
import imagePath from '../../../../constants/imagePath'
import CustomPagination from '../../dashboards/components/CustomPagination'
import clsx from 'clsx'
interface Resource {
  candidate_details: Object
  resource: Object
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const GET_TEST_DATA = `${API_URL}/jobapplication/get-attempt-test-data`

type Props = {
  job_id: any
  testExists: any
  trigger: any
}

const ApplicationsTableOnlineTest: React.FC<Props> = ({ job_id, trigger, testExists }) => {
  const navigate = useNavigate()
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const [popup, setpopup] = useState(<></>)
  const [approveAllPopup, setApproveAllPopup] = useState(<></>)
  const [currentJobId, setCurrentJobId] = useState('');
  const [showRejectAllPopup, setShowRejectAllPopup] = useState(false);
  const [InterviewSchedPopup, setInterviewSchedPopup] = useState(<></>)
  const [selectedPopup, setSelectedPopup] = useState(false)
  const [Master, setMaster] = useState(false)
  const [popupProfile, setpopupProfile] = useState(<></>)
  const [checkedstate, setcheckedstate] = useState(false)
  const [selectedApplicants, setSelectedApplicants] = useState<any>([])
  const [scheduleMultipleCandidateInterview, setScheduleMultipleCandidateInterview] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [multioptiondots, setmultioptiondots] = useState(false)
  const [result, setResult] = useState<any>([])
  const [openApprovalPopup, setOpenApprovalPopup] = useState(false)
  const [openProfilePopup, setOpenProfilePopup] = useState(false)
  const { auth } = useAuth()
  const [testScorePopup, setTestScorePopup] = useState<any>(<></>)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [jobTitle, setJobTitle] = useState<any>()
  const [showpop, setShow] = useState<any>(true)
  const [isLoading, setIsLoading] = useState(false)
  const [clicked, setClicked] = useState<any>(false)
  const approveButtonRefs = useRef<(HTMLButtonElement | null)[]>([])
  const dropdownRefAction = useRef<HTMLDivElement>(null)
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    age: '',
    bankingExp: '',
    totalExp: '',
    universities: ''
  })

  const createTestScorePopup = (
    name,
    jobapp_id,
    email,
    user_id,
    profile_pic,
    cnic,
    phone_number,
    education,
    skills,
    work_Experience,
    total_exp,
    banking_exp,
    nonBanking_exp,
    educations,
    certifications,
    personalData,
    profile_match,
    position
  ) => {
    console.log('test score popup')
    setTestScorePopup(
      <TestScorePopup
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        profile_pic={profile_pic}
        cnic={cnic}
        phone_number={phone_number}
        education={education}
        skills={skills}
        work_Experience={work_Experience}
        total_exp={total_exp}
        banking_exp={banking_exp}
        nonBanking_exp={nonBanking_exp}
        educations={educations}
        certifications={certifications}
        personalData={personalData}
        profile_match={profile_match}
        position={position}
        handleClose={() => {
          setTestScorePopup(null)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const createProfilePopup = (
    name,
    jobapp_id,
    email,
    user_id,
    profile_pic,
    cnic,
    phone_number,
    education,
    skills,
    work_Experience,
    total_exp,
    banking_exp,
    nonBanking_exp,
    educations,
    certifications,
    personalData,
    profile_match,
    position,
    recuiter_id,
    videoResumeUrl,
    testExists,
    index,
    enable
  ) => {
    setOpenProfilePopup(true)
    setpopupProfile(
      <ViewProfileOnlineTest
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        profile_pic={profile_pic}
        cnic={cnic}
        phone_number={phone_number}
        education={education}
        skills={skills}
        work_Experience={work_Experience}
        total_exp={total_exp}
        banking_exp={banking_exp}
        nonBanking_exp={nonBanking_exp}
        educations={educations}
        certifications={certifications}
        personalData={personalData}
        profile_match={profile_match}
        position={position}
        recuiter_id={recuiter_id}
        videoResumeUrl={videoResumeUrl}
        testExists={testExists}
        index={index}
        enable={enable}
        handleClose={() => {
          setpopupProfile(<></>)
          GetAllJobsApplicationsbypage()
        }}
        recaller={() => recaller(index)}
      />
    )
  }

  const recaller = (index) => {
    setClicked(index)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRefAction.current && !dropdownRefAction.current.contains(event.target)) {
        setmultioptiondots(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (clicked !== null) {
      if (approveButtonRefs.current[clicked]) {
        approveButtonRefs.current[clicked]?.click() // Use optional chaining to safely trigger the click event
      }
      setClicked(null) // Reset the clicked index
    }
  }, [clicked])

  const handleApprove = (name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp) => {
    console.log('hell0-boby')
    setShow(false)
    createPopupScheduleInterviewOne(name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp)
  }
  console.log({ showpop })

  const createPopupScheduleInterviewOne = (name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp) => {
    setpopup(
      <ScheduleInterviewPopUp
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        email={email}
        user_id={user_id}
        profile_pic={profile_pic}
        position={position}
        bexp={bexp}
        jexp={jexp}
        handleClose={() => {
          setpopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const createApprovalPopup = (showpop, name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp) => {
    setOpenApprovalPopup(true)
    setpopupApproval(
      <ApproveUserApplicationOnlineTest
        showpop={showpop}
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        profile_pic={profile_pic}
        position={position}
        bexp={bexp}
        jexp={jexp}
        handleClose={() => {
          setpopupApproval(<></>)
          GetAllJobsApplicationsbypage()
          setShow(false)
        }}
        handleApprove={() => handleApprove(name, jobapp_id, email, user_id, profile_pic, position, bexp, jexp)}
      />
    )
    setClicked(false)
    // setShow(true);
  }

  const createApproveAllPopup = (jobapp_id) => {
    setApproveAllPopup(
      <ApproveAllUserApplicationOnlineTest
        show={true}
        jobapp_id={jobapp_id}
        handleClose={() => {
          setApproveAllPopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const scheduleInterViewMultipleCandidatesStage1 = () => {
    setScheduleMultipleCandidateInterview(true)
  }

  const createRejectAllPopup = (job_id) => {

    setCurrentJobId(job_id);
    setShowRejectAllPopup(true);
  }

  const GetAllJobsApplicationsbypage = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        GET_ALL_JOB_APPLICATIONS,
        {
          job_id: job_id,
          getStage: 'online test',
          limit: params.limit,
          page: params.page,
          order: params.order,
          sort: params.sort,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      setIsLoading(false)
      setJobTitle(response?.data?.title)

      const jobApplications = response?.data?.jobs
      let pagination = response?.data?.pagination
      if (pagination) {
        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = { ...params }
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }
        setTotalPages(pagination?.totalPages || 0)
      }
      setResult(jobApplications)

    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  useEffect(() => {
    (async () => {
      await GetAllJobsApplicationsbypage()
    })()
  }, [job_id, trigger, params, popup])

  // useEffect(() => {
  //   if (trigger) {
  //     GetAllJobsApplicationsbypage()
  //   }
  // }, [trigger, job_id])

  //   const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const checkedStateFunction = () => {
    setcheckedstate(!checkedstate)
  }
  const isApplicantSelected = (candidateId) => {
    // return selectedApplicants.includes(candidateId);
    return selectedApplicants.some((applicant) => applicant.candidateData?.user_id === candidateId)
  }

  const updateSelectedApplicants = (candidateApplication) => {
    let candidateId = candidateApplication?.candidateData?.user_id
    // Check if candidateId is already in the list
    const isSelected = selectedApplicants.some((applicant) => applicant?.candidateData?.user_id === candidateId)
    // If already selected, remove it; otherwise, add it
    if (isSelected) {
      const updatedList = selectedApplicants.filter((applicant) => applicant?.candidateData?.user_id !== candidateId)
      setSelectedApplicants(updatedList)
    } else {
      // also check is the total selected applications are 7 do not add into list and show warning message else add application into list
      const totalSelected = selectedApplicants.length
      if (totalSelected === 7) {
        setMessage('Maximum limit reached. Cannot add more applicants.');
        setShowErrorMessage(true)
        return
      }
      setSelectedApplicants((prevList) => [...prevList, candidateApplication])
    }
  }

  const DisplayAllAoptiontoadopt = () => {
    setmultioptiondots(!multioptiondots)
  }

  const handleRefreshClick = () => {
    GetAllJobsApplicationsbypage();
  }

  return (
    <div style={{ margin: '8px 0px 0px 0px' }} className={`card shadow-sm mb-xl-8`}>
      <div className='card-header card-header1234864  border-0 pt-5'>
        <div className='ms-auto d-flex align-items-center'>
          <img className='me-4 cursor-pointer'
            title='Refresh data'
            src={imagePath.ic_refresh_orange}
            width={32}
            height={32}
            onClick={handleRefreshClick} />
          <div
            ref={dropdownRefAction}
            className={clsx(`d-flex align-items-center dropdown ${multioptiondots ? 'open' : ''}`)} id='kt_actions_applicants_toggle'>
            <div
              className={clsx('cursor-pointer symbol')}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              // data-kt-menu-placement='bottom-end'
              data-kt-menu-placement='bottom-start'
              data-kt-menu-flip='bottom'
              onClick={DisplayAllAoptiontoadopt}
            >
              <div className='d-flex align-items-center justify-content-center candi-btn2 px-3' style={{ height: '2.85rem' }}>
                <label className='text-white fs-5 fw-bolder cursor-pointer me-1'>Actions</label>
                <img className='cursor-pointer' src={imagePath.ic_arrow_down_white} width={18} height={18} style={{ objectFit: 'cover' }} />
              </div>
            </div>
            <div
              className={clsx(
                'dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-100px',
                {
                  show: multioptiondots,
                }
              )}
              data-kt-menu='true'
            >
              <div >
                <div>
                  <div
                    onClick={() => {
                      // createApproveAllPopup(job_id)
                      setmultioptiondots(false)
                      if (selectedApplicants && selectedApplicants.length > 0) {
                        scheduleInterViewMultipleCandidatesStage1()
                      } else {
                        setMessage('Please select one or more applications first and try again')
                        setShowErrorMessage(true)
                      }
                    }}
                    className=' liststyling fw-semibold fs-6'
                  >
                    Approve All
                  </div>
                  <div
                    onClick={() => {
                      setmultioptiondots(false)
                      createRejectAllPopup(job_id)
                    }}
                    className=' liststyling fw-semibold fs-6 mt-2'
                  >
                    Reject All
                  </div>
                </div>
                {approveAllPopup}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3 mt-8'>
        <div style={{ position: 'relative' }}>
          {isLoading && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-borders'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}

                  <th style={{ padding: '0px 0px 11px 20px' }} className='min-w-20px align-middle'></th>
                  <th className='min-w-100px align-items-center ps-2 align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>CNIC</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Total Experience</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Banking Experience</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>University</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Profile Match</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' style={{ whiteSpace: 'nowrap' }}>
                    <label className=' form-label fw-bolder fs-6 me-2'>Test Score</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                  </th>
                  {/* <th className='min-w-100px '></th> */}
                </tr>
              </thead>
              <tbody>
                {result && result?.map((candidategData, index) => {
                  let candidateId = candidategData?.candidateData?.user_id
                  let rowSelected = isApplicantSelected(candidateId)
                  const isEvenRow = index % 2 === 0
                  return (
                    <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                      <td className='firstrowmarginindex'>
                        {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                          <div
                            style={{ cursor: 'pointer' }}
                            // onClick={() => checkedStateFunction()}
                            onClick={() => updateSelectedApplicants(candidategData)}
                          >
                            {rowSelected === false ? (
                              <>
                                {' '}
                                <img src={unchecked}></img>
                              </>
                            ) : (
                              <>
                                {' '}
                                <img src={checked}></img>
                              </>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              className='profile-user-icon cursor-pointer'
                              src={
                                candidategData?.candidate?.profile_pic !== null
                                  ? candidategData?.candidate?.profile_pic
                                  : candidategData?.candidateData?.profile_pic !== null
                                    ? candidategData?.candidateData?.profile_pic
                                    : imagePath.userIcon
                              }
                              alt=''
                              onClick={() => {
                                createProfilePopup(
                                  candidategData?.candidateData?.fullName,
                                  candidategData?.jobapp_id,
                                  candidategData?.candidateData?.email,
                                  candidategData?.candidateData?.user_id,
                                  candidategData?.candidateData?.profile_pic,
                                  candidategData?.candidateData?.cnic,
                                  candidategData?.candidateData?.phone_number,
                                  candidategData?.institute,
                                  candidategData?.candidate?.skills,
                                  candidategData?.candidate?.work_experience,
                                  // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                  candidategData?.candidate?.totalExperience,
                                  candidategData?.banking_experience,
                                  candidategData?.job_experience,
                                  candidategData?.candidate?.qualification,
                                  candidategData?.candidate?.certification,
                                  candidategData?.candidateData?.dob,
                                  candidategData?.profile_match,
                                  jobTitle,
                                  candidategData?.recuiter_id,
                                  candidategData?.video_resume,
                                  testExists,
                                  index,
                                  candidategData?.test_aggregated_result
                                )
                              }}
                            />
                          </div>
                          <div
                            className='d-flex justify-content-start flex-column'
                          // onClick={() => {
                          //   createProfilePopup(
                          //     candidategData?.candidateData?.fullName,
                          //     candidategData?.jobapp_id,
                          //     candidategData?.candidateData?.email,
                          //     candidategData?.candidateData?.user_id,
                          //     candidategData?.candidateData?.profile_pic,
                          //     candidategData?.candidateData?.cnic,
                          //     candidategData?.candidateData?.phone_number,
                          //     candidategData?.institute,
                          //     candidategData?.candidate?.skills,
                          //     candidategData?.candidate?.work_experience,
                          //     // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                          //     candidategData?.candidate?.totalExperience,
                          //     candidategData?.banking_experience,
                          //     candidategData?.job_experience,
                          //     candidategData?.candidate?.qualification,
                          //     candidategData?.candidate?.certification,
                          //     candidategData?.candidateData?.dob,
                          //     candidategData?.profile_match,
                          //     jobTitle,
                          //     candidategData?.recuiter_id,
                          //     candidategData?.video_resume,
                          //     testExists,
                          //     index,
                          //     candidategData?.test_aggregated_result
                          //   )
                          // }}
                          >
                            <label className='job-funneling-table-content '>
                              {candidategData?.candidateData?.fullName}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <label className='job-funneling-table-content '>{candidategData?.candidateData?.cnic}</label>
                      </td>
                      <td>
                        <div className='job-funneling-table-content '>
                          {getTotalYears(JSON.parse(candidategData?.candidate?.work_experience))}
                        </div>
                      </td>

                      <td>
                        <div className='job-funneling-table-content '>
                          {getTotalBankingExperience(JSON.parse(candidategData?.candidate?.work_experience))}
                        </div>
                      </td>
                      <td>
                        <div className='job-funneling-table-content '>
                          {candidategData?.institute}
                          {/* {
                        JSON.parse(candidategData?.candidate?.qualification)[0]["qualification2"]["institution"]
                      } */}
                        </div>
                      </td>

                      <td >
                        <span className='job-funneling-table-content '>
                          {candidategData?.profile_match != null ? `${candidategData?.profile_match}%` : '0%'}
                        </span>
                      </td>

                      <td
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          createTestScorePopup(
                            candidategData?.candidateData?.fullName,
                            candidategData?.jobapp_id,
                            candidategData?.candidateData?.email,
                            candidategData?.candidateData?.user_id,
                            candidategData?.candidateData?.profile_pic,
                            candidategData?.candidateData?.cnic,
                            candidategData?.candidateData?.phone_number,
                            candidategData?.institute,
                            candidategData?.candidate?.skills,
                            candidategData?.candidate?.work_experience,
                            parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                            candidategData?.banking_experience,
                            candidategData?.job_experience,
                            candidategData?.candidate?.qualification,
                            candidategData?.candidate?.certification,
                            candidategData?.candidateData?.dob,
                            candidategData?.profile_match,
                            jobTitle
                          )
                        }}
                      >
                        <div className=' dateApplicationtable '>
                          {candidategData?.test_aggregated_result ? (
                            <label
                              className={`testscore-approval${candidategData?.test_aggregated_result !== null &&
                                parseInt(candidategData?.test_aggregated_result) > 80
                                ? ''
                                : '-less'
                                }`}
                            >
                              {candidategData?.test_aggregated_result}%
                            </label>
                          ) : (
                            <label className='test-score-await'>Result Awaited</label>
                          )}
                        </div>
                      </td>

                      <td
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          // gap: '10px',
                          // marginLeft: '2rem',
                          // marginRight: '2rem',
                          border: 'none',
                          backgroundColor: 'transparent',
                          marginTop: '8px',
                        }}
                        className=' me-4'
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                            <button
                              onClick={() => {
                                // setShow(true)
                                // createApprovalPopup(
                                //   showpop,
                                //   candidategData?.candidateData?.fullName,
                                //   candidategData?.jobapp_id,
                                //   candidategData?.candidateData?.email,
                                //   candidategData?.candidateData?.user_id,
                                //   candidategData?.candidateData?.profile_pic,
                                //   jobTitle,
                                //   candidategData?.banking_experience,
                                //   parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                // )

                                let currntApplication: any = []
                                currntApplication.push(candidategData)
                                setSelectedApplicants(currntApplication)
                                scheduleInterViewMultipleCandidatesStage1()
                              }}
                              disabled={candidategData?.test_aggregated_result === null}
                              className='Approvebuttononline'
                              ref={(button) => (approveButtonRefs.current[index] = button)}
                            >
                              Approve
                            </button>
                          ) : (
                            <div>
                              <label className='red-chip fs-7 fw-bolder form-label'>Withdrawn</label>
                            </div>
                          )}
                        </div>

                      </td>
                      {/* {InterviewSchedPopup} */}
                    </tr>
                  )
                })}
                {popup}
                {InterviewSchedPopup}
              </tbody>

              {testScorePopup}
              {openApprovalPopup && <div>{Approvalpopup}</div>}

              {openProfilePopup && <div>{popupProfile}</div>}
            </table>
            {/* pagination */}
            {(result && result.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
              <div className='col-md-4'>
                <label>{`Showing ${result ? result.length : 0} of ${params.total} results.`}</label>
              </div>
              <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                <CustomPagination
                  // totalPages={Math.ceil(allUsers.length / itemsPerPage)}
                  // pageValue={currentPage}
                  pageValue={params.page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>

              <div className='col-md-4 '>
                <div className='ms-auto d-flex flex-end align-items-center'>
                  <select
                    className='me-2 pagination-input'
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                    id='selectValues'
                    // value={itemsPerPage}
                    value={params.limit}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>

                  <div className='me-2'>
                    <label>Items per page</label>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>

      {scheduleMultipleCandidateInterview && (
        <ScheduleInterViewMultipleCandidatesStage1
          show={true}
          handleClose={() => {
            setScheduleMultipleCandidateInterview(false)
          }}
          handleSuccess={() => {
            setScheduleMultipleCandidateInterview(false)
            // refresh the list of applications
            setpopup(<></>)
            GetAllJobsApplicationsbypage()
          }}
          candidates={selectedApplicants}
          currentStage={'online test'}
          newStage={'stage1'}
        />
      )}

      {showRejectAllPopup && (
        <RejectAllUserApplicationOnlineTest
          show={true}
          job_id={currentJobId}
          handleClose={() => {
            setShowRejectAllPopup(false)
            GetAllJobsApplicationsbypage()
          }}
        />
      )}

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}

      {/* begin::Body */}
    </div>
  )
}

export default ApplicationsTableOnlineTest
