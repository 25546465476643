//import liraries
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { IconUserModel } from '../../../../app/modules/profile/ProfileModels'
import { UpdateIndividualCompetency } from '../../modals/create-app-stepper/UpdateIndividualCompetency';
import './cards.css'

import bin from '../images/bin.png'
import hoverBin from '../images/bin-delete.png'
import level4 from '../images/level4.png'
import level3 from '../images/level3.png'
import level5 from '../images/level5.png'
import editJob from '../images/edit-icon.png'
import editJobHover from '../images/edit-hover.png'
import axios from 'axios';
import WarningTARemovePopup from '../../modals/JobFunnelsModels/WarningTARemovePopup';
import imagePath from '../../../../constants/imagePath';

const API_URL = process.env.REACT_APP_API_URL
const DELETE_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency`
const GET_CREATE_LEVEL = `${API_URL}/level/get`

// create a component
const IndividualCompetency = ({
    competency,
    index,
    updateCompetency,
    deleteCompetency,
    ...props
}) => {

    const [IDforUpdate, setIDforUpdate] = useState<any>()
    const [name, setCompetencyName] = useState<any>()
    const [behaviours, setBehaviors] = useState<any>([])
    const [description, setDescription] = useState<any[]>()
    const [over, setOver] = useState(-1)
    const [overBin, setOverBin] = useState(-1)
    const [selectedCompetency, setSelectedCompetency] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showWarningPopup, setShowWarningPopup] = useState(false);

    const [popup, setPopup] = useState(<></>)
    const [level, setLevel] = useState(3)
    const { auth } = useAuth()
    const createPopup = (data) => {
        setPopup(
            <UpdateIndividualCompetency
                show={true}
                id={data.competency_id}
                name={data.name}
                behaviours={data.behaviors}
                description={data.description}
                level={data.level}
                handleClose={() => setPopup(<></>)}
                TotalIndivualCompetencies={() => data.total_competencies}
            />
        )
    }


    const DeleteCompetency = async (value) => {
        try {
            const url = `${DELETE_INDIVIDUAL_COMPETENCY_URL}/${value}`
            await axios.delete(url, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            })
        } catch (err) {
            console.log('ERROR', err)
        }
    }

    return (
        <div key={index} className='row col-md-6 col-xl-4 mb-7 '>
            <div className='card-bg shadow-sm p-6' >
                <div>
                    <div className='d-flex align-items-center'>
                        <span className='card-label-individual-name text-gray-800 fw-bolder fs-3'>
                            {competency.name}
                        </span>

                        <div className='ms-auto d-flex align-items-center'>
                            <img
                                className='backround-hover cursor-pointer me-2'
                                onClick={() => { updateCompetency(index, competency) }}
                                src={imagePath.ic_edit_circle_orange}
                                width={20} height={20}
                            />
                            <img
                                className='backround-hover cursor-pointer'
                                onClick={() => {
                                    // show warning popup
                                    setSelectedCompetency(competency);
                                    setSelectedIndex(index);
                                    setShowWarningPopup(true);
                                }}
                                src={imagePath.ic_delete_orange_cross}
                                width={18} height={18}
                            />

                        </div>
                    </div>
                    <div className='mt-2 '>
                        <div className='description-container'>
                            <p className='description-black-text me-4'>
                                {competency?.description ? competency?.description : ''}
                            </p>
                            {(competency?.description ? competency?.description.length : 0) > 90 ? (
                                <label
                                    onClick={() => { updateCompetency(index, competency) }}
                                    className="form-label fs-7  fw-semibold"
                                    style={{ cursor: 'pointer', height: '1rem' }}>
                                    See more
                                </label>) : (<label style={{ height: '1rem' }}>{' '}</label>)}
                        </div>
                    </div>

                    <div className='d-flex flex-wrap'>
                        <div className='level2-competencies rounded pt-3 me-3'>
                            <div>
                                {competency.level === '3' ? (
                                    <img src={imagePath.ic_level_3}></img>
                                ) : competency.level === '4' ? (
                                    <img src={imagePath.ic_level_4}></img>
                                ) : competency.level === '5' ? (
                                    <img src={imagePath.ic_level_5}></img>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className='fw-semibold text-gray-400'>
                                &nbsp;&nbsp;&nbsp;Levels: &nbsp;
                            </div>
                            <div className='fs-8 text-gray-800 fw-bolder'>{competency.level}</div>
                        </div>
                    </div>

                </div>
                {popup}
            </div>

            {showWarningPopup && (<WarningTARemovePopup
                show={true}
                message='Are you sure you want to remove competency?'
                handleClose={() => setShowWarningPopup(false)}
                handleYesSure={() => {
                    setShowWarningPopup(false)
                    deleteCompetency(selectedIndex, selectedCompetency)
                }}
            />)}
        </div>
    );
};

//make this component available to the app
export default IndividualCompetency;
