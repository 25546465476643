//import liraries
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../auth/core/Auth';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import imagePath from '../../../../../constants/imagePath';
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError';
import SuccessDialog from '../../../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import AddUpdateInstitute from '../../../../../_metronic/partials/modals/JobFunnelsModels/AddUpdateInstitute';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const DELETE_INSTITUTES_URL = `${API_URL}/configuration/delete-institutes`;
const SAVE_INSTITUTES_URL = `${API_URL}/configuration/add-institutes`;

// create a component
const InstitutionsCard = ({ configurationId, institutes, ...props }) => {

    const { currentUser, auth, logout } = useAuth();
    const [disabled, setDisabled] = useState(false);
    const [institutionsList, setInstitutionsList] = useState<any>([{ i: 0, data: '' }]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [showAddInstitutePopup, setShowAddInstitutePopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [type, setType] = useState('add');
    const [currentObj, setCurrentObj] = useState<any>();

    useEffect(() => {

        if (institutes) {
            institutes = JSON.parse(institutes);
            const initialData = Object.keys(institutes).map((key) => ({
                i: key,
                data: institutes[key]
            }));
            console.log('institutesData: ', initialData);
            setInstitutionsList(initialData);
        }
    }, [institutes]);

    const onEdit = (index) => {
        let data = institutionsList[index];
        setCurrentObj(data);
        setType('edit');
        setShowAddInstitutePopup(true);
    }

    const onDelete = async (index) => {
        if (index >= 0 && index < institutionsList.length) {
            const key = institutionsList[index].i;

            let obj = {
                "indicesToDelete": [key],
                "configuration_id": configurationId
            }
            await axios.post(DELETE_INSTITUTES_URL, obj, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                let data = [...institutionsList];
                data.splice(index, 1);
                setInstitutionsList(data);
                setMessage('Institution Deleted Successfully!');
                setShowSuccessPopup(true);
            }).catch((error) => {
                if (error.response && error.response.status === 400) {
                    // showToast(error.response.data.message)
                    setMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                    logout();
                } else if (error.response && error.response.data && error.response.data.error) {
                    if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                        logout();
                    } else {
                        setMessage(error.response.data.error.message);
                        setShowErrorMessage(true);
                    }
                } else {
                    setMessage(error.message);
                    setShowErrorMessage(true);
                }
            })

        }
    }

    const sortByField = (field) => {
        const direction = field === sortField && sortDirection === 'asc' ? 'desc' : 'asc';
        const sorted = [...institutionsList].sort((a, b) => {
            const valueA = a[field];
            const valueB = b[field];
            if (valueA < valueB) return direction === 'asc' ? -1 : 1;
            if (valueA > valueB) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setInstitutionsList(sorted);
        setSortField(field);
        setSortDirection(direction);
    };

    const renderSortIcon = (field) => {
        if (field === sortField) {
            return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
        } else {
            return <FaSort />;
        }
    };

    const addInstitute = (value) => {
        // Find the largest "i" value from the list
        const largestI = institutionsList.reduce((max, item) => {
            const currentItemI = parseInt(item.i, 10);
            return currentItemI > max ? currentItemI : max;
        }, -1); // Initialize with -1 in case the list is empty

        // Increment the largest "i" value by 1
        const newI = (largestI >= 0 ? largestI + 1 : 0).toString();

        // Create a new object with the incremented "i" value
        const newInstitute = {
            "i": newI,
            "data": value // Set the initial value as desired
        };

        let data = [...institutionsList];
        data.push(newInstitute);
        updateList(data);
        // Add the new object to the list
        // setInstitutionsList((prevFields) => [...prevFields, newProgram]);
    }

    const updateList = async (list) => {
        const data = {
            institutes: {},
            configuration_id: configurationId,
        };
        // Convert the array to an object with keys as "i" and values as "data"
        list.forEach((item) => {
            data.institutes[item.i] = item.data;
        });

        setDisabled(true);
        await axios.post(SAVE_INSTITUTES_URL, data, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            // fetch updated data
            // getConfigurations();
            setDisabled(false);

            setInstitutionsList(list);
            setMessage('Institutions Updated Successfully!');
            setShowSuccessPopup(true);

        }).catch((error) => {
            setDisabled(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }


    return (
        <div className='card-bg shadow-sm mt-12 py-4 position-relative'>
            <div className='d-flex align-items-center px-8'>
                <label className='form-label fs-4 fw-bolder card-heading'>Institution</label>
                <div className='ms-auto'>
                    <div className='candi-btn2 text-white fw-bolder fs-6 py-2 px-12'
                        onClick={() => {
                            setType('add');
                            setShowAddInstitutePopup(true);
                        }}
                    >
                        Add New
                    </div>
                </div>
            </div>

            <div className="table-responsive mx-8 mt-6" style={{ height: '26rem', position: 'relative', zIndex: '1' }} >
                <table className="table align-middle gs-0 gy-4 table-borders">
                    <thead>
                        <tr className='fw-bold graycolor bg-light'>
                            <th onClick={() => sortByField('i')}
                                className='align-items-center ps-4'
                                scope='col'
                                style={{ width: '10%', textAlign: 'center' }}
                            >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    No
                                </label>
                                {renderSortIcon('i')}
                            </th>
                            <th onClick={() => sortByField('data')}
                                className='align-items-center'
                                scope='col'
                                style={{ width: '75%' }}
                            >
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Title
                                </label>
                                {renderSortIcon('data')}
                            </th>
                            <th style={{ width: '15%', textAlign: 'center' }}>
                                <label className=' form-label fw-bolder fs-6 me-2'>
                                    Action
                                </label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {institutionsList ? institutionsList.map((item, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#FCFCFC' : 'white', borderBottom: '1px solid #EDF2F9' }}>
                                <td className=' form-label fs-6 ' style={{ textAlign: 'center' }}>{item.i}</td>
                                <td className=' form-label fs-6'>{item.data}</td>
                                <td >
                                    {/* <button className="btn btn-primary me-2" onClick={() => onEdit(index)}>Edit</button> */}
                                    {/* <button className="btn btn-danger" onClick={() => onDelete(index)}>Delete</button> */}
                                    <div className='ms-auto d-flex align-items-center me-4'
                                        style={{ justifyContent: 'center', position: 'relative', zIndex: '1' }}>
                                        <img className='me-2 cursor-pointer' src={imagePath.ic_edit_circle_orange} width={20} height={20} title='Edit'
                                            onClick={() => onEdit(index)} />
                                        <img style={{ cursor: 'pointer' }} src={imagePath.ic_delete_orange_cross} width={18} height={18} title='Delete'
                                            onClick={() => onDelete(index)} />
                                    </div>
                                </td>
                            </tr>
                        )) : (<div></div>)}
                    </tbody>
                </table>
            </div>
            {/* Positioning the image in the bottom right corner */}
            <div className='position-absolute bottom-0 end-0'>
                <img src={imagePath.ic_institute} style={{ width: '260px', height: '260px', opacity: '0.08', zIndex: '0' }} />
            </div>

            {showAddInstitutePopup && (
                <AddUpdateInstitute
                    handleClose={() => setShowAddInstitutePopup(false)}
                    handleAddInstitute={(value) => {
                        setShowAddInstitutePopup(false);
                        addInstitute(value);
                    }}
                    editInstitute={(institute) => {

                        setShowAddInstitutePopup(false);
                        let list = [...institutionsList];
                        list[institute.i]['data'] = institute.data;
                        updateList(list);
                    }}
                    type={type}
                    data={currentObj}
                />
            )}

            {showSuccessPopup && (
                <SuccessDialog
                    handleClose={() => setShowSuccessPopup(false)}
                    message={message}
                />
            )}

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default InstitutionsCard;
