//import libraries
import React, { useEffect, useState } from 'react'
import Layout from '../../../../_metronic/partials/modals/images/Layout-4-blocks (3).png'
import icTimer from '../assets/ic_timer_orange.png'
import icMenu from '../assets/ic_menu_orange.png'
import icTest from '../assets/ic_test_orange.png'
import icHappy from '../assets/happiness.png'
import axios from 'axios'
import { useAuth } from '../../auth'
import LoaderView from '../../../../_metronic/partials/modals/JobFunnelsModels/LoaderView'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import './Applications.css'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL
export const APPLIED_JOB_API_URL = `${API_URL}/test/`
const START_TEST_URL = `${API_URL}/jobapplication/start-test`

// create a component
const OnlineTest = (props) => {
  const { currentUser, logout, auth } = useAuth()
  const user_id = currentUser?.user_id

  const [loading, setLoading] = useState(false)
  const [testTitle, setTestTitle] = useState('')
  const [competencies, setCompetencies] = useState<any>([])
  const [testTime, setTestTime] = useState('')
  const [questions, setQuestions] = useState([])
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [actualNoOfQuestions, setActualNoOfQuestions] = useState(0)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const currentJobData = props.jobData
    const jobData = currentJobData?.jobdata
    const applicationData = currentJobData?.jobapplicationData
    let attemptTest = applicationData?.attempt_test
    if (attemptTest) {
      attemptTest = attemptTest ? JSON.parse(attemptTest) : null
      attemptTest = attemptTest['questionsData']
      console.log('attemptTest: ', attemptTest)
    }

    const getTotalQuestions = (questions) => {
      let count = 0;
      if (questions !== null && questions !== undefined && questions.length > 0) {
        questions.forEach(subList => {
          if (subList !== null && subList !== undefined && subList.length > 0) {
            count += subList.length;
          }
        });
      }
      return count;
    }

    // fetching test data
    async function getData() {
      // const candidate_id = user_id;
      setLoading(true)
      await axios
        .get(APPLIED_JOB_API_URL + props.test_id)
        .then((response) => {
          setLoading(false);
          const test = response.data?.test
          setTestTitle(test?.name)

          setCompetencies(test?.competencies)
          let attemptTestData = applicationData?.attempt_test
          if (attemptTestData) {
            attemptTestData = JSON.parse(attemptTestData)
            let remainingTime = attemptTestData['remainingTime']

            if (remainingTime) {
              remainingTime = Math.floor(remainingTime)
              setTestTime(`${remainingTime} minutes`)
              console.log('remainingTime: ', remainingTime)
            } else {
              setTestTime(test.test_time)
            }
          } else {
            setTestTime(test.test_time)
          }
          let queries: any = []
          let listOfQuestions = test?.questions
          // Filter out null or empty objects
          const filteredQuestions = listOfQuestions
            .filter(sublist => sublist.length > 0) // Remove empty sublists
            .map(sublist => sublist.filter(question => Object.keys(question).length > 0)); // Remove empty objects

          // Calculate total number of questions
          const questionsCount = filteredQuestions.reduce((total, sublist) => total + sublist.length, 0);
          // const questionsCount = getTotalQuestions(data?.questions);

          setActualNoOfQuestions(listOfQuestions?.length)
          if (Array.isArray(listOfQuestions)) {
            listOfQuestions.forEach((question) => {
              // Check if the object is not empty
              if (Object.keys(question).length > 0) {
                // remove attempted questions from list of questions
                if (attemptTest) {
                  let found = false
                  for (let i = 0; i < attemptTest.length; i++) {
                    let attemptedQuestion = attemptTest[i].question

                    if (attemptedQuestion?.text === question?.text) {
                      found = true
                    }
                  }
                  if (!found) {
                    queries.push(question)
                  }
                } else {
                  queries.push(question)
                }
              }
            })
          }
          setQuestions(queries)
          setTotalQuestions(questionsCount)
        })
        .catch((error) => {
          setLoading(false)
          if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
          } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
          ) {
            logout()
          } else if (error.response && error.response.data && error.response.data.error) {
            // Handle error message from the API
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
              logout()
            } else {
              setMessage(error.response.data.error.message)
              setShowErrorMessage(true)
            }
          } else {
            setMessage(error.message)
            setShowErrorMessage(true)
          }
        })
    }
    getData()
  }, [])

  // start test and go to next screen
  const startTest = async () => {
    setLoading(true)
    const jobId = props.job_id
    let data = {
      jobapp_id: jobId,
    }
    axios
      .post(START_TEST_URL, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        const isSessionValid = res.data.isSessionValid
        if (isSessionValid) {
          // goto next screen (SolveTest.tsx) to solve test
          props.updateQuestions(questions)
          props.updateTestTime(testTime)
          props.updateActualNoOfQuestions(actualNoOfQuestions)
          props.update(3)
        } else {
          setMessage('Session is expired can not start test again.')
          setShowErrorMessage(true)
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  return (
    <div>
      <div className='test-container shadow'>
        <div className='modal-header no-header-model123'>
          <h2></h2>
          {/* begin::Close */}
          <div
            className='btn btn-sm btn-icon btn-active-color-primary me-8'
            style={{ marginTop: '-1rem', cursor: 'pointer' }}
            onClick={() => props.update(1)}
          >
            <img alt='' src={imagePath.ic_close}></img>
          </div>
        </div>
        <div
          className='mb-12'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img className='me-4' src={icTest} />
          <h1 className='fw-bolder'>Online Test</h1>
        </div>
        <div className='test-body-container'>
          <h3 className='my-7 fs-1'>{testTitle ? testTitle : 'Title'}</h3>
          <label className='mb-6 fs-3 heading'>Skills Tested</label>
          {/* show list of competencies */}
          <div>
            {competencies.map((val, index) => (
              <div
                key={index}
                style={{ width: 'fit-content', display: 'inline-block', margin: '0 1rem 1rem 0 ' }}
              >
                <div className='withdrwa-btn'>{val?.name}&nbsp;</div>
              </div>
            ))}
          </div>
          {/* test total time and total questions */}
          <div className='mt-8' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img className='me-4' src={icMenu} width={24} />
            <label className=' fs-4 label-style'>{`Total Questions: ${totalQuestions}`}</label>
          </div>
          <div className='mt-8' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img className='me-4' src={icTimer} width={24} />
            <label className=' fs-4 label-style'>{`Total time: ${testTime} Seconds`}</label>
          </div>

          <hr className='shadow my-12' style={{ height: '2px', backgroundColor: '#707070' }} />
          {/* instructions view */}
          <label className='mb-6 fs-3 heading'>Instructions</label>
          {/* <ul style={{ listStyleType: 'disc', paddingLeft: '1rem', margin: '0', padding: '0' }}>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <label className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                Before starting the test make sure you have stable internet connection
              </label>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <label className=' fs-5 label-style' style={{ fontWeight: '600' }}>
                Participants have to complete the test in a given time
              </label>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <label className=' fs-5 label-style' style={{ fontWeight: '600' }}>
                Once clicked on finish you can not retake the test
              </label>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <label className=' fs-5 label-style' style={{ fontWeight: '600' }}>
                After starting the test you will prompt to record the screen,
              </label>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <label className=' fs-5 label-style' style={{ fontWeight: '600' }}>
                Please allow to record entire screen this recording will be sent to recruitment team
              </label>
            </li>
            <li style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <label className=' fs-5 label-style' style={{ fontWeight: '600' }}>
                If candidate do not allow to record the screen, application of candidate will be rejected.
              </label>
            </li>
          </ul> */}

          <ul className="custom-list">
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                Before starting the test, make sure you have a stable internet connection.
              </span>
            </li>
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                Participants have to complete the test in a given time.
              </span>
            </li>
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                Once clicked on finish, you cannot retake the test.
              </span>
            </li>
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                After starting the test, you will be prompted to record the screen.
              </span>
            </li>
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                Please allow to record the entire screen; this recording will be sent to the recruitment team.
              </span>
            </li>
            <li className="custom-list-item">
              <span className='fs-5 label-style' style={{ fontWeight: '600', marginLeft: '1rem' }}>
                If the candidate does not allow screen recording, the candidate's application will be rejected.
              </span>
            </li>
          </ul>

          {/* start test btn */}
          <div className='upload-video-div my-8' style={{ display: 'flex', justifyContent: 'center' }}>
            <button
              className='btn col-md-12 mt-6 text-white vacancies-button right-button-vacan fw-bolder'
              style={{ cursor: 'pointer' }}
              onClick={() => startTest()}
              disabled={loading}
            >
              Start Test
            </button>
          </div>
          {/* <div className='my-8 d-flex justify-content-center align-items-center'>
            <div
              className='mt-6 d-flex justify-content-center align-items-center'
              style={{ textAlign: 'center' }}
            >
              <img className='me-4' src={icHappy} width={24} />
              <label className=' fs-4 label-style' style={{fontWeight: '600'}}>
                Good Luck!
              </label>
            </div>
          </div> */}
        </div>
      </div>

      {loading && <LoaderView message='Loading...' />}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

//make this component available to the app
export default OnlineTest
