import React, { useState } from 'react';
import '../../Settings.css';

function ToggleSwitch({ label, checked, handleChange }) {

    return (
        <div className='d-flex align-items-center'>
            <h3 className='me-4 fs-6 fw-normal'>{label}</h3>
            <label className="toggle-switch">
                <input type="checkbox" checked={checked} onChange={handleChange} />
                <span className="toggle-switch-slider"></span>
                {/* {label} */}
            </label>
        </div>
    );
}

export default ToggleSwitch;
