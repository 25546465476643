import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import './CreateCompetency.css'
import { useEffect, useState } from 'react'
import addcompetenctimage from '../../images/warning@2x.png'

import { useAuth } from '../../../../app/modules/auth/core/Auth'

import cross from '../images/Layout-4-blocks.png'
import axios from 'axios'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../content/toasts/ShowError'

const API_URL = process.env.REACT_APP_API_URL

export const GET_POSITONS_BY_id = `${API_URL}/job/change-status`
export const GET_ALL_JOBS_ADMIN = `${API_URL}/job/show-status-jobs`
export const GET_ALL_JOBS_RECRUITER = `${API_URL}/job`
export const GET_TEST_DETAILS = `${API_URL}/test/`

type Props = {
  show: boolean
  id: string
  handleRefreshData: () => void
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SetJobInActive = ({ show, id, handleRefreshData, handleClose }: Props) => {
  const { auth } = useAuth()
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!reason) {
      setMessage('Reason can not be empty');
      setShowErrorMessage(true);
      return
    }

    const url = GET_POSITONS_BY_id
    const data = {
      published: false,
      job_id: id,
      job_status: 'inactive',
      reason: reason,
    }

    try {
      await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })

      handleClose()
      handleRefreshData()
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleChangeReason = (e) => {
    let value = e.target.value
    setReason(value)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      // onHide={handleClose}
      backdrop={true}
    >
      <div >
        <div className='d-flex ' >
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary ms-auto' onClick={handleClose}>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            <img alt='' src={imagePath.ic_close}></img>
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column mt-10'>
          <img alt='' className='addcompetenctimage' src={addcompetenctimage} style={{ height: '64px' }}></img>
          <h3 className='fw-bolder mx-auto my-5'>UnPublish Job Post </h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label style={{ color: '#80808F' }} className='form-label mx-auto fs-5 fw-semibold '>
                  Are you sure you want to UnPublish this Job Post?
                </label>
              </div>

              <div
                className='d-flex justify-content-center align-items-center col-md-8 mx-auto mt-4'
                style={{ alignSelf: 'center', borderRadius: '6px' }}
              >
                <textarea
                  style={{ width: '400px' }}
                  placeholder='Enter reason to hold the position'
                  value={reason}
                  onChange={(e) => handleChangeReason(e)}
                  rows={4}
                />
              </div>
            </form>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div onClick={handleSubmit} className='candi-btn2 text-white my-10 px-10 py-3 me-8 bgcolorofbutoonofsubmit'>
            Yes, I am sure!
          </div>
          <div onClick={handleClose} className=' py-3 px-5 bgcolorofbutoonofsubmitoftheaddingnewrecruiter '>
            No
          </div>
        </div>
      </div>
      {showErrorMessage && (
        <ShowError
          message={message}
          handleClose={() => setShowErrorMessage(false)}
        />
      )}
    </Modal>,
    modalsRoot
  )
}

export { SetJobInActive }
