//import liraries

import React, {useEffect, useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import Loader from '../../CVScreens/comps/Loader'

import {useAuth} from '../../../auth'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'

import '../../CVScreens/CandiMain.css'
import '../../CVScreens/personalinfo.css'
import '../../CVScreens/position.css'
import '../../CVScreens/Stepper.css'
// import ic_cross from '../assets/ic_cross.png';
import ic_cross from '../../assets/ic_cross.png'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import ShowError from '../../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-experience/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`
export const GET_ALL_GROUPS = `${API_URL}/group`

// create a component
const WorkExpScreen = ({
  inputFields,
  addFields,
  removeFields,
  handleBankingExp,
  handleExperienceTypeChange,
  handleCurrWork,
  handleFormChange,
  handleDateFromChange,
  handleDateToChange,
  handleItemClick,
  handleDelete,
  previouslyEmployed,
  handlePreviouslyEmployedChange,
  employeeNumber,
  handleEmployeeNoChange,
  employeeGrade,
  handleEmployeeGradeChange,
  employeeDesignation,
  handleEmployeeDesignationChange,
  employeeGroup,
  handleEmployeeGroupChange,

  ...props
}) => {
  const [bankCount, setBankCount] = useState(0)
  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [allGroups, setAllGroups] = useState<any>([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const {auth, currentUser, logout} = useAuth()
  const user_id = currentUser?.user_id

  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  // const staticSkills = ["Data Analyst", "MERN Stack", "Front-end developer", "Node developer", "Data Scientist", "Data Engineer"];
  const [competencies, setCompetencies] = useState<any>([])
  const [allGrades, setAllGrades] = useState<any>([])

  // const [inputFields, setInputFields] = React.useState<Dayjs | any>([{
  //     banking_experience: 'false',
  //     job_experience: '',
  //     name: '',
  //     job_title: '',
  //     Date_from: '',
  //     Date_to: '',
  //     end_date: '',
  //     start_date: '',
  //     currently_working: 'false',
  //     skills: []
  // }])

  const [profileStrength, setprofileStrength] = React.useState<any>([
    {
      profile_info: 'false',
      work_exp: 'false',
      education: 'false',
      certificate: 'false',
      skills: 'false',
      total: '5',
    },
  ])
  const [currentProgress, setCurrentProgress] = useState(5)

  // search skills
  const handleInputChange = (event) => {
    const query = event.target.value
    setQuery(query)
    // if (query.length > 0) {
    const filteredResults = competencies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    // setResults(filteredResults);
    setResults(filteredResults.length > 0 ? filteredResults : competencies)
    // } else {
    // setResults([]);
    // }
  }

  useEffect(() => {
    ;(() => {
      getGroups()
    })()
  }, [])

  // fetching list of groups
  const getGroups = async () => {
    await axios
      .get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setAllGroups(res.data.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setAllGrades(Object.values(data))
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ;(async () => {
      await getGrades()
      await getCompetencies()
    })()
  }, [])

  return (
    <>
      <div style={{width: '100%'}}>
        <div style={{width: '100%', margin: '0 auto'}}>
          <i style={{float: 'right'}} onClick={addFields} className='bi bi-plus-lg addmore'></i>
        </div>
        <div className='row g-5 g-xl-8 personal-info-container' style={{margin: '0', padding: '0'}}>
          {/* add more button  */}

          <div className='col-xl-12'>
            <div className=' displayflex-setting '>
              <div className='col-xl-12'>
                {' '}
                <div className='candi-card'>
                  {/* begin::Beader */}

                  <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row ms-4'>
                    <span className='card-label section-heading fw-bolder fs-7 ' style={{color: '#d50000'}}>
                      Please enter the oldest experience first!
                    </span>
                    {/* FORM START  */}
                    <form>
                      {inputFields.map((input, index) => {
                        // console.log(`workExperienceInitialize: index: ${index} banking_experience: ${input.banking_experience} type: ` + typeof (input.banking_experience))
                        // console.log(`workExperienceInitialize: index: ${index} currently_working: ${input.currently_working} type: ` + typeof (input.currently_working))
                        return (
                          <div className='dynamic-section' key={index}>
                            {index === 0 ? null : (
                              <div style={{width: '95%', margin: '0 auto'}}>
                                <i
                                  style={{float: 'right'}}
                                  onClick={() => removeFields(index)}
                                  className='bi bi bi-trash-fill trash-button'
                                ></i>
                              </div>
                            )}
                            {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                            <h3 className='card-title mt-5 align-items-center flex-column'>
                              <span className='card-label text-gray-800 fw-bolder fs-3 ms-3'>Work Experience {index + 1}</span>
                            </h3>
                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              <div className='form-group col-md-6'>
                                {/* <div className="form-check checkbox-container" style={{ marginLeft: '3%' }}>
                                                                    <input type='hidden' value={tempMail} onChange={(e) => setTempMail(e.target.value)} />

                                                                    <input
                                                                        name='banking_experience'
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={input.banking_experience}
                                                                        checked={input.banking_experience}
                                                                        onChange={event => handleBankingExp(index, event)}
                                                                    />
                                                                    <label className="form-check-label ">
                                                                        Banking Experience
                                                                    </label>
                                                                </div> */}

                                <label className='form-label labelse '>
                                  {' '}
                                  Experience Type<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div>
                                  <select
                                    name='type'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select '
                                    defaultValue='Select an option'
                                    placeholder='Select an option'
                                    value={input.type}
                                    required
                                    onChange={(event) => handleExperienceTypeChange(index, event)}
                                  >
                                    <option value='Select an option'>Select an option</option>
                                    <option value='banking_experience'>Banking Experience</option>
                                    <option value='work_experience'>Non Banking Experience</option>
                                    <option value='internship'>Internship</option>
                                  </select>
                                </div>
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Company<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='name'
                                    className='form-control'
                                    placeholder='Issacs Code'
                                    value={input.name}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            </div>

                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              {/* Job title  */}
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Job Title<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='job_title'
                                    className='form-control'
                                    placeholder='Full Stack Developer'
                                    value={input.job_title}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Job Grade<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='me-1'>
                                  {/* <input
                                                                        type='text'
                                                                        name='job_title'
                                                                        className='form-control'
                                                                        placeholder='Full Stack Developer'
                                                                        value={input.job_grade}
                                                                        required
                                                                        onChange={event => handleFormChange(index, event)}
                                                                    /> */}
                                  <select
                                    name='job_grade'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select   w-140px'
                                    defaultValue='Select an option'
                                    placeholder='Select an option'
                                    value={input.job_grade}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  >
                                    <option value='Select an option'>Select an option</option>
                                    {allGrades &&
                                      allGrades.map((grade) => (
                                        <option key={grade} value={grade}>
                                          {grade}
                                        </option>
                                      ))}
                                  </select>
                                </div>{' '}
                              </div>
                            </div>

                            {/* Date picker  */}
                            {/* from  */}
                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Date From<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                </label>
                                <div className='datepicker me-1'>
                                  <div className='form-group'>
                                    <input
                                      type='date'
                                      name='Date_from'
                                      className='form-control'
                                      max={new Date().toISOString().split('T')[0]}
                                      min={
                                        index > 0
                                          ? inputFields[index - 1].Date_to
                                            ? new Date(inputFields[index - 1].Date_to).toISOString().split('T')[0]
                                            : '1940-01-01'
                                          : '1940-01-01'
                                      }
                                      value={input.Date_from}
                                      required
                                      onChange={(event) => handleDateFromChange(index, event)}
                                      onFocus={(event) => event.target.blur()}
                                    />
                                  </div>
                                </div>
                              </div>

                              {!input.currently_working && (
                                <div className='form-group col-md-6'>
                                  <label className='form-label labelse '>
                                    Date To<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                                  </label>
                                  <div className='datepicker me-1'>
                                    <div className='form-group'>
                                      <input
                                        type='date'
                                        name='Date_to'
                                        className='form-control'
                                        max={new Date().toISOString().split('T')[0]}
                                        min={
                                          input.Date_from
                                            ? new Date(input.Date_from).toISOString().split('T')[0]
                                            : index > 0
                                            ? inputFields[index - 1].Date_to
                                              ? new Date(inputFields[index - 1].Date_to).toISOString().split('T')[0]
                                              : '1940-01-01'
                                            : '1940-01-01'
                                        }
                                        value={input.Date_to}
                                        required
                                        onChange={(event) => handleDateToChange(index, event)}
                                        onFocus={(event) => event.target.blur()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='form-group mt-10 checks-container col-md-12'>
                              <div className='form-check checkbox-container' style={{marginLeft: '3%'}}>
                                <input
                                  name='currently_working'
                                  className='form-check-input'
                                  type='checkbox'
                                  value={input.currently_working}
                                  checked={input.currently_working}
                                  onChange={(event) => handleCurrWork(index, event)}
                                />
                                <label className='form-check-label'>Currently working here?</label>
                              </div>
                            </div>
                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              {/* Job title  */}
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>Gross Salary</label>
                                <div className='me-1'>
                                  <input
                                    type='number'
                                    name='grossSalary'
                                    className='form-control'
                                    placeholder='0'
                                    value={input.grossSalary}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>Benefits</label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='benefits'
                                    className='form-control'
                                    placeholder='e.g Health Insurance'
                                    value={input.benefits}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            </div>
                            <label className='form-label labelse add-skills-label'>Add Skills Used</label>
                            {/* SKILLS  */}
                            <div className='form-row d-flex mt-2 justify-content-spacearound' style={{margin: '0 1.3rem 0 2rem'}}>
                              <div className='form-group col-md-12'>
                                {/* <label className='form-label labelse '> Blood Group</label> */}
                                <div className='me-4  '>
                                  <input
                                    type='text'
                                    className='search-bar-style form-control   w-140px'
                                    placeholder='Search skills'
                                    value={query}
                                    onChange={handleInputChange}
                                    onFocus={handleInputChange}
                                  />
                                  {results.length > 0 && (
                                    <ul className='form-control   w-140px'>
                                      {results.map((item, suggestionsIndex) => (
                                        <li key={suggestionsIndex} onClick={handleItemClick(item, index)}>
                                          {item}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                                {/* <Chips skillset={chips} /> */}
                                <div className=' mt-4'>
                                  {input.skills.map((val, i) => (
                                    <div key={i} style={{width: 'fit-content', display: 'inline-block', margin: '0 1rem 0 0'}}>
                                      <div className='chip-uxi'>
                                        {val}&nbsp;
                                        {/* <i className='bi bi-x-lg' onClick={() => handleDelete(index, i)}></i> */}
                                        <img src={ic_cross} alt='remove' onClick={() => handleDelete(index, i)}></img>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                {/* <h3 className='card-title align-items-center flex-column'>
                                                      <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                          Suggested Competencies
                                                      </span>
                                                  </h3> */}
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      <div className='form-row d-flex mt-6 justify-content-spacearound'>
                        <div className='form-group col-md-6'>
                          <label className='form-label labelse '>
                            Were you previously employed by BOP?
                            <span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                          </label>
                          <div className='me-1'>
                            <select
                              name='previouslyEmployed'
                              data-control='select2'
                              data-hide-search='true'
                              className='form-select   w-140px'
                              defaultValue='Select an option'
                              placeholder='Select an option'
                              value={previouslyEmployed}
                              required
                              onChange={(event) => handlePreviouslyEmployedChange(event)}
                            >
                              <option value='Select an option'>Select an option</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>{' '}
                        </div>
                      </div>

                      {previouslyEmployed === 'Yes' ? (
                        <div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Employee Number</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='employeeNumber'
                                  className='form-control'
                                  placeholder='Employee Number'
                                  value={employeeNumber}
                                  onChange={(event) => handleEmployeeNoChange(event)}
                                />
                              </div>{' '}
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Grade</label>
                              <div className='me-1'>
                                <select
                                  name='employeeGrade'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  placeholder='Select an option'
                                  value={employeeGrade}
                                  onChange={(event) => handleEmployeeGradeChange(event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {allGrades &&
                                    allGrades.map((grade) => (
                                      <option key={grade} value={grade}>
                                        {grade}
                                      </option>
                                    ))}
                                </select>
                              </div>{' '}
                            </div>
                          </div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Designation</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='employeeDesignation'
                                  className='form-control'
                                  placeholder='Designation'
                                  value={employeeDesignation}
                                  onChange={(event) => handleEmployeeDesignationChange(event)}
                                />
                              </div>{' '}
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Group</label>
                              <div className='me-1'>
                                <select
                                  name='employeeGroup'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  placeholder='Select an option'
                                  value={employeeGroup}
                                  onChange={(event) => handleEmployeeGroupChange(event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {allGroups &&
                                    allGroups.map((group) => (
                                      <option key={group?.group_name} value={group?.group_name}>
                                        {group?.group_name}
                                      </option>
                                    ))}
                                </select>
                              </div>{' '}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </form>
                    {/* FORM END  */}
                  </div>

                  {/* end::Body */}
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {/* <div style={{ margin: 0, position: 'absolute' }} id='loader' className="d-none col-md-12"> */}
      {/* <Saved /> */}
      {/* </div> */}
    </>
  )
}

//make this component available to the app
export default WorkExpScreen
