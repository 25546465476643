import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import './CandiMain.css'
import './position.css'
import './Stepper.css'
import './personalinfo.css'
import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Loader from './comps/Loader'
import Saved from './comps/Saved'
import ic_cross from '../assets/ic_cross.png'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'

import { useAuth } from '../../auth/core/Auth'
import { useNavigate } from 'react-router-dom'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
const API_URL = process.env.REACT_APP_API_URL
export const PERSONAL_INFORMATION_URL = `${API_URL}/candidate/upload-experience/`
export const ALLDATA_URL = `${API_URL}/candidate/`
const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`
export const GET_ALL_GROUPS = `${API_URL}/group`

const WorkExperience = (props) => {
  const navigate = useNavigate()
  const [bankCount, setBankCount] = useState(0)
  const [savedInfo, setSavedInfo] = useState<any>([])
  const [tempMail, setTempMail] = useState('')
  const [size, setSize] = useState(0)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

  const [inputFields, setInputFields] = React.useState<Dayjs | any>([
    {
      // banking_experience: false,
      type: '',
      job_experience: '',
      name: '',
      job_title: '',
      job_grade: '',
      Date_from: '',
      Date_to: '',
      end_date: '',
      start_date: '',
      currently_working: false,
      grossSalary: '',
      benefits: '',
      skills: [],
      query: '',
      searchResults: [],
      years: 0,
      months: 0,
    },
  ])

  const [profileStrength, setprofileStrength] = React.useState<any>({
    profile_info: 'false',
    work_exp: 'false',
    education: 'false',
    certificate: 'false',
    skills: 'false',
    total: '5',
  })
  const [currentProgress, setCurrentProgress] = useState(5)
  const [allGrades, setAllGrades] = useState<any>([])
  const [skills, setSkills] = useState<string>()
  // const [chips, setChips] = useState(['']);
  // end_date: dayjs('2014-08-18T21:11:54'),

  const [query, setQuery] = useState('')
  const [results, setResults] = useState<string[]>([])
  const staticSkills = ['Data Analyst', 'MERN Stack', 'Front-end developer', 'Node developer', 'Data Scientist', 'Data Engineer']
  const [competencies, setCompetencies] = useState<any>([])
  const [allGroups, setAllGroups] = useState<any>([])
  const [previouslyEmployed, setPreviouslyEmployed] = useState('')
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [employeeGrade, setEmployeeGrade] = useState('')
  const [employeeDesignation, setEmployeeDesignation] = useState('')
  const [employeeGroup, setEmployeeGroup] = useState('')

  const handleExperienceTypeChange = (index, event) => {
    let value = event.target.value
    let data = [...inputFields]
    data[index]['type'] = event.target.value
    // data[index]['banking_experience'] = value === 'Banking Experience' ? true : false
    setInputFields(data)
  }

  const handleFormChange = (index, event) => {
    const fieldTitle = event.target.name
    const value = event.target.value
    let data = [...inputFields]
    if (fieldTitle === 'grossSalary') {
      if (/^\d*$/.test(value)) {
        data[index][fieldTitle] = value.toLocaleString()
        setInputFields(data)
      }
    } else {
      data[index][event.target.name] = value
      setInputFields(data)
    }
  }

  const handlePreviouslyEmployedChange = (event) => {
    setPreviouslyEmployed(event.target.value)
  }

  const handleEmployeeNoChange = (event) => {
    setEmployeeNumber(event.target.value)
  }

  const handleEmployeeGradeChange = (event) => {
    setEmployeeGrade(event.target.value)
  }

  const handleEmployeeDesignationChange = (event) => {
    setEmployeeDesignation(event.target.value)
  }

  const handleEmployeeGroupChange = (event) => {
    setEmployeeGroup(event.target.value)
  }

  const handleDateFromChange = (index, event) => {
    // let dateFromValue = event.target.value
    let { value } = event.target

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10)

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9)
    }
    let data = [...inputFields]

    if (index > 0) {
      // checking is the value of dateFrom at current index is greater then the value of dateTo of previous index
      let previousDateToValue = data[index - 1]['Date_to']
      if (!validateDate(previousDateToValue, value)) {
        window.alert('Date From should be greater than Date To of previous experience')
        return
      }
    }

    let dateToValue = data[index]['Date_to']
    if (!validateDate(value, dateToValue)) {
      window.alert('Date From can not be equal or greater than Date To')
      return
    }

    //calculate years and months
    if (value && dateToValue) {
      let diff = getDateDiffInYearsAndMonths(value, dateToValue)
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  const handleDateToChange = (index, event) => {
    let { value } = event.target

    // Restrict to only the first 10 characters (YYYY-MM-DD format)
    value = value.slice(0, 10)

    // Check if the year part has more than 4 characters
    if (value.length === 10 && isNaN(parseInt(value.slice(0, 4)))) {
      // If the year part is not a valid number or has more than 4 characters, remove the last character
      value = value.slice(0, 9)
    }

    let data = [...inputFields]
    let dateFromValue = data[index]['Date_from']
    if (!dateFromValue) {
      window.alert('Please enter or select Date From first')
      return
    }
    let dateObject = new Date(value)
    let year = dateObject.getFullYear().toString()
    console.log('handleFormChangeWorkExp: dateToYearLength: ', year.length)
    if (!validateDate(dateFromValue, value)) {
      window.alert('Date From can not be equal or greater than Date To')
      return
    }
    //calculate years and months
    if (dateFromValue && value) {
      let diff = getDateDiffInYearsAndMonths(dateFromValue, value)
      console.log('calculatedYearsMonths:  ' + diff.years + ' years, ' + diff.months + ' months')
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    data[index][event.target.name] = value
    setInputFields(data)
  }

  // checking end date is greater than start date or not
  function validateDate(startDate, endDate) {
    if (startDate && endDate) {
      let dateObject = new Date(endDate)
      let year = dateObject.getFullYear().toString()
      if (year.length >= 4) {
        let start = new Date(startDate).getTime()
        let end = new Date(endDate).getTime()

        let timeDiff = end - start
        if (timeDiff <= 0) {
          return false
        }
      }
    }
    return true
  }

  function getDateDiffInYearsAndMonths(startDate, endDate) {
    let start = new Date(startDate).getTime()
    let end = new Date(endDate).getTime()
    let timeDiff = end - start
    let years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365))
    let months = Math.floor((timeDiff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30))
    if (months === 12) {
      years = years + 1
      months = 0
    }
    console.log(`calculatedValue: years: ${years} :: months: ${months}`)
    return { years: years, months: months }
  }

  // start_date: dayjs('2014-08-18T21:11:54'),
  const addFields = () => {
    let newfield = {
      // banking_experience: false,
      type: '',
      job_experience: '',
      name: '',
      job_title: '',
      job_grade: '',
      Date_from: null,
      Date_to: null,
      end_date: '',
      start_date: '',
      currently_working: false,
      grossSalary: '',
      benefits: '',
      years: 0,
      months: 0,
      skills: [],
    }
    // setInputFields([...inputFields, newfield])
    setInputFields((prevFields) => [...prevFields, newfield])
  }
  const removeFields = (index) => {
    let data = [...inputFields]
    data.splice(index, 1)
    setInputFields(data)
  }

  // checking name is entered for all training entry or not
  const isNameEmpty = () => {
    let totalNameEntered = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.name !== '' && field.name !== null && field.name !== undefined) {
          totalNameEntered++
        }
      }
    }
    return totalNameEntered !== 0 && totalNameEntered === inputFields.length
  }

  // checking job title is entered for all training entry or not
  const isJobTitleEmpty = () => {
    let totalJobTitleEntered = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.job_title !== '' && field.job_title !== null && field.job_title !== undefined) {
          totalJobTitleEntered++
        }
      }
    }
    return totalJobTitleEntered !== 0 && totalJobTitleEntered === inputFields.length
  }

  // checking Date_from is entered for all training entry or not
  const isDateFromSelected = () => {
    let totalSelectedDates = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if (field.Date_from !== '' && field.Date_from !== null && field.Date_from !== undefined) {
          totalSelectedDates++
        }
      }
    }
    return totalSelectedDates !== 0 && totalSelectedDates === inputFields.length
  }

  // checking Date_to is entered for all training entry or not
  const isDateToSelected = () => {
    let totalSelectedDates = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        if ((field.Date_to !== '' && field.Date_to !== null && field.Date_to !== undefined) || field.currently_working) {
          totalSelectedDates++
        }
      }
    }
    return totalSelectedDates !== 0 && totalSelectedDates === inputFields.length
  }

  const isSkillsEmpty = () => {
    let totalSkills = 0
    if (inputFields.length > 0) {
      for (let i = 0; i < inputFields.length; i++) {
        let field = inputFields[i]
        console.log('skillsWorkExp: ', field.skills)
        if (field && field.skills.length > 0) {
          totalSkills++
        }
      }
    }
    return totalSkills !== 0 && totalSkills === inputFields.length
  }

  const getCountValueOfType = (type) => {
    let count = 0
    inputFields.map((field) => {
      if (field.type === type) {
        count++
      }
    })
    return count
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(inputFields)
    // loader component

    if (!isNameEmpty()) {
      setMessage('Please enter Company Name first and try again.');
      setShowErrorMessage(true);
      return
    }

    if (!isJobTitleEmpty()) {
      setMessage('Please enter job title first and try again.');
      setShowErrorMessage(true);
      return
    }

    if (!isDateFromSelected()) {
      setMessage('Please select Date From first and try again.');
      setShowErrorMessage(true);
      return
    }

    if (!isDateToSelected()) {
      setMessage('Please select Date To first and try again.');
      setShowErrorMessage(true);
      return
    }

    if (!isSkillsEmpty()) {
      setMessage('Please add skills first and try again.');
      setShowErrorMessage(true);
      return
    }

    var loader = document.getElementById('loader')
    loader?.classList.remove('d-none')

    let totalBankingExperience = getCountValueOfType('banking_experience')
    let totalJobExperience = getCountValueOfType('work_experience')
    let totalInternships = getCountValueOfType('internship')

    const transformedData = {
      work_experience: [
        ...inputFields.map((experience, index) => ({
          [`company${index + 1}`]: {
            name: experience.name,
            job_title: experience.job_title,
            job_grade: experience.job_grade,
            type: experience.type,
            Date_from: experience.Date_from,
            Date_to: experience.Date_to,
            start_date: experience.Date_from,
            end_date: experience.Date_to,
            currently_working: experience.currently_working,
            grossSalary: experience.grossSalary,
            benefits: experience.benefits,
            years: `${experience.years}.${experience.months}`,
            skills: experience.skills.reduce((obj, skill, index) => {
              obj[index] = skill
              return obj
            }, {}),
          },
        })),
      ],
    }

    let work_experience: any = transformedData.work_experience

    // Merge company objects into one
    const mergedCompanies = Object.assign({}, ...Object.values(work_experience))

    // Create final work experience object with merged companies
    let bopEmployee = {
      employeeNumber: employeeNumber,
      grade: employeeGrade,
      designation: employeeDesignation,
      group: employeeGroup,
    }
    const finalWorkExperience = {
      work_experience: [
        {
          previously_employed: previouslyEmployed,
          bop_employee: previouslyEmployed === 'Yes' ? bopEmployee : null,
          experience: mergedCompanies,
        },
      ],
    }
    // console.log("workExperienceData: finalWorkExperience: ", finalWorkExperience);
    work_experience = finalWorkExperience.work_experience
    console.log('workExperienceData: work_experience: ', work_experience)

    var work = {
      banking_experience: totalBankingExperience,
      job_experience: totalJobExperience,
      internship_experience: totalInternships,
      profile_strength: profileStrength,
      work_experience,

      // work_experience: inputFields
    }
    var data = JSON.stringify(work)
    console.log('Candidate Experience: ', data)
    const url = PERSONAL_INFORMATION_URL + user_id
    // console.log('URL', url)
    // console.log('CURRENT USER', currentUser)

    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        // console.log('res ', res);
        // loader component
        props.reduce(2)
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        // setTimeout(function () {
        //   loader?.classList.add('d-none');
        // }, 4000)

        console.log('profileStrengthTotal: ', profileStrength.total)
        if (profileStrength.total == 100) {
          navigate('/candidatedashboard')
        }
      })
      .catch((error) => {
        var loader = document.getElementById('loader')
        loader?.classList.add('d-none')
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const { auth, currentUser, logout } = useAuth()
  const user_id = currentUser?.user_id
  const [sendRequest, setSendRequest] = useState(false)

  const handleSkillSet = (index, skills, event) => {
    let data = [...inputFields]
    let ski = [...data[index][event.target.name]]
    ski.push(event.target.value)
    data[index][event.target.name] = ski
    setInputFields(data)
  }
  const handleSkills = (e, index) => {
    e.preventDefault()
    setSkills(e.target.value)
    // console.log("SKILL SET: " + skills);
    // chips.push(skills)
    handleSkillSet(index, skills, e)
  }

  useEffect(() => {
    if (sendRequest) {
      //send the request
      setSendRequest(false)
    }
  }, [sendRequest])

  const getCompetencies = async () => {
    axios
      .get(GET_ALL_INDIVIDUAL_COMPETENCY_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let allCompetencies = res.data.competencies
        if (allCompetencies) {
          let individualCompetencies: any = []
          for (let i = 0; i < allCompetencies.length; i++) {
            let competency = allCompetencies[i]
            if (competency?.type === 'Individual') {
              individualCompetencies.push(competency?.name)
            }
          }
          setCompetencies(individualCompetencies)
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  useEffect(() => {
    ; (async () => {
      await getGrades()
      await getCompetencies()
    })()
  }, [])

  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setAllGrades(Object.values(data))
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const handleDelete = (index, sIndex) => {
    let data = [...inputFields]
    let ski = [...data[index]['skills']]
    ski.splice(sIndex, 1)
    data[index]['skills'] = ski
    setInputFields(data)
    setSendRequest(true)
  }

  // search skills
  const handleInputChange = (index, event) => {
    const query = event.target.value
    // setQuery(query);
    const filteredResults = competencies.filter((item) => item.toLowerCase().includes(query.toLowerCase()))
    // setResults(filteredResults.length > 0 ? filteredResults : competencies);

    let data = [...inputFields]
    data[index]['query'] = query
    data[index]['searchResults'] = filteredResults.length > 0 ? filteredResults : competencies
    setInputFields(data)
  }

  // handle click on list of searched suggestion if skill not already added add new skill
  const handleItemClick = (item, index) => () => {
    let data = [...inputFields]
    let ski = [...data[index]['skills']]
    if (!chipAlreadyAvailable(item, ski) && ski.length < 5) {
      ski.push(item)
      data[index]['skills'] = ski
    }
    data[index]['query'] = ''
    data[index]['searchResults'] = []
    setInputFields(data)
    // setResults([]);
  }

  // checking chip already available or not
  const chipAlreadyAvailable = (item, chips) => {
    let found = false
    for (let chip of chips) {
      if (chip === item) {
        found = true
      }
    }
    return found
  }

  // ---------
  function handleBankingExp(index, event) {
    let data = [...inputFields]
    // console.log('banking_experience: value: ', event.target.checked);
    // data[index]["banking_experience"] === 'false'
    // ? data[index]["banking_experience"] = 'true'
    // : data[index]["banking_experience"] = 'false'
    data[index]['banking_experience'] = event.target.checked
    setInputFields(data)
    if (event.target.checked) {
      setBankCount(bankCount + 1)
    } else {
      setBankCount(bankCount - 1)
    }
  }
  function handleCurrWork(index, event) {
    let data = [...inputFields]

    let currentDate = new Date().toLocaleDateString('en-GB')
    console.log('currentDateValue: ', currentDate)
    const [day, month, year] = currentDate.split('/')
    const formattedDate = `${year}-${month}-${day}`
    let currWorking = event.target.checked
    if (currWorking) {
      data[index]['Date_to'] = formattedDate
    }
    data[index]['currently_working'] = currWorking

    let dateFromValue = data[index]['Date_from']
    let dateToValue = formattedDate
    if (!validateDate(dateFromValue, dateToValue)) {
      window.alert('Date From can not be equal or greater than Date To')
      return
    }

    //calculate years and months
    if (dateFromValue && dateToValue) {
      let diff = getDateDiffInYearsAndMonths(dateFromValue, dateToValue)
      data[index]['years'] = diff.years
      data[index]['months'] = diff.months
    }

    setInputFields(data)
  }

  // setting saved data
  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        // fetching profile strength data
        const strength = JSON.parse(response.data.candidate.profile_strength)
        if (strength !== null && strength !== undefined) {
          if (strength === 0) {
            setprofileStrength({
              total: 25,
              profile_info: 'false',
              work_exp: 'true',
              education: 'false',
              certificate: 'false',
              skills: 'false',
            })
          } else {
            let totalProgress = 5

            let profile = strength.profile_info
            if (typeof profile === 'string') {
              profile = strength.profile_info?.toLowerCase?.() === 'true'
            }
            let work = strength.work_exp
            if (typeof work === 'string') {
              work = strength.work_exp?.toLowerCase?.() === 'true'
            }
            let edu = strength.education
            if (typeof edu === 'string') {
              edu = strength.education?.toLowerCase?.() === 'true'
            }
            let certifi = strength.certificate
            if (typeof certifi === 'string') {
              certifi = strength.certificate?.toLowerCase?.() === 'true'
            }
            let skill = strength.skills
            if (typeof skill === 'string') {
              skill = strength.skills?.toLowerCase?.() === 'true'
            }

            if (profile) {
              totalProgress += 20
            }
            if (work) {
              totalProgress += 20
            }
            if (edu) {
              totalProgress += 20
            }
            if (certifi) {
              totalProgress += 15
            }
            if (skill) {
              totalProgress += 20
            }
            setCurrentProgress(totalProgress)
            console.log('strength:total: ' + totalProgress)
            // console.log('strength:work_exp: total: '+ strength.work_exp === 'false' ? (totalProgress + 20) : totalProgress);
            setprofileStrength({
              total: strength.work_exp === 'false' ? totalProgress + 20 : totalProgress,
              profile_info: strength?.profile_info == undefined ? 'false' : strength.profile_info,
              work_exp: 'true',
              education: strength?.education == undefined ? 'false' : strength.education,
              certificate: strength?.certificate == undefined ? 'false' : strength.certificate,
              skills: strength?.skills == undefined ? 'false' : strength.skills,
            })
          }
        } else {
          setprofileStrength({
            total: 25,
            profile_info: 'false',
            work_exp: 'true',
            education: 'false',
            certificate: 'false',
            skills: 'false',
          })
        }

        setTempMail(response.data.user.email)
        let workExperience = response.data?.candidate?.work_experience
        if (workExperience) {
          workExperience = JSON.parse(workExperience)
          const experience = workExperience[0]?.experience
          setSavedInfo(experience)
          setPreviouslyEmployed(workExperience[0]?.previously_employed)
          let employeeData = workExperience[0]?.bop_employee
          if (employeeData) {
            setEmployeeNumber(employeeData?.employeeNumber)
            setEmployeeGrade(employeeData?.grade)
            setEmployeeDesignation(employeeData?.designation)
            setEmployeeGroup(employeeData?.group)
          }

          //fetching data from API and generating multiple input fields according to number of entries
          let fields: any = []
          Object.keys(savedInfo)?.map((companyKey, index) => {
            const entity: any = savedInfo[companyKey]
            let currentlyWorking = entity.currently_working
            let bankingExprience = entity.banking_experience
            if (typeof currentlyWorking === 'string') {
              currentlyWorking = entity.currently_working?.toLowerCase?.() === 'true'
            }
            if (typeof bankingExprience === 'string') {
              bankingExprience = entity.banking_experience?.toLowerCase?.() === 'true'
            }
            let yearsMonths = entity.years
            let [yearsPart, monthsPart] = yearsMonths?.split('.')
            let yearsNumber = parseInt(yearsPart)
            let monthsNumber = parseInt(monthsPart)

            let newField = {
              job_title: entity.job_title,
              job_grade: entity.job_grade,
              name: entity.name,
              end_date: entity.Date_to,
              start_date: entity.Date_from,
              Date_to: entity.Date_to,
              Date_from: entity.Date_from,
              currently_working: currentlyWorking,
              grossSalary: entity.grossSalary,
              benefits: entity.benefits,
              banking_experience: bankingExprience,
              type: entity.type,
              years: yearsNumber,
              months: monthsNumber,
              skills: entity.skills ? Object.values(entity.skills) : [],
              query: '',
              searchResults: [],
            }
            fields.push(newField)

            if (bankingExprience) {
              setBankCount(bankCount + 1)
            }
          })
          if (fields.length > 0) {
            setInputFields(fields)
          } else {
            if (inputFields.length == 0) {
              addFields()
            }
          }
        }

        savedInfo.map((exp) => {
          const companies = Object.values(exp)
        })
      })
    }
    getData()
  }, [tempMail])

  useEffect(() => {
    ; (() => {
      getGroups()
    })()
  }, [])

  // fetching list of groups
  const getGroups = async () => {
    await axios
      .get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setAllGroups(res.data.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const saveSkills = (newSkills) => {
    // console.log('newSkills: ', newSkills);
    if (skills !== null && skills !== undefined && skills.length > 0) {
      const allSkills = skills.concat(newSkills)
      setSkills(allSkills)
    } else {
      setSkills(newSkills)
    }
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ width: '88%', margin: '0 auto' }}>
          {/* <i style={{ float: 'right' }} onClick={addFields} className='bi bi-plus-lg addmore'></i> */}
          <img style={{ float: 'right', cursor: 'pointer' }} onClick={addFields} src={imagePath.ic_add_orange_border} />
        </div>
        <div className='row g-5 g-xl-8 personal-info-container' style={{ margin: '0 auto', padding: '0' }}>
          {/* add more button  */}

          <div className='col-xl-12'>
            <div className=' displayflex-setting '>
              <div className='col-xl-12'>
                {' '}
                <div className='candi-card'>
                  {/* begin::Beader */}

                  <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
                    <span className='card-label section-heading fw-bolder fs-7 ' style={{ color: '#d50000' }}>
                      Please enter the oldest experience first!
                    </span>
                    {/* FORM START  */}
                    <form onSubmit={handleSubmit} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                      {inputFields.map((input, index) => {
                        console.log(
                          `workExperienceInitialize: index: ${index} banking_experience: ${input.banking_experience} type: ` +
                          typeof input.banking_experience
                        )
                        console.log(
                          `workExperienceInitialize: index: ${index} currently_working: ${input.currently_working} type: ` +
                          typeof input.currently_working
                        )
                        console.log(
                          `workExperienceInitialize: index: ${index} currently_working condition: ${input.currently_working ? false : true
                          } `
                        )
                        return (
                          <div className='dynamic-section' key={index}>
                            {index === 0 ? null : (
                              <div style={{ width: '95%', margin: '0 auto' }}>
                                {/* <i style={{ float: 'right' }} onClick={() => removeFields(index)} className='bi bi bi-trash-fill trash-button'></i> */}
                                <img
                                  style={{ float: 'right', cursor: 'pointer' }}
                                  src={imagePath.ic_delete_black_holo}
                                  onClick={() => removeFields(index)}
                                />
                              </div>
                            )}
                            {/* <button onClick={() => removeFields(index)}>Remove</button> */}
                            <h3 className='card-title mt-5 align-items-center flex-column'>
                              <span className='card-label section-heading text-gray-800 fw-bolder fs-3 '>Work Experience {index + 1}</span>
                            </h3>
                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              <div className='form-group col-md-6'>
                                {/* <div className='form-check checkbox-container'>
                                  <input
                                    type='hidden'
                                    value={tempMail}
                                    onChange={(e) => setTempMail(e.target.value)}
                                  />

                                  <input
                                    name='banking_experience'
                                    className='form-check-input'
                                    type='checkbox'
                                    value={input.banking_experience}
                                    checked={input.banking_experience}
                                    onChange={(event) => handleBankingExp(index, event)}
                                  />
                                  <label className='form-check-label '>Banking Experience</label>
                                </div> */}

                                <label className='form-label labelse '>
                                  {' '}
                                  Experience Type<span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div>
                                  <select
                                    name='type'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select '
                                    defaultValue='Select an option'
                                    placeholder='Select an option'
                                    value={input.type}
                                    required
                                    onChange={(event) => handleExperienceTypeChange(index, event)}
                                  >
                                    <option value='Select an option'>Select an option</option>
                                    <option value='banking_experience'>Banking Experience</option>
                                    <option value='work_experience'>Non Banking Experience</option>
                                    <option value='internship'>Internship</option>
                                  </select>
                                </div>
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Company
                                  <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='name'
                                    className='form-control'
                                    placeholder='Company Name'
                                    value={input.name}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            </div>

                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              {/* Job title  */}
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Job Title
                                  <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='job_title'
                                    className='form-control'
                                    placeholder='Title'
                                    value={input.job_title}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Job Grade
                                  <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='me-1'>
                                  {/* <input
                                    type='text'
                                    name='job_grade'
                                    className='form-control'
                                    placeholder='Grade'
                                    value={input.job_grade}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  /> */}
                                  <select
                                    name='job_grade'
                                    data-control='select2'
                                    data-hide-search='true'
                                    className='form-select   w-140px'
                                    defaultValue='Select an option'
                                    placeholder='Select an option'
                                    value={input.job_grade}
                                    required
                                    onChange={(event) => handleFormChange(index, event)}
                                  >
                                    <option value='Select an option'>Select an option</option>
                                    {allGrades &&
                                      allGrades.map((grade) => (
                                        <option key={grade} value={grade}>
                                          {grade}
                                        </option>
                                      ))}
                                  </select>
                                </div>{' '}
                              </div>
                            </div>

                            {/* Date picker  */}
                            {/* from  */}
                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>
                                  Date From
                                  <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                </label>
                                <div className='datepicker me-1'>
                                  {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DesktopDatePicker
                                                                        
                                                                        label="start_date"
                                                                        inputFormat="MM/DD/YYYY"
                                                                        value={input.start_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) => <TextField id='start_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                  <div className='form-group'>
                                    <input
                                      type='date'
                                      name='Date_from'
                                      className='form-control'
                                      max={new Date().toISOString().split('T')[0]}
                                      min={
                                        index > 0
                                          ? inputFields[index - 1].Date_to
                                            ? new Date(inputFields[index - 1].Date_to).toISOString().split('T')[0]
                                            : '1940-01-01'
                                          : '1940-01-01'
                                      }
                                      value={input.Date_from}
                                      required
                                      onChange={(event) => handleDateFromChange(index, event)}
                                      onFocus={(event) => event.target.blur()}
                                    />
                                  </div>
                                </div>
                              </div>

                              {!input.currently_working && (
                                <div className='form-group col-md-6'>
                                  <label className='form-label labelse '>
                                    Date To
                                    <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                                  </label>
                                  <div className='datepicker me-1'>
                                    {/* <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                        label="end_date"
                                                                        value={input.end_date}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                        renderInput={(params) =>
                                                                            <TextField name='end_date' {...params} />}
                                                                    />
                                                                </LocalizationProvider> */}
                                    <div className='form-group'>
                                      <input
                                        type='date'
                                        name='Date_to'
                                        className='form-control'
                                        max={new Date().toISOString().split('T')[0]}
                                        min={
                                          input.Date_from
                                            ? new Date(input.Date_from).toISOString().split('T')[0]
                                            : index > 0
                                              ? inputFields[index - 1].Date_to
                                                ? new Date(inputFields[index - 1].Date_to).toISOString().split('T')[0]
                                                : '1940-01-01'
                                              : '1940-01-01'
                                        }
                                        value={input.Date_to}
                                        required={input.currently_working ? false : true}
                                        onChange={(event) => handleDateToChange(index, event)}
                                        onFocus={(event) => event.target.blur()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className='form-group mt-10 checks-container col-md-12'>
                              <div className='form-check checkbox-container'>
                                <input
                                  name='currently_working'
                                  className='form-check-input'
                                  type='checkbox'
                                  value={input.currently_working}
                                  checked={input.currently_working}
                                  onChange={(event) => handleCurrWork(index, event)}
                                />
                                <label className='form-check-label'>Currently working here?</label>
                              </div>
                            </div>

                            <div className='form-row d-flex mt-10 justify-content-spacearound'>
                              {/* Job title  */}
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>Gross Salary</label>
                                <div className='me-1'>
                                  <input
                                    type='number'
                                    name='grossSalary'
                                    className='form-control'
                                    placeholder='0'
                                    value={input.grossSalary}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                              <div className='form-group col-md-6'>
                                <label className='form-label labelse '>Benefits</label>
                                <div className='me-1'>
                                  <input
                                    type='text'
                                    name='benefits'
                                    className='form-control'
                                    placeholder='e.g Health Insurance'
                                    value={input.benefits}
                                    onChange={(event) => handleFormChange(index, event)}
                                  />
                                </div>{' '}
                              </div>
                            </div>

                            <label className='form-label labelse add-skills-label'>
                              Add Skills Used
                              <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                            </label>
                            {/* SKILLS  */}
                            <div className='form-row d-flex mt-2 justify-content-spacearound ' style={{ margin: '0 1.3rem 0 2rem' }}>
                              <div className='form-group col-md-12'>
                                {/* <label className='form-label labelse '> Blood Group</label> */}
                                <div className='me-4  '>
                                  <input
                                    type='text'
                                    className='search-bar-style form-control   w-140px'
                                    placeholder='Search skills'
                                    value={input.query}
                                    onChange={(event) => handleInputChange(index, event)}
                                    onFocus={(event) => handleInputChange(index, event)}
                                  />
                                  {input.searchResults && input.searchResults.length > 0 && (
                                    <ul className='form-control   w-140px'>
                                      {input.searchResults.map((item, suggestionsIndex) => (
                                        <li
                                          className='form-check-label'
                                          key={suggestionsIndex}
                                          onClick={handleItemClick(item, index)}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {item}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                                {/* <Chips skillset={chips} /> */}
                                <div className=' mt-4'>
                                  {input.skills.map((val, i) => (
                                    <div
                                      key={i}
                                      style={{
                                        width: 'fit-content',
                                        display: 'inline-block',
                                        margin: '0 1rem 0 0',
                                      }}
                                    >
                                      <div className='chip-uxi'>
                                        {val}&nbsp;
                                        <img src={ic_cross} alt='remove' onClick={() => handleDelete(index, i)}></img>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                {/* <h3 className='card-title align-items-center flex-column'>
                                                    <span className='card-label text-gray-800 fw-bolder fs-3 '>
                                                        Suggested Competencies
                                                    </span>
                                                </h3> */}
                              </div>
                            </div>
                          </div>
                        )
                      })}

                      <div className='form-row d-flex mt-6 justify-content-spacearound'>
                        <div className='form-group col-md-6'>
                          <label className='form-label labelse '>
                            Were you previously employed by BOP?
                            <span style={{ color: '#d50000', marginLeft: '4px' }}>*</span>
                          </label>
                          <div className='me-1'>
                            <select
                              name='previouslyEmployed'
                              data-control='select2'
                              data-hide-search='true'
                              className='form-select   w-140px'
                              defaultValue='Select an option'
                              placeholder='Select an option'
                              value={previouslyEmployed}
                              required
                              onChange={(event) => handlePreviouslyEmployedChange(event)}
                            >
                              <option value='Select an option'>Select an option</option>
                              <option value='Yes'>Yes</option>
                              <option value='No'>No</option>
                            </select>
                          </div>{' '}
                        </div>
                      </div>

                      {previouslyEmployed === 'Yes' ? (
                        <div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Employee Number</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='employeeNumber'
                                  className='form-control'
                                  placeholder='Employee Number'
                                  value={employeeNumber}
                                  onChange={(event) => handleEmployeeNoChange(event)}
                                />
                              </div>{' '}
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Grade</label>
                              <div className='me-1'>
                                <select
                                  name='employeeGrade'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  placeholder='Select an option'
                                  value={employeeGrade}
                                  onChange={(event) => handleEmployeeGradeChange(event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {allGrades &&
                                    allGrades.map((grade) => (
                                      <option key={grade} value={grade}>
                                        {grade}
                                      </option>
                                    ))}
                                </select>
                              </div>{' '}
                            </div>
                          </div>
                          <div className='form-row d-flex mt-10 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Designation</label>
                              <div className='me-1'>
                                <input
                                  type='text'
                                  name='employeeDesignation'
                                  className='form-control'
                                  placeholder='Designation'
                                  value={employeeDesignation}
                                  onChange={(event) => handleEmployeeDesignationChange(event)}
                                />
                              </div>{' '}
                            </div>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Group</label>
                              <div className='me-1'>
                                <select
                                  name='employeeGroup'
                                  data-control='select2'
                                  data-hide-search='true'
                                  className='form-select   w-140px'
                                  defaultValue='Select an option'
                                  placeholder='Select an option'
                                  value={employeeGroup}
                                  onChange={(event) => handleEmployeeGroupChange(event)}
                                >
                                  <option value='Select an option'>Select an option</option>
                                  {allGroups &&
                                    allGroups.map((group) => (
                                      <option key={group?.group_name} value={group?.group_name}>
                                        {group?.group_name}
                                      </option>
                                    ))}
                                </select>
                              </div>{' '}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* <div className='form-row d-flex mt-10 justify-content-spacearound'> */}
                      <div className='d-flex align-items-center justify-content-center mt-8'>
                        <button onClick={handleSubmit} className='candi-btn1 m-5' type='submit'>
                          <span style={{ fontWeight: 'bold' }}>Save</span>
                        </button>
                        <div
                          className='candi-btn2 text-white m-5'
                          // disabled={props.curr === 5}
                          onClick={(e) => {
                            if (props.curr === 5) {
                              // disable button
                            } else {
                              handleSubmit(e)
                              props.updates(props.curr + 1)
                            }
                          }}
                        >
                          <span style={{ fontWeight: 'bold' }}>Next</span>
                        </div>
                      </div>
                      {/* </div> */}
                    </form>
                    {/* FORM END  */}
                  </div>

                  {/* end::Body */}
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ margin: 0, position: 'absolute' }} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      {/* <div style={{ margin: 0, position: 'absolute' }} id='loader' className="d-none col-md-12"> */}
      {/* <Saved /> */}
      {/* </div> */}
    </>
  )
}

export default WorkExperience
