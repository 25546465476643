/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import imagePath from '../../../../constants/imagePath'
import {isCandidatePath} from '../../../../constants/CommonFunctions'

const VERSION = process.env.REACT_APP_VERSION
const hostname = window.location.hostname
const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const moveToEditProfile = () => {
    if (currentUser?.role === 'candidate') {
      const comingFrom = 'editProfile'
      navigate('/cvcreation', {state: {comingFrom}})
    }
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              style={{objectFit: 'cover', border: 'solid 1px #F36523'}}
              alt='profile'
              src={currentUser?.profile_pic || imagePath.userIcon}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
              <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>
                {currentUser?.role === 'masterRecruiter' ? 'Admin' : currentUser?.role?.toUpperCase()}
              </span>
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {(currentUser?.role === 'candidate' || isCandidatePath(hostname)) && (
        <div className='menu-item px-5'>
          <a onClick={moveToEditProfile} className='menu-link px-5'>
            My Profile
          </a>
        </div>
      )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
      {/* <label className='form-label fs-7 ' style={{textAlign: 'center', color: '#484848'}}>
        {VERSION}
      </label> */}
    </div>
  )
}

export {HeaderUserMenu}
