import React, { useState, useEffect } from 'react'
import axios from 'axios'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'
import { useAuth } from '../../auth/core/Auth'
import head_img from './assets/a5340016@2x.png'
import resume_icon from './assets/resume@2x.png'
import phone_icon from './assets/phone-call (3)@2x.png'
import user_icon from './assets/user@2x.png'
import card_icon from './assets/id-card (1)@2x.png'
import location_icon from './assets/ss@2x.png'
import email_icon from './assets/email (2)@2x.png'
import hat_icon from './assets/graduation-cap (4)@2x.png'
import warn_icon from './assets/Group 12444@2x.png'
import VacanProgressBar from './comps/VacanProgressBar'
import imagePath from '../../../../constants/imagePath'
import { Link } from 'react-router-dom'
const API_URL = process.env.REACT_APP_API_URL
export const ALLDATA_URL = `${API_URL}/candidate/`
export const JOB_APP__URL = `${API_URL}jobapplication/apply-for-job`
const GET_CONFIGURATIONS_URL = `${API_URL}/configuration/get-configuration`;

const ApplyNowTab = (props) => {
  const { auth } = useAuth()
  const { currentUser } = useAuth()
  const user_id = currentUser?.user_id

  const [savedInfo, setSavedInfo] = useState<any>('')
  const [savedCandidate, setSavedCandidate] = useState<any>('')

  const [fullName, setFullName] = useState('')
  const [cnic, setCnic] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [dob, setdob] = useState(new Date())
  const [freshGraduate, setFreshGraduate] = useState('Yes')
  const [status, setStatus] = useState('')
  const [gender, setGender] = useState('Male')
  const [maritalStatus, setMaritalStatus] = useState('Married')
  const [bloodGroup, setBloodGroup] = useState('A+')
  const [identificationMark, setIdentificationMark] = useState('')
  const [profileStrength, setProfileStrength] = useState(0)
  const [education, setEducation] = useState(0)
  const [comepleteEdu, setCompleteEdu] = useState('')
  const [profileUpdatedAt, setProfileUpdatedAt] = useState(0)
  const [docs, setDocs] = useState<any>();

  function validateValue(value) {
    if (value !== null && value !== undefined && value !== '') {
      return value
    } else {
      return ''
    }
  }

  useEffect(() => {
    async function getData() {
      await axios.get(ALLDATA_URL + user_id).then((response) => {
        const strength = JSON.parse(response.data.candidate.profile_strength)
        console.log('CANDIADTE:strength: ', strength)
        const userData = response.data.user
        let firstName = validateValue(userData.first_name)
        let middleName = validateValue(userData.middle_name)
        let lastName = validateValue(userData.last_name)

        const name = firstName + ' ' + lastName
        setSavedCandidate(response.data.candidate)
        setSavedInfo(response.data.user)
        console.log('USERS DATA: ', savedInfo)
        // setFullName(savedInfo.name)
        setFullName(name)
        setCnic(savedInfo.cnic)
        setEmail(savedInfo.email)
        setContactNumber(savedInfo.phone_number)
        setAddress(savedInfo.address)
        setGender(savedInfo.gender)
        setCity(savedInfo.city)
        setMaritalStatus(savedInfo.marital_status)
        setFreshGraduate(savedInfo.fresh_graduate)
        setStatus(savedInfo.employment_status)
        setBloodGroup(response.data.candidate.blood_group)
        setIdentificationMark(response.data.candidate.identification_mark)
        setProfileStrength(strength.total)
        setEducation(JSON.parse(response.data.candidate.qualification))
        console.log('EDU: ', education)
        props.setCandidateData(response.data.candidate)
        props.setUserData(response.data.user)
        // props.user_profile(response.data.)

        console.log(education[0]['qualification1'].education_level)
        setCompleteEdu(education[0]['qualification1'].institution + ', ' + education[0]['qualification1'].education_level)
        let updatedAt = response?.data?.candidate?.updated_at
        if (updatedAt) {
          const currentDate = Date.now() // Get the current date and time in milliseconds
          const updatedAtDate = new Date(updatedAt).getTime() // Convert updatedAt to milliseconds

          const timeDifference = currentDate - updatedAtDate
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          setProfileUpdatedAt(days)
        }

        // console.log("first", first)
        // let fData = Object.values(first)
        // console.log("fData", fData)
        // for (let i = 0; i < fData.length; i++) {
        //   let entity: any = fData[i]
        //   let data: any = entity["name"]
        //   // comptencyArray.push(data)
        //   console.log('comp: ', data)
        // }
      })
    }
    getData()
  }, [email])


  const getConfigurations = async () => {
    await axios.get(GET_CONFIGURATIONS_URL, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      let data = res?.data?.Configuration;
      let jobDocs = data?.job_documents;
      if (jobDocs) {
        jobDocs = JSON.parse(jobDocs);

        const initialData = Object.keys(jobDocs).map((key) => ({
          i: key,
          docName: jobDocs[key],
          docFile: null
        }));
        setDocs(initialData);
      }


    }).catch((err) => {
      console.error('err: ', err);
    });
  };

  useEffect(() => {
    (async () => {
      await getConfigurations();
    })()
  }, [])

  var data = [
    {
      job_position: 'Bank Teller',
      created: '10 days ago',
      name: 'John Doe',
      cnic: '342342341323',
      email: 'uxair.abs@gmail.com',
      phone: '0343533245',
      address: 'House No 4, Rawalpindi',
      university: 'NUML university, BS Computer Science',
      profile_strength: 80,
    },
  ]

  return (
    <>
      <div >
        <div className='dashboard-top' >
          <h1>Dashboard</h1>
          <div className='d-flex align-items-center'>
            <h3>
              <i className='bi bi-house text-white' style={{ fontSize: '1.5rem' }}></i>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              <Link to="/candidatedashboard" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}>
                My Dashboard
              </Link>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              <Link to="/mainvacancies" style={{ textDecoration: 'none', color: 'white', cursor: 'pointer' }}
                onClick={() => props.openApplyPage(1)}>
                Vacancies
              </Link>
              <i className='bi bi-dot text-white' style={{ fontSize: '1rem' }}></i>
              {props.job_title}
            </h3>
            {/* <h3 className='ms-auto'>
              <i className='bi bi-arrow-clockwise text-white'></i> Last update: 5 mins ago
            </h3> */}
          </div>
        </div>
        <div
          // className='apply-container mt-18'
          className='card-bg shadow-sm pt-8 mt-18'
        >
          {/* top  */}
          <div className='apply-intro text-center'>
            <img src={head_img} alt='...' />
            <h3>Apply now and become a part of BOP's amazing team</h3>
          </div>
          {/* bottom  */}
          <div className='apply-body-container'>
            <>
              <h3 className='mb-7'>Position: {props.job_title}</h3>
              <div className='apply-orange-body'>
                <div className='apply-inner-body p-10'>
                  <h3>
                    <img src={resume_icon} alt='...' /> Profile Resume
                  </h3>
                  {/* <p>Created {props.created_at} days ago</p> */}
                  <p>Created {profileUpdatedAt} days ago</p>
                  <hr />
                  <p
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#F36523',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Profile Strength
                  </p>
                  <VacanProgressBar percent={profileStrength} />
                  <div className='apply-info'>
                    <div className='apply-alert-container'>
                      {savedCandidate.work_experience === null ||
                        savedCandidate.work_experience === undefined ||
                        savedCandidate.work_experience === '' ||
                        !savedCandidate.work_experience ? (
                        <div className='apply-alert-bullet d-flex align-items-center'>
                          <img src={warn_icon} alt='...' />
                          Work Experience
                        </div>
                      ) : null}
                      {savedCandidate.skills === null ||
                        savedCandidate.skills === undefined ||
                        savedCandidate.skills === '' ||
                        !savedCandidate.skills ? (
                        <div className='apply-alert-bullet d-flex align-items-center'>
                          <img src={warn_icon} alt='...' />
                          Skills
                        </div>
                      ) : null}
                      {savedCandidate.qualification === null ||
                        savedCandidate.qualification === undefined ||
                        savedCandidate.qualification === '' ||
                        !savedCandidate.qualification ? (
                        <div className='apply-alert-bullet d-flex align-items-center'>
                          <img src={warn_icon} alt='...' />
                          Education
                        </div>
                      ) : null}
                      {savedCandidate.certification === null ||
                        savedCandidate.certification === undefined ||
                        savedCandidate.certification === '' ||
                        !savedCandidate.certification ? (
                        <div className='apply-alert-bullet d-flex align-items-center'>
                          <img src={warn_icon} alt='...' />
                          Certificates
                        </div>
                      ) : null}
                      {/* <div className='apply-alert-bullet'><img src={warn_icon} alt='...' />Skills</div> */}
                    </div>
                    <p>
                      <img src={user_icon} alt='...' /> {fullName}
                    </p>
                    <p>
                      <img src={card_icon} alt='...' /> {cnic}
                    </p>
                    <p>
                      <img src={email_icon} alt='...' /> {email}
                    </p>
                    <p>
                      <img src={phone_icon} alt='...' /> {contactNumber}
                    </p>
                    <p>
                      <img src={location_icon} alt='...' /> {address}
                    </p>
                    <p>
                      <img src={hat_icon} alt='...' /> {comepleteEdu}
                    </p>
                  </div>
                  <div style={{ borderTop: '1px solid #CBC9C9' }}>
                    <div className='d-flex align-items-center mt-6 mb-4'>
                      <img src={imagePath.ic_doc_gray_holo} width={44} height={44} />
                      <label className='ms-2 fw-bolder fs-3' >Documents Required</label>
                    </div>
                    {/* show list of required documents */}
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {docs ? docs.map((doc, index) => {
                        return (
                          <div key={index}>
                            <label className='orange-tag me-2 mb-2'>{doc?.docName}</label>
                          </div>
                        )
                      }) : (<div className='orange-tag'>List of required Documents not found</div>)}
                    </div>
                  </div>

                </div>

              </div>
            </>
            <div className='apply-bottom-container text-center pb-10'>
              {/* <button type='button' onClick={() => {props.openApplyPage(3)}} data-bs-toggle="modal" data-bs-target="#call-modal" data-bs-whatever="@mdo" className='btn apply-edit-button'><i className='bi bi-pen-fill' style={{ color: '#F36523' }}></i> Edit</button> */}
              <button
                className='btn apply-review-button'
                onClick={() => {
                  props.openApplyPage(3)
                }}
              >
                Review & Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplyNowTab
