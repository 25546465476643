/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useState, useEffect } from 'react'
import { RejectUserbyApplication } from '../../../../_metronic/partials/modals/JobFunnelsModels/RejectUserbyApplication'
import { RejectAllUserApplication } from '../../../../_metronic/partials/modals/JobFunnelsModels/RejectAllUserApplication'
import OfficeLetter from '../../../../_metronic/partials/modals/JobFunnelsModels/OfficeLetter'
import unchecked from '../images/Group 10062.png'
import checked from '../../auth/images/tick.png'
import icRefresh from '../images/ic_refresh.png'
import './HiredMainScreen.css'
import threeedota from '../images/menu.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { useAuth } from '../../auth/core/Auth'
import ViewProfile from '../../../../_metronic/partials/modals/JobFunnelsModels/ViewProfile'
// import { propTypes } from 'react-bootstrap/esm/Image'
import { object } from 'prop-types'
import AvatarIcon from '../img/Group 9681.png'
import EmployeeCardPopUp from '../../../../_metronic/partials/modals/JobFunnelsModels/EmployeeCardPopUp'
import ShowCurrentStatus from '../../../../_metronic/partials/modals/JobFunnelsModels/ShowCurrentStatus'
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError'
import imagePath from '../../../../constants/imagePath'
import CustomPagination from '../../dashboards/components/CustomPagination'
import CandidateReasonPopup from '../../../../_metronic/partials/modals/JobFunnelsModels/CandidateReasonPopup'

interface Resource {
  candidate_details: Object
  resource: Object
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_CANDIDATES = `${API_URL}/candidate`

export const GET_ALL_JOB_APPLICATIONS = `${API_URL}/jobapplication/get-current-job-application`

export const GET_CANDIDATE_DATA = `${API_URL}/candidate/`

export const APPROVE_CANDIDATE_DATA = `${API_URL}/jobapplication/accept-current-job-application`

export const HIRED_STATUS_API = `${API_URL}/jobapplication/set-hired-tab-status`

export const GET_TEST_DATA = `${API_URL}/jobapplication/get-attempt-test-data`

export const GET_INTERVIEW_RESULT = `${API_URL}/jobapplication/set-total-avg-score`
const HIRE_CANDIDATE_URL = `${API_URL}/jobapplication/hire-candidate`

type Props = {
  // className: string
  job_id: any
  testExists: any
  trigger: any
}
const HiredMainScreen: React.FC<Props> = ({ job_id, testExists, trigger }) => {
  console.log({ testExists })
  const [Approvalpopup, setpopupApproval] = useState(<></>)
  const [popup, setpopup] = useState(<></>)
  const [approveAllPopup, setApproveAllPopup] = useState(<></>)
  const [rejectAllPopup, setRejectAllPopup] = useState(<></>)
  const [offerLetterPopup, setOfferLetterPopup] = useState(<></>)
  const [Master, setMaster] = useState(false)
  const [checkedstate, setcheckedstate] = useState(false)
  const [multioptiondots, setmultioptiondots] = useState(false)
  const [hiredStatus, setHiredStatus] = useState<any>('')
  const [hiredInterview, setHiredInterview] = useState<any>('')
  const [StateMulti, setsetState] = useState()
  const [ApplicationData, setApplicationData] = useState<any>([])
  const [result, setResult] = useState<any>([])
  const [popupProfile, setpopupProfile] = useState(<></>)
  const [activeStep, setActiveStep] = useState(1)
  const { auth, logout } = useAuth()
  const [jobTitle, setJobTitle] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [showEmployeeCard, setShowEmployeeCard] = useState(false)
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [showStatusPopup, setShowStatusPopup] = useState(false)
  const [candidateReason, setCandidateReason] = useState('');
  const [showReasonPopup, setShowReasonPopup] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(1)
  const [params, setParams] = useState<any>({
    order: 'updated_at',
    sort: 'DESC',
    page: 1,
    limit: 10,
    total: 0,
    age: '',
    bankingExp: '',
    totalExp: '',
    universities: '',
    canFetchData: true,
  })

  const createPopup = (name, jobapp_id) => {
    setpopup(
      <RejectUserbyApplication
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        handleClose={() => {
          setpopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const createProfilePopup = (
    name,
    jobapp_id,
    email,
    user_id,
    profile_pic,
    cnic,
    phone_number,
    education,
    skills,
    work_Experience,
    total_exp,
    banking_exp,
    nonBanking_exp,
    educations,
    certifications,
    personalData,
    profile_match,
    position
  ) => {
    setpopupProfile(
      <ViewProfile
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        user_id={user_id}
        email={email}
        profile_pic={profile_pic}
        cnic={cnic}
        phone_number={phone_number}
        education={education}
        skills={skills}
        work_Experience={work_Experience}
        total_exp={total_exp}
        banking_exp={banking_exp}
        nonBanking_exp={nonBanking_exp}
        educations={educations}
        certifications={certifications}
        personalData={personalData}
        profile_match={profile_match}
        position={position}
        handleClose={() => {
          setpopupProfile(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const createOfferLetterPopup = (
    name,
    jobapp_id,
    candidate_id,
    banking_experience,
    job_experience,
    job_id,
    address,
    onNextStep,
    position,
    profile_pic,
    uni,
    degree,
    cityPreference
  ) => {
    setOfferLetterPopup(
      <OfficeLetter
        show={true}
        name={name}
        jobapp_id={jobapp_id}
        candidate_id={candidate_id}
        banking_experience={banking_experience}
        job_experience={job_experience}
        job_id={job_id}
        address={address}
        onNextStep={onNextStep}
        position={position}
        profile_pic={profile_pic}
        uni={uni}
        degree={degree}
        cityPreference={cityPreference}
        handleClose={() => {
          setOfferLetterPopup(<></>)
          GetAllJobsApplicationsbypage()
        }}
      />
    )
  }

  const GetAllJobsApplicationsbypage = async () => {

    setIsLoading(true);
    axios
      .post(
        GET_ALL_JOB_APPLICATIONS,
        {
          job_id: job_id,
          getStage: 'hired',
          limit: params.limit,
          page: params.page,
          order: params.order,
          sort: params.sort,
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (resp) => {
        setIsLoading(false);
        setJobTitle(resp?.data?.title)
        const jobApplications = resp?.data?.jobs
        let pagination = resp?.data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            paramsData.canFetchData = false;
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        setResult(jobApplications)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {

          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })

  }

  useEffect(() => {
    (async () => {
      if (params.canFetchData) {
        await GetAllJobsApplicationsbypage()
      }
    })()
  }, [trigger, params])

  //   const {loggedIn, setLoggedIn} = useContext(LoginContext)
  const checkedStateFunction = () => {
    setcheckedstate(!checkedstate)
  }

  const DisplayAllAoptiontoadopt = () => {
    setmultioptiondots(!multioptiondots)
  }

  const fetchHiredStatus = async () => {
    setIsLoading(true)
    axios
      .post(
        HIRED_STATUS_API,
        {
          job_id: job_id,
          jobapp_stage: 'hired',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resHired) => {
        setIsLoading(false)
        console.log({ resHired })
        const hiredStatus = resHired?.data?.jobDataGet.map((item) => ({
          jobapp_status: item?.jobapp_status,
          jobapp_id: item?.jobapp_id,
        }))

      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          // showToast(error.response.data.error.message);
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
          // handleClose();
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })
  }

  const fetchInterview = async () => {
    axios
      .post(
        GET_INTERVIEW_RESULT,
        {
          job_id: job_id,
          jobapp_stage: 'hired',
        },
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resHiredInt) => {
        console.log(resHiredInt)
        // setHiredInterview(resHired?.data?.jobapps.map((item) => item?.jobapp_status));
      })
  }
  const handlePageChange = (page) => {
    let data = { ...params }
    data.page = page
    data.canFetchData = true
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    let data = { ...params }
    data.limit = event.target.value
    data.canFetchData = true
    setParams(data)
  }

  useEffect(() => {
    fetchHiredStatus()
    fetchInterview()
  }, [])

  const getCandidateLatestDegree = (candidate) => {
    let qualification = JSON.parse(candidate?.qualification)
    qualification = qualification[0]?.qualification1
    console.log('candidate: ', candidate)
    const program = qualification?.education_level + ': ' + qualification?.program
    return program
  }

  const hireCandidate = (jobAppId) => {
    if (!jobAppId) {
      setMessage('Job application id not found, Please try again');
      setShowErrorMessage(true);
      return;
    }
    let data = {
      jobapp_id: jobAppId
    }
    axios.post(HIRE_CANDIDATE_URL, data,
      {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      }).then(async (res) => {

        // refresh data
        await fetchHiredStatus()
        GetAllJobsApplicationsbypage()

      }).catch((error) => {
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message);
          setShowErrorMessage(true);
        } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
          logout();
        } else if (error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout();
          } else {
            setMessage(error.response.data.error.message);
            setShowErrorMessage(true);
          }
        } else {
          setMessage(error.message);
          setShowErrorMessage(true);
        }
      })
  }

  return (
    <div style={{ margin: '8px 0px 0px 0px' }} className={`card shadow-sm mb-xl-8`}>
      {/* begin::Header */}
      <div >
        {/* <div>
          <div onClick={() => checkedStateFunction()}>
            {checkedstate === false ? (
              <>
                {' '}
                <img src={unchecked}></img>
              </>
            ) : (
              <>
                {' '}
                <img src={checked}></img>
              </>
            )}
          </div>
        </div> */}

        {/* <div>
          <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value='1'
              data-kt-check='true'
              data-kt-check-target='.widget-9-check'
            />
          </div>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}

      <div className='card-body py-3'>
        <div style={{ position: 'relative' }}>
          {isLoading && (
            // Show loading icon if isLoading state is true
            <div
              className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
            >
              <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
            </div>
          )}
          {/* begin::Table container */}
          <div className='table-responsive'>
            <div className='d-flex'>
              <h1></h1>
              <img className='me-4 cursor-pointer ms-auto'
                title='Refresh data'
                src={imagePath.ic_refresh_orange}
                width={32}
                height={32}
                onClick={async () => {
                  await fetchHiredStatus()
                  GetAllJobsApplicationsbypage()
                }} />
            </div>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 mt-8 table-borders'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold graycolor bg-light'>
                  {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-13-check'
                    />
                  </div>
                </th> */}

                  <th className='min-w-100px align-items-center ps-4 align-middle' scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Candidate</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>CNIC</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' style={{ whiteSpace: 'nowrap' }} scope='col'>
                    <label className=' form-label fw-bolder fs-6 me-2'>Location</label>
                  </th>
                  {testExists ? <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Test Score</label>
                  </th> : null}
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Interview Score</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Joining Date</label></th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Status</label>
                  </th>
                  <th className='min-w-100px align-items-center align-middle' scope='col' >
                    <label className=' form-label fw-bolder fs-6 me-2'>Actions</label>
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {result && result?.map((candidategData, index) => {
                  const isEvenRow = index % 2 === 0
                  return (
                    <tr style={{ backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }}>
                      <td>
                        <div className='d-flex align-items-center ps-4'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              className='profile-user-icon cursor-pointer'
                              src={
                                candidategData?.candidate?.profile_pic !== null
                                  ? candidategData?.candidate?.profile_pic
                                  : candidategData?.candidateData?.profile_pic !== null
                                    ? candidategData?.candidateData?.profile_pic
                                    : imagePath.userIcon
                              }
                              alt=''
                              onClick={() => {
                                createProfilePopup(
                                  candidategData?.candidateData?.fullName,
                                  candidategData?.jobapp_id,
                                  candidategData?.candidateData?.email,
                                  candidategData?.candidateData?.user_id,
                                  candidategData?.candidate?.profile_pic,
                                  candidategData?.candidateData?.cnic,
                                  candidategData?.candidateData?.phone_number,
                                  candidategData?.institute,
                                  candidategData?.candidate?.skills,
                                  candidategData?.candidate?.work_experience,
                                  // parseInt(candidategData?.job_experience) + parseInt(candidategData?.banking_experience),
                                  candidategData?.candidate?.totalExperience,
                                  candidategData?.banking_experience,
                                  candidategData?.job_experience,
                                  candidategData?.candidate?.qualification,
                                  candidategData?.candidate?.certification,
                                  candidategData?.candidateData?.dob,
                                  candidategData?.profile_match,
                                  jobTitle
                                )
                              }}
                            />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <label className='job-funneling-table-content '>
                              {candidategData?.candidateData?.fullName}
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <label className='job-funneling-table-content '>{candidategData?.candidateData?.cnic}</label>
                      </td>
                      <td>
                        <label className='job-funneling-table-content '>{candidategData?.posting_place}</label>
                      </td>

                      {testExists ? (
                        <td>
                          <div className='experienceJobstable'>
                            <td className='hiredscore-percentage-container' style={{ padding: '5px' }}>
                              <label>
                                {candidategData?.test_aggregated_result !== null
                                  ? `${candidategData?.test_aggregated_result}%`
                                  : '0%'}
                              </label>
                            </td>
                          </div>
                        </td>
                      ) : null}

                      <td>
                        <div className=' experienceJobstable '>
                          <label className='testscore-approval' >
                            {candidategData?.average_interview_score}%
                          </label>
                        </div>
                      </td>

                      <td>
                        <label className='job-funneling-table-content '>-</label>
                      </td>

                      <td>
                        <div className=' experienceJobstable '>
                          <td
                            className={
                              (candidategData?.jobapp_status === 'Offer Letter Accepted' || candidategData?.jobapp_status === 'Candidate Joined') ? 'green-chip' : 'offer-letter-conducted'
                            }
                            style={{ padding: '5px', cursor: 'pointer' }}
                            onClick={() => {
                              if (candidategData?.jobapp_status == 'Candidate Rejected') {
                                let reason = candidategData?.candidate_reason;

                                setCandidateReason(reason);
                                setShowReasonPopup(true);

                              } else {
                                setCurrentJobAppId(candidategData?.jobapp_id)
                                setShowStatusPopup(true)
                              }
                            }}
                          >
                            {candidategData?.jobapp_status}
                          </td>
                        </div>
                      </td>

                      <td>
                        <div className='d-flex align-items-center'>
                          {candidategData?.jobapp_status !== 'WITHDRAWN' ? (
                            <div>
                              {(candidategData?.jobapp_status === 'Offer Letter Pending' ||
                                candidategData?.jobapp_status === 'offer letter pending') && (
                                  //  || candidategData?.jobapp_status === 'Offer Letter Sent'
                                  // {candidategData?.jobapp_status === 'Offer Letter Approved' && (
                                  <td>
                                    <div className='experienceJobstable me-4'>
                                      <td>
                                        <button
                                          className='hiring-send-eoffer-btn'
                                          onClick={() => {
                                            const degree = getCandidateLatestDegree(candidategData?.candidate)
                                            createOfferLetterPopup(
                                              candidategData?.candidateData?.fullName,
                                              candidategData?.jobapp_id,
                                              candidategData?.candidateData?.user_id,
                                              candidategData?.banking_experience,
                                              candidategData?.job_experience,
                                              candidategData?.job_id,
                                              candidategData?.candidateData?.address,
                                              activeStep,
                                              jobTitle,
                                              candidategData?.candidate?.profile_pic,
                                              candidategData?.institute,
                                              degree,
                                              candidategData?.candidate?.city_preference
                                            )
                                          }}
                                        >
                                          Send E-Offer
                                        </button>
                                      </td>
                                    </div>
                                  </td>
                                )}

                              {candidategData?.jobapp_status == 'Candidate Rejected' && (
                                <td>
                                  <div className='experienceJobstable me-4'>
                                    <td>
                                      <button
                                        className='hiring-send-eoffer-btn'
                                        onClick={() => {
                                          const degree = getCandidateLatestDegree(candidategData?.candidate)
                                          createOfferLetterPopup(
                                            candidategData?.candidateData?.fullName,
                                            candidategData?.jobapp_id,
                                            candidategData?.candidateData?.user_id,
                                            candidategData?.banking_experience,
                                            candidategData?.job_experience,
                                            candidategData?.job_id,
                                            candidategData?.candidateData?.address,
                                            activeStep,
                                            jobTitle,
                                            candidategData?.candidate?.profile_pic,
                                            candidategData?.institute,
                                            degree,
                                            candidategData?.candidate?.city_preference
                                          )
                                        }}
                                      >
                                        Re-Send E-Offer
                                      </button>
                                    </td>
                                  </div>
                                  {offerLetterPopup}
                                </td>
                              )}

                              {/* {candidategData?.jobapp_status == 'candidate joined' && ( */}
                              {(candidategData?.jobapp_status == 'Offer Letter Accepted' || candidategData?.jobapp_status == 'Candidate Joined') && (
                                <td>
                                  <div className='experienceJobstable me-4'>
                                    <td>
                                      <button
                                        className='hiring-send-eoffer-btn'
                                        onClick={() => {
                                          setCurrentJobAppId(candidategData?.jobapp_id)
                                          setShowEmployeeCard(true)
                                        }}
                                      >
                                        Generate Employee Card
                                      </button>
                                    </td>
                                  </div>
                                  {offerLetterPopup}
                                </td>
                              )}
                            </div>
                          ) : (
                            <div>
                              <label className='red-chip fs-7 fw-bolder form-label me-4'>Withdrawn</label>
                            </div>
                          )}
                          <div className=' ViewUserProfileTag d-flex flex-column justify-content-center '>
                            {candidategData?.jobapp_status === 'Offer Letter Accepted' && (
                              <button className='candi-btn2 btn text-white px-3 py-2'
                                onClick={() => {
                                  hireCandidate(candidategData?.jobapp_id);
                                }}
                              >Hire</button>
                            )}

                          </div>
                        </div>
                        {popupProfile}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              {offerLetterPopup}
            </table>
            {/* pagination */}
            {(result && result.length > 0) && (<div className='d-flex col-md-12 align-items-center'>
              <div className='col-md-4'>
                <label>{`Showing ${result ? result.length : 0} of ${params.total} results.`}</label>
              </div>
              <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
                <CustomPagination
                  pageValue={params.page}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>

              <div className='col-md-4 '>
                <div className='ms-auto d-flex flex-end align-items-center'>
                  <select
                    className='me-2 pagination-input'
                    style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                    id='selectValues'
                    // value={itemsPerPage}
                    value={params.limit}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                  </select>

                  <div className='me-2'>
                    <label>Items per page</label>
                  </div>
                </div>
              </div>
            </div>)}
          </div>
        </div>
      </div>

      {showEmployeeCard && (
        <EmployeeCardPopUp
          show={true}
          jobAppId={currentJobAppId}
          handleClose={() => {
            setShowEmployeeCard(false)
          }}
        />
      )}

      {showStatusPopup && (
        <ShowCurrentStatus
          show={true}
          jobAppId={currentJobAppId}
          stage={'hired'}
          handleClose={() => {
            setShowStatusPopup(false)
          }}
        />
      )}

      {showReasonPopup && (
        <CandidateReasonPopup
          show={true}
          reason={candidateReason}
          handleClose={() => setShowReasonPopup(false)}

        />
      )}

      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </div>
  )
}

export default HiredMainScreen
