//import liraries
import {useIntl} from 'react-intl'
//import { PageTitle } from '../../../_metronic/layout/core'
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import '../../CVScreens/CandiMain.css'
import '../../CVScreens/personalinfo.css'
import '../../CVScreens/position.css'
import '../../CVScreens/Stepper.css'

import Loader from '../../CVScreens/comps/Loader'
import {useNavigate} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import TextField from '@mui/material/TextField'
import {useAuth} from '../../../auth'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import axios from 'axios'
import imagePath from '../../../../../constants/imagePath'

const API_URL = process.env.REACT_APP_API_URL

export const CV_URL = `${API_URL}candidate/upload-files/`
export const ALLDATA_URL = `${API_URL}/candidate/`

// create a component
const PersonalInfo = ({
  prefix,
  handlePrefixChange,
  firstName,
  handleFirstNameChange,
  middleName,
  handleMiddleNameChange,
  lastName,
  handleLastNameChange,
  fatherName,
  handleFatherNameChange,
  cnic,
  handleCnicChange,
  cnicExpiry,
  handleChangeCnicExpiryDate,
  dob,
  handleDate,
  age,
  contactNumber,
  handleContactNumberChange,
  email,
  handleEmailChange,
  address,
  handleAddressChange,
  city,
  handleCityChange,
  cityPreference,
  handleChangeCityPreference,
  gender,
  handleGenderChange,
  maritalStatus,
  handleMaritalStatusChange,
  religion,
  handleReligionChange,
  otherReligion,
  handleOtherReligionChange,
  identificationMark,
  handleIdentitficationMark,
  freshGraduate,
  handleFreshGraduateChange,
  status,
  handleStatusChange,
  spouseName,
  handleSpouseNameChange,
  spouseCnic,
  handleSpouseCnicChange,
  spouseBOD,
  handleSpouseDOB,
  childInfo,
  addChildFields,
  removeChildFields,
  handleChildInfoChange,
  handleChildCnicChange,

  ...props
}) => {
  const allReligions = ['Islam', 'Christianity', 'Hinduism', 'Sikhism', 'Buddhism', 'Folk religions', 'Judaism', 'Irreligion', 'Other']
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Position' })}</PageTitle> */}

      <div className='row g-5 g-xl-8 personal-info-container'>
        <div className='col-xl-12'>
          <div className=' displayflex-setting '>
            <div className='col-xl-12'>
              {' '}
              <div className='candi-card'>
                <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row ms-7' style={{paddingLeft:'2px'}}>
                  <form>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          First Name<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select
                                className='country-code'
                                id='country-code'
                                defaultValue='Prefix'
                                placeholder='Prefix'
                                value={prefix}
                                onChange={(event) => handlePrefixChange(event)}
                              >
                                <option value=''>Prefix</option>
                                <option value='Mr.'>Mr.</option>
                                <option value='Mrs.'>Mrs.</option>
                                <option value='Miss.'>Miss.</option>
                              </select>
                              <input
                                type='text'
                                // name='status'
                                className='phone-no-input'
                                placeholder='First Name'
                                value={firstName}
                                required
                                onChange={(event) => handleFirstNameChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>Middle Name</label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Middle Name'
                            value={middleName ? middleName : ''}
                            required
                            onChange={(event) => handleMiddleNameChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Last Name<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Last Name'
                            value={lastName}
                            required
                            onChange={(event) => handleLastNameChange(event)}
                          />
                        </div>{' '}
                      </div>

                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Father Name<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            // name='status'
                            className='form-control'
                            placeholder='Father Name'
                            value={fatherName}
                            required
                            onChange={(event) => handleFatherNameChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Enter CNIC<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            autoComplete='off'
                            // pattern="\d*"
                            maxLength={15}
                            minLength={15}
                            className='form-control'
                            placeholder='____-_______-_'
                            value={cnic}
                            required
                            onChange={(event) => handleCnicChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          CNIC Expiry Date
                          <span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='datepicker me-4'>
                          <div className='form-group'>
                            <input
                              type='date'
                              name='Date_from'
                              className='form-control'
                              min={new Date().toISOString().split('T')[0]}
                              value={cnicExpiry}
                              required
                              onChange={(event) => handleChangeCnicExpiryDate(event)}
                            />
                          </div>

                          {/* </ThemeProvider> */}
                        </div>
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          DOB ( as on CNIC )<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='datepicker me-4'>
                          {/* <ThemeProvider theme={theme}> */}
                          {/* <LocalizationProvider style={{ width: '100%', color: 'red' }} dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={dob}
                                                            onChange={(event) => handleDate(event)}
                                                            renderInput={(params) => <TextField {...params} required />}
                                                        />
                                                    </LocalizationProvider> */}

                          <div className='form-group'>
                            <input
                              type='date'
                              name='Date_from'
                              className='form-control'
                              value={dob}
                              required
                              onChange={(event) => handleDate(event)}
                            />
                          </div>

                          {/* </ThemeProvider> */}
                        </div>
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Age<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <label placeholder='0'>{age}</label>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Contact Number<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <div className='form-control'>
                            <div className='country-code-phone-no'>
                              <select className='country-code' id='country-code' defaultValue='+92'>
                                <option value='+92'>+92</option>
                              </select>
                              <input
                                type='tel'
                                name='status'
                                className='phone-no-input'
                                autoComplete='off'
                                placeholder='3xxxxxxxxx'
                                pattern='\d*'
                                maxLength={10}
                                minLength={10}
                                value={contactNumber}
                                required
                                onChange={(event) => handleContactNumberChange(event)}
                              />
                            </div>
                          </div>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Email<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <label className='form-control me-4'>{email}</label>
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Address<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            autoComplete='off'
                            className='form-control'
                            placeholder=''
                            value={address}
                            required
                            onChange={(event) => handleAddressChange(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          City<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder=''
                            value={city}
                            required
                            onChange={(event) => handleCityChange(event)}
                          />
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          City of Preference<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <input
                            type='text'
                            name='status'
                            className='form-control'
                            autoComplete='off'
                            placeholder=''
                            value={cityPreference}
                            required
                            onChange={(event) => handleChangeCityPreference(event)}
                          />
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '>
                          Gender<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='form-row d-flex mt-5 justify-content-spacearound'>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{alignContent: 'center', display: 'flex', alignItems: 'center'}}
                          >
                            <input
                              type='radio'
                              id='Male'
                              name='options'
                              value='Male'
                              checked={gender === 'Male'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label htmlFor='Male' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                              Male
                            </label>
                          </div>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{alignContent: 'center', display: 'flex', alignItems: 'center'}}
                          >
                            <input
                              type='radio'
                              id='Female'
                              name='options'
                              value='Female'
                              checked={gender === 'Female'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label htmlFor='Female' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                              Female
                            </label>
                          </div>
                          <div
                            className='form-group  col-md-3 me-4'
                            style={{alignContent: 'center', display: 'flex', alignItems: 'center'}}
                          >
                            <input
                              type='radio'
                              id='Others'
                              name='options'
                              value='Others'
                              checked={gender === 'Others'}
                              onChange={(event) => handleGenderChange(event)}
                              className='checks-input'
                            />
                            <label htmlFor='Others' style={{fontWeight: 'bold', margin: '0px 0px 0px 10px'}}>
                              Others
                            </label>
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className={`form-group ${religion === 'Other' ? 'col-md-2 me-2 ms-8' : 'col-md-6'}`}>
                        <label className='form-label labelse '>
                          {' '}
                          Religion<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                        </label>
                        <div className='me-4'>
                          <select
                            name='religion'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            placeholder='Select an option'
                            value={religion}
                            required
                            onChange={(event) => handleReligionChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            {allReligions.map((religionOption) => (
                              <option key={religionOption} value={religionOption}>
                                {religionOption}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {religion === 'Other' ? (
                        <div className='form-group col-md-3 me-6'>
                          <label className='form-label labelse '>
                            Enter Religion<span style={{color: '#d50000', marginLeft: '4px'}}>*</span>
                          </label>
                          <div className='me-1'>
                            <input
                              type='text'
                              name='otherInstitute'
                              className='form-control'
                              placeholder='Religion'
                              value={otherReligion}
                              required
                              onChange={(event) => handleOtherReligionChange(event)}
                            />
                          </div>{' '}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Fresh Graduate</label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px '
                            defaultValue='Yes'
                            value={freshGraduate}
                            required
                            onChange={(event) => handleFreshGraduateChange(event)}
                          >
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>
                    <div className='form-row d-flex mt-10 justify-content-spacearound'>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Employment Status</label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Unemployed'
                            value={status}
                            required
                            onChange={(event) => handleStatusChange(event)}
                          >
                            <option value='Select an option'>Select an option</option>
                            <option value='Employed'>Employed</option>
                            <option value='Self Employed'>Self Employed</option>
                            <option value='Looking for Opportunities'>Looking for Opportunities</option>
                          </select>
                        </div>{' '}
                      </div>
                      <div className='form-group col-md-6'>
                        <label className='form-label labelse '> Marital Status</label>
                        <div className='me-4'>
                          <select
                            name='status'
                            data-control='select2'
                            data-hide-search='true'
                            className='form-select   w-140px'
                            defaultValue='Married'
                            value={maritalStatus}
                            required
                            onChange={(event) => handleMaritalStatusChange(event)}
                          >
                            <option value='Married' selected>
                              Married
                            </option>
                            <option value='Single'>Single</option>
                            <option value='Divorced'>Divorced</option>
                            <option value='Widowed'>Widowed</option>
                          </select>
                        </div>{' '}
                      </div>
                    </div>

                    {maritalStatus !== 'Single' && maritalStatus !== 'Select an option' ? (
                      <div className='form-row mt-10 justify-content-spacearound mx-8'>
                        <label className='form-label labelse'>Family Info (Optional)</label>
                        <div className='mt-4' style={{border: 'solid 1px #8C8C8C', borderRadius: '10px'}}>
                          <div className='form-row d-flex mt-4 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Spouse Name</label>
                              <div className='me-4'>
                                <input
                                  type='text'
                                  // name='status'
                                  className='form-control'
                                  placeholder='Full Name'
                                  value={spouseName}
                                  required
                                  onChange={(event) => handleSpouseNameChange(event)}
                                />
                              </div>{' '}
                            </div>

                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Enter CNIC</label>
                              <div className='me-4'>
                                <input
                                  type='text'
                                  name='status'
                                  // pattern="\d*"
                                  autoComplete='off'
                                  maxLength={15}
                                  minLength={15}
                                  className='form-control'
                                  placeholder='____-_______-_'
                                  value={spouseCnic}
                                  required
                                  onChange={(event) => handleSpouseCnicChange(event)}
                                />
                              </div>{' '}
                            </div>
                          </div>
                          <div className='form-row d-flex mt-4 justify-content-spacearound'>
                            <div className='form-group col-md-6'>
                              <label className='form-label labelse '>Spouse DOB</label>
                              <div className='datepicker me-4'>
                                <div className='form-group'>
                                  <input
                                    type='date'
                                    name='Date_from'
                                    className='form-control'
                                    max={new Date().toISOString().split('T')[0]}
                                    value={spouseBOD}
                                    required
                                    onChange={(event) => handleSpouseDOB(event)}
                                  />
                                </div>

                                {/* </ThemeProvider> */}
                              </div>
                            </div>
                          </div>

                          {/* child info */}
                          <div className='d-flex flex-column'>
                            <div style={{width: '88%', margin: '0 auto'}}>
                              <img
                                style={{float: 'right', cursor: 'pointer'}}
                                onClick={addChildFields}
                                src={imagePath.ic_add_orange_border}
                              />
                            </div>
                            <div className='form-row mt-4 justify-content-spacearound'>
                              {childInfo &&
                                childInfo.map((child, index) => {
                                  return (
                                    <div key={index} className='d-flex flex-column mb-8'>
                                      <label className='form-label labelse ms-8'>{`Child # ${index + 1}`}</label>
                                      {index === 0 ? null : (
                                        <div style={{width: '88%', margin: '0 auto'}}>
                                          {/* <i style={{ float: 'right' }} onClick={() => removeFields(index)} className='bi bi bi-trash-fill trash-button'></i> */}
                                          <img
                                            style={{float: 'right', cursor: 'pointer'}}
                                            src={imagePath.ic_delete_black_holo}
                                            onClick={() => removeChildFields(index)}
                                          />
                                        </div>
                                      )}
                                      <div>
                                        <div className='form-row d-flex justify-content-spacearound'>
                                          <div className='form-group col-md-6'>
                                            <label className='form-label labelse '>Child Name</label>
                                            <div className='me-4'>
                                              <input
                                                type='text'
                                                name='name'
                                                className='form-control'
                                                placeholder='Full Name'
                                                value={child?.name}
                                                required
                                                onChange={(event) => handleChildInfoChange(index, event)}
                                              />
                                            </div>{' '}
                                          </div>

                                          <div className='form-group col-md-6'>
                                            <label className='form-label labelse '>Child CNIC</label>
                                            <div className='me-4'>
                                              <input
                                                type='text'
                                                name='cnic'
                                                // pattern="\d*"
                                                autoComplete='off'
                                                maxLength={15}
                                                minLength={15}
                                                className='form-control'
                                                placeholder='____-_______-_'
                                                value={child?.cnic}
                                                required
                                                onChange={(event) => handleChildCnicChange(index, event)}
                                              />
                                            </div>{' '}
                                          </div>
                                        </div>
                                        <div className='form-row d-flex mt-4 justify-content-spacearound'>
                                          <div className='form-group col-md-6'>
                                            <label className='form-label labelse '>Child DOB</label>
                                            <div className='datepicker me-4'>
                                              <div className='form-group'>
                                                <input
                                                  type='date'
                                                  name='dob'
                                                  className='form-control'
                                                  max={new Date().toISOString().split('T')[0]}
                                                  value={child?.dob}
                                                  required
                                                  onChange={(event) => handleChildInfoChange(index, event)}
                                                />
                                              </div>

                                              {/* </ThemeProvider> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </form>
                </div>

                {/* end::Body */}
              </div>{' '}
            </div>
          </div>
        </div>
      </div>
      <div style={{margin: 0, position: 'absolute'}} id='loader' className='d-none col-md-12'>
        <Loader id='loader' message='Saving data...' />
      </div>

      {/* <div style={{ margin: 0, position: 'absolute' }} id='loader' className="d-none col-md-12">
        <Saved />
      </div> */}
    </>
  )
}

//make this component available to the app
export default PersonalInfo
