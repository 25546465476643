/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconUserModel } from '../../../../app/modules/profile/ProfileModels'
import './cards.css'
import { SetJobInActive } from '../../modals/create-app-stepper/SetJobInActiveJobs'
import calendar from '../images/today_black_24dp.png'
import Location from '../images/place_black_24dp (1).png'
import editJob from '../images/edit-icon.png'
import candidate from '../images/candidate.png'
import star from '../images/grade_black_24dp.png'
import toggleburron from '../images/toggle.png'
import axios from 'axios'
import editJobHover from '../images/edit-hover.png'
import stackedimages from '../images/users.png'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ShowError from '../toasts/ShowError'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { getFormattedDate } from '../../../../constants/CommonFunctions'
import CustomPagination from '../../../../app/modules/dashboards/components/CustomPagination'
import ReasonsPopup from '../../modals/JobFunnelsModels/ReasonsPopup'
import imagePath from '../../../../constants/imagePath'
import EmployeeCardPopUp from '../../modals/JobFunnelsModels/EmployeeCardPopUp'

const API_URL = process.env.REACT_APP_API_URL
export const GET_ALL_JOBS_ADMIN = `${API_URL}/job/show-status-jobs`
export const GET_ALL_JOBS_RECRUITER = `${API_URL}/job`
export const GET_TEST_DETAILS = `${API_URL}/test/`
const GET_ALL_GROUPS = `${API_URL}/group`
const GET_GRADES_URL = `${API_URL}/configuration/get-job-grade`

// import
type Props = {
  icon: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  description: string
  date: string
  budget: string
  progress: number
  users?: Array<IconUserModel>
}
interface PropsL {
  jobs: {
    job_id: string
  }
  testExists: boolean
}
const CardClosed: FC<Props> = ({ }) => {
  const navigate = useNavigate()
  const { currentUser, auth, logout } = useAuth()
  const [over, setOver] = useState(-1)
  const [AllJobs, setAllJobs] = useState<any>([])
  const [showUpdateCompetecnyScreen, setshowUpdateCompetecnyScreen] = useState<boolean>(false)
  const [popup, setpopup] = useState(<></>)
  const [popup2, setpopup2] = useState(<></>)
  const [TestDet, setTestDet] = useState<any>([{}])
  const [refreshData, setRefreshData] = useState(false)
  const [getGrade, setGrade] = useState<any>([])
  const [getGroup, setGroup] = useState<any>([])
  const [selectedValue, setSelectedValue] = useState<any>([])
  const [selectedValueGroup, setSelectedValueGroup] = useState<any>([])
  const [isDropDownActive, setIsDropDownActive] = useState(false)
  const [candidateNew, setCandidateNew] = useState<any>('')
  const [candidateTotal, setcandidateTotal] = useState<any>('')
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenTotal, setIsOpenTotal] = useState(false)
  const [isOpenGroup, setIsOpenGroup] = useState(false)
  const [allGroups, setAllGroups] = useState<any>([])
  const [allGrades, setAllGrades] = useState<any>([])
  const [isDropDownActives, setIsDropDownActives] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRefGrade = useRef<HTMLDivElement>(null)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Change this value as needed
  const [showReasonsPopup, setShowReasonsPopup] = useState(false)
  const [currentReasons, setCurrentReasons] = useState<any>()
  const [selectedFilters, setSelectedFilters] = useState<any>([])
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [totalPages, setTotalPages] = useState(1)
  const [showEmployeeCard, setShowEmployeeCard] = useState(false)
  const [currentJobAppId, setCurrentJobAppId] = useState('')
  const [params, setParams] = useState<any>({
    job_status: 'closed',
    order_by: 'updated_at',
    direction: 'desc',
    page: 1,
    limit: 10,
    total: 0,
    group_id: '',
    grade: '',
  })

  const createPopup = (value) => {
    setpopup(<SetJobInActive show={true} id={value} handleRefreshData={handleRefreshData} handleClose={() => setpopup(<></>)} />)
  }

  const GetAllJobs = async () => {
    let data = { ...params }
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )

    await axios
      .get(
        GET_ALL_JOBS_ADMIN,
        {
          // params: {
          //   job_status: 'closed',
          // },
          params: filteredParams,
          headers: {
            Authorization: `Bearer ${auth?.token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((resp) => {
        setIsLoading(false)
        let data = resp.data;
        let pagination = data?.pagination
        if (pagination) {
          if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
            let paramsData = { ...params }
            paramsData.page = pagination?.page || 1
            paramsData.limit = pagination?.limit || 10
            paramsData.total = pagination?.total || 0
            setParams(paramsData)
          }
          setTotalPages(pagination?.totalPages || 0)
        }
        let GetAllJobsFromDB = resp.data.data
        setAllJobs(GetAllJobsFromDB)

        // const grades = GetAllJobsFromDB.map((job) => job.grade)
        // const uniqueGrades = grades.filter((value, index, self) => self.indexOf(value) === index)
        // setFilteredGrades(uniqueGrades)

        // const group = GetAllJobsFromDB.map((job) => job.groupName)
        // const uniqueGroups = group.filter((value, index, self) => {
        //   return value && self.indexOf(value) === index
        // })
        // setFilteredGroups(uniqueGroups)
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.response && error.response.status === 400) {
          // showToast(error.response.data.message)
          setMessage(error.response.data.message)
          setShowErrorMessage(true)
        } else if (
          error.response &&
          error.response.status === 401 &&
          error.response.data &&
          error.response.data.name === 'TokenExpiredError'
        ) {
          logout()
        } else if (error.response && error.response.data && error.response.data.error) {
          // Handle error message from the API
          if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
            logout()
          } else {
            setMessage(error.response.data.error.message)
            setShowErrorMessage(true)
          }
        } else {
          setMessage(error.message)
          setShowErrorMessage(true)
        }
      })

  }

  const GetAllJobsRecruiter = async () => {
    let data = { ...params }
    data['recruiter_id'] = currentUser?.user_id;
    const filteredParams = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && value !== null && value !== undefined)
    )

    axios.get(GET_ALL_JOBS_RECRUITER, {
      // params: {
      //   job_status: 'closed',
      //   recruiter_id: currentUser?.user_id,
      // },
      params: filteredParams,
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    }).then((resp) => {
      setIsLoading(false)
      let data = resp.data;
      let pagination = data?.pagination
      if (pagination) {
        if (params.page !== pagination.page || params.limit !== pagination.limit || params.total !== pagination.total) {
          let paramsData = { ...params }
          paramsData.page = pagination?.page || 1
          paramsData.limit = pagination?.limit || 10
          paramsData.total = pagination?.total || 0
          setParams(paramsData)
        }
        setTotalPages(pagination?.totalPages || 0)
      }
      let GetAllJobsFromDB = resp.data.jobs
      setAllJobs(GetAllJobsFromDB)
      // const grades = GetAllJobsFromDB.map((job) => job.grade)
      // const uniqueGrades = grades.filter((value, index, self) => self.indexOf(value) === index)
      // setFilteredGrades(uniqueGrades)

      // const group = GetAllJobsFromDB.map((job) => job.groupName)
      // const uniqueGroups = group.filter((value, index, self) => {
      //   return value && self.indexOf(value) === index
      // })
      // setFilteredGroups(uniqueGroups)

    }).catch((error) => {
      setIsLoading(false)
      if (error.response && error.response.status === 400) {
        // showToast(error.response.data.message)
        setMessage(error.response.data.message)
        setShowErrorMessage(true)
      } else if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.name === 'TokenExpiredError'
      ) {
        logout()
      } else if (error.response && error.response.data && error.response.data.error) {
        // Handle error message from the API
        if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(error.response.data.error.message)
          setShowErrorMessage(true)
        }
      } else {
        setMessage(error.message)
        setShowErrorMessage(true)
      }
    })
  }

  const handleGradeChange = (event) => {
    const { value, checked } = event.target

    if (checked) {
      setSelectedValue([...selectedValue, value])
      setIsDropDownActive(true)
    } else {
      setSelectedValue(selectedValue.filter((item) => item !== value))
      setIsDropDownActive(selectedValue.length > 1)
    }
  }

  const filteredItemsGrade = AllJobs.filter((item) => {
    return selectedValue.includes(item.grade)
  })
  console.log({ filteredItemsGrade })

  const filteredItemsGroup = AllJobs.filter((item) => {
    return selectedValueGroup.includes(item.groupName)
  })

  const handleGroupChange = (event) => {
    const { value, checked } = event.target

    if (checked) {
      setSelectedValueGroup([...selectedValueGroup, value])
      setIsDropDownActives(true)
    } else {
      setSelectedValueGroup(selectedValueGroup.filter((item) => item !== value))
      setIsDropDownActives(selectedValueGroup.length > 1)
    }
  }

  const jobIds = AllJobs.map((item) => item.job_id)

  useEffect(() => {
    (async () => {

      if (currentUser?.role === 'recruiter') {
        await GetAllJobsRecruiter()
      } else {
        await GetAllJobs()
      }

    })()
  }, [params])

  useEffect(() => {
    (async () => {
      await getGroups();
      await getGrades();
    })();
  }, []);

  // Fetching list of groups
  const getGroups = async () => {
    try {
      const response = await axios.get(GET_ALL_GROUPS, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })

      setAllGroups(response.data.data)
    } catch (error) {
      // Consolidated error handling
      handleErrors(error);
    }
  }
  // fetching list of grades
  const getGrades = async () => {
    await axios
      .get(GET_GRADES_URL, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        let data = res?.data?.job_grade
        if (data) {
          data = JSON.parse(data)
          if (data) {
            setAllGrades(Object.values(data))
          }
        }
      })
      .catch((err) => {
        handleErrors(err)
      });
  }

  const handleErrors = (error) => {
    if (error.response) {
      const { status, data } = error.response

      if (status === 400) {
        setMessage(data.message)
      } else if (status === 401 && data.name === 'TokenExpiredError') {
        logout()
      } else if (data.error) {
        if (data.error.status === 401 && data.error.name === 'TokenExpiredError') {
          logout()
        } else {
          setMessage(data.error.message)
        }
      } else {
        setMessage('An unknown error occurred')
      }
    } else {
      setMessage(error.message)
    }

    setShowErrorMessage(true)
  }

  const handleRefreshData = () => {

    if (currentUser?.role === 'recruiter') {
      setIsLoading(true)
      GetAllJobsRecruiter()
    } else {
      setIsLoading(true)
      GetAllJobs()
    }
    setRefreshData(true)
  }

  const toggleDropdownTotal = () => {
    setIsOpenTotal(!isOpenTotal)
  }

  const toggleDropdownGroup = () => {
    setIsOpenGroup(!isOpenGroup)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRefGrade.current && !dropdownRefGrade.current.contains(event.target)) {
        setIsOpenTotal(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenGroup(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  // Function to handle pagination
  const handlePageChange = (page) => {
    // setCurrentPage(page);
    let data = { ...params }
    data.page = page
    setParams(data)
  }
  const handleItemsPerPageChange = (event) => {
    // setItemsPerPage(event.target.value);
    let data = { ...params }
    data.limit = event.target.value
    setParams(data)
  }

  const resetFilters = async () => {

    let data = { ...params }
    data.job_status = 'closed'
    data.order_by = 'updated_at'
    data.direction = 'desc'
    data.page = 1
    data.limit = 10
    data.total = 0
    data.group_id = ''
    data.grade = ''

    setSelectedGrade('');
    setSelectedGroup('');
    setSelectedValueGroup([]);
    setSelectedValue([]);

    await setParams(data);
  }

  const filterChange = (filterValue, type) => {
    setIsOpenGroup(false);
    setIsOpenTotal(false);

    if (type === 'grade') {
      if (selectedGrade === filterValue) {
        setSelectedGrade('');
      } else {
        setSelectedGrade(filterValue);
      }
      setParams((prevParams) => ({
        ...prevParams,
        grade: selectedGrade === filterValue ? '' : filterValue
      }))
    } else {
      if (selectedGroup === filterValue) {
        setSelectedGroup('');
      } else {
        setSelectedGroup(filterValue);
      }
      setParams((prevParams) => ({
        ...prevParams,
        group_id: selectedGroup === filterValue ? '' : filterValue
      }))
    }
  }

  const openEmployeeCardPopup = (jobApplicationsId) => {
    if (jobApplicationsId !== null && jobApplicationsId !== undefined && jobApplicationsId.length > 0) {
      setCurrentJobAppId(jobApplicationsId[0])
      setShowEmployeeCard(true)
    }
  }

  return (
    <>
      <div
        className='d-flex flex-wrap mb-8'
        style={{ justifyContent: 'flex-end', }}
      >
        <div ref={dropdownRef}>
          <div className={`dropdown ${isOpenGroup ? 'open' : ''}`}>
            <div className='filter-btn fs-5 fw-bolder me-4 cursor-pointer' onClick={toggleDropdownGroup}>
              Filter by Group
              <img style={{ marginLeft: '4px' }} src={imagePath.ic_filter_orange} width={14} height={14} />
            </div>
            <div className='dropdown-menu' style={{ zIndex: '2' }}>
              {isOpenGroup && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '320px', maxHeight: '180px', overflowY: 'auto' }}>
                  {allGroups && allGroups.map((option, index) => {
                    const isChecked = selectedFilters.some((filter) => filter.type === 'group' && filter.value === option?.group_id)
                    console.log(`selectedGroup: ${selectedGroup} :: currentGroup: ${option?.group_id} `)
                    return (
                      <div key={index} className='d-flex hoverable-text mb-2 align-items-center ' style={{ whiteSpace: 'nowrap' }}>
                        <input
                          className='form-check-input me-2'
                          style={{ height: '16px', width: '16px' }}
                          type='checkbox'
                          value={option?.group_id}
                          // checked={selectedValueGroup.includes(option)}
                          // onChange={handleGroupChange} 
                          // checked={isChecked}
                          checked={selectedGroup === option?.group_id}
                          onChange={(event) => filterChange(option?.group_id, 'group')} />
                        <label className='form-check-label '>{option?.group_name}</label>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>

        <div ref={dropdownRefGrade}>
          <div className={`dropdown ${isOpenTotal ? 'open' : ''}`}>
            <div className='filter-btn fs-5 fw-bolder me-2 cursor-pointer' onClick={toggleDropdownTotal} style={{ marginRight: '2rem' }}>
              Filter by Grade
              <img style={{ marginLeft: '4px' }} src={imagePath.ic_filter_orange} width={14} height={14} />
            </div>
            <div className='dropdown-menu' style={{ zIndex: '2' }}>
              {isOpenTotal && (
                <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '180px', overflowY: 'auto' }}>
                  {allGrades && allGrades.map((option, index) => {
                    const isChecked = selectedFilters.some((filter) => filter.type === 'grade' && filter.value === option)
                    return (
                      <div key={option} className='d-flex hoverable-text mb-2 align-items-center ' style={{ whiteSpace: 'nowrap' }}>
                        <input
                          className='form-check-input me-2'
                          style={{ height: '16px', width: '16px' }}
                          type='checkbox'
                          value={option}
                          // checked={selectedValue.includes(option)}
                          // onChange={handleGradeChange} 
                          // checked={isChecked}
                          checked={selectedGrade === option}
                          onChange={(event) => filterChange(option, 'grade')}
                        />
                        <label className='form-check-label '>{option}</label>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <i
          className='bi bi-arrow-clockwise my-auto'
          style={{ fontSize: '28px', color: '#F36523', cursor: 'pointer' }}
          onClick={resetFilters}
        ></i>
      </div>
      {isLoading ? (
        <div className='text-center'>
          <FontAwesomeIcon
            icon={faSpinner}
            color='#F36523'
            spin
            size='5x'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              marginTop: '15rem',
            }}
          />
        </div>
      ) : (
        <div className='row g-6 g-xl-9'>
          {AllJobs ? AllJobs.map((jobs, index) =>
            isDropDownActive && filteredItemsGrade.length > 0 && !filteredItemsGrade.includes(jobs) ? null : isDropDownActives &&
              filteredItemsGroup.length > 0 &&
              !filteredItemsGroup.includes(jobs) ? null : (
              // (isDropDownActiveGrade && selectedValueGrade !== 'Active') && !filteredItemsGrade.includes(jobs) ? null : (

              <div key={index} className='col-md-6 mb-8 col-xl-4'>
                <div >
                  <div className='card-bg shadow-sm pt-4'>
                    <div className=' d-flex align-items-center px-4 mb-2'>
                      <div className='d-flex align-items-center '>
                        <img src={imagePath.ic_calendar_orange} alt='calendar' style={{ width: '20px', height: '20px', objectFit: 'cover' }} className='me-2' />

                        <label style={{ color: '#80808F' }} className='fs-7  fw-bold'>
                          {/* {jobs.valid_till.substring(0, 10)} */}
                          {getFormattedDate(jobs?.created_at)}
                        </label>
                      </div>
                      <div className='d-flex align-items-center ms-auto' >
                        {/* <Link to={'/updatejobs/' + jobs.job_id}>
                          {' '}
                          <img
                            className='cursor-pointer'
                            width={20} height={20}
                            src={imagePath.ic_edit_circle_orange}
                          />
                        </Link> */}

                        {jobs?.comments && (
                          <img
                            className='ms-2 cursor-pointer'
                            src={imagePath.ic_comment}
                            width={20} height={20}
                            title='Comments'
                            onClick={() => {
                              setCurrentReasons(jobs?.comments)
                              setShowReasonsPopup(true)
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        gap: '12px',
                      }}
                    >
                      <div>
                        <div className='d-flex flex-column px-4'>
                          <span className='card-label text-gray-800 fw-bolder fs-2 ' style={{ maxWidth: '23rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={jobs.title}>{jobs.title}</span>
                          <span className=' textry fw-bolder fs-6 '>{jobs.groupName}</span>
                        </div>

                        <div
                          className='mt-4 mx-4'
                        >
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className='orange-tag fw-bolder fs-7 text-gray-800fw-bold px-4 py-3 '
                          >
                            {`Test: ${jobs?.testName || 'Not Linked'}`}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className=' mt-6'>
                      <div className='d-flex flex-wrap mb-5 px-4'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-4 mb-3'>
                          <img className='mt-1' src={stackedimages}></img>

                          <div className='hoverfortheGoingtoJobFunneling   fw-bolder'>{jobs.totalApplied}</div>
                          <div style={{ color: '#80808F' }} className='fw-semibold '>
                            Total Candidates
                          </div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                          <img src={candidate}></img>
                          <div
                            className='  hoverfortheGoingtoJobFunneling fw-bolder'
                          >
                            {jobs.newCandidates}
                          </div>
                          <div style={{ color: '#80808F' }} className='fw-semibold '>
                            New Candidates
                          </div>
                        </div>
                      </div>

                      <div
                        className='d-flex px-4 align-items-center mb-3'
                      >
                        <div className='d-flex align-items-center min-w-80px me-4 '>
                          <img className='me-2' src={Location} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />

                          <label style={{ color: '#80808F' }} className='fw-semibold fs-6'>

                            {jobs?.location && typeof jobs?.location === 'string' ?
                              (jobs?.location.charAt(0).toUpperCase() + jobs?.location.slice(1).toLowerCase()) :
                              'Invalid Location'
                            }
                          </label>
                        </div>

                        <div className='d-flex align-items-center'>

                          <img className='me-2' src={star} style={{ width: '20px', height: '20px', objectFit: 'cover' }} />

                          <a style={{ color: '#80808F' }} className='fw-semibold fs-6'>
                            {jobs.grade}
                          </a>

                        </div>
                      </div>

                      <div className=' d-flex align-items-center px-4 py-3' >
                        <div className='d-flex align-items-center'>
                          <a style={{ color: '#80808F' }} className='fw-semibold fs-6 '>
                            Published &nbsp;
                          </a>

                          {/* <span onClick={() => createPopup(jobs.job_id)}>
                            {' '}
                            <img className='' src={toggleburron}></img>
                          </span> */}

                          <label className="toggle-switch-small">
                            <input type="checkbox" checked={true} onChange={() => {
                              // createPopup(jobs.job_id)
                            }} />
                            <span className="toggle-switch-small-slider"></span>
                          </label>
                        </div>

                        <div className='fs-6 fw-bolder ms-auto'>
                          {jobs?.expired ? (
                            <div
                              style={{
                                fontWeight: 'bold',
                                color: '#80808F',
                              }}
                            >
                              <p>Expired</p>
                            </div>
                          ) : (
                            // <Link
                            //   to={{
                            //     pathname: '/jobfunnelingmainscreen/' + jobs?.job_id,
                            //   }}
                            // >
                            //   <button className='shortlisting-btn'>View Candidates</button>
                            // </Link>
                            <div>
                              <button
                                className='hiring-send-eoffer-btn'
                                onClick={() => {
                                  openEmployeeCardPopup(jobs?.jobApplicationId)

                                }}
                              >
                                Generate Employee Card
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {popup}
                  {popup2}
                </div>
              </div>
            )
          ) : (<div>No Records Found</div>)}
          <div className='d-flex col-md-12 align-items-center'>
            <div className='col-md-4'>
              <label>{`Showing ${AllJobs ? AllJobs.length : 0} of ${params.total} results.`}</label>
            </div>
            <div className='mx-auto col-md-4' style={{ textAlign: 'center' }}>
              <CustomPagination
                // totalPages={Math.ceil(allUsers.length / itemsPerPage)}
                // pageValue={currentPage}
                pageValue={params.page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>

            <div className='col-md-4 '>
              <div className='ms-auto d-flex flex-end align-items-center'>
                <select
                  className='me-2 pagination-input'
                  style={{ backgroundColor: '#FFFFFF', borderRadius: '2px' }}
                  id='selectValues'
                  // value={itemsPerPage}
                  value={params.limit}
                  onChange={handleItemsPerPageChange}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                </select>

                <div className='me-2'>
                  <label>Items per page</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showReasonsPopup && (
        <ReasonsPopup
          show={true}
          reasons={currentReasons}
          handleClose={() => {
            setCurrentReasons('')
            setShowReasonsPopup(false)
          }}
        />
      )}
      {showEmployeeCard && (
        <EmployeeCardPopUp
          show={true}
          jobAppId={currentJobAppId}
          handleClose={() => {
            setShowEmployeeCard(false)
          }}
        />
      )}
      {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
    </>
  )
}

export { CardClosed }
