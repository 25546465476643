//import liraries
import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuth } from '../../auth/core/Auth';
import ShowError from '../../../../_metronic/partials/content/toasts/ShowError';
import imagePath from '../../../../constants/imagePath';
import './reports.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


const API_URL = process.env.REACT_APP_API_URL
const MONTHLY_REPORT_URL = `${API_URL}/position/monthly-report`;

// create a component
const MonthlyAchievementTable = () => {

    const currentYear = new Date().getFullYear();
    const { currentUser, auth, logout } = useAuth();
    const [year, setYear] = useState(currentYear);
    const [quarter, setQuarter] = useState(0);
    const [quarterlyReport, setQuarterlyReport] = useState<any>({});
    const [headers, setHeaders] = useState<any>();
    const [quarterlyReportURL, setQuarterlyReportURL] = useState<any>('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (() => {
            getMonthlyReport();
        })();
    }, [year]);

    const getMonthlyReport = async () => {
        setLoading(true);
        await axios.get(MONTHLY_REPORT_URL, {
            params: {
                year: year
            },
            headers: {
                Authorization: `Bearer ${auth?.token}`,
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            setLoading(false);
            const data = res?.data;
            if (data?.reportUrl) {
                setQuarterlyReportURL(data?.reportUrl);
                setQuarterlyReport(data?.analytics);
                const headers = data?.analytics?.headers
                setHeaders(headers);
            }


        }).catch((error) => {
            setLoading(false);
            if (error.response && error.response.status === 400) {
                // showToast(error.response.data.message)
                setMessage(error.response.data.message);
                setShowErrorMessage(true);
            } else if (error.response && error.response.status === 401 && error.response.data && error.response.data.name === 'TokenExpiredError') {
                logout();
            } else if (error.response && error.response.data && error.response.data.error) {
                if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                    logout();
                } else {
                    setMessage(error.response.data.error.message);
                    setShowErrorMessage(true);
                }
            } else {
                setMessage(error.message);
                setShowErrorMessage(true);
            }
        });
    }

    const handleChangeYear = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && parseInt(value) <= currentYear) {
            setYear(value);
        }
    }

    const handleDownloadFile = (url) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.blob();
            })
            .then((blob) => {
                // Create a link element, use it to download the blob, and remove it
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = url.split('/').pop(); // Extracts file name from URL
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.error('Error downloading the file:', error);
            });
    };

    return (
        <div style={{ position: 'relative' }}>
            {loading && (
                // Show loading icon if isLoading state is true
                <div
                    className='position-absolute w-100 h-100 d-flex justify-content-center align-items-center'
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', top: 0, left: 0, zIndex: 999 }}
                >
                    <FontAwesomeIcon icon={faSpinner} color='#F36523' spin size='4x' />
                </div>
            )}
            <div className='card-bg shadow-sm mb-10' >
                <div className='d-flex align-items-center px-6 py-8'>
                    <label className='fs-3 fw-bolder me-4' style={{ maxWidth: '18rem' }}>Recruitment KPI Monthly Achievement Chart - {currentYear}</label>
                    {/* quarter view */}
                    <div className='d-flex align-items-center bg-white'>
                        <label className='fs-5 fw-semibold cursor-pointer py-2 px-2 text-center' style={{ backgroundColor: quarter === 0 ? '#FD853A' : '#F36523', color: 'white', width: '6rem', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px', marginRight: '0.1rem' }}
                            onClick={() => setQuarter(0)}>
                            Full Year
                        </label>
                        <label className='fs-5 fw-semibold cursor-pointer py-2 px-2 text-center ' style={{ backgroundColor: quarter === 1 ? '#FD853A' : '#F36523', color: 'white', width: '6rem', marginRight: '0.1rem' }}
                            onClick={() => setQuarter(1)}>
                            Q1
                        </label>
                        <label className='fs-5 fw-semibold cursor-pointer py-2 px-2 text-center ' style={{ backgroundColor: quarter === 2 ? '#FD853A' : '#F36523', color: 'white', width: '6rem', marginRight: '0.1rem' }}
                            onClick={() => setQuarter(2)}>
                            Q2
                        </label>
                        <label className='fs-5 fw-semibold cursor-pointer py-2 px-2 text-center ' style={{ backgroundColor: quarter === 3 ? '#FD853A' : '#F36523', color: 'white', width: '6rem', marginRight: '0.1rem' }}
                            onClick={() => setQuarter(3)}>
                            Q3
                        </label>
                        <label className='fs-5 fw-semibold cursor-pointer py-2 px-2 text-center ' style={{ backgroundColor: quarter === 4 ? '#FD853A' : '#F36523', color: 'white', width: '6rem', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}
                            onClick={() => setQuarter(4)}>
                            Q4
                        </label>
                    </div>
                    {/* year view */}
                    <div className='d-flex align-items-center mx-4'>

                        <input
                            className='py-2 px-2 fs-5 fw-semibold'
                            style={{ border: 'solid 1px #F36523', borderRadius: '6px', color: '#F36523', width: '8rem' }}
                            type='number'
                            inputMode='numeric'
                            placeholder='2024'
                            value={year}
                            max={currentYear}
                            min={2023}
                            onChange={(event) => handleChangeYear(event)}
                        />
                    </div>
                    {/* download report view */}
                    <div className='d-flex py-2 px-2' style={{ border: 'solid 1px #F36523', borderRadius: '6px' }}>
                        <div className='d-flex align-items-center' style={{ cursor: 'pointer' }} onClick={() => handleDownloadFile(quarterlyReportURL)}>
                            <label className='fs-5 fw-semibold pe-4 cursor-pointer' style={{ color: '#F36523', whiteSpace: 'nowrap' }}>Download Report</label>
                            <img className='cursor-pointer' src={imagePath.ic_download} width={24} height={24} />
                        </div>
                    </div>
                    <div>
                        <img className='cursor-pointer ms-4' src={imagePath.ic_refresh_orange} width={34} height={34}
                            onClick={() => { getMonthlyReport() }} />
                    </div>
                </div>
                {/* table view */}
                <div >
                    {/* <div className='table-responsive'>
                        <div className="table-wrapper" style={{ overflowX: 'auto', position: 'relative' }}>
                            <table className='table align-middle table-borders'>
                                <thead>
                                    <tr className='fw-bold graycolor bg-light'>
                                        {headers && headers.map((header, headerIndex) => {
                                            // if quarter is 0 show all columns else show
                                            if (headerIndex === 0) {
                                                return (
                                                    <th key={headerIndex} className='ps-6 fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', position: 'sticky', left: 0, background: '#F5F8FA', zIndex: 1 }}>{header}</th>
                                                )
                                            } else {
                                                if (quarter === 0) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center', paddingRight: headerIndex === headers?.length - 1 ? '18px' : '0px' }}>{header}</th>
                                                    )
                                                } else if (quarter === 1 && headerIndex < 5) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    )
                                                } else if (quarter === 2 && headerIndex >= 5 && headerIndex < 9) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    )
                                                } else if (quarter === 3 && headerIndex >= 9 && headerIndex < 13) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    )
                                                } else if (quarter === 4 && headerIndex >= 13 && headerIndex < 17) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    )
                                                } else if (headerIndex === 17) {
                                                    return (
                                                        <th key={headerIndex} className='pe-6 fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    )
                                                }
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {quarterlyReport && Object.keys(quarterlyReport).map((key, keyIndex) => {
                                        if (key !== 'headers') {
                                            let list = quarterlyReport[key];
                                            return (
                                                <tr key={keyIndex} style={{ borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                    {list && list.map((data, listIndex) => {
                                                        let modValue = listIndex % 4;
                                                        if (listIndex === 0) {
                                                            return (
                                                                <td key={listIndex} className='ps-6 fw-bolder' style={{ color: '#475467', borderRight: '1px solid #EDF2F9', height: '4rem', position: 'sticky', left: 0, background: 'white', zIndex: 1 }}>
                                                                    {data}
                                                                </td>
                                                            )
                                                        } else {
                                                            if (quarter === 0) {
                                                                return (
                                                                    <td key={listIndex} style={{ paddingRight: listIndex === list?.length - 1 ? '18px' : '0px', textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            } else if (quarter === 1 && listIndex < 5) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            } else if (quarter === 2 && listIndex >= 5 && listIndex < 9) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            } else if (quarter === 3 && listIndex >= 9 && listIndex < 13) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            } else if (quarter === 4 && listIndex >= 13 && listIndex < 17) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            } else if (listIndex === 17) {
                                                                return (
                                                                    <td key={listIndex} className='pe-6' style={{ borderLeft: '1px solid #EDF2F9', textAlign: 'center' }}>
                                                                        {data}
                                                                    </td>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div> */}

                    <div className='table-responsive'>
                        <div className='scrollable-table-container'>
                            <table className='table align-middle table-borders'>
                                <thead>
                                    <tr className='fw-bold graycolor bg-light'>
                                        {headers && headers.map((header, headerIndex) => {
                                            if (headerIndex === 0) {
                                                return (
                                                    <th key={headerIndex} className='ps-6 fw-bolder sticky-header' style={{ color: '#475467', whiteSpace: 'nowrap', background: '#F5F8FA', zIndex: 2 }}>{header}</th>
                                                );
                                            } else {
                                                if (quarter === 0) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder sticky-header' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center', paddingRight: headerIndex === headers?.length - 1 ? '18px' : '0px', background: '#F5F8FA', zIndex: 1 }}>{header}</th>
                                                    );
                                                } else if (quarter === 1 && headerIndex < 5) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    );
                                                } else if (quarter === 2 && headerIndex >= 5 && headerIndex < 9) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    );
                                                } else if (quarter === 3 && headerIndex >= 9 && headerIndex < 13) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    );
                                                } else if (quarter === 4 && headerIndex >= 13 && headerIndex < 17) {
                                                    return (
                                                        <th key={headerIndex} className='fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    );
                                                } else if (headerIndex === 17) {
                                                    return (
                                                        <th key={headerIndex} className='pe-6 fw-bolder' style={{ color: '#475467', whiteSpace: 'nowrap', textAlign: 'center' }}>{header}</th>
                                                    );
                                                }
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {quarterlyReport && Object.keys(quarterlyReport).map((key, keyIndex) => {
                                        if (key !== 'headers') {
                                            let list = quarterlyReport[key];
                                            const isEvenRow = keyIndex % 2 === 0
                                            return (
                                                <tr key={keyIndex} style={{backgroundColor: isEvenRow ? 'white' : '#FCFCFC', borderBottom: '1px solid #EDF2F9' }} className='mx-2'>
                                                    {list && list.map((data, listIndex) => {
                                                        let modValue = listIndex % 4;
                                                        if (listIndex === 0) {
                                                            return (
                                                                <td key={listIndex} className='ps-6 fw-bolder sticky-column' style={{ color: '#475467', borderRight: '1px solid #EDF2F9', height: '4rem', background: isEvenRow ? 'white' : '#FCFCFC', zIndex: 1 }}>
                                                                    {data}
                                                                </td>
                                                            );
                                                        } else {
                                                            if (quarter === 0) {
                                                                return (
                                                                    <td key={listIndex} style={{ paddingRight: listIndex === list?.length - 1 ? '18px' : '0px', textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            } else if (quarter === 1 && listIndex < 5) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            } else if (quarter === 2 && listIndex >= 5 && listIndex < 9) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            } else if (quarter === 3 && listIndex >= 9 && listIndex < 13) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            } else if (quarter === 4 && listIndex >= 13 && listIndex < 17) {
                                                                return (
                                                                    <td key={listIndex} style={{ textAlign: 'center', borderRight: modValue === 0 ? '1px solid #EDF2F9' : 'none', borderLeft: modValue === 0 ? '1px solid #EDF2F9' : 'none' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            } else if (listIndex === 17) {
                                                                return (
                                                                    <td key={listIndex} className='pe-6' style={{ borderLeft: '1px solid #EDF2F9', textAlign: 'center' }}>
                                                                        {data}
                                                                    </td>
                                                                );
                                                            }
                                                        }
                                                    })}
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            {showErrorMessage && (
                <ShowError
                    handleClose={() => setShowErrorMessage(false)}
                    message={message}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default MonthlyAchievementTable;
