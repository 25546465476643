/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './CreateCompetency.css'
import {useState} from 'react'
import addcompetenctimage from '../images/information (3)@2x.png'
// import {AuthModel, UserModel} from '../../../../app/modules/auth/core/_models'
// import {useAuth} from '../../../../app/modules/auth/core/Auth'

// import plussquarebutton from '../images/Group 9142.png'
import Layout from '../images/Layout-4-blocks (3).png'
import cross from '../images/Layout-4-blocks.png'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const POCpopupModel = ({show, handleClose}: Props) => {
  // useEffect(() => {
  //   await axios.get
  // })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className=' no-header-model123'>
        <div className='modal-header noheader '>
          <h2></h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <img src={Layout}></img>
            {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
            {/* <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>{' '} */}
          </div>
        </div>
        {/* end::Close */}
        <div className='mx-auto d-flex flex-column '>
          <img alt='info_icon' className='addcompetenctimage' style={{objectFit:'contain', width:'60px', height:'60px'}} src={addcompetenctimage}></img>
          <h3 className='fw-bolder mx-auto my-5'>POC</h3>
        </div>
      </div>

      <div className='modal-body py-lg-8 px-lg-8'>
        <div className='card'>
          {/* begin::Beader */}

          <div className='card-body pb-0 mb-0 mt-0 pt-0 flex-row'>
            <form
            //  onSubmit={handleSubmit}
            >
              <div className='form-row d-flex flex-column  mt-2 justify-content-spacearound'>
                <label
                  style={{color: '#80808F', textAlign: 'center'}}
                  className='form-label mx-auto fs-5 fw-semibold '
                >
                  Every department has a POC, POC is the person who fills out Vacant Position forms
                  for their specific department{' '}
                </label>
              </div>
              <div className='sureunsure'>
                <button onClick={handleClose} className='btn my-7 px-5 bgcolorofbutoonofsubmit '>
                  Got it!{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {POCpopupModel}
