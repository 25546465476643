/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
// import {KTSVG} from '../../../helpers'
import './CreateCompetency.css'
import addcompetenctimage from '../../images/971 [Converted].png'
// import {AuthModel, UserModel} from '../../../../app/modules/auth/core/_models'
import bin from '../../content/images/bin.png'
import plussquarebutton from '../images/Group 9142.png'
import imageforrotate from '../images/rotate@2x.png'
import cross from '../images/Layout-4-blocks.png'
import level1image from '../../../../app/modules/Setting/images/level1.png'
import level2image from '../../../../app/modules/Setting/images/level2.png'
import level3image from '../../../../app/modules/Setting/images/level3.png'
import level4image from '../../../../app/modules/Setting/images/level4.png'
import level5image from '../../../../app/modules/Setting/images/level5.png'

import axios from 'axios'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import LoaderView from '../JobFunnelsModels/LoaderView'
import imagePath from '../../../../constants/imagePath'
import ShowError from '../../content/toasts/ShowError'
// import {useState} from 'react'
import {type} from 'os'

const API_URL = process.env.REACT_APP_API_URL

export const GET_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/get`
export const CREATE_ALL_INDIVIDUAL_COMPETENCY_URL = `${API_URL}/competency/create/`
// export const GET_CREATE_LEVEL = `${API_URL}/level/get`
const GET_CREATED_LEVELS_URL = `${API_URL}/level/get-level`

type Props = {
  show: boolean
  handleClose: () => void
  updateCompetencies: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateIndiviualComtency = ({show, handleClose, updateCompetencies}: Props) => {
  const {auth} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const [level, setlevel] = useState(3)
  const [levelsData, setLevelsData] = useState([
    {
      level: '',
      behaviors: [
        {
          id: 0,
          name: '',
        },
      ],
    },
  ])
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const allValid = (name, description, level, behaviors) => {
    if (name === null || name === undefined || name === '') {
      setMessage('Name of competency is invalid.')
      setShowErrorMessage(true)
      return false
    }

    if (description === null || description === undefined || description === '') {
      setMessage('Description of competency is invalid.')
      setShowErrorMessage(true)
      return false
    }

    if (level === null || level === undefined || level === 0 || level === '' || level === '0') {
      setMessage('Please set the levels for the competency model first and try again.')
      setShowErrorMessage(true)
      return false
    }
    if (behaviors === null || behaviors === undefined || behaviors.length === 0) {
      setMessage('Behaviors can not be empty!')
      setShowErrorMessage(true)
      return false
    }

    return true
  }

  useEffect(() => {
    ;(async () => {
      await axios
        .get(GET_CREATED_LEVELS_URL)
        .then((resp) => {
          setlevel(resp.data.level.level_no)
          const data = JSON.parse(resp.data.level.level_data)
          let fields: any = []
          data.map((item, index) => {
            let newField = {
              level: Object.keys(item)[0],
              behaviors: [{id: 0, name: ''}],
            }
            fields.push(newField)
          })
          setLevelsData(fields)
        })
        .catch((error) => {
          // handle the error
          console.log('ERROR', error)
        })
    })()
  }, [level])

  var totalCompetencies
  const handleSubmit = async (e) => {
    e.preventDefault()
    const {competencyName, competencyDescription} = e.target.elements
    await axios.get(GET_ALL_INDIVIDUAL_COMPETENCY_URL).then((resp) => {
      totalCompetencies = resp.data.competencies
    })

    const behaviors: {[key: string]: string}[] = [] // Changed to array instead of any

    for (let i = 0; i < levelsData.length; i++) {
      let behaviorsForLevel = levelsData[i].behaviors // Renamed to avoid naming conflict
      let obj = {}
      for (let j = 0; j < behaviorsForLevel.length; j++) {
        obj[j] = behaviorsForLevel[j].name
      }
      let obj2 = {}
      obj2['level_' + (i + 1)] = obj
      behaviors.push(obj2)
    }

    const name = competencyName.value
    const description = competencyDescription.value
    const total_competencies = totalCompetencies.length
    // const behaviors = levelsData
    const total_behaviors = behaviors.length
    const type = 'Individual'
    const icon = 'abc'

    if (!allValid(name, description, level, behaviors)) {
      return
    }
    setLoading(true)
    const CompetencyData = {
      name,
      description,
      total_competencies,
      total_behaviors,
      level,
      type,
      competencies: {},
      behaviors,
      icon,
    }

    var data = JSON.stringify(CompetencyData)
    console.log(data)

    const url = CREATE_ALL_INDIVIDUAL_COMPETENCY_URL
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setLoading(false)
        updateCompetencies()
        handleClose()
      })
      .catch((err) => {
        setLoading(false)
        console.log('err', err)
      })
  }

  const handleFormChange = (mainIndex, index, event) => {
    let arr: any = [...levelsData]
    let obj: any = arr[mainIndex].behaviors[index]
    obj['name'] = event.target.value

    arr[mainIndex].behaviors[index] = obj
    setLevelsData(arr)
  }

  const addFields = (index) => {
    if (levelsData[index].behaviors.length < 7) {
      let arr: any = [...levelsData]
      let behaviors = arr[index].behaviors
      let newField = {id: behaviors.length, name: ''}
      behaviors.push(newField)
      arr[index].behaviors = behaviors
      setLevelsData(arr)
    }
  }
  const removeButton = (index, mainIndex) => {
    let data = [...levelsData]
    let behaviors = data[mainIndex].behaviors
    behaviors.splice(index, 1)
    data[mainIndex].behaviors = behaviors
    setLevelsData(data)
  }

  const getImage = (index) => {
    switch (index) {
      case 0: {
        return imagePath.ic_level_1
      }
      case 1: {
        return imagePath.ic_level_2
      }
      case 2: {
        return imagePath.ic_level_3
      }
      case 3: {
        return imagePath.ic_level_4
      }
      case 4: {
        return imagePath.ic_level_5
      }
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      backdrop={true}
    >
      <>
        <div className=' create-competency-header '>
          <div className='modal-header  create-competency-header'>
            <h2></h2>
            {/* begin::Close */}
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              {/* <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' /> */}
              <img alt='' src={imagePath.ic_cross_white} />
            </div>
          </div>
          {/* end::Close */}
          <div className='mx-auto d-flex flex-column no-header-model'>
            <img alt='' className='addcompetenctimage' src={addcompetenctimage}></img>
            <h3 className='fw-bolder text-white my-5'>Create Competency </h3>
          </div>
        </div>

        <div>
          <div>
            {/* begin::Beader */}

            <div className='p-8'>
              <form className='d-flex flex-column justify-content-center' onSubmit={handleSubmit}>
                <div className='form-row d-flex flex-column justify-content-spacearound'>
                  <div className='form-group col-md-12'>
                    <label htmlFor='competencyName' className='form-label fs-6 fw-bolder'>
                      Name
                    </label>
                    <input
                      style={{height: '56px'}}
                      type='text'
                      className='form-control bg-white'
                      id='competencyName'
                      placeholder='Communication'
                      autoComplete='off'
                      required
                    />
                  </div>
                  <div className='form-group mt-5 col-md-12'>
                    <label htmlFor='competencyDescription' className='form-label fs-6 fw-bolder'>
                      Description
                    </label>
                    <textarea
                      style={{height: 'auto', maxHeight: '140px', resize: 'vertical'}}
                      className='form-control bg-white'
                      id='competencyDescription'
                      placeholder='Type Competency (e.g: Data Analyst)'
                      autoComplete='off'
                      rows={5}
                      required
                    />
                  </div>
                  <div className='mt-7' style={{width: '101%', marginLeft: '-8px'}}>
                    <div className='line'></div>
                  </div>
                  <label className='form-label my-7 fs-4 fw-bolder'>Levels</label>
                  {levelsData.map((item, mainIndex) => {
                    const level = item.level
                    const behaviors = item.behaviors
                    return (
                      <div className='form-group col-md-12' key={mainIndex}>
                        <div>
                          <div className='d-flex align-items-center mb-4'>
                            <img alt='' src={getImage(mainIndex)} width={'48px'} />
                            <label className='form-label fs-6 fw-bolder ms-2'>{level}</label>
                          </div>
                          <div className='d-flex align-items-center'>
                            <div className='my-3 d-flex align-items-center'>
                              <img alt='' src={imageforrotate} />
                              <label className='fs-6 fw-bolder ms-2'>{`Add Behaviors for ${level} Level`}</label>
                            </div>
                            <img
                              onClick={() => addFields(mainIndex)}
                              className='ms-auto cursor-pointer'
                              alt='add'
                              src={imagePath.ic_add_orange_border}
                            />
                          </div>
                          {behaviors?.map((input, index) => {
                            return (
                              <div key={index}>
                                <div className='binbeaviour'>
                                  <label className='form-label fs-6 fw-bolder mt-2'>{`Behavior ${index + 1}`}</label>
                                  {index !== 0 && (
                                    <img
                                      style={{cursor: 'pointer'}}
                                      onClick={() => removeButton(index, mainIndex)}
                                      width={18}
                                      height={18}
                                      src={imagePath.ic_delete_orange_cross}
                                    />
                                  )}
                                </div>
                                <input
                                  style={{height: '56px'}}
                                  type='text'
                                  className=' mb-3 form-control bg-white'
                                  id={`behaviour${index}`}
                                  placeholder='Type here'
                                  autoComplete='off'
                                  onChange={(event) => handleFormChange(mainIndex, index, event)}
                                  required
                                />
                              </div>
                            )
                          })}
                        </div>
                        <div>
                          <hr className='mt-10 rlineer'></hr>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <button type='submit' className='candi-btn2 btn text-white fw-bolder my-10 mx-auto' style={{textAlign: 'center'}}>
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>

        {loading && <LoaderView message='Loading...' />}
        {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
      </>
    </Modal>,
    modalsRoot
  )
}

export {CreateIndiviualComtency}
