//import liraries
import React, {useState} from 'react'
import axios from 'axios'

import './Applications.css'
import '../../../../_metronic/css/bootstrap-glyphicon.min.css'

import {useAuth} from '../../auth/core/Auth'
import ActiveApplications from './ApplicationPages/ActiveApplications'
import CloseApplications from './ApplicationPages/CloseApplications'
import {Link} from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL
export const VACANCIES_API_URL = `${API_URL}/job/show-actives`
export const GET_BY_JOB_ID = `${API_URL}/job/`

// create a component
const ApplicationsTab = (props) => {
  const {currentUser} = useAuth()
  const user_id = currentUser?.user_id

  const [currComp, updateCurrComp] = useState(1)

  const [IndiviualTab, setIndiviualTab] = useState(true)
  const [pageRefreshedAt, setPageRefreshedAt] = useState('')
  // const {CompetenciesButton, setCompetenciesButton} = useCompetenciesButtonContext()

  const ChangeInActiveIndex = (ActiveState: string) => {
    console.log('first')
    if (ActiveState === 'ActiveButtonClicked') {
      // setCompetenciesButton('indiviualCompetency')
      setIndiviualTab(true)
    } else if (ActiveState == 'InActiveButtonClicked') {
      setIndiviualTab(false)
      // setCompetenciesButton('groupCompetency')
    }
  }

  function updateState(cur) {
    console.log('updatingCurrComp: ', cur)
    props.update(cur)
  }
  function selectTest(testId) {
    console.log('updatingCurrComp: testId:', testId)
    props.setTest(testId)
  }
  function setJobId(jobId) {
    props.setJobId(jobId)
  }
  function setJob(job) {
    props.setJob(job)
  }

  const changeLastUpdatedAt = () => {
    const currentDate = new Date()
    const month = currentDate.toLocaleString('default', {month: 'short'})
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    const minute = currentDate.getMinutes()
    const formattedDateTime = `Last Updated at: ${hour}:${minute.toString().padStart(2, '0')} - ${month}, ${day}`
    setPageRefreshedAt(formattedDateTime)
  }

  return (
    <div>
      <div className='dashboard-top'>
        <h1>Dashboard</h1>
        <div className='d-flex align-items-center'>
          <h3>
            <i className='bi bi-house text-white' style={{fontSize: '1.5rem'}}></i>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/candidatedashboard' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              My Dashboard
            </Link>
            <i className='bi bi-dot text-white' style={{fontSize: '1rem'}}></i>
            <Link to='/candidateapplications' style={{textDecoration: 'none', color: 'white', cursor: 'pointer'}}>
              Applications
            </Link>
          </h3>
          <h3 className='ms-auto'>
            <i className='bi bi-arrow-clockwise text-white'></i> {pageRefreshedAt}
          </h3>
        </div>
        {/* <div className='dashboard-top-buttons'> */}
        <div className=' d-flex justify-content-center gy-5 gx-xl-8 mt-12'>
          <button
            className={`${IndiviualTab === true ? ' Onclicked-Active-Button  btn-bg-white ' : ' Notclicked-Active-Button '} 
                            btn fw-bolder Activejobs-Leftbutton fs-4  custom-button-size btn-lg`}
            type='button'
            onClick={() => ChangeInActiveIndex('ActiveButtonClicked')}
          >
            Active
          </button>
          <button
            type='button'
            onClick={() => ChangeInActiveIndex('InActiveButtonClicked')}
            className={`${IndiviualTab === true ? 'notclicked-close-button' : 'onclicked-close-button  btn-bg-white'} 
                            btn fw-bolder Activejobs-Rightbutton fs-4  custom-button-size btn-lg`}
          >
            Close
          </button>
        </div>
        {IndiviualTab === true ? (
          <>
            <ActiveApplications
              update={updateState}
              updateTest={selectTest}
              updateJobId={setJobId}
              updateJob={setJob}
              gotoClosedTab={ChangeInActiveIndex}
              updatedAt={changeLastUpdatedAt}
            />
          </>
        ) : (
          <>
            <CloseApplications updatedAt={changeLastUpdatedAt} />
          </>
        )}
      </div>
    </div>
  )
}

//make this component available to the app
export default ApplicationsTab
