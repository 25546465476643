import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {createContext, useState} from 'react'

import {JobsPageContext} from '../context/CreateJobsContext'
import {JobsButtonContext} from '../context/CreateJobsButtonContext'
import {CompetenciesButtonContext} from '../context/CreateCompetenciesButton'
import {GenReportButtonContext} from '../context/CreateGenerateReportContext'
import {LabelInsightsContext} from '../context/CreateContextforInsightlabels'
import { TestQuestionDataProvider} from '../context/TestQuestionDataContext';
// import {useContextForCreateTestButton} from '../context/useContextForCreateTestButton'
import {TestProvider} from '../context/TestContext'

export const LoginContext = createContext({loggedIn: false, setLoggedIn: (loggedIn: false) => {}})

const App = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(true)
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LoginContext.Provider value={{loggedIn: loggedIn, setLoggedIn}}>
        <CompetenciesButtonContext>
          <LabelInsightsContext>
            <GenReportButtonContext>
              <TestProvider>
                <JobsButtonContext>
                  <JobsPageContext>
                    <TestQuestionDataProvider>
                    {/* <SetJobsContext.Provider value={{jobState, setjobState}}> */}
                    <I18nProvider>
                      <LayoutProvider>
                        <AuthInit>
                          <Outlet />
                          <MasterInit />
                        </AuthInit>
                      </LayoutProvider>
                    </I18nProvider>
                    {/* </SetJobsContext.Provider> */}
                    </TestQuestionDataProvider>
                  </JobsPageContext>
                </JobsButtonContext>
              </TestProvider>
            </GenReportButtonContext>
          </LabelInsightsContext>
        </CompetenciesButtonContext>
      </LoginContext.Provider>
    </Suspense>
  )
}

export {App}
