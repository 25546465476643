import {Route, Routes} from 'react-router-dom'
import AddInterviewScoreExternal from '../modules/JobsFunneling/AddInterviewScoreExternal'
import RequestforApprovalsExternal from '../modules/JobsFunneling/RequestForApprovalsExternal/RequestforApprovalsExternal'
import InterviewScoringExternal from '../modules/JobsFunneling/InterviewScoringExternal'
import DummyCharts from '../modules/analytics/DummyCharts'
import ActionStatus from '../modules/Setting/ExternalPages/ActionStatus'

const PublicRoutes = () => (
  <Routes>
    {/* <Route path='/addInterviewscoreExternal' element={<AddInterviewScoreExternal />} /> */}
    <Route path='/addInterviewscoreExternal' element={<InterviewScoringExternal />} />
    <Route path='/requestforapprovals' element={<RequestforApprovalsExternal />} />
    <Route path='/charts' element={<DummyCharts />} />
    <Route path='/approval/*' element={<ActionStatus />} />
  </Routes>
)

export {PublicRoutes}
