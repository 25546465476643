//import liraries
import React, { useEffect, useState } from 'react';
import imagePath from '../../../constants/imagePath';
import axios from 'axios';
import LoaderView from '../../../_metronic/partials/modals/JobFunnelsModels/LoaderView';
import ShowError from '../../../_metronic/partials/content/toasts/ShowError';
import { useAuth } from '../auth/core/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import { getFormattedDate } from '../../../constants/CommonFunctions';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import RequisitionRejectionPopup from '../../../_metronic/partials/modals/JobFunnelsModels/RequisitionRejectionPopup';
import SuccessDialog from '../../../_metronic/partials/modals/JobFunnelsModels/SuccessDialog';
import AdvertisementRejectionCommentsPopup from '../../../_metronic/partials/modals/JobFunnelsModels/AdvertisementRejectionCommentsPopup';


const API_URL = process.env.REACT_APP_API_URL
const GET_JOB_DATA_URL = `${API_URL}/job/`
const GET_POSITION_DATA_URL = `${API_URL}/position/`
const GET_POSITIONS_BY_ID = `${API_URL}/job/change-status`

// create a component
const AdvertisementApproval = () => {
    const { currentUser, auth, logout } = useAuth()
    let { user_id, job_id, approver } = useParams();
    const navigate = useNavigate()

    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showReasonPopup, setShowReasonPopup] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [rejectionObj, setRejectionObj] = useState<any>();
    const [showCommentsPopup, setShowCommentsPopup] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [position, setPosition] = useState<any>();
    const [advertisementModes, setAdvertisementModes] = useState<any>();

    const [approvals, setApprovals] = useState<any>([]);
    const [currentStatus, setCurrentStatus] = useState('')
    const [allComments, setAllComments] = useState<any>();

    const displayError = (error) => {
        if (error.response && error.response.status === 400) {
            // showToast(error.response.data.message)
            setMessage(error.response.data.message)
            setShowErrorMessage(true)
        } else if (
            error.response &&
            error.response.status === 401 &&
            error.response.data &&
            error.response.data.name === 'TokenExpiredError'
        ) {
            logout()
        } else if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.error.status === 401 && error.response.data.error.name === 'TokenExpiredError') {
                logout()
            } else {
                setMessage(error.response.data.error.message)
                setShowErrorMessage(true)
            }
        } else {
            if (error.response.status === 401) {
                setMessage('User not authenticated, Please login and try again')
                setShowErrorMessage(true)
                setTimeout(() => {
                    navigate('/auth');
                }, 3000); // 3000 milliseconds = 3 seconds
            } else {
                setMessage(error.message)
                setShowErrorMessage(true)
            }
        }
    }

    useEffect(() => {
        (() => {
            getJobData();
        })();
    }, [])

    const getJobData = () => {
        setLoading(true);
        axios.get(GET_JOB_DATA_URL + job_id).then((res) => {
            let data = res.data;
            getPositionData(data?.position_id);
            setCurrentStatus(data?.approval_status);
            setAllComments(data?.comments)
            let taLead = data?.ta;
            let taHead = data?.taHead;
            let approvalsData = data?.approvals;
            let approvingAuthorities: any = [];
            let taLeadObj = {
                title: 'TA Lead',
                user: taLead
            };
            let taHeadObj = {
                title: 'TA Head',
                user: taHead
            }

            approvingAuthorities.push(taLeadObj);
            approvingAuthorities.push(taHeadObj);
            if (Array.isArray(approvalsData) && approvalsData.length > 0) {
                approvalsData.forEach(approval => {
                    approvingAuthorities.push(approval);
                });
            }
            // Add status to each object in approvingAuthorities based on currentStatus
            switch (data?.approval_status) {
                case 'APPROVED':
                    approvingAuthorities = approvingAuthorities.map(approval => ({
                        ...approval,
                        status: 'approved'
                    }));
                    break;
                case 'PENDING_TA_APPROVAL':
                    approvingAuthorities = approvingAuthorities.map(approval => ({
                        ...approval,
                        status: 'pending'
                    }));
                    break;
                case 'PENDING_TA_HEAD_APPROVAL':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 0 ? 'approved' : 'pending'
                    }));
                    break;
                case 'PENDING_1_APPROVAL':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index <= 1 ? 'approved' : 'pending'
                    }));
                    break;
                case 'PENDING_2_APPROVAL':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index <= 2 ? 'approved' : 'pending'
                    }));
                    break;
                case 'PENDING_3_APPROVAL':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index <= 3 ? 'approved' : 'pending'
                    }));
                    break;
                case 'TA_APPROVAL_REJECTED':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 0 ? 'rejected' : 'pending'
                    }));
                    break;
                case 'TA_HEAD_APPROVAL_REJECTED':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 1 ? 'rejected' : (index > 1 ? 'pending' : 'approved')
                    }));
                    break;
                case 'FIRST_APPROVAL_REJECTED':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 2 ? 'rejected' : (index > 1 ? 'pending' : 'approved')
                    }));
                    break;
                case 'SECOND_APPROVAL_REJECTED':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 3 ? 'rejected' : (index > 1 ? 'pending' : 'approved')
                    }));
                    break;
                case 'THIRD_APPROVAL_REJECTED':
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index === 4 ? 'rejected' : (index > 1 ? 'pending' : 'approved')
                    }));
                    break;
                default:
                    approvingAuthorities = approvingAuthorities.map((approval, index) => ({
                        ...approval,
                        status: index < parseInt(currentStatus.split('_')[1]) ? 'approved' : 'pending'
                    }));
                    break;
            }
            setApprovals(approvingAuthorities)

        }).catch((error) => {
            setLoading(false);
            displayError(error)
        });
    }

    const getPositionData = (positionId) => {
        axios.get(GET_POSITION_DATA_URL + positionId
            // , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setLoading(false);
            let data = res.data;
            let positionData = data?.position;
            setPosition(positionData);
            setGroupName(positionData?.group_name)
            let modes = positionData?.mode_of_advertisement;
            if (modes) {
                modes = JSON.parse(modes);
                console.log('modesOfAdver: ', modes)
                setAdvertisementModes(modes);
            }

        }).catch((error) => {
            setLoading(false);
            displayError(error)
        });
    }

    const handleSubmit = async (reason) => {
        setLoading(true);
        const url = GET_POSITIONS_BY_ID
        const data = {
            job_id: job_id,
            published: reason ? false : true,
            job_status: reason ? "inactive" : "active",
            reason: reason,
            approver: approver
        }

        axios.post(url, data
            // , {
            //     headers: {
            //         Authorization: `Bearer ${auth?.token}`,
            //         'Content-Type': 'application/json',
            //     },
            // }
        ).then((res) => {
            setLoading(false);
            // Show success popup
            setMessage(reason ? 'Job advertisement rejected successfully!' : 'Job advertisement approved successfully!')
            setShowSuccessPopup(true);

        }).catch((error) => {
            setLoading(false);
            displayError(error)
        })

    }

    return (
        <div className='px-10 py-6' style={{ backgroundColor: '#FFFFFF', height: '100%' }}>
            <div id='candidate-offer-letter-view mx-10 mb-6 mt-10 col-md-8'>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <img src={imagePath.ic_notes_orange} className="score-header-icon-internally" alt="Job Header Icon" />
                    <label className='form-label fs-3 fw-bolder' style={{ color: '#475467', marginLeft: '8px' }}>Approval for Job Advertisement</label>
                </div>
            </div>

            <div className='card-bg shadow-sm py-8 px-10 mt-10 d-flex align-items-center mx-auto col-md-11'>
                <div className='d-flex align-items-center'>
                    <img src={imagePath.ic_group_orange} width={34} height={34} />
                    <label className='fw-bolder fs-5 ms-4'>{groupName}</label>
                </div>
                <div className='ms-auto fw-bolder fs-5'>{getFormattedDate(new Date())}</div>
            </div>
            <div className=' mt-10 d-flex align-items-center mx-auto col-md-11'>
                <p className='fs-5 fw-semibold'>{` ${groupName} is in receipt of request from ${position?.sub_functional_area} for advertisement of ${position?.title} (Approved Organogram attached ) as mentioned below. The applications will be collected by Talent Acquisition Division, P&OE Group, through BOP Recruitment portal for assessment and further shortlisting: `}</p>
            </div>

            <div className=' mt-4 align-items-center mx-auto col-md-11'>
                <div className='request-for-approvals-external-header-hiring-txt'>
                    <label>Hiring Details</label>
                </div>

                <div className='request-approval-job-posting'>
                    <div>
                        <p className='request-approval-external-posting-txt'>Position To Advertised</p>
                    </div>
                    <div>
                        <p className='request-approval-external-posting-txt'>Grade Range</p>
                    </div>
                    <div>
                        <p className='request-approval-external-posting-txt'>Nature of Hiring</p>
                    </div>
                    <div>
                        <p className='request-approval-external-posting-txt'>No. of Positions</p>
                    </div>
                    <div>
                        <p className='request-approval-external-posting-txt'>Locations</p>
                    </div>
                    <div>
                        <p className='request-approval-external-posting-txt'>Mode of advertisement</p>
                    </div>
                </div>

                <div className='request-approval-job-posting-body'>
                    <div>
                        <p className='request-approval-external-hire-txt'>
                            {position?.title}
                        </p>
                    </div>
                    <div>
                        <p className='request-approval-external-hire-txt'>{position?.max_grade_range}</p>
                    </div>
                    <div>
                        <p className='request-approval-external-hire-txt'>{`${position?.position_type === 'budgeted' ? 'Budgeted' : 'Replacement'}`}</p>
                    </div>
                    <div>
                        <p className='request-approval-external-hire-txt'>{position?.no_of_positions ? position?.no_of_positions : 1}</p>
                    </div>
                    <div>
                        <p className='request-approval-external-hire-txt'>{position?.location}</p>
                    </div>
                    <div className='request-approval-external-hire-txt mw-150px d-flex flex-wrap'>
                        {advertisementModes ? Object.keys(advertisementModes).map((key) => {
                            if (advertisementModes[key]) {
                                return (
                                    <div key={key} className='orange-tag me-2 mb-2 px-2 py-1' style={{ whiteSpace: 'nowrap' }}>
                                        {`${key === 'internal' ? 'Internal' : (key === 'rozee' ? 'Rozee.pk' : (key === 'bop' ? 'BOP Career Portal' : 'Others'))}`}
                                    </div>
                                );
                            }
                        }) : (<div></div>)}
                    </div>
                </div>
            </div>

            <div className=' mt-10 align-items-center mx-auto col-md-11'>
                <p className='fs-5 fw-semibold'>{` ${groupName} affirms that this position is being advertised as per approved organogram -2023 & Budget 2024 and in line with hiring policy of the Bank`}</p>
                <p className='fs-5 fw-semibold'>{` Approval is solicited for placement of ad as per above detail.`}</p>
            </div>

            <div className=' mt-10 d-flex align-items-center justify-content-center mx-auto col-md-11'>
                {(approvals && approvals.length > 0) ? approvals.map((data, index) => {
                    let user = data?.user;
                    return (
                        <div key={index} className='d-flex align-items-center justify-content-center'>
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <img src={data?.status === 'pending' ? imagePath.ic_warning_gray_circle : (data?.status === 'approved' ? imagePath.ic_orange_tick_circle : imagePath.ic_warning_red_circle)}
                                    width={34} height={34} />
                                <div className='d-flex align-items-center mt-4'>
                                    <img className='me-2' src={user?.profile_pic ? user?.profile_pic : imagePath.userIcon} width={32} height={32} style={{ borderRadius: '15px', objectFit: 'contain' }} />
                                    <div className='d-flex flex-column'>
                                        <label className='fs-5 fw-bolder' style={{ whiteSpace: 'nowrap' }}>{user?.fullName}</label>
                                        <label className='fs-6 fw-semibold' style={{ whiteSpace: 'nowrap' }}>{data?.title}</label>
                                        <label className='fs-6 fw-semibold' style={{ whiteSpace: 'nowrap' }}>{data?.date ? getFormattedDate(data?.date) : ''}</label>
                                    </div>
                                </div>
                                <img className={`mt-4 cursor-pointer`} src={data?.status === 'rejected' ? imagePath.ic_comment_red_holo : imagePath.ic_comment_gray_holo}
                                    width={24} height={24}
                                    onClick={() => {
                                        let obj = {
                                            authority: data,
                                            comments: allComments
                                        }
                                        // find comments of current approving authority if found show comments popup else show error 
                                        setRejectionObj(obj);
                                        setShowCommentsPopup(true)
                                    }} />
                            </div>
                            {(index < approvals.length - 1) && (
                                <div className='mx-6' style={{ height: '2px', backgroundColor: '#CBC9C9', width: '4rem' }}>

                                </div>
                            )}
                        </div>
                    )
                }) : (<div></div>)}
            </div>

            <div className=' mt-10 d-flex align-items-center justify-content-center mb-10 mx-auto col-md-11'>
                <button className='btn orange-tag fw-bolder me-8' style={{ width: '8rem' }}
                    onClick={() => setShowReasonPopup(true)}>
                    Reject
                </button>
                <button className='btn candi-btn2 fw-bolder text-white' style={{ width: '8rem' }}
                    onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(null);
                    }}>
                    Approve
                </button>
            </div>

            {loading && <LoaderView message='Loading...' />}
            {showErrorMessage && <ShowError handleClose={() => setShowErrorMessage(false)} message={message} />}
            {showReasonPopup && (
                <RequisitionRejectionPopup
                    show={true}
                    handleClose={() => setShowReasonPopup(false)}
                    handleSubmit={(reason) => {
                        setShowReasonPopup(false);
                        handleSubmit(reason)
                    }}
                />
            )}
            {showSuccessPopup && (
                <SuccessDialog
                    message={message}
                    handleClose={() => {
                        setShowSuccessPopup(false)
                        navigate('/auth')
                    }}
                />
            )}
            {showCommentsPopup && (
                <AdvertisementRejectionCommentsPopup
                    show={true}
                    reason={rejectionObj}
                    handleClose={() => {
                        setShowCommentsPopup(false);
                        setRejectionObj(null);
                    }}
                />
            )}
        </div>
    );
};

//make this component available to the app
export default AdvertisementApproval;
